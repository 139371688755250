import * as React from 'react';
import {observer} from 'mobx-react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {
  Profile,
  TrustedDevicesPage,
  SecurityActivityPage,
  AntiPhishingPage,
  LegalInfoPage,
  DeleteAccountPage,
  AccountLevelsPage,
  AppearancePage,
  PaymentMethodsPage,
  AddPaymentMethodPage,
  UmaCreatePage,
  UmaSettingsPage,
} from '../pages';
import {VerificationStepsPage} from '../pages/AppPages/Profile/VerificationSteps';
import {ProfileRewardsRoutes} from './ProfileRewardsRoutes';

const ProfileWrapped = authRequired(Profile);
const VerificationStepsWrapped = authRequired(VerificationStepsPage);
const TrustedDevicesPageWrapped = authRequired(TrustedDevicesPage);
const SecurityActivityPageWrapped = authRequired(SecurityActivityPage);
const AntiPhishingPageWrapped = authRequired(AntiPhishingPage);
const PaymentMethodsPageWrapped = authRequired(PaymentMethodsPage);
const AddPaymentMethodPageWrapped = authRequired(AddPaymentMethodPage);
const LegalInfoPageWrapped = authRequired(LegalInfoPage);
const DeleteAccountPageWrapped = authRequired(DeleteAccountPage);
const AppearancePageWrapped = authRequired(AppearancePage);
const UmaSettingsPageWrapped = authRequired(UmaSettingsPage);
const UmaCreatePageWrapped = authRequired(UmaCreatePage);
const AccountLevelsPageWrapped = authRequired(AccountLevelsPage);

export const ProfileRoutes: React.FC<RouteComponentProps> = observer(
  ({match: {path}}) => {
    const {
      products: {loyalty, rewards},
    } = AuthMeResource.use({});

    return (
      <SwitchWithNotFound>
        <Route exact path={`${path}`} component={ProfileWrapped} />
        <Route
          exact
          path={`${path}/verification`}
          component={VerificationStepsWrapped}
        />
        <Route
          exact
          path={`${path}/trusted_devices`}
          component={TrustedDevicesPageWrapped}
        />
        <Route
          exact
          path={`${path}/security_activity`}
          component={SecurityActivityPageWrapped}
        />
        <Route
          exact
          path={`${path}/anti-phishing`}
          component={AntiPhishingPageWrapped}
        />
        <Route
          exact
          path={`${path}/legal-info`}
          component={LegalInfoPageWrapped}
        />
        <Route
          exact
          path={`${path}/delete-account`}
          component={DeleteAccountPageWrapped}
        />
        {rewards.available && (
          <Route path={`${path}/rewards`} component={ProfileRewardsRoutes} />
        )}
        {loyalty.available && (
          <Route
            exact
            path={`${path}/account-level`}
            component={AccountLevelsPageWrapped}
          />
        )}
        <Route
          exact
          path={`${path}/appearance`}
          component={AppearancePageWrapped}
        />
        <Route
          exact
          path={`${path}/payment-methods`}
          component={PaymentMethodsPageWrapped}
        />
        <Route
          exact
          path={`${path}/payment-methods/add-card`}
          component={AddPaymentMethodPageWrapped}
        />
        <Route exact path={`${path}/uma`} component={UmaSettingsPageWrapped} />
        <Route
          exact
          path={`${path}/uma/create`}
          component={UmaCreatePageWrapped}
        />
      </SwitchWithNotFound>
    );
  }
);
