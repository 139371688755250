import {observable} from 'mobx';
import {primitive, serializable} from 'serializr';
import {Big} from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';

export class FeeDepositResponse {
  @serializable(primitive())
  @observable
  enabled: boolean = false;

  @serializable(primitive())
  @observable
  percent!: string;

  @serializable(bigNumber())
  @observable
  minAmount!: Big;

  @serializable(bigNumber())
  @observable
  min!: Big;

  @serializable(bigNumber())
  @observable
  maxAmount!: Big;

  @serializable(bigNumber())
  @observable
  max!: Big;
}
