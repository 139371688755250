import * as React from 'react';
import {range} from 'lodash';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

type PasscodeValueProps = {
  value: string;
  isWrong?: boolean;
};

export const PasscodeValue: React.FC<PasscodeValueProps & BoxProps> = ({
  value,
  isWrong,
  ...boxProps
}) => {
  const renderDot = React.useCallback(
    (index: number) => {
      return (
        <Box
          key={index}
          backgroundColor={
            isWrong
              ? '$danger-04'
              : index < value.length
              ? '$interactive-01'
              : '$interactive-02'
          }
          width={12}
          height={12}
          borderRadius={6}
          ml={index > 0 ? 24 : 0}
        />
      );
    },
    [value, isWrong]
  );

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      {...boxProps}
    >
      {range(4).map(renderDot)}
    </Box>
  );
};
