import React from 'react';
import {Platform, ScrollView} from 'react-native';
import {useWizard} from '@youtoken/ui.elements';
import {OpenIBANAccount} from '@youtoken/ui.surface-bank-wire';
import {FiatDepositBankWireHeader} from '../../Header';
import {
  type FiatDepositBankWireRequisitesWizardNavigator,
  FiatDepositBankWireRequisitesWizardScreenName,
} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const FiatDepositBankWireWizardOpenAccount: React.FC = () => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    FiatDepositBankWireRequisitesWizardNavigator,
    FiatDepositBankWireRequisitesWizardScreenName.OPEN_ACCOUNT
  >();

  return (
    <>
      {showHeader && (
        <FiatDepositBankWireHeader ticker={ticker} onPressBack={goBack} />
      )}
      <ScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          minHeight: '100%',
        }}
      >
        <OpenIBANAccount ticker={ticker} onClose={goBack} />
      </ScrollView>
    </>
  );
};
