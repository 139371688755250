import {computed, observable} from 'mobx';
import {Form} from './Form';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {
  formatBigNumber,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {invariant} from '@youtoken/ui.utils';

const getDiffWithValue = (value: number, toCompareWith: number) => {
  return toBig(value).sub(toCompareWith).div(toCompareWith);
};

export class View {
  @observable
  public form: Form;

  constructor(form: Form) {
    this.form = form;
  }

  @computed
  public get initialPriceFormatted() {
    return this.form.resources.hodl.data.initialPrice?.toFixed(
      this.form.resources.hodl.data.precision
    );
  }

  @computed
  public get hodlBaseTickerFormatted() {
    return this.form.resources.hodl.data.baseTicker.toUpperCase();
  }

  @computed
  public get hodlQuoteTickerFormatted() {
    return this.form.resources.hodl.data.quoteTicker.toUpperCase();
  }

  @computed
  public get inputTickerFormatted() {
    return this.form.inputTicker.toUpperCase();
  }

  //#region info table
  @computed
  private get pricesPrecision() {
    return this.form.resources.hodl.data.precision;
  }

  @computed
  private get amountsPrecision() {
    return getCoinDecimalPrecision(this.form.inputTicker);
  }

  @computed
  private get stopLossPercent() {
    invariant(
      this.form.calculatedRawData && this.form.resources.hodl.data.data.mcPrice,
      'not enough data for stop loss percent calculation',
      {},
      {
        calculatedRawData: this.form.calculatedRawData,
        mcPrice: this.form.resources.hodl.data.data.mcPrice,
      }
    );

    return getDiffWithValue(
      this.form.calculatedRawData.mcPrice,
      this.form.resources.hodl.data.data.mcPrice
    );
  }

  @computed
  private get takeProfitsPercent() {
    invariant(
      this.form.calculatedRawData &&
        this.form.resources.hodl.data.data.ftpPrice,
      'not enough data for take profits percent calculation',
      {},
      {
        calculatedRawData: this.form.calculatedRawData,
        ftpPrice: this.form.resources.hodl.data.data.ftpPrice,
      }
    );

    return getDiffWithValue(
      this.form.calculatedRawData.ftpPrice,
      this.form.resources.hodl.data.data.ftpPrice
    );
  }

  @computed.struct get infoTableFormattedData() {
    if (!this.form.calculatedRawData) {
      return null;
    }

    return {
      maxStopLossFormatted: `${formatBigNumber(
        this.form.calculatedRawData.mcPrice,
        this.pricesPrecision
      )} (${formatPercent(this.stopLossPercent)}%)`,
      maxTakeProfitFormatted: `${formatBigNumber(
        this.form.calculatedRawData.ftpPrice,
        this.pricesPrecision
      )} (${formatPercent(this.takeProfitsPercent)}%)`,
      initialPriceFormatted: `${formatBigNumber(
        this.form.calculatedRawData.initialPrice,
        this.pricesPrecision
      )}`,
      inputAmountFormatted: `${formatBigNumber(
        this.form.calculatedRawData.inputAmount,
        this.amountsPrecision
      )}`,
      hodlTickersFormatted: `${this.form.resources.hodl.data.baseTicker.toUpperCase()}/${this.form.resources.hodl.data.quoteTicker.toUpperCase()}`,
      inputTickerFormatted: this.inputTickerFormatted,
    };
  }
}
