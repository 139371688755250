import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsInstrumentsSectorsFeature} from '../../../../features/HODLsInstrumentsSectorsFeature';
import {NoResults} from '../../../../components';
import {InstrumentsList} from '../InstrumentsList';

interface InstrumentsListSmartProps {
  withoutBorders: boolean;
  onPressInstrument: () => void;
  onPressAction: () => void;
}

export const SectorsInstrumentsListSmart: React.FC<InstrumentsListSmartProps> =
  observer(({withoutBorders, onPressInstrument, onPressAction}) => {
    const {sectorInstrumentsSorted} = HODLsInstrumentsSectorsFeature.use({});

    if (sectorInstrumentsSorted.length === 0) {
      return <NoResults />;
    }

    return (
      <InstrumentsList
        items={sectorInstrumentsSorted}
        withoutBorders={withoutBorders}
        onPressInstrument={onPressInstrument}
        onPressAction={onPressAction}
      />
    );
  });
