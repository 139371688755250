import * as React from 'react';
import {observer} from 'mobx-react';
import {useChartThemeContext} from '../../Providers';
import {Dot, Label} from '../../Primitives';
import {WalletChartState} from '../../logic/ChartState/WalletChartState';
import {G, Line} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';

export const LineChartCursor: React.FC<{
  chartState: WalletChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {colors} = useTheme();

    const {
      lineCursor: {layout},
      width,
      height,
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    if (!layout) {
      return null;
    }

    const color = getColorByDirection(layout.direction);

    return (
      <G>
        <Line
          x1={0}
          x2={width - layout.value.width}
          y1={layout.dot.y}
          y2={layout.dot.y}
          stroke={colors['$text-02']}
          vectorEffect="non-scaling-stroke"
          strokeWidth={1}
        />

        <Line
          y1={0}
          y2={height - 16}
          x1={layout.dot.x}
          x2={layout.dot.x}
          stroke={colors['$text-02']}
          vectorEffect="non-scaling-stroke"
          strokeWidth={1}
        />

        <Dot x={layout.dot.x} y={layout.dot.y} fill={color} />

        <Label
          height={layout.date.height}
          width={layout.date.width}
          x={layout.date.x}
          y={layout.date.y}
          // labelOpacity={0.07}
          labelFill={colors['$ui-background']}
          textFill={colors['$text-02']}
        >
          {layout.date.text}
        </Label>

        <Label
          height={layout.value.height}
          width={layout.value.width}
          x={layout.value.x}
          y={layout.value.y}
          labelFill={color}
          textFill={colors['$text-04']}
        >
          {layout.value.text}
        </Label>
      </G>
    );
  })
);
