import React from 'react';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useIsMobile} from '@youtoken/ui.primitives';

export const Help: React.FC = () => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  return (
    <Button
      type="secondary"
      size="small"
      icon="question"
      ml={8}
      onPress={() => {
        window.open(
          'https://help.youhodler.com',
          '_blank',
          'noopener,noreferrer'
        );
      }}
    >
      {!isMobile && t('surface.menu.help')}
    </Button>
  );
};
