import * as React from 'react';
import type {SectionListData} from 'react-native';
import {Box, Text} from '@youtoken/ui.primitives';
import type {BankCardTransaction} from '@youtoken/ui.resource-bank-cards';

interface SectionHeaderProps {
  section: SectionListData<BankCardTransaction>;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({section}) => {
  return (
    <Box
      backgroundColor="$ui-overflow"
      width="100%"
      height={40}
      px={24}
      py={12}
    >
      <Text variant="$body-01" color="$text-01">
        {section.title}
      </Text>
    </Box>
  );
};
