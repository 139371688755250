import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const GiftCircle: React.FC = React.memo(() => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        width={48}
        height={48}
        borderRadius={24}
        alignItems="center"
        justifyContent="center"
        backgroundColor="$interactive-01"
      >
        <Icon name="gift" size={24} color="$text-04" />
      </Box>
    </Box>
  );
});
