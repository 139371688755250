import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {ApplePayDepositFormResources} from './types';
import {Form} from './Form';
import {formatPercent} from '@youtoken/ui.formatting-utils';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  @observable
  public resources: ApplePayDepositFormResources;

  public constructor(form: Form, resources: ApplePayDepositFormResources) {
    this.resources = resources;
    this.form = form;
    this.instance = form.instance;
  }

  @computed
  get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  public get minAmountFormatted(): string {
    return this.form.tickerFees.minAmount.toFixed(this.form.tickerPrecision);
  }

  @computed
  public get maxAmountFormatted(): string {
    return this.form.tickerFees.maxAmount.toFixed(this.form.tickerPrecision);
  }

  @computed
  public get feePercentFormatted(): string {
    return formatPercent(this.form.tickerFees.percent);
  }

  @computed
  public get feeValueFormatted(): string {
    return this.form.feeValue.toFixed(this.form.tickerPrecision, 0);
  }

  @computed
  public get receiveSumFormatted(): string {
    return this.form.sumValue
      .minus(this.feeValueFormatted)
      .toFixed(this.form.tickerPrecision);
  }

  @computed
  public get paySumFormatted(): string {
    return this.form.sumValue
      .plus(this.feeValueFormatted)
      .toFixed(this.form.tickerPrecision, 0);
  }
}
