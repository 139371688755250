const padNumber = (num: number): string => {
  return num.toString().padStart(2, '0');
};

export const formatMilliseconds = (ms: number): string => {
  const hours = Math.floor(ms / 1000 / 60 / 60) % 24;
  const days = Math.floor(ms / 1000 / 60 / 60 / 24);
  const minutes = Math.floor(ms / 1000 / 60) % 60;
  const seconds = Math.floor(ms / 1000) % 60;

  // reusable 'hh:mm:ss'
  const hms = `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;

  if (days > 0) {
    return `${days}d ${hms}`;
  }

  return hms;
};
