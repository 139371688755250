import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  CreateNewLoanButtonFeature,
  CreateNewTurboButtonFeature,
} from '../../../state';

export interface CreateNewProps
  extends Omit<React.ComponentProps<typeof Button>, 'icon' | 'disabled'> {
  mode: 'regular' | 'turbo';
}

export const CreateNew: React.FC<CreateNewProps> = observer(
  ({mode, ...rest}) => {
    if (mode === 'turbo') {
      return <CreateNewTurbo {...rest} />;
    }

    return <CreateNewLoan {...rest} />;
  }
);

export const CreateNewLoan: React.FC<Omit<CreateNewProps, 'mode'>> = observer(
  props => {
    const {t} = useTranslation();

    const CreateNewFeature = CreateNewLoanButtonFeature.use({});

    return (
      <Button
        icon="plus"
        disabled={!CreateNewFeature.enabled}
        size="large"
        type="secondary"
        {...props}
      >
        {t('surface.loans.create_new_loan')}
      </Button>
    );
  }
);

export const CreateNewTurbo: React.FC<Omit<CreateNewProps, 'mode'>> = observer(
  props => {
    const {t} = useTranslation();

    const CreateNewFeature = CreateNewTurboButtonFeature.use({});

    return (
      <Button
        icon="plus"
        disabled={!CreateNewFeature.enabled}
        size="large"
        type="secondary"
        {...props}
      >
        {t('surface.loans.create_new_turbo')}
      </Button>
    );
  }
);
