import * as React from 'react';
import {Platform} from 'react-native';
import Big from 'big.js';
import {Slider} from '@youtoken/ui.legacy-slider';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {PercentageTextInput, TextInput} from '@youtoken/ui.inputs';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {warning} from '@youtoken/ui.utils';

interface AmountAndPercentInputProps extends BoxProps {
  amountLabel?: string;
  percentLabel?: string;
  amountValue: string;
  percentValue: string;
  percentSignIsActive?: boolean;
  percentInitiallyIsNegative?: boolean;
  onChangeAmount: (val: string) => void;
  onChangePercent: (val: string) => void;
  precisionAmount?: number;
  precisionPercent?: number;
  sliderKey?: string;
  percentRangeMin?: string;
  percentRangeMax?: string;
  validationComponent?: JSX.Element | React.FC;
  setCaretPositionToEndOnFocus?: boolean;
  shouldReverseSlider?: boolean;
  testIdSection: string;
}

export const AmountAndPercentInput: React.FC<AmountAndPercentInputProps> = ({
  amountLabel,
  percentLabel,
  amountValue,
  percentValue,
  percentSignIsActive,
  percentInitiallyIsNegative,
  onChangeAmount,
  onChangePercent,
  percentRangeMin,
  percentRangeMax,
  precisionAmount = 12,
  precisionPercent = 2,
  sliderKey,
  validationComponent,
  setCaretPositionToEndOnFocus = true,
  shouldReverseSlider,
  testIdSection,
  ...props
}) => {
  const handleChangeAmount = React.useCallback(
    (value: string) => {
      onChangeAmount?.(normalizeAmountWithPrecision(value, precisionAmount));
    },
    [onChangeAmount]
  );

  const handleChangeSliderVal = React.useCallback(
    (val: number) => {
      try {
        onChangePercent(Big(val).toFixed(precisionPercent));
      } catch (e) {
        warning(
          false,
          'cannot convert ${value} to big number',
          {},
          {
            value: val,
            precision: precisionPercent,
          }
        );
      }
    },
    [onChangePercent, precisionPercent]
  );

  return (
    <Box {...props}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Box flexBasis={0} flexGrow={2} mr={6}>
          {amountLabel && (
            <Text variant="$body-02" color="$text-02" mb={8}>
              {amountLabel}
            </Text>
          )}
          <TextInput
            size="medium"
            value={amountValue}
            onChangeText={handleChangeAmount}
            textAlign="left"
            autoComplete="off"
            autoCorrect={false}
            autoCapitalize="none"
            importantForAutofill="no"
            keyboardType="decimal-pad"
            setCaretPositionToEndOnFocus={setCaretPositionToEndOnFocus}
            testID={`${testIdSection}_TEXT_INPUT`}
          />
        </Box>
        <Box
          flexBasis={120}
          flexGrow={1}
          // @ts-ignore
          maxWidth={Platform.select({web: '50%', default: 150})}
          ml={6}
        >
          {percentLabel && (
            <Text variant="$body-02" color="$text-02" mb={8}>
              {percentLabel}
            </Text>
          )}
          <PercentageTextInput
            size="medium"
            value={percentValue}
            precision={precisionPercent}
            onChangeText={onChangePercent}
            signIsActive={percentSignIsActive}
            initiallyIsNegative={percentInitiallyIsNegative}
            testID={`${testIdSection}_PERCENT_INPUT`}
          />
        </Box>
      </Box>

      {React.isValidElement(validationComponent) && validationComponent}

      {sliderKey && (
        <Box>
          <Slider
            value={Number(percentValue)}
            minimumValue={Number(percentRangeMin)}
            maximumValue={Number(percentRangeMax)}
            onChange={handleChangeSliderVal}
            key={sliderKey}
            step={0.01}
            showLegend={false}
            shouldBeReversed={shouldReverseSlider}
            testID={`${testIdSection}_SLIDER`}
          />
        </Box>
      )}
    </Box>
  );
};
