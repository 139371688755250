import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {useResource} from '@youtoken/ui.data-storage';

export const NaumardInfo: React.FC<BoxProps> = observer(({...props}) => {
  const {naumard} = useResource(CompanyResource, {});

  return (
    <Box {...props}>
      <Text variant="$body-02" textTransform="uppercase" mb={12}>
        {naumard?.name}
      </Text>
      {Boolean(naumard?.address) && (
        <Text variant="$body-02" color="$text-02" mb={4}>
          {naumard?.address}
        </Text>
      )}
      {Boolean(naumard?.phone) && (
        <Text variant="$body-02" color="$text-02" mb={4}>
          {naumard?.phone}
        </Text>
      )}
      <Box flexDirection="row">
        <Link
          url={`mailto:${naumard?.email}`}
          variant="$body-02"
          color="$text-02"
        >
          {naumard?.email}
        </Link>
      </Box>
    </Box>
  );
});
