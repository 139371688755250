import React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {FeatureLoanCreateLandingForm} from '../../LoanRegularCreateLandingForm/state';

export const LoanRepay: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    conversionAmount,
    conversionTicker,
    apr,
    maxDurationValue,
    maxDurationLabel,
    repayUntil,
  } = useForm<FeatureLoanCreateForm | FeatureLoanCreateLandingForm>();

  return (
    <>
      <Box flexDirection="row" alignItems="center" mb={8}>
        <Text variant="$body-01" color="$text-02">
          <Trans
            t={t}
            i18nKey={'surface.loans.create_form.loan'}
            components={{
              Strong: <Text variant="$heading-01" color="$text-01" />,
            }}
            values={{
              amount: conversionAmount,
              ticker: conversionTicker,
            }}
          />
        </Text>
        <Box width={1} height={30} mx={8} bg="$ui-02" />
        <Text variant="$body-01" color="$text-02">
          <Trans
            t={t}
            i18nKey={'surface.loans.create_form.loan_apr'}
            components={{
              Strong: <Text variant="$heading-01" color="$text-01" />,
            }}
            values={{
              apr: apr,
            }}
          />
        </Text>
      </Box>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.loans.create_form.loan_repay_until_v2', {
          maxDurationValue: maxDurationValue,
          maxDurationLabel: maxDurationLabel,
          date: repayUntil,
        })}
      </Text>
    </>
  );
});
