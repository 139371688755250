import {getLocaleByLang} from './getLocaleByLang';

export function wrap<T extends (...args: any[]) => any>(fn: T) {
  return (...args: Parameters<T>): ReturnType<T> => {
    const locale = getLocaleByLang();

    const newArgs = [...args];
    let lastArgPosition = fn.length - 1;
    let lastArg: unknown = newArgs[lastArgPosition];

    // NOTE for formatDistanceStrict, formatDistance
    // they have only two args in function definition
    // but in fact they could accept three arguments
    // that's why this hack exists
    if (lastArg instanceof Date || typeof lastArg === 'number') {
      lastArgPosition = fn.length;
    }

    newArgs[lastArgPosition] = {
      locale,
      ...newArgs[lastArgPosition],
    };

    return fn(...newArgs);
  };
}
