import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  type TouchableBoxProps,
  TouchableBox,
  Text,
} from '@youtoken/ui.primitives';

export interface AccountLevelInfoItemAllBenefitsProps {
  level: number;
}

export const AccountLevelInfoItemAllBenefits: React.FC<
  AccountLevelInfoItemAllBenefitsProps & TouchableBoxProps
> = observer(({level, ...touchableBoxProps}) => {
  const {t} = useTranslation();

  const onPress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('AccountLevelAllBenefits', {level});
  }, [level]);

  return (
    <TouchableBox
      testID="ACCOUNT_LEVEL_INFO_ITEM_ALL_BENEFITS_BUTTON"
      alignItems="center"
      onPress={onPress}
      p={12}
      {...touchableBoxProps}
    >
      <Text variant="$body-02-high-accent">
        {t('surface.loyalty.all_benefits')}
      </Text>
    </TouchableBox>
  );
});
