import {observable} from 'mobx';
import {Form} from './Form';
import {View} from '../View';
import {
  type ExchangeUnauthorizedFormArgs,
  type ExchangeUnauthorizedFormResources,
} from './types';

export class ExchangeUnauthorizedFormState {
  @observable
  public form: Form;

  @observable
  public view: View;

  constructor(
    resources: ExchangeUnauthorizedFormResources,
    args: ExchangeUnauthorizedFormArgs
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
