import * as React from 'react';
import {Helmet} from 'react-helmet';
import {Box} from '@youtoken/ui.primitives';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {AuthFormWrapper} from '@web-app/src/components/layout/Shell/AppShell/AuthFormWrapper';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {EmailVerificationOnSignupSurfaceCell} from '@youtoken/ui.surface-email-verification-on-signup';

export const VerifyEmailPage: React.FC = () => {
  const {t} = useTranslation();

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
        renderRight={() => {
          return (
            <Box flexDirection="row" alignItems="center" flexShrink={1}>
              <LanguageSwitch
                variant="icon"
                size="small"
                width={96}
                dropdownWidth={116}
                boxProps={{ml: 16}}
              />
            </Box>
          );
        }}
      />
    );
  }, []);

  const {queryParams} = RouterResource.use({});

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
      <Helmet title={t('surface.verify_email.title')} />
      <OuterPageShell>
        <AuthFormWrapper>
          <EmailVerificationOnSignupSurfaceCell
            operationId={queryParams.operationId}
          />
        </AuthFormWrapper>
      </OuterPageShell>
    </AppShell>
  );
};
