import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {FacebookIcon as facebook} from './Facebook';
import {InstagramIcon as instagram} from './Instagram';
import {LightsparkIcon as lightspark} from './Lightspark';
import {LinkedinIcon as linkedin} from './Linkedin';
import {TelegramIcon as telegram} from './Telegram';
import {TwitterIcon as twitter} from './Twitter';
import {YoutubeIcon as youtube} from './Youtube';

export type IconName =
  | 'facebook'
  | 'instagram'
  | 'lightspark'
  | 'linkedin'
  | 'telegram'
  | 'twitter'
  | 'youtube';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  facebook,
  instagram,
  lightspark,
  linkedin,
  telegram,
  twitter,
  youtube,
};
