import {computed, action} from 'mobx';
import {createResource} from '@youtoken/ui.data-storage';
import {ChartResource} from './types';
import {getData} from './utils';

/** base chart data resource
 * - fetch data;
 * - use intervals to refetch;
 * - does __not__ use sockets;
 */
export class ChartDataResource extends createResource<
  ChartResource.Args,
  ChartResource.Data
>({
  skipRefreshOnVisible: false,
  getKey: ({key, product, fromTicker, toTicker, fromDate, toDate, mode}) => {
    return `chartData:${key}:${fromTicker}:${toTicker}:${fromDate}:${toDate}:${product}:${mode}`;
  },

  getData: args => getData(args),
  cacheTime: 60 * 1000,
  refetchInterval: 60 * 1000,
  defaultArgs: {
    tick: '15m',
    timePeriod: '24h',
    type: 'line',
    linePoints: 120,
    candlesPoints: 70,
  },
}) {
  constructor(args: ChartResource.Args, data: ChartResource.Data) {
    super(args, data);
  }

  onDestroy() {
    super.onDestroy();
  }

  @computed get chartData(): ChartResource.Data {
    return this.data;
  }

  /** chart type */
  @computed get chartType(): ChartResource.Type {
    return this.args.type!;
  }

  @action setChartType = (type: ChartResource.Type) => {
    this.args.type = type;
  };

  @computed get timePeriod() {
    return this.args.timePeriod!;
  }

  @action setTimePeriod = (timePeriod: ChartResource.TimePeriod) => {
    this.args.timePeriod = timePeriod;
  };

  @computed get tick() {
    return this.args.tick!;
  }

  @action setTick = (tick: ChartResource.Tick) => {
    this.args.tick = tick;
  };

  @computed get mode() {
    return this.args.mode;
  }

  @action setMode = (mode: 'mid' | 'ask' | 'bid') => {
    this.args.mode = mode;
  };

  @computed get hasData() {
    return this.data.data.length >= 2;
  }

  @computed get noData() {
    return !this.hasData;
  }

  @action setFromTicker = (fromTicker: string) => {
    this.args.fromTicker = fromTicker;
  };

  @action setToTicker = (toTicker: string) => {
    this.args.toTicker = toTicker;
  };

  @computed get chartControls() {
    return {
      type: this.chartType,
      timePeriod: this.timePeriod,
      tick: this.tick,
      setChartType: this.setChartType,
      setTimePeriod: this.setTimePeriod,
      setTick: this.setTick,
      mode: this.mode,
      setMode: this.setMode,
      setFromTicker: this.setFromTicker,
      setToTicker: this.setToTicker,
    };
  }
}
