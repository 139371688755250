import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation, i18n} from '@youtoken/ui.service-i18n';

const isNative = Platform.select({
  native: true,
  web: false,
});

const handlePress = () => {
  if (isNative) {
    return; // NOTE: just in case, this component is normally not rendered on native
  }

  const tab = window.open(
    i18n.t('url.profile.account.fees.commissions_and_limits.link'),
    '_blank'
  );
  tab && tab.focus();
};

export const HelpDesk: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Button
      size="large"
      type="secondary"
      icon="external_link"
      onPress={handlePress}
      {...boxProps}
    >
      {t('surface.profile.account.fees.action_2')}
    </Button>
  );
});
