import * as React from 'react';
import {TFuncKey} from 'react-i18next';
import {observer} from 'mobx-react';
import {Link} from '@youtoken/ui.elements';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const DisclosureIbans: React.FC<BoxProps> = observer(boxProps => {
  const {legalEntity} = AuthMeResource.use({});
  const {
    documents: {fiatRepublicIban},
  } = DocsResource.use({country: legalEntity});

  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
        {t('surface.profile.legal_info.subtitle.ibans_savings')}
      </Heading>

      <Box mb={16}>
        <Text variant="$body-02" textTransform="uppercase" mb={12}>
          {t('surface.profile.legal_info.ibans_savings.name')}
        </Text>

        <Text variant="$body-02" color="$text-02" mb={4}>
          {t('surface.profile.legal_info.ibans_savings.address')}
        </Text>
        <Text variant="$body-02" color="$text-02" mb={4}>
          {t('surface.profile.legal_info.ibans_savings.number')}
        </Text>
      </Box>

      <Text variant="$body-02" textTransform="uppercase" mb={12}>
        {t('surface.profile.legal_info.subtitle.documents')}
      </Text>

      {Boolean(fiatRepublicIban) &&
        fiatRepublicIban!.map(item => {
          const itemUrl = item.link;
          const itemName = item.i18nName
            ? t(item.i18nName as TFuncKey)
            : item.name;

          return (
            <Box flexDirection="row" mb={8} key={item.link}>
              <Link
                underlined
                url={itemUrl}
                variant="$body-02"
                color="$text-02"
              >
                {itemName}
              </Link>
            </Box>
          );
        })}
    </Box>
  );
});
