import {action, computed, observable, runInAction} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {
  getTranslatedValidationMessage,
  handleGeneralErrorTranslated,
} from '@youtoken/ui.validation-messages';
import type {
  TwoFactorEnableGAFormArgs,
  TwoFactorEnableGAFormResources,
} from './types';
import {Clipboard} from '@youtoken/ui.utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {TRANSPORT} from '@youtoken/ui.transport';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';

export class Form {
  @observable
  public args: TwoFactorEnableGAFormArgs;

  @observable
  public resources: TwoFactorEnableGAFormResources;

  @observable
  public instance: MobxReactForm;

  @observable secretCode = '';
  @observable operationId = '';
  @observable otpAuth = '';
  @observable loading = true;

  @computed public get code() {
    return this.instance.$('code').get('value');
  }

  @computed
  public get codeError() {
    return getTranslatedValidationMessage(this.instance.$('code').get('error'));
  }

  @computed get codeOnChange() {
    return this.instance.$('code').get('onChange');
  }

  @computed public get email() {
    return this.resources.authMe.userEmail;
  }

  public constructor(
    args: TwoFactorEnableGAFormArgs,
    resources: TwoFactorEnableGAFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          code: {
            value: '',
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  code: yup.string().required(),
                };
                return yup.object().shape(schema);
              });
            },
          }),
        },
        hooks: {
          onSuccess: () => {
            return __GLOBAL_RECAPTCHA__
              .requestToken('request')
              .then((token: any) => {
                return this.resources.authMe
                  .confirmOperation({
                    token,
                    operationId: this.operationId,
                    code: this.code,
                  })
                  .then(() => {
                    return this.resources.authMe.signOut();
                  })
                  .catch(e => {
                    handleFormSubmitError(this.instance, e);
                  });
              });
          },
        },
        options: {
          validateOnChange: true,
        },
      }
    );
  }

  @action
  createOperation = () => {
    const {requestToken} = __GLOBAL_RECAPTCHA__;
    this.loading = true;

    return requestToken('2fa_enable')
      .then(token => {
        return TRANSPORT.API.post('/v1/security/enable-2fa', {
          token,
          type: 'ga',
        }).then(({data}) => {
          runInAction(() => {
            this.secretCode = data.secret;
            this.operationId = data.operationId;
            this.otpAuth = data.otpAuth;
          });
          return data;
        });
      })
      .catch((e: any) => {
        handleGeneralErrorTranslated(e.response?.data);
      })
      .finally(() => {
        this.loading = false;
      });
  };

  @action copyCodeToClipboard = () => {
    Clipboard.setString(this.secretCode);
    LOCAL_NOTIFICATIONS.info({
      text: i18n.t('common.notifications.copied'),
    });
  };
}
