import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';

type ModalNoticeProps = {
  text: string;
} & BoxProps;

export const ModalNotice: React.FC<ModalNoticeProps> = ({
  text,
  ...boxProps
}) => {
  return (
    <Box
      backgroundColor="$attention-02"
      p={24}
      alignItems="center"
      justifyContent="center"
      width="100%"
      {...boxProps}
    >
      <Text variant="$body-01" color="$attention-01">
        {text}
      </Text>
    </Box>
  );
};
