import React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {FeatureListSize} from './types';
import {FeatureListItem} from './FeatureListItem';

interface FeatureListProps {
  size?: FeatureListSize;
  items: {
    icon: React.ComponentProps<typeof Icon>['name'];
    title?: string;
    children?: React.ReactNode;
  }[];
}

export const FeatureList: React.FC<BoxProps & FeatureListProps> = ({
  size,
  items,
  ...rest
}) => {
  return (
    <Box px={8} {...rest}>
      {items &&
        items.map(({icon, title, children}, index) => {
          return (
            <FeatureListItem
              key={index}
              size={size}
              icon={icon}
              title={title}
              mb={index === items.length - 1 ? 0 : 24}
            >
              {children}
            </FeatureListItem>
          );
        })}
    </Box>
  );
};
