import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {IncentivesBonusForDepositSmart} from '@youtoken/ui.elements-smart';
import {CardDepositForm} from '../../state';

export const IncentivesBonuses: React.FC<BoxProps> = observer(boxProps => {
  const {
    view: {incentivesBonusFormatted},
  } = useForm<CardDepositForm>();

  return (
    <IncentivesBonusForDepositSmart
      bonusesValue={incentivesBonusFormatted}
      {...boxProps}
    />
  );
});
