import * as React from 'react';
import {reaction} from 'mobx';
import {i18n, getOrDetectLocalLanguage} from '@youtoken/ui.service-i18n';
import {createRoot} from 'react-dom/client';
import App from './App';
import {createBrowserHistory} from 'history';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {analytics} from '@web-app/src/utils/analytics/segment';
import {AppEnv, getGoogleClientId} from '@youtoken/ui.env-utils';
import {EnvSelector} from './EnvSelector';
import {setHistoryRef} from '../services/shared-router';
import {initEnvironmentService} from '@web-app/src/services/environment';
import {ENVIRONMENT} from '@youtoken/ui.environment';

DATA_LAYER.track = analytics.track;

function assingLangToHTML(lang: string) {
  try {
    document.documentElement.setAttribute('lang', lang);
  } catch (e) {}
}

// just leave it here for now
function syncLangWithHTMLTag() {
  try {
    assingLangToHTML(getOrDetectLocalLanguage());

    reaction(
      () => i18n.language,
      lang => {
        assingLangToHTML(lang);
      },
      {
        fireImmediately: false,
      }
    );
  } catch (e) {}
}

/** Init app hook export in react-create-app defined env
 *
 * Creates store, awaits rehydration and renders app
 *
 * Also imports and sets up a service-worker
 */
export default function initApp() {
  const rootElement = document.getElementById('root');

  const root = createRoot(rootElement!);

  initEnvironmentService();

  syncLangWithHTMLTag();

  if (ENVIRONMENT.APP_ENV === AppEnv.local) {
    return root.render(<EnvSelector />);
  }

  const GOOGLE_CLIENT_ID = getGoogleClientId(ENVIRONMENT.APP_ENV, 'web');

  const history = createBrowserHistory();

  // provide history ref
  setHistoryRef(history);

  root.render(<App history={history} googleClientID={GOOGLE_CLIENT_ID} />);
}
