import * as React from 'react';
import {parse} from 'query-string';
import {FormikCustom} from '@web-app/src/components/elements/Forms';
import {
  useOnSubmit,
  useInitialValues,
  useValidationSchema,
} from '@web-app/src/hooks';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SubmitButton} from '@web-app/src/components/elements/Forms';
import {Box, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {FieldErrorMessage, Label} from '@youtoken/ui.form-elements';
import {PasswordTextInput} from '@youtoken/ui.inputs';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {RestoreAccessLine} from '../../Elements';

export type FormValues = {
  password: string;
  code: string;
};

export const Form: React.FC = () => {
  const {setNewPassword} = AuthMeResource.use({});

  const queryParams = React.useMemo(() => parse(location.search), [location]);

  const {t} = useTranslation();

  const initialValues = useInitialValues<FormValues>({
    password: '',
    code: queryParams.code as string,
  });

  const onSubmit = useOnSubmit<FormValues>(
    values => {
      // @ts-ignore
      return setNewPassword(values).then(() => {
        SHARED_ROUTER_SERVICE.navigate('SignIn');
      });
    },
    {
      successMessage: t('forms.new_password.messages.success'),
    }
  );

  const validationSchema = useValidationSchema<FormValues>(yup => {
    return {
      password: yup.string().max(128).required(),
      code: yup.string().required(),
    };
  });

  return (
    <>
      <Heading variant="$heading-01-responsive" mb={25}>
        {t('forms.new_password.title')}
      </Heading>
      <FormikCustom
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={({values, errors, handleChange}) => {
          return (
            <Box alignItems="stretch">
              <Label>{t('forms.new_password.fields.password.label')}</Label>
              <Box mb={15} mt={10}>
                <PasswordTextInput
                  hasError={Boolean(errors.password)}
                  size="medium"
                  value={values.password}
                  onChange={handleChange('password')}
                />
                <FieldErrorMessage
                  visible={Boolean(errors.password)}
                  placement="left"
                >
                  <ValidationMessageComponent
                    error={errors.password as string}
                  />
                </FieldErrorMessage>
              </Box>

              <SubmitButton size="large" mb={30}>
                {t('forms.new_password.restore')}
              </SubmitButton>
              <RestoreAccessLine />
            </Box>
          );
        }}
      />
    </>
  );
};
