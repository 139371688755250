import {useTranslation} from '@youtoken/ui.service-i18n';
import {ValidationMessageLocalized} from './ValidationMessageLocalized';
import {getTranslatedValidationMessage} from './getTranslatedValidationMessage';

export const useValidationMessage = (
  error: string | ValidationMessageLocalized | null
): string => {
  const {t} = useTranslation();

  return getTranslatedValidationMessage(error, t);
};
