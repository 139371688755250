import * as React from 'react';
import {Keyboard, Platform} from 'react-native';

export const useIsKeyboardVisible = () => {
  const [isKeyboardVisible, setKeyboardVisible] = React.useState(false);

  React.useEffect(() => {
    const keyboardShowListener = Keyboard.addListener(
      Platform.OS == 'android' ? 'keyboardDidShow' : 'keyboardWillShow',
      () => {
        setKeyboardVisible(true);
      }
    );
    const keyboardHideListener = Keyboard.addListener(
      Platform.OS == 'android' ? 'keyboardDidHide' : 'keyboardWillHide',
      () => {
        setKeyboardVisible(false);
      }
    );

    return () => {
      keyboardHideListener.remove();
      keyboardShowListener.remove();
    };
  }, []);

  return isKeyboardVisible;
};
