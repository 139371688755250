import {observable} from 'mobx';
import {CreateHODLForm} from './Form';
import {CreateHODLFormView} from './View';
import {CreateHODLFormArgs, CreateHODLFormResources} from './types';

export type {CreateHODLFormArgs} from './types';

export class CreateHODLFormClass {
  @observable
  form: CreateHODLForm;

  @observable
  view: CreateHODLFormView;

  constructor(resources: CreateHODLFormResources, args: CreateHODLFormArgs) {
    this.form = new CreateHODLForm(resources, args);
    this.view = new CreateHODLFormView(this.form);
  }
}
