import React from 'react';
import {Box, Text, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type FeatureListSize} from './types';

interface FeatureListItemProps {
  icon: React.ComponentProps<typeof Icon>['name'];
  title?: string;
  size?: FeatureListSize;
}

export const FeatureListItem: React.FC<FeatureListItemProps & BoxProps> = ({
  title,
  icon,
  children,
  size = 'medium',
  ...props
}) => {
  const elementsSizes = React.useMemo(() => {
    const sizes = {
      large: {box: 56, icon: 32},
      medium: {box: 40, icon: 20},
    };

    return sizes[size];
  }, [size]);

  return (
    <Box flexDirection="row" {...props}>
      <Box mr={16}>
        <Box
          width={elementsSizes.box}
          justifyContent="center"
          alignItems="center"
          height={elementsSizes.box}
          backgroundColor="$interactive-02"
          borderRadius={50}
        >
          <Icon name={icon} size={elementsSizes.icon} color="$interactive-01" />
        </Box>
      </Box>
      <Box flex={1} justifyContent="center">
        <Box mt={4} mb={4}>
          <Heading variant="$heading-04" color="$text-05">
            {title}
          </Heading>
        </Box>
        {typeof children === 'string' && (
          <Text variant="$body-02">{children}</Text>
        )}
        {typeof children !== 'string' && <>{children}</>}
      </Box>
    </Box>
  );
};
