import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Timer} from '@youtoken/ui.elements';
import {warning} from '@youtoken/ui.utils';

export interface ClaimingCountdownProps {
  tickerSymbol: string;
  amountFormatted: string;
  timeLeft: number;
  onTimerEnd?: () => void;
}

export const ClaimingCountdown: React.FC<ClaimingCountdownProps> = observer(
  ({tickerSymbol, amountFormatted, timeLeft, onTimerEnd}) => {
    const {t} = useTranslation();

    warning(timeLeft, 'timeLeft is not defined');

    return (
      <Box testID="SAVINGS_CLAIMING_COUNTDOWN">
        <Text variant="$heading-02" color="$success-01" fontWeight={500} mb={4}>
          <Text testID="SAVINGS_EARNED_SYMBOL">{tickerSymbol}</Text>
          <Text testID="SAVINGS_EARNED_AMOUNT">{amountFormatted}</Text>
        </Text>
        <Text testID="SAVINGS_PAYOUT_IN" variant="$body-02" color="$text-02">
          <Trans
            t={t}
            i18nKey="surface.savings.payout_in"
            components={{
              Timer: (
                <Timer
                  color="$text-02"
                  timeLeft={timeLeft}
                  onTimerEnd={onTimerEnd}
                />
              ),
            }}
          />
        </Text>
      </Box>
    );
  }
);
