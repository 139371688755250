import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type ErrorFallbackProps} from '@youtoken/ui.error-boundary';
import {TouchableBox, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const RewardsBannerErrroFallback: React.FC<ErrorFallbackProps> =
  observer(({dismissError}) => {
    const {t} = useTranslation();

    return (
      <TouchableBox
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        onPress={dismissError}
      >
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          px={16}
        >
          <Icon name="refresh" size={20} color="$interactive-01" />
          <Box flex={1} ml={8}>
            <Text variant="$body-02-medium-accent" color="$interactive-01">
              {t('common.errors.smth_went_wrong')}
            </Text>
          </Box>
        </Box>
      </TouchableBox>
    );
  });
