import {observable, action} from 'mobx';
import {Form} from './Form';
import {
  type RestorePasswordFormArgs,
  type RestorePasswordFormResources,
} from './types';

export class RestorePasswordForm {
  @observable
  public form: Form;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(
    args: RestorePasswordFormArgs,
    resources: RestorePasswordFormResources
  ) {
    this.form = new Form(args, resources);
  }
}
