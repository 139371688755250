import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ExchangeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          d="M11.879 14H9.74l-.008-3 .013-.233A2 2 0 0111.733 9h17l.233.013A2 2 0 0130.733 11v2l.014.204a1.5 1.5 0 002.972 0l.014-.204v-2a5 5 0 00-5-5h-17a5 5 0 00-5 5v3H4.707c-.89 0-1.337 1.077-.707 1.707l3.586 3.586a1 1 0 001.414 0l3.586-3.586c.63-.63.184-1.707-.707-1.707zm17.035 12h2.138l.008 3-.013.233A2 2 0 0129.06 31H12l-.233-.014A2 2 0 0110 29v-2l-.014-.203a1.5 1.5 0 00-2.972 0L7 27v2a5 5 0 005 5h17.06a5 5 0 005-5v-3h2.026c.89 0 1.337-1.077.707-1.707l-3.586-3.586a1 1 0 00-1.414 0l-3.586 3.586c-.63.63-.184 1.707.707 1.707z"
          fill={color}
        />
      </Svg>
    );
  }
);
