import * as React from 'react';
import {useCallback} from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text, TouchableText} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {INTERCOM_SERVICE} from '@youtoken/ui.service-intercom';

export const RestoreAccessLine: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const openIntercom = useCallback(() => {
    INTERCOM_SERVICE.displayMessenger();
  }, []);

  return (
    <Box {...boxProps}>
      <Trans
        t={t}
        i18nKey="surface.auth.to_restore"
        style={{flexWrap: 'wrap'}}
        parent={props => (
          <Text variant="$body-02" color="$text-02" {...props} />
        )}
        defaults="To restore account access, <IntercomButton>contact support</IntercomButton>"
        components={{
          IntercomButton: (
            <TouchableText
              variant="$body-02-medium-accent"
              color="$interactive-01"
              onPress={openIntercom}
            />
          ),
        }}
      />
    </Box>
  );
});
