import React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useResource} from '@youtoken/ui.data-storage';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {Tooltip} from '@youtoken/ui.tooltip';
import {BadgeContent} from './components/BadgeContent';

interface IBANBadgeProps {
  ticker: string;
  onShowTooltip?: () => void;
  onPress?: () => void;
}

export const IBANBadge: React.FC<IBANBadgeProps> = observer(
  ({ticker, onShowTooltip, onPress}) => {
    const isTooltipAvailable = Platform.select({default: true, native: false});

    const {getBadgeData} = useResource(IBANAccountsResource, {});

    const {variant, tooltipText} = getBadgeData(ticker);

    if (isTooltipAvailable) {
      return (
        <Tooltip
          strictPosition
          position="top"
          content={tooltipText}
          contentProps={{alignItems: 'center'}}
          width={200}
          onShow={onShowTooltip}
        >
          <BadgeContent ticker={ticker} variant={variant} onPress={onPress} />
        </Tooltip>
      );
    }

    return <BadgeContent ticker={ticker} variant={variant} onPress={onPress} />;
  }
);
