import {setStringAsync, getStringAsync} from 'expo-clipboard';

export const Clipboard = {
  setString: (value: string): Promise<boolean> => {
    return setStringAsync(value);
  },
  getString: (): Promise<string> => {
    return getStringAsync();
  },
};
