import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {Separator} from '@youtoken/ui.elements';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {
  AccountLevelProgressBarVariant,
  AccountLevelProgressWidgetV2,
} from '@youtoken/ui.surface-account-levels';
import {CreateHODLFormClass} from '../CreateHODLForm';
import {
  AmountSection,
  ChartSection,
  DetailsSection,
  FooterSection,
  MultiplySection,
  PendingOrderSection,
  StopLossSection,
  SwitchAdjustTpSlSection,
  TakeProfitSection,
  TariffAndDirectionSection,
  TradingViewChartSection,
  UseBonusesSection,
} from '../sections';
import {type CreateHODLSurfaceProps} from './types';

export const CreateHODLSurface: React.FC<CreateHODLSurfaceProps> = ({
  ...props
}) => {
  const resources = useResources({
    ratesResource: getResourceDescriptor(RatesResource, {
      product: 'hodl',
    }),
    ratesResourceDefault: getResourceDescriptor(RatesResource, {}),
    authMeResource: getResourceDescriptor(AuthMeResource, {}),
    walletsResource: getResourceDescriptor(WalletsResource, {}),
    tariffsResource: getResourceDescriptor(HodlsTariffsResource, {}),
    verificationResource: getResourceDescriptor(VerificationResource, {}),
  });

  const formClass = makeForm(
    () =>
      new CreateHODLFormClass(resources, {
        ...props,
      })
  );

  const {
    form: {volumeForecast, sparksAmount},
  } = formClass;

  const [isTradingViewChartOpen, setIsTradingViewChartOpen] =
    React.useState(false);

  const handleTradingViewChartOpen = React.useCallback(() => {
    setIsTradingViewChartOpen(true);
  }, []);

  const handleTradingViewChartClose = React.useCallback(() => {
    setIsTradingViewChartOpen(false);
  }, []);

  return (
    <Form form={formClass}>
      <TariffAndDirectionSection />
      {!isTradingViewChartOpen && (
        <ChartSection
          mt={15}
          mb={8}
          height={485}
          onPressTradingViewModeButton={handleTradingViewChartOpen}
        />
      )}
      {isTradingViewChartOpen && (
        <TradingViewChartSection onClose={handleTradingViewChartClose} />
      )}
      <AmountSection px={24} />
      <MultiplySection px={24} pt={28} pb={24} />
      <AccountLevelProgressWidgetV2
        variant={AccountLevelProgressBarVariant.HODL}
        volume={volumeForecast}
        sparks={sparksAmount}
        m={24}
        mt={0}
      />
      {formClass.form.bonusesEnabled && (
        <>
          <Separator />
          <UseBonusesSection px={24} py={24} />
        </>
      )}
      <PendingOrderSection px={24} py={24} />
      <Separator />
      <SwitchAdjustTpSlSection px={24} py={24} />
      {formClass.form.adjustTpSlActive && (
        <>
          <TakeProfitSection px={24} py={8} />
          <StopLossSection px={24} pt={20} mb={32} />
        </>
      )}
      <Separator />
      <DetailsSection px={24} pt={16} />
      <FooterSection px={24} />
    </Form>
  );
};
