import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {INTERCOM_SERVICE} from '@youtoken/ui.service-intercom';
import {MenuItemBase} from './MenuItemBase';

export const HelpCenter: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <MenuItemBase
      icon="question_outlined"
      title={t('surface.more.item_name.help')}
      onPress={INTERCOM_SERVICE.displayHelpCenter}
      {...boxProps}
    />
  );
});
