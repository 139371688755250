/**
 * Truncate long fractional part of prices and replace with ellipsis
 * @example
 * '0.000123412345' => '0.00…12345'
 * @param priceString String representation of the price
 * @param fractionalPartMaxLength Maximum length of the fractional part (default: 8)
 * @return: The price string with the fractional part truncated and replaced with ellipsis if necessary
 */
export const priceFormatterWithEllipsis = (
  priceString: string,
  fractionalPartMaxLength: number = 8
) => {
  const [integer, fractional] = priceString.split('.');
  // Return original string if fractional part is absent or within max length
  if (!fractional || fractional.length <= fractionalPartMaxLength) {
    return priceString;
  }
  // Extract the start and end parts of the fractional string based on the max length
  const fractionalStart = fractional.slice(0, 2);
  const fractionalEnd = fractional.slice(-(fractionalPartMaxLength - 3));
  // Format the result with the ellipsis in between the start and the end parts
  return `${integer}.${fractionalStart}…${fractionalEnd}`;
};
