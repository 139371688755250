import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {HowItWorksBanner as HowItWorksBannerComponent} from '../components';

export const HowItWorksBanner: React.FC<BoxProps> = observer(boxProps => {
  const {
    data: {
      clientSettings: {enableMinerHowItWorksBanner},
    },
    setMinerHowItWorksBannerEnabled,
  } = AuthMeResource.use({});

  const handleBannerClose = React.useCallback(() => {
    setMinerHowItWorksBannerEnabled(false);
  }, [setMinerHowItWorksBannerEnabled]);

  if (!enableMinerHowItWorksBanner) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <HowItWorksBannerComponent onClose={handleBannerClose} />
    </Box>
  );
});
