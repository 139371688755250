import {Platform} from 'react-native';
import {action, computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import Big from 'big.js';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import * as yupPackage from '@youtoken/ui.yup';
import {coinNames} from '@youtoken/ui.coin-utils';
import {messages} from '@youtoken/ui.validation-messages';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {TRANSPORT} from '@youtoken/ui.transport';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {openBrowser} from '@youtoken/ui.resource-bank-cards';
import {
  type BankCardTransferFormArgs,
  type BankCardTransferFormResources,
} from './types';

export class Form {
  @observable
  public args: BankCardTransferFormArgs;

  @observable
  public resources: BankCardTransferFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(
    args: BankCardTransferFormArgs,
    resources: BankCardTransferFormResources
  ) {
    this.args = args;
    this.resources = resources;

    const fields = {
      sum: {
        name: 'sum',
        label: 'From',
      },
    };

    const hooks = {
      onSuccess: () => {
        const returnUrl = Platform.select({
          native: '/wallets/cards/consent-final',
          web: '/wallets/cards',
        });

        return (
          TRANSPORT.API.post('/v1/intergiro/cards/pay', {
            cardId: this.args.cardId,
            amount: formatByTicker(this.sumValue, this.ticker),
            redirectURL: returnUrl,
          })
            .then(({data: {redirectURL}}) => {
              return openBrowser(redirectURL);
            })
            // when the browser is closed on iOS.
            // Can't quite bind logic to this moment on Android, so the form will have to be closed right away.
            .then(() => {
              SHARED_ROUTER_SERVICE.navigate('BankCards');
              this.resources.bankCards.refetch();
            })
            .catch(error => {
              handleFormSubmitError(this.instance, error);
            })
        );
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              sum: yup
                .big()
                .gt(0, () =>
                  messages.SHOULD_BE_GT({
                    value: '0',
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .gte(this.minAmount, () =>
                  messages.MIN_AMOUNT({
                    value: this.minAmount.toString(),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .lte(this.maxAmount, () =>
                  messages.MAX_AMOUNT({
                    value: this.maxAmount.toString(),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .lte(this.card.balance, () => messages.FUNDS_INSUFFICIENT())
                .required(messages.REQUIRED),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @computed
  get card() {
    return this.resources.bankCards.getCardById(this.args.cardId);
  }

  //#region ticker
  ticker = 'eur';

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  get tickerName() {
    return coinNames[this.ticker.toLowerCase()];
  }
  //#endregion ticker

  //#region sum field
  @computed
  public get sumField() {
    return this.instance.$('sum');
  }

  @computed
  get sumValue(): Big {
    return toBig(this.sumField.value);
  }

  @computed
  public get sumFormatted(): string {
    return formatByTicker(this.sumValue, this.ticker);
  }

  @computed
  public get hasSumError() {
    return Boolean(this.sumField.error);
  }

  @computed
  public get sumError() {
    return this.sumField.error;
  }

  @action
  onSumChange = (value: string | number) => {
    const onChange = this.sumField.get('onChange');
    onChange(normalizeAmountByTicker(value.toString(), this.ticker));
  };
  //#endregion sum field

  //#region amount limits
  @computed
  public get minAmount() {
    return 30;
  }

  @computed
  public get maxAmount() {
    return this.resources.bankCardLimits.data.maxAmount;
  }
  //#endregion amount limits
}
