import {computed} from 'mobx';
import {list, object, primitive, serializable} from 'serializr';
import {RewardFullResponse} from './RewardFullResponse';

export class RewardsListResponse {
  @serializable(primitive())
  count!: number;

  @serializable(list(object(RewardFullResponse)))
  items!: RewardFullResponse[];

  @computed get referralItems() {
    return this.items.filter(item => item.referralReward.usd.gt(0));
  }
}
