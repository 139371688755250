import {computed} from 'mobx';
import {RatesResource} from '@youtoken/ui.resource-rates';
import big from 'big.js';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';
import {formatDistanceToNowStrict} from '@youtoken/ui.date-fns';
import {i18n} from '@youtoken/ui.service-i18n';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export class WalletsSummaryFeature extends createFeature({
  getKey: _args => {
    return `feature:walletsSummary(${JSON.stringify(_args)})`;
  },
  getResources: _args => {
    return {
      rates: getResourceDescriptor(RatesResource, _args),
      wallets: getResourceDescriptor(WalletsResource, _args),
      authme: getResourceDescriptor(AuthMeResource, _args),
    };
  },
}) {
  constructor(args: any, resources: any) {
    super(args, resources);
  }

  @computed get enableRewards() {
    return this.resources.authme.data!.enableRewards;
  }

  @computed get shouldShowRewards() {
    return this.enableRewards;
  }

  @computed
  public get mainCurrency() {
    return this.resources.authme.mainCurrency;
  }

  @computed
  public get totalCapital() {
    return formatBigNumber(this._totalCapital, 2);
  }

  @computed
  public get totalEarned() {
    return formatBigNumber(this._totalEarned, 2);
  }

  @computed
  public get totalEarnedNextSettlementPeriod() {
    const date = this.resources.wallets.data.payout.nextSettlementPeriod
      ? this.resources.wallets.data.payout.nextSettlementPeriod
      : new Date();

    const dateFormated = formatDistanceToNowStrict(date);

    return i18n.t('surface.wallets.header.payout_in', {dateFormated});
  }

  // private

  @computed
  private get _totalCapital() {
    return big(this.resources.wallets.totalCapital?.[this.mainCurrency] || 0);
  }

  @computed
  private get _payout() {
    return this.resources.wallets.payout;
  }

  @computed
  private get _payoutToEarn() {
    return this._payout.toEarn;
  }

  @computed
  private get _totalEarned() {
    return big(
      (this._payoutToEarn && this._payoutToEarn[this.mainCurrency]) || 0
    );
  }
}
