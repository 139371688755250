import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {PasswordTextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {type SignInForm} from '../state';

export const PasswordField: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {passwordValue, hasPasswordError, passwordError, onPasswordChange},
  } = useForm<SignInForm>();

  return (
    <Box {...boxProps}>
      <Label mb={8}>{t('forms.sign_in.fields.password.label')}</Label>

      <Box>
        <PasswordTextInput
          size="large"
          value={passwordValue}
          hasError={hasPasswordError}
          onChangeText={onPasswordChange}
          autoComplete="current-password"
          testID="PASSWORD_INPUT"
        />
        <FieldErrorMessage visible={hasPasswordError} placement="left">
          {getTranslatedValidationMessage(passwordError)}
        </FieldErrorMessage>
      </Box>
    </Box>
  );
});
