import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {type MarketStatsItemProps} from '../types';

export const MarketStatsItem: React.FC<MarketStatsItemProps & BoxProps> = ({
  title,
  amountFormatted,
  amountMeasureFormatted,
  description,
  tooltipText,
  webTooltipOffset,
  ...props
}) => {
  return (
    <Box
      flexBasis={155}
      flexGrow={1}
      flexShrink={0}
      m={4}
      pl={16}
      pr={7}
      py={12}
      borderWidth={1}
      borderRadius={16}
      borderColor="$ui-01"
      backgroundColor="$ui-background"
      {...props}
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Text variant="$body-02">{title}</Text>
        <QuestionTooltip
          iconSize={15}
          wrapperWidth={25}
          content={tooltipText}
          bodyHorizontalOffset={webTooltipOffset}
          style={{
            bottom: 20,
            width: 328,
          }}
        />
      </Box>
      <Box flexDirection="row" my={4} minHeight={24} alignItems="flex-end">
        <Text variant="$heading-02">
          {amountFormatted}{' '}
          {Boolean(amountMeasureFormatted) && (
            <Text variant="$body-01">{amountMeasureFormatted}</Text>
          )}
        </Text>
      </Box>
      {typeof description === 'string' ? (
        <Text variant="$body-02" color="$text-02">
          {description}
        </Text>
      ) : (
        description || null
      )}
    </Box>
  );
};
