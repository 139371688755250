import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {RewardsHowItWorksConditions} from './Conditions';
import {RewardsHowItWorksGift} from './Gift';
import {RewardsHowItWorksInfo} from './Info';

export const RewardsHowItWorks: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box p={24}>
      <Text variant="$body-02-high-accent" color="$text-01">
        {t('surface.referral_program.rewards.how_it_works.description')}
      </Text>
      <Separator my={24} />
      <RewardsHowItWorksConditions />
      <Separator my={24} />
      <RewardsHowItWorksGift />
      <Separator my={24} />
      <RewardsHowItWorksInfo />
    </Box>
  );
});
