import * as React from 'react';
import {RefreshControl as NativeRefreshControl} from 'react-native';
import {useTheme} from '@youtoken/ui.primitives';

export type RefreshControlProps = React.ComponentProps<
  typeof NativeRefreshControl
>;

export const RefreshControl: React.FC<RefreshControlProps> = props => {
  const {colors} = useTheme();

  return (
    <NativeRefreshControl
      tintColor={colors['$text-02']}
      colors={[colors['$interactive-01']]}
      progressBackgroundColor={colors['$ui-01']}
      {...props}
    />
  );
};
