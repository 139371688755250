import {action, computed} from 'mobx';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {AllDisclosuresResponse} from './AllDisclosuresResponse';

export * from './AllDisclosuresResponse';

export class AllDisclosuresResource extends createResource({
  getKey: () => `AllDisclosures`,
  getData: () =>
    TRANSPORT.API.get('/v1/product/agreements').then(response =>
      deserialize(AllDisclosuresResponse, response.data)
    ),
  skipRefreshOnVisible: false,
}) {
  @computed
  public get availableGroups() {
    return this.data.availableGroups;
  }

  @computed
  public get countryGroup() {
    return this.data.countryGroup;
  }

  @computed
  public get legalEntities() {
    return this.data.legalEntities;
  }

  @computed
  public get walletAgreements() {
    return this.data.availableGroups.wallet;
  }

  @computed
  public get wirexAgreements() {
    return this.data.availableGroups.wirexIban;
  }

  @computed
  public get youhodlerAgreements() {
    return this.data.availableGroups.youhodlerDocuments;
  }

  @computed
  public get allMissingAgreements() {
    return Object.values(this.data.availableGroups)
      .filter(Boolean)
      .reduce((acc, curr) => {
        return acc.concat(Object.values(curr));
      }, []);
  }

  @computed
  public get needsToSignAgreements() {
    return Boolean(this.allMissingAgreements.length);
  }

  @action
  public sign = async () => {
    return TRANSPORT.API.post('/v1/product/agreements/sign');
  };

  @action
  public getDisclosuresForCountry = async (countryCode: string) => {
    return TRANSPORT.API.get(`/v1/product/agreements`, {
      params: {residence: countryCode},
    }).then(
      response =>
        (this.data = deserialize(AllDisclosuresResponse, response.data))
    );
  };
}
