import * as React from 'react';
import {observer} from 'mobx-react';
import {
  LoyaltyProgressBarLevel,
  LoyaltyLevel,
} from '@youtoken/ui.resource-loyalty';
import {type TouchableBoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarHeader} from './Header';
import {AccountLevelProgressBarContent} from './Content';
import {AccountLevelProgressBarFooter} from './Footer';

export type AccountLevelProgressBarProps = {
  value: number;
  sparks?: number;
  progressBarLevels: LoyaltyProgressBarLevel[];
  achievedLevels: LoyaltyLevel[];
};

export const AccountLevelProgressBar: React.FC<
  AccountLevelProgressBarProps & TouchableBoxProps
> = observer(
  ({value, sparks, progressBarLevels, achievedLevels, ...boxProps}) => {
    return (
      <TouchableBox
        testID="ACCOUNT_LEVEL_FORECAST"
        pt={5}
        px={16}
        borderWidth={1}
        borderColor="$ui-01"
        borderRadius={12}
        {...boxProps}
      >
        <AccountLevelProgressBarHeader levels={achievedLevels} />
        <AccountLevelProgressBarContent
          value={value}
          progressBarLevels={progressBarLevels}
          mt={-3}
        />
        <AccountLevelProgressBarFooter
          sparks={sparks}
          progressBarLevels={progressBarLevels}
        />
      </TouchableBox>
    );
  }
);
