import * as React from 'react';
import {G, Line, Rect} from 'react-native-svg';

export interface CandleProps {
  color: string;
  x: number;
  yHigh: number;
  yBody: number;
  bodyHeight: number;
  bodyWidth: number;
  yLow: number;
}

export const Candle: React.FC<CandleProps> = React.memo(
  ({color, x, yHigh, yBody, bodyHeight, bodyWidth, yLow}) => {
    return (
      <G>
        <Line
          x1={x}
          x2={x}
          y1={yHigh}
          y2={yBody}
          strokeWidth={1}
          stroke={color}
        />
        <Rect
          x={x - bodyWidth / 2}
          y={yBody}
          width={bodyWidth}
          height={bodyHeight}
          fill={color}
          rx={1}
        />
        <Line
          x1={x}
          x2={x}
          y1={yBody + bodyHeight}
          y2={yLow}
          strokeWidth={1}
          stroke={color}
        />
      </G>
    );
  }
);
