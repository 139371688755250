import {invariant} from '@youtoken/ui.utils';

export interface ConfirmationOptions {
  title?: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
}

export type ConfirmationHandler = (
  options: ConfirmationOptions
) => Promise<null>;

export class LocalConfirmationsService {
  private _requestConfirmation?: ConfirmationHandler = undefined;

  public provideHandler = (handler: ConfirmationHandler) => {
    this._requestConfirmation = handler;
  };

  public requestConfirmation = (options: ConfirmationOptions) => {
    invariant(this._requestConfirmation, 'Confirmation handler not provided');
    return this._requestConfirmation(options);
  };
}
