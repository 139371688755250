import * as React from 'react';
import {observer} from 'mobx-react';
import {Profit, Total} from '@youtoken/ui.elements-smart';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsListFeature} from '../../features';

export interface PageInfoProps extends BoxProps {}

export const HODLsInfo: React.FC<PageInfoProps> = observer(
  ({...containerProps}) => {
    const {
      mainCurrency,
      totalInvestedFormatted,
      currentProfitFormatted,
      currentProfitColor,
    } = HODLsListFeature.use({});
    const {t} = useTranslation();

    return (
      <>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          {...containerProps}
        >
          <Total
            text={t('surface.hodls.header.currently_invested')}
            currency={mainCurrency}
            currencyAmount={totalInvestedFormatted}
            minWidth="45%"
          />
          <Profit
            text={t('surface.hodls.header.current_profit')}
            currency={mainCurrency}
            formattedResult={currentProfitFormatted}
            resultDirection={currentProfitColor}
            minWidth="45%"
          />
        </Box>
      </>
    );
  }
);
