import React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {HODLByIdResource, HodlStatus} from '@youtoken/ui.resource-hodl';
import {AdjustTpSlFormClass} from './form';
import {MarginCallBlock, TakeProfitBlock} from './sections';

type AdjustTpSlSurfaceProps = {
  id: string;
  onSuccess: () => void;
  onCancel: () => void;
};

export const AdjustTpSlSurface: React.FC<AdjustTpSlSurfaceProps & BoxProps> =
  observer(({id, onSuccess, onCancel, ...boxProps}) => {
    const {t} = useTranslation();

    const resources = useResources({
      hodl: getResourceDescriptor(HODLByIdResource, {id}),
      ratesResource: getResourceDescriptor(RatesResource, {
        product: 'hodl',
      }),
    });

    const form = makeForm(
      () =>
        new AdjustTpSlFormClass(resources, {
          id,
          onSuccess,
        })
    );

    const {view} = form;

    const handleSubmit = React.useCallback(() => {
      // @ts-ignore
      form.form.submit();
    }, [form]);

    React.useEffect(() => {
      if (
        resources.hodl.data.data.status === HodlStatus.CLOSED ||
        resources.hodl.data.data.status === HodlStatus.CLOSING
      ) {
        onCancel();
      }
    }, [resources.hodl.data.data.status]);

    return (
      <Box {...boxProps}>
        <Form form={form}>
          <TakeProfitBlock />

          <MarginCallBlock mt={20} />

          <Box flexDirection="row" my={24}>
            <Box flexBasis={0} flexGrow={1}>
              <Button
                size="large"
                onPress={handleSubmit}
                disabled={view.calculating}
              >
                {t('surface.hodls.adjust_tp.action_adjust')}
              </Button>
            </Box>
          </Box>
        </Form>
      </Box>
    );
  });
