import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {
  type BoxProps,
  Box,
  Text,
  useIsSmallMobile,
} from '@youtoken/ui.primitives';
import {SavingsProgressBar} from '@youtoken/ui.progress-bar';
import {TickersStack} from '@youtoken/ui.elements';
import {warning} from '@youtoken/ui.utils';

export interface SavingsProgressProps {
  ticker: string;
  amount: number;
  limit: number;
  tickers: string[];
}

export const SavingsProgress: React.FC<SavingsProgressProps & BoxProps> =
  observer(({ticker, amount, limit, tickers, ...boxProps}) => {
    const {t} = useTranslation();

    const isSmallDevice = useIsSmallMobile();

    const tickerSymbol = getCoinSymbol(ticker);

    const value = amount / limit;

    const amountFormatted = React.useMemo(() => {
      return tickerSymbol + priceFormatterInThousands(amount);
    }, [tickerSymbol, amount]);

    warning(tickerSymbol, 'tickerSymbol is not defined', {}, {tickerSymbol});

    const limitZeroFormatted = tickerSymbol! + 0;

    const limitFormatted = React.useMemo(() => {
      return tickerSymbol + priceFormatterInThousands(limit);
    }, [tickerSymbol, limit]);

    return (
      <Box
        testID="SAVINGS_PROGRESS"
        justifyContent="space-between"
        alignItems="flex-start"
        {...boxProps}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flex={1} mr={4}>
            <Text
              testID="SAVINGS_PROGRESS_YIELD_ACCOUNT"
              variant="$body-01-high-accent"
              color="$text-01"
            >
              {t('surface.savings.yield_account')}
            </Text>
          </Box>
          {!isSmallDevice && (
            <TickersStack
              tickers={tickers}
              tickerBorderColor="$interactive-02"
              tickerBackgroundColor="$interactive-02"
              testID="SAVINGS_TICKERS_STACK"
            />
          )}
        </Box>
        <Box width="100%" mt={4}>
          <Text
            testID="SAVINGS_PROGRESS_YIELD_LIMIT"
            variant="$body-03"
            color="$text-02"
          >
            {t('surface.savings.yield_limit')}
          </Text>
          <Box mt={4}>
            <SavingsProgressBar
              testID="SAVINGS_PROGRESS_BAR"
              amountTestID="SAVINGS_PROGRESS_BAR_VALUE"
              value={value}
              valueText={amountFormatted}
              color={value > 1 ? 'red' : 'blue'}
              backgroundColor="$ui-background"
            />
          </Box>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            mt={2}
          >
            <Text
              testID="SAVINGS_PROGRESS_LIMIT_ZERO"
              variant="$body-03"
              color="$text-02"
            >
              {limitZeroFormatted}
            </Text>
            <Text
              testID="SAVINGS_PROGRESS_LIMIT_CURRENT"
              variant="$body-03"
              color="$text-02"
            >
              {limitFormatted}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  });
