import {AppEnv} from './AppEnv';

export const GoogleTagManagerId: Partial<Record<AppEnv, string>> = {
  production: 'GTM-5SFJGDTJ',
};
const allOthers = 'GTM-P9SXGDP';

export const getGoogleTagManagerId = (env: AppEnv): string => {
  return GoogleTagManagerId[env] ?? allOthers;
};
