import * as React from 'react';
import {TSpan} from 'react-native-svg';
import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';
import {themes} from '@youtoken/ui.primitives';
import {i18n} from '@youtoken/ui.service-i18n';

const font = themes.light.fonts.mono.regular;

export class MaxProfitLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (!this.chart.maxProfit || !this.chart.takeProfit) {
      return undefined;
    }

    const value = this.chart.formatNumberToPrecision(this.chart.maxProfit);
    const ticker = this.chart.maxProfitTicker?.toUpperCase();

    const maxProfitTranslated = i18n.t('charts.max_profit');
    const text = [maxProfitTranslated, value, ticker].join(' ');
    const labelLayout = this.chart.layout.getLabelLayout(
      this.chart.takeProfit,
      text,
      'left',
      this.chart.direction
    )!;

    if (
      this.chart.initialPriceLabel.layout?.isInDomain &&
      Math.abs(labelLayout.y - this.chart.initialPriceLabel.layout.y) <=
        this.chart.layout.labelHeight
    ) {
      return undefined;
    }

    return {
      ...labelLayout!,
      text: (
        <TSpan>
          {maxProfitTranslated} <TSpan fontFamily={font}>{value}</TSpan>
          {ticker && <TSpan> {ticker}</TSpan>}
        </TSpan>
      ),
    };
  }
}
