export const banks = {
  NexPay: [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'NEUALT21',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'NexPay, UAB',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: 'Olimpieciu g. 1-36, LT-09235 Vilnius, Lithuania',
    },
  ],
  Frick: [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'BFRILI22',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'Bank Frick & Co. AG',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: 'Landstrasse 14, 9496 Balzers, Liechtenstein',
    },
  ],
  Intergiro: [
    {
      priority: 300,
      type: 'bankCode',
      title: 'Bank SWIFT Code',
      value: 'FTCSSESS',
    },
    {
      priority: 500,
      type: 'bankName',
      title: 'Name of the bank',
      value: 'Intergiro Intl AB',
    },
    {
      priority: 600,
      type: 'bankAddress',
      title: 'Address of the bank',
      value: 'Intergiro Intl AB (publ) Box 3093 10361 - Stockholm Sweden',
    },
  ],
};
