import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type LevelStatus} from '@youtoken/ui.resource-loyalty';
import {Box, TColorTokens, Text, type BoxProps} from '@youtoken/ui.primitives';
import {SparkPriceBasic} from '../../SparkPriceBasic';

type GetWelcomePackProps = {
  variant: LevelStatus;
  welcomePackSize: number;
} & BoxProps;

type ColorMap = Record<'backgound' | 'icon' | 'text', keyof TColorTokens>;
const COLORS: Record<LevelStatus, ColorMap> = {
  completed: {
    backgound: '$ui-02',
    icon: '$text-02',
    text: '$text-02',
  },
  locked: {
    backgound: '$text-04',
    icon: '$attention-01',
    text: '$text-01',
  },
  // not supposed to be used
  active: {
    backgound: '$attention-03',
    icon: '$attention-01',
    text: '$text-01',
  },
};

export const GetWelcomePack: React.FC<GetWelcomePackProps> = ({
  variant,
  welcomePackSize,
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <Box
      borderRadius={16}
      backgroundColor="$ui-background"
      flexDirection="row"
      alignItems="center"
      py={8}
      px={12}
      {...boxProps}
    >
      {variant !== 'completed' && (
        <Text variant="$body-02" color="$text-01">
          {t('surface.miner.loyalty.welcome-pack.get')}
        </Text>
      )}
      <SparkPriceBasic amount={welcomePackSize} color={COLORS[variant].icon} />
      <Text variant="$body-02" color={COLORS[variant].text} ml={4}>
        {t('surface.miner.loyalty.welcome-pack')}
      </Text>
    </Box>
  );
};
