import * as React from 'react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HappyBirthdayStoryParams} from '@youtoken/ui.resource-insta-stories';

export const HappyBirthday: React.FC<HappyBirthdayStoryParams> = ({
  user: {fullName},
}) => {
  const {t} = useTranslation();

  return (
    <Box flex={1} justifyContent="center" alignItems="center" padding={40}>
      <Heading
        variant="$heading-01-responsive"
        color="$text-04"
        textAlign="center"
        mb={24}
      >
        {t('story.happy_birthday.title')}
        {Boolean(fullName) ? `, ${fullName}` : ''}!
      </Heading>
      <Text
        color="$text-04"
        textAlign="center"
        style={{fontSize: 24, lineHeight: 32}}
      >
        {t('story.happy_birthday.text')}
      </Text>
    </Box>
  );
};
