import * as React from 'react';
import {observer} from 'mobx-react';
import {TFAFormField, useForm} from '@youtoken/ui.form-elements';
import {UmaInvoiceConfirmationForm} from '../../state';

export const TFAComponent: React.FC = observer(() => {
  const {
    form: {
      twoFactorAuthOperationCodeError,
      createTwoFactorAuthOperation,
      twoFactorAuthOperationCodeOnChange,
    },
  } = useForm<UmaInvoiceConfirmationForm>();

  return (
    <TFAFormField
      error={twoFactorAuthOperationCodeError}
      getOperationData={createTwoFactorAuthOperation}
      onChangeCode={twoFactorAuthOperationCodeOnChange}
    />
  );
});
