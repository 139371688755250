import * as React from 'react';
import {Header} from './Header';
import {BuyCryptoButton} from './BuyCryptoButton';
import {Help} from './Header/elements';
import {SignOutButton} from './SignOutButton';

export const AppShellHeader: React.FC = () => {
  return (
    <Header
      renderRight={() => {
        return (
          <>
            <BuyCryptoButton />
            <Help />
            <SignOutButton />
          </>
        );
      }}
    />
  );
};
