import * as React from 'react';
import {observer} from 'mobx-react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelDetailedData} from './Data';
import {AccountLevelProgress, AccountLevelProgressNoMiner} from '../smarts';
import {TradeToUpgradeModalTrigger} from '../TradeToUpgradeModal';

const WIDGET_OFFSET = 24;

const borderWidth = {default: 0, tablet: 1};
const borderRadius = {default: 0, tablet: 16};

export const AccountLevelDetailed: React.FC<BoxProps> = observer(
  ({bg, ...boxProps}) => {
    const {
      authMe: {
        products: {miner},
      },
      loyalty: {currentLevelData},
    } = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
    });

    const {color} = currentLevelData;

    return (
      <Box testID="ACCOUNT_LEVEL_DETAILED" {...boxProps}>
        <AccountLevelDetailedData
          pt={40}
          px={{default: 16, phone: 24}}
          pb={32 + WIDGET_OFFSET}
          bg={bg ?? color}
          borderColor="$ui-01"
          borderTopWidth={borderWidth}
          borderLeftWidth={borderWidth}
          borderRightWidth={borderWidth}
          borderTopLeftRadius={borderRadius}
          borderTopRightRadius={borderRadius}
        />
        <Box
          bg="$ui-background"
          p={{default: 16, phone: 24}}
          pb={{default: 0, tablet: 24}}
          borderColor="$ui-01"
          borderBottomWidth={borderWidth}
          borderLeftWidth={borderWidth}
          borderRightWidth={borderWidth}
          borderBottomLeftRadius={borderRadius}
          borderBottomRightRadius={borderRadius}
        >
          {miner.available ? (
            <AccountLevelProgress
              TradeToUpgrade={TradeToUpgradeModalTrigger}
              // NOTE: marginTop = wrapPadding - widgetOffset
              mt={{
                default: -16 - WIDGET_OFFSET,
                phone: -24 - WIDGET_OFFSET,
              }}
            />
          ) : (
            <AccountLevelProgressNoMiner
              TradeToUpgrade={TradeToUpgradeModalTrigger}
              mt={{
                default: -16 - WIDGET_OFFSET,
                phone: -24 - WIDGET_OFFSET,
              }}
            />
          )}
        </Box>
      </Box>
    );
  }
);
