import {Platform} from 'react-native';
import {action, computed, observable, reaction} from 'mobx';
import {computedFn, type IDisposer} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import Big from 'big.js';
import {invariant, openBrowserAsync} from '@youtoken/ui.utils';
import {calculateIncentivesDebounced} from '@youtoken/ui.incentives-utils';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import * as yupPackage from '@youtoken/ui.yup';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {messages} from '@youtoken/ui.validation-messages';
import {TRANSPORT} from '@youtoken/ui.transport';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {getWebAppUrl} from '@youtoken/ui.env-utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {Providers} from '@youtoken/ui.surfaces-wallets';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import type {CardDepositFormArgs, CardDepositFormResources} from './types';

type UnlimintWebData = {
  returnUrl: string;
};

type CheckoutWebData = {
  declineUrl: string;
  cancelUrl: string;
  successUrl: string;
};

type CommonNativeData = {
  returnUrl?: string;
  // these override returnUrl for specific statuses
  declineUrl: string;
  cancelUrl: string;
  successUrl: string;
  inprocessUrl?: string;
};

type ProvidersData = CheckoutWebData | UnlimintWebData | CommonNativeData;

export class Form {
  @observable
  public args: CardDepositFormArgs;

  @observable
  public resources: CardDepositFormResources;

  @observable
  public instance: MobxReactForm;

  @observable
  disposers: IDisposer[] = [];

  @observable
  currentRequest = null;

  @observable
  isLoading: boolean = false;

  @observable
  public isRiskJsScriptLoaded: boolean = false;

  @observable
  incentivesBonus = toBig(0);

  public constructor(
    args: CardDepositFormArgs,
    resources: CardDepositFormResources
  ) {
    this.args = args;
    this.resources = resources;

    const fields = {
      sum: {
        name: 'sum',
        label: 'Sum',
        value: '100',
      },
      deviceSessionId: {value: ''},
      cardId: {value: resources.cards.cardsList?.[0]?.id || ''},
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        // NOTE: It's preventing multiple submissions
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        const {deviceSessionId, cardId} = form.values();

        DATA_LAYER.trackStrict('deposit-fiat-submit', {
          type: 'fiat',
          category: 'deposit',
          provider: `${args.provider}BankCard`,
          method: args.method,
          ticker: this.ticker,
          amount: Number(this.sumFormatted),
          amountUSD: Number(this.sumInUSDFormatted),
          cardId,
        });

        // NOTE: The Unlimint needs an absolute url but the Checkout needs a relative one
        const baseUrl =
          args.provider === Providers.UNLIMINT
            ? getWebAppUrl(ENVIRONMENT.APP_ENV)
            : '';
        const webRedirectUrl = `${baseUrl}/wallets/${this.ticker}`;

        const returnUrls: ProvidersData = Platform.select({
          // NOTE: different return url for unlimint provider on web and native platforms
          web:
            args.provider === Providers.UNLIMINT
              ? {
                  returnUrl: webRedirectUrl,
                }
              : {
                  cancelUrl: webRedirectUrl,
                  declineUrl: webRedirectUrl,
                  successUrl: webRedirectUrl,
                },
          native: {
            cancelUrl: `${baseUrl}/deposit/cancel`,
            declineUrl: `${baseUrl}/deposit/fail`,
            inprocessUrl: `${baseUrl}/deposit/processing`,
            successUrl: `${baseUrl}/deposit/success`,
          } as ProvidersData,
        })!;

        // NOTE: v2 is for the Iframe version which allows to pay by card from the app and v1 is for the version with redirect to the checkout payment page.
        const requestUrl =
          args.method === 'checkoutIframe' ? '/v2/deposit' : '/v1/deposit';

        return TRANSPORT.API.post(requestUrl, {
          // NOTE: userDepositSource is a flag for BE to understand that the request was made from the Deposit form on main Portfolio page,
          // not from the Buy Crypto wizard or other On-ramp widgets or something else.
          // It's related to the experiment with Checkout Frame / Checkout Payment page deposit methods.
          userDepositSource: 'app',
          provider: `${args.provider}BankCard`,
          amount: this.sumValue,
          ticker: this.ticker,
          conversionTicker: this.conversionTicker,
          providerData: {
            redirectUrls: returnUrls,
          },
          ...(deviceSessionId ? {deviceSessionId} : {}),
          ...(args.method === 'checkoutIframe' ? {cardId} : {}),
        })
          .then(async response => {
            const {
              data: {
                providerResponse: {redirect_url: providerUrl},
              },
            } = response;

            if (providerUrl) {
              return openBrowserAsync(providerUrl);
            } else {
              // NOTE: small delay is needed to be more sure, that transaction indeed appeared in the wallet item history
              await new Promise<void>(resolve =>
                setTimeout(() => {
                  resolve();
                  this.args.onSubmit?.();
                  SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
                    ticker: this.args.ticker,
                  });
                }, 2000)
              );
            }
          })
          .catch(error => {
            handleFormSubmitError(this.instance, error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            const cardIdSchema =
              this.args.method === 'checkoutIframe'
                ? yup.string().required(messages.REQUIRED)
                : yup.string();

            return yup.object().shape({
              sum: yup
                .big()
                .gte(this.minAmount.toNumber(), () =>
                  messages.MIN_AMOUNT({
                    value: this.minAmount.toFixed(this.tickerPrecision),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .lte(this.maxAmount.toNumber(), () =>
                  messages.MAX_AMOUNT({
                    value: this.maxAmount.toFixed(this.tickerPrecision),
                    ticker: this.ticker.toUpperCase(),
                  })
                )
                .required(messages.REQUIRED),
              deviceSessionId: yup.mixed().notRequired(),
              cardId: cardIdSchema,
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: true,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.disposers = [
      // NOTE: update bonuses value after sumValue changed
      reaction(
        () => this.sumValue,
        amount => {
          calculateIncentivesDebounced(
            amount.toFixed(),
            this.ticker,
            this.setIncentivesBonus
          );
        },
        {
          fireImmediately: true,
        }
      ),
    ];

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  //#region sum field
  @computed
  public get sumField() {
    return this.instance.$('sum');
  }

  @computed
  get sumValue(): Big {
    return toBig(this.sumField.value);
  }

  @computed
  get sumWithFee(): Big {
    return this.sumValue.plus(this.feeValue);
  }

  @computed
  public get sumFormatted(): string {
    return formatByTicker(this.sumValue, this.ticker);
  }

  @computed
  public get hasSumError() {
    return Boolean(this.sumField.error);
  }

  @computed
  public get sumError() {
    return this.sumField.error;
  }

  @computed
  get sumInUSD(): Big {
    const {getRate} = this.resources.rates;
    const rate = getRate(this.ticker, 'usd');
    return this.sumValue.times(toBig(rate));
  }

  @computed
  public get sumInUSDFormatted(): string {
    return formatByTicker(this.sumInUSD, 'usd');
  }

  @computed
  get sumInConversionTicker(): Big | null {
    if (!this.args.conversionTicker) {
      return null;
    }
    const {getRate} = this.resources.rates;
    const rate = getRate(this.ticker, this.args.conversionTicker);
    return this.sumValue.times(toBig(rate));
  }

  @computed
  get sumInConversionTickerWithFee(): Big | null {
    if (!this.conversionTicker || !this.sumInConversionTicker) {
      return null;
    }

    return this.sumInConversionTicker.plus(this.feeValueInConversionTicker);
  }

  @computed
  get sumInConversionTickerWithFeeFormatted(): string | null {
    if (
      !this.args.conversionTicker ||
      !this.sumInConversionTicker ||
      !this.sumInConversionTickerWithFee
    ) {
      return null;
    }
    return formatByTicker(
      this.sumInConversionTickerWithFee,
      this.args.conversionTicker
    );
  }

  @action
  onSumChange = (value: string) => {
    const onChange = this.sumField.get('onChange');
    onChange(normalizeAmountByTicker(value, this.ticker));
  };
  //#endregion sum field

  //#region ticker
  @computed
  get ticker() {
    return this.args.ticker;
  }

  @computed
  get conversionTicker() {
    return this.args.conversionTicker;
  }

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.ticker);
  }

  @computed
  get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.conversionTicker);
  }

  //#endregion ticker

  //#region fees
  @computed
  public get tickerFees() {
    if (this.args.provider === Providers.UNLIMINT) {
      return this.resources.feeAll.getFeeForProviderDeposit(
        `${this.args.provider}BankCard`,
        this.ticker,
        this.conversionTicker || this.ticker
      );
    }

    if (this.args.provider === Providers.CHECKOUT) {
      return this.resources.feeDeposit.data;
    }

    return null;
  }

  @computed
  public get feePercent() {
    return this.tickerFees?.percent;
  }

  @computed
  public get isMinCommissionEstablished() {
    return !this.tickerFees?.min.eq(0);
  }

  @computed
  public get isMaxCommissionEstablished() {
    return !this.tickerFees?.max.eq(0);
  }

  private getCalculatedFee = computedFn((fee: Big) => {
    if (
      this.isMinCommissionEstablished &&
      this.tickerFees &&
      fee.lte(this.tickerFees.min)
    ) {
      return this.tickerFees.min;
    }

    if (
      this.isMaxCommissionEstablished &&
      this.tickerFees &&
      fee.gte(this.tickerFees.max)
    ) {
      return this.tickerFees.max;
    }

    return fee;
  });

  @computed
  public get feeValue() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );

    const calculatedFee = toBig(this.sumValue.mul(this.tickerFees.percent));

    return this.getCalculatedFee(calculatedFee);
  }

  @computed
  public get feeValueInConversionTicker() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );

    const calculatedFee = toBig(
      this.sumInConversionTicker?.mul(this.tickerFees.percent)
    );

    return this.getCalculatedFee(calculatedFee);
  }

  //#endregion fees

  //#region amount limits
  @computed
  public get minAmount() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );
    return this.tickerFees.minAmount;
  }

  @computed
  public get maxAmount() {
    invariant(
      this.tickerFees,
      'tickerFees should be defined',
      {},
      {tickerFees: this.tickerFees}
    );
    return this.tickerFees.maxAmount;
  }

  //#endregion amount limits

  //#region cards

  @computed
  public get cardsList() {
    // NOTE: may be empty in case of TODO: in case of what??
    return this.resources.cards.cardsList ?? [];
  }

  @computed
  public get selectedCardId() {
    return this.instance.$('cardId').value;
  }

  @computed
  public get isSubmitDisabled() {
    if (this.args.method === 'checkoutIframe') {
      return !this.isRiskJsScriptLoaded || !this.selectedCardId;
    }

    return !this.isRiskJsScriptLoaded;
  }

  @computed
  public get isSubmitLoading() {
    return !this.isRiskJsScriptLoaded || this.isLoading;
  }

  @action
  public setCardId = (value: string) => {
    this.instance.$('cardId').set('value', value.toString());
  };

  //#endregion cards

  @action
  public setDeviceSessionId = (value: string) => {
    this.instance.$('deviceSessionId').set('value', value);
    this.finishRiskJsScriptLoading();
  };

  @action finishRiskJsScriptLoading = () => {
    this.isRiskJsScriptLoaded = true;
  };

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };

  @action setIncentivesBonus = (value: Big) => {
    this.incentivesBonus = value;
  };
}
