import {action, computed, observable} from 'mobx';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import * as yupPackage from '@youtoken/ui.yup';
import {
  handleGeneralErrorTranslated,
  messages,
} from '@youtoken/ui.validation-messages';
import {
  countriesList,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {type CardsFormArgs} from './types';
import {
  type FrameCardTokenizationFailEventProps,
  type FrameCardTokenizationSuccessEventProps,
} from '../types';

enum CardTokenizationErrorCodes {
  monthInvalid = 'card_expiry_month_invalid',
  yearInvalid = 'card_expiry_year_invalid',
  cvvInvalid = 'cvv_invalid',
  countryInvalid = 'country_invalid',
  cityInvalid = 'city_invalid',
  countryAddressInvalid = 'country_address_invalid',
  zipInvalid = 'zip_invalid',
}

const COUNTRY_ITEMS = (() => {
  const checkoutCountryItems = getCountriesListLocalized(countriesList);

  return checkoutCountryItems.map(country => ({
    value: country.codeISO2,
    code: country.code,
    label: country.name,
    key: country.code,
  }));
})();

const nameRegExp = new RegExp(/^[a-zA-Z0-9\s]*$/);
const addressLineRegExp = new RegExp(/^[a-zA-Z0-9\s\-.,]*$/);
const zipCodeRegExp = new RegExp(/^[0-9a-zA-Z ]{0,10}$/);

export class Form {
  @observable
  public args: CardsFormArgs;

  @observable
  public instance: MobxReactForm;

  @observable
  public isLoading = false;

  @observable
  public isValidCardNumber?: boolean = false;

  @observable
  public isCardDataValid = false;

  @observable
  public isValidExpiryDate?: boolean = false;

  @observable
  public isValidCvv?: boolean = false;

  @computed
  public get countryItems() {
    return COUNTRY_ITEMS;
  }

  @computed
  public get userAddress() {
    return AuthMeResource.getInstaceSafely({})?.data.address;
  }

  @computed
  public get defaultCountryCode() {
    if (this.userAddress?.country || this.userAddress?.countryOfResidence) {
      const countryItem = COUNTRY_ITEMS.find(
        item =>
          item.code === this.userAddress.country ||
          item.label === this.userAddress.countryOfResidence
      );

      if (countryItem) {
        return countryItem.value;
      }
    }

    return '';
  }

  @computed
  public get defaultCity() {
    const city = this.userAddress?.city || '';

    return city.match(addressLineRegExp) ? city : '';
  }

  @computed
  public get defaultZipCode() {
    const zipCode =
      this.userAddress?.postalCode || this.userAddress?.postCode || '';

    return zipCode.match(zipCodeRegExp) ? zipCode : '';
  }

  @computed
  public get defaultAddressLine() {
    const addressLine =
      this.userAddress?.streetAddress || this.userAddress?.address || '';

    return addressLine.match(addressLineRegExp) ? addressLine : '';
  }

  public constructor(args: CardsFormArgs) {
    this.args = args;

    const fields = {
      // NOTE: Main card data - most of the fields handled by checkout Frames library directly, so we have only cardToken from it in return
      cardToken: {value: ''},
      cardHolderName: {value: ''},
      // NOTE: Billing details fields
      countryCode: {value: this.defaultCountryCode},
      city: {
        value: this.defaultCity,
      },
      state: {value: ''},
      zipCode: {
        value: this.defaultZipCode,
      },
      addressLine1: {
        value: this.defaultAddressLine,
      },
      addressLine2: {value: ''},
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        const {cardToken} = form.values();

        return TRANSPORT.API.post(`/v2/cards`, {
          provider: 'checkoutBankCard',
          data: {
            token: cardToken,
            storeCard: true,
          },
        })
          .then(() => {
            // NOTE: Attempt to refetch cards resource if it was called before to update the list of cards
            CardsResource.getInstaceSafely({})
              ?.refetch()
              .finally(() => this.args.onSuccess?.());
          })
          .catch(error => {
            this.instance.$('cardToken').set('value', '');
            handleFormSubmitError(this.instance, error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              cardToken: yup.string().required(messages.REQUIRED),
              cardHolderName: yup
                .string()
                .required(messages.REQUIRED)
                .matches(nameRegExp, messages.IS_NOT_LATIN_SYMBOLS),
              countryCode: yup.string().required(messages.REQUIRED),
              city: yup
                .string()
                .required(messages.REQUIRED)
                .matches(
                  addressLineRegExp,
                  messages.ACCOUNT_NUMBER_WRONG_FORMAT
                ),
              state: yup
                .string()
                .matches(
                  addressLineRegExp,
                  messages.ACCOUNT_NUMBER_WRONG_FORMAT
                ),
              zipCode: yup
                .string()
                .required(messages.REQUIRED)
                .matches(zipCodeRegExp, i18n.t('validation.CODE_INVALID')),
              addressLine1: yup
                .string()
                .required(messages.REQUIRED)
                .matches(
                  addressLineRegExp,
                  messages.ACCOUNT_NUMBER_WRONG_FORMAT
                ),
              addressLine2: yup
                .string()
                .matches(
                  addressLineRegExp,
                  messages.ACCOUNT_NUMBER_WRONG_FORMAT
                ),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: true,
      validateOnChange: true,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @action
  public onChangeCardHolderName = (value: string) => {
    this.instance.$('cardHolderName').set('value', value);
  };

  @action
  public onChangeCountryCode = (value: string) => {
    this.instance.$('countryCode').set('value', value);
  };

  @action
  public onChangeCity = (value: string) => {
    this.instance.$('city').set('value', value);
  };

  @action
  public onChangeState = (value: string) => {
    this.instance.$('state').set('value', value);
  };

  @action
  public onChangeZipCode = (value: string) => {
    this.instance.$('zipCode').set('value', value);
  };

  @action
  public onChangeAddressLine1 = (value: string) => {
    this.instance.$('addressLine1').set('value', value);
  };

  @action
  public onChangeAddressLine2 = (value: string) => {
    this.instance.$('addressLine2').set('value', value);
  };

  @action
  public onCardTokenizationFail = (
    eventData: FrameCardTokenizationFailEventProps
  ) => {
    if (!eventData.error_codes.length) {
      handleGeneralErrorTranslated(eventData);
    }

    eventData.error_codes.forEach(errorCode => {
      switch (errorCode) {
        case CardTokenizationErrorCodes.monthInvalid:
        case CardTokenizationErrorCodes.yearInvalid:
          this.isValidExpiryDate = false;
          break;
        case CardTokenizationErrorCodes.cvvInvalid:
          this.isValidCvv = false;
          break;
        case CardTokenizationErrorCodes.countryAddressInvalid:
          this.instance
            .$('countryCode')
            .invalidate(
              i18n.t('surface.wallets.bank_card.country_address_error')
            );
          break;
        case CardTokenizationErrorCodes.countryInvalid:
          this.instance
            .$('countryCode')
            .invalidate(i18n.t('surface.wallets.bank_card.country_error'));
          break;
        case CardTokenizationErrorCodes.cityInvalid:
          this.instance
            .$('city')
            .invalidate(i18n.t('surface.wallets.bank_card.city_error'));
          break;
        case CardTokenizationErrorCodes.zipInvalid:
          this.instance
            .$('zipCode')
            .invalidate(i18n.t('surface.wallets.bank_card.zip_code_error'));
          break;
      }
    });
  };

  @action
  public onCardTokenizationSuccess = (
    e: FrameCardTokenizationSuccessEventProps
  ) => {
    // NOTE: It's preventing multiple submissions
    if (this.instance.$('cardToken').value) {
      return;
    }

    DATA_LAYER.trackStrict('card-save-submit', {provider: 'checkout'});

    this.isLoading = true;

    this.instance.$('cardToken').set('value', e.token);

    // NOTE: preventDefault is required avoid conflict with submitting Frames form inside MobxReactForm
    this.instance.onSubmit({
      preventDefault: () => {},
    });
  };
}
