import {type ValidationMessageLocalized} from '@youtoken/ui.validation-messages';

export const isInsufficientFunds = (
  error: string | ValidationMessageLocalized
) => {
  if (typeof error === 'string') {
    if (error === 'Failed to open a card due to insufficient funds') {
      return true;
    }

    return false;
  }

  if (error.i18n?.label === 'FUNDS_INSUFFICIENT') {
    return true;
  }

  if (
    error.i18n?.fallback === 'Failed to open a card due to insufficient funds'
  ) {
    return true;
  }

  return false;
};
