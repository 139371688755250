import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {HighRiskBanner} from '@youtoken/ui.high-risk-banner';
import {AppShellProps} from './types';
import {AppShellHeader} from './AppShellHeader';

export const AppShellLedger: React.FC<AppShellProps> = React.memo(
  ({
    children,
    country,
    renderHeader = () => {
      return <AppShellHeader />;
    },
  }) => {
    return (
      <Box minWidth={320}>
        <HighRiskBanner country={country} />
        <Box>
          {renderHeader?.()}
          <Box alignSelf="center" width="100%" maxWidth={1000} py={24}>
            {children}
          </Box>
        </Box>
      </Box>
    );
  }
);
