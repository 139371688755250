import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalFormState} from '../types';

export interface CryptoWithdrawalExtraIdProps {
  disabled?: boolean;
}

export const CryptoWithdrawalExtraId: React.FC<CryptoWithdrawalExtraIdProps> =
  observer(({disabled}) => {
    const {
      form: {
        extraIdVisible,
        extraIdLabel,
        extraIdPlaceholder,
        extraId,
        extraIdHasError,
        extraIdError,
        extraIdOnChange,
      },
    } = useForm<CryptoWithdrawalFormState>();

    if (!extraIdVisible) {
      return null;
    }

    return (
      <Box mt={16}>
        <Label mb={8}>{extraIdLabel}</Label>
        <TextInput
          size="large"
          placeholder={extraIdPlaceholder}
          value={extraId}
          onChangeText={extraIdOnChange}
          disabled={disabled}
          autoCorrect={false}
          spellCheck={false}
          testID="EXTRA_ID_INPUT"
        />
        <FieldErrorMessage visible={extraIdHasError}>
          {extraIdError}
        </FieldErrorMessage>
      </Box>
    );
  });
