import {object, optional, primitive, serializable} from 'serializr';
import {
  type IIBANAccount,
  type TIBANAccountStatus,
} from './types/IIBANAccountsResponse';
import {computed} from 'mobx';

export class BankAddress {
  // Line 1 of the bank account's address.
  @serializable(optional(primitive()))
  street1?: string;

  // Line 2 of the bank account's address.
  @serializable(optional(primitive()))
  street2?: string;

  @serializable(optional(primitive()))
  city?: string;

  @serializable(optional(primitive()))
  country_code?: string;

  @serializable(optional(primitive()))
  post_code?: string;
}

export class IBANAccountsResponse implements IIBANAccount {
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  type!: string;

  @serializable(primitive())
  status!: TIBANAccountStatus;

  @serializable(optional(primitive()))
  iban?: string;

  @serializable(optional(primitive()))
  bic?: string;

  @serializable(optional(primitive()))
  bankName?: string;

  // unused as of 10.10.23, kept for future use
  @serializable(optional(object(BankAddress)))
  bankAddress?: BankAddress;

  @computed get bankAddressString(): string | null {
    if (!this.bankAddress) {
      return null;
    }

    const result = [];

    if (this.bankAddress.street1) {
      result.push(this.bankAddress.street1);
    }
    if (this.bankAddress.street2) {
      result.push(this.bankAddress.street2);
    }
    if (this.bankAddress.post_code) {
      result.push(this.bankAddress.post_code);
    }
    if (this.bankAddress.city) {
      result.push(this.bankAddress.city);
    }
    if (this.bankAddress.country_code) {
      result.push(this.bankAddress.country_code);
    }
    return result.join(', ');
  }

  @serializable(optional(primitive()))
  fullName?: string;

  @serializable(optional(primitive()))
  accountNumber?: string;

  @serializable(optional(primitive()))
  sortCode?: string;
}
