import * as React from 'react';
import {observer} from 'mobx-react';
import {BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Wallet} from '@youtoken/ui.resource-wallets';

export interface WalletsRateProps extends BoxProps {
  item: Wallet;
}

export const WalletsRate: React.FC<WalletsRateProps> = observer(({item}) => {
  const {
    tickerFormatted,
    equivalentTickerFormatted,
    rateFormatted,
    rateDiffPercentFormatted,
    isRateDiffPositive,
  } = item;

  return (
    <Box alignItems="flex-end">
      <Text testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE`}>
        {rateFormatted}{' '}
        <Text color="$text-02">{equivalentTickerFormatted}</Text>
      </Text>
      <Text
        variant="$body-02"
        color={isRateDiffPositive ? '$success-01' : '$danger-01'}
        testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE_DIFF`}
      >
        {rateDiffPercentFormatted}%
      </Text>
    </Box>
  );
});
