import {observable} from 'mobx';
import {
  BuyCryptoFormAuthorizedOnRampStateArgs,
  BuyCryptoFormAuthorizedOnRampStateResources,
} from './types';
import {ViewBase} from '../ViewBase';
import {Form} from './Form';

export class BuyCryptoFormAuthorizedOnRampState {
  @observable
  public form: Form;

  @observable
  public view: ViewBase;

  constructor(
    args: BuyCryptoFormAuthorizedOnRampStateArgs,
    resources: BuyCryptoFormAuthorizedOnRampStateResources
  ) {
    this.form = new Form(args, resources);
    this.view = new ViewBase(this.form);
  }
}
