import {nanoid} from 'nanoid';
// import {nanoid as nanoIdAsync} from 'nanoid/async';

export const generateDeviceID = (): string => {
  return nanoid();
};

// export const generateDeviceIDAsync = (): Promise<string> => {
//   return nanoIdAsync();
// };
