import * as React from 'react';
import {
  SvgProps,
  Svg,
  Rect,
  G,
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';

export const BankCardVirtualImage = React.memo((props: SvgProps) => {
  const {colors} = useTheme();

  return (
    <Svg
      viewBox="0 0 232 138"
      fill="none"
      preserveAspectRatio="xMidYMid slice"
      {...props}
    >
      <Rect width="232" height="138" fill={colors['$interactive-03']} />
      <G
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        clipPath="url(#bank-card-virtual_svg__bank-card-virtual_svg__clip0_8636_53743)"
      >
        <Path
          d="M166.118 31.882l-32.942-19.764v79.059l32.942 19.764V31.882z"
          fill="#40C4FF"
        />
        <Path
          d="M166.118 31.882l-32.942-19.765 65.883-39.529L232-7.647l-65.882 39.53z"
          fill="#80D8FF"
        />
        <Path
          d="M232 190v-39.529l-65.882-39.53v39.53L232 190z"
          fill="#40C4FF"
        />
        <Path
          d="M188.056 18.706L232 45.059V-7.647l-43.944 26.353z"
          fill="url(#bank-card-virtual_svg__bank-card-virtual_svg__paint0_linear_8636_53743)"
        />
        <Path
          d="M166.118 110.941l43.943 26.353V84.588l-43.943 26.353z"
          fill="url(#bank-card-virtual_svg__bank-card-virtual_svg__paint1_linear_8636_53743)"
        />
        <Path
          d="M210.061 84.588l-43.943 26.353V58.235l43.943 26.353z"
          fill="url(#bank-card-virtual_svg__bank-card-virtual_svg__paint2_linear_8636_53743)"
        />
      </G>
      <Defs>
        <LinearGradient
          id="bank-card-virtual_svg__bank-card-virtual_svg__paint0_linear_8636_53743"
          x1={210.028}
          y1={45.059}
          x2={210.028}
          y2={-7.647}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#40C4FF" />
          <Stop offset={1} stopColor="#2979FF" />
        </LinearGradient>
        <LinearGradient
          id="bank-card-virtual_svg__bank-card-virtual_svg__paint1_linear_8636_53743"
          x1={232.032}
          y1={84.588}
          x2={232.032}
          y2={137.294}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#2979FF" />
          <Stop offset={1} stopColor="#304FFE" />
        </LinearGradient>
        <LinearGradient
          id="bank-card-virtual_svg__bank-card-virtual_svg__paint2_linear_8636_53743"
          x1={155.132}
          y1={84.588}
          x2={201.928}
          y2={65.08}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#2979FF" />
          <Stop offset={1} stopColor="#40C4FF" />
        </LinearGradient>
        <ClipPath id="bank-card-virtual_svg__bank-card-virtual_svg__clip0_8636_53743">
          <Path fill="#fff" d="M0 0h232v138H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
});
