import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DeviceData} from '@youtoken/ui.state-security';
import {observer} from 'mobx-react';

export interface DeviceInfoProps {
  device: DeviceData;
}

export const DeviceInfo: React.FC<DeviceInfoProps> = observer(({device}) => {
  const {t} = useTranslation();

  const {deviceType, deviceBrand, deviceName, browserOs, browserName} = device;

  return (
    <Box alignSelf={'flex-start'} mb={16}>
      <Text variant="$body-02" color="$text-02" mb={8}>
        {t('modal.activity_details.table_title.device')}
      </Text>
      {deviceType === 'web' && (
        <>
          <Text variant="$body-01-medium-accent" color="$text-01" mb={2}>
            {browserName}
          </Text>
          <Text variant="$body-01" color="$text-02">
            {browserOs}
          </Text>
        </>
      )}

      {deviceType !== 'web' && (
        <>
          <Text variant="$body-01-medium-accent" color="$text-01" mb={2}>
            {deviceName}
          </Text>
          <Text variant="$body-01" color="$text-02">
            {deviceBrand}
          </Text>
        </>
      )}
    </Box>
  );
});
