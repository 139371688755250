import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TouchableBox, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {RewardsHistoryModal} from '.';

export const RewardsHistoryModalTrigger: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  return (
    <>
      <TouchableBox
        onPress={handleToggle}
        alignItems="center"
        flexDirection="row"
      >
        <Text variant="$body-02-medium-accent" color="$text-05">
          {t('surface.referral_program.referrals.recent_bonuses_show_all')}
        </Text>
        <Icon name="chevron_right" size={16} color="$text-05" />
      </TouchableBox>
      <RewardsHistoryModal isOpen={isOpen} onClose={handleToggle} />
    </>
  );
});
