import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  AppLedgerNotFoundLayout,
  AppLedgerNotFoundLayoutContent,
} from '../components';

export const AppLedgerNotFoundTurbochargePage: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <AppLedgerNotFoundLayout>
      <AppLedgerNotFoundLayoutContent
        title={t('surface.ledger_app_not_found_turbocharge.title')}
        description={t('surface.ledger_app_not_found_turbocharge.description')}
      />
    </AppLedgerNotFoundLayout>
  );
});
