import * as React from 'react';
import {observer} from 'mobx-react';
import {
  RewardOverviewStatus,
  RewardsResource,
} from '@youtoken/ui.resource-rewards-v3';
import {Box, Text} from '@youtoken/ui.primitives';
import {Trans, Timer} from '@youtoken/ui.elements';

export const RewardsOverviewTimer: React.FC = observer(() => {
  const {
    data: {status, timeLeft},
    refetch,
  } = RewardsResource.use({});

  if (status === RewardOverviewStatus.OPEN) {
    return (
      <Box px={24} py={12} bg="$attention-02">
        <Text variant="$body-02" color="$attention-01" textAlign="center">
          <Trans
            i18nKey="surface.referral_program.rewards.overview.timer"
            components={{
              Timer: (
                <Timer
                  timeLeft={timeLeft}
                  onTimerEnd={refetch}
                  color="$attention-01"
                  textAlign="center"
                  fontWeight="bold"
                />
              ),
            }}
          />
        </Text>
      </Box>
    );
  }

  return null;
});
