import {useCallback} from 'react';
import {useEventListener} from '@web-app/src/hooks/general/useEventListener';

export const useUnhandlerRejection = (callback?: Function) => {
  const handleUnhanledRejection = useCallback(
    (event: PromiseRejectionEvent) => {
      console.error(event.toString());

      if (callback) {
        callback(event);
      }
    },
    []
  );

  useEventListener(window, 'unhandledrejection', handleUnhanledRejection);
};
