import * as React from 'react';
import {SENTRY} from '@youtoken/ui.sentry';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {observer} from 'mobx-react';
import {History} from 'history';
import {useOnMount} from '@web-app/src/hooks';
import {initSentry} from '@web-app/src/services/sentry';

export type SentryInitializerProps = {
  history: History;
};

/** __component that initializes sentry__
 *
 * Does not load external scripts but lzy loads npm module
 */
export const SentryInitializer: React.FC<SentryInitializerProps> = observer(
  ({history}) => {
    const {
      data: {id, email},
    } = AuthMeResource.use({});

    useOnMount(() => {
      initSentry(history);
    });

    React.useEffect(() => {
      SENTRY.setUserContext(
        id
          ? {
              id,
              email,
            }
          : null
      );
    }, [id, email]);

    return null;
  }
);
