import {observable, action, runInAction} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {extractErrorFromResponse} from '@youtoken/ui.validation-messages';

export class Calculated {
  @observable
  public form: any;

  @observable
  public loading: boolean = false;

  @observable
  public ready: boolean = false;

  @observable
  public data: any = undefined;

  @action
  public load = () => {
    this.loading = true;
    this.ready = false;

    const url = `/v${this.form.loan.version}/loan/${
      this.form.loan.version === 1 ? 'ftp' : 'tp'
    }/calculate`;

    return TRANSPORT.API.post(url, {
      id: this.form.loan.id,
      recompenseTicker: this.form.loan.collateralTicker,
      [this.form.loan.version === 1 ? 'FTP' : 'tp']: Number(this.form.amount),
    })
      .then(response => {
        runInAction(() => {
          this.data = {
            ...response.data,
          };
          this.loading = false;
          this.ready = true;

          this.form.instance.$('amount').invalidate();
        });
      })
      .catch(e => {
        const FTPError = extractErrorFromResponse(
          e?.response?.data,
          this.form.loan.version === 1 ? 'FTP' : 'tp'
        );
        this.form.instance.$('amount').invalidate(FTPError);

        this.loading = false;
      });
  };

  public constructor(form: any) {
    this.form = form;
  }
}
