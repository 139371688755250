import {messages} from '@youtoken/ui.validation-messages';
import {BaseSchema} from 'yup';
import {Message} from 'yup/lib/types';

export function create() {
  return new AgreeToTermsSchema();
}

export default class AgreeToTermsSchema extends BaseSchema {
  constructor() {
    super({type: 'agreeToTerms'});

    this.default(false);

    this.withMutation(() => {
      this.transform(function (value) {
        return !!value;
      });
    });
  }

  required(_message?: Message) {
    const message = _message || messages.AGREE_TO_TERMS;
    return this.oneOf([true], message).defined(message);
  }
}

create.prototype = AgreeToTermsSchema.prototype;
