import * as React from 'react';
import {observer} from 'mobx-react';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {type BoxProps, Text} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';
import {BenefitItem} from '../../BenefitItem';

// NOTE: to call this component, you need to check products.incentives.isEnabled flag is true
// and incentives.maxPercent for current user's loyalty level is above 0
export const DepositNowInfoIncentives: React.FC<BoxProps> = observer(
  boxProps => {
    const {maxPercentOnDeposit} = IncentivesFeature.use({});

    return (
      <BenefitItem
        testID="DEPOSIT_NOW_INFO_INCENTIVES"
        iconName="first_trade"
        iconColor="$interactive-01"
        iconColorBackground="$interactive-02"
        {...boxProps}
      >
        <Trans
          components={{
            Strong: <Text color="$text-01" />,
          }}
          values={{percent: maxPercentOnDeposit}}
          i18nKey="surface.wallets.deposit_now.benefits.incentives"
        />
      </BenefitItem>
    );
  }
);
