import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {Box} from '@youtoken/ui.primitives';
import {UpgradeToLevelMaxIncome} from './MaxIncome';

interface UpgradeToLevelMaxIncomesProps {
  level: LoyaltyLevel;
  showSavingsInfo?: boolean;
  showMinerInfo?: boolean;
}

export const UpgradeToLevelMaxIncomes: React.FC<UpgradeToLevelMaxIncomesProps> =
  observer(
    ({
      level: {
        saving: {
          maxIncomePeriodFormatted: savingsMaxIncomePeriodFormatted,
          maxIncomeTickerFormatted: savingsMaxIncomeTickerFormatted,
          maxIncomeAmountFormatted: savingsMaxIncomeAmountFormatted,
        },
        details: {
          minerMaxIncomePeriodFormatted,
          minerMaxIncomeTickerFormatted,
          minerMaxMonthlyIncomeBTCFormatted,
        },
      },
      showSavingsInfo = true,
      showMinerInfo = true,
    }) => {
      const {t} = useTranslation();

      return (
        <Box mb={40}>
          {showSavingsInfo && (
            <UpgradeToLevelMaxIncome
              periodFormatted={savingsMaxIncomePeriodFormatted}
              tickerFormatted={savingsMaxIncomeTickerFormatted}
              amountFormatted={savingsMaxIncomeAmountFormatted}
              mb={12}
            >
              {t('surface.loyalty.savings')}
            </UpgradeToLevelMaxIncome>
          )}
          {showMinerInfo && (
            <UpgradeToLevelMaxIncome
              periodFormatted={minerMaxIncomePeriodFormatted}
              tickerFormatted={minerMaxIncomeTickerFormatted}
              amountFormatted={minerMaxMonthlyIncomeBTCFormatted}
            >
              {t('surface.loyalty.cloud_miner')}
            </UpgradeToLevelMaxIncome>
          )}
        </Box>
      );
    }
  );
