import {computed} from 'mobx';
import {orderBy} from 'lodash';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {getSettlementFeePercentFormatted} from '@youtoken/ui.hodls-utils';

interface GenericRow {
  type: 'header' | 'table-header' | 'profit-share-row' | 'rollover-row';
  key: string;
}

interface DataRow {
  type: 'data-row';
  key: string;
  instrument: string;
  profitShareFee: string;
  rolloverFee: string;
  maxMultiplier: string;
  short: boolean;
}

type Row = DataRow | GenericRow;

export class ComissionsTableFeature extends createFeature({
  getKey: () => `ComissionsTableFeature`,
  getResources: () => ({
    tariffs: getResourceDescriptor(HodlsTariffsResource, {}),
  }),
}) {
  @computed get tableData() {
    const dataItems = this.resources.tariffs.data.reduce(
      // @ts-ignore previousValue and currentValue could have different types
      (acc: DataRow[], item) => {
        const instrument =
          `${item.baseTicker} / ${item.quoteTicker}`.toUpperCase();

        const rolloverFeePercentFormatted = getSettlementFeePercentFormatted(
          item.settlementFee
        );
        const rolloverFeeString = `${rolloverFeePercentFormatted}% / ${item.settlementPeriod}`;

        acc.push({
          type: 'data-row',
          instrument,
          key: `${instrument}:${item.isShort}`,
          profitShareFee: `${formatPercent(item.unifiedFee, true)}%`,
          rolloverFee: rolloverFeeString,
          maxMultiplier: `x${item.maxMultiplier}`,
          short: item.isShort,
        });
        return acc;
      },
      []
    );

    const orderedItems = orderBy(dataItems, ['instrument'], ['asc']);

    const result: Row[] = [
      {type: 'table-header', key: 'table-header'},
      ...orderedItems,
      {type: 'profit-share-row', key: 'profit-share-row'},
      {type: 'rollover-row', key: 'rollover-row'},
    ];

    return result;
  }
}
