import React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountBadge, ValidationMessage} from '@youtoken/ui.hodl-elements';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {AdjustPendingOrderFormClass} from '../../form';

export const StopLossSection: React.FC<BoxProps> = observer(({...boxProps}) => {
  const {t} = useTranslation();
  const testIdSection = 'STOP_LOSS';

  const {
    form: {
      stopLoss,
      stopLossPercent,
      updateStopLossValue,
      updateStopLossPercent,
      setStopLossLimit,
      maxLoss,
      stopLossError,
      isShort,
      inputTicker,
      precision,
    },
    view: {
      stopLossPercentMin,
      stopLossPercentMax,
      stopLossLimitMessage,
      slErrorIsClickable,
    },
  } = useForm<AdjustPendingOrderFormClass>();

  const validationComponent = React.useMemo(() => {
    return (
      <ValidationMessage
        validationMessage={stopLossLimitMessage}
        onValidationClick={setStopLossLimit}
        validationError={stopLossError}
        isValidationClickable={slErrorIsClickable}
        testIdSection={testIdSection}
      />
    );
  }, [
    stopLossLimitMessage,
    setStopLossLimit,
    stopLossError,
    slErrorIsClickable,
  ]);

  return (
    <Box px={24} {...boxProps}>
      <Box
        position="relative"
        zIndex={1}
        mb={8}
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-01-high-accent" mr={4}>
            {t('surface.hodls.adjust_tp.stop_loss')}
          </Text>
          <QuestionTooltip
            content={t('surface.hodls.adjust_tp.stop_loss_tooltip')}
            bodyHorizontalOffset={60}
            width={270}
          />
        </Box>

        <AmountBadge amount={maxLoss} ticker={inputTicker} />
      </Box>

      <Box mb={8}>
        <AmountAndPercentInput
          amountLabel={t('surface.hodls.adjust_tp.level')}
          percentLabel={t('surface.hodls.adjust_tp.change')}
          amountValue={stopLoss}
          percentValue={stopLossPercent}
          precisionAmount={precision}
          percentRangeMin={stopLossPercentMin}
          percentRangeMax={stopLossPercentMax}
          onChangeAmount={updateStopLossValue}
          onChangePercent={updateStopLossPercent}
          sliderKey="stopLoss"
          percentSignIsActive={true}
          validationComponent={validationComponent}
          shouldReverseSlider={!isShort}
          testIdSection={testIdSection}
        />
      </Box>
    </Box>
  );
});
