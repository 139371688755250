import {Form} from './Form';
import {type TFuncKey} from 'react-i18next';
import {observable, action} from 'mobx';
import {i18n} from '@youtoken/ui.service-i18n';
import {SurveyItem} from './index';
import {invariant} from '@youtoken/ui.utils';

export class View {
  @observable
  public form: Form;

  @observable
  public options?: SurveyItem[] = [];

  @observable
  public title: string;

  @observable
  public question?: string;

  @observable
  public shouldShowHeader: boolean = true;

  constructor({
    form,
    data,
  }: {
    form: Form;
    data: {
      options?: SurveyItem[];
      question?: string;
      value: string;
    };
  }) {
    this.form = form;
    this.options = data.options;
    this.question = data.question;
    this.title = data.value;

    // add new fields for the text input if needed
    this.checkAndSetFormFields();
  }

  @action private checkAndSetFormFields = () => {
    if (this.question && !this.options) {
      this.form.addNextField(i18n.t(this.question as TFuncKey, {lng: 'en'}));
    }
  };

  @action public setAnswer = (value: string) => {
    this.form.fillTheForm(
      i18n.t(value as TFuncKey, {lng: 'en'}),
      i18n.t(this.question as TFuncKey, {lng: 'en'})
    );

    const chosenItem = this.options?.find(el => el.value === value);

    invariant(
      chosenItem,
      'Chosen item is not found',
      {},
      {
        value,
        options: this.options,
      }
    );

    // if the question is the last one, submit the form and stop updating the view
    if (!chosenItem.question) {
      this.form.submitForm();
      return;
    }

    // update the view
    this.title = value;
    this.question = chosenItem.question;
    this.options = chosenItem.options;

    // update a question number in the form
    this.form.increaseQuestionNumber();

    // hide header after the first answer
    if (this.shouldShowHeader) {
      this.shouldShowHeader = false;
    }

    // add new fields for the text input if needed
    this.checkAndSetFormFields();
  };
}
