import React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';

export const TopBarLayout: React.FC<BoxProps> = ({children, ...boxProps}) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      pt={12}
      px={{default: 16, phone: 24}}
      pb={16}
      bg="$ui-background"
      borderColor="$ui-01"
      borderBottomWidth={1}
      {...boxProps}
    >
      {children}
    </Box>
  );
};
