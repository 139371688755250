import * as React from 'react';
import {observer} from 'mobx-react';
import {SavingsSelectedAssetsFeature} from '../../Feature';
import {SavingsClaimEarningButton} from '../../../../components';

export const SavingsSelectedAssetsActionClaim: React.FC = observer(() => {
  const {submitting, submit} = SavingsSelectedAssetsFeature.use({});

  return <SavingsClaimEarningButton loading={submitting} onPress={submit} />;
});
