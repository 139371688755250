import {observable, computed} from 'mobx';
import {serializable, optional, primitive, object, date} from 'serializr';
import {
  format,
  isBefore,
  subDays,
  formatDistanceToNow,
} from '@youtoken/ui.date-fns';
import {LocationData} from './LocationData';

export type DevicePlatformType = 'tablet' | 'mobile' | 'desktop';

export class DeviceData {
  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(optional(primitive()))
  sessionId?: string;

  @observable
  @serializable(primitive())
  deviceType!: string; // example: web, ios

  @observable
  @serializable(primitive())
  deviceIcon!: string;

  @observable
  @serializable(primitive())
  uuid!: string; // it is the uniq device id, from user, not use

  @observable
  @serializable(optional(primitive()))
  deviceName?: string; //todo new,/ use for deviceName, can be null, "{\"_U\":0,\"_V\":0,\"_W\":null,\"_X\":null}"

  @observable
  @serializable(optional(primitive()))
  deviceBrand?: string; //todo new,  example: "Apple"

  @observable
  @serializable(primitive())
  devicePlatform!: DevicePlatformType;

  @observable
  @serializable(optional(primitive()))
  deviceDisplayName?: string;

  @observable
  @serializable(optional(primitive()))
  deviceModel?: string; //todo new, deviceLogo from this field, example: "iPhone13,2"

  @observable
  @serializable(optional(primitive()))
  browserName?: string; //todo new, use for web  deviceLogo from this field, example: "Chrome", "Safari" for ios, android on FE write "app"

  @observable
  @serializable(optional(primitive()))
  browserOs?: string; //todo new,  use for web, example: "Mac OS"

  @serializable(primitive())
  @observable
  lastIp!: string;

  @observable
  @serializable(object(LocationData))
  lastIpLocation!: LocationData;

  @observable
  @serializable(optional(primitive()))
  versionOs?: string; //session/device field, example: "10.15.7", "14.4"

  @observable
  @serializable(optional(primitive()))
  versionBrowser?: string; //todo new session/device field, example: "10.15.7"

  @observable
  @serializable(optional(primitive()))
  versionApp?: string; // for future

  @observable
  @serializable(date())
  updatedAt!: Date; // use for getting usageTime, session/day and session/time

  @computed get date() {
    return format(this.updatedAt, 'd MMM');
  }

  @computed get fullDate() {
    return format(this.updatedAt, 'd MMM yyyy');
  }

  @computed get time() {
    return format(this.updatedAt, 'HH:mm');
  }

  @computed get displayLastUsedRelativeTime() {
    if (isBefore(this.updatedAt, subDays(new Date(), 2))) {
      return format(this.updatedAt, 'd MMM yyyy');
    }

    return formatDistanceToNow(this.updatedAt, {addSuffix: true});
  }

  // @observable
  // @serializable(primitive())
  // isRemoved!: boolean; // todo as I understand we should hide isRemoved=true devices from list?

  @computed get displayName() {
    if (this.deviceName) {
      return this.deviceName;
    }

    if (this.deviceType === 'web' && this.browserName) {
      let result = this.browserName;

      if (this.versionBrowser) {
        result = `${this.browserName} ${this.versionBrowser}`;
      }

      return result;
    }

    return 'Unknown device';
  }

  @computed get displayLocation() {
    return (
      [this.lastIpLocation?.country, this.lastIpLocation?.city]
        .filter(Boolean)
        .filter(v => v !== 'Unknown')
        .join(', ') || 'Unknown location'
    );
  }

  @computed get displayDeviceOs() {
    const os = this.deviceType === 'ios' ? 'iOS' : 'Android';

    return `${os} ${this.versionOs}`;
  }

  @computed get displayLocationWithIp() {
    return [this.displayLocation, this.lastIp].join(' ');
  }

  @computed get displayDeviceIcon() {
    switch (this.devicePlatform) {
      case 'desktop':
        return 'laptop';
      case 'mobile':
        return 'phone';
      case 'tablet':
        return 'tablet';
      default:
        return 'laptop';
    }
  }

  @computed get displayDeviceLogo() {
    if (this.deviceType === 'web') {
      if (
        this.browserOs?.toLowerCase().includes('mac') ||
        this.browserOs?.toLowerCase().includes('osx')
      ) {
        return 'os_mac';
      }
      if (this.browserOs?.toLowerCase().includes('windows')) {
        return 'os_windows';
      }

      if (this.browserOs?.toLowerCase().includes('ubuntu')) {
        return 'os_linux';
      }

      return 'os_other';
    }

    if (this.deviceType === 'ios') {
      return 'os_mac';
    }

    if (this.deviceType === 'android') {
      return 'os_android';
    }
  }

  @computed get displayDeviceModel() {
    if (this.deviceType === 'web') {
      let model = this.browserName
        ? `${this.browserName} ${this.versionBrowser}`
        : 'Unknown';

      if (this.browserOs) {
        model += `, ${this.browserOs} ${this.versionOs}`;
      }

      return model;
    } else {
      const deviceModel =
        this.deviceDisplayName ||
        this.deviceName ||
        this.deviceModel ||
        'Unknown';
      return `${deviceModel}, ${this.displayDeviceOs}`;
    }
  }
}

export class SecurityDeviceData extends DeviceData {
  @observable
  @serializable(primitive())
  isCurrent!: boolean;
}
