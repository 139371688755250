import * as React from 'react';
import {LedgerLiveWelcomeTradingSurface} from '@youtoken/ui.surface-hodl-instruments';
import {ServicePagesWrapper} from '../ServicePagesWrapper';

export const LedgerLiveHodlInstrumentsPage: React.FC = () => {
  return (
    <ServicePagesWrapper>
      <LedgerLiveWelcomeTradingSurface />
    </ServicePagesWrapper>
  );
};
