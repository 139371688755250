import React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {WalletsItemFeature} from '../../features/WalletsItemFeature';

interface WalletsItemRateProps {
  ticker: string;
  showEquivalentTicker?: boolean;
}

export const WalletsItemRate: React.FC<WalletsItemRateProps> = observer(
  ({ticker, showEquivalentTicker = true}) => {
    const {
      tickerFormatted,
      equivalentTickerFormatted,
      equivalentRateFormatted,
      equivalentRateDiffFormatted,
    } = WalletsItemFeature.use({
      ticker,
    });

    const positive = React.useMemo(() => {
      return Number(equivalentRateDiffFormatted) >= 0;
    }, [equivalentRateDiffFormatted]);

    return (
      <Box alignItems="flex-end">
        <Text
          testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE`}
        >
          {equivalentRateFormatted}{' '}
          {showEquivalentTicker && (
            <Text color="$text-02">{equivalentTickerFormatted}</Text>
          )}
        </Text>
        <Text
          variant="$body-02"
          color={positive ? '$success-01' : '$danger-01'}
          testID={`${tickerFormatted}_TO_${equivalentTickerFormatted}_RATE_DIFF`}
        >
          {equivalentRateDiffFormatted}%
        </Text>
      </Box>
    );
  }
);
