import {IComboboxItem} from './types';
import {matchSorter} from 'match-sorter';

export const matchSort = (
  items: IComboboxItem[],
  inputValue?: string | null,
  keys?: string[]
) => {
  if (!inputValue) {
    return items;
  }

  return matchSorter(items, inputValue, {
    keys: keys ?? ['value', 'label'],
  });
};
