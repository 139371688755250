import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {HodlHistoryResponse} from './HodlHistoryResponse';

export interface HodlHistoryArgs {
  hodlId: string;
}

export class HodlHistoryResource extends createResource<
  HodlHistoryArgs,
  HodlHistoryResponse[]
>({
  skipRefreshOnVisible: false,
  getKey: ({hodlId}: HodlHistoryArgs) => `hodlHistory:${hodlId}`,
  getData: ({hodlId}) =>
    TRANSPORT.API.get(`/v3/hodl/${hodlId}/history`).then(res => {
      return deserialize(HodlHistoryResponse, res.data as any[]);
    }),
}) {}
