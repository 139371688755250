import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {computed, observable, runInAction} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {toBig} from '@youtoken/ui.formatting-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';
import {handleFormSubmitError} from '../../../utils';
import {
  IFeatureTurboCreateFormArgs,
  IFeatureTurboCreateFormResources,
} from '../../types';
import {
  getBorrowedTickerGroups,
  getCollateralTickerGroups,
  getTermValues,
  handleChangeCollateralTicker,
  handleChangeBorrowedTicker,
  handleChangeTerm,
  handleChangeCollateralAmount,
  handleChangeFTP,
  getTermSetting,
} from './utils';

export class Form {
  @observable
  public args: IFeatureTurboCreateFormArgs;

  @observable
  public resources: IFeatureTurboCreateFormResources;

  @observable
  public instance: MobxReactForm;

  @computed
  public get collateralTickerGroups() {
    return getCollateralTickerGroups(this.resources);
  }

  @computed
  public get borrowedTickerGroups() {
    return getBorrowedTickerGroups(
      this.instance.$('collateralTicker').value,
      this.resources
    );
  }

  @computed get term() {
    return this.instance.$('term').value;
  }

  @computed get borrowedTicker() {
    return this.instance.$('borrowedTicker').value;
  }
  @computed get termSetting() {
    return getTermSetting(this.term, this.borrowedTicker)!;
  }

  @computed get termSettingMinFtp() {
    return toBig(this.termSetting.turboMin).toNumber();
  }

  @computed get termSettingMaxFtp() {
    return toBig(this.termSetting.turboMax).toNumber();
  }

  @computed
  public get termValues() {
    return getTermValues(
      this.resources.tariffsResource,
      this.instance.$('collateralTicker').value,
      this.instance.$('borrowedTicker').value
    );
  }

  @computed
  public get turboAgreements() {
    return this.resources.docsResource.data.turboCreateForm || [];
  }

  @computed
  public get totalCollateralAmount() {
    return (
      this.resources.walletsResource
        .getByTicker(this.instance.$('collateralTicker').value)
        ?.amount?.toString() || '0'
    );
  }

  public constructor(
    args: IFeatureTurboCreateFormArgs,
    resources: IFeatureTurboCreateFormResources
  ) {
    this.args = args;
    this.resources = resources;

    invariant(this.collateralTickerGroups[0], 'No collateral ticker groups');

    const collateralTickerName =
      this.collateralTickerGroups[0].values[0]!.ticker;
    const borrowedTickerGroups = getBorrowedTickerGroups(
      collateralTickerName,
      this.resources
    );

    invariant(borrowedTickerGroups[0], 'No borrowed ticker groups');

    const borrowedTickerGroupsValueMain = borrowedTickerGroups[0].values.find(
      value => value.ticker === this.resources.authMeResource.mainCurrency
    );
    const borrowedTickerName = borrowedTickerGroupsValueMain
      ? borrowedTickerGroupsValueMain.ticker
      : borrowedTickerGroups[0].values[0]!.ticker;
    const initialPrice = toBig(
      this.resources.ratesResource.getRate(
        collateralTickerName,
        borrowedTickerName
      )
    );
    const termValues = getTermValues(
      this.resources.tariffsResource,
      collateralTickerName,
      borrowedTickerName
    );
    const term = termValues[0];

    invariant(term, 'No term values');

    const termSetting = getTermSetting(term, borrowedTickerName)!;
    const leverageMin = toBig(termSetting.turboMin);
    const leverageMax = toBig(termSetting.turboMax);
    const leverage = leverageMin;
    const ftpMin = toBig(termSetting.minFTP);
    const ftpMax = toBig(termSetting.maxFTP);
    const ftpDefault = toBig(termSetting.defaultFTP);
    const ftp = ftpDefault.gt(0) ? initialPrice.mul(ftpDefault) : toBig(0);
    const collateralAmount =
      this.resources.walletsResource.getByTicker(collateralTickerName)
        ?.amount || toBig(0);
    const borrowedAmount = initialPrice
      .mul(toBig(collateralAmount))
      .mul(toBig(term.ltv))
      .round(getCoinDecimalPrecision(borrowedTickerName));

    this.instance = new MobxReactForm(
      {
        fields: {
          collateralTicker: {
            value: collateralTickerName,
            handlers: {
              onChange: (_field: any) => (value: string) => {
                runInAction(() => {
                  handleChangeCollateralTicker(
                    value,
                    this.instance,
                    this.resources
                  );
                });
              },
            },
          },
          borrowedTicker: {
            value: borrowedTickerName,
            handlers: {
              onChange: (_field: any) => (value: string) => {
                runInAction(() => {
                  handleChangeBorrowedTicker(
                    value,
                    this.instance,
                    this.resources
                  );
                });
              },
            },
          },
          initialPrice: {
            value: initialPrice.toString(),
          },
          collateralAmount: {
            value: collateralAmount.toString(),
            handlers: {
              onChange: (_field: any) => (value: any) => {
                runInAction(() => {
                  handleChangeCollateralAmount(
                    value,
                    this.instance,
                    this.resources
                  );
                });
              },
            },
          },
          borrowedAmount: {
            value: borrowedAmount.toString(),
          },
          term: {
            value: term,
            handlers: {
              onChange: (_field: any) => (value: any) => {
                runInAction(() => {
                  handleChangeTerm(value, this.instance, this.resources);
                });
              },
            },
          },
          leverage: {
            value: Number(leverage),
            extra: {
              min: Number(leverageMin),
              max: Number(leverageMax),
            },
          },
          agreeToTerms: {value: false},
          useFTP: {
            value: ftp.gt(0),
            disabled: ftpDefault.gt(0),
          },
          FTP: {
            value: ftp.gt(0)
              ? ftp.toFixed(getCoinDecimalPrecision(borrowedTickerName))
              : '',
            extra: {
              min: Number(ftpMin),
              max: Number(ftpMax),
            },
            handlers: {
              onChange: (_field: any) => (value: any) => {
                handleChangeFTP(value, this.instance, this.resources);
              },
            },
          },
        },
      },
      {
        hooks: {
          onSuccess: (form: any) => {
            if (
              !this.resources.authMeResource.checkProductAvailability('turbo')
            ) {
              return Promise.resolve();
            }

            const values = form.values();

            return TRANSPORT.API.post('/v1/loan', {
              mode: 'turbo',
              collateralTicker: values.collateralTicker,
              borrowedTicker: values.borrowedTicker,
              collateralAmount: values.collateralAmount,
              tariffId: values.term.id,
              turbo: values.leverage,
              initialPrice: values.initialPrice,
              borrowedAmount: values.borrowedAmount,
              FTP: values.useFTP ? values.FTP : undefined,
            })
              .then(() => {
                LOCAL_NOTIFICATIONS.info({
                  text: i18n.t(
                    'surface.loans.create_turbo_form.message.created'
                  ),
                });
                SHARED_ROUTER_SERVICE.navigate('Turbocharge', {
                  refresh: true,
                });
              })
              .catch(e => {
                handleFormSubmitError(form, e, {
                  amount: 'collateralAmount',
                });
              });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  collateralTicker: yup.mixed().required(),
                  borrowedTicker: yup.mixed().required(),
                  collateralAmount: yup.string().required(),
                  term: yup.object().required(),
                  leverage: yup.number().required(),
                  agreeToTerms: yup.agreeToTerms().required(),
                  useFTP: yup.mixed(),
                  initialPrice: yup.string(),
                  borrowedAmount: yup.string(),
                  FTP: yup.string(),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
