import {computed, observable, action} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {TRANSPORT} from '@youtoken/ui.transport';
import {messages} from '@youtoken/ui.validation-messages';

import {IFeatureDeleteAccountFormResources} from './types';

export class Form {
  @observable
  public args: {};

  @observable
  public resources: IFeatureDeleteAccountFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(args: {}, resources: IFeatureDeleteAccountFormResources) {
    this.args = args;
    this.resources = resources;

    const fields = {
      agree: {
        value: false,
      },
    };

    const hooks = {
      onSuccess: () => {
        return this.submit();
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              agree: yup.agreeToTerms().required(messages.REQUIRED),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: true,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @computed get requestSent() {
    return this.resources.authMe.data.accountRemovalRequested;
  }

  @action
  public submit = () => {
    return TRANSPORT.API.post('/v1/profile/remove');
  };
}
