import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {observer} from 'mobx-react';
import {HodlTariffResponseItem} from '@youtoken/ui.resource-hodl-tariffs';
import {HODLPublicInstrumentItem} from '@youtoken/ui.resource-hodl-public-instruments';
import {Icon, LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {Box, Text} from '@youtoken/ui.primitives';

interface InstrumentItemBaseProps {
  item: HodlTariffResponseItem | HODLPublicInstrumentItem;
  onPressStar?: () => void;
}

export const BaseBlock: React.FC<InstrumentItemBaseProps> = observer(
  ({item, onPressStar}) => {
    const {
      baseTickerName,
      baseTicker,
      baseTickerFormatted,
      quoteTickerFormatted,
      isStarred,
    } = item;

    return (
      <Box flexDirection="row" alignItems="center">
        <Box mr={10}>
          <LogoColored
            name={baseTicker as LogoColoredIconName}
            size={32}
            // @ts-ignore TS telling that this prop doesn't exist, but it does and work on mobile
            testID="INSTRUMENT_ICON"
          />
        </Box>

        <Box>
          <Box flexDirection="row" alignItems="center">
            <Box
              flexDirection="row"
              alignItems="flex-end"
              testID="INSTRUMENT_TICKERS"
            >
              <Box>
                <Text variant="$body-01-medium-accent" color="$text-01">
                  {baseTickerFormatted}
                </Text>
              </Box>
              <Box ml={2}>
                <Text variant="$body-01" color="$text-02">
                  /
                </Text>
              </Box>
              <Box ml={2}>
                <Text variant="$body-02" color="$text-02">
                  {quoteTickerFormatted}
                </Text>
              </Box>
            </Box>
            {onPressStar && (
              <TouchableOpacity
                onPress={onPressStar}
                activeOpacity={1}
                style={{display: item.isStarDisabled ? 'none' : 'flex'}}
                testID="INSTRUMENT_STARRED_BUTTON"
              >
                <Box ml={4} mt={-2}>
                  <Icon
                    name={isStarred ? 'star_filled' : 'star'}
                    size={16}
                    color="$text-05"
                  />
                </Box>
              </TouchableOpacity>
            )}
            {item.isCategoryRollovers && (
              <Box ml={2}>
                <Icon name="discount" color="$success-01" size={14} />
              </Box>
            )}
          </Box>

          <Box maxWidth={{default: 120, desktop: undefined}}>
            <Text
              variant="$body-02"
              color="$text-02"
              // @ts-ignore TS telling that this prop doesn't exist, but it does and work on mobile
              ellipsizeMode="tail"
              numberOfLines={1}
              testID="INSTRUMENT_NAME"
            >
              {baseTickerName}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
