import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {LogoColored} from '@youtoken/ui.icons';
import {Badge} from '@youtoken/ui.elements';
import {Version, type Wallet} from '@youtoken/ui.resource-wallets';
import {isNativeNetwork} from './utils';

export interface ListItemProps extends BoxProps {
  item: Wallet;
}

export const ListItem: React.FC<ListItemProps> = observer(
  ({item, ...boxProps}) => {
    const {t} = useTranslation();

    const {ticker, tickerName, versions, hasApr, aprPercent} = item;

    const itemVersions = React.useMemo(() => {
      return versions.filter(v => !isNativeNetwork(v));
    }, [versions]);

    const renderVersion = React.useCallback(({version}: Version) => {
      return (
        <Badge size="tiny" type="secondary" key={version} m={3}>
          {version.toUpperCase()}
        </Badge>
      );
    }, []);

    return (
      <Box
        flexGrow={1}
        flexShrink={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        minHeight={60}
        px={24}
        py={16}
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center" mr={24}>
          <Box mr={10}>
            <LogoColored name={ticker as any} size={32} />
          </Box>

          <Box flexShrink={1} borderColor={'$attention-01'}>
            <Text variant="$body-01-high-accent">{tickerName}</Text>
            <Box flexDirection="row">
              <Text variant="$body-02" color="$text-02">
                {`${ticker.toUpperCase()} `}
              </Text>
              {hasApr && (
                <Text variant="$body-02" color="$interactive-01">
                  {aprPercent}% {t('surface.wallets.apr')}
                </Text>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          flexDirection="row"
          flex={1}
          flexWrap="wrap"
          justifyContent="flex-end"
          m={-3}
        >
          {itemVersions.map(renderVersion)}
        </Box>
      </Box>
    );
  }
);
