import {computed, action} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SecurityDeviceData} from './response';
import {computedFn} from 'mobx-utils';

export class SecurityDevicesResource extends createResource<
  any,
  SecurityDeviceData[]
>({
  getKey: () => 'SecurityDevices',
  getData: () =>
    TRANSPORT.API.get(`/v1/security/devices`).then(res => {
      return deserialize(SecurityDeviceData, res.data as any[]);
    }),
}) {
  @computed get sessionListFiltered() {
    return this.data.filter(item => Boolean(item.sessionId));
  }

  getDeviceItem = computedFn((id: string) => {
    return this.data.find(item => item.id === id);
  });

  @action endSession = (deviceId: string) => {
    return TRANSPORT.API.delete(`/v1/security/devices/${deviceId}/session`)
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({text: 'Cannot end session'});
      })
      .then(() => this.refetchSilently());
  };

  @action removeDevice = (deviceId: string) => {
    return TRANSPORT.API.delete(`/v1/security/devices/${deviceId}/device`)
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({text: 'Cannot remove device'});
      })
      .then(() => this.refetchSilently());
  };
}
