import {MMKV, type NativeMMKV} from 'react-native-mmkv';
import {IStorageService} from '../types';
import {InMemoryMMKVLikeStorage} from '../InMemoryStorage';
import {SECURE_KEYS} from './types';

export class SecureStorage implements IStorageService {
  private _storage: NativeMMKV;

  constructor() {
    if (typeof window !== 'undefined' && window.localStorage) {
      this._storage = new MMKV();
    } else {
      this._storage = new InMemoryMMKVLikeStorage();
    }
  }

  public get(key: SECURE_KEYS): string | undefined {
    return this._storage.getString(key);
  }

  public set(key: SECURE_KEYS, value: string): void {
    this._storage.set(key, value);
  }

  public delete(key: SECURE_KEYS): void {
    this._storage.delete(key);
  }
}
