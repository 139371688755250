import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {
  FeatureCryptoDepositExternal,
  type FeatureCryptoDepositExternalArgs,
} from './state';
import {CryptoDepositExternalContent} from './components';

export interface CryptoDepositExternalProps
  extends FeatureCryptoDepositExternalArgs {}

export const CryptoDepositExternal: React.FC<CryptoDepositExternalProps> = cell(
  ({ticker}) => {
    const {walletTicker, walletVersionValue} = FeatureCryptoDepositExternal.use(
      {
        ticker,
      }
    );

    React.useEffect(() => {
      DATA_LAYER.trackStrict('deposit-crypto-attempt', {
        ticker: walletTicker,
        // @ts-ignore
        provider: walletVersionValue,
      });
    }, [walletTicker, walletVersionValue]);

    return (
      <Box flexGrow={1}>
        <CryptoDepositExternalContent ticker={ticker} />
      </Box>
    );
  }
);
