import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {type BankCardTransferFormResources} from './types';
import {Form} from './Form';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  @observable
  public resources: BankCardTransferFormResources;

  public constructor(form: Form, resources: BankCardTransferFormResources) {
    this.resources = resources;
    this.form = form;
    this.instance = form.instance;
  }

  //#region ticker
  @computed
  get tickerFormatted() {
    return this.form.tickerFormatted;
  }

  @computed
  get tickerName() {
    return getCoinName(this.form.ticker);
  }
  //#endregion ticker

  //#region amounts
  @computed
  public get minAmountFormatted() {
    return formatByTicker(this.form.minAmount, this.form.ticker);
  }

  @computed
  public get maxAmountFormatted() {
    return formatByTicker(this.form.maxAmount, this.form.ticker);
  }
  //#endregion amounts

  //#region labels
  @computed
  public get allOrMaxText() {
    return this.form.card.balance > this.form.maxAmount.toNumber()
      ? i18n.t('surface.bank_cards.deposit.sum.max')
      : i18n.t('surface.bank_cards.deposit.sum.all');
  }
  //#endregion labels
}
