import * as React from 'react';
import {FlatList} from 'react-native';
import {ScrollableArea} from '@youtoken/ui.elements';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {StoryPreviewListItemWrapped} from './StoryPreviewListItemWrapped';
import {IUserStory, StoryPreviewListProps} from '../interfaces';

export const StoryPreviewList = ({
  data,
  handleStoryPreviewPress,
  unPressedBorderColor,
  pressedBorderColor,
  unPressedPreviewTextColor,
  pressedPreviewTextColor,
  previewSize,
  showText,
  previewTextStyle,
  previewImageStyle,
  previewWrapperStyle,
  previewFlatListProps,
}: StoryPreviewListProps) => {
  const isMobile = useIsMobile();

  const renderItemOld = ({item, index}: {item: IUserStory; index: number}) => {
    return (
      <StoryPreviewListItemWrapped
        previewSize={previewSize}
        handleStoryPreviewPress={handleStoryPreviewPress}
        unPressedBorderColor={unPressedBorderColor}
        pressedBorderColor={pressedBorderColor}
        unPressedPreviewTextColor={unPressedPreviewTextColor}
        pressedPreviewTextColor={pressedPreviewTextColor}
        item={item}
        index={index}
        showText={showText}
        previewTextStyle={previewTextStyle}
        previewImageStyle={previewImageStyle}
        previewWrapperStyle={previewWrapperStyle}
      />
    );
  };

  if (isMobile) {
    return (
      <FlatList
        keyExtractor={(_item, index) => index.toString()}
        data={data}
        horizontal
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        ListFooterComponent={Box}
        renderItem={renderItemOld}
        contentContainerStyle={{
          paddingHorizontal: 12,
        }}
        {...previewFlatListProps}
      />
    );
  }

  return (
    <ScrollableArea
      items={data}
      renderItem={renderItemOld}
      renderModalItem={renderItemOld}
      scrollingScale={5}
      wrapperMargin={0}
      wrapperPadding={0}
      collapsedAreaBoxProps={{backgroundColor: '$ui-background'}}
      isExpandRestricted
      arrowIconColor="$ui-background"
      arrowBoxProps={{
        borderRadius: 16,
      }}
      px={{tablet: 20, desktop: 0}}
    />
  );
};
