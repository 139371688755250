import {observable} from 'mobx';
import {View} from '../View';
import {type ExchangeAuthorizedFormArgs} from '../types';
import {type ExchangeAuthorizedNewFormResources} from './types';
import {Form} from './Form';

export class ExchangeFormState {
  @observable
  public form: Form;

  @observable
  public view: View;

  constructor(
    args: ExchangeAuthorizedFormArgs,
    resources: ExchangeAuthorizedNewFormResources
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
