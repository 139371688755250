import {serializable, list, custom, serialize, deserialize} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {LoanRegularV1, LoanRegularV2} from '../Loan';

export class LoansListRegularResponse {
  @serializable(number())
  currentPage!: number;

  @serializable(number())
  total!: number;

  @serializable(
    list(
      custom(
        value => {
          return serialize(value);
        },
        jsonValue => {
          if (jsonValue.version === 1) {
            return deserialize(LoanRegularV1, jsonValue);
          }

          return deserialize(LoanRegularV2, jsonValue);
        }
      )
    )
  )
  rows!: Array<LoanRegularV1 | LoanRegularV2>;
}
