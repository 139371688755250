import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

export const Row: React.FC<BoxProps> = ({children, ...boxProps}) => {
  return (
    <Box
      flexDirection="row"
      borderTopWidth={1}
      mt={-1}
      borderColor="$interactive-03"
      {...boxProps}
    >
      {children}
    </Box>
  );
};
