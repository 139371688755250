import React from 'react';
import {observer} from 'mobx-react';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface TriggerPriceProps {
  hodl: HODLItem;
}

export const TriggerPrice: React.FC<TriggerPriceProps> = observer(
  ({hodl: {triggerPriceFormatted}}) => {
    const {t} = useTranslation();

    return (
      <Box>
        <Box flexDirection="row" justifyContent="flex-end">
          <Text variant="$body-02" color="$text-02" mb={2}>
            {t('surface.hodls.item.trigger_price_label')}
          </Text>
        </Box>
        <Box flexDirection="row" justifyContent="flex-end">
          <Text variant="$body-02" color="$text-01">
            {triggerPriceFormatted}
          </Text>
        </Box>
      </Box>
    );
  }
);
