import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export interface LoanItemAlertPenaltyProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemAlertPenalty: React.FC<LoanItemAlertPenaltyProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    if (item.version === 2 && item.penalty) {
      return (
        <Box
          testID="LOAN_ITEM_ALERT_PENALTY"
          px={24}
          py={16}
          flexDirection="row"
          bg="$attention-02"
        >
          <Box mr={8}>
            <Icon name="warning" color="$attention-04" />
          </Box>
          <Box flex={1} justifyContent="center">
            <Text variant="$body-02" color="$attention-04">
              {t('surface.loans.item.fee_alert', {
                ticker: item.borrowedTickerFormatted,
              })}
            </Text>
          </Box>
        </Box>
      );
    }

    return null;
  });
