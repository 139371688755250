import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {LoansFeature} from '@youtoken/ui.legacy-components';
import {Header} from './Header';
import {List} from './List';
import {Empty} from './Empty';

export const Content: React.FC = cell(() => {
  const featureLoans = LoansFeature.use({mode: 'turbo'});

  return (
    <>
      <Header />
      {featureLoans.hasLists ? <List /> : <Empty />}
    </>
  );
});
