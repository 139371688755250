import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {ValidationMessage} from '@youtoken/ui.hodl-elements';

interface StopLossSectionProps extends BoxProps {}

export const StopLossSection: React.FC<StopLossSectionProps> = observer(
  ({...boxProps}) => {
    const {
      form: {updateStopLossValue, updateStopLossPercent, isShort},
      view: {
        maxLossIsPositive,
        maxLossFormatted,
        maxLossTickerFormatted,
        stopLossValue,
        stopLossPercent,
        stopLossPercentMin,
        stopLossPercentMax,
        stopLossLimitMessage,
        setStopLossLimit,
        slError,
        slErrorIsClickable,
      },
    } = useForm<CreateHODLFormClass>();
    const {t} = useTranslation();
    const testIdSection = 'STOP_LOSS';

    const validationComponent = React.useMemo(() => {
      return (
        <ValidationMessage
          validationMessage={stopLossLimitMessage}
          onValidationClick={setStopLossLimit}
          validationError={slError}
          isValidationClickable={slErrorIsClickable}
          testIdSection={testIdSection}
        />
      );
    }, [stopLossLimitMessage, setStopLossLimit, slError, slErrorIsClickable]);

    return (
      <Box {...boxProps}>
        <Box
          position="relative"
          zIndex={1}
          mb={6}
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Label mr={5}>{t('surface.hodls.adjust_tp.stop_loss')}</Label>
            <QuestionTooltip
              content={t('surface.hodls.adjust_tp.stop_loss_tooltip')}
            />
          </Box>
          <Badge
            alignSelf="center"
            variant={maxLossIsPositive ? 'success' : 'danger'}
            size="small"
            type="secondary"
            py={3}
          >
            <Text
              color={maxLossIsPositive ? '$success-01' : '$danger-01'}
              variant="$body-01"
              weight="regular"
            >
              <Text weight="bold">{maxLossFormatted}</Text>{' '}
              {maxLossTickerFormatted}
            </Text>
          </Badge>
        </Box>
        <AmountAndPercentInput
          amountLabel={t('surface.hodls.adjust_tp.level')}
          percentLabel={t('surface.hodls.adjust_tp.change')}
          amountValue={stopLossValue}
          percentValue={stopLossPercent}
          percentRangeMin={stopLossPercentMin}
          percentRangeMax={stopLossPercentMax}
          onChangeAmount={updateStopLossValue}
          onChangePercent={updateStopLossPercent}
          sliderKey="stopLoss"
          validationComponent={validationComponent}
          shouldReverseSlider={!isShort}
          testIdSection={testIdSection}
        />
      </Box>
    );
  }
);
