import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {Label, RadioField, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type DepositInvoiceForm} from '../../state/DepositInvoiceForm';

export const FieldPurpose: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {purposeValue, changePurpose},
  } = useForm<DepositInvoiceForm>();

  return (
    <Box mb={10}>
      <Box mb={8}>
        <Label>
          {t('surface.wallets.fiat_deposit_wire.purpose_of_deposit')}
        </Label>
      </Box>

      <RadioField
        value={purposeValue}
        items={[
          {
            value: 'savings',
            label: t(
              'surface.wallets.fiat_deposit_wire.purpose_of_deposit_savings'
            ),
            testID: 'FIAT_DEPOSIT_BANK_WIRE_PURPOSE_SAVINGS',
          },
          {
            value: 'loans',
            label: t(
              'surface.wallets.fiat_deposit_wire.purpose_of_deposit_loans'
            ),
            testID: 'FIAT_DEPOSIT_BANK_WIRE_PURPOSE_LOANS',
          },
          {
            value: 'personal',
            label: t(
              'surface.wallets.fiat_deposit_wire.purpose_of_deposit_personal'
            ),
            testID: 'FIAT_DEPOSIT_BANK_WIRE_PURPOSE_PERSONAL',
          },
        ]}
        onChange={changePurpose}
      />
    </Box>
  );
});
