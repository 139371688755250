import * as React from 'react';
import {FlatList} from 'react-native';
import {observer} from 'mobx-react';
import {Separator} from '@youtoken/ui.elements';
import {type FlatListComponentProps} from './types';

export const FlatListComponent: React.FC<FlatListComponentProps> = observer(
  ({items, keyExtractor, renderItem}) => {
    return (
      <FlatList
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={Separator}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="never"
      />
    );
  }
);
