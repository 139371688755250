import * as React from 'react';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {PaginationItem} from './PaginationItem';
import {getPaginationPages} from './getPaginationPages';

interface PaginationProps {
  activePage: number;
  totalPages: number;
  onPageChange?: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  activePage,
  totalPages,
  onPageChange,
}) => {
  const isMobile = useIsMobile();

  const pages = React.useMemo(
    () => getPaginationPages(totalPages, activePage, isMobile),
    [totalPages, activePage, isMobile]
  );

  const handleChoosePage = React.useCallback(
    (pageNumber: number) => {
      onPageChange?.(pageNumber);
    },
    [onPageChange]
  );

  return (
    <Box flexDirection="row" mx={-4}>
      {pages.map((page: number | string, index) => (
        <PaginationItem
          key={`${page}-${index}`}
          item={page}
          isActive={page === activePage}
          onPress={handleChoosePage}
        />
      ))}
    </Box>
  );
};
