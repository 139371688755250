import {Wallet} from '@youtoken/ui.resource-wallets';
import {type WalletComboboxItem} from '@youtoken/ui.ticker-and-amount-input';

export const walletToComboboxItem = ({
  ticker,
  tickerFormatted,
  amount,
  amountFormatted,
  tickerName,
}: Wallet): WalletComboboxItem => {
  return {
    ticker,
    tickerFormatted,
    amountFormatted,
    coinName: tickerName,
    hasAmount: amount?.gt(0),
    key: ticker,
    keywords: `${ticker} ${tickerName}`,
  };
};
