import {cell} from '@youtoken/ui.cell';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import React from 'react';
import {Helmet} from 'react-helmet';
import {HODLsInstrumentPageContent} from './elements/HODLsInstrumentPageContent';

export const HODLsInstrumentPage: React.FC = cell(() => {
  return (
    <InnerPageShell>
      <Helmet title="YouHodler | MultiHODL™" />
      <HODLsInstrumentPageContent />
    </InnerPageShell>
  );
});
