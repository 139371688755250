import {ChartResource} from '../../state/Chart';

export const timePeriods: ChartResource.TimePeriod[] = [
  '1h',
  '24h',
  '7d',
  '30d',
  '90d',
  '1y',
];
export const ticks: ChartResource.Tick[] = [
  '1m',
  '5m',
  '15m',
  '30m',
  '1h',
  '4h',
];

export const CONTROLS_HEIGHT = 28;
