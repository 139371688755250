import * as React from 'react';

export interface MethodData {
  title: string;
  description?: string;
  icon: React.ReactNode;
  testID?: string;
}

export enum PaymentSystems {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  IBAN = 'iban',
  SWIFT = 'swift',
  SEPA = 'sepa',
  ACCOUNT = 'account',
}
