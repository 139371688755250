import * as React from 'react';
import {
  Platform,
  TextInput,
  type TextStyle,
  type TextInputProps,
  type NativeSyntheticEvent,
  type TextInputFocusEventData,
  type StyleProp,
} from 'react-native';
import {normalizeAmount} from '@youtoken/ui.normalizers';
import {useActiveThemeType, useTheme} from '@youtoken/ui.primitives';

export interface CustomInputProps
  extends React.ComponentProps<typeof InputPure> {
  isNumeric?: boolean;
}

export const Input = React.forwardRef<TextInput, CustomInputProps>(
  ({isNumeric = false, keyboardType, onChangeText, ...otherProps}, ref) => {
    const numericKeyboard = ['number-pad', 'decimal-pad', 'numeric'];
    const acceptOnlyNumbers =
      isNumeric || numericKeyboard.includes(keyboardType || '');

    const handleChangeText = React.useCallback(
      (text: string) => {
        if (onChangeText) {
          //@ts-ignore
          onChangeText(acceptOnlyNumbers ? normalizeAmount(text) : text);
        }
      },
      [onChangeText, acceptOnlyNumbers]
    );

    return (
      <InputPure
        ref={ref}
        keyboardType={keyboardType}
        onChangeText={handleChangeText}
        {...otherProps}
      />
    );
  }
);

export const InputPure = React.forwardRef<TextInput, TextInputProps>(
  (
    {
      focusable = true,
      autoFocus,
      style: _style,
      onFocus,
      onBlur,
      onChangeText,
      ...otherProps
    },
    ref
  ) => {
    const themeType = useActiveThemeType();
    const [isFocus, setIsFocus] = React.useState(Boolean(autoFocus));

    const {colors} = useTheme();

    const style: StyleProp<TextStyle> = React.useMemo(
      () => [
        {
          width: '100%',
          height: Platform.select({
            web: 36,
            default: 44,
          }),
          paddingHorizontal: 15,
          paddingVertical: 0, // NOTE: fix for Android
          backgroundColor: colors['$transparent'],
          placeholderTextColor: colors['$ui-02'],
          borderWidth: 1,
          borderRadius: 6,
          borderColor: isFocus ? colors['$interactive-01'] : colors['$ui-02'],
          outlineWidth: 0,
          color: colors['$text-01'], // NOTE: fix for Android not accessible input
        },
        _style,
      ],
      [colors, isFocus, _style]
    );

    const handleFocus = React.useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        if (focusable) {
          setIsFocus(true);

          if (onFocus) {
            onFocus(event);
          }
        }
      },
      [focusable, setIsFocus, onFocus]
    );

    const handleBlur = React.useCallback(
      (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
        setIsFocus(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      [setIsFocus, onBlur]
    );

    return (
      <TextInput
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={style}
        autoFocus={autoFocus}
        onChangeText={onChangeText}
        keyboardAppearance={themeType}
        {...otherProps}
      />
    );
  }
);
