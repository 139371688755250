import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {type InstrumentItemRateProps} from '../types';

export const InstrumentItemRate: React.FC<
  InstrumentItemRateProps & BoxProps
> = ({
  rateFormatted,
  quoteTickerFormatted,
  diffValueFormatted,
  diffPercentFormatted,
  diffDirection,
  ...otherProps
}) => {
  return (
    <Box {...otherProps}>
      <Box flexDirection="row">
        <Text variant="$body-01">
          {rateFormatted} <Text color="$text-02">{quoteTickerFormatted}</Text>
        </Text>
      </Box>
      <Box flexDirection="row">
        <Text variant="$body-02">
          <Text color="$text-02">
            {diffValueFormatted} {quoteTickerFormatted}
          </Text>
          <Text color={diffDirection === 'up' ? '$success-01' : '$danger-01'}>
            {' '}
            {diffPercentFormatted}
          </Text>
        </Text>
      </Box>
    </Box>
  );
};
