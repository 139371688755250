import * as React from 'react';
import {useCountdown} from '@youtoken/ui.hooks';
import {Text, type TextProps} from '@youtoken/ui.primitives';

export type TimerProps = {
  timeLeft: number;
  onTimerEnd?: () => void;
} & TextProps;

export const Timer: React.FC<TimerProps> = ({
  timeLeft,
  onTimerEnd,
  ...textProps
}) => {
  const [millisecondsLeft, {start}] = useCountdown(timeLeft, 1000, () => {
    onTimerEnd?.();
  });

  React.useEffect(() => {
    start(timeLeft);
  }, [timeLeft]);

  return (
    <Text testID="TIMER" variant="$body-02" color="$text-01" {...textProps}>
      {formatMilliseconds(millisecondsLeft)}
    </Text>
  );
};

const padNumber = (num: number): string => {
  return num.toString().padStart(2, '0');
};

const formatMilliseconds = (ms: number): string => {
  const hours = Math.floor(ms / 1000 / 60 / 60) % 24;
  const days = Math.floor(ms / 1000 / 60 / 60 / 24);
  const minutes = Math.floor(ms / 1000 / 60) % 60;
  const seconds = Math.floor(ms / 1000) % 60;

  // reusable 'hh:mm:ss'
  const hms = `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;

  if (days > 0) {
    return `${days}d ${hms}`;
  }

  return hms;
};
