import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box} from '@youtoken/ui.primitives';
import {Banner, Trans, Link} from '@youtoken/ui.elements';

interface HighRiskBannerProps {
  country?: string;
}

export const HighRiskBanner: React.FC<HighRiskBannerProps> = observer(
  ({country}) => {
    const {residenceOrCountry} = AuthMeResource.use({});

    if ([country, residenceOrCountry].includes('GBR')) {
      return (
        <Box
          width="100%"
          // @ts-ignore
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <Banner>
            <Trans
              components={{
                Link: (
                  <Link
                    url="https://help.youhodler.com/en/articles/8455958-risk-disclosure"
                    fontWeight="bold"
                  />
                ),
              }}
              i18nKey="surface.common.high_risk_banner"
            />
          </Banner>
        </Box>
      );
    }

    return null;
  }
);
