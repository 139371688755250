import {action, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Form} from './Form';

export interface FeatureAntiPhishingFormArgs {}

export interface FeatureAntiPhishingFormResources {
  authMe: AuthMeResource;
}

export class FeatureAntiPhishingForm extends createFeature({
  getKey: ({}: FeatureAntiPhishingFormArgs) => `AntiPhishingForm`,
  getResources: ({}: FeatureAntiPhishingFormArgs) => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(
    args: FeatureAntiPhishingFormArgs,
    resources: FeatureAntiPhishingFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
  }
}
