import * as React from 'react';
import {Platform} from 'react-native';
import {Separator} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {
  type BoxProps,
  Box,
  TouchableBox,
  Heading,
} from '@youtoken/ui.primitives';

export interface HeaderBaseProps {
  showBack?: boolean;
  onPressBack?: () => void;
  shouldShowSeparator?: boolean;
}

export const HeaderBase: React.FC<HeaderBaseProps & BoxProps> = ({
  children,
  showBack = Platform.select({
    default: true,
    native: false,
  }),
  onPressBack,
  shouldShowSeparator,
  ...boxProps
}) => {
  // NOTE: This is needed for a correct work of the wizards back button to not let the event props be passed into the goBack fnc
  const handlePressBack = React.useCallback(() => {
    return onPressBack && onPressBack();
  }, []);

  return (
    <>
      <Box p={{default: 16, phone: 24}} {...boxProps}>
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          flexGrow={1}
          position="relative"
        >
          {showBack && onPressBack && (
            <TouchableBox
              justifyContent="center"
              alignItems="center"
              onPress={handlePressBack}
              mr={6}
              testID="HEADER_BACK_BUTTON"
            >
              <Icon name="chevron_left" size={24} color="$text-05" />
            </TouchableBox>
          )}
          <Box flex={1} pr={showBack && onPressBack ? 30 : 0}>
            <Heading variant="$heading-02-responsive" textAlign="center">
              {children}
            </Heading>
          </Box>
        </Box>
      </Box>
      {shouldShowSeparator && <Separator />}
    </>
  );
};
