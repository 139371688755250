import {CustomError} from '@youtoken/ui.errors';
import {__global__} from '../globalObject';

/** stores already reported warnings */
const alreadyReported: string[] = [];

const captureWaringSafely = (error: any) => {
  // this is a hack to access global registry safely without importing the package (circular dependencies)
  // so we kinda just trying to call capture from global object;

  // @ts-ignore
  __global__?.__SERVICE_REGISTRY__?.services?.SENTRY?.service?.capture?.(
    error,
    {
      source: 'ui.utils.warning',
    }
  );
};

/**
 * Report a warning to Sentry, makes sure that one warning (with same text) is not reported twice.;
 */
export const reportWarningOnce = (error: CustomError) => {
  if (alreadyReported.includes(error.message)) {
    return;
  }

  alreadyReported.push(error.message);

  captureWaringSafely(error);
};
