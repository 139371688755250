import * as React from 'react';
import {createGlobalStyle} from 'styled-components';
import {useTheme} from '@youtoken/ui.primitives';

/** overrides some default browser styles */
export const _Defaults = createGlobalStyle<{
  colors: ReturnType<typeof useTheme>['colors'];
}>`
  * {
    box-sizing: border-box;
  }
  
  html {
    height: 100%;
    min-height: 100%;
  }

  body {
    height: 100%;
    min-height: 100%;
    background-color: ${props => props.colors['$ui-background']};
    font-family: 'Ubuntu', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${props => props.colors['$text-01']};
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }

  body.ReactModal__Body--open {
    overflow: hidden;
  }
  
  #root {
    height: 100%;
    min-height: 100%;
  }

  b {
    font-weight: 700;
  }
`;

export const Defaults: React.FC = () => {
  const {colors} = useTheme();

  return <_Defaults colors={colors} />;
};
