import {merge} from 'lodash';
import {invariant} from '@youtoken/ui.utils';
import type {ScopeContext} from '@sentry/types';
import {ERROR_TYPE} from './ERROR_TYPE';

interface WithErrorType {
  __type: ERROR_TYPE;
}

export interface WithSentryProps {
  __sentry: Partial<ScopeContext>;
}

export interface WithCustomErrorProps extends WithSentryProps, WithErrorType {
  __isCustomError: boolean;
}

export class CustomError extends Error implements WithCustomErrorProps {
  cause?: Error | CustomError;
  __isCustomError = true;
  __type!: ERROR_TYPE;
  __sentry!: Partial<ScopeContext>;

  constructor(
    message: string,
    type: ERROR_TYPE = ERROR_TYPE.GENERAL_ERROR,
    sentryContext: Partial<ScopeContext> = {}
  ) {
    super(message);

    Object.setPrototypeOf(this, CustomError.prototype); // restore prototype chain

    this.name = `CustomError`;

    this.__type = type;
    this.__sentry = sentryContext;
  }
}

// wraps any generic Error and returns __new__ CustomError with same message, stacktrace etc.
export const createCustomErrorFromGenericError = (
  originalError: Error | CustomError,
  type: ERROR_TYPE = ERROR_TYPE.GENERAL_ERROR,
  sentry: Partial<ScopeContext> = {}
) => {
  invariant(
    originalError instanceof Error,
    `Cant create CustomError. Must provide an error object. Value: ${JSON.stringify(
      originalError
    )}`
  );

  const customError = new CustomError(
    originalError.message,
    (originalError as CustomError).__type || type,
    merge((originalError as CustomError)?.__sentry, sentry)
  );

  customError.stack = originalError.stack;
  customError.cause = originalError;

  return customError;
};
