import {ResourceInstance} from '../types';

/** base resource, track this.resource parent */
export class BaseResourceFeature<Args extends {}, Result extends any> {
  resource!: ResourceInstance<Args, Result>;

  featureName!: string;

  /** resource feature-level logger: `console.log('[resource][featureName] ....')` */
  logger(...args: any[]) {
    this.resource.logger(this.featureName, ...args);
  }

  onDestroy() {
    this.logger('onDestroy');
  }

  constructor(resource: ResourceInstance<Args, Result>) {
    this.resource = resource;
  }
}
