import Big, {BigSource, RoundingMode} from 'big.js';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';
import {warning} from '@youtoken/ui.utils';

export const getAmountByPercent = (
  initialAmount: BigSource,
  percent: BigSource,
  precision?: number,
  rm?: RoundingMode
): string => {
  if (
    initialAmount === '' ||
    percent === '' ||
    initialAmount === '-' ||
    percent === '-'
  ) {
    return '';
  }

  let amount = '';

  try {
    const delta = Big(initialAmount).times(Big(percent).div(100));
    const priceFromPercent = Big(initialAmount).plus(delta);

    amount = formatBigNumber(priceFromPercent, precision, true, rm);
  } catch (e) {
    warning(false, `cannot convert $percent to big number`, {
      value: percent,
    });
  }

  return amount;
};
