import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps} from '@youtoken/ui.primitives';
import {
  AccountLevelInfoItemShort,
  AccountLevelMinerUpToUSD,
  AccountLevelMinerUpToBTCBadge,
} from '..';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('Miner');
};

export interface AccountLevelInfoItemCloudMinerShortProps {
  level: LoyaltyLevel;
}

export const AccountLevelInfoItemCloudMinerShort: React.FC<
  AccountLevelInfoItemCloudMinerShortProps & BoxProps
> = observer(
  ({
    level: {
      details: {
        minerMaxMonthlyIncomeBTCFormatted,
        minerMaxMonthlyIncomeUSDFormatted,
      },
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <AccountLevelInfoItemShort
        testID="ACCOUNT_LEVEL_INFO_ITEM_CLOUD_MINER"
        icon="mining"
        title={t('surface.loyalty.cloud_miner')}
        description={
          <AccountLevelMinerUpToUSD
            amount={minerMaxMonthlyIncomeUSDFormatted}
          />
        }
        badge={
          <AccountLevelMinerUpToBTCBadge
            amount={minerMaxMonthlyIncomeBTCFormatted}
            variant="neutral"
          />
        }
        onPress={handlePress}
        {...boxProps}
      />
    );
  }
);
