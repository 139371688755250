import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../../../types';
import {type AccountLevelProgressBarDetailedPerksItemProps} from '../types';
import {AccountLevelProgressBarDetailedPerksInfoItem} from './Item';

export const AccountLevelProgressBarDetailedPerksInfoItemProgressPoints: React.FC<
  AccountLevelProgressBarDetailedPerksItemProps & BoxProps
> = observer(({variant, level, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <AccountLevelProgressBarDetailedPerksInfoItem
      testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_PERKS_INFO_ITEM_PROGRESS_POINTS"
      icon="volume"
      title={t('surface.loyalty.common.progress_points')}
      description={t('surface.loyalty.common.progress_points_description', {
        amount:
          variant === AccountLevelProgressBarVariant.HODL
            ? 1
            : level.conversion.conversionRequiredVolumeFormatted,
      })}
      value={
        variant === AccountLevelProgressBarVariant.HODL
          ? 1
          : level.conversion.conversionVolumeFormatted!
      }
      colors={{
        primary: '$interactive-01',
        secondary: '$interactive-02',
      }}
      {...boxProps}
    />
  );
});
