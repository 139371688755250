import * as React from 'react';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {SENTRY} from '@youtoken/ui.sentry';
import {PlayButton} from './PlayButton';
import {Cover} from './Cover';
import {type VideoPreviewProps} from './types';

export const VideoPreview: React.FC<VideoPreviewProps> = ({
  testID,
  cover,
  videoUrl,
}) => {
  const [hasInteracted, setHasInteracted] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const onLoad = React.useCallback(() => {
    setHasLoaded(true);
  }, []);

  const onError = React.useCallback((payload: any) => {
    setHasInteracted(false);
    SENTRY.captureMessage('Youtube player error', {
      source: 'youtube player',
      extra: {
        payload,
      },
    });
  }, []);

  const startPlaying = React.useCallback(() => {
    setHasInteracted(true);
  }, []);

  return (
    <TouchableBox
      testID={testID}
      backgroundColor="$interactive-02"
      aspectRatio={16 / 10}
      width={{
        default: '100%',
        phone: '100%',
        tablet: 480,
      }}
      borderRadius={6}
      justifyContent="center"
      alignItems="center"
      position="relative"
      onPress={startPlaying}
    >
      {hasInteracted && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          opacity={hasLoaded ? 1 : 0}
        >
          <iframe
            onLoad={onLoad}
            onError={onError}
            width="100%"
            height="100%"
            src={`${videoUrl}?autoplay=1&modestbranding=1`}
            frameBorder="0"
            allowFullScreen
            allow="autoplay"
          />
        </Box>
      )}

      {!hasLoaded && (
        <>
          <Cover source={cover} />
          <PlayButton
            onPress={startPlaying}
            isLoading={hasInteracted && !hasLoaded}
          />
        </>
      )}
    </TouchableBox>
  );
};
