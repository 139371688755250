import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {RewardsHistoryResponse} from './RewardsHistoryResponse';
import {localClientSettings} from '@youtoken/ui.resource-auth-me';

export interface RewardsHistoryResourceArgs {
  skip?: number;
  limit?: number;
}

export class RewardsHistoryResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: (args: RewardsHistoryResourceArgs) =>
    `RewardHistoryResource:${args.limit}`,
  getData: args => {
    return TRANSPORT.API.get(`/v3/rewards/history`, {
      params: {
        ...args,
        viewedDateTime: localClientSettings?.referralViewedDateTime,
      },
    }).then(res => {
      return deserialize(RewardsHistoryResponse, res.data);
    });
  },
}) {}
