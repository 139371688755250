import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  AddWalletsModal,
  type WalletsChooseTickerType,
} from '@youtoken/ui.surfaces-wallets';
import {modal} from '../../../stores';
import {Modal} from '../_common';

export const modalAddWalletsName = 'addWallets';

type ModalAddWalletsProps = {
  initialFilter?: string;
};

export const ModalAddWallets: React.FC<
  ModalAddWalletsProps & React.ComponentProps<typeof Modal>
> = cell(({initialFilter, ...modalProps}) => {
  const {opened, close} = modal;

  return (
    <AddWalletsModal
      isOpen={opened?.name === modalAddWalletsName}
      onClose={close}
      initialFilter={initialFilter as WalletsChooseTickerType}
      {...modalProps}
    />
  );
});
