import React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Platform, ScrollView} from 'react-native';
import {useWizard} from '@youtoken/ui.elements';
import {AlternativeDepositRequisites} from '@youtoken/ui.surface-bank-wire';
import {FiatDepositBankWireHeader} from '../../Header';
import {
  type FiatDepositBankWireRequisitesWizardNavigator,
  FiatDepositBankWireRequisitesWizardScreenName,
} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const FiatDepositBankWireWizardAlternativeRequisites: React.FC = () => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    FiatDepositBankWireRequisitesWizardNavigator,
    FiatDepositBankWireRequisitesWizardScreenName.ALTERNATIVE_REQUISITES
  >();
  const {bottom} = useSafeAreaInsets();

  return (
    <>
      {showHeader && (
        <FiatDepositBankWireHeader ticker={ticker} onPressBack={goBack} />
      )}

      <ScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          minHeight: '100%',
          paddingBottom: bottom,
        }}
      >
        <AlternativeDepositRequisites ticker={ticker} />
      </ScrollView>
    </>
  );
};
