import {SharedRouterService} from './SharedRouterService';
import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';

export type {Routes} from './Routes';

export const SHARED_ROUTER_SERVICE = __SERVICE_REGISTRY__.registerService(
  'SHARED_ROUTER',
  SharedRouterService,
  require('../package.json').version
);
