import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {InstrumentSelectorRate} from './InstrumentSelectorRate';

interface InstrumentElementProps {
  baseTicker: string;
  quoteTicker: string;
  value: string;
  highlighted?: boolean;
}

export const InstrumentElement: React.FC<InstrumentElementProps> =
  React.forwardRef(
    ({baseTicker, quoteTicker, value, highlighted, ...props}, ref) => {
      return (
        <Box
          ref={ref}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          px={16}
          py={10}
          bg={highlighted ? '$interactive-02' : '$ui-background'}
          {...props}
        >
          <Box flexDirection="row" alignItems="center" mr={16}>
            <LogoColored name={baseTicker as LogoColoredIconName} size={20} />
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              ml={8}
            >
              <Text weight="bold">{baseTicker.toUpperCase()}</Text>
              <Text color="$text-02">/{quoteTicker.toUpperCase()}</Text>
            </Box>
          </Box>
          <InstrumentSelectorRate
            baseTicker={baseTicker}
            quoteTicker={quoteTicker}
            flexShrink={0}
          />
        </Box>
      );
    }
  );
