import {observable} from 'mobx';
import type {UnlimintSPEIFormArgs, UnlimintSPEIFormResources} from '../types';
import {Form} from './Form';

export class UnlimintSPEIForm {
  @observable
  public form: Form;

  public constructor(
    args: UnlimintSPEIFormArgs,
    resources: UnlimintSPEIFormResources
  ) {
    this.form = new Form(args, resources);
  }
}
