import React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Back} from '@youtoken/ui.surface-loans';
import {LoanRegularCreateForm} from '@youtoken/ui.surface-loan-regular-create';

export const GetLoanPageContent: React.FC = cell(() => {
  const {t} = useTranslation();

  return (
    <>
      <Box px={{tablet: 0, default: 20}} mb={24}>
        <Back />
        <Heading variant="$heading-01-responsive" color="$text-01">
          {t('surface.loans.create_form.title')}
        </Heading>
      </Box>
      <LoanRegularCreateForm borderWidth={1} borderRadius={8} />
    </>
  );
});
