import {action} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {
  SavingsOverviewStatus,
  SavingsOverviewResponse,
} from './SavingsOverviewResponse';

export {SavingsOverviewStatus};

export class SavingsOverviewResource extends createResource({
  getKey: () => `SavingsOverview`,
  getData: () =>
    TRANSPORT.API.get('/v4/saving/overview').then(response => {
      return deserialize(SavingsOverviewResponse, response.data);
    }),
  skipRefreshOnVisible: false,
}) {
  @action
  setStatusWaiting = () => {
    this.data.status = SavingsOverviewStatus.WAITING;
  };

  @action
  refetchWithDelay = () => {
    setTimeout(this.refetch, 1000);
  };
}
