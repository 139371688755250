import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  ButtonsSwitcherElement,
  type ButtonsSwitcherItem,
} from '@youtoken/ui.elements';
import {PageSwitcherType, PageSwitcherValue} from '../../state';

export interface PageSwitcherProps extends BoxProps {
  activeValue: PageSwitcherType;
  onChoose: (value: PageSwitcherType) => void;
}

export const PageSwitcher: React.FC<PageSwitcherProps> = observer(
  ({activeValue, onChoose, ...containerProps}) => {
    const {t} = useTranslation();

    const items: ButtonsSwitcherItem[] = [
      {
        value: PageSwitcherValue.TRADING,
        title: t('surface.hodls.header.tabs.what_to_trade'),
        testID: 'ALL_COINS_BUTTON',
      },
      {
        value: PageSwitcherValue.PORTFOLIO,
        title: t('surface.hodls.header.tabs.portfolio'),
        testID: 'PORTFOLIO_BUTTON',
      },
    ];

    const handleChange = React.useCallback(
      (value: string) => {
        onChoose(value as PageSwitcherType);
      },
      [onChoose]
    );

    return (
      <Box
        height={32}
        width={{
          default: undefined,
          desktop: 335,
        }}
        {...containerProps}
      >
        <ButtonsSwitcherElement
          value={activeValue}
          items={items}
          onChange={handleChange}
        />
      </Box>
    );
  }
);
