import * as React from 'react';
import {UseComboboxGetItemPropsOptions} from 'downshift';
import {Box} from '@youtoken/ui.primitives';
import {ComboboxGroupTitle} from '@youtoken/ui.combobox';
import {WalletComboboxGroup, type WalletComboboxItem} from '../types';

export const List: React.FC<{
  filteredInputItems: WalletComboboxGroup[];
  ItemComponent: React.FC<{wallet: WalletComboboxItem}>;
  highlightedIndex: number;
  getItemProps: (options: UseComboboxGetItemPropsOptions<any>) => any;
}> = ({filteredInputItems, ItemComponent, highlightedIndex, getItemProps}) => {
  let itemIndex = 0;

  return (
    <Box>
      {filteredInputItems.map(({name, values}) => {
        return (
          <Box key={name}>
            {Boolean(name) && <ComboboxGroupTitle>{name}</ComboboxGroupTitle>}
            {values.map(item => {
              const index = itemIndex++;

              return (
                <ItemComponent
                  testID={`TICKER_COMBOBOX_ITEM_${item.tickerFormatted}`}
                  wallet={item}
                  highlighted={highlightedIndex == index}
                  bg={
                    highlightedIndex == index
                      ? '$interactive-02'
                      : '$ui-background'
                  }
                  // @ts-ignore
                  style={{
                    cursor: 'pointer',
                  }}
                  {...getItemProps({item, index})}
                  {...item}
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
