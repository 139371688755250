import * as React from 'react';
import {
  createRestyleComponent,
  opacity,
  OpacityProps,
  // visible,
  // VisibleProps,
  typography,
  TypographyProps,
  textShadow,
  TextShadowProps,
  createVariant,
  VariantProps,
} from '@shopify/restyle';
import {
  spacing,
  spacingShorthand,
  SpacingProps,
  SpacingShorthandProps,
  color,
  ColorProps,
} from '../../system';

import {Theme} from '../../system';

export type TextProps = ColorProps<Theme> &
  OpacityProps<Theme> &
  SpacingProps<Theme> &
  SpacingShorthandProps<Theme> &
  // VisibleProps<Theme> &
  TypographyProps<Theme> &
  TextShadowProps<Theme> &
  VariantProps<Theme, 'textVariants', 'variant'> &
  VariantProps<Theme, 'textWeightVariants', 'weight'> & {testID?: string};

/** patched up `createText` from `restyle` */
export const createRestyleText = <Component extends React.ComponentType<any>>(
  BaseComponent: Component
) => {
  return createRestyleComponent<
    React.PropsWithChildren<
      TextProps & Omit<React.ComponentProps<Component>, keyof TextProps>
    >,
    Theme
  >(
    [
      color,
      opacity,
      // `visible` restyle prop is setting `display: flex` by default which is breaking text rendering on web;
      // HOWEVER `visible` is set by default `createText` by restyle and this util is created from restyle as-is;
      // so for now i'll just leave it here commented out, just in case;
      // visible,
      typography,
      spacing,
      spacingShorthand,
      textShadow,
      createVariant({
        themeKey: 'textVariants',
        property: 'variant',
      }),
      createVariant({
        themeKey: 'textWeightVariants',
        property: 'weight',
      }),
    ],
    BaseComponent
  );
};
