import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {
  InputWithSlider,
  PredefinedAmountButtons,
  type WalletComboboxItem,
} from '@youtoken/ui.ticker-and-amount-input';
import {AgreementsList} from '@youtoken/ui.elements';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {cell} from '@youtoken/ui.cell';
import {ExtendTpSlForm} from '../../form';
import {
  HowExtendTpSlWorkTouchable,
  InfoTable,
  ExtendTpSlValidationMessage,
} from '../../components';

interface ExtendTpSlSurfaceProps {
  hodlId: string;
  onClose: () => void;
  onPressHowItWorks: () => void;
}

export const ExtendTpSlSurface: React.FC<ExtendTpSlSurfaceProps & BoxProps> =
  cell(({hodlId, onClose, onPressHowItWorks, ...boxProps}) => {
    const {t} = useTranslation();
    const {residenceOrCountry} = AuthMeResource.use({});
    const resources = useResources({
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      hodl: getResourceDescriptor(HODLByIdResource, {id: hodlId}),
      docs: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
    });

    const form = makeForm(
      () => new ExtendTpSlForm(resources, {hodlId, onSuccess: onClose})
    );

    const {
      form: {
        inputTickerWallet,
        inputTicker,
        setInputTicker,
        setInputAmount,
        setMaxAmount,
        inputAmount,
        maxInputAmount,
        amountListFormatted,
        inputAmountError,
        submitForm,
        isLoading,
        isHodlExpired,
        agreementItem,
      },
      view: {inputTickerFormatted},
    } = form;

    React.useEffect(() => {
      if (isHodlExpired) {
        onClose();
      }
    }, [isHodlExpired, onClose]);

    return (
      <Box {...boxProps}>
        <Form form={form}>
          <InputWithSlider
            wallets={[inputTickerWallet] as WalletComboboxItem[]}
            ticker={inputTicker}
            tickerFormatted={inputTickerFormatted}
            amount={inputAmount}
            maximumValue={maxInputAmount}
            setAllSourceToAmount={setMaxAmount}
            onChangeAmount={setInputAmount}
            onChangeTicker={setInputTicker}
            validationComponent={
              Boolean(inputAmountError) ? (
                <ExtendTpSlValidationMessage />
              ) : undefined
            }
            isTickerImmutable
            sliderKey="EXTEND-FORM-SLIDER"
          />

          <Box>
            <PredefinedAmountButtons
              amountList={amountListFormatted}
              mt={16}
              mb={8}
              currentAmount={inputAmount}
              onPressAmount={setInputAmount}
              buttonsSize="small"
            />
          </Box>

          <InfoTable my={16} />
          <HowExtendTpSlWorkTouchable onPress={onPressHowItWorks} />
          <AgreementsList
            beforeListText={t(
              'agreements.documents.without_checkbox.start_text',
              {
                call_to_action: t('surface.hodls.extend_tp.action_extend'),
              }
            )}
            items={agreementItem}
            mt={16}
          />

          <Box flexDirection="row" my={24}>
            <Button
              size="large"
              type="primary"
              flexGrow={1}
              loading={isLoading}
              onPress={submitForm}
            >
              {t('surface.hodls.extend_tp.action_extend')}
            </Button>
          </Box>
        </Form>
      </Box>
    );
  });
