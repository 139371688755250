import {autorun, computed, observable} from 'mobx';
import {now} from 'mobx-utils';
import {date, deserialize, object, primitive, serializable} from 'serializr';
import {
  bigNumber,
  bigNumberNullable,
  chartData,
  ChartDataItem,
  duration,
  number,
} from '@youtoken/ui.utils-serializr';
import Big from 'big.js';
import {
  differenceInHours,
  format as formatDate,
  formatDistanceStrict,
  intervalToDuration,
  add,
  differenceInSeconds,
} from '@youtoken/ui.date-fns';
import {
  formatByTickerLoan,
  formatPercentTillPrecision,
} from '@youtoken/ui.formatting-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {getAprBySettlementPeriod, getSettlementPeriodFormatted} from '../utils';
import {LoanRegularIncrease} from './LoanIncrease';
import {LoanCloseNow} from './LoanCloseNow';

export enum LoanRegularStatus {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  OPEN = 'OPEN',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
}

export class LoanRegularBase {
  /** Version of loan. Possible values 1 or 2 */
  version!: number;

  @observable
  protected now!: number;

  constructor() {
    autorun(() => {
      this.now = now(1000);
    });
  }

  /** Loan Id */
  @serializable(primitive())
  id!: string;

  /** Status of loan */
  @serializable(primitive())
  status!: LoanRegularStatus;

  /** Collateral ticker */
  @serializable(primitive())
  collateralTicker!: string;

  /** Borrowed Ticker */
  @serializable(primitive())
  borrowedTicker!: string;

  /** Collateral amount */
  @serializable(bigNumber())
  collateralAmount!: Big;

  /** Borrowed amount */
  @serializable(bigNumber())
  borrowedAmount!: Big;

  /** Overdraft amount */
  @serializable(bigNumber())
  overdraftAmount!: Big;

  /** Cost of the loan. For v1 full cost in percent. For v2 annual percent rate */
  @serializable(bigNumber())
  apr!: Big;

  /** Loan to value */
  @serializable(bigNumber())
  ltv!: Big;

  /** Rate at loan creating */
  @serializable(bigNumber())
  initialPrice!: Big;

  /** Closed price */
  @serializable(bigNumberNullable())
  closedPrice!: Big | null;

  /** Take profit price */
  @serializable(bigNumberNullable())
  tp!: Big | null;

  /** Margin call price */
  @serializable(bigNumber())
  mcPrice!: Big;

  /** Minimum FTP value */
  @serializable(bigNumberNullable())
  minTP!: Big | null;

  /** Maximum FTP value */
  @serializable(bigNumberNullable())
  maxTP!: Big | null;

  /** Company. Possible values "YOUHODLER" and "NAUMARD" */
  @serializable(primitive())
  company!: 'NAUMARD' | 'YOUHODLER';

  /** Date when loan was started */
  @serializable(date())
  startedAt!: Date | null;

  /** Date when loan will be finished */
  @serializable(date())
  finishAt!: Date | null;

  /** Date when loan was finished */
  @serializable(date())
  finishedAt!: Date | null;

  /** Chart Data */
  @serializable(chartData())
  chartData!: ChartDataItem[];

  /** Is the price of a loan already makes 2/3 ways to MC */
  @serializable(primitive())
  isClientMC!: boolean;

  /** Length of loan for v1. Days passed from loan start for v2 */
  @serializable(number())
  days!: number;

  /** Is increase possible and increase data */
  @serializable(object(LoanRegularIncrease))
  increase!: LoanRegularIncrease | null;

  /** Is Decrease possible */
  @serializable(primitive())
  decrease!: boolean;

  /** Is closeNow possible and close now data */
  @serializable(object(LoanCloseNow))
  closeNow!: LoanCloseNow | null;

  //

  @computed
  get chartDataInteractive() {
    const {getExchangeRate} =
      RatesResource.__DANGEROUSLY__getInstanceStatically({});

    const chartDataItem = deserialize(ChartDataItem, {
      date: new Date(this.now),
      rate: getExchangeRate(this.collateralTicker, this.borrowedTicker),
    });

    return [...this.chartData, chartDataItem];
  }

  @computed
  get isOpening() {
    return [LoanRegularStatus.PROCESSING, LoanRegularStatus.PENDING].includes(
      this.status
    );
  }

  @computed
  get isOpened() {
    return [LoanRegularStatus.OPEN].includes(this.status);
  }

  @computed
  get isClosing() {
    return [LoanRegularStatus.CLOSING].includes(this.status);
  }

  @computed
  get isClosed() {
    return [
      LoanRegularStatus.CLOSED,
      LoanRegularStatus.DECLINED,
      LoanRegularStatus.CANCELED,
    ].includes(this.status);
  }

  @computed
  get isPendingOrOpen() {
    return [LoanRegularStatus.PENDING, LoanRegularStatus.OPEN].includes(
      this.status
    );
  }

  @computed
  get collateralTickerFormatted() {
    return this.collateralTicker.toUpperCase();
  }

  @computed
  get borrowedTickerFormatted() {
    return this.borrowedTicker.toUpperCase();
  }

  @computed
  get collateralAmountFormatted() {
    return formatByTickerLoan(this.collateralAmount, this.collateralTicker);
  }

  @computed
  get borrowedAmountFormatted() {
    return formatByTickerLoan(this.borrowedAmount, this.borrowedTicker);
  }

  @computed
  get aprFormatted() {
    return formatPercentTillPrecision(this.apr, 4);
  }

  @computed
  get ltvFormatted() {
    return formatPercentTillPrecision(this.ltv, 4);
  }

  @computed
  get overdraftAmountFormatted() {
    return formatByTickerLoan(this.overdraftAmount, this.borrowedTicker);
  }

  @computed
  get marginCallFormatted() {
    return formatByTickerLoan(this.mcPrice, this.borrowedTicker);
  }

  @computed
  get endDate() {
    return this.finishedAt || this.finishAt;
  }

  @computed
  get repayUntilFormatted() {
    return this.endDate && formatDate(this.endDate, 'dd, MMM, yyyy, HH:mm z');
  }

  @computed
  get finishAtFormatted() {
    return (
      this.finishAt &&
      formatDistanceStrict(this.finishAt, this.now, {
        addSuffix: true,
      })
    );
  }

  @computed
  get finishedAtFormatted() {
    return (
      this.finishedAt &&
      formatDistanceStrict(this.finishedAt, this.now, {
        addSuffix: true,
      })
    );
  }

  @computed
  get agreementUrl() {
    return `/v2/docks/loan/agreement/${this.id}`;
  }

  @computed
  get agreementAvaliable() {
    return [
      LoanRegularStatus.OPEN,
      LoanRegularStatus.CLOSING,
      LoanRegularStatus.CLOSED,
    ].includes(this.status);
  }

  @computed
  get allowIncrease() {
    return Boolean(this.increase);
  }

  @computed
  get allowDecrease() {
    return Boolean(this.decrease);
  }

  @computed
  get allowCloseNow() {
    return Boolean(this.closeNow);
  }

  @computed
  get allowSetClosePrice() {
    return Boolean(this.isOpened && this.minTP?.gte(1) && this.maxTP?.gte(1));
  }
}

export class LoanRegularV1 extends LoanRegularBase {
  @serializable(primitive())
  version!: 1;

  @serializable(number())
  overdueDays!: number;

  @serializable(bigNumber())
  overdueCollateralPercent!: Big;
}

export class LoanRegularV2 extends LoanRegularBase {
  @serializable(primitive())
  version!: 2;

  /** Annual percent rate if a fee would be taken from overdraft */
  @serializable(bigNumber())
  penaltyAPR!: Big;

  /** How many fees already taken from user */
  @serializable(bigNumber())
  totalSettlementFeeAmount!: Big;

  /** Value of fee in borrowed ticker */
  @serializable(bigNumber())
  settlementFeeAmount!: Big;

  /** value of fee in borrowed ticker if it would be taken from overdraft */
  @serializable(bigNumber())
  penaltySettlementFeeAmount!: Big;

  /** Next charge date */
  @serializable(date())
  chargeAt!: Date;

  /** Charge interval */
  @serializable(duration())
  settlementPeriod!: Duration;

  /** Next payment would be from overdraft instead of wallet */
  @serializable(primitive())
  penalty!: boolean;

  @computed
  get chargeAtFixed() {
    if (differenceInSeconds(this.chargeAt, this.now) > 0) {
      return this.chargeAt;
    }

    return add(this.chargeAt, this.settlementPeriod);
  }

  @computed
  get aprBySettlementPeriod() {
    return getAprBySettlementPeriod(this.apr, this.settlementPeriod);
  }

  @computed
  get penaltyAprBySettlementPeriod() {
    return getAprBySettlementPeriod(this.penaltyAPR, this.settlementPeriod);
  }

  @computed
  public get settlementPeriodFormatted() {
    return getSettlementPeriodFormatted(this.settlementPeriod);
  }

  @computed
  get aprBySettlementPeriodFormatted() {
    return formatPercentTillPrecision(this.aprBySettlementPeriod, 4);
  }

  @computed
  get penaltyAprBySettlementPeriodFormatted() {
    return formatPercentTillPrecision(this.penaltyAprBySettlementPeriod, 4);
  }

  @computed
  get totalSettlementFeeAmountFormatted() {
    return formatByTickerLoan(
      this.totalSettlementFeeAmount,
      this.borrowedTicker
    );
  }

  @computed
  get totalSettlementFeeDaysFormatted() {
    return this.days;
  }

  @computed
  get settlementFeeAmountFormatted() {
    return formatByTickerLoan(this.settlementFeeAmount, this.borrowedTicker);
  }

  @computed
  get startedAtFormatted() {
    return (
      this.startedAt && formatDate(this.startedAt, 'dd, MMM, yyyy, HH:mm z')
    );
  }

  @computed
  get chargeAtFormatted() {
    const {
      days = 0,
      hours = 0,
      minutes = 0,
    } = intervalToDuration({
      start: this.now,
      // We don't show seconds, need add 1 min. ({seconds: 59}) to duration
      // Result: {minutes: 0, seconds: 59} => {minutes: 1, seconds: 59},
      end: add(this.chargeAtFixed, {
        seconds: 59,
      }),
    });

    return `${padNumber(days * 24 + hours)}:${padNumber(minutes)}`;
  }

  @computed
  get chargeAtColor() {
    if (differenceInHours(this.chargeAtFixed, this.now) < 4) {
      return '$danger-01';
    }

    return undefined;
  }
}

const padNumber = (number: number) => {
  return number.toString().padStart(2, '0');
};
