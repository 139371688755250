import {AxiosInstance} from 'axios';
import {observable, computed} from 'mobx';
import {Socket} from 'socket.io-client';
import {invariant} from '@youtoken/ui.utils';
import EventEmitter from 'eventemitter3';

/** transport layer provider as service */
export class TransportService {
  private _API?: AxiosInstance;

  @observable.ref
  private _SOCKET?: Socket;

  public events = new EventEmitter<'set'>();

  constructor() {}

  /** get socket instance, will throw if not set before */
  @computed
  public get SOCKET(): Socket {
    invariant(
      this._SOCKET,
      `SOCKET in Transport Service was not set, api is ${this._SOCKET}`
    );

    return this._SOCKET;
  }

  /** get axios instance, will throw if not set before */
  public get API(): AxiosInstance {
    invariant(
      this._API,
      `API in Transport Service was not set, api is ${this._API}`
    );

    return this._API;
  }

  /** socket instance setter */
  public set SOCKET(socket: Socket) {
    this._SOCKET = socket;
    this.events.emit('set', 'socket', socket);
  }

  /** axios instance setter */
  public set API(api: AxiosInstance) {
    this._API = api;
    this.events.emit('set', 'api', api);
  }
}
