import {type IconName, iconSet} from '../__generated/IconSectorsLogo';
import {createIconComponent} from '../createIcon';

export {iconSet};
export type {IconName};

export const IconSectorsLogo = createIconComponent<IconName, typeof iconSet>({
  componentName: 'Icon.SectorsLogo',
  iconSet: iconSet,
  defaultSize: 256,
});
