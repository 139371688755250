import React from 'react';
import {PageSwitcher, PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {Box, useIsDesktop} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';
import {HODLsInfo, TraderQuizPromo} from '@youtoken/ui.surface-hodl-positions';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

export const PageMenu: React.FC<{page: PageSwitcherValue}> = observer(
  ({page}) => {
    const {push} = RouterResource.use({});
    const isDesktop = useIsDesktop();

    const handleChangePage = React.useCallback(
      (value: string) => {
        push(`/multihodl/${value}`);
      },
      [push]
    );

    return (
      <Box>
        <HODLsInfo px={{desktop: 0, default: 20}} />
        <TraderQuizPromo mt={20} testID="TRADING_QUIZ_BUTTON" />
        <PageSwitcher
          activeValue={page}
          onChoose={handleChangePage}
          my={24}
          px={isDesktop ? 0 : 20}
        />
      </Box>
    );
  }
);
