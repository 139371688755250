import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {
  Box,
  TouchableBox,
  useIsMobile,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {LoansListItemFeatureArgs, LoansListItemFeature} from '../../../state';
import {BriefActionsProps, BriefActions} from '..';
import {DetailedGeneral, DetailedAlert, BriefChart} from '..';

export interface BriefProps extends Omit<TouchableBoxProps, 'onPress'> {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onBringToFront?: (args: LoansListItemFeatureArgs) => void;
  onPress?: (args: LoansListItemFeatureArgs) => void;
  onPressPay?: (args: LoansListItemFeatureArgs) => void;
  onPressCancel?: (args: LoansListItemFeatureArgs) => void;
  onPressCloseNow?: (args: LoansListItemFeatureArgs) => void;
  onPressIncrease?: BriefActionsProps['onPressIncrease'];
  onPressDecrease?: BriefActionsProps['onPressDecrease'];
  onPressReopen?: BriefActionsProps['onPressReopen'];
  onPressSetClosePrice?: BriefActionsProps['onPressSetClosePrice'];
}

const BriefDesktop: React.FC<BriefProps> = observer(
  ({
    style,
    mode,
    id,
    onPress,
    onPressPay,
    onPressCancel,
    onPressCloseNow,
    onPressIncrease,
    onPressDecrease,
    onPressReopen,
    onPressSetClosePrice,
    onBringToFront,
    ...rest
  }) => {
    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const handlePress = React.useCallback(() => {
      onPress?.({id: loan.id!, mode: loan.mode});
    }, [onPress, loan]);

    const handleChangeOpenedAdditionalActions = React.useCallback(
      (opened: boolean) => {
        if (opened && onBringToFront) {
          onBringToFront?.({id: loan.id!, mode: loan.mode});
        }
      },
      [onBringToFront, loan]
    );

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <TouchableBox
        style={style}
        justifyContent="center"
        position="relative"
        width="100%"
        bg={loan.isOpening || loan.isClosing ? '$ui-01' : '$ui-background'}
        onPress={handlePress}
        {...rest}
      >
        <Box position="absolute" left={0} right={0} top={0} bottom={0} />
        <Box
          zIndex={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={20}
        >
          <DetailedGeneral
            mode={loan.mode}
            id={loan.id}
            flexGrow={1}
            flexShrink={1}
          />
          <BriefActions
            mode={loan.mode}
            id={loan.id}
            onChangeOpenedAdditionalActions={
              handleChangeOpenedAdditionalActions
            }
            onPressPay={onPressPay}
            onPressCancel={onPressCancel}
            onPressCloseNow={onPressCloseNow}
            onPressIncrease={onPressIncrease}
            onPressDecrease={onPressDecrease}
            onPressReopen={onPressReopen}
            onPressSetClosePrice={onPressSetClosePrice}
          />
        </Box>
        <BriefChart mode={loan.mode} id={loan.id} />
        <DetailedAlert mode={loan.mode} id={loan.id} />
      </TouchableBox>
    );
  }
);

const BriefMobile: React.FC<BriefProps> = observer(
  ({
    mode: _mode,
    id: _id,
    onPress,
    onPressPay: _onPressPay,
    onPressCancel: _onPressCancel,
    onPressCloseNow: _onPressCloseNow,
    onPressIncrease: _onPressIncrease,
    onPressDecrease: _onPressDecrease,
    onPressReopen: _onPressReopen,
    onPressSetClosePrice: _onPressSetClosePrice,
    ...rest
  }) => {
    const loan = LoansListItemFeature.use({
      mode: _mode,
      id: _id,
    });

    const handlePress = React.useCallback(() => {
      onPress?.({id: loan.id!, mode: loan.mode});
    }, [onPress, loan]);

    if (!loan.id) {
      console.error(`Loan id=${_id} not found`);
      return null;
    }

    return (
      <TouchableBox
        justifyContent="center"
        position="relative"
        width="100%"
        bg={loan.isOpening || loan.isClosing ? '$ui-01' : '$ui-background'}
        onPress={handlePress}
        {...rest}
      >
        <DetailedGeneral
          mode={loan.mode}
          id={loan.id}
          flexGrow={1}
          flexShrink={1}
          p={20}
        />
        <BriefChart mode={loan.mode} id={loan.id} />
        <DetailedAlert mode={loan.mode} id={loan.id} />
      </TouchableBox>
    );
  }
);

const BriefWeb: React.FC<BriefProps> = props => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <BriefMobile {...props} />;
  }

  return <BriefDesktop {...props} />;
};

export const Brief = Platform.select({
  web: BriefWeb,
  default: BriefMobile,
});
