import * as React from 'react';
import {observer} from 'mobx-react';
import {SavingsOverviewStatus} from '@youtoken/ui.resource-savings';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SavingsSelectedAssetsFeature} from '../../Feature';
import {SavingsTotalEarned} from '../../../../components';

export const SavingsSelectedAssetsTotalEarned: React.FC<BoxProps> = observer(
  boxProps => {
    const {status, totalEarnedQuoteTicker, totalEarnedInQuoteTickerFormatted} =
      SavingsSelectedAssetsFeature.use({});

    return (
      <SavingsTotalEarned
        ticker={totalEarnedQuoteTicker}
        amountFormatted={totalEarnedInQuoteTickerFormatted}
        amountProps={
          status === SavingsOverviewStatus.CLAIMED ? {color: '$success-01'} : {}
        }
        {...boxProps}
      />
    );
  }
);
