import * as React from 'react';
import {Field as FormikField, FieldProps} from 'formik';
import {EmailTextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage} from '@youtoken/ui.form-elements';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';

export const EmailInput: React.FC<{testID?: string}> = ({
  testID = 'EMAIL_INPUT',
}) => {
  return (
    <FormikField name="email">
      {({
        field: {name},
        form: {errors, touched, values, setFieldValue, setFieldTouched},
      }: FieldProps) => {
        return (
          <>
            <EmailTextInput
              hasError={Boolean(errors[name]) && Boolean(touched[name])}
              size="large"
              value={values[name]}
              testID={testID}
              onChangeText={text => setFieldValue(name, text)}
              onBlur={() => setFieldTouched(name)}
            />
            <FieldErrorMessage
              visible={Boolean(errors[name]) && Boolean(touched[name])}
              placement="left"
            >
              <ValidationMessageComponent error={errors[name] as string} />
            </FieldErrorMessage>
          </>
        );
      }}
    </FormikField>
  );
};
