import * as React from 'react';
import {SubmitButton} from '@youtoken/ui.form-elements';
import {Button} from '@youtoken/ui.buttons';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const Submit: React.FC<React.ComponentProps<typeof Button>> = observer(
  ({...props}) => {
    const {t} = useTranslation();

    return (
      <SubmitButton color="interactive" {...props}>
        {t('surface.wallets.fiat_deposit_card.pay')}
      </SubmitButton>
    );
  }
);
