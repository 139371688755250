import * as React from 'react';
import {DefaultSuspenseFallback, cell} from '@youtoken/ui.cell';
import {Box, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BankCardsFeature} from '../../state/BankCardsFeature';
import {CardStack} from './CardStack';
import {AccountBalance} from './AccountBalance';

export const BankCardsWidget: React.FC<BoxProps> = cell(
  boxProps => {
    const {isWidgetAvailable, cardsForWidget} = BankCardsFeature.use({});

    const handlePress = React.useCallback(
      () => SHARED_ROUTER_SERVICE.navigate('BankCards', {}),
      []
    );

    if (!isWidgetAvailable) {
      return null;
    }

    return (
      <TouchableBox
        flexDirection="row"
        justifyContent="space-between"
        backgroundColor="$fill-01"
        borderRadius={12}
        p={16}
        pr={12}
        onPress={handlePress}
        testID="BANKCARDS_WIDGET"
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center">
          <CardStack cards={cardsForWidget} />
          <AccountBalance ml={12} />
        </Box>

        <Box alignItems="center" justifyContent="center" pl={8}>
          <Icon name="chevron_right" size={20} color="$text-05" />
        </Box>
      </TouchableBox>
    );
  },
  {
    displayName: 'BankCardsWidget',
    SuspenseFallback: () => null,
    ErrorFallback: () => null,
  }
);
