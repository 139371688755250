import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {ReferralsAndBonusesSummary} from '../../components';

export const ReferralsAndBonusesSummaryV3Smart = observer(() => {
  const {t} = useTranslation();

  const {
    authMe: {
      products: {
        rewardsChallenge: {available: rewardsChallengeAvailable},
      },
    },
    rewards: {
      data: {totalEarnedUSD, maxEarningsUSD, newsCount},
    },
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    rewards: getResourceDescriptor(RewardsResource, {}),
  });

  return (
    <ReferralsAndBonusesSummary
      title={t(
        rewardsChallengeAvailable
          ? 'surface.referral_program.rewards.referrals_and_bonuses'
          : 'surface.referral_program.referrals_widget.referrals_and_bonuses'
      )}
      amount={rewardsChallengeAvailable ? totalEarnedUSD : undefined}
      maxAmount={rewardsChallengeAvailable ? maxEarningsUSD : undefined}
      newsCount={newsCount}
    />
  );
});
