import React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RewardsListResource} from '@youtoken/ui.resources-rewards';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Bonus} from '../../../../components';

export const BonusesList = cell(
  () => {
    const {updateRewardViewedDateTime} = AuthMeResource.use({});
    const {data} = RewardsListResource.use({});

    React.useEffect(() => {
      updateRewardViewedDateTime();
    }, []);

    return (
      <Box
        flexDirection={{tablet: 'row', default: 'column'}}
        flexWrap={{tablet: 'wrap', default: 'nowrap'}}
        marginHorizontal={{tablet: -12, default: 0}}
      >
        {data.items.map(item => (
          <Box
            mb={{tablet: 24, default: 16}}
            key={item.id}
            flexBasis={{tablet: '50%', default: undefined}}
            paddingHorizontal={{tablet: 12, default: 0}}
          >
            <Bonus
              type={item.type}
              flex={{tablet: 1, default: undefined}}
              title={item.title}
              description={item.description}
              amount={item.personalRewardUSD}
              id={item.id}
              dealsTotal={item.requiredSteps}
              dealsCompleted={item.completedSteps}
              bonusEarned={item.complete}
            />
          </Box>
        ))}
      </Box>
    );
  },
  {
    SuspenseFallback: () => (
      <Box height={200} justifyContent="center" alignItems="center">
        <ActivityIndicator />
      </Box>
    ),
  }
);
