import React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

interface ReferralsWidgetUserIconProps {
  active?: Boolean;
}

export const ReferralsWidgetUserIcon: React.FC<
  ReferralsWidgetUserIconProps & BoxProps
> = ({active, ...boxProps}) => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      width={32}
      height={32}
      backgroundColor={active ? '$text-04' : '$interactive-01'}
      borderWidth={1}
      borderColor={active ? '$interactive-01' : '$text-04'}
      borderStyle={active ? 'solid' : 'dashed'}
      borderRadius={16}
      {...boxProps}
    >
      <Icon
        name="profile"
        size={16}
        color={active ? '$interactive-01' : '$text-04'}
      />
    </Box>
  );
};
