import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const BlockexplorerIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2a8 8 0 110 16 8 8 0 010-16zm-.75 8.75l-2.726.001c.184 2.805 1.379 4.976 2.727 5.58L9.25 10.75zm4.226.001l-2.726-.001v5.58c1.348-.604 2.542-2.775 2.726-5.579zm-8.454 0h-1.48a6.497 6.497 0 002.725 4.571c-.69-1.238-1.144-2.823-1.245-4.571zm11.435 0h-1.479c-.101 1.748-.554 3.333-1.245 4.571a6.499 6.499 0 002.724-4.572zM6.267 4.677l-.035.025a6.496 6.496 0 00-2.69 4.548h1.48c.101-1.749.554-3.334 1.245-4.573zm.257 4.572H9.25v-5.58c-1.347.604-2.542 2.776-2.726 5.58zm4.226-5.58v5.58h2.726c-.184-2.804-1.378-4.976-2.726-5.58zm2.983 1.008l.08.146c.645 1.216 1.068 2.746 1.165 4.427h1.48a6.497 6.497 0 00-2.725-4.573z"
          fill={color}
        />
      </Svg>
    );
  }
);
