import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {SavingsOverviewResource} from '@youtoken/ui.resource-savings';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {SavingsProgressWithLevels, SavingsUpgradeLevel} from '../../components';
import {invariant} from '@youtoken/ui.utils';

const handlePress = () => {
  DATA_LAYER.trackStrict('loyalty-level-upgrade-attempt', {});

  SHARED_ROUTER_SERVICE.navigate('AccountLevels');
};

export const SavingsWidgetWithLevelsSmart: React.FC<BoxProps> = observer(
  props => {
    const {
      data: {
        level,
        quoteTicker,
        progressBarAmountInQuoteTicker,
        progressBarLimitInQuoteTicker,
      },
    } = SavingsOverviewResource.use({});

    invariant(
      level,
      'Cannot get level from SavingsOverviewResource',
      {
        level,
      },
      {
        level,
      }
    );

    const {getLevel} = LoyaltyResource.use({});

    const levelNext = getLevel(level + 1);

    const hasNextLevel = Boolean(levelNext);

    return (
      <Box {...props}>
        <SavingsProgressWithLevels
          level={level}
          levelNext={levelNext?.level}
          ticker={quoteTicker}
          amount={progressBarAmountInQuoteTicker}
          limit={progressBarLimitInQuoteTicker}
          limitNext={levelNext?.saving.limit}
          mb={hasNextLevel ? 24 : 0}
        />
        {hasNextLevel && (
          <SavingsUpgradeLevel level={levelNext!.level} onPress={handlePress} />
        )}
      </Box>
    );
  }
);
