import * as React from 'react';
import {NotificationsResource} from '@youtoken/ui.resource-notifications';
import {cell} from '@youtoken/ui.cell';
import {ModalNotification} from '../../components';

interface ModalNotificationSmartProps {
  notificationId: string;
  onClose?: () => void;
}

export const ModalNotificationSmart: React.FC<ModalNotificationSmartProps> =
  cell(({notificationId, onClose}) => {
    const {getById, markAsShowed, close} = NotificationsResource.use({});

    const notification = getById(notificationId);

    const handleClose = React.useCallback(
      (notificationId: string) => {
        close(notificationId);
        onClose?.();
      },
      [close, onClose]
    );

    if (!notification) {
      return null;
    }

    return (
      <ModalNotification
        data={notification}
        onShow={markAsShowed}
        onClose={handleClose}
      />
    );
  });
