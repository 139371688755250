import * as React from 'react';
import {type TFuncKey, useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';
import {HodlOperationType} from '@youtoken/ui.resource-hodl';

const dataByStatus: Record<
  HodlOperationType,
  {title: TFuncKey; icon: BaseIconName}
> = {
  OPENED: {
    title: 'surface.hodls.item.details_table.created_or_started.started',
    icon: 'check',
  },
  PENDING: {title: 'surface.hodls.portfolio.pending_title', icon: 'check'},
  EXTEND_SL: {title: 'surface.hodls.history.extend_title', icon: 'extend'},
  CLOSED: {title: 'surface.hodls.portfolio.closed_title', icon: 'close_now'},
  CANCELED: {title: 'surface.hodls.history.canceled_title', icon: 'close_now'},
};

interface HeaderSectionsProps {
  status: HodlOperationType;
  date: string;
}

export const HeaderSections: React.FC<HeaderSectionsProps> = observer(
  ({status, date}) => {
    const {t} = useTranslation();

    return (
      <Box flexDirection="row" justifyContent="space-between">
        <Box flexDirection="row">
          <Icon name={dataByStatus[status].icon}></Icon>
          <Heading ml={8} variant="$heading-03">
            {t(dataByStatus[status].title)}
          </Heading>
        </Box>
        <Text ml={20} variant="$body-02" color="$text-02" textAlign="right">
          {date}
        </Text>
      </Box>
    );
  }
);
