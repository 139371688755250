import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {Tabs} from '@youtoken/ui.tabs';
import {type CryptoDepositProps} from '../../../types';
import {FeatureCryptoDepositLedger} from '../../state';

export const CryptoDepositLedgerMethods: React.FC<CryptoDepositProps> =
  observer(({ticker}) => {
    const {methods, methodIndex, setMethod} = FeatureCryptoDepositLedger.use({
      ticker,
    });

    if (methods.length < 2) {
      return null;
    }

    return (
      <Box px={24} mt={24}>
        <Tabs
          size="small"
          activeIndex={methodIndex}
          onChange={setMethod}
          tabs={methods}
        />
      </Box>
    );
  });
