import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {Box, Heading} from '@youtoken/ui.primitives';
import {ReferralsHowItWorksSmartProps} from '../types';
import {
  type ReferralsHowItWorksItemProps,
  ReferralsHowItWorksItem,
} from './Item';

export const ReferralsHowItWorksV3Smart: React.FC<ReferralsHowItWorksSmartProps> =
  observer(({showShareRefCode}) => {
    const {t} = useTranslation();

    const {
      data: {maxEarningsPercentFormatted},
    } = RewardsResource.use({});

    const items: ReferralsHowItWorksItemProps[] = React.useMemo(() => {
      return [
        {
          icon: 'link',
          title: t(
            'surface.referral_program.how_it_works.invite_friends.title'
          ),
          description: t(
            'surface.referral_program.how_it_works.invite_friends.description'
          ),
        },
        {
          icon: 'first_trade',
          title: t(
            'surface.referral_program.how_it_works.friends_activity.title',
            {percent: maxEarningsPercentFormatted}
          ),
          description: t(
            'surface.referral_program.how_it_works.friends_activity.description'
          ),
        },
      ];
    }, [maxEarningsPercentFormatted, t]);

    return (
      <Box flex={1} p={{default: 16, phone: 24}}>
        <Heading mb={24} textAlign="center">
          {t('surface.referral_program.how_it_works.title')}
        </Heading>
        <Box my={-8}>
          {items.map(({icon, title, description}, index) => {
            return (
              <ReferralsHowItWorksItem
                key={index}
                icon={icon}
                title={title}
                description={description}
              />
            );
          })}
        </Box>
      </Box>
    );
  });
