import {action, observable, computed} from 'mobx';

type Opened = {
  name: string;
  props?: any;
};

export class Modal {
  @observable
  openedStack: Array<Opened> = [];

  @computed
  get opened() {
    return this.openedStack[this.openedStack.length - 1];
  }

  // NOTE: Open a new modal window, with removing the previous one (if it exists).
  @action
  open = (name: Opened['name'], props?: Opened['props']) => {
    this.openedStack = [{name, props}];
  };

  // NOTE: Open a new modal window, with saving state the previous one (if it exists).
  @action
  push = (name: Opened['name'], props?: Opened['props']) => {
    this.openedStack = [...this.openedStack, {name, props}];
  };

  @action
  close = () => {
    this.openedStack = this.openedStack.slice(0, -1);
  };
}
