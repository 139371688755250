import * as WebBrowser from 'expo-web-browser';

export const openBrowserAsync: typeof WebBrowser.openBrowserAsync = url => {
  return WebBrowser.openBrowserAsync(url, {
    // It is needed to prevent Chrome Custom Tab from closing
    // when swithcing to another app to copy requisites and back
    createTask: false,
    windowName: '_self',
  });
};
