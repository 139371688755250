import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Link} from '@youtoken/ui.elements';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {useResource} from '@youtoken/ui.data-storage';

export const YouhodlerServices: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();
  const {youhodler, legalInfo} = useResource(CompanyResource, {});

  return (
    <Box {...props}>
      <Text variant="$body-02" color="$text-02">
        {t('surface.disclosure_form.content.services_offered', {
          company: youhodler?.name,
          address: youhodler?.address,
        })}{' '}
        (
        <Link
          url={legalInfo.youhodlerSroPolyreg.link}
          variant="$body-02"
          color="$text-01"
          mb={4}
        >
          {legalInfo.youhodlerSroPolyreg.name}
        </Link>
        ).
      </Text>
    </Box>
  );
});
