import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {
  type AccountLevelAllBenefitsProps,
  AccountLevelAllBenefits,
} from '../../../smarts';

export const AccountLevelAllBenefitsModal: React.FC<
  AccountLevelAllBenefitsProps & React.ComponentProps<typeof Modal>
> = cell(({level, ...modalProps}) => {
  return (
    <Modal testID="ACCOUNT_LEVEL_ALL_BENEFITS_MODAL" pb={0} {...modalProps}>
      <AccountLevelAllBenefits level={level} />
    </Modal>
  );
});
