import {observable, reaction, action} from 'mobx';
import {Form} from './Form';
import {Calculated} from './Calculated';
import {
  IFeatureTurboCreateFormArgs,
  IFeatureTurboCreateFormResources,
} from '../types';
import {IDisposer} from 'mobx-utils';

import {RatesResource} from '@youtoken/ui.resource-rates';
import {LoanTariffsResource} from '@youtoken/ui.resource-loan-tariffs';
import {debounce} from 'lodash';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {DocsResource} from '@youtoken/ui.resource-documents';

export class FeatureTurboCreateForm extends createFeature({
  getKey: ({}: IFeatureTurboCreateFormArgs) => `FeatureTurboCreateForm`,
  getResources: ({}: IFeatureTurboCreateFormArgs) => {
    const {legalEntity} = AuthMeResource.use({});

    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      tariffsResource: getResourceDescriptor(LoanTariffsResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: legalEntity,
      }),
    };
  },
}) {
  @observable
  public form!: Form;

  @observable
  public calculated!: Calculated;

  @observable
  public disposers: IDisposer[];

  constructor(
    args: IFeatureTurboCreateFormArgs,
    resources: IFeatureTurboCreateFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
    this.calculated = new Calculated(this.form);
    this.disposers = [
      reaction(
        () => {
          const {
            collateralTicker,
            borrowedTicker,
            collateralAmount,
            borrowedAmount,
            term,
            leverage,
          } = this.form.instance.values();

          return JSON.stringify({
            collateralTicker,
            borrowedTicker,
            collateralAmount,
            borrowedAmount,
            term,
            leverage,
          });
        },
        debounce(() => {
          this.calculated.load();
        }, 300),
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action reset = () => {
    this.form.instance.reset();
  };

  onDestroy() {
    super.onDestroy();
    this.disposers.forEach(d => d());
  }
}
