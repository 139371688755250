import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {
  type BankCardType,
  type OrderBankCardType,
  type BankCardSize,
} from '../../types';
import {
  BankCardVirtualImage,
  BankCardVirtualOrderImage,
  BankCardPlasticImage,
  BankCardPlasticOrderImage,
} from './assets';
import {getSize} from '../../utils/getSize';

const images = {
  virtual: BankCardVirtualImage,
  virtual_order: BankCardVirtualOrderImage,
  plastic: BankCardPlasticImage,
  plastic_order: BankCardPlasticOrderImage,
};

interface BaseBankCardProps {
  type: BankCardType | OrderBankCardType;
  size: BankCardSize;
  testID?: string;
}

export const BaseBankCard: React.FC<BaseBankCardProps> = ({
  type,
  size,
  testID = 'BANK_CARD',
  children,
}) => {
  const {width, height, borderRadius} = getSize(size);

  const ImageComponent = React.useMemo(() => images[type], [type]);

  return (
    <Box
      width={width}
      height={height}
      borderRadius={borderRadius}
      overflow="hidden"
      testID={testID}
    >
      {size !== 'tiny' && (
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <ImageComponent width="100%" height="100%" />
        </Box>
      )}

      {children}
    </Box>
  );
};
