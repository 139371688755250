import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {FeatureLoanCreateLandingForm} from '../../LoanRegularCreateLandingForm/state';
import {Link} from '@youtoken/ui.elements';
import {Section} from '../Section';
import {INTERCOM_SERVICE} from '@youtoken/ui.service-intercom';

export interface TermDetailsProps extends BoxProps {}

export const TermDetails: React.FC<TermDetailsProps> = observer(props => {
  const {t} = useTranslation();

  const {
    conversionTicker,
    pdlPercent,
    pdlPrice,
    apr,
    penaltyAPR,
    settlementPeriod,
    interest,
    interestMultiplier,
    interestAmountFormatted,
    maxDurationValue,
    maxDurationLabel,
  } = useForm<FeatureLoanCreateForm | FeatureLoanCreateLandingForm>();

  const Separator = (
    <Box
      width={{
        tablet: 1,
        default: '100%',
      }}
      height={{
        tablet: 'auto',
        default: 1,
      }}
      bg="$ui-01"
      mx={{
        tablet: '$screen-default-offset',
        default: 0,
      }}
      my={{
        tablet: 0,
        default: '$screen-default-offset',
      }}
    />
  );

  return (
    <Section
      flexDirection={{
        tablet: 'row',
        default: 'column',
      }}
      {...props}
    >
      {/* INTEREST BLOCK START */}
      <Box
        flexGrow={1}
        flexShrink={1}
        flexBasis={{
          tablet: 1,
        }}
      >
        <Text variant="$body-01-high-accent" mb={6}>
          {t('surface.loans.create_form.interest_v2')}
        </Text>
        <Text variant="$body-01" color="$text-02" mb={6}>
          <Trans
            t={t}
            i18nKey={'surface.loans.create_form.interest_v2_value'}
            components={{
              Strong: <Heading variant="$heading-02" color="$text-01" />,
            }}
            values={{
              apr,
              amount: interestAmountFormatted,
              ticker: conversionTicker,
              period: settlementPeriod,
            }}
          />
        </Text>
        <Text variant="$body-02">
          <Trans
            t={t}
            i18nKey={'surface.loans.create_form.interest_description_v2'}
            components={{
              Strong: <Text variant="$body-02-high-accent" />,
              Link: (
                <Link
                  variant="$body-02"
                  color="$text-05"
                  onPress={() => {
                    INTERCOM_SERVICE.displayArticle('2669424');
                  }}
                />
              ),
            }}
            values={{
              apr,
              penaltyAPR,
              period: settlementPeriod,
              percent: interest,
              multiplier: interestMultiplier,
            }}
          />
        </Text>
      </Box>
      {/* INTEREST BLOCK END */}
      {Separator}
      {/* DURATION BLOCK START */}
      <Box
        flexGrow={1}
        flexShrink={1}
        flexBasis={{
          tablet: 1,
        }}
      >
        <Text variant="$body-01-high-accent" mb={6}>
          {t('surface.loans.create_form.max_duration')}
        </Text>
        <Text variant="$body-01" color="$text-02" mb={6}>
          <Trans
            t={t}
            i18nKey={'surface.loans.create_form.max_duration_value'}
            components={{
              Strong: <Heading variant="$heading-02" color="$text-01" />,
            }}
            values={{
              value: maxDurationValue,
              label: maxDurationLabel,
            }}
          />
        </Text>
        <Text variant="$body-02">
          <Trans
            t={t}
            i18nKey={
              'surface.loans.create_form.max_duration_description.no_date'
            }
            components={{
              Link: (
                <Link
                  variant="$body-02"
                  color="$text-05"
                  onPress={() => {
                    INTERCOM_SERVICE.displayArticle('2838468');
                  }}
                />
              ),
            }}
            values={{
              value: maxDurationValue,
              label: maxDurationLabel,
            }}
          />
        </Text>
      </Box>
      {/* DURATION BLOCK END */}
      {Separator}
      {/* PDL BLOCK START */}
      <Box
        flexGrow={1}
        flexShrink={1}
        flexBasis={{
          tablet: 1,
        }}
      >
        <Text variant="$body-01-high-accent" mb={6}>
          {t('surface.loans.create_form.pdl')}
        </Text>
        <Text variant="$body-01" color="$text-02" mb={6}>
          <Heading variant="$heading-02" color="$text-01">
            {pdlPrice}
          </Heading>{' '}
          {conversionTicker}
        </Text>
        <Text variant="$body-02">
          <Trans
            t={t}
            i18nKey={'surface.loans.create_form.pdl_description'}
            components={{
              Strong: <Text variant="$body-02-high-accent" />,
              Link: (
                <Link
                  variant="$body-02"
                  color="$text-05"
                  onPress={() => {
                    INTERCOM_SERVICE.displayArticle('3206184');
                  }}
                />
              ),
            }}
            values={{
              pdl: pdlPercent,
            }}
          />
        </Text>
      </Box>
      {/* PDL BLOCK END */}
    </Section>
  );
});
