import * as React from 'react';
import {TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const ToggleIcon: React.FC<{
  toggleMenu: () => void;
  isOpen: boolean;
  disabled?: boolean;
  closeButtonTestID?: string;
  variant?: 'default' | 'ghost';
}> = ({
  toggleMenu,
  isOpen,
  disabled,
  closeButtonTestID,
  variant = 'default',
}) => {
  return (
    <TouchableBox
      activeOpacity={0.8}
      accessible
      px={variant === 'ghost' ? 0 : 16}
      justifyContent="center"
      alignItems="center"
      height="100%"
      disabled={disabled}
      onPress={disabled ? undefined : toggleMenu}
      testID={isOpen ? closeButtonTestID : undefined}
    >
      <Icon
        name={isOpen ? 'close' : 'expand'}
        size={20}
        accessible={false}
        color={disabled ? '$ui-04' : '$text-01'}
      />
    </TouchableBox>
  );
};
