import * as React from 'react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {HeaderProps} from './types';
import {HeaderWeb} from './HeaderWeb';
import {HeaderLedger} from './HeaderLedger';

export const Header: React.FC<HeaderProps> = React.memo(props => {
  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <HeaderLedger {...props} />;
  }

  return <HeaderWeb {...props} />;
});
