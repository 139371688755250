import * as React from 'react';
import {Text} from '../Text';
import {THeadingTokens} from '../../system/themes/fonts/TOKENS';

const variantToLevel = (variant: keyof THeadingTokens): number => {
  return (
    {
      '$heading-01': 1,
      '$heading-02': 2,
      '$heading-03': 3,
      '$heading-04': 4,
      '$heading-01-responsive': 1,
      '$heading-02-responsive': 2,
    } as {[key in keyof THeadingTokens]: number}
  )[variant];
};

export const Heading: React.FC<
  Omit<React.ComponentProps<typeof Text>, 'variant'> & {
    variant?: keyof THeadingTokens;
    level?: number;
  }
> = ({children, variant = '$heading-02', level, ...props}) => {
  return (
    <Text
      variant={variant}
      accessible
      accessibilityRole="header"
      // @ts-ignore reason: react-native-web specific
      accessibilityLevel={level ?? variantToLevel(variant)}
      {...props}
    >
      {children}
    </Text>
  );
};
