import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';
import {useForm} from '../../../../../components';
import {FeatureLoanDecreaseForm} from '../../state';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';

export const Ticker: React.FC<BoxProps> = observer(props => {
  const {
    form: {ticker},
  } = useForm<FeatureLoanDecreaseForm>();

  if (!ticker) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      px={10}
      mr={5}
      position="absolute"
      top={0}
      bottom={0}
      {...props}
    >
      <Box mr={5}>
        <LogoColored size={20} name={ticker.ticker as LogoColoredIconName} />
      </Box>
      <Text>{ticker.ticker.toUpperCase()}</Text>
    </Box>
  );
});
