import * as React from 'react';
import styled from 'styled-components';
import {useTheme} from '@youtoken/ui.primitives';
import {FooterContent} from './Content';

const FooterWrapper = styled.footer<{
  colors: ReturnType<typeof useTheme>['colors'];
}>`
  grid-area: footer;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  min-width: 320px;
  border-top: 1px solid ${props => props.colors['$ui-02']};
  z-index: -1;
`;

export const FooterWeb: React.FC = ({children}) => {
  const {colors} = useTheme();

  return (
    <FooterWrapper colors={colors}>
      <FooterContent />
      {children}
    </FooterWrapper>
  );
};
