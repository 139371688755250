import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const MastercardPlainIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M6.15 4.225a5.775 5.775 0 103.252 10.548 6.508 6.508 0 01-2.077-4.774c0-1.884.8-3.582 2.076-4.773A5.748 5.748 0 006.15 4.225z"
          fill={color}
        />
        <Path
          d="M10 5.695a5.76 5.76 0 011.925 4.304A5.76 5.76 0 0110 14.303 5.76 5.76 0 018.075 10 5.76 5.76 0 0110 5.695z"
          fill={color}
        />
        <Path
          d="M10.6 5.226a5.775 5.775 0 11-.001 9.547 6.508 6.508 0 002.076-4.774 6.51 6.51 0 00-2.076-4.773z"
          fill={color}
        />
      </Svg>
    );
  }
);
