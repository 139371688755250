import * as React from 'react';
import {FlatList} from 'react-native';
import {observer} from 'mobx-react';
import {Text, Box, BoxProps} from '@youtoken/ui.primitives';
import {SecurityActivityListItem} from './SecurityActivityListItem';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SecurityActivityData} from '@youtoken/ui.state-security';

const TableHeader: React.FC<BoxProps> = () => {
  const {t} = useTranslation();

  return (
    <Box
      flexDirection="row"
      px="$screen-default-offset"
      py={8}
      borderBottomColor="$ui-01"
      borderBottomWidth={1}
    >
      <Box flexBasis="46%" pr={{default: 8, tablet: 16}}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.security_activity.activity_table.device')}
        </Text>
      </Box>
      <Box flexBasis="32%" pr={{default: 8, tablet: 16}}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.security_activity.activity_table.action')}
        </Text>
      </Box>
      <Box flexBasis="22%" alignItems="flex-end">
        <Text variant="$body-02" color="$text-02" textAlign="right">
          {t('surface.security_activity.activity_table.time')}
        </Text>
      </Box>
    </Box>
  );
};

export interface SecurityActivityListProps extends BoxProps {
  items: SecurityActivityData[];
  checkStatus?: () => Promise<any>;
}

export const SecurityActivityList: React.FC<SecurityActivityListProps> =
  observer(({checkStatus, items, ...boxProps}) => {
    const styleProps = React.useMemo<BoxProps>(() => {
      return {
        borderTopWidth: 1,
        borderBottomWidth: {default: 0, tablet: 1},
        borderLeftWidth: {default: 0, tablet: 1},
        borderRightWidth: {default: 0, tablet: 1},
        borderRadius: {default: 0, tablet: 10},
      };
    }, []);

    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const onRefresh = React.useCallback(() => {
      if (checkStatus) {
        setIsRefreshing(true);
        checkStatus().finally(() => setIsRefreshing(false));
      }
    }, [checkStatus]);

    const renderItem = React.useCallback(
      ({item, index}: {item: SecurityActivityData; index: number}) => {
        return (
          <SecurityActivityListItem
            key={item.id}
            item={item}
            isLast={index === items.length - 1}
            index={index}
          />
        );
      },
      [items]
    );

    return (
      <Box borderColor="$ui-01" {...styleProps} {...boxProps}>
        <TableHeader />

        <FlatList
          data={items}
          renderItem={renderItem}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
        />
      </Box>
    );
  });
