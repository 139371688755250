import React from 'react';
import {Modal} from '../_common';
import {FirstLoginModalCell} from '@youtoken/ui.surface-trusted-devices';

interface ModalFirstLoginProps extends React.ComponentProps<typeof Modal> {
  deviceId: string;
}

export const ModalFirstLogin: React.FC<ModalFirstLoginProps> = ({deviceId}) => {
  return (
    <Modal maxWidth={444}>
      <FirstLoginModalCell id={deviceId} />
    </Modal>
  );
};
