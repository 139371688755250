import * as React from 'react';
import {SUPPORT} from '@youtoken/ui.support';
import {BaseErrorFallback} from './BaseFallback';
import {Handler} from './Handler';
import {ErrorFallbackProps} from './types';

export const DefaultErrorFallback: React.FC<ErrorFallbackProps> = props => {
  return (
    <BaseErrorFallback {...props}>
      {props.retriesCount > 6 && (
        <Handler onPress={() => SUPPORT.reloadApp}>Reload App</Handler>
      )}
      {props.retriesCount <= 6 && (
        <Handler onPress={props.dismissError}>Retry</Handler>
      )}
    </BaseErrorFallback>
  );
};
