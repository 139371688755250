import {serializable, date, list, object} from 'serializr';
import {observable} from 'mobx';
import {number} from './number';

export class ChartDataItem {
  @serializable(number())
  @observable
  rate!: number;

  @serializable(date())
  @observable
  date!: Date;
}

export const chartData = () => list(object(ChartDataItem));
