import * as React from 'react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {FooterWeb} from './FooterWeb';

/** __Application footer__ */
export const Footer: React.FC = () => {
  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return null;
  }

  return <FooterWeb />;
};
