import {observable} from 'mobx';
import {Form} from './Form';
import {View} from './View';

export interface SurveyItem {
  value: string;
  question?: string;
  options?: SurveyItem[];
}

export interface SurveyFormArgs {
  data: SurveyItem;
}

export class SurveyForm {
  @observable
  form: Form;

  @observable
  view: View;

  constructor({data}: SurveyFormArgs) {
    this.form = new Form();
    this.view = new View({form: this.form, data});
  }
}
