const range = (start: number, end: number) => {
  let length = end - start + 1;

  return Array.from({length}, (_, idx) => idx + start);
};

export const getPaginationPages = (
  totalPages: number,
  activePage: number,
  isMobile: boolean
) => {
  const centerItemsQuantity = isMobile ? 4 : 5;
  const tailItemsQuantity = isMobile ? 5 : 7;
  const _activePage = totalPages >= activePage ? activePage : totalPages;

  if (_activePage < tailItemsQuantity) {
    // case 1: number of pages is less than the tail items quantity - no dots
    if (totalPages <= tailItemsQuantity + 2) {
      return range(1, totalPages);
    }
    // case 2: active page in left range, right dots added
    else {
      const leftRange = range(1, tailItemsQuantity);
      return [...leftRange, '...', totalPages];
    }
  } else {
    const firstNumber = _activePage - Math.floor(centerItemsQuantity / 2);
    const needDotsBeforeLast =
      totalPages > _activePage + centerItemsQuantity / 2 + 2;

    // case 3: active page in center range, both dots added
    if (needDotsBeforeLast) {
      const lastNumber = firstNumber + centerItemsQuantity - 1;
      const centerRange = range(firstNumber, lastNumber);
      return [1, '...', ...centerRange, '...', totalPages];
    }
    // case 4: active page in right range, left dots added
    else {
      const rightRange = range(firstNumber, totalPages);
      return [1, '...', ...rightRange];
    }
  }
};
