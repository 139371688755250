import * as React from 'react';
import {FeatureLoanIncreaseFormArgs, FeatureLoanIncreaseForm} from './state';
import {Alert} from '@youtoken/ui.elements';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {Form} from '..';
import {TableRow} from '../..';
import {Ticker} from './components/Ticker';
import {AgreementsList, Link} from '@youtoken/ui.elements';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {Button} from '@youtoken/ui.buttons';

export interface LoanIncreaseFormProps extends FeatureLoanIncreaseFormArgs {
  onUnMount?: () => void;
}

export const LoanIncreaseForm: React.FC<LoanIncreaseFormProps> = cell(
  ({id, onUnMount}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    const feature = FeatureLoanIncreaseForm.use({
      id,
    });

    React.useEffect(() => {
      feature.restore();

      return () => {
        onUnMount && onUnMount();
        feature.reset();
      };
    }, []);

    if (!feature.restored) {
      return <DefaultSuspenseFallback />;
    }

    if (!feature.loan) {
      return null;
    }

    return (
      <Form form={feature}>
        <Box flexGrow={1}>
          <Alert type="information">
            <Text variant="$body-02" color="$text-05">
              <Trans
                t={t}
                components={{
                  Strong: <Text variant="$body-02-high-accent" />,
                  Link: (
                    <Link
                      url="https://help.youhodler.com/en/articles/3061180-how-to-increase-ltv"
                      variant="$body-02-medium-accent"
                    />
                  ),
                }}
                values={{
                  amount: feature.loanIncreaseMaxAmount,
                  ticker: feature.ticker,
                  ltv: feature.loanIncreaseLTV,
                }}
                i18nKey="surface.loans.increase_form.alert"
              />
            </Text>
          </Alert>
          <Box p={24}>
            <Ticker width={isMobile ? '100%' : '50%'} mb={20} zIndex={1} />
            <Box opacity={feature.calculated.ready ? 1 : 0.7}>
              <Text weight="bold" mb={15}>
                {t('surface.loans.increase_form.table')}
              </Text>
              <Box>
                <TableRow
                  leftText={t('surface.loans.increase_form.table.new_ltv')}
                >
                  <Text variant="$body-02-high-accent" color="$text-02">
                    {feature.newLTV}%
                  </Text>
                </TableRow>
                <TableRow
                  leftText={t('surface.loans.increase_form.table.new_pdl')}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.newPDLAmount}</Text>{' '}
                    {feature.tickerCalculated}
                    {feature.newPDLAmount && (
                      <>
                        {' '}
                        <Text weight="bold" color="$danger-01">
                          {feature.newPDL}%
                        </Text>
                      </>
                    )}
                  </Text>
                </TableRow>
                <TableRow
                  leftText={t(
                    'surface.loans.increase_form.table.additional_cash'
                  )}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.additionalCash}</Text>{' '}
                    {feature.tickerCalculated}
                  </Text>
                </TableRow>
                {feature.loan.version === 1 && (
                  <TableRow
                    leftText={t('surface.loans.increase_form.table.interest')}
                  >
                    <Text variant="$body-02-high-accent" color="$text-02">
                      {feature.interest}%
                    </Text>
                  </TableRow>
                )}
                <TableRow
                  leftText={t('surface.loans.increase_form.table.service_fee')}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.serviceFee}</Text>{' '}
                    {feature.tickerCalculated}
                  </Text>
                </TableRow>
                <TableRow
                  leftText={t(
                    'surface.loans.increase_form.table.repayment_increase'
                  )}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.repaymentIncrease}</Text>{' '}
                    {feature.tickerCalculated}
                  </Text>
                </TableRow>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box p={24}>
            <AgreementsList
              beforeListText={t(
                'agreements.documents.without_checkbox.start_text',
                {
                  call_to_action: t('surface.loans.increase_form.submit'),
                }
              )}
              items={feature.agreements}
            />
          </Box>
          <Box
            bg="$ui-01"
            p={24}
            borderBottomLeftRadius={isMobile ? 0 : 10}
            borderBottomRightRadius={isMobile ? 0 : 10}
          >
            <Box
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={isMobile ? 'stretch' : 'center'}
              mb={20}
            >
              <Box
                mb={isMobile ? 20 : 0}
                opacity={feature.calculated.data ? 1 : 0}
              >
                <Text
                  variant="$body-01"
                  lineHeight={28}
                  color="$text-02"
                  textAlign={isMobile ? 'center' : 'left'}
                  mb={5}
                >
                  <Trans
                    t={t}
                    i18nKey="surface.loans.increase_form.to_get"
                    components={{
                      Strong: <Text variant="$heading-01" color="$text-01" />,
                    }}
                    values={{
                      amount: feature.amountWithoutCommission,
                      ticker: feature.tickerCalculated,
                    }}
                  />
                </Text>
                <Text
                  variant="$body-02"
                  color="$text-02"
                  textAlign={isMobile ? 'center' : 'left'}
                  mb={5}
                >
                  <Trans
                    t={t}
                    components={{
                      Strong: <Text weight="bold" />,
                    }}
                    values={{
                      amount: feature.repaymentIncrease,
                      ticker: feature.tickerCalculated,
                    }}
                    i18nKey="surface.loans.increase_form.repayment_increase"
                  />
                </Text>
              </Box>
              <Button
                size="large"
                loading={
                  feature.form.instance.submitting || feature.calculated.loading
                }
                onPress={feature.form.instance.onSubmit}
              >
                {t('surface.loans.increase_form.submit')}
              </Button>
            </Box>
            <Box>
              <Text variant="$body-02-high-accent" color="$text-02">
                {t('surface.loans.increase_form.risk_warning')}
              </Text>
              <Text variant="$body-02" color="$text-02">
                {t('surface.loans.increase_form.risk_warning_description')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Form>
    );
  }
);
