import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text, TouchableText} from '@youtoken/ui.primitives';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Trans} from '@youtoken/ui.elements';
import {FlipHODLForm} from '../../form';

interface AmountAndMultiplySectionProps extends BoxProps {}

export const AmountSection: React.FC<AmountAndMultiplySectionProps> = observer(
  boxProps => {
    const {t} = useTranslation();
    const {
      form: {
        inputAmount,
        inputTicker,
        setInputTicker,
        instance,
        allSourceAmount,
        setAllSourceToAmount,
      },
      view: {sourceWallets, inputTickerFormatted},
    } = useForm<FlipHODLForm>();

    return (
      <Box {...boxProps} zIndex={10} flexGrow={1}>
        <Box zIndex={50} mb={16}>
          <Box flexDirection="row" justifyContent="space-between" mb={8}>
            <Label>{t('surface.hodls.hodl_form.amount_label')}</Label>
            <TouchableText
              variant="$body-02"
              color="$text-05"
              onPress={setAllSourceToAmount}
              testID="SET_ALL_SOURCE_TO_AMOUNT_BUTTON"
            >
              <Trans
                i18nKey="surface.hodls.hodl_form.amount.action.all_source"
                components={{
                  Bold: <Text variant="$body-02-medium-accent" />,
                }}
                values={{
                  amount: allSourceAmount,
                  ticker: inputTickerFormatted,
                }}
              />
            </TouchableText>
          </Box>

          <TickerAndAmountInput
            focusAfterChangeTicker={false}
            wallets={sourceWallets}
            ticker={inputTicker}
            amount={inputAmount}
            onChangeTicker={setInputTicker}
            onChangeAmount={instance.$('inputAmount').onChange}
            setCaretPositionToEndOnFocus
          />
          {Boolean(instance.$('inputAmount').error) && (
            <Box mt={10}>
              <Text color="$danger-01" variant="$body-02">
                <ValidationMessageComponent
                  error={instance.$('inputAmount').get('error')}
                />
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
