import * as React from 'react';
import {StoryPreviewListItemProps} from '../../interfaces';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {StoryPreviewListItem} from './StoryPreviewListItem';

export const StoryPreviewListItemWrapped: React.FC<
  StoryPreviewListItemProps
> = props => {
  const {item, index} = props;

  React.useEffect(() => {
    DATA_LAYER.trackStrict('story-preview-shown', {
      storyId: item.id,
      storySlug: item.slug,
      storyTitle: item.title,
      storyNumberOfSlides: item.slides?.length,
      storySeenBefore: item.shown,
      storyIndex: index + 1,
    });
  }, []);

  return <StoryPreviewListItem {...props} />;
};
