import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {FeatureItem} from './FeatureItem';
import {useFeatures} from './useFeatures';

export const CardFeatures: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    data: {enableSkipCardOrderFee},
  } = AuthMeResource.use({});
  const features = useFeatures(t, enableSkipCardOrderFee);

  return (
    <Box
      borderWidth={1}
      borderColor="$interactive-03"
      borderRadius={16}
      p={24}
      overflow="hidden"
      {...boxProps}
    >
      {features.map(({text, icon, asteriskCount}, index) => (
        <FeatureItem
          text={text}
          icon={icon}
          asteriskCount={asteriskCount}
          mb={index < features.length - 1 ? 24 : 0}
          key={text}
        />
      ))}
    </Box>
  );
});
