import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const TradingIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M4.25 3c0 .414.336.75.75.75h3.19L1.97 9.97a.75.75 0 101.06 1.06l6.22-6.22V8a.75.75 0 001.5 0V3a.75.75 0 00-.75-.75H5a.75.75 0 00-.75.75zm11.5 14a.75.75 0 00-.75-.75h-3.19l6.22-6.22a.75.75 0 10-1.06-1.06l-6.22 6.22V12a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h5a.75.75 0 00.75-.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
