import Big from 'big.js';
import {webTickerConfig as CONFIG} from '@youtoken/ui.coin-utils';
import {getAmountRoundValue} from '@youtoken/ui.formatting-utils';

export const getAmountListFormatted = (
  inputTicker: string,
  rateInputTickerUsd: Big
) => {
  const amountListUSD = ['100', '200', '500', '1000'];

  if (
    [...CONFIG.TICKERS.FIAT, ...CONFIG.TICKERS.STABLE].includes(inputTicker) ||
    rateInputTickerUsd === undefined
  ) {
    return amountListUSD;
  }

  return amountListUSD.map(amountString => {
    const amountInTicket = Big(amountString).mul(rateInputTickerUsd);
    const roundVal = getAmountRoundValue(amountInTicket);

    return amountInTicket.round(roundVal).toString();
  });
};
