import * as React from 'react';
import type {TFuncKey} from 'react-i18next';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {privacyNoticeUnified} from '@youtoken/ui.resource-documents';

export const AcknowledgeOfRisk: React.FC<{}> = () => {
  const {t} = useTranslation();

  return (
    <>
      <Text variant="$body-01-high-accent" mb={16}>
        {(
          t('surface.profile.legal_info.acknowledge.title') as string
        ).toUpperCase()}
      </Text>
      {new Array(5).fill(0).map((_, index) => (
        <Text variant="$body-02" color="$text-02" key={index} mb={16}>
          {t(
            `surface.profile.legal_info.acknowledge.p${index + 1}` as TFuncKey
          )}
        </Text>
      ))}
      <Link
        underlined
        url={privacyNoticeUnified.link}
        variant="$body-02"
        color="$text-02"
      >
        {t(privacyNoticeUnified.i18nName as TFuncKey)}
      </Link>
    </>
  );
};
