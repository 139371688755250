import {observable} from 'mobx';
import type {UnlimintCoDiFormArgs, UnlimintCoDiFormResources} from '../types';
import {Form} from './Form';

export class UnlimintCoDiForm {
  @observable
  public form: Form;

  public constructor(
    args: UnlimintCoDiFormArgs,
    resources: UnlimintCoDiFormResources
  ) {
    this.form = new Form(args, resources);
  }
}
