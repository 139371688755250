import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {useForm} from '../Form';
import {Error} from '../Error';

export interface CheckboxFieldProps extends BoxProps {
  name: string;
  testID?: string;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = observer(
  ({name, children, testID, ...boxProps}) => {
    const {
      form: {instance},
    } = useForm();

    return (
      <Box {...boxProps}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            flexGrow={1}
            flexShrink={1}
            mr={10}
            flexDirection="row"
            flexWrap="wrap"
          >
            {children}
          </Box>
          <Switch
            value={instance.$(name).value}
            onValueChange={instance.$(name).onChange}
            hasError={Boolean(instance.$(name).error)}
            testID={testID}
          />
        </Box>
        <Error
          errors={instance.errors()}
          name={name}
          testID={testID ? `${testID}_ERROR` : undefined}
        />
      </Box>
    );
  }
);
