import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {RewardsReferralsSurface} from '@youtoken/ui.surfaces-referral-program';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export const RewardsReferralPage: React.FC = cell(() => {
  return (
    <InnerPageShell>
      <RewardsReferralsSurface />
    </InnerPageShell>
  );
});
