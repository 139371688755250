import EventEmitter from 'eventemitter3';
import i18next from 'i18next';
import {initReactI18next, TFunction} from 'react-i18next';
import {createAtom, IAtom} from 'mobx';
import {resources, defaultNS} from './resources';
import {getOrDetectLocalLanguage} from './utils';
import {setStoredLanguage} from './utils/storage';
import {languages, DEFAULT_LANG} from './utils/langList';
import {ENVIRONMENT} from '@youtoken/ui.environment';

export type TKey = Parameters<TFunction>[0];

export class i18nService {
  public events = new EventEmitter();

  public i18next: typeof i18next;

  public _languageAtom: IAtom | null = null;

  public isLangSwitcherTouched: boolean = false;

  constructor(debug: boolean = false) {
    if (!i18next.isInitialized) {
      i18next.use(initReactI18next).init({
        lng: this.language, // if you're using a language detector, do not define the lng option
        ns: ['translations', 'countries'],
        defaultNS,
        debug: debug,
        resources,
        interpolation: {escapeValue: false},
      });
    }

    this._languageAtom = createAtom('LanguageAtom');

    i18next.on('languageChanged', () => {
      this._languageAtom?.reportChanged();
    });

    this.i18next = i18next;
  }

  public get languages() {
    if (!ENVIRONMENT.APP_ENV || ENVIRONMENT.APP_ENV === 'production') {
      // show production only languages
      return languages.filter(l => l.production === true);
    }

    return languages;
  }

  public get languagesList() {
    return this.languages.map(l => l.value);
  }

  public get showLanguageSwitcher() {
    return this.languages.length > 1;
  }

  public detectUserLanguage() {
    this._changeLanguage(getOrDetectLocalLanguage());
  }

  /** translate default key from "translation" object */
  public t: TFunction = (...args) => {
    this._languageAtom?.reportObserved();
    const [key, options] = args;
    return this.i18next.t(key, options);
  };

  public t_countries: TFunction<'countries'> = (code3: string) => {
    this._languageAtom?.reportObserved();
    return this.i18next.t(`countries:${code3}`);
  };

  public _changeLanguage = (language: string) => {
    if (!this.languagesList.includes(language)) {
      return;
    }

    this.i18next.changeLanguage(language);
    setStoredLanguage(language);
  };

  get language() {
    this._languageAtom?.reportObserved();

    return this.i18next?.language || DEFAULT_LANG;
  }
}
