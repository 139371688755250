import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const BellIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.348 32h27.303c1.71 0 2.631-2.004 1.519-3.302l-2.36-2.753a3.363 3.363 0 01-.81-2.19V15.51a9.617 9.617 0 00-7.285-9.33 1.603 1.603 0 01-1.045-.838l-.776-1.552a3.236 3.236 0 00-5.788 0l-.776 1.55c-.21.418-.591.724-1.045.838A9.617 9.617 0 008 15.509v8.247a3.36 3.36 0 01-.81 2.189l-2.36 2.753C3.718 29.996 4.64 32 6.348 32zm24.184-4.103L31.477 29H8.523l.945-1.103A6.364 6.364 0 0011 23.756v-8.247a6.617 6.617 0 015.012-6.42 4.603 4.603 0 003-2.407l.777-1.552a.236.236 0 01.422 0l.776 1.552a4.603 4.603 0 003 2.407A6.617 6.617 0 0129 15.51v8.247a6.36 6.36 0 001.532 4.141z"
          fill={color}
        />
        <Path
          d="M20.299 37.989A4 4 0 0024 34h-8a4 4 0 004 4l.299-.011z"
          fill={color}
        />
      </Svg>
    );
  }
);
