import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BENEFITS_TYPE, DepositNowFeature} from '../../state';
import {MinAmount} from './MinAmount';
import {DepositNowInfoMiner} from './Miner';
import {DepositNowInfoSavings} from './Savings';
import {DepositNowInfoIncentives} from './Incentives';

export enum DepositNowInfoVariant {
  WALLETS = 'wallets',
  BUY_CRYPTO = 'buyCrypto',
}

interface DepositNowInfoProps {
  variant?: DepositNowInfoVariant;
}

export const DepositNowInfo: React.FC<DepositNowInfoProps & BoxProps> = cell(
  ({variant = DepositNowInfoVariant.WALLETS, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      showBenefitsType,
      showSparksBenefits,
      showAprBenefits,
      showIncentivesBenefits,
      requiredDepositFormatted,
    } = DepositNowFeature.use({});

    if (
      variant === DepositNowInfoVariant.WALLETS &&
      showBenefitsType === BENEFITS_TYPE.NEW_USER
    ) {
      return (
        <Box testID="DEPOSIT_NOW_INFO" {...boxProps}>
          <MinAmount amountFormatted={requiredDepositFormatted} mb={10} />
          <Box
            flexDirection={{
              default: 'column',
              tablet: 'row',
            }}
            mx={-4}
          >
            {showSparksBenefits && <DepositNowInfoMiner flex={1} mx={4} />}
            {showAprBenefits && <DepositNowInfoSavings flex={1} mx={4} />}
            {showIncentivesBenefits && (
              <DepositNowInfoIncentives flex={1} mx={4} />
            )}
          </Box>
        </Box>
      );
    }

    if (
      variant === DepositNowInfoVariant.BUY_CRYPTO &&
      showBenefitsType === BENEFITS_TYPE.NEW_USER
    ) {
      return (
        <Box {...boxProps}>
          <Text variant="$body-01-high-accent" color="$text-01" mb={10}>
            {t('surface.wallets.deposit_now.bonuses_info.title')}
          </Text>
          <Box mb={16}>
            {showSparksBenefits && <DepositNowInfoMiner />}
            {showAprBenefits && <DepositNowInfoSavings />}
            {showIncentivesBenefits && <DepositNowInfoIncentives />}
          </Box>
          <MinAmount amountFormatted={requiredDepositFormatted} />
        </Box>
      );
    }

    if (
      variant === DepositNowInfoVariant.BUY_CRYPTO &&
      showBenefitsType === BENEFITS_TYPE.COMMON
    ) {
      return (
        <Box {...boxProps}>
          <Text variant="$body-01-high-accent" color="$text-01" mb={10}>
            {t('surface.wallets.deposit_now.bonuses_info.title')}
          </Text>
          <Box>
            <DepositNowInfoIncentives />
          </Box>
        </Box>
      );
    }

    return null;
  }
);
