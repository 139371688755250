import {observable, computed} from 'mobx';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {LedgerResource} from '@youtoken/ui.resource-ledger';
import {Form} from './Form';

export interface CryptoDepositLedgerFormStateArgs {
  ticker: string;
}

export interface CryptoDepositLedgerFormStateResource {
  authMeResource: AuthMeResource;
  walletsResource: WalletsResource;
  ledgerResource: LedgerResource;
}

export class CryptoDepositLedgerFormState {
  @observable
  args: CryptoDepositLedgerFormStateArgs;

  @observable
  resources: CryptoDepositLedgerFormStateResource;

  @observable
  form: Form;

  @computed
  get walletTicker() {
    return this.form.ticker;
  }

  @computed
  get walletTickerFormatted() {
    return this.form.tickerFormatted;
  }

  @computed
  public get submitting() {
    return this.form.instance.submitting;
  }

  @computed
  public get submit() {
    return this.form.instance.onSubmit;
  }

  constructor(
    args: CryptoDepositLedgerFormStateArgs,
    resources: CryptoDepositLedgerFormStateResource
  ) {
    this.args = args;
    this.resources = resources;
    this.form = new Form(this.args, this.resources);
  }
}
