import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {FeatureList, Illustration} from '@youtoken/ui.elements';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';

export const PleaseVerifyWelcome: React.FC = observer(() => {
  const {t} = useTranslation();

  const texts = React.useMemo(() => {
    if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ramp-app']) {
      return {
        title: t('ramp.please_verify.title'),
        subtitle: t('ramp.please_verify.subtitle'),
        pointFast: t('ramp.please_verify.point_fast'),
        pointTrust: t('ramp.please_verify.point_trust'),
      };
    }

    return {
      title: t('surface.please_verify.title'),
      subtitle: t('surface.please_verify.subtitle'),
      pointFast: t('surface.please_verify.point_fast'),
      pointTrust: t('surface.please_verify.point_trust'),
    };
  }, [ENVIRONMENT.WEB_APP_ENV]);

  return (
    <>
      <Illustration
        style={{
          alignSelf: 'center',
          width: 244,
          height: 244,
        }}
        name="illustration-14"
      />
      <Box>
        <Heading variant="$heading-02" textAlign="center" mt={8} mb={8}>
          {texts.title}
        </Heading>
        <Text variant="$body-01" textAlign="center" color="$text-02">
          {texts.subtitle}
        </Text>
        <FeatureList
          size="medium"
          items={[
            {
              icon: 'pending',
              title: texts.pointFast,
            },
            {
              icon: 'verified',
              title: texts.pointTrust,
            },
          ]}
          py={32}
        />
      </Box>
    </>
  );
});
