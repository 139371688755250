import {i18n} from '@youtoken/ui.service-i18n';
import {InfoItemProps} from '../InfoItem';

export const useInstructions = (t: typeof i18n.t): InfoItemProps[] => {
  return [
    {
      title: t('surface.bank_cards.landing.instructions.step', {number: 1}),
      text: t('surface.bank_cards.landing.instructions.step_one.text'),
      icon: 'card',
    },
    {
      title: t('surface.bank_cards.landing.instructions.step', {number: 2}),
      text: t('surface.bank_cards.landing.instructions.step_two.text'),
      icon: 'hodl',
    },
    {
      title: t('surface.bank_cards.landing.instructions.step', {number: 3}),
      text: t('surface.bank_cards.landing.instructions.step_three.text'),
      icon: 'first_trade',
      asteriskCount: 2,
    },
  ];
};
