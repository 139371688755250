import * as React from 'react';
import {observer} from 'mobx-react';
import {Path} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation} from '../../hooks';

type ReflectionProps = {
  hexState: FEBlockState;
};

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 0,
  AVAILABLE_NOT_ENOUGH_SPARKS: 1,
  AVAILABLE_ENOUGH_SPARKS: 1,
  MINING_STARTING: 1,
  MINING: 0,
  READY: 0,
  CLAIMING_STARTING: 0,
  CLAIMING: 0,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 0,
};

export const Reflection: React.FC<ReflectionProps> = observer(({hexState}) => {
  const {colors} = useTheme();

  const style = useLegacyOpacityAnimation(OPACITIES, hexState);

  if (style.opacity === 0) {
    return null;
  }

  return (
    <Path
      opacity={style.opacity}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 5.4737V17.6382C32 18.1581 32.1009 18.6634 32.2886 19.1302C31.5026 19.0734 30.7035 19.249 29.998 19.6569L17.998 26.5944C16.7615 27.3092 16 28.6291 16 30.0573V43.9416C16 45.3699 16.7615 46.6897 17.998 47.4046L29.998 54.3421C31.2366 55.0581 32.7634 55.0581 34.002 54.3421L46.002 47.4046C47.2385 46.6897 48 45.3699 48 43.9416V30.0573C48 28.9895 47.5743 27.9822 46.8427 27.2431C47.682 27.2201 48.5187 27.021 49.2957 26.6442L59.6159 21.6405C61.0576 20.9415 61.1332 18.9158 59.7477 18.1113L35.0043 3.74412C33.6709 2.96993 32 3.93191 32 5.4737ZM33.0094 20.2947C33.3069 20.6297 33.663 20.9172 34.0677 21.1405L43.7796 26.4988C43.9228 26.5778 44.0684 26.6505 44.2162 26.717L45.5015 27.4601C46.4289 27.9962 47 28.9862 47 30.0573V43.9416C47 45.0128 46.4289 46.0027 45.5015 46.5388L33.5015 53.4763C32.5725 54.0134 31.4275 54.0134 30.4985 53.4763L18.4985 46.5388C17.5711 46.0027 17 45.0128 17 43.9416V30.0573C17 28.9862 17.5711 27.9963 18.4985 27.4601L30.4985 20.5226C31.269 20.0772 32.1882 20.0012 33.0094 20.2947Z"
      fill={
        hexState === 'AVAILABLE_NOT_ENOUGH_SPARKS'
          ? colors['$miner-gloss-blocked']
          : colors['$miner-gloss']
      }
    />
  );
});
