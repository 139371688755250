import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';

export const UMA_USERNAME_REGEX = new RegExp(/^[a-z0-9-_.+]+$/);

export const umaValidator = (umaValue: string) => {
  if (umaValue.includes('@')) {
    const [name, domain] = umaValue.split('@');

    if (!name || !UMA_USERNAME_REGEX.test(name) || name.length > 63) {
      return new yupPackage.ValidationError(
        i18n.t('surface.wallets.uma.uma_input.message.username_is_not_valid')
      );
    }

    if (!domain || !domain.includes('.')) {
      return new yupPackage.ValidationError(
        i18n.t('surface.wallets.uma.uma_input.message.domain_is_not_valid')
      );
    }

    return true;
  }

  return new yupPackage.ValidationError(
    i18n.t('surface.wallets.uma.uma_input.message.receiver_uma_is_not_valid')
  );
};
