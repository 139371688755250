import {observable, action, reaction, IReactionDisposer} from 'mobx';
import {Form} from './Form';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {
  createFeature,
  useResource,
  getResourceDescriptor,
} from '@youtoken/ui.data-storage';
import {
  IFeatureDisclosureFormArgs,
  IFeatureDisclosureFormResources,
} from './types';

export type {ProductType} from '../types';

export class FeatureDisclosureForm extends createFeature({
  getKey: (args: IFeatureDisclosureFormArgs) =>
    `FeatureDisclosureForm:${JSON.stringify(args)}`,
  getResources: () => {
    const {legalEntity} = useResource(AuthMeResource, {});

    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      documents: getResourceDescriptor(DocsResource, {
        country: legalEntity,
      }),
      company: getResourceDescriptor(CompanyResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  disposers: IReactionDisposer[] = [];

  constructor(
    args: IFeatureDisclosureFormArgs,
    resources: IFeatureDisclosureFormResources
  ) {
    super(args, resources);

    this.form = new Form(args, resources);

    this.disposers = [
      reaction(
        () => this.args,
        () => {
          this.form.args = this.args;
        }
      ),
    ];
  }

  @action reset = () => {
    this.form.instance.reset();
  };

  onDestroy() {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
}
