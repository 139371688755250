import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {
  type RewardsBannerLayoutProps,
  RewardsBannerContent,
} from '../../components';

export const RewardsBannerV3Smart: React.FC<RewardsBannerLayoutProps> =
  observer(({variant}) => {
    const {
      data: {totalEarnedUSD, maxEarningsUSD, newsCount},
    } = RewardsResource.use({});

    return (
      <RewardsBannerContent
        variant={variant}
        amount={totalEarnedUSD}
        maxAmount={maxEarningsUSD}
        newsCount={newsCount}
      />
    );
  });
