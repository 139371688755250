import * as React from 'react';
import {Platform, ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BankCardLimitsResource,
  BankCardsResource,
} from '@youtoken/ui.resource-bank-cards';
import {BankCardAddMoneyForm} from './state';
import {AmountInput, LimitItem, Submit} from './components';

type TabInternalProps = {
  cardId: string;
  onSuccess: () => void;
};

export const TabInternal: React.FC<TabInternalProps & BoxProps> = observer(
  ({cardId, onSuccess}) => {
    const {t} = useTranslation();

    const resources = useResources({
      authMe: getResourceDescriptor(AuthMeResource, {}),
      bankCards: getResourceDescriptor(BankCardsResource, {}),
      bankCardLimits: getResourceDescriptor(BankCardLimitsResource, {cardId}),
    });

    const form = makeForm(
      () => new BankCardAddMoneyForm({cardId, onSuccess}, resources)
    );

    const {
      form: {tickerFormatted},
      view: {minAmountFormatted, maxAmountFormatted},
    } = form;

    return (
      <Box width="100%" flex={1} justifyContent="space-between" pt={24} px={24}>
        <Form form={form}>
          <ScrollView keyboardDismissMode="on-drag">
            <Box mb={16}>
              <AmountInput />
              <LimitItem>
                {t('surface.bank_cards.deposit.limit.min', {
                  amount: minAmountFormatted,
                  ticker: tickerFormatted,
                })}
              </LimitItem>
              <LimitItem>
                {t('surface.bank_cards.deposit.limit.max', {
                  amount: maxAmountFormatted,
                  ticker: tickerFormatted,
                })}
              </LimitItem>
            </Box>
          </ScrollView>

          {/* this padding is to separate the element from the keyboard on Android */}
          <Submit mb={Platform.OS === 'android' ? 24 : 0} />
        </Form>
      </Box>
    );
  }
);
