import {computed, observable} from 'mobx';
import {BaseChartState} from '../BaseChartState';

export class LastValueLabel<
  ChartState extends BaseChartState = BaseChartState
> {
  @observable chart!: ChartState;
  @observable includePercents: boolean = true;

  constructor(chart: ChartState, includePercents: boolean = true) {
    this.chart = chart;
    this.includePercents = includePercents;
  }

  @computed get labelText() {
    const value = this.chart.formatNumber(this.chart.lastValueToCompare);

    if (!this.includePercents) {
      return value;
    }

    const diff = this.chart.formatPercent(
      (this.chart.lastValueToCompare - this.chart.firstValueToCompare) /
        this.chart.firstValueToCompare
    );

    return `${value} ${diff}`;
  }

  @computed get labelWidth() {
    return this.chart.layout.getLabelWidth(this.labelText);
  }

  @computed get layout() {
    if (!this.chart.lastValueToCompare) {
      return undefined;
    }

    return this.chart.layout.getLabelLayoutVertical(
      this.chart.lastValueToCompare,
      this.labelText,
      'right',
      this.chart.direction
    );
  }
}
