import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CloseNowIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.5a8.5 8.5 0 110 17 8.5 8.5 0 010-17zM10 3a7 7 0 100 14 7 7 0 000-14zm3.25 6a1 1 0 110 2h-6.5a1 1 0 110-2h6.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
