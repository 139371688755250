import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';

type YourLevelStickerProps = BoxProps;

export const YourLevelSticker: React.FC<YourLevelStickerProps> = ({
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <Box
      py={4}
      px={8}
      backgroundColor="$interactive-01"
      borderRadius={12}
      {...boxProps}
    >
      <Text variant="$body-03" color="$text-04">
        {(t('surface.miner.loyalty.your_level') as string).toUpperCase()}
      </Text>
    </Box>
  );
};
