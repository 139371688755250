import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const HideIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.142 5.858a1 1 0 010 1.414l-26.87 26.87a1 1 0 01-1.414-1.414l26.87-26.87a1 1 0 011.414 0zM31 12.544c2.161 1.766 4.335 4.045 6.524 6.84a1 1 0 01.104 1.07l-.104.163-.54.68C31.225 28.431 25.563 32 20 32c-2.23 0-4.474-.573-6.735-1.718l2.268-2.267c1.516.657 3.005.985 4.467.985 4.418 0 9.085-3 14-9-1.757-2.145-3.483-3.907-5.176-5.285l2.175-2.17zM20 8c2.234 0 4.484.575 6.749 1.726l-2.266 2.266C22.961 11.331 21.467 11 20 11c-4.418 0-9.085 3-14 9 1.76 2.15 3.49 3.913 5.186 5.293l-2.175 2.171c-2.164-1.766-4.342-4.049-6.534-6.847a1 1 0 01-.104-1.07l.104-.164.54-.68C8.775 11.569 14.437 8 20 8zm6 12a6 6 0 01-8.082 5.629l2.689-2.69a3.004 3.004 0 002.33-2.328l.607-.612L26 20zm-6-6l-.002 2.475-.578.581a3.004 3.004 0 00-2.363 2.36l-2.68 2.68a6 6 0 015.271-8.086L20 14z"
          fill={color}
        />
      </Svg>
    );
  }
);
