import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarDetailedCloseTradePerksIcon} from './Icon';

interface AccountLevelProgressBarDetailedTradePerksClosedProps {
  showSparks?: boolean;
}

export const AccountLevelProgressBarDetailedTradePerksClosed: React.FC<
  AccountLevelProgressBarDetailedTradePerksClosedProps & BoxProps
> = observer(({showSparks = false, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_TRADE_PERKS_CLOSED"
      gap={12}
      {...boxProps}
    >
      <Text
        testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_TRADE_PERKS_CLOSED_TITLE"
        variant="$body-02-high-accent"
        color="$text-01"
      >
        {t('surface.loyalty.progress_bar_detailed.perks.closed_trade')}
      </Text>
      <Box flexDirection="row" alignItems="flex-start" gap={12}>
        <AccountLevelProgressBarDetailedCloseTradePerksIcon />
        <Box flex={1} alignItems="flex-start">
          <Text
            testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_TRADE_PERKS_CLOSED_DESCRIPTION"
            variant="$body-02"
            color="$text-02"
          >
            {t(
              showSparks
                ? 'surface.loyalty.progress_bar_detailed.perks.closed_trade_description'
                : 'surface.loyalty.progress_bar_detailed.perks.closed_trade_volume_description'
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
});
