import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {SavingsSliderSlide} from './Slide';
import {Illustration} from '@youtoken/ui.elements';

export const SavingsSliderSlideIncreaseRewards: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <SavingsSliderSlide
      img={
        <Box width={200} height={200}>
          <Illustration
            name="illustration-4"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      }
      title={t('surface.savings.slider.increase_rewards.title')}
      description={t('surface.savings.slider.increase_rewards.description')}
    />
  );
});
