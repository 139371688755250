import * as React from 'react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {getAsterisksSting} from '../utils';

type ParagraphProps = {
  title?: string;
  text: string;
  asteriskCount?: number;
};

export const Paragraph: React.FC<ParagraphProps & BoxProps> = ({
  title,
  text,
  asteriskCount,
  ...boxProps
}) => {
  const asterisks = getAsterisksSting(asteriskCount);

  return (
    <Box {...boxProps}>
      {title && (
        <Text variant="$body-01-high-accent" color="$text-01">
          {title}
        </Text>
      )}

      <Box mt={16}>
        <Text variant="$body-01" color="$text-01">
          {asterisks && (
            <Text variant="$body-01-high-accent" color="$text-05">
              {asterisks + ' '}
            </Text>
          )}
          {text}
        </Text>
      </Box>
    </Box>
  );
};
