import {computed} from 'mobx';
import {LoanTariffsResource} from '@youtoken/ui.resource-loan-tariffs';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';

export class CreateNewTurboButtonFeature extends createFeature({
  getKey: () => `CreateNewTurboButtonFeature`,
  getResources: () => ({
    tariffs: getResourceDescriptor(LoanTariffsResource, {}),
    me: getResourceDescriptor(AuthMeResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
  }),
}) {
  @computed get enabled() {
    return !!(
      this.resources.me.data.enableTurbo &&
      Object.keys(
        this.resources.tariffs.getTurboMarketsWithWhiteList(
          this.resources.wallets.loanEnabledTickers
        )
      ).length
    );
  }
}
