import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {MinerOverviewResource} from '.';

export class MinerFeature extends createFeature({
  getKey: () => {
    return `miner`;
  },
  getResources: () => {
    return {
      minerOverview: getResourceDescriptor(MinerOverviewResource, {}),
      loyalty: getResourceDescriptor(LoyaltyResource, {}),
      authMe: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {}
