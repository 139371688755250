import * as React from 'react';
import {AgreementsList, ActivityIndicator} from '@youtoken/ui.elements';
import {privacyNoticeUnified} from '@youtoken/ui.resource-documents';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Switch} from '@youtoken/ui.elements';
import {FieldErrorMessage} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {FeatureSignUpAdditionalForm} from './state';

export type TermsCheckboxProps = {
  feature: FeatureSignUpAdditionalForm;
  testID?: string;
} & BoxProps;

export const TermsCheckbox = cell(
  ({testID, feature, ...boxProps}: TermsCheckboxProps) => {
    const {t} = useTranslation();

    return (
      <Box position="relative" {...boxProps}>
        <Box flexDirection="row" justifyContent="space-between">
          <AgreementsList items={[privacyNoticeUnified]} maxWidth="80%" />
          <Box ml={12}>
            <Switch
              value={feature.form.agree}
              onValueChange={feature.form.instance.$('agree').onChange}
              hasError={Boolean(feature.form.instance.$('agree').error)}
              testID="TERMS_CHECKBOX"
            />
          </Box>
        </Box>
        <FieldErrorMessage
          placement="right"
          visible={Boolean(feature.form.instance.$('agree').error)}
        >
          {t('validation.REQUIRED')}
        </FieldErrorMessage>
      </Box>
    );
  }
);
