import * as React from 'react';
import {observer} from 'mobx-react';
import {Wallets} from '@youtoken/ui.legacy-components';
import {
  BankCardsPromoBanner,
  BankCardsWidget,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {StoriesList} from '@youtoken/ui.insta-stories';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  CoindropBanner,
  DepositNow,
  IncentivesBalance,
  InfoMessageWidget,
} from '@youtoken/ui.surfaces-wallets';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {FeaturesResource} from '@youtoken/ui.resource-features';
import {VerificationResource} from '@youtoken/ui.resource-verification';

const {
  Groups: {Summary, SavingsOrRewards},
} = Wallets;

export const Header: React.FC = observer(() => {
  const {
    authMe: {
      data: {enableStories, hasEverDeposited},
      hasCoindrops,
    },
    features: {hideStories},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    features: getResourceDescriptor(FeaturesResource, {}),
  });

  const {accountVerificationCurrent} = VerificationResource.use({});

  return (
    <>
      <Summary width="100%" px={{default: 20, desktop: 0}} />
      {enableStories && !hideStories && <StoriesList mt={8} />}
      <SavingsOrRewards mt={16} mx={{default: 16, desktop: 0}} mb={14} />
      {hasCoindrops && <CoindropBanner mt={24} />}
      {Boolean(accountVerificationCurrent) && <InfoMessageWidget mt={8} />}
      <IncentivesBalance mt={8} mx={{default: 16, desktop: 0}} />
      <DepositNow
        variant={hasEverDeposited ? 'deposited' : 'never-deposited'}
        mt={20}
        mx={{default: 16, desktop: 0}}
      />
      <BankCardsPromoBanner mt={8} px={{default: 16, desktop: 0}} />
      <BankCardsWidget mt={8} mx={{default: 16, desktop: 0}} />
    </>
  );
});
