import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Modal} from '@youtoken/ui.modal';
import {HODLDetailsButton} from '../HODLDetailsButton';

interface HODLDetailsButtonAndModalProps
  extends Partial<React.ComponentProps<typeof Modal>> {
  children: React.ReactNode;
  buttonText?: string;
  buttonTestID?: string; // NOTE: use as param in case of several buttons on the page
  isTiny?: boolean;
}

export const HODLDetailsButtonAndModal: React.FC<
  HODLDetailsButtonAndModalProps
> = ({
  children,
  buttonText,
  buttonTestID = 'HOW_IT_WORKS_BUTTON',
  isTiny,
  ...modalProps
}) => {
  const {t} = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleModalOpen = React.useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalClose = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <HODLDetailsButton
        onPress={handleModalOpen}
        testID={buttonTestID}
        text={buttonText}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        snapPoints={isTiny ? ['50%'] : ['90%']}
        title={t('surface.hodls.mh_details')}
        shouldBeScrollable
        {...modalProps}
      >
        {children}
      </Modal>
    </>
  );
};
