import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {Text} from '@youtoken/ui.primitives';

export interface UpgradeToLevelRequirementsProps {
  level: LoyaltyLevel;
}

export const UpgradeToLevelRequirements: React.FC<UpgradeToLevelRequirementsProps> =
  observer(
    ({
      level: {
        level: levelNumber,
        requiredDepositVisibleFormatted,
        requiredVolumeFormatted,
      },
    }) => {
      const {t} = useTranslation();

      return (
        <Text textAlign="center" mt={8}>
          {levelNumber === 1 && t('surface.loyalty.next_level_requirement.1')}
          {levelNumber === 2 &&
            t('surface.loyalty.next_level_requirement.2', {
              amount: requiredDepositVisibleFormatted,
            })}
          {levelNumber === 3 && t('surface.loyalty.next_level_requirement.3')}
          {levelNumber > 3 &&
            t('surface.loyalty.next_level_requirement', {
              volume: requiredVolumeFormatted,
            })}
        </Text>
      );
    }
  );
