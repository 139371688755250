import {computed} from 'mobx';
import {
  BaseChartState,
  LineChartData,
  LineChartDataItem,
} from '../BaseChartState';
import {computedFn} from 'mobx-utils';
import {line, curveLinear} from 'd3-shape';
import {BaseDataDisaplay} from './BaseDataDisplay';

export class Lines extends BaseDataDisaplay {
  constructor(chart: BaseChartState) {
    super(chart);
  }

  getLine = computedFn((data?: LineChartData) => {
    if (!data || data.length < 1) {
      return undefined;
    }

    return line<LineChartDataItem>()
      .x(d => this.chart.layout.scaleXChart(d.date))
      .y(d => this.chart.layout.scaleY(d.rate))
      .curve(curveLinear)(data);
  });

  @computed get dataLine() {
    return this.getLine(
      this.chart.data.slice(
        this.dataStartIndex < 0 ? 0 : this.dataStartIndex,
        this.dataEndIndex
      ) as LineChartData
    );
  }

  @computed get pastDataLine() {
    // if (this.dataStartIndex === 0) {
    //   return undefined;
    // }

    return this.getLine(
      this.chart.data.slice(0, this.dataStartIndex + 1) as LineChartData
    );
  }

  @computed get futureDataLine() {
    // if (this.dataEndIndex === this.chart.data.length - 1) {
    //   return undefined;
    // }
    return this.getLine(
      this.chart.data.slice(
        this.dataEndIndex <= 0 ? 0 : this.dataEndIndex - 1,
        this.chart.data.length
      ) as LineChartData
    );
  }
}
