import {BaseChartState, BaseChartStateProps} from './BaseChartState';
import {action, computed, observable} from 'mobx';
import {
  InitialPriceLabel,
  LastValueLabel,
  MarginCallLabel,
  MaxLossLabel,
  MaxProfitLabel,
  TakeProfitLabel,
  TriggerPriceLabel,
  TriggerPriceNameLabel,
} from './Labels';
import {HODLHighlightArea} from './Areas';
import {computedFn} from 'mobx-utils';

export interface BaseHODLChartStateProps extends BaseChartStateProps {
  maxProfit?: number;
  maxProfitTicker?: string;
  maxLoss?: number;
  maxLossTicker?: string;

  marginCallType?: string;
  marginCall?: number;
  takeProfit?: number;

  reversed?: boolean;
  pending?: boolean;
  triggerPrice?: number;
  initialPrice?: number;
  closedPrice?: number;

  hasData?: boolean;

  displayInitialPriceLabel?: boolean;
}

export class BaseHODLChartState extends BaseChartState {
  @observable lastValueLabel!: LastValueLabel;
  @observable marginCallLabel!: MarginCallLabel;
  @observable takeProfitLabel!: TakeProfitLabel;
  @observable maxLossLabel!: MaxLossLabel;
  @observable maxProfitLabel!: MaxProfitLabel;
  @observable triggerPriceLabel!: TriggerPriceLabel;
  @observable triggerPriceNameLabel!: TriggerPriceNameLabel;
  @observable initialPriceLabel!: InitialPriceLabel;
  @observable HODLHighlightArea!: HODLHighlightArea;
  @observable displayInitialPriceLabel: boolean = true;

  constructor(props: BaseHODLChartStateProps) {
    super(props);
    this.lastValueLabel = new LastValueLabel(this, true);
    this.marginCallLabel = new MarginCallLabel(this);
    this.takeProfitLabel = new TakeProfitLabel(this);
    this.maxLossLabel = new MaxLossLabel(this);
    this.maxProfitLabel = new MaxProfitLabel(this);
    this.triggerPriceLabel = new TriggerPriceLabel(this);
    this.triggerPriceNameLabel = new TriggerPriceNameLabel(this);
    this.initialPriceLabel = new InitialPriceLabel(this);
    this.HODLHighlightArea = new HODLHighlightArea(this);

    this.setFromProps(props);
  }

  @observable marginCallType?: string;
  @observable marginCall?: number;
  @observable takeProfit?: number;
  @observable maxProfit?: number;
  @observable maxProfitTicker?: string;
  @observable maxLoss?: number;
  @observable maxLossTicker?: string;
  @observable currentPrice?: number;
  @observable reversed?: boolean = false;
  @observable pending?: boolean = false;
  @observable triggerPrice?: number;
  @observable initialPrice?: number;
  @observable closedPrice?: number;

  @action setFromProps(props: BaseHODLChartStateProps) {
    super.setFromProps(props);

    this.reversed = props.reversed;
    this.pending = props.pending;
    this.triggerPrice = props.triggerPrice;
    this.initialPrice = props.initialPrice;
    this.closedPrice = props.closedPrice;

    this.marginCallType = props.marginCallType;
    this.marginCall = props.marginCall;
    this.takeProfit = props.takeProfit;

    this.maxLoss = props.maxLoss;
    this.maxLossTicker = props.maxLossTicker;
    this.maxProfit = props.maxProfit;
    this.maxProfitTicker = props.maxProfitTicker;
    this.displayInitialPriceLabel = props.displayInitialPriceLabel ?? true;
  }

  isDateInLineDataRange = computedFn((date: Date) => {
    if (!this._lineData || this._lineData.length === 0 || !this._lineData[0]) {
      return false;
    }

    return (
      date > this._lineData[0].date &&
      date < this._lineData[this._lineData.length - 1]!.date
    );
  });

  @computed get lineData() {
    if (!this._lineData) {
      return this._lineData;
    }

    const finalHODLPoint =
      this.endDate && this.closedPrice
        ? {date: this.endDate, rate: this.closedPrice}
        : undefined;

    if (finalHODLPoint) {
      const dataWithFusedPoints = [...this._lineData];

      if (finalHODLPoint) {
        dataWithFusedPoints.push(finalHODLPoint);
      }

      return dataWithFusedPoints.sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );
    }

    return this._lineData;
  }

  @computed get firstValueToCompare() {
    return this.initialPrice ? this.initialPrice : this.firstValue;
  }

  @computed get lastValueToCompare() {
    return this.lastValue;
  }

  @computed get isProfitable() {
    return this.reversed
      ? this.lastValueToCompare <= this.firstValueToCompare
      : this.lastValueToCompare >= this.firstValueToCompare;
  }

  @computed get direction() {
    if (this._direction) {
      return this._direction;
    }

    return this.isProfitable ? 'up' : 'down';
  }

  // NOTE: triggerPrice line, labels have the priority over showing the MC / TP lines and labels only in case
  // 1) triggerPrice elements should be on the chart:
  // - chart of  create MultiHODL form with switcher "Set pending order" in active position
  // - chart of pending MultiHODL
  // - chart of closed MultiHODL, which has trigger price
  // 2) triggerPrice elements not in domain - it prevents the overlay of the elements
  // there are no triggerPrice elements on the chart of active MultiHODL
  @computed get triggerPricePriority() {
    return (
      this.pending &&
      Boolean(this.triggerPriceLabel.layout) &&
      !this.triggerPriceLabel.layout?.isInDomain
    );
  }

  @computed get initialPriceChart() {
    // triggerPrice isn't optional in pending orders
    return this.pending
      ? this.triggerPrice!
      : this.initialPrice || this.lastValue;
  }
}
