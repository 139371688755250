import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SavingsSelectedAssetsAssetProps} from '../types';
import {SavingsSelectedAssetsFeature} from '../../Feature';
import {SavingsAsset} from '../../../../components';
import {SavingsSelectedAssetsItemEarned} from '../ItemEarned';

export const SavingsSelectedAssetsItem: React.FC<
  SavingsSelectedAssetsAssetProps & BoxProps
> = observer(({ticker, ...boxProps}) => {
  const {getAssetItemPropsByTicker} = SavingsSelectedAssetsFeature.use({});

  const {
    baseTicker,
    baseAmountFormatted,
    quoteTicker,
    quoteAmountFormatted,
    level,
    aprFormatted,
  } = getAssetItemPropsByTicker(ticker);

  return (
    <SavingsAsset
      baseTicker={baseTicker}
      baseAmountFormatted={baseAmountFormatted}
      quoteTicker={quoteTicker}
      quoteAmountFormatted={quoteAmountFormatted}
      level={level!}
      aprFormatted={aprFormatted}
      px={{default: 16, phone: 24, desktop: 0}}
      {...boxProps}
    >
      <SavingsSelectedAssetsItemEarned ticker={ticker} />
    </SavingsAsset>
  );
});
