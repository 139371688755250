import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.elements';
import {
  SavingsSliderSlideWelcome,
  SavingsSliderSlideSelectWallets,
  SavingsSliderSlideClaimRewards,
  SavingsSliderSlideIncreaseRewards,
} from './components';

const data = [
  SavingsSliderSlideWelcome,
  SavingsSliderSlideSelectWallets,
  SavingsSliderSlideClaimRewards,
  SavingsSliderSlideIncreaseRewards,
];

const renderItem = ({item: Slide}: {item: (typeof data)[number]}) => {
  return <Slide />;
};

export const SavingsSlider: React.FC<BoxProps> = React.memo(boxProps => {
  return (
    <Box flex={1} minHeight={{default: 450, desktop: 400}} {...boxProps}>
      <Slider initialIndex={0} data={data} renderItem={renderItem} />
    </Box>
  );
});
