import {invariant, warning} from '@youtoken/ui.utils';
import {AppEnv} from './AppEnv';
import {getAppEnvFromHost} from './getAppEnvFromHost';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';

export const LOCAL_STORAGE_ENV_KEY = '__APP_ENV__';

/** gets AppEnv from localStorage; will throw invariant if used incorrect env */
const getAppEnvFromLocalStorage = (): AppEnv | null => {
  // @ts-ignore
  const env: AppEnv | null = SIMPLE_STORAGE.get(LOCAL_STORAGE_ENV_KEY);

  warning(
    env !== null,
    'cannot get env from localStorage, will need to setup in runtime!'
  );

  if (!env) {
    return null;
  }

  invariant(
    Object.values(AppEnv).includes(env),
    `AppEnv="${env}" is not valid as AppEnv!`
  );

  return env as AppEnv;
};

/** sets AppEnv to localStorage; will throw invariant if used incorrect env */
const setAppEnvToLocalStorage = (env: AppEnv): void => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot assign ${env} to AppEnv`
  );

  SIMPLE_STORAGE.set(LOCAL_STORAGE_ENV_KEY, env);
};

/** @platform web */
export const getAppEnvForWeb = (): AppEnv => {
  const hostEnv = getAppEnvFromHost();

  if (hostEnv !== AppEnv.local) {
    return hostEnv;
  }

  return getAppEnvFromLocalStorage() ?? AppEnv.local;
};

/** @platform web */
export const setAppEnvForWeb = (env: AppEnv) => {
  setAppEnvToLocalStorage(env);
};
