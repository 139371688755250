import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm, Label} from '@youtoken/ui.form-elements';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const SwitchAdjustTpSlSection: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();
    const {
      form: {setAdjustTpSlActive, adjustTpSlActive},
    } = useForm<CreateHODLFormClass>();

    return (
      <Box flexDirection="row" {...boxProps}>
        <Box flexDirection="row" flexGrow={1} flexShrink={1} mr={20}>
          <Icon name="ftp" />
          <Label ml={8} mb={0}>
            {t('surface.hodls.item.adjust_tp_button')}
          </Label>
        </Box>
        <Box>
          <Switch
            value={adjustTpSlActive}
            onValueChange={setAdjustTpSlActive}
            testID="SET_ADJUST_TP_SL_SWITCHER"
          />
        </Box>
      </Box>
    );
  }
);
