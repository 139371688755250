import React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {WalletsItemFeature} from '../../../../../../../../features/WalletsItemFeature';
import {ActionsProps} from '../../types/ActionsProps';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const ActionsMobile: React.FC<ActionsProps> = observer(({ticker}) => {
  const {t} = useTranslation();
  const {
    tickerFormatted,
    isFiatTicker,
    disabledDeposit,
    disabledWithdraw,
    disabledConvert,
    disabledHodl,
    handlePressDeposit,
    handlePressWithdrawal,
    handlePressConvert,
    handlePressHodl,
    tariffsCreateMHExists,
  } = WalletsItemFeature.use({
    ticker,
  });

  return (
    <Box width="100%">
      <Box flexDirection="row" width="100%" mb={10}>
        <Box width="50%" pr={5}>
          <Button
            type="secondary"
            size="small"
            icon={isFiatTicker ? 'fiat_deposit' : 'crypto_deposit'}
            disabled={disabledDeposit}
            onPress={handlePressDeposit}
            testID={`${tickerFormatted}_DEPOSIT_BUTTON`}
          >
            {t('surface.wallets.common.deposit_button')}
          </Button>
        </Box>
        <Box width="50%" pl={5}>
          <Button
            type="secondary"
            size="small"
            icon={isFiatTicker ? 'fiat_withdraw' : 'send'}
            disabled={disabledWithdraw}
            onPress={handlePressWithdrawal}
            testID={`${tickerFormatted}_WITHDRAW_BUTTON`}
          >
            {t('surface.wallets.common.withdraw_button')}
          </Button>
        </Box>
      </Box>
      <Box flexDirection="row" width="100%">
        <Box width="50%" pr={5}>
          <Button
            type="secondary"
            size="small"
            icon="exchange"
            disabled={disabledConvert}
            onPress={handlePressConvert}
            testID={`${tickerFormatted}_CONVERT_BUTTON`}
          >
            {t('surface.wallets.common.convert_button')}
          </Button>
        </Box>
        <Box width="50%" pl={5}>
          <Button
            type="secondary"
            size="small"
            icon="hodl"
            disabled={disabledHodl}
            onPress={tariffsCreateMHExists ? handlePressHodl : undefined}
            testID={`${tickerFormatted}_BOOST_BUTTON`}
          >
            {t('surface.wallets.common.boost_button')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
