import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {StatusPage} from '@youtoken/ui.status-page';

export const DepositProcessing: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <StatusPage text={t('screens.status.deposit.processing.title')}>
      <Text mt={8} variant="$body-02" color="$text-03">
        {t('screens.status.deposit.processing.subtitle')}
      </Text>
    </StatusPage>
  );
});
