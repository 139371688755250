import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const PinIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm1.5 3a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM10 5.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm1.5 3a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-1.5 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm1.5 3a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm3.5-12a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm1.5 3a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-1.5 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fill={color}
        />
      </Svg>
    );
  }
);
