import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {ExchangeFormState} from '../../state';

export const ExchangeFormInfoTableV2: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {rateIsFixed, rateTimeIntervalName, rateTimeIntervalProgress},
    view: {
      tickerFormatted,
      conversionTickerFormatted,
      rateFormatted,
      feeFormatted,
    },
  } = useForm<ExchangeFormState>();

  return (
    <InformationTable mt={24} py={0}>
      <InformationTableRow
        testID="CONVERT_TABLE_RATE_ROW"
        left={
          rateIsFixed
            ? t('surface.wallets.conversion_form_v2.table.rate_simple')
            : t('surface.wallets.conversion_form_v2.table.rate', {
                type: t(
                  'surface.wallets.conversion_form_v2.rate_type.approximate'
                ),
              })
        }
        leftTooltipText={t(
          rateIsFixed
            ? 'surface.wallets.conversion_form_v2.rate_type.fixed_tooltip'
            : 'surface.wallets.conversion_form_v2.rate_type.approximate_tooltip'
        )}
        tooltipCaretPosition="left"
        tooltipOffset={-24}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              1
            </Text>{' '}
            {tickerFormatted} {rateIsFixed ? '=' : '≈'}{' '}
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_RATE_VALUE"
            >
              {rateFormatted}
            </Text>{' '}
            {conversionTickerFormatted}
          </Text>
        }
      />
      <Box mb={20}>
        <ProgressBar
          key={rateTimeIntervalName}
          progress={rateTimeIntervalProgress}
        />
      </Box>
      <InformationTableRow
        testID="CONVERT_TABLE_FEE_ROW"
        left={t('surface.wallets.conversion_form.table.fee')}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_FEE_VALUE"
            >
              {feeFormatted}
            </Text>{' '}
            {tickerFormatted}
          </Text>
        }
        isLast
      />
    </InformationTable>
  );
});
