import {observable} from 'mobx';
import {type ExtendTpSlFormResources, type ExtendTpSlFormArgs} from './types';
import {Form} from './Form';
import {View} from './View';

export class ExtendTpSlForm {
  @observable
  form: Form;

  @observable
  view: View;

  constructor(resources: ExtendTpSlFormResources, args: ExtendTpSlFormArgs) {
    this.form = new Form(resources, args);
    this.view = new View(this.form);
  }
}
