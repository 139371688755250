import {computed} from 'mobx';
import {
  HODLByIdResource,
  HodlLoanChainResource,
} from '@youtoken/ui.resource-hodl';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  getHodlDetailsData,
  type IAbstractHowHODLWorks,
} from '@youtoken/ui.hodls-utils';

interface HODLDetailsFeatureArgs {
  id?: string;
}

export class HODLDetailsFeature extends createFeature({
  getKey: (args: HODLDetailsFeatureArgs) =>
    `feature:hodl_detail:${JSON.stringify(args)}`,
  getResources: ({id}) => {
    return {
      hodl: getResourceDescriptor(HODLByIdResource, {id}),
      loanChain: getResourceDescriptor(HodlLoanChainResource, {
        hodlId: id,
      }),
    };
  },
}) {
  @computed.struct get hodl() {
    return this.resources.hodl.data;
  }

  @computed.struct
  get detailsData(): IAbstractHowHODLWorks {
    const {
      loansSequence,
      totalCollateralAmountFormatted,
      repaymentDueAmountFormatted,
    } = this.resources.loanChain;

    return getHodlDetailsData(
      loansSequence,
      totalCollateralAmountFormatted,
      repaymentDueAmountFormatted,
      this.hodl
    );
  }
}
