import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

type AccountLevelProgressBarHeaderProps = {
  levels: LoyaltyLevel[];
};

export const AccountLevelProgressBarHeader: React.FC<
  AccountLevelProgressBarHeaderProps & BoxProps
> = observer(({levels, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_HEADER"
      alignItems="center"
      {...boxProps}
    >
      {levels.length ? (
        <Box
          testID="ACCOUNT_LEVEL_FORECAST_LEVEL_UP"
          flexDirection="row"
          alignItems="center"
        >
          <Text variant="$body-03" color="$success-01" textAlign="center">
            {t('surface.loyalty.common.level_up')}
          </Text>
          <Box flexDirection="row">
            {levels.map(({name}, index) => {
              return (
                <Icon
                  key={name}
                  name="sorting"
                  style={{
                    transform: [{rotate: '180deg'}],
                    marginLeft: index > 0 ? -4 : 0,
                  }}
                  size={12}
                  color="$success-01"
                />
              );
            })}
          </Box>
        </Box>
      ) : (
        <Text variant="$body-03" color="$text-02" textAlign="center">
          {t('surface.loyalty.account_level')}
        </Text>
      )}
    </Box>
  );
});
