import * as React from 'react';
import {observer} from 'mobx-react';
import {useCountdown} from '@youtoken/ui.hooks';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text, TouchableText} from '@youtoken/ui.primitives';

interface ResendCodeProps {
  onResend: () => Promise<void>;
  initialCountdownSeconds?: number;
}

export const ResendCode: React.FC<ResendCodeProps & BoxProps> = observer(
  ({onResend, initialCountdownSeconds = 45, ...boxProps}) => {
    const {t} = useTranslation();

    const [isResending, setIsResending] = React.useState(false);
    const [countdownLeft, countdown] = useCountdown(
      initialCountdownSeconds * 1000
    );

    React.useEffect(() => {
      countdown.start();
    }, []);

    const handleResend = React.useCallback(() => {
      setIsResending(true);

      onResend()
        .then(() => {
          countdown.start();
          return Promise.resolve(null);
        })
        .finally(() => {
          setIsResending(false);
        });
    }, [onResend, countdown, setIsResending]);

    return (
      <Box justifyContent="center" alignItems="center" {...boxProps}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.2fa_form.not_recived')}{' '}
          {isResending && <Text>{t('surface.2fa.resending')}</Text>}
          {!isResending && countdownLeft > 0 && (
            <Text>
              {t('surface.2fa_form.resend_in', {
                leftTime: countdownLeft / 1000,
              })}
            </Text>
          )}
          {!isResending && countdownLeft <= 0 && (
            <TouchableText
              color="$interactive-01"
              onPress={handleResend}
              testID="RESEND_TFA_CODE_BUTTON"
            >
              <Text>{t('surface.2fa_form.resend')}</Text>
            </TouchableText>
          )}
        </Text>
      </Box>
    );
  }
);
