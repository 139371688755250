import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Heading, Text, Box} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {goBack} from '@web-app/src/services/shared-router';
import {Form} from './Form';

export type GetLoanPageContentProps = {};

export const GetLoanPageContent: React.FC<GetLoanPageContentProps> = cell(
  () => {
    const {t} = useTranslation();

    return (
      <Box px={{default: 20, destop: 0}}>
        <Box mb={{default: 20, tablet: 30}}>
          <Link onPress={goBack} color="$interactive-01">
            <Box
              width="100%"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Icon name="chevron_left" size={24} color="$text-05" />
              <Text color="$interactive-01" variant="$body-01-medium-accent">
                {t('surface.loans.back')}
              </Text>
            </Box>
          </Link>
          <Heading variant="$heading-01-responsive" mb={0}>
            {t('surface.loans.create_turbo_form.title')}
          </Heading>
        </Box>
        <Form />
      </Box>
    );
  }
);
