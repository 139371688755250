import {Alert, Platform} from 'react-native';
import * as LocalAuthentication from 'expo-local-authentication';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {action, computed} from 'mobx';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {i18n} from '@youtoken/ui.service-i18n';

export type LocalAuthTypes = 'FINGERPRINT' | 'FACIAL_RECOGNITION' | 'NONE';

const getAuthType: {
  FINGERPRINT:
    | 'surface.auth.setup_passcode.auth_type.android.FINGERPRINT'
    | 'surface.auth.setup_passcode.auth_type.ios.FINGERPRINT';
  FACIAL_RECOGNITION:
    | 'surface.auth.setup_passcode.auth_type.android.FACIAL_RECOGNITION'
    | 'surface.auth.setup_passcode.auth_type.ios.FACIAL_RECOGNITION';
} = Platform.select({
  android: {
    FINGERPRINT: 'surface.auth.setup_passcode.auth_type.android.FINGERPRINT',
    FACIAL_RECOGNITION:
      'surface.auth.setup_passcode.auth_type.android.FACIAL_RECOGNITION',
  },
  ios: {
    FINGERPRINT: 'surface.auth.setup_passcode.auth_type.ios.FINGERPRINT',
    FACIAL_RECOGNITION:
      'surface.auth.setup_passcode.auth_type.ios.FACIAL_RECOGNITION',
  },
})!;

const mapAuthType = (
  types: LocalAuthentication.AuthenticationType[]
): LocalAuthTypes => {
  if (!types || !types.length) {
    return 'NONE';
  }

  if (types.length === 1) {
    const type = types[0] as any as number;

    if (type === 1) {
      return 'FINGERPRINT';
    }

    if (type === 2) {
      return 'FACIAL_RECOGNITION';
    }
  }

  return 'FINGERPRINT';
};

const USER_PREFERENCE_KEY = 'haveUserOptedInToLocalAuth';

type TUserPreference = 'UNKNOWN' | 'USE_LOCAL_AUTH' | 'DONT_USE_LOCAL_AUTH';

type LocalAuthResult = {
  hasHardware: boolean;
  isEnrolled: boolean;
  canUseLocalAuth: boolean;
  localAuthType: LocalAuthTypes;
  userPreference: TUserPreference;
};

export class LocalAuthResource extends createStaticResource<
  {},
  LocalAuthResult
>({
  getKey: () => 'LocalAuthResource',
  getData: () => {
    return Promise.all([
      LocalAuthentication.hasHardwareAsync(),
      LocalAuthentication.isEnrolledAsync(),
      LocalAuthentication.supportedAuthenticationTypesAsync(),
    ])
      .then(([hasHardware, isEnrolled, authType]) => {
        const localAuthType = mapAuthType(authType);

        return {
          hasHardware,
          isEnrolled,
          localAuthType,
          canUseLocalAuth: hasHardware && isEnrolled,
          userPreference:
            (SIMPLE_STORAGE.get(USER_PREFERENCE_KEY) as TUserPreference) ??
            'UNKNOWN',
        };
      })
      .catch(() => {
        return {
          hasHardware: false,
          isEnrolled: false,
          canUseLocalAuth: false,
          localAuthType: 'NONE',
          userPreference: 'UNKNOWN',
        };
      });
  },
  shouldBeDeletedOnCleanup: true,
  skipRefreshOnVisible: true,
}) {
  @action updateLocalAuthPermission = (value: TUserPreference) => {
    this.data.userPreference = value;
    SIMPLE_STORAGE.set(USER_PREFERENCE_KEY, value);
  };

  @computed get deviceCanUseLocaAuth() {
    return (
      this.data.localAuthType !== 'NONE' &&
      this.data.hasHardware &&
      this.data.isEnrolled
    );
  }

  @computed get canUseLocalAuth() {
    return (
      this.data.canUseLocalAuth && this.data.userPreference === 'USE_LOCAL_AUTH'
    );
  }

  @computed get shouldAskForLocalAuthPermission() {
    return this.deviceCanUseLocaAuth && this.data.userPreference === 'UNKNOWN';
  }

  @action clearData = () => {
    this.updateLocalAuthPermission('UNKNOWN');
  };

  @action askForLocalAuthPermission = (): Promise<TUserPreference> => {
    if (!this.deviceCanUseLocaAuth || this.data.localAuthType == 'NONE') {
      return Promise.resolve('DONT_USE_LOCAL_AUTH');
    }

    const authType = i18n.t(
      getAuthType[
        this.data.localAuthType as 'FINGERPRINT' | 'FACIAL_RECOGNITION'
      ]
    );

    return new Promise(resolve => {
      Alert.alert(
        i18n.t('surface.auth.setup_passcode.alert.title', {authType}),
        i18n.t('surface.auth.setup_passcode.alert.description', {authType}),
        [
          {
            text: i18n.t('surface.auth.setup_passcode.alert.button.allow'),
            style: 'default',
            onPress: () => {
              this._authenticate().then(({success}) => {
                this.updateLocalAuthPermission(
                  success ? 'USE_LOCAL_AUTH' : 'DONT_USE_LOCAL_AUTH'
                );
                resolve(success ? 'USE_LOCAL_AUTH' : 'DONT_USE_LOCAL_AUTH');
              });
            },
          },
          {
            text: i18n.t('surface.auth.setup_passcode.alert.button.dont_allow'),
            style: 'cancel',
            onPress: () => {
              this.updateLocalAuthPermission('DONT_USE_LOCAL_AUTH');
              resolve('DONT_USE_LOCAL_AUTH');
            },
          },
        ],
        {
          cancelable: true,
          onDismiss: () => {
            this.updateLocalAuthPermission('DONT_USE_LOCAL_AUTH');
            resolve('DONT_USE_LOCAL_AUTH');
          },
        }
      );
    });
  };

  @action private _authenticate = (): Promise<
    {success: true} | {success: false; error?: string}
  > => {
    return LocalAuthentication.authenticateAsync({
      promptMessage: i18n.t('surface.local_auth.unlock_youhodler'),
    });
  };

  @action authenticate = (): Promise<
    {success: true} | {success: false; error?: string}
  > => {
    if (!this.canUseLocalAuth) {
      return Promise.resolve({success: false, error: 'cant use local auth'});
    }

    return this._authenticate();
  };
}
