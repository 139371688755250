import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

export const ArrSnakeImage = React.memo(
  ({color = '#212121', ...props}: SvgProps) => {
    return (
      <Svg width="1em" height="1em" viewBox="0 0 24 22" fill="none" {...props}>
        <Path
          d="M1.5 21.5H8a3 3 0 003-3v-12a3 3 0 013-3h6.5"
          stroke="#2979FF"
          strokeLinecap="round"
          strokeDasharray="2 2"
        />
        <Path
          d="M20.5 5.5l2-2-2-2"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
);
