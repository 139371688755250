import * as React from 'react';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {Label} from '../../Primitives';
import {BaseHODLChartState} from '../../logic';

export const MarginCallLabel: React.FC<{
  chartState: BaseHODLChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {colors} = useTheme();

    const {
      isCursorActive,
      marginCallLabel: {layout},
      triggerPricePriority,
    } = chartState;

    if (!layout || triggerPricePriority) {
      return null;
    }

    return (
      <Label
        x={layout.x}
        y={layout.y}
        width={layout.width}
        height={layout.height}
        textFill={colors['$chart-danger-01']}
        labelFill={
          isCursorActive ? colors['$ui-background'] : colors['$chart-danger-02']
        }
      >
        {layout.text}
      </Label>
    );
  })
);
