import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {ClickableId} from '@youtoken/ui.elements';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowIdProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemDataTableRowId: React.FC<LoanItemDataTableRowIdProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_ID"
        left={t('surface.loans.item.table.id')}
        right={
          <ClickableId
            testID="LOAN_ITEM_TABLE_ROW_ID_COPY_BUTTON"
            id={item.id}
          />
        }
      />
    );
  });
