import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Category} from '@youtoken/ui.resource-hodl-tariffs';
import {InstrumentsStarredFilter} from './InstrumentsStarredFilter';
import {CategoryFilter} from './InstrumentsCategoryFilter';

export const InstrumentsFilters: React.FC<BoxProps> = boxProps => {
  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      {...boxProps}
    >
      <InstrumentsStarredFilter />
      <CategoryFilter category={Category.ROLLOVERS} ml={4} />
      <CategoryFilter category={Category.NEW} ml={4} />
      <CategoryFilter category={Category.POPULAR} ml={4} />
    </Box>
  );
};
