import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {
  transparentScreenOptions,
  withoutHeaderScreenOptions,
  createWizard,
  type StackNavigatorScreenWizardWrappedProps,
} from '@youtoken/ui.elements';
import {DepositMethodEnum} from '../../constants';
import {FiatDepositWizardChooseTicker} from './ChooseTicker';
import {FiatDepositWizardChooseMethod} from './ChooseMethod';
import {
  getFiatDepositWizardApplePayOptions,
  FiatDepositWizardApplePay,
} from './ApplePay';
import {FiatDepositWizardBankCard} from './BankCard';
import {FiatDepositBankWireWizardBankWire} from './BankWire';
import {getFiatDepositWizardCoDiOptions, FiatDepositWizardCoDi} from './CoDi';
import {getFiatDepositWizardSPEIOptions, FiatDepositWizardSPEI} from './SPEI';
import {FiatDepositWizardAdvCash} from './AdvCash';
import {getFiatDepositBankWireWizardScreenOptions} from '../FiatDepositBankWireWizard';
import {type CardDepositMethods} from '../CardDepositWizard';
import {Providers} from '../../components';

export enum FiatDepositWizardScreenName {
  CHOOSE_TICKER = 'chooseTicker',
  CHOOSE_METHOD = 'chooseMethod',
  APPLE_PAY = DepositMethodEnum.APPLE_PAY,
  BANK_CARD = DepositMethodEnum.BANK_CARD,
  BANK_CARD_IFRAME = DepositMethodEnum.BANK_CARD_IFRAME,
  BANK_WIRE = DepositMethodEnum.BANK_WIRE,
  CODI = DepositMethodEnum.CODI,
  SPEI = DepositMethodEnum.SPEI,
  ADV_CASH = DepositMethodEnum.ADV_CASH,
}

export interface FiatDepositWizardScreenPropsWithTicker {
  ticker: string;
}

export interface FiatDepositWizardCheckoutScreensProps {
  ticker: string;
  provider: Providers;
  operationMethod: CardDepositMethods;
}

export interface FiatDepositWizardNavigator {
  [FiatDepositWizardScreenName.CHOOSE_TICKER]: {};
  [FiatDepositWizardScreenName.CHOOSE_METHOD]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.APPLE_PAY]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.BANK_CARD]: FiatDepositWizardCheckoutScreensProps;
  [FiatDepositWizardScreenName.BANK_CARD_IFRAME]: FiatDepositWizardCheckoutScreensProps;
  [FiatDepositWizardScreenName.BANK_WIRE]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.CODI]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.SPEI]: FiatDepositWizardScreenPropsWithTicker;
  [FiatDepositWizardScreenName.ADV_CASH]: FiatDepositWizardScreenPropsWithTicker;
}

const Wizard = createWizard<FiatDepositWizardNavigator>();

export interface FiatDepositWizardProps {
  ticker?: string;
  defaultStep?: FiatDepositWizardScreenName;
  onPressBack?: () => void;
}

export const FiatDepositWizard: React.FC<FiatDepositWizardProps> = cell(
  ({ticker, defaultStep, onPressBack}) => {
    const {bankWireDeposit} = AuthMeResource.use({});
    const initialName =
      defaultStep ||
      (ticker
        ? FiatDepositWizardScreenName.CHOOSE_METHOD
        : FiatDepositWizardScreenName.CHOOSE_TICKER);

    return (
      <Wizard.Navigator
        initialName={initialName}
        initialProps={{
          ticker,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={FiatDepositWizardScreenName.CHOOSE_TICKER}
          options={transparentScreenOptions}
          component={FiatDepositWizardChooseTicker}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.CHOOSE_METHOD}
          options={transparentScreenOptions}
          component={FiatDepositWizardChooseMethod}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.APPLE_PAY}
          options={getFiatDepositWizardApplePayOptions}
          component={FiatDepositWizardApplePay}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.BANK_CARD}
          options={withoutHeaderScreenOptions}
          component={FiatDepositWizardBankCard}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.BANK_CARD_IFRAME}
          options={withoutHeaderScreenOptions}
          component={FiatDepositWizardBankCard}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.BANK_WIRE}
          options={getFiatDepositBankWireWizardScreenOptions(bankWireDeposit)}
          component={FiatDepositBankWireWizardBankWire}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.CODI}
          options={getFiatDepositWizardCoDiOptions}
          component={FiatDepositWizardCoDi}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.SPEI}
          options={getFiatDepositWizardSPEIOptions}
          component={FiatDepositWizardSPEI}
        />
        <Wizard.Screen
          name={FiatDepositWizardScreenName.ADV_CASH}
          options={withoutHeaderScreenOptions}
          component={FiatDepositWizardAdvCash}
        />
      </Wizard.Navigator>
    );
  }
);

export const FiatDepositWizardWrapped = ({
  route: {
    params: {ticker, defaultStep},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<{
  ticker: string;
  defaultStep: string;
}>) => {
  return (
    <FiatDepositWizard
      ticker={ticker}
      defaultStep={defaultStep as FiatDepositWizardScreenName}
      onPressBack={goBack}
    />
  );
};
