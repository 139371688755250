import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {MenuItemBase} from './MenuItemBase';

export const RewardTermsAndConditions: React.FC<BoxProps> = observer(
  boxProps => {
    const {accountId} = AuthMeResource.use({});

    const handlePressAgreement = React.useCallback(() => {
      const url = accountId
        ? `saving-account/${accountId}`
        : 'blank/saving-account/';

      SHARED_ROUTER_SERVICE.navigate('Agreement', {
        url: `/v1/docks/agreement/${url}`,
      });
    }, [accountId]);

    const {t} = useTranslation();

    return (
      <MenuItemBase
        title={t('surface.more.item_name.reward_terms_and_conditions')}
        icon="doc_address"
        onPress={handlePressAgreement}
        RightPartComponentWeb={
          <Icon name="chevron_right" size={20} color="$text-02" />
        }
        {...boxProps}
      />
    );
  }
);
