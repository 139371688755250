import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
import Big from 'big.js';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {toBig} from '@youtoken/ui.formatting-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {calculateIncentivesDebounced} from '@youtoken/ui.incentives-utils';

import type {
  AdvCashCreatePaymentFormArgs,
  AdvCashCreatePaymentFormResources,
} from './types';

export class Form {
  @observable
  args: AdvCashCreatePaymentFormArgs;

  @observable
  resources: AdvCashCreatePaymentFormResources;

  @observable
  instance: MobxReactForm;

  @observable
  disposers: IReactionDisposer[] = [];

  @observable
  method = 'deposit';

  @observable
  provider = 'advcash';

  @observable
  incentivesBonus = toBig(0);

  // source start

  @computed
  public get ticker() {
    return this.instance?.$('ticker').get('value');
  }

  @computed
  public get amount() {
    return this.instance.$('amount').get('value');
  }

  @computed
  public get sourceError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get sourceHasError() {
    return Boolean(this.sourceError);
  }

  @computed
  public get tickerOnChange() {
    return this.instance.$('ticker').get('onChange');
  }

  @computed
  public get amountOnChange() {
    const onChange = this.instance.$('amount').get('onChange');

    return (value: string) => {
      onChange(normalizeAmountByTicker(value, this.ticker));
    };
  }

  // source end

  @computed
  public get fee() {
    return toBig(
      this.resources.feeAll.calculateFee(
        this.method,
        this.provider,
        this.ticker,
        this.ticker,
        this.amount
      )
    );
  }

  @action
  public submit = () => {
    return TRANSPORT.API.post(`/v1/deposit`, {
      provider: this.provider,
      ticker: this.ticker,
      amount: Number(this.amount),
    }).then(({data: {providerResponse: payment}}) => {
      this.args.onSubmit?.({
        ticker: this.ticker,
        amount: this.amount,
        payment,
      });
    });
  };

  public constructor(
    args: AdvCashCreatePaymentFormArgs,
    resources: AdvCashCreatePaymentFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.args.ticker,
          },
          amount: {
            value: this.args.amount || '100',
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit().catch(e => {
              handleFormSubmitError(this.instance, e);
            });
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  ticker: yup.string(),
                  amount: yup.big().gt(0),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
    this.disposers = [
      // NOTE: update bonuses value after amount changed
      reaction(
        () => this.amount,
        amount => {
          calculateIncentivesDebounced(
            amount,
            this.ticker,
            this.setIncentivesBonus
          );
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };

  @action setIncentivesBonus = (value: Big) => {
    this.incentivesBonus = value;
  };
}
