import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {LoansListRegularFeature} from '../LoansListRegularFeature';

export const LoansListRegularCreate: React.FC = observer(() => {
  const {t} = useTranslation();

  const {createNewDisabled} = LoansListRegularFeature.use({});

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('LoanCreate');
  }, []);

  return (
    <Box px={{desktop: 0, default: 20}}>
      <Button
        testID="LOAN_LIST_CREATE_BUTTON"
        size="large"
        type="secondary"
        icon="plus"
        disabled={createNewDisabled}
        onPress={handlePress}
      >
        {t('surface.loans.create_new_loan')}
      </Button>
    </Box>
  );
});
