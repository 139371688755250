import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Text, type BoxProps, Box} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {MenuItemBase} from '../MenuItemBase';
import {useTwoFaAction} from './hooks';

const isNative = Platform.select({
  native: true,
  default: false,
});

const handlePress = () => {
  if (isNative) {
    SHARED_ROUTER_SERVICE.navigate('TwoFactorSetup');
  }
};

export const TwoFA: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {twoFactorEnabled, twoFactorAuthType} = AuthMeResource.use({});

  const {
    text,
    buttonText,
    handlePress: handleButtonPress,
    testID,
  } = useTwoFaAction(twoFactorAuthType);

  return (
    <MenuItemBase
      icon="twofa"
      title={t('surface.profile.security.2fa.title')}
      subtitle={t('surface.profile.security.2fa.text')}
      badgeText={t(
        twoFactorEnabled
          ? 'surface.more.label_enabled'
          : 'surface.more.label_disabled'
      )}
      badgeVariant={twoFactorEnabled ? 'success' : 'locked'}
      onPress={handlePress}
      {...boxProps}
    >
      <Box flexDirection="row" justifyContent="flex-start" alignItems="center">
        <Button mr={12} onPress={handleButtonPress} testID={testID}>
          {buttonText}
        </Button>
        <Text variant="$body-02">{text}</Text>
      </Box>
    </MenuItemBase>
  );
});
