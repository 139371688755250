import * as React from 'react';
import {ThemeProvider} from 'styled-components';
import {Helmet} from 'react-helmet';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, useTheme} from '@youtoken/ui.primitives';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {AppShell, OuterPageShell} from '../../../layout/Shell';
import {Header} from '../../../layout/Shell/AppShell/Header';
import {ActionsMenu, Menu} from '../components';

export const ServicePagesWrapper: React.FC = ({children}) => {
  const {t} = useTranslation();
  const baseTheme = useTheme();

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Menu />;
        }}
        renderRight={() => {
          return <ActionsMenu />;
        }}
      />
    );
  }, [t]);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <ThemeProvider theme={baseTheme}>
      <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
        <OuterPageShell mt={0}>
          <Helmet
            title={
              ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']
                ? 'YouHodler | Welcome'
                : t('surface.sign_up.tab_title')
            }
          />
          <Box px={{default: 0, tablet: 0}}>{children}</Box>
        </OuterPageShell>
      </AppShell>
    </ThemeProvider>
  );
};
