import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {TickersStack} from '@youtoken/ui.elements';

const tickers = ['btc', 'eth', 'xrp', 'bnb', 'usdt'];

interface SavingsWelcomeProps {
  variant?: 'primary' | 'secondary';
}

export const SavingsWelcome: React.FC<SavingsWelcomeProps & BoxProps> = ({
  variant = 'primary',
  children,
  ...boxProps
}) => {
  return (
    <Box testID="SAVINGS_WELCOME" flex={1} {...boxProps}>
      <Text
        variant="$body-01-high-accent"
        color={variant === 'primary' ? '$text-04' : '$text-01'}
        mb={8}
      >
        {children}
      </Text>
      <TickersStack
        tickers={tickers}
        tickerBorderColor="$interactive-02"
        tickerBackgroundColor="$interactive-02"
        testID="SAVINGS_TICKERS_STACK"
      />
    </Box>
  );
};
