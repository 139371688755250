import {action, autorun, computed, observable, reaction} from 'mobx';
import {ResourceInstance} from '../types';
import {BaseResourceFeature} from './BaseResourceFeature';

/** resource status */
type Status =
  | 'IDLE'
  | 'LOADING'
  | 'LOADING_SOFT'
  | 'LOADING_HARD'
  | 'SUCCESS'
  | 'ERROR';

/** refetch mode:
 * - `"SOFT"` - (default) fetch in background, usually triggered by refetchInterval, __should not display__ loading indicator;
 * - `"HARD"` - usually by user action, like pull-to-refresh, __should display__ loading indicator;
 */
type FetchMode = 'HARD' | 'SOFT';

/** Fetcher subclass, manages calling getData from interval call (interval?) or manually by user action */
export class Fetcher<
  Args extends {},
  Result extends any
> extends BaseResourceFeature<Args, Result> {
  featureName = 'Fetcher';

  disposeOfReactionToNewArgs!: ReturnType<typeof reaction>;
  disposeOfDataObservedReaction!: ReturnType<typeof autorun>;

  onDestroy() {
    super.onDestroy();
    this.cancelRunningGetData();
    this.disposeOfReactionToNewArgs();
    this.disposeOfDataObservedReaction();
  }

  constructor(resource: ResourceInstance<Args, Result>) {
    super(resource);

    this.disposeOfReactionToNewArgs = reaction(
      () => {
        return JSON.stringify(this.resource.args);
      },
      args => {
        this.logger('reactionToArguments', args);
        this.refresh('SOFT');
      },
      {fireImmediately: false}
    );

    this.disposeOfDataObservedReaction = autorun(() => {
      if (!this.resource.isDataObserved) {
        this.cancelRunningGetData();
      }
    });
  }

  @observable error?: Error;

  @observable status: Status = 'SUCCESS';

  /** last successful rest api fetch timestamp  */
  _loadedAt: number = Date.now();

  /** running refetch promise (for the purpose of tracking and be cancelable) */
  _runningGetData!: Promise<any>;

  /** some promises can and should be canceled; this is should be safe and no-op method for optimization only
   *
   * read more: https://react-query.tanstack.com/docs/guides/query-cancellation
   * */
  @action cancelRunningGetData() {
    // this is non-standard promise method - thous ts-ignore pragma;
    // @ts-ignore
    this._runningGetData?.cancel?.('__CANCELLED_REQUEST__');
  }

  @action refetch = (mode: FetchMode = 'SOFT') => {
    this.cancelRunningGetData();

    this.status = mode === 'HARD' ? 'LOADING_HARD' : 'LOADING_SOFT';

    this.resource.onFetchStarted();

    const promise = this.resource.getData(this.resource.args);

    // @ts-ignore
    const cancel = promise.cancel;

    this._runningGetData = promise
      .then((result: Result) => {
        // trying to patch assigning result to data;
        this.resource.assignData(result);
        this.status = 'SUCCESS';
        this._loadedAt = Date.now();
        this.resource.onFetchSucceeded();

        return this.resource.data;
      })
      .catch((error: Error) => {
        this.status = 'ERROR'; // need for updates after catch
        this.resource.onFetchFailed(error);

        if (this.resource._refetchErrorPolicy === 'throw') {
          this.resource.error = error;
          throw error;
        }

        return this.resource.data;
      })
      .finally(() => {
        this.resource.onFetchFinished();
      });

    // @ts-ignore
    this._runningGetData.cancel = cancel;

    return this._runningGetData;
  };

  /** refresh manually is used by react native to display loading indicator */
  @action refreshManually = () => {
    return this.refresh('HARD');
  };

  /* refresh current data */
  @action refresh = (mode: FetchMode = 'SOFT') => {
    return this.refetch(mode);
  };

  @computed get isLoading() {
    return (
      this.status === 'LOADING' ||
      this.status === 'LOADING_HARD' ||
      this.status === 'LOADING_SOFT'
    );
  }

  @computed get isLoadingManually() {
    return this.status === 'LOADING_HARD';
  }

  @computed get isLoadingSilently() {
    return this.status === 'LOADING_SOFT';
  }

  @computed get isError() {
    return this.status === 'ERROR';
  }
}
