import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {currencySignMap} from '@youtoken/ui.resource-auth-me';
import {BankCardsFeature} from '../..';
import {useTranslation} from '@youtoken/ui.service-i18n';

const currency = 'eur';
const currencySign = currencySignMap[currency];

export const AccountBalance: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {accountBalance} = BankCardsFeature.use({});

  return (
    <Box justifyContent="center" {...boxProps}>
      {accountBalance ? (
        <>
          <Text variant="$body-03" color="$text-02">
            {t('surface.bank_cards.widget.account_balance')}
          </Text>
          <Text
            variant="$body-01-high-accent"
            color="$text-01"
            testID="BANK-CARDS_WIDGET_BALANCE"
          >
            {currencySign}
            {accountBalance}
          </Text>
        </>
      ) : (
        <Text
          variant="$body-01-high-accent"
          color="$text-01"
          testID="BANK-CARDS_WIDGET_NO-BALANCE"
        >
          {t('surface.bank_cards.title.your_cards')}
        </Text>
      )}
    </Box>
  );
});
