import * as React from 'react';
import {Box, Text, useResponsive} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {FeatureCryptoDepositExternal} from '../../state';
import {CryptoDepositExternalAddress} from '../Address';
import {CryptoDepositExternalExtraId} from '../ExtraId';

interface DetailsProps {
  ticker: string;
}

export const Details: React.FC<DetailsProps> = ({ticker}) => {
  const {t} = useTranslation();

  const {walletTickerFormatted, hasFee, feeMinAmount} =
    FeatureCryptoDepositExternal.use({
      ticker,
    });

  const questionTooltipWidth = useResponsive({
    tablet: 466,
    default: 240,
  });

  return (
    <>
      <Box flexGrow={1}>
        <CryptoDepositExternalAddress ticker={ticker} />
        <CryptoDepositExternalExtraId ticker={ticker} />
      </Box>

      {hasFee && (
        <Box alignItems="center" flexDirection="row" alignSelf="center" mt={24}>
          <QuestionTooltip
            content={t('surface.wallets.crypto_deposit.deposit_rules_tooltip', {
              amount: feeMinAmount,
              ticker: walletTickerFormatted,
            })}
            width={questionTooltipWidth}
            iconColor="$text-03"
            testID="CRYPTO_DEPOSIT_RULES"
          />
          <Text variant="$body-02" color="$text-02" ml={5}>
            {t(`surface.wallets.crypto_deposit.deposit_rules`)}
          </Text>
        </Box>
      )}
    </>
  );
};
