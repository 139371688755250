import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';

import {MarkIconWithAchieved} from '../MarkIcon';
import {invariant} from '@youtoken/ui.utils';

export type AccountLevelProgressBarContentProps = {
  value: number;
  progressBarLevels: LoyaltyProgressBarLevel[];
};

export const AccountLevelProgressBarContent: React.FC<
  AccountLevelProgressBarContentProps & BoxProps
> = observer(({value, progressBarLevels, ...boxProps}) => {
  const firstProgressBarLevel = progressBarLevels[0];
  const lastProgressBarLevel = progressBarLevels[1];

  invariant(
    firstProgressBarLevel && lastProgressBarLevel,
    'Levels are not defined'
  );

  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_CONTENT"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <MarkIconWithAchieved progressBarLevel={firstProgressBarLevel!} />
      <Box flex={1} mx={7}>
        <ProgressBar
          size="tiny"
          color={firstProgressBarLevel.isAchieved ? 'success' : 'interactive'}
          progress={value}
        />
      </Box>
      <MarkIconWithAchieved progressBarLevel={lastProgressBarLevel} />
    </Box>
  );
});
