import React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

interface DirectionItemProps {
  value: 'buy' | 'sell';
  disabled?: boolean;
  testID: string;
}

export const DirectionItem: React.FC<DirectionItemProps & BoxProps> = ({
  value,
  disabled = false,
  testID,
}) => {
  const bg = disabled
    ? '$ui-01'
    : value === 'buy'
    ? '$success-02'
    : '$danger-02';

  return (
    <Box
      flexDirection="column"
      alignItems="center"
      bg={bg}
      width={34}
      height={24}
      borderTopRightRadius={value === 'buy' ? 6 : 0}
      borderBottomRightRadius={value === 'buy' ? 6 : 0}
      borderTopLeftRadius={value === 'sell' ? 6 : 0}
      borderBottomLeftRadius={value === 'sell' ? 6 : 0}
      justifyContent="center"
      testID={testID}
    >
      <Icon
        size={20}
        color={
          disabled ? '$text-02' : value === 'buy' ? '$success-01' : '$danger-01'
        }
        name={value === 'buy' ? 'up' : 'down'}
      />
    </Box>
  );
};
