import * as React from 'react';
import {
  Box,
  type TColorTokens,
  Text,
  TouchableBox,
  TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Illustration} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';

const images = {
  money: 'illustration-7',
  multihodl: 'illustration-3',
  ibanSetup: 'illustration-11',
  ibanOpenAccount: 'illustration-15',
  loyalty: 'illustration-14',
} as const;

export type PromoBannerImageName = keyof typeof images;

type PromoBannerVariant = 'interactive' | 'interactiveSecondary';

const getColorsByVariant = (
  variant: PromoBannerVariant
): {
  bgColor: keyof TColorTokens;
  textColor: keyof TColorTokens;
  closeIconColor: keyof TColorTokens;
  buttonType: 'primary' | 'secondary' | 'ghost' | 'inverted';
} => {
  switch (variant) {
    case 'interactive':
      return {
        bgColor: '$interactive-01',
        textColor: '$text-04',
        closeIconColor: '$text-04',
        buttonType: 'inverted',
      };
    case 'interactiveSecondary':
    default:
      return {
        bgColor: '$interactive-02',
        textColor: '$text-01',
        closeIconColor: '$text-02',
        buttonType: 'primary',
      };
  }
};

export interface PromoBannerProps extends Omit<TouchableBoxProps, 'onPress'> {
  onPressBanner?: () => void;
  onPressClose?: () => void;
  showActionButton?: boolean;
  text: string;
  secondaryText?: string;
  buttonText?: string;
  imageName?: PromoBannerImageName;
  variant?: PromoBannerVariant;
}

export const PromoBanner: React.FC<PromoBannerProps> = React.memo(
  ({
    onPressBanner,
    onPressClose,
    showActionButton = false,
    text,
    secondaryText,
    buttonText,
    imageName,
    variant = 'interactiveSecondary',
    children,
    ...otherProps
  }) => {
    const hasCloseButton = Boolean(onPressClose);
    const hasSecondaryText = Boolean(secondaryText);
    const {bgColor, textColor, closeIconColor, buttonType} =
      getColorsByVariant(variant);

    return (
      <TouchableBox
        position="relative"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={64}
        py={12}
        pl={16}
        pr={hasCloseButton ? 32 : 16} // space for close button
        backgroundColor={bgColor}
        onPress={onPressBanner}
        borderRadius={12}
        {...otherProps}
      >
        {imageName && images[imageName] && (
          <Box flexShrink={0} height={40} width={40}>
            <Illustration
              name={images[imageName]}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        )}
        <Box flexShrink={1} flexGrow={1} ml={12}>
          <Text color={textColor} variant="$body-02" numberOfLines={2}>
            {hasSecondaryText && (
              <>
                <Text>{secondaryText}</Text>
                {'\n'}
              </>
            )}
            <Text variant="$body-02-high-accent" mt={hasSecondaryText ? 2 : 0}>
              {text}
            </Text>
          </Text>
        </Box>
        {showActionButton && (
          <Button
            onPress={onPressBanner}
            size="small"
            type={buttonType}
            ml={12}
          >
            {buttonText?.toUpperCase()}
          </Button>
        )}
        {hasCloseButton && (
          <TouchableBox
            position="absolute"
            top={4}
            right={4}
            width={24}
            height={24}
            alignItems="center"
            justifyContent="center"
            onPress={onPressClose}
            testID="PROMO_BANNER_CLOSE_BUTTON"
          >
            <Icon name="close" size={16} color={closeIconColor} />
          </TouchableBox>
        )}
      </TouchableBox>
    );
  }
);
