import {deserialize} from 'serializr';
import {computedFn} from 'mobx-utils';
import {invariant} from '@youtoken/ui.utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HODLMarketStatsResponse} from './HODLMarketStatsResponse';

interface HODLMarketStatsResourceArgs {
  count?: number;
}

export class HODLMarketStatsResource extends createResource<
  HODLMarketStatsResourceArgs,
  HODLMarketStatsResponse
>({
  skipRefreshOnVisible: false,
  getKey: ({count}: HODLMarketStatsResourceArgs) => `HODLMarketStats(${count})`,
  getData: ({count}: HODLMarketStatsResourceArgs) => {
    return TRANSPORT.API.get(`/v3/hodl/getMarketStatsList`, {
      params: {count},
    }).then(res => {
      return deserialize(HODLMarketStatsResponse, res.data);
    });
  },
}) {
  getMarketCapById = computedFn((id: string) => {
    const item = this.data.data.find(el => el.id === id);

    invariant(item, `could not find marketStats item with id="${id}"`);

    return item.marketCapFormatted;
  });
}
