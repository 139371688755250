import React from 'react';
import {Image} from 'react-native';

export const Cover: React.FC<React.ComponentProps<typeof Image>> = ({
  source,
}) => {
  return (
    <Image
      height={480}
      width={300}
      source={source}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
        borderRadius: 6,
      }}
    />
  );
};
