import {invariant} from '@youtoken/ui.utils';
import {AppEnv} from './AppEnv';

const webAppUrls: {
  [key in AppEnv]: string;
} = {
  local: 'dev.youhodler.com',
  development: 'dev.youhodler.com',
  stage: 'stage.youhodler.com',
  rnd: 'rnd.youhodler.com',
  production: 'app.youhodler.com',
  alpha: 'alpha.youhodler.com',
  beta: 'beta.youhodler.com',
  gamma: 'gamma.youhodler.com',
  delta: 'delta.youhodler.com',
  lambda: 'lambda.youhodler.com',
  epsilon: 'epsilon.youhodler.com',
  zeta: 'zeta.youhodler.com',
  iota: 'iota.youhodler.com',
  'core-test': 'core-test.youhodler.com',
  'core-uat': 'core-uat.youhodler.com',
  'iota-aws': 'iota-aws.youhodler.com',
  'delta-aws': 'delta-aws.youhodler.com',
  'core-test-aws': 'core-test-aws.youhodler.com',
  'core-uat-aws': 'core-uat-aws.youhodler.com',
  'zeta-aws': 'zeta-aws.youhodler.com',
  'epsilon-aws': 'epsilon-aws.youhodler.com',
  localhost: 'localhost:8088',
  'b2b-sandbox': 'b2b-sandbox.youhodler.com',
};

/** Returns backend url based on given app environment */
export const getDisplayUrl = (env: AppEnv) => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot get displayUrl for AppEnv="${env}"`
  );

  return webAppUrls[env];
};
