import * as React from 'react';
import {ScrollView as NativeScrollView, StyleSheet} from 'react-native';
import {Box, createBox} from '@youtoken/ui.primitives';
import {CustomError} from '@youtoken/ui.errors';
import {DevelopmentInfoSection} from './Section';
import {ReportingResult} from './ReportingResult';

const ScrollView = createBox(NativeScrollView);

type Props = {
  error: Error | CustomError;
  reportingResult?: {eventId: string};
};

export const DevelopmentInfoComponent: React.FC<Props> = React.memo(
  ({error, reportingResult}) => {
    return (
      <Box
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        backgroundColor="$ui-background"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          flex={1}
          width="100%"
          backgroundColor="$danger-02"
        >
          <DevelopmentInfoSection title="Message">
            {error.message}
          </DevelopmentInfoSection>

          {(error as CustomError).__type && (
            <DevelopmentInfoSection title="Error Type">
              {(error as CustomError).__type}
            </DevelopmentInfoSection>
          )}

          {(error as CustomError).__sentry && (
            <DevelopmentInfoSection title="Sentry data">
              {JSON.stringify((error as CustomError).__sentry, null, 2)}
            </DevelopmentInfoSection>
          )}

          <ReportingResult reportingResult={reportingResult} />

          {error.stack && (
            <DevelopmentInfoSection title="Stack" borderBottomWidth={0}>
              {error.stack}
            </DevelopmentInfoSection>
          )}
        </ScrollView>
      </Box>
    );
  }
);

const styles = StyleSheet.create({
  contentContainer: {
    // flex: 1,
  },
});
