import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';

export interface LabelWithValueProps {
  text: string;
  secondaryText?: string;
  value: number;
}

export const LabelWithValue: React.FC<LabelWithValueProps> = ({
  text,
  secondaryText,
  value,
}) => {
  return (
    <Box flexDirection="row" justifyContent="space-between">
      <Text variant="$body-01-high-accent">
        {text}{' '}
        {secondaryText && (
          <Text variant="$body-01" color="$ui-02">
            {secondaryText}
          </Text>
        )}
      </Text>
      <Box
        alignItems="center"
        justifyContent="center"
        backgroundColor="$interactive-03"
        borderRadius={5}
        px={8}
        height={20}
        minWidth={26}
      >
        <Text variant="$body-02-high-accent" color="$interactive-01">
          x{value}
        </Text>
      </Box>
    </Box>
  );
};
