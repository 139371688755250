import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';

export const SavingsSelectedAssetsTitle: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px={{default: 16, phone: 24, desktop: 0}}
        {...boxProps}
      >
        <Text variant="$body-02" color="$text-02">
          {t('surface.savings.your_assets')}
        </Text>
        <Text variant="$body-02" color="$text-02">
          {t('surface.savings.earned')}
        </Text>
      </Box>
    );
  }
);
