import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {i18n} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {withoutHeaderScreenOptions} from '@youtoken/ui.elements';
import {invariant} from '@youtoken/ui.utils';
import type {FiatDepositWizardProps} from '../FiatDepositWizard';
import {FiatDepositBankWireRequisitesWizard} from './Requisites';
import {FiatDepositBankWireInvoice} from './Invoice';

export const getFiatDepositBankWireWizardScreenTitle = (ticker: string) => {
  return i18n.t('surface.wallets.fiat_deposit_wire.title', {
    ticker: ticker.toUpperCase(),
  });
};

export const getFiatDepositBankWireWizardScreenOptions =
  (bankWireDeposit: {[key: string]: boolean}) =>
  ({
    route: {
      params: {ticker},
    },
  }: {
    route: {
      params: {ticker: string};
    };
  }) => {
    if (bankWireDeposit[ticker]) {
      return withoutHeaderScreenOptions;
    }

    return {
      title: getFiatDepositBankWireWizardScreenTitle(ticker),
    };
  };

export const FiatDepositBankWireWizard: React.FC<FiatDepositWizardProps> = cell(
  ({ticker, onPressBack}) => {
    const {bankWireDeposit} = AuthMeResource.use({});

    invariant(ticker, 'ticker is required', {}, {ticker});

    React.useEffect(() => {
      DATA_LAYER.trackStrict('deposit-fiat-attempt', {
        type: 'fiat',
        category: 'deposit',
        provider: 'wire',
        ticker,
      });
    }, [ticker]);

    if (bankWireDeposit[ticker as keyof typeof bankWireDeposit]) {
      return (
        <FiatDepositBankWireRequisitesWizard
          ticker={ticker}
          onPressBack={onPressBack}
        />
      );
    }

    return (
      <FiatDepositBankWireInvoice ticker={ticker} onPressBack={onPressBack} />
    );
  }
);
