import * as React from 'react';
import {observer} from 'mobx-react';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TextInput} from '@youtoken/ui.inputs';
import {SurveyForm} from '../../form';

export const SurveyTextInput: React.FC = observer(() => {
  const {
    form: {textAnswer, onChangeTextAnswer, isLoading},
  } = useForm<SurveyForm>();
  const {t} = useTranslation();

  return (
    <>
      <TextInput
        size="textarea"
        testID="TEXT_INPUT"
        multiline
        value={textAnswer}
        onChangeText={onChangeTextAnswer}
      />
      <SubmitButton
        testID="SUBMIT_BUTTON"
        size="large"
        mt={16}
        loading={isLoading}
      >
        {t('common.survey.text_input_button_label')}
      </SubmitButton>
    </>
  );
});
