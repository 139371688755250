import * as React from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SwitchWithNotFound} from '../SwitchWithNotFound';
import {alreadyAuthorized} from '../alreadyAuthorized';
import {
  LedgerLiveHodlInstrumentsPage,
  LedgerLiveLoanPage,
  LedgerLiveExchangePage,
  LedgerLiveMinerPage,
} from '../../pages';

const TradingPageWrapped = alreadyAuthorized(LedgerLiveHodlInstrumentsPage);
const ExchangePageWrapped = alreadyAuthorized(LedgerLiveExchangePage);
const LoanPageWrapped = alreadyAuthorized(() => {
  const {t} = useTranslation();
  return (
    <LedgerLiveLoanPage
      submitText={t('surface.auth.actions.sign_up')}
      url="/sign-up"
    />
  );
});

export const AppLedgerUnauthorizedRoutes: React.FC<RouteComponentProps> = ({
  match: {path},
}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/trading`} />
      </Route>
      <Route exact path={`${path}/trading`} component={TradingPageWrapped} />
      <Route exact path={`${path}/loans`} component={LoanPageWrapped} />
      <Route exact path={`${path}/exchange`} component={ExchangePageWrapped} />
      <Route exact path={`${path}/miner`} component={LedgerLiveMinerPage} />
    </SwitchWithNotFound>
  );
};
