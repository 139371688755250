import * as React from 'react';
import {type FlatListProps, Platform, FlatList} from 'react-native';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Separator, RefreshControl} from '@youtoken/ui.elements';
import {type ListItem} from './types';
import {SavingsSelectedAssetsFeature} from './Feature';
import {SavingsWidgetWithLevelsSmart} from '../WidgetWithLevels';
import {HowItWorks} from '../HowItWorks';
import {
  SavingsSelectedAssetsEarningResult,
  SavingsSelectedAssetsTotalEarned,
  SavingsSelectedAssetsTitle,
  SavingsSelectedAssetsItem,
  SavingsSelectedAssetsAction,
} from './components';

const renderItem: FlatListProps<ListItem>['renderItem'] = ({
  item: {type, data},
}) => {
  if (type === 'progress') {
    return (
      <SavingsWidgetWithLevelsSmart
        px={{default: 16, phone: 24, desktop: 0}}
        mt={{default: 8, desktop: 0}}
      />
    );
  }

  if (type === 'how-it-works') {
    return <HowItWorks px={{default: 16, phone: 24, desktop: 0}} mt={24} />;
  }

  if (type === 'period') {
    return (
      <SavingsSelectedAssetsEarningResult
        px={{default: 16, phone: 24}}
        mt={24}
      />
    );
  }

  if (type === 'earned') {
    return <SavingsSelectedAssetsTotalEarned mt={24} />;
  }

  if (type === 'title') {
    return <SavingsSelectedAssetsTitle mt={24} mb={4} />;
  }

  if (type === 'asset') {
    return <SavingsSelectedAssetsItem ticker={data!.ticker} />;
  }

  return null;
};

const keyExtractor = (item: ListItem, index: number) => {
  return `${item.type}${index}`;
};

export const SavingsSelectedAssets: React.FC<BoxProps> = observer(boxProps => {
  const {isLoadingManually, refetchManually, items} =
    SavingsSelectedAssetsFeature.use({});

  const isNative = Platform.select({
    default: false,
    native: true,
  });

  return (
    <Box
      testID="SAVINGS_SELECTED_ASSETS"
      flex={1}
      overflow={isNative ? 'hidden' : 'visible'}
      {...boxProps}
    >
      <Box flex={1}>
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={isLoadingManually}
              onRefresh={refetchManually}
            />
          }
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          style={[
            {
              flex: 1,
              //@ts-ignore
              overflowX: 'visible',
              overflowY: 'visible',
            },
          ]}
          showsVerticalScrollIndicator={true}
          showsHorizontalScrollIndicator={true}
          contentContainerStyle={{
            paddingTop: isNative ? 16 : 0,
            minHeight: '100%',
          }}
        />
      </Box>
      {isNative && <Separator />}
      <SavingsSelectedAssetsAction backgroundColor="$ui-background" />
    </Box>
  );
});
