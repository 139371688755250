import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalFormState} from '../types';

export const CryptoWithdrawalExternalFooter: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    walletTickerFormatted,
    walletTicker,
    receiveFormatted,
    feeFormatted,
    submitting,
    submit,
  } = useForm<CryptoWithdrawalFormState>();

  return (
    <Box width="100%" p={24} borderTopWidth={1} borderColor="$ui-01">
      <Box
        flexGrow={1}
        flexDirection={{tablet: 'row', default: 'column'}}
        alignItems={{tablet: 'center', default: 'flex-start'}}
      >
        <Box flexGrow={1} mb={{tablet: 0, default: 24}}>
          <Box>
            <Text variant="$body-01" lineHeight={28} color="$text-02">
              <Text variant="$heading-01" color="$text-01">
                {receiveFormatted}
              </Text>{' '}
              {walletTickerFormatted} {/* this is special case for bitcoin */}
              {/* see: WL-1984  BTC blockchain fee add info - https://yhdl.slack.com/archives/D02HPNYTTS9/p1703844171986399 */}
              {walletTicker === 'btc'
                ? t('surface.wallets.crypto_withdrawal.to_send')
                : t('surface.wallets.crypto_withdrawal.to_receive')}
            </Text>
          </Box>
          <Box>
            <Text variant="$body-02" color="$text-02">
              <Text variant="$body-02-high-accent">{feeFormatted}</Text>{' '}
              {walletTickerFormatted}{' '}
              {t('surface.wallets.crypto_withdrawal.comission')}
            </Text>
          </Box>
        </Box>
        <Button
          size="large"
          width={{tablet: 256, default: '100%'}}
          loading={submitting}
          onPress={submit}
          testID="WITHDRAW_BUTTON"
        >
          {t('surface.wallets.crypto_withdrawal.submit')}
        </Button>
      </Box>
      <Box mt={24}>
        <Text variant="$body-02" color="$text-02">
          <Trans
            t={t}
            components={{
              Title: <Text variant="$body-02-high-accent" mb={6} />,
              Paragraph: <Text />,
            }}
            i18nKey="surface.wallets.crypto_withdrawal.important_block"
          />
        </Text>
      </Box>
    </Box>
  );
});
