import * as React from 'react';
import {observer} from 'mobx-react';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {StatusPage} from '@youtoken/ui.status-page';

export const DepositSuccess: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <StatusPage
      color="$success-01"
      icon={<Icon name="success_contour" color="$success-01" size={88} />}
      text={t('screens.status.deposit.success.title')}
    />
  );
});
