import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';

export interface DetailedAgreementsProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
}

export const DetailedAgreements: React.FC<DetailedAgreementsProps> = observer(
  ({mode, id, ...rest}) => {
    const {t} = useTranslation();

    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const handlePressAgreement = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('Agreement', {url: loan.agreementUrl});
    }, [SHARED_ROUTER_SERVICE.navigate, loan.agreementUrl]);

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box alignItems="flex-start" p={20} {...rest}>
        <Button type="ghost" icon="document" onPress={handlePressAgreement}>
          {t('surface.loans.item.agreement')}
        </Button>
      </Box>
    );
  }
);
