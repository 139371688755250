import * as React from 'react';
import {Platform, type GestureResponderEvent} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {
  Box,
  TouchableBox,
  useIsMobile,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
// import {getChartProps} from './utils';
// import {WalletFeatureArgs} from '../../../../../state';
import {Summary} from '../../../_common';
import {Rate} from './Rate';
import {Actions} from './Actions';
import {ActionsAdditional} from './ActionsAdditional';
import {Wallet} from '@youtoken/ui.resource-wallets';

export interface ItemProps extends TouchableBoxProps {
  item: Wallet;
  onPressDeposit?: () => void;
  onPressWithdrawal?: () => void;
  onPressConvert?: () => void;
  onPressHodl?: () => void;
  onBringToFront?: () => void;
}

export const ItemMobile: React.FC<ItemProps> = ({
  item,
  onPress,
  onBringToFront,
  ...otherProps
}) => {
  const handlePress = (event: GestureResponderEvent) => {
    DATA_LAYER.trackStrict('wallet-open', {ticker: item.ticker});

    if (onPress) {
      onPress(event);
    }
  };

  return (
    <TouchableBox
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="100%"
      pl={20}
      onPress={handlePress}
      {...otherProps}
    >
      <Summary item={item} />
      <Box width="35%" position="relative">
        <Rate
          item={item}
          // chartProps={getChartProps()}
          width="100%"
          height="100%"
          position="absolute"
          right={32}
        />
      </Box>
    </TouchableBox>
  );
};

export const ItemDesktop: React.FC<ItemProps> = ({
  item,
  onPress,
  onPressDeposit,
  onPressWithdrawal,
  onPressConvert,
  onPressHodl,
  onBringToFront,
  ...otherProps
}) => {
  const handleChangeOpened = React.useCallback(
    (opened: boolean) => {
      if (opened && onBringToFront) {
        onBringToFront();
      }
    },
    [onBringToFront]
  );

  const handlePress = React.useCallback(
    (event: GestureResponderEvent) => {
      DATA_LAYER.trackStrict('wallet-open', {ticker: item.ticker});

      if (onPress) {
        onPress(event);
      }
    },
    [item.ticker, onPress]
  );

  const handlePressHodl = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-open-hodl', {ticker: item.ticker});

    if (onPressHodl) {
      onPressHodl();
    }
  }, [item.ticker, onPressHodl]);

  return (
    <TouchableBox
      justifyContent="center"
      width="100%"
      height="100%"
      pl={20}
      onPress={handlePress}
      {...otherProps}
    >
      <Box position="absolute" left={0} right={0} top={0} bottom={0} />
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Summary item={item} />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="62%"
          flexGrow={1}
        >
          <Box flexGrow={1} flexShrink={0} mr={40}>
            <Rate
              item={item}
              // chartProps={getChartProps('desktop')}
              // rateProps={{showEquivalentTicker: false}}
              width="100%"
            />
          </Box>
          <Actions
            ticker={item.ticker}
            onPressDeposit={onPressDeposit!}
            onPressWithdrawal={onPressWithdrawal!}
            onPressConvert={onPressConvert!}
            onPressHodl={onPressHodl ? handlePressHodl : undefined}
            onFocus={onBringToFront}
          />
          <ActionsAdditional
            ticker={item.ticker}
            onChangeOpened={handleChangeOpened}
          />
        </Box>
      </Box>
    </TouchableBox>
  );
};

export const ItemWeb: React.FC<ItemProps> = props => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <ItemMobile {...props} />;
  }

  return <ItemDesktop {...props} />;
};

export const ItemNative: React.FC<ItemProps> = ({item, ...otherProps}) => {
  return (
    <TouchableBox
      width="100%"
      height="100%"
      px={20}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      {...otherProps}
    >
      <Summary item={item} />
      <Rate item={item} />
    </TouchableBox>
  );
};

export const Item = Platform.select({
  native: ItemNative,
  default: ItemWeb,
});
