import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const BackspaceIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M14.03 6.97a.75.75 0 010 1.06L12.06 10l1.97 1.97a.75.75 0 11-1.06 1.06L11 11.06l-1.97 1.97a.75.75 0 01-1.06-1.06L9.94 10 7.97 8.03a.75.75 0 011.06-1.06L11 8.94l1.97-1.97a.75.75 0 011.06 0z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 3H5.972a2 2 0 00-1.573.764l-3.928 5a2 2 0 000 2.472l3.928 5A2 2 0 005.972 17H17a2 2 0 002-2V5a2 2 0 00-2-2zm0 1.5H5.972a.5.5 0 00-.393.191l-3.929 5a.5.5 0 000 .618l3.929 5a.5.5 0 00.393.191H17a.5.5 0 00.5-.5V5a.5.5 0 00-.5-.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
