import * as React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {invariant} from '@youtoken/ui.utils';
import {getTabTextColorVariant} from './utils';
import {type TabProps} from './types';

export const Tab: React.FC<TabProps> = ({
  index,
  setActiveIndex,
  tab,
  totalTabsCount,
  disabled = false,
  size = 'small',
  type = 'default',
}) => {
  const color = getTabTextColorVariant({type, size, disabled});

  const handlePress = React.useCallback(() => {
    setActiveIndex(index);
  }, [index, setActiveIndex]);

  invariant(
    tab.type === 'text' || tab.type === 'icon',
    `Tab must have type="icon | text", ${tab.type} was given`
  );

  return (
    <Box
      px={4}
      accessibilityRole="tab"
      flexGrow={1}
      accessible={false}
      flexBasis={`${100 / totalTabsCount}%`}
    >
      <TouchableBox
        flex={1}
        alignItems="center"
        justifyContent="center"
        onPress={handlePress}
        disabled={disabled}
        testID={tab.testID || `TAB_${tab.value?.toUpperCase()}`}
      >
        {tab.type === 'text' && (
          <Text
            accessible={false}
            color={color}
            variant={
              type === 'ghost' && size === 'tiny'
                ? '$body-02-medium-accent'
                : '$body-01'
            }
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {tab.label}
          </Text>
        )}
        {tab.type === 'icon' && <Icon name={tab.icon} color={color} />}
      </TouchableBox>
    </Box>
  );
};
