import {parse} from 'query-string';
import {maskUUID} from '../../utils';

// removes query params and uuids from url.
// https://app.youhodler.com/api/v1/transactions/0b1b1b1b-0b1b-0b1b-0b1b-0b1b1b1b1b1b?limit=10 =>
// https://app.youhodler.com/api/v1/transactions/:uuid
export function sanitizeUrl(url: string): string {
  return maskUUID(url)
    .replace(/^https:\/\/(\w)+.youhodler.com\/api/, '/api')
    .split(/[?#]/)[0]!;
}
// returs parsed query params from url
export function getQueryParams(url: string) {
  return parse(url.split(/[?#]/)[1] ?? '');
}
