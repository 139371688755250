import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const PhoneIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.282 2.544a1.75 1.75 0 012.095.886l1.06 2.12a1.75 1.75 0 01-.328 2.02l-.665.666c-.08.08-.08.171-.054.222a9.841 9.841 0 001.704 2.408 9.878 9.878 0 002.42 1.728c.052.026.143.027.224-.052l.685-.677a1.75 1.75 0 012.008-.323l2.133 1.059a1.75 1.75 0 01.889 2.1l-.1.309a3.91 3.91 0 01-4.503 2.663l-.008-.002-.008-.001A13.82 13.82 0 012.332 7.15l-.002-.01a3.898 3.898 0 012.63-4.494h.003l.319-.102zM6.035 4.1a.25.25 0 00-.3-.126l-.316.1a2.398 2.398 0 00-1.62 2.76 12.32 12.32 0 009.353 9.37 2.41 2.41 0 002.77-1.643l.002-.005.1-.312a.25.25 0 00-.127-.3l-2.133-1.058a.25.25 0 00-.287.046l-.685.677c-.499.493-1.28.666-1.955.323a11.376 11.376 0 01-2.798-2l-.008-.008-.008-.008A11.341 11.341 0 016.05 9.13c-.34-.678-.163-1.459.333-1.956l.665-.665a.25.25 0 00.047-.289L6.035 4.1z"
          fill={color}
        />
      </Svg>
    );
  }
);
