import {Platform} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const getTradingQuizUrl = (accountId: string) =>
  `https://learn.youhodler.com/categories/multi-hodl?user_id=${accountId}`;

export const openQuizByLink = (
  accountId: string,
  source: 'multihodl' | 'profile'
) => {
  const url = getTradingQuizUrl(accountId);

  DATA_LAYER.trackStrict('hodl-quiz-opened', {quizSource: source});

  Platform.select({
    web: () => {
      window.open(url, '_blank', 'noopener,noreferrer');
    },
    native: () => {
      SHARED_ROUTER_SERVICE.navigate('TradingQuiz', {
        accountId,
      });
    },
  })!();
};
