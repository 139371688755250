import React from 'react';
import {useOnMount} from '@web-app/src/hooks';
import {init} from './init';

export type VoluumInitializerProps = {};

export const VoluumInitializer: React.FC<VoluumInitializerProps> = () => {
  useOnMount(() => {
    init();
  });

  return null;
};
