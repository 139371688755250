import * as React from 'react';
import {observer} from 'mobx-react';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {UmaCreateForm} from '../../form';

export const UmaInput: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {uma, setUma, hasUmaError},
    view: {umaErrorFormatted, umaDomainFormatted},
  } = useForm<UmaCreateForm>();

  return (
    <Box px={24} pt={20} pb={24}>
      <Label mb={8}>{t('surface.wallets.uma.uma_input.label')}</Label>
      <TextInput
        placeholder={t('surface.hodls.instruments.sorter.name')}
        onChangeText={setUma}
        value={uma}
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="off"
        LeftPartComponent={
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width={8}
            height="100%"
            ml={16}
            mr={2}
          >
            <Text variant="$body-01" color="$text-02">
              $
            </Text>
          </Box>
        }
        RightPartComponent={
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            height="100%"
            pr={16}
          >
            <Text variant="$body-01" color="$text-02">
              {umaDomainFormatted}
            </Text>
          </Box>
        }
        testID="UMA_CREATE_ADDRESS_INPUT"
      />
      <FieldErrorMessage visible={hasUmaError}>
        {umaErrorFormatted}
      </FieldErrorMessage>
    </Box>
  );
});
