import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IconSocial} from '@youtoken/ui.icons';

export const LightSparkLogo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Box alignItems="center" {...boxProps}>
      <Text variant="$body-02" color="$text-02">
        {t('surface.wallets.uma.powered_by')}
      </Text>
      <IconSocial name="lightspark" size={28} color="$text-01" />
    </Box>
  );
});
