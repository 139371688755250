import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Text} from '@youtoken/ui.primitives';

type CardItemRateProps = {
  baseTicker: string;
  quoteTicker: string;
  testID?: string;
};

export const CardItemRate: React.FC<CardItemRateProps> = observer(
  ({baseTicker, quoteTicker, testID}) => {
    const {getDiffAndFormattedRate} = HODLsTariffsFeature.use({});
    const {rateWithEllipsisFormatted} = getDiffAndFormattedRate(
      baseTicker,
      quoteTicker
    );

    return (
      <Text variant="$body-02" color="$text-02" testID={testID}>
        {rateWithEllipsisFormatted}
      </Text>
    );
  }
);
