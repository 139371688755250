import React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

interface ItemProps {
  title: string;
  condition: string;
  icon: BaseIconName;
}

export const Item: React.FC<ItemProps> = ({title, condition, icon}) => {
  return (
    <Box flexDirection="row" flexGrow={1}>
      <Box width={20} mr={10}>
        <Icon name={icon} />
      </Box>
      <Box flex={1}>
        <Box mb={4}>
          <Text>{title}</Text>
        </Box>
        <Box>
          <Text variant="$body-02" color="$interactive-01">
            {condition}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
