import * as React from 'react';
import {Text} from '@youtoken/ui.primitives';

export const prepareChildren = (children: React.ReactNode) => {
  if (!Array.isArray(children)) {
    return children;
  }

  return children.map(element => {
    if (typeof element === 'string') {
      return element.split(' ').map(text => (
        <Text variant="$body-02" color="$text-02" mr={3}>
          {text}
        </Text>
      ));
    }

    return element;
  });
};
