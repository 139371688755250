import {observable} from 'mobx';
import {Form} from './Form';
import {
  TwoFactorEnableGAFormArgs,
  TwoFactorEnableGAFormResources,
} from './types';

export class TwoFactorEnableGAFormClass {
  @observable
  form: Form;

  constructor(
    args: TwoFactorEnableGAFormArgs,
    resources: TwoFactorEnableGAFormResources
  ) {
    this.form = new Form(args, resources);
  }
}
