import {cell} from '@youtoken/ui.cell';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import React from 'react';
import {HODLPageComponent} from './elements/HODLPageComponent';

export const HODLPage: React.FC = cell(() => {
  return (
    <InnerPageShell>
      <HODLPageComponent />
    </InnerPageShell>
  );
});
