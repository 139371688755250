import * as React from 'react';
import {Image, type ImageProps, PixelRatio, Platform} from 'react-native';

// 400x400, unless specified otherwise
export type IllustrationName =
  | 'illustration-1'
  | 'illustration-2'
  | 'illustration-3' // 600x400
  | 'illustration-4'
  | 'illustration-5'
  | 'illustration-6'
  | 'illustration-7'
  | 'illustration-8'
  | 'illustration-9'
  | 'illustration-10'
  | 'illustration-11'
  | 'illustration-12'
  | 'illustration-13' // 600x400
  | 'illustration-14'
  | 'illustration-15';

interface IIllustrationProps
  extends Omit<
    React.ComponentProps<typeof Image>,
    'source' | 'width' | 'height'
  > {
  /** illustration name;*/
  name: IllustrationName;
}

const getIllustrationSize = (illustrationName: IIllustrationProps['name']) => {
  if (
    illustrationName === 'illustration-3' ||
    illustrationName === 'illustration-15'
  ) {
    return {
      width: 600,
      height: 400,
    };
  }

  return {
    width: 400,
    height: 400,
  };
};

export const useIllustrationSource = (name: IllustrationName) => {
  return React.useMemo<Pick<ImageProps, 'source' | 'height' | 'width'>>(() => {
    const {width, height} = getIllustrationSize(name);
    const ratio = PixelRatio.get();
    const pixelDensityPrefix = ratio < 1.5 ? '' : ratio < 3 ? '@2x' : '@3x';

    const uri = Platform.select<string>({
      //  it's stored in the xcassets
      ios: name,
      // android - it's stored in the assets folder with different xxhdpi, xhdpi, etc. folders
      // and for some reason dashes are not supported, i.e. "illustration-1" => "illustration_1"
      android: name.replace('-', '_'),
      // web needs the full path, it's stored in the public folder; density is handled by the filename
      web: `/illustrations/${name}${pixelDensityPrefix}.png`,
    })!;

    return {
      source: {uri, height, width},
      width,
      height,
    };
  }, [name]);
};

export const Illustration: React.FC<IIllustrationProps> = ({
  name,
  style,
  ...props
}) => {
  const {source, width, height} = useIllustrationSource(name);

  return (
    <Image
      source={source}
      width={width}
      height={height}
      resizeMode="contain"
      style={[
        {
          resizeMode: 'contain',
        },
        style,
      ]}
      {...props}
    />
  );
};
