import {orderBy} from 'lodash';
import {warning} from '@youtoken/ui.utils';
import {getCoinOrderIndex, hasCoinOrderIndex} from '@youtoken/ui.coin-utils';
import {formatAndLocalizeWeekDay} from '@youtoken/ui.formatting-utils';
import {
  HodlTariffResponseItem,
  TradingHoursResponse,
} from './HodlsTariffsResponse';

export const filterTariffs = (data: HodlTariffResponseItem[]) => {
  return data.reduce((acc, tariff) => {
    const allowedInputTickers = orderBy(
      tariff.allowedInputTickers.filter(hasCoinOrderIndex),
      [getCoinOrderIndex]
    );

    const validTickers =
      hasCoinOrderIndex(tariff.baseTicker) &&
      hasCoinOrderIndex(tariff.quoteTicker);

    if (validTickers && allowedInputTickers.length) {
      tariff.allowedInputTickers = allowedInputTickers;
      acc.push(tariff);
    }

    warning(
      validTickers,
      `HodlsTariffsResource: Tariff has invalid baseTicker or quoteTicker`,
      {
        id: tariff._id,
        baseTicker: tariff.baseTicker,
        quoteTicker: tariff.quoteTicker,
      }
    );

    warning(
      allowedInputTickers.length,
      `HodlsTariffsResource: Tariff has empty allowedInputTickers`,
      {
        id: tariff._id,
      }
    );

    return acc;
  }, [] as HodlTariffResponseItem[]);
};

export const formatDaysOff = (daysOff?: TradingHoursResponse[]) => {
  if (daysOff === undefined || daysOff.length === 0) {
    return undefined;
  }

  return daysOff.map(item => ({
    dayFrom: formatAndLocalizeWeekDay(item.dayFrom),
    timeFrom: item.timeFrom,
    dayTo: formatAndLocalizeWeekDay(item.dayTo),
    timeTo: item.timeTo,
  }));
};
