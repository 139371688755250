import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Text, useAppearance} from '@youtoken/ui.primitives';
import {Separator, Switch} from '@youtoken/ui.elements';
import {BorderBoxWrapper} from '@youtoken/ui.screen-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AppearanceSwitcher} from './AppearanceSwitcher';

export const AppearanceSurface: React.FC<{}> = cell(
  () => {
    const {t} = useTranslation();
    const {osAppearance, setUserPreferredTheme, userPreferredTheme} =
      useAppearance();

    return (
      <BorderBoxWrapper>
        <Box>
          <Box
            px={{
              default: 16,
              phone: 24,
            }}
            py={16}
            flexDirection="row"
          >
            <Box flex={1}>
              <Text variant="$body-01-high-accent" mb={16}>
                {t('surface.appearance.use_device_settings')}
              </Text>
              <Text variant="$body-02" color="$text-02" mb={4}>
                {t('surface.appearance.use_device_settings_description')}
              </Text>
            </Box>
            <Box ml={16}>
              <Switch
                value={userPreferredTheme === 'system'}
                onValueChange={value => {
                  if (value) {
                    setUserPreferredTheme('system');
                  } else {
                    setUserPreferredTheme(osAppearance);
                  }
                }}
                testID="APPEARANCE_USE_DEVICE_SETTINGS_SWITCH"
              />
            </Box>
          </Box>

          <Separator />

          <Box
            px={{
              default: 16,
              phone: 24,
            }}
            py={24}
          >
            <Text variant="$body-01-high-accent" mb={16}>
              {t('surface.appearance.color_themes.title')}
            </Text>
            <AppearanceSwitcher
              disabled={userPreferredTheme === 'system'}
              selectedTheme={userPreferredTheme}
              setUserPreferredTheme={setUserPreferredTheme}
            />
          </Box>
        </Box>
      </BorderBoxWrapper>
    );
  },
  {
    displayName: 'AppearanceSurface',
  }
);

export const AppearanceSurfaceWrapper = {
  component: AppearanceSurface,
};
