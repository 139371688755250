import React from 'react';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {Box, Text} from '@youtoken/ui.primitives';

export interface TableRowProps {
  leftText: string;
  tooltipText?: string;
  testID?: string;
}

// TODO: move to forms elements
export const TableRow: React.FC<TableRowProps> = ({
  leftText,
  tooltipText,
  children,
  testID,
}) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      mb={16}
      alignItems="center"
      testID={testID}
    >
      <Box flexDirection="row" alignItems="center">
        <Text variant="$body-02" color="$text-02">
          {leftText}
        </Text>
        {Boolean(tooltipText) && (
          <Box zIndex={1}>
            <QuestionTooltip content={tooltipText!} />
          </Box>
        )}
      </Box>
      <Box height={12} justifyContent="flex-end" flexGrow={1} mx={5}>
        <Box height={1} overflow="hidden">
          <Box
            borderColor="$ui-03"
            borderStyle="dashed"
            borderWidth={1}
            height={2}
          />
        </Box>
      </Box>
      <Box flexDirection="row">{children}</Box>
    </Box>
  );
};
