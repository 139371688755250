import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Heading, Box, BoxProps} from '@youtoken/ui.primitives';

export const Header: React.FC<BoxProps> = React.memo(({...boxProps}) => {
  const {t} = useTranslation();
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <Heading variant="$heading-02-responsive" testID="WALLETS_HEADING">
        {t('surface.wallets.wallets_list.header_title')}
      </Heading>
    </Box>
  );
});
