import {action, computed} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {LoanRegularStatus} from '../Loan';
import {LoansListRegularResponse} from './LoansListRegularResponse';
import {stringify} from 'query-string';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

export interface LoansListRegularArgs {
  offset?: number;
  limit?: number;
}

export class LoansListRegularResource extends createResource<
  LoansListRegularArgs,
  LoansListRegularResponse
>({
  getKey: args => {
    return `loansRegularActive(${args.limit})`;
  },
  getData: args => {
    return TRANSPORT.API.get(`/v2/loan`, {
      params: {
        status: [
          LoanRegularStatus.PROCESSING,
          LoanRegularStatus.PENDING,
          LoanRegularStatus.OPEN,
          LoanRegularStatus.CLOSING,
          LoanRegularStatus.CLOSING,
          LoanRegularStatus.CLOSED,
          LoanRegularStatus.DECLINED,
          LoanRegularStatus.CANCELED,
        ],
        limit: args.limit ?? DEFAULT_LIMIT,
        offset: args.offset ?? DEFAULT_OFFSET,
        sort: 'createdAt',
        order: 'DESC',
      },
      paramsSerializer: params => stringify(params, {arrayFormat: 'none'}),
    }).then(response => {
      return deserialize(LoansListRegularResponse, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  @computed
  public get limit() {
    return this.args.limit ?? DEFAULT_LIMIT;
  }

  @computed
  public get activePage() {
    return this.data.currentPage + 1;
  }

  @computed
  public get totalPages() {
    return Math.ceil(this.data.total / this.limit);
  }

  @computed.struct
  public get rows() {
    return this.data.rows;
  }

  @computed.struct
  public get rowsActive() {
    return this.rows.filter(item => {
      return [
        LoanRegularStatus.PROCESSING,
        LoanRegularStatus.PENDING,
        LoanRegularStatus.OPEN,
        LoanRegularStatus.CLOSING,
        LoanRegularStatus.CLOSING,
      ].includes(item.status);
    });
  }

  @computed.struct
  public get rowsClosed() {
    return this.rows.filter(item => {
      return [
        LoanRegularStatus.CLOSED,
        LoanRegularStatus.DECLINED,
        LoanRegularStatus.CANCELED,
      ].includes(item.status);
    });
  }

  @computed
  public get hasRows() {
    return Boolean(this.rows.length);
  }

  @action
  public setActivePage = (page: number) => {
    this.args = {
      ...this.args,
      offset: this.limit * (page - 1),
    };
  };
}
