import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {type ITab, Tabs} from '@youtoken/ui.tabs';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../state/WithdrawalForm';
import {TAccountType} from '../../state/Form';

export const AccountTypeSwitcher: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {accountType, changeAccountType},
  } = useForm<WithdrawalForm>();

  const accountTypeTabs: ITab[] = React.useMemo(
    () => [
      {
        type: 'text',
        label: t('surface.wallets.withdrawal_bank_wire_form.account_type_iban'),
        value: 'iban',
        testID: 'FIAT_WITHDRAWAL_BANK_WIRE_IBAN_BUTTON',
      },
      {
        type: 'text',
        label: t(
          'surface.wallets.withdrawal_bank_wire_form.account_type_account_number'
        ),
        value: 'accountNumber',
        testID: 'FIAT_WITHDRAWAL_BANK_WIRE_ACCOUNT_NUMBER_BUTTON',
      },
    ],
    []
  );

  const activeAccountTypeIndex = React.useMemo(
    () => accountTypeTabs.findIndex(t => t.value === accountType),
    [accountType, accountTypeTabs]
  );

  return (
    <Box mb={24}>
      <Tabs
        size="small"
        activeIndex={activeAccountTypeIndex}
        onChange={value => changeAccountType(value as TAccountType)}
        tabs={accountTypeTabs}
      />
    </Box>
  );
});
