import * as React from 'react';
import {useWizard} from '@youtoken/ui.elements';
import {FiatDepositWizard} from '../..';
import {type DepositNowWizardNavigator, DepositNowWizardScreenName} from '..';

export const DepositNowWizardDepositFiat: React.FC = () => {
  const {goBack} = useWizard<
    DepositNowWizardNavigator,
    DepositNowWizardScreenName.DEPOSIT_FIAT
  >();

  return <FiatDepositWizard onPressBack={goBack} />;
};
