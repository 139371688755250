import React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Platform, ScrollView} from 'react-native';
import {useWizard} from '@youtoken/ui.elements';
import {DepositRequisites} from '@youtoken/ui.surface-bank-wire';
import {FiatDepositBankWireHeader} from '../../Header';
import {
  type FiatDepositBankWireRequisitesWizardNavigator,
  FiatDepositBankWireRequisitesWizardScreenName,
} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const FiatDepositBankWireWizardMainRequisites: React.FC = () => {
  const {
    step: {
      props: {ticker},
    },
    navigate,
    goBack,
  } = useWizard<
    FiatDepositBankWireRequisitesWizardNavigator,
    FiatDepositBankWireRequisitesWizardScreenName.MAIN_REQUISITES
  >();
  const {bottom} = useSafeAreaInsets();

  const handleShowOpenAccount = React.useCallback(() => {
    navigate(FiatDepositBankWireRequisitesWizardScreenName.OPEN_ACCOUNT, {
      ticker,
    });
  }, [ticker, navigate]);

  const handleShowAlternativeRequisites = React.useCallback(() => {
    navigate(
      FiatDepositBankWireRequisitesWizardScreenName.ALTERNATIVE_REQUISITES,
      {ticker}
    );
  }, [ticker, navigate]);

  return (
    <>
      {showHeader && (
        <FiatDepositBankWireHeader ticker={ticker} onPressBack={goBack} />
      )}
      <ScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          minHeight: '100%',
          paddingBottom: bottom,
        }}
      >
        <DepositRequisites
          ticker={ticker}
          onShowOpenAccount={handleShowOpenAccount}
          onShowAlternativeRequisites={handleShowAlternativeRequisites}
        />
      </ScrollView>
    </>
  );
};
