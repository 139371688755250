import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '../_common';
import {FormA} from '@youtoken/ui.surface-more';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const ModalFormA: typeof Modal = cell(props => {
  React.useEffect(() => {
    DATA_LAYER.trackStrict('verification-form-a-attempt', {});
  }, []);

  return (
    <Modal p={24} {...props}>
      <FormA width="100%" justifyContent="center" alignItems="stretch" />
    </Modal>
  );
});
