export interface Resource {
  new (...any: any[]): any;
  getKey(...args: any): string;
}

export function getResourceDescriptor<A extends Resource>(
  Resource: A,
  ResourceArgs: Parameters<A['getKey']>[0]
) {
  return [Resource, ResourceArgs] as const;
}
