import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {KeyboardScrollView} from '@youtoken/ui.elements';

const shouldAddScrollView = Platform.select({
  default: false,
  native: true,
});

export const UmaCellsScrollViewWrapper: React.FC<BoxProps> = observer(
  ({children, ...boxProps}) => {
    return (
      <Box flex={1} {...boxProps}>
        {shouldAddScrollView ? (
          <KeyboardScrollView
            wrapInSafeArea
            insideTabs={false}
            scrollViewProps={{
              keyboardDismissMode: 'interactive',
              style: {flex: 1},
              showsVerticalScrollIndicator: false,
              bounces: false,
              contentContainerStyle: {
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            {children}
          </KeyboardScrollView>
        ) : (
          children
        )}
      </Box>
    );
  }
);
