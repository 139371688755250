import * as React from 'react';
import {Heading, Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const AllInstrumentsHeader: React.FC<BoxProps> = ({...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Heading variant="$heading-02-responsive">
        {t('surface.hodls.instruments.all_instruments_title')}
      </Heading>
    </Box>
  );
};
