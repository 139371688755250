import * as React from 'react';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {MenuItemsProps} from './types';
import {WebMenuItems} from './WebMenuItems';
import {LedgerMenuItems} from './LedgerMenuItems';

export const MenuItems: React.FC<MenuItemsProps> = observer(({toggle}) => {
  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <LedgerMenuItems toggle={toggle} />;
  }

  return <WebMenuItems toggle={toggle} />;
});
