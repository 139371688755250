import * as React from 'react';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {AppEnv} from '@youtoken/ui.env-utils';
import {GLOBAL} from '@youtoken/ui.service-global';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {analytics} from '@web-app/src/utils/analytics/segment';
// @ts-ignore JS import
import {init} from './init';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

const getSegmentKey = (env: AppEnv) => {
  if (env === AppEnv.production) {
    return 'rxGkFfdlzVoPL0Gr4Vj48VdLZrdNTOO4';
  }

  return 'd7mACHITfPslipcu5mvTJ8PKxQS5dcmW';
};

export type SegmentInitializerProps = {};

type User = {
  isSignedIn: boolean;
  id?: string | null;
  email?: string;
  intercomHash?: string | null;
};

declare global {
  interface Window {
    mixpanel: {[key: string]: any};
    _cio: {[key: string]: any};
  }
}

const useInitializeOrDestroySegment = (isSignedIn: boolean) => {
  React.useEffect(() => {
    if (isSignedIn && !window.analytics) {
      init(getSegmentKey(ENVIRONMENT.APP_ENV));
    }

    if (!isSignedIn && window.analytics) {
      window.analytics.reset();

      // @ts-ignore
      delete window.analytics;
      // delete window.ga;
      // @ts-ignore
      delete window.mixpanel;
      // window.Intercom('shutdown');
      // delete window.Intercom;
      // @ts-ignore
      delete window._cio;
    }
  }, [isSignedIn]);
};

const useAnalyticsSetUser = (user: User) => {
  React.useEffect(() => {
    if (user.isSignedIn) {
      analytics.identify({
        userId: user.id!,
        traits: {
          id: user.id!,
          email: user.email!,
          // @ts-ignore
          osAppearance: GLOBAL.appearance.osAppearance,
          // @ts-ignore
          userPreferredTheme: GLOBAL.appearance.userPreferredTheme,
          // @ts-ignore
          activeTheme: GLOBAL.appearance.activeTheme,
        },
        context: {
          Intercom: {
            user_hash: user.intercomHash!,
          },
        },
      });
    }
  }, [user.isSignedIn, user.email]);
};

const useAnalyticsTrackPage = (pathname: string) => {
  React.useEffect(() => {
    if (window.analytics && window.analytics.page) {
      window.analytics.page();
    }
  }, [pathname]);
};

export const SegmentInitializer: React.FC<SegmentInitializerProps> = observer(
  () => {
    const {
      data: {id, email, intercomHashForCurrentPlatform},
    } = AuthMeResource.use({});

    const {token} = GLOBAL;

    const {pathname} = RouterResource.use({});

    useInitializeOrDestroySegment(Boolean(token));

    useAnalyticsSetUser({
      isSignedIn: Boolean(token),
      id,
      email,
      intercomHash: intercomHashForCurrentPlatform,
    });

    useAnalyticsTrackPage(pathname);

    return null;
  }
);
