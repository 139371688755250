import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Banner} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {SavingsSignAgreementsFormState} from '../state';
import {SavingsSignAgreementsFormImage} from './Image';

export const SavingsSignAgreementsFormContent: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {
      resources: {
        authMe: {hasSignedSavings},
      },
    } = useForm<SavingsSignAgreementsFormState>();

    return (
      <Box flex={1} {...boxProps}>
        <SavingsSignAgreementsFormImage />
        {hasSignedSavings && (
          <Banner
            testID="SAVINGS_SIGN_AGREEMENTS_FORM_INFO"
            variant="attention"
          >
            {t('surface.forms.sign_savings_agreements.info')}
          </Banner>
        )}
      </Box>
    );
  }
);
