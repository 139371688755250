import {getGlobal} from '@youtoken/ui.utils';
import {DataStorage} from './DataStorage';

declare global {
  interface __GLOBAL__ {
    __DATA_STORAGE__?: DataStorage;
  }
}

/** returns global data-storage (creates empty object if needed) */
export const getDataStorage = () => {
  const global = getGlobal();

  if (!global.__DATA_STORAGE__) {
    global.__DATA_STORAGE__ = new DataStorage();
  }

  return global.__DATA_STORAGE__;
};

export const DATA_STORAGE = getDataStorage();
