import * as React from 'react';
import {Clipboard} from '@youtoken/ui.utils';
import {Code, Box, TouchableBox} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {DevelopmentInfoSection} from './Section';
import {Icon} from '@youtoken/ui.icons';

const CopyButton: React.FC<{string: string}> = ({string}) => {
  const copy = React.useCallback(() => {
    Clipboard.setString(string);
  }, [string]);

  return (
    <TouchableBox
      onPress={copy}
      backgroundColor="$danger-03"
      borderRadius={3}
      ml={4}
      width={16}
      height={16}
      justifyContent="center"
      alignItems="center"
    >
      <Icon name="copy" color="$danger-04" size={16} />
    </TouchableBox>
  );
};

export const ReportingResult: React.FC<{
  reportingResult?: {eventId: string};
}> = ({reportingResult}) => {
  if (!reportingResult) {
    return (
      <DevelopmentInfoSection>
        Error was not reported to sentry
      </DevelopmentInfoSection>
    );
  }

  const {eventId} = reportingResult;
  const url = `https://sentry.io/organizations/youtoken/issues/?query=${eventId}`;

  return (
    <>
      <DevelopmentInfoSection title="Sentry id">
        <Box flex={1} flexDirection="row">
          <Code color="$danger-04">{eventId}</Code>
          <CopyButton string={eventId} />
        </Box>
      </DevelopmentInfoSection>

      <DevelopmentInfoSection title="Sentry url">
        <Box flex={1} flexDirection="row" flexWrap="wrap">
          <Link color="$danger-04" textDecorationLine="underline" url={url}>
            <Code color="$danger-04">open on sentry.io</Code>
          </Link>

          <CopyButton string={url} />
        </Box>
      </DevelopmentInfoSection>
    </>
  );
};
