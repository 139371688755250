import * as React from 'react';
import {SvgProps, Svg, G, Path, Defs, ClipPath} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const GooglePayIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 46 20"
        fill="none"
        {...props}
      >
        <G clipPath="url(#google-pay_svg__google-pay_svg__clip0_11550_5646)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.716 10.614v5.167h-1.667V3.003h4.333c1.056 0 2.056.389 2.834 1.111.777.667 1.166 1.667 1.166 2.722 0 1.056-.389 2-1.166 2.723a4.036 4.036 0 01-2.834 1.11l-2.666-.055zm0-6.055v4.444h2.777c.612 0 1.223-.222 1.612-.667a2.13 2.13 0 00.055-3.055l-.055-.056c-.445-.444-1-.722-1.611-.666h-2.778zm10.5 2.221c1.222 0 2.166.334 2.889 1 .722.668 1.055 1.556 1.055 2.667v5.334h-1.555v-1.222h-.056c-.667 1-1.611 1.5-2.722 1.5-.945 0-1.778-.278-2.445-.834-.61-.555-1-1.333-1-2.166 0-.89.334-1.611 1-2.167.667-.556 1.611-.778 2.723-.778 1 0 1.777.167 2.388.556v-.39c0-.555-.222-1.11-.666-1.444a2.42 2.42 0 00-1.611-.61c-.945 0-1.667.388-2.167 1.166l-1.444-.889c.888-1.167 2.055-1.722 3.61-1.722zm-2.111 6.334c0 .445.222.833.555 1.056.389.277.833.444 1.278.444.667 0 1.333-.278 1.833-.778.556-.5.834-1.11.834-1.777-.5-.39-1.223-.611-2.167-.611-.667 0-1.222.166-1.667.5-.444.277-.666.666-.666 1.166z"
            fill={color}
          />
          <Path d="M45.136 7.114l.024-.055v.055h-.024z" fill="#5F6368" />
          <Path
            d="M45.136 7.114h-1.698l-2.556 6.278h-.055l-2.611-6.278h-1.778l3.611 8.167-2.056 4.389h1.667l5.476-12.556z"
            fill={color}
          />
          <Path
            d="M15.105 9.503c0-.5-.056-1-.111-1.5H8.049v2.833h3.945c-.167.89-.667 1.723-1.445 2.223v1.833h2.39c1.388-1.278 2.166-3.167 2.166-5.389z"
            fill="#4285F4"
          />
          <Path
            d="M8.05 16.67c2 0 3.666-.667 4.888-1.778l-2.389-1.833c-.666.444-1.5.722-2.5.722-1.889 0-3.555-1.278-4.11-3.056H1.493v1.89c1.277 2.5 3.777 4.055 6.555 4.055z"
            fill="#34A853"
          />
          <Path
            d="M3.938 10.725c-.333-.889-.333-1.889 0-2.833v-1.89H1.494c-1.056 2.056-1.056 4.5 0 6.612l2.444-1.889z"
            fill="#FBBC04"
          />
          <Path
            d="M8.05 4.892c1.055 0 2.055.389 2.832 1.111l2.111-2.111C11.66 2.67 9.883 1.947 8.105 2.003c-2.778 0-5.334 1.556-6.556 4.056l2.445 1.888c.5-1.777 2.166-3.055 4.055-3.055z"
            fill="#EA4335"
          />
        </G>
        <Defs>
          <ClipPath
            id={`${_id}_google-pay_svg__google-pay_svg__clip0_11550_5646`}
          >
            <Path
              fill="#fff"
              transform="translate(.716)"
              d="M0 0h44.444v20H0z"
            />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
);
