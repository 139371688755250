import {WalletComboboxItem} from '@youtoken/ui.ticker-and-amount-input';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {getCoinName} from '@youtoken/ui.coin-utils';

export const getInputTickerOptions = (
  wallets: Wallet[]
): WalletComboboxItem[] => {
  return wallets.reduce((acc, wallet) => {
    if (wallet.umaEnabled) {
      acc.push({
        ticker: wallet.ticker,
        tickerFormatted: wallet.ticker.toUpperCase(),
        coinName: getCoinName(wallet.ticker),
        key: wallet.ticker,
      });
    }
    return acc;
  }, [] as WalletComboboxItem[]);
};
