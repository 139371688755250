import * as React from 'react';
import {Box, BoxProps, Code} from '@youtoken/ui.primitives';

export const DevelopmentInfoSection: React.FC<
  {
    title?: string;
    hint?: string;
  } & BoxProps
> = ({title, children, hint, ...props}) => {
  return (
    <Box
      p={8}
      borderBottomWidth={1}
      flexGrow={1}
      borderBottomColor="$danger-03"
      flexDirection={{
        default: 'column',
        smallPhone: 'column',
        phone: 'row',
      }}
      justifyContent="flex-start"
      alignItems="flex-start"
      {...props}
    >
      {title && (
        <Box
          flexGrow={0}
          flexShrink={0}
          mb={{
            default: 2,
            phone: 0,
          }}
        >
          <Code color="$danger-04" opacity={0.8}>
            {title}
            {hint && <Code opacity={0.6}>" " ({hint})</Code>}:{' '}
          </Code>
        </Box>
      )}
      <Box flexGrow={1} flexShrink={1}>
        {typeof children === 'string' && (
          <Code color="$danger-04">{children}</Code>
        )}
        {typeof children !== 'string' && <>{children}</>}
      </Box>
    </Box>
  );
};
