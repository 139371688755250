import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {
  BankCardAddMoneySurface,
  getBankCardAddMoneyTitle,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {modal} from '../../../stores';
import {Modal as _Modal} from '../_common';

export const modalBankCardAddMoneyName = 'bankCardAddMoney';

type ModalBankCardAddMoneyProps = {
  cardId: string;
};

export const ModalBankCardAddMoney: React.FC<
  ModalBankCardAddMoneyProps & React.ComponentProps<typeof _Modal>
> = cell(({cardId}) => {
  const {opened, close} = modal;

  return (
    <Modal
      isOpen={opened?.name === modalBankCardAddMoneyName}
      onClose={close}
      title={getBankCardAddMoneyTitle()}
      webOverlayBackgroundColor="$transparent"
    >
      <BankCardAddMoneySurface cardId={cardId} onSuccess={close} pb={24} />
    </Modal>
  );
});
