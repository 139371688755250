import * as React from 'react';
import {useParams} from 'react-router-dom';
import {InstrumentHODLSurfaceCell} from '@youtoken/ui.surface-hodl-instruments';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {cell} from '@youtoken/ui.cell';

export const HODLsInstrumentPageContent: React.FC = cell(() => {
  const {symbol} = useParams<{symbol: string}>();
  const {getInstrumentById} = HODLsTariffsFeature.use({});
  const item = getInstrumentById(symbol);

  if (!item) {
    SHARED_ROUTER_SERVICE.navigate('NotFound', {});
  }

  return <InstrumentHODLSurfaceCell instrumentId={symbol} />;
});
