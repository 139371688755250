import {observable} from 'mobx';
import {
  serializable,
  primitive,
  list,
  object,
  optional,
  custom,
  deserialize,
} from 'serializr';
import type {
  VerificationStepCode,
  StepItemType,
  StepItemStatus,
  CurrentActionStatus,
} from './types';

type TPromoItem = TCoindropPromo;

export class CoindropPromoParams {
  @observable
  @serializable(list(primitive()))
  coins!: string[];
}

export class TCoindropPromo {
  @observable
  @serializable(primitive())
  type!: 'COINDROP';

  @observable
  @serializable(object(CoindropPromoParams))
  params!: CoindropPromoParams;
}

export class StepItemParams {
  @observable
  @serializable(primitive())
  currentCode!: VerificationStepCode;

  @observable
  @serializable(primitive())
  currentStatus!: CurrentActionStatus;
}

export class TStepItem {
  @observable
  @serializable(primitive())
  type!: StepItemType;

  @observable
  @serializable(primitive())
  status!: StepItemStatus;

  @observable
  @serializable(object(StepItemParams))
  params!: StepItemParams | null;
}

export class OnboardingWidgetResponse {
  @observable
  @serializable(
    optional(
      custom(
        item => item,
        item => {
          switch (item.type) {
            case 'COINDROP':
              return deserialize(TCoindropPromo, item);
            default:
              return undefined;
          }
        }
      )
    )
  )
  promo?: TPromoItem;

  @observable
  @serializable(list(object(TStepItem)))
  steps!: TStepItem[];
}
