import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

export interface AccountLevelInfoItemTitleProps {
  icon: BaseIconName;
}

export const AccountLevelInfoItemTitle: React.FC<AccountLevelInfoItemTitleProps> =
  observer(({icon, children}) => {
    return (
      <Box flexDirection="row" alignItems="center" flex={1}>
        <Icon size={20} name={icon} />
        <Box flex={1}>
          <Text variant="$body-02-high-accent" color="$text-01" ml={4}>
            {children}
          </Text>
        </Box>
      </Box>
    );
  });
