import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';

export const useAccountLevel = () => {
  const {
    currentLevelData: {name, cardColor, cardIconName},
  } = LoyaltyResource.use({});

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('AccountLevels');
  }, []);

  return {
    name,
    cardColor,
    cardIconName,
    handlePress,
  };
};
