import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {ListItemAssetData} from '../../types';
import {SavingsSelectAssetsFeature} from '../../Feature';
import {SavingsAsset} from '../../../../components';
import {SavingsSelectAssetsListAssetAction} from './AssetAction';

export const SavingsSelectAssetsListAsset: React.FC<
  ListItemAssetData & BoxProps
> = observer(({ticker, ...boxProps}) => {
  const {getAssetItemPropsByTicker} = SavingsSelectAssetsFeature.use({});

  const {
    level,
    baseTicker,
    baseAmountFormatted,
    quoteTicker,
    quoteAmountFormatted,
    aprFormatted,
  } = getAssetItemPropsByTicker(ticker);

  return (
    <SavingsAsset
      level={level}
      baseTicker={baseTicker}
      baseAmountFormatted={baseAmountFormatted}
      quoteTicker={quoteTicker}
      quoteAmountFormatted={quoteAmountFormatted}
      aprFormatted={aprFormatted}
      px={{default: 16, phone: 24}}
      {...boxProps}
    >
      <SavingsSelectAssetsListAssetAction ticker={ticker} />
    </SavingsAsset>
  );
});
