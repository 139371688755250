import React from 'react';
import {NotificationType} from '@youtoken/ui.resource-notifications';
import {TColorTokens} from '@youtoken/ui.primitives';
import {
  type BaseIconName,
  type LoyaltyIconName,
  Icon,
  LoyaltyIcon,
} from '@youtoken/ui.icons';

export interface NotificationIconProps {
  type: NotificationType;
  name?: BaseIconName | LoyaltyIconName;
  color?: keyof TColorTokens;
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({
  type,
  name,
  color,
}) => {
  if (type === NotificationType.LOYALTY) {
    return <LoyaltyIcon name={name as LoyaltyIconName} size={24} />;
  }

  return <Icon name={name as BaseIconName} size={24} color={color} />;
};
