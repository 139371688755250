import * as React from 'react';
import {type LevelStatus} from '@youtoken/ui.resource-loyalty';
import {BoxProps} from '@youtoken/ui.primitives';
import {LevelStatusIcon} from '../LevelStatusIcon';

export type LevelStatusViewProps = {
  status: LevelStatus;
};

export const LevelStatusView: React.FC<LevelStatusViewProps & BoxProps> =
  React.memo(({status, ...boxProps}) => {
    if (status === 'locked') {
      return (
        <LevelStatusIcon
          name="lock"
          color="$ui-background"
          backgroundColor="$text-01"
          {...boxProps}
        />
      );
    }

    if (status === 'completed') {
      return (
        <LevelStatusIcon
          name="check"
          color="$text-02"
          backgroundColor="$ui-02"
          {...boxProps}
        />
      );
    }

    return null;
  });
