import * as React from 'react';
import {Listbox} from '@headlessui/react';
import {SelectProps} from './types';
import {Text, Box, useTheme} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Option} from './Option.web';
import {
  getLabelByValue,
  getSelectHeight,
  getLabelVariant,
  getBorderColor,
} from './utils';

export const Select: React.FC<SelectProps> = ({
  items,
  onSelect,
  selected,
  size = 'medium',
  width,
  dropdownWidth,
  placeholder,
  dropdownAlign = 'right',
  hasError,
  disabled,
  testID,
  withBorder = true,
  withPadding = true,
  labelComponent: LabelComponent,
}) => {
  const height = getSelectHeight(size);
  const {shadow} = useTheme();
  const selectedLabel = React.useMemo(
    () => getLabelByValue(items, placeholder, selected),
    [items, placeholder, selected]
  );
  const padding = !withPadding ? 0 : size === 'small' ? 12 : 16;

  return (
    <Listbox value={selected} onChange={onSelect} disabled={disabled}>
      {({open}) => (
        <Box
          backgroundColor="$ui-background"
          position="relative"
          zIndex={10}
          width={width}
          flex={1}
        >
          <Listbox.Button as={React.Fragment}>
            <Box
              borderColor={getBorderColor(hasError, open)}
              backgroundColor={disabled ? '$ui-01' : '$ui-background'}
              borderRadius={6}
              borderWidth={withBorder ? 1 : 0}
              width={width}
              height={height}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingLeft={padding}
              paddingRight={padding}
              // @ts-ignore
              style={{cursor: disabled ? 'default' : 'pointer'}}
              testID={testID}
            >
              {!LabelComponent && (
                <Text
                  variant={getLabelVariant(!!selected, size)}
                  color={
                    disabled ? '$text-03' : selected ? '$text-01' : '$text-03'
                  }
                >
                  {selectedLabel ? selectedLabel : placeholder}
                </Text>
              )}
              {LabelComponent ? <LabelComponent /> : null}
              <Box mt={-3} testID={`${testID}-TOGGLE`}>
                <Icon
                  name={open ? 'collapse' : 'expand'}
                  color="$text-02"
                  width={20}
                  height={10}
                  viewBox="0 0 14 14"
                />
              </Box>
            </Box>
          </Listbox.Button>
          <Listbox.Options as={React.Fragment}>
            <Box
              position="absolute"
              top={height + 2}
              right={dropdownAlign === 'right' ? 0 : 'auto'}
              left={dropdownAlign === 'left' ? 0 : 'auto'}
              backgroundColor="$ui-background"
              width={dropdownWidth ? dropdownWidth : '100%'}
              paddingTop={8}
              paddingBottom={8}
              borderRadius={8}
              zIndex={10}
              // @ts-ignore
              style={{boxShadow: shadow}}
            >
              {items.map(({label, value}) => {
                return (
                  <Option
                    key={label}
                    value={value}
                    height={height}
                    label={label}
                  />
                );
              })}
            </Box>
          </Listbox.Options>
        </Box>
      )}
    </Listbox>
  );
};
