import * as React from 'react';
import {ScrollToTopController} from './ScrollToTopController';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {useUnhandlerRejection} from '@web-app/src/hooks';
import {AppShellWeb} from './AppShellWeb';
import {AppShellLedger} from './AppShellLedger';
import {Modals} from './Modals';
import {AppShellProps} from './types';
import {ErrorBoundary} from '@youtoken/ui.error-boundary';

export const AppShell: React.FC<AppShellProps> = React.memo(props => {
  useUnhandlerRejection();

  return (
    <>
      <ErrorBoundary>
        <ScrollToTopController />
        <AppShellContent {...props} />
        <Modals />
      </ErrorBoundary>
    </>
  );
});

export const AppShellContent: React.FC<AppShellProps> = React.memo(
  ({children, country, renderHeader, renderFooter}) => {
    if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
      return (
        <AppShellLedger country={country} renderHeader={renderHeader}>
          {children}
        </AppShellLedger>
      );
    }

    return (
      <AppShellWeb
        country={country}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
      >
        {children}
      </AppShellWeb>
    );
  }
);
