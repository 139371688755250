import React from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {BorderBoxWrapper, WebPageWrapper} from '@youtoken/ui.screen-elements';
import {AddPaymentMethodSurface} from '@youtoken/ui.surfaces-wallets';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {goBack} from '../../../../services/shared-router';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export const AddPaymentMethodPage: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.wallets.add_card')} />
      <WebPageWrapper
        hasBackButton
        onBackPress={goBack}
        windowTitle={t('surface.wallets.add_card')}
        title={t('surface.wallets.add_card')}
      >
        <BorderBoxWrapper overflow="hidden">
          <AddPaymentMethodSurface onPressCancel={goBack} />
        </BorderBoxWrapper>
      </WebPageWrapper>
    </InnerPageShell>
  );
});
