import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

type GetBackSectionPropsBase = {
  amountFormatted: string;
  tickerFormatted: string;
  additionalAmountFormatted: never;
  additionalTickerFormatted: never;
};

type GetBackSectionPropsAdditional = {
  amountFormatted: string;
  tickerFormatted: string;
  additionalAmountFormatted?: string;
  additionalTickerFormatted?: string;
};

type GetBackSectionProps =
  | GetBackSectionPropsBase
  | GetBackSectionPropsAdditional;

export const GetBackSection: React.FC<GetBackSectionProps> = observer(
  ({
    amountFormatted,
    tickerFormatted,
    additionalAmountFormatted,
    additionalTickerFormatted,
  }) => {
    const {t} = useTranslation();

    return (
      <Box px={24} py={20}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          testID="HODL_CLOSE_MODAL_WILL_GET_BACK"
        >
          <Text color="$text-02">
            {t('surface.hodls.close_now.will_get_back')}
          </Text>
          <Text>
            {'≈ '}
            <Heading
              variant="$heading-02"
              testID="HODL_CLOSE_MODAL_WILL_GET_BACK_VALUE"
            >
              {`${amountFormatted} `}
            </Heading>
            <Heading
              variant="$heading-02"
              color="$text-02"
              testID="HODL_CLOSE_MODAL_WILL_GET_BACK_TICKER"
            >
              {tickerFormatted}
            </Heading>
          </Text>
        </Box>
        {Boolean(additionalAmountFormatted && additionalTickerFormatted) && (
          <Box
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            testID="HODL_CLOSE_MODAL_WILL_GET_BACK_BONUSES"
          >
            <Text>
              {'+ '}
              <Text
                variant="$body-01-high-accent"
                testID="HODL_CLOSE_MODAL_WILL_GET_BACK_BONUSES_VALUE"
              >
                {`${additionalAmountFormatted} `}
              </Text>
              <Text
                variant="$body-02"
                color="$text-02"
                testID="HODL_CLOSE_MODAL_WILL_GET_BACK_BONUSES_TICKER"
              >
                {additionalTickerFormatted}
              </Text>
            </Text>
          </Box>
        )}
      </Box>
    );
  }
);
