import React from 'react';
import {Modal} from '../_common';
import {SessionModalCell} from '@youtoken/ui.surface-trusted-devices';

interface ModalSessionDetailsProps extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalSessionDetails: React.FC<ModalSessionDetailsProps> = ({
  id,
}) => {
  return (
    <Modal maxWidth={444} alignItems="stretch">
      <SessionModalCell id={id} />
    </Modal>
  );
};
