import {
  formatByAmountAndTicker,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';

export const getHODLResult = (
  ticker: string,
  amount?: number,
  percent?: number,
  isOpen?: boolean
): {
  resultAmount?: string;
  resultTicker: string;
  resultPercent?: string;
  resultDirection: 'up' | 'down' | 'neutral';
} => {
  let resultAmount = undefined;
  let resultPercent = undefined;
  let resultDirection: 'up' | 'down' | 'neutral' = 'neutral';

  if (amount !== undefined) {
    const amountBig = toBig(amount);
    const prefix = amountBig.gt(0) ? '+' : '';
    const amountFormatted = formatByAmountAndTicker(amountBig, ticker);

    resultAmount = `${prefix}${amountFormatted}`;
    // NOTE: show red color only for active loss-deals
    resultDirection = amountBig.gte(0) ? 'up' : isOpen ? 'down' : 'neutral';
  }

  if (percent !== undefined) {
    const percentBig = toBig(percent).abs();

    resultPercent = formatPercent(percentBig);
  }

  return {
    resultAmount,
    resultTicker: ticker.toUpperCase(),
    resultPercent,
    resultDirection,
  };
};
