import {action, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {HistoryListResource} from '@youtoken/ui.resource-history-new';
import {type TransactionType} from '../types';

type AccountStatsFeatureArgs = {};

export class AccountStatsFeature extends createFeature({
  getKey: (args: AccountStatsFeatureArgs) => {
    return `accountStatsFeature`;
  },
  getResources: (args: AccountStatsFeatureArgs) => {
    return {
      historyList: getResourceDescriptor(HistoryListResource, {
        key: 'accountStats',
      }),
    };
  },
}) {
  @observable selectedTickers: string[] = [];
  @observable selectedTypes: TransactionType[] = [];

  @action applyFilters = ({
    selectedTickers,
    selectedTypes,
  }: {
    selectedTickers: string[];
    selectedTypes: TransactionType[];
  }) => {
    this.selectedTickers = selectedTickers;
    this.selectedTypes = selectedTypes;

    this.resources.historyList.resetPagination();
    this.resources.historyList.updateArgs({
      ticker: selectedTickers,
      type: selectedTypes,
    });
  };

  getTickerFormatted = (ticker: string) => {
    return ticker === 'bonus' ? 'USD' : ticker.toUpperCase();
  };
}
