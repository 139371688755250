import {action, observable, computed} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {TRANSPORT} from '@youtoken/ui.transport';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';

export class Form {
  @observable
  public args: {};

  @observable
  public resources: {
    authMe: AuthMeResource;
  };

  @observable
  public instance: MobxReactForm;

  @computed
  public get antiPhishingCode() {
    return this.instance.$('antiPhishingCode');
  }

  @action
  public handleSubmit = () => {
    return TRANSPORT.API.post('/v1/security/enable-ap', {
      code: this.antiPhishingCode.get('value'),
    });
  };

  @action
  public handleError = () => {
    return Promise.resolve();
  };

  public constructor(args: {}, resources: {authMe: AuthMeResource}) {
    this.args = args;
    this.resources = resources;

    this.instance = new MobxReactForm(
      {
        fields: {
          antiPhishingCode: {
            name: 'antiPhishingCode',
            label: 'Anti-Phishing Code',
            value: '',
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.handleSubmit();
          },
          onError: () => {
            return this.handleError();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  antiPhishingCode: yup
                    .string()
                    .required(
                      i18n.t(
                        'surface.profile.security.antiphishing.error.required'
                      )
                    )
                    .min(
                      6,
                      i18n.t(
                        'surface.profile.security.antiphishing.error.format'
                      )
                    )
                    .max(
                      15,
                      i18n.t(
                        'surface.profile.security.antiphishing.error.format'
                      )
                    )
                    .matches(
                      /^[A-Za-z0-9]+$/gi,
                      i18n.t(
                        'surface.profile.security.antiphishing.error.format'
                      )
                    ),
                };
                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );
  }
}
