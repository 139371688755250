import {observable} from 'mobx';
import {serializable, primitive} from 'serializr';

export class LocationData {
  // @observable
  // @serializable(primitive())
  // eu!: string; // todo what is this?
  //
  // @observable
  // @serializable(list(primitive()))
  // ll!: string; // todo what is this?

  @observable
  @serializable(primitive())
  country!: string; // todo check for "Unknown"

  @observable
  @serializable(primitive())
  region!: string; // todo check for "Unknown"

  @observable
  @serializable(primitive())
  city!: string; // todo check for "Unknown"
  // "eu": "0",
  // "ll": [],
  // "area": 0,
  // "city": "Unknown",
  // "metro": 0,
  // "range": [],
  // "region": "Unknown",
  // "country": "Unknown",
  // "timezone": "Unknown"
}
