import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {SavingsSignAgreementsFormState} from '../state';
import {SavingsSignAgreementsFormAgree} from './Agree';

export const SavingsSignAgreementsFormFooter: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {submitting, submit} = useForm<SavingsSignAgreementsFormState>();

    return (
      <Box
        p={{default: 16, phone: 24}}
        borderTopWidth={1}
        borderColor="$ui-01"
        {...boxProps}
      >
        <SavingsSignAgreementsFormAgree />
        <Button
          testID="SAVINGS_SIGN_AGREEMENTS_FORM_SUBMIT_BUTTON"
          size="large"
          loading={submitting}
          onPress={submit}
        >
          {t('surface.forms.sign_savings_agreements.action')}
        </Button>
      </Box>
    );
  }
);
