import * as React from 'react';
import {Title} from '@web-app/src/components/layout/Shell';
import {Button} from '@youtoken/ui.buttons';
import {Text, Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {Illustration} from '@youtoken/ui.elements';

export const NotFoundPage: React.FC = () => {
  const {t} = useTranslation();

  const {push} = RouterResource.use({});

  const handleClickToMain = React.useCallback(() => {
    push('/');
  }, []);

  return (
    <Box width="100%" my={{default: 0, tablet: 30}} p={20}>
      <Box
        width={{default: '100%', tablet: 400}}
        alignSelf="center"
        alignItems="center"
      >
        <Box mb={20} width={{default: '100%', tablet: 400}}>
          <Illustration name="illustration-12" />
        </Box>
        <Title mb={20}>{t('surface.not_found.title')}</Title>
        <Text mb={20} textAlign="center">
          {t('surface.not_found.description')}
        </Text>
        <Button size="large" onPress={handleClickToMain}>
          {t('surface.not_found.go_to_main_page')}
        </Button>
      </Box>
    </Box>
  );
};
