import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';
import {GLOBAL} from '@youtoken/ui.service-global';
import {AuthMeResource, KYCStatus} from '@youtoken/ui.resource-auth-me';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {RouterResource} from '../RouterResource';

export const RootRedirect: React.FC<{}> = observer(() => {
  const {
    data: {emailConfirmRequired, emailResult, kycResult, forceIdentify},
  } = AuthMeResource.use({});

  const {token} = GLOBAL;

  const {search} = RouterResource.use({});

  const notSignedInRedirectDestination = React.useMemo(
    () =>
      ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']
        ? '/welcome'
        : '/sign-in',
    []
  );

  const signedInRedirectDestination = React.useMemo(() => {
    if (forceIdentify) {
      return '/please-verify';
    }
    return kycResult === KYCStatus.ACCEPT ? '/wallets' : '/profile';
  }, [kycResult, forceIdentify]);

  const redirectAddress = React.useMemo(() => {
    return Boolean(token)
      ? emailConfirmRequired && emailResult !== 'ACCEPT'
        ? `/verify/email${search}`
        : `${signedInRedirectDestination}${search}`
      : `${notSignedInRedirectDestination}${search}`;
  }, [
    token,
    emailConfirmRequired,
    emailResult,
    search,
    signedInRedirectDestination,
    notSignedInRedirectDestination,
  ]);

  return <Redirect to={redirectAddress} />;
});
