import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CloseIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.125 14.875a.884.884 0 010-1.25L8.75 10 5.125 6.375a.884.884 0 111.25-1.25L10 8.75l3.625-3.625a.884.884 0 111.25 1.25L11.25 10l3.625 3.625a.884.884 0 01-1.25 1.25L10 11.25l-3.625 3.625a.884.884 0 01-1.25 0z"
          fill={color}
        />
      </Svg>
    );
  }
);
