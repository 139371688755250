import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';

export const SectionTitle: React.FC<BoxProps & {children: string}> = ({
  children,
  ...boxProps
}) => {
  return (
    <Box
      backgroundColor="$ui-overflow"
      height={40}
      pl={24}
      flexDirection="row"
      alignItems="center"
      {...boxProps}
    >
      <Text variant="$body-02" color="$text-02">
        {children.toUpperCase()}
      </Text>
    </Box>
  );
};
