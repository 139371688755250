import * as React from 'react';
import {G} from 'react-native-svg';
import {observer} from 'mobx-react';
import {WalletChartState} from '../../logic';
import {Candle, type CandleProps} from './Candle';
import {useChartThemeContext} from '../../Providers';
import {type ChartDirection} from '../../logic';

interface RenderCandleProps extends Omit<CandleProps, 'color'> {
  direction: ChartDirection;
}

export const DataCandles: React.FC<{
  chartState: WalletChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {
      candles: {dataCandles},
      candlesCursor: {cursorActive},
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    const renderCandle = React.useCallback(
      (
        {
          direction,
          x,
          yHigh,
          yBody,
          bodyHeight,
          bodyWidth,
          yLow,
        }: RenderCandleProps,
        index: number
      ) => {
        return (
          <Candle
            key={`${index}`}
            x={x}
            bodyHeight={bodyHeight}
            yHigh={yHigh}
            yBody={yBody}
            bodyWidth={bodyWidth}
            yLow={yLow}
            color={getColorByDirection(direction)}
          />
        );
      },
      []
    );

    const candles = React.useMemo(() => {
      if (!dataCandles) {
        return null;
      }

      return dataCandles.map(renderCandle);
    }, [dataCandles]);

    return <G opacity={cursorActive ? 0.4 : 1}>{candles}</G>;
  })
);
