import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  type TouchableBoxProps,
  type BoxProps,
  Box,
  Text,
  TouchableBox,
} from '@youtoken/ui.primitives';
import {LoyaltyIcon, Icon} from '@youtoken/ui.icons';
import {cell} from '@youtoken/ui.cell';
import {useAccountLevel} from './hooks';

export interface AccountLevelFullProps extends TouchableBoxProps {
  size?: 'small' | 'large';
}

export const AccountLevelFull: React.FC<AccountLevelFullProps> = cell(
  ({size = 'small'}) => {
    const {t} = useTranslation();

    const {name, cardColor, cardIconName, handlePress} = useAccountLevel();

    return (
      <TouchableBox
        testID="ACCOUNT_LEVEL_FULL"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
        px={size === 'small' ? 12 : 16}
        py={size === 'small' ? 8 : 12}
        backgroundColor={cardColor}
        borderRadius={12}
        onPress={handlePress}
      >
        <LoyaltyIcon name={cardIconName} size={size === 'small' ? 40 : 48} />
        <Box flex={1} mx={8}>
          <Text
            variant="$body-03"
            color="$text-02"
            mb={size === 'small' ? 0 : 2}
          >
            {t('surface.loyalty.account_level')}
          </Text>
          <Text
            variant={size === 'small' ? '$body-02-high-accent' : '$heading-02'}
          >
            {name}
          </Text>
        </Box>
        <Icon name="chevron_right" color="$text-02" size={20} />
      </TouchableBox>
    );
  },
  {
    CellWrap: ({children, size = 'small', ...boxProps}) => {
      return (
        //  NOTE: minHeight for error message
        <Box
          flex={1}
          minHeight={size === 'small' ? 56 : 72}
          {...(boxProps as BoxProps)}
        >
          {children}
        </Box>
      );
    },
  }
);
