import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {LoansList, LoansDetails, LoansCreate} from '../pages';

const LoansListWrapped = authRequired(LoansList);
const LoansDetailsWrapped = authRequired(LoansDetails);
const LoansCreateWrapped = authRequired(LoansCreate);

export const LoansRoutes: React.FC<RouteComponentProps> = ({match: {path}}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}`} component={LoansListWrapped} />
      <Route exact path={`${path}/create`} component={LoansCreateWrapped} />
      <Route exact path={`${path}/:id`} component={LoansDetailsWrapped} />
    </SwitchWithNotFound>
  );
};
