import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {ApplePayDepositForm} from './state';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {WithdrawSumField} from './components';
import {Separator} from '@youtoken/ui.elements';
import {Submit} from './components';
import {FeeRow} from './components';
import {PaySumRow} from './components';
import {IApplePayDepositSurfaceProps} from './types';
import {APPLE_PAY_SERVICE} from '@youtoken/ui.apple-pay-service';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const ApplePayDepositWebSurface: React.FC<
  IApplePayDepositSurfaceProps
> = ({ticker}) => {
  const {t} = useTranslation();

  const resources = useResources({
    feeAll: getResourceDescriptor(FeeAllResource, {}),
    authMe: getResourceDescriptor(AuthMeResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
  });

  React.useEffect(() => {
    DATA_LAYER.trackStrict('deposit-fiat-attempt', {
      type: 'fiat',
      category: 'deposit',
      provider: 'unlimintApplePay',
      ticker,
    });
  }, [ticker]);

  const {view, form} = makeForm(() => {
    return new ApplePayDepositForm({ticker}, resources);
  });

  const {tickerFees} = form;
  const {
    tickerFormatted,
    paySumFormatted,
    feeValueFormatted,
    feePercentFormatted,
  } = view;

  const handleError = React.useCallback(() => {
    LOCAL_NOTIFICATIONS.error({text: t('common.errors.smth_went_wrong')});
  }, []);

  const handleSuccessOrPending = React.useCallback(() => {
    LOCAL_NOTIFICATIONS.info({
      text: t('surface.apple_pay.deposit.success_message'),
    });
    SHARED_ROUTER_SERVICE.navigate('__CloseModal');
  }, []);

  React.useEffect(() => {
    APPLE_PAY_SERVICE.events.on('applePaySessionCanceled', handleError);
    APPLE_PAY_SERVICE.events.on('paymentError', handleError);
    APPLE_PAY_SERVICE.events.on('paymentSuccess', handleSuccessOrPending);
    APPLE_PAY_SERVICE.events.on('paymentPending', handleSuccessOrPending);
    return () => {
      APPLE_PAY_SERVICE.events.removeListener(
        'applePaySessionCanceled',
        handleError
      );
      APPLE_PAY_SERVICE.events.removeListener(
        'paymentSuccess',
        handleSuccessOrPending
      );
      APPLE_PAY_SERVICE.events.removeListener(
        'paymentPending',
        handleSuccessOrPending
      );
      APPLE_PAY_SERVICE.events.removeListener('paymentError', handleError);
    };
  }, [handleError, handleSuccessOrPending]);

  if (!tickerFees) {
    return null;
  }

  return (
    <Box>
      <Form form={{view, form}}>
        <Box width="100%" flexGrow={1}>
          <Box>
            <Box width="100%">
              <WithdrawSumField mb={24} />
              <FeeRow
                percent={feePercentFormatted}
                value={feeValueFormatted}
                ticker={tickerFormatted}
              />
              <Separator />
              <PaySumRow value={paySumFormatted} ticker={tickerFormatted} />
              <Separator mb={24} />
              <Submit />
            </Box>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};
