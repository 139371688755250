import * as React from 'react';
import {createBox} from '@youtoken/ui.primitives';
import {IButtonProps} from '../types';
import {useButtonStyleProps} from './styles';
import {TouchableOpacity} from 'react-native';

const Wrapper = createBox(TouchableOpacity);

export const TouchableComponent: React.FC<
  React.ComponentProps<typeof Wrapper> & IButtonProps
> = ({children, onPress, disabled, loading, pressable, ...props}) => {
  const styleProps = useButtonStyleProps({disabled, ...props});

  return (
    <Wrapper
      accessible
      accessibilityRole="button"
      accessibilityState={{
        disabled: disabled,
        busy: loading,
      }}
      disabled={disabled || loading || !pressable}
      activeOpacity={disabled || loading ? 1 : 0.8}
      onPress={onPress}
      {...styleProps}
      {...props}
    >
      {children}
    </Wrapper>
  );
};
