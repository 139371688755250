import * as React from 'react';
import {observer} from 'mobx-react';
import {G, Defs, LinearGradient, Stop, Rect} from 'react-native-svg';
import {useChartThemeContext} from '../../Providers';
import {BaseHODLChartState, ChartDirection} from '../../logic';

export type HighlightAreaProps = {
  chartState: BaseHODLChartState;
};

const uid = (): string => Math.random().toString(36).substring(6);

const Gradient: React.FC<{
  gradientDirection: ChartDirection;
  color: string;
  // startIntensity: number;
  endIntensity: number;
  id: string;
}> = ({gradientDirection, color, endIntensity, id}) => {
  return (
    <LinearGradient
      id={id}
      x1="50%"
      x2="50%"
      y1={gradientDirection === 'up' ? 0 : '100%'}
      y2={gradientDirection === 'up' ? '100%' : 0}
    >
      <Stop stopColor={color} stopOpacity={endIntensity} offset="0%" />
      <Stop stopColor={color} stopOpacity={1} offset="100%" />
    </LinearGradient>
  );
};

export const HODLHighlightArea: React.FC<HighlightAreaProps> = observer(
  ({chartState}) => {
    const {
      HODLHighlightArea: {layout},
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    if (!layout) {
      return null;
    }

    const color = getColorByDirection(layout.direction);

    const id = `fill${uid()}`;

    return (
      <G>
        <Defs>
          <Gradient
            id={id}
            gradientDirection={layout.gradientDirection}
            color={color}
            endIntensity={layout.endIntensity}
          />
        </Defs>

        <Rect
          fill={`url(#${id})`}
          opacity={0.14}
          x={layout.x}
          y={layout.y}
          width={layout.width}
          height={layout.height}
        ></Rect>
      </G>
    );
  }
);
