import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Modal} from '@youtoken/ui.modal';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Link} from '@youtoken/ui.elements';
import {SavingsV4VideoPreview} from '@youtoken/ui.video-preview';

type HowItWorksModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const HowItWorksModal: React.FC<HowItWorksModalProps> = observer(
  ({isOpen, onClose}) => {
    const {t} = useTranslation();

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['CONTENT_HEIGHT']}
        shouldUseAnimatedSnapPoints
        title={t('surface.savings.how_it_works_modal.title')}
        webWidth={550}
      >
        <Box p={24}>
          <Box justifyContent="center" alignItems="center">
            <SavingsV4VideoPreview />
          </Box>
          <Text variant="$body-01" color="$text-01" mt={24}>
            {t('surface.savings.how_it_works_modal.primary_text')}
          </Text>
          <Text variant="$body-01" color="$text-02" mt={24}>
            <Trans
              t={t}
              components={{
                Link: (
                  <Link url="https://help.youhodler.com/en/collections/1948454-yield-account" />
                ),
              }}
              i18nKey="surface.savings.how_it_works_modal.secondary_text"
            />
          </Text>
        </Box>
      </Modal>
    );
  }
);
