import * as React from 'react';
import {useTheme} from '@youtoken/ui.primitives';
import {type Type, type Color, type IButtonComponentsProps} from '../types';
import {ActivityIndicator, View} from 'react-native';

const useActivityIndicatorColor = (props: IButtonComponentsProps) => {
  const {colors} = useTheme();

  return React.useMemo(() => {
    return (
      {
        interactive: {
          primary: colors['$text-04'],
          secondary: colors['$interactive-01'],
          ghost: colors['$interactive-01'],
          inverted: colors['$interactive-01'],
        },
        'external-auth': {
          primary: colors['$text-04'],
          secondary: colors['$interactive-01'],
          ghost: colors['$interactive-01'],
          inverted: colors['$interactive-01'],
        },
        success: {
          primary: colors['$text-04'],
          secondary: colors['$success-01'],
          ghost: colors['$success-01'],
          inverted: colors['$success-01'],
        },
        danger: {
          primary: colors['$text-04'],
          secondary: colors['$danger-01'],
          ghost: colors['$danger-01'],
          inverted: colors['$danger-01'],
        },
        attention: {
          primary: colors['$text-04'],
          secondary: colors['$attention-01'],
          ghost: colors['$attention-01'],
          inverted: colors['$attention-01'],
        },
        'apple-pay': {
          primary: colors['$text-06'],
          secondary: colors['$text-06'],
          ghost: colors['$fill-04'],
          inverted: colors['$fill-04'],
        },
      } as {
        [key in Color]: {
          [key in Type]: string;
        };
      }
    )[props.color][props.type];
  }, [props.color, props.size]);
};

export const LoadingComponent: React.FC<IButtonComponentsProps> = props => {
  const color = useActivityIndicatorColor(props);

  return (
    <View
      style={{
        transform: [
          {scale: props.size === 'small' || props.size === 'tiny' ? 0.7 : 1},
        ],
      }}
    >
      <ActivityIndicator size="small" color={color} />
    </View>
  );
};
