import * as React from 'react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {AgreementsList, Link} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {FeatureLoanDecreaseFormArgs, FeatureLoanDecreaseForm} from './state';
import {Alert} from '@youtoken/ui.elements';
import {Form} from '..';
import {TableRow} from '../..';
import {Ticker} from './components/Ticker';

export interface LoanDecreaseFormProps extends FeatureLoanDecreaseFormArgs {
  onUnMount?: () => void;
}

export const LoanDecreaseForm: React.FC<LoanDecreaseFormProps> = cell(
  ({id, onUnMount}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    const feature = FeatureLoanDecreaseForm.use({
      id,
    });

    React.useEffect(() => {
      feature.restore();

      return () => {
        onUnMount && onUnMount();
        feature.reset();
      };
    }, []);

    if (!feature.restored) {
      return <DefaultSuspenseFallback />;
    }

    if (!feature.loan) {
      return null;
    }

    return (
      <Form form={feature}>
        <Box flexGrow={1}>
          <Alert type="information">
            <Trans
              t={t}
              components={{
                Link: (
                  <Link
                    url="https://help.youhodler.com/en/articles/3206184-how-to-extend-pdl"
                    variant="$body-02-medium-accent"
                  />
                ),
              }}
              i18nKey="surface.loans.decrease_form.alert"
            />
          </Alert>
          <Box p={24}>
            <Ticker width={isMobile ? '100%' : '50%'} mb={20} zIndex={1} />
            <Box opacity={feature.calculated.ready ? 1 : 0.7}>
              <Text weight="bold" mb={15}>
                {t('surface.loans.decrease_form.table')}
              </Text>
              <Box mb={15}>
                <TableRow
                  leftText={t('surface.loans.decrease_form.table.new_ltv')}
                >
                  <Text variant="$body-02-high-accent" color="$text-02">
                    {feature.newLTV}%
                  </Text>
                </TableRow>
                <TableRow
                  leftText={t('surface.loans.decrease_form.table.new_pdl')}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.newPDLAmount}</Text>{' '}
                    {feature.conversionTickerCalculated}
                    {feature.newPDLAmount && (
                      <>
                        {' '}
                        <Text weight="bold">{feature.newPDL}%</Text>
                      </>
                    )}
                  </Text>
                </TableRow>
                <TableRow
                  leftText={t('surface.loans.decrease_form.table.service_fee')}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.serviceFee}</Text>{' '}
                    {feature.tickerCalculated}
                  </Text>
                </TableRow>
                <TableRow
                  leftText={t(
                    'surface.loans.decrease_form.table.total_collateral_increase'
                  )}
                >
                  <Text variant="$body-02" color="$text-02">
                    <Text weight="bold">{feature.totalCollateralIncrease}</Text>{' '}
                    {feature.tickerCalculated}
                  </Text>
                </TableRow>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box p={24}>
            <AgreementsList
              beforeListText={t(
                'agreements.documents.without_checkbox.start_text',
                {
                  call_to_action: t('surface.loans.decrease_form.submit'),
                }
              )}
              items={feature.agreements}
            />
          </Box>
          <Box
            bg="$ui-01"
            p={24}
            borderBottomLeftRadius={isMobile ? 0 : 10}
            borderBottomRightRadius={isMobile ? 0 : 10}
          >
            <Box
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              alignItems={isMobile ? 'stretch' : 'center'}
            >
              <Box
                mb={isMobile ? 20 : 0}
                opacity={feature.calculated.data ? 1 : 0}
              >
                <Text
                  variant="$body-01"
                  lineHeight={28}
                  color="$text-02"
                  textAlign={isMobile ? 'center' : 'left'}
                  mb={5}
                >
                  <Trans
                    t={t}
                    i18nKey="surface.loans.decrease_form.total_collateral_increase"
                    components={{
                      Strong: <Text variant="$heading-01" color="$text-01" />,
                    }}
                    values={{
                      amount: feature.totalCollateralIncrease,
                      ticker: feature.tickerCalculated,
                    }}
                  />
                </Text>
                <Text
                  variant="$body-02"
                  color="$text-02"
                  textAlign={isMobile ? 'center' : 'left'}
                  mb={5}
                >
                  <Trans
                    t={t}
                    components={{
                      Strong: <Text weight="bold" />,
                    }}
                    values={{
                      percent: feature.newPDL,
                      amount: feature.newPDLAmount,
                      ticker: feature.conversionTickerCalculated,
                    }}
                    i18nKey="surface.loans.decrease_form.new_pdl"
                  />
                </Text>
              </Box>
              <Button size="large" onPress={feature.form.instance.onSubmit}>
                {t('surface.loans.decrease_form.submit')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    );
  }
);
