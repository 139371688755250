import * as React from 'react';
import {useChartCursorState, ChartControls, HODLCreateChart} from '../Charts';
import {cell} from '@youtoken/ui.cell';
import {ChartSmartCells} from './utils/types';
import {ChartWithControlsSuspenseFallback} from './SuspenseFallbacks';
import {CellWrap} from './utils/CellWrap';
import {PanResponder} from '../PanResponder';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {LiveChartDataResource, ChartResource} from '../state/Chart';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

const storageKey = 'HODLCreateChartSmartCell';

type SavedParams = {
  type: 'line' | 'candle';
  timePeriod: '1h' | '24h' | '7d' | '30d' | '90d' | '1y';
  tick: '1m' | '5m' | '15m' | '30m' | '1h' | '4h';
};

const defaultParams: SavedParams = {
  type: 'line',
  timePeriod: '1h',
  tick: '1m',
};

const saveStorageParams = (params: Partial<SavedParams>) => {
  SIMPLE_STORAGE.set(
    storageKey,
    JSON.stringify({...getStorageParams(), ...params})
  );
};

const getStorageParams = (): SavedParams => {
  const params = SIMPLE_STORAGE.get(storageKey);

  if (params) {
    try {
      return {...defaultParams, ...JSON.parse(params)};
    } catch (e) {
      return defaultParams;
    }
  }

  return defaultParams;
};

export const HODLCreateChartSmartCell =
  cell<ChartSmartCells.HODLCreateChartSmartCellProps>(
    ({
      width,
      height,
      fromTicker,
      toTicker,
      precision,
      marginCallType = 'margin_call',
      marginCall,
      maxLoss,
      maxLossTicker,
      takeProfit,
      maxProfit,
      maxProfitTicker,
      triggerPrice,
      reversed,
      pending,
      ChartPanComponent = PanResponder,
      onPressTradingViewModeButton,
    }) => {
      const {setPointIndex, pointIndex, setCursorX, cursorX} =
        useChartCursorState();

      const currentMode = reversed ? 'bid' : 'ask';

      const {isLoading, chartData, chartControls, hasData, refetch} =
        LiveChartDataResource.use({
          key: 'HODLCreate',
          product: 'hodl',
          fromTicker,
          toTicker,
          mode: currentMode,
          ...getStorageParams(),
        });

      React.useLayoutEffect(() => {
        chartControls.setMode(currentMode);
      }, [currentMode, chartControls.setMode]);

      React.useLayoutEffect(() => {
        const params = getStorageParams();
        if (params.tick !== chartControls.tick) {
          chartControls.setTick(params.tick);
        }

        if (params.timePeriod !== chartControls.timePeriod) {
          chartControls.setTimePeriod(params.timePeriod);
        }

        if (params.type !== chartControls.type) {
          chartControls.setChartType(params.type);
        }
      }, [chartControls, fromTicker, toTicker]);

      const handleUpdateChartType = React.useCallback(
        (type: ChartResource.Type) => {
          chartControls.setChartType(type);
          saveStorageParams({type});
        },
        [chartControls]
      );

      const handleUpdateTick = React.useCallback(
        (tick: ChartResource.Tick) => {
          chartControls.setTick(tick);
          saveStorageParams({tick});
        },
        [chartControls]
      );

      const handleUpdateTimePeriod = React.useCallback(
        (timePeriod: ChartResource.TimePeriod) => {
          chartControls.setTimePeriod(timePeriod);
          saveStorageParams({timePeriod});
        },
        [chartControls]
      );

      React.useEffect(() => {
        DATA_LAYER.trackStrict('hodl-chart-shown', {
          screenName: 'hodl creation form',
          chartType: chartControls.type,
          instrumentName: `${fromTicker}/${toTicker}`,
          priceType: chartControls.mode!,
        });
      }, [chartControls.type, fromTicker, toTicker, chartControls.mode]);

      return (
        <>
          <ChartPanComponent
            width={width}
            height={height}
            onCursorChange={setCursorX}
          >
            <HODLCreateChart
              data={chartData}
              height={height}
              width={width}
              cursorX={cursorX}
              isFetching={isLoading}
              displayActivityIndicator
              onCursorIndexChange={setPointIndex}
              maxLoss={maxLoss}
              marginCallType={marginCallType}
              marginCall={marginCall}
              maxProfit={maxProfit}
              maxLossTicker={maxLossTicker}
              maxProfitTicker={maxProfitTicker}
              reversed={reversed}
              takeProfit={takeProfit}
              triggerPrice={pending ? triggerPrice : undefined}
              pending={pending}
              hasData={hasData}
              refetch={refetch}
              formatNumberPrecision={precision}
              direction={pending ? 'pending' : undefined}
            />
          </ChartPanComponent>
          <ChartControls
            isCursorActive={pointIndex !== undefined && hasData}
            type={chartControls.type}
            timePeriod={chartControls.timePeriod}
            tick={chartControls.tick}
            setChartType={handleUpdateChartType}
            setTick={handleUpdateTick}
            setTimePeriod={handleUpdateTimePeriod}
            onPressTradingViewModeButton={onPressTradingViewModeButton}
          />
        </>
      );
    },
    {
      displayName: 'HODLCreateChartSmartCell',
      SuspenseFallback: ChartWithControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
