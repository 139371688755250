import * as React from 'react';
import {AuthMeResource, AddressStatus} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  CoindropResource,
  CoindropsTariffs,
} from '@youtoken/ui.coindrop-resource';
import {VerificationResource} from '@youtoken/ui.resource-verification';

interface CoindropItem {
  title: string;
  steps: {
    title: string;
    description: string;
    isCompleted: boolean;
    stepAction?: () => void;
    actionLabel: string;
    testID: string;
  }[];
}

// NOTE: Two common steps: sign up and verify account
const getBaseSteps = (addressResult: AddressStatus) => [
  {
    title: i18n.t('surface.coindrop.verification_item_first_title') as string,
    description: i18n.t(
      'surface.coindrop.verification_item_first_text'
    ) as string,
    isCompleted: true,
    actionLabel: i18n.t('surface.coindrop.button') as string,
    testID: 'COINDROPS_VERIFICATION_ITEM_SIGN_UP',
  },
  {
    title: i18n.t('surface.coindrop.verification_item_second_title') as string,
    description: i18n.t(
      'surface.coindrop.verification_item_second_text'
    ) as string,
    isCompleted: addressResult === AddressStatus.ACCEPT,
    stepAction: () => {
      DATA_LAYER.trackStrict('coins-drop-verify-click', {});
      VerificationResource.launchIdentityVerification();
    },
    actionLabel: i18n.t('surface.coindrop.button') as string,
    testID: 'COINDROPS_VERIFICATION_ITEM_SIGN_UP',
  },
];

// NOTE: first two base steps and additional step for coindrops for the first deposit
const getFirstDepositSteps = (addressResult: AddressStatus) => [
  ...getBaseSteps(addressResult),
  {
    title: i18n.t('surface.coindrop.first_deposit.step_title') as string,
    description: i18n.t(
      'surface.coindrop.first_deposit.step_description'
    ) as string,
    isCompleted: false,
    stepAction: () => {
      SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
    },
    actionLabel: i18n.t(
      'surface.coindrop.first_deposit.action_label'
    ) as string,
    testID: 'COINDROPS_VERIFICATION_ITEM_FIRST_DEPOSIT',
  },
];

export const useGetCoindropsData = () => {
  const {tariff} = CoindropResource.use({});
  const {addressResult} = AuthMeResource.use({});
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);

  const coindropsData: CoindropItem = React.useMemo(() => {
    switch (tariff) {
      case CoindropsTariffs.ADDRESS_VERIFICATION:
        return {
          title: i18n.t('surface.coindrop.verify_account.title') as string,
          steps: [...getBaseSteps(addressResult)],
        };
      case CoindropsTariffs.FIRST_DEPOSIT:
        return {
          title: i18n.t('surface.coindrop.first_deposit.title') as string,
          steps: [...getFirstDepositSteps(addressResult)],
        };
      case CoindropsTariffs.NONE:
        return {
          title: '',
          steps: [],
        };
    }
  }, [addressResult]);

  React.useEffect(() => {
    for (let i = 0; i < coindropsData.steps.length; i++) {
      if (!coindropsData.steps[i]!.isCompleted) {
        setActiveStepIndex(i);
        break;
      }
    }
  }, [coindropsData.steps]);

  return {
    coindropsData,
    currentStep: coindropsData.steps[activeStepIndex],
  };
};
