import {webTickerConfig} from '@youtoken/ui.coin-utils';

const CONFIG = {
  DEFAULTS: {
    CRYPTO_PRECISION: 6,
    FIAT_PRECISION: 2,
  },
  TICKERS: webTickerConfig.TICKERS,
  TWO_FACTOR_AUTH_TYPES: ['sms', 'ga', 'email'],
};

export enum AUTH_OPERATION_TYPES {
  SIGN_IN = 'sign-in', // просто 2фа, без подтверждения
  SIGN_IN_UNTRUSTED = 'sign-in-untrusted', // 2фа и подтверждение устройства на следующем шаге
  CONFIRM_DEVICE = 'confirm-device', // просто подтверждение, без 2фа
}

export default CONFIG;
