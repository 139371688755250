import * as React from 'react';
import {Text, TextProps} from '@youtoken/ui.primitives';
import {AuthMeResource, LegalEntity} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface CopyrightProps extends TextProps {}

export const Copyright: React.FC<CopyrightProps> = props => {
  const {legalEntity} = AuthMeResource.use({});

  const {t} = useTranslation();

  const year = new Date().getFullYear();

  if (legalEntity === LegalEntity.ITA) {
    return (
      <Text variant="$body-02" color="$text-02" {...props}>
        {t('surface.legal.copyright_ita')}
      </Text>
    );
  }

  return (
    <Text variant="$body-02" color="$text-02" {...props}>
      {t('surface.more.legal.text.copyright_active', {year})}
    </Text>
  );
};
