import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const VerifiedIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M14.03 8.53a.75.75 0 00-1.06-1.06L9.5 10.94 8.03 9.47a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l4-4z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 4.671a1.5 1.5 0 011.136-1.455L9.515 1.62a2 2 0 01.97 0l6.379 1.595A1.5 1.5 0 0118 4.67v6.503a6 6 0 01-2.82 5.088l-4.385 2.741a1.5 1.5 0 01-1.59 0l-4.385-2.74A6 6 0 012 11.173V4.671zm14.5 0v6.503a4.5 4.5 0 01-2.115 3.817L10 17.73l-4.385-2.74A4.5 4.5 0 013.5 11.175V4.67l6.379-1.595a.5.5 0 01.242 0L16.5 4.671z"
          fill={color}
        />
      </Svg>
    );
  }
);
