import {uniq} from 'lodash';
import {amarketsPromoCountryCodes} from './promoCountries';

export const whitelistCodes = uniq(
  [
    'ARE', // United Arab Emirates
    'ARG', // Argentina
    'AUS', // Australia
    'AUT', // Austria
    'BEL', // Belgium
    'BGR', // Bulgaria
    'BIH', // Bosnia and Herzegovina
    'BRA', // Brazil
    'CHE', // Switzerland
    'CHL', // Chile
    'COL', // Colombia
    'CRI', // Costa Rica
    'CZE', // Czech Republic
    'DEU', // Germany
    'DNK', // Denmark
    'DOM', // Dominican Republic
    'ESP', // Spain
    'EST', // Estonia
    'FIN', // Finland
    'FRA', // France
    'GBR', // United Kingdom
    'GRC', // Greece
    'HRV', // Croatia
    'HUN', // Hungary
    'IDN', // Indonesia
    'IND', // India
    'IRL', // Ireland
    'ISL', // Iceland
    'ISR', // Israel
    'ITA', // Italy
    'JPN', // Japan
    'KOR', // South Korea
    'LTU', // Lithuania
    'LUX', // Luxembourg
    'LVA', // Latvia
    'MCO', // Monaco
    'MEX', // Mexico
    'MLT', // Malta
    'MYS', // Malaysia
    'NLD', // Netherlands
    'NOR', // Norway
    'NZL', // New Zealand
    'PER', // Peru
    'PHL', // Philippines
    'POL', // Poland
    'PRT', // Portugal
    'PRY', // Paraguay
    'ROU', // Romania
    'SAU', // Saudi Arabia
    'SGP', // Singapore
    'SRB', // Serbia
    'SVK', // Slovakia
    'SVN', // Slovenia
    'SWE', // Sweden
    'THA', // Thailand
    'TUR', // Turkey
    'TWN', // Taiwan
    'UKR', // Ukraine
    'URY', // Uruguay
    'VNM', // Vietnam
    'ZAF', // South Africa
  ].concat(amarketsPromoCountryCodes)
);

export const whitelistCodesFormA = [
  ...whitelistCodes,
  'CYP', // Cyprus
];
