import * as React from 'react';
import type {TFuncKey} from 'react-i18next';
import {observer} from 'mobx-react';
import {en} from '@youtoken/ui.lokalise-export';
import {Box, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ScrollWrapper} from './ScrollWrapper';

export const FILTER_LABELS: Record<string, keyof typeof en | string> = {
  erc20: 'ERC20',
  trc20: 'TRC20',
  bep20: 'BEP20',
  polygon: 'POLYGON',
  sol: 'SOLANA',
  ton: 'TON',
};

export type FilterProps = {
  search?: string;
  filters: string[];
  selectedFilters: string[];
  onSearchChange: (search: string) => void;
  onFilterPress: (filter: string) => void;
};

export const Filter: React.FC<FilterProps & BoxProps> = observer(
  ({
    filters,
    selectedFilters,
    onFilterPress,
    onSearchChange,
    search = '',
    ...rest
  }) => {
    const {t} = useTranslation();

    const getHandleFilterPress = React.useCallback(
      (filter: string) => () => {
        onFilterPress(filter);
      },
      [onFilterPress]
    );

    const handleInputChange = React.useCallback(
      (text: string) => {
        onSearchChange(text);
      },
      [onSearchChange]
    );

    const clearInput = React.useCallback(() => {
      onSearchChange('');
    }, [onSearchChange]);

    const LeftPartComponent: React.FC<{focused?: boolean}> = React.useCallback(
      ({focused}) => {
        return (
          <Box height="100%" justifyContent="center" alignItems="center" px={8}>
            <Icon
              color={focused ? '$interactive-01' : '$text-03'}
              name="search"
            />
          </Box>
        );
      },
      []
    );

    const RightPartComponent: React.FC<{focused?: boolean}> = React.useCallback(
      ({focused}) => {
        if (!search) {
          return null;
        }

        return (
          <TouchableBox
            height="100%"
            width={38}
            justifyContent="center"
            alignItems="center"
            onPress={clearInput}
            testID="ADD_WALLETS_SEARCH_INPUT_CLEAT"
          >
            <Icon
              color={focused ? '$interactive-01' : '$text-03'}
              name="close"
            />
          </TouchableBox>
        );
      },
      [search]
    );

    return (
      <Box {...rest}>
        <Box px={24} pb={filters.length > 0 ? 0 : 16}>
          <TextInput
            autoCorrect={false}
            value={search}
            placeholder={t('surface.wallets.add_wallets.search_placeholder')}
            size="medium"
            onChangeText={handleInputChange}
            LeftPartComponent={LeftPartComponent}
            RightPartComponent={RightPartComponent}
            testID="ADD_WALLETS_SEARCH_INPUT"
          />
        </Box>

        {filters.length > 0 && (
          <Box height={56} testID="ADD_WALLETS_FILTERS">
            <ScrollWrapper>
              <Box flexDirection="row" pt={16} pl={24} pr={16}>
                {filters.map(filter => (
                  <Button
                    key={filter}
                    size="tiny"
                    type={
                      selectedFilters.includes(filter) ? 'primary' : 'secondary'
                    }
                    mr={8}
                    onPress={getHandleFilterPress(filter)}
                    testID={`ADD_WALLETS_FILTER_${filter.toUpperCase()}`}
                  >
                    {t(FILTER_LABELS[filter] as TFuncKey)}
                  </Button>
                ))}
              </Box>
            </ScrollWrapper>
          </Box>
        )}
      </Box>
    );
  }
);
