import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TouchableBox, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {ReferralsHowItWorksModal} from '.';

export const ReferralsHowItWorksModalTrigger: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  return (
    <>
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        onPress={handleToggle}
      >
        <Text color="$text-05" variant="$body-02-medium-accent">
          {t(
            'surface.referral_program.referrals.friends_activity.how_it_works'
          )}{' '}
        </Text>
        <Icon name="question" size={16} color="$text-05" />
      </TouchableBox>
      <ReferralsHowItWorksModal isOpen={isOpen} onClose={handleToggle} />
    </>
  );
});
