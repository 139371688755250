import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {
  LEVEL_ICON_WRAP_SIZE,
  LevelIcon,
  LevelStatusView,
} from '@youtoken/ui.loyalty-miner-components';
import {invariant} from '@youtoken/ui.utils';
import {AccountLevelRequirements} from '../../../AccountLevelRequirements';
import {AccountLevelInfoFull} from '../../../components';

export interface LevelProps {
  id: number;
}

export const Level: React.FC<LevelProps> = observer(({id}) => {
  const {
    authMe: {
      products: {
        saving4: {isEnabled: isSaving4Enabled},
        miner: {isEnabled: showMinerInfo},
        exchange: {
          settings: {enableConvertsLoyalty: _showExchangeInfo},
        },
      },
    },
    loyalty: {getLevel, levels},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
  });

  const level = getLevel(id);

  invariant(
    level,
    `cannot get saving info for level ${id}`,
    {},
    {levels, level}
  );

  const {
    level: levelNumber,
    name,
    status,
    cardIconName,
    cardColor,
    saving: {shouldShowTariff},
  } = level;

  const showExchangeInfo = _showExchangeInfo && levelNumber > 2;

  const showSavingsInfo = isSaving4Enabled && shouldShowTariff;

  const showAnyBenefits = showSavingsInfo || showMinerInfo || showExchangeInfo;

  const onPress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('AccountLevelAllBenefits', {
      level: levelNumber,
    });
  }, [levelNumber]);

  return (
    <TouchableBox
      testID={`LEVEL_${id}`}
      height={465}
      disabled={!showAnyBenefits}
      onPress={onPress}
    >
      <Box
        backgroundColor={cardColor}
        borderRadius={16}
        position="relative"
        mt={24}
        flex={1}
      >
        <Box flex={1}>
          <LevelStatusView status={status} />
          <Box alignItems="center" mt={-LEVEL_ICON_WRAP_SIZE / 2}>
            <LevelIcon
              name={cardIconName}
              wrapSize={LEVEL_ICON_WRAP_SIZE}
              bg={cardColor}
            />
            <Text color="$text-01" variant="$body-01-high-accent" mt={12}>
              {name}
            </Text>
          </Box>
          <AccountLevelInfoFull
            level={level}
            showSavingsInfo={showSavingsInfo}
            showMinerInfo={showMinerInfo}
            showExchangeInfo={showExchangeInfo}
            mt={24}
            mx={16}
          />
        </Box>
        <Box
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <AccountLevelRequirements id={id} />
        </Box>
      </Box>
    </TouchableBox>
  );
});
