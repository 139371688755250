import {createGlobalStyle} from 'styled-components';

export const Notifications = createGlobalStyle`
  .notifications {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;

    > span {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .notifications-notice {
      display: block;
      width: auto;
      position: relative;
      margin: 10px 0;

      .notifications-custom-content {
        > span {
          display: block;
        }
      }

      .notifications-closable {
        padding-right: 20px;
      }

      .notifications-close {
        position: absolute;
        right: 5px;
        top: 3px;
        color: #000;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: 0.2;
        text-decoration: none;

        .notifications-x:after {
          content: '×';
        }

        &:hover {
          opacity: 1;
          filter: alpha(opacity=100);
          text-decoration: none;
        }
      }
    }

    .notifications-fade-enter {
      opacity: 0;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
      animation-play-state: paused;
    }

    .notifications-fade-leave {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
      animation-play-state: paused;
    }

    .notifications-fade-enter.notifications-fade-enter-active {
      animation-name: rcNotificationFadeIn;
      animation-play-state: running;
    }

    .notifications-fade-leave.notifications-fade-leave-active {
      animation-name: rcDialogFadeOut;
      animation-play-state: running;
    }

    @keyframes rcNotificationFadeIn {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @keyframes rcDialogFadeOut {
      0% {
        opacity: 1;
        transform: translateY(0);
      }
      100% {
        opacity: 0;
        transform: translateY(-100%);
      }
    }
  }
`;
