import {ScopeContext} from '@sentry/types';
import {ERROR_TYPE} from './ERROR_TYPE';
import {CustomError, WithCustomErrorProps} from './CustomError';
import {ResourceError} from './ResourceError';

/** will map ResourceError[] to something like
 *
 * `{"resource:resourceWithHTTPError": "Request failed ..."}`
 */
export const getResourcesKeysDescription = (errors: ResourceError[]) => {
  return JSON.stringify(
    errors.map(error => error.message),
    null,
    2
  );
};

/** will create human-readable AggregateError message like
 *
 * `AggregateError from useResources: {"resource:resourceWithHTTPError": "Request failed ..."}`
 */
export const createAggregateErrorMessage = (errors: ResourceError[]) => {
  return `[${errors.map(error => error.message).join(', ')}]`;
};

// check if AggregateError supported by env;
// @ts-ignore
const isAggregateErrorSupported = typeof AggregateError !== 'undefined';

// extending AggregateError or Error;
// @ts-ignore
const ErrorSuperClass = isAggregateErrorSupported ? AggregateError : Error;

/** ResourceAggregateError: new super-error to wrap ResourceErrors[]
 * - `sentryProps: {errors: ResourceErrors[], errorsDescription: "..."}`
 * - `__type`: `ErrorTypes`;
 */
export class ResourceAggregateError
  extends ErrorSuperClass
  implements Error, WithCustomErrorProps
{
  name!: string;
  message!: string;
  __type!: ERROR_TYPE;
  __sentry!: Partial<ScopeContext>;
  __isCustomError!: true;
  errors!: Array<ResourceError>;

  constructor(errors: ResourceError[]) {
    const message = createAggregateErrorMessage(errors);

    if (isAggregateErrorSupported) {
      super(errors, message);
    } else {
      super(message);
    }

    this.name = `ResourceAggregateError`;

    this.__isCustomError = true;

    const firstError = errors[0];

    // preserving error type from first error in feature if any
    this.__type =
      (firstError as CustomError).__type ?? ERROR_TYPE.GENERAL_ERROR;

    Object.setPrototypeOf(this, new.target.prototype);

    this.__sentry = {
      extra: {
        errors: getResourcesKeysDescription(errors),
      },
      level: firstError?.__sentry?.level ?? 'error',
      fingerprint: [
        'resource-aggregate-error',
        ...errors.map(error => error.__resourceKey || error.message),
      ],
    };
  }
}
