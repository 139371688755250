import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type UnlimintSPEIForm} from '../../state/UnlimintSPEIForm';

export const PaymentDataRow: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {paymentAmountFormatted},
  } = useForm<UnlimintSPEIForm>();

  return (
    <Box
      height={68}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderColor="$ui-01"
      borderBottomWidth={1}
    >
      <Box>
        <Text>
          {t('surface.wallets.fiat_deposit.unlimint_spei.payment_row.text')}
        </Text>
      </Box>
      <Heading testID="FIAT_DEPOSIT_SPEI_PAYMENT_DATA">
        {paymentAmountFormatted}
      </Heading>
    </Box>
  );
});
