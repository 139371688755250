import React from 'react';
import {type GetHandleProps, type SliderItem} from 'react-compound-slider';
import {Box, type TColorTokensNames} from '@youtoken/ui.primitives';
import {sliderElementStyles} from './styles';

export const Handle: React.FC<{
  handle: SliderItem;
  color: TColorTokensNames;
  getHandleProps: GetHandleProps;
}> = ({handle: {id, percent}, color, getHandleProps}) => {
  const handleProps = getHandleProps(id);

  return (
    <Box width={16} height={16} left={`${percent}%`} {...handleProps}>
      <Box
        bg={color}
        style={[
          sliderElementStyles.handle,
          // @ts-ignore
          {cursor: 'grab'},
        ]}
      />
    </Box>
  );
};
