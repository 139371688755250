import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HeaderBase} from '../../../../components';

export const ExchangeFormHeader: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <HeaderBase borderBottomWidth={1} borderColor="$ui-01">
      {t('surface.wallets.conversion_form.title')}
    </HeaderBase>
  );
});
