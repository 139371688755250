import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, useTheme} from '@youtoken/ui.primitives';
import {Images} from '@youtoken/ui.images';
import {type LoanSequenceItemProps} from '../../../types';

const ArrowForFirstItem = () => {
  const {colors} = useTheme();
  return (
    <Box position="absolute" left={11} top={-11}>
      <Images.arrFirst width={24} height={33} color={colors['$text-02']} />
    </Box>
  );
};

const ArrowForIntermediateItem = () => {
  const {colors} = useTheme();

  return (
    <Box position="absolute" left={11} bottom={33}>
      <Images.arrConnection width={24} height={47} color={colors['$text-02']} />
    </Box>
  );
};

const ArrowForLastItem = () => {
  const {colors} = useTheme();

  return (
    <Box position="absolute" left={11} bottom={-14}>
      <Images.arrLast width={27} height={33} color={colors['$text-02']} />
    </Box>
  );
};

export const LoanSequenceItem: React.FC<LoanSequenceItemProps> = observer(
  props => {
    const {loanSequenceItem, first, last, testNumber} = props;
    const {t} = useTranslation();

    return (
      <Box
        position="relative"
        height={54}
        mt={first ? 13 : 0}
        mb={last ? 16 : 8}
        justifyContent="center"
        backgroundColor="$ui-01"
        borderRadius={5}
        testID={`HODL_DETAILS_LOAN_SEQUENCE_ITEM_${testNumber}`}
      >
        <Box pl={44} pr={20}>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>
              <Text variant="$body-01-high-accent">
                {loanSequenceItem.collateralAmountFormatted}{' '}
              </Text>
              {loanSequenceItem.collateralTickerFormatted}
            </Text>
            <Text color="$text-02" variant="$body-02">
              {loanSequenceItem.feePercentFormatted}%{' '}
              {t('surface.hodls.hodl_form.details.rollover_percent')}
            </Text>
          </Box>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color="$text-02" variant="$body-02">
              <Text variant="$body-02-high-accent">
                {loanSequenceItem.borrowedAmountFormatted}{' '}
              </Text>
              {loanSequenceItem.borrowedTickerFormatted}{' '}
              {t('surface.hodls.hodl_form.details.borrowed')}
            </Text>
            <Text color="$text-02" variant="$body-02">
              {loanSequenceItem.ltvValueFormatted}%{' '}
              {t('surface.hodls.hodl_form.details.ltv')}
            </Text>
          </Box>
        </Box>
        {first && <ArrowForFirstItem />}
        {!first && <ArrowForIntermediateItem />}
        {last && <ArrowForLastItem />}
      </Box>
    );
  }
);
