import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Modal} from '@youtoken/ui.modal';
import {RewardsHowItWorks} from '../../components';

export const RewardsHowItWorksModal: React.FC<
  React.ComponentProps<typeof Modal>
> = observer(modalProps => {
  const {t} = useTranslation();

  return (
    <Modal
      title={t('surface.referral_program.rewards.how_it_works.title')}
      shouldUseAnimatedSnapPoints
      snapPoints={['CONTENT_HEIGHT']}
      {...modalProps}
    >
      <RewardsHowItWorks />
    </Modal>
  );
});
