import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {SecurityActivityData} from './SecurityActivityResponse';

export class SecurityActivityResource extends createResource<
  {},
  SecurityActivityData[]
>({
  getKey: () => 'SecurityActivity',
  getData: () =>
    TRANSPORT.API.get(`/v1/security/activity`).then(res => {
      return deserialize(SecurityActivityData, res.data as any[]);
    }),
}) {
  @computed get devicesList() {
    return this.data;
  }

  getActivityItem = computedFn((id: string) => {
    return this.data.find(item => item.id === id);
  });
}
