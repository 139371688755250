import {deserialize} from 'serializr';
import {computedFn} from 'mobx-utils';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  RatesSettingsResponseSchema,
  type RatesSettingsResponse,
} from './RatesSettingsResponse';

export interface RatesSettingsArgs {
  // Ticker of the quote currency - resource would return rates settings for this quote currency or all existing settings if not provided
  ticker?: string;
}

export class RatesSettingsResource extends createStaticResource<
  RatesSettingsArgs,
  RatesSettingsResponse
>({
  skipRefreshOnVisible: false,
  getKey: ({ticker}: RatesSettingsArgs) => `RatesSettings:${ticker}`,
  getData: ({ticker}) =>
    TRANSPORT.API.get(`/v3/rates/settings`, {
      params: {
        quote: ticker,
      },
    }).then(res => {
      return deserialize(RatesSettingsResponseSchema, res.data);
    }),
}) {
  getPrecision = computedFn(
    (baseTicker: string, quoteTicker: string, defaultPrecision = 4): number => {
      return (
        this.data[baseTicker]?.[quoteTicker]?.precision ?? defaultPrecision
      );
    }
  );
}
