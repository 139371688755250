import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Profit, Total} from '@youtoken/ui.elements-smart';
import {ReferralsAndBonusesSummarySmart} from '@youtoken/ui.surfaces-referral-program';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {WalletsSummaryFeature} from '../../../../state';

export interface SummaryProps extends BoxProps {}

export const Summary: React.FC<SummaryProps> = observer(({...boxProps}) => {
  const {t} = useTranslation();

  const feature = WalletsSummaryFeature.use({});

  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        mb={{
          default: 0,
          desktop: 24,
        }}
        {...boxProps}
      >
        <Total
          text={t('surface.wallets.header.portfolio_value')}
          currency={feature.mainCurrency}
          currencyAmount={feature.totalCapital}
          width="45%"
        />
        <Box alignItems="flex-end" width="55%">
          <SummaryProfitOrRewardsSmart />
        </Box>
      </Box>
    </>
  );
});

export const SummaryProfitOrRewardsSmart: React.FC<SummaryProps> = observer(
  () => {
    const {
      resources: {
        authme: {hasSavings, savings4Active, shouldDisplayRewardAndBonuses},
      },
    } = WalletsSummaryFeature.use({});

    return (
      <>
        {hasSavings && !savings4Active && <SummaryProfitSmart />}
        {shouldDisplayRewardAndBonuses && <ReferralsAndBonusesSummarySmart />}
      </>
    );
  }
);

export const SummaryProfitSmart: React.FC<SummaryProps> = observer(() => {
  const {mainCurrency, totalEarned, totalEarnedNextSettlementPeriod} =
    WalletsSummaryFeature.use({});

  return (
    <Profit
      currency={mainCurrency}
      formattedResult={totalEarned}
      resultDirection="neutral"
      text={totalEarnedNextSettlementPeriod}
    />
  );
});
