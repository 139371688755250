import {observable} from 'mobx';
import {UmaInvoiceResource} from '@youtoken/ui.resources-uma-invoice';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Form} from './Form';

export interface UmaInvoiceConfirmationFormStateResource {
  authMe: AuthMeResource;
  umaInvoiceResource: UmaInvoiceResource;
}

export interface UmaInvoiceConfirmationFormStateArgs {
  ticker: string;
  onRateTimerEnd: () => void;
}

export class UmaInvoiceConfirmationForm {
  @observable
  form: Form;

  constructor(
    args: UmaInvoiceConfirmationFormStateArgs,
    resources: UmaInvoiceConfirmationFormStateResource
  ) {
    this.form = new Form(args, resources);
  }
}
