import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Modal} from '@youtoken/ui.modal';
import {cell} from '@youtoken/ui.cell';
import {HodlHistorySurface} from './surface';

interface HodlHistoryBottomSheetProps {
  onClose: () => void;
  hodlId: string;
}

export const HodlHistoryBottomSheet: React.FC<HodlHistoryBottomSheetProps> =
  cell(({onClose, hodlId}) => {
    const {t} = useTranslation();
    const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(true);

    const handleClose = React.useCallback(() => {
      setIsBottomSheetOpen(false);
      if (onClose) {
        onClose();
      }
    }, [onClose]);

    return (
      <>
        <Modal
          title={t('surface.hodls.item.history_modal_title')}
          isOpen={isBottomSheetOpen}
          onClose={handleClose}
          webOverlayBackgroundColor="$transparent"
          nativeStackBehavior="push"
          borderRadius={14}
          maxWidth={590}
          shouldBeScrollable
          snapPoints={['95%']}
        >
          <HodlHistorySurface hodlId={hodlId} />
        </Modal>
      </>
    );
  });
