import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {MenuItemBase} from './MenuItemBase';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('TrustedDevices');
};

export const TrustedDevices: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <MenuItemBase
      icon="devices"
      title={t('surface.profile.security.trusted_devices.title')}
      subtitle={t('surface.profile.security.trusted_devices.description')}
      RightPartComponentWeb={
        <Icon name="chevron_right" size={20} color="$text-02" />
      }
      onPress={handlePress}
      {...boxProps}
    />
  );
});
