import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {getTitle} from './index.meta';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box} from '@youtoken/ui.primitives';
import {AccountLevels} from '../smarts';
import {AccountLevelDetailed} from '../AccountLevelDetailed';

export const AccountLevelsSurfaceCell = cell(
  () => {
    React.useEffect(() => {
      DATA_LAYER.trackStrict('loyalty-opened', {});
    }, []);

    return (
      <>
        <AccountLevelDetailed />
        <AccountLevels />
      </>
    );
  },
  {
    displayName: 'AccountLevelsSurface',
    CellWrap: ({children}) => (
      <WebPageWrapper
        windowTitle={getTitle()}
        title={getTitle()}
        hasBackButton
        onBackPress={() => SHARED_ROUTER_SERVICE.navigate('More', {})}
      >
        <Box flex={1}>{children}</Box>
      </WebPageWrapper>
    ),
  }
) as React.FC & {getTitle: () => string};

AccountLevelsSurfaceCell.getTitle = getTitle;
