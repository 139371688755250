import {compile} from 'path-to-regexp';
import {stringifyUrl} from 'query-string';
import {Routes} from './Routes';
import {routesToUrlMap} from './Routes/routeToUrlMap';
import {ParamsByRouteName, QueryByRouteName} from './types';

const compileToUrl = (path: string, params: {} = {}, query: {} = {}) => {
  return stringifyUrl({
    url: compile(path)(params),
    query: query,
  });
};

export const routeToUrl = <RouteName extends Routes.AllRoutes[0]>(
  name: RouteName,
  params?: ParamsByRouteName<RouteName>,
  query?: QueryByRouteName<RouteName>
) => {
  if (routesToUrlMap[name]) {
    return compileToUrl(routesToUrlMap[name], params, query);
  }

  return '/not-found';
};
