import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Button} from '@youtoken/ui.buttons';

export interface UpgradeToLevelActionProps {
  levelNumber: number;
}

export const UpgradeToLevelAction: React.FC<
  UpgradeToLevelActionProps & React.ComponentProps<typeof Button>
> = observer(({levelNumber, onPress, ...buttonProps}) => {
  const {t} = useTranslation();

  const handlePressDefault = React.useCallback(() => {
    onPress?.();

    SHARED_ROUTER_SERVICE.navigate('NewMultiHODL');
  }, [levelNumber, onPress]);

  if (levelNumber >= 3) {
    return (
      <Button
        size="medium"
        mb={8}
        onPress={handlePressDefault}
        {...buttonProps}
      >
        {t('surface.miner.trade_to_upgrade')}
      </Button>
    );
  }

  return null;
});
