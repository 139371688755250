import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {type BoxProps} from '@youtoken/ui.primitives';
import {IncentivesBonusForDepositSmart} from '@youtoken/ui.elements-smart';
import {UnlimintSPEIForm} from '../../state/UnlimintSPEIForm';

export const IncentivesBonuses: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {incentivesBonusFormatted},
  } = useForm<UnlimintSPEIForm>();

  return (
    <IncentivesBonusForDepositSmart
      bonusesValue={incentivesBonusFormatted}
      {...boxProps}
    />
  );
});
