import {invariant, warning} from '@youtoken/ui.utils';
import EventEmitter from 'eventemitter3';

type SemanticVersion = {
  major: number;
  minor: number;
  version: string;
};

const parseSemver = (version: string): SemanticVersion => {
  const [major, minor] = version.split('.');

  return {
    major: parseInt(major!),
    minor: parseInt(minor!),
    version,
  };
};

interface Service<T> {
  new (...args: any[]): T;
}

type RegisteredService<T> = {
  __version: string;
  service: InstanceType<Service<T>>;
};

export class ServiceRegistry {
  constructor() {}

  private services: {
    [key: string]: RegisteredService<any>;
  } = {};

  /** create service */
  private createService<T>(
    name: string,
    ServiceClass: Service<T>,
    version: string
  ): RegisteredService<T> {
    this.services[name] = {
      service: new ServiceClass(),
      __version: version,
    };

    return this.services[name]!;
  }

  private getRegisteredService = <T>(name: string): RegisteredService<T> => {
    warning(this.services[name], `service with name ${name} was not found`);

    return this.services[name]!;
  };

  /** check version compatibility */
  private checkVersions = (
    name: string,
    existingVersion: SemanticVersion,
    incomingVersion: SemanticVersion
  ) => {
    this.events.emit('checkVersion', {existingVersion, incomingVersion});

    // if major version mismatched - well, this is error no matter what;
    warning(
      existingVersion.major === incomingVersion.major,
      `trying to register incompatible service ${name} with version v${incomingVersion.version}, while v${existingVersion.version} is already exists; 
            Major versions v${incomingVersion.version} and v${existingVersion.version} are incompatible;
            This probably means that some package has not been updated to new release;
          `
    );

    // if minor version mismatched
    warning(
      existingVersion.minor >= incomingVersion.minor,
      `trying to register incompatible service ${name} with version v${incomingVersion.version}, while v${existingVersion.version} is already exists; 
            Minor versions v${incomingVersion.version} and v${existingVersion.version} are incompatible;
            This probably means that some package has not been updated to new release;
          `
    );
  };

  public events = new EventEmitter<'registerService' | 'checkVersion'>();

  /** has service registered */
  public hasService = (name: string) => {
    return Boolean(this.services[name]);
  };

  /** get service by name, throws if not defined*/
  public getService = <T>(name: string): T => {
    invariant(
      this.services[name],
      `service with name ${name} was not found! You probably haven't installed package`
    );

    return this.getRegisteredService<T>(name).service;
  };

  /** register service by name */
  public registerService = <T>(
    name: string,
    ServiceClass: Service<T>,
    version: string
  ): InstanceType<Service<T>> => {
    this.events.emit('registerService', name, ServiceClass, version);
    if (this.hasService(name)) {
      const {service, __version} = this.getRegisteredService<T>(name);

      this.checkVersions(name, parseSemver(__version), parseSemver(version));

      return service;
    }

    return this.createService(name, ServiceClass, version).service;
  };
}
