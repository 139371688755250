import * as React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {type AssetsItemProps} from '../types';
import {AssetsItemSummary} from '../ItemSummary';

export const AssetsItem: React.FC<AssetsItemProps & BoxProps> = ({
  primaryTicker,
  primaryAmountFormatted,
  primaryExtension,
  secondaryTicker,
  secondaryAmountFormatted,
  secondaryExtension,
  children,
  ...boxProps
}) => {
  return (
    <Box
      testID={`ASSET_ITEM_${primaryTicker.toUpperCase()}`}
      px={{default: 16, phone: 24}}
      py={16}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...boxProps}
    >
      <AssetsItemSummary
        primaryTicker={primaryTicker}
        primaryAmountFormatted={primaryAmountFormatted}
        secondaryTicker={secondaryTicker}
        secondaryAmountFormatted={secondaryAmountFormatted}
        primaryExtension={primaryExtension}
        secondaryExtension={secondaryExtension}
      />
      <Box>{children}</Box>
    </Box>
  );
};
