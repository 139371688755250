import * as React from 'react';
import {
  Box,
  TouchableBox,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {BankCard} from '@youtoken/ui.bank-card';
import type {IBankCard} from '@youtoken/ui.resource-bank-cards';

interface SliderItemProps {
  data: IBankCard;
  onPress?: TouchableBoxProps['onPress'];
}

export const SliderItem: React.FC<SliderItemProps> = ({data, onPress}) => {
  const isFrozen = data.status === 'FROZEN';

  return (
    <TouchableBox
      p={{default: 0, desktop: 2}}
      borderRadius={{default: 0, desktop: 20}}
      alignItems="center"
      onPress={onPress}
    >
      <Box opacity={isFrozen ? 0.4 : 1}>
        <BankCard
          type={data.format}
          cardNumber={data.panLast}
          balance={data.balanceFormatted}
        />
      </Box>
    </TouchableBox>
  );
};
