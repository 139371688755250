import {action, computed} from 'mobx';
import {
  LoansActiveResource,
  LoansClosedResource,
} from '@youtoken/ui.resource-loans';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';

export interface LoansFeatureArgs {
  mode: 'regular' | 'turbo';
}

const getKey = (args: LoansFeatureArgs) => {
  return `loansLists(${args.mode})`;
};

const getResources = (args: LoansFeatureArgs) => {
  return {
    loansActive: getResourceDescriptor(LoansActiveResource, args),
    loansClosed: getResourceDescriptor(LoansClosedResource, args),
  };
};

export class LoansFeature extends createFeature({
  getKey,
  getResources,
}) {
  @computed
  public get mode() {
    return this.args.mode;
  }

  @computed
  public get hasLists() {
    return !!(this.activeList.length || this.closedList.length);
  }

  @computed.struct
  public get activeList() {
    return this.resources.loansActive.data.map(r => r.id) ?? [];
  }

  @computed.struct
  public get closedList() {
    return this.resources.loansClosed.data.rows.map(r => r.id) ?? [];
  }

  @computed
  public get closedTotalPages() {
    return this.resources.loansClosed.totalPages;
  }

  @computed
  public get closedActivePage() {
    return this.resources.loansClosed.activePage;
  }

  @action
  public setClosedActivePage = (page: number) => {
    this.resources.loansClosed.setPage(page - 1);
  };
}
