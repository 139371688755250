import * as React from 'react';
import {Path, Svg, SvgProps} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const AvaxIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Path
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
          fill="#E84142"
        />
        <Path
          d="M21.644 16.416c.554-.958 1.449-.958 2.003 0l3.452 6.06c.555.957.101 1.738-1.008 1.738h-6.954c-1.096 0-1.55-.78-1.008-1.738l3.515-6.06zM14.967 4.75c.554-.958 1.436-.958 1.99 0l.769 1.385 1.814 3.188a3.311 3.311 0 010 2.885l-6.085 10.545a3.2 3.2 0 01-2.494 1.461H5.909c-1.109 0-1.563-.768-1.008-1.738L14.967 4.75z"
          fill="#fff"
        />
      </Svg>
    );
  }
);
