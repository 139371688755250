import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {HODLByIdFeature, HODLDetailsFeature} from '../../features';
import {HowHODLWorksSurface} from '../HowHODLWorksSurface';
import type {IAbstractHowHODLWorks} from '@youtoken/ui.hodls-utils';

interface HowHODLWorksSurfaceSmartProps {
  hodlId: string;
  version: number;
}

export const HowHODLWorksSurfaceSmart: React.FC<HowHODLWorksSurfaceSmartProps> =
  cell(({hodlId, version}) => {
    let detailsData: IAbstractHowHODLWorks;
    if (version === 2) {
      const hodlFeature = HODLByIdFeature.use({id: hodlId});
      detailsData = hodlFeature.detailsData!;
    } else {
      const detailsFeature = HODLDetailsFeature.use({id: hodlId});
      detailsData = detailsFeature.detailsData;
    }

    return <HowHODLWorksSurface {...detailsData} />;
  });
