import * as React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {WalletComboboxItem} from '../types';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';

export const TickerComboboxItem: React.FC<{
  highlighted?: boolean;
  wallet: WalletComboboxItem;
}> = React.forwardRef(
  (
    {
      highlighted,
      wallet: {ticker, amountFormatted, hasAmount, tickerFormatted, coinName},
      ...props
    },
    ref
  ) => {
    return (
      <Box
        px={16}
        height={48}
        bg={highlighted ? '$interactive-02' : '$ui-background'}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        ref={ref}
        {...props}
      >
        <Box flexDirection="row" alignItems="center">
          <LogoColored name={ticker as LogoColoredIconName} size={20} />
          <Text variant="$body-01" ml={8}>
            {coinName}
          </Text>
          <Text variant="$body-01" color="$text-03" ml={8}>
            {tickerFormatted}
          </Text>
        </Box>

        <Box>
          {Boolean(amountFormatted) && (
            <Text
              variant={hasAmount ? '$body-01-medium-accent' : '$body-01'}
              color={hasAmount ? '$text-01' : '$text-03'}
            >
              {amountFormatted}
            </Text>
          )}
        </Box>
      </Box>
    );
  }
);
