import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';

export const LimitItem: React.FC = ({children}) => {
  return (
    <Box mt={8}>
      <Text variant="$body-02" color="$text-02">
        {children}
      </Text>
    </Box>
  );
};
