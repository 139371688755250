import {Platform} from 'react-native';
import {orderBy, uniq} from 'lodash';
import {warning} from '@youtoken/ui.utils';
import {getCoinOrderIndex, hasCoinOrderIndex} from '@youtoken/ui.coin-utils';
import {LoanTariffsResponseItem} from './LoanTariffsResponse';

export const filterAndSortTerms = (terms: LoanTariffsResponseItem[]) => {
  return orderBy(
    terms.reduce((acc, term) => {
      const validCollateralTicker = hasCoinOrderIndex(term.collateralTicker);
      const validLTV = term.ltv > 0;
      const validDays = term.days > 0;

      warning(
        validCollateralTicker,
        `TariffsV1Resource: Term has invalid collateralTicker`,
        {
          id: term.id,
          collateralTicker: term.collateralTicker,
        }
      );
      warning(validLTV, `TariffsV1Resource: Term has invalid ltv`, {
        id: term.id,
        ltv: term.ltv,
      });
      warning(validDays, `TariffsV1Resource: Term has invalid days`, {
        id: term.id,
        days: term.days,
      });

      if (validCollateralTicker && validLTV && validDays) {
        const platformAvaliable = !(
          Platform.OS === 'android' && term.days <= 60
        );

        const termSettingsFiltered = orderBy(
          term.settings.filter(({borrowedTicker, minFTP, maxFTP}) => {
            const validBorrowedTicker =
              borrowedTicker && hasCoinOrderIndex(borrowedTicker);
            let validFTPSettings = true;
            if (minFTP || maxFTP) {
              const validMinFTP = !!(minFTP && minFTP > 0);
              const validMaxFTP = !!(maxFTP && maxFTP > 0);
              validFTPSettings = validMinFTP && validMaxFTP;
            }

            warning(
              validBorrowedTicker,
              `TariffsV1Resource: Term has invalid borrowedTicker`,
              {
                id: term.id,
                borrowedTicker,
              }
            );
            warning(
              validFTPSettings,
              `TariffsV1Resource: Term has invalid FTP`,
              {
                id: term.id,
              }
            );

            return validBorrowedTicker && validFTPSettings;
          }),
          [setting => getCoinOrderIndex(setting.borrowedTicker)]
        );

        if (platformAvaliable && termSettingsFiltered.length) {
          acc.push({
            ...term,
            settings: termSettingsFiltered,
          });
        }
      }

      return acc;
    }, [] as typeof terms),
    [
      term => {
        return getCoinOrderIndex(term.collateralTicker!);
      },
      term => term.type === 'vip',
      term => {
        return term.ltv;
      },
    ],
    ['asc', 'desc', 'desc']
  );
};

export const getMarketsByTerms = (terms: LoanTariffsResponseItem[]) => {
  return terms.reduce((acc, {collateralTicker, settings}) => {
    const tickers = settings.map(setting => setting.borrowedTicker);

    acc[collateralTicker] = uniq([
      ...(acc[collateralTicker] || []),
      ...tickers,
    ]);

    return acc;
  }, {} as {[key: string]: string[]});
};
