import axios, {type Canceler} from 'axios';
import {debounce} from 'lodash';
import {
  action,
  autorun,
  comparer,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {computedFn, now} from 'mobx-utils';
import {deserialize} from 'serializr';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import type {ExchangeAuthorizedFormArgs} from '../types';
import type {ExchangeAuthorizedNewFormResources} from './types';
import {
  normalizeAmount,
  normalizeAmountByTicker,
} from '@youtoken/ui.normalizers';
import {
  formatByTicker,
  formatPercentTillPrecision,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import Big from 'big.js';
import {
  handleFormFieldsErrors,
  handleFormSubmitError,
} from '@youtoken/ui.form-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {calculateAll, calculateAllReverse} from '@youtoken/converts-calculator';
import {invariant} from '@youtoken/ui.utils';
import {handleFormSubmitRateChangedError} from './utils';
import {Calculated} from './Calculated';

export const CURRENT_RATE_UPDATE_INTERVAL = 15;

export class Form {
  @observable
  args: ExchangeAuthorizedFormArgs;

  @observable
  resources: ExchangeAuthorizedNewFormResources;

  @observable
  instance: MobxReactForm;

  @observable
  disposers: IReactionDisposer[] = [];

  @observable
  side: 'from' | 'to' = 'from';

  //#region swap

  @observable
  swap: boolean = false;

  @computed
  get swapEnable() {
    return Boolean(this.tariffReverse);
  }

  @action
  swapOnPress = () => {
    const prevTicker = this.ticker;

    this.side = 'from';
    this.swap = true;

    this.setTicker(this.conversionTicker);
    this.setAmount(this.conversionAmount);

    this.instance
      .$('conversionTicker')
      .set(
        this.conversionTickers.includes(prevTicker)
          ? prevTicker
          : this.conversionTickers[0]
      );
  };

  //#endregion swap

  //#region balance

  @computed
  get balance() {
    invariant(
      this.resources.walletsResource.getByTicker(this.ticker),
      "Can't get balance by ticker",
      {},
      {ticker: this.ticker}
    );

    return this.resources.walletsResource.getByTicker(this.ticker)!.amount;
  }

  @computed
  get hasBalance() {
    return this.balance.gt(0);
  }

  @computed
  get balancePercent() {
    if (this.hasBalance) {
      return Number(
        formatPercentTillPrecision(toBig(this.amount).div(this.balance), 0)
      );
    }

    return 0;
  }

  @action
  setAmountByBalance = () => {
    return this.setAmount(this.balance.toString());
  };

  @action
  setAmountByPercentOfBalance = (percent: number) => {
    const amountValue = this.balance.mul(percent).div(100);

    this.setAmount(formatByTicker(amountValue, this.ticker, 0));
  };

  //#endregion

  //#region source

  @computed({
    equals: comparer.shallow,
  })
  get tickers() {
    return this.resources.walletsResource.marketEnabledTickers.filter(
      ticker => {
        return this.resources.exchangeTariffs.tickers.includes(ticker);
      }
    );
  }

  @computed
  get tickerInitial() {
    if (this.tickers.includes(this.args.ticker)) {
      return this.args.ticker;
    }

    return this.tickers.includes('eur') ? 'eur' : this.tickers[0];
  }

  @computed
  get initialAmount() {
    return this.args.initialAmount;
  }

  @computed
  get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.tickerInitial;
  }

  @computed
  get amount() {
    return this.instance?.$('amount').get('value') ?? this.initialAmount;
  }

  @computed
  get conversionTickerPrecision() {
    return this.tariff.precision;
  }

  @computed
  get amountWithoutFee() {
    let res = toBig(this.amount).minus(this.fee);

    if (res.lt(0)) {
      return toBig(0);
    }

    return res;
  }

  @computed
  get sourceError() {
    return getTranslatedValidationMessage(
      this.instance?.$('ticker').get('error') ||
        this.instance?.$('amount').get('error')
    );
  }

  @computed
  get hasSourceError() {
    return Boolean(this.sourceError);
  }

  @action
  setTicker = (value: string) => {
    const onChange = this.instance.$('ticker').get('onChange');

    onChange(value);
  };

  @action
  setAmount = (value: string) => {
    const onChange = this.instance.$('amount').get('onChange');

    onChange(normalizeAmount(value));
  };

  getConversionAmount = computedFn(_amount => {
    const amount = toBig(_amount);

    const {toAmount} = calculateAll(
      amount,
      this.tariff.fee,
      this.rate,
      this.ticker,
      this.conversionTicker
    );

    return toAmount;
  });

  getConversionAmountFormatted = computedFn(amount => {
    if (amount === '') {
      return '';
    }

    const conversionAmount = this.getConversionAmount(amount);

    return conversionAmount.gt(0)
      ? formatByTicker(conversionAmount, this.conversionTicker, 0)
      : '0';
  });

  //#endregion source

  //#region target

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickers() {
    return this.resources.walletsResource.marketEnabledTickers.filter(
      ticker => {
        return this.resources.exchangeTariffs
          .getConversionTickers(this.ticker)
          .includes(ticker);
      }
    );
  }

  @computed
  get conversionTickerInitial() {
    if (
      this.args.conversionTicker &&
      this.conversionTickers.includes(this.args.conversionTicker)
    ) {
      return this.args.conversionTicker;
    }

    return this.conversionTickers[0];
  }

  @computed
  get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickerInitial
    );
  }

  @computed
  get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value');
  }

  @computed
  get targetError() {
    return getTranslatedValidationMessage(
      this.instance?.$('conversionTicker').get('error') ||
        this.instance?.$('conversionAmount').get('error')
    );
  }

  @computed
  get hasTargetError() {
    return Boolean(this.targetError);
  }

  @action
  setConversionTicker = (value: string) => {
    const onChange = this.instance.$('conversionTicker').get('onChange');

    onChange(value);
  };

  @action
  setConversionAmount = (value: string) => {
    const onChange = this.instance.$('conversionAmount').get('onChange');

    onChange(normalizeAmountByTicker(value, this.conversionTicker));
  };

  getAmount = computedFn(_requiredConversionAmount => {
    const conversionAmountRequired = toBig(_requiredConversionAmount);

    const {fromAmount} = calculateAllReverse(
      conversionAmountRequired,
      this.tariff.fee,
      this.rate,
      this.ticker
    );

    return fromAmount;
  });

  getAmountFormatted = computedFn(conversionAmount => {
    if (conversionAmount === '') {
      return '';
    }

    const amount = this.getAmount(conversionAmount);

    return amount.gt(0) ? amount.toString() : '0';
  });

  //#endregion target

  //#region fee

  @computed
  get tariff() {
    const tariff = this.resources.exchangeTariffs.getTariff(
      this.ticker,
      this.conversionTicker
    );

    invariant(
      tariff,
      'Tariff not found',
      {},
      {ticker: this.ticker, conversionTicker: this.conversionTicker}
    );

    return tariff;
  }

  @computed
  get tariffReverse() {
    return this.resources.exchangeTariffs.getTariff(
      this.conversionTicker,
      this.ticker
    );
  }

  @computed
  get fee() {
    if (this.side === 'from') {
      const amount = toBig(this.amount);

      const {feeAmount} = calculateAll(
        amount,
        this.tariff.fee,
        this.rate,
        this.ticker,
        this.conversionTicker
      );

      return feeAmount;
    } else {
      const amount = toBig(this.conversionAmount);

      const {feeAmount} = calculateAllReverse(
        amount,
        this.tariff.fee,
        this.rate,
        this.ticker
      );

      return feeAmount;
    }
  }

  //#endregion fee

  //#region rate

  @observable
  rate!: Big;

  @observable
  calculated: Calculated = deserialize(Calculated, {
    isFixedRate: true,
  });

  @computed
  get rateIsFixed() {
    return this.calculated.isFixedRate;
  }

  @computed
  get volumeForecast() {
    return this.calculated.volumeForecast;
  }

  @computed
  get sparksAmount() {
    return this.calculated.sparksAmount ?? 0;
  }

  @observable
  rateTimeLeft: number = CURRENT_RATE_UPDATE_INTERVAL;

  @observable
  rateTimeInterval: number = 0;

  @computed
  get rateTimeIntervalName() {
    return `rate-interval-${this.rateTimeInterval}`;
  }

  @computed
  get rateTimeIntervalProgress() {
    return (
      (CURRENT_RATE_UPDATE_INTERVAL - this.rateTimeLeft) /
      (CURRENT_RATE_UPDATE_INTERVAL - 1)
    );
  }

  @action
  updateRate = () => {
    this.rateTimeInterval = this.rateTimeInterval + 1;
    this.rate = toBig(
      this.resources.ratesResource.getExchangeRate(
        this.ticker,
        this.conversionTicker
      )
    );
    this.rateTimeLeft = CURRENT_RATE_UPDATE_INTERVAL;
  };

  //#endregion rate

  //#region check

  @observable
  checkIsLoading = false;

  @observable
  checkCanceller?: Canceler;

  @computed
  get checkRateShowError() {
    return Boolean(!this.hasSourceError && this.instance.$submitted);
  }

  @action
  cancelCheck = () => {
    this.checkIsLoading = true;
    this.checkCanceller?.('__CANCELLED_REQUEST__');
  };

  @action
  check = () => {
    this.cancelCheck();

    const {token, cancel} = axios.CancelToken.source();

    this.checkCanceller = cancel;

    return TRANSPORT.API.post(
      '/v2/converts/check',
      {
        side: this.side,
        fromTicker: this.ticker,
        toTicker: this.conversionTicker,
        fromAmount: this.amount,
        toAmount: this.conversionAmount,
        rate: this.rate,
      },
      {
        cancelToken: token,
      }
    )
      .then(({data}) => {
        runInAction(() => {
          this.calculated = deserialize(Calculated, data);
        });
      })
      .catch(error => {
        if (this.checkRateShowError) {
          runInAction(() => {
            handleFormFieldsErrors(this.instance, error);
          });
        }

        runInAction(() => {
          this.calculated = deserialize(Calculated, {
            isFixedRate: this.calculated.isFixedRate,
          });
          this.checkIsLoading = false;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.checkIsLoading = false;
        });
      });
  };

  checkDebounced = debounce(this.check, 300);

  //#endregion check

  @action
  submit = () => {
    const {walletsDisclosureSigned, checkProductAvailability} =
      this.resources.authMeResource;

    if (!checkProductAvailability('exchange')) {
      return Promise.resolve();
    }

    if (!walletsDisclosureSigned) {
      SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {
        onSuccess: () =>
          SHARED_ROUTER_SERVICE.navigate('Exchange', {
            fromTicker: this.ticker,
            toTicker: this.conversionTicker,
          }),
      });
      return Promise.resolve();
    }

    return TRANSPORT.API.post('/v2/converts/exchange', {
      side: this.side,
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
      fromAmount: this.amount,
      toAmount: this.conversionAmount,
      rate: this.rate,
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.wallets.crypto_withdrawal.message.success'),
        });

        SHARED_ROUTER_SERVICE.navigate('__CloseModal', {});
        SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
        SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
          ticker: this.ticker,
        });
      })
      .catch(error => {
        handleFormSubmitRateChangedError(error, this.updateRate);
        handleFormSubmitError(this.instance, error, {
          price: 'conversionTicker',
        });
      });
  };

  constructor(
    args: ExchangeAuthorizedFormArgs,
    resources: ExchangeAuthorizedNewFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
          },
          conversionTicker: {
            value: this.conversionTicker,
          },
          amount: {
            value: this.amount,
            handlers: {
              onChange: () => {
                this.side = 'from';
              },
            },
          },
          conversionAmount: {
            handlers: {
              onChange: () => {
                this.side = 'to';
              },
            },
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                return yup.object().shape({
                  ticker: yup.string().required(),
                  conversionTicker: yup.string().required(),
                  amount: yup
                    .big()
                    .gt(0)
                    .lte(this.balance, messages.FUNDS_INSUFFICIENT),
                  conversionAmount: yup.big().gt(0),
                });
              }),
          }),
        },
        hooks: {
          onSuccess: this.submit,
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );
    this.disposers = [
      // set rateTimeLeft
      reaction(
        () => now(),
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            this.updateRate();
          }
        }
      ),
      // set amount / conversionAmount after fields changed
      autorun(() => {
        if (this.side === 'from') {
          this.instance
            .$('conversionAmount')
            .set(this.getConversionAmountFormatted(this.amount));
        } else {
          this.instance
            .$('amount')
            .set(this.getAmountFormatted(this.conversionAmount));
        }
      }),
      // set conversionTicker after fields changed
      reaction(
        () => [this.ticker],
        () => {
          if (this.swap) {
            this.swap = false;
          } else {
            if (this.conversionTickers.includes(this.conversionTicker)) {
              return;
            }

            this.instance.$('conversionTicker').set(this.conversionTickers[0]);
          }
        }
      ),
      // set rate after fields changed
      reaction(() => [this.ticker, this.conversionTicker], this.updateRate, {
        fireImmediately: true,
      }),
      // check rate after fields changed
      reaction(
        () => [
          this.side,
          this.ticker,
          this.conversionTicker,
          this.amount,
          this.conversionAmount,
          this.rate,
        ],
        () => {
          this.cancelCheck();
          this.checkDebounced();
        },
        {
          fireImmediately: true,
        }
      ),
      autorun(() => {
        this.resources.ratesResource.refetch();
      }),
    ];
  }

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };
}
