export function isRecaptchaError(error: any) {
  // if the error was thrown during recaptcha v3 lifecycle
  if (
    error?.message?.toLocaleLowerCase()?.includes('recaptcha') ||
    JSON.stringify(error)?.toLowerCase()?.includes('recaptcha')
  ) {
    return true;
  }

  // if server token v3 validation failed;
  if (
    (error?.response?.data?._error as string)
      ?.toLocaleLowerCase()
      ?.includes('recaptcha') ||
    JSON.stringify(error?.response)?.toLocaleLowerCase()?.includes('recaptcha')
  ) {
    return true;
  }

  return false;
}
