import {createRecaptchaError} from '../../utils/recaptchaErrors';

const addScript = (scriptUrl: string) => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');

    script.src = scriptUrl;

    script.onload = () => resolve();
    script.onerror = (...args) =>
      reject(
        createRecaptchaError(
          'v3',
          `script failed to load: ${JSON.stringify(args)}`
        )
      );

    document.body.appendChild(script);
  });
};

const TIMEOUT_INTERVAL = 5000;

const recaptchaReady = () => {
  return new Promise<void>((resolve, reject) => {
    const timeout = setTimeout(() => {
      clearInterval(interval);
      reject(
        createRecaptchaError(
          'v3',
          `failed to load under timeout interval ${TIMEOUT_INTERVAL}`
        )
      );
    }, TIMEOUT_INTERVAL);

    const interval = setInterval(() => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          clearInterval(interval);
          clearTimeout(timeout);
          resolve();
        });
      }
    }, 100);
  });
};

const createScript = (siteKey: string): [string, Element | undefined] => {
  const scriptUrl = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;

  const existingScript = document.querySelectorAll(
    `script[src="${scriptUrl}"]`
  )[0];

  return [scriptUrl, existingScript];
};

const loadRecaptcha = (siteKey: string) => {
  return new Promise<void>((resolve, reject) => {
    const [scriptUrl, existingScript] = createScript(siteKey);

    if (existingScript) {
      recaptchaReady().then(resolve, reject);
    } else {
      addScript(scriptUrl)
        .then(() => recaptchaReady().then(resolve, reject))
        .catch(reject);
    }
  });
};

export const getToken = (siteKey: string, action: string) => {
  return loadRecaptcha(siteKey).then(() => {
    // wrapping in promise because i am not sure execute is a real promise (?)
    return new Promise<string>((resolve, reject) => {
      window.grecaptcha
        .execute(siteKey, {action: `web/${action}`})
        .then(token => {
          resolve(token);
        })
        .catch(error => {
          reject(error);
        });
    });
  });
};
