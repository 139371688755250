import * as LocalAuthentication from 'expo-local-authentication';

export const mapAuthType = (
  types: LocalAuthentication.AuthenticationType[]
) => {
  if (types.length === 1) {
    const type = types[0];

    if (type === 1) {
      return 'FINGERPRINT';
    }

    if (type === 2) {
      return 'FACIAL_RECOGNITION';
    }
  }

  return 'FINGERPRINT';
};
