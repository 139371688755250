import {TRANSPORT} from '@youtoken/ui.transport';
import {action, computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {
  FeatureLoanCloseNowFormArgs,
  FeatureLoanCloseNowFormResources,
} from './index';
import {handleFormSubmitError} from '../../utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';

export class Form {
  @observable
  public args: FeatureLoanCloseNowFormArgs;

  @observable
  public resources: FeatureLoanCloseNowFormResources;

  @observable
  public instance: MobxReactForm;

  @computed.struct
  public get loan() {
    return this.resources.loanResource.data;
  }

  @action
  public restore = () => {};

  @action
  public submit = () => {
    return TRANSPORT.API.post(`/v${this.loan.version}/loan/closeNow`, {
      [this.loan.version === 1 ? 'loanId' : 'id']: this.loan.id,
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.loans.close_now_form.message.closed'),
        });
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  };

  public constructor(
    args: FeatureLoanCloseNowFormArgs,
    resources: FeatureLoanCloseNowFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {},
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
