import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {StepsHorizontal} from '@youtoken/ui.elements';
import {OnboardingWidgetResource} from '@youtoken/ui.resource-onboarding-widget';
import {type StepItemType} from '@youtoken/ui.resource-onboarding-widget/src/types';
import {type BaseIconName} from '@youtoken/ui.icons';
import {i18n} from '@youtoken/ui.service-i18n';

const getStepParameters = (type: StepItemType) => {
  const stepParameters = {
    SIGNUP: {
      iconName: 'check' as BaseIconName,
      label: i18n.t('surface.onboarding_widget.step_label.sign_up'),
    },
    VERIFICATIONS: {
      iconName: 'id_verification' as BaseIconName,
      label: i18n.t('surface.onboarding_widget.step_label.verification'),
    },
    DEPOSIT: {
      iconName: 'gift' as BaseIconName,
      label: i18n.t('surface.onboarding_widget.step_label.deposit'),
    },
  };

  return stepParameters[type];
};

export const Steps: React.FC<BoxProps> = observer(boxProps => {
  const {steps} = OnboardingWidgetResource.use({});

  const _steps = steps.map(item => {
    const params = getStepParameters(item.type);
    return {
      iconName: params.iconName,
      label: params.label,
      status: item.status,
    };
  });

  return <StepsHorizontal steps={_steps} {...boxProps} />;
});
