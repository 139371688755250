import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {IconPaymentMethod} from '@youtoken/ui.icons';

const tagsLogos: Record<string, React.ReactElement> = {
  mastercard: <IconPaymentMethod name="mastercard" size={18} />,
  // @ts-ignore: special case for visa icon
  visa: <IconPaymentMethod name="visa" color="#2C57FF" />,
  apple_pay: <IconPaymentMethod name="apple_pay" color="$text-01" />,
  google_pay: <IconPaymentMethod name="google_pay" color="$text-02" />,
};

interface MethodItemTagProps {
  name: string;
}

export const MethodItemTag: React.FC<MethodItemTagProps & BoxProps> = observer(
  ({name, ...boxProps}) => {
    return (
      <Box
        height={20}
        borderRadius={4}
        borderWidth={1}
        borderColor="$ui-01"
        alignItems="center"
        justifyContent="center"
        px={4}
        {...boxProps}
      >
        {tagsLogos[name] ?? (
          <Text variant="$body-03-medium-accent" color="$text-02">
            {name.toUpperCase()}
          </Text>
        )}
      </Box>
    );
  }
);
