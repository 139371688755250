import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowRepayUntilProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemDataTableRowRepayUntil: React.FC<LoanItemDataTableRowRepayUntilProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    if (!item.repayUntilFormatted) {
      return null;
    }

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_REPAY_UNTIL"
        left={t(
          item.isClosed
            ? 'surface.loans.item.table.closed'
            : 'surface.loans.item.table.repay_until'
        )}
        right={
          <Text
            testID="LOAN_ITEM_TABLE_ROW_REPAY_UNTIL_VALUE"
            variant="$body-02"
            color="$text-02"
          >
            {item.repayUntilFormatted}
          </Text>
        }
      />
    );
  });
