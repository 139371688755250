import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';
import {DataTableV1} from './v1';
import {DataTableV2} from './v2';

export const DataTable: React.FC<BoxProps> = observer(props => {
  const {
    form: {
      loan: {version},
    },
  } = useForm<LoanRepayOwnFundsFormState>();

  if (version === 1) {
    return <DataTableV1 {...props} />;
  }

  if (version === 2) {
    return <DataTableV2 {...props} />;
  }

  return null;
});
