import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const CurrentAntiPhishingCode: React.FC<{code: string}> = observer(
  ({code}) => {
    const {t} = useTranslation();

    if (!code) {
      return null;
    }
    return (
      <Box
        backgroundColor="$interactive-02"
        borderRadius={8}
        justifyContent="center"
        alignItems="center"
        py={16}
      >
        <Text variant="$body-01-high-accent" color="$text-01" mb={16}>
          {t('surface.profile.security.antiphishing.current')}
        </Text>
        <Text variant="$heading-02" color="$interactive-01">
          {code}
        </Text>
      </Box>
    );
  }
);
