import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps, useIsMobile} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {typeKeys} from '@youtoken/ui.resource-history-new';
import {Modal} from '@youtoken/ui.modal';
import {FiltersMenu} from './FiltersMenu';
import {AppliedFilter, AppliedFiltersWrapper} from './components';
import {TransactionType, type FiltersProps, ApplyFiltersProp} from './types';
import {allTickers} from './constants';

export const Filters: React.FC<FiltersProps & BoxProps> = observer(
  ({
    tickers = allTickers,
    selectedTickers,
    types = [],
    selectedTypes = [],
    applyFilters,
    showTitles = true,
    scrollOffset = 24,
    getTickerFormatted,
    testIdBase = '',
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = React.useState(false);
    const isMobile = useIsMobile();

    const shouldShowButtonLabel = React.useMemo(
      () =>
        !isMobile ||
        (selectedTickers.length === 0 && selectedTypes.length === 0),
      [isMobile, selectedTickers, selectedTypes]
    );

    const openModal = () => {
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
    };

    const unselectTicker = (ticker: string) => {
      applyFilters({
        selectedTickers: selectedTickers.filter(t => t !== ticker),
        selectedTypes,
      });
    };

    const unselectType = (type: TransactionType) => {
      applyFilters({
        selectedTickers,
        selectedTypes: selectedTypes.filter(t => t !== type),
      });
    };

    const handleApply: ApplyFiltersProp = ({
      selectedTickers,
      selectedTypes,
    }) => {
      applyFilters({selectedTickers, selectedTypes});
      closeModal();
    };

    return (
      <>
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          flexWrap="wrap"
          {...boxProps}
        >
          <AppliedFiltersWrapper scrollOffset={scrollOffset}>
            <Button
              icon="filter"
              type="secondary"
              size="small"
              mr={8}
              mb={8}
              onPress={openModal}
              testID={`${testIdBase}_APPLIED_FILTERS_BUTTON_FILTERS`}
            >
              {shouldShowButtonLabel
                ? t('surface.wallets.account_stats.filters')
                : null}
            </Button>

            {selectedTickers.map(ticker => {
              const testID = `${testIdBase}_APPLIED_FILTERS_TICKER_${ticker.toUpperCase()}`;
              const tickerFormatted = getTickerFormatted(ticker);
              return (
                <AppliedFilter
                  icon={
                    <LogoColored
                      name={ticker as LogoColoredIconName}
                      size={20}
                      testID={`${testID}_ICON`}
                    />
                  }
                  label={tickerFormatted}
                  onClose={() => {
                    unselectTicker(ticker);
                  }}
                  testID={testID}
                  key={`applied-filter-${ticker}`}
                />
              );
            })}

            {selectedTypes.map(type => (
              <AppliedFilter
                // @ts-ignore
                label={t(typeKeys[type], {
                  defaultValue: type.toLowerCase(),
                })}
                onClose={() => unselectType(type)}
                testID={`${testIdBase}_APPLIED_FILTERS_TYPE_${type}`}
                key={`applied-filter-${type}`}
              />
            ))}
          </AppliedFiltersWrapper>
        </Box>

        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          title={t('surface.wallets.account_stats.filters')}
          webHeight={700}
          testID={`${testIdBase}_FILTERS`}
        >
          <Box height="100%" justifyContent="center" alignItems="center">
            <FiltersMenu
              tickers={tickers}
              selectedTickers={selectedTickers}
              types={types}
              selectedTypes={selectedTypes}
              applyFilters={handleApply}
              getTickerFormatted={getTickerFormatted}
              testIdBase={testIdBase}
              showTitles={showTitles}
            />
          </Box>
        </Modal>
      </>
    );
  }
);
