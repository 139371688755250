import {observable} from 'mobx';
import {Form} from './Form';
import {View} from './View';
import {type DepositInvoiceFormResources} from './types';

export class DepositInvoiceForm {
  @observable
  public form: Form;

  @observable
  public view: View;

  public constructor(ticker: string, resources: DepositInvoiceFormResources) {
    this.form = new Form(ticker, resources);
    this.view = new View(ticker, resources);
  }
}
