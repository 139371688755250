import {warning} from '@youtoken/ui.utils';

export const coinSymbols: Record<string, string> = {
  usd: '$',
  eur: '€',
};

export const getCoinSymbol = (ticker: string) => {
  const symbol = coinSymbols[ticker];

  warning(symbol, `getCoinSymbol: symbol by ticker not found`, {
    ticker,
  });

  return symbol;
};
