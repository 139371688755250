import {observable} from 'mobx';
import {
  AdvCashCreatePaymentFormArgs,
  AdvCashCreatePaymentFormResources,
} from './types';
import {Form} from './Form';
import {View} from './View';

export class AdvCashCreatePaymentFormState {
  @observable
  form: Form;

  @observable
  view: View;

  constructor(
    args: AdvCashCreatePaymentFormArgs,
    resources: AdvCashCreatePaymentFormResources
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
