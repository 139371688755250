import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {
  ConversionPairSourceAuthed,
  ConversionPairTarget,
  FormFooter,
  InfoTableV2,
  TwoFA,
} from '../../..';
import {BuyCryptoFormAuthorizedOnRampState} from '../../../../state';
import {DepositMethodRampEnum} from '../../../../types';
import {getTitle} from '../../utils';
import {HeaderBase} from '../../../../../../components';
import {PayWithField} from '../../../PayWith/PayWithField';
import {
  type BuyCryptoFormAuthorizedOnRampWizardNavigator,
  BuyCryptoFormAuthorizedOnRampWizardScreenName,
} from '..';

export const BuyCryptoFormAuthorizedOnRampMain: React.FC = observer(() => {
  const {navigate, goBack} = useWizard<
    BuyCryptoFormAuthorizedOnRampWizardNavigator,
    BuyCryptoFormAuthorizedOnRampWizardScreenName.MAIN
  >();

  const {
    form: {conversionTicker, depositMethod},
  } = useForm<BuyCryptoFormAuthorizedOnRampState>();

  return (
    <>
      <HeaderBase onPressBack={goBack}>{getTitle(conversionTicker)}</HeaderBase>
      <Box flex={1} flexGrow={1} p={24} justifyContent="space-between">
        <Box>
          <ConversionPairSourceAuthed />
          <ConversionPairTarget mt={24} />
          <PayWithField
            onPress={() => {
              navigate(
                BuyCryptoFormAuthorizedOnRampWizardScreenName.CHOOSE_METHOD
              );
            }}
            mt={24}
          />
        </Box>
        <Box>
          {depositMethod !== DepositMethodRampEnum.BANK_WIRE && (
            <InfoTableV2 mt={24} />
          )}
          <TwoFA mt={24} />
        </Box>
      </Box>
      <FormFooter />
    </>
  );
});
