import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Illustration} from '@youtoken/ui.elements';

const imageSize = {default: 240, tablet: 312};

export const SavingsSignAgreementsFormImage: React.FC<BoxProps> = boxProps => {
  return (
    <Box
      justifyContent="center"
      alignSelf="center"
      flex={1}
      p={24}
      {...boxProps}
    >
      <Box width={imageSize} height={imageSize}>
        <Illustration
          name="illustration-2"
          resizeMode="contain"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
    </Box>
  );
};
