import * as React from 'react';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import type {StepItemProps} from './types';

export const StepItem: React.FC<BoxProps & StepItemProps> = ({
  backgroundColor,
  iconColor,
  textColor,
  icon,
  title,
  description,
  actionIcon,
  linkUp,
  linkDown,
  // NOTE: The prop below is used to show a solid single link instead of two half links. Currently, it only works with the bottom link.
  shouldShowSingleLink,
  onPress,
  ...boxProps
}) => {
  const Component = onPress ? TouchableBox : Box;

  return (
    <Component
      backgroundColor={backgroundColor}
      borderRadius={12}
      p={16}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      onPress={onPress}
      mb={shouldShowSingleLink ? (linkDown ? 16 : 0) : undefined}
      pt={shouldShowSingleLink ? 0 : 16}
      {...boxProps}
    >
      {linkUp && (
        <Box
          position="absolute"
          top={0}
          left={35}
          width={2}
          height={12}
          backgroundColor={iconColor}
        />
      )}
      <Box flex={1} flexDirection="row" alignItems="center">
        <Box
          width={40}
          height={40}
          borderRadius={20}
          backgroundColor={iconColor}
          justifyContent="center"
          alignItems="center"
          mr={16}
        >
          <Icon name={icon} size={24} color={textColor} />
        </Box>
        <Box flex={1} flexDirection="column">
          <Text color={textColor} variant="$body-01-high-accent" mb={2}>
            {title}
          </Text>
          <Text color={textColor} variant="$body-02">
            {description}
          </Text>
        </Box>
      </Box>
      {linkDown && (
        <Box
          position="absolute"
          bottom={shouldShowSingleLink ? -12 : 0}
          left={35}
          width={2}
          height={shouldShowSingleLink ? 24 : 12}
          backgroundColor={iconColor}
        />
      )}
      {actionIcon && <Icon name={actionIcon} size={20} color={textColor} />}
    </Component>
  );
};
