import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const VerificationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 11a.75.75 0 01-.75-.75V3a2 2 0 012-2h7.25a.75.75 0 010 1.5H3.5a1 1 0 00-1 1v6.75a.75.75 0 01-.75.75zM22 12a6 6 0 10-8.282 5.55A8.754 8.754 0 007.25 26a.75.75 0 001.5 0 7.25 7.25 0 1114.5 0 .75.75 0 001.5 0 8.754 8.754 0 00-6.468-8.45A6.002 6.002 0 0022 12zm-10.5 0a4.49 4.49 0 011.522-3.374c.885 1.35 2.006 2.28 3.356 2.769 1.263.456 2.636.533 4.107.24A4.5 4.5 0 1111.5 12zM16 7.5c-.603 0-1.178.119-1.704.334.71 1.072 1.57 1.781 2.592 2.15.973.351 2.046.419 3.228.194A4.501 4.501 0 0016 7.5zm15 2.75a.75.75 0 01-1.5 0V3.5a1 1 0 00-1-1h-6.75a.75.75 0 010-1.5H29a2 2 0 012 2v7.25zM1.75 21a.75.75 0 00-.75.75V29a2 2 0 002 2h7.25a.75.75 0 000-1.5H3.5a1 1 0 01-1-1v-6.75a.75.75 0 00-.75-.75zm29.25.75a.75.75 0 00-1.5 0v6.75a1 1 0 01-1 1h-6.75a.75.75 0 000 1.5H29a2 2 0 002-2v-7.25z"
          fill={color}
        />
      </Svg>
    );
  }
);
