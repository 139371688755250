import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Box} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {InformationTableRightElement} from '@youtoken/ui.hodl-elements';
import {FlipHODLForm} from '../../form';

export const InfoTable: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    view: {quoteTickerFormatted, inputTickerFormatted, infoTableData},
  } = useForm<FlipHODLForm>();

  if (!infoTableData) {
    return null;
  }

  const {
    hodlDirectionFormatted,
    initialPriceFormatted,
    takeProfitPercentFormatted,
    takeProfitFormatted,
    stopLossPercentFormatted,
    stopLossFormatted,
    profitLossTextFormatted,
    profitLossAmountFormatted,
    profitLossTickerFormatted,
    closeNowOutputAmountFormatted,
  } = infoTableData;

  return (
    <Box>
      <InformationTable py={16}>
        <InformationTableRow
          left={t('surface.hodls.item.details_table.direction')}
          right={
            <InformationTableRightElement mainText={hodlDirectionFormatted} />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.item.details_table.initial_price')}
          right={
            <InformationTableRightElement
              mainText={initialPriceFormatted}
              additionalText={quoteTickerFormatted}
            />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.extend_tp.table.max_tp')}
          leftTooltipText={t(
            'surface.hodls.item.details_table.take_profit_tooltip'
          )}
          right={
            <InformationTableRightElement
              preInfoText={takeProfitPercentFormatted}
              mainText={takeProfitFormatted}
              additionalText={quoteTickerFormatted}
            />
          }
          tooltipContentWidth={290}
          tooltipOffset={45}
        />
        <InformationTableRow
          left={t('surface.hodls.extend_tp.table.max_sl')}
          leftTooltipText={t(
            'surface.hodls.item.details_table.margin_call_tooltip'
          )}
          right={
            <InformationTableRightElement
              preInfoText={stopLossPercentFormatted}
              mainText={stopLossFormatted}
              additionalText={quoteTickerFormatted}
            />
          }
          tooltipContentWidth={250}
          tooltipOffset={45}
        />
        <InformationTableRow
          left={profitLossTextFormatted}
          leftTooltipText={t('surface.hodls.close_now.profit_loss_toolip')}
          tooltipContentWidth={250}
          tooltipOffset={85}
          right={
            <InformationTableRightElement
              mainText={profitLossAmountFormatted}
              additionalText={profitLossTickerFormatted}
            />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.item.details_table.output_amount')}
          right={
            <InformationTableRightElement
              mainText={closeNowOutputAmountFormatted}
              additionalText={inputTickerFormatted}
            />
          }
          isLast
        />
      </InformationTable>
    </Box>
  );
});
