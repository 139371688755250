// @ts-nocheck Strange errors, hope we will just get rid of FormikCustom soon
import React from 'react';
import {Formik, FormikActions, FormikErrors, FormikProps} from 'formik';
import {FormikContextProvider} from '@web-app/src/components/contexts';

interface FormikPropsCustom<Values> extends FormikProps<Values> {
  handlers?: any;
}

interface FormikConfigCustom<Values = any> {
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  isInitialValid?: boolean | ((props: object) => boolean | undefined);
  enableReinitialize?: boolean;
  component?: React.FC<Partial<FormikPropsCustom<Values>>>;
  render?: (props: FormikPropsCustom<Values>) => React.ReactNode;
  children?:
    | ((props: FormikProps<Values>) => React.ReactNode)
    | React.ReactNode;
  initialValues?: Values;
  initialStatus?: any;
  onReset?: (values: Values, formikActions: FormikActions<Values>) => void;
  onSubmit?: (values: Values, formikActions: FormikActions<Values>) => void;
  validationSchema?: any | (() => any);
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>;
  name?: string;

  submitOnlyButton?: boolean;
  requireConfirm?: boolean;
  confirmText?: string;
  handlers?: (...props) => any;
  submitOnEnter?: boolean;
}

const FormikComponent: React.FC<{
  formikProps: FormikConfigCustom;
  renderProps: FormikProps<any>;
}> = ({formikProps, renderProps}) => {
  const {isSubmitting, isValidating, handleSubmit} = renderProps;
  const {
    requireConfirm,
    confirmText,
    handlers,
    component: Component,
    render,
    children,
    submitOnlyButton,
    name,
    submitOnEnter = true,
  } = formikProps;

  const handleSubmitCustom = e => {
    (() => {
      if (requireConfirm) {
        const result = window.confirm(confirmText);
        if (!result) {
          return;
        }
      }
      if (!isSubmitting && !isValidating) {
        handleSubmit(e);
      }
    })();

    if (e && e.preventDefault) {
      e.preventDefault();
    }
  };

  const keyDownHandler = React.useCallback(
    e => {
      if (e.key === 'Enter' && submitOnEnter) {
        handleSubmitCustom(e);
      }
    },
    [handleSubmitCustom]
  );

  React.useEffect(() => {
    const input = document.activeElement;
    input?.addEventListener('keydown', keyDownHandler);
    return () => {
      input?.removeEventListener('keydown', keyDownHandler);
    };
  });

  const propsCustom = {
    ...renderProps,
    handleSubmit: handleSubmitCustom,
    name,
    requireConfirm,
    confirmText,
    handlers: handlers && handlers(renderProps),
  };

  const ContextProvider = (
    <FormikContextProvider formikProps={propsCustom}>
      {Component && <Component {...propsCustom} />}
      {render && render(propsCustom)}
      {typeof children === 'function' ? children(propsCustom) : children}
    </FormikContextProvider>
  );

  if (submitOnlyButton) {
    return ContextProvider;
  }

  return (
    <form
      onSubmit={e => e.preventDefault()}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {ContextProvider}
    </form>
  );
};

export const FormikCustom: React.FC<FormikConfigCustom> = props => {
  const {initialValues = {}, onSubmit = () => {}, ...otherProps} = props;

  return (
    <Formik
      {...{initialValues, onSubmit}}
      {...otherProps}
      render={renderProps => (
        <FormikComponent formikProps={props} renderProps={renderProps} />
      )}
    />
  );
};
