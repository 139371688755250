import * as React from 'react';
import {
  Box,
  type BoxProps,
  Text,
  TouchableBox,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {TradingViewModeButton} from './TradingViewModeButton';
import {CONTROLS_HEIGHT} from '../constants';

export interface TradingViewModeSwitchProps extends BoxProps {
  mode: 'classic' | 'tradingview';
  onChange: () => void;
}

export const TradingViewModeSwitch: React.FC<TradingViewModeSwitchProps> =
  React.memo(({mode, onChange, ...boxProps}) => {
    const isMobile = useIsMobile();

    if (isMobile) {
      return (
        <Box flexDirection="row" justifyContent="flex-end" {...boxProps}>
          <TradingViewModeButton mode={mode} onPress={onChange} ml={16} />
        </Box>
      );
    }

    return (
      <Box flexDirection="row" {...boxProps}>
        <TouchableBox
          activeOpacity={0.8}
          height={CONTROLS_HEIGHT}
          flexDirection="row"
          backgroundColor={
            mode === 'classic' ? '$interactive-02' : '$transparent'
          }
          alignItems="center"
          justifyContent="center"
          px={12}
          borderRadius={6}
          onPress={onChange}
        >
          <Box ml={4}>
            <Text
              variant="$body-02-medium-accent"
              color={mode === 'classic' ? '$text-05' : '$text-02'}
            >
              Classic
            </Text>
          </Box>
        </TouchableBox>
        <TouchableBox
          activeOpacity={0.8}
          height={CONTROLS_HEIGHT}
          flexDirection="row"
          backgroundColor={
            mode === 'tradingview' ? '$interactive-02' : '$transparent'
          }
          alignItems="center"
          justifyContent="center"
          px={12}
          borderRadius={6}
          onPress={onChange}
        >
          <Box ml={4}>
            <Text
              variant="$body-02-medium-accent"
              color={mode === 'tradingview' ? '$text-05' : '$text-02'}
            >
              TradingView
            </Text>
          </Box>
        </TouchableBox>
      </Box>
    );
  });
