import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '../_common';
import {UpgradeYourLevelSurfaceCell} from '@youtoken/ui.surface-account-levels';

export const modalUpgradeYourLevelName = 'modalUpgradeYourLevel';

export const ModalUpgradeYourLevel: typeof Modal = cell(props => {
  return (
    <Modal {...props}>
      <UpgradeYourLevelSurfaceCell p={24} />
    </Modal>
  );
});
