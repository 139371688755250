import {observable} from 'mobx';
import {serializable, custom, primitive, optional} from 'serializr';

const _number = () =>
  custom(
    outValue => outValue,
    inValue => (isFinite(inValue) ? Number(inValue) : 0)
  );

export class CloseData {
  @observable
  @serializable(_number())
  hftFeeAmount!: number;

  @observable
  @serializable(optional(_number()))
  mainOutputAmount?: number;

  @observable
  @serializable(optional(_number()))
  additionalOutputAmount?: number;

  @observable
  @serializable(_number())
  percent!: number;

  @observable
  @serializable(_number())
  feeIterations!: number;

  @observable
  @serializable(_number())
  feeAmount!: number;

  @observable
  @serializable(primitive())
  feePercent!: string;

  @observable
  @serializable(_number())
  settlementFeeAmount!: number;

  @observable
  @serializable(_number())
  revShareFeeAmount!: number;

  @observable
  @serializable(_number())
  revShareFeeOf!: number;

  @observable
  @serializable(_number())
  unifiedFeeAmount!: number;

  @observable
  @serializable(_number())
  unifiedFee!: number;

  @observable
  @serializable(_number())
  outputAmount!: number;

  @observable
  @serializable(_number())
  profit!: number;

  @observable
  @serializable(_number())
  convertPL!: number;
}
