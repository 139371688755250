import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {extractErrorFromResponse} from './extractErrorFromResponse';
import {getTranslatedValidationMessage} from './getTranslatedValidationMessage';

export const handleGeneralErrorTranslated = (response: any) => {
  const _error = extractErrorFromResponse(response, '_error');
  const _errorTranslated = getTranslatedValidationMessage(_error);

  LOCAL_NOTIFICATIONS.error({
    text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
  });
};
