import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Button} from '@youtoken/ui.buttons';

export const PleaseVerifyFooterRefreshButton: React.FC = observer(() => {
  const {t} = useTranslation();

  const {isLoading, refetch} = AuthMeResource.use({});

  const handlePress = React.useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Button
      testID="REFRESH_ACCOUNT_BUTTON"
      size="large"
      loading={isLoading}
      onPress={handlePress}
    >
      Refresh
    </Button>
  );
});
