import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Separator} from '@youtoken/ui.elements';
import {FeatureTurboCreateForm} from './stores';
import * as TurboForm from './Fields';
import {Form} from '../../../components';

export const TurboCreateForm: React.FC<BoxProps> = cell(({...props}) => {
  const form = FeatureTurboCreateForm.use({});

  React.useEffect(() => {
    return () => {
      form.reset();
    };
  }, []);

  return (
    <Form form={form}>
      <Box width="100%" bg="$ui-background" {...props}>
        <TurboForm.AmountFieldSet />
        <Separator />
        <TurboForm.PlansField />
        <Separator />
        <TurboForm.LeverageField />
        <TurboForm.CalculatedTable />
        <TurboForm.Informer />
        <TurboForm.TakeProfitFieldSet />
        <Separator />
        <TurboForm.AgreeToTermsField />
        <Separator />
        <TurboForm.Footer />
      </Box>
    </Form>
  );
});
