import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {TextInput, TextInputProps} from '@youtoken/ui.inputs';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import Big from 'big.js';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';

interface AmountInTickerTextInputProps extends TextInputProps {
  ticker: string;
  precision?: number;
  editable?: boolean;
}

export const AmountInTickerTextInput: React.FC<
  AmountInTickerTextInputProps
> = ({
  value,
  ticker,
  onChangeText,
  precision = getCoinDecimalPrecision(ticker),
  testID,
  editable = true,
  ...textInputProps
}) => {
  const handleTextChange = React.useCallback(
    (value: string) => {
      if (!editable) {
        return;
      }
      onChangeText?.(normalizeAmountWithPrecision(value, precision));
    },
    [editable, onChangeText, precision]
  );

  const amountPlaceholder = React.useMemo(
    () => Big(0).toFixed(precision),
    [precision]
  );

  return (
    <Box position="relative">
      <TextInput
        value={value}
        placeholder={amountPlaceholder}
        textAlign="right"
        autoComplete="off"
        autoCorrect={false}
        autoCapitalize="none"
        importantForAutofill="no"
        keyboardType="decimal-pad"
        onChangeText={handleTextChange}
        editable={editable}
        LeftPartComponent={
          <Box flexDirection="row" alignItems="center" height="100%" px={16}>
            <Box flexDirection="row" alignItems="center">
              <LogoColored name={ticker as LogoColoredIconName} size={20} />
              <Text
                ml={8}
                mr={2}
                variant="$body-01-medium-accent"
                color="$text-01"
              >
                {ticker.toUpperCase()}
              </Text>
            </Box>
          </Box>
        }
        {...textInputProps}
      />
    </Box>
  );
};
