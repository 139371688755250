import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {CopyButton, QRCode} from '@youtoken/ui.elements';
import {
  FeatureCryptoDepositExternal,
  type FeatureCryptoDepositExternalArgs,
} from '../state';

export const CryptoDepositExternalAddress: React.FC<FeatureCryptoDepositExternalArgs> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    const {walletTickerFormatted, walletVersionAddress} =
      FeatureCryptoDepositExternal.use({
        ticker,
      });

    if (!walletVersionAddress) {
      return null;
    }

    return (
      <Box alignItems="center">
        <Box mb={24}>
          <QRCode
            size={isMobile ? 146 : 105}
            value={walletVersionAddress}
            testID="DEPOSIT_QR"
          />
        </Box>
        <Box width="100%" px={{desktop: 24, default: 16}}>
          <Text variant="$body-02" color="$text-02" textAlign="center" mb={4}>
            {t(`surface.wallets.crypto_deposit.address`, {
              ticker: walletTickerFormatted,
            })}
          </Text>
          <Text
            variant="$body-01-medium-accent"
            textAlign="center"
            mb={10}
            testID="DEPOSIT_ADDRESS"
          >
            {walletVersionAddress}
          </Text>
        </Box>
        <CopyButton
          icon="copy"
          size="small"
          type="secondary"
          testID="COPY_ADDRESS_BUTTON"
          message={walletVersionAddress}
          messageCopied={t('surface.wallets.crypto_deposit.address_copied', {
            ticker: walletTickerFormatted,
          })}
        >
          {t('surface.wallets.crypto_deposit.copy')}
        </CopyButton>
      </Box>
    );
  });
