import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const BoostIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.149 9c.47 0 .851.448.851 1v6c0 .552-.381 1-.851 1H13.85c-.47 0-.851-.448-.851-1v-6c0-.552.381-1 .851-1h2.298zm-10 5c.47 0 .851.448.851 1v1c0 .552-.381 1-.851 1H3.85c-.469 0-.85-.448-.85-1v-1c0-.552.381-1 .851-1H6.15zm5-2c.47 0 .851.448.851 1v3c0 .552-.381 1-.851 1H8.85c-.469 0-.85-.448-.85-1v-3c0-.552.381-1 .851-1h2.298zM16.5 3a.5.5 0 01.5.5v3.793a.5.5 0 01-.854.353L14.7 6.2c-2.59 3.012-7.706 5.55-11.2 5.55a.75.75 0 010-1.5c3.093 0 7.884-2.416 10.138-5.114l-1.284-1.282A.5.5 0 0112.707 3H16.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
