import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {RewardsReviewModal} from '.';

export const RewardsReviewModalTrigger: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  return (
    <>
      <Button
        size="medium"
        icon="pending"
        color="attention"
        mt={24}
        onPress={handleToggle}
      >
        {t('surface.referral_program.rewards.review_action')}
      </Button>
      <RewardsReviewModal isOpen={isOpen} onClose={handleToggle} />
    </>
  );
});
