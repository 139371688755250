import * as React from 'react';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {CoinsPair} from '@youtoken/ui.elements';

const ICON_SIZE = 40;

type HeaderSectionProps = {
  item: HistoryItemData;
};

export const HeaderSection: React.FC<HeaderSectionProps> = ({item}) => {
  const {
    isConvert,
    createdAtWithTimeFormatted,
    typeLabel,
    ticker,
    conversionTicker,
    iconName,
  } = item;

  return (
    <Box
      width="100%"
      backgroundColor="$interactive-02"
      alignItems="center"
      pt={24}
      px={24}
      mb={ICON_SIZE / 2 + 12}
    >
      <Text variant="$body-01" color="$text-02">
        {createdAtWithTimeFormatted}
      </Text>

      <Heading variant="$heading-02" color="$text-01" mt={4}>
        {typeLabel}
      </Heading>

      <Box mt={10} style={{transform: [{translateY: ICON_SIZE / 2}]}}>
        {isConvert ? (
          <CoinsPair
            firstTicker={ticker as LogoColoredIconName}
            secondTicker={conversionTicker as LogoColoredIconName}
            iconSize={ICON_SIZE}
            iconOffset={32}
            overflow="visible"
          />
        ) : (
          <LogoColored size={ICON_SIZE} name={iconName} />
        )}
      </Box>
    </Box>
  );
};
