import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Label} from '../../../../../components';
import {Amount} from './Amount';
import {Ticker} from './Ticker';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const ConversionTicker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  return (
    <Box zIndex={20} width="100%" {...props}>
      <Label>{t('surface.loans.ftp_form.conversion_amount')}</Label>
      <Box position="relative">
        <Amount />
        <Ticker />
      </Box>
    </Box>
  );
});
