import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Wallets} from '@youtoken/ui.legacy-components';
import {Header} from './Header';
import {List} from './List';

const {
  Groups: {Header: HeaderShared},
} = Wallets;

export const Content: React.FC = cell(() => {
  return (
    <>
      <Header />
      <HeaderShared px={{default: 20, desktop: 0}} py={20} />
      <List />
    </>
  );
});
