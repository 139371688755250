import * as React from 'react';
import {useCountdown} from '@youtoken/ui.hooks';
import {Text, type TextProps} from '@youtoken/ui.primitives';
import {formatMilliseconds} from './HexGrid/Hexagon/utils';

type TimerProps = {
  timeLeft: number;
  onTimerEnd?: () => void;
} & TextProps;

export const Timer: React.FC<TimerProps> = ({
  timeLeft,
  onTimerEnd,
  ...textProps
}) => {
  const [millisecondsLeft, {start}] = useCountdown(timeLeft, 1000, () => {
    onTimerEnd?.();
  });

  React.useEffect(() => {
    start(timeLeft);
  }, [timeLeft]);

  return (
    <Text variant="$body-02" color="$text-01" {...textProps}>
      {formatMilliseconds(millisecondsLeft)}
    </Text>
  );
};
