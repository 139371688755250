import {action, type IObservableArray} from 'mobx';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import type {
  IBankWireTemplatesArgs,
  IBankWireTemplate,
  IBankWireTemplatesResponse,
  IBankWireTemplateResponse,
} from './types';
import {BankWireTemplatesResponse} from './BankWireTemplatesResponse';
import {convertData} from './utils';

export class BankWireTemplatesResource extends createStaticResource<
  IBankWireTemplatesArgs,
  IBankWireTemplatesResponse
>({
  getKey: ({ticker}) => `bankWireTemplates(${ticker})`,
  getData: ({ticker}) => {
    return TRANSPORT.API.get('/v1/payment-details/out/wire').then(res => {
      const data = res.data
        .filter(
          (template: IBankWireTemplateResponse) =>
            template.data.ticker === ticker
        )
        .map((template: IBankWireTemplateResponse) =>
          convertData({id: template.id, ...template.data}, 'toFrontend')
        );

      return deserialize(
        BankWireTemplatesResponse,
        data as IObservableArray<IBankWireTemplatesResponse>
      );
    });
  },
  skipRefreshOnVisible: false,
}) {
  @action public add = (template: IBankWireTemplate) => {
    return TRANSPORT.API.post(
      '/v1/payment-details/out/wire',
      convertData(template, 'toBackend')
    ).then(() => {
      this.refetch();
    });
  };

  @action public edit = (templateId: string, template: IBankWireTemplate) => {
    return TRANSPORT.API.post(
      `v1/payment-details/out/wire/${templateId}`,
      convertData(template, 'toBackend')
    ).then(() => {
      this.refetch();
    });
  };

  @action public remove = (templateId: string) => {
    return TRANSPORT.API.delete(
      `/v1/payment-details/out/wire/${templateId}`
    ).then(() => {
      this.refetch();
    });
  };
}
