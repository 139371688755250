import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const LinkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M11.136 3.179a4.04 4.04 0 012.85-1.18 4.04 4.04 0 012.85 1.18l.05.05-.014.027A4.026 4.026 0 0118 6.057a4.045 4.045 0 01-1.177 2.852l-3.092 3.084a4.04 4.04 0 01-2.85 1.179 4.04 4.04 0 01-2.85-1.18 3.997 3.997 0 01-.414-.488c-.277-.385-.172-.896.148-1.216l.359-.358a.238.238 0 01.243-.058.28.28 0 01.175.17c.124.325.315.623.562.87a2.531 2.531 0 003.568 0l3.09-3.084a2.504 2.504 0 00.73-1.77 2.516 2.516 0 00-.73-1.772 2.53 2.53 0 00-3.567 0l-1.66 1.658-.055-.015a5.145 5.145 0 00-1.358-.182.232.232 0 01-.163-.396l2.177-2.172z"
          fill={color}
        />
        <Path
          d="M6.278 8.007a4.04 4.04 0 012.85-1.179 4.04 4.04 0 013.264 1.667c.278.386.173.896-.148 1.216l-.355.355a.23.23 0 01-.237.074.272.272 0 01-.183-.167 2.464 2.464 0 00-.557-.859 2.531 2.531 0 00-3.568 0l-3.09 3.084a2.502 2.502 0 00-.73 1.771 2.515 2.515 0 00.73 1.771 2.531 2.531 0 003.566 0l1.232-1.229c.267-.267.649-.362 1.01-.31.273.04.549.056.824.052h.002c.208 0 .312.253.164.4L8.88 16.822A4.04 4.04 0 016.03 18a4.039 4.039 0 01-2.85-1.179A4.02 4.02 0 012 13.969a4.04 4.04 0 011.18-2.851l3.098-3.111z"
          fill={color}
        />
      </Svg>
    );
  }
);
