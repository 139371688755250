import big from 'big.js';
import {computed, observable} from 'mobx';
import {type DepositInvoiceFormResources} from './types';

export class View {
  @observable
  public readonly ticker: string;

  @observable
  private resources: DepositInvoiceFormResources;

  public constructor(ticker: string, resources: DepositInvoiceFormResources) {
    this.ticker = ticker;
    this.resources = resources;
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  public get minAmountFormatted() {
    const fee = this.resources.fees.getFeeForDeposit('wire', this.ticker);
    const minAmount = fee?.minAmount || big(0);
    return minAmount.toFixed(2);
  }
}
