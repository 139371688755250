import React from 'react';
import {TurboCreateForm} from '@youtoken/ui.legacy-components';
import {Box} from '@youtoken/ui.primitives';

export const Form: React.FC = () => {
  return (
    <Box
      backgroundColor="$ui-background"
      borderRadius={10}
      p={0}
      flex={1}
      borderColor="$ui-01"
      borderWidth={1}
    >
      <TurboCreateForm borderRadius={10} />
    </Box>
  );
};
