import * as React from 'react';
import {BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {Switch} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface FormANotUSProps extends BoxProps {
  hasError: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const FormANotUS: React.FC<FormANotUSProps> = ({
  hasError,
  value,
  onChange,
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Box flex={1} flexDirection="row" alignItems="center" position="relative">
        {/* @ts-ignore */}
        <Text variant="$body-02" mr={5} focusable={false}>
          {t('surface.form_a.field.not_us')}
        </Text>
        <QuestionTooltip content={t('surface.profile.form_a.step1.text')} />
      </Box>
      <Switch hasError={hasError} value={value} onValueChange={onChange} />
    </Box>
  );
};
