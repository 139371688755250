import axios, {type Canceler} from 'axios';
import {runInAction} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {Form as CreateForm} from '../surfaces/UmaCreateSurface/form/Form';

const CANCEL_MESSAGE = '__CANCELLED_REQUEST__';

// NOTE: Is used in UmaEditForm and UmaCreateForm to validate uma input
export const asyncCheckUmaAvailability = async (
  form: CreateForm,
  checkCanceller: Canceler | undefined
) => {
  // Canceling of the previous request
  checkCanceller?.(CANCEL_MESSAGE);
  form.isUmaChecking = true;

  const {token, cancel} = axios.CancelToken.source();

  form.checkCanceller = cancel;
  return await TRANSPORT.API.get('/v1/uma/check-availability', {
    params: {
      uma: form.umaFullName,
    },
    cancelToken: token,
  })
    .catch(error => {
      if (axios.isCancel(error)) {
        return;
      }

      throw error;
    })
    .finally(() => {
      runInAction(() => {
        form.isUmaChecking = false;
      });
    });
};
