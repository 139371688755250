import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {LedgerInfo} from '@youtoken/ui.elements';
import {AuthPromoBackground} from '@web-app/src/components/pages/AuthPages/Elements';

export type AuthFormWrapperProps = {};

export const AuthFormWrapper: React.FC<AuthFormWrapperProps> = ({children}) => {
  return (
    <Box
      width="100%"
      flexDirection={{
        default: 'column',
        tablet: 'row',
      }}
      justifyContent={{
        default: 'flex-start',
        tablet: 'space-between',
      }}
      px={{default: 24, tablet: 0}}
    >
      <Box
        position="relative"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="flex-start"
        width={{
          default: '100%',
          tablet: '50%',
        }}
      >
        <AuthPromoBackground />
        <LedgerInfo visible={{default: false, tablet: true}} mt={32} pr={88} />
      </Box>

      <Box
        width={{default: '100%', tablet: '50%'}}
        alignItems="stretch"
        pb={20}
        pl={{
          default: 0,
          tablet: 80,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
