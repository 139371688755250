// NOTE: Source of data: https://youhodler.atlassian.net/wiki/spaces/YOUT/pages/3997827073/Scanner+availability+for+the+coins

type ExplorerLink = {
  name: string;
  testnet?: string;
  production: string;
};

const bscscanLink = {
  name: 'bscscan.com',
  testnet: 'https://testnet.bscscan.com/tx/#{txHash}',
  production: 'https://bscscan.com/tx/#{txHash}',
};

const blockchairBTCLink = {
  name: 'blockchair.com',
  testnet:
    'https://blockchair.com/bitcoin/testnet/transaction/#{txHash}?from=youhodler',
  production:
    'https://blockchair.com/bitcoin/transaction/#{txHash}?from=youhodler',
};

const tronscanLink = {
  name: 'tronscan.org',
  testnet: 'https://tronscan.org/#/transaction/#{txHash}',
  production: 'https://tronscan.org/#/transaction/#{txHash}',
};

const blockchairETHLink = {
  name: 'blockchair.com',
  testnet:
    'https://blockchair.com/ethereum/testnet/transaction/#{txHash}?from=youhodler',
  production:
    'https://blockchair.com/ethereum/transaction/#{txHash}?from=youhodler',
};

const polygonLink = {
  name: 'polygonscan.com',
  testnet: 'https://mumbai.polygonscan.com/tx/#{txHash}',
  production: 'https://polygonscan.com/tx/#{txHash}',
};

const solanaLink = {
  name: 'solana.com',
  testnet: 'https://explorer.solana.com/tx/#{txHash}?cluster=testnet',
  production: 'https://explorer.solana.com/tx/#{txHash}',
};

const tonviewerLink = {
  name: 'tonviewer.com',
  testnet: 'https://testnet.tonviewer.com/transaction/#{txHash}',
  production: 'https://tonviewer.com/transaction/#{txHash}',
};

export const explorerUrls: {
  [key: string]: ExplorerLink | undefined;
} = {
  btc: blockchairBTCLink,
  btc_bep20: bscscanLink,
  ltc: {
    name: 'blockchair.com',
    testnet:
      'https://blockchair.com/litecoin/testnet/transaction/#{txHash}?from=youhodler',
    production:
      'https://blockchair.com/litecoin/transaction/#{txHash}?from=youhodler',
  },
  bch: {
    name: 'blockchair.com',
    testnet:
      'https://blockchair.com/bitcoin-cash/testnet/transaction/#{txHash}?from=youhodler',
    production:
      'https://blockchair.com/bitcoin-cash/transaction/#{txHash}?from=youhodler',
  },
  eth: blockchairETHLink,
  eth_erc20: blockchairETHLink,
  eth_bep20: bscscanLink,
  qtum: {
    name: 'qtum.org',
    testnet: 'https://testnet.qtum.org/tx/#{txHash}',
    production: 'https://explorer.qtum.org/tx/#{txHash}',
  },
  xrp: {
    name: 'blockchair.com',
    testnet:
      'https://blockchair.com/ripple/testnet/transaction/#{txHash}?from=youhodler',
    production:
      'https://blockchair.com/ripple/transaction/#{txHash}?from=youhodler',
  },
  usdt_omni: blockchairBTCLink,
  usdt_erc20: blockchairETHLink,
  usdt_bep20: bscscanLink,
  usdt_trc20: tronscanLink,
  usdt_polygon: polygonLink,
  usdt_sol: solanaLink,
  xlm: {
    name: 'blockchair.com',
    testnet:
      'https://blockchair.com/stellar/testnet/transaction/#{txHash}?from=youhodler',
    production:
      'https://blockchair.com/stellar/transaction/#{txHash}?from=youhodler',
  },
  ethw: {
    name: 'oklink.com',
    testnet: 'https://www.oklink.com/en/ethw/tx/#{txHash}',
    production: 'https://www.oklink.com/en/ethw/tx/#{txHash}',
  },
  bsv: {
    name: 'whatsonchain.com',
    testnet: 'https://test.whatsonchain.com/tx/#{txHash}',
    production: 'https://whatsonchain.com/tx/#{txHash}',
  },
  dash: {
    name: 'blockchair.com',
    testnet:
      'https://blockchair.com/dash/testnet/transaction/#{txHash}?from=youhodler',
    production:
      'https://blockchair.com/dash/transaction/#{txHash}?from=youhodler',
  },
  rep: blockchairETHLink,
  eos: {
    name: 'eosauthority.com',
    testnet: 'https://eosiotest.eosauthority.com/transaction/#{txHash}',
    production: 'https://eosauthority.com/transaction/#{txHash}',
  },
  bat: blockchairETHLink,
  bat_bep20: bscscanLink,
  usdc: blockchairETHLink,
  usdc_bep20: bscscanLink,
  usdc_trc20: tronscanLink,
  usdc_polygon: polygonLink,
  usdc_sol: solanaLink,
  pax: blockchairETHLink,
  pax_bep20: bscscanLink,
  paxg: blockchairETHLink,
  paxg_bep20: bscscanLink,
  tusd: blockchairETHLink,
  tusd_trc20: tronscanLink,
  bnb: {
    name: 'binance.org',
    testnet: 'https://testnet-explorer.binance.org/tx/#{txHash}',
    production: 'https://explorer.binance.org/tx/#{txHash}',
  },
  bnb_bep20: bscscanLink,
  link: blockchairETHLink,
  link_bep20: bscscanLink,
  ht: blockchairETHLink,
  husd: blockchairETHLink,
  dai: blockchairETHLink,
  dai_bep20: bscscanLink,
  eurs: blockchairETHLink,
  uni: blockchairETHLink,
  uni_bep20: bscscanLink,
  mkr: blockchairETHLink,
  mkr_bep20: bscscanLink,
  comp: blockchairETHLink,
  comp_bep20: bscscanLink,
  omg: blockchairETHLink,
  zrx: blockchairETHLink,
  snx: blockchairETHLink,
  snx_bep20: bscscanLink,
  yfi: blockchairETHLink,
  yfi_bep20: bscscanLink,
  busd: blockchairETHLink,
  busd_trc20: tronscanLink,
  bnt: blockchairETHLink,
  sushi: blockchairETHLink,
  doge: bscscanLink,
  doge_bep20: bscscanLink,
  shib: blockchairETHLink,
  shib_bep20: bscscanLink,
  ada: bscscanLink,
  dot: {
    name: 'polkascan.io',
    testnet: 'https://polkascan.io/polkadot/extrinsic/#{txHash}',
    production: 'https://polkascan.io/polkadot/extrinsic/#{txHash}',
  },
  xtz: {
    name: 'tzstats.com',
    testnet: 'https://tzstats.com/#{txHash}',
    production: 'https://tzstats.com/#{txHash}',
  },
  trx: tronscanLink,
  trx_bep20: bscscanLink,
  aave: blockchairETHLink,
  aave_erc20: blockchairETHLink,
  usdp: blockchairETHLink,
  usdp_bep20: bscscanLink,
  matic: blockchairETHLink,
  matic_polygon: polygonLink,
  xmr: {
    name: 'blockchair.com',
    testnet:
      'https://blockchair.com/monero/testnet/transaction/#{txHash}?from=youhodler',
    production:
      'https://blockchair.com/monero/transaction/#{txHash}?from=youhodler',
  },
  sol: solanaLink,
  theta: {
    name: 'thetatoken.org',
    testnet: '',
    production: 'https://explorer.thetatoken.org/txs/#{txHash}',
  },
  icp: {
    name: 'dfinityexplorer.org',
    testnet: '',
    production: 'https://www.dfinityexplorer.org/#/tx/#{txHash}',
  },
  vet: bscscanLink,
  flr: {
    name: 'flare.network',
    testnet: 'https://flare-explorer.flare.network/tx/#{txHash}',
    production: 'https://flare-explorer.flare.network/tx/#{txHash}',
  },
  sgb: {
    name: 'flare.network',
    testnet: 'https://songbird-explorer.flare.network/tx/#{txHash}',
    production: 'https://songbird-explorer.flare.network/tx/#{txHash}',
  },
  atom: bscscanLink,
  avax: bscscanLink,
  btt: bscscanLink,
  bttc: bscscanLink,
  cake: bscscanLink,
  egld: bscscanLink,
  fil: bscscanLink,
  ftm: bscscanLink,
  ftm_erc20: blockchairETHLink,
  ftt: blockchairETHLink,
  luna: {
    name: 'finder.terra.money',
    testnet: 'https://finder.terra.money/testnet/tx/#{txHash}',
    production: 'https://finder.terra.money/mainnet/tx/#{txHash}',
  },
  near: bscscanLink,
  '1inch': bscscanLink,
  '1inch_erc20': blockchairETHLink,
  srm: blockchairETHLink,
  zil: bscscanLink,
  mana: blockchairETHLink,
  sand: bscscanLink,
  sand_erc20: blockchairETHLink,
  sand_polygon: polygonLink,
  axs: bscscanLink,
  axs_erc20: blockchairETHLink,
  ilv: blockchairETHLink,
  gala: blockchairETHLink,
  ape: blockchairETHLink,
  gmt: bscscanLink,
  gmt_sol: solanaLink,
  pepe: blockchairETHLink,
  pyusd: blockchairETHLink,
  floki: bscscanLink,
  arb: blockchairETHLink,
  ton: tonviewerLink,
  meme: blockchairETHLink,
  wif: solanaLink,
  jup: solanaLink,
  ena: blockchairETHLink,
  strk: blockchairETHLink,
  w: blockchairETHLink,
  w_sol: solanaLink,
  grt: blockchairETHLink,
  inj: bscscanLink,
  inj_erc20: blockchairETHLink,
  wld: blockchairETHLink,
  not: tonviewerLink,
  lista: bscscanLink,
  pendle_bep20: bscscanLink,
  pendle_erc20: blockchairETHLink,
  mnt: blockchairETHLink,
  zro_bep20: bscscanLink,
  zro_erc20: blockchairETHLink,
  zro_polygon: polygonLink,
  people_bep20: bscscanLink,
  people_erc20: blockchairETHLink,
  tremp: solanaLink,
  wen: solanaLink,
  bonk: bscscanLink,
  bonk_sol: solanaLink,
  myro: solanaLink,
  silly: solanaLink,
  dogs: tonviewerLink,
  render: solanaLink,
  beam: bscscanLink,
  beam_erc20: blockchairETHLink,
  ens: blockchairETHLink,
  flow: bscscanLink,
  gt: blockchairETHLink,
  hnt: solanaLink,
  imx: blockchairETHLink,
  jasmy: bscscanLink,
  jasmy_erc20: blockchairETHLink,
  ldo: blockchairETHLink,
  leo: blockchairETHLink,
  ondo: blockchairETHLink,
  pyth: solanaLink,
  qnt: blockchairETHLink,
  zec: bscscanLink,
  fet: bscscanLink,
  fet_erc20: blockchairETHLink,
  xaut: blockchairETHLink,
  banana: blockchairETHLink,
  pol: polygonLink,
  // NOTE: no urls for coins: 'gyen', 'nzds', 'xchf', 'apt', 'agix'
  // as this is a virtual coins, only for MultiHODLs deals, no need to show url for explorer in the history item
};
