import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CopyIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M8.5 4.5A2.5 2.5 0 0111 2h3.5A2.5 2.5 0 0117 4.5v6a2.5 2.5 0 01-2.5 2.5h-1a.75.75 0 010-1.5h1a1 1 0 001-1v-6a1 1 0 00-1-1H11a1 1 0 00-1 1V5a.75.75 0 01-1.5 0v-.5z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 9.5A2.5 2.5 0 015.5 7H9a2.5 2.5 0 012.5 2.5v6A2.5 2.5 0 019 18H5.5A2.5 2.5 0 013 15.5v-6zm2.5-1a1 1 0 00-1 1v6a1 1 0 001 1H9a1 1 0 001-1v-6a1 1 0 00-1-1H5.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
