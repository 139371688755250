import {HodlTariffResponseItem} from '@youtoken/ui.resource-hodl-tariffs';
import {uniqBy} from 'lodash';
import {HODlInstrumentItem} from './types';

export const getId = (item: HodlTariffResponseItem) =>
  `${item.baseTicker}/${item.quoteTicker}`;

export const formatInstrumentsItems = (
  rawItems: HodlTariffResponseItem[]
): HODlInstrumentItem[] => {
  return uniqBy(rawItems, getId).map(item => {
    return {
      value: `${item.baseTicker.toUpperCase()}/${item.quoteTicker.toUpperCase()}`,
      baseTicker: item.baseTicker,
      quoteTicker: item.quoteTicker,
      allowedInputTickers: item.allowedInputTickers,
    };
  });
};
