import {Theme} from '@youtoken/ui.primitives';
import styled from 'styled-components';

export const Triangle = styled.div<{colors: Theme['colors']}>`
  width: 14px;
  height: 11px;
  position: absolute;
  overflow: hidden;
  top: -11px;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    top: 8px;
    left: 0px;
    background-color: ${({colors}) => colors['$ui-background']};
  }
`;
