import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  ActiveHODLsPaginationFeature,
  ClosedHODLsPaginationAndFilterFeature,
  HODLsActiveBlock,
  HODLsClosedBlock,
  HODLsListFeature,
  HODLsPendingBlock,
} from '@youtoken/ui.surface-hodl-positions';
import {HODLsPortfolioWSFeature} from '@youtoken/ui.resource-hodl';
import {MultiHODLVideoPreview} from '@youtoken/ui.video-preview';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

export const HODLsPortfolioPageContent: React.FC = cell(() => {
  const {t} = useTranslation();

  const {queryParams} = RouterResource.use({});

  const {pageActive, pageClosed, filterClosed} = queryParams;

  const {
    isEmpty,
    shouldShowActiveHODLs,
    shouldShowClosedHODLs,
    shouldShowPendingHODLs,
  } = HODLsListFeature.use({});

  const {applyFilters, setPage: setPageClosed} =
    ClosedHODLsPaginationAndFilterFeature.use({});

  const {setPage: setPageActive} = ActiveHODLsPaginationFeature.use({});

  //NOTE: subscribe to WS updates
  const {subscribeToUpdates} = HODLsPortfolioWSFeature.use({});
  // NOTE: subscribeToUpdates contains return function
  React.useEffect(subscribeToUpdates, []);

  // NOTE: scroll to top mostly after redirecting on this page
  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  React.useEffect(() => {
    setPageActive(pageActive ? Number(pageActive) : 1);
  }, [pageActive]);

  React.useEffect(() => {
    let _filterClosed = filterClosed;
    if (typeof filterClosed === 'string') {
      _filterClosed = [filterClosed];
    }
    applyFilters({selectedTickers: _filterClosed ?? []});
    setPageClosed(pageClosed ? Number(pageClosed) : 1);
  }, [pageClosed, filterClosed]);

  const handleNavigate = React.useCallback(
    (newParams: {}) => {
      SHARED_ROUTER_SERVICE.navigate(
        'MultiHODLPortfolio',
        {},
        {
          pageActive,
          pageClosed,
          filterClosed,
          ...newParams,
        }
      );
    },
    [pageActive, pageClosed, filterClosed]
  );

  return (
    <>
      {isEmpty ? (
        <Box justifyContent="center" alignItems="center" width="100%">
          <Box mb={16}>
            <MultiHODLVideoPreview />
          </Box>

          <Box
            width={{
              default: '100%',
              phone: '100%',
              tablet: 480,
            }}
          >
            <Heading mb={16} textAlign="center" variant="$heading-01">
              {t('surface.hodls.portfolio.empty')}
            </Heading>
            <Text textAlign="center">
              {t('surface.hodls.portfolio.empty_description')}
            </Text>
          </Box>
        </Box>
      ) : (
        <>
          {shouldShowActiveHODLs && (
            <HODLsActiveBlock handleNavigate={handleNavigate} mt={20} />
          )}
          {/* todo: use gap prop when its support is merged */}
          {shouldShowPendingHODLs && (
            <HODLsPendingBlock mt={shouldShowActiveHODLs ? 26 : 0} />
          )}
          {shouldShowClosedHODLs && (
            <HODLsClosedBlock
              handleNavigate={handleNavigate}
              mt={shouldShowActiveHODLs || shouldShowPendingHODLs ? 26 : 0}
            />
          )}
        </>
      )}
    </>
  );
});
