import {parseTimePeriod} from './parseTimePeriod';
import {invariant} from '@youtoken/ui.utils';
import {add, differenceInMinutes, sub} from '@youtoken/ui.date-fns';

/** get start/end for given time period (1h, 24h, etc)
 * - if both dates given, just return `[startDate, endDate]`
 * - if start date given with period its `[startDate, startDate + 24h]`
 * - if end date given with period its `[endDate - 24h, endDate]`
 * - if just period given its `[now - 24h, now]`
 * - if non given, error is thrown;
 */
export const getStartEndDates = (
  timePeriod: string,
  fromDate?: Date,
  toDate?: Date
): [Date, Date] => {
  invariant(timePeriod, 'timePeriod is required in getStartEndDates()');

  if (fromDate && toDate) {
    // special case - min diff in hours may
    if (Math.abs(differenceInMinutes(toDate, fromDate)) < 60) {
      return [sub(toDate, {hours: 1}), toDate];
    }

    return [fromDate, toDate];
  }

  const {duration} = parseTimePeriod(timePeriod);

  if (fromDate) {
    return [fromDate, add(fromDate, duration)];
  }

  if (toDate) {
    return [sub(toDate, duration), toDate];
  }

  const now = new Date();

  return [sub(now, duration), now];
};
