import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  normalizeWithoutSpaces,
  normalizeWithoutMultiplySpaces,
} from '@youtoken/ui.normalizers';
import {type WithdrawalForm} from '../../../../state/WithdrawalForm';
import {FormComboboxField} from '../../../FormComboboxField';
import {FormInputField} from '../../../FormInputField';

export const BeneficiaryBankFields: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {countryItems},
  } = useForm<WithdrawalForm>();

  return (
    <Box mb={10}>
      <Heading variant="$heading-01" mb={10}>
        {t('surface.wallets.withdrawal_bank_wire_form.beneficiary_bank')}
      </Heading>

      <FormInputField
        name="bankName"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BANK_NAME"
        maxLength={255}
        normalizer={normalizeWithoutMultiplySpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.bank_name')}
          </Label>
        }
      />

      <FormInputField
        name="swiftCode"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_SWIFT_CODE"
        maxLength={15}
        normalizer={normalizeWithoutSpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.swift_code')}
          </Label>
        }
      />

      <FormComboboxField
        name="bankCountry"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BANK_COUNTRY"
        items={countryItems}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.bank_country')}
          </Label>
        }
      />

      <FormInputField
        name="bankCity"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BANK_CITY"
        maxLength={255}
        normalizer={normalizeWithoutMultiplySpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.bank_city')}
          </Label>
        }
      />

      <FormInputField
        name="bankStreetAddress"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BANK_STREET_ADDRESS"
        maxLength={255}
        normalizer={normalizeWithoutMultiplySpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.bank_street_address')}
          </Label>
        }
      />

      <FormInputField
        name="bankZipCode"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BANK_ZIP_CODE"
        maxLength={15}
        normalizer={normalizeWithoutSpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.bank_postal_code')}
          </Label>
        }
      />
    </Box>
  );
});
