import * as React from 'react';
import {observer} from 'mobx-react';
import {Clipboard} from '@youtoken/ui.utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Button} from '@youtoken/ui.buttons';

export const ShareRefCodeCopyReferralCodeButtonSmart: React.FC = observer(
  () => {
    const {t} = useTranslation();

    const {referralCode} = AuthMeResource.use({});

    const [copied, setCopied] = React.useState(false);

    const timeoutRef = React.useRef<NodeJS.Timeout | undefined>(undefined);

    const handleCopy = React.useCallback(() => {
      Clipboard.setString(referralCode);
      DATA_LAYER.trackStrict('reward-referral-code-copy', {});

      setCopied(true);

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }, [referralCode, setCopied]);

    return (
      <Button
        icon={copied ? 'check' : undefined}
        onPress={handleCopy}
        type="secondary"
        color={copied ? 'success' : 'interactive'}
      >
        {copied
          ? t('surface.referral_program.share_ref_code.copied_status')
          : t('surface.referral_program.share_ref_code.copy_action')}
      </Button>
    );
  }
);
