import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {coinNames} from '@youtoken/ui.coin-utils';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BankCardTransferForm} from '../state';

export const ToField: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {ticker, sumField, hasSumError, sumError, onSumChange},
    view: {tickerFormatted},
  } = useForm<BankCardTransferForm>();

  return (
    <Box {...boxProps}>
      <Box mb={8}>
        <Text variant="$body-01-high-accent" testID="TO_LABEL">
          {t('surface.bank_cards.transfer.to_your_wallet')}
        </Text>
      </Box>

      <TextInput
        value={sumField.get('value')}
        onChangeText={onSumChange}
        placeholder="0.00"
        keyboardType="numeric"
        LeftPartComponent={
          <Box
            height={'100%'}
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            pl={15}
          >
            <LogoColored name={ticker as LogoColoredIconName} size={20} />

            <Text ml={8} variant="$body-01-medium-accent">
              {coinNames[ticker]}
            </Text>
          </Box>
        }
        RightPartComponent={
          <Box
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            ml={3}
            pr={15}
          >
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        }
        textAlign="right"
        hasError={hasSumError}
        testID="AMOUNT_INPUT"
      />
      <FieldErrorMessage visible={hasSumError} placement="right">
        {getTranslatedValidationMessage(sumError)}
      </FieldErrorMessage>
    </Box>
  );
});
