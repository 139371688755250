import * as React from 'react';
import {observer} from 'mobx-react';
import {Clipboard} from '@youtoken/ui.utils';
import type {TFunction} from 'react-i18next';
import {Box, Text, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';

const handlePress = (accountId: string, t: TFunction) => {
  Clipboard.setString(accountId);
  LOCAL_NOTIFICATIONS.info({
    text: t('surface.profile.heading.user_id.message'),
  });
};

export const AccountNumber: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    data: {accountId},
  } = AuthMeResource.use({});

  return (
    <Box flexDirection="row" alignItems="stretch" {...boxProps}>
      <Box
        visible={{default: false, desktop: true}}
        width={1}
        backgroundColor="$ui-02"
        mr={24}
      />
      <TouchableBox
        flex={1}
        onPress={() => handlePress(accountId, t)}
        bg="$ui-background"
        py={{default: 0, desktop: 12}}
      >
        <Box mb={6}>
          <Text
            variant="$body-01"
            color="$text-02"
            fontSize={{default: 12, desktop: 14}}
          >
            {t('surface.profile.heading.user_id')}
          </Text>
        </Box>
        <Text
          variant="$body-01-medium-accent"
          color="$interactive-01"
          style={{display: 'flex', alignItems: 'center'}}
        >
          {accountId}&nbsp;
          <Box>
            <Icon name="copy" color="$interactive-01" size={16} />
          </Box>
        </Text>
      </TouchableBox>
    </Box>
  );
});
