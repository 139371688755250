import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Separator, Switch} from '@youtoken/ui.elements';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {Icon} from '@youtoken/ui.icons';
import {TriggerDistancePresetButtons} from '@youtoken/ui.hodl-elements';
import {ValidationMessageTouchable} from './ValidationMessageTouchable';

interface PendingOrderSectionProps extends BoxProps {}

export const PendingOrderSection: React.FC<PendingOrderSectionProps> = observer(
  boxProps => {
    const {
      form: {
        pendingOrderDisabled,
        isPending,
        changeIsPending,
        triggerPrice,
        triggerPricePercent,
        triggerPriceDistanceMax,
        setOfTriggerPricePercent,
        pendingOrderExpirationPeriod,
        updateTriggerPriceValue,
        updateTriggerPricePercent,
      },
    } = useForm<CreateHODLFormClass>();
    const {t} = useTranslation();

    if (pendingOrderDisabled) {
      return null;
    }

    return (
      <>
        <Separator />

        <Box {...boxProps}>
          <Box flexDirection="row">
            <Box
              flexDirection="row"
              alignItems="center"
              flexGrow={1}
              flexShrink={1}
              mr={20}
            >
              <Icon name="pending" />
              <Label ml={8} mr={3} mb={0}>
                {t('surface.hodls.hodl_form.pending_mode_label')}
              </Label>
              {Boolean(pendingOrderExpirationPeriod) && (
                <QuestionTooltip
                  content={t('surface.hodls.hodl_form.pending_mode_tooltip', {
                    count: pendingOrderExpirationPeriod,
                  })}
                  iconSize={16}
                />
              )}
            </Box>
            <Box>
              <Switch
                value={isPending}
                onValueChange={changeIsPending}
                testID="SET_PENDING_ORDER_SWITCHER"
              />
            </Box>
          </Box>
          {isPending && (
            <>
              <AmountAndPercentInput
                amountLabel={t('surface.hodls.hodl_form.trigger_price_label')}
                percentLabel={t('surface.hodls.adjust_tp.change')}
                amountValue={triggerPrice}
                percentValue={triggerPricePercent}
                onChangeAmount={updateTriggerPriceValue}
                onChangePercent={updateTriggerPricePercent}
                percentSignIsActive={true}
                validationComponent={<ValidationMessageTouchable />}
                pt={24}
                testIdSection="PENDING_ORDER"
              />
              <TriggerDistancePresetButtons
                currentTriggerPricePercent={triggerPricePercent}
                triggerPriceDistanceMax={triggerPriceDistanceMax}
                setOfTriggerPricePercent={setOfTriggerPricePercent}
                onChangeDistance={updateTriggerPricePercent}
              />
            </>
          )}
        </Box>
      </>
    );
  }
);
