import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {
  RewardsHowItWorksConditionsItem,
  RewardsHowItWorksConditionsItemProps,
} from './Item';

export const RewardsHowItWorksConditions: React.FC = observer(() => {
  const {t} = useTranslation();

  const items: RewardsHowItWorksConditionsItemProps[] = React.useMemo(() => {
    return [
      {
        icon: 'fiat_deposit',
        title: t(
          'surface.referral_program.rewards.how_it_works.conditions.deposit.title'
        ),
        description: t(
          'surface.referral_program.rewards.how_it_works.conditions.deposit.description'
        ),
      },
      {
        icon: 'hodl',
        title: t(
          'surface.referral_program.rewards.how_it_works.conditions.trade.title'
        ),
        description: t(
          'surface.referral_program.rewards.how_it_works.conditions.trade.description'
        ),
      },
    ];
  }, [t]);

  return (
    <Box>
      <Text variant="$body-02" mb={16}>
        {t('surface.referral_program.rewards.how_it_works.conditions')}
      </Text>
      <Box my={-8}>
        {items.map(({icon, title, description}, index) => {
          return (
            <RewardsHowItWorksConditionsItem
              key={index}
              icon={icon}
              title={title}
              description={description}
            />
          );
        })}
      </Box>
    </Box>
  );
});
