import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {FriendsActivityItem} from './FriendsActivityItem';
import {FriendsActivityResource} from '@youtoken/ui.resources-rewards';
import {ActivityIndicator} from '@youtoken/ui.elements';

export const FriendsActivityList = cell(
  () => {
    const {data} = FriendsActivityResource.use({});

    return (
      <>
        {data.map((item, index) => (
          <Box key={item.type} mt={index > 0 ? 16 : 0}>
            <FriendsActivityItem
              icon={item.icon}
              title={item.title}
              revenueTotal={item.revenueTotalUSD}
              revenueCount={item.users}
            />
          </Box>
        ))}
      </>
    );
  },
  {
    SuspenseFallback: () => (
      <Box height={64} alignItems="center" justifyContent="center">
        <ActivityIndicator />
      </Box>
    ),
  }
);
