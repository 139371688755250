import React from 'react';
import {observer} from 'mobx-react';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface WillExpireInProps {
  hodl: HODLItem;
}

export const WillExpireIn: React.FC<WillExpireInProps> = observer(
  ({hodl: {expirationPeriodFormatted}}) => {
    const {t} = useTranslation();

    return (
      <Box>
        <Box flexDirection="row" justifyContent="flex-end">
          <Text variant="$body-02" color="$text-02" mb={2}>
            {t('surface.hodls.item.will_expire_label')}
          </Text>
        </Box>
        <Box flexDirection="row" justifyContent="flex-end">
          <Text variant="$body-02" color="$text-01">
            {expirationPeriodFormatted}
          </Text>
        </Box>
      </Box>
    );
  }
);
