import * as React from 'react';
import {ScrollView} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

interface KeyboardScrollViewProps {
  wrapInSafeArea?: boolean;
  insideTabs?: boolean;
  safeAreaProps?: React.ComponentProps<typeof SafeAreaView>;
  scrollViewProps?: React.ComponentProps<typeof ScrollView>;
  TABS_HEIGHT?: number;
}

export const KeyboardScrollView: React.FC<KeyboardScrollViewProps> = ({
  children,
  scrollViewProps = {},
}) => {
  const {style, contentContainerStyle, ...props} = scrollViewProps;

  return (
    <ScrollView
      style={[{flexGrow: 1, overflow: 'visible'}, style]}
      contentContainerStyle={[
        {
          flexGrow: 1,
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 20,
          paddingRight: 20,
        },
        contentContainerStyle,
      ]}
      {...props}
    >
      {children}
    </ScrollView>
  );
};
