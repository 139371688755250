export type ValidationMessageInfo<
  P extends Record<string, unknown> = Record<string, unknown>
> = {
  label: string;
  params?: P;
  fallback?: string;
};

export class ValidationMessageLocalized {
  constructor(public fallback: string, public i18n?: ValidationMessageInfo) {}
}
