import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {type BaseIconName} from '@youtoken/ui.icons';
import {AccountLevelInfoItemTitle} from './Title';

export interface AccountLevelInfoItemFullProps {
  icon: BaseIconName;
  title: string;
  badge: React.ReactNode;
  description?: React.ReactNode;
  badgeInfo?: React.ReactNode;
}

export const AccountLevelInfoItemFull: React.FC<
  AccountLevelInfoItemFullProps & BoxProps
> = observer(({icon, title, description, badge, badgeInfo, ...boxProps}) => {
  const hasDescription = React.isValidElement(description);
  const hasBadgeInfo = React.isValidElement(badgeInfo);

  return (
    <Box p={16} {...boxProps}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <AccountLevelInfoItemTitle icon={icon}>
          {title}
        </AccountLevelInfoItemTitle>
        <Box justifyContent="center" alignItems="flex-end" ml={4}>
          {badge}
        </Box>
      </Box>
      {(hasDescription || hasBadgeInfo) && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          mt={4}
        >
          {hasDescription && (
            <Box flexGrow={1} flexShrink={1}>
              {description}
            </Box>
          )}
          {hasBadgeInfo && (
            <Box
              justifyContent="center"
              alignItems="flex-end"
              flexGrow={1}
              flexShrink={1}
              ml={4}
            >
              {badgeInfo}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});
