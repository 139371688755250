import * as React from 'react';
import {type BoxProps, Box, type Theme, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';
import {Badge} from '@youtoken/ui.elements';

export interface TickersStackProps {
  tickers: string[];
  maxTickers?: number;
  size?: number;
  borderSize?: number;
  variant?: 'leftOnTop' | 'rightOnTop';
  tickerBackgroundColor?: keyof Theme['colors'];
  tickerBorderColor?: keyof Theme['colors'];
  countBackgroundColor?: keyof Theme['colors'];
  additionalOffset?: number;
}

export const TickersStack: React.FC<TickersStackProps & BoxProps> = React.memo(
  ({
    tickers,
    maxTickers = 5,
    size = 20,
    borderSize = 1,
    tickerBackgroundColor = '$ui-background',
    tickerBorderColor = '$ui-background',
    variant = 'leftOnTop',
    additionalOffset = 0,
    testID,
    ...boxProps
  }) => {
    const halfSize = React.useMemo(() => {
      return size / 2 + borderSize;
    }, [size, borderSize]);

    const offset = React.useMemo(() => {
      return halfSize - additionalOffset;
    }, [halfSize, additionalOffset]);

    const shownTickers = React.useMemo(() => {
      return tickers.slice(0, maxTickers);
    }, [tickers, maxTickers]);

    const hiddenTickersCount = tickers.length - shownTickers.length;

    const renderItem = React.useCallback(
      (ticker: string, index: number) => {
        return (
          <Box
            testID={`${testID}_${ticker.toUpperCase()}`}
            key={ticker}
            ml={-offset}
            zIndex={variant === 'leftOnTop' ? tickers.length - index : index}
            borderWidth={borderSize}
            borderRadius={halfSize}
            backgroundColor={tickerBackgroundColor}
            borderColor={tickerBorderColor}
          >
            <LogoColored name={ticker as LogoColoredIconName} size={size} />
          </Box>
        );
      },
      [tickers.length, size, borderSize, halfSize, offset, variant]
    );

    return (
      <Box
        testID={testID}
        flexDirection="row"
        alignItems="center"
        {...boxProps}
      >
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          pl={offset}
          zIndex={-1}
        >
          {shownTickers.map(renderItem)}
        </Box>

        {Boolean(hiddenTickersCount) && variant === 'leftOnTop' && (
          <Badge
            testID={`${testID}_BADGE`}
            size="tiny"
            borderRadius={12}
            position="absolute"
            top={-5}
            right={-10}
          >
            {`+${hiddenTickersCount}`}
          </Badge>
        )}

        {Boolean(hiddenTickersCount) && variant === 'rightOnTop' && (
          <Box
            testID={`${testID}_BADGE`}
            ml={-offset}
            zIndex={tickers.length}
            borderWidth={borderSize}
            borderRadius={halfSize}
            borderColor={tickerBorderColor}
            backgroundColor="$interactive-02"
            width={2 * halfSize}
            height={2 * halfSize}
            justifyContent="center"
            alignItems="center"
          >
            <Text variant="$body-03" color="$text-05">
              {`+${hiddenTickersCount}`}
            </Text>
          </Box>
        )}
      </Box>
    );
  }
);
