import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {RatesListFeature} from '@youtoken/ui.legacy-components';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Toggler} from './Toggler';
import {ShownRates} from './ShownRates';

export const RatesSwitcher = cell<BoxProps>(props => {
  const {t} = useTranslation();

  const feature = RatesListFeature.use({});

  return (
    <Box width="100%" alignItems="stretch" {...props}>
      <ShownRates />
      <Box flexDirection="row" alignItems="center">
        <Toggler
          value={feature.showEmptyWallets}
          toggle={feature.toggleShowEmptyWallets}
          text={t(
            feature.showEmptyWallets
              ? 'surface.menu.rates_hide'
              : 'surface.menu.rates_show'
          )}
        />
      </Box>
    </Box>
  );
});
