import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface SavingsNotEarnedProps {
  tickerSymbol: string;
}

export const SavingsNotEarned: React.FC<SavingsNotEarnedProps> = observer(
  ({tickerSymbol}) => {
    const {t} = useTranslation();

    return (
      <Box testID="SAVINGS_NOT_EARNED">
        <Text variant="$heading-02" color="$success-01" fontWeight={500} mb={4}>
          {tickerSymbol}0.00
        </Text>
        <Text variant="$body-02" color="$text-02">
          {t('surface.savings.earned')}
        </Text>
      </Box>
    );
  }
);
