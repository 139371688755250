import {deserialize} from 'serializr';
import {computed} from 'mobx';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {CoindropResponse} from './CoindropResponse';

export enum CoindropsTariffs {
  ADDRESS_VERIFICATION = 'addressVerification',
  FIRST_DEPOSIT = 'firstDeposit',
  NONE = 'none',
}

interface CoindropResourceProps {
  shouldFetchWithSuppressErrors?: boolean;
}

export class CoindropResource extends createResource({
  getKey: () => `coindrop`,
  getData: ({shouldFetchWithSuppressErrors}: CoindropResourceProps) =>
    TRANSPORT.API.get('/v1/drops/overview')
      .then(r => {
        return deserialize(CoindropResponse, r.data);
      })
      .catch(error => {
        if (!shouldFetchWithSuppressErrors) {
          throw error;
        }

        return deserialize(CoindropResponse, {});
      }),
}) {
  @computed get coins() {
    return this.data.coins;
  }

  // NOTE: return string in format like 'BTC, ETH or USDT'
  @computed get coinsListStringify() {
    return this.coins.reduce((acc, coin, index) => {
      const coinNameFormatted = coin.toUpperCase();

      if (index === 0) return coinNameFormatted;
      if (index === this.coins.length - 1)
        return acc + ` or ${coinNameFormatted}`;

      return acc + `, ${coinNameFormatted}`;
    }, '');
  }

  @computed get tariff() {
    return this.data.tariff;
  }

  @computed get hasCoins() {
    return this.coins && this.coins.length > 0;
  }

  @computed get dropsAvailableByTariff() {
    return this.hasCoins && this.tariff !== CoindropsTariffs.NONE;
  }
}
