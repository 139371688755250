import * as React from 'react';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {DeviceData} from '@youtoken/ui.state-security';
import {DeviceImage} from '@youtoken/ui.elements';
import {observer} from 'mobx-react';

export interface DeviceColumnProps {
  device?: DeviceData;
  deviceInfo?: DeviceData;
}

export const DeviceColumn: React.FC<DeviceColumnProps> = observer(
  ({device, deviceInfo}) => {
    const displayDeviceIcon =
      device?.displayDeviceIcon || deviceInfo?.displayDeviceIcon;
    const displayName = device?.displayName || deviceInfo?.displayName;
    const displayDeviceLogo =
      device?.displayDeviceLogo || deviceInfo?.displayDeviceLogo;
    const deviceType = device?.deviceType || deviceInfo?.deviceType;
    const browserName = device?.browserName || deviceInfo?.browserName;
    const browserOs = device?.browserOs || deviceInfo?.browserOs;
    const displayDeviceModel =
      device?.displayDeviceModel || deviceInfo?.displayDeviceModel;

    const isMobile = useIsMobile();

    return (
      <>
        <Box alignSelf="center" mr={8} width={32} height={32}>
          {displayDeviceIcon !== undefined &&
            displayDeviceLogo !== undefined && (
              <DeviceImage
                deviceIcon={displayDeviceIcon}
                deviceLogo={displayDeviceLogo}
                size="small"
              />
            )}
        </Box>

        <Box>
          {deviceType === 'web' && (
            <>
              <Text
                variant="$body-02"
                color="$text-01"
                mb={2}
                numberOfLines={1}
                ellipsizeMode="tail"
                style={
                  isMobile && {
                    maxWidth: 88,
                  }
                }
                testID="SECURITY_ACTIVITY_BROWSER"
              >
                {browserName}
              </Text>

              <Text
                variant="$body-02"
                color="$text-02"
                numberOfLines={1}
                ellipsizeMode="tail"
                style={
                  isMobile && {
                    maxWidth: 88,
                  }
                }
                testID="SECURITY_ACTIVITY_OS"
              >
                {browserOs}
              </Text>
            </>
          )}

          {deviceType !== 'web' && (
            <>
              <Text
                variant="$body-02"
                color="$text-01"
                mb={2}
                numberOfLines={1}
                ellipsizeMode="tail"
                style={
                  isMobile && {
                    maxWidth: 88,
                  }
                }
                testID="SECURITY_ACTIVITY_DEVICE"
              >
                {displayName}
              </Text>

              <Text
                variant="$body-02"
                color="$text-02"
                numberOfLines={1}
                ellipsizeMode="tail"
                style={
                  isMobile && {
                    maxWidth: 88,
                  }
                }
                testID="SECURITY_ACTIVITY_MODEL"
              >
                {displayDeviceModel}
              </Text>
            </>
          )}
        </Box>
      </>
    );
  }
);
