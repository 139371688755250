import * as React from 'react';
import {Platform, Animated, ViewProps} from 'react-native';
import {
  G,
  GProps,
  Circle,
  CircleProps,
  Line,
  LineProps,
  Rect,
  RectProps,
} from 'react-native-svg';
// import _Animated from 'react-native-reanimated';

// export const _AnimatedGroup = Platform.select({
//   // web Animated.createAnimatedComponent should be classes
//   web: () => {
//     const {
//       default: applyNativeMethods,
//     } = require('react-native-web/src/modules/applyNativeMethods');

//     return _Animated.createAnimatedComponent(
//       applyNativeMethods(
//         class AnimatedGroup extends React.Component<SVG.GProps & ViewProps> {
//           render() {
//             return <SVG.G {...this.props} />;
//           }
//         }
//       )
//     );
//   },
//   default: () =>
//     _Animated.createAnimatedComponent(SVG.G) as Animated.AnimatedComponent<
//       React.Component<SVG.GProps & ViewProps>
//     >,
// })()!;

// @ts-ignore
export const AnimatedGroup = Platform.select({
  // web Animated.createAnimatedComponent should be classes
  web: () =>
    Animated.createAnimatedComponent(
      class AnimatedGroup extends React.PureComponent<GProps & ViewProps> {
        render() {
          const {collapsable, ...props} = this.props;
          return <G {...props} />;
        }
      }
    ),
  default: () =>
    Animated.createAnimatedComponent(G) as Animated.AnimatedComponent<
      // @ts-ignore
      React.Component<GProps & ViewProps>
    >,
})()!;

// @ts-ignore
export const AnimatedCircle = Platform.select({
  web: () =>
    Animated.createAnimatedComponent(
      class AnimatedCircle extends React.PureComponent<
        CircleProps & ViewProps
      > {
        render() {
          const {collapsable, ...props} = this.props;
          return <Circle {...props} />;
        }
      }
    ),
  default: () =>
    Animated.createAnimatedComponent(Circle) as Animated.AnimatedComponent<
      // @ts-ignore
      React.Component<CircleProps & ViewProps>
    >,
})()!;

// @ts-ignore
export const AnimatedLine = Platform.select({
  web: () =>
    Animated.createAnimatedComponent(
      class AnimatedLine extends React.PureComponent<LineProps & ViewProps> {
        render() {
          const {collapsable, ...props} = this.props;
          return <Line {...props} />;
        }
      }
    ),
  default: () =>
    Animated.createAnimatedComponent(Line) as Animated.AnimatedComponent<
      // @ts-ignore
      React.Component<LineProps & ViewProps>
    >,
})()!;

// @ts-ignore
export const AnimatedRect = Platform.select({
  web: () =>
    Animated.createAnimatedComponent(
      class AnimatedRect extends React.PureComponent<RectProps & ViewProps> {
        render() {
          const {collapsable, ...props} = this.props;
          return <Rect {...props} />;
        }
      }
    ),
  default: () =>
    Animated.createAnimatedComponent(Rect) as Animated.AnimatedComponent<
      // @ts-ignore
      React.Component<RectProps & ViewProps>
    >,
})()!;
