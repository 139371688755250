import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import React from 'react';
import {WalletsItem} from '@youtoken/ui.surface-wallets-item';
import {useParams} from 'react-router-dom';

export const WalletsDetails: React.FC = () => {
  const {ticker} = useParams<{ticker: string}>();

  return (
    <InnerPageShell>
      <WalletsItem ticker={ticker} />
    </InnerPageShell>
  );
};
