import {observable, computed} from 'mobx';
import {now as mobxUtilsNow} from 'mobx-utils/lib/now';
import {add, format, formatDuration} from '@youtoken/ui.date-fns';
import {formatPercentTillPrecision, toBig} from '@youtoken/ui.formatting-utils';
import {
  getCoinDecimalPrecision,
  getCoinDecimalPrecisionForLoans,
} from '@youtoken/ui.coin-utils';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {TariffsLoansRegularResource} from '@youtoken/ui.resource-loans';
import {
  getMinInterestAmount,
  getInterestAmount,
  formatInterestAmount,
  getMaxDurationLabel,
  getMaxDurationValue,
} from '../../utils';
import {Form} from './Form';

export interface FeatureLoanCreateLandingFormArgs {
  url?: string;
  ticker?: string;
  conversionTicker?: string;
  amount?: string | number;
}

export interface FeatureLoanCreateLandingFormResources {
  ratesResource: RatesResource;
  tariffsResource: TariffsLoansRegularResource;
}

export class FeatureLoanCreateLandingForm extends createFeature({
  getKey: (_args: FeatureLoanCreateLandingFormArgs) =>
    `LoanRegularCreateLandingForm`,
  getResources: (_args: FeatureLoanCreateLandingFormArgs) => {
    return {
      ratesResource: getResourceDescriptor(RatesResource, {}),
      tariffsResource: getResourceDescriptor(TariffsLoansRegularResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  @computed
  public get submitting() {
    return this.form.instance.submitting;
  }

  @computed
  public get ticker() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  public get conversionTicker() {
    return this.form.conversionTicker.toUpperCase();
  }

  @computed
  public get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.form.conversionTicker);
  }

  @computed
  public get conversionAmount() {
    return toBig(this.form.conversionAmount)
      .round(getCoinDecimalPrecision(this.form.conversionTicker))
      .toString();
  }

  @computed
  public get pdlPercent() {
    return formatPercentTillPrecision(this._term?.marginCall, 4);
  }

  @computed
  public get pdlPrice() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.rate)
        .mul(toBig(1).plus(toBig(this._term?.marginCall)))
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toString()
    );
  }

  @computed
  public get apr() {
    return formatPercentTillPrecision(this._term?.apr, 4);
  }

  @computed
  public get penaltyAPR() {
    return formatPercentTillPrecision(this._term?.penaltyAPR, 4);
  }

  @computed
  public get settlementPeriod() {
    return this._term?.settlementPeriodFormatted;
  }

  @computed
  public get interest() {
    return formatPercentTillPrecision(this._term?.aprBySettlementPeriod, 4);
  }

  @computed
  public get interestMultiplier() {
    return formatPercentTillPrecision(
      this._term?.penaltyAprBySettlementPeriod,
      4
    );
  }

  @computed
  public get interestAmountMin() {
    return (
      this._term?.aprBySettlementPeriod &&
      getMinInterestAmount(
        this._term.aprBySettlementPeriod,
        this.conversionTickerPrecision
      )
    );
  }

  @computed
  public get interestAmount() {
    return (
      this._term?.aprBySettlementPeriod &&
      this.interestAmountMin &&
      getInterestAmount(
        this._term.aprBySettlementPeriod,
        toBig(this.form.conversionAmount),
        this.interestAmountMin
      )
    );
  }

  @computed
  public get interestAmountFormatted() {
    return (
      this.interestAmount &&
      formatInterestAmount(this.interestAmount, this.conversionTickerPrecision)
    );
  }

  @computed
  public get maxDurationFormatted() {
    return this._term && formatDuration(this._term?.maxDuration);
  }

  @computed
  public get maxDurationValue() {
    return (
      this.maxDurationFormatted &&
      getMaxDurationValue(this.maxDurationFormatted)
    );
  }

  @computed
  public get maxDurationLabel() {
    return (
      this.maxDurationFormatted &&
      getMaxDurationLabel(this.maxDurationFormatted)
    );
  }

  @computed
  public get repayUntil() {
    return (
      this._term &&
      format(add(mobxUtilsNow(), this._term.maxDuration), 'MMM dd, yyyy, HH:mm')
    );
  }

  @computed.struct
  private get _term() {
    return this.form.term;
  }

  constructor(
    args: FeatureLoanCreateLandingFormArgs,
    resources: FeatureLoanCreateLandingFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
  }
}
