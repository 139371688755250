import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

/** __Sign out button__
 *
 */
export const SignOutButton: React.FC = () => {
  const {t} = useTranslation();
  const {signOut} = AuthMeResource.use({});

  return (
    <Box visible={{default: false, desktop: true}} ml={8}>
      <Button type="secondary" onPress={signOut} size="small">
        {t('surface.menu.sign_out')}
      </Button>
    </Box>
  );
};
