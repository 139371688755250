import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {WithdrawalMethods} from '@youtoken/ui.surfaces-wallets';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HeaderBase} from '../../../components';
import {
  type FiatWithdrawalWizardNavigator,
  FiatWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const FiatWithdrawalChooseMethodBase: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {ticker},
    },
    navigate,
    goBack,
  } = useWizard<
    FiatWithdrawalWizardNavigator,
    FiatWithdrawalWizardScreenName.CHOOSE_METHOD
  >();

  const handleChangeMethod = React.useCallback(
    (method: FiatWithdrawalWizardScreenName) => navigate(method, {ticker}),
    [navigate]
  );

  return (
    <>
      {showHeader && (
        <>
          <HeaderBase onPressBack={goBack}>
            {t('surface.wallets.fiat_withdrawal.title', {
              ticker: ticker.toUpperCase(),
            })}
          </HeaderBase>
          <Separator />
        </>
      )}
      <WithdrawalMethods ticker={ticker} onChange={handleChangeMethod} />
    </>
  );
});
