import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box} from '@youtoken/ui.primitives';
import {IconSmall} from '@youtoken/ui.icons';

export interface LoanItemAlertClientMCProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemAlertClientMC: React.FC<LoanItemAlertClientMCProps> =
  observer(({item}) => {
    if (!item.isClientMC) {
      return null;
    }

    return (
      <Box
        testID="LOAN_ITEM_ALERT_CLIENT_MC"
        position="absolute"
        top={8}
        right={8}
      >
        <IconSmall
          width={5}
          height={5}
          name="notification"
          color="$danger-01"
        />
      </Box>
    );
  });
