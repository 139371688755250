import * as React from 'react';
import {TFuncKey} from 'react-i18next';
import {observer} from 'mobx-react';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text, TouchableText} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {ExtendTpSlForm} from '../../form';

export const ExtendTpSlValidationMessage = observer(() => {
  const {
    form: {inputAmountError, setInputAmount},
  } = useForm<ExtendTpSlForm>();
  const {t} = useTranslation();

  return (
    <Text
      color={Boolean(inputAmountError) ? '$danger-01' : '$text-02'}
      variant="$body-02"
      mt={8}
    >
      {inputAmountError.i18n?.label && inputAmountError.i18n?.params?.value ? (
        inputAmountError.i18n?.label === 'SHOULD_BE_LTE' ||
        inputAmountError.i18n?.label === 'SHOULD_BE_GTE' ? (
          <Trans
            t={t}
            i18nKey={
              `surface.hodls.validation.${inputAmountError.i18n.label.toLowerCase()}` as TFuncKey
            }
            components={{
              Touchable: (
                <TouchableText
                  color="$text-05"
                  onPress={() =>
                    setInputAmount(
                      inputAmountError.i18n!.params!.value as string
                    )
                  }
                />
              ),
            }}
            values={{amount: inputAmountError.i18n.params.value}}
            defaults="Must be less than or equal to <Touchable>{{amount}}</Touchable>"
          />
        ) : (
          <Trans
            t={t}
            i18nKey={`validation.${inputAmountError.i18n.label}` as TFuncKey}
            values={{
              value: inputAmountError.i18n.params.value,
            }}
          />
        )
      ) : (
        getTranslatedValidationMessage(inputAmountError)
      )}
    </Text>
  );
});
