import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';

export const RewardsHistoryItemIndicator: React.FC = () => {
  return (
    <Box
      position="absolute"
      top={10}
      right={10}
      width={6}
      height={6}
      borderRadius={3}
      bg="$success-01"
    />
  );
};
