import * as React from 'react';
import {Button} from '@youtoken/ui.buttons';
import {Box, Text} from '@youtoken/ui.primitives';

interface PaginationItemProps {
  item: number | string;
  isActive: boolean;
  onPress?: (pageNumber: number) => void;
}

export const PaginationItem: React.FC<PaginationItemProps> = React.memo(
  ({item, isActive, onPress}) => {
    const isPage = typeof item === 'number';
    const handlePress = React.useCallback(() => {
      onPress?.(item as number);
    }, [onPress, item]);

    if (isPage) {
      return (
        <Button
          size="small"
          type="secondary"
          onPress={handlePress}
          disabled={isActive}
          width={32}
          px={0}
          mx={4}
        >
          {item}
        </Button>
      );
    }

    return (
      <Box width={20} alignItems="center" justifyContent="center">
        <Text variant="$body-02-medium-accent" color="$text-05">
          {item}
        </Text>
      </Box>
    );
  }
);
