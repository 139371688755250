import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {RewardWidgetResponse} from './RewardWidgetResponse';
import {localClientSettings} from '@youtoken/ui.resource-auth-me';

export class RewardWidgetResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => `RewardWidgetResource`,
  getData: () => {
    return TRANSPORT.API.get(`/v3/rewards/widget`, {
      params: {
        referralViewedDateTime: localClientSettings?.referralViewedDateTime,
        rewardViewedDateTime: localClientSettings?.rewardViewedDateTime,
      },
    }).then(res => {
      return deserialize(RewardWidgetResponse, res.data);
    });
  },
}) {}
