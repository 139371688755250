import * as React from 'react';
import {TFuncKey} from 'react-i18next';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {RouterResource} from '../../../../routes/RouterResource';
import {MenuItem} from '@web-app/src/components/elements/MenuItem';
import {Dropdown} from '../../../../elements';
import {MenuButton} from '../MenuButton';

const menuItems: {
  icon: BaseIconName;
  to: string;
  title: TFuncKey;
  value: 'loans' | 'trading' | 'exchange' | 'miner';
  testID: string;
}[] = [
  {
    icon: 'hodl',
    to: 'trading',
    title: 'surface.welcome.ledger_menu_trading',
    value: 'trading',
    testID: 'TRADING_LINK',
  },
  {
    icon: 'get_loan',
    to: 'loans',
    title: 'surface.welcome.ledger_menu_loans',
    value: 'loans',
    testID: 'LOAN_LINK',
  },
  {
    icon: 'convert',
    to: 'exchange',
    title: 'surface.welcome.ledger_menu_exchange',
    value: 'exchange',
    testID: 'EXCHANGE_LINK',
  },
  {
    icon: 'mining',
    to: 'miner',
    title: 'surface.menu.miner',
    value: 'miner',
    testID: 'MINER_LINK',
  },
];

export const Menu: React.FC = () => {
  const isMobile = useIsMobile();
  const {pathname, push} = RouterResource.use({});
  const {t} = useTranslation();

  const activeTab = React.useMemo(() => {
    return menuItems.find(item => pathname.includes(item.to))!;
  }, [pathname]);

  if (isMobile) {
    return (
      <Dropdown
        renderTrigger={({toggle, isOpen}) => (
          <Button
            icon={activeTab.icon}
            onPress={toggle}
            type={isOpen ? 'primary' : 'secondary'}
            size="small"
          >
            <Box flexDirection="row" alignItems="center">
              <Text
                variant="$body-02-medium-accent"
                color={isOpen ? '$text-04' : '$interactive-01'}
                mr={8}
              >
                {t(activeTab.title)}
              </Text>

              {isOpen ? (
                <Icon name="collapse" color="$text-04" />
              ) : (
                <Icon name="expand" color="$text-05" />
              )}
            </Box>
          </Button>
        )}
        renderItems={({toggle}) => (
          <>
            {menuItems.map(item => {
              return (
                <MenuItem
                  icon={item.icon}
                  onPress={toggle}
                  testID={item.testID}
                  to={item.to}
                  text={t(item.title)}
                />
              );
            })}
          </>
        )}
      />
    );
  }

  return (
    <Box flexDirection="row" alignItems="center" flexShrink={1}>
      {menuItems.map((item, index) => {
        const marginRight = index === menuItems.length - 1 ? 0 : 8;
        const isActive = pathname.includes(item.to);

        return (
          <MenuButton
            type={isActive ? 'secondary' : 'ghost'}
            testID={item.testID}
            onPress={() => {
              push(item.to);
            }}
            icon={item.icon}
            mr={marginRight}
          >
            {t(item.title)}
          </MenuButton>
        );
      })}
    </Box>
  );
};
