import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {SavingsChooseTickersResponse} from './SavingsChooseTickersResponse';

export class SavingsChooseTickersResource extends createResource({
  getKey: () => `SavingsChooseTickers`,
  getData: () =>
    TRANSPORT.API.get('/v4/saving/choose-tickers').then(response => {
      return deserialize(SavingsChooseTickersResponse, response.data);
    }),
  skipRefreshOnVisible: false,
}) {
  @computed
  get top() {
    return this.data.top;
  }

  @computed
  get other() {
    return this.data.other;
  }

  @computed
  get all() {
    return [...this.top, ...this.other];
  }

  @computed
  get selected() {
    return this.all.filter(({selected}) => selected);
  }

  getAssetByTicker = computedFn(ticker => {
    return this.all.find(asset => {
      return asset.ticker === ticker;
    });
  });
}
