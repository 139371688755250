import {action, computed} from 'mobx';
import Big from 'big.js';
import {
  HodlsTariffsResource,
  HodlTariffResponseItem,
  TradingMode,
} from '@youtoken/ui.resource-hodl-tariffs';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {invariant} from '@youtoken/ui.utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  type DirectionType,
  getHodlDetailsData,
  getHodlLoansSequence,
  type IAbstractHowHODLWorks,
} from '@youtoken/ui.hodls-utils';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';

interface HODLByIdFeatureArgs {
  id: string;
}

export class HODLByIdFeature extends createFeature({
  getKey: (args: HODLByIdFeatureArgs) => `feature:hodlById:${args.id}}`,
  getResources: ({id}: HODLByIdFeatureArgs) => {
    return {
      hodl: getResourceDescriptor(HODLByIdResource, {id}),
      tariffsResource: getResourceDescriptor(HodlsTariffsResource, {}),
      authme: getResourceDescriptor(AuthMeResource, {}),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
      wallets: getResourceDescriptor(WalletsResource, {}),
    };
  },
}) {
  @action subscribeToUpdates = () => {
    return this.resources.hodl.subscribeToUpdates();
  };

  @computed.struct get hodl() {
    return this.resources.hodl.data;
  }

  @computed get inputTicker() {
    return this.hodl.inputTicker;
  }

  @computed get baseTicker() {
    return this.hodl.baseTicker;
  }

  @computed get quoteTicker() {
    return this.hodl.quoteTicker;
  }

  @computed get outputTicker() {
    return this.hodl.data.outputTicker;
  }

  @computed get isShort() {
    return this.hodl.data.isShort;
  }

  @computed get hodlPageTitle() {
    if (this.hodl.isPending) {
      return i18n.t('surface.hodls.item.title_pending');
    }

    if (this.hodl.isClosedLike) {
      return i18n.t('surface.hodls.item.title_closed');
    }

    return i18n.t('surface.hodls.item.title_active');
  }

  @computed get multiplier() {
    return this.hodl.multiplier?.toString();
  }

  @computed get repeatTradeParams() {
    const bonusBalance = this.resources.wallets.bonusesWallet?.amount || Big(0);

    const additionalInputAmount = toBig(
      bonusBalance.gte(toBig(this.hodl.additionalInputAmount))
        ? this.hodl.additionalInputAmount
        : bonusBalance
    );

    return {
      baseTicker: this.baseTicker,
      quoteTicker: this.quoteTicker,
      direction: (this.isShort ? 'sell' : 'buy') as DirectionType,
      multiplier: this.multiplier,
      inputTicker: this.inputTicker,
      inputAmount: this.hodl.mainInputAmount || this.hodl.inputAmount,
      additionalInputAmount: this.hodl.additionalInputAmount
        ? formatByTicker(additionalInputAmount, this.hodl.additionalInputTicker)
        : '0',
    };
  }

  @computed get isExtendTpSlAvailableByVersion() {
    return this.resources.hodl.data.version === 3;
  }

  @computed get isExtendTpSlAvailable() {
    const isAvailableByTariffs =
      this.resources.tariffsResource.isTariffsExtendMHAvailable(
        this.baseTicker,
        this.quoteTicker
      );

    return isAvailableByTariffs && this.isExtendTpSlAvailableByVersion;
  }

  @computed get showAgreementBtn() {
    const {isOpen, isPendingLike, isClosing, isClosed} = this.hodl;

    return isOpen || isPendingLike || isClosing || isClosed;
  }

  @computed get showDetailsButton() {
    const {isOpen, isPendingLike, isClosed} = this.hodl;

    return isOpen || isPendingLike || isClosed;
  }

  @computed.struct get loansSequence() {
    if (this.hodl.version > 2) {
      return [];
    }

    const loan = this.hodl.data.loan;

    invariant(
      loan,
      'cannot get loan data from hodl',
      {},
      {hodl: this.hodl, loan: this.hodl.data.loan}
    );

    const {loans, borrowedTicker, collateralTicker, ltv} = loan;
    return getHodlLoansSequence(borrowedTicker, collateralTicker, ltv, loans);
  }

  @computed get detailsData(): IAbstractHowHODLWorks | undefined {
    if (this.hodl.version > 2) {
      return undefined;
    }

    return getHodlDetailsData(
      this.loansSequence,
      this.hodl._loanTotalCollateral,
      this.hodl._loanTotalRepayment,
      this.hodl
    );
  }

  @computed get isFlipDirectionAvailable() {
    const currentTariff = this.resources.tariffsResource.data.find(
      (item: HodlTariffResponseItem) => {
        return (
          item.isShort !== this.isShort &&
          item.baseTicker === this.baseTicker &&
          item.quoteTicker === this.quoteTicker
        );
      }
    );

    return currentTariff?.tradingMode === TradingMode.ENABLED;
  }

  @computed get incentivesUsed() {
    return this.hodl.additionalInputAmount;
  }

  @computed get showTotalInBaseTicker() {
    return (
      Boolean(this.hodl.baseTickerOutputAmount) &&
      this.hodl.baseTickerOutputAmount! > 0
    );
  }

  @computed get closeNowAmountFormatted() {
    return this.showTotalInBaseTicker
      ? this.hodl.baseTickerOutputAmountFormatted
      : this.hodl.additionalOutputAmountFormatted;
  }

  @computed get closeNowTickerFormatted() {
    return this.showTotalInBaseTicker
      ? this.hodl.outputTickerUpperCase
      : this.hodl.additionalInputTickerUI;
  }
}
