import {computedFn} from 'mobx-utils';
import {orderBy} from 'lodash';
import {computed} from 'mobx';
import {hasCoinOrderIndex, getCoinOrderIndex} from '@youtoken/ui.coin-utils';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {warning} from '@youtoken/ui.utils';

export class MarketsResource extends createResource({
  getKey: () => 'markets',
  getData: () =>
    TRANSPORT.API.get(`/v1/exchange/markets`).then(res => {
      return res.data;
    }),
}) {
  @computed
  public get markets() {
    return orderBy(Object.keys(this.data).filter(hasCoinOrderIndex), [
      getCoinOrderIndex,
    ]).reduce((result, ticker) => {
      const tickers = orderBy(
        Object.keys(this.data[ticker]).filter(hasCoinOrderIndex),
        [getCoinOrderIndex]
      );

      if (tickers.length) {
        result[ticker] = tickers;
      }

      warning(
        tickers.length,
        `MarketsResource: Market by ticker has empty tickers`,
        {
          ticker,
        },
        {}
      );

      return result;
    }, {} as {[key: string]: string[]});
  }

  /**
   * get tickers by market with whiteList in default order
   * const marketsTickers = marketsResource.getTickersByMarketWithWhiteList('btc', walletsResource.marketEnabledTickers) - get tickers by market only with marketEnabled
   */
  getTickersByMarketWithWhiteList = computedFn(
    (ticker: string, whiteList: string[]) => {
      const tickers = (this.markets[ticker] || []).filter(ticker => {
        return whiteList.includes(ticker);
      });

      warning(
        tickers.length,
        `MarketsResource: Market by ticker with white list has empty tickers`,
        {
          ticker,
        },
        {
          whiteList,
        }
      );

      return tickers;
    }
  );
}
