export enum BankCardTransactionType {
  DEPOSIT = 'deposit',
  HOLD = 'hold',
  WITHDRAWAL = 'withdrawal',
  SPENT = 'spent',
  REFUND = 'refund',
}

export enum BankCardTransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
}

export const typeKeys: Record<BankCardTransactionType, string> = {
  [BankCardTransactionType.DEPOSIT]: 'surface.history.item.type_name.deposit',
  [BankCardTransactionType.WITHDRAWAL]:
    'surface.history.item.type_name.withdrawal',
  [BankCardTransactionType.HOLD]: 'surface.history.item.type_name.hold',
  [BankCardTransactionType.SPENT]: 'surface.history.item.type_name.spent',
  [BankCardTransactionType.REFUND]: 'surface.history.item.type_name.refund',
};

export const statusKeys: Record<BankCardTransactionStatus, string> = {
  SUCCESS: 'surface.history.item.status_label.success',
  FAILED: 'surface.history.item.status_label.failed',
  PROCESSING: 'surface.history.item.status_label.processing',
};
