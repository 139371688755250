import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';

export const InnerPageShellContentForceIdentify: React.FC = ({children}) => {
  return (
    <Box width="100%" mt={20} mb={80}>
      {children}
    </Box>
  );
};
