import * as React from 'react';
import {AccountLevelsSurfaceCell} from '@youtoken/ui.surface-account-levels';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export const AccountLevelsPage: React.FC = () => {
  return (
    <InnerPageShell>
      <AccountLevelsSurfaceCell />
    </InnerPageShell>
  );
};
