import * as React from 'react';
import {CheckboxField, useForm} from '../../../../components';
import {FeatureTurboCreateForm} from '../stores';
import {observer} from 'mobx-react';
import {AgreementsList} from '@youtoken/ui.elements';

export const AgreeToTermsField: React.FC = observer(() => {
  const {
    form: {turboAgreements},
  } = useForm<FeatureTurboCreateForm>();

  return (
    <CheckboxField name="agreeToTerms">
      <AgreementsList items={turboAgreements} />
    </CheckboxField>
  );
});
