import {action, observable} from 'mobx';
import {createFeature} from '@youtoken/ui.data-storage';
import {Form} from '../index';

export interface FeatureEmailVerificationOnSignUpArgs {}

export interface FeatureEmailVerificationOnSignUpResources {}

export class FeatureEmailVerificationOnSignUp extends createFeature({
  getKey: ({}: FeatureEmailVerificationOnSignUpArgs) =>
    `EmailVerificationOnSignUp`,
  getResources: ({}: FeatureEmailVerificationOnSignUpResources) => {
    return {};
  },
}) {
  @observable
  public form: Form;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(
    args: FeatureEmailVerificationOnSignUpArgs,
    resources: FeatureEmailVerificationOnSignUpResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
  }
}
