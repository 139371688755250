import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';

export const SavingsProgressWithLevelsMark: React.FC<BoxProps> = boxProps => {
  return (
    <Box
      width={1}
      height={6}
      my={2}
      bg="$ui-background"
      borderRadius={0.5}
      {...boxProps}
    />
  );
};
