import {action, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {AllDisclosuresResource} from '@youtoken/ui.resource-all-disclosures';
import {Form} from './Form';
import {FeatureFormAArgs, FeatureFormAResources} from './types';

export class FeatureFormA extends createFeature({
  getKey: ({}: FeatureFormAArgs) => `FormA`,
  getResources: ({}: FeatureFormAArgs) => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      allDisclosures: getResourceDescriptor(AllDisclosuresResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  @action
  submit = () => {
    return this.form.instance.onSubmit({preventDefault: () => {}});
  };

  constructor(args: FeatureFormAArgs, resources: FeatureFormAResources) {
    super(args, resources);
    this.form = new Form(args, resources);
  }
}
