import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {stringify} from 'query-string';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LoansItem, LoanStatus} from './LoansItem';
import {type LoansActiveOrClosedResourceArgs} from './types';

export interface LoansActiveResourceArgs
  extends LoansActiveOrClosedResourceArgs {}

export class LoansActiveResource extends createResource<
  LoansActiveResourceArgs,
  LoansItem[]
>({
  getKey: (args: LoansActiveResourceArgs) => {
    return `loansActive(${args.mode})`;
  },
  getData: (args: LoansActiveResourceArgs) => {
    return TRANSPORT.API.get(`/v1/loan`, {
      params: {
        mode: args.mode,
        status: [
          LoanStatus.PENDING,
          LoanStatus.PROCESSING,
          LoanStatus.OPEN,
          LoanStatus.CLOSING,
        ],
        sort: 'finishAt',
        order: 'asc',
      },
      paramsSerializer: params => stringify(params, {arrayFormat: 'none'}),
    }).then(response => {
      return deserialize(LoansItem, response.data as []);
    });
  },
  skipRefreshOnVisible: false,
}) {
  public getById = computedFn((id: string) => {
    return this.data.find(l => l.id === id);
  });
}
