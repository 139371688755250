import {invariant} from '@youtoken/ui.utils';
import {AppEnv} from './AppEnv';

export const getRiskJsData = (env: AppEnv) => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot get Risk.js data for AppEnv="${env}"`
  );

  const token =
    env === AppEnv.production
      ? 'pk_wca3tek2vobsw5e6272cwkts5iu'
      : 'pk_sbox_mgpjuplowjsxcu46lbhv3khspuu';

  const url =
    env === AppEnv.production
      ? 'https://risk.checkout.com/cdn/risk/1/risk.js'
      : 'https://risk.sandbox.checkout.com/cdn/risk/1/risk.js';

  return {
    token,
    url,
  };
};
