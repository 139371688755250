import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {MinerOverviewResourceContext} from '../context';

// a custom styled SparkPrice is needed to render inside <Trans> component
const SparkPriceForVolume: React.FC<{amount: number}> = ({amount}) => {
  return (
    <Text>
      <Icon
        name="spark"
        size={20}
        color="$attention-01"
        // @ts-ignore web-specific style format clashes with native
        style={{
          // @ts-ignore web and native require different transform syntax
          transform: Platform.select({
            web: 'translateY(5px)',
            android: [
              {
                translateY: 5,
              },
            ],
            ios: [
              {
                translateY: 2,
              },
            ],
          }),
        }}
      />
      <Text variant="$body-01-high-accent" color="$attention-01">
        {amount}
      </Text>
    </Text>
  );
};

export const SparkForVolumeWithContext: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {
      data: {
        settings: {hodlSparksRequiredVolume, hodlSparksAmount},
      },
    } = React.useContext(MinerOverviewResourceContext).use({});

    return (
      <Box
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        {...boxProps}
      >
        <Text variant="$body-02" color="$text-02" textAlign="center">
          <Trans
            t={t}
            components={{
              Spark: <SparkPriceForVolume amount={hodlSparksAmount} />,
            }}
            values={{
              volume: hodlSparksRequiredVolume,
            }}
            i18nKey="surface.miner.spark_for_volume.sparks_for_volume"
          />
        </Text>
      </Box>
    );
  }
);
