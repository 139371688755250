import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HODLsOverviewResponse} from './HODLsOverviewResponse';

export class HODLOverviewResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => 'hodl:overview',
  getData: () =>
    TRANSPORT.API.get(`/v3/hodl/overview`).then(res => {
      return deserialize(HODLsOverviewResponse, res.data);
    }),
}) {}
