import * as React from 'react';
import {ScrollView} from 'react-native';
import {Box, BoxProps, useTheme} from '@youtoken/ui.primitives';

export const ComboboxItemsList = React.forwardRef<
  unknown,
  React.PropsWithChildren<{isOpen: boolean; itemHeight?: number} & BoxProps>
>(({isOpen, children, itemHeight = 32, ...props}, ref) => {
  const {shadow} = useTheme();

  return (
    <Box
      style={{
        // @ts-ignore
        boxShadow: shadow,
      }}
      position="absolute"
      top="100%"
      mt={2}
      left={0}
      width="100%"
      overflow="hidden"
      maxHeight={itemHeight * 7}
      opacity={isOpen ? 1 : 0}
      backgroundColor="$ui-background"
      borderRadius={6}
      borderColor="$ui-01"
      borderWidth={1}
      {...props}
      ref={ref}
    >
      <ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1}}>
        <Box>{children}</Box>
      </ScrollView>
    </Box>
  );
});
