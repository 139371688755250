import {TRANSPORT} from '@youtoken/ui.transport';
import {AxiosResponse} from 'axios';
import EventEmitter from 'eventemitter3';
import type {DepositRequest} from './types/DepositRequest';
import type {DepositResponse} from './types/DepositResponse';

export abstract class ApplePayServiceBase {
  protected countryCode = 'CH';
  protected provider = 'unlimintApplePay';
  protected label = 'YouHodler';
  protected supportedNetworks = ['maestro', 'visa', 'masterCard'];
  protected merchantCapabilities = ['supports3DS'];

  public isAvailable: boolean = false;
  public canMakePayments: boolean = false;

  public events = new EventEmitter<
    | 'initBackendSessionError'
    | 'applePaySessionCanceled'
    | 'paymentError'
    | 'paymentSuccess'
    | 'paymentPending'
  >();

  public abstract makePayment: (
    currency: string,
    amount: string,
    amountWithFee: string
  ) => Promise<boolean>;

  protected requestDeposit = (
    data: DepositRequest
  ): Promise<AxiosResponse<DepositResponse>> => {
    return TRANSPORT.API.post('/v1/deposit', data);
  };
}
