import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ActionsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 14.375a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm0-6.25a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75zm0-6.25a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
