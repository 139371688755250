import * as React from 'react';
import {observer} from 'mobx-react';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {ReferralsAndBonusesScreen} from '../ReferralsAndBonusesScreen';
import {getTitleRewardsMainSurface} from './utils';

export {getTitleRewardsMainSurface} from './utils';

export const RewardsMainSurface: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <WebPageWrapper
      windowTitle={t('surface.referral_program.main_page.nav_title')}
      title={getTitleRewardsMainSurface()}
      hasBackButton
      onBackPress={SHARED_ROUTER_SERVICE.goBack}
    >
      <ReferralsAndBonusesScreen />
    </WebPageWrapper>
  );
});
