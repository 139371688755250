import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox} from '@youtoken/ui.primitives';
import {Badge, type BadgeVariant} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface BadgeContentProps {
  ticker: string;
  variant: BadgeVariant;
  onPress?: () => void;
}

export const BadgeContent: React.FC<BadgeContentProps> = observer(
  ({ticker, variant, onPress}) => {
    const {t} = useTranslation();

    const badgeTestId = React.useMemo(
      () => `IBAN_BADGE_${ticker.toUpperCase()}_${variant.toUpperCase()}`,
      [ticker, variant]
    );

    return (
      <TouchableBox ml={4} onPress={onPress} disabled={!onPress}>
        <Badge
          variant={variant}
          size="tiny"
          icon="bank_wire"
          testID={badgeTestId}
        >
          {t('surface.wirex_iban.status_badge.label')}
        </Badge>
      </TouchableBox>
    );
  }
);
