import {primitive, serializable} from 'serializr';
import {type IBankWireTemplate} from './types';

export class BankWireTemplatesResponse implements IBankWireTemplate {
  @serializable(primitive())
  templateId!: string;

  @serializable(primitive())
  accountType!: string;

  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  amount!: string;

  @serializable(primitive())
  beneficiaryName!: string;

  @serializable(primitive())
  iban!: string;

  @serializable(primitive())
  provider!: string;

  @serializable(primitive())
  bankName!: string;

  @serializable(primitive())
  swiftCode!: string;

  @serializable(primitive())
  bankCountry!: string;

  @serializable(primitive())
  bankCity!: string;

  @serializable(primitive())
  bankStreetAddress!: string;

  @serializable(primitive())
  bankZipCode!: string;

  @serializable(primitive())
  beneficiaryAddressCountry!: string;

  @serializable(primitive())
  beneficiaryAddressCity!: string;

  @serializable(primitive())
  beneficiaryAddressStreetAddress!: string;

  @serializable(primitive())
  beneficiaryAddressZipCode!: string;

  @serializable(primitive())
  paymentReference!: string;

  @serializable(primitive())
  twoFactorAuthCode!: string;
}
