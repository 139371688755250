import * as React from 'react';
import {
  SvgProps,
  Svg,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const LaptopIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        fill="none"
        {...props}
      >
        <Path
          d="M0 52h64a2 2 0 01-2 2H2a2 2 0 01-2-2zm4-35a6 6 0 016-6h44a6 6 0 016 6v34H4V17z"
          fill="#425266"
        />
        <Path
          d="M58 49V17a4 4 0 00-4-4H10a4 4 0 00-4 4v32h52z"
          fill={`url(#${_id}_laptop_svg__laptop_svg__paint0_linear)`}
        />
        <Defs>
          <LinearGradient
            id={`${_id}_laptop_svg__laptop_svg__paint0_linear`}
            x1={32}
            y1={11.667}
            x2={32}
            y2={50.333}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6B7A94" />
            <Stop offset={0} stopColor="#6B7A94" />
            <Stop offset={1} stopColor="#353E4F" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
