import * as React from 'react';
import {observer} from 'mobx-react';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SavingsWidgetWelcomeExperimentProps} from './types';
import {SavingsStartEarningButton, SavingsWelcome} from '../../../components';
import {SavingsWidgetLayout} from '../Layout';

export const SavingsWidgetWelcomeExperimentA: React.FC<
  SavingsWidgetWelcomeExperimentProps & BoxProps
> = observer(
  ({variant = 'primary', saving: {maxTickersAPR}, children, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <SavingsWidgetLayout
        variant={variant}
        primaryContent={
          <SavingsWelcome variant={variant}>
            {children ??
              t('surface.savings.widget.welcome.title', {
                apr: formatPercent(maxTickersAPR),
              })}
          </SavingsWelcome>
        }
        secondaryContent={
          <SavingsStartEarningButton
            type={variant === 'primary' ? 'inverted' : 'primary'}
            size="small"
            alignSelf="center"
          />
        }
        {...boxProps}
      />
    );
  }
);
