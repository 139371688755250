import * as React from 'react';
import {TextProps} from 'react-native-svg';
import {LabelText} from './LabelText';
import {themes} from '@youtoken/ui.primitives';

const font = themes.light.fonts.mono.regular;

export const ValueText: React.FC<TextProps> = React.memo(props => {
  return <LabelText fill="white" fontFamily={font} {...props} />;
});
