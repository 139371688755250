import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DownloadIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 19h10a2 2 0 002-2V5.828a2 2 0 00-.586-1.414l-2.828-2.828A2 2 0 0012.172 1H5a2 2 0 00-2 2v14a2 2 0 002 2zm10-1.5H5a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h6.25V5c0 .966.784 1.75 1.75 1.75h2.5V17a.5.5 0 01-.5.5zm.129-12.25L12.75 2.871V5c0 .138.112.25.25.25h2.129z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.207 7.5a.75.75 0 01.75.75v4.5l1.25-1.25a.707.707 0 01.912-.075l.088.075a.707.707 0 010 1l-2.47 2.47a.75.75 0 01-1.06 0l-2.47-2.47a.707.707 0 01.912-1.075l.088.075 1.25 1.25v-4.5a.75.75 0 01.75-.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
