import {observable, action, computed, reaction, IReactionDisposer} from 'mobx';
import {Form} from './Form';
import {LoanItemRegularResource} from '@youtoken/ui.resource-loans';
import {invariant} from '@youtoken/ui.utils';
import {
  formatByTickerLoan,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {format as formatDate} from '@youtoken/ui.date-fns';
import {Calculated} from './Calculated';
import {debounce} from 'lodash';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export interface FeatureLoanIncreaseFormArgs {
  id: string;
}

export interface FeatureLoanIncreaseFormResources {
  docsResource: DocsResource;
  walletsResource: WalletsResource;
  loanResource: LoanItemRegularResource;
}

export class FeatureLoanIncreaseForm extends createFeature({
  getKey: (args: FeatureLoanIncreaseFormArgs) =>
    `IncreaseForm:${JSON.stringify(args)}`,
  getResources: (args: FeatureLoanIncreaseFormArgs) => {
    const authMeResource = AuthMeResource.use({});

    return {
      docsResource: getResourceDescriptor(DocsResource, {
        country: authMeResource.residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      loanResource: getResourceDescriptor(LoanItemRegularResource, {
        id: args.id,
      }),
    };
  },
}) {
  @observable
  public restored = false;

  @observable
  public form: Form;

  @observable
  public calculated: Calculated;

  @observable
  public disposers: IReactionDisposer[] = [];

  @computed
  public get loan() {
    return this.form.loan;
  }

  @computed
  public get ticker() {
    return this._ticker?.toUpperCase();
  }

  @computed
  public get loanIncreaseMaxAmount() {
    invariant(this._ticker, 'cant get ticker from current tickers group');

    return formatByTickerLoan(this._loanIncrease.maxAmount, this._ticker);
  }

  @computed
  public get loanIncreaseLTV() {
    return formatPercent(this._loanIncrease.ltv);
  }

  @computed
  public get tickerCalculated() {
    return this._tickerCalculated?.toUpperCase();
  }

  @computed
  public get newLTV() {
    if (this.calculated.data?.ltv) {
      return formatPercent(this.calculated.data.ltv);
    }
    return '0';
  }

  @computed
  public get newPDL() {
    if (this.calculated.data?.mcPrice) {
      return formatPercent(
        toBig(-1).plus(
          toBig(this.calculated.data.mcPrice).div(this.loan.initialPrice)
        )
      );
    }
    return '0';
  }

  @computed
  public get newPDLAmount() {
    if (this.calculated.data?.mcPrice && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data.mcPrice,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get additionalCash() {
    if (this.calculated.data?.increasedAmount && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data.increasedAmount,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get interest() {
    if (this.calculated.data?.APR) {
      return formatPercent(this.calculated.data.APR);
    }
    return '0';
  }

  @computed
  public get serviceFee() {
    if (this.calculated.data?.fee && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data.fee,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get repaymentIncrease() {
    if (this.calculated.data?.repaymentAmount && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data.repaymentAmount,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get payOffUntil() {
    if (this.calculated.data?.payOffUntil) {
      return formatDate(
        this.calculated.data.payOffUntil,
        'dd, MMM, yyyy, HH:mm z'
      );
    }
    return undefined;
  }

  @computed
  public get amountWithoutCommission() {
    if (
      this.calculated.data?.amountWithoutCommission &&
      this._tickerCalculated
    ) {
      return formatByTickerLoan(
        this.calculated.data.amountWithoutCommission,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get agreements() {
    const documents = this.resources.docsResource.data;

    if (
      this.loan.company === 'NAUMARD' &&
      documents.loanIncreaseFormNaumard?.length
    ) {
      return documents.loanIncreaseFormNaumard;
    }

    if (
      this.loan.company === 'YOUHODLER' &&
      documents.loanIncreaseFormYouhodler?.length
    ) {
      return documents.loanIncreaseFormYouhodler;
    }

    return documents.loanIncreaseForm || [];
  }

  @action
  public restore = () => {
    this.form.restore();
    this.restored = true;
  };

  @action
  public reset = () => {
    this.restored = false;
    this.form.instance.reset();
  };

  @computed
  private get _loanIncrease() {
    return this.form.loanIncrease;
  }

  @computed
  private get _ticker() {
    return this.form.ticker?.ticker;
  }

  @computed
  private get _tickerCalculated() {
    return this.calculated.data?.ticker;
  }

  constructor(
    args: FeatureLoanIncreaseFormArgs,
    resources: FeatureLoanIncreaseFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
    this.calculated = new Calculated(this.form);
    this.disposers = [
      reaction(
        () => {
          return [this.restored, this.form.amount];
        },
        debounce(() => {
          if (this.restored) {
            this.calculated.load();
          }
        }, 300),
        {
          fireImmediately: true,
        }
      ),
    ];
  }
}
