import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelInfoItemSavingsProps} from './types';
import {
  AccountLevelInfoItemFull,
  AccountLevelSavingsYeldLimit,
  AccountLevelSavingsUpToAPRBadge,
  AccountLevelSavingsMaxAssets,
} from '..';

export const AccountLevelInfoItemSavingsFull: React.FC<
  AccountLevelInfoItemSavingsProps & BoxProps
> = observer(
  ({
    level: {
      cardInfoBadgeVariant,
      saving: {limit, maxTickersAPR, tickerAmount},
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <AccountLevelInfoItemFull
        testID="ACCOUNT_LEVEL_INFO_ITEM_SAVINGS"
        icon="interest"
        title={t('surface.loyalty.savings')}
        description={<AccountLevelSavingsYeldLimit amount={limit} />}
        badge={
          <AccountLevelSavingsUpToAPRBadge
            percent={maxTickersAPR}
            variant={cardInfoBadgeVariant}
          />
        }
        badgeInfo={<AccountLevelSavingsMaxAssets count={tickerAmount} />}
        {...boxProps}
      />
    );
  }
);
