import React from 'react';
import {type GetTrackProps, SliderItem} from 'react-compound-slider';
import {Box, type TColorTokensNames} from '@youtoken/ui.primitives';
import {sliderElementStyles} from './styles';

export const Track: React.FC<{
  source: SliderItem;
  target: SliderItem;
  color: TColorTokensNames;
  getTrackProps: GetTrackProps;
}> = ({source, target, color, getTrackProps}) => {
  const trackProps = getTrackProps();

  return (
    <Box
      left={`${source.percent}%`}
      width={`${target.percent - source.percent}%`}
      style={[sliderElementStyles.wrap, {cursor: 'pointer'}]}
      {...trackProps}
    >
      <Box bg={color} style={sliderElementStyles.inner} />
    </Box>
  );
};
