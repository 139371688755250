import * as React from 'react';
import {observer} from 'mobx-react';
import {ScrollView} from 'react-native';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  WalletDisclosures,
  WirexDisclosures,
  YouhodlerDocuments,
  Disclosure,
} from '@youtoken/ui.resource-all-disclosures';

const AgreementLink: React.FC<Disclosure & {testID?: string}> = ({
  link,
  name,
  i18nLink,
  i18nName,
  testID,
}) => {
  const {t} = useTranslation();

  return (
    <Link url={Boolean(i18nLink) ? t(i18nLink) : link} mb={16} testID={testID}>
      {Boolean(i18nName) ? t(i18nName) : name}
    </Link>
  );
};

type AllDisclosuresProps = {
  walletAgreements?: WalletDisclosures;
  wirexAgreements?: WirexDisclosures;
  youhodlerAgreements?: YouhodlerDocuments;
} & BoxProps;

export const AllDisclosures: React.FC<AllDisclosuresProps> = observer(
  ({walletAgreements, wirexAgreements, youhodlerAgreements, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <ScrollView>
        <Box {...boxProps}>
          <Text>{t('surface.disclosure_form.subtitle')}</Text>
          <Box>
            {Boolean(walletAgreements) && (
              <>
                <Text variant="$body-01-high-accent" mt={24} mb={16}>
                  {t('disclosure.product.wallet')}
                </Text>
                {Object.values(walletAgreements!).map(
                  (agreement: Disclosure, i) =>
                    Boolean(agreement) && (
                      <AgreementLink
                        key={agreement.name}
                        testID={`AGREEMENT_LINK_WALLET_${i}`}
                        {...agreement}
                      />
                    )
                )}
              </>
            )}

            {Boolean(wirexAgreements) && (
              <>
                <Text variant="$body-01-high-accent" mt={24} mb={16}>
                  {t('disclosure.product.wirex')}
                </Text>
                {Object.values(wirexAgreements!).map(
                  (agreement: Disclosure, i) =>
                    Boolean(agreement) && (
                      <AgreementLink
                        key={agreement.name}
                        testID={`AGREEMENT_LINK_WIREX_${i}`}
                        {...agreement}
                      />
                    )
                )}
              </>
            )}

            {Boolean(youhodlerAgreements) && (
              <>
                <Text variant="$body-01-high-accent" mt={24} mb={16}>
                  {t('disclosure.product.hodl2')},{' '}
                  {t('disclosure.product.loan')},{' '}
                  {t('disclosure.product.turbo')}
                </Text>
                {Object.values(youhodlerAgreements!).map(
                  (agreement: Disclosure, i) =>
                    Boolean(agreement) && (
                      <AgreementLink
                        key={agreement.name}
                        testID={`AGREEMENT_LINK_GENERAL_${i}`}
                        {...agreement}
                      />
                    )
                )}
              </>
            )}
          </Box>
        </Box>
      </ScrollView>
    );
  }
);
