import {useState} from 'react';

export const useChartCursorState = () => {
  const [cursorX, setCursorX] = useState<number | undefined>(undefined);
  const [pointIndex, setPointIndex] = useState<number | undefined>(undefined);

  return {
    cursorX,
    setCursorX,
    pointIndex,
    setPointIndex,
  };
};
