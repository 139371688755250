import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryResource} from '@youtoken/ui.resources-rewards';
import {RewardsHistoryFlatListV1} from '../../../..';
import {RewardsHistorySmartProps} from '../../types';

export const RewardsHistoryFlatListV1Smart: React.FC<RewardsHistorySmartProps> =
  observer(({showHeader, limit}) => {
    const {
      data: {items},
    } = RewardsHistoryResource.use({limit});

    return <RewardsHistoryFlatListV1 showHeader={showHeader} items={items} />;
  });
