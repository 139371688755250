import React from 'react';
import {Helmet} from 'react-helmet';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Content} from './Content';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const Details: React.FC = () => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.loans.tab_turbos_title')} />
      <Content />
    </InnerPageShell>
  );
};
