import React, {useContext} from 'react';
import {Button, IButtonProps} from '@youtoken/ui.buttons';
import {FormikContext} from '@web-app/src/components/contexts';
import {BoxProps} from '@youtoken/ui.primitives';

export const SubmitButton: React.FC<BoxProps & IButtonProps> = ({
  children,
  loading,
  testID = 'SUBMIT_BUTTON',
  ...otherProps
}) => {
  const {isSubmitting, isValid, isValidating, handleSubmit} =
    useContext(FormikContext);

  return (
    <Button
      loading={isSubmitting || isValidating || loading}
      disabled={!isValid}
      onPress={handleSubmit}
      testID={testID}
      {...otherProps}
    >
      {children}
    </Button>
  );
};
