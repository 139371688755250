import * as React from 'react';
import {FirstLoginModal} from './FirstLoginModal';
import {cell} from '@youtoken/ui.cell';
import {SecurityDevicesResource} from '@youtoken/ui.state-security';

export const FirstLoginModalCell = cell<{id: string}>(({id}) => {
  const {getDeviceItem} = SecurityDevicesResource.use({});

  const item = getDeviceItem(id);

  if (!item) {
    return null;
  }

  return <FirstLoginModal item={item} />;
});
