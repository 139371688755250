import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {DATA_STORAGE} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {authRequired} from './authRequired';
import {RouterResource} from './RouterResource';
import {
  PleaseVerifyPage,
  Profile,
  LegalInfoPage,
  DeleteAccountPage,
  AppearancePage,
} from '../pages';

const unmountWithRedirect = () => {
  const {push} = RouterResource.__DANGEROUSLY__getInstanceStatically({});
  const {forceIdentify} = AuthMeResource.__DANGEROUSLY__getInstanceStatically(
    {}
  );

  if (!forceIdentify) {
    DATA_STORAGE.delete(VerificationResource.getKey({}));

    push('/');
  }
};

export const ForceIdentifyRoutes: React.FC<RouteComponentProps> = authRequired(
  () => {
    React.useEffect(() => {
      return unmountWithRedirect;
    }, []);

    return (
      <SwitchWithNotFound>
        <Route path="/please-verify" component={PleaseVerifyPage} />
        <Route path="/profile" component={ProfileRoutes} />
      </SwitchWithNotFound>
    );
  }
);

export const ProfileRoutes: React.FC<RouteComponentProps> = ({
  match: {path},
}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}`} component={Profile} />
      <Route exact path={`${path}/legal-info`} component={LegalInfoPage} />
      <Route
        exact
        path={`${path}/delete-account`}
        component={DeleteAccountPage}
      />
      <Route exact path={`${path}/appearance`} component={AppearancePage} />
    </SwitchWithNotFound>
  );
};
