import * as React from 'react';
import {Platform} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {HistoryList} from '@youtoken/ui.surface-history-list';
import {Filters} from '@youtoken/ui.filters';
import {HistoryItemType} from '@youtoken/ui.resource-history-new';
import {AccountStatsFeature} from '../AccountStatsFeature';
import {TransactionType} from '../types';

const EXCLUDED_TYPES: TransactionType[] = [
  HistoryItemType.REF_BONUS,
  HistoryItemType.COLLATERAL_SOLD,
  HistoryItemType.SAVING_RELEASE,
  HistoryItemType.SAVING_ADD,
  HistoryItemType.FINE,
  HistoryItemType.DEPOSIT_BONUS,
];

const TYPES_LIST: TransactionType[] = Object.keys(HistoryItemType).filter(
  type => !EXCLUDED_TYPES.includes(type as TransactionType)
) as TransactionType[];

export const AccountStatsSurface: React.FC = cell(() => {
  const {selectedTickers, selectedTypes, getTickerFormatted, applyFilters} =
    AccountStatsFeature.use({});

  const isMobile = useIsMobile();

  return (
    <Box flex={1} bg="$ui-background">
      <Filters
        types={TYPES_LIST}
        selectedTickers={selectedTickers}
        selectedTypes={selectedTypes}
        applyFilters={applyFilters}
        scrollOffset={20}
        getTickerFormatted={getTickerFormatted}
        testIdBase="ACCOUNT_STATS"
        mx={Platform.OS === 'web' && isMobile ? -20 : 0}
        pt={Platform.OS === 'web' ? 0 : 16}
        pb={{default: 8, desktop: 16}}
      />

      <HistoryList resourceKey="accountStats" pageSize={20} />
    </Box>
  );
});
