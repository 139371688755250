import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {HODLInstrumentResource} from '@youtoken/ui.resource-hodl-instrument';
import {
  MarketStatsSection,
  PriceRangeSection,
  PerformanceSection,
} from '../../sections';

export const MarketStatsCell = cell<{
  baseTicker: string;
  quoteTicker: string;
}>(
  ({baseTicker, quoteTicker}) => {
    const {
      shouldShowMarketStatsSection,
      shouldShowPriceRangeSection,
      shouldShowPerformanceSection,
    } = HODLInstrumentResource.use({
      baseTicker,
      quoteTicker,
    });

    return (
      <>
        {shouldShowMarketStatsSection && (
          <>
            <Separator />
            <MarketStatsSection
              baseTicker={baseTicker}
              quoteTicker={quoteTicker}
            />
          </>
        )}
        {shouldShowPriceRangeSection && (
          <>
            <Separator />
            <PriceRangeSection
              baseTicker={baseTicker}
              quoteTicker={quoteTicker}
            />
          </>
        )}
        {shouldShowPerformanceSection && (
          <>
            <Separator />
            <PerformanceSection
              baseTicker={baseTicker}
              quoteTicker={quoteTicker}
            />
          </>
        )}
      </>
    );
  },
  {
    CellWrap: ({children}) => {
      return (
        <Box
          minHeight={{
            default: 146,
            tablet: 162,
          }}
        >
          {children}
        </Box>
      );
    },
  }
);
