import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {AppLedgerRoutes} from './LedgerLiveRoutes/AppLedgerRoutes';
import {AppWebRoutes} from './AppWebRoutes';
import {ForceIdentifyRoutes} from './ForceIdentifyRoutes';

export const AppRoutes: React.FC<RouteComponentProps> = observer(props => {
  const {forceIdentify} = AuthMeResource.use({});

  if (forceIdentify) {
    return <ForceIdentifyRoutes {...props} />;
  }

  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <AppLedgerRoutes {...props} />;
  }

  return <AppWebRoutes {...props} />;
});
