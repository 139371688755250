import {computed, observable} from 'mobx';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {toBig} from '@youtoken/ui.formatting-utils';
import {Form} from './Form';

export class View {
  @observable
  public form: Form;

  @computed
  public get tickerPrecision() {
    return getCoinDecimalPrecision(this.form.ticker);
  }

  @computed
  public get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  public get amountFormatted() {
    return toBig(this.form.amount).toFixed(this.tickerPrecision);
  }

  @computed
  public get feeAmountFormatted() {
    return toBig(this.form.fee).toFixed(this.tickerPrecision);
  }

  @computed
  public get incentivesBonusFormatted() {
    return this.form.incentivesBonus.toFixed(getCoinDecimalPrecision('bonus'));
  }

  constructor(form: Form) {
    this.form = form;
  }
}
