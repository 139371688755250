import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type TextProps, Text} from '@youtoken/ui.primitives';

export const RewardsBannerLabel: React.FC<TextProps> = observer(textProps => {
  const {t} = useTranslation();

  return (
    <Text variant="$body-01-high-accent" {...textProps}>
      {t('surface.referral_program.referrals_widget.referrals_and_bonuses')}
    </Text>
  );
});
