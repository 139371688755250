import * as React from 'react';
import {observer} from 'mobx-react';
import {modal} from '@web-app/src/stores';
import {Modal} from '@youtoken/ui.modal';
import {AdjustTpSlSurface} from '@youtoken/ui.surface-hodl-positions';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TpSlInitialPrice} from '@youtoken/ui.surface-hodl-positions';

export const modalHodlsAdjustTpSlName = 'hodlAdjustTpSl';

interface ModalHodlsAdjustTpSlProps extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalHodlsAdjustTpSl: React.FC<ModalHodlsAdjustTpSlProps> =
  observer(({id, ...props}) => {
    const {t} = useTranslation();

    return (
      <Modal
        title={t('surface.hodls.adjust_tp.title')}
        subtitle={<TpSlInitialPrice hodlId={id} />}
        webOverlayBackgroundColor="$transparent"
        shouldShowHeaderSeparator={false}
        px={{default: 16, desktop: 24}}
        {...props}
      >
        <AdjustTpSlSurface
          id={id}
          onCancel={modal.close}
          onSuccess={modal.close}
          width="100%"
        />
      </Modal>
    );
  });
