import * as React from 'react';
import {Heading, Box, BoxProps} from '@youtoken/ui.primitives';
import {Combobox, type IComboboxItem} from '@youtoken/ui.combobox';

interface FormAComboboxInputProps extends BoxProps {
  title: string;
  hasError: boolean;
  value: string;
  onChange: (value: string) => void;
  items: IComboboxItem[];
}

export const FormAComboboxInput: React.FC<FormAComboboxInputProps> = ({
  title,
  hasError,
  value,
  onChange,
  items,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {/* @ts-ignore */}
      <Heading variant="$heading-03" mb={8} focusable={false}>
        {title}
      </Heading>
      <Combobox
        hasError={hasError}
        value={value}
        onChange={onChange}
        items={items}
      />
    </Box>
  );
};
