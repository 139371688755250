import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const UpIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 8.5l3.75-3.75H5a.75.75 0 010-1.5h4.25a.5.5 0 01.5.5V8a.75.75 0 11-1.5 0V5.75L4.5 9.5a.707.707 0 01-1-1z"
          fill={color}
        />
      </Svg>
    );
  }
);
