import {observable, runInAction} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {TRANSPORT} from '@youtoken/ui.transport';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import * as yupPackage from '@youtoken/ui.yup';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {AllDisclosuresResource} from '@youtoken/ui.resource-all-disclosures';
import {countriesList} from '../..';
import {FeatureFormAArgs, FeatureFormAResources} from '../types';

export const isASCII = (value?: string) => {
  if (!value) {
    return false;
  }

  return /^[\x00-\x7F]*$/.test(value);
};

const ASCIIValidator = yupPackage
  .string()
  .required()
  .test('isASCII', messages.IS_NOT_LATIN_SYMBOLS, isASCII);

export const getCurrentCountry = (residence: string) => {
  return countriesList.find(country => country.value === residence);
};

export const serializeFormValues = ({
  nationality,
  streetAddress,
  residence,
  city,
  postalCode,
}: {
  nationality: string;
  streetAddress: string;
  residence: string;
  city: string;
  postalCode: string;
}) => {
  return {
    nationality,
    streetAddress,
    residence,
    city,
    postalCode,
  };
};

export class Form {
  @observable
  public args: FeatureFormAArgs;

  @observable
  public resources: FeatureFormAResources;

  @observable
  public instance: MobxReactForm;

  @observable
  public isSubmitting = false;

  public constructor(
    args: {},
    resources: {authMe: AuthMeResource; allDisclosures: AllDisclosuresResource}
  ) {
    this.args = args;
    this.resources = resources;

    this.instance = new MobxReactForm(
      {
        fields: {
          nationality: {
            name: 'nationality',
            value: undefined,
          },
          residence: {
            name: 'residence',
            value: undefined,
          },
          city: {
            name: 'city',
            value: '',
          },
          postalCode: {
            name: 'postalCode',
            value: '',
          },
          streetAddress: {
            name: 'streetAddress',
            value: '',
          },
          notUS: {
            name: 'notUS',
            value: false,
          },
          isSoleOwner: {
            name: 'isSoleOwner',
            value: false,
          },
          disclosure: {
            name: 'disclosure',
            value: false,
          },
        },
      },
      {
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            this.isSubmitting = true;
            return TRANSPORT.API.post(
              '/v3/kyc/form-a/sign',
              serializeFormValues(form.values())
            )
              .catch(error => {
                const _error = extractErrorFromResponse(
                  error?.response?.data,
                  '_error'
                );
                const _errorTranslated = getTranslatedValidationMessage(_error);

                LOCAL_NOTIFICATIONS.error({
                  text:
                    _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
                });

                throw error;
              })
              .then(() => {
                LOCAL_NOTIFICATIONS.info({
                  text: i18n.t('surface.profile.form_a.submit_msg.success'),
                });

                SHARED_ROUTER_SERVICE.navigate('__CloseModal');
                this.args.onSent?.();
              })
              .finally(() => {
                runInAction(() => {
                  this.isSubmitting = false;
                });
              });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  nationality: yup.string().required(),
                  residence: yup.string().required(),
                  city: ASCIIValidator,
                  postalCode: ASCIIValidator,
                  streetAddress: ASCIIValidator,
                  notUS: yup.bool().required().oneOf([true]),
                  isSoleOwner: yup.bool().required().oneOf([true]),
                  disclosure: this.resources.allDisclosures
                    .needsToSignAgreements
                    ? yup.bool().required().oneOf([true])
                    : yup.bool(),
                };
                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );
  }
}
