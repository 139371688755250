import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {LoanItemHeaderStatusOpening} from './Opening';
import {LoanItemHeaderStatusClosed} from './Closed';
import {LoanItemHeaderStatusOther} from './Other';

export interface LoanItemHeaderStatusProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeaderStatus: React.FC<LoanItemHeaderStatusProps> =
  observer(({item}) => {
    if (item.isOpening) {
      return <LoanItemHeaderStatusOpening item={item} />;
    }

    if (item.isClosed) {
      return <LoanItemHeaderStatusClosed item={item} />;
    }

    return <LoanItemHeaderStatusOther item={item} />;
  });
