import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Text} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {DepositNowFeature} from '../../../state';
import {BenefitItem} from '../../BenefitItem';

export const DepositNowInfoMiner: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {sparksBenefitsFormatted} = DepositNowFeature.use({});

  return (
    <BenefitItem
      testID="DEPOSIT_NOW_INFO_SPARKS"
      iconName="spark"
      iconColor="$attention-01"
      iconColorBackground="$attention-02"
      {...boxProps}
    >
      <Trans
        t={t}
        components={{
          Strong: <Text color="$text-01" />,
        }}
        values={{count: sparksBenefitsFormatted}}
        i18nKey="surface.wallets.deposit_now.benefits.sparks.with_plural"
      />
    </BenefitItem>
  );
});
