import * as React from 'react';
import {Box, Heading} from '@youtoken/ui.primitives';
import {FeatureList, Separator} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AntiPhishingForm} from '../AntiPhishingForm';
import {cell} from '@youtoken/ui.cell';
import {CurrentAntiPhishingCode} from './CurrentAntiPhishingCode';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export const AntiPhishingSurface: React.FC = () => {
  const {data} = AuthMeResource.use({});
  const {t} = useTranslation();

  return (
    <Box width="100%" flex={1}>
      <Heading
        variant="$heading-01-responsive"
        color="$text-01"
        mb={24}
        px={{default: 16, phone: 24, desktop: 0}}
      >
        {t('surface.profile.security.antiphishing.form_title')}
      </Heading>

      <Box borderColor="$ui-01" borderRadius={10} borderWidth={1} px={24}>
        <Box px={0} py={24}>
          <FeatureList
            size="medium"
            items={[
              {
                icon: 'anti_phishing',
                title: t('surface.profile.security.antiphishing.what_is'),
                children: t(
                  'surface.profile.security.antiphishing.what_is_answer'
                ),
              },
              {
                icon: 'question_outlined',
                title: t('surface.profile.security.antiphishing.how_works'),
                children: t(
                  'surface.profile.security.antiphishing.how_works_answer'
                ),
              },
            ]}
          />
        </Box>

        <Separator mx={-25} backgroundColor="$ui-01" />
        {data.antiPhishingCode && (
          <Box px={0} py={24}>
            <CurrentAntiPhishingCode code={data.antiPhishingCode} />
          </Box>
        )}
        <AntiPhishingForm hasCode={Boolean(data.antiPhishingCode)} />
      </Box>
    </Box>
  );
};

export const AntiPhishingSurfaceCell = cell(AntiPhishingSurface, {
  CellWrap: ({children}) => <Box flex={1}>{children}</Box>,
});
