import * as React from 'react';
import {type LevelStatus} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, type TColorTokens, Text} from '@youtoken/ui.primitives';

type LevelCardTitleProps = {
  text: string;
  variant: LevelStatus;
} & BoxProps;

const COLORS: Record<LevelStatus, keyof TColorTokens> = {
  completed: '$text-02',
  active: '$text-01',
  locked: '$text-01',
};

export const LevelCardTitle: React.FC<LevelCardTitleProps> = ({
  text,
  variant,
  ...boxProps
}) => {
  return (
    <Text variant="$body-01-high-accent" color={COLORS[variant]} {...boxProps}>
      {text}
    </Text>
  );
};
