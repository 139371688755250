import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Icon, LogoColored} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {MethodData} from '../../types';
import {MethodItem} from '../MethodItem';
import {DepositMethodEnum} from '../../../../constants';
import {DepositMethodsFeature, Providers} from './state/DepositMethodsFeature';
import {CardDepositMethods} from '../../../../smart';
import {HeaderBase} from '../../../../components';

interface DepositMethodsProps {
  ticker: string;
  onChange: (
    method: DepositMethodEnum,
    provider?: Providers,
    operationMethod?: CardDepositMethods
  ) => void;
  showHeader?: boolean;
  onPressBack?: () => void;
  onNavigateToVerification?: () => void;
}

export const DepositMethods: React.FC<DepositMethodsProps> = cell(
  ({ticker, onChange, showHeader, onPressBack}) => {
    const {t} = useTranslation();

    const {
      methodsList,
      allowUnlimintApplePay,
      allowCheckoutBankCardFrame,
      createHandleMethodPress,
    } = DepositMethodsFeature.use({ticker});

    const methodsData: Record<DepositMethodEnum, MethodData> = React.useMemo(
      () => ({
        [DepositMethodEnum.APPLE_PAY]: {
          title: allowUnlimintApplePay
            ? t('web_app.wallets.fiat_deposit.item_apple_pay_title.default')
            : t(
                'web_app.wallets.fiat_deposit.item_apple_pay_title.available_in_safari'
              ),
          description: t(
            'web_app.wallets.fiat_deposit.item_apple_pay_duration'
          ),
          icon: (
            <Icon
              name="os_mac"
              color={!allowUnlimintApplePay ? '$text-03' : undefined}
            />
          ),
          testID: 'FIAT_DEPOSIT_METHOD_APPLE_PAY',
        },
        [DepositMethodEnum.BANK_CARD]: {
          title: t(
            allowCheckoutBankCardFrame
              ? 'surface.wallets.fiat_deposit.item_apple_or_google_title'
              : 'surface.wallets.fiat_deposit.item_card_title'
          ),
          description: t('surface.wallets.fiat_deposit.item_card_duration'),
          icon: <Icon name="card" color="$text-05" />,
          testID: allowCheckoutBankCardFrame
            ? 'FIAT_DEPOSIT_METHOD_APPLE_OR_GOOGLE_PAY'
            : 'FIAT_DEPOSIT_METHOD_BANK_CARD',
        },
        [DepositMethodEnum.BANK_CARD_IFRAME]: {
          title: t('surface.wallets.fiat_deposit.item_card_title'),
          description: t('surface.wallets.fiat_deposit.item_card_duration'),
          icon: <Icon name="card" color="$text-05" />,
          testID: 'FIAT_DEPOSIT_METHOD_BANK_CARD_FRAME',
        },
        [DepositMethodEnum.BANK_WIRE]: {
          title: t('surface.wallets.fiat_deposit.item_bank_wire_title'),
          description:
            ticker === 'eur'
              ? t('surface.wallets.fiat_deposit.item_bank_wire_duration.eur')
              : t('surface.wallets.fiat_deposit.item_bank_wire_duration.other'),
          icon: <Icon name="bank_wire" color="$text-05" />,
          testID: 'FIAT_DEPOSIT_METHOD_BANK_WIRE',
        },
        [DepositMethodEnum.CODI]: {
          title: t('surface.wallets.fiat_deposit.item.codi.title'),
          description: t('surface.wallets.fiat_deposit.item.codi.duration'),
          icon: <LogoColored name="codi" />,
          testID: 'FIAT_DEPOSIT_METHOD_CODI',
        },
        [DepositMethodEnum.SPEI]: {
          title: t('surface.wallets.fiat_deposit.item.spei.title'),
          description: t('surface.wallets.fiat_deposit.item.spei.duration'),
          icon: <LogoColored name="spei" />,
          testID: 'FIAT_DEPOSIT_METHOD_SPEI',
        },

        [DepositMethodEnum.ADV_CASH]: {
          title: t('surface.wallets.fiat_deposit.item_advcash_title'),
          description: t('surface.wallets.fiat_deposit.item_advcash_duration'),
          icon: <LogoColored name="advcash" />,
          testID: 'FIAT_DEPOSIT_METHOD_ADVCASH',
        },
      }),
      [t, ticker, allowUnlimintApplePay]
    );

    return (
      <Box borderTopWidth={1} borderColor="$ui-01">
        {showHeader && (
          <HeaderBase onPressBack={onPressBack}>
            {t('surface.wallets.fiat_deposit.title', {
              ticker: ticker.toUpperCase(),
            })}
          </HeaderBase>
        )}
        {methodsList.map(
          ({
            method,
            disabled,
            provider,
            fee,
            paymentSystems,
            operationMethod,
          }) => (
            <MethodItem
              key={`${method}_${provider}`}
              disabled={disabled}
              onPress={createHandleMethodPress({
                method,
                provider,
                operationMethod,
                onChange,
              })}
              fee={fee}
              tags={paymentSystems}
              {...methodsData[method]}
            />
          )
        )}
      </Box>
    );
  },
  {
    SuspenseFallback: () => (
      <Box minHeight={80} justifyContent="center" alignItems="center">
        <ActivityIndicator size="large" />
      </Box>
    ),
  }
);
