import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const OsWindowsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M10 9.467v-6.4L18 2v7.467h-8zm-8 0V4.133L9 3.2v6.267H2zm8 1.066v6.4L18 18v-7.467h-8zm-8 5.334v-5.334h7V16.8l-7-.933z"
          fill={color}
        />
      </Svg>
    );
  }
);
