import {createResource} from '@youtoken/ui.data-storage';
import {i18n} from '@youtoken/ui.service-i18n';
import {TRANSPORT} from '@youtoken/ui.transport';

export interface VerificationResourceArgs {
  type: 'link' | 'accessToken';
  levelName: string;
  lang: string;
}

export type VerificationResourceData =
  | {
      type: 'link';
      levelName: string;
      lang: string;
      url: string;
    }
  | {
      type: 'accessToken';
      levelName: string;
      lang: string;
      token: string;
    };

export class SumSubResource extends createResource<
  VerificationResourceArgs,
  VerificationResourceData
>({
  refetchInterval: 30 * 60 * 1000, // 30 minutes
  cacheTime: 0,
  getKey: ({type, levelName, lang}) =>
    `SumSub({${type}, ${levelName}, ${lang}})`,
  getData: ({type, levelName, lang}) => {
    return TRANSPORT.API.get(`/v3/kyc/${type}`, {
      params: {
        levelName,
        lang,
      },
    }).then(({data: {url, token}}) => {
      if (type === 'link') {
        return {
          type,
          levelName,
          lang,
          url,
        };
      }

      return {
        type,
        levelName,
        lang,
        token,
      };
    });
  },
}) {
  static getAccessToken(levelName: string) {
    return TRANSPORT.API.get(`/v3/kyc/accessToken`, {
      params: {
        levelName,
        lang: i18n.language,
      },
    }).then(({data: {token}}) => {
      return token;
    });
  }
}
