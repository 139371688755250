import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const RemoveIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 3.5a2 2 0 012 2V6a2 2 0 01-2 2h-11a2 2 0 01-2-2v-.5a2 2 0 012-2h11zm0 1.5h-11a.5.5 0 00-.492.41L4 5.5V6a.5.5 0 00.41.492l.09.008h11a.5.5 0 00.492-.41L16 6v-.5a.5.5 0 00-.41-.492L15.5 5zm-.75 4a.75.75 0 01.743.648l.007.102V16a2 2 0 01-2 2h-7a2 2 0 01-2-2V9.75l.007-.102a.75.75 0 011.486 0L6 9.75V16a.5.5 0 00.41.492l.09.008h7a.5.5 0 00.492-.41L14 16V9.75l.007-.102A.75.75 0 0114.75 9zm-6.5 0a.75.75 0 01.75.75v4.75a.75.75 0 01-1.5 0V9.75A.75.75 0 018.25 9zm3.5 0a.75.75 0 01.75.75v4.75a.75.75 0 01-1.5 0V9.75a.75.75 0 01.75-.75zm0-8a.75.75 0 010 1.5h-3.5a.75.75 0 010-1.5h3.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
