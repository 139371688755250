import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, Text} from '@youtoken/ui.primitives';
import {diffColor} from '../../types';

type ItemDiffProps = {
  baseTicker: string;
  quoteTicker: string;
  testIdBase?: string;
};

export const ItemDiff: React.FC<ItemDiffProps> = observer(
  ({baseTicker, quoteTicker, testIdBase}) => {
    const {getDiffAndFormattedRate} = HODLsTariffsFeature.use({});
    const {diffDirection, diffPercentFormatted, rateFormatted} =
      getDiffAndFormattedRate(baseTicker, quoteTicker);

    return (
      <Box alignItems="flex-end" ml={8} width="25%">
        <Text
          variant="$body-01"
          color="$text-01"
          testID={`${testIdBase}_INSTRUMENT_RATE`}
        >
          {rateFormatted}
        </Text>
        <Text
          variant="$body-02"
          color={diffColor[diffDirection]}
          testID={`${testIdBase}_INSTRUMENT_DIFF`}
        >
          {diffPercentFormatted}
        </Text>
      </Box>
    );
  }
);
