import * as React from 'react';
import {ThemeProvider as RestyleThemeProvider} from '@shopify/restyle';
import {invariant} from '@youtoken/ui.utils';
import {Theme, ThemeNames, themes} from '../../system';

type ThemeProviderProps = {
  /** pass theme directly to override predefined themes */
  theme?: Theme;

  /** one of the predefined themes
   * @default 'light'
   */
  themeName?: ThemeNames;
};

/** wrapper around ThemeProvider from `restyle`
 *
 * - accepts themeName: `light` | `dark`,
 * - theme is for direct override, you could pass any object as theme;
 */
export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  theme,
  themeName = 'light',
}) => {
  invariant(
    themes[themeName],
    `themeName is invalid, only "light" | "dark" are supported, you've passed ${themeName}`
  );

  return (
    <RestyleThemeProvider theme={theme ? theme : themes[themeName]}>
      {children}
    </RestyleThemeProvider>
  );
};
