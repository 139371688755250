import React from 'react';
import {useIsMobile} from '@youtoken/ui.primitives';
import {ActionsProps} from './types/ActionsProps';
import {ActionsMobile} from './components/ActionsMobile';
import {ActionsDesktop} from './components/ActionsDesktop';

export const Actions: React.FC<ActionsProps> = props => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <ActionsMobile {...props} />;
  }

  return <ActionsDesktop {...props} />;
};
