import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps} from '@youtoken/ui.primitives';
import {
  AccountLevelInfoItemFull,
  AccountLevelMinerUpToUSD,
  AccountLevelMinerUpToBTCBadge,
  AccountLevelMinerWelcomePack,
} from '..';

export interface AccountLevelInfoItemCloudMinerFullProps {
  level: LoyaltyLevel;
  showBadgeInfo?: boolean;
}

export const AccountLevelInfoItemCloudMinerFull: React.FC<
  AccountLevelInfoItemCloudMinerFullProps & BoxProps
> = observer(
  ({
    level: {
      miner: {welcomePack},
      details: {
        minerMaxMonthlyIncomeBTCFormatted,
        minerMaxMonthlyIncomeUSDFormatted,
      },
      cardInfoBadgeVariant,
    },
    showBadgeInfo = true,
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <AccountLevelInfoItemFull
        testID="ACCOUNT_LEVEL_INFO_ITEM_CLOUD_MINER"
        icon="mining"
        title={t('surface.loyalty.cloud_miner')}
        description={
          <AccountLevelMinerUpToUSD
            amount={minerMaxMonthlyIncomeUSDFormatted}
            mt={4}
          />
        }
        badge={
          <AccountLevelMinerUpToBTCBadge
            amount={minerMaxMonthlyIncomeBTCFormatted}
            variant={cardInfoBadgeVariant}
          />
        }
        badgeInfo={
          showBadgeInfo && (
            <AccountLevelMinerWelcomePack count={welcomePack} mt={4} />
          )
        }
        {...boxProps}
      />
    );
  }
);
