import * as React from 'react';
import {observer} from 'mobx-react';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../state/WithdrawalForm';

export const Submit: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {ibanStep},
    view: {isAllowed},
  } = useForm<WithdrawalForm>();

  return (
    <SubmitButton
      size="large"
      disabled={!isAllowed}
      testID="FIAT_WITHDRAWAL_BANK_WIRE_SUBMIT"
    >
      {ibanStep === 'small'
        ? t('surface.wallets.withdrawal_bank_wire_form.submit_continue')
        : t('surface.wallets.withdrawal_bank_wire_form.submit_withdrawal')}
    </SubmitButton>
  );
});
