import {TRANSPORT} from '@youtoken/ui.transport';
import {action, computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {FeatureLoanFTPFormArgs, FeatureLoanFTPFormResources} from './index';
import {handleFormSubmitError} from '../../utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {toBig, formatByTickerLoan} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import {i18n} from '@youtoken/ui.service-i18n';

export class Form {
  @observable
  public args: FeatureLoanFTPFormArgs;

  @observable
  public resources: FeatureLoanFTPFormResources;

  @observable
  public instance: MobxReactForm;

  @computed.struct
  public get loan() {
    return this.resources.loanResource.data;
  }

  @computed
  public get loanCollateralTicker() {
    return this.loan.collateralTicker;
  }

  @computed
  public get loanBorrowedTicker() {
    return this.loan.borrowedTicker;
  }

  @computed
  public get loanInitialPrice() {
    return this.loan.initialPrice;
  }

  @computed
  public get loanMinFTP() {
    return this.loan.minTP;
  }

  @computed
  public get loanFTP() {
    return this.loan.tp;
  }

  @computed
  public get amountMin() {
    if (this.loanFTP && this.loanBorrowedTicker) {
      return formatByTickerLoan(this.loanFTP, this.loanBorrowedTicker);
    }

    if (this.loanInitialPrice && this.loanMinFTP && this.loanBorrowedTicker) {
      return formatByTickerLoan(
        toBig(this.loanInitialPrice)
          .mul(toBig(this.loanMinFTP))
          .plus(
            toBig(1).div(
              toBig(10).pow(
                getCoinDecimalPrecisionForLoans(this.loanBorrowedTicker)
              )
            )
          ),
        this.loanBorrowedTicker
      );
    }

    return undefined;
  }

  @computed
  public get amount() {
    return this.instance.$('amount').get('value');
  }

  @computed
  public get amountOnChange() {
    return this.instance.$('amount').get('onChange');
  }

  @action
  public restore = () => {};

  @action
  public submit = () => {
    const url = `/v${this.loan.version}/loan/${
      this.loan.version === 1 ? 'ftp' : 'tp'
    }`;

    return TRANSPORT.API.post(url, {
      id: this.args.id,
      [this.loan.version === 1 ? 'FTP' : 'tp']: Number(this.amount),
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.loans.ftp_form.message.setted'),
        });
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e, {
          [this.loan.version === 1 ? 'FTP' : 'tp']: 'amount',
        });
      });
  };

  public constructor(
    args: FeatureLoanFTPFormArgs,
    resources: FeatureLoanFTPFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          amount: {},
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  amount: yup.string(),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
