import * as React from 'react';
import {ConversionUnauthorizedForm} from '@youtoken/ui.surfaces-wallets';
import {cell} from '@youtoken/ui.cell';
import {Box, ThemeProvider} from '@youtoken/ui.primitives';
import {useLandingTheme, useLandingEffectChangeLanguage} from '../hooks';
import {RouterResource} from '../../../routes/RouterResource';
import {ServicePagesWrapper} from '../ServicePagesWrapper';

export const LandingExchangePage: React.FC = cell(() => {
  const {
    queryParams: {theme: themeName, lang, ticker, conversionTicker, isInIframe},
  } = RouterResource.use({});

  const theme = useLandingTheme(themeName);

  useLandingEffectChangeLanguage(lang);

  return (
    <ThemeProvider theme={theme}>
      <Box
        justifyContent={{
          default: 'flex-start',
          tablet: 'center',
        }}
        alignItems="center"
        width="100%"
        height="100%"
        bg="$ui-background"
      >
        <Box width="100%" maxWidth={688}>
          <ConversionUnauthorizedForm
            ticker={ticker}
            toTicker={conversionTicker}
            borderWidth={1}
            borderRadius={16}
            isInIframe={isInIframe}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
});

export const LedgerLiveExchangePage: React.FC = () => {
  return (
    <ServicePagesWrapper>
      <ConversionUnauthorizedForm />
    </ServicePagesWrapper>
  );
};
