import * as React from 'react';
import {Text, TouchableBox, TouchableBoxProps} from '@youtoken/ui.primitives';
import {CONTROLS_HEIGHT} from '../constants';

export type TimeFrameButtonProps = {
  active: boolean;
  children: string;
} & TouchableBoxProps;

export const TimeFrameButton: React.FC<TimeFrameButtonProps> = React.memo(
  ({active, children, ...props}) => {
    return (
      <TouchableBox
        activeOpacity={0.8}
        height={CONTROLS_HEIGHT}
        backgroundColor={active ? '$interactive-02' : '$transparent'}
        alignItems="center"
        justifyContent="center"
        px={8}
        borderRadius={5}
        {...props}
      >
        <Text
          variant="$body-02-medium-accent"
          color={active ? '$text-05' : '$text-02'}
        >
          {children.toUpperCase()}
        </Text>
      </TouchableBox>
    );
  }
);
