import * as React from 'react';
import {useWizard} from '@youtoken/ui.elements';
import {CryptoDepositWizard} from '../..';
import {type DepositNowWizardNavigator, DepositNowWizardScreenName} from '..';

export const DepositNowWizardDepositCrypto: React.FC = () => {
  const {goBack} = useWizard<
    DepositNowWizardNavigator,
    DepositNowWizardScreenName.DEPOSIT_CRYPTO
  >();

  return <CryptoDepositWizard onPressBack={goBack} />;
};
