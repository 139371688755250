import * as React from 'react';
import {observer} from 'mobx-react';
import {
  FlatList,
  type LayoutChangeEvent,
  type LayoutRectangle,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
} from 'react-native';
import {DotPagination} from './DotPagination';
import {Box} from '@youtoken/ui.primitives';
import {type SliderProps} from './types';
import {MIDDLE_CARD_OFFSET, GAP} from './constants';

export const Slider = observer(
  <T extends Object = Object>({
    initialIndex = 0,
    data,
    renderItem: _renderItem,
    cardWidthMax,
    cardFullWidth = false,
    paginationBoxProps,
    onActiveItemChange,
    onProgressChange,
    currentIndex,
    gap = GAP,
    paginationVisibility = 'auto',
  }: SliderProps<T>) => {
    const ref = React.useRef<FlatList | null>(null);
    const currentIndexRef = React.useRef<number>(currentIndex ?? initialIndex);

    const [activeIndex, setActiveIndex] = React.useState(
      currentIndex ?? initialIndex
    );

    // to scroll to the current index (if used and was changed)
    React.useEffect(() => {
      if (
        layoutSizeWidthCalculated &&
        typeof currentIndex === 'number' &&
        currentIndex !== currentIndexRef.current
      ) {
        ref.current?.scrollToIndex({index: currentIndex});
      }
    }, [currentIndex]);

    const handleSnapToItem = React.useCallback(
      (index: number) => {
        if (index !== currentIndexRef.current) {
          currentIndexRef.current = index;
          onActiveItemChange?.(data[index]!, index);
        }
      },
      [data, onActiveItemChange]
    );

    const [layoutSize, setLayoutSize] = React.useState<LayoutRectangle | null>(
      null
    );

    const layoutSizeWidth = layoutSize?.width ?? 0;

    const layoutSizeWidthCalculated = layoutSizeWidth > 0;

    const cardWidth = React.useMemo(() => {
      if (cardFullWidth) {
        return layoutSizeWidth;
      }

      const width = layoutSizeWidth - MIDDLE_CARD_OFFSET * 2;

      if (cardWidthMax && cardWidthMax < width) {
        return cardWidthMax;
      }

      return width;
    }, [layoutSizeWidth, MIDDLE_CARD_OFFSET, cardWidthMax]);

    const cardWidthWithGap = cardWidth + gap;

    const scrollPadding = (layoutSizeWidth - cardWidthWithGap) / 2;

    const onLayout = React.useCallback(
      ({nativeEvent: {layout}}: LayoutChangeEvent) => {
        if (layout.width == 0 || layout.height == 0) {
          return;
        }

        setLayoutSize(layout);
      },
      []
    );

    const onScroll = React.useCallback(
      ({
        nativeEvent: {
          contentOffset: {x},
          contentSize: {width},
        },
      }: NativeSyntheticEvent<NativeScrollEvent>) => {
        const widthWithoutPaddings = width - scrollPadding * 2;

        const floatIndex = Math.round((x / widthWithoutPaddings) * data.length);

        const index = Math.min(Math.max(floatIndex, 0), data.length - 1);

        if (typeof onProgressChange === 'function') {
          onProgressChange(floatIndex);
        }

        setActiveIndex(index);
        handleSnapToItem(index);
      },
      [scrollPadding, data.length, onProgressChange]
    );

    const onPressPagination = React.useCallback(
      (index: number) => {
        ref.current?.scrollToIndex({index});
      },
      [ref.current]
    );

    const getItemLayout = React.useCallback(
      (_: ArrayLike<T> | null | undefined, index: number) => {
        return {
          length: cardWidthWithGap,
          index,
          offset: cardWidthWithGap * index,
        };
      },
      [cardWidthWithGap]
    );

    const renderItem = React.useCallback(
      (info: {item: T; index: number}) => {
        return (
          <Box width={cardWidthWithGap} alignItems="center">
            <Box width={cardWidth}>{_renderItem(info)}</Box>
          </Box>
        );
      },
      [cardWidthWithGap, cardWidth, _renderItem]
    );

    const keyExtractor = React.useCallback((_: T, index: number) => {
      return `slide-${index}`;
    }, []);

    // to scroll to the initial index
    React.useEffect(() => {
      if (layoutSizeWidthCalculated) {
        ref.current?.scrollToIndex({
          index: initialIndex,
          animated: false,
        });
      }
    }, [ref.current, layoutSizeWidthCalculated]);

    const showPagination = React.useMemo(() => {
      return (
        layoutSizeWidthCalculated &&
        (paginationVisibility === 'always' ||
          (paginationVisibility === 'auto' && data.length > 1))
      );
    }, [layoutSizeWidthCalculated, paginationVisibility, data.length]);

    return (
      <Box width="100%" height="100%">
        <Box flex={1} onLayout={onLayout}>
          <FlatList
            ref={ref}
            scrollEventThrottle={100}
            onScroll={onScroll}
            data={layoutSizeWidthCalculated ? data : []}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            getItemLayout={getItemLayout}
            horizontal
            pagingEnabled={true}
            snapToInterval={cardWidthWithGap}
            showsHorizontalScrollIndicator={false}
            style={{
              paddingHorizontal: scrollPadding,
            }}
          />
        </Box>
        {showPagination && (
          <DotPagination
            count={data.length}
            activeIndex={activeIndex}
            onIndexChange={onPressPagination}
            {...paginationBoxProps}
          />
        )}
      </Box>
    );
  }
);
