import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LoanTurbo} from '../Loan';

export class LoanItemTurboResource extends createResource<
  {id: string},
  LoanTurbo
>({
  getKey: ({id}) => `LoanTurbo(${id})`,
  getData: ({id}) => {
    return TRANSPORT.API.get(`/v1/loan/${id}`).then(response => {
      return deserialize(LoanTurbo, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {}
