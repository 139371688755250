import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {ReferralsWidgetV1Smart} from './v1';
import {ReferralsWidgetV3Smart} from './v3';

export const ReferralsWidgetSmart: React.FC = observer(() => {
  const {
    data: {rewardVersion},
  } = AuthMeResource.use({});

  if (rewardVersion === 3) {
    return <ReferralsWidgetV3Smart />;
  }

  return <ReferralsWidgetV1Smart />;
});
