import * as React from 'react';
import {Fonts} from './Fonts';
import {Reset} from './Reset';
import {Defaults} from './Defaults';
import {Controls} from './Controls';
import {Notifications} from './Notifications';

export const GlobalStyles = React.memo(
  () => {
    return (
      <>
        <Fonts />
        <Reset />
        <Defaults />
        <Controls />
        <Notifications />
      </>
    );
  },
  () => true
);
