import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored} from '@youtoken/ui.icons';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const LeftSection: React.FC = observer(() => {
  const {
    totalMinedAmountFormatted,
    totalMinedTickerFormatted,
    totalMinedAmountUSDFormatted,
  } = MinerOverviewResource.use({});

  const {t} = useTranslation();

  return (
    <Box>
      <Text variant="$body-02" color="$text-02" px={4}>
        {t('surface.miner.overview.total')}
      </Text>
      <Box
        testID="TOTAL_MINED_BTC"
        borderRadius={24}
        backgroundColor="$success-03"
        flexDirection="row"
        alignItems="center"
        mt={8}
        p={4}
        pr={16}
      >
        <LogoColored name="btc" size={24} />
        <Text variant="$body-01-high-accent" color="$text-01" ml={6}>
          {totalMinedAmountFormatted}
        </Text>
        <Text variant="$body-01" color="$text-01" ml={2}>
          {totalMinedTickerFormatted}
        </Text>
      </Box>
      <Box testID="TOTAL_MINED_USD" mt={8} px={4} flexDirection="row">
        <Text variant="$body-02-high-accent" color="$text-02">
          {totalMinedAmountUSDFormatted}
        </Text>
        <Text variant="$body-02" color="$text-02" ml={2}>
          USD
        </Text>
      </Box>
    </Box>
  );
});
