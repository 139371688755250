import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {DATA_STORAGE} from '@youtoken/ui.data-storage';
import {HistoryItemData} from './HistoryItemData';
import {HistoryListResource, HISTORY_LIST_KEY} from './HistoryListResource';
import {type HistoryItemResourceArgs} from './types';

export class HistoryItemResource extends createResource<
  HistoryItemResourceArgs,
  HistoryItemData
>({
  getKey: ({id}: HistoryItemResourceArgs) => `historyItem:${id}`,
  getData: ({id}: HistoryItemResourceArgs) => {
    // checking if already in storage (in one of history lists)
    for (const key of Object.keys(DATA_STORAGE.storage)) {
      if (key.startsWith(`resource:${HISTORY_LIST_KEY}`)) {
        const historyList = DATA_STORAGE.storage[key] as HistoryListResource;

        const item = historyList.data.rows.find(item => item.id === id);
        if (item) {
          return Promise.resolve(item);
        }
      }
    }

    // if not found, fetching from API
    return TRANSPORT.API.get(`/v2/balance/history/${id}`).then(res => {
      return deserialize(HistoryItemData, res.data);
    });
  },
  refetchInterval: 1000 * 60 * 1, // 1 minute
}) {}
