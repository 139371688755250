import {type TFunction} from 'react-i18next';

const PUNCTUATION_REGEX = /[^\w\s\)]+/g;

export const choosePunctuationMark = (
  name: string,
  index: number,
  arrLength: number,
  t: TFunction
) => {
  const punctuationInTheEndOfName: null | string[] = name
    .slice(-1)
    .match(PUNCTUATION_REGEX);

  if (punctuationInTheEndOfName?.length) {
    return '';
  }
  if (index === arrLength - 1) {
    return '.';
  }
  if (index === arrLength - 2) {
    return ` ${t('common.agreements.and')} `;
  }
  return ', ';
};
