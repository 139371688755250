import {useMemo} from 'react';

/** __hook, set up memoized initial values for form__
 *
 * if uses dynamic values from state inputs should be passed to `useMemo`
 */
export const useInitialValues = <FormValues>(
  initialValues: FormValues,
  inputs: any[] = []
): FormValues => {
  return useMemo(() => {
    return initialValues;
  }, inputs);
};
