/** to keep track of broad types of error */
export enum ERROR_TYPE {
  /** special case for warnings to catch stacktrace */
  WARNING = 'WARNING',

  /** special case for invariant */
  INVARIANT = 'INVARIANT',

  /** default value for error type */
  'GENERAL_ERROR' = 'GENERAL_ERROR',

  /** this is network error (generic); it can be retried
   * - show retry button in case it's was just a blip on a server
   */
  'NETWORK_ERROR' = 'NETWORK_ERROR',

  /** offline error for when there is no connection
   * - show users something like _"go check your internet connection pal"_
   * - keep retry button active;
   */
  'NETWORK_ERROR_OFFLINE' = 'NETWORK_ERROR_OFFLINE',

  /** network error, but timeout was invoked
   * - go check connection
   * - retry
   */
  'NETWORK_ERROR_TIMEOUT' = 'NETWORK_ERROR_TIMEOUT',

  'NETWORK_ERROR_MAINTENANCE' = 'NETWORK_ERROR_MAINTENANCE',

  /** server error, something happened */
  'NETWORK_ERROR_SERVER' = 'NETWORK_ERROR_SERVER',

  /** special case for 401 errors; */
  'NETWORK_ERROR_UNAUTHORIZED' = 'NETWORK_ERROR_UNAUTHORIZED',

  /** special case for recaptcha errors
   * because we need to keep track of them and will try to fallback to v2 etc
   * */
  'RECAPTCHA_ERROR' = 'RECAPTCHA_ERROR',
  /** Recaptcha v3 error
   * - fallback to v2 recaptcha;
   */

  'RECAPTCHA_V3_ERROR' = 'RECAPTCHA_V3_ERROR',
  /** Recaptcha v2 error cannot be handled by fallback
   * - just by retry;
   */
  'RECAPTCHA_V2_ERROR' = 'RECAPTCHA_V3_ERROR',
  /** special case for canceled requests errors
   * just by canceled requests
   */
  'CANCELLED_REQUEST_ERROR' = 'CANCELLED_REQUEST_ERROR',
  /** unknown error means that this is some error in code.
   *  - unknown because we generally don't know how to fallback from them;
   */
  'UNKNOWN_ERROR' = 'UNKNOWN_ERROR',

  /** error know to occur during serialization/deserialization in our mobx resources */
  'SERIALIZATION_ERROR' = 'SERIALIZATION_ERROR',

  /** 4XX client errors, like bad request, forbidden, etc. */
  'NETWORK_ERROR_CLIENT_ERROR' = 'NETWORK_ERROR_CLIENT_ERROR',
}
