import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {DepositNowInfoIncentives} from '@youtoken/ui.surfaces-wallets';

export const IncentivesPercentInfo: React.FC<BoxProps> = observer(boxProps => {
  const {showIncentivesBonuses} = IncentivesFeature.use({});

  if (!showIncentivesBonuses) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <DepositNowInfoIncentives />
    </Box>
  );
});
