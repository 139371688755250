import React from 'react';
import {Text, TextProps} from '@youtoken/ui.primitives';

interface LabelProps extends TextProps {
  required?: boolean;
}

const fieldRequiredMark = ' *';

export const Label: React.FC<LabelProps> = ({children, required, ...props}) => {
  return (
    <Text variant="$body-01-high-accent" accessible={false} {...props}>
      {children}
      {required ? fieldRequiredMark : ''}
    </Text>
  );
};
