import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, TouchableBox, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useForm} from '@youtoken/ui.form-elements';
import type {
  LoanRepayOwnFundsFormProps,
  LoanRepayOwnFundsFormState,
} from '../../types';

export const Header: React.FC<
  Pick<LoanRepayOwnFundsFormProps, 'onPressBack'> & BoxProps
> = observer(({onPressBack, ...rest}) => {
  const {t} = useTranslation();

  const {
    form: {ticker},
  } = useForm<LoanRepayOwnFundsFormState>();

  return (
    <Box justifyContent="center" {...rest}>
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        position="relative"
      >
        <Text variant="$heading-02">
          {t('surface.loans.loan_pay_own_funds_form.title', {
            ticker: ticker.toUpperCase(),
          })}
        </Text>
        {onPressBack && (
          <TouchableBox
            justifyContent="center"
            alignItems="center"
            onPress={onPressBack}
            top="0"
            bottom="0"
            left={0}
            position="absolute"
          >
            <Box flexDirection="row" alignItems="center">
              <Icon name="chevron_left" color="$text-05" />
              <Text color="$interactive-01">{t('surface.loans.back')}</Text>
            </Box>
          </TouchableBox>
        )}
      </Box>
    </Box>
  );
});
