import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {cell} from '@youtoken/ui.cell';
import {transparentScreenOptions, createWizard} from '@youtoken/ui.elements';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {BuyCryptoFormAuthorizedYouHodlerState} from '../../../state';
import {NotEnoughData} from '../../NotEnoughData';
import {getTitle} from '../utils';
import {BuyCryptoFormAuthorizedYouHodlerMain} from './Main';
import {BuyCryptoFormAuthorizedYouHodlerChooseMethod} from './ChooseMethod';

export enum BuyCryptoFormAuthorizedYouHodlerWizardScreenName {
  MAIN = 'main',
  CHOOSE_METHOD = 'chooseMethod',
}

export interface BuyCryptoFormAuthorizedYouHodlerWizardNavigator {
  [BuyCryptoFormAuthorizedYouHodlerWizardScreenName.MAIN]: {
    source?: 'FORK' | 'WALLET';
    ticker?: string;
  };
  [BuyCryptoFormAuthorizedYouHodlerWizardScreenName.CHOOSE_METHOD]: {
    source?: 'FORK' | 'WALLET';
    ticker?: string;
  };
}

const Wizard = createWizard<BuyCryptoFormAuthorizedYouHodlerWizardNavigator>();

interface BuyCryptoFormAuthorizedYouHodlerFormProps {
  conversionTicker?: string;
  onPressBack?: () => void;
  onSubmit?: () => void;
}

const BuyCryptoFormAuthorizedYouHodlerForm: React.FC<BuyCryptoFormAuthorizedYouHodlerFormProps> =
  cell(({conversionTicker, onPressBack, onSubmit}) => {
    const {residenceOrCountry} = AuthMeResource.use({});

    const resources = useResources({
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsResource, {}),
      fees: getResourceDescriptor(FeeAllResource, {}), // for deposit method
    });

    const form = makeForm(
      () =>
        new BuyCryptoFormAuthorizedYouHodlerState(
          {
            conversionTicker,
            onSubmit,
          },
          resources
        )
    );

    return (
      <Form form={form}>
        <Wizard.Navigator
          initialName={BuyCryptoFormAuthorizedYouHodlerWizardScreenName.MAIN}
          goBack={onPressBack}
        >
          <Wizard.Screen
            name={BuyCryptoFormAuthorizedYouHodlerWizardScreenName.MAIN}
            options={{
              title: getTitle(form.form.conversionTicker),
            }}
            component={BuyCryptoFormAuthorizedYouHodlerMain}
          />
          <Wizard.Screen
            name={
              BuyCryptoFormAuthorizedYouHodlerWizardScreenName.CHOOSE_METHOD
            }
            options={transparentScreenOptions}
            component={BuyCryptoFormAuthorizedYouHodlerChooseMethod}
          />
        </Wizard.Navigator>
      </Form>
    );
  });

export const BuyCryptoFormAuthorizedYouHodler: typeof BuyCryptoFormAuthorizedYouHodlerForm =
  props => {
    if (!props.conversionTicker) {
      return <NotEnoughData />;
    }

    return <BuyCryptoFormAuthorizedYouHodlerForm {...props} />;
  };
