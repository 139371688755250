import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

export const FieldWrap: React.FC<BoxProps> = ({children, ...props}) => {
  return (
    <Box p={20} {...props}>
      {children}
    </Box>
  );
};
