import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {TFAFormField, useForm} from '@youtoken/ui.form-elements';
import {TwoFactorDisableFormState} from '../../state';

export const TwoFactorDisableFormContent: React.FC = observer(() => {
  const {
    form: {createOperation, codeError, codeOnChange},
  } = useForm<TwoFactorDisableFormState>();

  return (
    <ScrollView>
      <Box flex={1} p={{default: 16, phone: 24}}>
        <TFAFormField
          getOperationData={createOperation}
          onChangeCode={codeOnChange}
          error={codeError}
          px={0}
          py={0}
          bg="$transparent"
        />
      </Box>
    </ScrollView>
  );
});
