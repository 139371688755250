import * as React from 'react';
import {TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type ArrowProps} from '../types';

export const Arrow: React.FC<ArrowProps> = ({
  direction,
  iconColor,
  onPress,
  ...boxProps
}) => {
  const handleOnPress = React.useCallback(() => {
    onPress(direction);
  }, [direction, onPress]);

  return (
    <TouchableBox
      position="absolute"
      top="50%"
      alignItems="center"
      style={{
        ...(direction === 'back' && {left: 12}),
        ...(direction === 'forward' && {right: 12}),
        transform: [
          {
            translateY: -16,
          },
        ],
      }}
      justifyContent="center"
      onPress={handleOnPress}
      width={32}
      height={32}
      borderRadius={14}
      opacity={0.8}
      backgroundColor="$ui-02"
      zIndex={1}
      {...boxProps}
    >
      <Icon
        size={20}
        color={iconColor || '$ui-background'}
        name={direction === 'back' ? 'chevron_left' : 'chevron_right'}
      />
    </TouchableBox>
  );
};
