import {useCallback} from 'react';
import {FormikActions} from 'formik';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {i18n} from '@youtoken/ui.service-i18n';
import {message} from '@web-app/src/utils';

type DefaultValues = {
  [key: string]: any;
};

type handleSubmitType<FormValues> = (
  values: FormValues,
  formikActions: FormikActions<FormValues>
) => Promise<any>;

type SubmitProps = {
  displaySuccessMessage?: boolean;
  successMessage?: string;
  successMessageTestID?: string;
  displayErrorMessage?: boolean;
  errorMessage?: string;
  errorMessageTestID?: string;
};

/** __hook, memoized callback for `Formik`'s `onSubmit`__
 *
 * will set `isSubmitting` to `true`/`false` for you
 */
export const useOnSubmit = <FormValues = DefaultValues>(
  handleSubmit: handleSubmitType<FormValues>,
  props: SubmitProps = {},
  inputs: any[] = []
) => {
  return useCallback(
    (values: FormValues, formikActions: FormikActions<FormValues>): void => {
      const {setSubmitting, setFieldError} = formikActions;
      const {
        displayErrorMessage = true,
        errorMessage,
        successMessage,
        successMessageTestID,
        errorMessageTestID,
      } = props;

      setSubmitting(true);

      handleSubmit(values, formikActions)
        .then(result => {
          if (successMessage) {
            message.info(
              successMessage,
              undefined,
              undefined,
              successMessageTestID
            );
          }

          setSubmitting(false);

          return result;
        })
        .catch(axiosError => {
          // get ky error or axios, temp solution

          const error =
            axiosError &&
            (axiosError.data ||
              (axiosError.response && axiosError.response.data));

          const extractedError =
            extractErrorFromResponse(error, '_error') ||
            errorMessage ||
            i18n.t('common.errors.smth_went_wrong');

          if (displayErrorMessage && extractedError) {
            const translatedError =
              getTranslatedValidationMessage(extractedError);
            message.error(
              translatedError,
              undefined,
              undefined,
              errorMessageTestID
            );
          }

          // setting error messages
          // will be able to disaplay errors
          if (error) {
            Object.keys(error)
              .filter(k => k !== 'i18n')
              .forEach(fieldName => {
                const fieldValue = values[fieldName as keyof typeof values];
                const fieldError = error && error[fieldName];

                if (typeof fieldValue !== 'undefined' && fieldError) {
                  const fieldErrorLocalized = extractErrorFromResponse(
                    error,
                    fieldName
                  );

                  setFieldError(fieldName, fieldErrorLocalized as any);
                }
              });
          }

          setSubmitting(false);
        });
    },
    inputs
  );
};
