import {primitive, serializable} from 'serializr';

export class BankCardsAccountDetailsResponse {
  @serializable(primitive())
  fullName!: string;

  @serializable(primitive())
  iban!: string;

  @serializable(primitive())
  bic!: string;

  @serializable(primitive())
  bankCountry!: string;

  @serializable(primitive())
  bankCity!: string;

  @serializable(primitive())
  bankStreet!: string;

  @serializable(primitive())
  bankPostalCode!: string;
}
