declare global {
  interface __GLOBAL__ {
    [key: string]: any;
  }
}

const __GLOBAL_FALLBACK__: __GLOBAL__ = {};

/** returns global object for env: `window`, `global`, `self` and fallbacks to local var */
export const getGlobal = (): __GLOBAL__ => {
  if (typeof global !== 'undefined') {
    return global as unknown as __GLOBAL__;
  }

  if (typeof window !== 'undefined') {
    return window as unknown as __GLOBAL__;
  }

  if (typeof self !== 'undefined') {
    return self as unknown as __GLOBAL__;
  }

  return __GLOBAL_FALLBACK__;
};

export const __global__ = getGlobal();
