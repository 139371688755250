import React from 'react';
import {modal} from '@web-app/src/stores';
import {Modal} from '../_common';
import {HODLCloseNowSurface} from '@youtoken/ui.surface-hodl-positions';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';

interface ModalHodlCloseNowProps extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalHodlCloseNow: React.FC<ModalHodlCloseNowProps> = ({
  id,
  ...otherProps
}) => {
  const handleCloseModal = React.useCallback(() => {
    modal.close();
  }, []);

  const {t} = useTranslation();

  return (
    <Modal {...otherProps}>
      <Box width="100%" alignItems="stretch">
        <Box
          justifyContent="center"
          alignItems="center"
          py={24}
          px={{default: 16, phone: 24}}
        >
          <Heading variant="$heading-01" textAlign="center">
            {t('surface.hodls.close_now.title')}
          </Heading>
        </Box>
        <HODLCloseNowSurface hodlId={id} onCloseAction={handleCloseModal} />
      </Box>
    </Modal>
  );
};
