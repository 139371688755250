import * as React from 'react';
import {Box, useTheme} from '@youtoken/ui.primitives';
import {Content, Footer} from './AwaitConfirmDevice';
import {AwaitConfirmDeviceProps} from './types';

export const AwaitConfirmDevice: React.FC<AwaitConfirmDeviceProps> = ({
  userEmail,
  onResendEmail,
}) => {
  const {spacing} = useTheme();

  return (
    <Box
      borderRadius={10}
      overflow="hidden"
      borderColor="$ui-01"
      borderWidth={{
        desktop: 1,
        tablet: 1,
        phone: 0,
        smallPhone: 0,
      }}
      bg="$ui-background"
      width="100%"
      maxWidth={700}
      px={spacing['$screen-default-offset']}
    >
      <Content userEmail={userEmail} />
      <Footer pt={24} pb={32} onResendEmail={onResendEmail} />
    </Box>
  );
};
