import * as React from 'react';
import {observer} from 'mobx-react';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Combobox} from '@youtoken/ui.combobox';
import {TextInput} from '@youtoken/ui.inputs';
import {CardsForm} from '../../../state';

export const BillingDetailsSection: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    form: {
      onChangeCountryCode,
      onChangeCity,
      onChangeState,
      onChangeZipCode,
      onChangeAddressLine1,
      onChangeAddressLine2,
      countryItems,
    },
    view: {
      countryCode,
      countryCodeError,
      city,
      cityError,
      onCityBlur,
      state,
      stateError,
      onStateBlur,
      zipCode,
      zipCodeError,
      onZipCodeBlur,
      addressLine1,
      addressLine1Error,
      onAddressLine1Blur,
      addressLine2,
      addressLine2Error,
      onAddressLine2Blur,
    },
  } = useForm<CardsForm>();

  return (
    <Box pt={8}>
      <Heading>{t('surface.wallets.bank_card.billing_details')}</Heading>

      <Label mt={24} mb={8} required>
        {t(
          'surface.profile.form_proof_of_address.address.select_country.label'
        )}
      </Label>
      <Combobox
        zIndex={10}
        size="large"
        items={countryItems}
        value={countryCode}
        onChange={onChangeCountryCode}
        hasError={Boolean(countryCodeError)}
        testID="COUNTRY_SELECTOR"
      />
      <FieldErrorMessage visible={Boolean(countryCodeError)}>
        <ValidationMessageComponent error={countryCodeError} />
      </FieldErrorMessage>

      <Label mt={24} my={8} required>
        {t('surface.form_a.field.city')}
      </Label>
      <TextInput
        value={city}
        onChangeText={onChangeCity}
        onBlur={onCityBlur}
        testID="CITY_INPUT"
      />
      <FieldErrorMessage visible={Boolean(cityError)}>
        <ValidationMessageComponent error={cityError} />
      </FieldErrorMessage>

      <Label mt={24} my={8}>
        {t('surface.wallets.bank_card.state')}
      </Label>
      <TextInput
        value={state}
        onChangeText={onChangeState}
        onBlur={onStateBlur}
        testID="STATE_INPUT"
      />
      <FieldErrorMessage visible={Boolean(stateError)}>
        <ValidationMessageComponent error={stateError} />
      </FieldErrorMessage>

      <Label mt={24} my={8} required>
        {t('surface.wallets.bank_card.zip_code')}
      </Label>
      <TextInput
        value={zipCode}
        onChangeText={onChangeZipCode}
        onBlur={onZipCodeBlur}
        testID="ZIP_CODE_INPUT"
      />
      <FieldErrorMessage visible={Boolean(zipCodeError)}>
        <ValidationMessageComponent error={zipCodeError} />
      </FieldErrorMessage>

      <Label mt={24} my={8} required>
        {t('surface.wallets.bank_card.address_line', {number: '1'})}
      </Label>
      <TextInput
        value={addressLine1}
        onChangeText={onChangeAddressLine1}
        onBlur={onAddressLine1Blur}
        testID="ADDRESS_1_INPUT"
      />
      <FieldErrorMessage visible={Boolean(addressLine1Error)}>
        <ValidationMessageComponent error={addressLine1Error} />
      </FieldErrorMessage>

      <Label mt={24} my={8}>
        {t('surface.wallets.bank_card.address_line', {number: '2'})}
      </Label>
      <TextInput
        value={addressLine2}
        onChangeText={onChangeAddressLine2}
        onBlur={onAddressLine2Blur}
        testID="ADDRESS_2_INPUT"
      />
      <FieldErrorMessage visible={Boolean(addressLine2Error)}>
        <ValidationMessageComponent error={addressLine2Error} />
      </FieldErrorMessage>
    </Box>
  );
});
