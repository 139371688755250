import {computed, observable} from 'mobx';
import {serializable, optional, primitive, object, date} from 'serializr';
import {format} from '@youtoken/ui.date-fns';
import {DeviceData} from '../SecurityDevicesResource';

export class SecurityActivityData {
  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(primitive())
  deviceId!: string;

  @observable
  @serializable(primitive())
  type!: string;

  @observable
  @serializable(optional(primitive()))
  target?: string;

  @observable
  @serializable(primitive())
  action!: string;

  @observable
  @serializable(date())
  createdAt!: Date;

  @observable
  @serializable(optional(object(DeviceData)))
  device?: DeviceData;

  @observable
  @serializable(optional(object(DeviceData)))
  deviceInfo?: DeviceData;

  @computed get date() {
    return format(this.createdAt, 'd MMM');
  }

  @computed get fullDate() {
    return format(this.createdAt, 'd MMM yyyy');
  }

  @computed get time() {
    return format(this.createdAt, 'HH:mm');
  }
}
