import * as React from 'react';
import {Box, type WithBoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {type CryptoDepositProps} from '../types';
import {CryptoDepositHeader} from '../CryptoDepositHeader';
import {CryptoDepositBanner} from '../CryptoDepositBanner';
import {CryptoDepositExternal} from '../CryptoDepositExternal';

export const CryptoDeposit: React.FC<WithBoxProps<CryptoDepositProps>> = cell(
  ({ticker, showHeader = true, onPressBack, ...rest}) => {
    return (
      <Box flexGrow={1} {...rest}>
        {showHeader && (
          <CryptoDepositHeader
            ticker={ticker}
            onPressBack={onPressBack}
            pb={0}
          />
        )}
        <CryptoDepositBanner ticker={ticker} mt={24} />
        <CryptoDepositExternal ticker={ticker} />
      </Box>
    );
  }
);
