import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BaseAction} from './BaseAction';
import type {ActionProps} from '../types';

export const TradeAction: React.FC<ActionProps & BoxProps> = observer(
  ({
    operationVolumeAmount,
    sparksAmount,
    product,
    volumeEquivalent,
    multiplier,
    onPress,
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const benefitTitle = t('surface.upgrade_modal.volume.benefit_title', {
      amount: operationVolumeAmount,
    });

    const benefitText = React.useMemo(() => {
      if (product === 'hodl') {
        return t(`surface.upgrade_modal.hodl_benefit`, {
          volumeEquivalent,
          multiplier,
        });
      }
      if (product === 'convert') {
        return t(`surface.upgrade_modal.convert_benefit`, {
          volumeEquivalent,
        });
      }
    }, [t, product, volumeEquivalent, multiplier]);

    return (
      <BaseAction
        borderColor="$interactive-02"
        chevronColor="$interactive-01"
        onPress={onPress}
        {...boxProps}
      >
        <Box flexDirection="row" flex={1}>
          <Box
            px={8}
            height={32}
            borderRadius={32}
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            backgroundColor="$interactive-02"
            mr={12}
          >
            <Icon name="volume" color="$interactive-01" size={16} />
          </Box>

          <Box flexDirection="column" flex={1} pr={15}>
            <Box flexDirection="row" alignItems="center" mb={3}>
              <Text variant="$body-02-high-accent">{benefitTitle}</Text>
              <Box
                flexDirection="row"
                alignItems="center"
                backgroundColor="$attention-02"
                ml={6}
                px={6}
                py={2}
                borderRadius={24}
              >
                <Icon name="spark" size={12} color="$attention-01" />
                <Text variant="$body-03" color="$attention-01">
                  {`+${sparksAmount}`}
                </Text>
              </Box>
            </Box>

            <Text variant="$body-02" color="$text-02">
              {benefitText}
            </Text>
          </Box>
        </Box>
      </BaseAction>
    );
  }
);
