import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const IncreasePositionIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 12.293a1 1 0 00.993-.883l.007-.117v-5h1.293a.5.5 0 00.411-.784l-.058-.07-2.292-2.293a.5.5 0 00-.638-.057l-.07.057L7.354 5.44a.5.5 0 00.263.846l.09.008H9v5a1 1 0 001 1zm-5.5 4a1 1 0 100-2h-1a1 1 0 100 2h1zm4 0a1 1 0 100-2h-1a1 1 0 100 2h1zm4 0a1 1 0 100-2h-1a1 1 0 100 2h1zm4 0a1 1 0 100-2h-1a1 1 0 100 2h1z"
          fill={color}
        />
      </Svg>
    );
  }
);
