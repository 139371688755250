import {CustomError, ERROR_TYPE} from '@youtoken/ui.errors';

export const createRecaptchaError = (version: 'v2' | 'v3', message: string) => {
  return new CustomError(
    `Recaptcha:${version}: ${message}`,
    version === 'v2'
      ? ERROR_TYPE.RECAPTCHA_V2_ERROR
      : ERROR_TYPE.RECAPTCHA_V3_ERROR,
    {
      tags: {
        version,
      },
      fingerprint: ['recaptcha', version, message],
    }
  );
};
