import * as React from 'react';
import {observer} from 'mobx-react';
import {getLevelIconName} from '@youtoken/ui.loyalty-miner-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {LoyaltyIcon} from '@youtoken/ui.icons';

export interface SavingsAprProps {
  level: number;
  aprFormatted: string;
}

export const SavingsApr: React.FC<SavingsAprProps & BoxProps> = observer(
  ({level, aprFormatted, ...boxProps}) => {
    const {t} = useTranslation();

    const levelIconName = getLevelIconName(level);

    return (
      <Box
        testID="SAVINGS_APR"
        flexDirection="row"
        alignItems="center"
        {...boxProps}
      >
        <Box mx={2}>
          <LoyaltyIcon name={levelIconName!} size={16} />
        </Box>
        <Text variant="$body-02" color="$text-02">
          <Text testID="SAVINGS_APR_VALUE">{aprFormatted}</Text>{' '}
          {t('surface.wallets.apr')}
        </Text>
      </Box>
    );
  }
);
