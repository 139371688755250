import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import * as yupPackage from '@youtoken/ui.yup';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {nanoid} from 'nanoid';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import type {
  AdjustPendingOrderFormArgs,
  AdjustPendingOrderFormResources,
} from './types';
import {toBig} from '@youtoken/ui.formatting-utils';
import {deserialize} from 'serializr';
import {CalculatedDataResponse} from './CalculatedDataResponse';
import {getAmountByPercent, getPercentByAmount} from '@youtoken/ui.hodls-utils';
import {messages} from '@youtoken/ui.validation-messages';
import axios, {type AxiosError, type CancelTokenSource} from 'axios';
import {SENTRY} from '@youtoken/ui.sentry';
import {invariant, warning} from '@youtoken/ui.utils';

export class AdjustPendingOrderForm {
  @observable
  public instance: MobxReactForm;

  @observable
  public args: AdjustPendingOrderFormArgs;

  @observable
  public resources: AdjustPendingOrderFormResources;

  @observable
  public requestId: string = '';

  @observable
  public isLoading: boolean = false;

  @observable
  currentCancelToken: CancelTokenSource | null = null;

  @observable
  calculatedData: null | CalculatedDataResponse = null;

  @observable
  currentPrice = toBig(0);

  @observable
  triggerPricePercent = '0';

  @observable
  takeProfitPercent: string = '0';

  @observable
  stopLossPercent: string = '0';

  setOfTriggerPricePercent = ['-2', '-1', '-0.5', '0.5', '1', '2'];

  @computed
  public get triggerPrice() {
    return this.instance?.$('triggerPrice').get('value');
  }

  @computed
  public get takeProfit() {
    return this.instance?.$('takeProfit').get('value');
  }

  @computed
  public get stopLoss() {
    return this.instance?.$('stopLoss').get('value');
  }

  disposers: Array<IReactionDisposer>;

  id: string;
  isShort: boolean;
  baseTicker: string;
  quoteTicker: string;
  inputTicker: string;
  precision: number;

  public constructor(
    resources: AdjustPendingOrderFormResources,
    args: AdjustPendingOrderFormArgs
  ) {
    this.args = args;
    this.resources = resources;
    this.requestId = nanoid();

    const {
      id,
      baseTicker,
      quoteTicker,
      inputTicker,
      precision,
      triggerPrice,
      triggerPriceFormatted,
      takeProfit,
      takeProfitFormatted,
      marginCall,
      marginCallFormatted,
      data,
    } = this.args.hodl;

    this.id = id;
    this.isShort = data.isShort;
    this.baseTicker = baseTicker;
    this.quoteTicker = quoteTicker;
    this.inputTicker = inputTicker;
    this.precision = precision;

    const _currentPrice = this.resources.rates?.getBidAskRate(
      this.baseTicker,
      this.quoteTicker,
      this.priceType
    );

    if (_currentPrice) {
      this.currentPrice = toBig(_currentPrice);
    }

    warning(
      triggerPrice && marginCall,
      'missing triggerPrice or marginCall data',
      {},
      {triggerPrice, marginCall}
    );

    this.triggerPricePercent = getPercentByAmount(
      this.currentPrice,
      triggerPrice ?? ''
    );
    this.takeProfitPercent = getPercentByAmount(
      triggerPrice ?? '',
      takeProfit ?? ''
    );
    this.stopLossPercent = getPercentByAmount(
      triggerPrice ?? '',
      marginCall ?? ''
    );

    const fields = {
      triggerPrice: {
        value: triggerPriceFormatted,
      },
      takeProfit: {
        value: takeProfitFormatted,
      },
      stopLoss: {
        value: marginCallFormatted,
      },
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        this.isLoading = true;

        return TRANSPORT.API.put(`/v3/hodl/${this.id}`, {
          requestId: this.requestId,
          ...this.requestData,
        })
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.hodls.adjust_tp.message.adjusted'),
            });

            this.resources.hodl.refetch();
            this.args.onSuccess();
          })
          .catch(e => {
            handleFormSubmitError(form, e, {
              tp: 'takeProfit',
              sl: 'stopLoss',
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            const triggerPriceValidator = yup
              .big()
              .required()
              .gte(this.triggerPriceValueMin, 'TRIGGER_PRICE_LIMIT')
              .lte(this.triggerPriceValueMax, 'TRIGGER_PRICE_LIMIT');

            warning(
              this.takeProfitValueMax && this.takeProfitValueMin,
              'missing takeProfitValueMax or takeProfitValueMin in validation',
              {},
              {
                takeProfitValueMax: this.takeProfitValueMax,
                takeProfitValueMin: this.takeProfitValueMin,
              }
            );

            const takeProfitValidator = this.isShort
              ? yup
                  .big()
                  .gt(0)
                  .lt(
                    this.takeProfitValueMax!,
                    messages.SHOULD_BE_LT_TRIGGER_PRICE
                  )
                  .gte(this.takeProfitValueMin!, 'TAKE_PROFIT_LIMIT')
              : yup
                  .big()
                  .gt(
                    this.takeProfitValueMin!,
                    messages.SHOULD_BE_GT_TRIGGER_PRICE
                  )
                  .lte(this.takeProfitValueMax!, 'TAKE_PROFIT_LIMIT');

            const stopLossValidator = this.isShort
              ? yup
                  .big()
                  .gt(
                    this.stopLossValueMin,
                    messages.SHOULD_BE_GT_TRIGGER_PRICE
                  )
                  .lte(this.stopLossValueMax, 'MARGIN_CALL_LIMIT')
              : yup
                  .big()
                  .lt(
                    this.stopLossValueMax,
                    messages.SHOULD_BE_LT_TRIGGER_PRICE
                  )
                  .gte(this.stopLossValueMin, 'MARGIN_CALL_LIMIT');

            return yup.object().shape({
              triggerPrice: triggerPriceValidator,
              stopLoss: stopLossValidator,
              takeProfit: takeProfitValidator,
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm(
      {fields},
      {
        options,
        plugins,
        hooks,
      }
    );

    this.disposers = [
      reaction(
        () => this.requestData,
        requestData => {
          const {token} = this.createCancelToken();
          this.isLoading = true;

          TRANSPORT.API.post(`/v3/hodl/${this.id}/calculate`, requestData, {
            cancelToken: token,
          })
            .then(res => {
              runInAction(() => {
                const calculatedData = deserialize(
                  CalculatedDataResponse,
                  res.data
                );

                this.calculatedData = calculatedData;

                invariant(
                  calculatedData.initialPrice,
                  `initialPrice was not sent from BE`
                );
                this.currentPrice = calculatedData.initialPrice;
              });
            })
            .catch((error: AxiosError) => {
              SENTRY.capture(error, {
                source: 'AdjustPendingOrderFormCalculate',
              });
            })
            .finally(() => {
              this.currentCancelToken = null;

              runInAction(() => {
                this.isLoading = false;
              });
            });
        },
        {fireImmediately: true, delay: 300}
      ),
      reaction(
        () => this.triggerPrice,
        triggerPrice => {
          runInAction(() => {
            const takeProfitAmount = getAmountByPercent(
              triggerPrice,
              this.takeProfitPercent,
              this.precision,
              this.takeProfitRoundingMode
            );
            const stopLossAmount = getAmountByPercent(
              triggerPrice,
              this.stopLossPercent,
              this.precision,
              this.stopLossRoundingMode
            );

            this.instance.$('takeProfit').set('value', takeProfitAmount);
            this.instance.$('stopLoss').set('value', stopLossAmount);
          });
        },
        {fireImmediately: true}
      ),
    ];
  }

  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }

  @computed
  public get onSubmit() {
    return this.instance.onSubmit;
  }

  @computed.struct get requestData() {
    const obj: {[key: string]: any} = {
      triggerPrice: Number(this.triggerPrice),
      tp: Number(this.takeProfit),
      sl: Number(this.stopLoss),
    };

    return obj;
  }

  @computed get priceType() {
    // NOTE: for pending-orders this price will be used for open the deal
    return this.isShort ? 'bid' : 'ask';
  }

  @computed get tariffs() {
    return this.resources?.tariffs?.tariffsWithoutIndexes || [];
  }

  @computed get currentTariff() {
    return this.tariffs.find(
      tariff =>
        tariff.baseTicker === this.baseTicker &&
        tariff.quoteTicker === this.quoteTicker &&
        tariff.isShort === this.isShort
    );
  }

  @computed get triggerPriceDistanceMax() {
    return this.currentTariff?.triggerPriceDistanceMax ?? 0;
  }

  @computed get triggerPriceDistanceValueLimit() {
    return this.currentPrice.times(this.triggerPriceDistanceMax);
  }

  @computed get triggerPriceValueMin() {
    const minPrice = this.currentPrice.minus(
      this.triggerPriceDistanceValueLimit
    );

    return minPrice.gt(0) ? minPrice.toFixed(this.precision, 3) : '0';
  }

  @computed get triggerPriceValueMax() {
    return this.currentPrice
      .plus(this.triggerPriceDistanceValueLimit)
      .toFixed(this.precision, 0);
  }

  @action updateTriggerPriceValue = (value: string) => {
    this.triggerPricePercent = getPercentByAmount(this.currentPrice, value);
    this.instance.$('triggerPrice').set('value', value);
  };

  @action updateTriggerPricePercent = (percent: string) => {
    const amount = getAmountByPercent(
      this.currentPrice,
      percent,
      this.precision
    );

    this.triggerPricePercent = percent;
    this.instance.$('triggerPrice').set('value', amount);
  };

  @computed get triggerPriceBig() {
    return toBig(this.triggerPrice);
  }

  @computed get takeProfitValueMin() {
    return this.isShort ? this.calculatedData?.ftpPrice : this.triggerPriceBig;
  }

  @computed get takeProfitValueMax() {
    return this.isShort ? this.triggerPriceBig : this.calculatedData?.ftpPrice;
  }

  @computed get maxProfit() {
    return (
      this.calculatedData?.maxProfit ?? toBig(this.args.hodl.data.maxProfit)
    );
  }

  @computed get takeProfitError() {
    return this.instance.$('takeProfit').get('error');
  }

  @computed get takeProfitRoundingMode() {
    return this.isShort ? 3 : 0;
  }

  @computed get stopLossRoundingMode() {
    return this.isShort ? 0 : 3;
  }

  @action
  updateTakeProfitValue = (value: string) => {
    this.instance.$('takeProfit').set('value', value);
    this.takeProfitPercent = getPercentByAmount(this.triggerPrice, value);
  };

  @action updateTakeProfitPercent = (percent: string) => {
    this.takeProfitPercent = percent;

    const amount = getAmountByPercent(
      this.triggerPrice,
      percent,
      this.precision,
      this.takeProfitRoundingMode
    );

    this.instance.$('takeProfit').set('value', amount);
  };

  @computed get stopLossValueMin() {
    return this.isShort ? this.triggerPrice : this.calculatedData?.mcPrice;
  }

  @computed get stopLossValueMax() {
    return this.isShort ? this.calculatedData?.mcPrice : this.triggerPrice;
  }

  @computed get maxLoss() {
    return this.calculatedData?.maxLoss ?? toBig(this.args.hodl.data.maxLoss);
  }

  @computed get stopLossError() {
    return this.instance.$('stopLoss').get('error');
  }

  @action
  updateStopLossValue = (value: string) => {
    this.instance.$('stopLoss').set('value', value);
    this.stopLossPercent = getPercentByAmount(this.triggerPrice, value);
  };

  @action updateStopLossPercent = (percent: string) => {
    this.stopLossPercent = percent;

    const amount = getAmountByPercent(
      this.triggerPrice,
      percent,
      this.precision,
      this.stopLossRoundingMode
    );
    this.instance.$('stopLoss').set('value', amount);
  };

  @action
  setTakeProfitLimit = () => {
    const takeProfitLimit = this.calculatedData?.ftpPrice.toFixed(
      this.precision,
      this.takeProfitRoundingMode
    );

    warning(
      takeProfitLimit,
      'takeProfitLimit is not defined',
      {},
      {takeProfitLimit}
    );

    this.updateTakeProfitValue(takeProfitLimit!);
  };

  @action
  setStopLossLimit = () => {
    const stopLossLimit = this.calculatedData?.mcPrice.toFixed(
      this.precision,
      this.stopLossRoundingMode
    );

    warning(stopLossLimit, 'stopLossLimit is not defined', {}, {stopLossLimit});

    this.updateStopLossValue(stopLossLimit!);
  };

  @action
  createCancelToken() {
    if (this.currentCancelToken) {
      this.currentCancelToken.cancel('New request initiated');
    }
    this.currentCancelToken = axios.CancelToken.source();

    return this.currentCancelToken;
  }

  @action
  resetCancelToken = () => {
    if (this.currentCancelToken) {
      this.currentCancelToken.cancel('Component unmounted');
      this.currentCancelToken = null;
    }
  };
}
