import * as React from 'react';
import {observer} from 'mobx-react';
import {LOCAL_CONFIRMATIONS} from '@youtoken/ui.local-confirmations';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Button} from '@youtoken/ui.buttons';
import {getStatusLabel} from './utils';

export interface LoanItemMainActionProps
  extends React.ComponentProps<typeof Button> {
  item: LoanRegularV1 | LoanRegularV2;
  onCancelled?: () => void;
}

export const LoanItemMainAction: React.FC<LoanItemMainActionProps> = observer(
  ({item, onCancelled, ...rest}) => {
    const {t} = useTranslation();

    const [loading, setLoading] = React.useState(false);

    const handlePress = React.useCallback(() => {
      if (item.isOpening) {
        LOCAL_CONFIRMATIONS.requestConfirmation({
          title: i18n.t('surface.loans.item.cancel_confirm'),
          description: i18n.t('surface.loans.item.cancel_confirm_description'),
        }).then(() => {
          setLoading(true);

          TRANSPORT.API.post(`/v${item.version}/loan/cancel`, {
            id: item.id,
          })
            .catch(axiosError => {
              const error = axiosError?.response?.data;

              handleGeneralErrorTranslated(error);

              throw axiosError;
            })
            .then(() => {
              onCancelled?.();
            })
            .finally(() => {
              setLoading(false);
            });
        });
        return;
      }

      SHARED_ROUTER_SERVICE.navigate('LoanPay', {
        id: item.id,
      });
    }, [item.version, item.id, item.status, onCancelled]);

    return (
      <Button
        testID={
          item.isOpening ? `LOAN_ITEM_CANCEL_BUTTON` : 'LOAN_ITEM_REPAY_BUTTON'
        }
        disabled={!item.isPendingOrOpen}
        loading={loading}
        onPress={handlePress}
        {...rest}
      >
        {t(getStatusLabel(item.status))}
      </Button>
    );
  }
);
