import * as React from 'react';
import {type LayoutChangeEvent} from 'react-native';
import {Icon} from '@youtoken/ui.icons';
import {Box, Text} from '@youtoken/ui.primitives';
import {
  getGhostTabTextColorVariant,
  getUnderlayTextColorVariant,
} from './utils';
import {type GhostTabProps, type UnderlayProps} from './types';

const GhostTab: React.FC<GhostTabProps> = ({
  tab,
  totalTabsCount,
  disabled = false,
  size = 'small',
  type = 'default',
}) => {
  const color = getGhostTabTextColorVariant({type, size, disabled});

  return (
    <Box
      px={4}
      flexGrow={1}
      flexBasis={`${100 / totalTabsCount}%`}
      alignItems="center"
      justifyContent="center"
      accessible={false}
    >
      {tab.type === 'text' && (
        <Text
          accessible={false}
          color={color}
          variant={
            type === 'ghost' && size === 'tiny'
              ? '$body-02-medium-accent'
              : '$body-01'
          }
          ellipsizeMode="tail"
          numberOfLines={1}
        >
          {tab.label}
        </Text>
      )}
      {tab.type === 'icon' && <Icon name={tab.icon} color={color} />}
    </Box>
  );
};

export const Underlay: React.FC<UnderlayProps> = ({
  activeIndex,
  tabs,
  disabled = false,
  size = 'small',
  type = 'default',
}) => {
  const [width, setWidth] = React.useState(0);
  const color = getUnderlayTextColorVariant({type, size, disabled});

  const handleLayout = React.useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);

  const underlayStyles = React.useMemo(() => {
    return {
      transform: [
        {
          translateX: width * activeIndex,
        },
      ],
    };
  }, [activeIndex, width]);

  const ghostTextStyle = React.useMemo(() => {
    return {
      transform: [
        {
          translateX: -1 * width * activeIndex,
        },
      ],
    };
  }, [activeIndex, width]);

  return (
    <Box
      position="absolute"
      height="100%"
      width="100%"
      pointerEvents="none"
      accessible={false}
    >
      <Box
        onLayout={handleLayout}
        height="100%"
        backgroundColor={color}
        width={`${100 / tabs.length}%`}
        borderRadius={6}
        style={underlayStyles}
        overflow="hidden"
        accessible={false}
      >
        <Box
          flexDirection="row"
          width={`${100 * tabs.length}%`}
          height="100%"
          style={ghostTextStyle}
          accessible={false}
        >
          {tabs.map((tab, index) => {
            return (
              <GhostTab
                key={index}
                tab={tab}
                totalTabsCount={tabs.length}
                disabled={disabled}
                size={size}
                type={type}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
