import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';

export class InitialPriceLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (!this.chart.initialPrice) {
      return undefined;
    }

    return this.chart.layout.getLabelLayout(
      this.chart.initialPrice,
      this.chart.formatNumber(this.chart.initialPrice),
      'left',
      this.chart.direction
    );
  }
}
