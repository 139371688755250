import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Text} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {DepositNowFeature} from '../../../state';
import {BenefitItem} from '../../BenefitItem';

export const DepositNowInfoSavings: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {aprBenefitsFormatted} = DepositNowFeature.use({});

  return (
    <BenefitItem
      testID="DEPOSIT_NOW_INFO_SAVINGS"
      iconName="interest"
      iconColor="$interactive-01"
      iconColorBackground="$interactive-02"
      {...boxProps}
    >
      <Trans
        t={t}
        components={{
          Strong: <Text color="$text-01" />,
        }}
        values={{percent: aprBenefitsFormatted}}
        i18nKey="surface.wallets.deposit_now.benefits.apr"
      />
    </BenefitItem>
  );
});
