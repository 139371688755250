import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

export const ArrConvertImage = React.memo(
  ({color = '#212121', ...props}: SvgProps) => {
    return (
      <Svg height="1em" viewBox="0 0 7 15" width="1em" {...props}>
        <G
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          strokeOpacity={0.4}
        >
          <Path d="M5.5 12.5l-2 2-2-2" strokeLinejoin="round" />
          <Path d="M3.5 12.5v-13" strokeDasharray="2 3" />
        </G>
      </Svg>
    );
  }
);
