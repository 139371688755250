import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {NotificationsResource} from '@youtoken/ui.resource-notifications';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {NotificationAnimated} from '../../components';

export const NotificationsWidget: React.FC<BoxProps> = cell(
  boxProps => {
    const {
      notifications: {topBar, markAsShowed, close},
    } = useResources({
      lokalise: getResourceDescriptor(LocaliseResource, {
        namespace: LocaliseResourceNamespace.NOTIFICATION,
      }),
      notifications: getResourceDescriptor(NotificationsResource, {}),
    });

    if (!topBar.length) {
      return null;
    }

    return (
      <Box testID="NOTIFICATIONS_WIDGET" {...boxProps}>
        {topBar.map((notification, index) => {
          return (
            <NotificationAnimated
              key={notification.notificationId}
              notification={notification}
              onShow={markAsShowed}
              onClose={close}
              mt={index > 0 ? 8 : 0}
            />
          );
        })}
      </Box>
    );
  },
  {
    CellWrap: ({children}) => {
      return <Box>{children}</Box>;
    },
    SuspenseFallback: () => {
      return null;
    },
  }
);
