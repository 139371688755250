import * as React from 'react';
import {Text, Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation, type TKey} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Icon} from '@youtoken/ui.icons';
import {Button, type IButtonProps} from '@youtoken/ui.buttons';
import {Dropdown} from '@web-app/src/components/elements';
import {MenuItems} from '@web-app/src/components/layout/Shell/InnerPageShell/MenuItems';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {observer} from 'mobx-react';

const menuItems = {
  wallets: {icon: 'wallets', title: 'surface.menu.wallets', route: '/wallets'},
  loans: {icon: 'get_loan', title: 'surface.menu.loans', route: '/loans'},
  multihodl: {
    icon: 'hodl',
    title: 'surface.menu.multihodl',
    route: '/multihodl',
  },
  miner: {
    icon: 'mining',
    // TODO add i18n
    title: 'surface.menu.cloud_miner',
    route: '/miner',
  },
  turbocharge: {
    icon: 'turbo',
    title: 'surface.menu.turbos',
    route: '/turbocharge',
  },
  'account-stats': {
    icon: 'history',
    title: 'surface.menu.history_short',
    route: '/account-stats',
  },
  profile: {icon: 'profile', title: 'surface.menu.profile', route: '/profile'},
};

export const MenuButton: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {pathname} = RouterResource.use({});

  // const dropDownProps = getDropdownPropsByWebApp();

  const module = React.useMemo(() => {
    return Object.keys(menuItems)
      .map(key => menuItems[key as keyof typeof menuItems])
      .find(module => {
        return pathname.startsWith(module.route);
      });
  }, [pathname]);

  const {forceIdentify} = AuthMeResource.use({});

  if (!module || forceIdentify) {
    return null;
  }

  return (
    <Box {...props}>
      <Dropdown
        renderTrigger={({toggle, isOpen}) => (
          <Button
            icon={module.icon as IButtonProps['icon']}
            onPress={toggle}
            type={isOpen ? 'primary' : 'secondary'}
            size="small"
            ml={10}
          >
            <Box flexDirection="row" alignItems="center">
              <Text
                variant="$body-02-medium-accent"
                color={isOpen ? '$text-04' : '$interactive-01'}
                mr={8}
              >
                {t(module.title as TKey)}
              </Text>

              {isOpen ? (
                <Icon name="collapse" color="$text-04" />
              ) : (
                <Icon name="expand" color="$text-05" />
              )}
            </Box>
          </Button>
        )}
        renderItems={({toggle}) => <MenuItems toggle={toggle} />}
        // {...dropDownProps}
      />
    </Box>
  );
});
