import {ValidationMessageLocalized} from './ValidationMessageLocalized';

export const extractErrorFromResponse = (response: any, fieldName: string) => {
  if (!response?.[fieldName] && !response?.i18n?.[fieldName]) {
    return '';
  }

  return new ValidationMessageLocalized(
    response?.[fieldName],
    response?.i18n?.[fieldName]
  );
};
