import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const PendingContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.8 2.6a.6.6 0 00.6-.6.6.6 0 00.6.6.6.6 0 00-.6.6.6.6 0 00-.6-.6zM16 2.2a1.4 1.4 0 01-1.4 1.4A1.4 1.4 0 0116 5a1.4 1.4 0 011.4-1.4A1.4 1.4 0 0116 2.2zM1.4 9.6A1.4 1.4 0 010 11a1.4 1.4 0 011.4 1.4A1.4 1.4 0 012.8 11a1.4 1.4 0 01-1.4-1.4zM17 15.2a1 1 0 01-1 1 1 1 0 011 1 1 1 0 011-1 1 1 0 01-1-1zm-.6-5.2a6.4 6.4 0 11-12.8 0 6.4 6.4 0 0112.8 0zM9.742 2.604a7.4 7.4 0 013.151.585l.078-.184a7.6 7.6 0 00-10.377 5.29l.194.046a7.4 7.4 0 016.954-5.736zm7.629 6.74a7.4 7.4 0 00-.815-2.776l.177-.093a7.598 7.598 0 01-6.087 11.098 7.6 7.6 0 01-7.404-4.097l.177-.091a7.4 7.4 0 0013.952-4.04zM7.75 7.35h4.5v.5h-.5v.467c0 .178 0 .313-.034.442a.968.968 0 01-.148.315 1.7 1.7 0 01-.318.31l-.86.716.86.717c.138.114.241.2.318.309a1 1 0 01.148.316c.034.128.034.263.034.441v.467h.5v.5h-4.5v-.5h.5v-.467c0-.178 0-.313.034-.441.03-.114.08-.22.148-.316.077-.109.18-.195.318-.31l.86-.716-.86-.717a1.7 1.7 0 01-.318-.309 1 1 0 01-.148-.315c-.034-.129-.034-.264-.034-.442V7.85h-.5v-.5zm1 .5v.438c0 .22.002.284.017.34a.5.5 0 00.074.158c.034.048.082.09.25.232l.909.757.908-.757c.17-.141.217-.184.25-.232a.5.5 0 00.075-.157c.015-.057.017-.12.017-.341V7.85h-2.5zM10 10.425l-.908.757a1.787 1.787 0 00-.24.218h2.297a1.79 1.79 0 00-.24-.218L10 10.425z"
          fill={color}
        />
      </Svg>
    );
  }
);
