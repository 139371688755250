import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  type LoanRepayFeatureArgs,
  LoanRepayOwnFundsForm,
} from '@youtoken/ui.surface-loans';
import {Modal} from '../_common';

interface ModalLoanPayProps extends React.ComponentProps<typeof Modal> {
  loan: LoanRepayFeatureArgs;
}

export const ModalLoanPay: React.FC<ModalLoanPayProps> = cell(
  ({loan: {mode, id}, ...props}) => {
    return (
      <Modal minHeight={350} {...props}>
        <LoanRepayOwnFundsForm mode={mode} id={id} />
      </Modal>
    );
  }
);
