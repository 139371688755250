import {orderBy, uniq} from 'lodash';
import {warning} from '@youtoken/ui.utils';
import {getCoinOrderIndex, hasCoinOrderIndex} from '@youtoken/ui.coin-utils';
import {TariffLoansRegular} from './TariffsLoansRegular';
import {assign} from 'lodash';

export const filterAndSortTerms = (terms: TariffLoansRegular[]) => {
  return orderBy(
    terms.reduce((acc, term) => {
      const validCollateralTicker = hasCoinOrderIndex(term.collateralTicker);
      const validLTV = !!(term.ltv && term.ltv.gt(0));

      warning(
        validCollateralTicker,
        `TariffsLoansRegularResource: Term has invalid collateralTicker`,
        {
          id: term.id,
          collateralTicker: term.collateralTicker,
        }
      );
      warning(validLTV, `TariffsLoansRegularResource: Term has invalid ltv`, {
        id: term.id,
        ltv: term.ltv && term.ltv.toString(),
      });

      if (validCollateralTicker && validLTV) {
        const termSettingsFiltered = orderBy(
          term.settings.filter(({borrowedTicker, minTP, maxTP}) => {
            const validBorrowedTicker =
              borrowedTicker && hasCoinOrderIndex(borrowedTicker);

            let validFTPSettings = true;
            if (minTP || maxTP) {
              const validminTP = !!(minTP && minTP.gt(0));
              const validmaxTP = !!(maxTP && maxTP.gt(0));
              validFTPSettings = validminTP && validmaxTP;
            }

            warning(
              validBorrowedTicker,
              `TariffsLoansRegularResource: Term has invalid borrowedTicker`,
              {
                id: term.id,
                borrowedTicker,
              }
            );
            warning(
              validFTPSettings,
              `TariffsLoansRegularResource: Term has invalid FTP`,
              {
                id: term.id,
              }
            );

            return validBorrowedTicker && validFTPSettings;
          }),
          [setting => getCoinOrderIndex(setting.borrowedTicker)]
        );

        if (termSettingsFiltered.length) {
          acc.push(assign(term, {settings: termSettingsFiltered}));
        }
      }

      return acc;
    }, [] as typeof terms),
    [
      term => {
        return getCoinOrderIndex(term.collateralTicker!);
      },
      term => term.type === 'VIP',
      term => {
        return term.ltv;
      },
    ],
    ['asc', 'desc', 'desc']
  );
};

export const getMarketsByTerms = (terms: TariffLoansRegular[]) => {
  return terms.reduce((acc, {collateralTicker, settings}) => {
    const tickers = settings.map(setting => setting.borrowedTicker);

    acc[collateralTicker] = uniq([
      ...(acc[collateralTicker] || []),
      ...tickers,
    ]);

    return acc;
  }, {} as {[key: string]: string[]});
};
