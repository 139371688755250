import {observable, action, runInAction} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {extractErrorFromResponse} from '@youtoken/ui.validation-messages';

export class Calculated {
  @observable
  public form: any;

  @observable
  public loading: boolean = false;

  @observable
  public ready: boolean = false;

  @observable
  public data: any = undefined;

  @action
  public load = () => {
    this.loading = true;
    this.ready = false;

    return TRANSPORT.API.post(
      `/v${this.form.loan.version}/loan/decrease/calculate`,
      {
        id: this.form.loanId,
        amount: Number(this.form.amount),
      }
    )
      .then(response => {
        runInAction(() => {
          this.data = {
            ...response.data,
            ticker: this.form.ticker.ticker,
            conversionTicker: this.form.conversionTicker,
            amount: this.form.amount,
          };
          this.loading = false;
          this.ready = true;

          this.form.instance.$('amount').invalidate();
        });
      })
      .catch(e => {
        const amountError = extractErrorFromResponse(
          e?.response?.data,
          'amount'
        );
        this.form.instance.$('amount').invalidate(amountError);
        this.loading = false;
      });
  };

  public constructor(form: any) {
    this.form = form;
  }
}
