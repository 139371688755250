import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export const SignOutItem: React.FC<BoxProps & {onSignOut?: () => void}> =
  observer(({onSignOut, ...boxProps}) => {
    const {t} = useTranslation();
    const {signOut} = AuthMeResource.use({});

    const handleSignOut = React.useCallback(() => {
      onSignOut?.();
      signOut();
    }, [signOut, onSignOut]);

    return (
      <Box
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={88}
        backgroundColor="$ui-overflow"
        {...boxProps}
      >
        <Link onPress={handleSignOut} textAlign="center">
          {t('surface.menu.sign_out')}
        </Link>
      </Box>
    );
  });
