import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RouterResource} from '../../../../../routes/RouterResource';
import {VerificationSteps} from './VerificationSteps';

export const VerificationStepsPageContent = cell(() => {
  const {t} = useTranslation();

  const {goBack} = RouterResource.use({});

  return (
    <Box px={{default: 20, tablet: 0}}>
      <Box flexDirection="row" justifyContent="flex-start" mb={23}>
        <Button type="ghost" onPress={goBack} ml={-5}>
          <Box flexDirection="row" alignItems="center">
            <Icon name="chevron_left" color="$text-05" />
            <Text color="$interactive-01" variant="$body-01-medium-accent">
              {t('surface.profile.verification_steps.back')}
            </Text>
          </Box>
        </Button>
      </Box>
      <VerificationSteps />
    </Box>
  );
});
