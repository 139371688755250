import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Modal} from '@youtoken/ui.modal';
import {useTranslation, type TKey} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';
import {
  WalletsChooseTicker,
  type WalletsChooseTickerType,
} from '../../components';
import {AddWalletsItemWrapper} from './AddWalletsItemWrapper';

type AddWalletsModalProps = {
  isOpen: boolean;
  initialFilter?: WalletsChooseTickerType;
  onClose: () => void;
};

const i18nKeys: {[key in WalletsChooseTickerType]?: TKey} = {
  fiat: 'surface.wallets.add_wallets_by_category.title.fiat',
  stable: 'surface.wallets.add_wallets_by_category.title.stable',
  crypto: 'surface.wallets.add_wallets_by_category.title.crypto',
};

export const AddWalletsModal: React.FC<AddWalletsModalProps> = observer(
  ({isOpen, initialFilter, onClose}) => {
    invariant(
      initialFilter,
      'initialFilter is required for add wallet modal',
      {},
      {initialFilter}
    );

    const {t} = useTranslation();

    const title = Platform.select({
      default: undefined,
      web:
        initialFilter && ['fiat', 'stable', 'crypto'].includes(initialFilter)
          ? t(i18nKeys[initialFilter]!)
          : t('surface.wallets.add_wallets_default.title'),
    });

    const mt = Platform.select({
      default: 24,
      web: 16,
    });

    // on native, scroll is handled by BottomSheetFlatList
    const scrollable = Platform.select({
      default: false,
      web: true,
    });

    return (
      <Modal
        testID="ADD_WALLETS_MODAL"
        webHeight={700}
        shouldBeScrollable={scrollable}
        title={title}
        snapPoints={['90%']}
        pb={0}
        webOverlayBackgroundColor="$transparent"
        isOpen={isOpen}
        onClose={onClose}
      >
        <WalletsChooseTicker
          type={initialFilter}
          method="addWallet"
          ListItemWrapper={AddWalletsItemWrapper}
          mt={mt}
        />
      </Modal>
    );
  }
);
