import * as React from 'react';
import {Badge, type BadgeVariant} from '@youtoken/ui.elements';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {type BoxProps} from '@youtoken/ui.primitives';

type Props = Pick<HistoryItemData, 'statusLabel' | 'badgeVariant'> & BoxProps;

export const StatusBadge: React.FC<Props> = ({
  statusLabel,
  badgeVariant,
  ...boxProps
}) => {
  return (
    <Badge
      type="primary"
      size="tiny"
      variant={badgeVariant as BadgeVariant}
      {...boxProps}
    >
      {statusLabel}
    </Badge>
  );
};
