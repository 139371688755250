import {ScopeContext} from '@sentry/types';
import {WithCustomErrorProps} from './CustomError';
import {ERROR_TYPE} from './ERROR_TYPE';

export class WarningError extends Error implements WithCustomErrorProps {
  __isCustomError!: boolean;
  __type!: ERROR_TYPE.WARNING;
  __sentry!: Partial<ScopeContext>;

  constructor(
    message: string,
    extra?: Record<string, any>,
    tags?: Record<string, any>
  ) {
    super(message);
    Object.setPrototypeOf(this, WarningError.prototype); // restore prototype chain
    this.__isCustomError = true;
    this.__type = ERROR_TYPE.WARNING;
    this.name = 'Warning';
    this.__sentry = {
      extra,
      tags,
      level: 'warning',
      fingerprint: ['warning', message],
    };
  }
}
