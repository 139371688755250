import * as React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const SearchLeftPart: React.FC<BoxProps> = () => {
  return (
    <Box flexDirection="row" alignItems="center" height="100%" px={16}>
      <Icon name="search" size={20} color="$text-01" />
    </Box>
  );
};
