import {DATA_STORAGE} from '../DataStorage';
import {PickResourceResponseType} from '../PickResourceResponseType';
import {
  createResourceErrorFromGenericError,
  ResourceAggregateError,
  ResourceError,
} from '@youtoken/ui.errors';
import {Resource, getResourceDescriptor} from '../getResourceDescriptor';
import {useCallback} from 'react';
import {InteractionManager} from 'react-native';

let REFRESH_ON_VISIBLE_ENABLED = false;
export const setupRefreshOnVisible = (
  next: typeof REFRESH_ON_VISIBLE_ENABLED
) => {
  REFRESH_ON_VISIBLE_ENABLED = next;
};

let useOnVisible = (_effect: () => void) => {};
export const setupUseOnVisible = (nextHook: typeof useOnVisible) => {
  useOnVisible = nextHook;
};

let getNavString = () => '';
export const setupGetNavString = (nextFn: typeof getNavString) => {
  getNavString = nextFn;
};

export function useResources<R extends {[key: string]: any}>(resources: R) {
  const pendingResources: any[] = [];

  Object.keys(resources).forEach(resourceLocalKey => {
    const [ResourceClass, resourceArgs] = resources[resourceLocalKey];

    const resourceKey = ResourceClass.getKey(resourceArgs);

    if (!DATA_STORAGE.has(resourceKey)) {
      DATA_STORAGE.set(
        resourceKey,
        ResourceClass.create(resourceArgs)
          .then((data: any) => {
            DATA_STORAGE.set(
              resourceKey,
              new ResourceClass(resourceArgs, data)
            );
            DATA_STORAGE.get(resourceKey).__nav = getNavString();
          })
          .catch((error: Error) => {
            // @ts-ignore, reason - we need to set custom flag for error;
            error.error = true;

            DATA_STORAGE.set(resourceKey, error);

            throw error;
          })
      );
      DATA_STORAGE.get(resourceKey).promise = true; // todo check this
      DATA_STORAGE.get(resourceKey).__nav = getNavString();
    }

    if (DATA_STORAGE.get(resourceKey).promise) {
      pendingResources.push(DATA_STORAGE.get(resourceKey));
    }
  });

  // errors to throw
  let errors: Array<ResourceError> = [];

  Object.keys(resources).forEach(resourceLocalKey => {
    const [ResourceClass, resourceArgs] = resources[resourceLocalKey];
    const resourceKey = ResourceClass.getKey(resourceArgs);

    if (DATA_STORAGE.get(resourceKey).error) {
      // pushing new ResourceError from existing error (probably HTTP error);
      // this will preserve error to report while disposing of original error from cache;
      errors.push(
        createResourceErrorFromGenericError(
          DATA_STORAGE.get(resourceKey),
          resourceKey
        )
      );

      // clean up all errors
      setTimeout(() => {
        // if the resource was deleted programmatically, we need to check the error before deleting
        if (DATA_STORAGE.get(resourceKey)?.error) {
          DATA_STORAGE.delete(resourceKey);
        }
      }, 3000); // Allow resource update after error, after 3 second
    }
  });

  // throwing new AggregateError if errors > 0
  if (errors.length > 0) {
    throw new ResourceAggregateError(errors);
  }

  if (pendingResources.length > 0) {
    throw Promise.all(pendingResources);
  }

  const handler = useCallback(() => {
    if (!REFRESH_ON_VISIBLE_ENABLED) {
      return;
    }

    const dsMap = Object.keys(resources).map(resourceLocalKey => {
      const [ResourceClass, resourceArgs] = resources[resourceLocalKey];

      return ResourceClass.getKey(resourceArgs);
    });

    dsMap.forEach(resourceKey => {
      // Без этого в сафари ошибка
      // На sign in в инкогнито в сафари
      if (!DATA_STORAGE.has(resourceKey)) {
        return;
      }

      if (DATA_STORAGE.get(resourceKey).__nav !== getNavString()) {
        DATA_STORAGE.get(resourceKey).__nav = getNavString();

        // if we could skip it we will
        if (DATA_STORAGE.get(resourceKey).skipRefreshOnVisible) {
          return;
        }

        setTimeout(() => {
          if (DATA_STORAGE.has(resourceKey)) {
            // if resource is loading now skipping refresh
            if (DATA_STORAGE.get(resourceKey).isLoading) {
              return;
            }

            // if resource is already has pending refetch
            if (DATA_STORAGE.get(resourceKey).__pendingRefetch) {
              return;
            }

            // creating pending refetch with `InteractionManager`;
            DATA_STORAGE.get(resourceKey).__pendingRefetch =
              InteractionManager.runAfterInteractions(() => {
                // this may be error or promise! we need to check if method is available;
                return DATA_STORAGE.get(resourceKey)?.refetchSilently?.();
              })
                .then(() => {
                  // we need to check if resource is still here, this is a delayed action;
                  if (DATA_STORAGE.get(resourceKey)) {
                    DATA_STORAGE.get(resourceKey).__pendingRefetch = null;
                  }
                })
                .catch(() => {
                  if (DATA_STORAGE.get(resourceKey)) {
                    DATA_STORAGE.get(resourceKey).__pendingRefetch = null;
                  }
                });
          }
        }, 0);
      }
    });

    return () => {
      // если не использовать setTimeout, то на unmount/blur хеш навигации(window.location.href) все еще будет старым, поэтому зачистка не сработает
      // выглядит как хак, какие есть еще варианты?
      //
      // зачем нужна зачистка nav?
      // допустим есть две страницы, с использованием useResource(A) и без использования(B)
      // зайдя на A мы запишем текущий урл(хеш навигации, допустим hash1) в nav
      // переходя на B не будет вызван useResources(или ресурсы на B будут другими) тогда nav никогда не поменяется
      // переходя с B на A мы проверим nav всех ресурсов, которые юзаются на странице A - если nav и window.location.href не совпадают, мы запустим обновление.
      // но так как на B не было вызовов ресурсов то nav останется прежним

      // как вариант заюзать setInterval, в функции получать текущий стейт навигации
      // и, например, каждую секунду очищать nav если он не совпадает с текущим(=ресурс не используется)
      // да, так должно быть лучше
      setTimeout(() => {
        dsMap.forEach(resourceKey => {
          // После sign out dataStorage очищается, и ресурса может не быть
          if (!DATA_STORAGE.has(resourceKey)) {
            return;
          }

          if (
            DATA_STORAGE.get(resourceKey).__nav &&
            DATA_STORAGE.get(resourceKey).__nav !== getNavString()
          ) {
            DATA_STORAGE.get(resourceKey).__nav = null;
          }
        });
      }, 100); // 100ms delay to check next usage
    };
  }, [resources]);

  // сейчас обновление вызывается только если будет onmount или focus
  // но может быть кейс, когда onmount не будет, например, в будущем рейты могут не рендерится из-за смены урла
  // тогда нам нужно понять что сменился урл и запустить обновление
  // но при этом не запускать обновление в нейтиве, т.к. там все компоненты смонтированы и живут в фоне
  // нужен аналог useFocusEffect на вебе

  useOnVisible(handler);

  // может ли быть такое что навигация поменялась
  // но компонент не должен рендериться, но все еще рендерится?
  // кажется в новом реакте есть что-то типа транзишенов между стейтами/суспензами
  // когда старый суспенз рендерится, а новый подгружается

  return Object.keys(resources).reduce((acc, resourceLocalKey) => {
    const [ResourceClass, resourceArgs] = resources[resourceLocalKey];
    const resourceKey = ResourceClass.getKey(resourceArgs);

    acc[resourceLocalKey as keyof R] = DATA_STORAGE.get(resourceKey);

    return acc;
  }, {} as PickResourceResponseType<R>);
}

// same as useResources (obviously) but for one resource;
export function useResource<R extends Resource, Args extends {}>(
  resource: R,
  args: Args
) {
  const {_resource} = useResources({
    _resource: getResourceDescriptor(resource, args),
  });

  return _resource;
}
