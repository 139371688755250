import {
  AppEnv,
  getAppEnvForWeb,
  getWebAppEnvFromHost,
} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';

export const initEnvironmentService = () => {
  ENVIRONMENT.APP_ENV = getAppEnvForWeb();
  ENVIRONMENT.DEV = ENVIRONMENT.APP_ENV !== AppEnv.production;
  ENVIRONMENT.WEB_APP_ENV = getWebAppEnvFromHost();
};
