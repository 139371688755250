import {reaction} from 'mobx';
import {i18n, getOrDetectLocalLanguage} from '@youtoken/ui.service-i18n';
import initApp from './client';
import {initSupportService} from './services/support';
import '../src/services/notifications';

initSupportService();

// Our main entry point into the app
// setups react rendrer and store rehydration
initApp();

// @ts-ignore
const __version_bump__ = 1;

// TODO: remove bind??
// @ts-ignore
window._changeLanguage = i18n._changeLanguage.bind(i18n);
