import * as React from 'react';
import {observer} from 'mobx-react';
import {
  HODLChartSmartCell,
  HODLClosedChartSmartCell,
  PanResponder,
  ResizableBoxWithOverlay,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';
import {ScreenNameMH} from '@youtoken/ui.trading-view-charts';
import type {HODLItem} from '@youtoken/ui.resource-hodl';

interface ChartFullViewSectionProps {
  hodl: HODLItem;
  onPressTradingViewModeButton?: () => void;
}

export const ChartFullViewSection: React.FC<ChartFullViewSectionProps> =
  observer(({hodl: item, onPressTradingViewModeButton}) => {
    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <ResizableBoxWithOverlay mt={15} height={485} width="100%" {...bind}>
        {/*NOTE: without converting boolean first, "0" is renders  */}
        {Boolean(width && height) && (
          <>
            {item.isClosedLike && (
              <HODLClosedChartSmartCell
                width={width!}
                height={height!}
                product="hodl"
                fromTicker={item.baseTicker}
                toTicker={item.quoteTicker}
                precision={item.precision}
                initialPrice={item.initialPrice}
                triggerPrice={item.triggerPrice}
                pending={item.isPendingLike}
                closedPrice={item.closedPrice}
                startDate={item.startDate}
                endDate={item.endDate}
                ChartPanComponent={PanResponder}
                shouldDisplayControl
                defaultTick="5m"
                defaultType="candle"
              />
            )}

            {!item.isClosedLike && (
              <HODLChartSmartCell
                width={width!}
                height={height!}
                product="hodl"
                fromTicker={item.baseTicker}
                toTicker={item.quoteTicker}
                precision={item.precision}
                initialPrice={item.initialPrice}
                triggerPrice={item.triggerPrice}
                closedPrice={item.closedPrice}
                marginCallType={item.marginCallType}
                marginCall={item.marginCall}
                takeProfit={item.takeProfit}
                maxLoss={item.data.maxLoss}
                maxProfit={item.data.maxProfit}
                startDate={item.startDate}
                endDate={item.endDate}
                reversed={item.isChartReversed}
                pending={item.isPendingLike}
                direction={item.chartDirection}
                isOpen={item.isOpen}
                isClosedLike={item.isClosedLike}
                isOpeningLike={item.isOpening}
                ChartPanComponent={PanResponder}
                defaultTick="5m"
                defaultType="candle"
                onPressTradingViewModeButton={onPressTradingViewModeButton}
                screenName={ScreenNameMH.DETAILS}
              />
            )}
          </>
        )}
      </ResizableBoxWithOverlay>
    );
  });
