import {Products} from './Products';

export enum KYCStatus {
  SEND = 'SEND',
  ACCEPT = 'ACCEPT',
  REPEATED = 'REPEATED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  IN_PROGRESS = 'IN_PROGRESS',
  ADDITIONAL_DOCS_REQUIRED = 'ADDITIONAL_DOCS_REQUIRED',
  DENY = 'DENY',
  NOT_SET = 'NOT_SET',
  KYC_RESTRICTION = 'KYC_RESTRICTION',
}

export enum AddressStatus {
  SEND = 'SEND',
  ACCEPT = 'ACCEPT',
  REPEATED = 'REPEATED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  IN_PROGRESS = 'IN_PROGRESS',
  ADDITIONAL_DOCS_REQUIRED = 'ADDITIONAL_DOCS_REQUIRED',
  DENY = 'DENY',
  NOT_SET = 'NOT_SET',
  KYC_RESTRICTION = 'KYC_RESTRICTION',
}

export type currencyName = 'usd' | 'eur' | 'btc';
export type currencyNameMap = {[currency in currencyName]: string};

export const currencySignMap: currencyNameMap = {
  usd: '$',
  eur: '€',
  btc: '₿',
};

export enum LegalEntity {
  CHE = 'CHE',
  ITA = 'ITA',
  ALL = 'ALL',
}

export type ChallengeBase = {
  type: 'emailConfirmation' | 'disclosuresSignature';
};

export type EmailChallenge = {
  operationId: string;
  operationType: 'confirm-email';
} & ChallengeBase;

export type DisclosureChallenge = {} & ChallengeBase;

export type Challenges = Array<EmailChallenge | DisclosureChallenge>;

export enum SavingsAndRewardsType {
  SAVINGS = 'SAVINGS',
  REWARDS = 'REWARDS',
  SAVINGS_AND_REWARDS = 'SAVINGS_AND_REWARDS',
}

export enum Requirements {
  IDENTITY = 'identity',
  FORM_A = 'formA',
  ADDRESS = 'address',
  AGREEMENT = 'agreement',
  SUPPORT = 'support',
}

export type ProductName = keyof Omit<Products, 'kycAccepted'>;

export type AlertsMethods = 'email' | 'sms' | 'push';
