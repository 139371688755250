import * as React from 'react';
import {TFAFormField, useForm} from '@youtoken/ui.form-elements';
import {observer} from 'mobx-react';
import {CryptoWithdrawalFormState} from '../../types';

export const CryptoWithdrawalConfirmCode: React.FC = observer(() => {
  const {
    form: {
      twoFactorAuthOperationCodeError,
      twoFactorAuthOperationCodeOnChange,
      createTwoFactorAuthOperation,
    },
  } = useForm<CryptoWithdrawalFormState>();

  return (
    <TFAFormField
      error={twoFactorAuthOperationCodeError}
      getOperationData={createTwoFactorAuthOperation}
      onChangeCode={twoFactorAuthOperationCodeOnChange}
    />
  );
});
