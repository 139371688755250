import {i18n} from '@youtoken/ui.service-i18n';

export const getCardDepositWizardTitle = (ticker: string) => {
  return i18n.t('surface.wallets.fiat_deposit_card.title', {
    ticker: ticker.toUpperCase(),
  });
};

export const getCardDepositWizardOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {ticker: string};
  };
}) => {
  return {
    title: getCardDepositWizardTitle(ticker),
  };
};
