import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {UmaInfoFeature} from '../../feature';

export const UmaInfo: React.FC = observer(() => {
  const {t} = useTranslation();
  const {uma} = UmaInfoFeature.use({});

  return (
    <Box p={24} alignItems="flex-start" testID="UMA_SETTINGS_ADDRESS_INFO">
      <Text color="$text-02" variant="$body-02" mb={4}>
        {t('surface.wallets.uma.edit.current_uma.label')}
      </Text>
      <Text textAlign="center">${uma}</Text>
    </Box>
  );
});
