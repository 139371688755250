import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {AdvCashCreatePaymentFormState} from '../state';

export const RepayInfo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    view: {tickerFormatted, amountFormatted},
  } = useForm<AdvCashCreatePaymentFormState>();

  return (
    <Box {...boxProps}>
      <Text variant="$body-01" color="$text-02" mb={2}>
        <Trans
          components={{
            Strong: <Text variant="$heading-01" color="$text-01" />,
          }}
          values={{
            ticker: tickerFormatted,
            amount: amountFormatted,
          }}
          i18nKey="surface.wallets.fiat_deposit.adv_cash.to_pay"
        />
      </Text>
      <Text variant="$body-02" color="$text-02">
        {t('surface.wallets.fiat_deposit.adv_cash.fee')}
      </Text>
    </Box>
  );
});
