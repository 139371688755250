import * as React from 'react';
import {observer} from 'mobx-react';
import {useIsMobile, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {MenuItemBase} from './MenuItemBase';

export const Language: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const Selector = (
    <LanguageSwitch
      size={isMobile ? 'small' : 'medium'}
      testID="LANGUAGE_SELECT"
      width="100%"
      boxProps={{width: {default: 120, tablet: 140}}}
    />
  );

  return (
    <MenuItemBase
      icon="blockexplorer"
      title={t('surface.profile.subtitle.language')}
      RightPartComponentWeb={Selector}
      RightPartComponentNative={Selector}
      {...boxProps}
    />
  );
});
