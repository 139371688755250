import * as React from 'react';
import {Box, Heading} from '@youtoken/ui.primitives';

export const LoansListHeader: React.FC = ({children}) => {
  return (
    <Box px={{desktop: 0, default: 20}} py={20}>
      <Heading variant="$heading-02-responsive" color="$text-01">
        {children}
      </Heading>
    </Box>
  );
};
