import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';

export interface LoanItemHeaderStatusOverdraftAmountProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeaderStatusOverdraftAmount: React.FC<LoanItemHeaderStatusOverdraftAmountProps> =
  observer(({item}) => {
    return (
      <Text
        testID="LOAN_ITEM_STATUS_OVERDRAFT_AMOUNT"
        variant="$body-01"
        color="$text-02"
      >
        <Text variant="$body-01-high-accent" color="$text-01">
          {item.overdraftAmountFormatted}
        </Text>{' '}
        {item.borrowedTickerFormatted}
      </Text>
    );
  });
