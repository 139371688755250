import {observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Form} from './Form';
import type {
  FeatureSignUpAdditionalFormArgs,
  FeatureSignUpAdditionalFormResources,
} from './types';

export class FeatureSignUpAdditionalForm extends createFeature({
  getKey: (args: FeatureSignUpAdditionalFormArgs) =>
    `FeatureSignUpAdditionalForm`,
  getResources: (args: FeatureSignUpAdditionalFormArgs) => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  constructor(
    args: FeatureSignUpAdditionalFormArgs,
    resources: FeatureSignUpAdditionalFormResources
  ) {
    super(args, resources);

    this.form = new Form(args, resources);
  }
}
