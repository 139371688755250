import {useTheme} from '@youtoken/ui.primitives';
import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

export const InterfaceOverview: React.FC<{}> = () => {
  const {colors} = useTheme();

  return (
    <Svg fill="none" viewBox="0 0 52 48" width={78} height={72}>
      <Path
        fill={colors['$ui-background']}
        d="M52 42V6a6 6 0 0 0-6-6H6a6 6 0 0 0-6 6v36a6 6 0 0 0 6 6h40a6 6 0 0 0 6-6Z"
      />
      <Path
        stroke={colors['$fill-01']}
        strokeLinecap="round"
        strokeWidth={2}
        d="M33 32h14"
      />
      <Path
        stroke={colors['$interactive-01']}
        strokeLinecap="round"
        strokeWidth={2}
        d="M5 32h25"
      />
      <Path
        fill={colors['$interactive-01']}
        d="M34 32a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        d="M5.5 13v12"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5.5 16v7"
      />
      <Path
        stroke={colors['$danger-01']}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10.5 19v5M20.5 15v6"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15.5 13v5"
      />
      <Path
        stroke={colors['$danger-01']}
        strokeLinejoin="round"
        d="M10.5 17v10"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        d="M15.5 11v8"
      />
      <Path
        stroke={colors['$danger-01']}
        strokeLinejoin="round"
        d="M20.5 13v10"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        d="M25.5 14v12M45.5 15v11"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M25.5 17v7M45.5 18v6M30.5 17v4M40.5 15v6"
      />
      <Path
        stroke={colors['$danger-01']}
        strokeLinejoin="round"
        strokeWidth={2}
        d="M35.5 13v4"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        d="M30.5 15v9"
      />
      <Path
        stroke={colors['$danger-01']}
        strokeLinejoin="round"
        d="M35.5 11v8"
      />
      <Path
        stroke={colors['$success-01']}
        strokeLinejoin="round"
        d="M40.5 13v10"
      />
      <Path
        stroke={colors['$ui-04']}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 6 6 4.5M4.5 6 6 7.5M4.5 6h3"
      />
      <Path
        stroke={colors['$ui-04']}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M23 6h6M45 6h2"
      />
      <Path
        fill={colors['$danger-01']}
        d="M4 41a3 3 0 0 1 3-3h15a3 3 0 1 1 0 6H7a3 3 0 0 1-3-3Z"
      />
      <Path
        fill={colors['$success-01']}
        d="M27 41a3 3 0 0 1 3-3h15a3 3 0 1 1 0 6H30a3 3 0 0 1-3-3Z"
      />
      <Path
        stroke={colors['$text-04']}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
        d="M37 40h1.5m0 0v1.5m0-1.5-2 2M14 42h1.5m0 0v-1.5m0 1.5-2-2"
      />
    </Svg>
  );
};
