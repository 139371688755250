import React from 'react';
import {observer} from 'mobx-react';
import {WalletsRate} from '../../../../_common';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Wallet} from '@youtoken/ui.resource-wallets';

export interface RateProps extends BoxProps {
  item: Wallet;
}

export const Rate: React.FC<RateProps> = observer(({item, ...otherProps}) => {
  const {shouldDisplayRate} = item;

  if (!shouldDisplayRate) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...otherProps}
    >
      <Box />
      <WalletsRate item={item} />
    </Box>
  );
});
