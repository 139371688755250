import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';

export class MoreFeature extends createFeature({
  getKey: () => `feature:More`,
  getResources: () => {
    return {
      me: getResourceDescriptor(AuthMeResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed get me() {
    return this.resources.me;
  }
}
