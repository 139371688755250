import * as React from 'react';
import {G} from 'react-native-svg';
import {useObserver} from 'mobx-react';
import {
  Wrap,
  GridLines,
  GridLabels,
  LastValueLabel,
  LastValueLine,
  DataLine,
  PastDataLine,
  FutureDataLine,
  LineChartCursor,
  CandlesChartCursor,
  DataCandles,
  ActivityIndicator,
} from '../Elements';

import {
  useLocalChartState,
  WalletChartState,
  type WalletChartStateProps,
} from '../logic';

import {ChartThemeProvider} from '../Providers';
import {NoDataState} from './NoDataState';

export const WalletChart: React.FC<WalletChartStateProps> = React.memo(
  ({children, refetch, ...props}) => {
    const state = useLocalChartState(WalletChartState, props);

    return useObserver(() => {
      if (!state.hasData) {
        return <NoDataState refetch={refetch} />;
      }

      return (
        <ChartThemeProvider>
          <Wrap chartState={state}>
            <GridLines key="gridLines" chartState={state} />

            {state.type === 'line' && (
              <G key="lines">
                <PastDataLine chartState={state} />
                <FutureDataLine chartState={state} />
                <DataLine chartState={state} />
              </G>
            )}

            <GridLabels chartState={state} />

            {state.type === 'candle' && (
              <G key="candles">
                <DataCandles chartState={state} />
              </G>
            )}

            <G key="lastValue">
              <LastValueLine chartState={state} />
              <LastValueLabel chartState={state} />
            </G>

            {state.type === 'line' && (
              <LineChartCursor key="lineCursor" chartState={state} />
            )}
            {state.type === 'candle' && (
              <CandlesChartCursor key="candlesCursor" chartState={state} />
            )}
          </Wrap>
          {props.displayActivityIndicator && (
            <ActivityIndicator chartState={state} />
          )}
        </ChartThemeProvider>
      );
    });
  }
);
