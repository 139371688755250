import {computed} from 'mobx';
import {BaseCursor} from './BaseCursor';
import {BaseChartState, LineChartDataItem} from '../BaseChartState';

export class LineCursor extends BaseCursor<BaseChartState> {
  constructor(chart: BaseChartState) {
    super(chart);
  }

  @computed get layout() {
    if (!this.cursorActive || !this.cursorDataPoint) {
      return undefined;
    }

    const {date, rate} = this.cursorDataPoint as LineChartDataItem;

    const valueText = `${this.chart.formatNumber(
      rate
    )} ${this.chart.formatPercent(this.chart.getDiff(rate))}`;

    const dateText = this.chart.formatDate(date);
    const x = this.chart.layout.scaleXChart(date);
    const y = this.chart.layout.scaleY(rate);

    return {
      direction: this.chart.direction,
      dot: {
        x: x,
        y: y,
      },
      date: this.chart.layout.getLabelLayoutHorizontal(
        date,
        dateText,
        'bottom',
        'center',
        this.chart.direction
      )!,
      value: this.chart.layout.getLabelLayout(
        rate,
        valueText,
        'right',
        this.chart.direction
      )!,
    };
  }
}
