import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DocAddressIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2 1A1.8 1.8 0 0117 2.8v14.4a1.8 1.8 0 01-1.8 1.8H4.8A1.8 1.8 0 013 17.2V2.8A1.8 1.8 0 014.8 1h10.4zm-.436 1.371H5.236a.9.9 0 00-.893.788l-.007.112V16.73a.9.9 0 00.787.893l.113.007h9.528a.9.9 0 00.893-.788l.007-.112V3.27a.9.9 0 00-.787-.893l-.113-.007zM12.5 13a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-2.456-2.057c.339 0 .62.25.667.575l.008.121a.675.675 0 01-.675.675H6.675a.675.675 0 01-.668-.575L6 11.618c0-.373.302-.675.675-.675h3.369zm2.672-2.972c.339 0 .62.25.667.576l.008.12a.675.675 0 01-.675.676H6.675a.675.675 0 01-.668-.575L6 8.646c0-.372.302-.675.675-.675h6.04zm0-2.971c.339 0 .62.25.667.575l.008.121a.675.675 0 01-.675.675H6.675a.675.675 0 01-.668-.575L6 5.675C6 5.302 6.302 5 6.675 5h6.04z"
          fill={color}
        />
      </Svg>
    );
  }
);
