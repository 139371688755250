import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SavingsSelectAssetsFeature} from './Feature';
import {HowItWorks} from '../HowItWorks';
import {
  SavingsSelectAssetsFilter,
  SavingsSelectAssetsList,
  SavingsSelectAssetsFooter,
} from './components';

export const SavingsSelectAssetsSmart: React.FC<BoxProps> = observer(props => {
  const {reset} = SavingsSelectAssetsFeature.use({});

  React.useEffect(() => {
    return reset;
  }, []);

  return (
    <Box testID="SAVINGS_SELECT_ASSETS" flex={1} {...props}>
      <SavingsSelectAssetsFilter />
      <HowItWorks p={{default: 16, phone: 24}} pt={0} />
      <SavingsSelectAssetsList
        borderTopWidth={1}
        borderBottomWidth={1}
        borderColor="$ui-01"
      />
      <SavingsSelectAssetsFooter />
    </Box>
  );
});
