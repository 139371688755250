import * as React from 'react';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {StoryNavigation} from '../../interfaces';
import {useKeyPressEffect} from '@youtoken/ui.hooks';
import {iconElementSize, navElementSize} from './constants';

export interface StoryListItemNavigatorProps {
  storyRef: StoryNavigation;
}

export const StoryListItemNavigator: React.FC<StoryListItemNavigatorProps> = ({
  storyRef,
}) => {
  const handlePressPrevious = React.useCallback(() => {
    storyRef.current?.previous();
  }, [storyRef.current]);

  const handlePressNext = React.useCallback(() => {
    storyRef.current?.next(true);
  }, [storyRef.current]);

  useKeyPressEffect('ArrowLeft', handlePressPrevious);
  useKeyPressEffect('ArrowRight', handlePressNext);

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      mx={navElementSize * -1.5}
    >
      <TouchableBox
        justifyContent="center"
        alignItems="center"
        onPress={handlePressPrevious}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          width={navElementSize}
          height={navElementSize}
          borderRadius={navElementSize / 2}
          bg="$text-04"
          opacity={0.1}
          position="absolute"
        />
        <Icon
          name="chevron_left"
          size={iconElementSize}
          color="$text-04"
        ></Icon>
      </TouchableBox>
      <TouchableBox
        justifyContent="center"
        alignItems="center"
        onPress={handlePressNext}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          width={navElementSize}
          height={navElementSize}
          borderRadius={navElementSize / 2}
          bg="$text-04"
          opacity={0.1}
          position="absolute"
        />
        <Icon
          name="chevron_right"
          size={iconElementSize}
          color="$text-04"
        ></Icon>
      </TouchableBox>
    </Box>
  );
};
