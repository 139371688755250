import {serializable, primitive} from 'serializr';
import {observable} from 'mobx';
import {dateNullable} from '@youtoken/ui.utils-serializr';

export class IncentivesResponse {
  @observable
  @serializable(primitive())
  enabled!: boolean;

  @observable
  @serializable(primitive())
  balance!: string;

  @serializable(dateNullable())
  @observable
  burningAt!: Date | null;
}
