import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  NotificationScopes,
  NotificationsResource,
} from '@youtoken/ui.resource-notifications';
import {Box, useIsDesktop} from '@youtoken/ui.primitives';
import {AccountLevelFull} from '@youtoken/ui.surface-account-levels';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {Rates} from '../Rates';
import {MenuItems} from '../MenuItems';

const getModalNotificationScope = (
  pathname: string
): NotificationScopes | undefined => {
  return Object.values(NotificationScopes).find(scope => {
    return pathname.includes(scope);
  });
};

export const InnerPageShellContentMain: React.FC = observer(({children}) => {
  const {pathname} = RouterResource.use({});

  const {
    data: {
      products: {
        loyalty: {available: loyaltyAvailable},
      },
    },
  } = AuthMeResource.use({});

  const isDesktop = useIsDesktop();

  const showAccountLevel = React.useMemo(() => {
    return loyaltyAvailable && !pathname.startsWith('/profile');
  }, [loyaltyAvailable, pathname]);

  const showRates = React.useMemo(() => {
    return !pathname.startsWith('/multihodl');
  }, [pathname]);

  //#region notificationScope

  const {setScope} = NotificationsResource.use({});

  const modalNotificationScope = getModalNotificationScope(pathname);

  React.useEffect(() => {
    setScope(modalNotificationScope);
  }, [setScope, modalNotificationScope]);

  //#endregion notificationScope

  return (
    <Box
      flexDirection={{
        default: 'column',
        desktop: 'row',
      }}
      justifyContent={{
        default: 'flex-start',
        desktop: 'space-between',
      }}
      width="100%"
      mt={{
        default: 40,
        desktop: 20,
      }}
      mb={80}
    >
      {isDesktop && (
        <Box width={200}>
          <MenuItems />
          {showAccountLevel && (
            <Box>
              <AccountLevelFull size="small" mt={20} />
            </Box>
          )}
          {showRates && <Rates />}
        </Box>
      )}

      <Box
        width={{
          default: '100%',
          desktop: '720px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
});
