import * as React from 'react';
import {observer} from 'mobx-react';
import {useWizard} from '@youtoken/ui.elements';
import {FiatDepositBankWireWizard} from '../../../../../';
import {
  type BuyCryptoFormAuthorizedOnRampWizardNavigator,
  BuyCryptoFormAuthorizedOnRampWizardScreenName,
} from '..';

export const BuyCryptoFormAuthorizedOnRampLater: React.FC = observer(() => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    BuyCryptoFormAuthorizedOnRampWizardNavigator,
    BuyCryptoFormAuthorizedOnRampWizardScreenName.LATER
  >();

  return <FiatDepositBankWireWizard ticker={ticker} onPressBack={goBack} />;
});
