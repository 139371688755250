import * as React from 'react';
import {Defs, LinearGradient, Path, Stop, Svg} from 'react-native-svg';
import {useIsMobile, useTheme} from '@youtoken/ui.primitives';

export const Logo = ({
  responsive,
  hideTextPermanently,
  ...props
}: any & {responsive: boolean; hideTextPermanently?: boolean}) => {
  const {colors} = useTheme();

  const isMobile = useIsMobile();

  const hideText = hideTextPermanently || (responsive && isMobile);

  return (
    <Svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width={hideText ? 34 : 150}
      height={34}
      viewBox={hideText ? '0 0 34 34' : '0 0 150 34'}
      fill="none"
      {...props}
    >
      <Path
        fill="#40C4FF"
        fillRule="evenodd"
        d="m27 10 5-3v12l-5 3V10Z"
        clipRule="evenodd"
      />
      <Path
        fill="#80D8FF"
        fillRule="evenodd"
        d="m27 10 5-3-10-6-5 3 10 6Z"
        clipRule="evenodd"
      />
      <Path
        fill="#304FFE"
        fillRule="evenodd"
        d="M17 34v-6L7 22v6l10 6Z"
        clipRule="evenodd"
      />
      <Path
        fill="#40C4FF"
        fillRule="evenodd"
        d="M17 34v-6l10-6v6l-10 6Z"
        clipRule="evenodd"
      />
      <Path
        fill="url(#a)"
        fillRule="evenodd"
        d="M23.67 8 17 12V4l6.67 4Z"
        clipRule="evenodd"
      />
      <Path
        fill="url(#b)"
        fillRule="evenodd"
        d="M13.67 18 7 22v-8l6.67 4Z"
        clipRule="evenodd"
      />
      <Path
        fill="url(#c)"
        fillRule="evenodd"
        d="m27 22-6.67 4v-8L27 22Z"
        clipRule="evenodd"
      />
      <Path
        fill="url(#d)"
        fillRule="evenodd"
        d="M10.33 8 17 12V4l-6.67 4Z"
        clipRule="evenodd"
      />
      <Path
        fill="url(#e)"
        fillRule="evenodd"
        d="m7 22 6.67 4v-8L7 22Z"
        clipRule="evenodd"
      />
      <Path
        fill="url(#f)"
        fillRule="evenodd"
        d="M20.33 18 27 22v-8l-6.67 4Z"
        clipRule="evenodd"
      />
      <Path
        fill="#80D8FF"
        fillRule="evenodd"
        d="M7 10 2 7l10-6 5 3-10 6Z"
        clipRule="evenodd"
      />
      <Path
        fill="#304FFE"
        fillRule="evenodd"
        d="M7 10 2 7v12l5 3V10Z"
        clipRule="evenodd"
      />
      {!hideText && (
        <Path
          fill={colors['$text-01']}
          fillRule="evenodd"
          d="M57.461 12c3.388 0 5.743 2.304 5.743 5.508S60.849 23 57.46 23c-3.387 0-5.742-2.288-5.742-5.492S54.074 12 57.46 12Zm-13.77 10.763V17.95L40 12.237h2.482l2.305 3.661 2.306-3.661h2.482l-3.788 5.713v4.813H43.69Zm17.377-5.255c0 2.13-1.503 3.63-3.627 3.63-2.124 0-3.627-1.5-3.627-3.63s1.503-3.646 3.627-3.646c2.124 0 3.627 1.515 3.627 3.646Zm5.964-5.271h2.053v6.612c0 .805.268 1.294.505 1.563.3.363.852.726 1.673.726.821 0 1.374-.363 1.674-.726.237-.268.505-.758.505-1.563v-6.612h2.053v6.439c0 .994-.158 2.004-.79 2.809-.758.994-2.084 1.515-3.442 1.515s-2.684-.52-3.442-1.515c-.631-.805-.789-1.815-.789-2.81v-6.438Zm14.991 4.071v-4.071h-2.096v10.526h2.096v-4.687h4.675v4.687h2.095V12.237h-2.095v4.071h-4.675ZM98.163 12c3.387 0 5.742 2.304 5.742 5.508S101.55 23 98.162 23c-3.387 0-5.742-2.288-5.742-5.492S94.775 12 98.162 12Zm3.647 5.508c0 2.13-1.504 3.63-3.627 3.63-2.124 0-3.627-1.5-3.627-3.63s1.503-3.646 3.627-3.646c2.123 0 3.627 1.515 3.627 3.646Zm5.52-5.271h2.805c1.499 0 2.805.158 3.998 1.026 1.305.962 2.063 2.493 2.063 4.245 0 1.752-.742 3.267-2.16 4.245-1.257.868-2.434 1.01-3.933 1.01h-2.773V12.237Zm2.096 8.759v-6.992h.645c.709 0 1.773.063 2.643.695.855.631 1.322 1.704 1.322 2.809 0 1.12-.484 2.194-1.338 2.793-.935.663-2.095.695-2.627.695h-.645Zm10.8-8.76h2.095v8.76h3.547v1.767h-5.642V12.237Zm14.91 1.768v-1.767h-6.044v10.526h6.044v-1.767h-3.949v-2.873h3.748v-1.767h-3.748v-2.352h3.949Zm4.03-1.767h3.111c1.483 0 2.241.379 2.74.789.822.678 1.064 1.578 1.064 2.367 0 1.026-.419 1.925-1.257 2.478-.274.19-.661.363-1.177.458L147 22.763h-2.611l-2.87-4.23h-.257v4.23h-2.096V12.237Zm2.015 4.845V13.91h.667c.41 0 2.154.015 2.154 1.578 0 1.546-1.761 1.594-2.189 1.594h-.632Z"
          clipRule="evenodd"
          id="#logoName"
        />
      )}
      <Defs>
        <LinearGradient
          id="a"
          x1={20.335}
          x2={20.335}
          y1={12}
          y2={4}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#40C4FF" />
          <Stop offset={1} stopColor="#2979FF" />
        </LinearGradient>
        <LinearGradient
          id="b"
          x1={8.668}
          x2={15.771}
          y1={26}
          y2={23.039}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#40C4FF" />
          <Stop offset={1} stopColor="#80D8FF" />
        </LinearGradient>
        <LinearGradient
          id="c"
          x1={16.995}
          x2={16.995}
          y1={18}
          y2={26}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#2979FF" />
          <Stop offset={1} stopColor="#304FFE" />
        </LinearGradient>
        <LinearGradient
          id="d"
          x1={20.335}
          x2={20.335}
          y1={12}
          y2={4}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#2979FF" />
          <Stop offset={1} stopColor="#304FFE" />
        </LinearGradient>
        <LinearGradient
          id="e"
          x1={17.005}
          x2={17.005}
          y1={26}
          y2={18}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#2979FF" />
          <Stop offset={1} stopColor="#40C4FF" />
        </LinearGradient>
        <LinearGradient
          id="f"
          x1={28.668}
          x2={21.564}
          y1={18}
          y2={15.039}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#2979FF" />
          <Stop offset={1} stopColor="#40C4FF" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};
