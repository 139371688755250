import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {
  type RestorePasswordFormArgs,
  type RestorePasswordFormResources,
} from './types';

export class Form {
  @observable
  public args: RestorePasswordFormArgs;

  @observable
  public resources: RestorePasswordFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(
    args: RestorePasswordFormArgs,
    resources: RestorePasswordFormResources
  ) {
    this.args = args;
    this.resources = resources;

    const fields = {
      email: {
        name: 'email',
        label: 'Email',
      },
    };

    const hooks = {
      onSuccess: () => {
        const {requestToken} = __GLOBAL_RECAPTCHA__;

        requestToken('restore_password')
          .then(token =>
            this.resources.authMe.passwordRestoreRequest({
              email: this.emailValue,
              token,
            })
          )
          .catch(e => {
            handleFormSubmitError(this.instance, e);
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              email: yup.string().max(128).email().required(messages.REQUIRED),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @computed
  public get emailField() {
    return this.instance.$('email');
  }

  @computed
  public get emailValue() {
    return this.emailField.value;
  }

  @computed
  public get hasEmailError() {
    return Boolean(this.emailField.error);
  }

  @computed
  public get isEmailValidating() {
    return this.emailField.validating;
  }

  @computed
  public get emailError() {
    return getTranslatedValidationMessage(this.emailField.error);
  }

  @computed
  public get onEmailChange() {
    return this.emailField.get('onChange');
  }
}
