import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {TouchableWidgetBox} from '@youtoken/ui.elements';
import {IncentivesDetailedModal} from '../Incentives/IncentivesDetailedModal';
import {IncentivesResource} from '@youtoken/ui.resource-incentives';

export const BonusesWidget: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    incentivesEnabled,
    incentivesBalanceFormatted,
    incentivesTickerSymbol,
    incentivesBurningIn,
  } = IncentivesResource.use({});

  const [isModalOpened, setModalOpened] = React.useState(false);

  const handleOpenModal = React.useCallback(() => {
    DATA_LAYER.trackStrict('incentives-preview-click', {
      balance: incentivesBalanceFormatted,
    });

    setModalOpened(true);
  }, [incentivesBalanceFormatted]);

  const handleCloseModal = React.useCallback(() => {
    setModalOpened(false);
  }, []);

  if (!incentivesEnabled) {
    return null;
  }

  return (
    <>
      <TouchableWidgetBox
        title={t('surface.incentives.bonuses.title')}
        iconName="bonuses"
        onPress={handleOpenModal}
      >
        <Text variant="$heading-02" fontWeight={500} mb={4}>
          {incentivesTickerSymbol}
          {incentivesBalanceFormatted}
        </Text>
        <Text variant="$body-02" color="$text-02">
          {Boolean(incentivesBurningIn)
            ? t('surface.incentives.expiration_button.expires_in', {
                period: incentivesBurningIn,
              })
            : t('surface.incentives.text.balance')}
        </Text>
      </TouchableWidgetBox>
      <IncentivesDetailedModal
        isOpen={isModalOpened}
        onClose={handleCloseModal}
      />
    </>
  );
});
