import * as React from 'react';

type FooterContextType = {
  button: React.ReactNode;
  setButton: React.Dispatch<React.SetStateAction<React.ReactElement | null>>;
};

export const FooterContext = React.createContext<FooterContextType | null>(
  null
);

export const LandingButtonProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [button, setButton] = React.useState<React.ReactElement | null>(null);

  return (
    <FooterContext.Provider value={{button, setButton}}>
      {children}
    </FooterContext.Provider>
  );
};
