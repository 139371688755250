import React from 'react';
import {Modal} from '../_common';
import {DisclosureSurface} from '@youtoken/ui.surface-legal-info';
import {modal} from '../../../stores';

export const ModalDisclosureLoans: React.FC = () => {
  const handleSuccess = React.useCallback(() => modal.close(), [modal]);

  return (
    <Modal maxWidth={590} minHeight={200}>
      <DisclosureSurface product={'loan'} handleOnSuccess={handleSuccess} />
    </Modal>
  );
};
