import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {
  BankCardTransactionStatus,
  type BankCardTransaction,
} from '@youtoken/ui.resource-bank-cards';
import {StatusBadge} from '../StatusBadge';

type TransactionItem = {
  item: BankCardTransaction;
  index?: number;
};

const ICON_SIZE = 24;

export const TransactionItem: React.FC<TransactionItem & BoxProps> = observer(
  ({item, index, ...props}) => {
    const {
      typeLabel,
      ticker,
      tickerFormatted,
      amountStringFormatted,
      conversionTicker,
      conversionTickerFormatted,
      conversionAmountStringFormatted,
      status,
      statusLabel,
      badgeVariant,
      isConvert,
      merchant: {name: merchantName},
    } = item;

    const iconTicker = React.useMemo(() => {
      return conversionTicker !== ticker
        ? (conversionTicker as LogoColoredIconName)
        : (ticker as LogoColoredIconName);
    }, [ticker, conversionTicker]);

    const itemSubtitleElement = React.useMemo(() => {
      if (badgeVariant) {
        return (
          <StatusBadge
            badgeVariant={badgeVariant}
            statusLabel={statusLabel}
            mt={2}
          />
        );
      }
      if (merchantName) {
        return (
          <Text variant="$body-02" color="$text-02">
            {typeLabel}
          </Text>
        );
      }
      return null;
    }, [badgeVariant, statusLabel, merchantName, typeLabel]);

    return (
      <Box
        width="100%"
        backgroundColor="$ui-background"
        flexDirection="row"
        alignItems="center"
        px={24}
        height={72}
        {...props}
      >
        <Box>
          <LogoColored size={ICON_SIZE} name={iconTicker} />
        </Box>

        <Box flex={1} alignItems="flex-start" ml={8}>
          <Text variant="$body-01-high-accent" color="$text-01">
            {merchantName || typeLabel}
          </Text>
          {itemSubtitleElement}
        </Box>

        <Box flexDirection="column" alignItems="flex-end" ml={8}>
          <Box flexDirection="row">
            <Text
              variant="$body-01-high-accent"
              color={
                status === BankCardTransactionStatus.FAILED
                  ? '$danger-01'
                  : '$text-01'
              }
            >
              {`${amountStringFormatted}`}
            </Text>
            <Text variant="$body-01" color="$text-02">
              {' '}
              {tickerFormatted}
            </Text>
          </Box>

          {isConvert && (
            <Box mt={2}>
              <Text variant="$body-02" color="$text-02">
                {`${conversionAmountStringFormatted} ${conversionTickerFormatted}`}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
