import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {PromoBanner} from '@youtoken/ui.elements';
import {HowItWorksModal} from './Modal';

export const HowItWorks: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    data: {
      clientSettings: {enableSavingsHowItWorksBanner},
    },
    setSavingsHowItWorksBannerEnabled,
  } = AuthMeResource.use({});

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleIsOpen = React.useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  const handlePressCloseBanner = React.useCallback(() => {
    setSavingsHowItWorksBannerEnabled(false);
  }, [setSavingsHowItWorksBannerEnabled]);

  if (!enableSavingsHowItWorksBanner) {
    return null;
  }

  return (
    <Box testID="HOW_IT_WORKS" {...boxProps}>
      <PromoBanner
        testID="HOW_IT_WORKS_BANNER"
        imageName="money"
        text={t('surface.savings.how_it_works_banner.text')}
        onPressBanner={toggleIsOpen}
        onPressClose={handlePressCloseBanner}
      />
      <HowItWorksModal isOpen={isOpen} onClose={toggleIsOpen} />
    </Box>
  );
});
