import React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';
import {SavingsSummaryAprSmart} from '@youtoken/ui.surface-savings';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {IBANBadge} from '@youtoken/ui.surface-bank-wire';
import {useResource} from '@youtoken/ui.data-storage';
import {WalletsItemFeature} from '../../features/WalletsItemFeature';

interface WalletsItemSummaryProps {
  ticker: string;
  showAPR?: boolean;
}

export const WalletsItemSummary: React.FC<WalletsItemSummaryProps> = observer(
  ({ticker, showAPR}) => {
    const {isBadgeEnabled} = useResource(IBANAccountsResource, {});
    const {
      wallet,
      equivalentTicker,
      tickerFormatted,
      amountFormatted,
      equivalentFormatted,
      equivalentTickerFormatted,
    } = WalletsItemFeature.use({
      ticker,
    });

    const handleBadgePress = React.useCallback(
      () => SHARED_ROUTER_SERVICE.navigate('FiatDepositBankWire', {ticker}),
      [ticker]
    );

    return (
      <Box flexDirection="row" alignItems="center">
        <Box mr={10}>
          <LogoColored name={ticker as LogoColoredIconName} size={32} />
        </Box>
        <Box>
          <Box flexDirection="row" alignItems="center">
            <Text>
              <Text
                variant="$body-01-high-accent"
                testID={`${tickerFormatted}_BALANCE`}
              >
                {amountFormatted}
              </Text>{' '}
              <Text variant="$body-01" color="$text-02">
                {tickerFormatted}
              </Text>
            </Text>
            {isBadgeEnabled(ticker) && (
              <IBANBadge ticker={ticker} onPress={handleBadgePress} />
            )}
          </Box>
          <Box
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            {Boolean(equivalentFormatted && equivalentTicker) && (
              <Text
                variant="$body-02"
                color="$text-02"
                testID={`${tickerFormatted}_IN_${equivalentTickerFormatted}_BALANCE`}
              >
                {equivalentFormatted} {equivalentTickerFormatted}
              </Text>
            )}
            {showAPR && <SavingsSummaryAprSmart item={wallet} />}
          </Box>
        </Box>
      </Box>
    );
  }
);
