import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Illustration} from '@youtoken/ui.elements';

export const SuccessComponent: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Box
      backgroundColor="$fill-03"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      p={48}
    >
      <Box flexShrink={0} height={120} width={120}>
        <Illustration name="illustration-2" style={{width: 120, height: 120}} />
      </Box>
      <Box minWidth={200}>
        <Heading color="$text-04" variant="$heading-02" textAlign="center">
          {t('common.survey.success_message')}
        </Heading>
      </Box>
    </Box>
  );
};
