import * as React from 'react';
import {RewardStatus, RewardLevel} from '@youtoken/ui.resource-rewards-v3';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {RewardsLevelsVariant} from '../types';
import {LEVELS_ITEM_WIDTH} from '../constants';
import {getActiveIndicatorColor, getBadgeProps} from './utils';

export interface RewardsLevelsItemProps {
  data: RewardLevel;
  onChangeCurrentLevel: (currentLevel: number) => void;
  variant: RewardsLevelsVariant;
  active?: boolean;
}

export const RewardsLevelsItem: React.FC<RewardsLevelsItemProps> = ({
  variant,
  active,
  data,
  onChangeCurrentLevel,
}) => {
  const {level, status, amount} = data;

  const handlePress = React.useCallback(() => {
    onChangeCurrentLevel(level);
  }, [level, onChangeCurrentLevel]);

  return (
    <Box width={LEVELS_ITEM_WIDTH} justifyContent="center" alignItems="center">
      <RewardsLevelsItemProgress variant={variant} data={data} />
      <Box bg="$ui-background" borderRadius={20} m={1}>
        {active && (
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            m={-1}
            borderWidth={2}
            borderRadius={20}
            borderColor={getActiveIndicatorColor(status, variant)}
          />
        )}
        <TouchableBox minWidth={80} m={3} onPress={handlePress}>
          <Badge
            px={8}
            py={8}
            borderRadius={16}
            {...getBadgeProps(status, variant, active)}
          >
            {'$' + amount}
          </Badge>
        </TouchableBox>
      </Box>
    </Box>
  );
};

interface RewardsLevelsItemProgressProps {
  variant: RewardsLevelsVariant;
  data: RewardLevel;
}

export const RewardsLevelsItemProgress: React.FC<
  RewardsLevelsItemProgressProps
> = ({variant, data: {status, isFirst, isLast}}) => {
  const progressWrapperBackground = React.useMemo(() => {
    return variant === RewardsLevelsVariant.COMPLETED
      ? '$ui-01'
      : '$success-02';
  }, [status, variant]);

  const progressBackground = React.useMemo(() => {
    if (status === RewardStatus.READY || status === RewardStatus.LAST_READY) {
      return variant === RewardsLevelsVariant.COMPLETED
        ? '$success-02'
        : '$success-01';
    }

    if (status === RewardStatus.PROCESSING) {
      return variant === RewardsLevelsVariant.COMPLETED
        ? '$transparent'
        : '$success-01';
    }

    return '$transparent';
  }, [status, variant]);

  const progressWidth = React.useMemo(() => {
    if (status === RewardStatus.READY) {
      return '100%';
    }

    if (status === RewardStatus.LAST_READY) {
      return variant === RewardsLevelsVariant.COMPLETED ? '50%' : '100%';
    }

    if (status === RewardStatus.PROCESSING) {
      return variant === RewardsLevelsVariant.COMPLETED ? 0 : '50%';
    }

    return 0;
  }, [status, variant]);

  return (
    <Box
      alignSelf="center"
      width={isFirst || isLast ? '50%' : '100%'}
      height={16}
      bg={progressWrapperBackground}
      position="absolute"
      left={isFirst ? undefined : 0}
      right={isLast ? undefined : 0}
    >
      <Box width={progressWidth} height={16} bg={progressBackground} />
    </Box>
  );
};
