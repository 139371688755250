import React from 'react';
import {BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {SecurityDeviceData} from '@youtoken/ui.state-security';
import {RowItem} from './RowItem';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {observer} from 'mobx-react';

export interface SessionsListItemProps extends BoxProps {
  item: SecurityDeviceData;
  isLast?: boolean;
  index?: number;
}

export const SessionsListItem: React.FC<SessionsListItemProps> = observer(
  ({item, isLast, index, ...boxProps}) => {
    const {
      id,
      lastIp,
      fullDate,
      time,
      deviceType,
      browserName,
      browserOs,
      displayDeviceOs,
      displayLocation,
    } = item;

    const handlePressItem = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('SessionModal', {id});
    }, [id]);

    return (
      <TouchableBox
        flexDirection="row"
        onPress={handlePressItem}
        px={{default: 16, phone: 24}}
        borderBottomColor="$ui-01"
        borderBottomWidth={{default: 1, tablet: isLast ? 0 : 1}}
        py={14}
        testID={`SESSION_${index}`}
        {...boxProps}
      >
        {deviceType === 'web' && (
          <RowItem
            title={browserName}
            text={browserOs}
            flexBasis="35%"
            pr={{default: 8, tablet: 16}}
            testID="SESSION_BROWSER"
          />
        )}

        {deviceType !== 'web' && (
          <RowItem
            title="App"
            text={displayDeviceOs}
            flexBasis="35%"
            pr={{default: 8, tablet: 16}}
            testID="SESSION_APP"
          />
        )}

        <RowItem
          title={displayLocation}
          text={lastIp}
          flexBasis="35%"
          pr={{default: 8, tablet: 16}}
          testID="SESSION_LOCATION"
        />
        <RowItem
          title={fullDate}
          text={time}
          flexBasis="30%"
          alignItems="flex-end"
          testID="SESSION_DATE"
        />
      </TouchableBox>
    );
  }
);
