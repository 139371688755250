import * as React from 'react';
import {Text} from '@youtoken/ui.primitives';

interface InformationTableRightElementProps {
  preInfoText?: string;
  preInfoTextId?: string;
  mainText: string;
  mainTextId?: string;
  additionalText?: string;
  additionalTextId?: string;
  postInfoText?: string;
  postInfoTextId?: string;
}

export const InformationTableRightElement: React.FC<
  InformationTableRightElementProps
> = ({
  preInfoText,
  preInfoTextId,
  mainText,
  mainTextId,
  additionalText,
  additionalTextId,
  postInfoText,
  postInfoTextId,
}) => {
  return (
    <>
      {Boolean(preInfoText) && (
        <Text mr={3} variant="$body-02" color="$text-02" testID={preInfoTextId}>
          {preInfoText}
        </Text>
      )}
      <Text mr={3} variant="$body-02-high-accent" testID={mainTextId}>
        {mainText}
      </Text>
      {Boolean(additionalText) && (
        <Text variant="$body-02" color="$text-02" testID={additionalTextId}>
          {additionalText}
        </Text>
      )}
      {Boolean(postInfoText) && (
        <Text
          ml={3}
          variant="$body-02"
          color="$text-02"
          testID={postInfoTextId}
        >
          {postInfoText}
        </Text>
      )}
    </>
  );
};
