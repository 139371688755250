import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box, Text} from '@youtoken/ui.primitives';
import {LoanItemHeaderStatusOverdraftAmount} from '../LoanItemHeaderStatusOverdraftAmount';

export interface LoanItemHeaderStatusClosedProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeaderStatusClosed: React.FC<LoanItemHeaderStatusClosedProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <Box testID={`LOAN_ITEM_STATUS_${item.status}`} alignItems="flex-end">
        <LoanItemHeaderStatusOverdraftAmount item={item} />
        <Text variant="$body-02" color="$text-02">
          {t('surface.loans.item.closed_distance', {
            distance: item.finishedAtFormatted,
          })}
        </Text>
      </Box>
    );
  });
