import * as React from 'react';
import {Path, Svg, SvgProps} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const DaiIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Path
          d="M16 0c8.837 0 16 7.164 16 16 0 8.837-7.163 16-16 16-8.836 0-16-7.163-16-16C0 7.164 7.164 0 16 0z"
          fill="#F5AC37"
        />
        <Path
          d="M16.584 17.122h6.08c.13 0 .191 0 .2-.17.05-.618.05-1.24 0-1.86 0-.12-.06-.17-.19-.17h-12.1c-.15 0-.19.05-.19.19v1.78c0 .23 0 .23.24.23h5.96zm5.602-4.28a.194.194 0 000-.14 3.95 3.95 0 00-.362-.63 5.102 5.102 0 00-.74-.93 2.454 2.454 0 00-.46-.45 7.188 7.188 0 00-3-1.52 7.457 7.457 0 00-1.7-.18h-5.37c-.15 0-.17.06-.17.19v3.55c0 .15 0 .19.19.19h11.54s.1-.02.12-.08h-.05.002zm0 6.36a2.324 2.324 0 00-.512 0h-11.09c-.149 0-.2 0-.2.2v3.471c0 .16 0 .2.2.2h5.12c.245.019.49.002.73-.05a7.619 7.619 0 002.17-.48c.254-.088.5-.202.73-.34h.07a6.497 6.497 0 002.79-2.808s.07-.152-.008-.191v-.002zm-13.811 5.67v-5.53c0-.13 0-.149-.16-.149h-2.17c-.12 0-.17 0-.17-.16v-1.9h2.32c.13 0 .18 0 .18-.17v-1.88c0-.12 0-.15-.16-.15h-2.17c-.12 0-.17 0-.17-.16v-1.76c0-.11 0-.139.16-.139h2.15c.15 0 .19 0 .19-.19v-5.39c0-.16 0-.2.2-.2h7.5c.544.022 1.085.081 1.62.18a9.775 9.775 0 013.13 1.16 8.824 8.824 0 011.76 1.36c.395.411.752.856 1.07 1.33.314.48.575.993.78 1.53a.26.26 0 00.3.21h1.79c.229 0 .229 0 .239.22v1.64c0 .16-.06.2-.22.2h-1.38c-.14 0-.18 0-.17.18.054.61.054 1.221 0 1.83 0 .17 0 .19.19.19h1.58c.07.09 0 .18 0 .271.01.116.01.233 0 .35v1.21c0 .17-.05.22-.2.22h-1.89a.25.25 0 00-.291.19 7.997 7.997 0 01-2.1 3.06c-.34.306-.697.594-1.07.86-.4.23-.79.47-1.2.66-.756.34-1.548.59-2.36.75-.772.138-1.555.2-2.34.19h-6.94v-.01l.002-.003z"
          fill="#FEFEFD"
        />
      </Svg>
    );
  }
);
