import React from 'react';
import {Modal} from '../_common';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {SavingsSignAgreementsForm} from '@youtoken/ui.surface-savings';

const handleClose = () => {
  SHARED_ROUTER_SERVICE.navigate('__CloseModal');
};

export const ModalSavingsHowItWork: typeof Modal = props => {
  const {t} = useTranslation();

  return (
    <Modal {...props}>
      <SavingsSignAgreementsForm onClose={handleClose} />
    </Modal>
  );
};
