import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {Icon} from '@youtoken/ui.icons';
import {Box, Code} from '@youtoken/ui.primitives';

export const DevelopmentInfoTrigger: React.FC<{
  open: boolean;
  onPress: () => void;
}> = ({open, onPress}) => {
  return (
    <Box position="absolute" right={10} top={10} padding={4} borderRadius={5}>
      <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
        <Box
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          bg={open ? '$danger-03' : '$danger-02'}
          p={4}
          borderRadius={6}
        >
          {/* {!open && (
            <Code color="$danger-01" mr={5}>
              dev info
            </Code>
          )} */}
          <Icon
            name={open ? 'close' : 'settings'}
            color={open ? '$danger-01' : '$danger-04'}
          />
        </Box>
      </TouchableOpacity>
    </Box>
  );
};
