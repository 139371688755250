import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const VolumeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M4 5.667V8.25a.5.5 0 00.692.462L9.808 6.58a.5.5 0 01.384 0l5.116 2.132A.5.5 0 0016 8.25V5.667a1 1 0 00-.615-.923L10.192 2.58a.5.5 0 00-.384 0L4.615 4.744A1 1 0 004 5.667zm0 5.5v2.083a.5.5 0 00.692.462l5.116-2.132a.5.5 0 01.384 0l5.116 2.132A.5.5 0 0016 13.25v-2.083a1 1 0 00-.615-.923L10.192 8.08a.5.5 0 00-.384 0l-5.193 2.164a1 1 0 00-.615.923zm0 6.083v-1.083a1 1 0 01.615-.923l5.193-2.164a.5.5 0 01.384 0l5.193 2.164a1 1 0 01.615.923v1.083a.5.5 0 01-.692.462l-5.116-2.132a.5.5 0 00-.384 0l-5.116 2.132A.5.5 0 014 17.25z"
          fill={color}
        />
      </Svg>
    );
  }
);
