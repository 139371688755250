import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {type WithdrawalForm} from '../../state/WithdrawalForm';
import {
  BeneficiaryAddressFields,
  BeneficiaryBankFields,
  FieldAgreement,
  FieldTFA,
  WithdrawalAmount,
  WithdrawalFees,
} from './components';

export const OtherFields: React.FC = observer(() => {
  const {
    form: {accountType, ibanStep},
  } = useForm<WithdrawalForm>();

  const showAdditionalFields =
    accountType === 'accountNumber' || ibanStep === 'full';

  return (
    <>
      {showAdditionalFields && (
        <>
          <Box px={24}>
            <BeneficiaryBankFields />
            <BeneficiaryAddressFields />
          </Box>

          <FieldTFA />
        </>
      )}

      <WithdrawalFees showLastSeparator={showAdditionalFields} />

      {showAdditionalFields && (
        <>
          <WithdrawalAmount />
          <FieldAgreement />
        </>
      )}
    </>
  );
});
