import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const TradingViewIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M8.023 14.5H4.518V9.005H1.012V5.5h6.995v9h.016zm7.01 0H11.04l3.758-9h3.994l-3.758 9zm-4.01-9c1.105 0 2.005.9 2.005 2.005 0 1.106-.9 2.006-2.005 2.006s-2.005-.9-2.005-2.006c.015-1.105.9-2.005 2.005-2.005z"
          fill={color}
        />
      </Svg>
    );
  }
);
