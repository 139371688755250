import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {TouchableBanner} from '@youtoken/ui.elements';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SmallBankCard} from '@youtoken/ui.bank-card';
import {PaymentSystem} from '@youtoken/ui.resource-bank-cards';
import {BankCardsFeature} from '../../state/BankCardsFeature';

export const BankCardsPromoBanner: React.FC<BoxProps> = cell(
  boxProps => {
    const {t} = useTranslation();
    const {isPromoBannerAvailable, cards} = BankCardsFeature.use({});

    const handlePress = React.useCallback(
      () => SHARED_ROUTER_SERVICE.navigate('BankCards', {}),
      []
    );

    if (!isPromoBannerAvailable) {
      return null;
    }

    // this implies only one (virtual) card allowed and/or being opened
    // logic for handling multiple cards hasn't been formalised yet
    const status = cards[0]?.status;

    return (
      <Box {...boxProps}>
        <TouchableBanner
          type="component"
          IconComponent={
            <SmallBankCard
              type="virtual"
              cardNumber="••••"
              paymentSystem={PaymentSystem.VISA}
            />
          }
          text={
            status === 'ONBOARDING'
              ? t('surface.bank_cards.promo_banner.finish_ordering')
              : status === 'CREATING'
              ? t('surface.bank_cards.promo_banner.review')
              : t('surface.bank_cards.promo_banner.get_virtual')
          }
          variant="interactive"
          backgroundColor="$fill-03"
          onPress={handlePress}
        />
      </Box>
    );
  },
  {
    displayName: 'BankCardsPromoBanner',
    SuspenseFallback: () => null,
    ErrorFallback: () => null,
  }
);
