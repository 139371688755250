import * as React from 'react';
import {observer} from 'mobx-react';
import {i18n} from '@youtoken/ui.service-i18n';
import {useResource} from '@youtoken/ui.data-storage';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {
  type BoxProps,
  useActiveThemeType,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {
  type MultiHODLScreenName,
  TVChartContainer,
  type tvPositionLineParams,
} from '../TVChartContainer';
import {TradingViewChartData, type TradingViewData} from '../data';

export interface SmartTVChartContainerWebProps extends BoxProps {
  fromTicker: string;
  toTicker: string;
  mode: TradingViewData.Args['mode'];
  screenName: MultiHODLScreenName;
  fullscreen?: boolean;
  autosize?: boolean;
  initialPriceLine?: tvPositionLineParams;
  takeProfitLine?: tvPositionLineParams;
  marginCallLine?: tvPositionLineParams;
  triggerPriceLine?: tvPositionLineParams;
}

export const SmartTVChartContainerWeb: React.FC<SmartTVChartContainerWebProps> =
  observer(
    ({
      fromTicker,
      toTicker,
      mode = 'mid',
      screenName,
      fullscreen = false,
      autosize = true,
      initialPriceLine,
      takeProfitLine,
      marginCallLine,
      triggerPriceLine,
      ...boxProps
    }) => {
      const isMobile = useIsMobile();

      const {getTariffByTickers} = useResource(HodlsTariffsResource, {});

      const data = React.useMemo(() => {
        // NOTE: it is important to get all data inside this function - not to update data after HodlsTariffsResource updated

        const tariff = getTariffByTickers(fromTicker, toTicker);

        return new TradingViewChartData({
          fromTicker,
          toTicker,
          mode,
          precision: tariff?.precision || 4,
          daysOff: tariff?.daysOff || [],
        });
      }, [fromTicker, toTicker, mode, getTariffByTickers]);

      // NOTE: as TradingView has only light and dark theme, we will use activeThemeType in case we have more then one dark theme
      const activeThemeType = useActiveThemeType();

      React.useEffect(() => {
        DATA_LAYER.trackStrict('hodl-chart-shown', {
          screenName,
          chartType: 'tradingview',
          instrumentName: `${fromTicker}/${toTicker}`,
          priceType: mode,
        });
      }, [fromTicker, toTicker, mode]);

      return (
        <TVChartContainer
          datafeed={data.datafeed}
          symbol={`${fromTicker}_${toTicker}.${mode}`}
          initialPriceLine={initialPriceLine}
          takeProfitLine={takeProfitLine}
          marginCallLine={marginCallLine}
          triggerPriceLine={triggerPriceLine}
          fullscreen={fullscreen}
          autosize={autosize}
          theme={activeThemeType}
          locale={i18n.language}
          isMobileVersion={isMobile}
          {...boxProps}
        />
      );
    }
  );
