import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {LoanItemMainAction} from '../LoanItemMainAction';

export interface LoanItemActionsProps {
  item: LoanRegularV1 | LoanRegularV2;
  onCancelled?: () => void;
}

export const LoanItemActions: React.FC<LoanItemActionsProps> = observer(
  ({item, onCancelled}) => {
    const {t} = useTranslation();

    const handlePress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('LoanCloseNow', {
        id: item.id,
      });
    }, [item.id]);

    return (
      <Box testID="LOAN_ITEM_ACTIONS">
        <LoanItemMainAction
          item={item}
          onCancelled={onCancelled}
          size="large"
        />
        {item.allowCloseNow && (
          <Button
            testID="LOAN_ITEM_CLOSE_NOW_BUTTON"
            type="secondary"
            size="large"
            onPress={handlePress}
            mt={10}
          >
            {t('surface.loans.item.close_now', {
              amount: item.collateralAmountFormatted,
              ticker: item.collateralTickerFormatted,
            })}
          </Button>
        )}
      </Box>
    );
  }
);
