import {computed} from 'mobx';
import {
  LoansItemIncrease,
  LoansActiveResource,
  LoansClosedResource,
} from '@youtoken/ui.resource-loans';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {computedFn} from 'mobx-utils';
import {now as mobxUtilsNow} from 'mobx-utils/lib/now';
import {
  format as formatDate,
  formatDistanceStrict,
} from '@youtoken/ui.date-fns';
import {
  formatByTickerLoan,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';

export interface LoansListItemFeatureArgs {
  mode: 'regular' | 'turbo';
  id: string;
}

const getKey = (args: LoansListItemFeatureArgs) => {
  return `loansListItem(${args.mode}, ${args.id})`;
};

const getResources = (args: LoansListItemFeatureArgs) => {
  return {
    rates: getResourceDescriptor(RatesResource, {}),
    authMe: getResourceDescriptor(AuthMeResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
    loansActive: getResourceDescriptor(LoansActiveResource, {mode: args.mode}),
    loansClosed: getResourceDescriptor(LoansClosedResource, {mode: args.mode}),
  };
};

export class LoansListItemFeature extends createFeature({
  getKey,
  getResources,
}) {
  @computed
  public get mode() {
    return this.args.mode;
  }

  @computed
  public get data() {
    return this.getById(this.args.id);
  }

  @computed
  public get id() {
    return this.data?.id;
  }

  @computed
  public get status() {
    return this.data?.status;
  }

  @computed
  public get turbo() {
    return this.data?.turbo;
  }

  @computed.struct
  public get loans() {
    return (this.data?.loans || []).map(loan => {
      return {
        ...loan,
        collateralTicker: this.collateralTicker,
        borrowedTicker: this.borrowedTicker,
      };
    });
  }

  @computed
  public get isOpening() {
    return ['PENDING', 'PROCESSING'].includes(String(this.status));
  }

  @computed
  public get isOpened() {
    return ['OPEN'].includes(String(this.status));
  }

  @computed
  public get isClosing() {
    return ['CLOSING'].includes(String(this.status));
  }

  @computed
  public get isClosed() {
    return ['CLOSED', 'DECLINED', 'CANCELED', 'FAIL'].includes(
      String(this.status)
    );
  }

  @computed
  public get isClosingOrClosed() {
    return this.isClosing || this.isClosed;
  }

  @computed
  public get quickActionEnabled() {
    return ['PENDING', 'OPEN'].includes(String(this.status));
  }

  @computed
  public get quickActionText() {
    if (this.isOpening) {
      return 'surface.loans.item.cancel';
    }

    if (this.isOpened) {
      return 'surface.loans.item.repay';
    }

    if (this.isClosing) {
      return 'surface.loans.item.closing';
    }

    if (this.isClosed) {
      return 'surface.loans.item.closed';
    }

    return 'surface.loans.item.error';
  }

  @computed
  public get isOverdue() {
    return !!this.data?.overdueDays;
  }

  @computed
  public get allowIncrease() {
    return !!(
      this.mode === 'regular' &&
      this.isOpened &&
      !this.isOverdue &&
      this.increase
    );
  }

  @computed
  public get allowDecrease() {
    return !!(
      this.mode === 'regular' &&
      this.isOpened &&
      !this.isOverdue &&
      this.decrease
    );
  }

  @computed
  public get allowReopen() {
    return !!(
      this.mode === 'regular' &&
      this.isOpened &&
      !this.isOverdue &&
      this.reopen
    );
  }

  @computed
  public get allowCloseNow() {
    return !!(this.isOpened && !this.isOverdue && this.closeNow);
  }

  @computed
  public get allowSetClosePrice() {
    return !!(this.isOpened && this.minFTP && this.maxFTP);
  }

  @computed
  public get enabledIncrease() {
    return (
      this.allowIncrease &&
      this.status === 'OPEN' &&
      !(this.increase as LoansItemIncrease).oldData
    );
  }

  @computed
  public get enabledDecrease() {
    return this.allowDecrease && this.status === 'OPEN';
  }

  @computed
  public get enabledCloseNow() {
    return this.allowCloseNow && this.status === 'OPEN';
  }

  @computed
  public get enabledReopen() {
    return this.allowReopen && this.status === 'OPEN';
  }

  @computed
  public get enabledSetClosePrice() {
    return this.allowSetClosePrice && this.status === 'OPEN';
  }

  @computed
  public get collateralTicker() {
    return this.data?.collateralTicker;
  }

  @computed
  public get borrowedTicker() {
    return this.data?.borrowedTicker;
  }

  @computed
  public get collateralAmount() {
    return formatByTickerLoan(
      this.data?.collateralAmount || 0,
      String(this.collateralTicker)
    );
  }

  @computed
  public get borrowedAmount() {
    return formatByTickerLoan(
      this.data?.borrowedAmount || 0,
      String(this.borrowedTicker)
    );
  }

  @computed
  public get overdraftAmount() {
    return formatByTickerLoan(
      this.data?.overdraftAmount || 0,
      String(this.borrowedTicker)
    );
  }

  @computed
  public get LTV() {
    return formatPercent(this.data?.LTV);
  }

  @computed
  public get APR() {
    return formatPercent(this.data?.APR);
  }

  @computed
  public get takeProfit() {
    return formatByTickerLoan(this.data?.FTP || 0, String(this.borrowedTicker));
  }

  @computed
  public get marginCall() {
    return formatByTickerLoan(
      this.data?.marginCall || 0,
      String(this.borrowedTicker)
    );
  }

  @computed
  public get initialPrice() {
    return formatByTickerLoan(
      this.data?.initialPrice || 0,
      String(this.borrowedTicker)
    );
  }

  @computed
  public get closedPrice() {
    return formatByTickerLoan(
      this.data?.closedPrice || 0,
      String(this.borrowedTicker)
    );
  }

  @computed
  public get days() {
    return this.data?.days;
  }

  @computed
  public get minFTP() {
    return this.data?.minFTP;
  }

  @computed
  public get maxFTP() {
    return this.data?.maxFTP;
  }

  @computed
  public get FTP() {
    return this.data?.FTP;
  }

  @computed
  public get repayment() {
    return formatByTickerLoan(
      toBig(
        this.data?.details && this.data?.details[`_${this.borrowedTicker}`]
      ).abs(),
      String(this.borrowedTicker)
    );
  }

  @computed public get repayUntil() {
    const endDate = this.endDate;

    if (endDate) {
      return formatDate(endDate, 'dd, MMM, yyyy, HH:mm z');
    }

    return 'Invalid endDate';
  }

  @computed public get repayUntilDistance() {
    const now = new Date(mobxUtilsNow());
    const endDate = this.endDate;

    if (endDate) {
      return i18n.t(
        this.isClosed
          ? 'surface.loans.item.closed_distance'
          : 'surface.loans.item.to_repay_distance',
        {
          distance: formatDistanceStrict(endDate, now, {
            addSuffix: true,
          }),
        }
      );
    }

    return 'Invalid endDate';
  }

  @computed
  public get isAlert() {
    return Boolean(this.data?.isClientMC);
  }

  @computed.struct
  public get chartData() {
    if (this.status === 'OPEN') {
      const {bid} = this.resources.rates.getRateObj(
        this.collateralTicker,
        this.borrowedTicker
      );

      return {
        type: this.data?.chartData?.type || 'line',
        data: [
          ...(this.data?.chartData?.data || []),
          {
            date: new Date(),
            rate: bid,
          },
        ],
      };
    }

    return this.data?.chartData;
  }

  @computed.struct
  public get chartDataAvailable() {
    return Boolean(this.chartData && this.chartData?.data.length > 2);
  }

  @computed
  public get startDate() {
    return (
      this.data?.reopenedAt || this.data?.startedAt || this.data?.createdAt
    );
  }

  @computed
  public get endDate() {
    const finishedAt = this.data?.finishedAt;
    const finishAt = this.data?.finishAt;

    if (finishedAt) {
      return finishedAt;
    }

    if (finishAt) {
      return finishAt;
    }

    return undefined;
  }

  @computed.struct
  public get closeNow() {
    return this.data?.closeNow;
  }

  @computed.struct
  public get increase() {
    return this.data?.increase;
  }

  @computed.struct
  public get decrease() {
    return this.data?.decrease;
  }

  @computed.struct
  public get reopen() {
    return this.data?.reopen;
  }

  @computed
  public get agreementUrl() {
    return `/v2/docks/turbo/agreement/${this.id}`;
  }

  public getById = computedFn((id: string) => {
    return (
      this.resources.loansActive.getById(id) ||
      this.resources.loansClosed.getById(id)
    );
  });
}
