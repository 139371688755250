import * as React from 'react';
import {observer} from 'mobx-react';
import {
  TouchableBox,
  type TouchableBoxProps,
  Box,
  TColorTokens,
} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export type BaseActionProps = {
  chevronColor?: keyof TColorTokens;
};

export const BaseAction: React.FC<BaseActionProps & TouchableBoxProps> =
  observer(({chevronColor, children, ...touchableBoxProps}) => {
    return (
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px={16}
        py={18}
        borderRadius={12}
        borderWidth={1}
        {...touchableBoxProps}
      >
        <Box flexDirection="row" flex={1} pr={20}>
          {children}
        </Box>
        <Icon name="chevron_right" size={16} color={chevronColor} />
      </TouchableBox>
    );
  });
