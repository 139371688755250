import * as React from 'react';
import {observer} from 'mobx-react';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';

export interface ErrorProps extends BoxProps {
  name: string | string[];
}

export const Error: React.FC<ErrorProps & {errors: any}> = observer(
  ({name, errors, ...rest}) => {
    const error = React.useMemo(() => {
      if (Array.isArray(name)) {
        const key = name.find(n => !!errors[n]);

        if (key) {
          return errors[key];
        } else {
          return '';
        }
      } else {
        return errors[name];
      }
    }, [name, errors]);

    if (!error) {
      return null;
    }

    return (
      <Box mt={10} {...rest}>
        <Text variant="$body-02" color="$danger-01">
          <ValidationMessageComponent error={error} />
        </Text>
      </Box>
    );
  }
);
