import * as React from 'react';
import {observer} from 'mobx-react';
import {Link, Banner} from '@youtoken/ui.elements';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {VerificationResource} from '@youtoken/ui.resource-verification';

export const ReverifyMessage: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {hasToReverify} = VerificationResource.use({});

  if (!hasToReverify) {
    return null;
  }

  return (
    <Box overflow="hidden" {...boxProps}>
      <Banner variant="attention" width="100%">
        <Trans
          t={t}
          components={{
            Link: (
              <Link
                color="$attention-04"
                textDecorationLine="underline"
                url="https://help.youhodler.com/en/articles/6807936-identity-verification-with-sumsub"
              />
            ),
          }}
          values={{date: '1.02.2023'}}
          i18nKey="surface.profile.verification.reverify_message"
        />
      </Banner>
    </Box>
  );
});
