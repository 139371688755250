import * as React from 'react';
import {KeyboardAvoidingView, Platform, ScrollView} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {
  BankCardLimitsResource,
  BankCardsResource,
} from '@youtoken/ui.resource-bank-cards';
import {ToField, FromField, Submit} from './components';
import {BankCardTransferForm} from './state';
import {LimitItem} from '../../components';

type BankCardTransferSurfaceProps = {
  cardId: string;
};
export const BankCardTransferSurface: React.FC<
  BankCardTransferSurfaceProps & BoxProps
> = cell(
  ({cardId, ...boxProps}) => {
    const {t} = useTranslation();

    const resources = useResources({
      bankCards: getResourceDescriptor(BankCardsResource, {}),
      bankCardLimits: getResourceDescriptor(BankCardLimitsResource, {cardId}),
    });

    const form = makeForm(() => new BankCardTransferForm({cardId}, resources));

    const {
      form: {tickerFormatted},
    } = form;
    const {minAmountFormatted, maxAmountFormatted} = form.view;

    return (
      <KeyboardAvoidingView
        behavior="padding"
        enabled={Platform.OS !== 'android'}
        style={{flex: 1}}
        keyboardVerticalOffset={80}
      >
        <Box
          width="100%"
          flex={1}
          backgroundColor="$ui-background"
          justifyContent="space-between"
          p={24}
          testID="BANK_CARD_TRANSFER_FORM"
          {...boxProps}
        >
          <Form form={form}>
            <ScrollView>
              <Box mb={16}>
                <FromField />
                <ToField mt={24} />
                <LimitItem>
                  {t('surface.bank_cards.deposit.limit.min', {
                    amount: minAmountFormatted,
                    ticker: tickerFormatted,
                  })}
                </LimitItem>
                <LimitItem>
                  {t('surface.bank_cards.deposit.limit.max', {
                    amount: maxAmountFormatted,
                    ticker: tickerFormatted,
                  })}
                </LimitItem>
              </Box>
            </ScrollView>

            <Submit />
          </Form>
        </Box>
      </KeyboardAvoidingView>
    );
  },
  {
    displayName: 'BankCardTransfer',
  }
);

export const getTitle = () => i18n.t('surface.bank_cards.transfer.title');
