import type {SelectItem, SelectSize} from './types';

export const getLabelByValue = (
  items: SelectItem[],
  placeholder: string = 'Select',
  selected?: string | null
) => {
  return items.find(item => item.value === selected)?.label ?? placeholder;
};

export const getSelectHeight = (size?: SelectSize) => {
  if (size === 'small') return 32;
  if (size === 'medium') return 40;
  if (size === 'large') return 48;
  return 32;
};

export const getItemBG = (active: boolean, selected: boolean) => {
  if (selected) return '$interactive-03';
  if (active) return '$interactive-02';
  return '$ui-background';
};

export const getLabelVariant = (isSelected: boolean, size?: SelectSize) => {
  if (size === 'small' && !isSelected) return '$body-02';
  if (size === 'small' && isSelected) return '$body-02-medium-accent';
  if (!isSelected) return '$body-01';
  return '$body-01-medium-accent';
};

export const getBorderColor = (hasError?: boolean, open?: boolean) => {
  if (open && hasError) return '$danger-01';
  if (open) return '$interactive-01';
  if (hasError) return '$danger-03';
  return '$ui-02';
};
