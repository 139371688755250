import * as React from 'react';
import {
  LoanRegularCreateLandingForm,
  type LoanRegularCreateLandingFormProps,
} from '@youtoken/ui.surface-loan-regular-create';
import {ServicePagesWrapper} from '../ServicePagesWrapper';

export const LedgerLiveLoanPage: React.FC<
  LoanRegularCreateLandingFormProps
> = ({ticker, conversionTicker, submitText, url}) => {
  return (
    <ServicePagesWrapper>
      <LoanRegularCreateLandingForm
        ticker={ticker}
        conversionTicker={conversionTicker}
        submitText={submitText}
        url={url}
        borderWidth={1}
        borderRadius={16}
        width="100%"
      />
    </ServicePagesWrapper>
  );
};
