import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  AccountLevelInfoItemShort,
  AccountLevelConversionCoefficientBadge,
} from '..';

export interface AccountLevelInfoItemConversionShortProps {
  level: LoyaltyLevel;
}

export const AccountLevelInfoItemConversionShort: React.FC<
  AccountLevelInfoItemConversionShortProps & BoxProps
> = observer(
  ({
    level: {
      conversion: {multiplier},
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    const handlePress = () => {
      SHARED_ROUTER_SERVICE.navigate('Exchange', {
        fromTicker: 'usdt',
        toTicker: 'btc',
      });
    };

    return (
      <AccountLevelInfoItemShort
        testID="ACCOUNT_LEVEL_INFO_ITEM_CONVERSION"
        icon="exchange"
        title={t('surface.loyalty.conversion')}
        onPress={handlePress}
        badge={
          <AccountLevelConversionCoefficientBadge
            value={multiplier}
            variant="neutral"
          />
        }
        {...boxProps}
      />
    );
  }
);
