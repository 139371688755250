import React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {BriefStatus} from '@youtoken/ui.resource-hodl';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface JustStatusProps {
  briefStatusVariant: string;
}

export const JustStatus: React.FC<JustStatusProps> = observer(
  ({briefStatusVariant}) => {
    const {t} = useTranslation();

    return (
      <Box flexDirection="row" justifyContent="flex-end">
        <Text variant="$body-02" color="$text-02" mb={2}>
          {briefStatusVariant === BriefStatus.OPENING &&
            t('surface.hodls.item.opening')}
          {briefStatusVariant === BriefStatus.CLOSING &&
            t('surface.hodls.item.closing')}
        </Text>
      </Box>
    );
  }
);
