import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {ListItemAssetData} from '../../types';
import {SavingsSelectAssetsFeature} from '../../Feature';
import {SavingsSelectAssetsListAsset} from './Asset';

export const SavingsSelectAssetsListAssetTouchable: React.FC<
  ListItemAssetData & BoxProps
> = observer(({ticker, ...boxProps}) => {
  const {toggleTicker} = SavingsSelectAssetsFeature.use({});

  const handleToggle = React.useCallback(() => {
    toggleTicker(ticker);
  }, [toggleTicker, ticker]);

  return (
    <TouchableBox
      testID={`SAVINGS_SELECT_ASSETS_LIST_ASSET_TOUCHABLE_${ticker.toUpperCase()}`}
      onPress={handleToggle}
      {...boxProps}
    >
      <SavingsSelectAssetsListAsset ticker={ticker} />
    </TouchableBox>
  );
});
