import EventEmitter from 'eventemitter3';
import LedgerLiveApi, {WindowMessageTransport} from '@ledgerhq/live-app-sdk';
import {signExchangeFund} from './utils';

export type {Account, Currency} from '@ledgerhq/live-app-sdk';
export {ExchangeType, FeesLevel} from '@ledgerhq/live-app-sdk';

export class LedgerLiveService {
  public events = new EventEmitter<
    | 'connect'
    | 'listCurrencies'
    | 'listAccounts'
    | 'startExchange'
    | 'signExchangeFund'
    | 'completeExchange'
  >();

  private connected = false; // NOTE: only 1 connection needed, it is permanent

  private ledgerLiveInstance = new LedgerLiveApi(new WindowMessageTransport());

  public connect: LedgerLiveApi['connect'] = () => {
    if (!this.connected) {
      this.ledgerLiveInstance.connect();
      this.connected = true;
      this.events.emit('connect');
    }
  };

  public listCurrencies: LedgerLiveApi['listCurrencies'] = async params => {
    const result = await this.ledgerLiveInstance.listCurrencies(params);
    this.events.emit('listCurrencies', params, result);
    return result;
  };

  public listAccounts: LedgerLiveApi['listAccounts'] = async params => {
    const result = await this.ledgerLiveInstance.listAccounts(params);
    this.events.emit('listAccounts', params, result);
    return result;
  };

  public startExchange: LedgerLiveApi['startExchange'] = async params => {
    const result = await this.ledgerLiveInstance.startExchange(params);
    this.events.emit('startExchange', params, result);
    return result;
  };

  public completeExchange: LedgerLiveApi['completeExchange'] = async params => {
    const result = await this.ledgerLiveInstance.completeExchange(params);
    this.events.emit('completeExchange', params, result);
    return result;
  };

  public signExchangeFund: typeof signExchangeFund = (...params) => {
    const result = signExchangeFund(...params);
    this.events.emit('signExchangeFund', ...params, result);
    return result;
  };
}
