import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text, TouchableText} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CardItem} from '@youtoken/ui.resource-cards';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {CardItemComponent} from './components';

interface CardSelectorProps {
  onPressAddNewCard: () => void;
  cardsList: CardItem[];
  onSelectCard: (id: string) => void;
  onPressManage?: () => void;
  selectedCardId?: string;
  shouldShowLabel?: boolean;
}

export const CardSelector: React.FC<CardSelectorProps & BoxProps> = observer(
  ({
    onPressAddNewCard,
    cardsList,
    onSelectCard,
    onPressManage,
    selectedCardId,
    shouldShowLabel,
    ...boxProps
  }) => {
    const {t} = useTranslation();
    const isListEmpty = cardsList.length === 0;

    return (
      <Box pb={10} {...boxProps}>
        {shouldShowLabel && (
          <Text variant="$body-01-high-accent">
            {t('surface.wallets.checout_bank_card.select_card_action')}
          </Text>
        )}

        {/* Render empty state */}
        {isListEmpty && (
          <Box
            mt={8}
            p={16}
            borderWidth={1}
            borderRadius={6}
            borderColor="$ui-01"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            testID="EMPTY_CARDS_STATE"
          >
            <Icon name="card" color="$text-02"></Icon>
            <Text ml={8} variant="$body-02" color="$text-02">
              {t('surface.wallets.checout_bank_card.no_cards_disclosure')}
            </Text>
          </Box>
        )}

        {/* Render cards */}
        {!isListEmpty &&
          cardsList.map(item => {
            const isSelected = item.id === selectedCardId;

            return (
              <CardItemComponent
                item={item}
                isSelected={isSelected}
                onSelectCard={onSelectCard}
              />
            );
          })}

        <Box
          pt={16}
          flexDirection="row"
          justifyContent={isListEmpty ? 'center' : 'space-between'}
        >
          <Button
            icon="plus"
            width={isListEmpty ? '100%' : 'auto'}
            type={isListEmpty ? 'primary' : 'secondary'}
            size={isListEmpty ? 'large' : 'small'}
            pr={isListEmpty ? 20 : 12}
            onPress={onPressAddNewCard}
            testID="ADD_CARD_BUTTON"
          >
            {t('surface.wallets.checout_bank_card.add_new_card_action')}
          </Button>
          {!isListEmpty && Boolean(onPressManage) && (
            <TouchableText
              variant="$body-02"
              color="$text-02"
              onPress={onPressManage}
              testID="MANAGE_CARDS_BUTTON"
            >
              {t('surface.wallets.manage')}
            </TouchableText>
          )}
        </Box>
      </Box>
    );
  }
);
