import React from 'react';
import {Svg, SvgProps} from 'react-native-svg';

type Props = SvgProps;

export const HexGrid: React.FC<Props> = ({
  width = 800,
  height = 600,
  viewBox = '-50 -50 100 100',
  ...props
}: Props) => {
  return (
    <Svg
      // className="grid"
      width={width}
      height={height}
      viewBox={viewBox}
      {...props}
    />
  );
};
