import {computedFn} from 'mobx-utils';
import {comparer} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {DayPreviewChartDataResource} from '@youtoken/ui.resource-day-preview-chart-data';

type DayPreviewChartFeatureArgs = {
  tickersPairs: string[];
};

export class DayPreviewChartFeature extends createFeature({
  getKey: (_args: DayPreviewChartFeatureArgs) => {
    return `feature:dayPreviewChart(${JSON.stringify(_args)})`;
  },
  getResources: ({tickersPairs}) => {
    return {
      chartDayPreview: getResourceDescriptor(DayPreviewChartDataResource, {
        tickersPairs,
      }),
    };
  },
}) {
  dayPreviewChartDataByPair = computedFn(
    (baseTicker: string, quoteTicker: string) => {
      const dataByPair = this.resources.chartDayPreview.allPairsChartData.find(
        (pair: any) =>
          pair.fromTicker === baseTicker && pair.toTicker === quoteTicker
      );

      return dataByPair?.chartData;
    },
    {equals: comparer.structural}
  );
}
