import {warning} from '@youtoken/ui.utils';
import {explorerUrls} from './explorerUrls';

export {explorerUrls};

export const getExplorerUrl = (
  env: string,
  ticker: string,
  txHash?: string,
  version?: string
): {name: string; url: string} | null => {
  warning(txHash, `cannot build explorerUrl without txHash`, {
    env,
    ticker,
    version,
    txHash,
  });

  if (!txHash) {
    return null;
  }

  const urlsObject =
    explorerUrls[`${ticker}_${version}`] ?? explorerUrls[ticker];

  warning(urlsObject, `cannot find urlsObject`, {
    ticker,
    version,
    env,
  });

  if (!urlsObject) {
    return null;
  }

  const urlTemplate =
    urlsObject[env === 'production' ? 'production' : 'testnet'];

  warning(urlTemplate, `cannot find url`, {
    ticker,
    version,
    env,
  });

  if (!urlTemplate) {
    return null;
  }

  return {
    name: urlsObject.name,
    url: urlTemplate.replace('#{txHash}', txHash),
  };
};
