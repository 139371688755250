import React from 'react';
import {Helmet} from 'react-helmet';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {Box} from '@youtoken/ui.primitives';
import {DeviceConfirmResult} from '@youtoken/ui.surface-device-confirm-result';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const NewDeviceResultPage: React.FC<{
  status: 'allow' | 'decline' | 'error';
}> = ({status}) => {
  const {t} = useTranslation();

  const title = React.useMemo(() => {
    if (status === 'allow') {
      return t('surface.new_device.tab_title.allow');
    }

    if (status === 'decline') {
      return t('surface.new_device.tab_title.decline');
    }

    return t('surface.new_device.tab_title.error');
  }, [t, status]);

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
      />
    );
  }, []);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
      <OuterPageShell>
        <Helmet title={title} />
        <Box alignItems="center">
          <DeviceConfirmResult status={status} />
        </Box>
      </OuterPageShell>
    </AppShell>
  );
};
