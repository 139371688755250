import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Modal} from '@youtoken/ui.modal';
import {RewardsHistoryFlatListSmart} from '../../smarts';

export const RewardsHistoryModal: React.FC<React.ComponentProps<typeof Modal>> =
  observer(modalProps => {
    const {t} = useTranslation();

    return (
      <Modal
        title={t(
          'surface.referral_program.referrals.recent_bonuses_subheading'
        )}
        snapPoints={['90%']}
        nativeStackBehavior="push"
        {...modalProps}
      >
        <RewardsHistoryFlatListSmart />
      </Modal>
    );
  });
