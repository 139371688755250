import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {getSettlementFeePercentFormatted} from '@youtoken/ui.hodls-utils';

type CardItemRolloverProps = {
  baseTicker: string;
  quoteTicker: string;
  testID?: string;
};

export const CardItemRollover: React.FC<CardItemRolloverProps> = observer(
  ({baseTicker, quoteTicker, testID}) => {
    const {getBestRolloverTariffByTickers} = HodlsTariffsResource.use({});

    const {settlementFee} = getBestRolloverTariffByTickers(
      baseTicker,
      quoteTicker
    );

    const settlementFeeFormatted = getSettlementFeePercentFormatted(
      settlementFee,
      true
    );

    return (
      <Text variant="$body-02" color="$text-02" testID={testID}>
        {settlementFeeFormatted + '%'}
      </Text>
    );
  }
);
