import React from 'react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {Form} from '@youtoken/ui.form-elements';
import {
  FeatureLoanCreateLandingForm,
  type FeatureLoanCreateLandingFormArgs,
} from './state';
import {
  Section,
  Ticker,
  ConversionTicker,
  Term,
  TermDetails,
  LoanRepay,
} from '../components';

export interface LoanRegularCreateLandingFormProps
  extends React.ComponentProps<typeof Section>,
    FeatureLoanCreateLandingFormArgs {
  submitText?: string;
}

export const LoanRegularCreateLandingForm: React.FC<LoanRegularCreateLandingFormProps> =
  cell(
    ({
      submitText: _submitText,
      ticker,
      conversionTicker,
      amount,
      url,
      ...props
    }) => {
      const isMobile = useIsMobile();

      const {t} = useTranslation();

      const form = FeatureLoanCreateLandingForm.use({
        url,
        ticker,
        conversionTicker,
        amount,
      });

      return (
        <Section p={0} {...props}>
          <Form form={form}>
            {/* CONTENT */}
            <Section p={0} flexGrow={1} zIndex={1}>
              <Section
                flexDirection={{
                  tablet: 'row',
                  default: 'column',
                }}
                borderBottomWidth={1}
                zIndex={1}
              >
                <Box
                  flexGrow={1}
                  flexShrink={0}
                  flexBasis={{tablet: 0, default: 'auto'}}
                  mb={{tablet: 0, default: 20}}
                  zIndex={2}
                >
                  <Ticker showAllAmount={false} showAccessoryView={false} />
                </Box>
                {!isMobile && (
                  <Box alignItems="center" mx={15} mt={41}>
                    <Icon name="convert" color="$text-03" />
                  </Box>
                )}
                <Box
                  flexGrow={1}
                  flexShrink={0}
                  flexBasis={{tablet: 0, default: 'auto'}}
                  zIndex={1}
                >
                  <ConversionTicker />
                </Box>
              </Section>
              <Term borderBottomWidth={1} />
              <TermDetails />
            </Section>
            {/* FOOTER */}
            <Section p={0} borderTopWidth={1}>
              <Section
                flexDirection={{tablet: 'row', default: 'column'}}
                justifyContent="space-between"
                alignItems={{tablet: 'center', default: 'stretch'}}
              >
                <Box flex={1} mb={{tablet: 0, default: 20}}>
                  <LoanRepay />
                </Box>
                <Button
                  size="large"
                  minWidth={135}
                  loading={form.submitting}
                  onPress={form.form.instance.onSubmit}
                >
                  {_submitText || t('surface.loans.create_form.submit')}
                </Button>
              </Section>
            </Section>
          </Form>
        </Section>
      );
    }
  );
