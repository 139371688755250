import * as React from 'react';
import {Box, type WithBoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {type CryptoDepositProps} from '../types';
import {CryptoDepositHeader} from '../CryptoDepositHeader';
import {CryptoDepositBanner} from '../CryptoDepositBanner';
import {
  CryptoDepositLedgerMethods,
  CryptoDepositLedgerMethod,
} from './components';

export const CryptoDepositLedger: React.FC<WithBoxProps<CryptoDepositProps>> =
  cell(({ticker, showHeader = true, ...rest}) => {
    return (
      <Box flexGrow={1} {...rest}>
        {showHeader && <CryptoDepositHeader ticker={ticker} pb={0} />}
        <CryptoDepositBanner ticker={ticker} mt={24} />
        <Box flexGrow={1}>
          <CryptoDepositLedgerMethods ticker={ticker} />
          <CryptoDepositLedgerMethod ticker={ticker} />
        </Box>
      </Box>
    );
  });
