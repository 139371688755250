import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Illustration} from '@youtoken/ui.elements';

export const PleaseVerifyWaiting: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <>
      <Illustration
        style={{
          alignSelf: 'center',
          width: 244,
          height: 244,
        }}
        name="illustration-14"
      />
      <Box>
        <Heading variant="$heading-02" textAlign="center" mt={8} mb={8}>
          {t('surface.after_identity.title')}
        </Heading>
        <Text variant="$body-01" textAlign="center" color="$text-02">
          {t('surface.after_identity.text')}
        </Text>
      </Box>
    </>
  );
});
