import {observable, action} from 'mobx';
import {Form} from './Form';
import {type CardDepositFormArgs, type CardDepositFormResources} from './types';
import {View} from './View';

export class CardDepositForm {
  @observable
  public form: Form;

  @observable
  public view: View;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(args: CardDepositFormArgs, resources: CardDepositFormResources) {
    this.form = new Form(args, resources);
    this.view = new View(this.form, resources);
  }
}
