import {useTheme as useRestyleTheme} from '@shopify/restyle';
import type {
  Theme,
  TColorTokens,
  TColorTokensNames,
  TLoyaltyColorTokens,
  TLoyaltyColorTokensNames,
  TSectorColorTokensNames,
  TSectorsColorTokens,
} from './types';
import {lightTheme} from './lightTheme';
import {darkTheme} from './darkTheme';

export type {
  Theme,
  TColorTokens,
  TColorTokensNames,
  TLoyaltyColorTokens,
  TLoyaltyColorTokensNames,
  TSectorColorTokensNames,
  TSectorsColorTokens,
};

/** supported theme type */
export type ThemeNames = 'light' | 'dark';

/** themes object */
export const themes: {[key in ThemeNames]: Theme} = {
  light: lightTheme,
  dark: darkTheme,
};

export function useTheme() {
  return useRestyleTheme<Theme>();
}

export function useColors() {
  return useTheme().colors;
}
