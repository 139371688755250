import React from 'react';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Helmet} from 'react-helmet';
import {TrustedDevicesSurfaceCell} from '@youtoken/ui.surface-trusted-devices';
import {Text, Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {goBack} from '../../../../services/shared-router';

export const TrustedDevicesPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.profile.tab_security_trusted_devices')} />
      <Box pb={27} flexDirection="row" justifyContent="flex-start">
        <Button type="ghost" onPress={goBack} ml={-5}>
          <Box width="100%" flexDirection="row" alignItems="center">
            <Icon name="chevron_left" color="$text-05" />
            <Text color="$interactive-01" variant="$body-01-medium-accent">
              {t('surface.profile.form_confirm_phone.action.back')}
            </Text>
          </Box>
        </Button>
      </Box>
      <TrustedDevicesSurfaceCell />
    </InnerPageShell>
  );
};
