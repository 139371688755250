import {serializable} from 'serializr';
import {Big} from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';

export class BankCardLimitsResponse {
  @serializable(bigNumber())
  minAmount!: Big;

  @serializable(bigNumber())
  maxAmount!: Big;
}
