import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {MenuItemBase} from './MenuItemBase';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('DeleteAccount');
};

export const DeleteAccount: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <MenuItemBase
      icon="heart_broken"
      title={t('surface.profile.delete_account.title')}
      RightPartComponentWeb={
        <Icon name="chevron_right" size={20} color="$text-02" />
      }
      onPress={handlePress}
      borderBottomLeftRadius={{
        default: 0,
        tablet: 10,
      }}
      borderBottomRightRadius={{
        default: 0,
        desktop: 10,
      }}
      {...boxProps}
    />
  );
});
