import {observable, action, computed} from 'mobx';
import {Form} from './Form';
import {
  LoanItemRegularResource,
  LoanItemTurboResource,
} from '@youtoken/ui.resource-loans';
import {formatByTickerLoan} from '@youtoken/ui.formatting-utils';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export interface FeatureLoanCloseNowFormArgs {
  mode: 'regular' | 'turbo';
  id: string;
}

export interface FeatureLoanCloseNowFormResources {
  docsResource: DocsResource;
  walletsResource: WalletsResource;
  loanResource: LoanItemRegularResource | LoanItemTurboResource;
}

export class FeatureLoanCloseNowForm extends createFeature({
  getKey: (args: FeatureLoanCloseNowFormArgs) =>
    `CloseNowForm:${JSON.stringify(args)}`,
  getResources: (args: FeatureLoanCloseNowFormArgs) => {
    const authMeResource = AuthMeResource.use({});

    return {
      docsResource: getResourceDescriptor(DocsResource, {
        country: authMeResource.residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      loanResource: getResourceDescriptor(
        args.mode === 'regular'
          ? LoanItemRegularResource
          : LoanItemTurboResource,
        {id: args.id}
      ),
    };
  },
}) {
  @observable
  public restored = false;

  @observable
  public form: Form;

  @computed
  public get loan() {
    return this.form.loan;
  }

  @computed
  public get collateralTicker() {
    return this._collateralTicker.toUpperCase();
  }

  @computed
  public get borrowedTicker() {
    return this._borrowedTicker.toUpperCase();
  }

  @computed
  public get outstandingLoan() {
    return formatByTickerLoan(this.loan.overdraftAmount, this._borrowedTicker);
  }

  @computed
  public get price() {
    return formatByTickerLoan(this._closeNow.price, this._borrowedTicker);
  }

  @computed
  public get collateralRepayment() {
    return formatByTickerLoan(
      this._closeNow.sellCollateralAmount,
      this._collateralTicker
    );
  }

  @computed
  public get serviceFee() {
    return formatByTickerLoan(this._closeNow.closeFee, this._borrowedTicker);
  }

  @computed
  public get collateralReturn() {
    return formatByTickerLoan(
      this._closeNow.recompenseAmount,
      this._collateralTicker
    );
  }

  @computed
  public get agreements() {
    const documents = this.resources.docsResource.data;

    if (
      this.loan.company === 'NAUMARD' &&
      documents.loanCloseNowFormNaumard?.length
    ) {
      return documents.loanCloseNowFormNaumard;
    }

    if (
      this.loan.company === 'YOUHODLER' &&
      documents.loanCloseNowFormYouhodler?.length
    ) {
      return documents.loanCloseNowFormYouhodler;
    }

    return documents.loanCloseNowForm || [];
  }

  @action
  public restore = () => {
    this.form.restore();
    this.restored = true;
  };

  @action
  public reset = () => {
    this.restored = false;
    this.form.instance.reset();
  };

  @computed
  private get _collateralTicker() {
    return this.loan.collateralTicker;
  }

  @computed
  private get _borrowedTicker() {
    return this.loan.borrowedTicker;
  }

  @computed.struct
  private get _closeNow() {
    return this.loan.closeNow!;
  }

  constructor(
    args: FeatureLoanCloseNowFormArgs,
    resources: FeatureLoanCloseNowFormResources
  ) {
    super(args, resources);

    this.form = new Form(args, resources);
  }
}
