import {computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {i18n} from '@youtoken/ui.service-i18n';

import {
  IFeatureDisclosureFormArgs,
  IFeatureDisclosureFormResources,
} from './types';

export class Form {
  @observable
  public args: IFeatureDisclosureFormArgs;

  @observable
  public resources: IFeatureDisclosureFormResources;

  @observable
  public instance: MobxReactForm;

  public constructor(
    args: IFeatureDisclosureFormArgs,
    resources: IFeatureDisclosureFormResources
  ) {
    this.args = args;
    this.resources = resources;

    const fields = {
      agree: {
        value: false,
      },
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        const {agree} = form.values();

        return TRANSPORT.API.post(
          `/v1/product/${this.args.product}/agreements/sign`,
          {
            agreeToTerms: agree,
          }
        )
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.disclosure_form.message.success'),
            });
            this.resources.authMe.refetchManually();

            if (Boolean(this.args.handleOnSuccess)) {
              this.args.handleOnSuccess!();
            }
          })
          .catch(e => {
            const error = extractErrorFromResponse(e.response?.data, '_error');
            const errorTranslated = getTranslatedValidationMessage(error);
            LOCAL_NOTIFICATIONS.error({
              text: errorTranslated || i18n.t('common.errors.smth_went_wrong'),
            });
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              agree: yup.agreeToTerms().required(),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});
  }

  @computed
  get productAgreementsList() {
    const product = this.args.product;
    const docs = this.resources.documents.data;

    switch (product) {
      case 'wallet':
        return docs.wallet;
      case 'dual':
        return docs.dual;
      case 'hodl2':
      case 'loan':
      case 'turbo':
        return docs.youhodlerDocuments;
      case 'wirex':
        return docs.wirexIban;
      case 'fiat-republic':
        return docs.fiatRepublicIban;
      default:
        return [];
    }
  }
}
