import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Logo, MenuButton} from './elements';
import {AppEnvStatus} from './AppEnv';
import {ThemeSwitcher} from './ThemeSwitcher';
import {HeaderProps} from './types';
import {useIsDesktop} from '@youtoken/ui.primitives';

export const HeaderWeb: React.FC<HeaderProps> = React.memo(
  ({
    renderLeft = () => {
      return <HeaderWebLeftDefault />;
    },
    renderRight,
  }) => {
    const isDesktop = useIsDesktop();

    return (
      <Box width="100%" height={56} pt={15} mt={20} zIndex={2}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          px={isDesktop ? undefined : 20}
        >
          <Box
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {renderLeft?.()}
          </Box>

          <Box
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            zIndex={-1}
          >
            {renderRight?.()}
            <AppEnvStatus />
            <ThemeSwitcher />
          </Box>
        </Box>
      </Box>
    );
  }
);

export const HeaderWebLeftDefault: React.FC<HeaderProps> = () => {
  const isDesktop = useIsDesktop();

  return (
    <>
      <Logo />
      {!isDesktop && <MenuButton />}
    </>
  );
};
