import {action, computed} from 'mobx';
import {Platform} from 'react-native';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {FeeDepositResource} from '@youtoken/ui.resource-fee-deposit';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {AuthMeResource, type ProductName} from '@youtoken/ui.resource-auth-me';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {i18n} from '@youtoken/ui.service-i18n';
import {APPLE_PAY_SERVICE} from '@youtoken/ui.apple-pay-service';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {getFeeString} from '../../../utils';
import {DepositMethodEnum} from '../../../../../constants';
import {type CardDepositMethods} from '../../../../../smart';
import {PaymentSystems} from '../../../types';

export enum Providers {
  CHECKOUT = 'checkout',
  UNLIMINT = 'unlimint',
}

interface DepositMethodsFeatureArgs {
  ticker: string;
  onNavigateToVerification?: () => void;
}

interface DepositMethodsItem {
  method: DepositMethodEnum;
  disabled?: boolean;
  provider: Providers;
  fee: string;
  productName: ProductName;
  paymentSystems?: PaymentSystems[];
  operationMethod?: CardDepositMethods;
}

export class DepositMethodsFeature extends createFeature({
  getKey: (args: DepositMethodsFeatureArgs) =>
    `depositMethods(${JSON.stringify(args)})`,
  getResources: (args: DepositMethodsFeatureArgs) => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      fees: getResourceDescriptor(FeeAllResource, {}),
      feeDeposit: getResourceDescriptor(FeeDepositResource, {
        ticker: args.ticker,
      }),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed
  get ticker() {
    return this.args.ticker;
  }

  @computed
  get conversionTicker() {
    return (this.ticker === 'eur' || this.ticker === 'usd') &&
      this.resources.authMe.residenceOrCountry === 'ARG'
      ? 'ars'
      : undefined;
  }

  @computed
  get unlimintBankCardFee() {
    return this.resources.fees.getFeeForProviderDeposit(
      'unlimintBankCard',
      this.ticker,
      this.conversionTicker || this.ticker
    );
  }

  @computed
  get checkoutBankCardFee() {
    return this.resources.feeDeposit.data;
  }

  @computed
  get unlimintApplePayFee() {
    return this.resources.fees.getFeeForProviderDeposit(
      'unlimintApplePay',
      this.ticker,
      this.ticker
    );
  }

  @computed
  get unlimintCoDiFee() {
    return this.resources.fees.getFeeForProviderDeposit(
      'unlimintCoDi',
      this.ticker,
      'mxn'
    );
  }

  @computed
  get unlimintSPEIFee() {
    return this.resources.fees.getFeeForProviderDeposit(
      'unlimintSPEI',
      this.ticker,
      'mxn'
    );
  }

  @computed
  get isUnlimintBankCardFeeAvailable() {
    return Boolean(this.unlimintBankCardFee?.enabled);
  }

  @computed
  get isCheckoutBankCardFeeAvailable() {
    return Boolean(this.checkoutBankCardFee?.enabled);
  }

  @computed
  get isUnlimintApplePayFeeAvailable() {
    return Boolean(this.unlimintApplePayFee?.enabled);
  }

  @computed
  get isUnlimintCoDiFeeAvailable() {
    return Boolean(this.unlimintCoDiFee?.enabled);
  }

  @computed
  get isUnlimintSPEIFeeAvailable() {
    return Boolean(this.unlimintSPEIFee?.enabled);
  }

  @computed
  get unlimintBankCardFeePercentFormatted() {
    return this.isUnlimintBankCardFeeAvailable
      ? formatPercent(this.unlimintBankCardFee!.percent)
      : null;
  }

  @computed
  get checkoutBankCardFeePercentFormatted() {
    return this.isCheckoutBankCardFeeAvailable
      ? formatPercent(this.checkoutBankCardFee!.percent)
      : null;
  }

  @computed
  get unlimintApplePayFeePercentFormatted() {
    return this.isUnlimintApplePayFeeAvailable
      ? formatPercent(this.unlimintApplePayFee!.percent)
      : null;
  }

  @computed
  get unlimintCoDiFeePercentFormatted() {
    return this.isUnlimintCoDiFeeAvailable
      ? formatPercent(this.unlimintCoDiFee!.percent)
      : null;
  }

  @computed
  get unlimintSPEIFeePercentFormatted() {
    return this.isUnlimintSPEIFeeAvailable
      ? formatPercent(this.unlimintSPEIFee!.percent)
      : null;
  }

  @computed
  get bankWireFeeFormatted() {
    const fees = this.resources.fees.data.filter(
      fee =>
        fee.ticker === this.ticker &&
        fee.conversionTicker === this.ticker &&
        fee.method === 'deposit' &&
        ['swift', 'sepa', 'wire'].includes(fee.provider)
    );

    return getFeeString(fees, this.ticker.toUpperCase(), i18n.t);
  }

  @computed
  get allowUnlimintBankCard() {
    return (
      this.resources.authMe.products.depositFiatUnlimintBankCard.isEnabled &&
      this.isUnlimintBankCardFeeAvailable
    );
  }

  @computed
  get allowCheckoutBankCard() {
    return (
      this.resources.authMe.products.depositFiatCheckoutBankCard.isEnabled &&
      this.isCheckoutBankCardFeeAvailable
    );
  }

  @computed
  public get allowCheckoutBankCardFrame() {
    const {
      depositFiatCheckoutBankCardFrame: {isEnabled},
    } = this.resources.authMe.products;

    return isEnabled && this.isCheckoutBankCardFeeAvailable;
  }

  @computed
  get allowUnlimintApplePay() {
    return (
      this.isUnlimintApplePayFeeAvailable &&
      Platform.select({
        web: APPLE_PAY_SERVICE.isAvailable,
        ios:
          this.resources.authMe.products.depositFiatUnlimintApplePay
            .isEnabled && APPLE_PAY_SERVICE.isAvailable,
        android: false,
      })
    );
  }

  @computed
  get allowUnlimintCoDi() {
    return (
      this.resources.authMe.products.depositFiatUnlimintCoDi.isEnabled &&
      this.isUnlimintCoDiFeeAvailable
    );
  }

  @computed
  get allowUnlimintSPEI() {
    return (
      this.resources.authMe.products.depositFiatUnlimintSPEI.isEnabled &&
      this.isUnlimintSPEIFeeAvailable
    );
  }

  @computed
  get allowBankWire() {
    return this.resources.authMe.products.depositFiatWire.isEnabled;
  }

  @computed
  get allowAdvCash() {
    return (
      this.resources.authMe.products.depositFiatAdvcash.isEnabled &&
      ['usd', 'eur'].includes(this.ticker)
    );
  }

  @computed
  get methodsList(): DepositMethodsItem[] {
    const list: DepositMethodsItem[] = [];

    if (this.allowUnlimintBankCard) {
      list.push(
        {
          method: DepositMethodEnum.APPLE_PAY,
          disabled: !this.allowUnlimintApplePay,
          provider: Providers.UNLIMINT,
          fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
            feePercent: this.unlimintApplePayFeePercentFormatted,
          }),
          productName: 'depositFiatUnlimintApplePay',
        },
        {
          method: DepositMethodEnum.BANK_CARD,
          provider: Providers.UNLIMINT,
          fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
            feePercent: this.unlimintBankCardFeePercentFormatted,
          }),
          productName: 'depositFiatUnlimintBankCard',
        }
      );
    }

    if (this.allowCheckoutBankCard) {
      const paymentSystems = [
        PaymentSystems.APPLE_PAY,
        PaymentSystems.GOOGLE_PAY,
      ];

      // NOTE: If checkout Frame is available, visa and mc payments isn't available in checkoutExternal option
      if (!this.allowCheckoutBankCardFrame) {
        paymentSystems.push(PaymentSystems.VISA, PaymentSystems.MASTERCARD);
      }

      list.push({
        method: DepositMethodEnum.BANK_CARD,
        operationMethod: 'checkoutExternal',
        provider: Providers.CHECKOUT,
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.checkoutBankCardFeePercentFormatted,
        }),
        productName: 'depositFiatCheckoutBankCard',
        paymentSystems,
      });
    }

    if (this.allowCheckoutBankCardFrame) {
      list.push({
        method: DepositMethodEnum.BANK_CARD_IFRAME,
        operationMethod: 'checkoutIframe',
        provider: Providers.CHECKOUT,
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.checkoutBankCardFeePercentFormatted,
        }),
        productName: 'depositFiatCheckoutBankCardFrame',
        paymentSystems: [PaymentSystems.VISA, PaymentSystems.MASTERCARD],
      });
    }

    if (this.allowBankWire) {
      list.push({
        method: DepositMethodEnum.BANK_WIRE,
        provider: Providers.UNLIMINT,
        fee: this.bankWireFeeFormatted,
        productName: 'depositFiatWire',

        paymentSystems: [
          PaymentSystems.IBAN,
          PaymentSystems.ACCOUNT,
          PaymentSystems.SEPA,
        ],
      });
    }

    if (this.allowUnlimintCoDi) {
      list.push({
        method: DepositMethodEnum.CODI,
        provider: Providers.UNLIMINT,
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.unlimintCoDiFeePercentFormatted,
        }),
        productName: 'depositFiatUnlimintCoDi',
      });
    }

    if (this.allowUnlimintSPEI) {
      list.push({
        method: DepositMethodEnum.SPEI,
        provider: Providers.UNLIMINT,
        fee: i18n.t('surface.wallets.fiat_deposit.item_fee_percent', {
          feePercent: this.unlimintSPEIFeePercentFormatted,
        }),
        productName: 'depositFiatUnlimintSPEI',
      });
    }

    if (this.allowAdvCash) {
      list.push({
        method: DepositMethodEnum.ADV_CASH,
        provider: Providers.UNLIMINT,
        fee: i18n.t('surface.wallets.fiat_deposit.item_advcash_fee'),
        productName: 'depositFiatAdvcash',
        paymentSystems: [
          PaymentSystems.VISA,
          PaymentSystems.MASTERCARD,
          PaymentSystems.ACCOUNT,
        ],
      });
    }

    list.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1;
      } else if (!a.disabled && b.disabled) {
        return -1;
      }
      return 0;
    });

    return list;
  }

  @action
  public createHandleMethodPress =
    ({
      method,
      provider,
      operationMethod,
      onChange,
    }: {
      method: DepositMethodEnum;
      provider: Providers;
      operationMethod?: CardDepositMethods;
      onChange: (
        method: DepositMethodEnum,
        provider?: Providers,
        operationMethod?: CardDepositMethods
      ) => void;
    }) =>
    () => {
      if (
        this.resources.authMe.checkProductAvailability(
          this.methodsList.find(
            item => item.method === method && item.provider === provider
            // NOTE: "!" because function can be called only on list items and checkProductAvailability has invariant anyway
          )!.productName,
          () => SHARED_ROUTER_SERVICE.navigate('__CloseModal') // FIXME: a hack
        )
      ) {
        onChange(method, provider, operationMethod);
      }
    };
}
