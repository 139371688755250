import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SecurityChangesIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2.75A.75.75 0 015.75 2h9.5a.75.75 0 010 1.5h-9.5A.75.75 0 015 2.75zm0 8a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM2.75 10a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM5 6.75A.75.75 0 015.75 6h3.5a.75.75 0 010 1.5h-3.5A.75.75 0 015 6.75zM2.75 2a.75.75 0 000 1.5h.5a.75.75 0 000-1.5h-.5zM2 6.75A.75.75 0 012.75 6h.5a.75.75 0 010 1.5h-.5A.75.75 0 012 6.75zM15.5 9v1h-3V9a1.5 1.5 0 013 0zm1.5 1V9a3 3 0 10-6 0v1a2 2 0 00-2 2v4a2 2 0 002 2h6a2 2 0 002-2v-4a2 2 0 00-2-2zm-6.5 2a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-4zm4.5 2a1 1 0 11-2 0 1 1 0 012 0z"
          fill={color}
        />
      </Svg>
    );
  }
);
