import * as React from 'react';
import {Loans} from '@youtoken/ui.legacy-components';
import {Box, Heading} from '@youtoken/ui.primitives';
import {RouterResource} from '../../../../../../routes/RouterResource';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {modal} from '../../../../../../../stores';

const {CreateNew} = Loans;

export const Header: React.FC = observer(() => {
  const {t} = useTranslation();

  const routerResource = RouterResource.use({});

  const handlePressCreateNew = React.useCallback(() => {
    routerResource.push(`/turbocharge/create`);
  }, [routerResource.push, modal]);

  return (
    <Box p={{default: 20, desktop: 0}}>
      <Box mb={36}>
        <Heading variant="$heading-01-responsive">
          {t('surface.loans.title_turbos')}
        </Heading>
      </Box>
      <CreateNew mode="turbo" onPress={handlePressCreateNew} />
    </Box>
  );
});
