import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {MenuItemBase} from './MenuItemBase';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('AntiPhishing');
};

export const AntiPhishing: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {antiPhishingCode} = AuthMeResource.use({});

  return (
    <MenuItemBase
      icon="anti_phishing"
      title={t('surface.more.item_name.anti_phishing')}
      subtitle={t('surface.more.item_name.anti_phishing.caption')}
      badgeText={
        Boolean(antiPhishingCode)
          ? t('surface.more.label_enabled')
          : t('surface.more.label_disabled')
      }
      badgeVariant={Boolean(antiPhishingCode) ? 'success' : 'locked'}
      RightPartComponentWeb={
        <Icon name="chevron_right" size={20} color="$text-02" />
      }
      onPress={handlePress}
      {...boxProps}
    />
  );
});
