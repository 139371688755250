import {action, computed} from 'mobx';
import {stringify} from 'query-string';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HODLsResponsePaginated} from '../HODLsResponsePaginated';
import {HODLItem} from '../HODLItem';
import {HODLItemData} from '../HODLItemData';

export type ClosedHODLsResourceArgs = {
  limit?: number;
  offset?: number;
  filter?: string[];
};

export class ClosedHODLsResource extends createResource<
  ClosedHODLsResourceArgs,
  HODLsResponsePaginated
>({
  skipRefreshOnVisible: false,
  getKey: () => 'hodls:closed',
  getData: (args: ClosedHODLsResourceArgs) =>
    TRANSPORT.API.get('/v3/hodl', {
      params: {
        status: ['CLOSED', 'CANCELED'],
        limit: args.limit ?? 10,
        offset: args.offset ?? 0,
        baseTicker: args.filter,
      },
      paramsSerializer: (params: any) =>
        stringify(params, {arrayFormat: 'none'}),
    }).then(res => {
      const data = res.data;

      const rows = data.rows.map((i: any) => ({
        data: i,
      }));

      return deserialize(HODLsResponsePaginated, {
        ...data,
        rows,
      });
    }),
}) {
  @computed get argLimit() {
    return this.args?.limit ?? 10;
  }

  @computed get argOffset() {
    return this.args?.offset ?? 0;
  }

  @action updateArgs = (newArgs: ClosedHODLsResourceArgs) => {
    this.args = {
      ...this.args,
      ...newArgs,
    };
  };

  @action.bound
  addNewHodl = (hodl: HODLItemData) => {
    if (!this.data.rows.some(h => h.id === hodl.id)) {
      this.data.rows.unshift(deserialize(HODLItem, {data: hodl}));
    }
  };

  onInit() {
    super.onInit();

    TRANSPORT.SOCKET.on('hodl-closed', this.addNewHodl);
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off('hodl-closed', this.addNewHodl);
  }
}
