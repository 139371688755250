import {AccountRequisite} from '../../../types/AccountRequisite';

export const splitBankRequisites = (requisites: AccountRequisite[]) => {
  const intermediaryBankRequisiteTypes = [
    'intermediary-bank',
    'intermediary-bank-swift',
  ];
  const correspondentBankRequisiteTypes = [
    'correspondentBankName',
    'correspondentBankCode',
    'additionalRoutingInfo',
  ];

  const mainRequisites: AccountRequisite[] = [];
  const intermediaryBankRequisites: AccountRequisite[] = [];
  const correspondentBankRequisites: AccountRequisite[] = [];

  requisites.forEach(requisite => {
    if (correspondentBankRequisiteTypes.includes(requisite.type)) {
      correspondentBankRequisites.push(requisite);
    } else if (intermediaryBankRequisiteTypes.includes(requisite.type)) {
      intermediaryBankRequisites.push(requisite);
    } else {
      mainRequisites.push(requisite);
    }
  });

  return {
    mainRequisites,
    intermediaryBankRequisites,
    correspondentBankRequisites,
  };
};
