import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {MenuItemBase} from './MenuItemBase';

export const ResetPassword: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {userEmail, passwordResetRequest, signOut} = AuthMeResource.use({});

  const handlePress = React.useCallback(() => {
    if (
      confirm(t('surface.profile.security.reset_password.action.confirmation'))
    ) {
      return passwordResetRequest({email: userEmail})
        .then(() => {
          LOCAL_NOTIFICATIONS.info({
            text: t(
              'surface.profile.security.reset_password.sumbit_msg.success'
            ),
          });
          signOut();
        })
        .catch(() => {
          LOCAL_NOTIFICATIONS.error({
            text: t('surface.profile.security.reset_password.sumbit_msg.error'),
          });
        });
    }
  }, [userEmail, passwordResetRequest, signOut, t]);

  return (
    <MenuItemBase
      icon="lock"
      title={t('surface.profile.security.reset_password.title')}
      subtitle={t('surface.profile.security.reset_password.text', {
        email: userEmail,
      })}
      RightPartComponentWeb={
        <Button onPress={handlePress} testID="RESET_PASSWORD_BUTTON">
          {t('surface.profile.security.reset_password.action')}
        </Button>
      }
      {...boxProps}
    />
  );
});
