import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const RewardsOverviewStatusMessageCompleted: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box alignItems="center">
      <Icon name="success_contour" size={48} color="$success-01" />
      <Text variant="$heading-02" color="$text-01" textAlign="center" mt={8}>
        {t('surface.referral_program.rewards.overview.message.completed.title')}
      </Text>
    </Box>
  );
});
