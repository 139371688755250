import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {useForm} from '@youtoken/ui.form-elements';
import {CardDepositForm} from '../../state';

export const FeeRow: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    view: {
      feeValueFormatted,
      feePercentFormatted,
      feeMaxValueFormatted,
      feeMinValueFormatted,
      tickerFormatted,
      conversionTickerFormatted,
    },
  } = useForm<CardDepositForm>();
  const showFeeValue = !conversionTickerFormatted;

  return (
    <Box
      height={48}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Text mr={4}>
          {t('surface.apple_pay.deposit.components.fee_row.fee')}
        </Text>
        {showFeeValue && (
          <QuestionTooltip
            bodyHorizontalOffset={100}
            content={t(
              `surface.wallets.fiat_deposit.bank_card_fee_tooltip${
                feeMinValueFormatted ? '_lte' : ''
              }${feeMaxValueFormatted ? '_gte' : ''}`,
              {
                percentValue: feePercentFormatted,
                minValue: feeMinValueFormatted,
                maxValue: feeMaxValueFormatted,
                ticker: tickerFormatted,
              }
            )}
          />
        )}
      </Box>
      <Box flexDirection="row">
        {showFeeValue ? (
          <>
            <Text mr={2} variant="$body-01-high-accent">
              {feeValueFormatted}
            </Text>
            {tickerFormatted && <Text>{tickerFormatted}</Text>}
          </>
        ) : (
          <Text mr={8}>{feePercentFormatted}%</Text>
        )}
      </Box>
    </Box>
  );
});
