import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {HighRiskBanner} from '@youtoken/ui.high-risk-banner';
import {AppShellProps} from './types';
import {AppShellHeader} from './AppShellHeader';
import {Footer} from './Footer';

export const AppShellWeb: React.FC<AppShellProps> = React.memo(
  ({
    children,
    country,
    renderHeader = () => {
      return <AppShellHeader />;
    },
    renderFooter = () => {
      return <Footer />;
    },
  }) => {
    return (
      <Box
        justifyContent="space-between"
        width="100%"
        minWidth={320}
        minHeight="100%"
        backgroundColor="$ui-background"
      >
        <HighRiskBanner country={country} />
        <Box width="100%" alignItems="center" minHeight="100%">
          <Box width={{default: '100%', desktop: 960}}>
            {renderHeader?.()}
            {children}
          </Box>
        </Box>
        {renderFooter?.()}
      </Box>
    );
  }
);
