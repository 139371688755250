import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {
  type ConfirmationOptions,
  LocalConfirmationsService,
} from './LocalConfirmationsService';

export type {ConfirmationOptions};

// exported const is reference to global service, not scoped const;
export const LOCAL_CONFIRMATIONS = __SERVICE_REGISTRY__.registerService(
  'LOCAL_CONFIRMATIONS',
  LocalConfirmationsService,
  require('../package.json').version
);
