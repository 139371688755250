import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';

export const SavingsAssetsEmptyState: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box
      testID="SAVINGS_ASSETS_EMPTY_STATE"
      justifyContent="center"
      alignItems="center"
      py={24}
    >
      <Text>{t('inputs.combobox.nothing_found')}</Text>
    </Box>
  );
});
