import * as React from 'react';
import {Box, Text, TouchableText, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Slider} from '@youtoken/ui.legacy-slider';
import {Label} from '@youtoken/ui.form-elements';
import {Trans} from '@youtoken/ui.elements';
import {type WalletComboboxItem} from '../TickerAndAmountInput/types';
import {TickerAndAmountInput} from '../TickerAndAmountInput';

export interface InputWithSliderProps {
  wallets: WalletComboboxItem[];
  ticker: string;
  tickerFormatted: string;
  amount: string;
  onChangeAmount: (amount: string) => void;
  onChangeTicker: (ticker: string) => void;
  setAllSourceToAmount: () => void;
  maximumValue: number;
  minimumValue?: number;
  isTickerImmutable?: boolean;
  validationComponent?: React.ReactElement;
  sliderKey?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const InputWithSlider: React.FC<InputWithSliderProps & BoxProps> = ({
  wallets,
  ticker,
  tickerFormatted,
  amount,
  onChangeAmount,
  onChangeTicker,
  minimumValue = 0,
  maximumValue,
  setAllSourceToAmount,
  isTickerImmutable = false,
  validationComponent,
  sliderKey,
  onFocus,
  onBlur,
  ...boxProps
}) => {
  const {t} = useTranslation();
  const amountValue = Number(amount);
  const sliderValue = amountValue > 0 ? (amountValue / maximumValue) * 100 : 0;

  const handleSliderChange = React.useCallback(
    (val: number) => {
      const amountValueFromPercentage = (maximumValue / 100) * val;
      onChangeAmount(amountValueFromPercentage.toString());
    },
    [onChangeAmount]
  );

  const TransTouchableWrapper = React.useCallback(
    ({children}: {children: React.ReactNode}) => {
      return (
        <TouchableText
          variant="$body-02"
          color="$text-05"
          onPress={setAllSourceToAmount}
        >
          {children}
        </TouchableText>
      );
    },
    [setAllSourceToAmount]
  );

  return (
    <Box {...boxProps}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label>{t('surface.hodls.hodl_form.amount_label')}</Label>
        <Trans
          i18nKey="surface.hodls.hodl_form.amount.action.all_source"
          components={{
            Bold: <Text variant="$body-02-medium-accent" />,
          }}
          values={{
            amount: maximumValue,
            ticker: tickerFormatted,
          }}
          parent={TransTouchableWrapper}
        />
      </Box>
      <TickerAndAmountInput
        wallets={wallets}
        onChangeTicker={onChangeTicker}
        onChangeAmount={onChangeAmount}
        ticker={ticker}
        amount={amount}
        isTickerImmutable={isTickerImmutable}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {validationComponent}
      <Box mt={8}>
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          minimumValue={0}
          maximumValue={100}
          step={0.01}
          key={sliderKey}
          showLegend={false}
        />
      </Box>
    </Box>
  );
};
