/** wraps args, merging it with default args
 * @example
 * ```typescript
 * const mergeArgs = wrapMergeArgs({foo: 'bar'})
 *
 * mergeArgs({bar: 'foo'}) // => {foo: 'bar', bar: 'foo'}
 * ```
 */
export const wrapMergeArgs =
  <Args extends {}>(defaultArgs: Partial<Args>) =>
  (args: Args) => {
    return {...defaultArgs, ...args};
  };
