import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, TouchableText, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {type SignInForm} from '../state';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('RestorePassword');
};

export const ResetPassword: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {},
  } = useForm<SignInForm>();

  const {t} = useTranslation();

  return (
    <Box flexDirection="row" {...boxProps}>
      <Text color="$text-01" variant="$body-01-medium-accent">
        {t('forms.sign_in.forgot_password.hint')}
      </Text>
      <TouchableText
        color="$text-05"
        onPress={handlePress}
        variant="$body-01-medium-accent"
        testID="RESET_PASSWORD_LINK"
      >
        {' '}
        {t('forms.sign_in.forgot_password.action')}
      </TouchableText>
    </Box>
  );
});
