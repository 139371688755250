import * as React from 'react';
import {observer} from 'mobx-react';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {ResetButton as ResetButtonComponent} from '../components';

export const ResetButton: React.FC<BoxProps> = observer(boxProps => {
  const {isAllBlocksClaimed} = MinerOverviewResource.use({});

  if (!isAllBlocksClaimed) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <ResetButtonComponent />
    </Box>
  );
});
