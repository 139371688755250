import {observable} from 'mobx';
import {list, object, primitive, serializable} from 'serializr';

export class Loans {
  @observable
  @serializable(primitive())
  borrowed!: string;

  @observable
  @serializable(primitive())
  collateral!: string;

  @observable
  @serializable(primitive())
  settlementFee!: string;

  @observable
  @serializable(primitive())
  settlementPeriod!: string;
}

export class HodlLoanChainResponse {
  @observable
  @serializable(list(object(Loans)))
  loans!: Loans[];

  @observable
  @serializable(primitive())
  borrowedTicker!: string;

  @observable
  @serializable(primitive())
  collateralTicker!: string;

  @observable
  @serializable(primitive())
  ltv!: string;

  @observable
  @serializable(primitive())
  totalCollateral!: string;

  @observable
  @serializable(primitive())
  totalRepayment!: string;
}
