import {computedFn} from 'mobx-utils';
import {
  BaseChartState,
  CandlestickChartData,
  ChartDirection,
} from '../BaseChartState';
import {computed, observable} from 'mobx';
import {BaseDataDisaplay} from './BaseDataDisplay';

export class Candles extends BaseDataDisaplay {
  constructor(chart: BaseChartState) {
    super(chart);
  }

  @observable minCandleWidth = 7;
  @observable candleMargin = 3;

  @computed get candleWidth() {
    return 10;
  }

  @computed get visibleCandles() {
    return this.chart.normalizedCandlesData;
  }

  getCandleLayout = computedFn((candle: any, index: number) => {
    const direction: ChartDirection =
      candle.close >= candle.open ? 'up' : 'down';

    const open = this.chart.layout.scaleY(candle.open);
    const close = this.chart.layout.scaleY(candle.close);

    const upperLimit = Math.min(open, close);
    const lowerLimit = Math.max(open, close);

    const y = upperLimit;
    const x = this.chart.layout.scaleX(index);

    const height = Math.max(lowerLimit - upperLimit, 1);

    return {
      direction,
      x,
      yHigh: this.chart.layout.scaleY(candle.high),
      yLow: this.chart.layout.scaleY(candle.low),
      yBody: y,
      bodyHeight: height,
      bodyWidth: this.candleWidth - 3,
    };
  });

  @computed get dataCandles() {
    if (!this.visibleCandles) {
      return undefined;
    }

    return (this.visibleCandles as CandlestickChartData)!.map((candle, index) =>
      this.getCandleLayout(candle, index)
    );
  }
}
