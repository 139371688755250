import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

import {Icon} from '@youtoken/ui.icons';
import {MenuItemBase} from './MenuItemBase';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('Turbocharge', {});
};

export const Turbo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <MenuItemBase
      title={t('surface.menu.turbos')}
      icon="turbo"
      onPress={handlePress}
      RightPartComponentWeb={
        <Icon name="chevron_right" size={20} color="$text-02" />
      }
      {...boxProps}
    />
  );
});
