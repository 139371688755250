import * as React from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {Router, useLocation, useParams} from 'react-router';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import NiceModal from '@ebay/nice-modal-react';
import {DetectLangComponent} from '@youtoken/ui.service-i18n';
import {
  setupGetNavString,
  setupRefreshOnVisible,
  setupUseOnVisible,
} from '@youtoken/ui.data-storage';
// NOTE: Providers must be above cell
import {
  ConfirmationContextProvider,
  NotificationContextProvider,
  RecaptchaContextProvider,
  SumSubVerificationContextProvider,
  TwoFactorAuthContextProvider,
} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {History} from 'history';
import {ModalOpenedContextProvider} from '@web-app/src/components/layout/Shell/AppShell/Modals';
import {useFocusEffect} from '@web-app/src/hooks/useFocusEffect';
import {modal} from '@web-app/src/stores';
import Routes from '@web-app/src/components/routes';
import {GlobalStyles} from '@web-app/src/components/styles';
import {useCookies, useOnMount} from '@web-app/src/hooks';
import {
  GTMInitializer,
  IntercomInitializer,
  SegmentInitializer,
  SentryInitializer,
  VoluumInitializer,
} from './ExternaServicesInitializers';
import {DataSourcesProvider} from './DataSourcesProvider';
import {AppearanceAndThemeProvider, useTheme} from '@youtoken/ui.primitives';
import {SafeAreaProvider} from 'react-native-safe-area-context';

const useBindQueryParamsToCookies = (queryParams: any) => {
  const [_cid, setCid] = useCookies('click_id');
  const [_et, setEt] = useCookies('goal_token');

  useOnMount(() => {
    const cid = queryParams['cid'];
    const et = queryParams['et'];

    if (cid) {
      setCid(cid);
    }
    if (et) {
      setEt(et);
    }
  });
};

const AppContainer: React.FC<{history: History}> = observer(({history}) => {
  const queryParams = useParams();
  const location = useLocation();

  useBindQueryParamsToCookies(queryParams);

  return (
    <>
      <React.Suspense fallback={<DefaultSuspenseFallback />}>
        <Helmet title="YouHodler" />
        <SentryInitializer history={history} />
        {!location.pathname.startsWith('/widgets') && (
          <>
            <GTMInitializer />
            <IntercomInitializer />
            <SegmentInitializer />
          </>
        )}
        {location.pathname.startsWith('/widgets/loan-track') && (
          <>
            <VoluumInitializer />
          </>
        )}
        <Routes />
      </React.Suspense>
      <GlobalStyles />
      <DetectLangComponent />
    </>
  );
});

const getCurrentNav = () => {
  return window.location.href + '::' + modal.opened?.name;
};

setupRefreshOnVisible(true);
setupUseOnVisible(useFocusEffect);
setupGetNavString(getCurrentNav);

const ThemeColor: React.FC = () => {
  const {colors} = useTheme();

  return (
    <Helmet>
      <meta name="theme-color" content={colors['$ui-background']} />
    </Helmet>
  );
};

/** Main application entry point
 *
 */
const App: React.FC<{
  history: History;
  googleClientID: string;
}> = ({history, googleClientID}) => {
  return (
    <SafeAreaProvider style={{width: '100%', minHeight: '100%'}}>
      <AppearanceAndThemeProvider>
        <ThemeColor />
        <DataSourcesProvider>
          <Router history={history}>
            <ModalOpenedContextProvider>
              <NiceModal.Provider>
                <RecaptchaContextProvider>
                  <NiceModal.Provider>
                    <RecaptchaContextProvider>
                      <NiceModal.Provider>
                        <TwoFactorAuthContextProvider>
                          <NotificationContextProvider>
                            <ConfirmationContextProvider>
                              <SumSubVerificationContextProvider>
                                <GoogleOAuthProvider clientId={googleClientID}>
                                  <AppContainer history={history} />
                                </GoogleOAuthProvider>
                              </SumSubVerificationContextProvider>
                            </ConfirmationContextProvider>
                          </NotificationContextProvider>
                        </TwoFactorAuthContextProvider>
                      </NiceModal.Provider>
                    </RecaptchaContextProvider>
                  </NiceModal.Provider>
                </RecaptchaContextProvider>
              </NiceModal.Provider>
            </ModalOpenedContextProvider>
          </Router>
        </DataSourcesProvider>
      </AppearanceAndThemeProvider>
    </SafeAreaProvider>
  );
};

export default App;
