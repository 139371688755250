import {IIntercomService, IUser, User} from './types';
import {lowerCase, startCase} from 'lodash';

declare global {
  interface Window {
    Intercom: Function;
  }
}

type IntercomCallback = (Intercom: typeof window.Intercom) => void;

function throwNotSupported() {
  throw new Error('INTERCOM_SERVICE is not supported on web yet!');
}

export const withIntercom = (cb: IntercomCallback) => {
  if (typeof window !== 'undefined' && window.Intercom) {
    cb(window.Intercom);
  }

  return Promise.resolve();
};

//NOTE: docs https://developers.intercom.com/installing-intercom/web/methods
export class IntercomService implements IIntercomService {
  displayHelpCenter() {
    return withIntercom(Intercom => {
      Intercom('showSpace', 'help');
    });
  }

  displayArticle(articleId: string) {
    return withIntercom(Intercom => {
      Intercom('showArticle', articleId);
    });
  }

  displayMessenger() {
    return withIntercom(Intercom => {
      Intercom('showSpace', 'home');
    });
  }

  displayMessageComposer(message?: string) {
    return withIntercom(Intercom => {
      Intercom('showNewMessage', message);
    });
  }

  hideIntercom() {
    return withIntercom(Intercom => {
      Intercom('hide');
    });
  }

  sendToken(token: string) {
    throwNotSupported();
    return Promise.reject();
  }

  setUserHash(hash: string) {
    throwNotSupported();
    return Promise.reject();
  }

  getUnreadConversationCount() {
    throwNotSupported();
    return Promise.reject();
  }

  subscribeToNotificationCountChange(callback: (count: number) => void) {
    throwNotSupported();
    return {remove: () => {}};
  }

  registerIdentifiedUser(id: string, email: string) {
    throwNotSupported();
    return Promise.reject();
  }

  logout() {
    throwNotSupported();
    return Promise.reject();
  }

  handlePushMessage() {
    throwNotSupported();
    return Promise.reject();
  }

  setUser(user?: IUser) {
    throwNotSupported();
    return Promise.resolve();
  }

  bootIntercom(params: User) {
    const {email, name, id, isDeposit, phone, userHash, appId} = params;

    return withIntercom(Intercom => {
      Intercom('boot', {
        app_id: appId,
        email: email,
        user_hash: userHash,
        ...(phone ? {phone} : {}),
        ...(name ? {name: startCase(lowerCase(name))} : {}),
        ...(id ? {user_id: id} : {}),
        ...(isDeposit ? {Deposited: isDeposit} : {}),
      });
    });
  }

  shutdownIntercom() {
    return withIntercom(Intercom => {
      Intercom('shutdown');
    });
  }
}
