import {computed} from 'mobx';
import {uniq} from 'lodash';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {getVersionText} from '../../../utils';
import {PaymentSystems} from '../../../components/PaymentMethods/types';

export class DepositNowWizardChooseTypeFeature extends createFeature({
  getKey: () => `DepositNowWizardChooseType`,
  getResources: () => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
    };
  },
}) {
  buyCryptoTags: string[] = [
    PaymentSystems.MASTERCARD,
    PaymentSystems.VISA,
    PaymentSystems.APPLE_PAY,
    PaymentSystems.GOOGLE_PAY,
  ];

  @computed
  get depositFiatTags(): string[] {
    const {
      depositFiatCheckoutBankCard: {isEnabled: allowCheckoutBankCard},
      depositFiatWire: {isEnabled: allowBankWire},
      depositFiatAdvcash: {isEnabled: allowAdvCash},
    } = this.resources.authMe.products;

    const list = [];
    if (allowCheckoutBankCard) {
      list.push(
        PaymentSystems.MASTERCARD,
        PaymentSystems.VISA,
        PaymentSystems.APPLE_PAY,
        PaymentSystems.GOOGLE_PAY
      );
    }

    if (allowBankWire) {
      list.push(
        PaymentSystems.IBAN,
        PaymentSystems.ACCOUNT,
        PaymentSystems.SEPA
      );
    }

    if (allowAdvCash) {
      list.push(
        PaymentSystems.MASTERCARD,
        PaymentSystems.VISA,
        PaymentSystems.ACCOUNT
      );
    }

    return uniq(list);
  }

  @computed
  get depositCryptoTags(): string[] {
    const {walletsList} = this.resources.wallets;

    return Object.values(
      walletsList.reduce((acc, {isFiat, ticker, versions}) => {
        if (!isFiat) {
          versions.forEach(({version}) => {
            if (version && !acc[version]) {
              acc[version] = getVersionText(ticker, version);
            }
          });
        }

        return acc;
      }, {} as Record<string, string>)
    );
  }
}
