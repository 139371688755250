import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type TextProps, Text} from '@youtoken/ui.primitives';

interface ReferralsHeaderV3Props {
  percentFormatted: string;
}

export const ReferralsHeaderV3: React.FC<ReferralsHeaderV3Props & TextProps> =
  observer(({percentFormatted, ...textProps}) => {
    const {t} = useTranslation();

    return (
      <Text variant="$heading-01" color="$text-04" {...textProps}>
        {t('surface.referral_program.referrals_and_bonuses.get_up_to_percent', {
          percent: percentFormatted,
        })}
      </Text>
    );
  });
