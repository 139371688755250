import * as React from 'react';
import {LineProps} from 'react-native-svg';
import {useChartThemeContext} from '../../Providers';
import {DashedLine} from '../../Primitives';
import {observer} from 'mobx-react';
import {BaseChartState} from '../../logic';

export const LastValueLine: React.FC<
  {
    chartState: BaseChartState;
    fullWidth?: boolean;
  } & LineProps
> = React.memo(
  observer(({chartState, fullWidth, ...props}) => {
    const {
      width,
      lastValueLabel: {layout},
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    if (!layout) {
      return null;
    }

    return (
      <DashedLine
        vectorEffect="non-scaling-stroke"
        x1={0}
        x2={fullWidth ? width : width - layout.width}
        y1={layout.y}
        y2={layout.y}
        stroke={getColorByDirection(layout.direction)}
        {...props}
      />
    );
  })
);
