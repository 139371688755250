import * as React from 'react';
import {observer} from 'mobx-react';
import {TFAFormField, useForm} from '@youtoken/ui.form-elements';
import {type WithdrawalForm} from '../../../../state/WithdrawalForm';

export const FieldTFA: React.FC = observer(() => {
  const {
    form: {isTFARequired, getOperationData, errorTFA, instance},
  } = useForm<WithdrawalForm>();

  const handleChange = React.useCallback(
    (value: string) => {
      instance.$('twoFactorAuthCode').set('value', value);
    },
    [instance]
  );

  if (!isTFARequired) {
    return null;
  }

  return (
    <TFAFormField
      error={errorTFA}
      getOperationData={getOperationData}
      onChangeCode={handleChange}
    />
  );
});
