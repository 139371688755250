import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {CryptoWithdrawalWizardScreenName} from '../';

interface WithdrawalMethodsFeatureArgs {
  ticker: string;
}

export class CryptoWithdrawalMethodsFeature extends createFeature({
  getKey: (args: WithdrawalMethodsFeatureArgs) =>
    `cryptoWithdrawalMethods(${JSON.stringify(args)})`,
  getResources: () => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      verification: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @computed
  get ticker() {
    return this.args.ticker;
  }

  @computed
  get allowUma() {
    const {
      available,
      settings: {enable, enableWithdrawal},
    } = this.resources.authMe.products.uma;

    return available && enable && enableWithdrawal;
  }

  @computed
  get methodsList(): {
    method: CryptoWithdrawalWizardScreenName;
    disabled?: boolean;
  }[] {
    const list: {
      method: CryptoWithdrawalWizardScreenName;
      disabled?: boolean;
    }[] = [];

    list.push({
      method: CryptoWithdrawalWizardScreenName.WITHDRAWAL,
    });

    if (this.allowUma) {
      list.push({
        method: CryptoWithdrawalWizardScreenName.UMA_WITHDRAWAL,
      });
    }

    list.sort((a, b) => {
      return Number(a.disabled) - Number(b.disabled);
    });

    return list;
  }
}
