import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelInfoItemSavingsProps} from './types';
import {
  AccountLevelInfoItemShort,
  AccountLevelSavingsYeldLimit,
  AccountLevelSavingsUpToAPRBadge,
} from '..';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsSavings');
};

export const AccountLevelInfoItemSavingsShort: React.FC<
  AccountLevelInfoItemSavingsProps & BoxProps
> = observer(
  ({
    level: {
      saving: {limit, maxTickersAPR},
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <AccountLevelInfoItemShort
        testID="ACCOUNT_LEVEL_INFO_ITEM_SAVINGS"
        icon="interest"
        title={t('surface.loyalty.savings')}
        description={<AccountLevelSavingsYeldLimit amount={limit} />}
        badge={
          <AccountLevelSavingsUpToAPRBadge
            percent={maxTickersAPR}
            variant="neutral"
          />
        }
        onPress={handlePress}
        {...boxProps}
      />
    );
  }
);
