import * as React from 'react';
import {Link} from '@youtoken/ui.elements';

export const Toggler: React.FC<{
  value: boolean;
  toggle: () => void;
  text: string;
}> = ({value, toggle, text}) => {
  if (value) {
    return (
      <Link variant="$body-02" onPress={toggle}>
        {text}
      </Link>
    );
  }

  return (
    <Link variant="$body-02" onPress={toggle} mr={5}>
      {text}
    </Link>
  );
};
