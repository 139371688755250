import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const EmailIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 5a2 2 0 012-2h14a2 2 0 012 2v10a2 2 0 01-2 2H3a2 2 0 01-2-2V5zm15.29-.5H3.71l5.467 4.783a1.25 1.25 0 001.646 0L16.29 4.5zM2.5 5.434v9.005l4.803-4.802L2.5 5.434zM3.56 15.5h12.88l-4.888-4.887a2.75 2.75 0 01-3.104 0L3.56 15.5zm13.94-1.06V5.433l-4.803 4.203 4.803 4.802z"
          fill={color}
        />
      </Svg>
    );
  }
);
