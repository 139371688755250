import Big, {BigSource, RoundingMode} from 'big.js';
import {formatPercent, toBig} from '@youtoken/ui.formatting-utils';
import {warning} from '@youtoken/ui.utils';

export const getPercentByAmount = (
  initialAmount: BigSource,
  amount: BigSource,
  rm?: RoundingMode
) => {
  if (
    initialAmount === '' ||
    amount === '' ||
    toBig(initialAmount).eq(0) ||
    toBig(amount).eq(0)
  ) {
    return '';
  }

  let valueInPercents = Big(0);

  try {
    const amountBig = Big(amount);
    valueInPercents = amountBig.minus(initialAmount).div(initialAmount);
  } catch (e) {
    warning(
      false,
      'cannot convert ${amount} to big number',
      {},
      {
        value: amount,
      }
    );
  }

  return formatPercent(valueInPercents, false, rm);
};
