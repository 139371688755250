import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {stringify} from 'query-string';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LoanTurboStatus, LoanTurbo} from '../Loan';

export class LoansListTurboActiveResource extends createResource({
  getKey: () => {
    return `loansTurboActive`;
  },
  getData: () => {
    return TRANSPORT.API.get(`/v1/loan`, {
      params: {
        mode: 'turbo',
        status: [
          LoanTurboStatus.PENDING,
          LoanTurboStatus.PROCESSING,
          LoanTurboStatus.OPEN,
          LoanTurboStatus.CLOSING,
        ],
        sort: 'finishAt',
        order: 'asc',
      },
      paramsSerializer: params => stringify(params, {arrayFormat: 'none'}),
    }).then(response => {
      return deserialize(LoanTurbo, response.data as any[]);
    });
  },
  skipRefreshOnVisible: false,
}) {
  public getById = computedFn((id: string) => {
    return this.data.find(l => l.id === id);
  });
}
