import {computed, observable} from 'mobx';
import {list, object, serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';

export const defaultTicker = 'usd';

class LoyaltyUpgradeOption {
  @observable
  @serializable(number())
  volume!: number;

  @computed
  get volumeFormatted() {
    return this.volume % 1
      ? this.volume.toFixed(getCoinDecimalPrecision(defaultTicker))
      : this.volume.toString();
  }

  @observable
  @serializable(number())
  multiplier!: number;
}

export class UpgradeOption {
  @observable
  @serializable(number())
  operationVolumeAmount!: number;

  @observable
  @serializable(number())
  sparksAmount!: number;

  @observable
  @serializable(number())
  volumeEquivalent!: number;

  @observable
  @serializable(number())
  multiplier!: number;
}

class ActionToUpgrade {
  @observable
  @serializable(object(LoyaltyUpgradeOption))
  loyalty!: LoyaltyUpgradeOption;

  @observable
  @serializable(list(object(UpgradeOption)))
  options?: UpgradeOption[];

  @observable
  @serializable(number())
  sparksAmount!: number;
}

export class MinerUpgradeRequirementsResponse {
  @observable
  @serializable(object(ActionToUpgrade))
  convert!: ActionToUpgrade;

  @observable
  @serializable(object(ActionToUpgrade))
  hodl!: ActionToUpgrade;
}
