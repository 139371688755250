import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {TFAFormField, useForm} from '@youtoken/ui.form-elements';
import {TwoFactorEnableGAFormClass} from '../../form';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {CopyButton, Link} from '@youtoken/ui.elements';

export const StepSecond: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    form: {codeError, codeOnChange, createOperation, secretCode},
  } = useForm<TwoFactorEnableGAFormClass>();

  const [showSecretText, setShowSecretText] = React.useState<boolean>(false);

  const handleClickShowSecretText = React.useCallback(() => {
    setShowSecretText(true);
  }, []);

  return (
    <Box
      pt={8}
      pb={24}
      px={24}
      flexBasis={{default: '100%', tablet: 290}}
      flexGrow={1}
    >
      <Text variant="$body-02-high-accent" mb={4}>
        {t('surface.more.2fa_enable_ga.subtitle.next')}
      </Text>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.more.2fa_enable_ga.description.next')}
      </Text>
      <Text variant="$body-02-high-accent" mb={8}>
        {t('surface.auth.2fa_code.label.ga')}
      </Text>
      <TFAFormField
        authType="ga"
        getOperationData={createOperation}
        onChangeCode={codeOnChange}
        error={codeError}
        showTitle={false}
        px={0}
        py={0}
        mb={8}
        bg="$transparent"
      />

      <Trans
        i18nKey="surface.profile.form_2fa_app_enable.right.instructions"
        style={{
          flexWrap: 'wrap',
          fontSize: '12px',
          lineHeight: '16px',
        }}
        parent={props => (
          <Text variant="$body-02" color="$text-02" {...props} />
        )}
        components={{
          AuthyLink: (
            <Link
              variant="$body-02-medium-accent"
              url={t('url.profile.security.2fa.authy_download.link')}
            />
          ),
          AppLink: (
            <Link
              variant="$body-02-medium-accent"
              url={t('url.profile.security.2fa.authenticator_download.link')}
            />
          ),
        }}
        defaults="Not sure what that means? You may need to install <AuthyLink>Authy</AuthyLink> or <AppLink>Google Authenticator</AppLink>"
      />

      <Box width="100%" mt={8}>
        <>
          {!showSecretText && (
            <Link
              onPress={handleClickShowSecretText}
              variant="$body-02-medium-accent"
            >
              {t('surface.profile.form_2fa_app_enable.right.no_secret_text')}
            </Link>
          )}
          {showSecretText && (
            <>
              <Text variant="$body-02-high-accent" mb={8}>
                {secretCode}
              </Text>
              <CopyButton
                icon="copy"
                message={secretCode}
                messageCopied={t(
                  'surface.profile.form_2fa_app_enable.copy_msg.success'
                )}
              >
                {t('surface.more.2fa_enable_ga.action.copy')}
              </CopyButton>
            </>
          )}
        </>
      </Box>
    </Box>
  );
});
