import {date, list, object, optional, primitive, serializable} from 'serializr';
import Big from 'big.js';
import {computed, observable} from 'mobx';
import {invariant} from '@youtoken/ui.utils';
import {format} from '@youtoken/ui.date-fns';
import {bigNumber, bigNumberNullable} from '@youtoken/ui.utils-serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {i18n, type TKey} from '@youtoken/ui.service-i18n';
import {
  BankCardTransactionStatus,
  BankCardTransactionType,
  statusKeys,
  typeKeys,
} from './constants';

export class BankCardTransactionMerchant {
  @observable
  @serializable(optional(primitive()))
  name?: string;
}

export class BankCardTransaction {
  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(primitive())
  transactionId!: string;

  @observable
  @serializable(primitive())
  ticker!: string;

  @observable
  @serializable(bigNumber())
  amount!: Big;

  @observable
  @serializable(primitive())
  status!: BankCardTransactionStatus;

  @observable
  @serializable(primitive())
  type!: BankCardTransactionType;

  @observable
  @serializable(date())
  createdAt!: Date;

  @observable
  @serializable(object(BankCardTransactionMerchant))
  merchant!: BankCardTransactionMerchant;

  //#region optionals
  @observable
  @serializable(bigNumberNullable())
  fee: Big | null = null;

  @observable
  @serializable(optional(primitive()))
  conversionTicker?: string;

  @observable
  @serializable(optional(bigNumberNullable()))
  conversionAmount: Big | null = null;

  @observable
  @serializable(optional(primitive()))
  version?: string;

  //#endregion optionals

  @computed get typeLabel() {
    if (!this.type || !this.type[0]) {
      return '';
    }

    return i18n.t(typeKeys[this.type] as TKey, {
      defaultValue:
        this.type[0].toUpperCase() +
        this.type.slice(1).toLowerCase() +
        this.transactionId,
    });
  }

  @computed get statusLabel() {
    if (!this.status) {
      return '';
    }

    // @ts-ignore
    return i18n.t(statusKeys[this.status], {
      defaultValue: this.status.toLowerCase(),
    });
  }

  @computed get badgeVariant() {
    return this.status === BankCardTransactionStatus.PROCESSING
      ? 'attention'
      : this.status === BankCardTransactionStatus.FAILED
      ? 'danger'
      : undefined;
  }

  @computed get tickerFormatted(): string {
    return this.ticker.toUpperCase();
  }

  @computed get conversionTickerFormatted(): string | undefined {
    return this.conversionTicker?.toUpperCase();
  }

  @computed get amountStringFormatted() {
    return this.amount.toFixed(getCoinDecimalPrecision(this.ticker));
  }

  @computed get conversionAmountStringFormatted() {
    invariant(this.conversionAmount, 'conversion amount is not defined');

    return this.conversionAmount.toFixed(
      getCoinDecimalPrecision(this.conversionTicker)
    );
  }

  @computed get isConvert() {
    return Boolean(
      this.conversionTicker &&
        this.conversionAmount &&
        this.ticker !== this.conversionTicker
    );
  }

  @computed get isCreatedThisYear() {
    return this.createdAt.getFullYear() === new Date().getFullYear();
  }

  /** Formatted date: day, month, year (if not current)
   * @example
   * '2022-09-07T09:59:36.907Z' => '7 September'
   * '2021-09-07T09:59:36.907Z' => '7 September 2021'
   */
  @computed get createdAtFormatted() {
    return this.isCreatedThisYear
      ? format(this.createdAt, 'd MMMM')
      : format(this.createdAt, 'd MMMM yyyy');
  }

  /** Formatted date: day, month, year (if not current), time
   * @example
   * '2022-09-07T09:59:36.907Z' => '7 September, 09:41'
   * '2022-09-07T09:59:36.907Z' => '7 September 2021, 09:41'
   */
  @computed get createdAtWithTimeFormatted() {
    return this.isCreatedThisYear
      ? format(this.createdAt, 'd MMMM, HH:mm')
      : format(this.createdAt, 'd MMMM yyyy, HH:mm');
  }
}

export class BankCardTransactionsResponse {
  @observable
  @serializable(list(object(BankCardTransaction)))
  rows!: BankCardTransaction[];

  @observable
  @serializable(optional(primitive()))
  hasNext?: boolean;
}
