import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {ArrConnectionImage as arrConnection} from './ArrConnection';
import {ArrConvertImage as arrConvert} from './ArrConvert';
import {ArrFirstImage as arrFirst} from './ArrFirst';
import {ArrLastImage as arrLast} from './ArrLast';
import {ArrSnakeImage as arrSnake} from './ArrSnake';
import {YouHodlerLogoFullImage as youHodlerLogoFull} from './YouHodlerLogoFull';
import {YouHodlerLogoImage as youHodlerLogo} from './YouHodlerLogo';

export type ImageName =
  | 'arrConnection'
  | 'arrConvert'
  | 'arrFirst'
  | 'arrLast'
  | 'arrSnake'
  | 'youHodlerLogoFull'
  | 'youHodlerLogo';

export const imageSet: {
  [key in ImageName]: React.FC<SvgProps>;
} = {
  arrConnection,
  arrConvert,
  arrFirst,
  arrLast,
  arrSnake,
  youHodlerLogoFull,
  youHodlerLogo,
};
