import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {ReferralsSmart} from '../../smarts';

export const RewardsReferralsSurface: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <WebPageWrapper
      windowTitle={t('surface.referral_program.referrals_page.nav_title')}
      title={t('surface.referral_program.referrals_page.title')}
      hasBackButton
      onBackPress={SHARED_ROUTER_SERVICE.goBack}
    >
      <ReferralsSmart />
    </WebPageWrapper>
  );
});
