import {computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import type {BankCardAddMoneyFormResources} from './types';
import {Form} from './Form';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  @observable
  public resources: BankCardAddMoneyFormResources;

  public constructor(form: Form, resources: BankCardAddMoneyFormResources) {
    this.resources = resources;
    this.form = form;
    this.instance = form.instance;
  }

  //#region ticker
  @computed
  get tickerFormatted() {
    return this.form.tickerFormatted;
  }

  @computed
  get tickerName() {
    return getCoinName(this.form.ticker);
  }

  //#endregion ticker

  @computed
  public get minAmountFormatted() {
    return formatByTicker(this.form.minAmount, this.form.ticker);
  }

  @computed
  public get maxAmountFormatted() {
    return formatByTicker(this.form.maxAmount, this.form.ticker);
  }
}
