import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box, Text} from '@youtoken/ui.primitives';
import {useWizard} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {HeaderBase, MethodItem} from '../../../components';
import {
  DepositNowInfo,
  DepositNowInfoVariant,
} from '../../DepositNow/components';
import {DepositNowWizardChooseTypeFeature} from './state';
import {type DepositNowWizardNavigator, DepositNowWizardScreenName} from '..';

export const DepositNowWizardChooseTypeBase: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    checkProductAvailability,
    walletsDisclosureSigned,
    products: {
      depositBuyCrypto: {isEnabled},
    },
  } = AuthMeResource.use({});

  const {
    step: {
      props: {source},
    },
    navigate,
    goBack,
  } = useWizard<
    DepositNowWizardNavigator,
    DepositNowWizardScreenName.CHOOSE_TYPE
  >();

  const {buyCryptoTags, depositFiatTags, depositCryptoTags} =
    DepositNowWizardChooseTypeFeature.use({});

  const handleChange = React.useCallback(
    (method: keyof DepositNowWizardNavigator) => {
      if (method === 'buyCrypto') {
        if (!checkProductAvailability('depositBuyCrypto')) {
          return;
        }
      }

      if (method === 'depositCrypto') {
        if (!checkProductAvailability('depositCrypto')) {
          return;
        }

        if (!walletsDisclosureSigned) {
          return SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {});
        }
      }

      navigate(method, {source});
    },
    [source, walletsDisclosureSigned, checkProductAvailability, navigate]
  );

  const handlePressBuyCrypto = React.useCallback(() => {
    DATA_LAYER.trackStrict('buy-wizard-fork-buy-click', {});

    handleChange(DepositNowWizardScreenName.BUY_CRYPTO);
  }, [handleChange]);

  const handlePressDepositFiat = React.useCallback(() => {
    DATA_LAYER.trackStrict('buy-wizard-fork-deposit-fiat-click', {});

    handleChange(DepositNowWizardScreenName.DEPOSIT_FIAT);
  }, [handleChange]);

  const handlePressDepositCrypto = React.useCallback(() => {
    DATA_LAYER.trackStrict('buy-wizard-fork-deposit-crypto-click', {});

    handleChange(DepositNowWizardScreenName.DEPOSIT_CRYPTO);
  }, [handleChange]);

  return (
    <>
      <HeaderBase onPressBack={goBack}>
        {t('surface.wallets.deposit_wizard.choose_type.title')}
      </HeaderBase>
      <Box>
        <Box px={24} pb={16} borderBottomWidth={1} borderColor="$ui-01">
          <Text variant="$body-02" color="$text-02">
            {t('surface.wallets.deposit_wizard.choose_type.description.fiat')}
          </Text>
        </Box>
        {isEnabled && (
          <MethodItem
            icon={<Icon name="card" color="$text-05" />}
            title={t(
              'surface.wallets.deposit_wizard.choose_type.methods.buy_crypto'
            )}
            tags={buyCryptoTags}
            onPress={handlePressBuyCrypto}
          />
        )}
        <MethodItem
          icon={<Icon name="fiat_deposit" color="$text-05" />}
          title={t(
            'surface.wallets.deposit_wizard.choose_type.methods.deposit_fiat'
          )}
          tags={depositFiatTags}
          onPress={handlePressDepositFiat}
        />
      </Box>
      <Box>
        <Box p={24} pt={24} pb={16} borderBottomWidth={1} borderColor="$ui-01">
          <Text variant="$body-02" color="$text-02">
            {t('surface.wallets.deposit_wizard.choose_type.description.crypto')}
          </Text>
        </Box>
        <MethodItem
          icon={<Icon name="crypto_deposit" color="$text-05" />}
          title={t(
            'surface.wallets.deposit_wizard.choose_type.methods.deposit_crypto'
          )}
          tags={depositCryptoTags}
          onPress={handlePressDepositCrypto}
        />
      </Box>
      <DepositNowInfo
        variant={DepositNowInfoVariant.BUY_CRYPTO}
        p={24}
        m={24}
        borderColor="$ui-01"
        borderRadius={12}
        borderWidth={1}
      />
    </>
  );
});
