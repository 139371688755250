import * as React from 'react';
import {ReferralsRoundedText} from '../..';

interface RewardsBannerCountProps {
  value: number;
}

export const RewardsBannerCount: React.FC<RewardsBannerCountProps> = React.memo(
  ({value}) => {
    if (!value) {
      return null;
    }

    return (
      <ReferralsRoundedText bg="$danger-01" textColor="$text-04">
        +{value}
      </ReferralsRoundedText>
    );
  }
);
