import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';

export interface DetailedActionsAdditionalsProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onPressSetClosePrice: (args: LoansListItemFeatureArgs) => void;
  onPressIncrease: (args: LoansListItemFeatureArgs) => void;
  onPressDecrease: (args: LoansListItemFeatureArgs) => void;
  onPressReopen: (args: LoansListItemFeatureArgs) => void;
}

export const DetailedActionsAdditionals: React.FC<DetailedActionsAdditionalsProps> =
  observer(
    ({
      mode,
      id,
      onPressSetClosePrice,
      onPressIncrease,
      onPressDecrease,
      onPressReopen,
      ...rest
    }) => {
      const {t} = useTranslation();

      const loan = LoansListItemFeature.use({
        mode,
        id,
      });

      const handlePressSetClosePrice = React.useCallback(() => {
        onPressSetClosePrice?.({id: loan.id!, mode: loan.mode});
      }, [onPressSetClosePrice, loan]);

      const handlePressIncrease = React.useCallback(() => {
        onPressIncrease?.({id: loan.id!, mode: loan.mode});
      }, [onPressIncrease, loan]);

      const handlePressDecrease = React.useCallback(() => {
        onPressDecrease?.({id: loan.id!, mode: loan.mode});
      }, [onPressDecrease, loan]);

      const handlePressReopen = React.useCallback(() => {
        onPressReopen?.({id: loan.id!, mode: loan.mode});
      }, [onPressReopen, loan]);

      const Actions = React.useMemo(() => {
        let actions = [[], []] as React.ReactElement[][];

        if (loan.allowIncrease) {
          actions[0]!.push(
            <Button
              type="secondary"
              icon="increase"
              disabled={!loan.enabledIncrease}
              onPress={handlePressIncrease}
              width="100%"
            >
              {t('surface.loans.item.increase')}
            </Button>
          );
        }

        if (loan.allowDecrease) {
          actions[0]!.push(
            <Button
              type="secondary"
              icon="extend"
              disabled={!loan.enabledDecrease}
              onPress={handlePressDecrease}
              width="100%"
            >
              {t('surface.loans.item.extend')}
            </Button>
          );
        }

        if (loan.allowReopen) {
          actions[1]!.push(
            <Button
              type="secondary"
              icon="reopen"
              disabled={!loan.enabledReopen}
              onPress={handlePressReopen}
              width="100%"
            >
              {t('surface.loans.item.reopen')}
            </Button>
          );
        }

        if (loan.allowSetClosePrice) {
          actions[1]!.push(
            <Button
              type="secondary"
              icon="ftp"
              disabled={!loan.enabledSetClosePrice}
              onPress={handlePressSetClosePrice}
              width="100%"
            >
              {t('surface.loans.item.ftp')}
            </Button>
          );
        }

        actions = actions.filter(row => row.length);

        if (!actions.length) {
          return null;
        }

        return actions
          .filter(row => row.length)
          .map((row, rowIndex) => {
            return (
              <Box key={rowIndex} flexDirection="row" flexGrow={1} mx={-5}>
                {row.map((Action, columnIndex) => {
                  return (
                    <Box
                      key={`${rowIndex}:${columnIndex}`}
                      flexGrow={1}
                      flexBasis={1}
                      m={5}
                    >
                      {Action}
                    </Box>
                  );
                })}
              </Box>
            );
          });
      }, [
        t,
        loan.allowIncrease,
        loan.allowDecrease,
        loan.allowReopen,
        loan.allowSetClosePrice,
        loan.enabledIncrease,
        loan.enabledDecrease,
        loan.enabledReopen,
        loan.enabledSetClosePrice,
        handlePressSetClosePrice,
        handlePressIncrease,
        handlePressDecrease,
        handlePressReopen,
      ]);

      if (!loan.id) {
        console.error(`Loan id=${id} not found`);
        return null;
      }

      return (
        <>
          {Actions && (
            <Box width="100%" p={20} {...rest}>
              <Box width="100%" my={-5}>
                {Actions}
              </Box>
            </Box>
          )}
        </>
      );
    }
  );
