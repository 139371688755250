import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Modal} from '@youtoken/ui.modal';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ModalNotice} from '../ModalNotice';
import {TradeToUpgradeModalContent} from '@youtoken/ui.surface-account-levels';

type GetMoreSparksModalProps = {
  title: string;
  noticeText?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const GetMoreSparksModal: React.FC<GetMoreSparksModalProps> = observer(
  ({title, noticeText, isOpen, onClose}) => {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        snapPoints={['CONTENT_HEIGHT']}
        shouldUseAnimatedSnapPoints
        title={title}
        shouldShowHeaderSeparator={false}
      >
        {noticeText && <ModalNotice text={noticeText} mt={-1} mb={16} />}
        <TradeToUpgradeModalContent
          variant="sparks"
          px={24}
          pb={24}
          onClose={onClose}
        />
      </Modal>
    );
  }
);

export const GetMoreSparksModalTrigger: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    DATA_LAYER.trackStrict('miner-get-more', {
      category: 'miner',
      type: 'get-more',
    });

    setModalOpen(true);
  }, [setModalOpen]);

  const onModalClose = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <Button
        size="large"
        width="100%"
        mt={16}
        onPress={openModal}
        icon="spark"
        testID="GET_MORE_SPARKS_BUTTON"
      >
        {t('surface.upgrade_modal.sparks.title')}
      </Button>
      <GetMoreSparksModal
        title={t('surface.upgrade_modal.sparks.title')}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />
    </>
  );
});
