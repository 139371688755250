import {iconSet, type IconName} from '../__generated/Icon';
import {createIconComponent} from '../createIcon';

export {iconSet};
export type {IconName};

export const Icon = createIconComponent<IconName, typeof iconSet>({
  componentName: 'Icon',
  iconSet: iconSet,
  defaultSize: 20,
  defaultColor: '$text-01',
});
