import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {TwoFactorEnableGAFormClass} from '../../form';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CopyButton, QRCode} from '@youtoken/ui.elements';

export const StepFirst: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    form: {otpAuth, code, secretCode},
  } = useForm<TwoFactorEnableGAFormClass>();

  // NOTE:
  // if we call createOperation action on the first form render - we don't need this check
  // if we get operation data in TFAFormField, we need this check
  if (!otpAuth) {
    return null;
  }

  return (
    <Box pt={8} pb={24} px={24} flexBasis={{default: 'auto', tablet: 220}}>
      <Text mb={5} variant="$body-02-high-accent">
        {t('surface.more.2fa_enable_ga.subtitle.first')}
      </Text>
      <Box visible={{default: false, tablet: true}}>
        <Text variant="$body-02" color="$text-02" mb={15}>
          {t('surface.profile.form_2fa_app_enable.left.text')}
        </Text>
        <Box m={-8}>
          <QRCode value={otpAuth} size={160} />
        </Box>
      </Box>
      <Box width="100%" visible={{default: true, tablet: false}}>
        <Text color="$text-02" variant="$body-02" mb={5}>
          {t('surface.more.2fa_enable_ga.description.first')}
        </Text>
        <Text variant="$body-02-high-accent" mb={10}>
          {secretCode}
        </Text>
        <CopyButton
          icon="copy"
          message={secretCode}
          messageCopied={t(
            'surface.profile.form_2fa_app_enable.copy_msg.success'
          )}
          type="secondary"
          width={90}
        >
          {t('surface.more.2fa_enable_ga.action.copy')}
        </CopyButton>
      </Box>
    </Box>
  );
});
