import * as React from 'react';
import PropTypes from 'prop-types';
import {PanResponder, Animated, StyleSheet, Platform} from 'react-native';

const PERSPECTIVE = Platform.select({
  default: 2.38,
  android: 1.7,
});

const TR_POSITION = Platform.select({
  default: 2,
  android: 1.5,
});

const PLATFORM_LOOP_SUPPORTED = Platform.select({
  default: true,
  android: false,
});

export default class CubeEffect extends React.Component {
  constructor(props) {
    super(props);

    const {width} = this.props.size;

    this.pages = this.props.children.map((child, index) => width * -index);
    this.fullWidth = (this.props.children.length - 1) * width;

    this.state = {
      currentPage: this.props.currentPage,
      scrollLockPage: this.pages[this.props.scrollLockPage],
    };
  }

  UNSAFE_componentWillMount() {
    const {width} = this.props.size;

    this._animatedValue = new Animated.ValueXY();
    this._animatedValue.setValue({x: 0, y: 0});
    this._value = {x: 0, y: 0};

    this._animatedValue.addListener(value => {
      this._value = value;
    });

    const onDoneSwiping = gestureState => {
      if (this.props.callbackOnSwipe) {
        this.props.callbackOnSwipe(false);
      }

      let mod = gestureState.dx > 0 ? 100 : -100;

      const currentPage = Math.abs(this._closestPage(this._value.x + mod));
      const currentPageX = this.pages[currentPage];

      this._animatedValue.flattenOffset({
        x: this._value.x,
        y: this._value.y,
      });

      Animated.spring(this._animatedValue, {
        toValue: {x: currentPageX, y: 0},
        friction: 5,
        tension: 0.6,
        useNativeDriver: false,
      }).start();

      setTimeout(() => {
        this.setState({
          currentPage,
        });
        if (this.props.callBackAfterSwipe)
          this.props.callBackAfterSwipe(currentPage);
      }, 500);
    };

    this._panResponder = PanResponder.create({
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => {
        return Math.abs(gestureState.dx) > this.props.responderCaptureDx;
      },
      onPanResponderGrant: () => {
        if (this.props.callbackOnSwipe) {
          this.props.callbackOnSwipe(true);
        }
        this._animatedValue.stopAnimation();
        this._animatedValue.setOffset({x: this._value.x, y: this._value.y});
      },
      onPanResponderMove: (e, gestureState) => {
        if (this.props.loop) {
          if (gestureState.dx < 0 && this._value.x < -this.fullWidth) {
            this._animatedValue.setOffset({x: width});
          } else if (gestureState.dx > 0 && this._value.x > 0) {
            this._animatedValue.setOffset({x: -(this.fullWidth + width)});
          }
        }
        Animated.event([null, {dx: this._animatedValue.x}], {
          useNativeDriver: false,
        })(e, gestureState);
      },
      onPanResponderRelease: (e, gestureState) => {
        onDoneSwiping(gestureState);
      },
      onPanResponderTerminate: (e, gestureState) => {
        onDoneSwiping(gestureState);
      },
    });

    this.scrollTo(this.state.currentPage, false);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      scrollLockPage: props.scrollLockPage
        ? this.pages[props.scrollLockPage]
        : undefined,
    });
  }

  /*
      @page: index
    */
  scrollTo(page, animated) {
    animated = animated == undefined ? true : animated;

    if (animated) {
      Animated.spring(this._animatedValue, {
        toValue: {x: this.pages[page], y: 0},
        friction: 5,
        tension: 0.6,
        useNativeDriver: false,
      }).start();
    } else {
      this._animatedValue.setValue({x: this.pages[page], y: 0});
    }
    this.setState({
      currentPage: page,
    });
  }

  /*
    Private methods
    */

  _loopVariable = (variable, sign = 1) => {
    return variable + Math.sign(sign) * (this.fullWidth + width);
  };

  _padInput = variables => {
    if (!PLATFORM_LOOP_SUPPORTED || !this.props.loop) {
      return variables;
    }

    const returnedVariables = [...variables];
    returnedVariables.unshift(
      ...variables.map(variable => this.loopVariable(variable, -1))
    );
    returnedVariables.push(
      ...variables.map(variable => this.loopVariable(variable, 1))
    );
    return returnedVariables;
  };

  _padOutput = variables => {
    if (!PLATFORM_LOOP_SUPPORTED || !this.props.loop) {
      return variables;
    }

    const returnedVariables = [...variables];
    returnedVariables.unshift(...variables);
    returnedVariables.push(...variables);
    return returnedVariables;
  };

  _getTransformsFor = i => {
    const {width} = this.props.size;

    let scrollX = this._animatedValue.x;
    let pageX = -width * i;

    let translateX = scrollX.interpolate({
      inputRange: this._padInput([pageX - width, pageX, pageX + width]),
      outputRange: this._padOutput([
        (-width - 1) / TR_POSITION,
        0,
        (width + 1) / TR_POSITION,
      ]),
      extrapolate: 'clamp',
    });

    let rotateY = scrollX.interpolate({
      inputRange: this._padInput([pageX - width, pageX, pageX + width]),
      outputRange: this._padOutput(['-60deg', '0deg', '60deg']),
      extrapolate: 'clamp',
    });

    let translateXAfterRotate = scrollX.interpolate({
      inputRange: this._padInput([
        pageX - width,
        pageX - width + 0.1,
        pageX,
        pageX + width - 0.1,
        pageX + width,
      ]),
      outputRange: this._padOutput([
        -width - 1,
        (-width - 1) / PERSPECTIVE,
        0,
        (width + 1) / PERSPECTIVE,
        +width + 1,
      ]),
      extrapolate: 'clamp',
    });

    let opacity = scrollX.interpolate({
      inputRange: this._padInput([
        pageX - width,
        pageX - width + 10,
        pageX,
        pageX + width - 250,
        pageX + width,
      ]),
      outputRange: this._padOutput([0, 0.6, 1, 0.6, 0]),
      extrapolate: 'clamp',
    });

    return {
      transform: [
        {perspective: width},
        {translateX},
        {rotateY: rotateY},
        {translateX: translateXAfterRotate},
      ],
      opacity: opacity,
    };
  };

  _renderChild = (child, i) => {
    const {width, height} = this.props.size;

    let expandStyle = this.props.expandView
      ? {paddingTop: 100, paddingBottom: 100, height: height + 200}
      : {width, height};
    let style = [child.props.style, expandStyle];
    let props = {
      i,
      style,
    };
    let element = React.cloneElement(child, props);

    return (
      <Animated.View
        style={[
          StyleSheet.absoluteFill,
          {backgroundColor: 'transparent'},
          this._getTransformsFor(i, false),
        ]}
        key={`child- ${i}`}
        // pointerEvents={this.state.currentPage == i ? 'auto' : 'none'}
      >
        {element}
      </Animated.View>
    );
  };

  _closestPage = num => {
    let array = this.pages;
    let i = 0;
    let minDiff;
    let ans;
    for (i in array) {
      let m = Math.abs(num - array[i]);
      if (m < minDiff || minDiff === undefined) {
        minDiff = m;
        ans = i;
      }
    }
    return ans;
  };

  render() {
    const {width, height} = this.props.size;

    let expandStyle = this.props.expandView
      ? {top: -100, left: 0, width, height: height + 200}
      : {width, height};

    return (
      <Animated.View
        style={styles.cube}
        ref={view => {
          this._scrollView = view;
        }}
        {...this._panResponder.panHandlers}
      >
        <Animated.View style={[styles.blackFullScreen, expandStyle]}>
          {this.props.children.map(this._renderChild)}
        </Animated.View>
      </Animated.View>
    );
  }
}

CubeEffect.propTypes = {
  callBackAfterSwipe: PropTypes.func,
  callbackOnSwipe: PropTypes.func,
  scrollLockPage: PropTypes.number,
  responderCaptureDx: PropTypes.number,
  expandView: PropTypes.bool,
  loop: PropTypes.bool,
  children: PropTypes.array,
};

CubeEffect.defaultProps = {
  responderCaptureDx: 60,
  expandView: false,
  loop: false,
};

const styles = StyleSheet.create({
  cube: {
    flex: 1,
  },
  blackFullScreen: {
    backgroundColor: '#000',
    position: 'absolute',
  },
});
