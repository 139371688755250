import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';

export type CommonInfoProps = {};

export const CommonInfo: React.FC<CommonInfoProps> = observer(() => {
  const {t} = useTranslation();

  return (
    <Text mb={24}>
      <Trans
        t={t}
        i18nKey="surface.more.legal.title"
        style={{
          flexWrap: 'wrap',
        }}
        components={{
          BoldText: <Text variant="$body-02" fontWeight="bold" />,
          SmallText: <Text variant="$body-02" color="$text-02" />,
        }}
        defaults="<BoldText>YOUHODLER BRAND</BoldText> <SmallText>- services are segregated by products</SmallText>"
      />
    </Text>
  );
});
