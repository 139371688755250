import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {PaymentStatus} from '@youtoken/ui.elements';
import {Helmet} from './Helmet';

type DepositStatusProps = {
  status: 'success' | 'failed' | 'canceled' | 'processing';
};

export const DepositStatus: React.FC<DepositStatusProps & BoxProps> = cell(
  ({status, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <>
        <Helmet />
        <Box
          height="100%"
          flex={1}
          justifyContent="space-around"
          alignItems="center"
          backgroundColor="$ui-overflow"
          p={24}
          {...boxProps}
        >
          <PaymentStatus
            status={status}
            text={t(`screens.status.deposit.${status}.title_nicer`)}
            subtitle={t(`screens.status.deposit.${status}.subtitle_nicer`)}
          />
        </Box>
      </>
    );
  }
);
