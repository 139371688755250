import * as React from 'react';
import {RecaptchaV2Component} from './v2';
import {RecaptchaV3Component} from './v3';
import {shouldFallbackToV2Recaptcha} from './utils/shouldFallbackToV2';
import {RecaptchaMethods} from './utils/recaptchaMethods';

type WithTokenCallback = (token: string) => Promise<any>;

/** Recaptcha class component with common interface for web and mobile;
 * class because refs are easier this way;
 * requires
 * - "action", like `'sign-in'`, `'sign-up'`, etc..;
 * - RecaptchaV2Component: class component with requestToken() fn for v2 token;
 * - RecaptchaV3Component: class component with requestToken() fn for v3 token;
 *
 * @example
 * ```typescript
 * // create ref
 * const recaptcha = useRef<Recaptcha>(null);
 *
 * // call somewhere
 * const fn = useCallback(() => {
 *   recaptcha.current!.withToken((token: string) => {
 *     return asyncFnWithToken(token);
 *   });
 * },[]);
 *
 * // render component
 * <Recaptcha action="sign-in" ref={recaptcha}/>
 *
 * ```
 */
export class RecaptchaComponent
  extends React.PureComponent
  implements RecaptchaMethods
{
  v2 = React.createRef<RecaptchaMethods>();
  v3 = React.createRef<RecaptchaMethods>();

  /** request v2 token */
  requestTokenV2 = (action: string) => {
    return this.v2.current!.requestToken(action);
  };

  /* request v3 token */
  requestTokenV3 = (action: string) => {
    return this.v3.current!.requestToken(action);
  };

  /** back-compat fn, just a promise
   *
   * @description
   * - try get v3 token
   * - if __recaptcha__ failed to initialize or whatever - show v2 bottom sheet;
   *   - does not validates v3 token on server, so will not solve attack problems
   *
   */
  requestToken = (action: string) => {
    return this.requestTokenV3(action).catch(error => {
      if (!shouldFallbackToV2Recaptcha(error)) {
        // in case of connection troubles "shouldFallbackToV2Recaptcha" function will get "false"
        // and an error will be shown
        throw error;
      }

      return this.requestTokenV2(action);
    });
  };

  /** run async fn with v2 token  */
  withTokenV2 = (action: string, callback: WithTokenCallback) => {
    return this.requestTokenV2(action).then(tokenV2 => callback(tokenV2));
  };

  /** run async fn with v3 token  */
  withTokenV3 = (action: string, callback: WithTokenCallback) => {
    return this.requestTokenV3(action).then(tokenV3 => callback(tokenV3));
  };

  /** run async fn with token:
   * - will try to get v3 token (invisible for user);
   * - will try to run callback (api request) with v3 token;
   * - if callback failed with validation error (captcha failed) will show v2 recaptcha
   */
  withToken = (action: string, callback: WithTokenCallback) => {
    return this.withTokenV3(action, callback).catch(error => {
      if (!shouldFallbackToV2Recaptcha(error)) {
        throw error;
      }

      return this.withTokenV2(action, callback);
    });
  };

  render() {
    return (
      <>
        <RecaptchaV2Component ref={this.v2} />
        <RecaptchaV3Component ref={this.v3} />
      </>
    );
  }
}
