import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ConvertIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          d="M32.063 13.257l-6.394-5.755C25.025 6.922 24 7.38 24 8.245V12H7.5a1.5 1.5 0 000 3h23.894c.916 0 1.35-1.13.669-1.743zM5.937 26.743l6.394 5.755c.644.58 1.669.122 1.669-.743V28h16.5a1.5 1.5 0 000-3H6.606c-.917 0-1.35 1.13-.669 1.743z"
          fill={color}
        />
      </Svg>
    );
  }
);
