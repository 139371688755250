import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {RewardsBannerVariant} from './types';

export interface RewardsBannerLayoutProps {
  variant: RewardsBannerVariant;
}

export const RewardsBannerLayout: React.FC<RewardsBannerLayoutProps> = ({
  variant,
  children,
}) => {
  const handlePress = React.useCallback(() => {
    DATA_LAYER.trackStrict('reward-widget-click', {});
    SHARED_ROUTER_SERVICE.navigate('RewardsMain');
  }, []);

  return (
    <TouchableBox
      testID="REWARDS_WIDGET"
      height={variant === 'row' ? 64 : 102}
      px={16}
      overflow="hidden"
      bg="$interactive-02"
      borderRadius={16}
      onPress={handlePress}
    >
      <Box position="absolute" right={-28} bottom={-23}>
        <Icon name="gift" size={103} color="$interactive-03" />
      </Box>
      {children}
    </TouchableBox>
  );
};
