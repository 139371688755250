import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, Text, BoxProps} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';

interface InstrumentSelectorRateProps extends BoxProps {
  baseTicker: string;
  quoteTicker: string;
}

export const InstrumentSelectorRate: React.FC<InstrumentSelectorRateProps> =
  observer(({baseTicker, quoteTicker, ...boxProps}) => {
    const {getDiffAndFormattedRate} = HODLsTariffsFeature.use({});
    const {diffPercent, diffPercentFormatted, rateFormatted} =
      getDiffAndFormattedRate(baseTicker, quoteTicker);
    const badgeVariant = diffPercent.gte(0) ? 'success' : 'danger';

    return (
      <Box
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        {...boxProps}
      >
        <Text>{rateFormatted}</Text>
        <Badge size="tiny" variant={badgeVariant} ml={10}>
          {diffPercentFormatted}
        </Badge>
      </Box>
    );
  });
