import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Back} from '@youtoken/ui.surface-loans';

export const AppLedgerNotFoundLayout: React.FC = ({children}) => {
  return (
    <Box width="100%" height="100%">
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={58}
        px={24}
        py={12}
        zIndex={1}
        borderBottomWidth={1}
        borderColor="$ui-01"
      >
        <Back mb={0} />
      </Box>
      <Box flexGrow={1}>{children}</Box>
    </Box>
  );
};
