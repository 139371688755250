import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {FiatWithdrawalChooseMethodBase} from './index.base';

export const FiatWithdrawalChooseMethod: React.FC = observer(() => {
  return (
    <Box pb={32}>
      <FiatWithdrawalChooseMethodBase />
    </Box>
  );
});
