export const acquisitionSurveyData = {
  value: 'common.survey.acquisition_survey.survey_header',
  question: 'common.survey.acquisition_survey.how_you_discover_question',
  options: [
    {
      value: 'common.survey.acquisition_survey.search_engine_answer',
      question:
        'common.survey.acquisition_survey.can_you_remember_where_question',
      options: [
        {
          value: 'common.survey.acquisition_survey.google_search_answer',
        },
        {
          value: 'Bing',
        },
        {
          value: 'Yahoo',
        },
        {
          value: 'common.survey.acquisition_survey.other_answer',
          question: 'common.survey.acquisition_survey.can_you_specify_question',
        },
        {
          value: 'common.survey.acquisition_survey.i_dont_remember_answer',
        },
      ],
    },
    {
      value: 'common.survey.acquisition_survey.social_media_answer',
      question:
        'common.survey.acquisition_survey.can_you_remember_where_question',
      options: [
        {
          value: 'Twitter',
        },
        {
          value: 'Instagram',
        },
        {
          value: 'Facebook',
        },
        {
          value: 'YouTube',
        },
        {
          value: 'TikTok',
        },
        {
          value: 'Reddit',
        },
        {
          value: 'common.survey.acquisition_survey.other_answer',
          question: 'common.survey.acquisition_survey.can_you_specify_question',
        },
        {
          value: 'common.survey.acquisition_survey.i_dont_remember_answer',
        },
      ],
    },
    {
      value: 'common.survey.acquisition_survey.advertisement_answer',
      question:
        'common.survey.acquisition_survey.can_you_remember_where_ad_question',
      options: [
        {
          value: 'common.survey.acquisition_survey.mobile_app_ads_answer',
        },
        {
          value: 'Facebook',
        },
        {
          value: 'Instagram',
        },
        {
          value: 'Google',
        },
        {
          value: 'Twitter',
        },
        {
          value: 'Reddit',
        },
        {
          value: 'TikTok',
        },
        {
          value: 'common.survey.acquisition_survey.other_answer',
          question: 'common.survey.acquisition_survey.can_you_specify_question',
        },
        {
          value: 'common.survey.acquisition_survey.i_dont_remember_answer',
        },
      ],
    },
    {
      value: 'common.survey.acquisition_survey.friend_or_colleague_answer',
    },
    {
      value: 'common.survey.acquisition_survey.other_answer',
      question: 'common.survey.acquisition_survey.can_you_specify_question',
    },
    {
      value: 'common.survey.acquisition_survey.i_dont_remember_answer',
    },
  ],
};
