import * as React from 'react';
import {observer} from 'mobx-react';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {TextInput} from '@youtoken/ui.inputs';
import {UmaValidationAnimatedComponent} from '../UmaValidationAnimatedComponent';
import {UmaWithdrawalForm} from '../../form';

export const UmaInput: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {uma, setUma, isUmaValid},
    view: {umaError},
  } = useForm<UmaWithdrawalForm>();

  return (
    <Box {...boxProps} testID="UMA_WITHDRAW_ADDRESS_INPUT_FIELD">
      <Box flexDirection="row" alignItems="center" mb={8}>
        <Label mr={4}>{t('surface.wallets.uma.uma_input.label')}</Label>
        <QuestionTooltip
          content={t('surface.wallets.uma.withdrawal.uma_input.tooltip')}
        />
      </Box>
      <TextInput
        placeholder={t('surface.wallets.uma.uma_input_placeholder')}
        onChangeText={setUma}
        value={uma}
        autoCapitalize="none"
        autoCorrect={false}
        autoComplete="off"
        keyboardType="email-address"
        LeftPartComponent={
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width={8}
            height="100%"
            ml={16}
            mr={2}
          >
            <Text variant="$body-01" color="$text-02">
              $
            </Text>
          </Box>
        }
        testID="UMA_WITHDRAW_ADDRESS_INPUT"
      />
      <FieldErrorMessage visible={Boolean(umaError)}>
        {umaError}
      </FieldErrorMessage>
      <UmaValidationAnimatedComponent visible={isUmaValid} />
    </Box>
  );
});
