import {action, computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {Form} from './Form';
import {
  type FrameCardValidationChangedEventProps,
  type FrameValidationChangedEventProps,
} from '../types';

export class View {
  @observable
  public instance: MobxReactForm;

  @observable
  public form: Form;

  public constructor(form: Form) {
    this.form = form;
    this.instance = form.instance;
  }

  @observable
  public showCardNumberError?: boolean = false;

  @observable
  public showExpiryDateError?: boolean = false;

  @observable
  public showCvvError?: boolean = false;

  @computed
  public get isFormValid() {
    const hasErrorInBillingDetailsFields = [
      'cardHolderName',
      'countryCode',
      'city',
      'zipCode',
      'addressLine1',
    ].some(item => {
      return (
        this.instance.$(item).isEmpty || Boolean(this.instance.$(item).error)
      );
    });

    return this.form.isCardDataValid && !hasErrorInBillingDetailsFields;
  }

  @computed
  public get cardHolderName() {
    return this.instance.$('cardHolderName').get('value');
  }

  @computed
  public get cardHolderNameError() {
    return this.instance.$('cardHolderName').error;
  }

  @action
  public onCardHolderNameBlur = () => {
    this.showCardNumberError = true;
  };

  @action
  public onCardNumberBlur = () => {
    this.showCardNumberError = true;
  };

  @action
  public onExpiryDateBlur = () => {
    this.showExpiryDateError = true;
  };

  @action
  public onCvvBlur = () => {
    this.showCvvError = true;
  };

  @computed
  public get countryCode() {
    return this.instance.$('countryCode').get('value');
  }

  @computed
  public get countryCodeError() {
    return this.instance.$('countryCode').error;
  }

  @computed
  public get city() {
    return this.instance.$('city').get('value');
  }

  @computed
  public get cityError() {
    return this.instance.$('city').error;
  }

  @action
  public onCityBlur = () => {
    this.instance.$('city').set('value', this.city.trim());
  };

  @computed
  public get state() {
    return this.instance.$('state').get('value');
  }

  @computed
  public get stateError() {
    return this.instance.$('state').error;
  }

  @action
  public onStateBlur = () => {
    this.instance.$('state').set('value', this.state.trim());
  };

  @computed
  public get zipCode() {
    return this.instance.$('zipCode').get('value');
  }

  @computed
  public get zipCodeError() {
    return this.instance.$('zipCode').error;
  }

  @action
  public onZipCodeBlur = () => {
    this.instance.$('zipCode').set('value', this.zipCode.trim());
  };

  @computed
  public get addressLine1() {
    return this.instance.$('addressLine1').get('value');
  }

  @computed
  public get addressLine1Error() {
    return this.instance.$('addressLine1').error;
  }

  @action
  public onAddressLine1Blur = () => {
    this.instance.$('addressLine1').set('value', this.addressLine1.trim());
  };

  @computed
  public get addressLine2() {
    return this.instance.$('addressLine2').get('value');
  }

  @computed
  public get addressLine2Error() {
    return this.instance.$('addressLine2').error;
  }

  @action
  public onAddressLine2Blur = () => {
    this.instance.$('addressLine2').set('value', this.addressLine2.trim());
  };

  @action
  public onCardValidationChanged = (
    data: FrameCardValidationChangedEventProps
  ) => {
    this.form.isCardDataValid = data.isValid;
  };

  @action
  public onFrameValidationChanged = ({
    isValid,
    element,
  }: FrameValidationChangedEventProps) => {
    switch (element) {
      case 'expiry-date': {
        this.form.isValidExpiryDate = isValid;
        break;
      }
      case 'card-number': {
        this.form.isValidCardNumber = isValid;
        break;
      }
      case 'cvv': {
        this.form.isValidCvv = isValid;
        break;
      }
    }
  };
}
