import {serializable, primitive, object} from 'serializr';
import {observable} from 'mobx';
import {Big} from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';

export class ReceiverData {
  @observable
  @serializable(primitive())
  ticker!: string;

  @observable
  @serializable(bigNumber())
  minAmount!: Big;

  @observable
  @serializable(bigNumber())
  maxAmount!: Big;
}

export class SenderData {
  @observable
  @serializable(bigNumber())
  minAmount!: Big;

  @observable
  @serializable(bigNumber())
  maxAmount!: Big;
}

export class CheckRecipientResponse {
  @observable
  @serializable(object(ReceiverData))
  receiver!: ReceiverData;

  @observable
  @serializable(object(SenderData))
  sender!: SenderData;

  @observable
  @serializable(bigNumber())
  rate!: Big;

  @observable
  @serializable(primitive())
  invoiceRequestId!: string;
}
