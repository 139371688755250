import * as React from 'react';
import {Box, Text, TouchableText} from '@youtoken/ui.primitives';
import {useCountdown} from '@youtoken/ui.hooks';
import {useRecaptcha} from '../../../Recaptcha';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const ResendCode: React.FC<{
  operationId: string;
  initialCountdownSeconds?: number;
}> = ({operationId, initialCountdownSeconds = 45}) => {
  const [isResending, setIsResending] = React.useState(false);
  const [countdownLeft, countdown] = useCountdown(
    initialCountdownSeconds * 1000
  );
  const {withToken} = useRecaptcha();

  const {t} = useTranslation();

  React.useEffect(() => {
    countdown.start();
  }, []);

  const handleReset = React.useCallback(() => {
    setIsResending(true);
    withToken<null>('refresh_operation', token => {
      return TRANSPORT.API.post('/v1/security/refresh-operation', {
        operationId,
        token,
      });
    })
      .then(() => {
        countdown.start();
        return Promise.resolve(null);
      })
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({
          text: 'Failed to resend code',
        });
      })
      .finally(() => {
        setIsResending(false);
      });
  }, [operationId, countdown, setIsResending]);

  return (
    <Box justifyContent="center" alignItems="center" mb={16}>
      <Text variant="$body-02" color="$text-02">
        {t('surface.2fa_form.not_recived')}{' '}
        {isResending && (
          <Text color="$text-01">{t('surface.2fa.resending')}</Text>
        )}
        {!isResending && countdownLeft > 0 && (
          <Text color="$text-01">
            {t('surface.2fa_form.resend_in', {leftTime: countdownLeft / 1000})}
          </Text>
        )}
        {!isResending && countdownLeft <= 0 && (
          <TouchableText color="$interactive-01" onPress={handleReset}>
            {t('surface.2fa_form.resend')}
          </TouchableText>
        )}
      </Text>
    </Box>
  );
};
