import React from 'react';
import {observer} from 'mobx-react';
import {
  withSpring,
  useSharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import {throttle} from 'lodash';
import {Notification as NotificationItem} from '@youtoken/ui.resource-notifications';
import {type BoxProps, ReanimatedBox} from '@youtoken/ui.primitives';
import {Notification} from '../Notification';

export interface NotificationAnimatedProps {
  notification: NotificationItem;
  onShow?: (id: string) => void;
  onClose?: (id: string) => void;
}

export const NotificationAnimated: React.FC<
  NotificationAnimatedProps & BoxProps
> = observer(({notification, onShow, onClose, ...props}) => {
  const {notificationId, showed} = notification;

  const progress = useSharedValue<number>(showed ? 1 : 0);
  const height = useSharedValue<number | null>(showed ? null : 0);

  const wrapperAnimatedStyle = useAnimatedStyle(() => {
    if (height.value === null) {
      return {};
    }

    return {
      height: withSpring(progress.value * height.value, {
        overshootClamping: true,
      }),
    };
  }, [height]);

  const contentAnimatedStyle = useAnimatedStyle(() => {
    if (height.value === null) {
      return {};
    }

    return {
      opacity: withSpring(progress.value),
      transform: [
        {
          translateY: withSpring((progress.value - 1) * height.value, {
            overshootClamping: true,
          }),
        },
      ],
    };
  }, [height]);

  const handleLayout = React.useCallback(
    throttle(
      // apparently, layout event is not accessible from sometimes because of throttle?..
      event => {
        const layout = event?.nativeEvent?.layout;

        if (!layout) {
          return;
        }

        if (height.value !== layout.height) {
          height.value = layout.height;
        }
      },
      16
    ),
    []
  );

  const handleShow = React.useCallback(() => {
    progress.value = 1;

    onShow?.(notificationId);
  }, [notificationId, onShow]);

  const handleClose = React.useCallback(() => {
    progress.value = 0;

    onClose?.(notificationId);
  }, [notificationId, onClose]);

  React.useEffect(() => {
    if (!showed) {
      handleShow();
    }
  }, [showed, handleShow]);

  return (
    <ReanimatedBox style={wrapperAnimatedStyle} {...props}>
      <Notification
        data={notification}
        onPressClose={handleClose}
        opacity={0}
      />

      <ReanimatedBox
        style={contentAnimatedStyle}
        position="absolute"
        top={0}
        left={0}
        right={0}
        onLayout={handleLayout}
      >
        <Notification data={notification} onPressClose={handleClose} />
      </ReanimatedBox>
    </ReanimatedBox>
  );
});
