import * as React from 'react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {SuccessIcon} from './SuccessIcon';
import {ProcessingIcon} from './ProcessingIcon';
import {FailedIcon} from './FailedIcon';

type PaymentStatusProps = {
  text: string;
  subtitle: string;
  status: 'success' | 'failed' | 'canceled' | 'processing';
};

export const PaymentStatus: React.FC<PaymentStatusProps & BoxProps> = ({
  text,
  subtitle,
  status,
  children,
  ...boxProps
}) => {
  const icon = {
    success: <SuccessIcon />,
    canceled: <FailedIcon color="$text-02" />,
    failed: <FailedIcon color="$danger-04" />,
    processing: <ProcessingIcon />,
  }[status];

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      p={24}
      backgroundColor="$ui-background"
      borderRadius={12}
      width={{default: '100%', desktop: 390}}
      {...boxProps}
    >
      {icon}

      <Heading variant="$heading-02" textAlign="center">
        {text}
      </Heading>

      <Text mt={8} variant="$body-01" color="$text-02">
        {subtitle}
      </Text>

      {children}
    </Box>
  );
};
