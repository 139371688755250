import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';

type MarkLabelProps = {
  labelPosition: 'flex-start' | 'flex-end' | 'center';
  label: string;
  secondaryLabel?: string;
};

export const MarkLabel: React.FC<MarkLabelProps> = ({
  labelPosition,
  label,
  secondaryLabel,
}) => {
  return (
    <Box alignItems="center" position="absolute" top={8}>
      <Box
        alignItems={labelPosition}
        position="absolute"
        left={labelPosition === 'flex-start' ? 0 : undefined}
        right={labelPosition === 'flex-end' ? 0 : undefined}
      >
        <Text variant="$body-03" color="$text-02">
          {label}
        </Text>
        {secondaryLabel && <Text variant="$body-03">{secondaryLabel}</Text>}
      </Box>
    </Box>
  );
};
