import * as React from 'react';
import {Trans} from '@youtoken/ui.service-i18n';
import {type LevelStatus} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, TColorTokens, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

type LevelTermSlotProps = {
  variant: LevelStatus;
  title: string | React.ReactElement<typeof Trans>;
  titleOnly?: boolean;
  iconName?: BaseIconName;
  text?: string;
} & BoxProps;

type ColorsMap = Record<'title' | 'content', keyof TColorTokens>;
const COLORS: Record<LevelStatus, ColorsMap> = {
  completed: {
    title: '$text-02',
    content: '$text-02',
  },
  active: {
    title: '$text-01',
    content: '$text-01',
  },
  locked: {
    title: '$text-02',
    content: '$text-01',
  },
};

export const LevelTermSlot: React.FC<LevelTermSlotProps> = ({
  variant,
  title,
  titleOnly = false,
  iconName,
  text,
}) => {
  return (
    <Box
      alignItems="center"
      justifyContent={'center'}
      backgroundColor="$ui-background"
      p={12}
      flex={1}
    >
      <Text variant="$body-02" color={COLORS[variant].title} textAlign="center">
        {title}
      </Text>

      {!titleOnly && (
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mt={4}
        >
          {iconName && (
            <Icon name={iconName} size={20} color={COLORS[variant].content} />
          )}
          <Text
            variant="$body-01-high-accent"
            color={COLORS[variant].content}
            ml={4}
          >
            {text}
          </Text>
        </Box>
      )}
    </Box>
  );
};
