import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ReopenIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          d="M20 4.5a15.5 15.5 0 00-7.75 2.077 1.5 1.5 0 101.5 2.598A12.5 12.5 0 0132.5 20h-2.086c-.89 0-1.337 1.077-.707 1.707l3.586 3.586a1 1 0 001.414 0l3.586-3.586c.63-.63.184-1.707-.707-1.707H35.5A15.5 15.5 0 0020 4.5zM4.5 20H2.414c-.89 0-1.337-1.077-.707-1.707l3.586-3.586a1 1 0 011.414 0l3.586 3.586c.63.63.184 1.707-.707 1.707H7.5a12.5 12.5 0 0018.75 10.825 1.5 1.5 0 111.5 2.598A15.5 15.5 0 014.5 20z"
          fill={color}
        />
      </Svg>
    );
  }
);
