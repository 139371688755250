import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {Switch} from '@youtoken/ui.elements';

interface FormASoleOwnershipProps extends BoxProps {
  isNative: boolean;
  hasError: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const TextWithTooltipNative: React.FC<BoxProps> = boxProps => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Box maxWidth="90%">
        <Text variant="$body-02">{t('surface.form_a.field.sole_owner')} </Text>
      </Box>
      <QuestionTooltip content={t('surface.profile.form_a.step6.text')} />
    </Box>
  );
};

export const TextWithTooltipWeb: React.FC<BoxProps> = boxProps => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      {/* @ts-ignore */}
      <Text variant="$body-02" focusable={false}>
        {t('surface.form_a.field.sole_owner')}{' '}
      </Text>
      <QuestionTooltip content={t('surface.profile.form_a.step6.text')} />
    </Box>
  );
};

export const FormASoleOwnership: React.FC<FormASoleOwnershipProps> = ({
  isNative,
  hasError,
  value,
  onChange,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {isNative && (
        <TextWithTooltipNative
          flex={1}
          flexDirection="row"
          alignItems="flex-start"
        />
      )}
      {!isNative && (
        <TextWithTooltipWeb flex={1} flexDirection="row" alignItems="center" />
      )}
      <Switch hasError={hasError} value={value} onValueChange={onChange} />
    </Box>
  );
};
