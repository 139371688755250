import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {ListItemAssetData} from '../../types';
import {SavingsSelectAssetsFeature} from '../../Feature';

export const SavingsSelectAssetsListAssetAction: React.FC<ListItemAssetData> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {getAssetItemActionPropsByTicker, toggleTicker} =
      SavingsSelectAssetsFeature.use({});

    const {selected, disabled} = getAssetItemActionPropsByTicker(ticker);

    const handleToggle = React.useCallback(() => {
      toggleTicker(ticker);
    }, [toggleTicker, ticker]);

    return (
      <Button
        testID={`SAVINGS_SELECT_ASSETS_LIST_ASSET_ACTION_${
          selected ? 'UNSELECT' : 'SELECT'
        }_BUTTON`}
        type="secondary"
        size="small"
        icon={selected ? 'check' : 'plus'}
        color={selected ? 'success' : 'interactive'}
        disabled={disabled}
        onPress={handleToggle}
      >
        {selected && t('surface.savings.select_assets.selected')}
      </Button>
    );
  });
