import * as React from 'react';
import {observer} from 'mobx-react';
import type {BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {StepItem} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('Verification');
};

export const VerificationItemComplete: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    return (
      <StepItem
        backgroundColor="$success-02"
        iconColor="$success-03"
        textColor="$success-01"
        icon="verified"
        title={t('surface.verifications.item.complete.title')}
        description={t('surface.verifications.item.complete.description')}
        actionIcon="chevron_right"
        onPress={handlePress}
        descriptionColor="$success-01"
        {...boxProps}
      />
    );
  }
);
