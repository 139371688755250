import React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {Box, Text} from '@youtoken/ui.primitives';

interface FriendsActivityItemProps {
  icon: React.ComponentProps<typeof Icon>['name'];
  title: string;
  revenueTotal: string;
  revenueForOne?: string;
  revenueCount: number;
}

export const FriendsActivityItem: React.FC<FriendsActivityItemProps> = ({
  icon,
  title,
  revenueTotal,
  revenueForOne,
  revenueCount,
}) => {
  return (
    <Box flexDirection="row" justifyContent="space-between">
      <Box flexDirection="row" alignItems="center">
        {icon && (
          <Box
            width={24}
            height={24}
            backgroundColor="$interactive-03"
            borderRadius={12}
            justifyContent="center"
            alignItems="center"
            mr={8}
          >
            <Icon name={icon} size={16} color="$text-05" />
          </Box>
        )}

        <Box>
          <Text variant="$body-01" color="$text-02">
            {title}
          </Text>
        </Box>
      </Box>
      <Box
        flex={1}
        borderBottomWidth={1}
        borderStyle="dashed"
        borderColor="$ui-02"
        height={1}
        position="relative"
        top={16}
        marginHorizontal={5}
      />
      <Box flexDirection="row" alignItems="center">
        <Icon name="profile" size={16} color="$text-02" />
        <Text variant="$body-01" color="$text-02" ml={2}>
          {revenueCount}
        </Text>
        {revenueForOne && (
          <Text variant="$body-01-medium-accent" color="$success-01" ml={8}>
            +{revenueForOne}
          </Text>
        )}
        <Text variant="$body-01-medium-accent" color="$text-01" ml={8}>
          {revenueTotal}
        </Text>
      </Box>
    </Box>
  );
};
