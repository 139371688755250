import {
  action,
  autorun,
  computed,
  observable,
  IReactionDisposer,
  reaction,
} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {
  FeatureLoanCreateFormArgs,
  FeatureLoanCreateFormResources,
} from './index';
import {toBig} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';

export type FormTerm =
  | FeatureLoanCreateFormResources['tariffsResource']['data'][number]
  | undefined;

export class Form {
  @observable
  public args: FeatureLoanCreateFormArgs;

  @observable
  public resources: FeatureLoanCreateFormResources;

  @observable
  public instance: MobxReactForm;

  @observable
  public disposers: IReactionDisposer[];

  @observable
  public from: string = 'amount';

  @computed.struct
  public get wallets() {
    return this.resources.walletsResource.loanEnabledWallets;
  }

  @computed.struct
  public get wallet() {
    return this.wallets.find(wallet => wallet.ticker === this.ticker);
  }

  @computed.struct
  public get walletAmount() {
    const wallet = this.wallets.find(wallet => wallet.ticker === this.ticker);

    if (wallet) {
      return wallet.amount.toFixed();
    }

    return undefined;
  }

  @computed.struct
  public get loanMarkets() {
    return this.resources.tariffsResource.getLoanMarketsWithWhiteList(
      this.resources.walletsResource.loanEnabledTickers
    );
  }

  @computed.struct
  public get tickers() {
    const avaliableTickers = Object.keys(this.loanMarkets);

    const wallets = this.wallets.filter(wallet => {
      return avaliableTickers.includes(wallet.ticker);
    });

    return wallets.map(wallet => {
      return {
        ticker: wallet.ticker,
        tickerFormatted: wallet.ticker.toUpperCase(),
        amountFormatted: wallet.amount.toFixed(),
        coinName: wallet.tickerName,
        hasAmount: wallet.amount.gt(0),
        key: `${wallet.ticker} ${wallet.tickerName}`,
      };
    });
  }

  @computed.struct
  public get conversionTickers() {
    const avaliableTickers = this.loanMarkets[this.ticker] ?? [];

    const wallets = this.wallets.filter(wallet => {
      return avaliableTickers.includes(wallet.ticker);
    });

    return wallets.map(wallet => {
      return {
        ticker: wallet.ticker,
        tickerFormatted: wallet.ticker.toUpperCase(),
        amountFormatted: wallet.amount.toFixed(),
        coinName: wallet.tickerName,
        hasAmount: wallet.amount.gt(0),
        key: `${wallet.ticker} ${wallet.tickerName}`,
      };
    });
  }

  @computed.struct
  public get terms() {
    return this.resources.tariffsResource.data.filter(
      term =>
        term.collateralTicker === this.ticker &&
        term.settings.find(
          setting => setting.borrowedTicker === this.conversionTicker
        )
    );
  }

  @computed.struct
  public get term() {
    return this.terms.find(term => term.id === this.termId);
  }

  @computed.struct
  public get termSetting() {
    return this.term?.settings.find(
      setting => setting.borrowedTicker === this.conversionTicker
    );
  }

  @computed
  public get rate() {
    return this.resources.ratesResource
      .getRate(this.ticker, this.conversionTicker)
      .toString();
  }

  // collateral start

  @computed
  public get ticker() {
    return this.instance?.$('ticker').get('value') ?? this.tickers[0]?.ticker;
  }

  @computed
  public get amount() {
    return this.instance?.$('amount').get('value') ?? '';
  }

  @computed
  public get collateralError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get collateralHasError() {
    return Boolean(this.collateralError);
  }

  @computed
  public get tickerOnChange() {
    return this.instance.$('ticker').get('onChange');
  }

  @computed
  public get amountOnChange() {
    return this.instance.$('amount').get('onChange');
  }

  // collateral end

  // borrowed start

  @computed
  public get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickers[0]?.ticker
    );
  }

  @computed
  public get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value') ?? '';
  }

  @computed
  public get borrowedError() {
    return getTranslatedValidationMessage(
      this.instance.$('conversionTicker').get('error') ??
        this.instance.$('conversionAmount').get('error')
    );
  }

  @computed
  public get borrowedHasError() {
    return Boolean(this.borrowedError);
  }

  @computed
  public get conversionTickerOnChange() {
    return this.instance.$('conversionTicker').get('onChange');
  }

  @computed
  public get conversionAmountOnChange() {
    return this.instance.$('conversionAmount').get('onChange');
  }

  // borrowed end

  // termId start

  @computed
  public get termId() {
    return this.instance?.$('termId').get('value') ?? this.terms[0]?.id;
  }

  @computed
  public get termIdError() {
    return getTranslatedValidationMessage(
      this.instance.$('termId').get('error')
    );
  }

  @computed
  public get termIdHasError() {
    return Boolean(this.termIdError);
  }

  @computed
  public get termIdOnChange() {
    return this.instance.$('termId').get('onChange');
  }

  // termId end

  // tp start

  @computed
  public get tpActive() {
    return this.instance?.$('tpActive').get('value') ?? false;
  }

  @computed
  public get tp() {
    return this.instance?.$('tp').get('value') ?? '';
  }

  @computed
  public get tpError() {
    return this.tpActive
      ? getTranslatedValidationMessage(
          this.instance.$('tpActive').get('error') ??
            this.instance.$('tp').get('error')
        )
      : undefined;
  }

  @computed
  public get tpHasError() {
    return Boolean(this.tpError);
  }

  @computed
  public get tpActiveOnChange() {
    return this.instance.$('tpActive').get('onChange');
  }

  @computed
  public get tpOnChange() {
    const onChange = this.instance.$('tp').get('onChange');

    return (value: string) => {
      onChange(
        normalizeAmountWithPrecision(
          value,
          getCoinDecimalPrecisionForLoans(this.conversionTicker)
        )
      );
    };
  }

  // tp end

  @computed
  public get agreements() {
    const documents = this.resources.docsResource.data;

    if (
      this.term?.company === 'NAUMARD' &&
      documents.loanCreateFormNaumard?.length
    ) {
      return documents.loanCreateFormNaumard;
    }

    if (
      this.term?.company === 'YOUHODLER' &&
      documents.loanCreateFormYouhodler?.length
    ) {
      return documents.loanCreateFormYouhodler;
    }

    if (
      this.term?.company === 'YOUHODLER_ITA' &&
      documents.loanCreateFormItaly?.length
    ) {
      return documents.loanCreateFormItaly;
    }

    return documents.loanCreateForm ?? [];
  }

  @action
  public restore = () => {
    // if we want to set the default amount associated with the wallet,
    // we need to do this on restore, because the values in the constructor are immutable
  };

  @action
  public reset = () => {
    this.from = 'amount';
    this.instance.reset();
  };

  @action
  public submit = () => {
    if (!this.resources.authMeResource.checkProductAvailability('loan')) {
      return Promise.resolve();
    }

    return TRANSPORT.API.post('/v2/loan', {
      collateralAmount: this.amount,
      collateralTicker: this.ticker,
      borrowedTicker: this.conversionTicker,
      initialPrice: this.rate,
      tariffId: this.termId,
      tp: this.tpActive ? this.tp : undefined,
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.loans.create_form.message.created'),
        });
        SHARED_ROUTER_SERVICE.navigate('Loans');
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  };

  public constructor(
    args: FeatureLoanCreateFormArgs,
    resources: FeatureLoanCreateFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
          },
          conversionTicker: {
            value: this.conversionTicker,
          },
          termId: {
            value: this.termId,
          },
          amount: {
            value: this.amount,
            handlers: {
              onChange: (_field: any) => {
                this.from = 'amount';
              },
            },
          },
          conversionAmount: {
            value: this.conversionAmount,
            handlers: {
              onChange: (_field: any) => {
                this.from = 'conversionAmount';
              },
            },
          },
          tp: {
            value: this.tp,
          },
          tpActive: {
            value: this.tpActive,
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let amountSchema = yup.big().gt(0);
                if (this.walletAmount) {
                  amountSchema = amountSchema.lte(
                    this.walletAmount,
                    messages.FUNDS_INSUFFICIENT
                  );
                }

                const conversionAmountMin = this.termSetting?.minAmount.toFixed(
                  getCoinDecimalPrecisionForLoans(this.conversionTicker)
                );
                // const conversionAmountMax = this.termSetting?.maxAmount.toFixed(
                //   getCoinDecimalPrecisionForLoans(this.conversionTicker)
                // );
                let conversionAmountSchema = yup.big().gt(0);
                if (conversionAmountMin) {
                  conversionAmountSchema =
                    conversionAmountSchema.gte(conversionAmountMin);
                }
                // if (conversionAmountMax) {
                //   conversionAmountSchema =
                //     conversionAmountSchema.lte(conversionAmountMax);
                // }

                let tpSchema = yup.big();
                if (this.tpActive) {
                  const min = this.termSetting?.minTP
                    .mul(this.rate)
                    .toFixed(
                      getCoinDecimalPrecisionForLoans(this.conversionTicker)
                    );
                  const max = this.termSetting?.maxTP
                    .mul(this.rate)
                    .toFixed(
                      getCoinDecimalPrecisionForLoans(this.conversionTicker)
                    );

                  tpSchema = tpSchema.gt(0);
                  if (min) {
                    tpSchema = tpSchema.gte(min);
                  }
                  if (max) {
                    tpSchema = tpSchema.lte(max);
                  }
                }

                const schema = {
                  ticker: yup.string(),
                  conversionTicker: yup.string(),
                  amount: amountSchema,
                  conversionAmount: conversionAmountSchema,
                  termId: yup.string(),
                  tp: tpSchema,
                  tpActive: yup.boolean(),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
    this.disposers = [
      // if ticker changed
      // Then need recalculate [conversionTicker]
      reaction(
        () => {
          return this.ticker;
        },
        () => {
          // console.log('Then need recalculate [conversionTicker]');
          this.conversionTickerOnChange(this.conversionTickers[0]?.ticker);
          this.amountOnChange(
            this.wallet?.amount.gt(0) ? this.wallet.amount.toFixed() : ''
          );
        }
      ),
      // If changed [terms]
      // Then need recalculate [termId]
      reaction(
        () => {
          return this.terms;
        },
        () => {
          if (
            !this.terms.find(term => term.id === this.termId) &&
            this.terms[0]?.id
          ) {
            // console.log('Then need recalculate [termId]');
            this.termIdOnChange(this.terms[0].id);
          }
        }
      ),
      // If changed [from, ticker, conversionTicker, amount, conversionAmount, rate, ltv]
      // Then need recalculate [amount || conversionAmount]
      autorun(() => {
        if (this.from === 'amount') {
          // console.log('Then need recalculate [conversionAmount]');
          const amount = toBig(this.amount);

          if (amount.gt(0)) {
            this.instance
              .$('conversionAmount')
              .set(
                'value',
                toBig(this.rate)
                  .mul(toBig(this.amount))
                  .times(toBig(this.term?.ltv))
                  .round(getCoinDecimalPrecisionForLoans(this.conversionTicker))
                  .toString()
              );

            return;
          }

          this.instance.$('conversionAmount').set('value', '');
          return;
        }

        if (this.from === 'conversionAmount') {
          // console.log('Then need recalculate [amount]');
          const conversionAmount = toBig(this.conversionAmount);

          if (conversionAmount.gt(0)) {
            this.instance
              .$('amount')
              .set(
                'value',
                conversionAmount
                  .div(toBig(this.rate))
                  .div(toBig(this.term?.ltv))
                  .round(getCoinDecimalPrecisionForLoans(this.ticker))
                  .toString()
              );

            return;
          }

          this.instance.$('amount').set('value', '');
          return;
        }
      }),
    ];
  }
}
