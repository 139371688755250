import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface FeeRowProps extends BoxProps {
  percent: string;
  value: string;
  ticker: string;
}

export const FeeRow: React.FC<FeeRowProps> = observer(
  ({percent, value, ticker, ...props}) => {
    const {t} = useTranslation();

    return (
      <Box
        height={48}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        {...props}
      >
        <Box>
          <Text>{t('surface.apple_pay.deposit.components.fee_row.fee')}</Text>
        </Box>
        <Box flexDirection="row">
          <Text mr={8}>{percent}%</Text>
          <Text mr={2} variant="$body-01-high-accent">
            {value}
          </Text>
          <Text>{ticker}</Text>
        </Box>
      </Box>
    );
  }
);
