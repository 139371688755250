import {useMemo} from 'react';
import {omit} from 'lodash';
import {useTheme} from '@youtoken/ui.primitives';
import {Platform, TextInputProps as NativeTextInputProps} from 'react-native';
import {TextInputProps} from './types';
import {InputProps} from './createInput';

const useInputColorsStyles = (
  props: TextInputProps & {focused?: boolean}
): InputProps & NativeTextInputProps => {
  if (props.hasError) {
    if (props.disabled) {
      return {
        color: '$text-02',
        backgroundColor: '$transparent', // NOTE: fix for default MacOS stylesheets
      };
    }

    return {
      color: '$text-01',
    };
  }

  if (props.disabled) {
    return {
      color: '$text-02',
      backgroundColor: '$transparent', // NOTE: fix for default MacOS stylesheets
    };
  }

  return {
    color: props.focused ? '$text-05' : '$text-01',
  };
};

export const useInputStyleProps = (
  props: TextInputProps & {focused?: boolean}
): TextInputProps => {
  const {textVariants, colors} = useTheme();

  const style = useMemo(() => {
    return {
      // test without lineHeight
      ...(omit(textVariants['$body-01-medium-accent'], ['lineHeight']) as any),
      ...(Platform.OS === 'web'
        ? {
            // @ts-ignore
            outline: 'none',
            // @ts-ignore web-only
            outlineWidth: 0,
            // @ts-ignore web-only
            outlineColor: 'transparent',
          }
        : {}),
    };
  }, [Platform.OS, textVariants]);

  return {
    style,
    placeholderTextColor: colors['$text-03'],
    ...useInputColorsStyles(props),
  };
};
