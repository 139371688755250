import * as React from 'react';
import {useChartThemeContext} from '../../Providers';
import {DashedLine} from '../../Primitives';
import {observer} from 'mobx-react';
import {BaseHODLChartState} from '../../logic';

export const MarginCallLine: React.FC<{
  chartState: BaseHODLChartState;
  fullWidth?: boolean;
}> = React.memo(
  observer(({chartState, fullWidth}) => {
    const {
      colors: {error},
    } = useChartThemeContext();

    const {
      width,
      isCursorActive,
      marginCallLabel,
      maxLossLabel,
      triggerPricePriority,
    } = chartState;

    if (
      !marginCallLabel.layout ||
      !marginCallLabel.layout.isInDomain ||
      triggerPricePriority
    ) {
      return null;
    }

    return (
      <DashedLine
        stroke={error}
        x1={
          fullWidth
            ? 0
            : maxLossLabel.layout && !isCursorActive
            ? maxLossLabel.layout.width
            : 0
        }
        x2={fullWidth ? width : width - marginCallLabel.layout.width}
        y1={marginCallLabel.layout.y}
        y2={marginCallLabel.layout.y}
      />
    );
  })
);
