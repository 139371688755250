import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {SubmitButton} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const Submit: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box flexDirection="row" justifyContent="space-around" p={24}>
      <Box flex={1} justifyContent="center">
        <Text variant="$body-02" color="$text-02" pr={10}>
          {t('surface.wallets.fiat_deposit.redirect_notice', {
            providerUrl: 'cardpay.com',
          })}
        </Text>
      </Box>
      <Box flex={1} pl={10}>
        <SubmitButton color="interactive" testID="FIAT_DEPOSIT_CODI_SUBMIT">
          {t('surface.wallets.fiat_deposit_card.pay')}
        </SubmitButton>
      </Box>
    </Box>
  );
});
