import * as React from 'react';
import Big from 'big.js';
import {Text, Box} from '@youtoken/ui.primitives';

interface RateDiffProps {
  value: string;
}

export const RateDiff: React.FC<RateDiffProps> = ({value: _value}) => {
  const value = Big(_value);

  return (
    <Box flexDirection="row" justifyContent="center">
      <Text
        variant="$body-02"
        color={value.gte(0) ? '$success-01' : '$danger-01'}
      >
        {value.times(100).round(2).toFixed(2)}%
      </Text>
    </Box>
  );
};
