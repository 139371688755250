import * as React from 'react';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {LineCurve} from '../../Primitives';
import {BaseChartState} from '../../logic';

export const PastDataLine: React.FC<{
  chartState: BaseChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {colors} = useTheme();
    const {
      lines: {pastDataLine},
    } = chartState;

    if (!pastDataLine) {
      return null;
    }

    return (
      <LineCurve
        d={pastDataLine}
        stroke={colors['$chart-disabled-01']}
        strokeWidth={1}
      />
    );
  })
);
