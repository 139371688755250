import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type BoxProps} from '@youtoken/ui.primitives';
import {RewardsStatisticsV1Smart} from './v1';
import {RewardsStatisticsV3Smart} from './v3';

export const RewardsStatisticsSmart: React.FC<BoxProps> = observer(boxProps => {
  const {
    data: {rewardVersion},
  } = AuthMeResource.use({});

  if (rewardVersion === 3) {
    return <RewardsStatisticsV3Smart {...boxProps} />;
  }

  return <RewardsStatisticsV1Smart {...boxProps} />;
});
