import {i18n} from '@youtoken/ui.service-i18n';
import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';

export class TakeProfitLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (!this.chart.takeProfit || !this.chart.initialPriceChart) {
      return undefined;
    }

    const takeProfitTranslated = i18n.t('charts.take_profit');
    const text = `${takeProfitTranslated} ${this.chart.formatNumber(
      this.chart.takeProfit
    )} ${this.chart.formatPercent(
      this.chart.getDiffWithValue(
        this.chart.takeProfit,
        this.chart.initialPriceChart
      )
    )}`;

    return this.chart.layout.getLabelLayout(
      this.chart.takeProfit,
      text,
      'right',
      this.chart.direction
    );
  }
}
