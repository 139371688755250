import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {type WithBoxProps} from '@youtoken/ui.primitives';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {LedgerResource} from '@youtoken/ui.resource-ledger';
import {type CryptoDepositProps} from '../types';
import {CryptoDepositLedgerFormState} from './state';
import {
  CryptoDepositLedgerFormContent,
  CryptoDepositLedgerFormFooter,
} from './components';

export const CryptoDepositLedgerForm: React.FC<
  WithBoxProps<CryptoDepositProps>
> = cell(({ticker, ...rest}) => {
  const resources = useResources({
    authMeResource: getResourceDescriptor(AuthMeResource, {}),
    walletsResource: getResourceDescriptor(WalletsResource, {}),
    ledgerResource: getResourceDescriptor(LedgerResource, {}),
  });

  const formState = makeForm(
    () =>
      new CryptoDepositLedgerFormState(
        {
          ticker,
        },
        resources
      )
  );

  return (
    <Box flexGrow={1} {...rest}>
      <Form form={formState}>
        <CryptoDepositLedgerFormContent />
        <CryptoDepositLedgerFormFooter />
      </Form>
    </Box>
  );
});
