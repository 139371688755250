import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const IdScanIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.75 13.75a.75.75 0 01.743.648l.007.102v3a1 1 0 00.883.993l.117.007h3a.75.75 0 010 1.5H2a2 2 0 01-2-2v-3.5a.75.75 0 01.75-.75zm18.5 0a.75.75 0 01.743.648L20 14.5V18a2 2 0 01-2 2h-3.5a.75.75 0 010-1.5h3a1 1 0 00.993-.883l.007-.117v-3l.007-.102a.75.75 0 01.743-.648zM16 6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1h12zM9.5 8h-4a.5.5 0 00-.492.41L5 8.5v3a.5.5 0 00.41.492L5.5 12h4a.5.5 0 00.492-.41L10 11.5v-3a.5.5 0 00-.5-.5zm3.75 2.5h-1.5a.75.75 0 00-.102 1.493l.102.007h1.5a.75.75 0 000-1.5zm1-2.5h-2.5a.75.75 0 00-.102 1.493l.102.007h2.5a.75.75 0 000-1.5zM5.5 0a.75.75 0 01.102 1.493L5.5 1.5h-3a1 1 0 00-.993.883L1.5 2.5v3a.75.75 0 01-1.5 0V2a2 2 0 012-2h3.5zM18 0a2 2 0 012 2v3.5a.75.75 0 01-1.5 0v-3a1 1 0 00-1-1h-3a.75.75 0 010-1.5H18z"
          fill={color}
        />
      </Svg>
    );
  }
);
