import {HODlInstrumentItem} from './types';

export const getDefaultInstrument = (
  instrumentsItems: HODlInstrumentItem[],
  ticker?: string,
  quoteTicker?: string
): HODlInstrumentItem | undefined => {
  const hodlTickerValueByBaseAndQuote = instrumentsItems.find(
    (item: HODlInstrumentItem) => {
      return item.baseTicker === ticker && item.quoteTicker === quoteTicker;
    }
  );

  const hodlTickerValueByBaseAndQuoteUsdt = instrumentsItems.find(
    (item: HODlInstrumentItem) => {
      return item.baseTicker === ticker && item.quoteTicker === 'usdt';
    }
  );

  const hodlTickerValueByBase = instrumentsItems.find(
    (item: HODlInstrumentItem) => {
      return item.baseTicker === ticker;
    }
  );

  const hodlTickerValueByAllowedTickers =
    ticker &&
    instrumentsItems.find((item: HODlInstrumentItem) => {
      return item.allowedInputTickers.includes(ticker);
    });

  const hodlTickerFirst = instrumentsItems.find((item: HODlInstrumentItem) => {
    return item.baseTicker !== 'ybtc';
  });

  return (
    hodlTickerValueByBaseAndQuote ||
    hodlTickerValueByBaseAndQuoteUsdt ||
    hodlTickerValueByBase ||
    hodlTickerValueByAllowedTickers ||
    hodlTickerFirst
  );
};
