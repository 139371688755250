import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {AdjustPendingOrderFormClass} from '../../form';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {AmountBadge, ValidationMessage} from '@youtoken/ui.hodl-elements';

export const TakeProfitSection: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();
    const testIdSection = 'TAKE_PROFIT';

    const {
      form: {
        takeProfit,
        takeProfitPercent,
        updateTakeProfitValue,
        updateTakeProfitPercent,
        setTakeProfitLimit,
        maxProfit,
        takeProfitError,
        isShort,
        inputTicker,
        precision,
      },
      view: {
        takeProfitPercentMin,
        takeProfitPercentMax,
        takeProfitLimitMessage,
        tpErrorIsClickable,
      },
    } = useForm<AdjustPendingOrderFormClass>();

    const validationComponent = React.useMemo(() => {
      return (
        <ValidationMessage
          validationMessage={takeProfitLimitMessage}
          onValidationClick={setTakeProfitLimit}
          validationError={takeProfitError}
          isValidationClickable={tpErrorIsClickable}
          testIdSection={testIdSection}
        />
      );
    }, [
      takeProfitLimitMessage,
      setTakeProfitLimit,
      takeProfitError,
      tpErrorIsClickable,
    ]);

    return (
      <Box px={24} {...boxProps}>
        <Box
          position="relative"
          zIndex={1}
          mb={8}
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Text variant="$body-01-high-accent" mr={4}>
              {t('surface.hodls.adjust_tp.take_profit')}
            </Text>
            <QuestionTooltip
              content={t('surface.hodls.adjust_tp.take_profit_tooltip')}
              bodyHorizontalOffset={60}
              width={300}
            />
          </Box>

          <AmountBadge amount={maxProfit} ticker={inputTicker} />
        </Box>

        <Box mb={8}>
          <AmountAndPercentInput
            amountLabel={t('surface.hodls.adjust_tp.level')}
            percentLabel={t('surface.hodls.adjust_tp.change')}
            amountValue={takeProfit}
            percentValue={takeProfitPercent}
            precisionAmount={precision}
            percentRangeMin={takeProfitPercentMin}
            percentRangeMax={takeProfitPercentMax}
            onChangeAmount={updateTakeProfitValue}
            onChangePercent={updateTakeProfitPercent}
            sliderKey="takeProfit"
            percentSignIsActive={true}
            validationComponent={validationComponent}
            shouldReverseSlider={isShort}
            testIdSection={testIdSection}
          />
        </Box>
      </Box>
    );
  }
);
