import {merge} from 'lodash';
import {invariant} from '@youtoken/ui.utils';
import {ScopeContext} from '@sentry/types';
import {WithCustomErrorProps, CustomError} from './CustomError';
import {ERROR_TYPE} from './ERROR_TYPE';
import {isCustomError, maskUUID} from './utils';

/** Resource error for any error coming form createResource()
 * - `resourceKey` to track;
 * - `sentryProps` with {resourceKey: string} to send to sentry;
 */
export class ResourceError extends Error implements WithCustomErrorProps {
  __isCustomError!: boolean;
  __resourceKey!: string;
  __type!: ERROR_TYPE;
  __sentry!: Partial<ScopeContext>;

  public cause?: Error;

  constructor(
    message: string,
    resourceKey: string,
    type: ERROR_TYPE = ERROR_TYPE.GENERAL_ERROR,
    sentryScope: Partial<ScopeContext> = {}
  ) {
    const resourceKeyMasked = maskUUID(resourceKey);
    super(`'${message}' for key '${resourceKeyMasked}'`); // 'Error' breaks prototype chain here
    this.name = `ResourceError`;
    Object.setPrototypeOf(this, ResourceError.prototype); // restore prototype chain

    this.__isCustomError = true;

    this.__resourceKey = resourceKeyMasked;

    this.__type = type;
    this.__sentry = sentryScope;

    // tags, only masked key to reduce fracturing of data with too many keys with uuid;
    this.__sentry.tags = this.__sentry.tags ?? {};
    this.__sentry.tags.resourceKey = resourceKeyMasked;

    // extra, key and masked key;
    this.__sentry.extra = this.__sentry.extra ?? {};
    this.__sentry.extra.resourceKeyMasked = resourceKeyMasked;
    this.__sentry.extra.resourceKey = resourceKey;

    this.__sentry.fingerprint = this.__sentry.fingerprint ?? [];
    // only adding key to fingerprint if not already there;
    // just in case, this should never happen
    if (!this.__sentry.fingerprint?.includes(resourceKeyMasked)) {
      this.__sentry.fingerprint.push(resourceKeyMasked);
    }
  }
}

/** this fn to take error during resource life cycle and:
 * - returns __new__ ResourceError;
 * - original error may be safely deleted;
 */
export const createResourceErrorFromGenericError = (
  originalError: Error | CustomError,
  resourceKey: string,
  type: ERROR_TYPE = ERROR_TYPE.GENERAL_ERROR,
  sentryContext: Partial<ScopeContext> = {}
) => {
  invariant(
    originalError instanceof Error,
    `Cant create ResourceError. Must provide an Error but was provided with "${JSON.stringify(
      originalError
    )}"`
  );

  // explicitly return original ResourceError if somehow it was passed into this function
  if (originalError instanceof ResourceError) {
    return originalError;
  }

  // otherwise create new ResourceError
  const resourceError = new ResourceError(
    originalError.message,
    resourceKey,
    type,
    merge((originalError as CustomError)?.__sentry ?? {}, sentryContext)
  );
  resourceError.name = `ResourceError: ${originalError.name}`;

  resourceError.cause = originalError;

  if (isCustomError(originalError)) {
    resourceError.__type = originalError.__type;
  }

  return resourceError;
};
