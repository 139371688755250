import React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

/** __Outer page shell;__
 *
 * used to render pages for unauthorized users,
 * like `/sign-in`, `/sign-up`, etc
 */
export const OuterPageShell: React.FC<BoxProps> = ({children, ...boxProps}) => {
  return (
    <Box width="100%" mt={40} {...boxProps}>
      {children}
    </Box>
  );
};
