import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {CryptoDepositLedgerFormState} from '../state';
import {LedgerAccount, LedgerAccountCombobox} from '../../LedgerAccount';
import {CryptoDepositLedgerFormAmountAllLink} from './AmountAllLink';

export const CryptoDepositLedgerFormAmount: React.FC = observer(() => {
  const {
    form: {
      amountLabel,
      amountPlaceholder,
      amount,
      amountHasError,
      amountError,
      amountOnChange,
    },
  } = useForm<CryptoDepositLedgerFormState>();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(value => !value);
  }, []);

  return (
    <Box zIndex={3}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={8}
      >
        <Label>{amountLabel}</Label>
        <CryptoDepositLedgerFormAmountAllLink />
      </Box>
      {isOpen ? (
        <LedgerAccountCombobox onClose={handleToggle} />
      ) : (
        <TextInput
          size="large"
          placeholder={amountPlaceholder}
          value={amount}
          hasError={amountHasError}
          onChangeText={amountOnChange}
          keyboardType="numeric"
          textAlign="right"
          LeftPartComponent={<LedgerAccount onPress={handleToggle} />}
        />
      )}
      <FieldErrorMessage visible={amountHasError}>
        {amountError}
      </FieldErrorMessage>
    </Box>
  );
});
