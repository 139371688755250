import {action, observable} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {ResourceInstance} from '../types';
import {BaseResourceFeature} from './BaseResourceFeature';

/** socket connection feature; tracks state of socket connection (for now)
 * - isSocketConnection - observable boolean;
 */
export class SocketConnection<
  Args extends {},
  Result
> extends BaseResourceFeature<Args, Result> {
  @observable isSocketConnected: boolean = TRANSPORT.SOCKET.connected;

  onDestroy() {
    super.onDestroy();
    TRANSPORT.SOCKET.off('connect', this.onConnect);
    TRANSPORT.SOCKET.off('disconnect', this.onDisconnect);
  }

  constructor(resource: ResourceInstance<Args, Result>) {
    super(resource);

    TRANSPORT.SOCKET.connect();

    TRANSPORT.SOCKET.on('connect', this.onConnect);
    TRANSPORT.SOCKET.on('disconnect', this.onDisconnect);
  }

  @action onConnect = () => {
    this.isSocketConnected = true;
  };

  @action onDisconnect = () => {
    this.isSocketConnected = false;
  };

  @action reconnect = () => {
    TRANSPORT.SOCKET.connect();
  };
}
