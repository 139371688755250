import * as React from 'react';
import {SvgProps, Svg, G, Path, Defs, ClipPath} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const GoogleIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 21 20"
        fill="none"
        {...props}
      >
        <G clipPath="url(#google_svg__google_svg__clip0_8519_60568)">
          <Path
            d="M18.833 10.191c0-.566-.046-1.136-.146-1.693h-8.02v3.21h4.592a3.853 3.853 0 01-1.699 2.531v2.083h2.74c1.609-1.452 2.533-3.595 2.533-6.13z"
            fill="#4285F4"
          />
          <Path
            d="M10.667 18.333c2.293 0 4.227-.738 5.636-2.011l-2.74-2.083c-.762.509-1.746.796-2.893.796-2.218 0-4.099-1.466-4.773-3.438H3.069v2.146c1.444 2.814 4.383 4.59 7.598 4.59z"
            fill="#34A853"
          />
          <Path
            d="M5.894 11.597a4.898 4.898 0 010-3.19V6.26H3.069a8.193 8.193 0 000 7.483l2.825-2.146z"
            fill="#FBBC04"
          />
          <Path
            d="M10.667 4.964a4.672 4.672 0 013.262 1.25l2.427-2.38a8.263 8.263 0 00-5.689-2.167c-3.215 0-6.154 1.776-7.598 4.593l2.825 2.146c.671-1.975 2.555-3.442 4.773-3.442z"
            fill="#EA4335"
          />
        </G>
        <Defs>
          <ClipPath id={`${_id}_google_svg__google_svg__clip0_8519_60568`}>
            <Path
              fill="#fff"
              transform="translate(2.167 1.667)"
              d="M0 0h16.667v16.667H0z"
            />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
);
