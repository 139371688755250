import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TouchableBox, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {RewardsHowItWorksModal} from '.';

export const RewardsHowItWorksModalTrigger: React.FC = observer(() => {
  const {t} = useTranslation();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  return (
    <>
      <TouchableBox
        alignSelf="center"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        px={12}
        py={8}
        backgroundColor="$ui-background"
        borderColor="$ui-01"
        borderWidth={1}
        borderRadius={8}
        onPress={handleToggle}
        mt={16}
        mb={8}
      >
        <Icon name="question" color="$text-02" size={16} />
        <Text variant="$body-02" color="$text-02" ml={4}>
          {t('surface.referral_program.rewards.how_it_works_action')}
        </Text>
      </TouchableBox>
      <RewardsHowItWorksModal isOpen={isOpen} onClose={handleToggle} />
    </>
  );
});
