import {Platform} from 'react-native';

export const FONTS = {
  normal: {
    regular: Platform.select({
      ios: 'Ubuntu-Regular',
      android: 'Ubuntu-Regular',
      web: 'Ubuntu, sans-serif',
    })!,
    medium: Platform.select({
      ios: 'Ubuntu-Medium',
      android: 'Ubuntu-Medium',
      web: 'Ubuntu, sans-serif',
    })!,
    bold: Platform.select({
      ios: 'Ubuntu-Bold',
      android: 'Ubuntu-Bold',
      web: 'Ubuntu, sans-serif',
    })!,
  },
  mono: {
    regular: Platform.select({
      android: 'UbuntuMono-Regular',
      ios: 'Ubuntu Mono',
      web: '"Ubuntu Mono", monospace',
    })!,
    bold: Platform.select({
      android: 'UbuntuMono-Bold',
      ios: 'Ubuntu Mono',
      web: '"Ubuntu Mono", monospace',
    })!,
  },
};
