import {init} from './init';
import {useOnMount} from '@web-app/src/hooks';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {getGoogleTagManagerId} from '@youtoken/ui.env-utils';

export const GTMInitializer = () => {
  useOnMount(() => {
    init(getGoogleTagManagerId(ENVIRONMENT.APP_ENV));
  });

  return null;
};
