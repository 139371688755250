import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {
  Frames,
  CardNumber,
  ExpiryDate,
  Cvv,
  SubmitButton,
} from 'frames-react-native';
import {Label, FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getCheckoutData} from '@youtoken/ui.env-utils';
import {Box, useTheme} from '@youtoken/ui.primitives';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {TextInput} from '@youtoken/ui.inputs';
import {Button} from '@youtoken/ui.buttons';
import {BillingDetailsSection} from './Sections';
import {CardsForm} from '../state';

interface FramesProps {
  onPressCancel: () => void;
}

export const FramesComponent: React.FC<FramesProps> = observer(
  ({onPressCancel}) => {
    const {t} = useTranslation();
    const {colors, fonts} = useTheme();

    const {
      form: {
        isValidCardNumber,
        isValidExpiryDate,
        isValidCvv,
        onChangeCardHolderName,
        onCardTokenizationFail,
        onCardTokenizationSuccess,
      },
      view: {
        cardHolderName,
        cardHolderNameError,
        onCardHolderNameBlur,
        onCardNumberBlur,
        showCardNumberError,
        onExpiryDateBlur,
        showExpiryDateError,
        countryCode,
        city,
        state,
        zipCode,
        addressLine1,
        addressLine2,
        onCvvBlur,
        showCvvError,
        isFormValid,
        onCardValidationChanged,
        onFrameValidationChanged,
      },
    } = useForm<CardsForm>();

    const fontStyle = React.useMemo(
      () => ({
        fontSize: 14,
        fontFamily: fonts.normal.regular,
        fontWeight: '500' as const,
        lineHeight: 18,
        letterSpacing: 0.28,
      }),
      [fonts]
    );

    const inputStyle = React.useMemo(
      () => ({
        backgroundColor: colors['$ui-background'],
        borderColor: colors['$ui-02'],
        color: colors['$text-01'],
        borderRadius: 6,
        height: 48,
        ...fontStyle,
      }),
      [colors, fontStyle]
    );

    const config = {
      publicKey: getCheckoutData(ENVIRONMENT.APP_ENV).token,
      cardholder: {
        name: cardHolderName,
        billingAddress: {
          country: countryCode,
          city,
          state,
          zip: zipCode,
          addressLine1,
          addressLine2,
        },
      },
    };

    // NOTE: This is needed for a correct work of the wizards back button to not let the event props be passed into the goBack fnc
    const handlePressCancel = React.useCallback(() => {
      return onPressCancel?.();
    }, [onPressCancel]);

    return (
      <ScrollView
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          padding: 24,
          backgroundColor: colors['$ui-background'],
        }}
        bounces={false}
      >
        <Frames
          config={config}
          cardValidationChanged={onCardValidationChanged}
          frameValidationChanged={onFrameValidationChanged}
          cardTokenizationFailed={onCardTokenizationFail}
          cardTokenized={onCardTokenizationSuccess}
        >
          <Box justifyContent="space-between" height="100%">
            <Box>
              <Box zIndex={100}>
                <Label mb={8} required>
                  {t('surface.wallets.bank_card.cardholder_name')}
                </Label>
                <TextInput
                  value={cardHolderName}
                  onChangeText={onChangeCardHolderName}
                  onBlur={onCardHolderNameBlur}
                  placeholderTextColor={colors['$text-03']}
                  placeholder={t('surface.form_a.field.name')}
                  testID="CARDHOLDER_NAME_INPUT"
                />
                <FieldErrorMessage visible={Boolean(cardHolderNameError)}>
                  <ValidationMessageComponent error={cardHolderNameError} />
                </FieldErrorMessage>
              </Box>

              <Label mt={24} mb={8} required>
                {t('surface.wallets.bank_card.card_number')}
              </Label>
              <CardNumber
                style={inputStyle}
                placeholderTextColor={colors['$text-03']}
                placeholder={'0000 0000 0000 0000'}
                onBlur={onCardNumberBlur}
                testID="CARD_NUMBER_INPUT"
              />
              <FieldErrorMessage
                visible={!isValidCardNumber && showCardNumberError}
              >
                {t('surface.wallets.bank_card.card_number_invalid')}
              </FieldErrorMessage>

              <Box flexDirection="row" py={24}>
                <Box flexBasis={0} flexGrow={1} mr={16}>
                  <Label mb={8} required>
                    {t('surface.wallets.bank_card.expiration_date')}
                  </Label>
                  <ExpiryDate
                    style={inputStyle}
                    placeholderTextColor={colors['$text-03']}
                    placeholder={t(
                      'surface.wallets.bank_card.expiration_date_placeholder'
                    )}
                    onBlur={onExpiryDateBlur}
                    testID="EXPIRY_DATE_INPUT"
                  />
                  <FieldErrorMessage
                    visible={!isValidExpiryDate && showExpiryDateError}
                  >
                    {t('surface.wallets.bank_card.expiration_date_invalid')}
                  </FieldErrorMessage>
                </Box>
                <Box flexBasis={0} flexGrow={1}>
                  <Label mb={8} required>
                    {t('surface.wallets.bank_card.security_code')}
                  </Label>
                  <Cvv
                    style={inputStyle}
                    placeholderTextColor={colors['$text-03']}
                    placeholder={t(
                      'surface.wallets.bank_card.security_code_placeholder'
                    )}
                    onBlur={onCvvBlur}
                    testID="CVV_INPUT"
                  />
                  <FieldErrorMessage visible={!isValidCvv && showCvvError}>
                    {t('surface.wallets.bank_card.security_code_invalid')}
                  </FieldErrorMessage>
                </Box>
              </Box>

              <BillingDetailsSection />
            </Box>
            <Box flexDirection="row" mt={24}>
              <Box flexBasis={0} flexGrow={1} mr={16}>
                <Button
                  size="large"
                  type="secondary"
                  onPress={handlePressCancel}
                  testID="CANCEL_BUTTON"
                >
                  {t('surface.wallets.cancel')}
                </Button>
              </Box>
              <Box flexBasis={0} flexGrow={1}>
                <SubmitButton
                  title={t('surface.wallets.add_card')}
                  disabled={!isFormValid}
                  activeOpacity={isFormValid ? 0.8 : 1}
                  style={{
                    backgroundColor: isFormValid
                      ? colors['$interactive-01']
                      : colors['$ui-01'],
                    borderRadius: 6,
                    flex: 1,
                  }}
                  textStyle={{
                    color: isFormValid
                      ? colors['$text-04']
                      : colors['$text-03'],
                    fontSize: 14,
                    fontFamily: fonts.normal.regular,
                    fontWeight: '500',
                  }}
                  testID="SUBMIT_CARD_DATA_BUTTON"
                />
              </Box>
            </Box>
          </Box>
        </Frames>
      </ScrollView>
    );
  }
);
