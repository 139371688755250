import * as React from 'react';
import {ErrorMessage as FormikErrorMessage} from 'formik';
import {Text, Box, BoxProps} from '@youtoken/ui.primitives';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';

type Props = {
  name: string;
  align?: 'left' | 'right';
} & BoxProps;

export const ErrorMessage: React.FC<Props & BoxProps> = ({
  name,
  align = 'left',
  testID,
  ...otherProps
}) => {
  return (
    <Box
      flexDirection="row"
      alignItems="flex-end"
      justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
      width="100%"
      {...otherProps}
    >
      <FormikErrorMessage
        name={name}
        render={message =>
          message ? (
            <Text
              variant="$body-02-medium-accent"
              color="$danger-01"
              mb={-18}
              mt={4}
              testID={testID}
            >
              <ValidationMessageComponent error={message} />
            </Text>
          ) : null
        }
      />
    </Box>
  );
};
