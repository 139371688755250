import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  type TouchableBoxProps,
  TouchableBox,
  Text,
  Box,
} from '@youtoken/ui.primitives';
import {LoyaltyIcon, type LoyaltyIconName} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {
  getLevelColor,
  getLevelIconName,
} from '@youtoken/ui.loyalty-miner-utils';

export interface SavingsUpgradeLevelProps {
  level: number;
}

export const SavingsUpgradeLevel: React.FC<
  SavingsUpgradeLevelProps & TouchableBoxProps
> = observer(({level, onPress, ...touchableProps}) => {
  const {t} = useTranslation();

  const backgroundColor = getLevelColor(level);
  const iconName = getLevelIconName(level);

  return (
    <TouchableBox
      testID="SAVINGS_UPGRADE_LEVEL"
      onPress={onPress}
      flexDirection="row"
      alignItems="center"
      p={8}
      backgroundColor={backgroundColor}
      borderRadius={12}
      {...touchableProps}
    >
      <LoyaltyIcon name={iconName as LoyaltyIconName} size={32} />
      <Box flex={1} mx={8}>
        <Text variant="$body-02" color="$text-01">
          {t('surface.savings.upgrade_level.text')}
        </Text>
      </Box>

      <Button
        testID="SAVINGS_UPGRADE_LEVEL_BUTTON"
        size="small"
        onPress={onPress}
      >
        {t('surface.savings.upgrade_level.action')}
      </Button>
    </TouchableBox>
  );
});
