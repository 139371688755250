import * as React from 'react';
import {observer} from 'mobx-react';
import {Path} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';

type BorderProps = {
  hexState: FEBlockState;
};

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 1,
  AVAILABLE_NOT_ENOUGH_SPARKS: 1,
  AVAILABLE_ENOUGH_SPARKS: 1,
  MINING_STARTING: 1,
  MINING: 1,
  READY: 0,
  CLAIMING_STARTING: 1,
  CLAIMING: 1,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 1,
};

export const Border: React.FC<BorderProps> = observer(({hexState}) => {
  const {borderColor} = useMinerColors(hexState);

  const style = useLegacyOpacityAnimation(OPACITIES, hexState);

  if (style.opacity === 0) {
    return null;
  }

  return (
    <Path
      opacity={style.opacity}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.997 4.04583L4.99698 17.9208C3.14228 18.9931 2 20.9729 2 23.1152V50.8838C2 53.0262 3.14228 55.006 4.99699 56.0782L28.997 69.9532C30.8549 71.0274 33.1451 71.0274 35.003 69.9532L59.003 56.0782C60.8577 55.006 62 53.0262 62 50.8838V23.1152C62 20.9729 60.8577 18.9931 59.003 17.9208L35.003 4.04582C33.1451 2.9717 30.8549 2.9717 28.997 4.04583ZM3.99598 16.1894L27.996 2.31436C30.4732 0.882195 33.5268 0.882192 36.004 2.31435L60.004 16.1894C62.477 17.619 64 20.2588 64 23.1152V50.8838C64 53.7403 62.477 56.38 60.004 57.8097L36.004 71.6847C33.5268 73.1169 30.4732 73.1169 27.996 71.6847L3.99598 57.8097C1.52304 56.38 0 53.7403 0 50.8838V23.1152C0 20.2588 1.52304 17.619 3.99598 16.1894Z"
      fill={borderColor}
    />
  );
});
