import {computed, observable} from 'mobx';
import {AdjustPendingOrderForm} from './Form';
import {getPercentByAmount} from '@youtoken/ui.hodls-utils';
import {toBig} from '@youtoken/ui.formatting-utils';
import {TFuncKey} from 'react-i18next';

export class AdjustPendingOrderFormView {
  @observable
  public form: AdjustPendingOrderForm;

  constructor(form: AdjustPendingOrderForm) {
    this.form = form;
  }

  @computed get currentPriceFormatted() {
    return this.form.currentPrice.toFixed(this.form.precision);
  }

  @computed get baseTickerFormatted() {
    return this.form.baseTicker.toUpperCase();
  }

  @computed get quoteTickerFormatted() {
    return this.form.quoteTicker.toUpperCase();
  }

  @computed get triggerPriceHasError() {
    return Boolean(this.form.instance.$('triggerPrice').get('error'));
  }

  @computed get takeProfitValueFormatted() {
    return this.form.takeProfit.toFixed(this.form.precision);
  }

  @computed get takeProfitValueMin() {
    if (this.form.takeProfitValueMin === undefined) {
      return toBig(0);
    }

    return this.form.takeProfitValueMin;
  }

  @computed get takeProfitValueMax() {
    if (this.form.takeProfitValueMax === undefined) {
      return toBig(0);
    }

    return this.form.takeProfitValueMax;
  }

  @computed get takeProfitPercentMin() {
    return getPercentByAmount(this.form.triggerPrice, this.takeProfitValueMin);
  }

  @computed get takeProfitPercentMax() {
    return getPercentByAmount(this.form.triggerPrice, this.takeProfitValueMax);
  }

  @computed get takeProfitLimitMessage(): {errorKey: TFuncKey; amount: string} {
    if (this.form.isShort) {
      return {
        errorKey: 'surface.hodls.validation.should_be_gte',
        amount: `${this.takeProfitValueMin.toFixed(this.form.precision)} (${
          this.takeProfitPercentMin
        }%)`,
      };
    }
    return {
      errorKey: 'surface.hodls.validation.should_be_lte',
      amount: `${this.takeProfitValueMax.toFixed(this.form.precision)} (${
        this.takeProfitPercentMax
      }%)`,
    };
  }

  @computed get tpErrorIsClickable() {
    return this.form.takeProfitError === 'TAKE_PROFIT_LIMIT';
  }

  @computed get stopLossValueFormatted() {
    return this.form.stopLoss.toFixed(this.form.precision);
  }

  @computed get stopLossValueMin() {
    if (this.form.stopLossValueMin === undefined) {
      return toBig(0);
    }

    return this.form.stopLossValueMin;
  }

  @computed get stopLossValueMax() {
    if (this.form.stopLossValueMax === undefined) {
      return toBig(0);
    }

    return this.form.stopLossValueMax;
  }

  @computed get stopLossPercentMin() {
    return getPercentByAmount(this.form.triggerPrice, this.stopLossValueMin);
  }

  @computed get stopLossPercentMax() {
    return getPercentByAmount(this.form.triggerPrice, this.stopLossValueMax);
  }

  @computed get stopLossLimitMessage() {
    if (this.form.isShort) {
      return {
        errorKey: 'surface.hodls.validation.should_be_lte' as TFuncKey,
        amount: `${this.stopLossValueMax.toFixed(this.form.precision)} (${
          this.stopLossPercentMax
        }%)`,
      };
    }

    return {
      errorKey: 'surface.hodls.validation.should_be_gte' as TFuncKey,
      amount: `${this.stopLossValueMin.toFixed(this.form.precision)} (${
        this.stopLossPercentMin
      }%)`,
    };
  }

  @computed get slErrorIsClickable() {
    return this.form.stopLossError === 'MARGIN_CALL_LIMIT';
  }

  @computed get isLoading() {
    return this.form.isLoading;
  }
}
