import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const IdVerificationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M6.25 1.75A.75.75 0 005.5 1H3a2 2 0 00-2 2v2.5a.75.75 0 001.5 0v-2l.007-.117A1 1 0 013.5 2.5h2l.102-.007a.75.75 0 00.648-.743zm-4.5 12a.75.75 0 01.743.648l.007.102v2a1 1 0 00.883.993l.117.007h2a.75.75 0 010 1.5H3a2 2 0 01-2-2v-2.5a.75.75 0 01.75-.75zm16.5 0a.75.75 0 01.743.648L19 14.5V17a2 2 0 01-2 2h-2.5a.75.75 0 010-1.5h2a1 1 0 00.993-.883l.007-.117v-2a.75.75 0 01.75-.75zM17 1a2 2 0 012 2v2.5a.75.75 0 01-1.5 0v-2a1 1 0 00-1-1h-2a.75.75 0 010-1.5H17z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 7.5c0 .956-.447 1.808-1.144 2.357A4.997 4.997 0 0115 14.5a.75.75 0 01-1.5 0 3.5 3.5 0 10-7 0 .75.75 0 01-1.5 0 5 5 0 013.144-4.643A3 3 0 1113 7.5zM10 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
          fill={color}
        />
      </Svg>
    );
  }
);
