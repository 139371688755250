import * as React from 'react';
import {observer} from 'mobx-react';
import {type TKey, useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {FieldErrorMessage, Label} from '@youtoken/ui.form-elements';
import {
  TickerAndAmountInput,
  type WalletComboboxItem,
  type WalletComboboxGroup,
} from '@youtoken/ui.ticker-and-amount-input';

interface TickerInputProps extends BoxProps {
  labelKey: TKey;
  labelPlugins?: React.ReactNode;
  ticker: string;
  amount: string;
  initialAmount?: number;
  wallets: WalletComboboxItem[] | WalletComboboxGroup[];
  onChangeTicker: (ticker: string) => void;
  onChangeAmount: (amount: string) => void;
  onPressAllBalance?: () => void;
  hasError?: boolean;
  error?: string;
  inputTestId?: string;
  errorFieldTestId?: string;
  textInputEditable?: boolean;
  textInputAccessible?: boolean;
}

export const ExchangeFormConversionPairField: React.FC<TickerInputProps> =
  observer(
    ({
      labelKey,
      labelPlugins,
      ticker,
      amount,
      wallets,
      onPressAllBalance,
      onChangeTicker,
      onChangeAmount,
      hasError = false,
      error,
      inputTestId,
      errorFieldTestId,
      textInputEditable = true,
      textInputAccessible = true,
      ...props
    }) => {
      const {t} = useTranslation();

      return (
        <Box {...props}>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mb={10}
          >
            <Label>{t(labelKey)}</Label>
            {labelPlugins}
          </Box>
          <TickerAndAmountInput
            ticker={ticker}
            amount={amount}
            wallets={wallets}
            hasError={hasError}
            onChangeTicker={onChangeTicker}
            onChangeAmount={onChangeAmount}
            testID="FIELD_INPUT"
            textInputEditable={textInputEditable}
            textInputAccessible={textInputAccessible}
            focusAfterChangeTicker={false}
          />
          <FieldErrorMessage testID="FIELD_ERROR" visible={hasError}>
            {error}
          </FieldErrorMessage>
        </Box>
      );
    }
  );
