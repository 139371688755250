import {observable, computed, action, comparer} from 'mobx';
import {computedFn} from 'mobx-utils';
import {concat, without} from 'lodash';
import {matchSorter} from 'match-sorter';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {LOCAL_CONFIRMATIONS} from '@youtoken/ui.local-confirmations';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {TRANSPORT} from '@youtoken/ui.transport';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {invariant, warning} from '@youtoken/ui.utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  createFeature,
  getResourceDescriptor,
  DATA_STORAGE,
} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {
  SavingsChooseTickersResource,
  SavingsOverviewResource,
} from '@youtoken/ui.resource-savings';
import type {ListItem, ListItemAsset} from './types';

export class SavingsSelectAssetsFeature extends createFeature({
  getKey: () => `SavingsSelectAssetsFeature`,
  getResources: () => {
    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      savings: getResourceDescriptor(SavingsChooseTickersResource, {}),
      savingsOverview: getResourceDescriptor(SavingsOverviewResource, {}),
    };
  },
}) {
  //#region filter

  @observable
  filterSearch = '';

  @action
  setFilterSearch = (value: string) => {
    this.filterSearch = value;
  };

  //#endregion filter

  //#region items

  @computed
  get itemsAssetsWithFilter() {
    const assetsFiltered = matchSorter(
      this.resources.savings.all,
      this.filterSearch,
      {
        keys: ['ticker', 'tickerName'],
      }
    );

    return assetsFiltered.map(({ticker}) => {
      return {
        type: 'asset',
        data: {ticker},
      };
    }) as ListItemAsset[];
  }

  @computed
  get itemsAssetsWithGroups() {
    const itemsAssetsTop = this.resources.savings.top.map(({ticker}) => {
      return {
        type: 'asset',
        data: {ticker},
      };
    });

    const itemsAssetsOther = this.resources.savings.other.map(({ticker}) => {
      return {
        type: 'asset',
        data: {ticker},
      };
    });

    const itemsTop = itemsAssetsTop.length
      ? [
          {
            type: 'title',
            data: {
              title: i18n.t('surface.savings.select_assets.most_profitable'),
            },
          },
          ...itemsAssetsTop,
        ]
      : [];

    const itemsOther = itemsAssetsOther.length
      ? [
          {
            type: 'title',
            data: {
              title: i18n.t('surface.savings.select_assets.other'),
            },
          },
          ...itemsAssetsOther,
        ]
      : [];

    return [...itemsTop, ...itemsOther] as ListItem[];
  }

  @computed({equals: comparer.shallow})
  get items() {
    if (this.filterSearch) {
      return this.itemsAssetsWithFilter;
    }

    return this.itemsAssetsWithGroups;
  }

  getAssetItemPropsByTicker = computedFn(
    (ticker: string) => {
      const {
        wallets: {getByTicker},
        savings: {
          data: {level},
          getAssetByTicker,
        },
      } = this.resources;

      const wallet = getByTicker(ticker);

      invariant(
        wallet,
        `Wallet with ticker ${ticker} not found`,
        {},
        {wallet, ticker}
      );

      const {
        ticker: baseTicker,
        amountFormatted: baseAmountFormatted,
        equivalentTicker: quoteTicker,
        equivalentFormatted: quoteAmountFormatted,
      } = wallet;

      const asset = getAssetByTicker(baseTicker);

      warning(asset, 'Asset not found', {}, {wallet, baseTicker});

      return {
        baseTicker,
        baseAmountFormatted,
        quoteTicker,
        quoteAmountFormatted,
        level,
        aprFormatted: asset?.aprFormatted ?? '',
      };
    },
    {equals: comparer.shallow}
  );

  getAssetItemActionPropsByTicker = computedFn(
    (ticker: string) => {
      const selected = this.tickers.includes(ticker);
      const disabled =
        this.tickers.length >= this.requiredTickersCount && !selected;

      return {selected, disabled};
    },
    {equals: comparer.shallow}
  );

  //#endregion items

  //#region selected tickers

  @computed
  get requiredTickersCount() {
    return this.resources.savings.data.tickerAmount;
  }

  @computed
  get tickersDefault() {
    return this.resources.savings.selected.map(({ticker}) => ticker);
  }

  @observable
  tickers = this.tickersDefault;

  @action
  toggleTicker = (ticker: string) => {
    const {disabled} = this.getAssetItemActionPropsByTicker(ticker);

    if (disabled) {
      return;
    }

    this.tickers = this.tickers.includes(ticker)
      ? without(this.tickers, ticker)
      : concat(this.tickers, ticker);
  };

  //#endregion selected tickers

  //#region submit and reset

  @computed
  get disabled() {
    return this.tickers.length < 1;
  }

  @observable
  submitting = false;

  @action
  toggleSubmitting = () => {
    this.submitting = !this.submitting;
  };

  @action
  submit = () => {
    DATA_LAYER.trackStrict('savings-sprint-start-attempt', {});

    this.toggleSubmitting();
    return TRANSPORT.API.post('/v4/saving/start', {
      tickers: this.tickers,
    })
      .then(() => {
        this.resources.savingsOverview.setStatusWaiting();

        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
        SHARED_ROUTER_SERVICE.navigate('WalletsSavings');
      })
      .catch(axiosError => {
        const error = axiosError?.response?.data;

        const _error = extractErrorFromResponse(error, '_error');
        const _errorTranslated = getTranslatedValidationMessage(_error);

        LOCAL_NOTIFICATIONS.error({
          text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
        });
      })
      .finally(this.toggleSubmitting);
  };

  @action
  submitWithConfirmation = () => {
    const tickercCount = this.tickers.length;
    const tickersMaxCount = this.requiredTickersCount;

    if (tickercCount < tickersMaxCount) {
      return LOCAL_CONFIRMATIONS.requestConfirmation({
        title: i18n.t('surface.savings.select_assets.confirmation.title'),
        description: i18n.t(
          'surface.savings.select_assets.confirmation.description',
          {
            value: tickercCount,
            maxValue: tickersMaxCount,
          }
        ),
      }).then(this.submit);
    }

    return this.submit();
  };

  @action
  reset = () => {
    // NOTE: Hard reset selected
    DATA_STORAGE.delete(this.resources.savings.getKey({}));
    DATA_STORAGE.delete(this.getKey({}));
  };

  //#endregion submit and reset
}
