import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {TColorTokens, Theme} from '@youtoken/ui.primitives';
import {warning} from '@youtoken/ui.utils';
import {useIconColor} from './useIconColor';
import {QuestionOutlinedIcon} from '../__generated/Icon/QuestionOutlined';

export type IconProps<IconName> = Omit<SvgProps, 'color'> & {
  /** color of icon */
  color?: keyof Theme['colors'];
  /**
   * size of icon (outer boundaries)
   * @default 24
   * */
  size?: number;
  /** name of icon in icon set provided */
  name: IconName;
};

type IconComponentDescriptor<IconSet> = {
  /** component name, something line "Icon" or "Icon.Smth"
   *
   * also display name of the component
   */
  componentName: string;
  /** set of icons to choose from; must comply to interface `{[name]: React.FC<..>, ...}` */
  iconSet: IconSet;
  /** default icon props */
  defaultSize: number;
  defaultColor?: keyof TColorTokens;
  /** fallback icon component will be rendered in case we could not find "name" in iconSet */
  fallbackIconComponent?: React.FC<SvgProps & {size?: number}>;
};

export const createIconComponent = <
  IconName extends string,
  IconSet extends {[key in IconName]: React.FC<any>}
>(
  descriptor: IconComponentDescriptor<IconSet>
) => {
  const {
    componentName,
    defaultSize,
    defaultColor,
    fallbackIconComponent = QuestionOutlinedIcon,
    iconSet,
  } = descriptor;

  const IconComponent: React.FC<IconProps<IconName>> = ({
    color = defaultColor,
    name,
    size = defaultSize,
    ...props
  }) => {
    const isIconPresentInSet = Boolean(iconSet[name]);

    // map color to theme color or "throw" red color in development for missing icon
    const endColor = useIconColor(
      !isIconPresentInSet && ENVIRONMENT.DEV ? '$danger-01' : color
    );

    warning(
      isIconPresentInSet,
      `${componentName}: icon with name="${name}" was not found!`,
      {name}
    );

    const IconComponent = isIconPresentInSet
      ? iconSet[name]
      : fallbackIconComponent;

    return <IconComponent color={endColor} size={size} {...props} />;
  };

  IconComponent.displayName = componentName;

  return IconComponent;
};
