import {RatesResource} from '@youtoken/ui.resource-rates';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {AdjustTpSlForm} from './AdjustTpSlForm';
import {AdjustTpSlFormView} from './AdjustTpSlFormView';

export interface AdjustTpSlFormResources {
  ratesResource: RatesResource;
  hodl: HODLByIdResource;
}

export class AdjustTpSlFormClass {
  form: AdjustTpSlForm;
  view: AdjustTpSlFormView;

  constructor(resources: AdjustTpSlFormResources, args: any = {}) {
    this.form = new AdjustTpSlForm(resources, args);
    this.view = new AdjustTpSlFormView(this.form);
  }
}
