import {computed, action} from 'mobx';
import {getResourceDescriptor, createFeature} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  LoansListRegularResource,
  TariffsLoansRegularResource,
} from '@youtoken/ui.resource-loans';
import {WalletsResource} from '@youtoken/ui.resource-wallets';

export class LoansListRegularFeature extends createFeature({
  getKey: () => `LoansListRegularCreate`,
  getResources: () => ({
    rates: getResourceDescriptor(RatesResource, {}),
    authMe: getResourceDescriptor(AuthMeResource, {}),
    tariffs: getResourceDescriptor(TariffsLoansRegularResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
    loansListRegular: getResourceDescriptor(LoansListRegularResource, {}),
  }),
}) {
  @computed
  get createNewDisabled() {
    return !(
      this.resources.authMe.data.enableGetLoan &&
      Object.keys(
        this.resources.tariffs.getLoanMarketsWithWhiteList(
          this.resources.wallets.loanEnabledTickers
        )
      ).length
    );
  }

  @computed
  get totalPages() {
    return this.resources.loansListRegular.totalPages;
  }
  @computed
  get activePage() {
    return this.resources.loansListRegular.activePage;
  }

  @computed
  get hasRows() {
    return this.resources.loansListRegular.hasRows;
  }

  @computed
  get rows() {
    return this.resources.loansListRegular.rows;
  }

  @computed
  get rowsActive() {
    return this.resources.loansListRegular.rowsActive;
  }

  @computed
  get rowsClosed() {
    return this.resources.loansListRegular.rowsClosed;
  }

  @action
  setActivePage = (page: number) => {
    this.resources.loansListRegular.setActivePage(page);
  };
}
