import big, {Big} from 'big.js';
import {custom, serializable, primitive} from 'serializr';

const _number = () => {
  return custom(
    outValue => outValue,
    inValue => {
      let inValuePrepared;
      try {
        inValuePrepared = big(inValue);
      } catch (e) {
        inValuePrepared = big(0);
      }
      return inValuePrepared;
    },
    {
      beforeDeserialize: (callback, jsonValue) => {
        callback(null, jsonValue || 0);
      },
    }
  );
};

export class FeeItemResponse {
  @serializable(primitive())
  conversionTicker!: string;

  @serializable(primitive())
  estimationTime!: string;

  @serializable(primitive())
  estimationPeriod!: string;

  @serializable(_number())
  max!: Big;

  @serializable(_number())
  maxAmount!: Big;

  @serializable(primitive())
  method!: string;

  @serializable(_number())
  min!: Big;

  @serializable(_number())
  minAmount!: Big;

  @serializable(_number())
  percent!: Big;

  @serializable(primitive())
  provider!: string;

  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  enabled!: boolean;
}
