import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {TextInput} from '@youtoken/ui.inputs';
import {type SignUpForm} from '../state';

export const ReferralCodeField: React.FC<BoxProps> = observer(
  ({testID, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        showReferralCodeField,
        onShowReferralCodeFieldChange,
        referralCodeValue,
        onReferralCodeChange,
        hasReferralCodeError,
        referralCodeError,
      },
    } = useForm<SignUpForm>();

    return (
      <Box {...boxProps}>
        <TouchableBox
          flexDirection="row"
          justifyContent="space-between"
          testID="REFERRAL_CHECKBOX"
          onPress={() => onShowReferralCodeFieldChange(!showReferralCodeField)}
        >
          <Text variant="$body-01-high-accent" style={{flex: 1}}>
            {t('forms.sign_up.fields.promo.label_with_referral')}
          </Text>
          <Switch
            onValueChange={onShowReferralCodeFieldChange}
            value={showReferralCodeField}
          />
        </TouchableBox>

        {showReferralCodeField && (
          <Box mt={24}>
            <TextInput
              size="large"
              value={referralCodeValue}
              onChangeText={onReferralCodeChange}
              placeholder={t('forms.sign_up.fields.promo.placeholder')}
              hasError={hasReferralCodeError}
              autoComplete="off"
              testID="REFERRAL_INPUT"
            />
            <FieldErrorMessage visible={hasReferralCodeError} placement="left">
              {getTranslatedValidationMessage(referralCodeError)}
            </FieldErrorMessage>
          </Box>
        )}
      </Box>
    );
  }
);
