export const withoutHeaderScreenOptions = {
  headerShown: false,
};

export const transparentScreenOptions = {
  presentation: 'transparentModal',
  headerShown: false,
  cardStyle: {
    backgroundColor: 'transparent',
  },
  detachPreviousScreen: false,
  animationEnabled: false,
};
