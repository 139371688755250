import * as React from 'react';
import HelmetWeb from 'react-helmet';
import {Platform} from 'react-native';

export function Helmet(props: React.ComponentProps<typeof HelmetWeb>) {
  if (Platform.OS !== 'web') {
    return null;
  }

  <HelmetWeb {...props}>
    <meta name="apple-itunes-app" content="" />
  </HelmetWeb>;
}
