import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {ValidationMessage} from '@youtoken/ui.hodl-elements';

interface TakeProfitSectionProps extends BoxProps {}

export const TakeProfitSection: React.FC<TakeProfitSectionProps> = observer(
  ({...boxProps}) => {
    const {
      form: {updateTakeProfitValue, updateTakeProfitPercent, isShort},
      view: {
        maxProfitIsPositive,
        maxProfitFormatted,
        maxProfitTickerFormatted,
        takeProfitValue,
        takeProfitPercent,
        takeProfitPercentMin,
        takeProfitPercentMax,
        takeProfitLimitMessage,
        setTakeProfitLimit,
        tpError,
        tpErrorIsClickable,
      },
    } = useForm<CreateHODLFormClass>();
    const {t} = useTranslation();
    const testIdSection = 'TAKE_PROFIT';

    const validationComponent = React.useMemo(() => {
      return (
        <ValidationMessage
          validationMessage={takeProfitLimitMessage}
          onValidationClick={setTakeProfitLimit}
          validationError={tpError}
          isValidationClickable={tpErrorIsClickable}
          testIdSection={testIdSection}
        />
      );
    }, [
      takeProfitLimitMessage,
      setTakeProfitLimit,
      tpError,
      tpErrorIsClickable,
    ]);

    return (
      <Box {...boxProps}>
        <Box
          position="relative"
          zIndex={1}
          mb={6}
          width="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexDirection="row" alignItems="center">
            <Label mr={5}>{t('surface.hodls.adjust_tp.take_profit')}</Label>
            <QuestionTooltip
              content={t('surface.hodls.adjust_tp.take_profit_tooltip')}
            />
          </Box>
          <Badge
            alignSelf="center"
            variant={maxProfitIsPositive ? 'success' : 'danger'}
            size="small"
            type="secondary"
            py={3}
          >
            <Text
              color={maxProfitIsPositive ? '$success-01' : '$danger-01'}
              variant="$body-01"
              weight="regular"
            >
              <Text weight="bold">{maxProfitFormatted}</Text>{' '}
              {maxProfitTickerFormatted}
            </Text>
          </Badge>
        </Box>
        <AmountAndPercentInput
          amountLabel={t('surface.hodls.adjust_tp.level')}
          percentLabel={t('surface.hodls.adjust_tp.change')}
          amountValue={takeProfitValue}
          percentValue={takeProfitPercent}
          percentRangeMin={takeProfitPercentMin}
          percentRangeMax={takeProfitPercentMax}
          onChangeAmount={updateTakeProfitValue}
          onChangePercent={updateTakeProfitPercent}
          sliderKey="takeProfit"
          validationComponent={validationComponent}
          shouldReverseSlider={isShort}
          testIdSection={testIdSection}
        />
      </Box>
    );
  }
);
