import * as React from 'react';
import {
  Defs,
  LinearGradient,
  Path,
  Stop,
  Svg,
  SvgProps,
} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const ManaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Path
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
          fill={`url(#${_id}_mana_svg__mana_svg__paint0_linear_3978_3633)`}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.336 10.4v12h10l-10-12z"
          fill={`url(#${_id}_mana_svg__mana_svg__paint1_linear_3978_3633)`}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.336 22.4h10v-12l-10 12z"
          fill="#fff"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.2 25.6a16.387 16.387 0 003.2 3.2h19.2a16.387 16.387 0 003.2-3.2H3.2z"
          fill="#FC9965"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.4 28.8c2.672 2.008 6 3.2 9.6 3.2 3.6 0 6.928-1.192 9.6-3.2H6.4z"
          fill="#FF2D55"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.464 16.8v8.8H28.8l-7.336-8.8z"
          fill={`url(#${_id}_mana_svg__mana_svg__paint2_linear_3978_3633)`}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.464 22.4H1.336A15.932 15.932 0 003.2 25.6h18.272v-3.2h-.008z"
          fill="#FFBC5B"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.136 25.6h7.328v-8.8l-7.328 8.8z"
          fill="#fff"
        />
        <Path
          d="M21.464 14.4a4 4 0 100-8 4 4 0 000 8zM11.336 8a2 2 0 100-4 2 2 0 000 4z"
          fill="#FFC95B"
        />
        <Defs>
          <LinearGradient
            id={`${_id}_mana_svg__mana_svg__paint0_linear_3978_3633`}
            x1={27.314}
            y1={4.686}
            x2={4.686}
            y2={27.314}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FF2D55" />
            <Stop offset={1} stopColor="#FFBC5B" />
          </LinearGradient>
          <LinearGradient
            id={`${_id}_mana_svg__mana_svg__paint1_linear_3978_3633`}
            x1={16.332}
            y1={10.4}
            x2={16.332}
            y2={22.4}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#A524B3" />
            <Stop offset={1} stopColor="#FF2D55" />
          </LinearGradient>
          <LinearGradient
            id={`${_id}_mana_svg__mana_svg__paint2_linear_3978_3633`}
            x1={25.129}
            y1={16.8}
            x2={25.129}
            y2={25.6}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#A524B3" />
            <Stop offset={1} stopColor="#FF2D55" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
