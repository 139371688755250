import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type ConfirmationOptions} from '@youtoken/ui.local-confirmations';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

export interface ConfirmationProps extends ConfirmationOptions {
  confirm?: () => void;
  cancel?: () => void;
}

export const Confirmation: React.FC<ConfirmationProps> = ({
  title,
  description,
  confirmText,
  cancelText,
  confirm,
  cancel,
}) => {
  const {t} = useTranslation();

  return (
    <Box testID="CONFIRMATION" flex={1}>
      <Box flex={1} p={{default: 16, phone: 24}}>
        <Heading testID="CONFIRMATION_TITLE" variant="$heading-01-responsive">
          {title}
        </Heading>
        {Boolean(description) && (
          <Box pt={32}>
            <Text testID="CONFIRMATION_DESCRIPTION" variant="$body-01">
              {description}
            </Text>
          </Box>
        )}
      </Box>
      <Box p={{default: 16, phone: 24}} borderTopWidth={1} borderColor="$ui-01">
        <Box flexDirection="row" mx={-8}>
          <Button
            testID="CONFIRMATION_CANCEL_BUTTON"
            type="secondary"
            flex={1}
            onPress={cancel}
            mx={8}
          >
            {cancelText ?? t('common.actions.no')}
          </Button>
          <Button
            testID="CONFIRMATION_CONFIRM_BUTTON"
            flex={1}
            onPress={confirm}
            mx={8}
          >
            {confirmText ?? t('common.actions.yes')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
