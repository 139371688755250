// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {computedFn} from 'mobx-utils';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {messages} from '@youtoken/ui.validation-messages';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {getDefaultInputTicker} from '../utils';
import {i18n} from '@youtoken/ui.service-i18n';
import {nanoid} from 'nanoid';
import {
  CreateHODLFormArgs,
  CreateHODLFormHodlObject,
  CreateHODLFormResources,
} from './types';
import {CalculatedDataResponse} from './CalculatedDataResponse';
import Big from 'big.js';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  handleFormSubmitError,
  helperToGetIsFormAgreementsPreAccepted,
} from '@youtoken/ui.form-utils';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import {
  type DirectionType,
  formatInstrumentsItems,
  getAmountByPercent,
  getAmountListFormatted,
  getDefaultInstrument,
  getPercentByAmount,
  getSourceWallets,
  getWalletComboboxItems,
  type HODlInstrumentItem,
} from '@youtoken/ui.hodls-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {HodlTariffResponseItem} from '@youtoken/ui.resource-hodl-tariffs';
import {invariant} from '@youtoken/ui.utils';

export class CreateHODLForm {
  //#region initial params
  @observable
  public args: CreateHODLFormArgs;

  @observable
  public resources: CreateHODLFormResources;

  @observable
  public instance: MobxReactForm;

  setOfTriggerPricePercent = ['-2', '-1', '-0.5', '0.5', '1', '2'];

  disposers: Array<IReactionDisposer>;

  //#endregion initial params

  @computed
  public get bonusBalance() {
    return this.resources.walletsResource.bonusesWallet?.amount;
  }

  @computed
  public get bonusBalanceFormatted() {
    const balanceFormatted = this.bonusBalance?.eq(0)
      ? '0'
      : formatByTicker(this.bonusBalance, this.additionalInputTickerBE);

    // NOTE: we use $ as our bonuses are equal to usd
    return `$${balanceFormatted}`;
  }

  //#region fields
  @computed
  public get hodlInstrument() {
    return this.instance.$('hodlInstrument').get('value');
  }

  @computed
  public get direction() {
    return this.instance.$('direction').get('value');
  }

  @computed
  public get inputAmount() {
    return this.instance.$('inputAmount').get('value');
  }

  @computed
  public get inputAmountError() {
    return this.instance.$('inputAmount').get('error');
  }

  @computed
  public get inputAmountUsd() {
    const {getRate} = this.resources.ratesResource;
    const rate = getRate(this.inputTicker, 'usd');
    return new Big(this.inputAmount || 0).times(toBig(rate));
  }

  @computed
  public get isAmountPositive() {
    return Number(this.inputAmount) > 0;
  }

  @computed
  public get inputTicker() {
    return this.instance.$('inputTicker').get('value');
  }

  @computed
  public get multiplier() {
    return this.instance.$('multiplier').get('value');
  }

  @computed
  public get takeProfit() {
    return this.instance.$('takeProfit').get('value');
  }

  @computed
  public get stopLoss() {
    return this.instance.$('stopLoss').get('value');
  }

  @computed
  public get adjustTpSlActive(): boolean {
    return this.instance?.$('adjustTpSlActive').get('value');
  }

  @computed
  public get setAdjustTpSlActive() {
    return this.instance.$('adjustTpSlActive').get('onChange');
  }

  @computed
  public get bonusesEnabled(): boolean {
    const incentivesProduct = this.resources.authMeResource.products.incentives;

    return Boolean(
      incentivesProduct?.available &&
        incentivesProduct?.isEnabled &&
        this.currentTariff?.bonusesEnabled
    );
  }

  @computed
  public get useBonusesActive(): boolean {
    return this.instance?.$('useBonusesActive').get('value');
  }

  @computed
  public get setUseBonusesActive() {
    return this.instance?.$('useBonusesActive').get('onChange');
  }

  @computed
  public get hasEnoughBonuses() {
    return this.bonusBalance?.gt(this.minBonusesAmount) ?? false;
  }

  @observable minBonusesAmount = 0.01; // todo is this from docs?

  @computed get maxBonusesPercent() {
    if (!this.bonusesEnabled) {
      return undefined;
    }

    return this.currentTariff?.maxBonusesPercent;
  }

  @computed get maxBonusesAmountByInput() {
    const percentRestrictedMax = formatByTicker(
      new Big(this.inputAmountUsd || 0)
        .mul(this.maxBonusesPercent || 0)
        .div(100),
      this.additionalInputTickerBE,
      0
    );

    const amountRestrictedMax = this.currentTariff?.maxBonusesAmount;

    return Math.min(Number(percentRestrictedMax), Number(amountRestrictedMax));
  }

  @computed get maxBonusesAmount() {
    return formatByTicker(
      Math.min(this.maxBonusesAmountByInput, Number(this.bonusBalance)),
      this.additionalInputTickerBE
    );
  }

  @computed get additionalInputAmount() {
    return this.instance?.$('additionalInputAmount').get('value');
  }

  @computed get additionalInputAmountError() {
    return this.instance?.$('additionalInputAmount').error;
  }

  @computed
  public get isPending() {
    return (
      !this.pendingOrderDisabled && this.instance?.$('isPending').get('value')
    );
  }

  @computed
  public get changeIsPending() {
    return this.instance?.$('isPending').get('onChange');
  }

  @computed
  public get triggerPrice() {
    return this.instance.$('triggerPrice').get('value');
  }

  @computed
  public get isAgreementsAccepted() {
    return this.instance.$('isAgreementsAccepted').get('value');
  }

  @computed
  public get setIsAgreementsAccepted() {
    return this.instance.$('isAgreementsAccepted').get('onChange');
  }

  //#endregion fields

  //#region helpers
  @observable
  requestId: string = '';

  @observable
  pendingOrderDisabled: boolean = true;

  @observable
  rateInputTickerUsd!: Big;

  @observable
  calculating: boolean = false;

  @observable
  currentCalculateRequestId: any = null;

  @observable
  latestMultiplier: any;

  @observable
  latestTariffId: any;

  @observable
  latestTriggerPrice?: number;

  // todo optimize calculatedRawData and calculatedData
  @observable
  calculatedRawData: null | any = null;

  @observable
  stopLossChangedByUser = false;

  @observable
  takeProfitChangedByUser = false;

  @observable
  takeProfitPercent = '0';

  @observable
  stopLossPercent = '0';

  @observable
  triggerPricePercent = '0';

  @observable
  additionalInputTickerBE = 'bonus'; // NOTE: we can't use yhusd in interfaces

  //#endregion helpers

  //#region computed data for view and auto updates
  @computed get createHODlUrl() {
    return this.isPending ? '/v3/hodl/pending' : '/v3/hodl';
  }

  @computed.struct get calculatedData() {
    if (!this.calculatedRawData) {
      return null;
    }

    return deserialize(CalculatedDataResponse, this.calculatedRawData);
  }

  @computed get volumeForecast() {
    return this.calculatedData?.volumeForecast;
  }

  @computed get sparksAmount() {
    return this.calculatedData?.sparksAmount ?? 0;
  }

  @computed get baseTicker() {
    return this.hodlInstrument.baseTicker;
  }

  @computed get quoteTicker() {
    return this.hodlInstrument.quoteTicker;
  }

  @computed
  public get disableSell() {
    const tariffSell = this.tariffs.find(
      tariff =>
        tariff.baseTicker === this.baseTicker &&
        tariff.quoteTicker === this.quoteTicker &&
        tariff.isShort === true
    );
    return tariffSell === undefined;
  }

  @computed
  public get disableBuy() {
    const tariffBuy = this.tariffs.find(
      tariff =>
        tariff.baseTicker === this.baseTicker &&
        tariff.quoteTicker === this.quoteTicker &&
        tariff.isShort === false
    );
    return tariffBuy === undefined;
  }

  @computed get tariffs() {
    return this.resources?.tariffsResource?.tariffsWithoutIndexes || [];
  }

  @computed get currentTariff(): HodlTariffResponseItem | undefined {
    return this.tariffs.find(
      tariff =>
        tariff.baseTicker === this.baseTicker &&
        tariff.quoteTicker === this.quoteTicker &&
        tariff.isShort === this.isShort
    ) as HodlTariffResponseItem | undefined;
  }

  @computed get precision() {
    return this.currentTariff?.precision;
  }

  @computed get isShort() {
    return this.direction === 'sell';
  }

  @computed.struct get hodlObj() {
    const inputAmount = Number(this.inputAmount);
    const inputTicker = this.inputTicker;
    const tariffId = this.currentTariff?._id!;
    const multiplier = Number(this.multiplier);
    const tpSource = this.takeProfit;
    const slSource = this.stopLoss;

    const obj: CreateHODLFormHodlObject = {
      inputAmount,
      inputTicker,
      tariffId,
      multiplier,
    };

    if (this.adjustTpSlActive) {
      if (tpSource && this.takeProfitChangedByUser) {
        obj.tp = Number(tpSource);
      }

      if (slSource && this.stopLossChangedByUser) {
        obj.sl = Number(slSource);
      }
    }

    if (this.isPending && this.triggerPrice > 0) {
      obj.triggerPrice = Number(this.triggerPrice);
    }

    if (this.bonusesEnabled && this.useBonusesActive) {
      obj.additionalInputAmount = Number(this.additionalInputAmount);
      obj.additionalInputTicker = this.additionalInputTickerBE;
    }

    return obj;
  }

  @computed get calculatedArgs() {
    return this.hodlObj;
  }

  @computed.struct get instrumentsItems(): HODlInstrumentItem[] {
    return formatInstrumentsItems(this.tariffs);
  }

  @computed.struct
  public get hodlsWalletList() {
    return getWalletComboboxItems(
      this.resources.walletsResource.walletsListSortedByEquivalent
    );
  }

  @computed get sourceWallets() {
    const tariff = this.currentTariff;

    if (!tariff) {
      return [];
    }

    return getSourceWallets(tariff, this.hodlsWalletList);
  }

  @computed get allSourceAmount() {
    const currentWallet = this.sourceWallets.find(
      wallet => wallet.ticker === this.inputTicker
    );
    // in case there are no balance, we return '0', but can use some default value for simulate calculation
    return currentWallet?.amountFormatted ?? '0';
  }

  @computed get amountListFormatted() {
    return getAmountListFormatted(this.inputTicker, this.rateInputTickerUsd);
  }

  @computed get defaultSourceAmount() {
    return Number(this.allSourceAmount) < Number(this.amountListFormatted[0])
      ? this.allSourceAmount
      : Number(this.allSourceAmount) < Number(this.amountListFormatted[1])
      ? this.amountListFormatted[0]
      : this.amountListFormatted[1];
  }

  // NOTE: think about update to current price
  // NOTE: think about adding delta = 0.1%
  @computed get initialPriceServer() {
    return toBig(this.calculatedData?.initialPrice);
  }

  @computed get triggerPriceBig() {
    return toBig(this.triggerPrice);
  }

  @computed get defaultTriggerPricePercent() {
    const triggerPriceDistanceMaxPercent = this.triggerPriceDistanceMax * 100;

    return triggerPriceDistanceMaxPercent <=
      Number(this.setOfTriggerPricePercent[3])
      ? `${this.isShort ? '' : '-'}${triggerPriceDistanceMaxPercent}`
      : this.setOfTriggerPricePercent[this.isShort ? 3 : 2]; // NOTE: 2 element is -0.5, 3 element is 0.5
  }

  @computed get initialPriceOrder() {
    return this.isPending ? this.triggerPriceBig : this.initialPriceServer;
  }

  @computed get pendingOrderExpirationPeriod() {
    return this.currentTariff?.expirationPeriod;
  }

  @computed get triggerPriceDistanceMax() {
    return this.currentTariff?.triggerPriceDistanceMax ?? 0;
  }

  @computed get triggerPriceDistanceValueLimit() {
    return this.initialPriceServer.times(this.triggerPriceDistanceMax);
  }

  @computed get triggerPriceValueMin() {
    const minPrice = this.initialPriceServer.minus(
      this.triggerPriceDistanceValueLimit
    );

    return minPrice.gt(0) ? minPrice.toFixed(this.precision, 3) : '0';
  }

  @computed get triggerPriceValueMax() {
    return this.initialPriceServer
      .plus(this.triggerPriceDistanceValueLimit)
      .toFixed(this.precision, 0);
  }

  @computed get takeProfitValueMin() {
    const value = this.isShort
      ? this.calculatedData?.ftpPrice
      : this.initialPriceOrder;

    try {
      return value?.toFixed(this.precision, 3);
    } catch (e) {
      return undefined;
    }
  }

  @computed get takeProfitValueMax() {
    const value = this.isShort
      ? this.initialPriceOrder
      : this.calculatedData?.ftpPrice;

    try {
      return value?.toFixed(this.precision, 0);
    } catch (e) {
      return undefined;
    }
  }

  @computed get stopLossValueMin() {
    const value = this.isShort
      ? this.initialPriceOrder
      : this.calculatedData?.mcPrice;

    try {
      return value?.toFixed(this.precision, 3);
    } catch (e) {
      return undefined;
    }
  }

  @computed get stopLossValueMax() {
    const value = this.isShort
      ? this.calculatedData?.mcPrice
      : this.initialPriceOrder;

    try {
      return value?.toFixed(this.precision, 0);
    } catch (e) {
      return undefined;
    }
  }

  @computed get hodlExpirationPeriod() {
    return this.currentTariff?.hodlExpirationPeriod;
  }

  //#endregion computed data for view and auto updates

  public constructor(
    resources: CreateHODLFormResources,
    args: CreateHODLFormArgs
  ) {
    this.resources = resources;
    this.args = args;
    // NOTE: args is optional
    const {
      baseTicker,
      quoteTicker,
      direction: argDirection,
      inputTicker: argInputTicker,
      inputAmount: argInputAmount,
      additionalInputAmount: argAdditionalInputAmount,
    } = args;
    this.requestId = nanoid();

    const hodlInstrumentValue = getDefaultInstrument(
      this.instrumentsItems,
      baseTicker,
      quoteTicker
    );

    const defaultDirection = argDirection || 'buy';

    const defaultInputTicker =
      argInputTicker ??
      getDefaultInputTicker(baseTicker!, quoteTicker!, defaultDirection);

    // NOTE: for updates use effects and reaction outside the constructor
    const fields = {
      hodlInstrument: {
        value: hodlInstrumentValue,
      },
      direction: {
        value: defaultDirection,
      },
      inputTicker: {
        value: defaultInputTicker,
      },
      inputAmount: {
        value: argInputAmount ?? '0',
      },
      multiplier: {
        value: 2,
      },
      takeProfit: {
        value: '',
      },
      stopLoss: {
        value: '',
      },
      adjustTpSlActive: {
        value: false,
      },
      useBonusesActive: {
        value: Number(argAdditionalInputAmount) > 0,
      },
      additionalInputAmount: {
        value: argAdditionalInputAmount ?? this.minBonusesAmount,
      },
      isPending: {
        value: false,
      },
      triggerPrice: {
        value: '',
      },
      isAgreementsAccepted: {
        value: helperToGetIsFormAgreementsPreAccepted(),
      },
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        if (!this.resources.authMeResource.checkProductAvailability('hodl')) {
          return Promise.resolve();
        }

        const date = new Date().toISOString();
        const rateObj = this.resources.ratesResource.getRateObj(
          this.currentTariff?.baseTicker,
          this.currentTariff?.quoteTicker
        );

        const initial = this.currentTariff?.isShort ? rateObj.bid : rateObj.ask;

        return TRANSPORT.API.post(this.createHODlUrl, {
          ...this.hodlObj,
          date,
          requestId: this.requestId,
          initial,
        })
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.hodls.hodl_form.message.created'),
            });
            SHARED_ROUTER_SERVICE.navigate('MultiHODLPortfolio');

            // to update bonus balance
            this.resources.walletsResource.refetch();
          })
          .catch(e => {
            handleFormSubmitError(form, e, {});
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            let takeProfitValidator = yup.mixed();
            let stopLossValidator = yup.mixed();
            let triggerPriceValidator = yup.mixed();

            if (this.adjustTpSlActive) {
              if (this.takeProfitChangedByUser) {
                invariant(
                  this.takeProfitValueMax && this.takeProfitValueMin,
                  'takeProfitValueMax and takeProfitValueMin is required for validation',
                  {},
                  {
                    takeProfitValueMax: this.takeProfitValueMax,
                    takeProfitValueMin: this.takeProfitValueMin,
                  }
                );

                takeProfitValidator = this.currentTariff?.isShort
                  ? yup
                      .big()
                      .gt(0)
                      .lt(
                        this.takeProfitValueMax,
                        this.isPending
                          ? messages.SHOULD_BE_LT_TRIGGER_PRICE
                          : messages.SHOULD_BE_LT_CURRENT_PRICE
                      )
                      .gte(this.takeProfitValueMin, 'TAKE_PROFIT_LIMIT')
                  : yup
                      .big()
                      .gt(
                        this.takeProfitValueMin,
                        this.isPending
                          ? messages.SHOULD_BE_GT_TRIGGER_PRICE
                          : messages.SHOULD_BE_GT_CURRENT_PRICE
                      )
                      .lte(this.takeProfitValueMax, 'TAKE_PROFIT_LIMIT');
              }

              if (this.stopLossChangedByUser) {
                invariant(
                  this.stopLossValueMax && this.stopLossValueMin,
                  'stopLossValueMax and stopLossValueMin is required for validation',
                  {},
                  {
                    stopLossValueMax: this.stopLossValueMax,
                    stopLossValueMin: this.stopLossValueMin,
                  }
                );

                stopLossValidator = this.currentTariff?.isShort
                  ? yup
                      .big()
                      .gt(
                        this.stopLossValueMin,
                        this.isPending
                          ? messages.SHOULD_BE_GT_TRIGGER_PRICE
                          : messages.SHOULD_BE_GT_CURRENT_PRICE
                      )
                      .lte(this.stopLossValueMax, 'MARGIN_CALL_LIMIT')
                  : yup
                      .big()
                      .lt(
                        this.stopLossValueMax,
                        this.isPending
                          ? messages.SHOULD_BE_LT_TRIGGER_PRICE
                          : messages.SHOULD_BE_LT_CURRENT_PRICE
                      )
                      .gte(this.stopLossValueMin, 'MARGIN_CALL_LIMIT');
              }
            }

            if (this.isPending) {
              triggerPriceValidator = yup
                .big()
                .required()
                .gte(this.triggerPriceValueMin, 'TRIGGER_PRICE_LIMIT')
                .lte(this.triggerPriceValueMax, 'TRIGGER_PRICE_LIMIT');
            }

            const additionalInputAmountValidator = this.useBonusesActive
              ? yup
                  .big()
                  .required()
                  .gt(0)
                  .lte(this.maxBonusesAmount)
                  .lte(this.bonusBalance, messages.FUNDS_INSUFFICIENT)
              : yup.big();

            return yup.object().shape({
              hodlInstrument: yup.object().required(),
              direction: yup.string(),
              inputTicker: yup.string().required(),
              inputAmount: yup.big().required().gt(0),
              multiplier: yup.number(),
              takeProfit: takeProfitValidator,
              stopLoss: stopLossValidator,
              adjustTpSlActive: yup.boolean(),
              useBonusesActive: yup.boolean(),
              additionalInputAmount: additionalInputAmountValidator,
              isPending: yup.boolean(),
              triggerPrice: triggerPriceValidator,
              isAgreementsAccepted: yup.boolean(),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm(
      {fields},
      {
        plugins,
        hooks,
        options,
      }
    );

    //#region reactions on updates params
    let tariffSymbol: string;
    let amountListFormattedFirstRun = true;
    this.disposers = [
      // NOTE: after checking available tariffs, change direction and disable some, if needed
      autorun(() => {
        if (this.disableBuy && !this.disableSell) {
          this.setDirection('sell');
        }
        if (this.disableSell && !this.disableBuy) {
          this.setDirection('buy');
        }
      }),

      // NOTE: update multiplier after tariff changed and check tariffs settings
      reaction(
        () => this.currentTariff,
        currentTariff => {
          if (!currentTariff) {
            return;
          }

          const symbol = `${currentTariff.baseTicker}/${currentTariff.quoteTicker}`;

          // NOTE: first run, tariffSymbol is not set
          if (
            tariffSymbol === undefined &&
            this.args.multiplier !== undefined
          ) {
            const argsMultiplier = Number(this.args.multiplier);
            const verifiedMultiplier =
              currentTariff?.minMultiplier > argsMultiplier
                ? currentTariff?.minMultiplier
                : currentTariff?.maxMultiplier < argsMultiplier
                ? currentTariff?.maxMultiplier
                : argsMultiplier;

            this.setMultiplier(verifiedMultiplier);
          } else if (tariffSymbol !== symbol) {
            // NOTE: further runs, tariffSymbol may be changed
            const verifiedMultiplier = currentTariff?.defaultMultiplier
              ? currentTariff?.defaultMultiplier
              : currentTariff?.minMultiplier > this.multiplier
              ? currentTariff?.minMultiplier
              : currentTariff?.maxMultiplier < this.multiplier
              ? currentTariff?.maxMultiplier
              : this.multiplier;

            this.setMultiplier(verifiedMultiplier);
          }
          tariffSymbol = symbol;

          runInAction(() => {
            this.pendingOrderDisabled = currentTariff?.pendingOrderDisabled;
          });
        },
        {
          fireImmediately: true,
        }
      ),

      autorun(() => {
        if (this.sourceWallets.length > 0) {
          // NOTE: if inputTicker not set - use first from wallets
          if (!this.inputTicker && this.sourceWallets[0]) {
            this.setInputTicker(this.sourceWallets[0].ticker);
            return;
          }
          // NOTE: if no wallet for current inputTicker - update inputTicker
          const walletExists = this.sourceWallets.some(
            wallet => wallet.ticker === this.inputTicker
          );
          if (!walletExists && this.sourceWallets[0]) {
            const newTicker = this.sourceWallets[0].ticker;
            const amount = this.calculateVolumeEquivalent(
              this.inputTicker,
              newTicker
            );
            this.setInputTicker(newTicker);
            this.setInputAmount(amount);
            return;
          }
        } else {
          this.setInputTicker(
            getDefaultInputTicker(
              this.baseTicker,
              this.quoteTicker,
              this.direction
            )
          );
        }
      }),

      // NOTE: update rateInputTickerUsd after inputTicker changed
      reaction(
        () => this.inputTicker,
        () => {
          this.rateInputTickerUsd = Big(
            this.resources.ratesResource.getRate('usd', this.inputTicker)
          );
        },
        {
          fireImmediately: true,
        }
      ),

      // NOTE: update inputAmount after amountListFormatted changed
      reaction(
        () => this.amountListFormatted,
        () => {
          if (amountListFormattedFirstRun) {
            if (this.args.inputAmount === undefined) {
              this.setDefaultSourceToAmount();
            }
            amountListFormattedFirstRun = false;
          } else {
            this.setDefaultSourceToAmount();
          }
        },
        {
          fireImmediately: true,
        }
      ),

      // NOTE: update additionalInputAmount after inputAmount changed (by user!)
      reaction(
        () => this.inputAmount,
        () => {
          this.setAdditionalInputAmount(this.maxBonusesAmount);
        },
        {
          fireImmediately: false,
        }
      ),
      // NOTE: update additionalInputAmount after useBonusesActive flag changed (by user!)
      reaction(
        () => this.useBonusesActive,
        useBonusesActive => {
          useBonusesActive &&
            this.setAdditionalInputAmount(this.maxBonusesAmount);
        },
        {
          fireImmediately: false,
        }
      ),
      // NOTE: update additionalInputAmount after inputAmountUsd changed (automatically because the rate changed)
      reaction(
        () => this.inputAmountUsd,
        () => {
          if (
            Number(this.additionalInputAmount) >
            Number(this.maxBonusesAmountByInput)
          ) {
            this.setAdditionalInputAmount(this.maxBonusesAmount);
          }
        },
        {
          fireImmediately: true,
        }
      ),

      // NOTE: update tp and sl after getting calculated data
      reaction(
        () => this.calculatedArgs,
        requestData => {
          const localCalculateRequestId = Date.now();
          this.currentCalculateRequestId = localCalculateRequestId;

          this.calculating = true;

          TRANSPORT.API.post('/v3/hodl/calculate', requestData)
            .then(async res => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              runInAction(() => {
                this.calculatedRawData = res.data;
              });

              // NOTE: we should update tp/sl fields only in case of user changed tariff (by instrument or direction), multiplier or trigger price
              const needUpdates =
                requestData.multiplier !== this.latestMultiplier ||
                requestData.tariffId !== this.latestTariffId ||
                requestData.triggerPrice !== this.latestTriggerPrice;

              runInAction(async () => {
                if (this.calculatedData && needUpdates) {
                  const currentInitialPriceOrder = this.isPending
                    ? this.triggerPriceBig
                    : this.calculatedData.initialPrice;

                  // NOTE: update tp value and percent by condition
                  const takeProfitValid = await this.instance
                    .validate('takeProfit')
                    .then(({isValid}: any) => isValid);

                  if (!this.takeProfitChangedByUser || !takeProfitValid) {
                    const roundingMode = this.isShort ? 3 : 0;
                    const defaultTPValue = currentInitialPriceOrder.mul(
                      this.calculatedData.isShort ? 0.5 : 2
                    );

                    const tpValue =
                      this.calculatedData.ftpPrice &&
                      this.calculatedData.ftpPrice.gt(0)
                        ? this.calculatedData.ftpPrice
                        : defaultTPValue;

                    const tpPercent = getPercentByAmount(
                      currentInitialPriceOrder,
                      tpValue
                    );

                    this.takeProfitChangedByUser = false;
                    this.takeProfitPercent = tpPercent;
                    this.instance
                      .$('takeProfit')
                      .set(
                        'value',
                        tpValue.toFixed(this.precision, roundingMode)
                      );
                  } else {
                    // NOTE: update only tp percent and save the user's setted value
                    this.takeProfitPercent = getPercentByAmount(
                      currentInitialPriceOrder,
                      requestData.tp ?? 0
                    );
                  }

                  // NOTE: update sl value and percent by condition
                  const stopLossValid = await this.instance
                    .validate('stopLoss')
                    .then(({isValid}: any) => isValid);

                  if (!this.stopLossChangedByUser || !stopLossValid) {
                    const roundingMode = this.isShort ? 0 : 3;
                    const slValue = this.calculatedData.mcPrice;

                    const slPercent = getPercentByAmount(
                      currentInitialPriceOrder,
                      slValue
                    );

                    this.stopLossChangedByUser = false;
                    this.stopLossPercent = slPercent;
                    this.instance
                      .$('stopLoss')
                      .set(
                        'value',
                        slValue.toFixed(this.precision, roundingMode)
                      );
                  } else {
                    // NOTE: update only sl percent and save the user's setted value
                    this.stopLossPercent = getPercentByAmount(
                      currentInitialPriceOrder,
                      requestData.sl ?? 0
                    );
                  }
                }

                // NOTE: we should update triggerPrice only in case the user changed tariff (by instrument or direction)
                if (
                  this.calculatedData &&
                  requestData.tariffId !== this.latestTariffId
                ) {
                  const amount = getAmountByPercent(
                    this.calculatedData.initialPrice,
                    this.defaultTriggerPricePercent ?? '',
                    this.precision
                  );

                  this.triggerPricePercent = this.defaultTriggerPricePercent!;
                  this.instance.$('triggerPrice').set('value', amount);
                }

                // update last calculate params:
                this.latestTariffId = requestData?.tariffId;
                this.latestMultiplier = requestData?.multiplier;
                this.latestTriggerPrice = requestData?.triggerPrice;
              });
            })
            .catch(() => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              runInAction(() => {
                this.calculatedRawData = this.calculatedRawData && {
                  ...this.calculatedRawData,
                  volumeForecast: undefined,
                  sparksAmount: undefined,
                };
              });
            })
            .finally(() => {
              if (this.currentCalculateRequestId !== localCalculateRequestId) {
                return;
              }

              runInAction(() => {
                this.calculating = false;
              });
            });
        },
        {fireImmediately: true, delay: 300}
      ),
    ];
    //#endregion reactions on updates params
  }

  // NOTE: important for clean-up
  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }

  //#region actions
  @action setDirection = (value: DirectionType) => {
    this.instance.$('direction').set('value', value);
  };

  @action setHodlInstrument = (value: HODlInstrumentItem) => {
    this.instance.$('hodlInstrument').set('value', value);
  };

  @action setInputTicker = (value: string) => {
    this.instance.$('inputTicker').set('value', value);
  };

  @action setInputAmount = (value: string) => {
    this.instance.$('inputAmount').set('value', value);
  };

  @action setAllSourceToAmount = () => {
    this.setInputAmount(this.allSourceAmount);
  };

  @action setDefaultSourceToAmount = () => {
    this.setInputAmount(this.defaultSourceAmount!);
  };

  @action setMultiplier = (value: number) => {
    this.instance.$('multiplier').set('value', value);
  };

  @action updateTriggerPriceValue = (value: string) => {
    this.triggerPricePercent = getPercentByAmount(
      this.initialPriceServer,
      value
    );
    this.instance.$('triggerPrice').set('value', value);
  };

  @action updateTriggerPricePercent = (percent: string) => {
    const amount = getAmountByPercent(
      this.initialPriceServer,
      percent,
      this.precision
    );

    this.triggerPricePercent = percent;
    this.instance.$('triggerPrice').set('value', amount);
  };

  @action updateTakeProfitValue = (value: string) => {
    const percent = getPercentByAmount(this.initialPriceOrder, value);

    this.takeProfitChangedByUser = true;
    this.takeProfitPercent = percent;
    this.instance.$('takeProfit').set('value', value);
  };

  @action updateTakeProfitPercent = (percent: string) => {
    const amount = getAmountByPercent(
      this.initialPriceOrder,
      percent,
      this.precision
    );

    this.takeProfitChangedByUser = true;
    this.takeProfitPercent = percent;
    this.instance.$('takeProfit').set('value', amount);
  };

  @action updateStopLossValue = (value: string) => {
    const percent = getPercentByAmount(this.initialPriceOrder, value);

    this.stopLossChangedByUser = true;
    this.stopLossPercent = percent;
    this.instance.$('stopLoss').set('value', value);
  };

  @action updateStopLossPercent = (percent: string) => {
    const amount = getAmountByPercent(
      this.initialPriceOrder,
      percent,
      this.precision
    );

    this.stopLossChangedByUser = true;
    this.stopLossPercent = percent;
    this.instance.$('stopLoss').set('value', amount);
  };

  @action setAdditionalInputAmount = (value: string) => {
    this.instance.$('additionalInputAmount').get('onChange')(value);
  };
  //#endregion actions

  calculateVolumeEquivalent = computedFn(
    (oldTicker: string, newTicker: string) => {
      const {getRate} = this.resources.ratesResource;
      const rate = getRate(oldTicker, newTicker);

      const precision = getCoinDecimalPrecision(newTicker);
      const amount = new Big(this.inputAmount || 0)
        .times(toBig(rate))
        .toFixed(precision);

      return amount;
    }
  );
}
