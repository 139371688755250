import React, {useCallback, useRef} from 'react';
import {StyledInput} from './index.parts';
import {useTheme, TColorTokens} from '@youtoken/ui.primitives';

export interface TextInputProps
  extends Omit<React.ComponentProps<typeof StyledInput>, 'colors'>,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  type?: 'text' | 'email' | 'password' | 'date';
  placeholder?: string;
  value?: string;
  name?: string; // TODO Зачем?
  onChange?: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: any) => void;
  readonly?: boolean;
  className?: string;
  ref?: React.Ref<HTMLInputElement>;
}

export const TextInput: React.FC<TextInputProps> = ({
  readonly,
  onChange,
  onBlur,
  type = 'text',
  ...otherProps
}) => {
  const input = useRef<HTMLInputElement>(null);

  const handleFocus = useCallback(() => {
    if (input.current) {
      input.current.select();
    }
  }, []);

  const {colors} = useTheme();

  return (
    <StyledInput
      ref={input}
      readOnly={readonly}
      onChange={readonly ? undefined : onChange}
      onBlur={readonly ? undefined : onBlur}
      onFocus={readonly ? handleFocus : undefined}
      type={type}
      colors={colors}
      {...otherProps}
    />
  );
};
