import {AppEnv} from './AppEnv';
import {invariant, warning} from '@youtoken/ui.utils';

/** @platform web */
export const getAppEnvFromHost = () => {
  invariant(
    window?.location?.hostname,
    'trying to use getAppEnvFromHost in env that does not support `window.location`'
  );

  const host = window.location.host;

  if (host.includes('localhost')) {
    return AppEnv.local;
  }

  if (
    host.startsWith('app.youhodler') ||
    host.startsWith('ledgerlive.youhodler') ||
    host.startsWith('magicstore.youhodler')
  ) {
    return AppEnv.production;
  }

  warning(false, 'cannot extract AppEnv from window.location');

  return AppEnv.local;
};
