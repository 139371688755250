import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const UpIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.625 15.375a.884.884 0 001.25 0L14.25 7v5.875a.875.875 0 101.75 0V4.75a.75.75 0 00-.75-.75H7.125a.875.875 0 100 1.75H13l-8.375 8.375a.884.884 0 000 1.25z"
          fill={color}
        />
      </Svg>
    );
  }
);
