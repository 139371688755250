import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

export const ArrLastImage = React.memo(
  ({color = '#212121', ...props}: SvgProps) => {
    return (
      <Svg height="1em" viewBox="0 0 27 33" width="1em" {...props}>
        <G
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          strokeOpacity={0.4}
        >
          <Path d="M4.5 30.5l-2 2-2-2" strokeLinejoin="round" />
          <Path d="M2.5 30.5v-25a5 5 0 015-5h19" strokeDasharray="2 3" />
        </G>
      </Svg>
    );
  }
);
