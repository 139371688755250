import React from 'react';
import {Box} from '@youtoken/ui.primitives';

export const CurvedLine: React.FC = ({children}) => {
  return (
    <Box flexDirection="row" alignItems="center" height={20} pl={16}>
      <Box
        width={12.5}
        height={12.5}
        mt={-10}
        mr={12}
        borderStyle={'solid'}
        borderLeftWidth={1}
        borderBottomWidth={1}
        borderLeftColor={'$text-02'}
        borderBottomColor={'$text-02'}
        borderBottomLeftRadius={6.25}
      />
      {children}
    </Box>
  );
};
