import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

export const ArrFirstImage = React.memo(
  ({color = '#212121', ...props}: SvgProps) => {
    return (
      <Svg height="1em" viewBox="0 0 24 33" width="1em" {...props}>
        <G
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          strokeOpacity={0.4}
        >
          <Path d="M21.5 32.5l2-2-2-2" strokeLinejoin="round" />
          <Path d="M21.5 30.5h-16a5 5 0 01-5-5V.5" strokeDasharray="2 3" />
        </G>
      </Svg>
    );
  }
);
