import * as React from 'react';
import {
  Defs,
  LinearGradient,
  Path,
  Stop,
  Svg,
  SvgProps,
} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const SolIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Path
          d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
          fill="#000"
        />
        <Path
          d="M8.899 20.096a.688.688 0 01.486-.2h16.79c.306 0 .46.37.242.587l-3.316 3.316a.688.688 0 01-.487.201H5.826a.344.344 0 01-.244-.587L8.9 20.096z"
          fill={`url(#${_id}_sol_svg__sol_svg__paint0_linear_2696_2816)`}
        />
        <Path
          d="M8.899 7.714a.707.707 0 01.486-.201h16.789c.307 0 .46.37.243.587l-3.316 3.316a.688.688 0 01-.487.201H5.825a.344.344 0 01-.243-.587l3.317-3.316z"
          fill={`url(#${_id}_sol_svg__sol_svg__paint1_linear_2696_2816)`}
        />
        <Path
          d="M23.101 13.865a.688.688 0 00-.487-.2H5.826c-.307 0-.46.37-.244.587L8.9 17.567a.688.688 0 00.486.201h16.79c.306 0 .46-.37.242-.587l-3.316-3.317z"
          fill={`url(#${_id}_sol_svg__sol_svg__paint2_linear_2696_2816)`}
        />
        <Defs>
          <LinearGradient
            id={`${_id}_sol_svg__sol_svg__paint0_linear_2696_2816`}
            x1={25}
            y1={10.5}
            x2={8}
            y2={29.5}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00FFA3" />
            <Stop offset={1} stopColor="#DC1FFF" />
          </LinearGradient>
          <LinearGradient
            id={`${_id}_sol_svg__sol_svg__paint1_linear_2696_2816`}
            x1={25}
            y1={6.5}
            x2={3.5}
            y2={21}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00FFA3" />
            <Stop offset={1} stopColor="#DC1FFF" />
          </LinearGradient>
          <LinearGradient
            id={`${_id}_sol_svg__sol_svg__paint2_linear_2696_2816`}
            x1={23.5}
            y1={7}
            x2={10.395}
            y2={26.452}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00FFA3" />
            <Stop offset={1} stopColor="#DC1FFF" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
