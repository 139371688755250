import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, useTheme} from '@youtoken/ui.primitives';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';
import {
  DetailedActionsAdditionalsProps,
  DetailedActionsProps,
  DetailedTableProps,
  DetailedGeneral,
  DetailedAlert,
  DetailedChart,
  DetailedTable,
  DetailedChainLoans,
  DetailedAgreements,
  DetailedActions,
  DetailedActionsAdditionals,
} from '..';

export interface DetailedProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onPressPay: DetailedActionsProps['onPressPay'];
  onPressCancel: DetailedActionsProps['onPressCancel'];
  onPressCloseNow: DetailedActionsProps['onPressCloseNow'];
  onPressCopy: DetailedTableProps['onPressCopy'];
  onPressIncrease: DetailedActionsAdditionalsProps['onPressIncrease'];
  onPressDecrease: DetailedActionsAdditionalsProps['onPressDecrease'];
  onPressReopen: DetailedActionsAdditionalsProps['onPressReopen'];
  onPressSetClosePrice: DetailedActionsAdditionalsProps['onPressSetClosePrice'];
}

export const Detailed: React.FC<DetailedProps> = observer(
  ({
    mode,
    id,
    onPressIncrease,
    onPressDecrease,
    onPressSetClosePrice,
    onPressReopen,
    onPressPay,
    onPressCancel,
    onPressCloseNow,
    onPressCopy,
    ...rest
  }) => {
    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box
        justifyContent="flex-start"
        width="100%"
        bg="$ui-background"
        {...rest}
      >
        <DetailedGeneral
          mode={loan.mode}
          id={loan.id}
          p={20}
          borderBottomWidth={1}
          borderBottomColor="$ui-01"
        />
        <DetailedAlert mode={loan.mode} id={loan.id} />
        <DetailedChart
          mode={loan.mode}
          id={loan.id}
          borderBottomWidth={1}
          borderBottomColor="$ui-01"
        />
        <DetailedActionsAdditionals
          mode={loan.mode}
          id={loan.id}
          onPressIncrease={onPressIncrease}
          onPressDecrease={onPressDecrease}
          onPressSetClosePrice={onPressSetClosePrice}
          onPressReopen={onPressReopen}
          borderBottomWidth={1}
          borderBottomColor="$ui-01"
        />
        <DetailedTable
          mode={loan.mode}
          id={loan.id}
          p={20}
          onPressCopy={onPressCopy}
          borderBottomWidth={1}
          borderBottomColor="$ui-01"
        />
        {loan.mode === 'turbo' && (
          <DetailedChainLoans mode={loan.mode} id={loan.id} />
        )}
        <DetailedAgreements mode={loan.mode} id={loan.id} />
        <DetailedActions
          mode={loan.mode}
          id={loan.id}
          onPressPay={onPressPay}
          onPressCancel={onPressCancel}
          onPressCloseNow={onPressCloseNow}
        />
      </Box>
    );
  }
);
