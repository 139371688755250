import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Trans, Link} from '@youtoken/ui.elements';

export const RewardsHowItWorksInfo: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box>
      <Text variant="$body-02-high-accent" color="$text-01">
        {t('surface.referral_program.rewards.how_it_works.info.title')}
      </Text>
      <Text variant="$body-02" color="$text-01" mt={16}>
        {t('surface.referral_program.rewards.how_it_works.info.description')}
      </Text>
      <Text variant="$body-02" color="$text-02" mt={16}>
        <Trans
          components={{
            Link: (
              <Link
                url="https://help.youhodler.com/en/articles/6797708-referrals-and-bonuses-program"
                color="$text-05"
              />
            ),
          }}
          i18nKey="surface.referral_program.rewards.how_it_works.info.link"
        />
      </Text>
    </Box>
  );
});
