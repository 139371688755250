import {
  BaseTheme,
  createRestyleFunction,
  ResponsiveValue,
} from '@shopify/restyle';

/** mostly copied from  restyle/src
 *
 * patches spacing props to accept numbers and strings as-is, not only from spacing theme key;
 *
 */

const spacingProperties = {
  margin: true,
  marginTop: true,
  marginRight: true,
  marginBottom: true,
  marginLeft: true,
  marginHorizontal: true,
  marginVertical: true,
  marginStart: true,
  marginEnd: true,
  padding: true,
  paddingTop: true,
  paddingRight: true,
  paddingBottom: true,
  paddingLeft: true,
  paddingHorizontal: true,
  paddingVertical: true,
  paddingStart: true,
  paddingEnd: true,
  columnGap: true,
  rowGap: true,
  gap: true,
};

const spacingPropertiesShorthand = {
  m: 'margin',
  mt: 'marginTop',
  mr: 'marginRight',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mx: 'marginHorizontal',
  my: 'marginVertical',
  ms: 'marginStart',
  me: 'marginEnd',
  p: 'padding',
  pt: 'paddingTop',
  pr: 'paddingRight',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  px: 'paddingHorizontal',
  py: 'paddingVertical',
  ps: 'paddingStart',
  pe: 'paddingEnd',
  g: 'gap',
  rg: 'rowGap',
  cg: 'columnGap',
};

export const spacing = (
  Object.keys(spacingProperties) as Array<keyof typeof spacingProperties>
).map((property: keyof typeof spacingProperties) => {
  return createRestyleFunction({
    property: property,
    styleProperty: property,
    themeKey: 'spacing',
    // important part - accept value as is
    transform: ({value, theme, themeKey}) => {
      if (theme[themeKey as keyof typeof theme]?.[value]) {
        return theme[themeKey!][value];
      }

      return value;
    },
  });
});

export const spacingShorthand = (
  Object.keys(spacingPropertiesShorthand) as Array<
    keyof typeof spacingPropertiesShorthand
  >
).map((shorthandProp: keyof typeof spacingPropertiesShorthand) => {
  const property = spacingPropertiesShorthand[
    shorthandProp
  ] as keyof typeof spacingProperties;

  return createRestyleFunction({
    property: shorthandProp,
    styleProperty: property,
    themeKey: 'spacing',
    // accept value as is
    transform: ({value, theme, themeKey}) => {
      if (theme[themeKey as keyof typeof theme]?.[value]) {
        return theme[themeKey as keyof typeof theme][value];
      }

      return value;
    },
  });
});

export type SpacingProps<Theme extends BaseTheme> = {
  [Key in keyof typeof spacingProperties]?: ResponsiveValue<
    number | string,
    Theme
  >;
};
export type SpacingShorthandProps<Theme extends BaseTheme> = {
  [Key in keyof typeof spacingPropertiesShorthand]?: ResponsiveValue<
    number | string,
    Theme
  >;
};
