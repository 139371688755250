import * as React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {StatusBadge} from '../StatusBadge';
import {isStatusFailed, isStatusPending} from '../utils';

type AmountsSectionProps = {
  item: HistoryItemData;
};

export const AmountsSection: React.FC<AmountsSectionProps> = ({item}) => {
  const {
    badgeVariant,
    status,
    statusLabel,
    typeSign,
    amountStringFormatted,
    tickerFormatted,
    isConvert,
    conversionAmountStringFormatted,
    conversionTickerFormatted,
    fee,
  } = item;

  const {t} = useTranslation();

  return (
    <Box width="100%" alignItems="center">
      {badgeVariant && (
        <StatusBadge
          badgeVariant={badgeVariant}
          statusLabel={statusLabel}
          mt={24}
        />
      )}
      <Box flexDirection="row" alignItems="baseline" mt={24}>
        <Heading
          variant="$heading-02"
          color={isStatusFailed(status) ? '$danger-01' : '$text-01'}
        >
          {`${typeSign}${amountStringFormatted}`}
        </Heading>
        <Text variant="$body-01" color="$text-02">
          {' '}
          {tickerFormatted}
        </Text>
      </Box>
      {isConvert && (
        <>
          <Box
            width={24}
            height={24}
            borderRadius={12}
            backgroundColor="$interactive-02"
            mt={16}
            alignItems="center"
            justifyContent="center"
          >
            <Icon name="expand" size={16} color="$text-05" />
          </Box>
          <Box flexDirection="row" alignItems="baseline" mt={16}>
            <Heading
              variant="$heading-02"
              color={isStatusFailed(status) ? '$danger-01' : '$text-01'}
            >
              {`+${conversionAmountStringFormatted}`}
            </Heading>
            <Text variant="$body-01" color="$text-02">
              {' '}
              {conversionTickerFormatted}
            </Text>
          </Box>
        </>
      )}

      {!isStatusFailed(status) && !isStatusPending(status) && (
        <Box width="100%" pl={24} pr={24} mt={40}>
          <InformationTable
            pb={0}
            width="100%"
            borderTopWidth={1}
            borderTopColor="$ui-02"
          >
            <InformationTableRow
              left={t('surface.history.item.fee')}
              right={`${fee} ${tickerFormatted}`}
              isLast
            />
          </InformationTable>
        </Box>
      )}
    </Box>
  );
};
