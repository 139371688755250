import {deserialize} from 'serializr';
import {computed} from 'mobx';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {MinerUpgradeRequirementsResponse} from './MinerUpgradeRequirementsResponse';
import type {MinerUpgradeRequirementsArgs} from './types';

export class MinerUpgradeRequirementsResource extends createResource<
  MinerUpgradeRequirementsArgs,
  MinerUpgradeRequirementsResponse
>({
  getKey: () => `minerUpgradeRequirements`,
  getData: () => {
    return TRANSPORT.API.get('/v1/miner/upgrade-requirements').then(res => {
      return deserialize(MinerUpgradeRequirementsResponse, res.data);
    });
  },
  skipRefreshOnVisible: false,
}) {}
