import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';

type CoinIconsBigProps = BoxProps & {
  coins: string[];
};

export const CoinIconsBig: React.FC<CoinIconsBigProps> = cell(
  ({coins, ...boxProps}) => {
    return (
      <Box gap={8} flexDirection="row" alignItems="center" {...boxProps}>
        {coins.slice(0, 4).map((logoName, i) => (
          <Box
            key={`${logoName}-${i}`}
            borderColor="$success-03"
            borderWidth={1}
            p={4}
            borderRadius={20}
          >
            <Box
              backgroundColor="$success-03"
              borderRadius={16}
              p={12}
              gap={4}
              testID={`COINDROPS_COIN_ICON_${logoName.toUpperCase()}`}
            >
              <LogoColored name={logoName as LogoColoredIconName} size={40} />
              <Text
                textAlign="center"
                textTransform="uppercase"
                variant="$body-02-medium-accent"
              >
                {logoName}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }
);
