import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SignIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M4 2.25A1.75 1.75 0 002.25 4v12c0 .966.784 1.75 1.75 1.75h4a.75.75 0 000-1.5H4a.25.25 0 01-.25-.25V4A.25.25 0 014 3.75h9a.25.25 0 01.25.25v4a.75.75 0 001.5 0V4A1.75 1.75 0 0013 2.25H4z"
          fill={color}
        />
        <Path
          d="M5.25 6.5A.75.75 0 016 5.75h3.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.763 8.677a1.75 1.75 0 012.474 0l.586.586a1.75 1.75 0 010 2.474l-5.5 5.5a1.75 1.75 0 01-1.237.513H10.5a.75.75 0 01-.75-.75v-1.586c0-.464.184-.909.513-1.237l5.5-5.5zm1.414 1.06a.25.25 0 00-.354 0l-5.5 5.5a.25.25 0 00-.073.177v.836h.836a.25.25 0 00.177-.073l5.5-5.5a.25.25 0 000-.354l-.586-.586z"
          fill={color}
        />
        <Path
          d="M6 8.75a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5H6zm-.75 3.75a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
