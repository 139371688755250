import * as React from 'react';
import {observer} from 'mobx-react';
import {useIsDesktop} from '@youtoken/ui.primitives';
import {Button, type IButtonProps} from '@youtoken/ui.buttons';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type Wallet} from '@youtoken/ui.resource-wallets';

interface AddWalletsItemButtonProps extends IButtonProps {
  item: Wallet;
}

export const AddWalletsItemButton: React.FC<AddWalletsItemButtonProps> =
  observer(({item, ...buttonProps}) => {
    const {t} = useTranslation();

    const {addedWalletTickers} = AuthMeResource.use({});
    const showButtonLabels = useIsDesktop();
    const tickerFormatted = item.ticker.toUpperCase();

    const isWalletAdded = React.useMemo(
      () => addedWalletTickers.includes(item.ticker),
      [addedWalletTickers, item.ticker]
    );

    return (
      <Button
        // width comes from the longest button content, in German
        minWidth={showButtonLabels ? 110 : undefined}
        mr={24}
        size="small"
        type="secondary"
        color={isWalletAdded ? 'success' : 'interactive'}
        icon={isWalletAdded ? 'check' : 'plus'}
        testID={
          isWalletAdded
            ? `REMOVE_WALLET_${tickerFormatted}`
            : `ADD_WALLET_${tickerFormatted}`
        }
        {...buttonProps}
      >
        {showButtonLabels &&
          t(
            isWalletAdded
              ? 'surface.wallets.common.added_wallet_button'
              : 'surface.wallets.common.add_wallet_button_short'
          )}
      </Button>
    );
  });
