import React from 'react';
import {observer} from 'mobx-react';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';
import {SavingsSummaryAprSmart} from '@youtoken/ui.surface-savings';
import {IBANBadge} from '@youtoken/ui.surface-bank-wire';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useResource} from '@youtoken/ui.data-storage';

export interface SummaryProps extends BoxProps {
  item: Wallet;
}

export const Summary: React.FC<SummaryProps> = observer(
  ({item, ...boxProps}) => {
    const {isBadgeEnabled} = useResource(IBANAccountsResource, {});
    const {
      ticker,
      amountFormatted,
      tickerFormatted,
      equivalentFormatted,
      equivalentTickerFormatted,
      isAmountPositive,
    } = item;

    const handleBadgePress = React.useCallback(
      () => SHARED_ROUTER_SERVICE.navigate('WalletsItem', {ticker}),
      [ticker]
    );

    return (
      <Box flexDirection="row" alignItems="center" {...boxProps}>
        <Box mr={10}>
          <LogoColored name={ticker as LogoColoredIconName} size={32} />
        </Box>
        <Box>
          <Box flexDirection="row" alignItems="center">
            <Text
              variant={isAmountPositive ? '$body-01-high-accent' : '$body-01'}
              color={isAmountPositive ? '$text-01' : '$text-02'}
              testID={`${tickerFormatted}_BALANCE`}
            >
              {amountFormatted}{' '}
            </Text>

            <Text variant="$body-01" color="$text-02">
              {tickerFormatted}
            </Text>
            {isBadgeEnabled(ticker) && (
              <IBANBadge ticker={ticker} onPress={handleBadgePress} />
            )}
          </Box>
          <Box
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            {Boolean(equivalentFormatted) && (
              <Text
                variant="$body-02"
                color="$text-02"
                testID={`${ticker.toUpperCase()}_IN_${equivalentTickerFormatted}_AMOUNT`}
              >
                {equivalentFormatted} {equivalentTickerFormatted}
              </Text>
            )}
            <SavingsSummaryAprSmart item={item} />
          </Box>
        </Box>
      </Box>
    );
  }
);
