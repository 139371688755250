import * as React from 'react';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import type {BoxProps} from '@youtoken/ui.primitives';
import {type AdvCashWizardNavigator, AdvCashWizardScreenName} from '..';
import {AdvCashCreatePaymentForm} from './Form';
import type {IAdvCashData} from '../types';

export const AdvCashCreatePayment: React.FC<BoxProps> = () => {
  const {
    step: {
      props: {ticker, amount},
    },
    navigate,
    goBack,
  } = useWizard<AdvCashWizardNavigator, AdvCashWizardScreenName.MAIN>();

  const handleSubmit = React.useCallback(
    (data: IAdvCashData) => {
      navigate(AdvCashWizardScreenName.PAYMENT, data);
    },
    [navigate]
  );

  return (
    <KeyboardScrollView
      insideTabs={false}
      wrapInSafeArea
      scrollViewProps={{
        keyboardDismissMode: 'interactive',
        style: {flex: 1},
        contentContainerStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
    >
      <AdvCashCreatePaymentForm
        ticker={ticker}
        amount={amount}
        onSubmit={handleSubmit}
        onPressBack={goBack}
      />
    </KeyboardScrollView>
  );
};
