import * as React from 'react';
import {
  type NotificationModalProps,
  NotificationModal,
} from './NotificationModal';

export const __GLOBAL_NOTIFICATION_REF__ =
  React.createRef<NotificationModalProps>();

export const __GLOBAL_NOTIFICATION__ = {
  request: (notificationId: string) => {
    if (!__GLOBAL_NOTIFICATION_REF__.current?.request) {
      return Promise.reject(new Error('NotificationContext not initialized'));
    }

    return __GLOBAL_NOTIFICATION_REF__.current?.request(notificationId);
  },
};

export const NotificationContext = React.createContext<NotificationModalProps>(
  __GLOBAL_NOTIFICATION__
);

export const NotificationContextProvider: React.FC = ({children}) => {
  return (
    <NotificationContext.Provider value={__GLOBAL_NOTIFICATION__}>
      <NotificationModal ref={__GLOBAL_NOTIFICATION_REF__} />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = React.useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationContextProvider'
    );
  }

  return context;
};
