import * as React from 'react';
import {observer} from 'mobx-react';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';

interface RewardsOverviewStatusMessageOpenedProps {
  amount: number;
}

export const RewardsOverviewStatusMessageOpened: React.FC<RewardsOverviewStatusMessageOpenedProps> =
  observer(({amount}) => {
    const {t} = useTranslation();

    return (
      <Box alignItems="center">
        <Text variant="$heading-02" color="$text-01" textAlign="center">
          {t('surface.referral_program.rewards.overview.message.opened.title', {
            amount: priceFormatterInThousands(amount),
          })}
        </Text>
        <Text variant="$body-02" color="$text-02" textAlign="center" mt={4}>
          {t(
            'surface.referral_program.rewards.overview.message.opened.description',
            {amount}
          )}
        </Text>
      </Box>
    );
  });
