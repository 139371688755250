import {action, computed, observable} from 'mobx';
import {i18n} from '@youtoken/ui.service-i18n';
import {parseDateForLokalise, toBig} from '@youtoken/ui.formatting-utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  AddressStatus,
  AuthMeResource,
  KYCStatus,
} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {
  checkAccountVerifications,
  VerificationItemAccountCode,
  VerificationResource,
} from '@youtoken/ui.resource-verification';
import {Form} from './Form';

export interface CryptoWithdrawalExternalFormStateArgs {
  ticker: string;
}

export interface CryptoWithdrawalExternalFormStateResource {
  authMe: AuthMeResource;
  wallets: WalletsResource;
  feeAll: FeeAllResource;
  verification: VerificationResource;
}

export class CryptoWithdrawalExternalFormState {
  @observable
  args: CryptoWithdrawalExternalFormStateArgs;

  @observable
  resources: CryptoWithdrawalExternalFormStateResource;

  @observable
  form: Form;

  @computed
  get walletTicker() {
    return this.form.ticker;
  }

  @computed
  get version(): string | null {
    return this.form.version;
  }

  @computed
  get shouldShowContent() {
    return this.form.version !== null;
  }

  @computed
  get walletTickerFormatted() {
    return this.walletTicker.toUpperCase();
  }

  @computed
  get walletAmount() {
    return this.form.walletAmount;
  }

  @computed
  get walletVersions() {
    return this.form.walletVersions;
  }

  @computed
  get walletVersion() {
    return this.form.walletVersion;
  }

  @computed
  get walletVersionLabel() {
    return this.form.walletVersionLabel;
  }

  @computed
  get walletVersionValue() {
    return this.form.walletVersionValue;
  }

  @computed.struct
  get fee() {
    return this.form.fee;
  }

  @computed
  get feeMinAmountFormatted() {
    return this.form.feeMinAmount || '0';
  }

  @computed
  get feeEstimationTimeFormatted() {
    return this.fee?.estimationTime || 'undefined';
  }

  @computed
  get feeEstimationTimeLokalised() {
    if (!this.fee?.estimationPeriod) {
      return '';
    }

    const [amount, unit] =
      parseDateForLokalise(this.fee?.estimationPeriod) || [];

    if (!amount || !unit) {
      return '';
    }

    return [
      i18n.t('surface.wallets.withdraw_crypto.eta'),
      // @ts-ignore
      i18n.t(`common.period.${unit}`, {count: +amount}),
    ].join(' ');
  }

  @computed
  get comission() {
    return (
      this.fee &&
      this.resources.feeAll.calculateFee(
        this.fee.method,
        this.fee.provider,
        this.fee.ticker,
        this.fee.conversionTicker,
        this.form.amount
      )
    );
  }

  @computed
  get sendFormatted() {
    return toBig(this.form.amount).toFixed();
  }

  @computed
  get feeFormatted() {
    return toBig(this.comission).toFixed();
  }

  @computed
  get receiveFormatted() {
    const value = toBig(this.form.amount).minus(toBig(this.comission));

    return value.gt(0) ? value.toFixed() : '0';
  }

  @computed
  get submitting() {
    return this.form.instance.submitting;
  }

  @action
  submit = (e: SubmitEvent) => {
    const {residenceOrCountry, kycResult, addressResult} =
      this.resources.authMe;

    const shouldForceAllowWithdrawals =
      residenceOrCountry === 'CAN' &&
      (kycResult === KYCStatus.KYC_RESTRICTION ||
        addressResult === AddressStatus.KYC_RESTRICTION);
    if (
      !shouldForceAllowWithdrawals &&
      !checkAccountVerifications(this.resources.verification, [
        VerificationItemAccountCode.IDENTITY,
      ])
    ) {
      return Promise.resolve();
    }

    if (!this.resources.authMe.walletsDisclosureSigned) {
      SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {});
      return Promise.resolve();
    }

    return this.form.instance.onSubmit(e);
  };

  constructor(
    args: CryptoWithdrawalExternalFormStateArgs,
    resources: CryptoWithdrawalExternalFormStateResource
  ) {
    this.args = args;
    this.resources = resources;
    this.form = new Form(this.args, this.resources);
  }
}
