import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {AccountLevelProgressBarVariant} from '../../types';
import {type AccountLevelProgressBarDetailedPerksProps} from './types';
import {AccountLevelProgressBarDetailedTradePerksInfo} from './Info';
import {AccountLevelProgressBarDetailedTradePerksClosed} from './Closed';

export const AccountLevelProgressBarDetailedPerks: React.FC<
  AccountLevelProgressBarDetailedPerksProps & BoxProps
> = observer(({showSparks = false, level, variant, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_PERKS"
      gap={16}
      {...boxProps}
    >
      <Box flexDirection="row" justifyContent="space-between">
        <Text
          testID={`ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_PERKS_TITLE`}
          variant="$body-01-high-accent"
          color="$text-01"
        >
          {variant === AccountLevelProgressBarVariant.HODL
            ? t('surface.loyalty.progress_bar_detailed.perks.opened_trade')
            : t('surface.loyalty.progress_bar_detailed.perks.opened_exchange')}
        </Text>
        <QuestionTooltip
          content={
            variant === AccountLevelProgressBarVariant.HODL
              ? t(
                  showSparks
                    ? 'surface.loyalty.progress_bar_detailed.perks.opened_trade_hint'
                    : 'surface.loyalty.progress_bar_detailed.perks.opened_trade_volume_hint'
                )
              : t(
                  showSparks
                    ? 'surface.loyalty.progress_bar_detailed.perks.opened_exchange_hint'
                    : 'surface.loyalty.progress_bar_detailed.perks.opened_exchange_volume_hint'
                )
          }
          bodyHorizontalOffset={24}
          caretPosition="right"
        />
      </Box>
      <AccountLevelProgressBarDetailedTradePerksInfo
        showSparks={showSparks}
        variant={variant}
        level={level}
      />
      {variant === AccountLevelProgressBarVariant.HODL && (
        <AccountLevelProgressBarDetailedTradePerksClosed
          showSparks={showSparks}
        />
      )}
    </Box>
  );
});
