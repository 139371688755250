import {observable} from 'mobx';
import {primitive, serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';

export class CardItem {
  @serializable(primitive())
  @observable
  id!: string;

  @serializable(primitive())
  @observable
  main!: boolean;

  @serializable(primitive())
  @observable
  bin!: string;

  @serializable(primitive())
  @observable
  brand!: string;

  @serializable(primitive())
  @observable
  last4!: string;

  @serializable(number())
  @observable
  expMonth!: number;

  @serializable(number())
  @observable
  expYear!: number;
}
