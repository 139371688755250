import {CustomError} from '@youtoken/ui.errors';
import {CaptureContext, ScopeContext} from '@sentry/types';

export interface CaptureScope extends Partial<ScopeContext> {
  /**custom source sentry tag, use if service or fn throw error*/
  source?: string;
  /** custom component sentry tag, use if component did throw error (cell or ErrorBoundary) */
  component?: string;
}

export const enhanceCapture = (
  _error: Error | CustomError | string,
  scope: CaptureScope = {}
): CaptureContext => {
  let {source = 'NOT_SET', component = 'NOT_SET'} = scope;

  scope.tags = scope.tags || {};
  scope.tags.source = source;
  scope.tags.component = component;

  return scope;
};
