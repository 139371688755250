import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {Form, SubmitButton, makeForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RestorePasswordForm} from './state';
import {EmailField, RestoreAccessLine} from './components';

type RestorePasswordSurface = {
  showTitle?: boolean;
};

export const RestorePasswordSurface: React.FC<
  RestorePasswordSurface & BoxProps
> = observer(({showTitle = true, ...boxProps}) => {
  const {t} = useTranslation();

  const resources = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
  });

  const form = makeForm(() => new RestorePasswordForm({}, resources));

  return (
    <Form form={form}>
      <Box flex={1} px={20} {...boxProps}>
        {showTitle && (
          <Box mb={24}>
            <Heading variant="$heading-01-responsive">
              {t('surface.reset_password.navigation_title')}
            </Heading>
          </Box>
        )}

        <EmailField />

        <SubmitButton size="large" mt={24}>
          {t('forms.reset_password.action_button')}
        </SubmitButton>

        <RestoreAccessLine mt={30} mb={20} />
      </Box>
    </Form>
  );
});
