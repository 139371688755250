import * as React from 'react';
import {Platform} from 'react-native';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {en} from '@youtoken/ui.lokalise-export';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {TickersStack} from '@youtoken/ui.elements';
import {type SectionList} from '@youtoken/ui.utils';

const KEYS: Record<SectionTogglerProps['section'], keyof typeof en> = {
  fiat: 'surface.wallets.section.fiat',
  stable: 'surface.wallets.section.stable',
  crypto: 'surface.wallets.section.crypto',
};

export type SectionTogglerProps = {
  section: SectionList;
  tickers?: string[];
  visibility: boolean;
  disabled?: boolean;
  onPress: () => void;
};

const TICKERS_SIZE = 20;
const TICKERS_BORDER_SIZE = 2;

export const SectionToggler: React.FC<SectionTogglerProps & BoxProps> =
  React.memo(
    ({section, visibility, tickers, disabled, onPress, ...boxProps}) => {
      const {t} = useTranslation();

      const label = t(KEYS[section]);

      const handleAddPress = React.useCallback(() => {
        SHARED_ROUTER_SERVICE.navigate('AddWallets', {initialFilter: section});
      }, [section]);

      return (
        <TouchableBox
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="$ui-background"
          pl={24}
          pr={Platform.select({default: 20, web: 24})}
          px={24}
          pt={16}
          pb={visibility ? 8 : 16}
          onPress={onPress}
          disabled={disabled}
          testID={`WALLETS_SECTION_TOGGLER_${section.toUpperCase()}`}
          {...boxProps}
        >
          <Box
            flexDirection="row"
            alignItems="center"
            // to prevent label movement during layout animation
            minHeight={TICKERS_SIZE + 2 * TICKERS_BORDER_SIZE}
          >
            <Text variant="$body-01-high-accent" color="$text-01" mr={4}>
              {label}
            </Text>
            {!disabled && (
              <Icon
                color={visibility ? '$text-03' : '$text-01'}
                name={visibility ? 'collapse' : 'expand'}
                size={16}
              />
            )}
            {!visibility && Boolean(tickers?.length) && (
              <TickersStack
                tickers={tickers as string[]}
                variant="rightOnTop"
                size={TICKERS_SIZE}
                borderSize={TICKERS_BORDER_SIZE}
                additionalOffset={2}
                testID={`ADD_WALLET_SECTION_TICKERS_STACK_${section.toUpperCase()}`}
                ml={4}
              />
            )}
          </Box>
          <Button
            icon="plus"
            color="interactive"
            type="secondary"
            size="small"
            testID={`ADD_WALLET_SECTION_ADD_WALLET_BUTTON_${section.toUpperCase()}`}
            onPress={handleAddPress}
            pr={12}
          >
            {t('surface.wallets.wallets_list.add_wallets_button')}
          </Button>
        </TouchableBox>
      );
    }
  );
