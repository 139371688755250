import {action, autorun, observable} from 'mobx';
import {ResourceInstance} from '../types';
import {BaseResourceFeature} from './BaseResourceFeature';

/** long-polling Refetcher. responsible for setting and removing interval refetch for resource:
 * - sets interval for refetch once resource data is observed;
 * - cancels interval once resource data is no longer observed;
 */
export class IntervalRefetcher<
  Args extends {},
  Result extends any
> extends BaseResourceFeature<Args, Result> {
  featureName = 'IntervalRefetcher';
  refetchInterval!: number;

  disposeOfDataObservedReaction!: ReturnType<typeof autorun>;

  //tracking if interval is set;
  @observable isIntervalSet: boolean = false;

  onDestroy() {
    super.onDestroy();
    this.clearRefetchInterval();
    this.disposeOfDataObservedReaction();
  }

  constructor(
    resource: ResourceInstance<Args, Result>,
    refetchInterval: number
  ) {
    super(resource);
    this.refetchInterval = refetchInterval;

    this.disposeOfDataObservedReaction = autorun(() => {
      if (this.resource.isDataObserved) {
        this.setRefetchInterval();
      } else {
        this.clearRefetchInterval();
      }
    });
  }

  _refetchIntervalId?: number;

  /** removing existing interval if any */
  @action clearRefetchInterval = () => {
    if (this.isIntervalSet) {
      this.logger('clearRefetchInterval');
      clearInterval(this._refetchIntervalId);
      this._refetchIntervalId = undefined;
      this.isIntervalSet = false;
    }
  };

  /** setting new interval (old one will be cleared) */
  @action setRefetchInterval = () => {
    this.clearRefetchInterval();
    this.logger('setRefetchInterval', this.refetchInterval);
    this.isIntervalSet = true;
    this._refetchIntervalId = setInterval(() => {
      this.logger('intervalTriggered');
      if ((this.resource as any).__nav === null) {
        return;
      }
      this.resource.refetch();
    }, this.refetchInterval) as unknown as number;
  };
}
