import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {InnerPageShellContentForceIdentify} from './ForceIdentify';
import {InnerPageShellContentMain} from './Main';

export const InnerPageShellContent: React.FC = cell(({children}) => {
  const {forceIdentify} = AuthMeResource.use({});

  if (forceIdentify) {
    return (
      <InnerPageShellContentForceIdentify>
        {children}
      </InnerPageShellContentForceIdentify>
    );
  }

  return <InnerPageShellContentMain>{children}</InnerPageShellContentMain>;
});
