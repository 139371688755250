import * as React from 'react';
import {observer} from 'mobx-react';
import {Error, Label, useForm} from '@youtoken/ui.form-elements';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {TextInput} from '@youtoken/ui.inputs';
import {Box, Text} from '@youtoken/ui.primitives';
import {coinNames} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type DepositInvoiceForm} from '../../state/DepositInvoiceForm';

export const FieldAmount: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {amountValue, hasAmountError, changeAmount, instance},
    view: {ticker, tickerFormatted},
  } = useForm<DepositInvoiceForm>();

  return (
    <Box mb={10}>
      <Box mb={8}>
        <Label>
          {t('surface.apple_pay.deposit.components.sum_field.amount')}
        </Label>
      </Box>

      <Box
        alignItems="stretch"
        position="relative"
        width="100%"
        flexGrow={1}
        flexShrink={0}
      >
        <TextInput
          maxLength={8}
          textAlign="right"
          keyboardType="decimal-pad"
          value={amountValue}
          onChangeText={changeAmount}
          hasError={hasAmountError}
          placeholder="0.00"
          testID="FIAT_DEPOSIT_BANK_WIRE_AMOUNT"
          LeftPartComponent={
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              pl={15}
            >
              <LogoColored name={ticker as LogoColoredIconName} size={20} />

              <Text ml={8} variant="$body-01-medium-accent">
                {coinNames[ticker]}
              </Text>
            </Box>
          }
          RightPartComponent={
            <Box
              height="100%"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              ml={3}
              pr={15}
            >
              <Text color="$text-02">{tickerFormatted}</Text>
            </Box>
          }
        />
      </Box>

      <Error
        errors={instance.errors()}
        name="amount"
        testID="FIAT_DEPOSIT_BANK_WIRE_AMOUNT_ERROR"
      />
    </Box>
  );
});
