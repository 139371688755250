import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {MenuButton} from '../MenuButton';

export const ActionsMenu: React.FC = () => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();

  const navigateToSignIn = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('SignIn');
  }, []);

  const navigateToSignUp = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('SignUp');
  }, []);

  return (
    <Box flexDirection="row" alignItems="center" flexShrink={1}>
      <MenuButton testID="SIGN_IN_LINK" onPress={navigateToSignIn} mr={8}>
        {t('surface.auth.actions.sign_in')}
      </MenuButton>
      <MenuButton
        type="secondary"
        testID="SIGN_UP_LINK"
        onPress={navigateToSignUp}
      >
        {t('surface.auth.actions.sign_up')}
      </MenuButton>

      <LanguageSwitch
        size="small"
        variant={isMobile ? 'icon' : 'default'}
        width={isMobile ? 40 : 96}
        dropdownWidth={116}
        boxProps={{ml: 8}}
        testID="LANGUAGE_LIST"
      />
    </Box>
  );
};
