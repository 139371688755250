import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';

interface ExtendHODLButtonProps extends React.ComponentProps<typeof Button> {
  isShort: boolean;
}

export const FlipHODLButton: React.FC<ExtendHODLButtonProps> = ({
  isShort,
  ...buttonProps
}) => {
  const {t} = useTranslation();

  return (
    <Button
      type="secondary"
      icon={isShort ? 'up' : 'down'}
      flexBasis={{default: 'auto', desktop: 0}}
      flexGrow={1}
      {...buttonProps}
    >
      {t('surface.hodls.flip_order.action_title', {
        direction: isShort
          ? t('surface.hodls.item.details_table.direction_up')
          : t('surface.hodls.item.details_table.direction_down'),
      })}
    </Button>
  );
};
