import {Platform} from 'react-native';
import {COLORS} from './colors/COLORS';
import {FONTS} from './fonts/FONTS';
import {BODY_TOKENS, HEADING_TOKENS, CODE_TOKENS} from './fonts/TOKENS';
import {Theme} from './types';

export const sharedTheme: Pick<
  Theme,
  | 'spacing'
  | 'breakpoints'
  | 'pallet'
  | 'textVariants'
  | 'textWeightVariants'
  | 'fonts'
> = {
  pallet: COLORS,
  fonts: FONTS,

  spacing: {
    '$screen-section': 24,
    '$screen-default-offset': 24,
  },

  breakpoints: {
    default: 0,
    smallPhone: 0,
    phone: 360,
    tablet: 768,
    desktop: 991,
  },

  textVariants: {
    defaults: BODY_TOKENS['$body-01'],
    ...HEADING_TOKENS,
    ...BODY_TOKENS,
    ...CODE_TOKENS,
  },

  textWeightVariants: {
    regular: {
      fontFamily: FONTS.normal.regular,
      fontWeight: Platform.select({web: '400', native: undefined}),
    },
    medium: {
      fontFamily: FONTS.normal.medium,
      fontWeight: Platform.select({web: '500', native: undefined}),
    },
    bold: {
      fontFamily: FONTS.normal.bold,
      fontWeight: Platform.select({web: '700', native: undefined}),
    },
  },
};
