import * as React from 'react';
import {
  SvgProps,
  Svg,
  G,
  Path,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';

export const BankCardPlasticImage = React.memo((props: SvgProps) => {
  const {colors} = useTheme();

  return (
    <Svg
      viewBox="0 0 232 138"
      fill="none"
      preserveAspectRatio="xMidYMid slice"
      {...props}
    >
      <G clipPath="url(#bank-card-plastic_svg__bank-card-plastic_svg__clip0_17936_189792)">
        <Path fill={colors['$interactive-01']} d="M0 0h232v138H0z" />
        <G opacity={0.4} fillRule="evenodd" clipRule="evenodd">
          <Path
            d="M118.177 78.823L78.765 55.176v94.588l39.412 23.647V78.823z"
            fill="#40C4FF"
          />
          <Path
            d="M118.176 78.823L78.765 55.176l78.823-47.294L197 31.529l-78.824 47.294z"
            fill="#80D8FF"
          />
          <Path
            d="M144.425 63.059L197 94.589v-63.06l-52.575 31.53z"
            fill="#37A4FF"
          />
          <Path
            d="M223.248 141.882l52.575 31.529v-63.058l-52.575 31.529z"
            fill="url(#bank-card-plastic_svg__bank-card-plastic_svg__paint0_linear_17936_189792)"
          />
          <Path
            d="M249.575 63.059l-52.574 31.53v-63.06l52.574 31.53z"
            fill="#2F8AFF"
          />
          <Path
            d="M170.751 141.882l-52.575 31.529v-63.058l52.575 31.529z"
            fill="#37A4FF"
          />
          <Path
            d="M275.824 78.823l39.411-23.647-78.823-47.294L197 31.529l78.824 47.294z"
            fill="#80D8FF"
          />
        </G>
      </G>
      <Defs>
        <LinearGradient
          id="bank-card-plastic_svg__bank-card-plastic_svg__paint0_linear_17936_189792"
          x1={262.679}
          y1={204.941}
          x2={206.69}
          y2={181.6}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#40C4FF" />
          <Stop offset={1} stopColor="#80D8FF" />
        </LinearGradient>
        <ClipPath id="bank-card-plastic_svg__bank-card-plastic_svg__clip0_17936_189792">
          <Path fill="#fff" d="M0 0h232v138H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
});
