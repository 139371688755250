import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const UmaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
        {...props}
      >
        <Path
          d="M24.67 17.802a1.5 1.5 0 01-.686.142c-.17 0-.317-.026-.44-.077a.658.658 0 01-.29-.218.613.613 0 01-.097-.348c0-.098.02-.187.06-.267a.686.686 0 01.196-.218c.09-.069.208-.132.354-.19.127-.047.274-.091.44-.13.168-.04.387-.084.66-.132l.007-.001c.17-.032.382-.071.622-.133-.004.109-.01.234-.02.374-.01.21-.054.396-.13.555a1.37 1.37 0 01-.229.31 1.521 1.521 0 01-.446.333z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 14.986c0-1.956 0-2.933.38-3.68A3.492 3.492 0 012.907 9.78c.747-.38 1.725-.38 3.68-.38h18.828c1.955 0 2.933 0 3.68.38a3.492 3.492 0 011.525 1.526c.381.747.381 1.724.381 3.68v2.028c0 1.956 0 2.933-.38 3.68a3.493 3.493 0 01-1.526 1.526c-.747.38-1.725.38-3.68.38H6.586c-1.955 0-2.933 0-3.68-.38a3.492 3.492 0 01-1.525-1.526C1 19.947 1 18.97 1 17.014v-2.028zm21.596 3.883c.294.16.651.24 1.073.24.508 0 .927-.087 1.258-.262.263-.14.512-.348.746-.623v.721h1.296v-3.594c0-.268-.011-.511-.033-.73a1.755 1.755 0 00-.207-.653 1.726 1.726 0 00-.915-.827 3.73 3.73 0 00-1.383-.24c-.708 0-1.267.152-1.677.457-.41.305-.69.701-.839 1.188l1.34.424a.911.911 0 01.495-.56 1.66 1.66 0 01.681-.159c.392 0 .672.082.839.245.14.137.22.338.243.604a1.943 1.943 0 01-.646.226c-.339.06-.625.106-.852.142h-.003l-.287.047c-.25.042-.642.134-.853.197-.21.064-.47.184-.658.358-.185.17-.317.373-.4.599a1.642 1.642 0 00-.106.654c0 .326.075.626.224.898.152.269.373.485.664.648zm-3.458-3.398v3.474h1.48V15.08c0-.643-.172-1.162-.516-1.557-.342-.4-.848-.6-1.52-.6-.392 0-.75.088-1.073.262a1.997 1.997 0 00-.724.651 1.751 1.751 0 00-.577-.618c-.294-.196-.672-.295-1.133-.295-.403 0-.768.09-1.094.267a2.104 2.104 0 00-.627.52v-.645h-1.306v5.881h1.492v-3.561c0-.338.092-.612.277-.822a.946.946 0 01.746-.322c.323 0 .576.11.757.327.185.214.278.516.278.904v3.474h1.481v-3.561c0-.225.042-.423.125-.594a.972.972 0 01.36-.403.975.975 0 01.539-.147c.323 0 .575.11.757.327.185.214.278.516.278.904zM7.323 19.12c-.46 0-.836-.078-1.127-.235a1.882 1.882 0 01-.68-.593 2.513 2.513 0 01-.344-.746c-.069-.261-.11-.5-.125-.713a7.769 7.769 0 01-.022-.469v-3.3h1.503v2.777c0 .135.008.307.022.518.015.207.06.415.136.626.077.21.2.387.37.528.175.142.42.213.736.213.127 0 .263-.02.408-.06.145-.04.282-.117.409-.23.127-.115.23-.284.31-.506.084-.225.125-.519.125-.882v-2.984h1.492v5.881H9.23v-.606a2.415 2.415 0 01-.473.405c-.378.25-.855.376-1.433.376z"
          fill={color}
        />
      </Svg>
    );
  }
);
