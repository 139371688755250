import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SavingsSelectAssetsFeature} from '../../Feature';
import {SavingsStartEarningButton} from '../../../../components';

export const SavingsSelectAssetsFooter: React.FC<BoxProps> = observer(
  boxProps => {
    const {disabled, submitting, submitWithConfirmation} =
      SavingsSelectAssetsFeature.use({});

    return (
      <Box p={{default: 16, phone: 24}} {...boxProps}>
        <SavingsStartEarningButton
          disabled={disabled}
          loading={submitting}
          onPress={submitWithConfirmation}
        />
      </Box>
    );
  }
);
