import React, {useLayoutEffect} from 'react';
import {useLocation, useRouteMatch} from 'react-router';
import {debounce} from 'lodash';

export type ScrollToTopControllerProps = {};

const scrollToTop = debounce(() => {
  try {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  } catch (e) {
    window.scrollTo(0, 0);
  }
}, 300);

// Component that attaches scroll to top hanler on router change
// renders nothing, just attaches side effects
export const ScrollToTopController: React.FC<
  ScrollToTopControllerProps
> = () => {
  const {key} = useLocation();
  const isMultiHODLPortolio = useRouteMatch('/multihodl/portfolio');

  useLayoutEffect(() => {
    //NOTE: no scrollToTop for MH portfolio because there are another scrolling effect used
    if (isMultiHODLPortolio) {
      return;
    }
    scrollToTop();
  }, [key, isMultiHODLPortolio]);

  return null;
};
