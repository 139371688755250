import * as React from 'react';
import {observer} from 'mobx-react';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {AmountAndPercentInput} from '@youtoken/ui.amount-and-percent-input';
import {ValidationMessageTouchable} from './ValidationMessageTouchable';
import {AdjustPendingOrderFormClass} from '../../form';
import {TriggerDistancePresetButtons} from '@youtoken/ui.hodl-elements';

export const PendingOrderSection: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {
      triggerPrice,
      triggerPricePercent,
      triggerPriceDistanceMax,
      setOfTriggerPricePercent,
      updateTriggerPriceValue,
      updateTriggerPricePercent,
    },
  } = useForm<AdjustPendingOrderFormClass>();
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Label mb={8}>{t('surface.hodls.item.trigger_price_label')}</Label>
      <AmountAndPercentInput
        amountLabel={t('surface.hodls.hodl_form.trigger_price_label')}
        percentLabel={t('surface.hodls.adjust_tp.change')}
        amountValue={triggerPrice}
        percentValue={triggerPricePercent}
        onChangeAmount={updateTriggerPriceValue}
        onChangePercent={updateTriggerPricePercent}
        percentSignIsActive={true}
        validationComponent={<ValidationMessageTouchable />}
        testIdSection="TRIGGER_PRICE"
      />
      <TriggerDistancePresetButtons
        currentTriggerPricePercent={triggerPricePercent}
        triggerPriceDistanceMax={triggerPriceDistanceMax}
        setOfTriggerPricePercent={setOfTriggerPricePercent}
        onChangeDistance={updateTriggerPricePercent}
      />
    </Box>
  );
});
