import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm, DirectionButtons} from '@youtoken/ui.form-elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {InstrumentSelector} from '../../components';

interface TariffAndDirectionSectionProps {
  withBackAction?: boolean;
}

export const TariffAndDirectionSection: React.FC<TariffAndDirectionSectionProps> =
  observer(({withBackAction = false}) => {
    const {
      form: {
        direction,
        disableSell,
        disableBuy,
        setDirection,
        hodlInstrument,
        instrumentsItems,
        setHodlInstrument,
      },
    } = useForm<CreateHODLFormClass>();

    return (
      <>
        <Box
          zIndex={10}
          flexDirection="row"
          position="relative"
          alignItems="center"
          justifyContent="center"
          px={16}
          height={{
            default: 48,
            desktop: 64,
          }}
        >
          <InstrumentSelector
            instrument={hodlInstrument}
            items={instrumentsItems}
            onChange={setHodlInstrument}
            width={400}
          />
          {withBackAction && (
            <TouchableBox
              onPress={() => SHARED_ROUTER_SERVICE.goBack()}
              position="absolute"
              top={14}
              left={16}
            >
              <Icon name="close" size={20} color="$interactive-01" />
            </TouchableBox>
          )}
          <DirectionButtons
            currentValue={direction}
            onChange={setDirection}
            disableSell={disableSell}
            disableBuy={disableBuy}
            position="absolute"
            top={{
              default: 10,
              desktop: 18,
            }}
            right={16}
          />
        </Box>
        <Separator />
      </>
    );
  });
