import * as React from 'react';
import {Platform} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {type BoxProps, Box} from '@youtoken/ui.primitives';

export const ScreenWrap: React.FC<BoxProps> = ({children, ...boxProps}) => {
  const {bottom} = useSafeAreaInsets();

  const mb = React.useMemo(() => {
    if (Platform.OS === 'ios') {
      return bottom ? {default: bottom - 16, phone: bottom - 24} : 0;
    }

    return 0;
  }, [bottom]);

  return (
    <Box mb={mb} {...boxProps}>
      {children}
    </Box>
  );
};
