import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Heading, useTheme} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SubmitButton} from '@youtoken/ui.form-elements';
import {
  DisclosureSurfaceContentProps,
  DisclosureSurfaceFooterProps,
  DisclosureSurfaceProps,
} from './types';
import {Content, AgreeToTermsField, DisclosureForm} from './components';

export const DisclosureSurfaceContent: React.FC<
  DisclosureSurfaceContentProps
> = () => {
  throw new Error('Not implemented');

  return null;
};

export const DisclosureSurfaceFooter: React.FC<
  DisclosureSurfaceFooterProps
> = () => {
  throw new Error('Not implemented');

  return null;
};

export const DisclosureSurface: React.FC<DisclosureSurfaceProps> = cell(
  ({product, handleOnSuccess, ...props}) => {
    const {t} = useTranslation();
    const {spacing} = useTheme();

    return (
      <Box
        borderRadius={10}
        overflow="hidden"
        borderColor="$ui-01"
        borderWidth={{
          desktop: 1,
          tablet: 1,
          phone: 0,
          smallPhone: 0,
        }}
        bg="$ui-background"
        width="100%"
        maxWidth={700}
        p={spacing['$screen-default-offset']}
        {...props}
      >
        <Heading variant="$heading-01" mb={16} textAlign="center">
          {t('surface.disclosure_form.title')}
        </Heading>
        <DisclosureForm product={product} handleOnSuccess={handleOnSuccess}>
          <Box width="100%" flexGrow={1}>
            <Box flexGrow={1}>
              <Content product={product} mb={32} />
            </Box>
            <Box>
              <AgreeToTermsField pb={24} testID="HAVE_READ_SWITCHER" />
              <Box width="100%">
                <SubmitButton testID="AGREEMENT_BUTTON">
                  {t('surface.disclosure_form.action')}
                </SubmitButton>
              </Box>
            </Box>
          </Box>
        </DisclosureForm>
      </Box>
    );
  }
);
