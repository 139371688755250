import big from 'big.js';
import {Platform} from 'react-native';
import {action, computed, observable} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {messages} from '@youtoken/ui.validation-messages';
import {
  normalizeAmountByTicker,
  normalizeWithoutMultiplySpaces,
} from '@youtoken/ui.normalizers';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {i18n} from '@youtoken/ui.service-i18n';
import {type DepositInvoiceFormResources} from './types';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  private readonly ticker: string;

  @observable
  private resources: DepositInvoiceFormResources;

  public constructor(ticker: string, resources: DepositInvoiceFormResources) {
    this.ticker = ticker;
    this.resources = resources;

    this.instance = new MobxReactForm(
      {
        fields: {
          amount: {
            value: '',
          },
          purpose: {
            value: 'savings',
          },
          bankName: {
            value: '',
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                return yup.object().shape({
                  amount: yup
                    .big()
                    .gte(this.minAmount.toNumber(), () =>
                      messages.SHOULD_BE_GTE({
                        value: this.minAmount.toFixed(0),
                      })
                    )
                    .required(messages.REQUIRED),
                  purpose: yup.string(),
                  bankName: yup
                    .string()
                    .matches(/\w+/i, messages.REQUIRED)
                    .required(messages.REQUIRED),
                });
              }),
          }),
        },
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            const {amount, purpose, bankName} = form.values();

            TRANSPORT.API.post('/v1/deposit/invoice', {
              ticker: this.ticker,
              amount,
              purpose,
              bankName,
            })
              .then(() => {
                if (Platform.OS === 'web') {
                  SHARED_ROUTER_SERVICE.navigate('__CloseModal');
                } else {
                  SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
                }

                LOCAL_NOTIFICATIONS.info({
                  text: i18n.t(
                    'surface.wallets.fiat_deposit_wire.invoice_will_sent'
                  ),
                });
              })
              .catch(error => {
                handleFormSubmitError(this.instance, error);
              });
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: true,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }

  @computed
  private get fee() {
    return this.resources.fees.getFeeForDeposit('wire', this.ticker);
  }

  @computed
  public get minAmount() {
    return this.fee?.minAmount || big(0);
  }

  @computed
  public get amountValue() {
    return this.instance.$('amount').get('value');
  }

  @computed
  public get amountFormatted() {
    return this.amountValue ? big(this.amountValue).toFixed(2) : 0;
  }

  @computed
  public get currentFee() {
    if (!this.amountValue || !this.fee) {
      return big(0);
    }

    const {method, provider, ticker, conversionTicker} = this.fee;
    return this.resources.fees.calculateFee(
      method,
      provider,
      ticker,
      conversionTicker,
      this.amountValue
    );
  }

  @computed
  public get currentFeeFormatted() {
    return this.currentFee.toFixed(2);
  }

  @computed
  public get purposeValue() {
    return this.instance.$('purpose').get('value');
  }

  @computed
  public get bankNameValue() {
    return this.instance.$('bankName').get('value');
  }

  @computed
  public get hasAmountError() {
    return Boolean(this.instance.$('amount').error);
  }

  @computed
  public get hasBankNameError() {
    return Boolean(this.instance.$('bankName').error);
  }

  @action
  public changeAmount = (value: string) => {
    this.instance
      .$('amount')
      .set('value', normalizeAmountByTicker(value, this.ticker));
  };

  @action
  public changePurpose = (value: string) => {
    this.instance.$('purpose').set('value', value);
  };

  @action
  public changeBankName = (value: string) => {
    this.instance
      .$('bankName')
      .set('value', normalizeWithoutMultiplySpaces(value));
  };
}
