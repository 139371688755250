import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';

interface ReferralsLayoutProps {
  header: React.ReactNode;
  contentProps?: BoxProps;
}

export const ReferralsLayout: React.FC<ReferralsLayoutProps & BoxProps> =
  observer(({header, contentProps = {}, children, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box flex={1} {...boxProps}>
        <Box
          p={{default: 16, phone: 24}}
          backgroundColor="$interactive-01"
          borderRadius={12}
        >
          <Box position="absolute" right={0} top={0} opacity={0.08}>
            <Icon name="gift" size={262} color="$text-04" />
          </Box>
          <Box mb={{default: 16, phone: 24}}>
            <Text mb={16} variant="$body-01" color="$text-04">
              {t('surface.referral_program.referrals.heading')}
            </Text>
            {header}
          </Box>
        </Box>
        <Box
          flex={1}
          mt={{default: -16, phone: -24}}
          backgroundColor="$ui-background"
          borderColor="$ui-01"
          borderWidth={1}
          borderRadius={12}
          {...contentProps}
        >
          {children}
        </Box>
      </Box>
    );
  });
