import React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {type DirectionText} from '@youtoken/ui.resource-hodl';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type IconProps, IconSmall} from '@youtoken/ui.icons';
import {getThemeColorForDirection} from '../../utils';

export type DirectionBadgeProps = {
  direction: DirectionText;
  directionColor?: string;
  showTitle?: boolean;
} & BoxProps;

export const DirectionBadge: React.FC<DirectionBadgeProps> = React.memo(
  ({showTitle = true, direction, directionColor = 'neutral', ...props}) => {
    const {t} = useTranslation();

    const _directionColor = React.useMemo(() => {
      return getThemeColorForDirection(directionColor);
    }, [directionColor]);

    return (
      <Box flexDirection="row" alignItems="center" {...props}>
        <IconSmall
          name={direction}
          color={_directionColor as IconProps<typeof direction>['color']}
          size={16}
        />

        {showTitle && (
          <Text color={_directionColor} variant="$body-02">
            {t(`surface.hodls.item.direction.${direction}`)}
          </Text>
        )}
      </Box>
    );
  }
);
