import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {
  type RewardsBannerLayoutProps,
  RewardsBannerLayout,
} from '../../components';
import {RewardsBannerErrroFallback} from './ErrorFallback';
import {RewardsBannerV3Smart} from './v3';
import {RewardsBannerV1Smart} from './v1';

export const RewardsBannerSmart: React.FC<RewardsBannerLayoutProps> = cell(
  ({variant}) => {
    const {
      data: {rewardVersion},
    } = AuthMeResource.use({});

    if (rewardVersion === 3) {
      return <RewardsBannerV3Smart variant={variant} />;
    }

    return <RewardsBannerV1Smart variant={variant} />;
  },
  {
    CellWrap: RewardsBannerLayout,
    ErrorFallback: RewardsBannerErrroFallback,
  }
);
