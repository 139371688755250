import * as React from 'react';
import {RenderProp} from '@web-app/src/types/react';
import {openIntercomChat, isIntercomAvaliable} from '@web-app/src/utils';

export type IntercomButtonProps = {
  render: RenderProp<{
    openIntercom: () => void;
    isIntercomAvaliable: boolean;
    children?: any;
  }>;
  message?: string;
};

export const IntercomButton: React.FC<IntercomButtonProps> = ({
  render,
  message,
  children,
}) => {
  const openIntercom = React.useCallback(() => {
    openIntercomChat(message);
  }, [message]);

  if (!render) {
    return null;
  }

  return render({
    openIntercom,
    isIntercomAvaliable: isIntercomAvaliable(),
    children,
  });
};
