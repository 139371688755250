import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {type TouchableWrapperProps} from './types';

export const TouchableWrapper: React.FC<TouchableWrapperProps> = observer(
  ({item, children}) => {
    return (
      <Box
        width="100%"
        flexDirection="row"
        alignItems="center"
        testID={`ADD_WALLET_ITEM_${item.ticker.toUpperCase()}`}
      >
        {children}
      </Box>
    );
  }
);
