import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Text, Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export interface AccountLevelMinerWelcomePackProps {
  count: number;
  showLabel?: boolean;
}

export const AccountLevelMinerWelcomePack: React.FC<
  AccountLevelMinerWelcomePackProps & BoxProps
> = observer(({count, showLabel = true, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      testID="ACCOUNT_LEVEL_MINER_WELCOME_PACK"
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      flexWrap="wrap"
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Icon name="spark" size={16} />
        <Text
          testID="ACCOUNT_LEVEL_MINER_WELCOME_PACK_VALUE"
          variant="$body-02"
          color="$text-01"
        >
          {count}
        </Text>
      </Box>
      {showLabel && (
        <Text variant="$body-02" color="$text-02" ml={3}>
          {t('surface.miner.loyalty.welcome-pack')}
        </Text>
      )}
    </Box>
  );
});
