import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {i18n} from '@youtoken/ui.service-i18n';
import {BankCardTransactionsResource} from '@youtoken/ui.resource-bank-cards';
import {Button} from '@youtoken/ui.buttons';
import {DATA_STORAGE} from '@youtoken/ui.data-storage';
import {TransactionsList} from './TransactionsList';

type BankCardTransactionsSurfaceProps = {
  cardId: string;
};

export const BankCardTransactionsSurface: React.FC<
  BankCardTransactionsSurfaceProps & BoxProps
> = cell(
  ({cardId, ...boxProps}) => {
    const {
      hasNext,
      hasPrev,
      toNextPage,
      toPrevPage,
      isLoading,
      sectionsByDate,
    } = BankCardTransactionsResource.use({cardId});

    React.useEffect(() => {
      return () => {
        DATA_STORAGE.delete(BankCardTransactionsResource.getKey({cardId}));
      };
    }, [cardId]);

    return (
      <Box
        width="100%"
        flex={1}
        backgroundColor="$ui-background"
        justifyContent="space-between"
        {...boxProps}
      >
        <TransactionsList
          isLoading={isLoading}
          sectionsByDate={sectionsByDate}
        />

        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          py={16}
        >
          <Button
            size="small"
            type="secondary"
            disabled={!hasPrev}
            onPress={toPrevPage}
            width={32}
            icon="chevron_left"
            px={0}
            mx={4}
          />
          <Button
            size="small"
            type="secondary"
            disabled={!hasNext || isLoading}
            onPress={toNextPage}
            width={32}
            icon="chevron_right"
            px={0}
            mx={4}
          />
        </Box>
      </Box>
    );
  },
  {
    displayName: 'BankCardTransactions',
  }
);

export const getTitle = () =>
  i18n.t('surface.bank_cards.menu_item.transactions');
