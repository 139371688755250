import * as React from 'react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';

export const Label: React.FC<BoxProps> = React.memo(({children, ...rest}) => {
  return (
    <Box height={24} mb={10} justifyContent="center" {...rest}>
      <Text variant="$body-01-high-accent" textAlignVertical="center">
        {children}
      </Text>
    </Box>
  );
});
