import * as React from 'react';
import {debounce, throttle} from 'lodash';
import {LayoutChangeEvent, ViewProps} from 'react-native';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

/** size of the `<Box />`, may be "null", before initial callback fires */
interface Size {
  width: number | null;
  height: number | null;
}

export interface ResizableCallbacks {
  /** on box size changed */
  onSizeChange?: (size: {width: number | null; height: number | null}) => void;
  /** on state of resizing change. initially `false`, then true while user resizes (debounced) */
  onResizingStateChange?: (isResizing: boolean) => void;
  /** throttle time for resize event, purely for optimization
   * @default 16
   */
  throttleTime?: number;

  /** debounce time from last resize and before `isResizing` is set to false
   * @default 400
   */
  debounceTime?: number;
}

interface ResizableBoxProps extends BoxProps, ResizableCallbacks, ViewProps {}

/** WebResizable box. provides callbacks to it`s own size.
 *
 * Throttled, debounced and overwise optimized;
 * Note, that this base component make no assumptions about rendering children, just provides callbacks;
 *
 * Better used with `useResizableBox`:
 *
 * ```typescript
 * const [{size: {width, height}, isResizing}, bind] = useResizableBox({
 *   onSizeChange: () => {}, // run on change to avoid effects;
 *   onResizingStateChange: () => {}, // run on state change
 * });
 *
 * return (
 *   <ResizableBox {...bind}>
 *  //...
 * )
 * ```
 **/
export const ResizableBox: React.FC<ResizableBoxProps> = ({
  children,
  onSizeChange,
  onResizingStateChange,
  throttleTime = 16,
  debounceTime = 400,
  ...props
}) => {
  const [initialSizeSet, setInitialSizeSet] = React.useState<boolean>(false);

  // after initial sie is called all other size events are debounced;
  const handleLayoutChangeEnd = React.useCallback(
    debounce(
      (size: Size) => {
        onSizeChange?.(size);
        onResizingStateChange?.(false);
      },
      debounceTime,
      {leading: false, trailing: true}
    ),
    [debounceTime]
  );

  // if initial size was not set called onSizeChange immediately;
  // all other events are debounced for performance;

  const handleLayoutChange = React.useCallback(
    throttle(
      // apparently, layout event is not accessible from sometimes because of throttle?..
      (event: LayoutChangeEvent) => {
        const layout = event?.nativeEvent?.layout;

        if (!layout) {
          return;
        }

        const size: Size = {width: layout.width, height: layout.height};

        if (!initialSizeSet) {
          setInitialSizeSet(true);
          onSizeChange?.(size);
        } else {
          onResizingStateChange?.(true);
          handleLayoutChangeEnd(size);
        }
      },
      throttleTime,
      {
        leading: true,
        trailing: true,
      }
    ),
    [initialSizeSet, throttleTime]
  );

  return (
    <Box onLayout={handleLayoutChange} {...props}>
      {initialSizeSet && children}
    </Box>
  );
};

/**  binds resize callbacks to ResizableBox;
 * @example
 * const [{size: {width, height},isResizing}, bind] = useResizableBox();
 *
 * return (
 *   <ResizableBox {...bind}>
 *  */
export const useResizableBox = (
  callbacks?: ResizableCallbacks
): [{size: Size; isResizing: boolean}, ResizableCallbacks] => {
  const [size, setSize] = React.useState<Size>({height: null, width: null});
  const [isResizing, setIsResizing] = React.useState<boolean>(false);

  const bind: ResizableCallbacks = React.useMemo(
    () => ({
      onSizeChange: size => {
        callbacks?.onSizeChange?.(size);
        setSize(size);
      },
      onResizingStateChange: isResizing => {
        callbacks?.onResizingStateChange?.(isResizing);
        setIsResizing(isResizing);
      },
    }),
    [callbacks?.onSizeChange, callbacks?.onResizingStateChange]
  );

  const values = React.useMemo(
    () => ({
      size,
      isResizing,
    }),
    [size.width, size.height, isResizing]
  );

  return [values, bind];
};
