import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {
  ConversionFormClass,
  ConversionUnauthorizedFormClass,
} from '../../state';

export const ExchangeFormInfoTableV1: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    view: {
      tickerFormatted,
      conversionTickerFormatted,
      amountFormatted,
      amountWithoutFeeFormatted,
      rateFormatted,
      feeFormatted,
    },
  } = useForm<ConversionFormClass | ConversionUnauthorizedFormClass>();

  return (
    <InformationTable mt={24} py={0}>
      <InformationTableRow
        testID="CONVERT_TABLE_INITIAL_ROW"
        left={t('surface.wallets.conversion_form.table.initial')}
        right={
          <>
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_INITIAL_VALUE"
            >
              {amountFormatted}{' '}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {tickerFormatted}
            </Text>
          </>
        }
      />
      <InformationTableRow
        testID="CONVERT_TABLE_FEE_ROW"
        left={t('surface.wallets.conversion_form.table.fee')}
        right={
          <>
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_FEE_VALUE"
            >
              {feeFormatted}{' '}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {tickerFormatted}
            </Text>
          </>
        }
      />
      <InformationTableRow
        testID="CONVERT_TABLE_AMOUNT_ROW"
        left={t('surface.wallets.conversion_form.table.amount_to_convert')}
        right={
          <>
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_AMOUNT_VALUE"
            >
              {amountWithoutFeeFormatted}{' '}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {tickerFormatted}
            </Text>
          </>
        }
      />
      <InformationTableRow
        testID="CONVERT_TABLE_RATE_ROW"
        left={t('surface.wallets.conversion_form.table.rate')}
        right={
          <>
            <Text variant="$body-02-high-accent" color="$text-01">
              1{' '}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {tickerFormatted} ≈{' '}
            </Text>
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_RATE_VALUE"
            >
              {rateFormatted}{' '}
            </Text>
            <Text variant="$body-02" color="$text-02">
              {conversionTickerFormatted}
            </Text>
          </>
        }
        isLast
      />
    </InformationTable>
  );
});
