import React from 'react';
import {observer} from 'mobx-react';
import {RewardSummaryResource} from '@youtoken/ui.resources-rewards';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {FriendsActivity} from './FriendsActivity';
import {
  BottomSheetScrollView,
  ReferralsHeaderV1,
  InviteFriends,
  RewardsStatisticsSmart,
  ShareRefCodeModalTrigger,
  RewardsHistoryViewSmart,
} from '../../..';
import {ReferralsSmartProps} from '../types';
import {ReferralsLayout} from '../Layout';

export const ReferralsScreenV1: React.FC<ReferralsSmartProps> = observer(
  ({showShareRefCode, ...rest}) => {
    const {
      data: {maxUserEarningUSD},
    } = RewardSummaryResource.use({});

    return (
      <ReferralsLayout
        header={<ReferralsHeaderV1 amountFormatted={maxUserEarningUSD} />}
        {...rest}
      >
        <BottomSheetScrollView style={{flex: 1}}>
          {!showShareRefCode && (
            <>
              <InviteFriends />
              <Separator />
            </>
          )}
          <RewardsStatisticsSmart />
          <Separator />
          <FriendsActivity />
          <Separator />
          <RewardsHistoryViewSmart showHeader />
        </BottomSheetScrollView>
        {showShareRefCode && (
          <Box p={24}>
            <ShareRefCodeModalTrigger source="referrals-v1" />
          </Box>
        )}
      </ReferralsLayout>
    );
  }
);
