import * as React from 'react';
import {SvgProps, Svg, Circle, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const Web3Icon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Circle cx={10} cy={10} r={7.25} stroke={color} strokeWidth={1.5} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.252 4.9a2.304 2.304 0 00-.14-.284c-.25-.435-.632-.768-1.112-1.001 2.69-.619 4.806-.362 5.526.885 1.224 2.12-2.048 6.302-7.31 9.34C6.957 16.876 1.698 17.62.473 15.5c-.752-1.304.195-3.387 2.26-5.505-.065.582.027 1.126.298 1.596.036.061.074.12.115.178-.094.11-.183.22-.269.33-.59.755-.933 1.403-1.079 1.897-.144.49-.061.692-.025.754.035.062.169.234.665.354.501.122 1.233.15 2.183.015 1.892-.268 4.339-1.131 6.845-2.579 2.507-1.447 4.479-3.134 5.656-4.639.591-.755.934-1.403 1.08-1.897.143-.49.06-.692.025-.754-.035-.062-.17-.234-.666-.354-.5-.122-1.232-.15-2.183-.015l-.126.019z"
          fill={color}
        />
      </Svg>
    );
  }
);
