import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Button} from '@youtoken/ui.buttons';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsSavings');
};

export interface SavingsClaimEarnedButtonProps {
  tickerSymbol: string;
  value: string;
}

export const SavingsClaimEarnedButton: React.FC<
  SavingsClaimEarnedButtonProps & React.ComponentProps<typeof Button>
> = observer(({tickerSymbol, value, ...buttonProps}) => {
  const {t} = useTranslation();

  return (
    <Button
      testID="SAVINGS_CLAIM_EARNED_BUTTON"
      color="success"
      size="small"
      onPress={handlePress}
      width="100%"
      {...buttonProps}
    >
      {t('surface.earn_more.reward_banner.claim')} {tickerSymbol}
      {value}
    </Button>
  );
});
