import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';

export const Header: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box mb={28}>
      <Box px={{tablet: 0, default: 20}}>
        <Heading variant="$heading-01-responsive" color="$text-01">
          {t('surface.loans.title_loans')}
        </Heading>
      </Box>
    </Box>
  );
});
