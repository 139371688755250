import * as React from 'react';
import {observer} from 'mobx-react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Heading} from '@youtoken/ui.primitives';
import {LevelIcon} from '@youtoken/ui.loyalty-miner-components';
import {AccountLevelInfoShort} from '../../components';

export const AccountLevelDetailedData: React.FC<BoxProps> = observer(props => {
  const {
    authMe: {
      products: {
        saving4: {available: availableSaving4, isEnabled: isSaving4Enabled},
        miner: {isEnabled: showMinerInfo},
        exchange: {
          settings: {enableConvertsLoyalty: showExchangeInfo},
        },
      },
    },
    loyalty: {currentLevelData},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    loyalty: getResourceDescriptor(LoyaltyResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
  });

  const {
    color,
    name,
    iconName,
    saving: {shouldShowTariff},
  } = currentLevelData;

  const showSavingsInfo = isSaving4Enabled && shouldShowTariff;

  return (
    <Box testID="ACCOUNT_LEVEL_DETAILED_DATA" bg={color} {...props}>
      <Box alignItems="center" mb={24}>
        <LevelIcon
          name={iconName}
          size={42}
          wrapSize={56}
          borderWidth={0}
          mb={8}
        />
        <Heading
          testID="ACCOUNT_LEVEL_DETAILED_DATA_NAME"
          variant="$heading-02"
        >
          {name}
        </Heading>
      </Box>
      <AccountLevelInfoShort
        level={currentLevelData}
        showSavingsInfo={showSavingsInfo}
        showMinerInfo={showMinerInfo}
        showExchangeInfo={showExchangeInfo}
        pressableSavingsInfo={availableSaving4}
      />
    </Box>
  );
});
