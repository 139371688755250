import {Companies} from '@youtoken/ui.resource-companies';
import {banks} from '../consts/banks';
import {composeWith} from './composeWith';

export type CompaniesRequisites = ReturnType<typeof getCompaniesRequisites>;

const getCompaniesRequisites = (companies: Companies) => {
  return {
    YouHodler: [
      {
        priority: 100,
        type: 'beneficiary',
        title: 'Beneficiary',
        value: companies.youhodler.name,
      },
      {
        priority: 200,
        type: 'beneficiaryAddress',
        title: 'Beneficiary Address',
        value: companies.youhodler.address,
      },
    ],
    Naumard: [
      {
        priority: 100,
        type: 'beneficiary',
        title: 'Beneficiary',
        value: companies.naumard.name,
      },
      {
        priority: 200,
        type: 'beneficiaryAddress',
        title: 'Beneficiary Address',
        value: companies.naumard.address,
      },
    ],
  };
};

const getNexPayYouhodler = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodler, banks.NexPay, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LT14 3080 0200 0000 0750',
    },
  ]);

const getNexPayNaumard = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.Naumard, banks.NexPay, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LT58 3080 0200 0000 0249',
    },
  ]);

const getFrickUSD = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.Naumard, banks.Frick, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LI58 0881 1010 3341 K000 U',
    },
    {
      type: 'correspondentBankName',
      priority: 700,
      title: 'Name of the correspondent bank',
      value: 'DZ Bank AG, Frankfurt',
    },
    {
      priority: 800,
      type: 'correspondentBankCode',
      title: 'SWIFT / BIC of the correspondent bank',
      value: 'GENO DE FF',
    },
    {
      priority: 900,
      type: 'additionalRoutingInfo',
      title: 'Additional Routing Information',
      value: 'The Bank of New York Mellon, New York\nBIC: IRVTUS3N',
    },
  ]);

const getFrickGBP = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.Naumard, banks.Frick, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LI48 0881 1010 3341 K000 G',
    },
  ]);

const getFrickCHF = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.Naumard, banks.Frick, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LI59 0881 1010 3341 K000 C',
    },
  ]);

const getFrickEUR = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.Naumard, banks.Frick, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'LI05 0881 1010 3341 K000 E',
    },
  ]);

const getIntergiroEUR = (companiesRequisites: CompaniesRequisites) =>
  composeWith(companiesRequisites.YouHodler, banks.Intergiro, [
    {
      type: 'iban',
      priority: 400,
      title: 'IBAN',
      value: 'SE02 9770 0000 0100 0804 4793',
    },
  ]);

export const getBankAccounts = (companies: Companies) => {
  const companiesRequisites = getCompaniesRequisites(companies);

  return {
    NexPayYouhodler: getNexPayYouhodler(companiesRequisites),
    NexPayNaumard: getNexPayNaumard(companiesRequisites),
    FrickUSD: getFrickUSD(companiesRequisites),
    FrickGBP: getFrickGBP(companiesRequisites),
    FrickCHF: getFrickCHF(companiesRequisites),
    FrickEUR: getFrickEUR(companiesRequisites),
    IntergiroEUR: getIntergiroEUR(getCompaniesRequisites(companies)),
  };
};
