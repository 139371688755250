import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {ApplePayIcon as apple_pay} from './ApplePay';
import {GooglePayIcon as google_pay} from './GooglePay';
import {MaestroIcon as maestro} from './Maestro';
import {MastercardIcon as mastercard} from './Mastercard';
import {MastercardPlainIcon as mastercard_plain} from './MastercardPlain';
import {VisaIcon as visa} from './Visa';

export type IconName =
  | 'apple_pay'
  | 'google_pay'
  | 'maestro'
  | 'mastercard'
  | 'mastercard_plain'
  | 'visa';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  apple_pay,
  google_pay,
  maestro,
  mastercard,
  mastercard_plain,
  visa,
};
