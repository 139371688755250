import * as React from 'react';
import {ProductType} from '../state';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {WalletDisclosureContent} from './WalletDisclosureContent';
import {YouhodlerDisclosureContent} from './YouhodlerDisclosureContent';

export interface ContentProps extends BoxProps {
  product: ProductType;
}

export const Content: React.FC<ContentProps> = ({product, ...props}) => {
  return (
    <Box {...props}>
      {product === 'wallet' ? (
        <WalletDisclosureContent />
      ) : (
        <YouhodlerDisclosureContent product={product} />
      )}
    </Box>
  );
};
