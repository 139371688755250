import * as React from 'react';
import {useChartThemeContext} from '../../Providers';
import {LineCurve} from '../../Primitives';
import {observer} from 'mobx-react';
import {BaseChartState} from '../../logic';

export const FutureDataLine: React.FC<{
  chartState: BaseChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {
      lines: {futureDataLine},
    } = chartState;

    const {
      colors: {neutral},
    } = useChartThemeContext();

    if (!futureDataLine) {
      return null;
    }

    return (
      <LineCurve
        d={futureDataLine}
        stroke={neutral}
        opacity={0.5}
        strokeWidth={1}
      />
    );
  })
);
