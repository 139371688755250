import * as React from 'react';
import {LayoutChangeEvent} from 'react-native';
import {useAnimatedStyle, withSpring} from 'react-native-reanimated';
import {Box, Text, ReanimatedBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useDebounce} from '@youtoken/ui.hooks';
import {Icon} from '@youtoken/ui.icons';

export const UmaValidationAnimatedComponent: React.FC<{
  testID?: string;
  visible?: boolean;
}> = ({testID, visible, children}) => {
  const {t} = useTranslation();
  const [_children, setChildren] = React.useState(children);
  const [size, setSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    if (children) {
      setChildren(children);
    }
  }, [children]);

  const handleLayout = React.useCallback((event: LayoutChangeEvent) => {
    setSize(event.nativeEvent.layout);
  }, []);

  const [visibleDebounced] = useDebounce(visible, 10);

  const errorStyle = useAnimatedStyle(() => {
    return {
      opacity: withSpring(visibleDebounced ? 1 : 0, {
        overshootClamping: true,
      }),
      height: withSpring(visibleDebounced ? size.height + 5 : 0, {
        overshootClamping: true,
      }),
    };
  }, [visibleDebounced, size.height]);

  const messageStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      transform: [
        {
          translateY: withSpring(visibleDebounced ? 0 : -size.height - 5, {
            overshootClamping: true,
          }),
        },
      ],
    };
  }, [visibleDebounced, size.height]);

  return (
    <ReanimatedBox
      testID={testID}
      style={errorStyle}
      // overflow="hidden"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      pointerEvents="none"
      accessible={false}
      zIndex={-1}
    >
      <ReanimatedBox style={messageStyle}>
        <Box mt={8} onLayout={handleLayout}>
          <Box flexDirection="row">
            <Text variant="$body-02" color="$text-01" mr={4}>
              {t('surface.verification.item.verified')}
            </Text>
            <Icon name="uma" size={24} height={16} />
          </Box>
        </Box>
      </ReanimatedBox>
    </ReanimatedBox>
  );
};
