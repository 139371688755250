import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {
  transparentScreenOptions,
  withoutHeaderScreenOptions,
  createWizard,
  type StackNavigatorScreenWizardWrappedProps,
} from '@youtoken/ui.elements';
import {CryptoBuyWizardChooseTicker} from './ChooseTicker';
import {CryptoBuyWizardBuy} from './Buy';

export enum CryptoBuyWizardScreenName {
  CHOOSE_TICKER = 'chooseTicker',
  BUY = 'buy',
}

export interface CryptoBuyWizardNavigator {
  [CryptoBuyWizardScreenName.CHOOSE_TICKER]: {
    source?: 'FORK' | 'WALLETS' | 'WALLET';
  };
  [CryptoBuyWizardScreenName.BUY]: {
    source?: 'FORK' | 'WALLETS' | 'WALLET';
    ticker?: string;
  };
}

const Wizard = createWizard<CryptoBuyWizardNavigator>();

export interface CryptoBuyWizardProps {
  source?: 'FORK' | 'WALLETS' | 'WALLET';
  ticker?: string;
  onPressBack?: () => void;
}

export const CryptoBuyWizard: React.FC<CryptoBuyWizardProps> = cell(
  ({source, ticker, onPressBack}) => {
    return (
      <Wizard.Navigator
        initialName={
          ticker
            ? CryptoBuyWizardScreenName.BUY
            : CryptoBuyWizardScreenName.CHOOSE_TICKER
        }
        initialProps={{
          source,
          ticker,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CryptoBuyWizardScreenName.CHOOSE_TICKER}
          options={transparentScreenOptions}
          component={CryptoBuyWizardChooseTicker}
        />
        <Wizard.Screen
          name={CryptoBuyWizardScreenName.BUY}
          options={withoutHeaderScreenOptions}
          component={CryptoBuyWizardBuy}
        />
      </Wizard.Navigator>
    );
  }
);

export const CryptoBuyWizardWrapped = ({
  route: {
    params: {ticker},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<{ticker: string}>) => {
  return <CryptoBuyWizard ticker={ticker} onPressBack={goBack} />;
};

export const getCryptoBuyWizardOptions = ({
  route: {
    params: {ticker},
  },
}: StackNavigatorScreenWizardWrappedProps<{ticker: string}>) => {
  if (ticker) {
    return;
  }

  return transparentScreenOptions;
};
