import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import qs from 'qs';
import {createResource} from '@youtoken/ui.data-storage';
import {type ChartResource} from '@youtoken/ui.legacy-charts';
import {TRANSPORT} from '@youtoken/ui.transport';

export type DayPreviewChartResourceArgs = {
  tickersPairs: string[];
  product?: ChartResource.ChartProduct; // MultiHODL has own source of data
};

export type DayPreviewChartDataResourceResponse = Array<{
  fromTicker: string;
  toTicker: string;
  chartData: Array<{
    rate: number;
    date: number;
  }>;
}>;

export class DayPreviewChartDataResource extends createResource<
  DayPreviewChartResourceArgs,
  DayPreviewChartDataResourceResponse
>({
  getKey: ({product}) => `DayPreviewChartData(${product})`,
  getData: ({tickersPairs, product = 'default'}) => {
    return TRANSPORT.API.get('/v3/rates/chart/preview', {
      params: {
        symbol: tickersPairs,
        product,
      },
      paramsSerializer: params => qs.stringify(params, {indices: false}),
    }).then(res => {
      return res.data;
    });
  },
  refetchInterval: 1000 * 60 * 10, // 10 minutes
}) {
  @computed get allPairsChartData() {
    return this.data;
  }

  getChartDataBySymbol = computedFn((fromTicker: string, toTicker: string) => {
    return this.data.find(
      d => d.fromTicker === fromTicker && d.toTicker === toTicker
    )?.chartData;
  });
}
