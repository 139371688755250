import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const WarningIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M10.75 1a.75.75 0 00-1.5 0v.5a.75.75 0 001.5 0V1zM15 9a5.001 5.001 0 01-3 4.584V15H8v-1.416A5.001 5.001 0 0110 4a5 5 0 015 5zm-3 8v-1H8v1a1 1 0 001 1h2a1 1 0 001-1zM2 8.25a.75.75 0 000 1.5h.5a.75.75 0 000-1.5H2zM16.75 9a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM3.97 2.97a.75.75 0 011.06 0l.5.5a.75.75 0 11-1.06 1.06l-.5-.5a.75.75 0 010-1.06zm12.06 1.06a.75.75 0 00-1.06-1.06l-.5.5a.75.75 0 001.06 1.06l.5-.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
