import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TextProps, Path} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';
import {useTheme} from '@youtoken/ui.primitives';

export type SparkPriceProps = {
  price: number;
  hexState: FEBlockState;
} & TextProps;

const FONT_SIZE = 14;

export const SparkPrice: React.FC<SparkPriceProps> = observer(
  ({hexState, price, ...rest}) => {
    const {textColor} = useMinerColors(hexState);

    const OPACITIES: Record<FEBlockState, number> = {
      DISABLED: 0,
      AVAILABLE_NOT_ENOUGH_SPARKS: 1,
      AVAILABLE_ENOUGH_SPARKS: 1,
      MINING_STARTING: 1,
      MINING: 0,
      READY: 0,
      CLAIMING_STARTING: 0,
      CLAIMING: 0,
      CLAIMED_COLORED: 0,
      CLAIMED_GREY: 0,
      CLAIMED_INFO: 0,
    };

    const style = useLegacyOpacityAnimation(OPACITIES, hexState);

    const text = price.toString();

    const textOffsetX = text.length * 1.3 + 3;
    const sparkOffsetX = -4 - (text.length * 2 + 3);
    const sparkOffsetY = -6;

    const {fonts} = useTheme();

    if (style.opacity === 0) {
      return null;
    }

    return (
      <>
        <Text
          opacity={style.opacity}
          x={textOffsetX}
          y={FONT_SIZE / 3.3}
          fill={textColor}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fontFamily={fonts.mono.bold}
          fontWeight={700}
          {...rest}
        >
          {text}
        </Text>
        <Path
          opacity={style.opacity}
          d="M3.07762 11.5C2.82639 11.5 2.63556 11.274 2.67768 11.0263L3.19935 7.95883C3.25127 7.65357 3.01607 7.375 2.70643 7.375H0.843307C0.667926 7.375 0.559657 7.32917 0.518501 7.2375C0.477813 7.14583 0.504237 7.01979 0.597773 6.85938L4.58185 0.685711C4.65656 0.569937 4.78493 0.5 4.92272 0.5C5.17395 0.5 5.36478 0.726025 5.32266 0.9737L4.80098 4.04117C4.74907 4.34643 4.98427 4.625 5.29391 4.625H7.15703C7.33241 4.625 7.44045 4.67083 7.48114 4.7625C7.52229 4.85417 7.4961 4.98021 7.40257 5.14062L3.41849 11.3143C3.34378 11.4301 3.21541 11.5 3.07762 11.5Z"
          fill={textColor}
          transform={`translate(${sparkOffsetX} ${sparkOffsetY})`}
        />
      </>
    );
  }
);
