export enum ScreenNameMH {
  CREATE_FORM = 'hodl creation form',
  DETAILS = 'hodl details',
  INSTRUMENT = 'instrument page',
}

export type MultiHODLScreenName =
  | ScreenNameMH.INSTRUMENT
  | ScreenNameMH.DETAILS
  | ScreenNameMH.CREATE_FORM;

export interface tvPositionLineParams {
  text: string;
  price: number;
  bodyTextColor: string;
  lineColor: string;
}
