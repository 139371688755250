import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LeftSection} from './LeftSection';
import {RightSection} from './RightSection';

type OverviewProps = BoxProps;

export const Overview: React.FC<OverviewProps> = observer(({...boxProps}) => {
  return (
    <Box
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      pb={4}
      px={24}
      {...boxProps}
    >
      <LeftSection />
      <RightSection />
    </Box>
  );
});
