import * as React from 'react';
import {Helmet} from 'react-helmet';
import {Form} from './Form';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {AuthPromoBackground} from '../Elements';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const PasswordRestoreRequestPage: React.FC = () => {
  const {t} = useTranslation();

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
        renderRight={() => {
          return (
            <Box flexDirection="row" alignItems="center">
              <Button
                size="small"
                onPress={() => {
                  SHARED_ROUTER_SERVICE.navigate('SignIn');
                }}
              >
                {t('surface.auth.actions.sign_in')}
              </Button>
              <Text variant="$body-02" color="$text-02" mx={10}>
                {t('surface.auth.actions.or')}
              </Text>
              <Box>
                <Button
                  size="small"
                  onPress={() => {
                    SHARED_ROUTER_SERVICE.navigate('SignUp');
                  }}
                >
                  {t('surface.auth.actions.sign_up')}
                </Button>
              </Box>

              <LanguageSwitch
                size="small"
                width={96}
                dropdownWidth={116}
                boxProps={{ml: 16}}
              />
            </Box>
          );
        }}
      />
    );
  }, [t]);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
      <OuterPageShell>
        <Helmet title={t('forms.reset_password.tab_title')} />
        <Box
          width="100%"
          flexDirection={{
            default: 'column',
            tablet: 'row',
          }}
          justifyContent={{
            default: 'flex-start',
            tablet: 'space-between',
          }}
        >
          <Box
            flexDirection="row"
            justifyContent="flex-end"
            width={{
              default: '100%',
              tablet: '50%',
            }}
          >
            <AuthPromoBackground />
          </Box>
          <Box
            width={{
              default: '100%',
              tablet: '50%',
            }}
            alignItems="stretch"
            pb={20}
            pl={{
              default: 0,
              tablet: 80,
            }}
          >
            <Box
              px={{
                default: 20,
                desktop: 0,
              }}
            >
              <Form />
            </Box>
          </Box>
        </Box>
      </OuterPageShell>
    </AppShell>
  );
};
