import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';

export const SavingsSelectAssetsListTitle: React.FC<BoxProps> = ({
  children,
  ...boxProps
}) => {
  return (
    <Box px={{default: 16, phone: 24}} py={16} {...boxProps}>
      <Text variant="$body-01-high-accent">{children}</Text>
    </Box>
  );
};
