import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DownIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.625 4.625a.884.884 0 011.25 0L14.25 13V7.125a.875.875 0 111.75 0v8.125a.75.75 0 01-.75.75H7.125a.875.875 0 110-1.75H13L4.625 5.875a.884.884 0 010-1.25z"
          fill={color}
        />
      </Svg>
    );
  }
);
