import * as React from 'react';
import {Text, TouchableText} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {IntercomButton} from '@web-app/src/components/elements';

export const RestoreAccessLine: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Trans
      t={t}
      i18nKey="surface.auth.to_restore"
      style={{flexWrap: 'wrap'}}
      parent={props => <Text variant="$body-02" color="$text-02" {...props} />}
      defaults="To restore account access, <IntercomButton>contact support</IntercomButton>"
      components={{
        IntercomButton: (
          <IntercomButton
            render={({openIntercom, children}) => {
              return (
                <TouchableText
                  variant="$body-02-medium-accent"
                  color="$interactive-01"
                  onPress={openIntercom}
                >
                  {children}
                </TouchableText>
              );
            }}
          />
        ),
      }}
    />
  );
};
