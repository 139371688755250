import * as React from 'react';
import {WalletsFeature} from '@youtoken/ui.legacy-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  formatInstrumentsItems,
  getDefaultInstrument,
} from '@youtoken/ui.hodls-utils';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import CONFIG from '@web-app/src/config';
import {modal} from '@web-app/src/stores';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {type List} from '@youtoken/ui.utils';
import {VerificationResource} from '@youtoken/ui.resource-verification';

export const useGroupsController = () => {
  const {
    listVisibilities,
    listTickers,
    setVisibility,
    resources: {
      authme: {walletsDisclosureSigned, checkProductAvailability},
    },
  } = WalletsFeature.use({});

  const {data: tariffs, tariffsCreateMHExists} = HodlsTariffsResource.use({});

  const [bringedToFront, setBringedToFront] = React.useState<{
    list: string;
    ticker: string;
  }>({
    list: '',
    ticker: '',
  });

  const {push} = RouterResource.use({});

  const verificationResource = VerificationResource.use({});

  const createHandlePress = React.useCallback(
    (ticker: string) => () => {
      SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
        ticker,
      });
    },
    [push]
  );

  const handleCryptoDepositNextStep = React.useCallback(
    (ticker: string) => {
      if (!walletsDisclosureSigned) {
        modal.open('disclosureWallets');
      } else {
        modal.open('cryptoDeposit', {ticker});
      }
    },
    [walletsDisclosureSigned]
  );

  const createHandlePressDeposit = React.useCallback(
    (ticker: string) => () => {
      if (CONFIG.TICKERS.FIAT.includes(ticker)) {
        modal.open('fiatDeposit', {ticker});
      } else {
        if (checkProductAvailability('depositCrypto')) {
          handleCryptoDepositNextStep(ticker);
        }
      }
    },
    [CONFIG, verificationResource, modal, walletsDisclosureSigned]
  );

  const createHandlePressWithdrawal = React.useCallback(
    (ticker: string) => () => {
      const isFiat = CONFIG.TICKERS.FIAT.includes(ticker);
      if (isFiat && checkProductAvailability('withdrawalFiat')) {
        modal.open('fiatWithdrawal', {ticker});
        return;
      }
      if (!isFiat && checkProductAvailability('withdrawalCrypto')) {
        modal.open('cryptoWithdrawal', {ticker});
      }
    },
    [CONFIG, modal]
  );

  const createHandlePressConvert = React.useCallback(
    (ticker: string) => () => {
      modal.open('conversion', {ticker});
    },
    [modal]
  );

  const createHandlePressHodl = React.useCallback(
    (ticker: string) => () => {
      const instrument = getDefaultInstrument(
        formatInstrumentsItems(tariffs),
        ticker,
        undefined
      );
      SHARED_ROUTER_SERVICE.navigate(
        'NewMultiHODL',
        {},
        {
          direction: 'buy',
          quoteTicker: instrument?.quoteTicker,
          baseTicker: instrument?.baseTicker,
          inputTicker: ticker,
        }
      );
    },
    [push, getDefaultInstrument, formatInstrumentsItems, tariffs]
  );

  const createHandleBringToFront = React.useCallback(
    (list: List, ticker: string) => () => {
      setBringedToFront({list, ticker});
    },
    [setBringedToFront]
  );

  const createHandleToggleHiddenVisibility = React.useCallback(
    (list: List) => () => {
      setVisibility(list, !listVisibilities[list]);
    },
    [listVisibilities, setVisibility]
  );

  return {
    listVisibilities,
    listTickers,
    createHandlePress,
    createHandlePressDeposit,
    createHandlePressWithdrawal,
    createHandlePressConvert,
    createHandlePressHodl: tariffsCreateMHExists
      ? createHandlePressHodl
      : undefined,
    createHandleBringToFront,
    createHandleToggleHiddenVisibility,
    bringedToFront,
  };
};
