import React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Clipboard} from '@youtoken/ui.utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const InviteFriends = observer(() => {
  const {t} = useTranslation();

  const {referralCode, referralLink} = AuthMeResource.use({});

  const handleCopyCode = React.useCallback(() => {
    Clipboard.setString(referralCode);
    LOCAL_NOTIFICATIONS.info({
      text: t('surface.referral_program.referrals.code_copied'),
    });
    DATA_LAYER.trackStrict('reward-referral-code-copy', {});
  }, [referralCode, t]);

  const handleCopyLink = React.useCallback(() => {
    Clipboard.setString(referralLink);
    LOCAL_NOTIFICATIONS.info({
      text: t('surface.referral_program.referrals.link_copied'),
    });
    DATA_LAYER.trackStrict('reward-referral-link-share', {});
  }, [referralLink, t]);

  return (
    <Box flexDirection={{default: 'column', desktop: 'row'}}>
      <Box
        flex={1}
        flexBasis={{default: 'auto', desktop: 0}}
        alignItems="center"
        p={24}
        paddingTop={20}
      >
        <Box flex={1} alignItems="center">
          <Text variant="$body-01" color="$text-02" mb={8}>
            {t('surface.referral_program.referrals.your_referral_code')}
          </Text>
          <Text variant="$body-01-high-accent" color="$text-01" mb={16}>
            {referralCode}
          </Text>
        </Box>
        <Button type="secondary" size="small" onPress={handleCopyCode}>
          {t('surface.referral_program.referrals.copy_action')}
        </Button>
      </Box>
      <Box
        width={{default: undefined, desktop: 1}}
        height={{default: 1, desktop: 'auto'}}
        bg="$ui-01"
      />
      <Box
        flex={1}
        flexBasis={{default: 'auto', desktop: 0}}
        alignItems="center"
        p={24}
        paddingTop={20}
      >
        <Box flex={1} alignItems="center">
          <Text variant="$body-01" color="$text-02" mb={8}>
            {t('surface.referral_program.referrals.your_referral_link')}
          </Text>
          <Text
            variant="$body-01-high-accent"
            color="$text-01"
            mb={16}
            textAlign="center"
          >
            {referralLink}
          </Text>
        </Box>
        <Button type="secondary" size="small" onPress={handleCopyLink}>
          {t('surface.referral_program.referrals.copy_action')}
        </Button>
      </Box>
    </Box>
  );
});
