import {AppState, AppStateStatus, NativeEventSubscription} from 'react-native';
import {action, computed, observable, autorun} from 'mobx';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {SECURE_STORAGE} from '@youtoken/ui.service-storage';
import {GLOBAL} from '@youtoken/ui.service-global';

type TAppStateTransition = `${AppStateStatus} => ${AppStateStatus}`;

export type PasscodeData = {
  passcode: string | null;
};

const EMAIL_KEY = 'youhodlerEmailKey';
const PASSCODE_KEY = 'youhodlerPasscodeKey';

const PASSCODE_SETUP_TIMEOUT = 3 * 60 * 1000; // 3 minutes

export class PasscodeResource extends createStaticResource<{}, PasscodeData>({
  getKey: () => 'Passcode',
  getData: () => {
    try {
      return Promise.resolve({
        passcode: SECURE_STORAGE.get(PASSCODE_KEY) ?? null,
      });
    } catch {
      return Promise.resolve({passcode: null});
    }
  },
  shouldBeDeletedOnCleanup: true,
  skipRefreshOnVisible: true,
}) {
  private passcodeSetupTimeout = PASSCODE_SETUP_TIMEOUT;
  private lockAt = 0;

  @observable
  previousAppState: AppStateStatus = 'unknown';

  @observable
  appState: AppStateStatus = 'active';

  @observable
  protectors!: {
    blur: boolean;
    passcode: boolean;
  };

  // if you have already seen the app;
  // this is needed to hide actual app until user enters passcode for the first time;
  // otherwise app will render in the background and performance will be bad;
  @observable
  hadAccessToApp: boolean;

  appStateListener!: NativeEventSubscription;

  @observable disposers: Array<() => void> = [];

  constructor(args: {}, data: PasscodeData) {
    super(args, data);

    this.hadAccessToApp = data.passcode ? false : true;

    this.protectors = {
      blur: false,
      passcode: Boolean(data.passcode),
    };

    this.appStateListener = AppState.addEventListener(
      'change',
      this.onAppStateChange
    );

    this.disposers = [
      () => {
        this.appStateListener.remove();
      },
      autorun(() => {
        if (this.previousAppState !== this.appState) {
          this.onStatusChange();
        }
      }),
    ];
  }

  statusResponders: Partial<Record<TAppStateTransition, () => void>> = {
    'active => inactive': () => {
      this.protectByBlur();
    },
    'inactive => active': () => {
      this.protectors.blur = false;
      this.resetLockTime();
    },
    'active => background': () => {
      this.protectByBlur();
      this.startPasscodeProtectedCountdown();
    },
    'inactive => background': () => {
      this.startPasscodeProtectedCountdown();
    },
    'background => active': () => {
      this.checkAndRemovePasscodeProtectionCountdown();
    },
  } as const;

  @action onStatusChange = () => {
    const responder: TAppStateTransition = `${this.previousAppState} => ${this.appState}`;

    this.statusResponders[responder]?.();
  };

  private onAppStateChange = (nextAppState: AppStateStatus) => {
    this.previousAppState = this.appState;
    this.appState = nextAppState;
  };

  @action protectByBlur = () => {
    if (!this.protectors.passcode) {
      this.protectors.blur = true;
    }
  };

  @action protectByPasscode = () => {
    this.protectors.blur = false;
    this.protectors.passcode = true;
  };

  @action startPasscodeProtectedCountdown = () => {
    if (!this.protectors.passcode) {
      this.setLockTime(Date.now() + this.passcodeSetupTimeout);
    }
  };

  @action checkAndRemovePasscodeProtectionCountdown = () => {
    if (!GLOBAL.isAuthorized || !this.data.passcode) {
      this.protectors.blur = false;
      this.protectors.passcode = false;
      this.resetLockTime();
      return;
    }

    if (!this.protectors.passcode) {
      if (this.lockAt <= Date.now()) {
        this.protectByPasscode();
        this.resetLockTime();
      } else {
        this.protectors.blur = false;
        this.resetLockTime();
      }
    } else {
      this.resetLockTime();
      this.protectors.blur = false;
    }
  };

  private setLockTime(value: number) {
    this.lockAt = value;
  }

  private resetLockTime() {
    this.lockAt = 0;
  }

  @computed get hasPasscode() {
    return Boolean(this.data.passcode);
  }

  @action setData = (passcode: string) => {
    SECURE_STORAGE.set(PASSCODE_KEY, passcode);
    this.data.passcode = passcode;
    this.hadAccessToApp = true;
    this.protectors.passcode = false;
    this.refetch();
  };

  @action clearData = () => {
    this.hadAccessToApp = true;
    this.protectors.blur = false;
    this.protectors.passcode = false;
    this.data.passcode = null;
    this.resetLockTime();
    SECURE_STORAGE.delete(EMAIL_KEY);
    SECURE_STORAGE.delete(PASSCODE_KEY);
    this.refetch();
  };

  @action unlockByPasscode = (passcode: string) => {
    if (passcode === this.data.passcode) {
      this._unlock();
      return true;
    }

    return false;
  };

  @action unlockByBiometrics = () => {
    this._unlock();
  };

  @action unlockByBiometricsFailed = () => {};

  @action _unlock() {
    this.resetLockTime();
    this.protectors.passcode = false;
    this.protectors.blur = false;
    if (!this.hadAccessToApp) {
      this.hadAccessToApp = true;
    }
  }

  onDestroy() {
    super.onDestroy();
    this.clearData();
    this.disposers.forEach(disposer => disposer());
  }
}
