import {serializable} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import Big from 'big.js';

export class LoanRegularIncrease {
  @serializable(bigNumber())
  maxAmount!: Big;

  @serializable(bigNumber())
  ltv!: Big;
}
