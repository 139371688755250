import * as React from 'react';
import {Helmet} from 'react-helmet';
import {cell} from '@youtoken/ui.cell';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {Box} from '@youtoken/ui.primitives';
import {AwaitConfirmDevice} from '@youtoken/ui.surface-await-confirm-device';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

export const DeviceConfirmPage: React.FC = cell(() => {
  const {push, queryParams} = RouterResource.use({});

  const {
    confirmDevice,
    signInConfirmSuccess,
    refreshOperation,
    data: {email},
  } = AuthMeResource.use({});

  const {t} = useTranslation();

  let getterTimeout: ReturnType<typeof setTimeout>;

  const getDeviceConfirmed = React.useCallback(() => {
    confirmDevice(queryParams.operationId)
      .then(payload => {
        signInConfirmSuccess(payload);
      })
      .catch(error => {
        if (error?.response?.data?.repeat) {
          getterTimeout = setTimeout(getDeviceConfirmed, 10000);
        }
        // NOTE: it should be exact false value of "repeat" param
        if (error?.response?.data?.repeat === false) {
          push('/sign-in');
        }
      });
  }, [queryParams.operationId, confirmDevice, signInConfirmSuccess]);

  React.useEffect(() => {
    getDeviceConfirmed();

    return () => clearTimeout(getterTimeout);
  }, []);

  const onResendEmail = React.useCallback(() => {
    return __GLOBAL_RECAPTCHA__.withToken('device_confirm_resend', token => {
      return refreshOperation({
        operationId: queryParams.operationId,
        token,
      }).catch(error => {
        LOCAL_NOTIFICATIONS.error({
          text: t('info_message.email_resend_failed'),
        });

        throw error;
      });
    });
  }, [queryParams.operationId]);

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
      />
    );
  }, []);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
      <OuterPageShell>
        <Helmet title={t('surface.device_confirm.tab_title')} />
        <Box alignItems="center">
          <AwaitConfirmDevice userEmail={email} onResendEmail={onResendEmail} />
        </Box>
      </OuterPageShell>
    </AppShell>
  );
});
