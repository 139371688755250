import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {AgreementsList, Switch} from '@youtoken/ui.elements';
import {privacyNoticeUnified} from '@youtoken/ui.resource-documents';
import {Box, BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {type SignUpForm} from '../state';

export const TermsField: React.FC<BoxProps> = cell(
  () => {
    const {
      form: {
        countryValue,
        agreeValue,
        onAgreeChange,
        hasAgreeError,
        agreeError,
      },
    } = useForm<SignUpForm>();

    React.useEffect(() => {
      if (!countryValue) {
        return;
      }

      onAgreeChange(false);
    }, [countryValue]);

    return (
      <>
        <TouchableBox
          flexDirection="row"
          justifyContent="space-between"
          testID="PRIVACY_CHECKBOX"
          onPress={() => onAgreeChange(!agreeValue)}
        >
          <AgreementsList items={[privacyNoticeUnified]} pr={12} />
          <Switch
            onValueChange={onAgreeChange}
            value={agreeValue}
            hasError={hasAgreeError}
          />
        </TouchableBox>

        <Box mt={4}>
          <FieldErrorMessage visible={hasAgreeError} placement="right">
            {getTranslatedValidationMessage(agreeError)}
          </FieldErrorMessage>
        </Box>
      </>
    );
  },
  {
    displayName: 'TermsField',
    CellWrap: boxProps => {
      return <Box minHeight={34} position="relative" {...boxProps}></Box>;
    },
  }
);
