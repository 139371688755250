import React from 'react';
import {i18n} from '@youtoken/ui.service-i18n';

export const useTabItems = (t: typeof i18n.t) => {
  return React.useMemo(() => {
    return [
      {
        type: 'text',
        label: t('surface.trusted_devices.tabs.devices'),
        value: 'devices',
      },
      {
        type: 'text',
        label: t('surface.trusted_devices.tabs.sessions'),
        value: 'sessions',
      },
    ] as Array<{type: 'text'; label: string; value: string}>;
  }, [t]);
};
