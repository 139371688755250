import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {ReactComponent as LogoSvg} from './assets/logo.svg';
import {ReactComponent as LogoDarkSvg} from './assets/logo-dark.svg';
import {useActiveThemeType} from '@youtoken/ui.primitives';

const LogoWrap = styled.div`
  height: 36px;
  width: 168px;
  margin-left: 8px;

  #LogoText {
    opacity: 1;
  }

  @media (max-width: 48em) {
    width: 38px;
    overflow: hidden;

    #LogoText {
      opacity: 0;
    }
  }
`;

export const Logo: React.FC = observer(() => {
  const themeType = useActiveThemeType();

  const Logo = themeType === 'dark' ? LogoDarkSvg : LogoSvg;

  return (
    <Link to="/">
      <LogoWrap>
        <Logo />
      </LogoWrap>
    </Link>
  );
});
