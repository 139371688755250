import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Heading, Text, useTheme} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface ContentItem {
  icon: React.ComponentProps<typeof Icon>['name'];
  title: string;
  text: string;
}

const Item: React.FC<ContentItem> = ({icon, title, text}) => {
  const {spacing} = useTheme();

  return (
    <Box
      flexDirection="row"
      flex={1}
      py={spacing['$screen-default-offset']}
      borderBottomWidth={1}
      borderBottomColor="$ui-02"
    >
      <Box mr={8}>
        <Icon name={icon} size={20} color="$interactive-01" />
      </Box>
      <Box flex={1}>
        <Box mb={8}>
          <Text variant="$body-01-medium-accent" color="$text-05">
            {title}
          </Text>
        </Box>
        <Text variant="$body-02">{text}</Text>
      </Box>
    </Box>
  );
};

export const Content: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();
  const {spacing} = useTheme();

  return (
    <Box {...props}>
      <Heading variant="$heading-02" mb={16}>
        {t('surface.profile.delete_account.review')}
      </Heading>
      <Item
        icon="wallets"
        title={t('surface.profile.delete_account.steps.withdraw')}
        text={t('surface.profile.delete_account.steps.withdraw.description')}
      />
      <Item
        icon="fiat_deposit"
        title={t('surface.profile.delete_account.steps.check_deposits')}
        text={t(
          'surface.profile.delete_account.steps.check_deposits.description'
        )}
      />
      <Item
        icon="trading"
        title={t('surface.profile.delete_account.steps.close_deals')}
        text={t('surface.profile.delete_account.steps.close_deals.description')}
      />

      <Box py={spacing['$screen-default-offset']}>
        <Text variant="$body-01" color="$text-02">
          {t('surface.profile.delete_account.will_contact')}
        </Text>
      </Box>
    </Box>
  );
});
