import big, {BigSource, RoundingMode} from 'big.js';

export const formatPercent = (
  value?: BigSource,
  keepZeros = false,
  rm?: RoundingMode
) => {
  if (!value) {
    return '0';
  }

  const trimmed = big(value).mul(100).toFixed(2, rm);

  return keepZeros ? trimmed : big(trimmed).toFixed();
};

export const formatPercentTillPrecision = (
  value?: BigSource,
  maxPrecision = 2,
  keepZeros = false,
  rm?: RoundingMode
) => {
  if (!value) {
    return '0';
  }

  const trimmed = big(value).mul(100).toFixed(maxPrecision, rm);

  return keepZeros ? trimmed : big(trimmed).toFixed();
};
