import * as React from 'react';
import {
  Box,
  Text,
  TouchableBox,
  TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {BaseIconName, Icon} from '@youtoken/ui.icons';

interface ActionButtonProps {
  title: string;
  icon?: BaseIconName;
  disabled?: boolean;
  onPress: TouchableBoxProps['onPress'];
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  title,
  icon,
  disabled,
  onPress,
  children,
}) => {
  return (
    <Box
      alignItems="center"
      opacity={disabled ? 0.4 : 1}
      flexGrow={1}
      flexShrink={0}
      flexBasis={0}
    >
      <TouchableBox alignItems="center" onPress={onPress} disabled={disabled}>
        <Box
          width={40}
          height={40}
          borderRadius={40}
          backgroundColor="$interactive-02"
          alignItems="center"
          justifyContent="center"
        >
          {icon && <Icon name={icon} color="$text-05" size={20} />}
          {children}
        </Box>
        <Text variant="$body-02" color="$text-05" textAlign="center" mt={8}>
          {title}
        </Text>
      </TouchableBox>
    </Box>
  );
};
