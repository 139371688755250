import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {PromoBanner} from '@youtoken/ui.elements';
import {HowItWorksModal} from './HowItWorksModal';

type HowItWorksBannerProps = {
  onClose?: () => void;
};

export const HowItWorksBanner: React.FC<HowItWorksBannerProps> = observer(
  ({onClose}) => {
    const {t} = useTranslation();

    const [isModalOpen, setModalOpen] = React.useState(false);

    const handleBannerPress = React.useCallback(() => {
      setModalOpen(true);
    }, [setModalOpen]);

    const onModalClose = React.useCallback(() => {
      setModalOpen(false);
    }, [setModalOpen]);

    return (
      <>
        <PromoBanner
          testID="HOW_IT_WORKS_BANNER"
          imageName="money"
          text={t('surface.miner.how_it_works.banner.text')}
          onPressBanner={handleBannerPress}
          onPressClose={onClose}
        />
        <HowItWorksModal
          title={t('surface.miner.how_it_works')}
          isOpen={isModalOpen}
          onClose={onModalClose}
        />
      </>
    );
  }
);
