import {startSpan} from '@sentry/core';

/** todo in future: get data with interaction manager */
export const wrapGetData =
  <Args extends {}, Result extends any>(
    getData: (args: Args) => Promise<Result>,
    _getKey: (args: Args) => string
  ) =>
  (args: Args) => {
    const key = _getKey(args);

    return startSpan(
      {
        name: `getData:${key}`,
        op: 'resource.getData',
        description: `getData for Resource`,
      },
      () => getData(args)
    );
  };
