import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CryptoDepositIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 5h6.5a1.5 1.5 0 010 3H9a1 1 0 00-1 1v6.5a1.5 1.5 0 01-3 0V9a4 4 0 014-4zM6.5 23a1.5 1.5 0 011.486 1.297L8 24.5V31a1 1 0 00.82.984L9 32h6.5a1.5 1.5 0 01.204 2.986L15.5 35H9a4 4 0 01-3.989-3.701L5 31v-6.5A1.5 1.5 0 016.5 23zm27 0a1.5 1.5 0 011.486 1.297L35 24.5V31a4 4 0 01-3.701 3.989L31 35h-6.5a1.5 1.5 0 01-.203-2.986L24.5 32H31a1 1 0 00.984-.82L32 31v-6.5a1.5 1.5 0 011.5-1.5zM17 21a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2a2 2 0 012-2h2zm0-10a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4a2 2 0 012-2h4zm8 2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2a2 2 0 012-2h2zm6-8a4 4 0 014 4v6.5a1.5 1.5 0 01-3 0V9a1 1 0 00-1-1h-6.5a1.5 1.5 0 010-3H31zm-4 16a2 2 0 012 2v4a2 2 0 01-2 2h-4a2 2 0 01-2-2v-4a2 2 0 012-2h4zm-1 2h-2a1 1 0 00-.984.82L23 24v2a1 1 0 00.82.984L24 27h2a1 1 0 00.984-.82L27 26v-2a1 1 0 00-.82-.984L26 23z"
          fill={color}
        />
      </Svg>
    );
  }
);
