import React from 'react';
import {createBox} from '../Box';
import {TouchableOpacity} from 'react-native';

export const TouchableBox = createBox(TouchableOpacity);

TouchableBox.defaultProps = {
  activeOpacity: 0.8,
};

export type TouchableBoxProps = React.ComponentProps<typeof TouchableBox>;
