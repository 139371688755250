import * as React from 'react';
import {Helmet} from 'react-helmet';
import {cell} from '@youtoken/ui.cell';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {VerificationStepsPageContent} from './elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const VerificationStepsPage = cell(() => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.profile.verification_steps.tab_title')} />
      <VerificationStepsPageContent />
    </InnerPageShell>
  );
});
