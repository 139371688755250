import * as React from 'react';
import {Text} from 'react-native';
import {createRestyleText, type TextProps} from './createRestyledText';
import {TextStyleContext, useMergedTextStyle} from './TextStyleContext';

export function createText<C extends React.ComponentType<any>>(Component: C) {
  const StyledText = createRestyleText(
    Component
  ) as React.ExoticComponent<TextProps>;

  return React.forwardRef<
    typeof Component,
    React.PropsWithChildren<
      TextProps & Omit<React.ComponentProps<C>, keyof TextProps>
    >
  >((props, ref) => {
    const {children, weight, color, variant, ...restProps} = props;

    const mergedProps = useMergedTextStyle(props);

    return (
      <TextStyleContext.Provider value={mergedProps}>
        <StyledText
          ref={ref}
          accessible
          accessibilityRole="text"
          {...mergedProps}
          {...restProps}
        >
          {children}
        </StyledText>
      </TextStyleContext.Provider>
    );
  });
}
