import * as React from 'react';
import {TouchableBox, type TouchableBoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const CloseRightPart: React.FC<TouchableBoxProps> = ({onPress}) => {
  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      height="100%"
      px={16}
      onPress={onPress}
    >
      <Icon name="close" size={20} color="$text-01" />
    </TouchableBox>
  );
};
