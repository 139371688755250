import * as React from 'react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {StoryProps} from '../interfaces';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {observer} from 'mobx-react';
import {Story} from './Story';

export const StorySmartContainer: React.FC<
  Omit<StoryProps, 'stories'> & BoxProps
> = observer(({...props}) => {
  const {storiesSorted, markAsSeen, markAsShown} = InstaStoriesResource.use({});

  const handleStoryShown = React.useCallback(
    (id: string, slide: number) => {
      markAsShown(id, slide);
    },
    [markAsShown]
  );

  const handleStorySeen = React.useCallback(
    (slug: string) => {
      markAsSeen(slug);
    },
    [markAsSeen]
  );

  return (
    <Story
      stories={storiesSorted}
      onStorySeen={handleStorySeen}
      onStoryShown={handleStoryShown}
      {...props}
    />
  );
});
