import {type SortingTabSize} from './types';

export const getTabHeightBySize = (size: SortingTabSize) => {
  switch (size) {
    case 'small':
      return 28;
    case 'medium':
    default:
      return 32;
  }
};

export const getArrowColor = ({
  position,
  direction,
  isActive,
}: {
  position: 'first' | 'second';
  direction: 'asc' | 'desc';
  isActive: boolean;
}) => {
  const baseColor = isActive ? '$interactive-04' : '$text-03';
  const accentColor = isActive ? '$interactive-01' : '$text-02';

  const isAccented =
    (position === 'first' && direction === 'desc') ||
    (position === 'second' && direction === 'asc');

  return isAccented ? accentColor : baseColor;
};
