import * as React from 'react';
import {observer} from 'mobx-react';
import {Error, Label, useForm} from '../../../../../components';
import {FeatureLoanFTPForm} from '../../state';
import {Amount} from './Amount';
import {Ticker as TickerField} from './Ticker';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

export const Ticker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {instance},
  } = useForm<FeatureLoanFTPForm>();

  return (
    <Box zIndex={20} width="100%" {...props}>
      <Label>{t('surface.loans.ftp_form.amount')}</Label>
      <Box position="relative">
        <Amount />
        <TickerField />
      </Box>
      <Error errors={instance.errors()} name={['amount']} />
    </Box>
  );
});
