import {observable, action, runInAction} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {addDays, format} from '@youtoken/ui.date-fns';

export class Calculated {
  @observable
  public form: any;

  @observable
  public loading: boolean = false;

  @observable
  public ready: boolean = false;

  @observable
  public data: any = undefined;

  @action
  public load = () => {
    this.loading = true;
    this.ready = false;

    const data = {
      ...this.form.instance.values(),
    };

    TRANSPORT.API.post('/v1/loan/turbo/calculate', {
      mode: 'turbo',
      collateralTicker: data.collateralTicker,
      borrowedTicker: data.borrowedTicker,
      collateralAmount: data.collateralAmount,
      borrowedAmount: data.borrowedAmount,
      initialPrice: data.initialPrice,
      tariffId: data.term.id,
      turbo: data.leverage,
    })
      .then(response => {
        runInAction(() => {
          const responseData = response.data;

          this.data = {
            collateralTicker: data.collateralTicker,
            borrowedTicker: data.borrowedTicker,
            collateralAmount: responseData.collateralAmount,
            borrowedAmount: responseData.borrowedAmount,
            // term: data.term,
            borrowedTail: responseData.tail,
            overdraftAmount: responseData.overdraftAmount,
            ltv: data.term.ltv,
            pdl: data.term.marginCall,
            days: data.term.days,
            repayUntil: format(
              addDays(Date.now(), data.term.days),
              'dd MMM yyyy, HH:mm'
            ),
            loans: responseData.loans.map((loan: any) => {
              return {
                collateralTicker: data.collateralTicker,
                borrowedTicker: data.borrowedTicker,
                collateralAmount: loan.collateralAmount,
                borrowedAmount: loan.borrowedAmount,
                interest: loan.interest,
              };
            }),
          };

          this.loading = false;
          this.ready = true;
        });
      })
      .catch(e => {
        this.loading = false;

        return e;
      });
  };

  public constructor(form: any) {
    this.form = form;
  }
}
