import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const HeartBrokenIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M4 3.5l-.31-.683L4 3.5zm6 15l-.405.63a.75.75 0 00.811 0L10 18.5zM9 8l-.735-.147a.75.75 0 00.44.836L9 8zm7-4.5l.31-.683L16 3.5zm-3.5 6l.48.576a.75.75 0 00-.184-1.266l-.296.69zm-3 2.5l-.48-.576a.75.75 0 00-.144.992L9.5 12zm.376 1.916a.75.75 0 101.248-.832l-1.248.832zm-2.263-9.46a.75.75 0 10.775-1.285l-.775 1.284zM3.69 2.816C2.12 3.531 1.194 4.684.9 6.114c-.288 1.386.038 2.93.73 4.446 1.386 3.037 4.375 6.263 7.964 8.57l.811-1.262c-3.411-2.193-6.172-5.217-7.41-7.93-.62-1.359-.826-2.564-.628-3.522.189-.914.763-1.698 1.943-2.234l-.62-1.366zm6.046 5.33c.214-1.07.998-2.436 2.122-3.341 1.1-.885 2.425-1.263 3.832-.623l.62-1.366c-2.059-.936-3.983-.314-5.392.82C9.533 4.752 8.55 6.422 8.265 7.853l1.47.294zm5.954-3.964c1.18.536 1.754 1.32 1.943 2.234.198.958-.007 2.163-.627 3.521-1.239 2.714-4 5.738-7.411 7.931l.811 1.262c3.589-2.307 6.578-5.533 7.964-8.57.693-1.517 1.019-3.061.732-4.447-.295-1.43-1.22-2.583-2.791-3.297l-.62 1.366zM8.705 8.689l3.5 1.5.59-1.379-3.5-1.5-.59 1.38zm3.315.235l-3 2.5.96 1.152 3-2.5-.96-1.152zm-3.144 3.492l1 1.5 1.248-.832-1-1.5-1.248.832zM8.388 3.17c-1.305-.787-2.965-1.142-4.698-.354l.62 1.366c1.182-.537 2.316-.323 3.303.272l.775-1.284z"
          fill={color}
        />
      </Svg>
    );
  }
);
