import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {type AccountLevelProgressBarDetailedPerksProps} from '../types';
import {AccountLevelProgressBarDetailedPerksInfoItemProgressPoints} from './ItemProgressPoints';
import {AccountLevelProgressBarDetailedPerksInfoItemSparks} from './ItemSparks';

export const AccountLevelProgressBarDetailedTradePerksInfo: React.FC<
  AccountLevelProgressBarDetailedPerksProps & BoxProps
> = observer(({showSparks = false, variant, level, ...boxProps}) => {
  return (
    <Box
      testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_TRADE_PERKS_INFO"
      flexDirection="row"
      gap={12}
      {...boxProps}
    >
      <AccountLevelProgressBarDetailedPerksInfoItemProgressPoints
        variant={variant}
        level={level}
      />
      {showSparks && (
        <AccountLevelProgressBarDetailedPerksInfoItemSparks
          variant={variant}
          level={level}
        />
      )}
    </Box>
  );
});
