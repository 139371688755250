import * as React from 'react';
import {BoxProps} from '@youtoken/ui.primitives';
import {IProgressBarProps} from '../types';

import {Wrapper} from './Wrapper';
import {Progress} from './Progress';

export const ProgressBar: React.FC<IProgressBarProps & BoxProps> = ({
  size = 'tiny',
  color = 'interactive',
  progress = 0,
  isIndeterminate = false,
  text,
}) => {
  return (
    <Wrapper color={color} size={size} isIndeterminate={isIndeterminate}>
      <Progress
        size={size}
        color={color}
        isIndeterminate={isIndeterminate}
        progress={progress}
        text={text}
      />
    </Wrapper>
  );
};
