import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {createWizard} from '@youtoken/ui.elements';
import {getCardDepositWizardOptions} from './utils';
import type {
  CardDepositFormArgs,
  CardDepositMethods,
} from './CardDepositSurface/state/types';
import {CardDepositSurface} from './CardDepositSurface';
import {Providers} from '../../components';
import {CardDepositPaymentMethods} from './CardDepositPaymentMethods';
import {CardDepositAddCard} from './CardDepositAddCard';
import {useTranslation} from '@youtoken/ui.service-i18n';

export {type CardDepositMethods} from './CardDepositSurface/state/types';

export enum CardDepositWizardScreenName {
  MAIN = 'main',
  PAYMENT_METHODS = 'paymentMethods',
  EDIT_CARD = 'editCard',
  ADD_CARD = 'addCard',
}

export interface CardDepositWizardNavigator {
  [CardDepositWizardScreenName.MAIN]: CardDepositFormArgs;
  [CardDepositWizardScreenName.PAYMENT_METHODS]: {};
  [CardDepositWizardScreenName.ADD_CARD]: {};
  [CardDepositWizardScreenName.EDIT_CARD]: {};
}

const Wizard = createWizard<CardDepositWizardNavigator>();

export interface CardDepositWizardProps {
  ticker: string;
  provider: Providers;
  method: CardDepositMethods;
  onPressBack?: () => void;
}

export const CardDepositWizard: React.FC<CardDepositWizardProps> = cell(
  ({ticker, provider, method, onPressBack}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName={CardDepositWizardScreenName.MAIN}
        initialProps={{
          ticker,
          provider,
          method,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={CardDepositWizardScreenName.MAIN}
          component={CardDepositSurface}
          options={getCardDepositWizardOptions}
        />
        <Wizard.Screen
          name={CardDepositWizardScreenName.PAYMENT_METHODS}
          options={{title: t('surface.wallets.payment_methods')}}
          component={CardDepositPaymentMethods}
        />
        <Wizard.Screen
          name={CardDepositWizardScreenName.ADD_CARD}
          options={{title: t('surface.wallets.add_card')}}
          component={CardDepositAddCard}
        />
      </Wizard.Navigator>
    );
  }
);
