import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {createBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

const TouchableIcon = createBox(TouchableOpacity);

export const SecureModeSwitcher: React.FC<{
  onPress?: () => void;
  secureModeEnabled?: boolean;
  hasError?: boolean;
  focused?: boolean;
}> = ({onPress, secureModeEnabled = true}) => {
  return (
    <TouchableIcon
      height="100%"
      onPress={onPress}
      justifyContent="center"
      alignItems="center"
      activeOpacity={0.6}
      px={16}
      accessibilityRole="button"
      accessible
      accessibilityHint="Toggle secure mode"
    >
      <Icon
        accessible={false}
        name={secureModeEnabled ? 'hide' : 'show'}
        color={'$text-01'}
      />
    </TouchableIcon>
  );
};
