import * as React from 'react';
import {Route, type RouteComponentProps} from 'react-router-dom';
import {cell} from '@youtoken/ui.cell';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {
  SignUpPage,
  SignInPage,
  SignInConfirmPage,
  DeviceConfirmPage,
  PasswordRestoreRequestPage,
  NewPasswordPage,
  NewDeviceResultPage,
  VerifyEmailPage,
  ConsentFinalScreen,
  AppleAuthRedirectPage,
} from '@web-app/src/components/pages';
import {DepositStatus} from '@youtoken/ui.surface-deposit-status';
import {RootRedirect} from './RootRedirect';
import {alreadyAuthorized} from './alreadyAuthorized';
import {RouterResource} from './RouterResource';
import {AppRoutes} from './AppRoutes';
import {AppLedgerUnauthorizedRoutes} from './LedgerLiveRoutes/AppLedgerUnauthorizedRoutes';

const SignInWrapped = alreadyAuthorized(SignInPage);
const SignInConfirmWrapped = alreadyAuthorized(SignInConfirmPage);
const DeviceConfirmWrapped = alreadyAuthorized(DeviceConfirmPage);
const SignUpWrapped = alreadyAuthorized(SignUpPage);
const PasswordRestoreRequestWrapped = alreadyAuthorized(
  PasswordRestoreRequestPage
);
const NewPasswordWrapped = alreadyAuthorized(NewPasswordPage);
const VerifyEmailPageWrapped = alreadyAuthorized(VerifyEmailPage);

const RouteSyncer: React.FC<RouteComponentProps> = cell(props => {
  const routerResource = RouterResource.use({});
  routerResource.setRouterProps(props);

  return null;
});

const DeviceConfirmResultAllowed = alreadyAuthorized(() => (
  <NewDeviceResultPage status="allow" />
));
const DeviceConfirmResultRejected = alreadyAuthorized(() => (
  <NewDeviceResultPage status="decline" />
));
const DeviceConfirmResultError = alreadyAuthorized(() => (
  <NewDeviceResultPage status="error" />
));

const DepositStatusSuccess = () => <DepositStatus status="success" />;
const DepositStatusProcessing = () => <DepositStatus status="processing" />;
const DepositStatusCanceled = () => <DepositStatus status="canceled" />;
const DepositStatusFailed = () => <DepositStatus status="failed" />;

/** Application routes */
const Routes: React.FC = () => {
  return (
    <>
      <Route path="*" component={RouteSyncer} />
      <SwitchWithNotFound>
        {/* Main route redirect */}
        <Route exact path="/" component={RootRedirect} />
        {/* Outer pages, for sign-in, restore password, etc */}
        <Route path="/welcome" component={AppLedgerUnauthorizedRoutes} />
        <Route exact path="/sign-in" component={SignInWrapped} />
        <Route exact path="/sign-in-confirm" component={SignInConfirmWrapped} />
        <Route exact path="/device-confirm" component={DeviceConfirmWrapped} />
        <Route
          exact
          path="/new-device-allowed"
          component={DeviceConfirmResultAllowed}
        />
        <Route
          exact
          path="/new-device-rejected"
          component={DeviceConfirmResultRejected}
        />
        <Route
          exact
          path="/new-device-error"
          component={DeviceConfirmResultError}
        />
        <Route exact path="/sign-up" component={SignUpWrapped} />
        <Route exact path="/verify-email" component={VerifyEmailPageWrapped} />
        <Route
          exact
          path="/restore-password"
          component={PasswordRestoreRequestWrapped}
        />
        <Route exact path="/new-password" component={NewPasswordWrapped} />

        {/* Unlimint deposit status pages */}
        <Route exact path="/deposit/success" component={DepositStatusSuccess} />
        <Route exact path="/deposit/fail" component={DepositStatusFailed} />
        <Route exact path="/deposit/cancel" component={DepositStatusCanceled} />
        <Route
          exact
          path="/deposit/processing"
          component={DepositStatusProcessing}
        />

        {/* Intergiro status pages for bank cards */}
        <Route
          exact
          path="/wallets/cards/consent-final"
          component={ConsentFinalScreen}
        />

        {/* Redirect for Apple sign up */}
        <Route exact path="/apple/callback" component={AppleAuthRedirectPage} />

        {/* Actual application pages */}
        <Route path="*" component={AppRoutes} />
      </SwitchWithNotFound>
    </>
  );
};

export default Routes;
