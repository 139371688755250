import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowAprTotalProps {
  item: LoanRegularV2;
}

export const LoanItemDataTableRowAprTotal: React.FC<LoanItemDataTableRowAprTotalProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_APR_TOTAL"
        left={t('surface.loans.item.table.apr_total')}
        right={
          <Text
            testID="LOAN_ITEM_TABLE_ROW_APR_TOTAL_VALUE"
            variant="$body-02"
            color="$text-02"
          >
            <Trans
              t={t}
              i18nKey="surface.loans.item.table.apr_total_value"
              components={{
                Strong: (
                  <Text variant="$body-02-high-accent" color="$text-01" />
                ),
              }}
              values={{
                amount: item.totalSettlementFeeAmountFormatted,
                ticker: item.borrowedTickerFormatted,
                count: item.days,
              }}
            />
          </Text>
        }
      />
    );
  });
