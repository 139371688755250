import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
  runInAction,
} from 'mobx';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {deserialize} from 'serializr';
import {nanoid} from 'nanoid';
import Big from 'big.js';
import {getCoinDecimalPrecision, getCoinName} from '@youtoken/ui.coin-utils';
import {ValidationMessageLocalized} from '@youtoken/ui.validation-messages';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {DocumentItem} from '@youtoken/ui.resource-documents';
import {TRANSPORT} from '@youtoken/ui.transport';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {getAmountListFormatted} from '@youtoken/ui.hodls-utils';
import type {ExtendTpSlFormArgs, ExtendTpSlFormResources} from './types';
import {CalculateExtendResult} from './CalculateExtendResult';
import {invariant} from '@youtoken/ui.utils';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  public resources: ExtendTpSlFormResources;

  @observable
  public requestId: string = '';

  @observable
  public isLoading: boolean = false;

  @observable
  public calculatedRawData: null | CalculateExtendResult = null;

  @observable
  rateInputTickerUsd!: Big;

  disposers: Array<IReactionDisposer>;

  public constructor(
    resources: ExtendTpSlFormResources,
    {hodlId, onSuccess}: ExtendTpSlFormArgs
  ) {
    const {
      collateralTicker: hodlInputTicker,
      _inputAmount: inputAmountBig,
      inputAmount: hodlInputAmount,
    } = resources.hodl.data;

    const inputTickerWalletAmount =
      resources.walletsResource.getByTicker(hodlInputTicker)?.amount ?? 0;

    this.resources = resources;
    this.requestId = nanoid();

    const fields = {
      inputTicker: {
        value: hodlInputTicker,
      },
      inputAmount: {
        value: inputAmountBig.gt(inputTickerWalletAmount)
          ? formatByTicker(inputTickerWalletAmount, hodlInputTicker)
          : hodlInputAmount,
      },
      id: {
        value: hodlId,
      },
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        const data = form.values();
        this.isLoading = true;

        DATA_LAYER.trackStrict('hodl-add-collateral-attempt', {
          hodlId: data.id,
          inputTicker: data.inputTicker,
          inputAmount: data.inputAmount,
        });

        return TRANSPORT.API.post('/v3/hodl/addCollateral', {
          id: data.id,
          amount: data.inputAmount,
          ticker: data.inputTicker,
          requestId: this.requestId,
        })
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.hodls.extend_tp.message.extended'),
            });

            onSuccess();
          })
          .catch(e => {
            handleFormSubmitError(form, e, {});
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              id: yup.string().required(),
              inputTicker: yup.string().required(),
              inputAmount: yup
                .big()
                .required()
                .lte(this.inputTickerWallet.amount)
                .gt(0),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm(
      {fields},
      {
        options,
        plugins,
        hooks,
      }
    );

    this.disposers = [
      reaction(
        () => this.inputAmount,
        () => {
          TRANSPORT.API.post('/v3/hodl/calculateExtend', {
            id: this.hodlId,
            amount: this.inputAmount,
            ticker: this.inputTicker,
          })
            .then(res => {
              runInAction(() => {
                this.calculatedRawData = deserialize(
                  CalculateExtendResult,
                  res.data
                );
              });
            })
            .catch(() => {
              return;
            });
        },
        {
          fireImmediately: true,
          delay: 300,
        }
      ),

      // Update rateInputTickerUsd after inputTicker changed
      reaction(
        () => this.inputTicker,
        () => {
          this.rateInputTickerUsd = Big(
            this.resources.rates.getRate('usd', this.inputTicker)
          );
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }

  @computed
  public get inputAmount() {
    return this.instance?.$('inputAmount').get('value');
  }

  @computed
  public get inputTicker() {
    return this.instance?.$('inputTicker').get('value');
  }

  @computed get amountListFormatted() {
    return getAmountListFormatted(this.inputTicker, this.rateInputTickerUsd);
  }

  @computed
  public get hodlId() {
    return this.instance?.$('id').get('value');
  }

  @computed.struct get inputTickerWallet() {
    if (!this.inputTicker) {
      return {};
    }

    const wallet = this.resources.walletsResource.getByTicker(
      this.inputTicker
    )!;

    invariant(
      wallet,
      'cannot get wallet by input ticker',
      {},
      {wallet, inputTicker: this.inputTicker}
    );

    return {
      ticker: wallet.ticker,
      tickerFormatted: wallet.ticker.toUpperCase(),
      amountFormatted: wallet.amount.toFixed(
        getCoinDecimalPrecision(wallet.ticker)
      ),
      amount: wallet.amount,
      coinName: getCoinName(wallet.ticker),
      hasAmount: wallet.amount?.gt(0),
      key: wallet.ticker,
    };
  }

  @computed
  public get maxInputAmount() {
    const precision = getCoinDecimalPrecision(this.inputTickerWallet.ticker);
    return Number(this.inputTickerWallet?.amount?.toFixed(precision));
  }

  @computed get inputAmountError(): ValidationMessageLocalized {
    return this.instance.$('inputAmount').get('error');
  }

  @computed get agreementItem(): DocumentItem[] {
    const documentItem = (
      this.resources.docs.documents.youhodlerDocuments ||
      this.resources.docs.documents.youhodlerDocumentsFull
    ).find(el => el.name === 'Specific Terms for Lending Services');

    return documentItem ? [documentItem] : [];
  }

  @computed get isHodlExpired() {
    return (
      this.resources.hodl.data.isClosing || this.resources.hodl.data.isClosed
    );
  }

  @action setInputTicker = (value: string) => {
    this.instance.$('inputTicker').set('value', value);
  };

  @action setInputAmount = (value: string) => {
    const normalizedValue = normalizeAmountWithPrecision(
      value,
      getCoinDecimalPrecision(this.inputTicker)
    );

    this.instance.$('inputAmount').set('value', normalizedValue);
  };

  @action setMaxAmount = () => {
    this.setInputAmount(this.inputTickerWallet?.amount?.toString() ?? '0');
  };

  @action
  public submitForm = () => {
    this.instance.submit();
  };
}
