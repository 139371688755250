import * as React from 'react';
import {Platform} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell} from '@youtoken/ui.cell';
import {AdvCashCreatePaymentFormState} from './state';
import {Header} from '../../Header';
import {Content, Footer} from './components';
import type {IAdvCashData} from '../../types';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export interface AdvCashCreatePaymentFormProps {
  ticker: string;
  amount?: string;
  onSubmit?: (data: IAdvCashData) => void;
  onPressBack: () => void;
}

export const AdvCashCreatePaymentForm: React.FC<
  AdvCashCreatePaymentFormProps & BoxProps
> = cell(({ticker, amount, onSubmit, onPressBack, ...boxProps}) => {
  const {residenceOrCountry} = AuthMeResource.use({});

  const resources = useResources({
    docs: getResourceDescriptor(DocsResource, {
      country: residenceOrCountry,
    }),
    feeAll: getResourceDescriptor(FeeAllResource, {}),
  });

  const state = makeForm(
    () =>
      new AdvCashCreatePaymentFormState(
        {
          ticker,
          amount,
          onSubmit,
        },
        resources
      )
  );

  React.useEffect(() => {
    DATA_LAYER.trackStrict('deposit-fiat-attempt', {
      type: 'fiat',
      category: 'deposit',
      provider: 'advcash',
      ticker,
    });
  }, [ticker]);

  return (
    <Form form={state}>
      <Box flex={1} {...boxProps}>
        {showHeader && <Header ticker={ticker} onPressBack={onPressBack} />}
        <Content />
        <Separator />
        <Footer />
      </Box>
    </Form>
  );
});
