import React from 'react';
import {createGlobalStyle} from 'styled-components';
import {useTheme} from '@youtoken/ui.primitives';

/** overrides some default browser styles */
export const _Controls = createGlobalStyle<{
  colors: ReturnType<typeof useTheme>['colors'];
}>`
  button {
    border: none;
  }

  button, input, textarea, label{
    &:focus {
      outline: none;
    }
  }

  a {
    text-decoration: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 60px ${props =>
        props.colors['$ui-01']} inset !important;
      background-color: ${props => props.colors['$ui-01']} !important;
      background-clip: content-box !important;
      color: ${props => props.colors['$text-01']} !important;
      -webkit-text-fill-color: ${props => props.colors['$text-01']};
  }
  
  ::placeholder {
    color: ${props => props.colors['$text-03']} !important;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: ${props => props.colors['$text-03']} !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: ${props => props.colors['$text-03']} !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: ${props => props.colors['$text-03']} !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: ${props => props.colors['$text-03']} !important;
  }
`;

export const Controls = () => {
  const theme = useTheme();

  return <_Controls colors={theme.colors} />;
};
