import * as React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {StatusContent, WentWrongContent} from './components';
import {ConfirmationStatus} from './types';

export interface DeviceConfirmResultProps extends BoxProps {
  status: ConfirmationStatus;
}

export const DeviceConfirmResult: React.FC<DeviceConfirmResultProps> = ({
  status,
}) => {
  return (
    <Box
      borderRadius={10}
      bg="$ui-background"
      width="100%"
      maxWidth={700}
      px={{smallPhone: 16, phone: 24}}
    >
      {status === 'error' ? (
        <WentWrongContent />
      ) : (
        <StatusContent status={status} />
      )}
    </Box>
  );
};
