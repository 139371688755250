import {action, computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';

import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {
  type SavingsSignAgreementsFormProps,
  type SavingsSignAgreementsFormResources,
} from '../types';

export class Form {
  @observable
  args: SavingsSignAgreementsFormProps;

  @observable
  resources: SavingsSignAgreementsFormResources;

  @observable
  instance: MobxReactForm;

  //#region agree

  @computed
  get agreements() {
    return this.resources.docs.documents.savings3;
  }

  @computed
  get agree() {
    return this.instance?.$('agree').get('value') ?? false;
  }

  @computed
  get agreeError() {
    return getTranslatedValidationMessage(
      this.instance.$('agree').get('error')
    );
  }

  @computed
  get agreeHasError() {
    return Boolean(this.agreeError);
  }

  @action
  agreeOnChange = (value: boolean) => {
    return this.instance.$('agree').get('onChange')(value);
  };

  //#endregion agree

  @action
  submit = () => {
    return this.resources.authMe
      .signSavings(this.agree)
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.forms.sign_savings_agreements.message.signed'),
        });

        this.args.onClose?.();
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  };

  constructor(
    args: SavingsSignAgreementsFormProps,
    resources: SavingsSignAgreementsFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          agree: {
            value: this.agree,
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
          onError: () => {
            LOCAL_NOTIFICATIONS.error({
              text: i18n.t('validation.VALIDATION'),
            });
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let agreeSchema = yup
                  .agreeToTerms()
                  .required(messages.AGREE_TO_TERMS);

                const schema = {
                  agree: agreeSchema,
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
