import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useIsMobile} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const BuyCryptoButton = () => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('DepositWizard', {source: 'TOP_BAR'});
  }, []);

  return (
    <Button
      icon="fiat_deposit"
      size="small"
      onPress={handlePress}
      type="secondary"
      ml={8}
      testID="BUY_CRYPTO_BUTTON"
    >
      {!isMobile && t('surface.wallets.common.deposit_button')}
    </Button>
  );
};
