import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation, Trans} from '@youtoken/ui.service-i18n';
import {type TextProps, Text} from '@youtoken/ui.primitives';
import {type TimerProps, Timer} from '@youtoken/ui.elements';

export interface SavingsPayoutInProps {
  timerProps: TimerProps;
}

export const SavingsPayoutIn: React.FC<SavingsPayoutInProps & TextProps> =
  observer(({timerProps, ...textProps}) => {
    const {t} = useTranslation();

    return (
      <Text testID="SAVINGS_PAYOUT_IN" variant="$body-01" {...textProps}>
        <Trans
          t={t}
          i18nKey="surface.savings.payout_in"
          components={{
            Timer: <Timer variant="$body-01-medium-accent" {...timerProps} />,
          }}
        />
      </Text>
    );
  });
