import {
  en,
  fr,
  it,
  es,
  de,
  ro,
  pt,
  countries_en,
  countries_fr,
  countries_it,
  countries_es,
  countries_de,
  countries_ro,
  countries_pt,
} from '@youtoken/ui.lokalise-export';

export const defaultNS = 'translations' as const;

type TLanguage = {
  translations: {
    [key in keyof typeof en]: string;
  };
  countries: {
    [key in keyof typeof countries_en]: string;
  };
};

export const resources = {
  en: {
    translations: en,
    countries: countries_en,
  },
  fr: {
    translations: fr,
    countries: countries_fr,
  },
  it: {
    translations: it,
    countries: countries_it,
  },
  es: {
    translations: es,
    countries: countries_es,
  },
  de: {
    translations: de,
    countries: countries_de,
  },
  ro: {
    translations: ro,
    countries: countries_ro,
  },
  pt: {
    translations: pt,
    countries: countries_pt,
  },
} as unknown as Record<
  'en' | 'fr' | 'it' | 'es' | 'de' | 'ro' | 'pt',
  TLanguage
>;
// without the explicit type annotation the following error is thrown:
// The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof defaultNS;
    resources: (typeof resources)['en'];
  }
}
