export const iconSet = {
  'level-1-grayscale': require('./level-1-grayscale.png'),
  'level-1': require('./level-1.png'),
  'level-2-grayscale': require('./level-2-grayscale.png'),
  'level-2': require('./level-2.png'),
  'level-3-grayscale': require('./level-3-grayscale.png'),
  'level-3': require('./level-3.png'),
  'level-4-grayscale': require('./level-4-grayscale.png'),
  'level-4': require('./level-4.png'),
  'level-5-grayscale': require('./level-5-grayscale.png'),
  'level-5': require('./level-5.png'),
  'level-6-grayscale': require('./level-6-grayscale.png'),
  'level-6': require('./level-6.png'),
  'level-7-grayscale': require('./level-7-grayscale.png'),
  'level-7': require('./level-7.png'),
  'level-8-grayscale': require('./level-8-grayscale.png'),
  'level-8': require('./level-8.png'),
};

export type IconName = keyof typeof iconSet;
