import * as React from 'react';
import {observer} from 'mobx-react';
import {
  type ChartingLibraryFeatureset,
  type ChartingLibraryWidgetOptions,
  type IChartingLibraryWidget,
  type IPositionLineAdapter,
  type LanguageCode,
  type ResolutionString,
  type ThemeName,
  type Timezone,
  widget,
} from '../charting_library';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import capitalize from 'lodash/capitalize';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import type {MultiHODLScreenName, tvPositionLineParams} from './types';

export type {tvPositionLineParams, MultiHODLScreenName};

const TV_STATE_KEY = 'TVChartState';
const TV_INTERVAL_KEY = 'TVChartInterval';

const saveTVChartStateParams = (params: {}) => {
  SIMPLE_STORAGE.set(TV_STATE_KEY, JSON.stringify(params));
};

const getTVChartStateParams = () => {
  const params = SIMPLE_STORAGE.get(TV_STATE_KEY);

  try {
    return JSON.parse(params as string);
  } catch (e) {
    return undefined;
  }
};

const saveTVChartInterval = (interval: string) => {
  SIMPLE_STORAGE.set(TV_INTERVAL_KEY, interval);
};

const getTVChartInterval = (): ResolutionString => {
  return (SIMPLE_STORAGE.get(TV_INTERVAL_KEY) || '15') as ResolutionString;
};

const getTVChartTimeframe = (interval: ResolutionString): string => {
  // Possible intervals are here: const tvChartResolutions from components/trading-view-charts/src/data/TradingViewChartData.ts
  // A relative timeframe is a number with a letter H for hours, D for days and M for months
  switch (interval) {
    case '1':
      return '1H';
    case '5':
      return '12H';
    case '15':
      return '24H';
    case '30':
    case '60':
      return '2D';
    case '240':
    default:
      return '14D';
  }
};

export interface TVChartContainerProps extends BoxProps {
  datafeed: ChartingLibraryWidgetOptions['datafeed'];
  symbol: ChartingLibraryWidgetOptions['symbol'];
  initialPriceLine?: tvPositionLineParams;
  takeProfitLine?: tvPositionLineParams;
  marginCallLine?: tvPositionLineParams;
  triggerPriceLine?: tvPositionLineParams;
  fullscreen?: ChartingLibraryWidgetOptions['fullscreen'];
  autosize?: ChartingLibraryWidgetOptions['autosize'];
  theme?: 'light' | 'dark';
  locale?: string;
  isMobileVersion?: boolean;
}

export const TVChartContainer: React.FC<TVChartContainerProps> = observer(
  ({
    datafeed,
    symbol,
    initialPriceLine,
    takeProfitLine,
    marginCallLine,
    triggerPriceLine,
    fullscreen = false,
    autosize = true,
    theme,
    locale,
    isMobileVersion = false,
    ...boxProps
  }) => {
    const chartContainerRef =
      React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const takeProfitChartLine = React.useRef<IPositionLineAdapter | null>(null);
    const marginCallChartLine = React.useRef<IPositionLineAdapter | null>(null);
    const initialPriceChartLine = React.useRef<IPositionLineAdapter | null>(
      null
    );
    const triggerPriceChartLine = React.useRef<IPositionLineAdapter | null>(
      null
    );

    const tvWidget = React.useRef<IChartingLibraryWidget | null>(null);

    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const defaultInterval = getTVChartInterval();
    const defaultTimeframe = getTVChartTimeframe(defaultInterval);

    const disabled_features_mobile: ChartingLibraryFeatureset[] = [
      'header_fullscreen_button',
    ];

    React.useEffect(() => {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        container: chartContainerRef.current,
        datafeed: datafeed,
        interval: defaultInterval,
        symbol: symbol,
        autosize: autosize,
        disabled_features: [
          'header_symbol_search',
          'symbol_search_hot_key',
          'save_shortcut',
          'header_compare',
          'header_screenshot',
          'timeframes_toolbar',
          ...(isMobileVersion ? disabled_features_mobile : []),
        ],
        // https://www.tradingview.com/charting-library-docs/latest/ui_elements/indicators/#hide-volume-based-indicators
        studies_access: {
          type: 'black',
          tools: [
            {name: 'Accumulation/Distribution'},
            {name: 'Chaikin Money Flow'},
            {name: 'Chaikin Oscillator'},
            {name: 'Ease of Movement'},
            {name: 'Elders Force Index'},
            {name: 'Klinger Oscillator'},
            {name: 'Money Flow Index'},
            {name: 'Net Volume'},
            {name: 'On Balance Volume'},
            {name: 'Price Volume Trend'},
            {name: 'VWAP'},
            {name: 'Volume'},
            {name: 'Volume Oscillator'},
            {name: 'Volume Profile Fixed Range'},
            {name: 'Volume Profile Visible Range'},
            {name: 'VWMA'},
          ],
        },
        enabled_features: [
          'header_in_fullscreen_mode',
          'side_toolbar_in_fullscreen_mode',
        ],
        fullscreen: fullscreen,
        library_path: '/charting_library/', // NOTE: it is important to have library in public folder: apps/web-app/public/charting_library and in one src folder with component
        locale: (locale ?? 'en') as LanguageCode, // NOTE: if locale not supported, 'en' will be used
        saved_data: getTVChartStateParams(),
        auto_save_delay: 0.5,
        timeframe: defaultTimeframe, // timeframe can be relative to the current date, or a range. A relative timeframe is a number with a letter D for days and M for months
        timezone: timezoneName as Timezone,
        theme: capitalize(theme ?? 'light') as ThemeName,
        overrides: {
          'paneProperties.background': theme === 'light' ? '#FFF' : '#212630',
          'paneProperties.backgroundType': 'solid',
        },
        custom_css_url: './../css/style.css',
        custom_font_family: "'Ubuntu', sans-serif",
        // debug: true, // NOTE: for development purposes
      };

      tvWidget.current = new widget(widgetOptions);

      tvWidget.current.onChartReady(() => {
        tvWidget.current?.subscribe('onAutoSaveNeeded', () => {
          const data = tvWidget.current?.symbolInterval();
          saveTVChartInterval(data?.interval!);
          tvWidget.current?.save((state: {}) => {
            saveTVChartStateParams(state);
          });
        });
      });

      return () => {
        if (tvWidget) {
          tvWidget.current?.remove();
        }
      };
    }, [datafeed, isMobileVersion]);

    React.useEffect(() => {
      tvWidget.current?.onChartReady(() => {
        // @ts-ignore TS says that theme names should be different. Does it work now?
        tvWidget.current?.changeTheme(theme).then(() => {
          tvWidget.current?.applyOverrides({
            'paneProperties.background': theme === 'light' ? '#FFF' : '#212630',
            'paneProperties.backgroundType': 'solid',
          });
        });
      });
    }, [theme]);

    React.useEffect(() => {
      tvWidget.current?.onChartReady(() => {
        // NOTE: create line with initial price
        if (initialPriceLine !== undefined) {
          // NOTE: create or update line
          if (initialPriceChartLine.current === null) {
            initialPriceChartLine.current = tvWidget
              .current!.chart()
              .createPositionLine()
              .setPrice(initialPriceLine.price)
              .setText(initialPriceLine.text)
              .setBodyTextColor(initialPriceLine.bodyTextColor)
              .setBodyBorderColor('transparent')
              .setLineColor(initialPriceLine.lineColor)
              .setLineLength(100)
              .setExtendLeft(true)
              // @ts-ignore it wants string, not a boolean
              .setQuantity(false);
          } else {
            initialPriceChartLine.current
              .setPrice(initialPriceLine.price)
              .setText(initialPriceLine.text);
          }
        }

        if (takeProfitLine !== undefined) {
          // NOTE: create or update line
          if (takeProfitChartLine.current === null) {
            takeProfitChartLine.current = tvWidget
              .current!.chart()
              .createPositionLine()
              .setPrice(takeProfitLine.price)
              .setText(takeProfitLine.text)
              .setBodyTextColor(takeProfitLine.bodyTextColor)
              .setBodyBorderColor('transparent')
              .setLineColor(takeProfitLine.lineColor)
              .setLineLength(100)
              .setExtendLeft(true)
              // @ts-ignore it wants string, not a boolean
              .setQuantity(false);
          } else {
            takeProfitChartLine.current
              .setPrice(takeProfitLine.price)
              .setText(takeProfitLine.text);
          }
        }

        if (marginCallLine !== undefined) {
          // NOTE: create or update line
          if (marginCallChartLine.current === null) {
            marginCallChartLine.current = tvWidget
              .current!.chart()
              .createPositionLine()
              .setPrice(marginCallLine.price)
              .setText(marginCallLine.text)
              .setBodyTextColor(marginCallLine.bodyTextColor)
              .setBodyBorderColor('transparent')
              .setLineColor(marginCallLine.lineColor)
              .setLineLength(100)
              .setExtendLeft(true)
              // @ts-ignore it wants string, not a boolean
              .setQuantity(false);
          } else {
            marginCallChartLine.current
              .setPrice(marginCallLine.price)
              .setText(marginCallLine.text);
          }
        }

        if (triggerPriceLine !== undefined) {
          // NOTE: create or update line
          if (triggerPriceChartLine.current === null) {
            triggerPriceChartLine.current = tvWidget
              .current!.chart()
              .createPositionLine()
              .setPrice(triggerPriceLine.price)
              .setText(triggerPriceLine.text)
              .setBodyTextColor(triggerPriceLine.bodyTextColor)
              .setBodyBorderColor('transparent')
              .setLineColor(triggerPriceLine.lineColor)
              .setLineLength(100)
              .setExtendLeft(true)
              // @ts-ignore it wants string, not a boolean
              .setQuantity(false);
          } else {
            triggerPriceChartLine.current
              .setPrice(triggerPriceLine.price)
              .setText(triggerPriceLine.text);
          }
        } else {
          // NOTE: remove line after pending order triggered or switched off
          triggerPriceChartLine.current?.remove();
          triggerPriceChartLine.current = null;
        }
      });
    }, [initialPriceLine, takeProfitLine, marginCallLine, triggerPriceLine]);

    return <Box ref={chartContainerRef} {...boxProps} />;
  }
);
