import * as React from 'react';
import {
  LoansFeature,
  Loans,
  type LoansListItemFeatureArgs,
} from '@youtoken/ui.legacy-components';
import {Box, Heading, useIsMobile, useTheme} from '@youtoken/ui.primitives';
import {Pagination} from '@youtoken/ui.elements';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {modal} from '../../../../../../../stores';
import {observer} from 'mobx-react';
import {TRANSPORT} from '@youtoken/ui.transport';
import {useTranslation} from '@youtoken/ui.service-i18n';

const {Brief} = Loans;

export const List: React.FC = observer(() => {
  const {t} = useTranslation();

  const [bringedToFrontId, setBringedToFrontId] = React.useState<string>();

  const routerResource = RouterResource.use({});

  const featureLoans = LoansFeature.use({mode: 'turbo'});

  const handleBringToFront = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      setBringedToFrontId(loan.id);
    },
    []
  );

  const handlePressOpen = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      routerResource.push(`/turbocharge/details?id=${loan.id}`);
    },
    [routerResource.push]
  );

  const handlePressPay = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('loanPay', {loan});
    },
    [modal.open]
  );

  const handlePressCancel = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      const res = window.confirm(
        t('surface.loans.item.cancel_confirm_description')
      );

      if (!res) {
        return;
      }

      TRANSPORT.API.post('/v1/loan/cancel', {id: loan.id});
    },
    [t, window.confirm, TRANSPORT.API.post]
  );

  const handlePressCloseNow = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('closeNow', {loan});
    },
    [modal.open]
  );

  const handlePressIncrease = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('increaseLtv', {loan});
    },
    [modal.open]
  );

  const handlePressDecrease = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('decreaseLtv', {loan});
    },
    [modal.open]
  );

  const handlePressReopen = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('reopenLoan', {loan});
    },
    [modal.open]
  );

  const handlePressSetClosePrice = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('setClosePrice', {loan});
    },
    [modal.open]
  );

  const isMobile = useIsMobile();
  const {colors} = useTheme();

  const handleMap = React.useCallback(
    (id: string, index: number, list: string[]) => {
      const styleBorder =
        index < list.length - 1
          ? {
              borderBottomWidth: 1,
              borderBottomColor: colors['$ui-01'],
            }
          : {};

      const styleBorderRadius = isMobile
        ? {}
        : {
            borderTopLeftRadius: index === 0 ? 5 : 0,
            borderTopRightRadius: index === 0 ? 5 : 0,
            borderBottomLeftRadius: index === list.length - 1 ? 5 : 0,
            borderBottomRightRadius: index === list.length - 1 ? 5 : 0,
          };

      const style = {
        ...styleBorder,
        ...styleBorderRadius,
      };

      return (
        <Brief
          zIndex={bringedToFrontId === id ? 1 : 0}
          key={id}
          mode={featureLoans.mode}
          id={id}
          onBringToFront={handleBringToFront}
          onPress={handlePressOpen}
          onPressPay={handlePressPay}
          onPressCancel={handlePressCancel}
          onPressCloseNow={handlePressCloseNow}
          onPressIncrease={handlePressIncrease}
          onPressDecrease={handlePressDecrease}
          onPressReopen={handlePressReopen}
          onPressSetClosePrice={handlePressSetClosePrice}
          width="100%"
          style={style}
        />
      );
    },
    [
      bringedToFrontId,
      featureLoans.mode,
      handleBringToFront,
      handlePressOpen,
      handlePressPay,
      handlePressCancel,
      handlePressCloseNow,
      handlePressIncrease,
      handlePressDecrease,
      handlePressReopen,
      handlePressSetClosePrice,
    ]
  );

  return (
    <Box py={20}>
      {featureLoans.activeList.length > 0 && (
        <Box mb={20} zIndex={2}>
          <Box px={{default: 20, desktop: 0}} mb={20}>
            <Heading variant="$heading-02-responsive">
              {t('surface.loans.active')}
            </Heading>
          </Box>
          <Box
            borderTopWidth={1}
            borderRightWidth={{default: 0, desktop: 1}}
            borderBottomWidth={1}
            borderLeftWidth={{default: 0, desktop: 1}}
            borderColor="$ui-01"
            borderRadius={{default: 0, desktop: 5}}
          >
            {featureLoans.activeList.map(handleMap)}
          </Box>
        </Box>
      )}

      {featureLoans.closedList.length > 0 && (
        <Box zIndex={1}>
          <Box px={{default: 20, desktop: 0}} mb={20}>
            <Heading variant="$heading-02-responsive">
              {t('surface.loans.closed')}
            </Heading>
          </Box>
          <Box
            borderTopWidth={1}
            borderRightWidth={{default: 0, desktop: 1}}
            borderBottomWidth={1}
            borderLeftWidth={{default: 0, desktop: 1}}
            borderColor="$ui-01"
            borderRadius={{default: 0, desktop: 5}}
          >
            {featureLoans.closedList.map(handleMap)}
          </Box>
          {featureLoans.closedTotalPages > 1 && (
            <Box alignSelf="center" m={20}>
              <Pagination
                activePage={featureLoans.closedActivePage}
                totalPages={featureLoans.closedTotalPages}
                onPageChange={featureLoans.setClosedActivePage}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});
