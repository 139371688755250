/**
 * Converts an array of values to a map of them to the provided flag (false by default).
 * @example
 * arrayToFilter(['a', 'b', 'c']) => {a: false, b: false, c: false}
 * arrayToFilter(['a', 'b', 'c'], true) => {a: true, b: true, c: true}
 */
export const arrayToFilter = <T extends string>(
  items: T[],
  flag: boolean = false
): Record<T, boolean> => {
  return items.reduce(
    (acc, type) => ({
      ...acc,
      [type]: flag,
    }),
    {} as Record<T, boolean>
  );
};

/**
 * Converts a filter to an array of the values that are set to true.
 * @example
 * filterToArray({a: true, b: false, c: true}) => ['a', 'c']
 */
export const filterToArray = <T extends string>(
  filter: Record<T, boolean>
): T[] => {
  return (Object.entries(filter) as [T, boolean][]).reduce(
    (acc: T[], [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    []
  );
};

/**
 * Resets all values in a filter to false.
 * @example
 * filterToArray({a: true, b: false}) => {a: false, b: false}
 */
export const clearFilter = <T extends Record<string, boolean>>(
  filter: T
): T => {
  return Object.keys(filter).reduce(
    (acc, key) => ({
      ...acc,
      [key]: false,
    }),
    {} as T
  );
};

/**
 * Creates a filter of all values with selected values set to true.
 * @example
 * getInitialFilter([a, b, c], [a, b]) => {a: true, b: true, c: false}
 */
export const getInitialFilter = <T extends string>(
  allItems: T[],
  selectedItems: T[]
): Record<T, boolean> => {
  return {
    ...arrayToFilter(allItems),
    ...arrayToFilter(selectedItems, true),
  };
};
