import * as React from 'react';
import {observer} from 'mobx-react';
import {BoxProps, Box, Text, TouchableText} from '@youtoken/ui.primitives';
import {Switch, ActivityIndicator} from '@youtoken/ui.elements';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';

interface FormADisclosureProps extends BoxProps {
  hasError: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
  openModal: () => void;
  isLoading?: boolean;
}

export const FormADisclosure: React.FC<FormADisclosureProps> = observer(
  ({hasError, value, onChange, openModal, isLoading, ...boxProps}) => {
    const {t} = useTranslation();

    if (isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <Box {...boxProps}>
          <Box
            flex={1}
            flexDirection="row"
            alignItems="center"
            position="relative"
          >
            {/* @ts-ignore */}
            <Text variant="$body-02" mr={5} focusable={false}>
              <Trans
                t={t}
                components={{
                  Link: (
                    <TouchableText
                      variant="$body-02"
                      color="$text-05"
                      onPress={openModal}
                      testID="DISCLOSURE_LINK"
                    />
                  ),
                }}
                i18nKey="surface.form_a.field.disclosure"
              />
            </Text>
          </Box>
          <Switch hasError={hasError} value={value} onValueChange={onChange} />
        </Box>
      </>
    );
  }
);
