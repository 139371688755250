import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SuccessIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2a8 8 0 110 16 8 8 0 010-16zm4.03 5.47a.75.75 0 010 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-2.5-2.5a.75.75 0 111.06-1.06L9 11.44l3.97-3.97a.75.75 0 011.06 0z"
          fill={color}
        />
      </Svg>
    );
  }
);
