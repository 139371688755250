import {invariant} from '@youtoken/ui.utils';
import EventEmitter from 'eventemitter3';

interface Payload {
  text: string;
  testID?: string;
}

interface Handlers {
  info: (payload: Payload) => void;
  error: (payload: Payload) => void;
}

export class LocalNotificationsService {
  public events = new EventEmitter<
    'provideHandlers' | 'info' | 'error' | 'message'
  >();

  constructor() {}

  private _handlers?: Handlers = undefined;

  public provideHandlers = (handlers: Handlers) => {
    this.events.emit('provideHandlers', handlers);

    this._handlers = handlers;
  };

  public info = (payload: Payload) => {
    this.events.emit('info', payload);

    invariant(
      this._handlers,
      'handlers was not provided for LocalNotificationsService'
    );

    this._handlers.info(payload);
  };

  public error = (payload: Payload) => {
    this.events.emit('error', payload);

    invariant(
      this._handlers,
      'handlers was not provided for LocalNotificationsService'
    );

    this._handlers.error(payload);
  };

  public message = (type: 'info' | 'error', payload: Payload) => {
    this.events.emit('info', type, payload);

    invariant(
      this._handlers,
      'handlers was not provided for LocalNotificationsService'
    );

    invariant(
      type === 'info' || type === 'error',
      `message type "${type}" is not supported in LocalNotificationsService`
    );

    invariant(
      this._handlers[type],
      `handler type "${type}" was not provided for LocalNotificationsService`
    );

    this._handlers[type](payload);
  };
}
