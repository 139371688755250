import {WebAppEnv} from './WebAppEnv';

/** @platform web */
export const getWebAppEnvFromHost = () => {
  const host = window.location.host;

  if (host.includes('ledgerlive.youhodler')) {
    return WebAppEnv['ledger-app'];
  }

  // todo after release to propd can be used simple version: host.includes('ramp.youhodler')
  if (['ramp.youhodler', 'ramp-'].some(value => host.includes(value))) {
    return WebAppEnv['ramp-app'];
  }

  return WebAppEnv['web-app'];
};
