import * as React from 'react';
import NativeQRCode, {
  QRCodeProps as NativeQRCodeProps,
} from 'react-native-qrcode-svg';
import {
  Box,
  ThemeProvider,
  useTheme,
  type TColorTokens,
} from '@youtoken/ui.primitives';

export interface QRCodeProps
  extends Omit<
    NativeQRCodeProps,
    | 'logo'
    | 'logoSize'
    | 'logoBackgroundColor'
    | 'logoMargin'
    | 'logoBorderRadius'
  > {
  color?: keyof TColorTokens;
  backgroundColor?: keyof TColorTokens;
  testID?: string;
}

const _QRCode: React.FC<QRCodeProps> = ({
  ecl = 'M',
  size = 145,
  color = '$text-01',
  backgroundColor = '$ui-background',
  testID,
  ...rest
}) => {
  const {colors} = useTheme();

  return (
    <Box
      width={size + 16}
      height={size + 16}
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      borderRadius={8}
      p={8}
      backgroundColor="$ui-background"
      testID={testID}
    >
      {/* Box wrap is fixing svg scaling in flex */}

      <NativeQRCode
        size={size + 2}
        ecl={ecl}
        color={colors[color]}
        backgroundColor={colors[backgroundColor]}
        {...rest}
      />
    </Box>
  );
};

export const QRCode: React.FC<QRCodeProps> = ({...props}) => {
  return (
    <ThemeProvider themeName="light">
      <_QRCode {...props} />
    </ThemeProvider>
  );
};
