import {iconSet, type IconName} from '../__generated/Device';
import {createIconComponent} from '../createIcon';

export {iconSet};
export type {IconName};

export const DeviceIcon = createIconComponent<IconName, typeof iconSet>({
  componentName: 'Icon.Device',
  iconSet: iconSet,
  defaultSize: 64,
});
