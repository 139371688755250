import React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Banner} from '@youtoken/ui.elements';
import {Box, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BankWireFeature} from '../../states/BankWireFeature';
import {CopyRequisites} from './components/CopyRequisites';
import {PaymentInfo} from './components/PaymentInfo';
import {RequisitesListItem} from './components/RequisitesListItem';
import {splitBankRequisites} from './utils/splitBankRequisites';

interface RequisitesProps {
  ticker: string;
  isAlternative?: boolean;
}

export const Requisites: React.FC<RequisitesProps> = observer(
  ({ticker, isAlternative}) => {
    const {t} = useTranslation();

    const {accountType, account, alternativeToIBANAccount} =
      BankWireFeature.use({
        ticker,
      });
    const requisitesList = isAlternative
      ? alternativeToIBANAccount.requisites
      : account.requisites;

    if (!requisitesList?.length) {
      return null;
    }

    const {
      mainRequisites,
      correspondentBankRequisites,
      intermediaryBankRequisites,
    } = splitBankRequisites(requisitesList);

    return (
      <>
        <PaymentInfo ticker={ticker} />

        <Box px={24} pb={0}>
          {mainRequisites.map(item => (
            <RequisitesListItem key={item.value} requisite={item} />
          ))}

          {accountType === 'swift' &&
            intermediaryBankRequisites.map(item => (
              <RequisitesListItem key={item.value} requisite={item} />
            ))}

          {Boolean(correspondentBankRequisites.length) && (
            <>
              <Heading
                variant="$heading-04"
                pt={20}
                pb={10}
                testID="FIAT_DEPOSIT_BANK_WIRE_CORRESPONDENT_BANK_TITLE"
              >
                {t('surface.bank_wire.requisites.correspondent_bank.title')}
              </Heading>
              {correspondentBankRequisites.map(item => (
                <RequisitesListItem key={item.value} requisite={item} />
              ))}
            </>
          )}
        </Box>

        <CopyRequisites requisites={requisitesList} />

        <Banner
          variant="attention"
          testID="FIAT_DEPOSIT_BANK_WIRE_ATTENTION_BANNER"
          borderRadius={Platform.select({default: 0, web: 8})}
          m={Platform.select({default: 0, web: 24})}
          mt={0}
          pb={Platform.select({default: 16, ios: 24})}
        >
          {`${t('surface.wallets.fiat_deposit_wire.attention.new')}`}
        </Banner>
      </>
    );
  }
);
