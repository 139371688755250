import * as React from 'react';
import {BaseIconName, Icon} from '@youtoken/ui.icons';
import {
  Box,
  type BoxProps,
  Text,
  type TextProps,
} from '@youtoken/ui.primitives';
import {getAsterisksSting} from './utils';

export type InfoItemProps = {
  icon: BaseIconName;
  title: string;
  text: string;
  textProps?: TextProps;
  asteriskCount?: number;
};

export const InfoItem: React.FC<InfoItemProps & BoxProps> = ({
  icon,
  title,
  text,
  asteriskCount,
  textProps,
  ...boxProps
}) => {
  const asterisks = getAsterisksSting(asteriskCount);

  return (
    <Box flexDirection="row" {...boxProps}>
      <Box
        width={40}
        height={40}
        borderRadius={40}
        mr={16}
        backgroundColor="$interactive-02"
        alignItems="center"
        justifyContent="center"
      >
        <Icon name={icon} color="$interactive-01" size={20} />
      </Box>
      <Box flex={1}>
        <Text variant="$body-02-high-accent" color="$text-05" mb={4}>
          {title}
        </Text>
        <Text variant="$body-01" color="$text-01" {...textProps}>
          {text}
          {asterisks && (
            <Text variant="$body-01" color="$interactive-01">
              {' ' + asterisks}
            </Text>
          )}
        </Text>
      </Box>
    </Box>
  );
};
