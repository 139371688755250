import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {cell} from '@youtoken/ui.cell';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RewardsListResource} from '@youtoken/ui.resources-rewards';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {SavingsProgressBar} from '@youtoken/ui.progress-bar';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';

interface BonusScreenProps {
  id: string;
  onPressButton?: () => void;
}

export const BonusScreen: React.FC<BonusScreenProps> = cell(
  ({id, onPressButton = () => {}}) => {
    const {
      data: {rewardVersion},
    } = AuthMeResource.use({});

    if (rewardVersion === 3) {
      return null;
    }

    const rewardsList = RewardsListResource.use({});
    const data = rewardsList.data?.items?.find(item => item.id === id);

    invariant(
      data,
      'Cannot find bonus screen data',
      {},
      {dataList: rewardsList.data?.items, id}
    );

    const {walletsTickersWithAmountOrder} = WalletsResource.use({});
    const bonusEarned = data.complete;

    const {t} = useTranslation();

    const handleClick = React.useCallback(() => {
      onPressButton();

      switch (data.type) {
        case 'hodl':
          return SHARED_ROUTER_SERVICE.navigate('NewMultiHODL');
        case 'loan':
          return SHARED_ROUTER_SERVICE.navigate('LoanCreate');
        case 'convert':
          const toTicker = 'btc';

          const fromTicker =
            walletsTickersWithAmountOrder.filter(t => t !== toTicker)[0] ||
            'usd';

          return SHARED_ROUTER_SERVICE.navigate('Exchange', {
            fromTicker,
            toTicker,
          });
        default:
          return SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
      }
    }, [data.type]);

    return (
      <Box>
        <Box
          bg={bonusEarned ? '$success-03' : '$fill-02'}
          p={24}
          paddingTop={32}
        >
          <Box alignItems="center">
            <Button
              icon={bonusEarned ? 'success' : 'gift'}
              type="primary"
              color={bonusEarned ? 'success' : 'interactive'}
              mb={16}
            >
              {data.personalRewardUSD}
            </Button>
            <Text variant="$heading-02" color="$text-01">
              {data.title}
            </Text>
          </Box>

          {data.requiredSteps &&
            data.completedSteps &&
            data.requiredSteps > 1 && (
              <Box marginTop={24}>
                <Box>
                  <SavingsProgressBar
                    backgroundColor="$ui-background"
                    color={bonusEarned ? 'green' : 'blue'}
                    size="tiny"
                    value={data.completedSteps / data.requiredSteps}
                  />
                </Box>
                <Box marginTop={8} alignItems="flex-end">
                  <Text
                    variant="$body-02-high-accent"
                    color={
                      bonusEarned
                        ? '$success-01'
                        : data.completedSteps > 0
                        ? '$text-05'
                        : '$text-02'
                    }
                  >
                    {data.completedSteps}
                    {' / '}
                    {data.requiredSteps}
                  </Text>
                </Box>
              </Box>
            )}
        </Box>

        {data.conditions.length > 0 && (
          <Box p={24}>
            <Text variant="$body-01-high-accent" mb={16}>
              {t('surface.referral_program.bonus_screen.conditions')}
            </Text>
            {data.conditions.map((item, index) => (
              <Text key={index} mt={index === 0 ? 0 : 8}>
                {'\u25CF'} {item}
              </Text>
            ))}
          </Box>
        )}

        <Box
          p={24}
          paddingVertical={32}
          borderTopColor="$ui-01"
          borderTopWidth={1}
          borderStyle="solid"
        >
          {bonusEarned ? (
            <Box
              flexDirection="row"
              justifyContent="center"
              p={16}
              bg="$success-02"
              borderRadius={6}
            >
              <Box flexShrink={0}>
                <Icon name="success" color="$success-01" size={20} />
              </Box>
              <Box flexShrink={1} ml={6}>
                <Text color="$success-01">
                  {t('surface.referral_program.bonus_screen.bonus_paid')}
                </Text>
              </Box>
            </Box>
          ) : (
            <Button onPress={handleClick} size="large">
              {data.buttonTitle}
            </Button>
          )}
        </Box>
      </Box>
    );
  },
  {
    SuspenseFallback: () => (
      <Box height={200} justifyContent="center" alignItems="center">
        <ActivityIndicator />
      </Box>
    ),
  }
);
