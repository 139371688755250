import React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {FriendsActivityList} from './FriendsActivityList';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ReferralsHowItWorksModalTrigger} from '../../../..';

interface FriendsActivityProps {}

export const FriendsActivity: React.FC<FriendsActivityProps> = () => {
  const {t} = useTranslation();

  return (
    <Box p={{default: 16, phone: 24}}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={16}
      >
        <Text variant="$heading-02">
          {t('surface.referral_program.referrals.friends_activity.heading')}
        </Text>
        <ReferralsHowItWorksModalTrigger />
      </Box>
      <FriendsActivityList />
    </Box>
  );
};
