import {observable, action} from 'mobx';
import {Form} from './Form';
import {type SignUpFormArgs, type SignUpFormResources} from './types';

export class SignUpForm {
  @observable
  public form: Form;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(args: SignUpFormArgs, resources: SignUpFormResources) {
    this.form = new Form(args, resources);
  }
}
