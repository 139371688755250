import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Heading} from '@youtoken/ui.primitives';
import {AgreementsList, Illustration, Switch} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AllDisclosuresResource} from '@youtoken/ui.resource-all-disclosures';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';

export interface ReSignDisclosuresSurfaceCellProps {
  onConfirm?: () => void;
}

export const ReSignDisclosuresSurfaceCell: React.FC<ReSignDisclosuresSurfaceCellProps> =
  cell(({onConfirm}) => {
    const {t} = useTranslation();
    const {allMissingAgreements, sign, needsToSignAgreements} =
      AllDisclosuresResource.use({});
    const [hasAccepted, setHasAccepted] = React.useState(false);

    const titleText = React.useMemo(() => {
      if (
        allMissingAgreements.length === 1 &&
        allMissingAgreements[0].name === 'Privacy Notice'
      ) {
        return t('surface.disclosure.updated_privacy_notice');
      }
      return t('surface.disclosure_form.title');
    }, [allMissingAgreements]);

    const handleConfirm = React.useCallback(() => {
      sign()
        .then(() => {
          SHARED_ROUTER_SERVICE.navigate('__CloseModal');
          onConfirm?.();
        })
        .catch(error => {
          handleGeneralErrorTranslated(error.response?.data);
        });
    }, [onConfirm]);

    if (!needsToSignAgreements) {
      return null;
    }

    return (
      <>
        <Box
          flexDirection="column"
          alignItems="center"
          p={24}
          borderBottomWidth={1}
          borderBottomColor="$ui-01"
        >
          <Heading textAlign="center" variant="$heading-01">
            {titleText}
          </Heading>
          <Illustration
            name="illustration-2"
            style={{width: 312, height: 312}}
          />
        </Box>
        <Box p={24} testID="DISCLOSURE_FOOTER">
          <Box flexDirection="row">
            <Box flexShrink={1} flexGrow={0} pr={12}>
              <AgreementsList items={allMissingAgreements} />
            </Box>
            <Box>
              <Switch
                value={hasAccepted}
                onValueChange={setHasAccepted}
                testID="DISCLOSURE_SWITCH"
              />
            </Box>
          </Box>
          <Button
            mt={24}
            disabled={!hasAccepted}
            onPress={handleConfirm}
            testID="DISCLOSURE_CONFIRM"
          >
            {t('surface.disclosure_form.confirm')}
          </Button>
        </Box>
      </>
    );
  });
