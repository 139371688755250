import React from 'react';
import {Field} from '../../Field';
import {TextInput} from '../../Inputs';

export type TextFieldProps = {
  type: 'text' | 'email' | 'password' | 'date';
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  readonly?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (event: any) => void;
  renderLabelPlugins?: Function;
  normalize?: (event: React.ChangeEvent<any>) => React.ChangeEvent<any>;
  testID?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
  type,
  name,
  label,
  placeholder,
  onChange: customOnChange,
  onBlur: customOnBlur,
  readonly,
  renderLabelPlugins,
  normalize,
  testID,
}) => {
  return (
    <Field
      name={name}
      label={label}
      renderLabelPlugins={renderLabelPlugins}
      errorProps={{
        testID: testID ? `${testID}_ERROR` : undefined,
      }}
      render={({field, form}) => {
        const {onBlur, onChange, name, value} = field;
        const {errors, touched} = form;

        const invalid = Boolean(errors[name] && touched[name]);

        const onChangeModified = (event: React.ChangeEvent<any>): void => {
          const caretPosition = event.target.selectionStart;
          const preparedEvent = normalize ? normalize(event) : event;

          if (event.target.selectionStart !== caretPosition) {
            try {
              // a hack to avoid cursor issue in Safari (DEV-3085)
              event.target.selectionStart = caretPosition;
              event.target.selectionEnd = caretPosition;
            } catch (e) {}
          }

          onChange(preparedEvent);
          if (customOnChange) {
            customOnChange(event.target.value);
          }
        };

        const onBlurModified = (event: any) => {
          if (customOnBlur) {
            customOnBlur(event);
          }
          onBlur(event);
        };

        return (
          <TextInput
            className={invalid ? 'invalid' : undefined}
            type={type}
            name={name}
            onChange={onChangeModified}
            onBlur={onBlurModified}
            value={value}
            placeholder={placeholder}
            readonly={readonly}
            // NOTE: need to refactor that
            data-testid={testID}
          />
        );
      }}
    />
  );
};
