import {computed, observable} from 'mobx';
import {TFuncKey} from 'react-i18next';
import {i18n, type TKey} from '@youtoken/ui.service-i18n';
import {Form} from './Form';

export class View {
  @observable
  public form: Form;

  constructor(form: Form) {
    this.form = form;
  }

  @computed
  public get sourceWallets() {
    return this.form.sourceWallets;
  }

  @computed
  public get inputTickerFormatted() {
    return this.form.inputTicker.toUpperCase();
  }

  @computed
  public get quoteTickerFormatted() {
    return this.form.quoteTicker.toUpperCase();
  }

  @computed get loansInChain() {
    return this.form.calculatedData?.loansInChain;
  }

  @computed get leverageMin() {
    return Number(this.form.currentTariff?.minMultiplier);
  }

  @computed get leverageMax() {
    return Number(this.form.currentTariff?.maxMultiplier);
  }

  @computed get agreementItems() {
    return this.form.resources.docs.documents.hodlCreateForm;
  }

  @computed get infoTableData() {
    const calculatedData = this.form.calculatedData;
    const hodlItem = this.form.resources.hodl.data;
    const precision = hodlItem.precision;

    if (!calculatedData) {
      return null;
    }

    return {
      hodlDirectionFormatted: (
        i18n.t(
          `surface.hodls.item.details_table.direction_${this.form.direction}` as TFuncKey
        ) as string
      ).toUpperCase(),
      initialPriceFormatted: calculatedData.initialPrice.toFixed(
        hodlItem.precision
      ),
      takeProfitPercentFormatted: calculatedData.tpPercent + '%',
      takeProfitFormatted: calculatedData.tpValue.toFixed(
        precision,
        calculatedData.roundingMode
      ),
      stopLossPercentFormatted: calculatedData.slPercent + '%',
      stopLossFormatted: calculatedData.mcPrice.toFixed(
        precision,
        calculatedData.roundingMode
      ),
      profitLossTextFormatted: i18n.t(
        `surface.hodls.close_now.${hodlItem.calculateProfitLossText}` as TKey
      ),
      profitLossAmountFormatted: `${hodlItem.calculateProfit.gt(0) ? '+' : ''}${
        hodlItem.calculateProfitLossAmount
      }`,
      profitLossTickerFormatted: hodlItem.outputTickerUpperCase,
      closeNowOutputAmountFormatted: `≈ ${hodlItem.closeNowOutputAmountFormatted}`,
    };
  }
}
