import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ProfileIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.004 19.75a8.004 8.004 0 015.573-7.376 5 5 0 114.847 0A8.002 8.002 0 0120 20a1 1 0 01-1.993.117L18 20a6 6 0 00-11.996-.225L6 20a1 1 0 01-1.993.117L4 20l.004-.25zM12 5a3 3 0 100 6 3 3 0 000-6z"
          fill={color}
        />
      </Svg>
    );
  }
);
