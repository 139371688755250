import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {CoindropResource} from '@youtoken/ui.coindrop-resource';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CoinIcons} from './components';

const handlePress = () => {
  DATA_LAYER.trackStrict('coins-drop-click', {});
  SHARED_ROUTER_SERVICE.navigate('Coindrop', {});
};

export const CoindropBanner: React.FC<BoxProps> = cell(
  boxProps => {
    const {shouldDisplayInitialCoindropModal} = AuthMeResource.use({});
    const {coins, dropsAvailableByTariff} = CoindropResource.use({});
    const {t} = useTranslation();

    React.useEffect(() => {
      if (shouldDisplayInitialCoindropModal && dropsAvailableByTariff) {
        SHARED_ROUTER_SERVICE.navigate('Coindrop');
      }
    }, [dropsAvailableByTariff, shouldDisplayInitialCoindropModal]);

    if (!dropsAvailableByTariff) {
      return null;
    }

    return (
      <TouchableBox
        onPress={handlePress}
        backgroundColor="$interactive-01"
        borderRadius={12}
        px={16}
        py={10}
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        testID="COINDROPS_BANNER"
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center">
          <CoinIcons coins={coins} />
          <Text variant="$body-01-high-accent" color="$text-04">
            {t('banner.coindrop.title')}
          </Text>
        </Box>
        <Box flexDirection="row" gap={4} alignItems="center">
          <Text variant="$body-02" color="$text-04">
            {t('coindrop.badge')}
          </Text>
          <Icon name="pending" color="$text-04" />
        </Box>
      </TouchableBox>
    );
  },
  {
    displayName: 'CoindropBanner',
    SuspenseFallback: () => null,
    ErrorFallback: () => null,
  }
);
