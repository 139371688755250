import * as React from 'react';
import {observer} from 'mobx-react';
import {DayPreviewChartDataResource} from '@youtoken/ui.resource-day-preview-chart-data';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {ChartSmallPreview} from './';

export interface SmallPreviewChartSmartCellProps {
  fromTicker: string;
  toTicker: string;
  width: number;
  height: number;
  gradient?: boolean;
}

export const HODLSmallPreviewChartSmartCell = observer(
  ({
    fromTicker,
    toTicker,
    width,
    height,
    gradient,
  }: SmallPreviewChartSmartCellProps) => {
    const {tickersPairsList, getDiffDirection} = HODLsTariffsFeature.use({});
    const {getChartDataBySymbol} = DayPreviewChartDataResource.use({
      tickersPairs: tickersPairsList,
      product: 'hodl',
    });
    const chartData = getChartDataBySymbol(fromTicker, toTicker);
    const diffDirection = getDiffDirection(fromTicker, toTicker);

    if (!chartData) {
      return null;
    }

    return (
      <ChartSmallPreview
        width={width}
        height={height}
        data={chartData}
        gradient={gradient}
        diffDirection={diffDirection}
      />
    );
  }
);
