import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

export interface ReferralsHowItWorksItemProps {
  icon: BaseIconName;
  title: string;
  description: string;
}

export const ReferralsHowItWorksItem: React.FC<ReferralsHowItWorksItemProps> =
  React.memo(({icon, title, description}) => {
    return (
      <Box flexDirection="row" alignItems="flex-start" my={8}>
        <Box p={10} backgroundColor="$success-02" borderRadius={20}>
          <Icon name={icon} size={20} color="$success-01" />
        </Box>
        <Box flex={1} ml={16}>
          <Text variant="$body-01-high-accent" color="$text-01" mt={4}>
            {title}
          </Text>
          <Text variant="$body-02" color="$text-02" mt={4}>
            {description}
          </Text>
        </Box>
      </Box>
    );
  });
