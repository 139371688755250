import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';
import {ChartDirection} from '../BaseChartState';
import {computedFn} from 'mobx-utils';

type HODLHighlightAreaLayout = {
  x: number;
  y: number;
  width: number;
  height: number;
  direction: ChartDirection;
  gradientDirection: ChartDirection;
  endIntensity: number;
};

export class HODLHighlightArea<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  getValueLayout = computedFn((value: number) => {
    return {
      y: this.chart.layout.getClampedLineY(value),
      isInDomain: this.chart.layout.getIsValueInDomain(value),
    };
  });

  @computed get initialPriceLine() {
    return this.getValueLayout(this.chart.initialPrice!);
  }

  @computed get takeProfitLine() {
    if (this.chart.takeProfit) {
      return this.getValueLayout(this.chart.takeProfit);
    }

    return {
      y: this.chart.reversed ? this.chart.height : 0,
      isInDomain: false,
    };
  }

  @computed get marginCallLine() {
    if (this.chart.marginCall) {
      return this.getValueLayout(this.chart.marginCall);
    }

    return {
      y: this.chart.reversed ? 0 : this.chart.height,
      isInDomain: false,
    };
  }

  @computed get lastValue() {
    return this.getValueLayout(this.chart.lastValueToCompare);
  }

  @computed get domainState(): 'one' | 'both' | 'none' {
    if (this.initialPriceLine.isInDomain) {
      return 'one';
    }

    return 'none';
  }

  @computed get layout(): HODLHighlightAreaLayout | undefined {
    if (this.domainState === 'none') {
      return undefined;
    }

    const layout = {
      x: 0,
      width: this.chart.width,
      direction: this.chart.direction,
    };

    const whatPartToUse =
      this.initialPriceLine.y > this.lastValue.y ? 'top' : 'bottom';

    return {
      ...layout,
      y: whatPartToUse === 'top' ? 0 : this.initialPriceLine.y,
      endIntensity: 0,
      height:
        whatPartToUse === 'top'
          ? this.initialPriceLine.y
          : this.chart.height - this.initialPriceLine.y,
      gradientDirection: whatPartToUse === 'top' ? 'up' : 'down',
    };
  }
}
