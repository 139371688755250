import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {LedgerLiveService} from './LedgerLiveService';

export type {Account, Currency} from './LedgerLiveService';
export {ExchangeType, FeesLevel} from './LedgerLiveService';

export const LEDGER_LIVE = __SERVICE_REGISTRY__.registerService(
  'LEDGER_LIVE',
  LedgerLiveService,
  require('../package.json').version
);
