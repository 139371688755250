import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {FiatDepositWizardChooseTickerBase} from './index.base';

export const FiatDepositWizardChooseTicker: React.FC = observer(() => {
  return (
    <Box pb={32}>
      <FiatDepositWizardChooseTickerBase />
    </Box>
  );
});
