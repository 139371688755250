import React from 'react';
import {observer} from 'mobx-react';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, Text} from '@youtoken/ui.primitives';
import {RolloverIn} from './RolloverIn';
import {getThemeColorForDirection} from '../../../utils';

export const CurrentResultRollover: React.FC<CurrentResultProps> = observer(
  ({hodl}) => {
    const {rolloverData} = hodl;

    return (
      <Box alignItems="flex-end">
        {!rolloverData?.active && (
          <Text color="$text-02" variant="$body-02">
            {hodl.relativeDate}
          </Text>
        )}
        {rolloverData?.active && <RolloverIn rolloverData={rolloverData} />}
      </Box>
    );
  }
);

interface CurrentResultProps {
  hodl: HODLItem;
}

export const CurrentResult: React.FC<CurrentResultProps> = observer(
  ({hodl}) => {
    const {
      hodlResultFormatted: {
        resultAmount,
        resultTicker,
        resultPercent,
        resultDirection,
      },
    } = hodl;

    const _directionColor = React.useMemo(() => {
      return getThemeColorForDirection(resultDirection);
    }, [resultDirection]);

    return (
      <Box alignItems="flex-end">
        <Box flexDirection="row">
          {resultAmount && (
            <Text color={_directionColor} variant="$body-01">
              {resultAmount} {resultTicker}
            </Text>
          )}
          {resultAmount && resultPercent && (
            <Text color={_directionColor} variant="$body-01-high-accent" mx={4}>
              ·
            </Text>
          )}
          {resultPercent && (
            <Text color={_directionColor} variant="$body-01-high-accent">
              {resultPercent}%
            </Text>
          )}
        </Box>
        <CurrentResultRollover hodl={hodl} />
      </Box>
    );
  }
);
