import {serializable} from 'serializr';
import Big from 'big.js';
import {bigNumber} from '@youtoken/ui.utils-serializr';

export class LoanTurboChainLoans {
  @serializable(bigNumber())
  borrowedAmount!: Big;

  @serializable(bigNumber())
  collateralAmount!: Big;

  @serializable(bigNumber())
  interest!: Big;

  @serializable(bigNumber())
  overdraftAmount!: Big;
}
