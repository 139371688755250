import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Modal} from '@youtoken/ui.modal';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {BoostYourTradingSurface} from '../BoostYourTradingSurface';
import {TradingBonusesSurface} from '../TradingBonusesSurface';

export type IncentivesDetailedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const IncentivesDetailedModal: React.FC<IncentivesDetailedModalProps> =
  observer(({isOpen, onClose}) => {
    const {t} = useTranslation();
    const {incentivesBalanceIsPositive} = IncentivesFeature.use({});

    return (
      <Modal
        isOpen={isOpen}
        shouldUseAnimatedSnapPoints
        shouldBeScrollable
        snapPoints={['CONTENT_HEIGHT']}
        onClose={onClose}
        title={
          incentivesBalanceIsPositive
            ? t('surface.incentives.bonuses.title')
            : undefined
        }
        nativeStackBehavior="push"
      >
        {incentivesBalanceIsPositive ? (
          <TradingBonusesSurface onClose={onClose} />
        ) : (
          <BoostYourTradingSurface onClose={onClose} />
        )}
      </Modal>
    );
  });
