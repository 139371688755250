import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {useTranslation} from '@youtoken/ui.service-i18n';

export type MembershipProps = {};

export const Membership: React.FC<MembershipProps> = observer(() => {
  const {legalInfo} = CompanyResource.use({});
  const {t} = useTranslation();

  return (
    <Box>
      <Text variant="$body-02" color="$text-02">
        {t('surface.disclosure_form.youhodler_info.member')}
      </Text>
      <Link
        url={legalInfo.youhodlerSroPolyreg.link}
        variant="$body-02"
        color="$text-01"
        mb={4}
      >
        {legalInfo.youhodlerSroPolyreg.name}
      </Link>
    </Box>
  );
});
