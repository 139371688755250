import {invariant} from '@youtoken/ui.utils';
import {routeToUrl} from './routeToUrl';
import {urlToRoute} from './urlToRoute';
import {Navigate, RouteHandlers, NotFoundHandler} from './types';

import EventEmitter from 'eventemitter3';

export class SharedRouterService {
  private _handlers?: RouteHandlers;
  private _backHandler?: () => void;
  private _notFoundHandler?: NotFoundHandler;

  public events = new EventEmitter<
    'navigate' | 'goBack' | 'notFound' | 'provideHandlers'
  >();

  /** navigate
   * @example
   * ```ts
   * SHARED_ROUTER_SERVICE.navigate('WalletsList', {}) // => void;
   * // => handler for `WalletsList` is called with ['WalletsList', {}] as ags
   * // if provideHandlers() was not called - will throw invariant violation;
   * ```
   */
  public navigate: Navigate = (name, params = {}, query = {}) => {
    invariant(
      this._handlers,
      'handlers was not provided! please call `provideHandlers` before using `navigate`'
    );

    this.events.emit('navigate', {name, params, query});

    if (this._handlers[name]) {
      // WTF actually, i have no idea what i've done but anyway
      // @ts-ignore
      this._handlers[name](name, params, query);
    } else {
      this.events.emit('notFound', name, params, query);
      this._notFoundHandler?.(name, params, query);
    }
  };

  public goBack = () => {
    invariant(
      this._backHandler,
      'backHandler was not provided! Please call `provideHandlers` before using `goBack`'
    );
    this.events.emit('goBack');
    this._backHandler?.();
  };

  /** provide handlers for routes */
  public provideHandlers = (
    handlers: RouteHandlers,
    backHandler: () => void,
    notFoundHandler?: NotFoundHandler
  ) => {
    this._handlers = handlers;
    this._backHandler = backHandler;
    this._notFoundHandler = notFoundHandler;

    this.events.emit('provideHandlers', {
      handlers,
      backHandler,
      notFoundHandler,
    });
  };

  /** matches url to Route, or returns `['NotFound', {}]`
   * @example
   * ```typescript
   * urlToRoute('wallets/btc') // => ['WalletsList', {ticker: 'btc'}]
   * ```
   */
  public urlToRoute = urlToRoute;

  /** build url from given Route, or returns `/not-found`
   * @example
   * ```typescript
   * routeToUrl('WalletsItem', {ticker: 'btc'}) // => '/wallets/btc'
   * ```
   * @
   */
  public routeToUrl = routeToUrl;
}
