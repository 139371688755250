import * as React from 'react';
import {observer} from 'mobx-react';
import {
  ResizableBoxWithOverlay,
  TradingViewModeSwitch,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';
import {
  ScreenNameMH,
  SmartTVChartContainerWeb,
} from '@youtoken/ui.trading-view-charts';
import {Separator} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {
  getMarginCallLineParams,
  getTakeProfitLineParams,
  getTriggerPriceLineParams,
} from '@youtoken/ui.hodls-utils';
import {useTheme} from '@youtoken/ui.primitives';

interface TradingViewChartSectionProps {
  onClose: () => void;
}

export const TradingViewChartSection: React.FC<TradingViewChartSectionProps> =
  observer(({onClose}) => {
    const {
      form: {hodlInstrument, isShort, precision},
      view: {chartData},
    } = useForm<CreateHODLFormClass>();

    const {colors} = useTheme();
    const lines = React.useMemo(() => {
      const takeProfitLine = getTakeProfitLineParams(
        precision,
        colors,
        chartData?.takeProfit,
        chartData?.maxProfit
      );
      const marginCallLine = getMarginCallLineParams(
        precision,
        colors,
        chartData?.marginCall,
        chartData?.maxLoss
      );
      const triggerPriceLine = getTriggerPriceLineParams(
        colors,
        chartData?.triggerPrice,
        chartData?.pending
      );
      return {takeProfitLine, marginCallLine, triggerPriceLine};
    }, [precision, colors, chartData]);

    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <ResizableBoxWithOverlay height={500} mb={8} width="100%" {...bind}>
        {Boolean(width && height) && (
          <SmartTVChartContainerWeb
            fromTicker={hodlInstrument.baseTicker}
            toTicker={hodlInstrument.quoteTicker}
            mode={isShort ? 'bid' : 'ask'} // NOTE: be careful, it is opposite for create deal and active deal
            takeProfitLine={lines.takeProfitLine}
            marginCallLine={lines.marginCallLine}
            triggerPriceLine={lines.triggerPriceLine}
            screenName={ScreenNameMH.CREATE_FORM}
            height={440}
          />
        )}
        <Separator />
        <TradingViewModeSwitch
          mode="tradingview"
          onChange={onClose}
          pt={15}
          pb={16}
          px={24}
        />
      </ResizableBoxWithOverlay>
    );
  });
