import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DemoFundsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.801 11.318c.151-.374.443-.685.876-.931-.667-.146-1.06-.603-1.177-1.373a1.706 1.706 0 01.038-.768 1.5 1.5 0 01.367-.62c.175-.18.375-.337.6-.47a5.48 5.48 0 01.794-.375l-.392-1.556.912-.26.38 1.513.498-.134.228-.059-.384-1.525.912-.26.392 1.556.285-.076.291-.081 1.254-.364.255 1.012-.657.188c-.3.085-.44.275-.421.57l.446 1.772-.068.022a.36.36 0 00-.025.011l.095-.027.625 2.482c.085.201.226.273.423.217l.657-.187.469 1.078-1.429.409-.4.118.397 1.575-.912.26-.392-1.557-.397.12-.324.092.39 1.55-.911.26-.397-1.575a7.085 7.085 0 01-.885.153 3.44 3.44 0 01-.773-.008 1.926 1.926 0 01-.666-.197 1.68 1.68 0 01-.52-.441 2.422 2.422 0 01-.387-.717c-.196-.557-.218-1.023-.067-1.397zm3.864-2.021L10.188 7.4l-.556.149c-.035.009-.073.02-.116.034l-.143.049-.081.03a3.333 3.333 0 00-.352.153 2.11 2.11 0 00-.3.195.95.95 0 00-.24.254.922.922 0 00-.116.31.875.875 0 00.017.383.9.9 0 00.133.3c.06.084.133.148.22.192.087.043.174.078.26.104a.992.992 0 00.317.032l.25-.013a2.157 2.157 0 00.31-.044l.35-.081.1-.026.424-.125zm-1.7 3.224a.876.876 0 01-.344-.504.89.89 0 01-.01-.42.993.993 0 01.147-.347c.068-.1.166-.194.293-.286.127-.091.247-.166.36-.224a3.55 3.55 0 01.522-.216l.17-.058.136-.041.209-.058.457-.12.526 2.088-.3.09-.25.073-.268.067-.19.042-.163.032-.162.024-.189.02a2.244 2.244 0 01-.35.007 1.978 1.978 0 01-.314-.05.823.823 0 01-.28-.119z"
          fill={color}
        />
        <Path
          d="M17.75 10a7.75 7.75 0 11-15.5 0 7.75 7.75 0 0115.5 0z"
          stroke={color}
          strokeWidth={1.5}
        />
      </Svg>
    );
  }
);
