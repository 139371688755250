import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {CopyButton} from '@youtoken/ui.elements';
import {
  FeatureCryptoDepositExternal,
  type FeatureCryptoDepositExternalArgs,
} from '../state';
import {CryptoDepositExternalExtraIdAlert} from './ExtraIdAlert';

export const CryptoDepositExternalExtraId: React.FC<FeatureCryptoDepositExternalArgs> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {
      walletTickerFormatted,
      walletVersionExtraId,
      walletVersionExtraIdName,
    } = FeatureCryptoDepositExternal.use({
      ticker,
    });

    if (!walletVersionExtraId) {
      return null;
    }

    return (
      <Box mt={24}>
        <Box alignItems="center">
          <Box width="100%" px={{desktop: 24, default: 16}}>
            <Text variant="$body-02" color="$text-02" textAlign="center" mb={4}>
              {t(`surface.wallets.crypto_deposit.${walletVersionExtraIdName}`)}
            </Text>
            <Text variant="$body-01-high-accent" textAlign="center" mb={10}>
              {walletVersionExtraId}
            </Text>
          </Box>
          <CopyButton
            icon="copy"
            size="small"
            type="secondary"
            testID="COPY_EXTRA_ID_BUTTON"
            message={walletVersionExtraId}
            messageCopied={t(
              `surface.wallets.crypto_deposit.${walletVersionExtraIdName}_copied`,
              {
                ticker: walletTickerFormatted,
              }
            )}
          >
            {t('surface.wallets.crypto_deposit.copy')}
          </CopyButton>
        </Box>
        <Box px={24}>
          <CryptoDepositExternalExtraIdAlert ticker={ticker} />
        </Box>
      </Box>
    );
  });
