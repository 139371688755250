import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Tab} from './Tab';
import {Underlay} from './Underlay';
import {type ITabsProps} from './types';

export const Tabs: React.FC<ITabsProps> = ({
  tabs,
  activeIndex = 0,
  onChange,
  size = 'small',
  type = 'default',
  disabled = false,
}) => {
  const setActiveIndex = React.useCallback(
    (index: number) => {
      onChange?.(tabs[index]!.value, index);
    },
    [tabs, onChange]
  );

  return (
    <Box
      accessibilityRole="tablist"
      backgroundColor={
        type === 'default'
          ? disabled
            ? '$ui-01'
            : '$interactive-02'
          : '$ui-background'
      }
      borderRadius={6}
      height={size === 'small' ? 32 : 28}
      flexDirection="row"
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            index={index}
            setActiveIndex={setActiveIndex}
            tab={tab}
            totalTabsCount={tabs.length}
            disabled={disabled}
            type={type}
            size={size}
          />
        );
      })}

      {activeIndex !== null && (
        <Underlay
          activeIndex={activeIndex}
          tabs={tabs}
          disabled={disabled}
          size={size}
          type={type}
        />
      )}
    </Box>
  );
};
