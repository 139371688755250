import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';

interface AdjustHODLButtonProps {
  version: number;
  onPress: () => void;
}

export const AdjustHODLButton: React.FC<AdjustHODLButtonProps> = ({
  version,
  onPress,
}) => {
  const {t} = useTranslation();

  return (
    <Button
      type="secondary"
      icon="ftp"
      flexGrow={1}
      onPress={onPress}
      flexBasis={{default: 'auto', desktop: 0}}
    >
      {version === 1
        ? t('surface.hodls.item.adjust_tp_version_1')
        : t('surface.hodls.item.adjust_tp_button')}
    </Button>
  );
};
