import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Separator} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';
import {FooterContext} from './FooterContext';
import {BankCardsAgreementsLink} from '..';
import {BankCardsFeature} from '../..';

export const Footer: React.FC = observer(() => {
  const {bottom} = useSafeAreaInsets();
  const context = React.useContext(FooterContext);
  const {hasAgreement} = BankCardsFeature.use({});

  const isNative = Platform.select({default: true, web: false});

  if (!context?.button) {
    return null;
  }

  return (
    <>
      {isNative && <Separator />}
      {hasAgreement && (
        <Box px={24} pt={isNative ? 16 : 0}>
          <Text variant="$body-02" color="$text-02">
            <Trans
              components={{
                Link: <BankCardsAgreementsLink />,
              }}
              i18nKey="surface.bank_cards.landing.agreement"
            />
          </Text>
        </Box>
      )}
      <Box mt={16} pb={isNative ? bottom : 24}>
        {context!.button}
      </Box>
    </>
  );
});
