import {comparer, computed} from 'mobx';
import {tickerToComboboxItem} from './utils';
import {ViewBase} from '../ViewBase';
import {Form as FormV1} from './v1/Form';
import {Form as FormV2} from './v2/Form';

export class View extends ViewBase<FormV1 | FormV2> {
  //#region wallets

  @computed({
    equals: comparer.shallow,
  })
  get wallets() {
    return this.form.tickers.map(ticker => {
      return tickerToComboboxItem(ticker);
    });
  }

  //#endregion wallets

  //#region conversionWallets

  @computed({
    equals: comparer.shallow,
  })
  get conversionWallets() {
    return this.form.conversionTickers.map(ticker => {
      return tickerToComboboxItem(ticker);
    });
  }

  //#endregion conversionWallets

  public constructor(form: FormV1 | FormV2) {
    super(form);
  }
}
