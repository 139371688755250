import * as React from 'react';
import {observer} from 'mobx-react';
import {GLOBAL} from '@youtoken/ui.service-global';
import {RootRedirect} from '@web-app/src/components/routes/RootRedirect';

/** __HOC for pages what requires auth;__
 *
 * will redirect users to `/sign-in` if they not authorized
 */
export const alreadyAuthorized = (Component: React.ComponentType<any>) => {
  const ComponentWithRedirect: React.FC = observer(props => {
    const {token} = GLOBAL;

    if (token) {
      return <RootRedirect />;
    }

    return <Component {...props} />;
  });

  ComponentWithRedirect.displayName = `authRequired(${
    Component.displayName || ''
  }))`;

  return ComponentWithRedirect;
};
