import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Banner, Link} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {type CryptoDepositBannerByVersionProps} from '../types';

const bannerTestId = 'CRYPTO_WITHDRAWAL_BANNER';
const bannerLinkTestId = 'CRYPTO_WITHDRAWAL_BANNER_LINK';

export const CryptoWithdrawalBannerByVersion: React.FC<CryptoDepositBannerByVersionProps> =
  observer(({ticker, walletVersion}) => {
    const {t} = useTranslation();

    if (ticker === 'btc') {
      if (walletVersion === 'bep20') {
        return null;
      }

      return (
        <Box mt={24}>
          <Banner variant="success" testID={bannerTestId}>
            <Trans
              t={t}
              components={{
                Link: (
                  <Link
                    url={t(
                      'surface.wallets.crypto_deposit.url_btc_address_format'
                    )}
                    testID={bannerLinkTestId}
                  />
                ),
              }}
              i18nKey="surface.wallets.crypto_withdrawal.address_description_btc"
            />
          </Banner>
          {/* this is special case for bitcoin */}
          {/* see: WL-1984  BTC blockchain fee add info - https://yhdl.slack.com/archives/D02HPNYTTS9/p1703844171986399 */}
          <Banner variant="attention" testID={bannerTestId} mt={8}>
            <Trans
              t={t}
              i18nKey="surface.wallets.crypto_deposit.btc_fee_banner"
              components={{
                Link: <Link url="https://blockchair.com/bitcoin" />,
              }}
            />
          </Banner>
        </Box>
      );
    }

    return null;
  });
