import {
  observable,
  action,
  computed,
  reaction,
  type IReactionDisposer,
  runInAction,
} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {i18n} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {getExtraIdNameByTicker, walletVersionsToItems} from '../../utils';

export interface FeatureCryptoDepositExternalArgs {
  ticker: string;
}

export interface FeatureCryptoDepositExternalResource {
  authMe: AuthMeResource;
  wallets: WalletsResource;
  feeAll: FeeAllResource;
}

export class FeatureCryptoDepositExternal extends createFeature({
  getKey: (args: FeatureCryptoDepositExternalArgs) =>
    `CryptoDepositExternal(${args.ticker})`,
  getResources: (_args: FeatureCryptoDepositExternalResource) => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      feeAll: getResourceDescriptor(FeeAllResource, {}),
    };
  },
}) {
  @observable
  public addressError: string | null = null;

  private disposers: Array<IReactionDisposer> = [];

  @computed
  public get wallet() {
    return this.resources.wallets.getByTicker(this.args.ticker)!;
  }

  @computed
  public get walletTicker() {
    return this.wallet.ticker;
  }

  @computed
  public get walletTickerFormatted() {
    return this.walletTicker.toUpperCase();
  }

  @observable
  walletVersionValue: null | string = null;

  @computed
  public get walletVersions() {
    return walletVersionsToItems(
      this.wallet.versions,
      '{{version}}_DEPOSIT_TAB'
    );
  }

  @computed
  public get walletVersion() {
    return this.walletVersionValue !== null
      ? this.walletVersions.find(walletVersion => {
          return walletVersion.value === this.walletVersionValue;
        })
      : null;
  }

  @computed
  public get walletVersionLabel() {
    return this.walletVersion?.label;
  }

  @computed
  public get walletVersionAddress() {
    return this.walletVersion?.address;
  }

  @computed
  public get walletVersionExtraId() {
    return this.walletVersion?.extraId;
  }

  @computed
  public get walletVersionExtraIdName() {
    return getExtraIdNameByTicker(this.walletTicker);
  }

  @computed.struct
  get fee() {
    return this.walletVersionValue
      ? this.resources.feeAll.getFeeForDeposit(
          this.walletVersionValue,
          this.walletTicker
        )
      : undefined;
  }

  @computed
  get hasFee() {
    return Boolean(this.fee);
  }

  @computed.struct
  get feeMinAmount() {
    return this.fee?.minAmount;
  }

  @action
  setWalletVersionValue = (value: string | null) => {
    this.walletVersionValue = value;
  };

  @action
  private createAddress = () => {
    if (!this.walletVersionAddress) {
      this.resources.wallets
        .createWalletAddress(this.walletTicker, this.walletVersion?.value)
        .catch(error => {
          runInAction(() => {
            const _error = extractErrorFromResponse(
              error.response?.data,
              '_error'
            );

            if (_error && _error.i18n?.label === 'MISSING_FORM_A_SIGNED') {
              LOCAL_NOTIFICATIONS.error({
                text: i18n.t('validation.MISSING_FORM_A_SIGNED'),
              });
              SHARED_ROUTER_SERVICE.navigate('FormAVerificationModal', {});
              return;
            }

            const _errorTranslated = getTranslatedValidationMessage(_error);
            this.addressError =
              _errorTranslated ||
              i18n.t('surface.wallets.crypto_deposit.warning.try_again');
          });
        });
    }
  };

  @action
  public retryCreateAddress = () => {
    this.addressError = null;
    this.createAddress();
  };

  constructor(
    args: FeatureCryptoDepositExternalArgs,
    resources: FeatureCryptoDepositExternalResource
  ) {
    super(args, resources);

    this.disposers.push(
      reaction(
        () => this.walletVersionAddress,
        () => {
          if (this.walletVersion) {
            this.createAddress();
          }
        },
        {fireImmediately: true}
      )
    );
  }

  public onDestroy() {
    super.onDestroy();
    this.disposers?.forEach(disposer => disposer?.());
  }
}
