import * as React from 'react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type LevelStatus} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {LevelTermSlot} from '.';

type LevelTermsTableProps = {
  variant: LevelStatus;
  miningCost: number;
  miningPeriod: string;
  blocksAvailable: number;
  freeSparksAmount?: number;
  freeSparksPeriod?: string;
  freeSparksTerminatePeriodFormatted?: string;
} & BoxProps;

export const LevelTermsTable: React.FC<LevelTermsTableProps> = ({
  variant,
  miningCost,
  miningPeriod,
  blocksAvailable,
  freeSparksAmount,
  freeSparksPeriod,
  freeSparksTerminatePeriodFormatted,
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <Box borderRadius={12} overflow="hidden" {...boxProps}>
      <Box flexDirection="row">
        <LevelTermSlot
          title={t('surface.miner.loyalty.speed')}
          iconName="pending"
          text={miningPeriod}
          variant={variant}
        />
        <Box width={1} backgroundColor="$transparent" />
        <LevelTermSlot
          title={t('surface.miner.loyalty.available')}
          iconName="hexagon"
          text={blocksAvailable.toString()}
          variant={variant}
        />
      </Box>

      <Separator backgroundColor="$transparent" />

      <Box flexDirection="row">
        <LevelTermSlot
          title={t('surface.miner.loyalty.cost')}
          iconName="hexagon_spark"
          text={miningCost.toString()}
          variant={variant}
        />
        <Box width={1} backgroundColor="$transparent" />

        {freeSparksTerminatePeriodFormatted ? (
          <LevelTermSlot
            title={
              <Trans
                t={t}
                i18nKey={'surface.miner.loyalty.free_sparks_terminate_period'}
                components={{
                  Strong: <Text weight="bold" color="$text-01" />,
                }}
                values={{
                  period: freeSparksTerminatePeriodFormatted,
                }}
              />
            }
            titleOnly={true}
            variant={variant}
          />
        ) : (
          <LevelTermSlot
            title={
              freeSparksAmount && freeSparksPeriod
                ? t('surface.miner.loyalty.free_every')
                : t('surface.miner.loyalty.no_free')
            }
            titleOnly={!freeSparksAmount || !freeSparksPeriod}
            iconName="spark"
            text={freeSparksPeriod}
            variant={variant}
          />
        )}
      </Box>
    </Box>
  );
};
