import {observable} from 'mobx';
import {serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';

export class CalculateExtendResult {
  @observable
  @serializable(number())
  maxLoss!: number;

  @observable
  @serializable(number())
  maxProfit!: number;

  @observable
  @serializable(number())
  mcPrice!: number;

  @observable
  @serializable(number())
  ftpPrice!: number;

  @observable
  @serializable(number())
  initialPrice!: number;

  @observable
  @serializable(number())
  inputAmount!: number;
}
