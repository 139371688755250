import * as React from 'react';
import {ScrollView} from 'react-native';

export const ScrollWrapper: React.FC = ({children}) => {
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {children}
    </ScrollView>
  );
};
