import {computed, observable} from 'mobx';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {Form} from './Form';

export class View {
  @observable
  public form: Form;

  constructor(form: Form) {
    this.form = form;
  }

  @computed public get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed public get receiveTickerFormatted() {
    return this.form.recipientData?.receiver.ticker.toUpperCase() ?? '';
  }

  @computed public get maxReceiveAmountFormatted() {
    return this.form.recipientData?.receiver.maxAmount.toString() ?? '';
  }

  @computed public get minReceiveAmountFormatted() {
    return this.form.recipientData?.receiver.minAmount.toString() ?? '';
  }

  @computed public get maxSendAmountFormatted() {
    return this.form.recipientData?.sender.maxAmount.toString() ?? '';
  }

  @computed public get minSendAmountFormatted() {
    return this.form.recipientData?.sender.minAmount.toString() ?? '';
  }

  @computed public get umaError() {
    return getTranslatedValidationMessage(this.form.instance.$('uma').error);
  }

  @computed public get sendAmountError() {
    return getTranslatedValidationMessage(
      this.form.instance.$('sendAmount').error
    );
  }

  @computed public get getAmountError() {
    return getTranslatedValidationMessage(
      this.form.instance.$('getAmount').error
    );
  }
}
