import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {PleaseVerifyContent} from './Content';
import {PleaseVerifyFooter} from './Footer';

export const PleaseVerify: React.FC<BoxProps> = observer(boxProps => {
  return (
    <Box flex={1} {...boxProps}>
      <PleaseVerifyContent />
      <PleaseVerifyFooter />
    </Box>
  );
});
