import * as React from 'react';
import {observer} from 'mobx-react';
import {getMultiplySliderButtons} from '@youtoken/ui.hodls-utils';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Slider} from '@youtoken/ui.legacy-slider';
import {SliderLabel} from './SliderLabel';

interface MultiplySliderProps extends BoxProps {
  value: number;
  onChange: (value: number) => void;
  sliderKey: string;
  minimumValue: number;
  maximumValue: number;
  loansInChain?: number;
}

export const MultiplySlider: React.FC<MultiplySliderProps> = observer(
  ({
    value,
    onChange,
    sliderKey,
    minimumValue,
    maximumValue,
    loansInChain,
    ...boxProps
  }) => {
    const {t} = useTranslation();
    const labelRenderer = React.useCallback(
      ({value: valueFromSlider}: {value: number}) => (
        <SliderLabel
          text={t('surface.hodls.hodl_form.multiply_label')}
          secondaryText={
            loansInChain
              ? t('surface.hodls.loans', {
                  loansInChain,
                })
              : undefined
          }
          value={valueFromSlider}
        />
      ),
      [t, loansInChain]
    );

    const buttons = React.useMemo(
      () => getMultiplySliderButtons(minimumValue, maximumValue),
      [minimumValue, maximumValue]
    );

    return (
      <Box {...boxProps}>
        <Slider
          value={value}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          renderLabel={labelRenderer}
          showLegend={false}
          buttons={buttons}
          buttonsSize="small"
          onChange={onChange}
          key={sliderKey}
        />
      </Box>
    );
  }
);
