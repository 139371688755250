import * as React from 'react';
import {observer} from 'mobx-react';
import {type RouteComponentProps, Route, Redirect} from 'react-router-dom';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {RewardsMainPage, RewardsReferralPage} from '../pages';

export const ProfileRewardsRoutes: React.FC<RouteComponentProps> = observer(
  ({match: {path}}) => {
    const {
      data: {rewardVersion},
      products: {rewardsChallenge},
    } = AuthMeResource.use({});

    const rewardsMainPage =
      rewardVersion === 3 && !rewardsChallenge.available ? 'referrals' : 'main';

    return (
      <SwitchWithNotFound>
        <Route
          exact
          path={path}
          render={() => {
            if (rewardsMainPage === 'referrals') {
              return <RewardsReferralPage />;
            }

            return <RewardsMainPage />;
          }}
        />
        <Route
          exact
          path={`${path}/referrals`}
          render={() => {
            if (rewardsMainPage === 'referrals') {
              return <Redirect to={path} />;
            }

            return <RewardsReferralPage />;
          }}
        />
      </SwitchWithNotFound>
    );
  }
);
