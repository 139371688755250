import * as React from 'react';
import {Box, Heading} from '@youtoken/ui.primitives';

type SectionTitleProps = {
  title: string;
  subtitleElement?: React.ReactNode;
};

export const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  subtitleElement,
}) => {
  return (
    <Box p={24} pb={16} borderBottomWidth={1} borderColor="$ui-01">
      <Heading variant="$heading-03">{title}</Heading>

      {React.isValidElement(subtitleElement) && (
        <Box mt={4}>{subtitleElement}</Box>
      )}
    </Box>
  );
};
