import * as React from 'react';
import {
  Box,
  Heading,
  Text,
  type BoxProps,
  AppearanceAndThemeProvider,
} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
// TODO: remove this hack?!
import {Fonts} from '@web-app/src/components/styles/GlobalStyles/Fonts';
import {
  AppEnv,
  getAppEnvForWeb,
  setAppEnvForWeb,
  getDisplayUrl,
} from '@youtoken/ui.env-utils';

type EnvItemProps = BoxProps & {
  env: AppEnv;
  selected: boolean;
  onSelect: (env: AppEnv) => void;
};

const EnvItem: React.FC<EnvItemProps> = ({
  onSelect,
  selected,
  env,
  ...props
}) => {
  return (
    <Box
      px={{default: 16, phone: 24}}
      py={16}
      borderBottomWidth={1}
      borderColor="$ui-01"
      {...props}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Text variant="$body-01-high-accent">{env}</Text>
        <Text mt={8}>
          <Text variant="$body-01-medium-accent" color="$text-02">
            {getDisplayUrl(env)}
          </Text>
        </Text>
      </Box>
      <Button
        color={selected ? 'success' : 'interactive'}
        type="secondary"
        onPress={() => onSelect(env)}
      >
        {selected ? 'Selected' : 'Select'}
      </Button>
    </Box>
  );
};

export const EnvSelector: React.FC = () => {
  const selectedEnv = getAppEnvForWeb();

  const [env, setEnv] = React.useState(selectedEnv);

  const selectEnv = React.useCallback((env: AppEnv) => {
    setEnv(env);
    setAppEnvForWeb(env);
  }, []);

  return (
    <AppearanceAndThemeProvider>
      <Fonts />
      <Box
        flex={1}
        justifyContent="center"
        alignItems="center"
        py={36}
        backgroundColor="$ui-background"
      >
        <Heading mb={24} variant="$heading-01-responsive">
          Select environment
        </Heading>

        <Box
          borderColor="$ui-01"
          borderBottomWidth={1}
          borderTopWidth={1}
          borderLeftWidth={{
            default: 0,
            tablet: 1,
          }}
          borderRightWidth={{
            default: 0,
            tablet: 1,
          }}
          flex={1}
          width={{default: '100%', tablet: 720}}
          borderRadius={{default: 0, tablet: 6}}
        >
          {(Object.keys(AppEnv) as AppEnv[]).map(_env => {
            if (_env === AppEnv.local) {
              return null;
            }

            return (
              <EnvItem
                key={_env}
                env={_env}
                onSelect={() => {
                  selectEnv(_env);
                }}
                selected={env === _env}
              />
            );
          })}

          <Box px={{default: 16, phone: 24}} py={16}>
            <Button onPress={() => window.location.reload()}>Continue</Button>
          </Box>
        </Box>
      </Box>
    </AppearanceAndThemeProvider>
  );
};
