import {
  action,
  computed,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  _allowStateChangesInsideComputed,
} from 'mobx';
import {Fetcher} from './ResourceFeatures';
import {BaseResourceInstance} from './types';

/** Base Resource super-class, abstract.
 * Implements only Fetcher, no garbage collection, no intervals, no fancy stuff;
 * Only observable data and interface to refresh data / loading status;
 * - makes `data` and `args` observable;
 * - tracks if `data` actually observed by someone;
 * - disposes of internal reactions and fetcher reactions;
 * - provides interface for base Resource functionality:
 *   - isLoading
 *   - isLoadingManually (with loading state);
 *   - isError
 *   - refetch(mode: "SOFT" | "HARD")
 *   - refetchSilently()
 *   - refetchManually()
 */
export class BaseResource<Args extends {}, Result extends any>
  implements BaseResourceInstance<Args, Result>
{
  @observable args!: Args;
  @observable data!: Result;

  getKey!: (args: Args) => string;
  getData!: (args: Args) => Promise<Result>;
  skipRefreshOnVisible!: boolean;
  shouldBeDeletedOnCleanup!: boolean;

  fetcher!: Fetcher<Args, Result>;

  _loggerEnabled: boolean = false;

  enableLogger() {
    this._loggerEnabled = true;
  }

  disableLogger() {
    this._loggerEnabled = false;
  }

  disposeOfReactionToBecomeObserved: ReturnType<typeof onBecomeObserved>;
  disposeOfReactionToBecomeUnobserved: ReturnType<typeof onBecomeUnobserved>;

  onInit() {
    this.logger('onInit', 'from BaseResource');
  }

  onDestroy() {
    this.fetcher.onDestroy();
    this.disposeOfReactionToBecomeObserved();
    this.disposeOfReactionToBecomeUnobserved();
    this.logger('onDestroy', 'from BaseResource');
  }

  onFetchStarted() {}
  onFetchFinished() {}
  onFetchFailed() {}
  onFetchSucceeded() {}

  // tracks if data of resource is observed by anyone;
  // will be used by resources "features" (canceling intervals, running requests etc);
  @observable isDataObserved: boolean = false;

  logger = (..._args: any[]) => {
    if (this._loggerEnabled) {
      const key = this.getKey(this.args);
      const [method, ...args] = _args;

      console.log(`[${key}]`, `[${method}]`, ...args);
    }
  };

  _refetchErrorPolicy!: 'throw' | 'contain';

  constructor(
    args: Args,
    data: Result,
    getKey: (args: Args) => string,
    getData: (args: Args) => Promise<Result>,
    refetchErrorPolicy: 'throw' | 'contain' = 'contain',
    skipRefreshOnVisible: boolean = true,
    shouldBeDeletedOnCleanup: boolean = true
  ) {
    this.args = args;
    this.data = data;
    this.getKey = getKey;
    this.getData = getData;
    this.fetcher = new Fetcher(this);
    this.skipRefreshOnVisible = skipRefreshOnVisible;
    this.shouldBeDeletedOnCleanup = shouldBeDeletedOnCleanup;
    this._refetchErrorPolicy = refetchErrorPolicy;
    this.disposeOfReactionToBecomeObserved = onBecomeObserved(
      this,
      'data',
      () => {
        this.logger('onBecomeObserved');
        // then data is read inside the @computed without this wrapper it will cause the error
        // without this only reading data in component is a valid way
        // https://github.com/mobxjs/mobx/issues/1910#issuecomment-466403521
        _allowStateChangesInsideComputed(() => {
          this.isDataObserved = true;
        });
      }
    );

    this.disposeOfReactionToBecomeUnobserved = onBecomeUnobserved(
      this,
      'data',
      () => {
        this.logger('onBecomeUnobserved');
        // then data is read inside the @computed without this wrapper it will cause the error
        // without this only reading data in component is a valid way
        // https://github.com/mobxjs/mobx/issues/1910#issuecomment-466403521
        _allowStateChangesInsideComputed(() => {
          this.isDataObserved = false;
        });
      }
    );

    this.onInit();
  }

  @action refetch = (mode: 'SOFT' | 'HARD' = 'SOFT') => {
    this.logger('refetch', `mode = ${mode}`);
    return this.fetcher.refetch(mode);
  };

  @action refetchManually = () => {
    this.logger('refetchManually');
    return this.fetcher.refresh('HARD');
  };

  @action refetchSilently = () => {
    this.logger('refetchSilently');
    return this.fetcher.refetch('SOFT');
  };

  @action assignData = (data: Result) => {
    this.data = data;
  };

  @computed get isLoading() {
    return this.fetcher.isLoading;
  }

  @computed get isLoadingManually() {
    return this.fetcher.isLoadingManually;
  }

  @computed get isLoadingSilently() {
    return this.fetcher.isLoadingSilently;
  }

  @computed get isError() {
    return this.fetcher.isError;
  }
}
