import * as React from 'react';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {useTranslation} from '@youtoken/ui.service-i18n';

type ButtonsSectionProps = {
  item: HistoryItemData;
  onAgreementClick: () => void;
  onInvoiceClick: () => void;
  onExplorerClick: () => void;
  onRepeatClick: () => void;
};

export const ButtonsSection: React.FC<ButtonsSectionProps> = ({
  item,
  onAgreementClick,
  onInvoiceClick,
  onExplorerClick,
  onRepeatClick,
}) => {
  const {type, isConvert, hasExplorerUrl} = item;

  const {t} = useTranslation();

  return (
    <>
      <Box width="100%" flexDirection="row" px={24}>
        {isConvert && (
          <TouchableBox
            onPress={onAgreementClick}
            flexDirection="row"
            alignItems="center"
            mt={16}
          >
            <Icon name="document" color="$text-05" />
            <Text variant="$body-02-medium-accent" color="$text-05">
              {t('surface.history.item.agreement')}
            </Text>
          </TouchableBox>
        )}
        {type === 'LOAN_PAYMENT' && (
          <Button
            flexGrow={1}
            type="secondary"
            icon="document"
            mx={8}
            mt={24}
            onPress={onInvoiceClick}
          >
            {t('surface.history.item.invoice')}
          </Button>
        )}
        {hasExplorerUrl && (
          <Button
            flexGrow={1}
            type="secondary"
            icon="blockexplorer"
            mt={24}
            onPress={onExplorerClick}
          >
            {t('surface.history.item.check_on_explorer_name', {
              explorerName: item.explorerName,
            })}
          </Button>
        )}
      </Box>
      {isConvert && (
        <Box width="100%" flexDirection="column" px={24} mt={24}>
          <Button type="secondary" flexGrow={1} onPress={onRepeatClick}>
            {t('surface.history.item.repeat_convert')}
          </Button>
        </Box>
      )}
    </>
  );
};
