import {observable, action, computed} from 'mobx';
import {now as mobxUtilsNow} from 'mobx-utils/lib/now';
import {add, format, formatDuration} from '@youtoken/ui.date-fns';
import {formatPercentTillPrecision, toBig} from '@youtoken/ui.formatting-utils';
import {
  getCoinDecimalPrecision,
  getCoinDecimalPrecisionForLoans,
} from '@youtoken/ui.coin-utils';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {TariffsLoansRegularResource} from '@youtoken/ui.resource-loans';
import {
  getMinInterestAmount,
  getInterestAmount,
  formatInterestAmount,
  getMaxDurationLabel,
  getMaxDurationValue,
} from '../../utils';
import {Form} from './Form';

export interface FeatureLoanCreateFormArgs {}

export interface FeatureLoanCreateFormResources {
  authMeResource: AuthMeResource;
  docsResource: DocsResource;
  walletsResource: WalletsResource;
  ratesResource: RatesResource;
  tariffsResource: TariffsLoansRegularResource;
  verificationResource: VerificationResource;
}

export class FeatureLoanCreateForm extends createFeature({
  getKey: (_args: FeatureLoanCreateFormArgs) => `LoanRegularCreateForm`,
  getResources: (_args: FeatureLoanCreateFormArgs) => {
    const authMeResource = AuthMeResource.use({});

    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: authMeResource.residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      tariffsResource: getResourceDescriptor(TariffsLoansRegularResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  @observable
  public restored = false;

  @computed
  public get submitting() {
    return this.form.instance.submitting;
  }

  @computed
  public get enabled() {
    return this.resources.authMeResource.data.enableGetLoan;
  }

  @computed
  public get ticker() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  public get conversionTicker() {
    return this.form.conversionTicker.toUpperCase();
  }

  @computed
  public get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.form.conversionTicker);
  }

  @computed
  public get conversionAmount() {
    return toBig(this.form.conversionAmount)
      .round(getCoinDecimalPrecision(this.form.conversionTicker))
      .toString();
  }

  @computed
  public get pdlPercent() {
    return formatPercentTillPrecision(this._term?.marginCall, 4);
  }

  @computed
  public get pdlPrice() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.rate)
        .mul(toBig(1).plus(toBig(this._term?.marginCall)))
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toString()
    );
  }

  @computed
  public get apr() {
    return formatPercentTillPrecision(this._term?.apr, 4);
  }

  @computed
  public get penaltyAPR() {
    return formatPercentTillPrecision(this._term?.penaltyAPR, 4);
  }

  @computed
  public get settlementPeriod() {
    return this._term?.settlementPeriodFormatted;
  }

  @computed
  public get interest() {
    return formatPercentTillPrecision(this._term?.aprBySettlementPeriod, 4);
  }

  @computed
  public get interestMultiplier() {
    return formatPercentTillPrecision(
      this._term?.penaltyAprBySettlementPeriod,
      4
    );
  }

  @computed
  public get interestAmountMin() {
    return (
      this._term?.aprBySettlementPeriod &&
      getMinInterestAmount(
        this._term.aprBySettlementPeriod,
        this.conversionTickerPrecision
      )
    );
  }

  @computed
  public get interestAmount() {
    return (
      this._term?.aprBySettlementPeriod &&
      this.interestAmountMin &&
      getInterestAmount(
        this._term.aprBySettlementPeriod,
        toBig(this.form.conversionAmount),
        this.interestAmountMin
      )
    );
  }

  @computed
  public get interestAmountFormatted() {
    return (
      this.interestAmount &&
      formatInterestAmount(this.interestAmount, this.conversionTickerPrecision)
    );
  }

  @computed
  public get canSetTP() {
    return Boolean(this._termSetting?.minTP && this._termSetting?.maxTP);
  }

  @computed
  public get minTP() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.rate)
        .mul(toBig(this._termSetting?.minTP))
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toString()
    );
  }

  @computed
  public get maxTP() {
    return (
      this.form.conversionTicker &&
      toBig(this.form.rate)
        .mul(toBig(this._termSetting?.maxTP))
        .round(getCoinDecimalPrecisionForLoans(this.form.conversionTicker))
        .toString()
    );
  }

  @computed
  public get maxDurationFormatted() {
    return this._term && formatDuration(this._term?.maxDuration);
  }

  @computed
  public get maxDurationValue() {
    return (
      this.maxDurationFormatted &&
      getMaxDurationValue(this.maxDurationFormatted)
    );
  }

  @computed
  public get maxDurationLabel() {
    return (
      this.maxDurationFormatted &&
      getMaxDurationLabel(this.maxDurationFormatted)
    );
  }

  @computed
  public get repayUntil() {
    return (
      this._term &&
      format(add(mobxUtilsNow(), this._term.maxDuration), 'MMM dd, yyyy, HH:mm')
    );
  }

  @computed.struct
  public get _term() {
    return this.form.term;
  }

  @computed.struct
  private get _termSetting() {
    return this._term?.settings.find(
      setting => setting.borrowedTicker === this.form.conversionTicker
    );
  }

  @action
  public restore = () => {
    this.form.restore();
    this.restored = true;
  };

  @action
  public reset = () => {
    this.restored = false;
    this.form.reset();
  };

  constructor(
    args: FeatureLoanCreateFormArgs,
    resources: FeatureLoanCreateFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
  }
}
