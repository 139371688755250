import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';
import {type AssetsItemProps} from '../types';

export const AssetsItemSummary: React.FC<AssetsItemProps & BoxProps> = ({
  primaryTicker,
  primaryAmountFormatted,
  primaryExtension,
  secondaryTicker,
  secondaryAmountFormatted,
  secondaryExtension,
  ...boxProps
}) => {
  return (
    <Box
      testID="ASSETS_ITEM_SUMMARY"
      flexDirection="row"
      alignItems="center"
      {...boxProps}
    >
      <LogoColored name={primaryTicker as LogoColoredIconName} size={32} />
      <Box ml={8}>
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Text variant="$body-01" color="$text-02">
            <Text
              testID="ASSETS_ITEM_SUMMARY_PRIMARY_AMOUNT"
              variant="$body-01-high-accent"
              color="$text-01"
            >
              {primaryAmountFormatted}
            </Text>{' '}
            <Text testID="ASSETS_ITEM_SUMMARY_PRIMARY_TICKER">
              {primaryTicker.toUpperCase()}
            </Text>
          </Text>
          {primaryExtension}
        </Box>

        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mt={2}
        >
          <Text variant="$body-02" color="$text-02">
            <Text testID="ASSETS_ITEM_SUMMARY_SECONDARY_AMOUNT">
              {secondaryAmountFormatted}
            </Text>{' '}
            <Text testID="ASSETS_ITEM_SUMMARY_SECONDARY_TICKER">
              {secondaryTicker.toUpperCase()}
            </Text>
          </Text>
          {secondaryExtension}
        </Box>
      </Box>
    </Box>
  );
};
