import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {
  type AccountLevelRequirementsProps,
  AccountLevelRequirements,
} from '../../../AccountLevelRequirements';

export const AccountLevelAllBenefitsRequirements: React.FC<AccountLevelRequirementsProps> =
  observer(({id}) => {
    const {t} = useTranslation();

    const {
      data: {currentLevel},
    } = LoyaltyResource.use({});

    if (id === currentLevel) {
      return null;
    }

    return <AccountLevelRequirements id={id} />;
  });
