import * as React from 'react';
import {Rect} from 'react-native-svg';
import {ContentLoader} from '../ContentLoader';

export const ChartWithoutControlsSuspenseFallback = React.memo<{
  width: number;
  height: number;
}>(({width, height}) => {
  return (
    <ContentLoader width={width} height={height}>
      {/*  chart itself */}
      <Rect x={0} y={0} width={width} height={height} />
    </ContentLoader>
  );
});
