import * as React from 'react';
import {i18n} from '@youtoken/ui.service-i18n';
import {TextInput, TextInputProps} from '../TextInput';
import {SecureModeSwitcher} from './SecureModeSwitcher';

export const PasswordTextInput: React.FC<TextInputProps> = ({...props}) => {
  const [secureModeEnabled, setSecureMode] = React.useState(true);

  const toggleSecureMode = React.useCallback(() => {
    setSecureMode(v => !v);
  }, []);

  const RightPartComponent = React.useMemo(() => {
    return ({hasError, focused}: {hasError?: boolean; focused?: boolean}) => {
      return (
        <SecureModeSwitcher
          onPress={toggleSecureMode}
          secureModeEnabled={secureModeEnabled}
          hasError={hasError}
          focused={focused}
        />
      );
    };
  }, [secureModeEnabled, toggleSecureMode]);

  return (
    <TextInput
      placeholder={i18n.t('inputs.password.placeholder')}
      secureTextEntry={secureModeEnabled}
      textContentType="password"
      autoCapitalize="none"
      autoCorrect={false}
      autoComplete="password"
      importantForAutofill="yes"
      RightPartComponent={RightPartComponent}
      {...props}
    />
  );
};
