import * as React from 'react';
import {i18n} from '@youtoken/ui.service-i18n';
import {type ThemeNames, themes} from '@youtoken/ui.primitives';

export const useLandingTheme = (themeName: ThemeNames) => {
  return React.useMemo(() => {
    const {breakpoints, ...rest} = themes[themeName] ?? themes['light'];

    return {
      breakpoints: {
        ...breakpoints,
        tablet: 480,
      },
      ...rest,
    };
  }, [themes, themeName]);
};

export const useLandingEffectChangeLanguage = (lang: string) => {
  React.useEffect(() => {
    // Language can be changed by autodetection via timeout, fix it, and hard set lang from route
    if (i18n.languagesList.includes(lang) && i18n.language !== lang) {
      i18n.i18next.changeLanguage(lang);
    }
  }, [i18n.language, lang]);
};
