import {warning} from '@youtoken/ui.utils';
import {generateDeviceID} from './generateDeviceID';

const KEY = 'x-uid';

export const getDeviceID = (): string => {
  if (!window.localStorage) {
    warning(false, 'localStorage is unaccessible new id will be returned');

    return generateDeviceID();
  }

  const storedID = localStorage.getItem(KEY);

  if (storedID) {
    return storedID;
  }

  const newID = generateDeviceID();

  localStorage.setItem(KEY, newID);

  return newID;
};
