import * as React from 'react';
import {G} from 'react-native-svg';
import {useObserver} from 'mobx-react';
import {
  Wrap,
  GridLabels,
  GridLines,
  LastValueLabel,
  LastValueLine,
  DataLine,
  PastDataLine,
  FutureDataLine,
  LineChartCursor,
  CandlesChartCursor,
  DataCandles,
  MaxProfitLabel,
  MaxLossLabel,
  InitialPriceLabel,
  TakeProfitLabel,
  MarginCallLabel,
  TakeProfitLine,
  MarginCallLine,
  InitialPriceLine,
  HODLHighlightArea,
  ActivityIndicator,
  TriggerPriceLine,
  TriggerPriceLabel,
  TriggerPriceNameLabel,
} from '../Elements';
import {
  HODLChartState,
  HODLChartStateProps,
  useLocalChartState,
} from '../logic';

import {ChartThemeProvider} from '../Providers';
import {NoDataState} from './NoDataState';

export const HODLChart: React.FC<HODLChartStateProps> = React.memo(
  ({children, refetch, ...props}) => {
    const state = useLocalChartState(HODLChartState, props);

    return useObserver(() => {
      if (!state.hasData) {
        return <NoDataState refetch={refetch} />;
      }

      return (
        <ChartThemeProvider>
          <Wrap chartState={state}>
            <GridLines chartState={state} />
            <GridLabels chartState={state} />

            {state.type === 'line' && (
              <G>
                <PastDataLine chartState={state} />
                <FutureDataLine chartState={state} />
                <DataLine chartState={state} />
              </G>
            )}

            {state.type === 'candle' && (
              <G>
                <DataCandles chartState={state} />
              </G>
            )}

            <G>
              {!state.pending && <InitialPriceLine chartState={state} />}
              <TakeProfitLine chartState={state} />
              <MarginCallLine chartState={state} />
              <LastValueLine chartState={state} />
              {state.pending && <TriggerPriceLine chartState={state} />}
            </G>

            <G>
              <MaxProfitLabel chartState={state} />
              <MaxLossLabel chartState={state} />
              <TakeProfitLabel chartState={state} />
              <LastValueLabel chartState={state} />
              <MarginCallLabel chartState={state} />
              {state.pending && <TriggerPriceLabel chartState={state} />}
              {state.pending && <TriggerPriceNameLabel chartState={state} />}
            </G>

            {state.type === 'line' && !state.pending && (
              <HODLHighlightArea chartState={state} />
            )}

            <G>{!state.pending && <InitialPriceLabel chartState={state} />}</G>

            {state.type === 'line' && <LineChartCursor chartState={state} />}
            {state.type === 'candle' && (
              <CandlesChartCursor chartState={state} />
            )}
          </Wrap>
          {props.displayActivityIndicator && (
            <ActivityIndicator chartState={state} />
          )}
        </ChartThemeProvider>
      );
    }, 'HODLChart');
  }
);
