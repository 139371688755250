import {observable} from 'mobx';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {HODLByIdResource} from '@youtoken/ui.resource-hodl';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Form} from './Form';
import {View} from './View';

export interface FlipHODLFormArgs {
  hodlId: string;
  onFormSubmit: () => void;
}
export interface FlipHODLFormResources {
  hodl: HODLByIdResource;
  wallets: WalletsResource;
  tariffs: HodlsTariffsResource;
  rates: RatesResource;
  docs: DocsResource;
}

export class FlipHODLForm {
  @observable
  form: Form;

  @observable
  view: View;

  constructor(resources: FlipHODLFormResources, args: FlipHODLFormArgs) {
    this.form = new Form(resources, args);
    this.view = new View(this.form);
  }
}
