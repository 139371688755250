import * as React from 'react';
import {type BoxProps, Box, Heading, Text} from '@youtoken/ui.primitives';

export interface SavingsSliderSlideProps extends BoxProps {
  img: React.ReactNode;
  title: string;
  description: string;
}

export const SavingsSliderSlide: React.FC<SavingsSliderSlideProps> = React.memo(
  ({img, title, description, ...boxProps}) => {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        height="100%"
        p={32}
        {...boxProps}
      >
        {img}
        <Box mt={24}>
          <Heading textAlign="center" mb={8}>
            {title}
          </Heading>
          <Text variant="$body-01" color="$text-02" textAlign="center">
            {description}
          </Text>
        </Box>
      </Box>
    );
  }
);
