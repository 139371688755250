import {deserialize} from 'serializr';
import {computed} from 'mobx';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {TRANSPORT} from '@youtoken/ui.transport';
import {UmaInfoResponse} from './UmaInfoResponse';

export class UmaInfoResource extends createStaticResource<{}, UmaInfoResponse>({
  getKey: () => `UmaInfoResource`,
  getData: () => {
    const {
      data: {
        products: {
          uma: {
            available,
            settings: {enable, enableWithdrawal},
          },
        },
      },
    } = AuthMeResource.getInstance({});

    if (!available || (!enable && !enableWithdrawal)) {
      return Promise.resolve(deserialize(UmaInfoResponse, {}));
    }

    return TRANSPORT.API.get('/v1/uma/info').then(res => {
      return deserialize(UmaInfoResponse, res.data);
    });
  },
}) {
  @computed
  get isUmaOpened() {
    return Boolean(this.data.uma);
  }

  @computed
  get umaDomainFormatted() {
    return `@${this.data.domain}`;
  }
}
