import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Switch} from '@youtoken/ui.elements';
import {Error, useForm, FieldWrap} from '../../../../components';

export interface CheckboxFieldProps extends BoxProps {
  name: string;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = observer(
  ({name, children, ...boxProps}) => {
    const {
      form: {instance},
    } = useForm();

    return (
      <FieldWrap {...boxProps}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            flexGrow={1}
            flexShrink={1}
            mr={10}
            flexDirection="row"
            flexWrap="wrap"
          >
            {children}
          </Box>
          <Switch
            value={instance.$(name).value}
            onValueChange={instance.$(name).onChange}
            // @ts-ignore
            hasError={instance.$(name).error}
          />
        </Box>
        <Error errors={instance.errors()} name={name} />
      </FieldWrap>
    );
  }
);
