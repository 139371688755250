import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const GiftIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 4.5c0-1.519 1.448-2.75 3.25-2.75 1.217 0 2.312.7 3 1.85.688-1.15 1.783-1.85 3-1.85 1.802 0 3.25 1.231 3.25 2.75 0 .403-.066.84-.242 1.25A1.75 1.75 0 0117.75 7.5V9c0 .698-.409 1.3-1 1.582V16A1.75 1.75 0 0115 17.75H5A1.75 1.75 0 013.25 16v-5.418A1.75 1.75 0 012.25 9V7.5c0-.964.78-1.746 1.742-1.75A3.175 3.175 0 013.75 4.5zM6.5 5.75c-.57 0-.841-.181-.986-.355-.165-.198-.264-.506-.264-.895 0-.481.552-1.25 1.75-1.25.838 0 1.944.788 2.197 2.5H6.5zm2.75 1.5H4a.25.25 0 00-.25.25V9c0 .138.112.25.25.25h5.25v-2zm0 3.5h-4.5V16c0 .138.112.25.25.25h4.25v-5.5zm1.5 5.5v-5.5h4.5V16a.25.25 0 01-.25.25h-4.25zm0-7v-2H16a.25.25 0 01.25.25V9a.25.25 0 01-.25.25h-5.25zm2.75-3.5c.57 0 .841-.181.986-.355.165-.198.264-.506.264-.895 0-.481-.552-1.25-1.75-1.25-.838 0-1.944.788-2.197 2.5H13.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
