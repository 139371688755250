import * as React from 'react';
import {useTheme} from '@youtoken/ui.primitives';
import {ActivityIndicator as SystemActivityIndicator} from 'react-native';

export type ActivityIndicatorProps = React.ComponentProps<
  typeof SystemActivityIndicator
>;

export const ActivityIndicator: React.FC<ActivityIndicatorProps> = props => {
  const {colors} = useTheme();

  return <SystemActivityIndicator color={colors['$text-02']} {...props} />;
};
