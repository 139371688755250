import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, useIsMobile, type BoxProps} from '@youtoken/ui.primitives';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {HODLChartSmartCell} from '@youtoken/ui.legacy-charts';
import {ScreenNameMH} from '@youtoken/ui.trading-view-charts';
import {GLOBAL} from '@youtoken/ui.service-global';

type ChartSectionProps = {
  instrumentId: string;
  chartHeight?: number;
  onPressTradingViewModeButton?: () => void;
};

export const ChartSection: React.FC<ChartSectionProps & BoxProps> = observer(
  ({instrumentId, onPressTradingViewModeButton, chartHeight, ...boxProps}) => {
    const isMobile = useIsMobile();
    const height = chartHeight ? chartHeight : isMobile ? 220 : 500;
    const [width, setWidth] = React.useState(0);

    const {getInstrumentById} = HODLsTariffsFeature.use({});
    const {baseTicker, quoteTicker, precision} =
      getInstrumentById(instrumentId);

    const Chart = React.useMemo(() => {
      if (!baseTicker || !quoteTicker || !height || !width) {
        return null;
      }

      return (
        <HODLChartSmartCell
          width={width}
          height={height}
          fromTicker={baseTicker}
          toTicker={quoteTicker}
          mode="mid"
          screenName={ScreenNameMH.INSTRUMENT}
          precision={precision}
          product="hodl"
          onPressTradingViewModeButton={onPressTradingViewModeButton}
          defaultTimePeriod={!GLOBAL.isAuthorized ? '24h' : undefined}
        />
      );
    }, [width, height, baseTicker, quoteTicker, precision]);

    return (
      <Box
        flexGrow={1}
        mb={8}
        onLayout={({
          nativeEvent: {
            layout: {width: layoutWidth},
          },
        }) => setWidth(layoutWidth)}
        {...boxProps}
      >
        {Chart}
      </Box>
    );
  }
);
