import * as React from 'react';
import {
  Handles,
  Rail,
  Slider as BaseSlider,
  SliderItem,
  Ticks,
  Tracks,
} from 'react-compound-slider';
import {Box, type Theme} from '@youtoken/ui.primitives';
import {Handle, sliderElementStyles, Track} from './elements';
import {getSliderColor} from './utils';

export interface SliderProps {
  style?: {};
  value: number;
  minimumValue: number;
  maximumValue: number;
  onValueChange?: (value: number) => void;
  onSlidingComplete?: (value: number) => void;
  showLegend: boolean;
  legendPosition: 'top' | 'bottom';
  baseColor: keyof Theme['colors'];
  key?: string;
  disabled: boolean;
  step?: number;
  /**
   * maxSteps for the slider legend
   *
   * @default 50
   */
  maxLegendSteps?: number;
  shouldBeReversed?: boolean;
}

export const Slider: React.FC<SliderProps> = ({
  style,
  value,
  minimumValue,
  maximumValue,
  onValueChange,
  onSlidingComplete,
  showLegend,
  legendPosition,
  key,
  disabled,
  step = 1,
  maxLegendSteps = 50,
  shouldBeReversed = false,
}) => {
  const legend: number = React.useMemo(() => {
    if (!showLegend) {
      return 0;
    }

    return Math.min(maximumValue - minimumValue + 1, maxLegendSteps);
  }, [minimumValue, maximumValue, showLegend, maxLegendSteps]);

  const handleChange = React.useCallback(
    (values: ReadonlyArray<number>) => {
      // @ts-ignore yep, this types has no overlap, so we use not the strict equality
      if (values[0] != value.toString()) {
        onValueChange?.(values[0]!);
      }
    },
    [onValueChange, value]
  );

  const handleComplete = React.useCallback(
    (values: ReadonlyArray<number>) => {
      // @ts-ignore same here
      if (values[0] != value.toString()) {
        onSlidingComplete?.(values[0]!);
      }
    },
    [onSlidingComplete, value]
  );

  const rootStyle = React.useMemo(
    () => ({
      ...style,
      height: '16px',
      position: 'relative',
      width: 'calc(100% - 16px)',
      marginRight: 8,
      marginTop: showLegend && legendPosition === 'top' ? '16px' : 0,
      marginBottom: showLegend && legendPosition === 'bottom' ? '16px' : 0,
    }),
    [style, showLegend, legendPosition]
  );

  const sliderColor = getSliderColor(disabled);

  return (
    <BaseSlider
      rootStyle={rootStyle}
      domain={[minimumValue, maximumValue]}
      values={[value]}
      step={step}
      onChange={handleChange}
      onSlideEnd={handleComplete}
      key={key}
      disabled={disabled}
      reversed={shouldBeReversed}
    >
      {showLegend && legendPosition === 'top' && (
        <Ticks count={legend}>
          {({ticks}) => (
            <>
              {ticks.map((tick: SliderItem) => (
                <Box
                  top={-16}
                  left={`${tick.percent}%`}
                  style={sliderElementStyles.tick}
                  bg={sliderColor.secondary}
                  key={tick.id}
                />
              ))}
            </>
          )}
        </Ticks>
      )}
      {/* @ts-ignore "Error: cannot be used as a JSX component" */}
      <Rail>
        {({getRailProps}) => (
          <Box
            style={[
              sliderElementStyles.wrap,
              {
                width: 'calc(100% + 16px)',
                cursor: 'pointer',
              },
            ]}
            {...getRailProps()}
          >
            <Box bg={sliderColor.secondary} style={sliderElementStyles.inner} />
          </Box>
        )}
      </Rail>
      {/* @ts-ignore "Error: cannot be used as a JSX component" */}
      <Tracks right={false}>
        {({tracks, getTrackProps}) => (
          <>
            {tracks.map(track => (
              <Track
                key={track.id}
                source={track.source}
                target={track.target}
                color={sliderColor.primary}
                getTrackProps={getTrackProps}
              />
            ))}
          </>
        )}
      </Tracks>

      {/* @ts-ignore Error: "cannot be used as a JSX component" */}
      <Handles>
        {({handles, getHandleProps}) => (
          <>
            {handles.map(handle => (
              <Handle
                key={handle.id}
                handle={handle}
                color={sliderColor.primary}
                getHandleProps={getHandleProps}
              />
            ))}
          </>
        )}
      </Handles>
      {showLegend && legendPosition === 'bottom' && (
        <Ticks count={legend}>
          {({ticks}) => (
            <>
              {ticks.map(tick => (
                <Box
                  bg={sliderColor.secondary}
                  top={24}
                  left={`${tick.percent}%`}
                  style={sliderElementStyles.tick}
                  key={tick.id}
                />
              ))}
            </>
          )}
        </Ticks>
      )}
    </BaseSlider>
  );
};
