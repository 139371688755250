import * as React from 'react';
import {Box, Text, TouchableBox, type Theme} from '@youtoken/ui.primitives';
import {Icon, type BaseIconName} from '@youtoken/ui.icons';

interface SwipeElementProps {
  onPress: () => void;
  mainColor: keyof Theme['colors'];
  backgroundColor: keyof Theme['colors'];
  text: string;
  icon: BaseIconName;
  disabled?: boolean;
}

export const SwipeElement: React.FC<SwipeElementProps> = ({
  onPress,
  text,
  icon,
  mainColor,
  backgroundColor,
  disabled,
}) => {
  return (
    <Box
      width={64}
      height={64}
      backgroundColor={disabled ? '$ui-01' : backgroundColor}
    >
      <TouchableBox
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        onPress={onPress}
        disabled={disabled}
      >
        <Icon
          name={icon}
          width={20}
          height={20}
          color={disabled ? '$text-03' : mainColor}
        />
        <Text
          variant="$body-03"
          color={disabled ? '$text-03' : mainColor}
          mt={2}
          textTransform="uppercase"
        >
          {text}
        </Text>
      </TouchableBox>
    </Box>
  );
};
