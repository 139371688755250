import {observable, action, runInAction} from 'mobx';
import {serializable, deserialize, primitive} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {Form} from './Form';

export class Calculated {
  @observable
  public form: Form;

  @observable
  public loading: boolean = false;

  @observable
  public ready: boolean = false;

  @observable
  public data?: CalculatedResponse;

  @action
  public load = () => {
    this.loading = true;
    this.ready = false;

    return TRANSPORT.API.post(
      `/v${this.form.loan.version}/loan/payment/calculate`,
      {
        // remove after modify backend
        id: this.form.loan.id,
        loanId: this.form.loan.id,
        ticker: this.form.ticker,
        conversionTicker: this.form.conversionTicker,
        amount: Number(this.form.amount),
        conversionAmount: Number(this.form.conversionAmount),
        priceFrom: this.form.priceFrom,
        price: Number(this.form.price),
      }
    )
      .then(response => {
        runInAction(() => {
          this.data = deserialize(CalculatedResponse, {
            borrowedTicker: this.form.conversionTicker,
            ...response.data,
          });
          this.ready = true;
        });
      })
      .finally(() => {
        this.loading = false;
      });
  };

  public constructor(form: Form) {
    this.form = form;
  }
}

export class CalculatedResponse {
  @serializable(primitive())
  repaid!: boolean;

  @serializable(bigNumber())
  ltv!: string;

  @serializable(bigNumber())
  mcPrice!: string;

  @serializable(primitive())
  borrowedTicker!: string;

  @serializable(bigNumber())
  borrowedAmount!: string;

  @serializable(bigNumber())
  overdraftAmount!: string;
}
