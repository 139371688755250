import {type IconSectorsLogoName} from '@youtoken/ui.icons';
import {type SectorsCardProps} from './components';

type CardProps = Omit<
  SectorsCardProps,
  'onPress' | 'itemCount' | 'itemName'
> & {logo: IconSectorsLogoName};

export const cardsData: Record<string, CardProps> = {
  stable: {
    title: 'Stable',
    icon: 'stable',
    logo: 'usdc',
    cardColor: '$hodls-instruments-sectors-01',
  },
  metaverse: {
    title: 'Metaverse',
    icon: 'metaverse',
    logo: 'mana',
    cardColor: '$hodls-instruments-sectors-02',
  },
  bnb: {
    title: 'BNB Chain',
    icon: 'bnb-chain',
    logo: 'bnb',
    cardColor: '$hodls-instruments-sectors-03',
  },
  avalanche: {
    title: 'Avalanche',
    icon: 'avalanche',
    logo: 'avax',
    cardColor: '$hodls-instruments-sectors-04',
  },
  nft: {
    title: 'NFTs',
    icon: 'nft',
    logo: 'ape',
    cardColor: '$hodls-instruments-sectors-05',
  },
  defi: {
    title: 'DeFi',
    icon: 'defi',
    logo: 'dai',
    cardColor: '$hodls-instruments-sectors-06',
  },
  ai: {
    title: 'AI',
    icon: 'ai',
    logo: 'agix',
    cardColor: '$hodls-instruments-sectors-07',
  },
  solana: {
    title: 'Solana',
    icon: 'solana',
    logo: 'sol',
    cardColor: '$hodls-instruments-sectors-08',
  },
  polkadot: {
    title: 'Polkadot',
    icon: 'polkadot',
    logo: 'dot',
    cardColor: '$hodls-instruments-sectors-09',
  },
  memes: {
    title: 'Memes',
    icon: 'memes',
    logo: 'pepe',
    cardColor: '$hodls-instruments-sectors-10',
  },
  fantokens: {
    title: 'Fantokens',
    icon: 'fan-tokens',
    logo: 'asr',
    cardColor: '$hodls-instruments-sectors-11',
  },
  layer1: {
    title: 'Layer 1',
    icon: 'layer-1',
    logo: 'ada',
    cardColor: '$hodls-instruments-sectors-12',
  },
};
