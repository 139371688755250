import * as React from 'react';
import {Box, Text, type Theme, type BoxProps} from '@youtoken/ui.primitives';
import {Icon, BaseIconName} from '@youtoken/ui.icons';

type BannerVariants = 'info' | 'success' | 'attention' | 'danger';

interface BannerProps {
  variant?: BannerVariants;
}

export const getByVariant = (
  variant: BannerVariants
): {
  backgroundColor: keyof Theme['colors'];
  color: keyof Theme['colors'];
  iconName: BaseIconName;
} => {
  if (variant === 'success') {
    return {
      backgroundColor: '$success-02',
      color: '$success-01',
      iconName: 'success',
    };
  }

  if (variant === 'attention') {
    return {
      backgroundColor: '$attention-02',
      color: '$attention-04',
      iconName: 'warning',
    };
  }

  if (variant === 'danger') {
    return {
      backgroundColor: '$danger-02',
      color: '$danger-04',
      iconName: 'warning',
    };
  }

  return {
    backgroundColor: '$interactive-02',
    color: '$text-05',
    iconName: 'question', // TODO change to 'success' when Eugene add icon
  };
};

export const Banner: React.FC<BoxProps & BannerProps> = ({
  children,
  variant = 'info',
  ...props
}) => {
  const {backgroundColor, color, iconName} = getByVariant(variant);

  return (
    <Box
      paddingHorizontal={24}
      paddingVertical={16}
      backgroundColor={backgroundColor}
      flexDirection="row"
      position="relative"
      {...props}
    >
      <Box mr={8}>
        <Icon color={color} name={iconName} size={20} />
      </Box>

      <Box flex={1} justifyContent="flex-start" pt={2}>
        <Text color={color} variant="$body-02">
          {children}
        </Text>
      </Box>
    </Box>
  );
};
