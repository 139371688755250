import {observable, action, computed} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  public questionNumber = 1;

  @observable
  public isSuccessful = false;

  @observable
  public isLoading = false;

  public constructor() {
    this.instance = new MobxReactForm(
      {
        fields: {
          source: {
            value: 'platform',
          },
          surveyTitle: {
            value: 'Acquisition sources survey',
          },
        },
      },
      {
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            const data = form.values();
            this.isLoading = true;
            const {setAcquisitionSurveyPassed} = AuthMeResource.getInstance({});

            if (this.shouldSendForm) {
              DATA_LAYER.trackStrict('product-survey-submit', data);
            }

            setAcquisitionSurveyPassed();
            this.isSuccessful = true;
            this.isLoading = false;
          },
        },
      }
    );
  }

  @action private checkAndRemoveFormFields = () => {
    if (
      this.instance.has(`productSurveyAnswer_${this.questionNumber}`) &&
      !this.textAnswer
    ) {
      this.instance.del(`productSurveyAnswer_${this.questionNumber}`);
      this.instance.del(`productSurveyQuestion_${this.questionNumber}`);
      this.questionNumber--;
    }
  };

  @action
  public submitForm = () => {
    // remove empty fields for text input if needed
    this.checkAndRemoveFormFields();

    this.instance.submit();
  };

  @action
  public increaseQuestionNumber() {
    this.questionNumber++;
  }

  @action
  public addNextField(question?: string) {
    this.instance.add({key: `productSurveyAnswer_${this.questionNumber}`});
    this.instance.add({key: `productSurveyQuestion_${this.questionNumber}`});

    if (question) {
      this.setQuestion(question);
    }
  }

  @computed
  private get shouldSendForm() {
    return this.instance.has('productSurveyAnswer_1');
  }

  @computed
  public get onChangeTextAnswer() {
    return this.instance
      .$(`productSurveyAnswer_${this.questionNumber}`)
      ?.get('onChange');
  }

  @computed
  public get textAnswer() {
    return (
      this.instance
        .$(`productSurveyAnswer_${this.questionNumber}`)
        ?.get('value') ?? ''
    );
  }

  @action
  private setAnswer(value: string) {
    this.instance
      .$(`productSurveyAnswer_${this.questionNumber}`)
      .set('value', value);
  }

  @action
  private setQuestion(value: string) {
    this.instance
      .$(`productSurveyQuestion_${this.questionNumber}`)
      .set('value', value);
  }

  @action
  public fillTheForm(answer: string, question: string) {
    this.addNextField();
    this.setQuestion(question);
    this.setAnswer(answer);
  }
}
