import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryItemV1} from '@youtoken/ui.resources-rewards';
import {Box} from '@youtoken/ui.primitives';
import {RewardsHistoryProps} from '../types';
import {keyExtractor} from '../utils';
import {RewardsHistoryTitle, RewardsHistoryItemV1Item} from '../..';

const renderItem = (item: RewardsHistoryItemV1, index: number) => {
  return (
    <RewardsHistoryItemV1Item key={keyExtractor(item, index)} data={item} />
  );
};

export interface RewardsHistoryViewV1Props extends RewardsHistoryProps {
  items: RewardsHistoryItemV1[];
}

export const RewardsHistoryViewV1: React.FC<RewardsHistoryViewV1Props> =
  observer(({showHeader, items}) => {
    return (
      <Box>
        {showHeader && <RewardsHistoryTitle />}
        {items.map(renderItem)}
      </Box>
    );
  });
