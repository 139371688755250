import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const TwitterIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          d="M17.637 5c-.251.11-.51.204-.773.28.312-.35.55-.764.696-1.215a.255.255 0 00-.085-.279.26.26 0 00-.292-.02 6.105 6.105 0 01-1.8.706 3.465 3.465 0 00-2.405-.972c-1.894 0-3.435 1.53-3.435 3.409 0 .148.01.295.029.44A8.814 8.814 0 013.536 4.17a.259.259 0 00-.423.033 3.387 3.387 0 00.352 3.924 2.92 2.92 0 01-.46-.204.26.26 0 00-.253.002.256.256 0 00-.13.216v.045c0 1.226.665 2.33 1.681 2.932a2.982 2.982 0 01-.261-.038.26.26 0 00-.242.082.254.254 0 00-.052.248 3.424 3.424 0 002.516 2.284 6.111 6.111 0 01-3.248.92c-.243 0-.488-.014-.728-.042a.258.258 0 00-.273.168.255.255 0 00.104.302A9.315 9.315 0 007.128 16.5c3.497 0 5.684-1.636 6.904-3.01 1.52-1.711 2.392-3.977 2.392-6.216 0-.093-.001-.188-.004-.282a6.648 6.648 0 001.536-1.615.255.255 0 00-.017-.307.26.26 0 00-.302-.07z"
          fill={color}
          fillRule="evenodd"
        />
      </Svg>
    );
  }
);
