import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {VerificationResource} from '@youtoken/ui.resource-verification';

const handlePress = () => {
  VerificationResource.launchIdentityVerification();
};

export const RewardsOverviewStatusActionVerify: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Button size="medium" color="interactive" mt={24} onPress={handlePress}>
      {t('surface.referral_program.rewards.verify_action')}
    </Button>
  );
});
