import * as React from 'react';
import type {TSumSubVerificationImperativeMethods} from './types';
import {SumSubVerificationComponent} from './SumSubVerificationComponent';

export const __GLOBAL_SUMSUB_REF__ =
  React.createRef<TSumSubVerificationImperativeMethods>();

export const __GLOBAL_SUM_SUB_VERIFICATION__: TSumSubVerificationImperativeMethods =
  {
    launch: (levelName, onDismiss) => {
      if (!__GLOBAL_SUMSUB_REF__?.current?.launch) {
        return Promise.reject(new Error('SumSub is not ready'));
      }

      return __GLOBAL_SUMSUB_REF__.current?.launch(levelName, onDismiss);
    },
  };

export const SumSubVerificationContext =
  React.createContext<TSumSubVerificationImperativeMethods>(
    __GLOBAL_SUM_SUB_VERIFICATION__
  );

export const SumSubVerificationContextProvider: React.FC = ({children}) => {
  return (
    <SumSubVerificationContext.Provider value={__GLOBAL_SUM_SUB_VERIFICATION__}>
      <SumSubVerificationComponent ref={__GLOBAL_SUMSUB_REF__} />
      {children}
    </SumSubVerificationContext.Provider>
  );
};

export const useSumSubVerification = () => {
  const context = React.useContext(SumSubVerificationContext);

  if (!context) {
    throw new Error(
      'useSumSubVerification must be used within a SumSubVerificationContextProvider'
    );
  }

  return context;
};
