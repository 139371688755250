import {serializable} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {Big} from 'big.js';

export class CalculatedDataResponse {
  @serializable(bigNumber())
  initialPrice!: Big;

  @serializable(bigNumber())
  maxLoss!: Big;

  @serializable(bigNumber())
  maxProfit!: Big;

  @serializable(bigNumber())
  mcPrice!: Big;

  @serializable(bigNumber())
  ftpPrice!: Big;
}
