import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text, TouchableText} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {Label} from '@youtoken/ui.form-elements';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {ExtendTpSlHODLFeature} from '../../../features';

type DepositForExtendTpSlSurfaceProps = {
  hodlId: string;
  onDepositPress: () => void;
};

export const DepositForExtendTpSlSurface: React.FC<DepositForExtendTpSlSurfaceProps> =
  observer(({hodlId, onDepositPress}) => {
    const {t} = useTranslation();

    const {
      initialPriceFormatted,
      hodlInputTicker,
      hodlInputTickerFormatted,
      hodlBaseTickerFormatted,
      hodlQuoteTickerFormatted,
      inputTickerWallet,
    } = ExtendTpSlHODLFeature.use({id: hodlId});

    return (
      <Box p={24}>
        <Box alignItems="center">
          <Heading variant="$heading-02">
            {t('surface.hodls.extend_tp.action_title')}
          </Heading>
          {initialPriceFormatted && (
            <Text variant="$body-02" color="$text-02" textAlign="center" my={8}>
              {t('surface.hodls.adjust_tp.initial_price', {
                pair: hodlBaseTickerFormatted,
              })}{' '}
              <Text color="$text-01">{initialPriceFormatted}</Text>{' '}
              {hodlQuoteTickerFormatted}
            </Text>
          )}
        </Box>

        <Box>
          <Box flexDirection="row" justifyContent="space-between" mb={8}>
            <Label>{t('surface.hodls.hodl_form.amount_label')}</Label>
            <TouchableText
              variant="$body-02"
              color="$text-05"
              testID="SET_ALL_SOURCE_TO_AMOUNT_BUTTON"
            >
              <Trans
                t={t}
                i18nKey="surface.hodls.hodl_form.amount.action.all_source"
                components={{
                  Bold: <Text variant="$body-02-medium-accent" />,
                }}
                values={{
                  amount: 0,
                  ticker: hodlInputTickerFormatted,
                }}
                defaults="All <Bold>{{amount}}</Bold> {{ticker}}"
              />
            </TouchableText>
          </Box>

          <TickerAndAmountInput
            isTickerImmutable
            wallets={[inputTickerWallet]}
            ticker={hodlInputTicker}
            amount="0"
            onChangeTicker={undefined}
            disabled
          />
          <Text color="$text-01" mt={24}>
            {t('surface.hodls.extend_tp.deposit_required')}
          </Text>
        </Box>
        <Box flexDirection="row" mt={24}>
          <Button
            size="large"
            type="primary"
            flexGrow={1}
            onPress={onDepositPress}
          >
            {t('surface.hodls.extend_tp.action_deposit')}
          </Button>
        </Box>
      </Box>
    );
  });
