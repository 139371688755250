import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {
  BankCardsResource,
  type IBankCard,
} from '@youtoken/ui.resource-bank-cards';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {ActivityIndicator, Separator} from '@youtoken/ui.elements';
import {ActionButton, MenuItem} from './components';
import {BankCardsFeature} from '../../../../../../state/BankCardsFeature';

interface BankCardInfoProps {
  data: IBankCard;
}

export const BankCardInfo: React.FC<BankCardInfoProps> = observer(({data}) => {
  const {t} = useTranslation();

  const {freezeCard, unfreezeCard, isCardFreezeLoading, requestBalanceConsent} =
    BankCardsResource.use({});

  const {redirectToDetails} = BankCardsFeature.use({});

  const canBeFrozen = React.useMemo(
    () => data.status === 'ACTIVE',
    [data.status]
  );
  const isFrozen = React.useMemo(() => data.status === 'FROZEN', [data.status]);

  const handleTransactionsPress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('BankCardTransactions', {
      cardId: data.cardId,
    });
  }, [data.cardId]);

  const handleAddMoneyPress = React.useCallback(() => {
    if (!isFrozen) {
      SHARED_ROUTER_SERVICE.navigate('BankCardAddMoney', {cardId: data.cardId});
    }
  }, [data.cardId, isFrozen]);

  const handleViewBalancePress = React.useCallback(() => {
    requestBalanceConsent();
  }, [requestBalanceConsent]);

  const handleDetailsPress = React.useCallback(() => {
    if (!isFrozen) {
      redirectToDetails(data.cardId);
    }
  }, [redirectToDetails, data.cardId, isFrozen]);

  const handleFreezePress = React.useCallback(() => {
    freezeCard(data.cardId);
  }, [freezeCard, data.cardId, freezeCard]);

  const handleUnfreezePress = React.useCallback(() => {
    unfreezeCard(data.cardId);
  }, [unfreezeCard, data.cardId, unfreezeCard]);

  const handleTransferPress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('BankCardTransfer', {cardId: data.cardId});
  }, [data.cardId]);

  return (
    <Box>
      <Box flexDirection="row" justifyContent="space-around" mb={24} px={24}>
        <ActionButton
          title={t('surface.bank_cards.menu_item.add_money')}
          icon="fiat_deposit"
          disabled={isFrozen}
          onPress={handleAddMoneyPress}
        />
        {canBeFrozen && (
          <ActionButton
            title={t('surface.bank_cards.menu_item.freeze_card')}
            icon={isCardFreezeLoading ? undefined : 'snowflake'}
            onPress={handleFreezePress}
          >
            {isCardFreezeLoading && <ActivityIndicator />}
          </ActionButton>
        )}
        {isFrozen && (
          <ActionButton
            title={t('surface.bank_cards.menu_item.unfreeze_card')}
            icon={isCardFreezeLoading ? undefined : 'snowflake_crossed'}
            onPress={handleUnfreezePress}
          >
            {isCardFreezeLoading && <ActivityIndicator />}
          </ActionButton>
        )}
        <ActionButton
          title={t('surface.bank_cards.menu_item.transfer')}
          icon="convert"
          onPress={handleTransferPress}
        />
      </Box>

      <Separator />

      <Box>
        {data.balance === null && (
          <MenuItem
            title={t('surface.bank_cards.menu_item.activate_card')}
            // todo cards: icon
            icon="document"
            onPress={handleViewBalancePress}
          />
        )}
        <MenuItem
          title={t('surface.bank_cards.menu_item.transactions')}
          icon="pending"
          borderBottomWidth={1}
          onPress={handleTransactionsPress}
        />
        <MenuItem
          title={t('surface.bank_cards.menu_item.card_details')}
          icon="card"
          disabled={isFrozen}
          borderBottomWidth={{default: 1, desktop: 0}}
          onPress={handleDetailsPress}
        />
      </Box>
    </Box>
  );
});
