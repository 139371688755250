import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ShowIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <G fill={color}>
          <Path d="M18.416 17.452A3 3 0 0120 17a1.5 1.5 0 000-3 6 6 0 105.796 7.553 1.5 1.5 0 00-2.898-.777 3 3 0 11-4.482-3.324z" />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.523 19.383C31.58 11.795 25.738 8 20 8 14.436 8 8.775 11.568 3.017 18.704l-.54.68-.104.163a1 1 0 00.104 1.07C8.42 28.205 14.262 32 20 32c5.564 0 11.225-3.568 16.983-10.704l.54-.68.104-.163a1 1 0 00-.104-1.07zM6 20c4.915-6 9.582-9 14-9 4.418 0 9.085 3 14 9-4.915 6-9.582 9-14 9-4.418 0-9.085-3-14-9z"
          />
        </G>
      </Svg>
    );
  }
);
