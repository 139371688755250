import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Header} from './Header';
import {LoansListRegularSurface} from '@youtoken/ui.surface-loans';

export const Content: React.FC = cell(() => {
  return (
    <>
      <Header />
      <LoansListRegularSurface />
    </>
  );
});
