import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {LoansVideoPreview} from '@youtoken/ui.video-preview';
import {observer} from 'mobx-react';
import {
  LoansListRegularResource,
  TariffsLoansRegularResource,
} from '@youtoken/ui.resource-loans';

export const LoansListRegularEmpty: React.FC = observer(() => {
  const {t} = useTranslation();

  const {minAprFormatted, maxAprFormatted} = TariffsLoansRegularResource.use(
    {}
  );

  return (
    <Box testID="LOAN_LIST_EMPTY" p={24} alignItems="center">
      <Box maxWidth={480}>
        <Box mb={24}>
          <LoansVideoPreview />
        </Box>
        <Heading
          variant="$heading-01"
          color="$text-01"
          textAlign="center"
          mb={16}
        >
          {t('surface.loans.empty')}
        </Heading>
        <Text textAlign="center" mb={16}>
          {t('surface.loans.empty_description')}
        </Text>
        <Text textAlign="center">
          {t('surface.loans.empty_apr_description', {
            from: minAprFormatted,
            to: maxAprFormatted,
          })}
        </Text>
      </Box>
    </Box>
  );
});
