import * as React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import './Modal';
import {TwoFactorAuthParams} from '../../types';

export const TwoFactorAuthVerificationComponent = React.forwardRef<
  {
    requestTwoFactorAuth: (params: TwoFactorAuthParams) => Promise<null>;
  },
  {}
>((_props, ref) => {
  React.useImperativeHandle(
    ref,
    () => ({
      requestTwoFactorAuth: (params: TwoFactorAuthParams) => {
        return new Promise<null>((resolve, reject) => {
          NiceModal.show('two-factor-auth-modal', {
            params: params,
            onSuccess: resolve,
            onError: reject,
          });
        });
      },
    }),
    []
  );

  return <></>;
});
