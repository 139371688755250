import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {type BadgeProps, Badge} from '@youtoken/ui.elements';

export interface AccountLevelConversionCoefficientBadgeProps
  extends BadgeProps {
  value: number;
}

export const AccountLevelConversionCoefficientBadge: React.FC<AccountLevelConversionCoefficientBadgeProps> =
  observer(({value, ...badgeProps}) => {
    const {t} = useTranslation();

    return (
      <Badge
        testID="ACCOUNT_LEVEL_CONVERSION_COEFFICIENT"
        size="small"
        borderRadius={12}
        {...badgeProps}
      >
        <Text variant="$body-02">
          <Trans
            t={t}
            components={{
              Strong: <Text variant="$body-02-high-accent" />,
            }}
            values={{value}}
            i18nKey="surface.loyalty.conversion.coefficient"
          />
        </Text>
      </Badge>
    );
  });
