import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CaptureIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.586 2.5a1 1 0 01.707.293L14 4.5h2.5a2 2 0 012 2v9a2 2 0 01-2 2h-13a2 2 0 01-2-2v-9a2 2 0 012-2H6l1.707-1.707a1 1 0 01.707-.293h3.172zM16.5 6h-13a.5.5 0 00-.492.41L3 6.5v9a.5.5 0 00.41.492L3.5 16h13a.5.5 0 00.492-.41L17 15.5v-9a.5.5 0 00-.41-.492L16.5 6zm-6.5.5a4 4 0 110 8 4 4 0 010-8zM10 8a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.75 7a.75.75 0 110 1.5.75.75 0 010-1.5zm6.336-3H8.914a1 1 0 00-.608.206l-.099.087L6.5 6h7l-1.707-1.707a1 1 0 00-.576-.284L11.086 4z"
          fill={color}
        />
      </Svg>
    );
  }
);
