import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {type RewardsOverviewType} from './types';
import {RewardsOverviewTimer} from './Timer';
import {RewardsOverviewStatus} from './Status';
import {RewardsOverviewProgress} from './Progress';

interface RewardsOverviewProps {
  type?: RewardsOverviewType;
}

export const RewardsOverview: React.FC<RewardsOverviewProps & BoxProps> = ({
  type,
  ...boxProps
}) => {
  return (
    <Box bg="$fill-01" borderRadius={16} overflow="hidden" {...boxProps}>
      <RewardsOverviewTimer />
      <RewardsOverviewStatus />
      <RewardsOverviewProgress type={type} />
    </Box>
  );
};
