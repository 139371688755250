import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {UmaInvoiceResponse} from './UmaInvoiceResponse';

export {UmaInvoiceResponse} from './UmaInvoiceResponse';

export interface UmaInvoiceRequestArgs {
  uma: string;
  amount: string;
  ticker: string;
  toTicker: string;
  invoiceRequestId: string;
  requestUuid: string;
}

export class UmaInvoiceResource extends createStaticResource<
  UmaInvoiceRequestArgs,
  UmaInvoiceResponse
>({
  getKey: ({
    uma,
    amount,
    ticker,
    toTicker,
    requestUuid,
    invoiceRequestId,
  }: UmaInvoiceRequestArgs) =>
    `UmaInvoiceResource:${uma}:${amount}:${ticker}:${toTicker}:${invoiceRequestId}:${requestUuid}`,
  getData: ({invoiceRequestId, uma, toTicker, ticker, amount}) => {
    return TRANSPORT.API.post('/v1/uma/invoice', {
      uma: `${uma}`,
      fromTicker: ticker,
      toTicker,
      amount,
      invoiceRequestId,
    }).then(res => {
      return deserialize(UmaInvoiceResponse, res.data);
    });
  },
}) {}
