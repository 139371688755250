import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AccountLevelInfoProps} from './types';
import {useItems, useRenderItems} from './hooks';

export const AccountLevelInfoShort: React.FC<AccountLevelInfoProps & BoxProps> =
  observer(
    ({
      level,
      showSavingsInfo = false,
      showMinerInfo = false,
      showExchangeInfo: _showExchangeInfo = false,
      pressableSavingsInfo = true,
      ...boxProps
    }) => {
      const cardColor = '$ui-background';

      const items = useItems({
        showMiner: showMinerInfo,
        showExchange: _showExchangeInfo && level.level > 2,
        showSavings: showSavingsInfo,
      });

      const renderItem = useRenderItems(level, 'short', {
        cardColor,
        disabledSavings: !pressableSavingsInfo,
      });

      return (
        <Box
          testID="ACCOUNT_LEVEL_INFO_SMART"
          borderColor={cardColor}
          borderWidth={1}
          borderRadius={12}
          {...boxProps}
        >
          {items.map(renderItem)}
        </Box>
      );
    }
  );
