import * as React from 'react';
import {ScrollView} from 'react-native';
import {type TFuncKey} from 'react-i18next';
import {observer} from 'mobx-react';
import {Box, Heading, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {SurveyTextInput} from '../SurveyTextInput';
import {SurveyForm} from '../../form';
import {Illustration} from '@youtoken/ui.elements';

const getIdFromText = (text: string) => {
  return text.toUpperCase().replace(/ /g, '_');
};

export const SurveyQuestions: React.FC = observer(() => {
  const {
    form: {isLoading},
    view: {options, title, question, setAnswer, shouldShowHeader},
  } = useForm<SurveyForm>();
  const {t} = useTranslation();

  return (
    <>
      {shouldShowHeader && (
        <Box
          py={16}
          px={24}
          backgroundColor="$fill-03"
          borderTopRightRadius={{default: 14, tablet: 10}}
          borderTopLeftRadius={{default: 14, tablet: 10}}
        >
          <Box
            flexDirection="row"
            alignItems="center"
            justifyContent={{default: 'center', tablet: 'flex-start'}}
            px={{default: 24, tablet: 0}}
          >
            <Box mr={16} height={72} width={72}>
              <Illustration
                name="illustration-2"
                style={{width: 72, height: 72}}
              />
            </Box>
            <Text variant="$body-01-high-accent" color="$text-04">
              {t('common.survey.header_title')}
            </Text>
          </Box>
        </Box>
      )}
      <ScrollView
        style={{height: '100%', padding: 24}}
        keyboardShouldPersistTaps="handled"
        bounces={false}
      >
        <Box pb={24}>
          <Text color="$text-02" testID="TITLE">
            {t(title as TFuncKey)}
          </Text>
          <Heading variant="$heading-02" mt={8} testID="QUESTION">
            {t(question as TFuncKey)}
          </Heading>
        </Box>
        <Box>
          {options ? (
            options.map((option, index) => {
              const isLast = index === options.length - 1;
              const value = t(option.value as TFuncKey);

              return (
                <TouchableBox
                  disabled={isLoading}
                  testID={getIdFromText(value)}
                  key={option.value}
                  backgroundColor="$ui-01"
                  borderRadius={6}
                  p={16}
                  mb={isLast ? 0 : 8}
                  onPress={() => {
                    setAnswer(option.value);
                  }}
                >
                  <Text color={isLoading ? '$text-02' : '$text-01'}>
                    {value}
                  </Text>
                </TouchableBox>
              );
            })
          ) : (
            <SurveyTextInput />
          )}
        </Box>
      </ScrollView>
    </>
  );
});
