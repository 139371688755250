import * as React from 'react';
import {type TFuncKey} from 'react-i18next';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Separator, Trans} from '@youtoken/ui.elements';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {HODLsInstrumentsSectorsFeature} from '../../../../features/HODLsInstrumentsSectorsFeature';
import {BulletList, BulletListItem} from '../../../components';

export const DescriptionModalContentSmart: React.FC = observer(() => {
  const {
    chosenSector,
    shouldShowSectorInfoTitle,
    shouldShowInfoSectorAttraction,
    shouldShowInfoSectorTakeaways,
    sectorInfo: {
      title,
      description,
      attractions,
      attractionsTitle,
      takeaways,
      takeawaysTitle,
    },
  } = HODLsInstrumentsSectorsFeature.use({});

  React.useEffect(() => {
    DATA_LAYER.trackStrict('hodl-sector', {
      sectorName: chosenSector!,
    });
  }, [chosenSector]);

  return (
    <Box p={32} pb={40}>
      {shouldShowSectorInfoTitle && (
        <>
          <Heading variant="$heading-02" mb={16}>
            {title}
          </Heading>
          <Text variant="$body-02" color="$text-02" mb={24}>
            {description}
          </Text>
          <Separator mb={24} />
        </>
      )}
      {shouldShowInfoSectorAttraction && (
        <>
          <Text variant="$body-01-high-accent">{attractionsTitle}</Text>
          <Trans
            i18nKey={attractions as TFuncKey}
            components={{
              List: <BulletList />,
              ListItem: <BulletListItem />,
            }}
          />
          <Separator my={24} />
        </>
      )}
      {shouldShowInfoSectorTakeaways && (
        <>
          <Text variant="$body-01-high-accent">{takeawaysTitle}</Text>
          <Trans
            i18nKey={takeaways as TFuncKey}
            components={{
              List: <BulletList />,
              ListItem: <BulletListItem />,
            }}
          />
        </>
      )}
    </Box>
  );
});
