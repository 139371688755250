import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {
  useResizableBox,
  ResizableBox,
  HODLClosedChartSmartCell,
  HODLChartSmartCell,
} from '@youtoken/ui.legacy-charts';
import {Box} from '@youtoken/ui.primitives';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';

export interface LoanItemChartProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemChart: React.FC<LoanItemChartProps> = observer(
  ({item}) => {
    const [{size}, bind] = useResizableBox();

    return (
      <ResizableBox width="100%" height={360} {...bind}>
        {size.width && size.height && (
          <>
            {item.isClosed ? (
              <Box testID="LOAN_ITEM_CHART_CLOSED">
                <HODLClosedChartSmartCell
                  width={size.width}
                  height={size.height}
                  fromTicker={item.collateralTicker}
                  toTicker={item.borrowedTicker}
                  precision={getCoinDecimalPrecision(item.borrowedTicker) + 2}
                  initialPrice={item.initialPrice.toNumber()}
                  closedPrice={item.closedPrice?.toNumber()}
                  startDate={item.startedAt ? item.startedAt : undefined}
                  endDate={item.endDate ? item.endDate : undefined}
                />
              </Box>
            ) : (
              <Box testID="LOAN_ITEM_CHART_OPENED">
                <HODLChartSmartCell
                  displayTypeSwitcher={false}
                  width={size.width}
                  height={size.height}
                  fromTicker={item.collateralTicker}
                  toTicker={item.borrowedTicker}
                  precision={getCoinDecimalPrecision(item.borrowedTicker) + 2}
                  takeProfit={item.tp?.toNumber()}
                  marginCall={item.mcPrice.toNumber()}
                  initialPrice={item.initialPrice.toNumber()}
                  startDate={item.startedAt ? item.startedAt : undefined}
                />
              </Box>
            )}
          </>
        )}
      </ResizableBox>
    );
  }
);
