import * as React from 'react';
import {Line, G} from 'react-native-svg';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {useChartThemeContext} from '../../Providers';
import {Label} from '../../Primitives';
import {WalletChartState} from '../../logic/ChartState/WalletChartState';
import {Candle} from '../Candles/Candle';

const CursorCandle: React.FC<{chartState: WalletChartState}> = React.memo(
  observer(({chartState}) => {
    const {
      candlesCursor: {candleLayout},
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    if (!candleLayout) {
      return null;
    }

    return (
      <Candle
        {...candleLayout}
        color={getColorByDirection(candleLayout.direction)}
      />
    );
  })
);

const CursorCrosshair: React.FC<{chartState: WalletChartState}> = React.memo(
  observer(({chartState}) => {
    const {
      candlesCursor: {crosshair},
      height,
      width,
    } = chartState;

    const {colors} = useTheme();

    if (!crosshair) {
      return null;
    }

    return (
      <>
        <Line
          y1={0}
          y2={crosshair.yHigh - 3}
          x1={crosshair.x}
          x2={crosshair.x}
          stroke={colors['$text-02']}
          vectorEffect="non-scaling-stroke"
          strokeWidth={1}
        />

        <Line
          y1={crosshair.yLow + 3}
          y2={height}
          x1={crosshair.x}
          x2={crosshair.x}
          stroke={colors['$text-02']}
          vectorEffect="non-scaling-stroke"
          strokeWidth={1}
        />

        <Line
          y1={crosshair.yClose}
          y2={crosshair.yClose}
          x1={0}
          x2={width}
          stroke={colors['$text-02']}
          vectorEffect="non-scaling-stroke"
          strokeWidth={1}
        />
      </>
    );
  })
);

const CursorPriceLabel: React.FC<{chartState: WalletChartState}> = React.memo(
  observer(({chartState}) => {
    const {
      candlesCursor: {priceLabel, direction},
    } = chartState;

    const {colors} = useTheme();

    const {getColorByDirection} = useChartThemeContext();

    if (!priceLabel) {
      return null;
    }

    return (
      <Label
        height={priceLabel.height}
        width={priceLabel.width}
        x={priceLabel.x}
        y={priceLabel.y}
        labelFill={getColorByDirection(direction!)}
        textFill={colors['$text-04']}
      >
        {priceLabel.text}
      </Label>
    );
  })
);

const CursorInfo: React.FC<{chartState: WalletChartState}> = React.memo(
  observer(({chartState}) => {
    const {colors} = useTheme();

    const {
      candlesCursor: {candleInfo},
    } = chartState;

    if (!candleInfo) {
      return null;
    }

    return (
      <>
        <Label
          height={candleInfo.info.height}
          width={candleInfo.info.width}
          x={candleInfo.info.x}
          y={candleInfo.info.y}
          labelFill={colors['$ui-background']}
          textFill={colors['$text-02']}
        >
          {candleInfo.info.text}
        </Label>

        <Label
          height={candleInfo.date.height}
          width={candleInfo.date.width}
          x={candleInfo.date.x}
          y={candleInfo.date.y}
          labelFill={colors['$ui-background']}
          textFill={colors['$text-02']}
        >
          {candleInfo.date.text}
        </Label>
      </>
    );
  })
);

export const CandlesChartCursor: React.FC<{
  chartState: WalletChartState;
}> = React.memo(
  observer(({chartState}) => {
    return (
      <G>
        <CursorCandle chartState={chartState} />
        <CursorCrosshair chartState={chartState} />
        <CursorPriceLabel chartState={chartState} />
        <CursorInfo chartState={chartState} />
      </G>
    );
  })
);
