import * as React from 'react';
import {StackNavigationOptions} from '@react-navigation/stack';
import {useTheme} from '@youtoken/ui.primitives';
import {type WizardContextValue, WizardContext} from './context';

export const useDefaultWizardScreenOptions = () => {
  const {colors, textVariants} = useTheme();

  return React.useMemo(() => {
    return {
      cardStyle: {
        backgroundColor: colors['$ui-background'],
      },
      headerStyle: {
        backgroundColor: colors['$ui-background'],
        borderBottomWidth: 1,
        borderBottomColor: colors['$ui-01'],
        elevation: 0,
        shadowOpacity: 0,
      },
      headerTitleStyle: {
        color: colors['$text-01'],
        ...textVariants['$heading-02'],
      },

      headerBackTitleVisible: false,
    } as StackNavigationOptions;
  }, [colors, textVariants]);
};

export const useWizard = <
  Wizard extends Object,
  WizardScreen extends keyof Wizard
>(): WizardContextValue<Wizard, WizardScreen> => {
  return React.useContext(WizardContext);
};
