import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {type ExchangeFormContentProps} from '../types';
import {
  ExchangeFormAmountFastHandlers,
  ExchangeFormConversionPair,
  ExchangeFormInfoTableV1,
} from '../..';
import {ExchangeFormContentWrap} from '../Wrap';

export const ExchangeFormContentV1: React.FC<ExchangeFormContentProps> = ({
  showBalance,
  showAmountFastHandlers = true,
}) => {
  return (
    <ExchangeFormContentWrap>
      <Box flex={1} p={24}>
        <ExchangeFormConversionPair
          showBalance={showBalance}
          targetLabelKey={'surface.wallets.conversion_form.to'}
          targetAmountEditable={false}
        />
        {showAmountFastHandlers && <ExchangeFormAmountFastHandlers />}
        <ExchangeFormInfoTableV1 />
      </Box>
    </ExchangeFormContentWrap>
  );
};
