import * as React from 'react';
import {Platform, Animated, ViewProps} from 'react-native';
import {LinearGradient, LinearGradientProps} from 'react-native-svg';

// @ts-ignore
export const AnimatedLinearGradient = Platform.select({
  // web Animated.createAnimatedComponent should be classes
  web: () =>
    Animated.createAnimatedComponent(
      class AnimatedGroup extends React.PureComponent<
        LinearGradientProps & ViewProps
      > {
        render() {
          const {collapsable, ...props} = this.props;
          return <LinearGradient {...props} />;
        }
      }
    ),
  default: () =>
    Animated.createAnimatedComponent(
      LinearGradient
    ) as Animated.AnimatedComponent<
      // @ts-ignore
      React.Component<LinearGradientProps & ViewProps>
    >,
})()!;
