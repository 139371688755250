import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useWizard} from '@youtoken/ui.elements';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {ExchangeFormAgreeToTerms} from '../../ExchangeForm';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../state';
import {
  BuyCryptoFormAuthorizedOnRampWizardNavigator,
  BuyCryptoFormAuthorizedOnRampWizardScreenName,
} from './Authorized';

export interface FormFooterProps {
  showAgreements?: boolean; // NOTE: only for authorised users
}

export const FormFooter: React.FC<FormFooterProps & BoxProps> = observer(
  ({showAgreements = true, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        ticker,
        depositMethod,
        depositMethodType,
        checkIsLoading,
        rateTimeLeft,
      },
      view: {convertActionText},
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    const {navigate} = useWizard<
      BuyCryptoFormAuthorizedOnRampWizardNavigator,
      BuyCryptoFormAuthorizedOnRampWizardScreenName
    >();

    return (
      <Box backgroundColor="$ui-background" p={24} {...boxProps}>
        {showAgreements && <ExchangeFormAgreeToTerms mb={16} />}
        {depositMethodType === 'later' ? (
          <SubmitButton
            testID="CONVERSION_SUBMIT_BUTTON"
            onPress={() => {
              navigate(BuyCryptoFormAuthorizedOnRampWizardScreenName.LATER, {
                method: depositMethod,
                ticker,
              });
            }}
          >
            {t('ramp.conversion_form.action.submit_deposit')}
          </SubmitButton>
        ) : (
          <SubmitButton
            testID="CONVERSION_SUBMIT_BUTTON"
            loading={checkIsLoading}
          >
            {convertActionText} ({rateTimeLeft})
          </SubmitButton>
        )}
      </Box>
    );
  }
);
