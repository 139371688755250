import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardSummaryResource} from '@youtoken/ui.resources-rewards';
import {ReferralsHeaderV1, ReferralsWidget} from '../../components';

export const ReferralsWidgetV1Smart = observer(() => {
  const {
    data: {
      totalEarned: {usd: totalEarnedUSD},
      maxReferralEarning: {usd: maxEarningUSD},
      referralsCount,
      newReferralEarningsCount,
    },
  } = RewardSummaryResource.use({});

  return (
    <ReferralsWidget
      header={
        <ReferralsHeaderV1
          variant="$heading-02"
          amountFormatted={'$' + Number(maxEarningUSD)}
        />
      }
      referralsCount={referralsCount}
      newEarnedUSD={newReferralEarningsCount}
      totalEarnedUSD={Number(totalEarnedUSD)}
    />
  );
});
