import * as React from 'react';
import {Platform, ViewProps} from 'react-native';
import {
  ResizableBox,
  useResizableBox,
  ResizableCallbacks,
} from './ResizableBox';
import {type BoxProps} from '@youtoken/ui.primitives';
import {ResizingHintComponent as DefaultResizingHintComponent} from './ResizingHintComponent';

const DefaultOverlayComponent = Platform.select<
  React.FC<{isResizing?: boolean}>
>({
  default: ({children}) => <>{children}</>,
})!;

type ResizableBoxWithOverlayProps = {
  OverlayComponent?: React.FC<{isResizing: boolean}>;
  ResizingHintComponent?: React.FC;
} & ResizableCallbacks &
  BoxProps &
  ViewProps;

/** same as `ResizableBox` but with overlay while resizing to "mask" content shifts
 *
 *  - **web-only component**;
 *  - accepts components for hint and for overlay, but defaults are production ready;
 **/
export const ResizableBoxWithOverlay: React.FC<
  ResizableBoxWithOverlayProps
> = ({
  children,
  onResizingStateChange,
  onSizeChange,
  OverlayComponent = DefaultOverlayComponent,
  ResizingHintComponent = DefaultResizingHintComponent,
  ...props
}) => {
  const [{isResizing}, bind] = useResizableBox({
    onResizingStateChange,
    onSizeChange,
  });

  return (
    <ResizableBox overflow="hidden" {...bind} {...props}>
      <OverlayComponent isResizing={isResizing}>{children}</OverlayComponent>
      {isResizing && <ResizingHintComponent />}
    </ResizableBox>
  );
};
