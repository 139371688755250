import * as React from 'react';
import {Helmet} from 'react-helmet';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {RouterResource} from '../../../routes/RouterResource';
import {SignInSurface} from '@youtoken/ui.auth-surfaces';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {AuthFormWrapper} from '@web-app/src/components/layout/Shell/AppShell/AuthFormWrapper';

export const SignInPage: React.FC = () => {
  const {t} = useTranslation();

  const {queryParams} = RouterResource.use({});

  const handlePressSignUp = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('SignUp', {}, queryParams);
  }, [queryParams]);

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
        renderRight={() => {
          return (
            <Box flexDirection="row" alignItems="center" flexShrink={1}>
              <Text variant="$body-02" color="$text-02" mr={10} ml={10}>
                {t('surface.auth.dont_have_account')}
              </Text>
              <Box>
                <Button
                  size="small"
                  onPress={handlePressSignUp}
                  testID="SIGN_UP_LINK"
                >
                  {t('surface.auth.actions.sign_up')}
                </Button>
              </Box>

              <LanguageSwitch
                variant="icon"
                size="small"
                width={96}
                dropdownWidth={116}
                boxProps={{ml: 16}}
                testID="LANGUAGE_LIST"
              />
            </Box>
          );
        }}
      />
    );
  }, [t]);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
      <Helmet title={t('surface.sign_in.tab_title')} />
      <OuterPageShell>
        <AuthFormWrapper>
          <SignInSurface />
        </AuthFormWrapper>
      </OuterPageShell>
    </AppShell>
  );
};
