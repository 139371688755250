import * as React from 'react';
import {Button} from '@youtoken/ui.buttons';

export const MenuButton: React.FC<React.ComponentProps<typeof Button>> = ({
  children,
  ...buttonProps
}) => {
  return (
    <Button size="small" px={12} py={8} height={32} {...buttonProps}>
      {children}
    </Button>
  );
};
