import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {INTERCOM_SERVICE} from '@youtoken/ui.service-intercom';
import {MenuItemBase} from './MenuItemBase';

export const ContactSupport: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <MenuItemBase
      icon="message"
      title={t('surface.more.item_name.support')}
      onPress={INTERCOM_SERVICE.displayMessenger}
      {...boxProps}
    />
  );
});
