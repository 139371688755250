import React from 'react';
import {useParams} from 'react-router-dom';
import {cell} from '@youtoken/ui.cell';
import {Back, LoanItemRegularSurface} from '@youtoken/ui.surface-loans';

export const Content: React.FC = cell(() => {
  const {id} = useParams<{id: string}>();

  return (
    <>
      <Back px={{tablet: 0, default: 20}} />
      <LoanItemRegularSurface
        id={id}
        position="relative"
        borderTopWidth={1}
        borderRightWidth={{
          tablet: 1,
          default: 0,
        }}
        borderBottomWidth={1}
        borderLeftWidth={{
          tablet: 1,
          default: 0,
        }}
        borderColor="$ui-01"
        borderRadius={{
          tablet: 5,
          default: 0,
        }}
      />
    </>
  );
});
