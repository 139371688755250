import * as React from 'react';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {LegalInfoProfileSurface} from '@youtoken/ui.surface-legal-info';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {goBack} from '../../../../services/shared-router';

export const LegalInfoPage: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.profile.legal_info.title')} />
      <Box px={{desktop: 0, default: 20}}>
        <Box mb={22} flexDirection="row" justifyContent="flex-start">
          <Button type="ghost" onPress={goBack} ml={-5}>
            <Box width="100%" flexDirection="row" alignItems="center">
              <Icon name="chevron_left" color="$interactive-01" />
              <Text color="$interactive-01" variant="$body-01-medium-accent">
                {t('surface.profile.form_confirm_phone.action.back')}
              </Text>
            </Box>
          </Button>
        </Box>
        <LegalInfoProfileSurface />
      </Box>
    </InnerPageShell>
  );
});
