import * as React from 'react';
import {OptionPropsWeb} from './types';
import {Text, Box} from '@youtoken/ui.primitives';
import {getItemBG} from './utils';
import {Icon} from '@youtoken/ui.icons';
import {Listbox} from '@headlessui/react';

export const Option: React.FC<OptionPropsWeb> = ({value, height, label}) => {
  return (
    <Listbox.Option value={value} as={React.Fragment}>
      {({active, selected}) => (
        <Box
          height={height}
          // @ts-ignore
          style={{cursor: 'pointer'}}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={16}
          paddingRight={16}
          backgroundColor={getItemBG(active, selected)}
          testID={`SELECT_ITEM_${value.toUpperCase()}`}
        >
          <Text color="$text-01">{label}</Text>
          {selected && <Icon name="check" color="$text-05" />}
        </Box>
      )}
    </Listbox.Option>
  );
};
