import {observable, computed, action} from 'mobx';
import {
  type SavingsSignAgreementsFormProps,
  type SavingsSignAgreementsFormResources,
} from '../types';
import {Form} from './Form';

export class SavingsSignAgreementsFormState {
  @observable
  args: SavingsSignAgreementsFormProps;

  @observable
  resources: SavingsSignAgreementsFormResources;

  @observable
  form: Form;

  @computed
  get submitting() {
    return this.form.instance.submitting;
  }

  @action
  submit = (e: SubmitEvent) => {
    return this.form.instance.onSubmit(e);
  };

  constructor(
    args: SavingsSignAgreementsFormProps,
    resources: SavingsSignAgreementsFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.form = new Form(this.args, this.resources);
  }
}
