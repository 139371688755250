import * as React from 'react';
import {Animated, Platform, ViewProps} from 'react-native';

import {G, GProps} from 'react-native-svg';

export const AnimatedGroup = Platform.select({
  // web Animated.createAnimatedComponent should be classes
  web: () =>
    Animated.createAnimatedComponent(
      class AnimatedGroup extends React.PureComponent<GProps & ViewProps> {
        render() {
          const {collapsable, ...props} = this.props;
          return <G {...props} />;
        }
      }
    ),
  default: () =>
    Animated.createAnimatedComponent(G) as Animated.AnimatedComponent<
      React.FC<GProps & ViewProps>
    >,
})()!;
