import * as React from 'react';
import {Box, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {type TouchableOpacityProps} from 'react-native';

export type CheckboxProps = {
  disabled?: boolean;
  value?: boolean;
  onChange?: (checked: boolean) => void;
  hasError?: boolean;
} & BoxProps;

export const Checkbox: React.FC<
  CheckboxProps & Pick<TouchableOpacityProps, 'testID'>
> = ({
  disabled = false,
  value = false,
  onChange,
  hasError = false,
  testID,
  ...boxProps
}) => {
  return (
    <Box position="relative" mr={10} {...boxProps}>
      <TouchableBox
        testID={testID}
        disabled={disabled}
        borderRadius={4}
        borderWidth={2}
        width={20}
        height={20}
        accessible
        accessibilityRole="checkbox"
        accessibilityState={{
          checked: value,
          disabled: disabled,
        }}
        borderColor={
          value
            ? disabled
              ? '$ui-02'
              : '$interactive-01'
            : disabled
            ? '$ui-02'
            : '$ui-04'
        }
        backgroundColor={
          value ? (disabled ? '$ui-02' : '$interactive-01') : '$ui-background'
        }
        onPress={() => onChange?.(!value)}
      >
        {value && (
          <Box
            position="absolute"
            top={4}
            left={3}
            width={9}
            height={6}
            borderColor="$text-04"
            borderRadius={1}
            borderLeftWidth={2}
            borderBottomWidth={2}
            style={{
              transform: [{rotate: '-45deg'}],
            }}
          />
        )}
      </TouchableBox>
      {hasError && (
        <Box
          borderRadius={8}
          borderWidth={2}
          width={20 + 8}
          height={20 + 8}
          position="absolute"
          zIndex={-1}
          left={-4}
          top={-4}
          borderColor="$danger-01"
        />
      )}
    </Box>
  );
};
