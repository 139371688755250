import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel} from '@youtoken/ui.resource-loyalty';
import {Box, Heading} from '@youtoken/ui.primitives';
import {LoyaltyIcon} from '@youtoken/ui.icons';
import {AccountLevelInfoItemAllBenefits} from '../../smarts';
import {UpgradeToLevelRequirements} from './Requirements';
import {UpgradeToLevelMaxIncomes} from './MaxIncomes';
import {UpgradeToLevelAction} from './Action';

interface UpgradeToLevelProps {
  level: LoyaltyLevel;
  showSavingsInfo?: boolean;
  showMinerInfo?: boolean;
  onPressAction?: () => void;
}

export const UpgradeToLevel: React.FC<UpgradeToLevelProps> = observer(
  ({level, showSavingsInfo = true, showMinerInfo = true, onPressAction}) => {
    const {t} = useTranslation();

    const {level: levelNumber, cardIconName, cardColor} = level;

    return (
      <Box
        p={{default: 16, phone: 24}}
        backgroundColor={cardColor}
        borderRadius={8}
      >
        <Box alignItems="center" mb={24}>
          <Box justifyContent="center" alignItems="center" mb={8}>
            <LoyaltyIcon name={cardIconName} size={72} />
          </Box>
          <Heading textAlign="center" mt={8}>
            {t('surface.loyalty.upgrade_to_level_modal.title')}
          </Heading>
          <UpgradeToLevelRequirements level={level} />
        </Box>
        <UpgradeToLevelMaxIncomes
          showSavingsInfo={showSavingsInfo}
          showMinerInfo={showMinerInfo}
          level={level}
        />
        <Box>
          <UpgradeToLevelAction
            levelNumber={levelNumber}
            onPress={onPressAction}
            mb={8}
          />
          <AccountLevelInfoItemAllBenefits level={levelNumber} />
        </Box>
      </Box>
    );
  }
);
