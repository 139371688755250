import * as React from 'react';
import {useChartThemeContext} from '../../Providers';
import {LineCurve} from '../../Primitives';
import {observer} from 'mobx-react';
import {BaseChartState} from '../../logic';

export const DataLine: React.FC<{chartState: BaseChartState}> = React.memo(
  observer(({chartState}) => {
    const {
      lines: {dataLine},
      direction,
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    if (!dataLine) {
      return null;
    }

    return <LineCurve d={dataLine} stroke={getColorByDirection(direction)} />;
  })
);
