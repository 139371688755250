import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

export interface RewardsHowItWorksConditionsItemProps {
  icon: BaseIconName;
  title: string;
  description: string;
}

export const RewardsHowItWorksConditionsItem: React.FC<RewardsHowItWorksConditionsItemProps> =
  React.memo(({icon, title, description}) => {
    return (
      <Box flexDirection="row" alignItems="flex-start" my={8}>
        <Box p={10} backgroundColor="$interactive-02" borderRadius={20}>
          <Icon name={icon} size={20} color="$text-05" />
        </Box>
        <Box flex={1} ml={16}>
          <Text variant="$body-01-high-accent" color="$text-05" mt={4}>
            {title}
          </Text>
          <Text variant="$body-02" color="$text-02" mt={4}>
            {description}
          </Text>
        </Box>
      </Box>
    );
  });
