import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {
  type UpgradeToLevelSmartProps,
  UpgradeToLevelSmart,
} from '../../../smarts';

export const UpgradeToLevelModal: React.FC<
  UpgradeToLevelSmartProps & React.ComponentProps<typeof Modal>
> = cell(({levelNumber, onPressAction, ...modalProps}) => {
  return (
    <Modal testID="UPGRADE_TO_LEVEL_MODAL" {...modalProps}>
      <UpgradeToLevelSmart
        levelNumber={levelNumber}
        onPressAction={onPressAction ?? modalProps.onClose}
      />
    </Modal>
  );
});
