import React from 'react';

export const InsideModalContext = React.createContext(false);

export const InsideModalContextProvider: React.FC<{value?: boolean}> = ({
  value = false,
  children,
}) => {
  return (
    <InsideModalContext.Provider value={value}>
      {children}
    </InsideModalContext.Provider>
  );
};
