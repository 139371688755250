import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

export const InformationTable: React.FC<BoxProps> = ({
  children,
  ...boxProps
}) => {
  return (
    <Box py={24} {...boxProps}>
      {children}
    </Box>
  );
};
