import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {
  LevelIcon,
  LevelStatusView,
} from '@youtoken/ui.loyalty-miner-components';
import {AccountLevelRequirements} from '@youtoken/ui.surface-account-levels';
import {invariant} from '@youtoken/ui.utils';
import {
  GetWelcomePack,
  LevelTermsTable,
  YourLevelSticker,
  LevelRewardPerBlock,
  LevelCardTitle,
} from '.';

type LevelCardProps = {
  levelNumber: number;
} & BoxProps;

export const LevelCard: React.FC<LevelCardProps> = observer(
  ({levelNumber, ...boxProps}) => {
    const {getLevel} = LoyaltyResource.use({});
    const level = getLevel(levelNumber);

    invariant(level, `data for loyalty level ${levelNumber} not found`);

    const {
      name,
      status,
      cardColor,
      cardIconName,
      miner: {
        earnAmountFormatted,
        welcomePack,
        miningCost,
        miningPeriod,
        blocksAvailable,
        freeSparksAmount,
        freeSparksPeriod,
        freeSparksTerminatePeriodFormatted,
      },
    } = level;

    return (
      <Box
        testID={`LEVEL_${levelNumber}`}
        minHeight={473}
        height="100%"
        {...boxProps}
      >
        <Box
          borderRadius={16}
          backgroundColor={cardColor}
          mt={24}
          px={24}
          flex={1}
        >
          <Box flexDirection="row" justifyContent="center" zIndex={1} mt={-28}>
            <LevelIcon name={cardIconName} bg={cardColor} />
          </Box>

          <LevelStatusView status={status} zIndex={1} />
          {/* Header */}
          <Box alignItems="center" mt={12}>
            <LevelCardTitle variant={status} text={name!} />
            <LevelRewardPerBlock
              variant={status === 'completed' ? 'locked' : 'success'}
              amount={earnAmountFormatted}
            />
          </Box>

          {/* Terms table */}
          <LevelTermsTable
            mt={16}
            variant={status}
            miningCost={miningCost}
            miningPeriod={miningPeriod}
            blocksAvailable={blocksAvailable}
            freeSparksPeriod={freeSparksPeriod ?? undefined}
            freeSparksAmount={freeSparksAmount}
            freeSparksTerminatePeriodFormatted={
              freeSparksTerminatePeriodFormatted ?? undefined
            }
          />

          <Box justifyContent="center" alignItems="center" flex={1}>
            {/* 'Your Level' sticker */}
            {status === 'active' && (
              <Box flexDirection="row" justifyContent="center" mb={22}>
                <YourLevelSticker />
              </Box>
            )}

            {/* Lower part elements */}
            {status !== 'active' && (
              <Box alignItems="center" flex={1} mt={24}>
                <GetWelcomePack
                  welcomePackSize={welcomePack}
                  variant={status}
                />
                <Box justifyContent="center" alignItems="center" flex={1}>
                  <AccountLevelRequirements id={levelNumber} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
);
