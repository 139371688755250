import type {BigSource} from 'big.js';
import {HODLItem, HODLItemCalculatedResult} from '@youtoken/ui.resource-hodl';
import {deserialize} from 'serializr';
import {prepare} from '@youtoken/multihodl-calculator';
import {toBig} from '@youtoken/ui.formatting-utils';

export const deserializeHODLItemCalculatedResult = (
  hodl: HODLItem,
  givenPrices: BigSource,
  givenRates: BigSource
) => {
  return deserialize(
    HODLItemCalculatedResult,
    prepare(
      {
        baseTicker: hodl.baseTicker,
        quoteTicker: hodl.quoteTicker,
        status: hodl.status,
        inputAmount: hodl.inputAmount,
        inputTicker: hodl.inputTicker,
        outputTicker: hodl.outputTicker,
        isShort: hodl.data.isShort,
        totalAmount: toBig(hodl.totalAmount),
        ownAmount: toBig(hodl.data.ownAmount),
        borrowedAmount: toBig(hodl.borrowedAmount),
        borrowedTicker: hodl.data.borrowedTicker,
        overdraftAmount: hodl.data.overdraftAmount,
        overdraftTicker: hodl.data.overdraftTicker,
        initialPrice: toBig(hodl.initialPrice),
        settlementPeriod: hodl.settlementPeriod ?? '',
        apr: hodl.data.apr,
      },
      givenPrices,
      givenRates
    )
  );
};
