import {observable} from 'mobx';
import {
  type TwoFactorDisableFormArgs,
  type TwoFactorDisableFormResources,
} from './types';
import {Form} from './Form';

export class TwoFactorDisableFormState {
  @observable
  public form: Form;

  constructor(
    args: TwoFactorDisableFormArgs,
    resources: TwoFactorDisableFormResources
  ) {
    this.form = new Form(args, resources);
  }
}
