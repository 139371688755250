import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {InstrumentListItem} from '../InstrumentsListItem';
import {HodlTariffResponseItem} from '@youtoken/ui.resource-hodl-tariffs';
import {HODLPublicInstrumentItem} from '@youtoken/ui.resource-hodl-public-instruments';

export interface InstrumentsListProps extends BoxProps {
  items: Array<HodlTariffResponseItem | HODLPublicInstrumentItem>;
  withoutBorders?: boolean;
  onPressInstrument?: () => void;
  onPressAction?: () => void;
}

export const InstrumentsList: React.FC<InstrumentsListProps> = observer(
  ({
    items,
    withoutBorders,
    onPressInstrument,
    onPressAction,
    ...otherProps
  }) => {
    return (
      <Box
        borderColor="$ui-01"
        borderTopWidth={withoutBorders ? 0 : 1}
        borderRightWidth={{default: 0, desktop: 1}}
        borderBottomWidth={{default: withoutBorders ? 0 : 1, desktop: 1}}
        borderLeftWidth={{default: 0, desktop: 1}}
        borderRadius={{default: 0, desktop: withoutBorders ? 0 : 10}}
        {...otherProps}
      >
        {items.map((item, index, list) => {
          return (
            <InstrumentListItem
              key={item.id}
              item={item}
              shouldShowSeparator={index < list.length - 1}
              testID={`ALL_INSTRUMENTS_ITEM_${
                item.baseTickerFormatted + item.quoteTickerFormatted
              }`}
              onPressInstrument={onPressInstrument}
              onPressAction={onPressAction}
            />
          );
        })}
      </Box>
    );
  }
);
