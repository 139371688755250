import * as React from 'react';
import {G, Line, LineProps} from 'react-native-svg';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {Label} from '../Primitives';
import {BaseChartState} from '../logic';

export const TickLine: React.FC<LineProps> = React.memo(props => {
  const {colors} = useTheme();

  return (
    <Line
      vectorEffect="non-scaling-stroke"
      stroke={colors['$text-01']}
      opacity={0.04}
      strokeWidth={1}
      {...props}
    />
  );
});

export const GridLines: React.FC<{
  chartState: BaseChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {
      width: chartWidth,
      grid: {
        layout: {ticks},
      },
    } = chartState;

    return (
      <G>
        {ticks.map(({y, width}, i) => {
          return (
            <TickLine
              key={`${i}-${y}`}
              y={y}
              x1={0}
              x2={chartWidth - width}
              y1={y}
              y2={y}
            />
          );
        })}
      </G>
    );
  })
);

export const GridLabels: React.FC<{
  chartState: BaseChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {
      width: chartWidth,
      grid: {layout},
    } = chartState;
    const {colors} = useTheme();

    return (
      <G>
        {layout.ticks.map(({y, width, label}, i) => {
          return (
            <Label
              key={`${i}-${y}`}
              x={chartWidth - width}
              y={y}
              width={width}
              height={16}
              labelFill={colors['$ui-background']}
              textFill={colors['$text-03']}
            >
              {label}
            </Label>
          );
        })}
      </G>
    );
  })
);
