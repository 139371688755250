import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowOpenedProps {
  item: LoanRegularV2;
}

export const LoanItemDataTableRowOpened: React.FC<LoanItemDataTableRowOpenedProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    if (!item.startedAtFormatted) {
      return null;
    }

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_OPENED"
        left={t('surface.loans.item.table.opened')}
        right={
          <Text
            testID="LOAN_ITEM_TABLE_ROW_OPENED_VALUE"
            variant="$body-02"
            color="$text-02"
          >
            {item.startedAtFormatted}
          </Text>
        }
      />
    );
  });
