import React from 'react';
import {Helmet} from 'react-helmet';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {GetLoanPageContent as Content} from './Content';
import {useOnMount} from '@web-app/src/hooks';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const Create: React.FC = () => {
  const {t} = useTranslation();

  useOnMount(() => {
    DATA_LAYER.trackStrict('loan-turbo-create-attempt', {});
  });

  return (
    <InnerPageShell>
      <Helmet title={t('surface.loans.tab_turbos_create_title')} />
      <Content />
    </InnerPageShell>
  );
};
