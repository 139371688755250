import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type DepositInvoiceForm} from '../../state/DepositInvoiceForm';

export const PaymentInfo: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {currentFeeFormatted},
    view: {tickerFormatted, minAmountFormatted},
  } = useForm<DepositInvoiceForm>();

  return (
    <Box mb={20}>
      <Box flexDirection="row" mb={8}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.wallets.fiat_deposit_wire.min_amount_label')}{' '}
        </Text>
        <Text variant="$body-02-medium-accent">
          <Text testID="FIAT_DEPOSIT_BANK_WIRE_MIN_AMOUNT">
            {minAmountFormatted}
          </Text>{' '}
          {tickerFormatted}
        </Text>
      </Box>
      <Box flexDirection="row" mb={8}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.wallets.fiat_deposit_wire.fee_amount_label')}{' '}
        </Text>
        <Text variant="$body-02-medium-accent">
          <Text testID="FIAT_DEPOSIT_BANK_WIRE_AMOUNT_FEE">
            {currentFeeFormatted}
          </Text>{' '}
          {tickerFormatted}
        </Text>
      </Box>
    </Box>
  );
});
