import {computed, action, observable} from 'mobx';
import {Platform} from 'react-native';
import {
  createFeature,
  getResourceDescriptor,
  useResource,
} from '@youtoken/ui.data-storage';
import type {TFuncKey} from 'react-i18next';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  BankCardsResource,
  type TBankCardFormat,
  type TBankCardStatus,
  openBrowser,
} from '@youtoken/ui.resource-bank-cards';
import {i18n} from '@youtoken/ui.service-i18n';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {extractErrorFromResponse} from '@youtoken/ui.validation-messages';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {isInsufficientFunds} from './utils';

const MAX_WIDGET_CARDS = 3;

export class BankCardsFeature extends createFeature({
  getKey: () => 'bankCardsFeature',
  getResources: () => {
    const {residenceOrCountry} = useResource(AuthMeResource, {});

    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      bankCardsResource: getResourceDescriptor(BankCardsResource, {}),
      docs: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
    };
  },
}) {
  @computed
  public get featureEnabled() {
    const {
      data: {enableVirtualBankCard, enablePhysicalBankCard},
    } = this.resources.authMeResource;
    return enableVirtualBankCard || enablePhysicalBankCard;
  }

  @computed
  public get openingCardStatuses(): TBankCardStatus[] {
    return ['AVAILABLE', 'ONBOARDING', 'CREATING'];
  }

  @computed
  public get cards() {
    return this.resources.bankCardsResource.cards;
  }

  @computed
  public get pageTitle() {
    if (this.cards.length > 1) {
      return i18n.t('surface.bank_cards.title.your_cards');
    }
    if (this.cards.length === 1 && this.cards[0]!.status === 'AVAILABLE') {
      return i18n.t('surface.bank_cards.title.get_your_card');
    }
    return i18n.t('surface.bank_cards.title.your_card');
  }

  @computed
  public get isPromoBannerAvailable() {
    return (
      this.featureEnabled &&
      this.cards.some(card => this.openingCardStatuses.includes(card.status))
    );
  }

  // user has at leat one fully opened card
  @computed
  public get isWidgetAvailable() {
    return (
      this.featureEnabled &&
      this.cards.some(card => !this.openingCardStatuses.includes(card.status))
    );
  }

  // widget on home page should show only real cards, not the mock ones for opening
  @computed
  public get cardsForWidget() {
    return this.cards
      .filter(card => !this.openingCardStatuses.includes(card.status))
      .slice(0, MAX_WIDGET_CARDS);
  }

  // cards todo: use an endpoint for it when it's ready
  @computed
  public get accountBalance() {
    const cards = this.cards.filter(
      card => !this.openingCardStatuses.includes(card.status)
    );
    return cards.find(card => card.balance !== null)?.balanceFormatted;
  }

  @computed
  public get agreementsUrl() {
    const docs = this.resources.docs.data.intergiroCards?.[0];
    if (!docs) {
      return null;
    }

    const {i18nLink, link} = docs;

    if (!i18nLink) {
      return link;
    }

    return i18n.t(i18nLink as TFuncKey, {defaultValue: link});
  }

  @computed
  public get agreementsLinkText() {
    const docs = this.resources.docs.data.intergiroCards?.[0];
    if (!docs) {
      return null;
    }

    const {i18nName, name} = docs;

    if (!i18nName) {
      return name;
    }

    return i18n.t(i18nName as TFuncKey, {defaultValue: name});
  }

  @computed
  public get hasAgreement() {
    return Boolean(this.agreementsUrl && this.agreementsLinkText);
  }

  @action redirectToDetails = (cardId: string) => {
    const returnUrl = Platform.select({
      native: '/wallets/cards/consent-final',
      web: '/wallets/cards',
    });

    TRANSPORT.API.get('/v1/intergiro/cards/details', {
      params: {
        cardId,
        redirectURL: returnUrl,
      },
    })
      .then(({data: {redirectURL}}) => {
        return openBrowser(redirectURL);
      })
      .then(() => {
        if (Platform.OS !== 'web') {
          this.refetch();
          SHARED_ROUTER_SERVICE.navigate('BankCards');
        }
      });
  };

  @observable isCheckingIfCardCanBeOpened = false;

  @action verifyCardCanBeOpenedOrRedirect = (format: TBankCardFormat) => {
    this.isCheckingIfCardCanBeOpened = true;

    return this.resources.bankCardsResource
      .checkIfCardCanBeOpened(format)
      .then(() => Promise.resolve())
      .catch(error => {
        const _error = extractErrorFromResponse(error.response?.data, '_error');
        if (isInsufficientFunds(_error)) {
          this.trackFundsInsufficient(format);
          LOCAL_NOTIFICATIONS.error({
            text: i18n.t('validation.FUNDS_INSUFFICIENT'),
          });
          SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
        } else {
          LOCAL_NOTIFICATIONS.error({
            text: i18n.t('common.errors.smth_went_wrong'),
          });
        }
        throw error;
      })
      .finally(() => {
        this.isCheckingIfCardCanBeOpened = false;
      });
  };

  @action openCard = (format: TBankCardFormat) => {
    DATA_LAYER.trackStrict('card-open-attempt', {
      productType: format,
      provider: 'InterGiro',
    });

    return this.resources.bankCardsResource.openCard(format).catch(error => {
      if (isInsufficientFunds(error.response?.data)) {
        this.trackFundsInsufficient(format);
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('validation.FUNDS_INSUFFICIENT'),
        });

        SHARED_ROUTER_SERVICE.navigate('DepositWizard', {});
      } else {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('common.errors.smth_went_wrong'),
        });
      }
    });
  };

  trackFundsInsufficient = (format: TBankCardFormat) => {
    DATA_LAYER.trackStrict('card-fee-requested', {
      productType: format,
      provider: 'InterGiro',
    });
  };
}
