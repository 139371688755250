import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HowItWorksModal} from './HowItWorksModal';

export const HowItWorksButton: React.FC = observer(() => {
  const {t} = useTranslation();
  const [isModalOpen, setModalOpen] = React.useState(false);

  const handlePress = React.useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const onClose = React.useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <>
      <TouchableBox
        testID="HOW_IT_WORKS_BUTTON"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        pl={12}
        pr={16}
        py={8}
        borderRadius={8}
        backgroundColor="$ui-background"
        borderColor="$ui-01"
        borderWidth={1}
        onPress={handlePress}
      >
        <Icon name="question" color="$text-02" size={20} />
        <Text variant="$body-01-medium-accent" color="$text-02" ml={4}>
          {t('surface.miner.how_it_works')}
        </Text>
      </TouchableBox>

      <HowItWorksModal
        title={t('surface.miner.how_it_works')}
        isOpen={isModalOpen}
        onClose={onClose}
      />
    </>
  );
});
