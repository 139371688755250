import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {SubmitButton} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const Footer: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box width="100%" px={24}>
      <SubmitButton>
        {t('surface.more.2fa_enable_ga.action.enable')}
      </SubmitButton>
      <Box alignItems="center" my={16}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.more.2fa_enable_ga.message.info')}
        </Text>
      </Box>
    </Box>
  );
});
