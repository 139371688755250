import * as React from 'react';
import {Modal} from '@youtoken/ui.modal';
import {ShareRefCodeSmart} from '../../smarts';

export const ShareRefCodeModal: React.FC<
  React.ComponentProps<typeof Modal>
> = modalProps => {
  return (
    <Modal
      shouldUseAnimatedSnapPoints
      snapPoints={['CONTENT_HEIGHT']}
      nativeStackBehavior="push"
      {...modalProps}
    >
      <ShareRefCodeSmart />
    </Modal>
  );
};
