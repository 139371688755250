import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';

export const Rules: React.FC<BoxProps> = boxProps => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Link url="https://help.youhodler.com/en/articles/4190420">
        <Text variant="$body-02" color="$text-05">
          {t('surface.wallets.fiat_deposit_adv_cash.rules')}
        </Text>
      </Link>
    </Box>
  );
};
