import * as React from 'react';
import {observer} from 'mobx-react';
import {Link} from '@youtoken/ui.elements';
import {Box, Heading, Text, type BoxProps} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CompanyResource} from '@youtoken/ui.resource-companies';

export const DisclosureItaly: React.FC<BoxProps> = observer(boxProps => {
  const {legalEntity} = AuthMeResource.use({});
  const {
    documents: {disclosureIta},
  } = DocsResource.use({country: legalEntity});
  const {youhodlerITA} = CompanyResource.use({});

  const {t} = useTranslation();

  if (!disclosureIta || !disclosureIta.length) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
        {/* @ts-ignore */}
        {t('surface.legal.disclosure_ita').toUpperCase()}
      </Heading>

      <Box mb={16}>
        <Text variant="$body-02" textTransform="uppercase" mb={12}>
          {youhodlerITA.name}
        </Text>
        <Text variant="$body-02" color="$text-02" mb={4}>
          {youhodlerITA.address}
        </Text>
        <Text variant="$body-02" color="$text-02" mb={4}>
          {youhodlerITA.phone}
        </Text>

        <Link
          underlined
          variant="$body-02"
          color="$text-02"
          url="mailto:welcome@youhodler.com"
        >
          welcome@youhodler.com
        </Link>
      </Box>

      <Text variant="$body-02" textTransform="uppercase" mb={12}>
        {t('surface.profile.legal_info.subtitle.documents')}
      </Text>

      {Boolean(disclosureIta) &&
        disclosureIta.map(item => {
          const itemUrl = item.link;
          // @ts-ignore
          const itemName = item.i18nName ? t(item.i18nName) : item.name;

          return (
            <Box flexDirection="row" mb={8} key={itemUrl}>
              <Link
                underlined
                url={itemUrl}
                variant="$body-02"
                color="$text-02"
              >
                {itemName}
              </Link>
            </Box>
          );
        })}
    </Box>
  );
});
