import * as React from 'react';
import {observer} from 'mobx-react';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Label} from '@youtoken/ui.form-elements';
import {invariant} from '@youtoken/ui.utils';
import {UmaInfoFeature} from '../../feature';

export const TickerSelector: React.FC = observer(() => {
  const {t} = useTranslation();

  const {ticker, tickersOptions, setTicker, isTickerUpdating} =
    UmaInfoFeature.use({});

  invariant(ticker, 'Cannot get ticker from UmaInfoFeature');

  return (
    <Box p={24} zIndex={1}>
      <Label mb={8}>{t('surface.wallets.uma.ticker_selector.label')}</Label>
      <TickerAndAmountInput
        ticker={ticker}
        disabled={isTickerUpdating}
        onChangeTicker={setTicker}
        wallets={tickersOptions}
        tickerOnly
        zIndex={10}
        testID="UMA_SETTINGS_TICKER_SELECTOR"
      />
      <Text variant="$body-02" color="$text-02" mt={8}>
        {t('surface.wallets.uma.ticker_selector.explanation')}
      </Text>
    </Box>
  );
});
