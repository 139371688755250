import {normalizeAmount} from './normalizeAmount';

export const normalizeAmountWithPrecision = (
  value: string,
  precision: number = 2
) => {
  const str = normalizeAmount(value);

  const parts = str.split('.');
  if (parts[1]) {
    parts[1] = parts[1].substr(0, precision);
  }

  return parts.join('.');
};
