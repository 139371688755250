import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {createWizard} from '@youtoken/ui.elements';
import {IAdvCashData} from './types';
import {getAdvCashWizardOptions} from './utils';
import {AdvCashCreatePayment} from './CreatePayment';
import {AdvCashPayment} from './Payment';

export enum AdvCashWizardScreenName {
  MAIN = 'main',
  PAYMENT = 'payment',
}

export interface AdvCashWizardNavigator {
  [AdvCashWizardScreenName.MAIN]: {
    ticker: string;
    amount?: string;
  };
  [AdvCashWizardScreenName.PAYMENT]: IAdvCashData;
}

const Wizard = createWizard<AdvCashWizardNavigator>();

export interface AdvCashWizardProps {
  ticker: string;
  amount?: string;
  onPressBack?: () => void;
}

export const AdvCashWizard: React.FC<AdvCashWizardProps> = cell(
  ({ticker, amount, onPressBack}) => {
    return (
      <Wizard.Navigator
        initialName={AdvCashWizardScreenName.MAIN}
        initialProps={{
          ticker,
          amount,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={AdvCashWizardScreenName.MAIN}
          component={AdvCashCreatePayment}
          options={getAdvCashWizardOptions}
        />
        <Wizard.Screen
          name={AdvCashWizardScreenName.PAYMENT}
          component={AdvCashPayment}
          options={getAdvCashWizardOptions}
        />
      </Wizard.Navigator>
    );
  }
);
