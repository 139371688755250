import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';

export type AccountLevelProgressBarSparksProps = {
  value: number;
};

export const AccountLevelProgressBarSparks: React.FC<
  AccountLevelProgressBarSparksProps & BoxProps
> = ({value, ...boxProps}) => {
  return (
    <Box
      testID="ACCOUNT_LEVEL_FORECAST_SPARKS"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      px={16}
      py={3}
      bg="$attention-02"
      borderTopLeftRadius={20}
      borderTopRightRadius={20}
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <Text variant="$body-02-medium-accent" color="$attention-01">
          +
        </Text>
        <Icon name="spark" size={16} color="$attention-01" />
      </Box>
      <Text
        variant="$body-02"
        color="$attention-01"
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        <Trans
          i18nKey="surface.loyalty.progress_bar.sparks"
          components={{
            Strong: (
              <Text
                variant="$body-02-medium-accent"
                testID="ACCOUNT_LEVEL_FORECAST_SPARKS_COUNT"
              />
            ),
          }}
          values={{
            count: value,
          }}
        />
      </Text>
    </Box>
  );
};
