import {alias, createSimpleSchema, object, serializable} from 'serializr';
import {observable} from 'mobx';
import {number} from '@youtoken/ui.utils-serializr';

// TODO: Link to doc ??

class RatesSettingsTickerItem {
  @observable
  @serializable(alias('decimal', number()))
  precision!: number;
}

export const RatesSettingsItemSchema = createSimpleSchema({
  '*': object(RatesSettingsTickerItem),
});

export interface RatesSettingsResponse {
  [key: string]: {
    [key: string]: RatesSettingsTickerItem | undefined;
  };
}

export const RatesSettingsResponseSchema =
  createSimpleSchema<RatesSettingsResponse>({
    '*': object(RatesSettingsItemSchema),
  });
