import React from 'react';
import {useRef} from 'react';
import {Animated} from 'react-native';
import {
  Box,
  type BoxProps,
  TouchableBox,
  useTheme,
} from '@youtoken/ui.primitives';
import type {DirectionType} from '@youtoken/ui.hodls-utils';
import {Icon} from '@youtoken/ui.icons';
import {DirectionItem} from './DirectionItem';

export const useAnimatedValue = (initialValue: number) => {
  return useRef(new Animated.Value(initialValue)).current;
};

export interface DirectionButtonsProps extends BoxProps {
  currentValue: 'sell' | 'buy';
  onChange: (val: DirectionType) => void;
  disableSell?: boolean;
  disableBuy?: boolean;
}

export const DirectionButtons: React.FC<DirectionButtonsProps> = ({
  currentValue,
  onChange,
  disableSell = false,
  disableBuy = false,
  ...boxProps
}) => {
  const {colors} = useTheme();
  const animationState = useAnimatedValue(0);

  const activeElementBg =
    currentValue === 'sell'
      ? disableSell
        ? colors['$ui-01']
        : colors['$danger-01']
      : disableBuy
      ? colors['$ui-01']
      : colors['$success-01'];

  const rotateIcon = animationState.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-90deg'],
  });

  const leftValue = animationState.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 34],
  });

  const handleChange = React.useCallback(() => {
    return onChange(currentValue === 'sell' ? 'buy' : 'sell');
  }, [currentValue, onChange]);

  React.useEffect(() => {
    Animated.timing(animationState, {
      toValue: currentValue === 'sell' ? 0 : 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  }, [currentValue]);

  return (
    <TouchableBox
      onPress={handleChange}
      flexDirection="row"
      borderRadius={6}
      overflow="hidden"
      position="relative"
      disabled={disableSell || disableBuy}
      {...boxProps}
    >
      <DirectionItem
        value="sell"
        disabled={currentValue === 'sell' || disableSell}
        testID="DIRECTION_SELL_BUTTON"
      />
      <DirectionItem
        value="buy"
        disabled={currentValue === 'buy' || disableBuy}
        testID="DIRECTION_BUY_BUTTON"
      />
      <Animated.View
        style={[
          {
            zIndex: 10,
            position: 'absolute',
            top: 0,
            width: 34,
            height: 24,
            borderRadius: 6,
            left: 0,
          },
          {
            transform: [{translateX: leftValue}],
            backgroundColor: activeElementBg,
          },
        ]}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          justifyContent="center"
          alignItems="center"
        >
          <Animated.View
            style={[
              {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              {
                transform: [{rotate: rotateIcon}],
              },
            ]}
          >
            <Box
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              justifyContent="center"
              alignItems="center"
            >
              <Icon size={20} color="$ui-background" name="down" />
            </Box>
          </Animated.View>
        </Box>
      </Animated.View>
    </TouchableBox>
  );
};
