import {observable, action} from 'mobx';
import {Form} from './Form';
import {ApplePayDepositFormArgs, ApplePayDepositFormResources} from './types';
import {View} from './View';

export class ApplePayDepositForm {
  @observable
  public form: Form;

  @observable
  public view: View;

  @observable
  public sessionToken: string | null = null;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(
    args: ApplePayDepositFormArgs,
    resources: ApplePayDepositFormResources
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form, resources);
  }
}
