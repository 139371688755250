import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';

export class TriggerPriceLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (!this.chart.triggerPrice) {
      return undefined;
    }

    const text = `${this.chart.formatNumber(this.chart.triggerPrice)}`;

    return this.chart.layout.getLabelLayout(
      this.chart.triggerPrice,
      text,
      'right',
      this.chart.direction
    );
  }
}
