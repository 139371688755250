import * as React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {AppLedgerTurbochargeRoutes} from './AppLedgerTurbochargeRoutes';
import {AppWebTurbochargeRoutes} from './AppWebTurbochargeRoutes';

export const TurbochargeRoutes: React.FC<RouteComponentProps> = props => {
  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <AppLedgerTurbochargeRoutes {...props} />;
  }

  return <AppWebTurbochargeRoutes {...props} />;
};
