import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {type IButtonProps, type Size, type Color, type Type} from '../types';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

type IconProps = React.ComponentProps<typeof Icon>;

const getIconSizeVariant = (size: Size): IconProps => {
  return (
    {
      large: {
        size: 20,
      },
      medium: {
        size: 20,
      },
      small: {
        size: 16,
      },
      tiny: {
        size: 16,
      },
    } as {[key in Size]: IconProps}
  )[size];
};

const getIconColorVariant = (
  type: Type,
  color: Color,
  disabled: IButtonProps['disabled']
): IconProps => {
  if (disabled) {
    if (color === 'apple-pay') {
      if (type === 'inverted' || type === 'ghost') {
        return {
          color: '$text-02',
        } as IconProps;
      }
      return {
        color: '$text-04',
      } as IconProps;
    }
    return {
      color: '$text-03',
    } as IconProps;
  }

  return (
    {
      interactive: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$interactive-01',
        },
        ghost: {
          color: '$interactive-01',
        },
        inverted: {
          color: '$interactive-01',
        },
      },
      'external-auth': {
        primary: {
          color: '$text-01',
        },
        secondary: {
          color: '$interactive-01',
        },
        ghost: {
          color: '$interactive-01',
        },
        inverted: {
          color: '$interactive-01',
        },
      },
      danger: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$danger-01',
        },
        ghost: {
          color: '$danger-01',
        },
        inverted: {
          color: '$danger-01',
        },
      },
      success: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$success-01',
        },
        ghost: {
          color: '$success-01',
        },
        inverted: {
          color: '$success-01',
        },
      },
      attention: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$attention-01',
        },
        ghost: {
          color: '$attention-01',
        },
        inverted: {
          color: '$attention-01',
        },
      },
      'apple-pay': {
        primary: {
          color: '$text-06',
        },
        secondary: {
          color: '$text-06',
        },
        ghost: {
          color: '$fill-04',
        },
        inverted: {
          color: '$fill-04',
        },
      },
    } as {
      [key in Color]: {
        [key in Type]: IconProps;
      };
    }
  )[color][type];
};

const useIconStyleProps = ({
  size = 'medium',
  type = 'primary',
  color = 'interactive',
  disabled = false,
}: IButtonProps): IconProps => {
  return {
    ...getIconSizeVariant(size),
    ...getIconColorVariant(type, color, disabled),
  };
};

const getIconMargin = (size: Size) => {
  return (
    {
      large: 7,
      medium: 7,
      small: 6,
      tiny: 4,
    } as {[key in Size]: number}
  )[size];
};

export const IconComponent: React.FC<
  IButtonProps & {rightMargin?: boolean; icon: BaseIconName}
> = ({icon, rightMargin = false, ...props}) => {
  const iconProps = useIconStyleProps(props);
  const margin = rightMargin ? getIconMargin(props.size!) : 0;

  return (
    <Box mr={margin}>
      <Icon {...iconProps} name={icon} />
    </Box>
  );
};
