import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const ChevronDownIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 17.25l9.69 9.69a1.5 1.5 0 002.12 0l9.69-9.69a1.768 1.768 0 10-2.5-2.5L20 23l-8.25-8.25a1.768 1.768 0 10-2.5 2.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
