import * as React from 'react';
import {Platform} from 'react-native';
import {i18n} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {useWizard} from '@youtoken/ui.elements';
import {ApplePayDepositSurface} from '@youtoken/ui.surface-deposit-with-apple-pay';
import {HeaderBase} from '../../../components';
import {FiatDepositVisaBanner} from '../../../FiatDepositVisaBanner';
import {type FiatDepositWizardNavigator, FiatDepositWizardScreenName} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

const getFiatDepositWizardApplePayTitle = (ticker: string) => {
  return i18n.t('web_app.wallets.fiat_deposit.item_apple_pay_title.default', {
    ticker: ticker.toUpperCase(),
  });
};

export const getFiatDepositWizardApplePayOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {
      ticker: string;
    };
  };
}) => {
  return {
    title: getFiatDepositWizardApplePayTitle(ticker),
  };
};

export const FiatDepositWizardApplePay: React.FC = () => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    FiatDepositWizardNavigator,
    FiatDepositWizardScreenName.APPLE_PAY
  >();

  return (
    <>
      <Box flex={1} p={24}>
        {showHeader && (
          <HeaderBase onPressBack={goBack}>
            {getFiatDepositWizardApplePayTitle(ticker)}
          </HeaderBase>
        )}
        <ApplePayDepositSurface ticker={ticker} />
      </Box>
      <FiatDepositVisaBanner />
    </>
  );
};
