import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';

type CoinIconsProps = {
  coins: string[];
};

const OneCoin = ({coins}: CoinIconsProps) => {
  return (
    <>
      {coins.map((coinName: string, index: number) => {
        return (
          <Box
            key={`${coinName}-${index}`}
            backgroundColor="$interactive-01"
            borderRadius={20}
          >
            <LogoColored name={coinName as LogoColoredIconName} size={24} />
          </Box>
        );
      })}
    </>
  );
};

const TwoCoins = ({coins}: CoinIconsProps) => {
  return (
    <>
      {coins.map((coinName: string, index: number) => {
        return (
          <Box
            key={`${coinName}-${index}`}
            backgroundColor="$interactive-01"
            position="absolute"
            bottom={index * 18}
            right={index * 18}
            borderColor="$interactive-01"
            borderWidth={2}
            borderRadius={20}
          >
            <LogoColored name={coinName as LogoColoredIconName} size={24} />
          </Box>
        );
      })}
    </>
  );
};

const ThreeCoins = ({coins}: CoinIconsProps) => {
  return (
    <>
      {coins.map((coinName: string, index: number) => {
        return (
          <Box
            key={`${coinName}-${index}`}
            backgroundColor="$interactive-01"
            position="absolute"
            bottom={index * 10}
            right={index * 11}
            borderColor="$interactive-01"
            borderWidth={2}
            borderRadius={20}
          >
            <LogoColored name={coinName as LogoColoredIconName} size={24} />
          </Box>
        );
      })}
    </>
  );
};

const FourCoins = ({coins}: CoinIconsProps) => {
  return (
    <>
      {coins.map((coinName: string, index: number) => {
        return (
          <Box
            key={`${coinName}-${index}`}
            backgroundColor="$interactive-01"
            borderRadius={20}
          >
            <LogoColored name={coinName as LogoColoredIconName} size={20} />
          </Box>
        );
      })}
    </>
  );
};

const FiveCoinsOrMore = ({coins}: CoinIconsProps) => {
  return (
    <>
      <FourCoins coins={coins.slice(0, 4)} />

      <Box
        position="absolute"
        top={12}
        left={12}
        borderColor="$interactive-01"
        borderWidth={2}
        borderRadius={20}
      >
        <LogoColored name={coins[4] as LogoColoredIconName} size={20} />
      </Box>
    </>
  );
};

export const CoinIcons: React.FC<CoinIconsProps & BoxProps> = ({coins}) => {
  return (
    <Box
      mr={12}
      width={44}
      height={44}
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      flexWrap="wrap"
      position="relative"
      gap={4}
    >
      {coins.length === 1 && <OneCoin coins={coins} />}
      {coins.length === 2 && <TwoCoins coins={coins} />}
      {coins.length === 3 && <ThreeCoins coins={coins} />}
      {coins.length === 4 && <FourCoins coins={coins} />}
      {coins.length >= 5 && <FiveCoinsOrMore coins={coins} />}
    </Box>
  );
};
