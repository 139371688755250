import * as React from 'react';
import {Keyboard} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';

export const Footer: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    loadingOrSubmitting,
    form: {instance},
  } = useForm<LoanRepayOwnFundsFormState>();

  return (
    <Box
      p={{
        default: 16,
        phone: 24,
      }}
      {...props}
    >
      <Button
        size="large"
        loading={loadingOrSubmitting}
        onPress={(...args) => {
          Keyboard.dismiss();
          instance.onSubmit(...args);
        }}
      >
        {t('surface.loans.loan_pay_own_funds_form.submit')}
      </Button>
    </Box>
  );
});
