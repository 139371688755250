import React from 'react';
import {Banner} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export const FiatDepositVisaBanner: React.FC = () => {
  const {isOnlyVisaAvailable} = AuthMeResource.use({});
  const {t} = useTranslation();

  if (!isOnlyVisaAvailable) {
    return null;
  }

  return (
    <Banner variant="attention">
      {t('surface.wallets.fiat_deposit.alert_visa.title')}
    </Banner>
  );
};
