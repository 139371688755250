import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const RewardsHowItWorksGift: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box flexDirection="row" alignItems="flex-start">
      <Box p={10} backgroundColor="$success-02" borderRadius={20}>
        <Icon name="gift" size={20} color="$success-01" />
      </Box>
      <Box flex={1} ml={16}>
        <Text variant="$body-02" color="$success-01" mt={4}>
          {t('surface.referral_program.rewards.how_it_works.gift')}
        </Text>
      </Box>
    </Box>
  );
});
