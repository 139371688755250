import * as React from 'react';
import {type GestureResponderEvent} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';
import {
  TouchableBox,
  Box,
  Text,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

export interface MenuItemProps extends TouchableBoxProps {
  to?: string;
  icon?: BaseIconName;
  text: string;
  pathKey?: string;
}

export const MenuItem: React.FC<MenuItemProps> = cell(
  ({to, icon, children, text, pathKey, onPress, ...rest}) => {
    const {pathname, push} = RouterResource.use({});

    const isActive = React.useMemo(
      () =>
        (to && pathname.includes(to)) ||
        (typeof pathKey !== 'undefined' && pathname.includes(pathKey)),
      [pathname]
    ) as boolean;

    const handlePress = React.useCallback(
      (event: GestureResponderEvent) => {
        if (to && pathname !== to) {
          push(to);
        }
        onPress?.(event);
      },
      [pathname, to]
    );

    return (
      <TouchableBox
        activeOpacity={0.8}
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        minHeight={40}
        py={8}
        px={12}
        mb={5}
        minWidth={{default: 240, desktop: 200}}
        bg={isActive ? '$interactive-02' : '$ui-background'}
        onPress={handlePress}
        {...rest}
      >
        <Box
          position="absolute"
          width={2}
          borderRadius={1}
          backgroundColor="$interactive-01"
          opacity={isActive ? 1 : 0}
          height="100%"
          left={0}
          top={0}
        />

        <Box flexDirection="row" alignItems="flex-start" flex={1}>
          <Box mr={10} width={24} height={24}>
            {icon && <Icon color="$text-05" size={24} name={icon} />}
          </Box>
          <Box maxWidth="80%">
            <Box height={24} justifyContent="center">
              <Text color="$interactive-01" variant="$body-01-medium-accent">
                {text}
              </Text>
            </Box>

            {children && <Box flex={1}>{children}</Box>}
          </Box>
        </Box>
      </TouchableBox>
    );
  }
);
