import range from 'lodash/range';
import {type TradingHoursResponse} from '@youtoken/ui.resource-hodl-tariffs';
import {type TradingViewData} from './types';
import {invariant} from '@youtoken/ui.utils';

// Possible resolution formats of Trading View library (input):
// xS (seconds), x (minutes), xD (days), xW (weeks), xM (months).
// Hours are expressed in minutes
// Years are set using months
// Possible 'tick' for our BE: "1m", "5m", "15m", "30m", "1h", or "4h" (output)
// on current stage we only support intra-day resolution
export const getTickFromResolution = (resolution: string) => {
  const regex = /^([\d]+)([SDWM]+)?$/;
  const result = resolution.match(regex);

  if (!result) {
    return undefined;
  }

  const [_string, _numberValue, _symbol] = result as [string, string, string];

  // CASE: resolution contains only numbers
  if (!_symbol) {
    const quantity = parseInt(_numberValue);
    if (
      quantity === 1 ||
      quantity === 5 ||
      quantity === 15 ||
      quantity === 30
    ) {
      return `${quantity}m`;
    } else if (quantity === 60) {
      return '1h';
    } else if (quantity === 240) {
      return '4h';
    } else {
      return undefined;
    }
  }

  // CASE: resolution contains numbers and symbol
  // later BE can have more possible "tick" values
  return undefined;
};

export const getNextDailyBarTime = (barTime: number) => {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
};

export const convertBarFromServerToBarForLibrary = (
  serverBar: TradingViewData.BarFromServer
): TradingViewData.BarForLibrary => {
  return {
    time: Date.parse(serverBar.date),
    open: Number(serverBar.open),
    close: Number(serverBar.close),
    high: Number(serverBar.high),
    low: Number(serverBar.low),
  };
};

// parsing for the format: xxTicker_xxTicker.mode
export const parseSymbol = (symbolString: string) => {
  const [tickets, mode] = symbolString.split('.') as [string, string];
  const [fromTicker, toTicker] = tickets.split('_') as [string, string];

  invariant(
    fromTicker && toTicker && mode,
    'Invalid symbol format',
    {},
    {
      symbolString,
    }
  );

  return {
    fromTicker,
    toTicker,
    mode,
  };
};

// NOTE: time from BE comes in format hh:mm
const clearTimeString = (timeString: string) => timeString.replace(':', '');

export const generatePricescale = (precision: number = 2) =>
  Math.pow(10, precision);

export const getWorkingHoursFromDaysOff = (daysOff: TradingHoursResponse[]) => {
  if (daysOff.length === 0) {
    return '24x7';
  }

  const week: {[day: number]: {timeFrom: string; timeTo: string}} = {
    0: {timeFrom: '0000', timeTo: '2400'},
    1: {timeFrom: '0000', timeTo: '2400'},
    2: {timeFrom: '0000', timeTo: '2400'},
    3: {timeFrom: '0000', timeTo: '2400'},
    4: {timeFrom: '0000', timeTo: '2400'},
    5: {timeFrom: '0000', timeTo: '2400'},
    6: {timeFrom: '0000', timeTo: '2400'},
  };

  daysOff.map(({dayFrom, timeFrom, dayTo, timeTo}) => {
    const days =
      dayFrom <= dayTo
        ? range(dayFrom, dayTo + 1)
        : [...range(0, dayTo + 1), ...range(dayFrom, 6 + 1)];

    days.forEach(day => {
      if (day === dayFrom && typeof week[dayFrom] !== 'undefined') {
        week[dayFrom]!.timeTo = clearTimeString(timeFrom);
      }
      if (day === dayTo && typeof week[dayTo] !== 'undefined') {
        week[dayTo]!.timeFrom = clearTimeString(timeTo);
      }

      if (
        day !== dayFrom &&
        day !== dayTo &&
        typeof week[day] !== 'undefined'
      ) {
        week[day]!.timeFrom = '';
        week[day]!.timeTo = '';
      }
    });
  });

  const workingTimes: string[] = Object.entries(week)
    .filter(([, {timeFrom, timeTo}]) => timeFrom && timeTo)
    .map(
      ([day, {timeFrom, timeTo}]) => `${timeFrom}-${timeTo}:${Number(day) + 1}`
    );

  return workingTimes.join('|');
};
