import React from 'react';
import {Modal} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TwoFactorEnableGaSurface} from '@youtoken/ui.surface-more';

export const ModalTwoFactorAuthenticationGAEnable: typeof Modal = props => {
  const {t} = useTranslation();

  return (
    <Modal title={t('surface.more.screen_title.2fa_enable')} {...props}>
      <TwoFactorEnableGaSurface />
    </Modal>
  );
};
