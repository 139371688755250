// https://youhodler.atlassian.net/browse/EN-1270
// to be removed on or before 20 April 2024

export const amarketsPromoCountryCodes = [
  'AUS', // Australia
  'AUT', // Austria
  'AZE', // Azerbaijan
  'BEL', // Belgium
  'BRA', // Brazil
  'CZE', // Czech Republic
  'FIN', // Finland
  'FRA', // France
  'GEO', // Georgia
  'DEU', // Germany
  'IND', // India
  'IDN', // Indonesia
  'ISR', // Israel
  'KAZ', // Kazakhstan
  'KOR', // South Korea
  'MYS', // Malaysia
  'MDA', // Moldova
  'OMN', // Oman
  'PER', // Peru
  'POL', // Poland
  'SGP', // Singapore
  'TWN', // Taiwan
  'TJK', // Tajikistan
  'TUR', // Turkey
  'TKM', // Turkmenistan
  'UKR', // Ukraine
  'ARE', // United Arab Emirates
  'GBR', // United Kingdom
  'VNM', // Vietnam
];
