import React from 'react';
import {Modal} from '../_common';
import {DeviceModalCell} from '@youtoken/ui.surface-trusted-devices';

interface ModalDeviceDetailsProps extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalDeviceDetails: React.FC<ModalDeviceDetailsProps> = ({id}) => {
  return (
    <Modal maxWidth={444} alignItems="stretch" testID="TRUSTED_DEVICE_MODAL">
      <DeviceModalCell id={id} />
    </Modal>
  );
};
