export interface Country {
  name: string;
  code2: string;
  code3: string;
  currency: string;
  phone: string;
}

// save old format
export interface CountryShort {
  /** 3-symbols ISO country code
   *
   * `"RUS"`, `"GBR"` etc
   */
  code: string;
  /** 2-symbols ISO country code
   *
   * `"RU"`, `"GB"` etc
   */
  codeISO2: string;
  /** `"+.."` phone number code
   *
   * `"+7"`, `"+1"` etc
   */
  phoneCode: string;
  /** human-readable name of a country
   *
   * `"Russia"`, `"Afghanistan"` etc
   */
  name: string;
}

export const fullCountriesList: Country[] = [
  {
    name: 'Andorra',
    code2: 'AD',
    code3: 'AND',
    currency: 'EUR',
    phone: '+376',
  },
  {
    name: 'United Arab Emirates',
    code2: 'AE',
    code3: 'ARE',
    currency: 'AED',
    phone: '+971',
  },
  {
    name: 'Afghanistan',
    code2: 'AF',
    code3: 'AFG',
    currency: 'AFN',
    phone: '+93',
  },
  {
    name: 'Antigua and Barbuda',
    code2: 'AG',
    code3: 'ATG',
    currency: 'XCD',
    phone: '+1-268',
  },
  {
    name: 'Anguilla',
    code2: 'AI',
    code3: 'AIA',
    currency: 'XCD',
    phone: '+1-264',
  },
  {
    name: 'Albania',
    code2: 'AL',
    code3: 'ALB',
    currency: 'ALL',
    phone: '+355',
  },
  {
    name: 'Armenia',
    code2: 'AM',
    code3: 'ARM',
    currency: 'AMD',
    phone: '+374',
  },
  {
    name: 'Angola',
    code2: 'AO',
    code3: 'AGO',
    currency: 'AOA',
    phone: '+244',
  },
  {
    name: 'Antarctica',
    code2: 'AQ',
    code3: 'ATA',
    currency: '',
    phone: '+672',
  },
  {
    name: 'Argentina',
    code2: 'AR',
    code3: 'ARG',
    currency: 'ARS',
    phone: '+54',
  },
  {
    name: 'American Samoa',
    code2: 'AS',
    code3: 'ASM',
    currency: 'USD',
    phone: '+1-684',
  },
  {
    name: 'Austria',
    code2: 'AT',
    code3: 'AUT',
    currency: 'EUR',
    phone: '+43',
  },
  {
    name: 'Australia',
    code2: 'AU',
    code3: 'AUS',
    currency: 'AUD',
    phone: '+61',
  },
  {
    name: 'Aruba',
    code2: 'AW',
    code3: 'ABW',
    currency: 'AWG',
    phone: '+297',
  },
  {
    name: 'Ascension',
    code2: 'AC',
    code3: 'ASC',
    currency: 'SHP',
    phone: '+247',
  },
  {
    name: 'Aland Islands',
    code2: 'AX',
    code3: 'ALA',
    currency: 'EUR',
    phone: '+358-18',
  },
  {
    name: 'Azerbaijan',
    code2: 'AZ',
    code3: 'AZE',
    currency: 'AZN',
    phone: '+994',
  },
  {
    name: 'Bosnia and Herzegovina',
    code2: 'BA',
    code3: 'BIH',
    currency: 'BAM',
    phone: '+387',
  },
  {
    name: 'Barbados',
    code2: 'BB',
    code3: 'BRB',
    currency: 'BBD',
    phone: '+1-246',
  },
  {
    name: 'Bangladesh',
    code2: 'BD',
    code3: 'BGD',
    currency: 'BDT',
    phone: '+880',
  },
  {
    name: 'Belgium',
    code2: 'BE',
    code3: 'BEL',
    currency: 'EUR',
    phone: '+32',
  },
  {
    name: 'Burkina Faso',
    code2: 'BF',
    code3: 'BFA',
    currency: 'XOF',
    phone: '+226',
  },
  {
    name: 'Bulgaria',
    code2: 'BG',
    code3: 'BGR',
    currency: 'BGN',
    phone: '+359',
  },
  {
    name: 'Bahrain',
    code2: 'BH',
    code3: 'BHR',
    currency: 'BHD',
    phone: '+973',
  },
  {
    name: 'Burundi',
    code2: 'BI',
    code3: 'BDI',
    currency: 'BIF',
    phone: '+257',
  },
  {
    name: 'Benin',
    code2: 'BJ',
    code3: 'BEN',
    currency: 'XOF',
    phone: '+229',
  },
  {
    name: 'Saint Barthelemy',
    code2: 'BL',
    code3: 'BLM',
    currency: 'EUR',
    phone: '+590',
  },
  {
    name: 'Bermuda',
    code2: 'BM',
    code3: 'BMU',
    currency: 'BMD',
    phone: '+1-441',
  },
  {
    name: 'Brunei',
    code2: 'BN',
    code3: 'BRN',
    currency: 'BND',
    phone: '+673',
  },
  {
    name: 'Bolivia',
    code2: 'BO',
    code3: 'BOL',
    currency: 'BOB',
    phone: '+591',
  },
  {
    name: 'Bonaire, Saint Eustatius and Saba',
    code2: 'BQ',
    code3: 'BES',
    currency: 'USD',
    phone: '+599',
  },
  {
    name: 'Brazil',
    code2: 'BR',
    code3: 'BRA',
    currency: 'BRL',
    phone: '+55',
  },
  {
    name: 'Bahamas',
    code2: 'BS',
    code3: 'BHS',
    currency: 'BSD',
    phone: '+1-242',
  },
  {
    name: 'Bhutan',
    code2: 'BT',
    code3: 'BTN',
    currency: 'BTN',
    phone: '+975',
  },
  {
    name: 'Bouvet Island',
    code2: 'BV',
    code3: 'BVT',
    currency: 'NOK',
    phone: '+55',
  },
  {
    name: 'Botswana',
    code2: 'BW',
    code3: 'BWA',
    currency: 'BWP',
    phone: '+267',
  },
  {
    name: 'Belarus',
    code2: 'BY',
    code3: 'BLR',
    currency: 'BYR',
    phone: '+375',
  },
  {
    name: 'Belize',
    code2: 'BZ',
    code3: 'BLZ',
    currency: 'BZD',
    phone: '+501',
  },
  {
    name: 'Canada',
    code2: 'CA',
    code3: 'CAN',
    currency: 'CAD',
    phone: '+1',
  },
  {
    name: 'Cocos Islands',
    code2: 'CC',
    code3: 'CCK',
    currency: 'AUD',
    phone: '+61',
  },
  {
    name: 'Democratic Republic of the Congo',
    code2: 'CD',
    code3: 'COD',
    currency: 'CDF',
    phone: '+243',
  },
  {
    name: 'Central African Republic',
    code2: 'CF',
    code3: 'CAF',
    currency: 'XAF',
    phone: '+236',
  },
  {
    name: 'Republic of the Congo',
    code2: 'CG',
    code3: 'COG',
    currency: 'XAF',
    phone: '+242',
  },
  {
    name: 'Switzerland',
    code2: 'CH',
    code3: 'CHE',
    currency: 'CHF',
    phone: '+41',
  },
  {
    name: 'Ivory Coast',
    code2: 'CI',
    code3: 'CIV',
    currency: 'XOF',
    phone: '+225',
  },
  {
    name: 'Cook Islands',
    code2: 'CK',
    code3: 'COK',
    currency: 'NZD',
    phone: '+682',
  },
  {
    name: 'Chile',
    code2: 'CL',
    code3: 'CHL',
    currency: 'CLP',
    phone: '+56',
  },
  {
    name: 'Cameroon',
    code2: 'CM',
    code3: 'CMR',
    currency: 'XAF',
    phone: '+237',
  },
  {
    name: 'China',
    code2: 'CN',
    code3: 'CHN',
    currency: 'CNY',
    phone: '+86',
  },
  {
    name: 'Colombia',
    code2: 'CO',
    code3: 'COL',
    currency: 'COP',
    phone: '+57',
  },
  {
    name: 'Costa Rica',
    code2: 'CR',
    code3: 'CRI',
    currency: 'CRC',
    phone: '+506',
  },
  {
    name: 'Cuba',
    code2: 'CU',
    code3: 'CUB',
    currency: 'CUP',
    phone: '+53',
  },
  {
    name: 'Cape Verde',
    code2: 'CV',
    code3: 'CPV',
    currency: 'CVE',
    phone: '+238',
  },
  {
    name: 'Curacao',
    code2: 'CW',
    code3: 'CUW',
    currency: 'ANG',
    phone: '+599',
  },
  {
    name: 'Christmas Island',
    code2: 'CX',
    code3: 'CXR',
    currency: 'AUD',
    phone: '+61',
  },
  {
    name: 'Cyprus',
    code2: 'CY',
    code3: 'CYP',
    currency: 'EUR',
    phone: '+357',
  },
  {
    name: 'Czech Republic',
    code2: 'CZ',
    code3: 'CZE',
    currency: 'CZK',
    phone: '+420',
  },
  {
    name: 'Germany',
    code2: 'DE',
    code3: 'DEU',
    currency: 'EUR',
    phone: '+49',
  },
  {
    name: 'Djibouti',
    code2: 'DJ',
    code3: 'DJI',
    currency: 'DJF',
    phone: '+253',
  },
  {
    name: 'Denmark',
    code2: 'DK',
    code3: 'DNK',
    currency: 'DKK',
    phone: '+45',
  },
  {
    name: 'Dominica',
    code2: 'DM',
    code3: 'DMA',
    currency: 'XCD',
    phone: '+1-767',
  },
  {
    name: 'Dominican Republic',
    code2: 'DO',
    code3: 'DOM',
    currency: 'DOP',
    phone: '+1-809',
  },
  {
    name: 'Algeria',
    code2: 'DZ',
    code3: 'DZA',
    currency: 'DZD',
    phone: '+213',
  },
  {
    name: 'Ecuador',
    code2: 'EC',
    code3: 'ECU',
    currency: 'USD',
    phone: '+593',
  },
  {
    name: 'Estonia',
    code2: 'EE',
    code3: 'EST',
    currency: 'EUR',
    phone: '+372',
  },
  {
    name: 'Egypt',
    code2: 'EG',
    code3: 'EGY',
    currency: 'EGP',
    phone: '+20',
  },
  {
    name: 'Western Sahara',
    code2: 'EH',
    code3: 'ESH',
    currency: 'MAD',
    phone: '+212',
  },
  {
    name: 'Eritrea',
    code2: 'ER',
    code3: 'ERI',
    currency: 'ERN',
    phone: '+291',
  },
  {
    name: 'Spain',
    code2: 'ES',
    code3: 'ESP',
    currency: 'EUR',
    phone: '+34',
  },
  {
    name: 'Ethiopia',
    code2: 'ET',
    code3: 'ETH',
    currency: 'ETB',
    phone: '+251',
  },
  {
    name: 'Finland',
    code2: 'FI',
    code3: 'FIN',
    currency: 'EUR',
    phone: '+358',
  },
  {
    name: 'Fiji',
    code2: 'FJ',
    code3: 'FJI',
    currency: 'FJD',
    phone: '+679',
  },
  {
    name: 'Falkland Islands',
    code2: 'FK',
    code3: 'FLK',
    currency: 'FKP',
    phone: '+500',
  },
  {
    name: 'Micronesia',
    code2: 'FM',
    code3: 'FSM',
    currency: 'USD',
    phone: '+691',
  },
  {
    name: 'Faroe Islands',
    code2: 'FO',
    code3: 'FRO',
    currency: 'DKK',
    phone: '+298',
  },
  {
    name: 'France',
    code2: 'FR',
    code3: 'FRA',
    currency: 'EUR',
    phone: '+33',
  },
  {
    name: 'Gabon',
    code2: 'GA',
    code3: 'GAB',
    currency: 'XAF',
    phone: '+241',
  },
  {
    name: 'United Kingdom',
    code2: 'GB',
    code3: 'GBR',
    currency: 'GBP',
    phone: '+44',
  },
  {
    name: 'Grenada',
    code2: 'GD',
    code3: 'GRD',
    currency: 'XCD',
    phone: '+1-473',
  },
  {
    name: 'Georgia',
    code2: 'GE',
    code3: 'GEO',
    currency: 'GEL',
    phone: '+995',
  },
  {
    name: 'French Guiana',
    code2: 'GF',
    code3: 'GUF',
    currency: 'EUR',
    phone: '+594',
  },
  {
    name: 'Guernsey',
    code2: 'GG',
    code3: 'GGY',
    currency: 'GBP',
    phone: '+44',
  },
  {
    name: 'Ghana',
    code2: 'GH',
    code3: 'GHA',
    currency: 'GHS',
    phone: '+233',
  },
  {
    name: 'Gibraltar',
    code2: 'GI',
    code3: 'GIB',
    currency: 'GIP',
    phone: '+350',
  },
  {
    name: 'Greenland',
    code2: 'GL',
    code3: 'GRL',
    currency: 'DKK',
    phone: '+299',
  },
  {
    name: 'Gambia',
    code2: 'GM',
    code3: 'GMB',
    currency: 'GMD',
    phone: '+220',
  },
  {
    name: 'Guinea',
    code2: 'GN',
    code3: 'GIN',
    currency: 'GNF',
    phone: '+224',
  },
  {
    name: 'Guadeloupe',
    code2: 'GP',
    code3: 'GLP',
    currency: 'EUR',
    phone: '+590',
  },
  {
    name: 'Equatorial Guinea',
    code2: 'GQ',
    code3: 'GNQ',
    currency: 'XAF',
    phone: '+240',
  },
  {
    name: 'Greece',
    code2: 'GR',
    code3: 'GRC',
    currency: 'EUR',
    phone: '+30',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code2: 'GS',
    code3: 'SGS',
    currency: 'GBP',
    phone: '+500',
  },
  {
    name: 'Guatemala',
    code2: 'GT',
    code3: 'GTM',
    currency: 'GTQ',
    phone: '+502',
  },
  {
    name: 'Guam',
    code2: 'GU',
    code3: 'GUM',
    currency: 'USD',
    phone: '+1-671',
  },
  {
    name: 'Guinea-Bissau',
    code2: 'GW',
    code3: 'GNB',
    currency: 'XOF',
    phone: '+245',
  },
  {
    name: 'Guyana',
    code2: 'GY',
    code3: 'GUY',
    currency: 'GYD',
    phone: '+592',
  },
  {
    name: 'Hong Kong',
    code2: 'HK',
    code3: 'HKG',
    currency: 'HKD',
    phone: '+852',
  },
  {
    name: 'Heard Island and McDonald Islands',
    code2: 'HM',
    code3: 'HMD',
    currency: 'AUD',
    phone: '+672',
  },
  {
    name: 'Honduras',
    code2: 'HN',
    code3: 'HND',
    currency: 'HNL',
    phone: '+504',
  },
  {
    name: 'Croatia',
    code2: 'HR',
    code3: 'HRV',
    currency: 'HRK',
    phone: '+385',
  },
  {
    name: 'Haiti',
    code2: 'HT',
    code3: 'HTI',
    currency: 'HTG',
    phone: '+509',
  },
  {
    name: 'Hungary',
    code2: 'HU',
    code3: 'HUN',
    currency: 'HUF',
    phone: '+36',
  },
  {
    name: 'Indonesia',
    code2: 'ID',
    code3: 'IDN',
    currency: 'IDR',
    phone: '+62',
  },
  {
    name: 'Ireland',
    code2: 'IE',
    code3: 'IRL',
    currency: 'EUR',
    phone: '+353',
  },
  {
    name: 'Israel',
    code2: 'IL',
    code3: 'ISR',
    currency: 'ILS',
    phone: '+972',
  },
  {
    name: 'Isle of Man',
    code2: 'IM',
    code3: 'IMN',
    currency: 'GBP',
    phone: '+44',
  },
  {
    name: 'India',
    code2: 'IN',
    code3: 'IND',
    currency: 'INR',
    phone: '+91',
  },
  {
    name: 'British Indian Ocean Territory',
    code2: 'IO',
    code3: 'IOT',
    currency: 'USD',
    phone: '+246',
  },
  {
    name: 'Iraq',
    code2: 'IQ',
    code3: 'IRQ',
    currency: 'IQD',
    phone: '+964',
  },
  {
    name: 'Iran',
    code2: 'IR',
    code3: 'IRN',
    currency: 'IRR',
    phone: '+98',
  },
  {
    name: 'Iceland',
    code2: 'IS',
    code3: 'ISL',
    currency: 'ISK',
    phone: '+354',
  },
  {
    name: 'Italy',
    code2: 'IT',
    code3: 'ITA',
    currency: 'EUR',
    phone: '+39',
  },
  {
    name: 'Jersey',
    code2: 'JE',
    code3: 'JEY',
    currency: 'GBP',
    phone: '+44',
  },
  {
    name: 'Jamaica',
    code2: 'JM',
    code3: 'JAM',
    currency: 'JMD',
    phone: '+1-876',
  },
  {
    name: 'Jordan',
    code2: 'JO',
    code3: 'JOR',
    currency: 'JOD',
    phone: '+962',
  },
  {
    name: 'Japan',
    code2: 'JP',
    code3: 'JPN',
    currency: 'JPY',
    phone: '+81',
  },
  {
    name: 'Kenya',
    code2: 'KE',
    code3: 'KEN',
    currency: 'KES',
    phone: '+254',
  },
  {
    name: 'Kyrgyzstan',
    code2: 'KG',
    code3: 'KGZ',
    currency: 'KGS',
    phone: '+996',
  },
  {
    name: 'Cambodia',
    code2: 'KH',
    code3: 'KHM',
    currency: 'KHR',
    phone: '+855',
  },
  {
    name: 'Kiribati',
    code2: 'KI',
    code3: 'KIR',
    currency: 'AUD',
    phone: '+686',
  },
  {
    name: 'Comoros',
    code2: 'KM',
    code3: 'COM',
    currency: 'KMF',
    phone: '+269',
  },
  {
    name: 'Saint Kitts and Nevis',
    code2: 'KN',
    code3: 'KNA',
    currency: 'XCD',
    phone: '+1-869',
  },
  {
    name: 'North Korea',
    code2: 'KP',
    code3: 'PRK',
    currency: 'KPW',
    phone: '+850',
  },
  {
    name: 'South Korea',
    code2: 'KR',
    code3: 'KOR',
    currency: 'KRW',
    phone: '+82',
  },
  {
    name: 'Kuwait',
    code2: 'KW',
    code3: 'KWT',
    currency: 'KWD',
    phone: '+965',
  },
  {
    name: 'Cayman Islands',
    code2: 'KY',
    code3: 'CYM',
    currency: 'KYD',
    phone: '+1-345',
  },
  {
    name: 'Kazakhstan',
    code2: 'KZ',
    code3: 'KAZ',
    currency: 'KZT',
    phone: '+7',
  },
  {
    name: 'Laos',
    code2: 'LA',
    code3: 'LAO',
    currency: 'LAK',
    phone: '+856',
  },
  {
    name: 'Lebanon',
    code2: 'LB',
    code3: 'LBN',
    currency: 'LBP',
    phone: '+961',
  },
  {
    name: 'Saint Lucia',
    code2: 'LC',
    code3: 'LCA',
    currency: 'XCD',
    phone: '+1-758',
  },
  {
    name: 'Liechtenstein',
    code2: 'LI',
    code3: 'LIE',
    currency: 'CHF',
    phone: '+423',
  },
  {
    name: 'Sri Lanka',
    code2: 'LK',
    code3: 'LKA',
    currency: 'LKR',
    phone: '+94',
  },
  {
    name: 'Liberia',
    code2: 'LR',
    code3: 'LBR',
    currency: 'LRD',
    phone: '+231',
  },
  {
    name: 'Lesotho',
    code2: 'LS',
    code3: 'LSO',
    currency: 'LSL',
    phone: '+266',
  },
  {
    name: 'Lithuania',
    code2: 'LT',
    code3: 'LTU',
    currency: 'LTL',
    phone: '+370',
  },
  {
    name: 'Luxembourg',
    code2: 'LU',
    code3: 'LUX',
    currency: 'EUR',
    phone: '+352',
  },
  {
    name: 'Latvia',
    code2: 'LV',
    code3: 'LVA',
    currency: 'LVL',
    phone: '+371',
  },
  {
    name: 'Libya',
    code2: 'LY',
    code3: 'LBY',
    currency: 'LYD',
    phone: '+218',
  },
  {
    name: 'Morocco',
    code2: 'MA',
    code3: 'MAR',
    currency: 'MAD',
    phone: '+212',
  },
  {
    name: 'Monaco',
    code2: 'MC',
    code3: 'MCO',
    currency: 'EUR',
    phone: '+377',
  },
  {
    name: 'Moldova',
    code2: 'MD',
    code3: 'MDA',
    currency: 'MDL',
    phone: '+373',
  },
  {
    name: 'Montenegro',
    code2: 'ME',
    code3: 'MNE',
    currency: 'EUR',
    phone: '+382',
  },
  {
    name: 'Saint Martin',
    code2: 'MF',
    code3: 'MAF',
    currency: 'EUR',
    phone: '+590',
  },
  {
    name: 'Madagascar',
    code2: 'MG',
    code3: 'MDG',
    currency: 'MGA',
    phone: '+261',
  },
  {
    name: 'Marshall Islands',
    code2: 'MH',
    code3: 'MHL',
    currency: 'USD',
    phone: '+692',
  },
  {
    name: 'Macedonia',
    code2: 'MK',
    code3: 'MKD',
    currency: 'MKD',
    phone: '+389',
  },
  {
    name: 'Mali',
    code2: 'ML',
    code3: 'MLI',
    currency: 'XOF',
    phone: '+223',
  },
  {
    name: 'Myanmar',
    code2: 'MM',
    code3: 'MMR',
    currency: 'MMK',
    phone: '+95',
  },
  {
    name: 'Mongolia',
    code2: 'MN',
    code3: 'MNG',
    currency: 'MNT',
    phone: '+976',
  },
  {
    name: 'Macao',
    code2: 'MO',
    code3: 'MAC',
    currency: 'MOP',
    phone: '+853',
  },
  {
    name: 'Northern Mariana Islands',
    code2: 'MP',
    code3: 'MNP',
    currency: 'USD',
    phone: '+1-670',
  },
  {
    name: 'Martinique',
    code2: 'MQ',
    code3: 'MTQ',
    currency: 'EUR',
    phone: '+596',
  },
  {
    name: 'Mauritania',
    code2: 'MR',
    code3: 'MRT',
    currency: 'MRO',
    phone: '+222',
  },
  {
    name: 'Montserrat',
    code2: 'MS',
    code3: 'MSR',
    currency: 'XCD',
    phone: '+1-664',
  },
  {
    name: 'Malta',
    code2: 'MT',
    code3: 'MLT',
    currency: 'EUR',
    phone: '+356',
  },
  {
    name: 'Mauritius',
    code2: 'MU',
    code3: 'MUS',
    currency: 'MUR',
    phone: '+230',
  },
  {
    name: 'Maldives',
    code2: 'MV',
    code3: 'MDV',
    currency: 'MVR',
    phone: '+960',
  },
  {
    name: 'Malawi',
    code2: 'MW',
    code3: 'MWI',
    currency: 'MWK',
    phone: '+265',
  },
  {
    name: 'Mexico',
    code2: 'MX',
    code3: 'MEX',
    currency: 'MXN',
    phone: '+52',
  },
  {
    name: 'Malaysia',
    code2: 'MY',
    code3: 'MYS',
    currency: 'MYR',
    phone: '+60',
  },
  {
    name: 'Mozambique',
    code2: 'MZ',
    code3: 'MOZ',
    currency: 'MZN',
    phone: '+258',
  },
  {
    name: 'Namibia',
    code2: 'NA',
    code3: 'NAM',
    currency: 'NAD',
    phone: '+264',
  },
  {
    name: 'New Caledonia',
    code2: 'NC',
    code3: 'NCL',
    currency: 'XPF',
    phone: '+687',
  },
  {
    name: 'Niger',
    code2: 'NE',
    code3: 'NER',
    currency: 'XOF',
    phone: '+227',
  },
  {
    name: 'Norfolk Island',
    code2: 'NF',
    code3: 'NFK',
    currency: 'AUD',
    phone: '+672',
  },
  {
    name: 'Nigeria',
    code2: 'NG',
    code3: 'NGA',
    currency: 'NGN',
    phone: '+234',
  },
  {
    name: 'Nicaragua',
    code2: 'NI',
    code3: 'NIC',
    currency: 'NIO',
    phone: '+505',
  },
  {
    name: 'Netherlands',
    code2: 'NL',
    code3: 'NLD',
    currency: 'EUR',
    phone: '+31',
  },
  {
    name: 'Norway',
    code2: 'NO',
    code3: 'NOR',
    currency: 'NOK',
    phone: '+47',
  },
  {
    name: 'Nepal',
    code2: 'NP',
    code3: 'NPL',
    currency: 'NPR',
    phone: '+977',
  },
  {
    name: 'Nauru',
    code2: 'NR',
    code3: 'NRU',
    currency: 'AUD',
    phone: '+674',
  },
  {
    name: 'Niue',
    code2: 'NU',
    code3: 'NIU',
    currency: 'NZD',
    phone: '+683',
  },
  {
    name: 'New Zealand',
    code2: 'NZ',
    code3: 'NZL',
    currency: 'NZD',
    phone: '+64',
  },
  {
    name: 'Oman',
    code2: 'OM',
    code3: 'OMN',
    currency: 'OMR',
    phone: '+968',
  },
  {
    name: 'Panama',
    code2: 'PA',
    code3: 'PAN',
    currency: 'PAB',
    phone: '+507',
  },
  {
    name: 'Peru',
    code2: 'PE',
    code3: 'PER',
    currency: 'PEN',
    phone: '+51',
  },
  {
    name: 'French Polynesia',
    code2: 'PF',
    code3: 'PYF',
    currency: 'XPF',
    phone: '+689',
  },
  {
    name: 'Papua New Guinea',
    code2: 'PG',
    code3: 'PNG',
    currency: 'PGK',
    phone: '+675',
  },
  {
    name: 'Philippines',
    code2: 'PH',
    code3: 'PHL',
    currency: 'PHP',
    phone: '+63',
  },
  {
    name: 'Pakistan',
    code2: 'PK',
    code3: 'PAK',
    currency: 'PKR',
    phone: '+92',
  },
  {
    name: 'Poland',
    code2: 'PL',
    code3: 'POL',
    currency: 'PLN',
    phone: '+48',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code2: 'PM',
    code3: 'SPM',
    currency: 'EUR',
    phone: '+508',
  },
  {
    name: 'Pitcairn',
    code2: 'PN',
    code3: 'PCN',
    currency: 'NZD',
    phone: '+870',
  },
  {
    name: 'Puerto Rico',
    code2: 'PR',
    code3: 'PRI',
    currency: 'USD',
    phone: '+1-939',
  },
  {
    name: 'Palestinian Territory',
    code2: 'PS',
    code3: 'PSE',
    currency: 'ILS',
    phone: '+970',
  },
  {
    name: 'Portugal',
    code2: 'PT',
    code3: 'PRT',
    currency: 'EUR',
    phone: '+351',
  },
  {
    name: 'Palau',
    code2: 'PW',
    code3: 'PLW',
    currency: 'USD',
    phone: '+680',
  },
  {
    name: 'Paraguay',
    code2: 'PY',
    code3: 'PRY',
    currency: 'PYG',
    phone: '+595',
  },
  {
    name: 'Qatar',
    code2: 'QA',
    code3: 'QAT',
    currency: 'QAR',
    phone: '+974',
  },
  {
    name: 'Reunion',
    code2: 'RE',
    code3: 'REU',
    currency: 'EUR',
    phone: '+262',
  },
  {
    name: 'Romania',
    code2: 'RO',
    code3: 'ROU',
    currency: 'RON',
    phone: '+40',
  },
  {
    name: 'Serbia',
    code2: 'RS',
    code3: 'SRB',
    currency: 'RSD',
    phone: '+381',
  },
  {
    name: 'Russia',
    code2: 'RU',
    code3: 'RUS',
    currency: 'RUB',
    phone: '+7',
  },
  {
    name: 'Rwanda',
    code2: 'RW',
    code3: 'RWA',
    currency: 'RWF',
    phone: '+250',
  },
  {
    name: 'Saudi Arabia',
    code2: 'SA',
    code3: 'SAU',
    currency: 'SAR',
    phone: '+966',
  },
  {
    name: 'Solomon Islands',
    code2: 'SB',
    code3: 'SLB',
    currency: 'SBD',
    phone: '+677',
  },
  {
    name: 'Seychelles',
    code2: 'SC',
    code3: 'SYC',
    currency: 'SCR',
    phone: '+248',
  },
  {
    name: 'Sudan',
    code2: 'SD',
    code3: 'SDN',
    currency: 'SDG',
    phone: '+249',
  },
  {
    name: 'South Sudan',
    code2: 'SS',
    code3: 'SSD',
    currency: 'SSP',
    phone: '+211',
  },
  {
    name: 'Sweden',
    code2: 'SE',
    code3: 'SWE',
    currency: 'SEK',
    phone: '+46',
  },
  {
    name: 'Singapore',
    code2: 'SG',
    code3: 'SGP',
    currency: 'SGD',
    phone: '+65',
  },
  {
    name: 'Saint Helena',
    code2: 'SH',
    code3: 'SHN',
    currency: 'SHP',
    phone: '+290',
  },
  {
    name: 'Slovenia',
    code2: 'SI',
    code3: 'SVN',
    currency: 'EUR',
    phone: '+386',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code2: 'SJ',
    code3: 'SJM',
    currency: 'NOK',
    phone: '+47',
  },
  {
    name: 'Slovakia',
    code2: 'SK',
    code3: 'SVK',
    currency: 'EUR',
    phone: '+421',
  },
  {
    name: 'Sierra Leone',
    code2: 'SL',
    code3: 'SLE',
    currency: 'SLL',
    phone: '+232',
  },
  {
    name: 'San Marino',
    code2: 'SM',
    code3: 'SMR',
    currency: 'EUR',
    phone: '+378',
  },
  {
    name: 'Senegal',
    code2: 'SN',
    code3: 'SEN',
    currency: 'XOF',
    phone: '+221',
  },
  {
    name: 'Somalia',
    code2: 'SO',
    code3: 'SOM',
    currency: 'SOS',
    phone: '+252',
  },
  {
    name: 'Suriname',
    code2: 'SR',
    code3: 'SUR',
    currency: 'SRD',
    phone: '+597',
  },
  {
    name: 'Sao Tome and Principe',
    code2: 'ST',
    code3: 'STP',
    currency: 'STD',
    phone: '+239',
  },
  {
    name: 'El Salvador',
    code2: 'SV',
    code3: 'SLV',
    currency: 'USD',
    phone: '+503',
  },
  {
    name: 'Sint Maarten',
    code2: 'SX',
    code3: 'SXM',
    currency: 'ANG',
    phone: '+599',
  },
  {
    name: 'Syria',
    code2: 'SY',
    code3: 'SYR',
    currency: 'SYP',
    phone: '+963',
  },
  {
    name: 'Swaziland',
    code2: 'SZ',
    code3: 'SWZ',
    currency: 'SZL',
    phone: '+268',
  },
  {
    name: 'Turks and Caicos Islands',
    code2: 'TC',
    code3: 'TCA',
    currency: 'USD',
    phone: '+1-649',
  },
  {
    name: 'Chad',
    code2: 'TD',
    code3: 'TCD',
    currency: 'XAF',
    phone: '+235',
  },
  {
    name: 'French Southern Territories',
    code2: 'TF',
    code3: 'ATF',
    currency: 'EUR',
    phone: '+262',
  },
  {
    name: 'Togo',
    code2: 'TG',
    code3: 'TGO',
    currency: 'XOF',
    phone: '+228',
  },
  {
    name: 'Thailand',
    code2: 'TH',
    code3: 'THA',
    currency: 'THB',
    phone: '+66',
  },
  {
    name: 'Tajikistan',
    code2: 'TJ',
    code3: 'TJK',
    currency: 'TJS',
    phone: '+992',
  },
  {
    name: 'Tokelau',
    code2: 'TK',
    code3: 'TKL',
    currency: 'NZD',
    phone: '+690',
  },
  {
    name: 'East Timor',
    code2: 'TL',
    code3: 'TLS',
    currency: 'USD',
    phone: '+670',
  },
  {
    name: 'Turkmenistan',
    code2: 'TM',
    code3: 'TKM',
    currency: 'TMT',
    phone: '+993',
  },
  {
    name: 'Tunisia',
    code2: 'TN',
    code3: 'TUN',
    currency: 'TND',
    phone: '+216',
  },
  {
    name: 'Tonga',
    code2: 'TO',
    code3: 'TON',
    currency: 'TOP',
    phone: '+676',
  },
  {
    name: 'Turkey',
    code2: 'TR',
    code3: 'TUR',
    currency: 'TRY',
    phone: '+90',
  },
  {
    name: 'Trinidad and Tobago',
    code2: 'TT',
    code3: 'TTO',
    currency: 'TTD',
    phone: '+1-868',
  },
  {
    name: 'Tristan da Cunha',
    code2: 'TA',
    code3: 'TAA',
    currency: 'SHP',
    phone: '+290',
  },
  {
    name: 'Tuvalu',
    code2: 'TV',
    code3: 'TUV',
    currency: 'AUD',
    phone: '+688',
  },
  {
    name: 'Taiwan',
    code2: 'TW',
    code3: 'TWN',
    currency: 'TWD',
    phone: '+886',
  },
  {
    name: 'Tanzania',
    code2: 'TZ',
    code3: 'TZA',
    currency: 'TZS',
    phone: '+255',
  },
  {
    name: 'Ukraine',
    code2: 'UA',
    code3: 'UKR',
    currency: 'UAH',
    phone: '+380',
  },
  {
    name: 'Uganda',
    code2: 'UG',
    code3: 'UGA',
    currency: 'UGX',
    phone: '+256',
  },
  {
    name: 'United States Minor Outlying Islands',
    code2: 'UM',
    code3: 'UMI',
    currency: 'USD',
    phone: '+1581',
  },
  {
    name: 'United States',
    code2: 'US',
    code3: 'USA',
    currency: 'USD',
    phone: '+1',
  },
  {
    name: 'Uruguay',
    code2: 'UY',
    code3: 'URY',
    currency: 'UYU',
    phone: '+598',
  },
  {
    name: 'Uzbekistan',
    code2: 'UZ',
    code3: 'UZB',
    currency: 'UZS',
    phone: '+998',
  },
  {
    name: 'Vatican',
    code2: 'VA',
    code3: 'VAT',
    currency: 'EUR',
    phone: '+379',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code2: 'VC',
    code3: 'VCT',
    currency: 'XCD',
    phone: '+1-784',
  },
  {
    name: 'Venezuela',
    code2: 'VE',
    code3: 'VEN',
    currency: 'VEF',
    phone: '+58',
  },
  {
    name: 'British Virgin Islands',
    code2: 'VG',
    code3: 'VGB',
    currency: 'USD',
    phone: '+1-284',
  },
  {
    name: 'U.S. Virgin Islands',
    code2: 'VI',
    code3: 'VIR',
    currency: 'USD',
    phone: '+1-340',
  },
  {
    name: 'Vietnam',
    code2: 'VN',
    code3: 'VNM',
    currency: 'VND',
    phone: '+84',
  },
  {
    name: 'Vanuatu',
    code2: 'VU',
    code3: 'VUT',
    currency: 'VUV',
    phone: '+678',
  },
  {
    name: 'Wallis and Futuna',
    code2: 'WF',
    code3: 'WLF',
    currency: 'XPF',
    phone: '+681',
  },
  {
    name: 'Samoa',
    code2: 'WS',
    code3: 'WSM',
    currency: 'WST',
    phone: '+685',
  },
  {
    name: 'Yemen',
    code2: 'YE',
    code3: 'YEM',
    currency: 'YER',
    phone: '+967',
  },
  {
    name: 'Mayotte',
    code2: 'YT',
    code3: 'MYT',
    currency: 'EUR',
    phone: '+262',
  },
  {
    name: 'South Africa',
    code2: 'ZA',
    code3: 'ZAF',
    currency: 'ZAR',
    phone: '+27',
  },
  {
    name: 'Zambia',
    code2: 'ZM',
    code3: 'ZMB',
    currency: 'ZMK',
    phone: '+260',
  },
  {
    name: 'Zimbabwe',
    code2: 'ZW',
    code3: 'ZWE',
    currency: 'ZWL',
    phone: '+263',
  },

  {
    name: 'Netherlands Antilles',
    code2: 'AN',
    code3: 'ANT',
    currency: 'ANG',
    phone: '+599',
  },
];
