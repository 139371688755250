import * as React from 'react';
import {observer} from 'mobx-react';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {Button} from '@youtoken/ui.buttons';

export const RewardsOverviewStatusActionClaim: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    data: {completedLevelAmount},
    claim,
  } = RewardsResource.use({});

  const [loading, setLoading] = React.useState(false);

  const handlePress = React.useCallback(() => {
    setLoading(true);

    claim().finally(() => {
      setLoading(false);
    });
  }, [claim]);

  return (
    <Button
      size="medium"
      color="success"
      loading={loading}
      mt={24}
      onPress={handlePress}
    >
      {t('surface.referral_program.rewards.claim_action', {
        amount: priceFormatterInThousands(completedLevelAmount),
      })}
    </Button>
  );
});
