import {computed} from 'mobx';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {LoyaltyResponse} from './LoyaltyResponse';
import {
  getLevelColor,
  getLevelIconName,
  getLevelName,
} from '@youtoken/ui.loyalty-miner-utils';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {LoyaltyProgressBarPoint} from './types';

const mock = {
  levels: [
    {
      level: 1,
      name: 'Jumpstart',
      requiredVolume: 0,
    },
    {
      level: 2,
      name: 'Silver',
      requiredVolume: 50000,
    },
    {
      level: 3,
      name: 'Gold',
      requiredVolume: 150000,
    },
    {
      level: 4,
      name: 'Platinum',
      requiredVolume: 500000,
    },
    {
      level: 5,
      name: 'Diamond',
      requiredVolume: 2000000,
    },
    {
      level: 6,
      name: 'VIP',
      requiredVolume: 5000000,
    },
  ],
  currentLevel: 1,
  currentVolume: '0',
  requiredVolumeForNextLevel: '5000000',
};

export class LoyaltyUnauthorizedResource extends createStaticResource({
  getKey: () => `loyaltyUnauthorized`,
  getData: () => {
    return Promise.resolve(deserialize(LoyaltyResponse, mock));
  },
}) {
  //#region progressBar

  @computed get progressBarPoints() {
    return this.data.levels.map(({level, requiredVolume}) => ({
      level: level,
      requiredVolume: requiredVolume,
      name: getLevelName(level),
      iconColorBorder: getLevelColor(level),
      iconColor: getLevelColor(level),
      iconName: getLevelIconName(level),
    })) as LoyaltyProgressBarPoint[];
  }

  @computed get progressBarValue() {
    return 0;
  }

  @computed get progressBarValueText() {
    return `$${priceFormatterInThousands(this.data.currentVolume)}`;
  }

  //#endregion progressBar
}
