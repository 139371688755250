import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {Select} from '@youtoken/ui.select';
import {type CryptoWithdrawalFormState} from '../types';

export const CryptoWithdrawalVersions: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      walletVersions,
      versionLabel,
      version,
      versionOnChange,
      versionHasError,
      versionError,
    },
  } = useForm<CryptoWithdrawalFormState>();

  return (
    <Box px={24} zIndex={1}>
      <Label textAlign="center" mb={16}>
        {versionLabel}
      </Label>
      <Box alignItems="center" width="100%">
        <Select
          items={walletVersions}
          placeholder={t('surface.wallets.crypto_deposit.protocol_placeholder')}
          onSelect={versionOnChange}
          selected={version}
          width="100%"
          testID="CRYPTO-WITHDRAWAL-VERSION-SELECTOR"
          size="large"
        />
      </Box>
      <FieldErrorMessage visible={versionHasError}>
        {versionError}
      </FieldErrorMessage>
    </Box>
  );
});
