import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const VisaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M18.186 13.06l-.184-.921h-2.058l-.327.914-1.65.003a5623.15 5623.15 0 012.361-5.662c.134-.32.372-.483.724-.481.268.002.706.002 1.314 0l1.275 6.144-1.455.002zm-1.779-2.183h1.326l-.495-2.308-.83 2.308zM5.777 6.912l1.659.002-2.563 6.146-1.679-.001a778.131 778.131 0 01-1.25-4.875c-.082-.324-.244-.55-.556-.658C.928 7.372.465 7.224 0 7.082v-.167h2.649c.458 0 .726.221.812.676.087.456.304 1.617.655 3.482l1.662-4.16zm3.937.002l-1.311 6.145-1.578-.002 1.31-6.145 1.579.002zm3.2-.114c.472 0 1.067.147 1.409.282l-.277 1.275c-.309-.125-.818-.292-1.246-.287-.622.01-1.007.272-1.007.522 0 .408.668.613 1.356 1.059.785.507.888.963.878 1.458-.01 1.028-.878 2.042-2.708 2.042-.835-.012-1.136-.083-1.817-.324l.288-1.33c.693.29.987.383 1.58.383.542 0 1.008-.22 1.012-.602.003-.271-.164-.406-.773-.742-.608-.336-1.463-.802-1.451-1.737.014-1.196 1.147-1.999 2.757-1.999h-.001z"
          fill={color}
        />
      </Svg>
    );
  }
);
