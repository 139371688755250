import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const HexagonIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M4.407 4.507l4-2.25a3.25 3.25 0 013.186 0l4 2.25a3.25 3.25 0 011.657 2.832v5.322a3.25 3.25 0 01-1.657 2.832l-4 2.25a3.25 3.25 0 01-3.186 0l-4-2.25a3.25 3.25 0 01-1.657-2.832V7.339a3.25 3.25 0 011.657-2.832z"
          stroke={color}
          strokeWidth={1.5}
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
);
