import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {i18n} from '@youtoken/ui.service-i18n';

export const EmptyState: React.FC<{message?: string}> = ({
  message = i18n.t('inputs.combobox.nothing_found'),
}) => {
  return (
    <Box justifyContent="center" alignItems="center" py={24}>
      <Text>{message}</Text>
    </Box>
  );
};
