import {BadgeSize, BadgeType, BadgeVariant} from './types';
import {BoxProps, TextProps, Theme} from '@youtoken/ui.primitives';

export const useBadgeProps = (
  size: BadgeSize,
  type: BadgeType,
  variant: BadgeVariant
) => {
  return {
    ...getBadgeSizes(size),
    ...getBadgeBackground(variant, type),
  };
};

export const useTextProps = (
  size: BadgeSize,
  type: BadgeType,
  variant: BadgeVariant
) => {
  return {
    ...getTextVariant(size),
    ...getInnerColor(variant, type),
  };
};

export const useIconProps = (
  size: BadgeSize,
  type: BadgeType,
  variant: BadgeVariant
) => {
  return {
    size: geticonSize(size),
    color: getInnerColor(variant, type).color as keyof Theme['colors'],
  };
};

export const useIconWrapperProps = (size: BadgeSize) => {
  return {
    ...getWrapperMargin(size),
  };
};

function getBadgeSizes(size: BadgeSize) {
  const props: {[key in BadgeSize]: BoxProps} = {
    tiny: {height: 16, px: 4, py: 1},
    small: {height: 24, px: 8, py: 4},
    medium: {height: 32, px: 16, py: 8},
    large: {height: 40, px: 24, py: 11},
  };

  return props[size];
}

function getBadgeBackground(variant: BadgeVariant, type: BadgeType) {
  const background: {
    [key in BadgeVariant]: {[key in BadgeType]: BoxProps};
  } = {
    interactive: {
      primary: {
        bg: '$interactive-01',
      },
      secondary: {
        bg: '$interactive-02',
      },
    },
    success: {
      primary: {
        bg: '$success-01',
      },
      secondary: {
        bg: '$success-02',
      },
    },
    attention: {
      primary: {
        bg: '$attention-01',
      },
      secondary: {
        bg: '$attention-02',
      },
    },
    danger: {
      primary: {
        bg: '$danger-01',
      },
      secondary: {
        bg: '$danger-02',
      },
    },
    locked: {
      primary: {
        bg: '$ui-04',
      },
      secondary: {
        bg: '$ui-01',
      },
    },
    neutral: {
      primary: {
        bg: '$ui-background',
      },
      secondary: {
        bg: '$ui-01',
      },
    },
  };
  return background[variant][type];
}

function getTextVariant(size: BadgeSize) {
  const variants: {[key in BadgeSize]: TextProps} = {
    tiny: {variant: '$body-03-medium-accent'},
    small: {variant: '$body-02-medium-accent'},
    medium: {variant: '$body-02-medium-accent'},
    large: {variant: '$body-01-medium-accent'},
  };

  return variants[size];
}

function getInnerColor(variant: BadgeVariant, type: BadgeType) {
  const colors: {
    [key in BadgeVariant]: {[key in BadgeType]: TextProps};
  } = {
    interactive: {
      primary: {
        color: '$text-04',
      },
      secondary: {
        color: '$interactive-01',
      },
    },
    success: {
      primary: {
        color: '$text-04',
      },
      secondary: {
        color: '$success-01',
      },
    },
    attention: {
      primary: {
        color: '$text-04',
      },
      secondary: {
        color: '$attention-01',
      },
    },
    danger: {
      primary: {
        color: '$text-04',
      },
      secondary: {
        color: '$danger-01',
      },
    },
    locked: {
      primary: {
        color: '$text-04',
      },
      secondary: {
        color: '$text-03',
      },
    },
    neutral: {
      primary: {
        color: '$text-02',
      },
      secondary: {
        color: '$text-02',
      },
    },
  };
  return colors[variant][type];
}

function geticonSize(size: BadgeSize) {
  const sizes: {[key in BadgeSize]: number} = {
    tiny: 12,
    small: 16,
    medium: 16,
    large: 20,
  };

  return sizes[size];
}

function getWrapperMargin(size: BadgeSize) {
  const margins: {[key in BadgeSize]: BoxProps} = {
    tiny: {mr: 2},
    small: {mr: 2},
    medium: {mr: 4},
    large: {mr: 4},
  };

  return margins[size];
}
