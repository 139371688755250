import {computed} from 'mobx';
import {primitive, serializable} from 'serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {
  mainCurrencyVariants,
  MainCurrencyVariants,
} from '@youtoken/ui.utils-serializr';

export class RewardWidgetResponse {
  @serializable(mainCurrencyVariants())
  maxEarnings!: MainCurrencyVariants;

  @serializable(mainCurrencyVariants())
  totalEarned!: MainCurrencyVariants;

  @serializable(primitive())
  newsCount: number = 0;

  @computed get userEarnedSomething() {
    return this.totalEarned.usd.gt(0);
  }

  @computed get widgetAmountValue() {
    if (this.userEarnedSomething) {
      return this.totalEarned.usd;
    }

    return this.maxEarnings.usd;
  }

  @computed get widgetAmountValueFormatted() {
    return '$' + formatByTicker(this.widgetAmountValue, 'usd');
  }
}
