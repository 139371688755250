import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {DownIcon as down} from './Down';
import {GainIcon as gain} from './Gain';
import {LoseIcon as lose} from './Lose';
import {NotificationIcon as notification} from './Notification';
import {RemoveIcon as remove} from './Remove';
import {UpIcon as up} from './Up';

export type IconName =
  | 'down'
  | 'gain'
  | 'lose'
  | 'notification'
  | 'remove'
  | 'up';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  down,
  gain,
  lose,
  notification,
  remove,
  up,
};
