import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {AmountSet} from './AmountSet';
import {Rules} from './Rules';
import {IncentivesBonuses} from './IncentivesBonuses';
import {RepayInfo} from './RepayInfo';

export const Content: React.FC<BoxProps> = observer(boxProps => {
  return (
    <Box flex={1} p={{default: 16, phone: 24}} {...boxProps}>
      <AmountSet />
      <Box flex={1} mt={8}>
        <Rules />
        <IncentivesBonuses flex={1} mt={16} />
        <RepayInfo mt={{default: 16, phone: 24}} />
      </Box>
    </Box>
  );
});
