import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm, CheckboxField} from '@youtoken/ui.form-elements';
import {AgreementsList} from '@youtoken/ui.elements';
import {FeatureDisclosureForm} from '../state';

interface AgreeToTermsFieldProps {
  beforeListText?: string;
  testID?: string;
}

export const AgreeToTermsField: React.FC<AgreeToTermsFieldProps & BoxProps> =
  observer(({beforeListText, testID, ...boxProps}) => {
    const {
      form: {productAgreementsList},
    } = useForm<FeatureDisclosureForm>();

    return (
      <CheckboxField name="agree" testID={testID} {...boxProps}>
        <AgreementsList
          beforeListText={beforeListText}
          items={productAgreementsList}
        />
      </CheckboxField>
    );
  });
