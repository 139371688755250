import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLSmallPreviewChartSmartCell} from '@youtoken/ui.legacy-charts';
import {Icon, LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {CardItemRate} from './CardItemRate';
import {CardItemCap} from './CardItemCap';
import {CardItemDiff} from './CardItemDiff';
import {CardItemRollover} from './CardItemRollover';
import {type TradingInstrumentItemProps} from '../../types';

export const TradingInstrumentItem: React.FC<TradingInstrumentItemProps> =
  observer(
    ({
      item,
      isParentListExpanded,
      subtitleVariant,
      showCharts = true,
      allowRolloversIcon = false,
      onPressItem,
      testIdBase,
    }) => {
      const handlePress = React.useCallback(() => {
        onPressItem(item.id, isParentListExpanded);
      }, [item.id, isParentListExpanded]);

      return (
        <TouchableBox
          key={item.id}
          borderRadius={12}
          borderWidth={1}
          width={152}
          height={showCharts ? 152 : 64}
          borderColor="$ui-02"
          backgroundColor="$ui-background"
          justifyContent="space-between"
          overflow="hidden"
          onPress={handlePress}
          testID={`${testIdBase}_CARD_${
            item.baseTickerFormatted + item.quoteTickerFormatted
          }`}
        >
          {allowRolloversIcon && item.isCategoryRollovers && (
            <Box position="absolute" top={6} right={6}>
              <Icon name="discount" color="$success-01" size={14} />
            </Box>
          )}

          <Box flexDirection="row" alignItems="center" pt={16} px={16}>
            <LogoColored
              name={item.baseTicker as LogoColoredIconName}
              size={32}
            />
            <Box ml={8}>
              <Box
                flexDirection="row"
                alignItems="center"
                testID={`${testIdBase}_INSTRUMENT_TICKERS`}
              >
                <Text variant="$body-02-medium-accent" color="$text-01">
                  {item.baseTickerFormatted}
                </Text>
                <Text variant="$body-02" color="$text-02" ml={2}>
                  /
                </Text>
                <Text variant="$body-02" color="$text-02" ml={2}>
                  {item.quoteTickerFormatted}
                </Text>
              </Box>
              {subtitleVariant === 'rate' && (
                <CardItemRate
                  baseTicker={item.baseTicker}
                  quoteTicker={item.quoteTicker}
                  testID={`${testIdBase}_INSTRUMENT_RATE`}
                />
              )}
              {subtitleVariant === 'rollover' && (
                <CardItemRollover
                  baseTicker={item.baseTicker}
                  quoteTicker={item.quoteTicker}
                  testID={`${testIdBase}_INSTRUMENT_BEST_ROLLOVER`}
                />
              )}
              {subtitleVariant === 'capitalization' && (
                <CardItemCap
                  id={item.id}
                  testID={`${testIdBase}_INSTRUMENT_MARKET_CAP`}
                />
              )}
            </Box>
          </Box>
          {showCharts && (
            <>
              <CardItemDiff
                baseTicker={item.baseTicker}
                quoteTicker={item.quoteTicker}
                testID={`${testIdBase}_INSTRUMENT_DIFF`}
                px={16}
              />
              <Box
                flexDirection="row"
                alignItems="center"
                testID={`${testIdBase}_INSTRUMENT_CHART`}
                minHeight={48}
              >
                <HODLSmallPreviewChartSmartCell
                  fromTicker={item.baseTicker}
                  toTicker={item.quoteTicker}
                  width={120 + 32}
                  height={48}
                  gradient
                />
              </Box>
            </>
          )}
        </TouchableBox>
      );
    }
  );
