import React from 'react';
import {Circle, CircleProps} from 'react-native-svg';

export interface DotProps extends CircleProps {
  x: number;
  y: number;
}

export const Dot: React.FC<DotProps> = React.memo(({x, y, ...props}) => {
  return (
    <Circle
      cx={x}
      cy={y}
      r={9 / 2}
      fillOpacity={1}
      vectorEffect="non-scaling-stroke"
      {...props}
    />
  );
});
