import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {DocsResponse} from './DocsResponse';
import {computed} from 'mobx';

export interface DocsResourceArgs {
  country: string;
}

export const privacyNoticeUnified = {
  name: 'Privacy Notice',
  link: 'https://app.youhodler.com/api/v1/docks/privacy-notice',
  i18nName: 'agreement.naumard.general.privacy_policy.name',
  i18nLink: 'https://app.youhodler.com/api/v1/docks/privacy-notice',
};

export class DocsResource extends createStaticResource({
  getKey: ({country}: DocsResourceArgs) => `docsResource:${country}`,
  getData: ({country}: DocsResourceArgs) =>
    TRANSPORT.API.get('/v1/docks/agreements', {
      params: {country},
    }).then(res => deserialize(DocsResponse, res.data)),
}) {
  @computed get documents() {
    return this.data;
  }
}
