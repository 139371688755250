import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const FiatWithdrawIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5 25.5a1.5 1.5 0 011.486 1.296L34 27v4a4 4 0 01-3.701 3.989L30 35H10a4 4 0 01-3.989-3.701L6 31v-4l.014-.204a1.5 1.5 0 012.972 0L9 27v3.5a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5V27l.014-.204A1.5 1.5 0 0132.5 25.5zM18.94 6.06a1.5 1.5 0 011.952-.145l.169.146L26 11c.497.497.547 1.272.15 1.825L26 13a1.415 1.415 0 01-1.825.15L24 13l-2.5-2.5v15a1.5 1.5 0 01-2.986.203L18.5 25.5v-15L16 13l-.175.15a1.415 1.415 0 01-1.974-1.975L14 11l4.94-4.94z"
          fill={color}
        />
      </Svg>
    );
  }
);
