import * as React from 'react';
import {ImageProps} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Illustration} from '@youtoken/ui.elements';

export interface AppLedgerNotFoundLayoutContentProps {
  img?: ImageProps['source'];
  title: string;
  description: string;
}

export const AppLedgerNotFoundLayoutContent: React.FC<AppLedgerNotFoundLayoutContentProps> =
  observer(({title, description}) => {
    const {t} = useTranslation();

    const handlePressGoToFullApp = React.useCallback(() => {
      window.open('https://app.youhodler.com', '_blank', 'noopener,noreferrer');
    }, []);

    return (
      <Box
        alignSelf="center"
        flexGrow={{default: 1, tablet: 0}}
        width="100%"
        maxWidth={442}
        mt={32}
      >
        <Box
          justifyContent={{
            default: 'center',
            tablet: 'flex-start',
          }}
          flexGrow={1}
          p={24}
        >
          <Box width="100%" height={{default: 160, tablet: 230}} mb={32}>
            <Illustration name="illustration-3" />
          </Box>
          <Heading variant="$heading-02" textAlign="center" mb={16}>
            {title}
          </Heading>
          <Text textAlign="center">{description}</Text>
        </Box>
        <Box p={24}>
          <Button size="large" onPress={handlePressGoToFullApp}>
            {t('surface.ledger_app_not_found.go_to_full_version')}
          </Button>
        </Box>
      </Box>
    );
  });
