import {
  action,
  autorun,
  comparer,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
import {computedFn} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {invariant} from '@youtoken/ui.utils';
import {
  formatByTicker,
  formatPercentTillPrecision,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import type {ExchangeAuthorizedFormArgs} from '../types';
import type {ExchangeAuthorizedFormV1Resources} from './types';
import {normalizeAmount} from '@youtoken/ui.normalizers';

export class Form {
  @observable
  public args: ExchangeAuthorizedFormArgs;

  @observable
  public resources: ExchangeAuthorizedFormV1Resources;

  @observable
  public instance: MobxReactForm;

  @observable
  public disposers: IReactionDisposer[] = [];

  //#region swap

  @observable
  swap: boolean = false;

  @computed
  get swapEnable() {
    const conversionTickerMarkets =
      this.resources.walletsResource.marketEnabledTickers.filter(ticker => {
        return this.resources.marketsResource
          .getTickersByMarketWithWhiteList(
            this.conversionTicker,
            this.resources.walletsResource.marketEnabledTickers
          )
          .includes(ticker);
      });

    return conversionTickerMarkets.includes(this.ticker);
  }

  @action
  swapOnPress = () => {
    const prevTicker = this.ticker;

    this.swap = true;

    this.setTicker(this.conversionTicker);
    this.setAmount(this.conversionAmount);

    this.instance
      .$('conversionTicker')
      .set(
        this.conversionTickers.includes(prevTicker)
          ? prevTicker
          : this.conversionTickers[0]
      );
  };

  //#endregion swap

  //#region balance

  @computed
  get balance() {
    invariant(
      this.resources.walletsResource.getByTicker(this.ticker),
      "Can't get balance by ticker",
      {},
      {ticker: this.ticker}
    );

    return this.resources.walletsResource.getByTicker(this.ticker)!.amount;
  }

  @computed
  get hasBalance() {
    return this.balance.gt(0);
  }

  @computed
  get balancePercent() {
    if (this.hasBalance) {
      return Number(
        formatPercentTillPrecision(toBig(this.amount).div(this.balance), 0)
      );
    }

    return 0;
  }

  @action
  setAmountByBalance = () => {
    return this.setAmount(this.balance.toString());
  };

  @action
  setAmountByPercentOfBalance = (percent: number) => {
    const amountValue = this.balance.mul(percent).div(100);

    this.setAmount(formatByTicker(amountValue, this.ticker, 0));
  };

  //#endregion

  //#region source

  @computed({
    equals: comparer.shallow,
  })
  get tickers() {
    return this.resources.walletsResource.marketEnabledTickers;
  }

  @computed
  get tickerInitial() {
    if (this.tickers.includes(this.args.ticker)) {
      return this.args.ticker;
    }

    return this.tickers[0];
  }

  @computed
  get ticker(): string {
    return this.instance?.$('ticker').value ?? this.tickerInitial;
  }

  @computed
  get amount() {
    return this.instance?.$('amount').get('value');
  }

  @computed
  get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.conversionTicker);
  }

  @computed
  get amountWithoutFee() {
    let res = toBig(this.amount).minus(this.fee);

    if (res.lt(0)) {
      return toBig(0);
    }

    return res;
  }

  @computed
  get sourceError() {
    return getTranslatedValidationMessage(
      this.instance?.$('ticker').get('error') ||
        this.instance?.$('amount').get('error')
    );
  }

  @computed
  get hasSourceError() {
    return Boolean(this.sourceError);
  }

  @action
  setTicker = (value: string) => {
    const onChange = this.instance.$('ticker').get('onChange');

    onChange(value);
  };

  @action
  setAmount = (value: string) => {
    const onChange = this.instance.$('amount').get('onChange');

    onChange(normalizeAmount(value));
  };

  getConversionAmount = computedFn(_amount => {
    const fee = this.resources.feeResource.calculateFee(
      'exchange',
      'exchange',
      this.ticker,
      this.conversionTicker,
      _amount
    );

    const amountWithoutFee = toBig(_amount).minus(fee);

    return amountWithoutFee.gt(0) ? amountWithoutFee.mul(this.rate) : toBig(0);
  });

  getConversionAmountFormatted = computedFn(amount => {
    if (amount === '') {
      return '';
    }

    const conversionAmount = this.getConversionAmount(amount);

    return conversionAmount.gt(0)
      ? formatByTicker(conversionAmount, this.conversionTicker, 0)
      : '0';
  });

  //#endregion source

  //#region target

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickers() {
    return this.resources.walletsResource.marketEnabledTickers.filter(
      ticker => {
        return this.resources.marketsResource
          .getTickersByMarketWithWhiteList(
            this.ticker,
            this.resources.walletsResource.marketEnabledTickers
          )
          .includes(ticker);
      }
    );
  }

  @computed
  get conversionTickerInitial() {
    if (
      this.args.conversionTicker &&
      this.conversionTickers.includes(this.args.conversionTicker)
    ) {
      return this.args.conversionTicker;
    }

    return this.conversionTickers[0];
  }

  @computed
  get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickerInitial
    );
  }

  @computed
  get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value');
  }

  @computed
  get targetError() {
    return getTranslatedValidationMessage(
      this.instance?.$('conversionTicker').get('error') ||
        this.instance?.$('conversionAmount').get('error')
    );
  }

  @computed
  get hasTargetError() {
    return Boolean(this.targetError);
  }

  @action
  setConversionTicker = (value: string) => {
    const onChange = this.instance.$('conversionTicker').get('onChange');

    onChange(value);
  };

  @action
  setConversionAmount = (_: string) => {
    // do nothing
  };

  //#endregion target

  //#region fee

  @computed
  get tariff() {
    return this.resources.feeResource.getFeeForExchange(
      this.ticker,
      this.conversionTicker
    );
  }

  @computed
  get tariffMinAmount() {
    return this.tariff?.minAmount;
  }

  @computed
  get tariffMaxAmount() {
    return this.tariff?.maxAmount;
  }

  @computed
  get fee() {
    return this.resources.feeResource.calculateFee(
      'exchange',
      'exchange',
      this.ticker,
      this.conversionTicker,
      this.amount
    );
  }

  //#endregion fee

  //#region rate

  @observable
  public rate: number;

  @computed
  get _rate() {
    return this.resources.ratesResource.getExchangeRate(
      this.ticker,
      this.conversionTicker
    );
  }

  @action
  setCurrentRate = (rate: number) => {
    this.rate = rate;
  };

  //#endregion rate

  public constructor(
    args: ExchangeAuthorizedFormArgs,
    resources: ExchangeAuthorizedFormV1Resources
  ) {
    this.args = args;
    this.resources = resources;
    this.rate = this._rate;

    const fields = {
      ticker: {
        value: this.ticker,
      },
      amount: {},
      conversionTicker: {
        value: this.conversionTicker,
      },
      conversionAmount: {},
    };

    const hooks = {
      onSuccess: (form: MobxReactForm) => {
        if (
          !this.resources.authMeResource.checkProductAvailability('exchange')
        ) {
          return Promise.resolve();
        }

        const {ticker, conversionTicker, amount} = form.values();

        return TRANSPORT.API.post('/v1/exchange', {
          ticker: ticker,
          amount: amount,
          toTicker: conversionTicker,
        })
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('surface.wallets.crypto_withdrawal.message.success'),
            });

            SHARED_ROUTER_SERVICE.navigate('__CloseModal', {});
            SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
            SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
              ticker: ticker,
            });
          })
          .catch(e => {
            handleFormSubmitError(form, e, {
              inputAmount: 'amount',
              toTicker: 'conversionTicker',
            });
          });
      },
    };

    const plugins = {
      yup: yupValidator({
        package: yupPackage,
        schema: (yup: typeof yupPackage) =>
          yup.lazy(() => {
            return yup.object().shape({
              amount: yup
                .big()
                .gte(this.tariffMinAmount!, () =>
                  messages.MIN_AMOUNT({
                    value: '' + this.tariffMinAmount,
                    ticker: this.ticker,
                  })
                )
                .lte(this.tariffMaxAmount!, () =>
                  messages.MAX_AMOUNT({
                    value: '' + this.tariffMaxAmount,
                    ticker: this.ticker,
                  })
                )
                .lte(this.balance, messages.FUNDS_INSUFFICIENT)
                .required(),
              ticker: yup.mixed().required(),
              conversionAmount: yup.string().required(),
              conversionTicker: yup.mixed().required(),
            });
          }),
      }),
    };

    const options = {
      validateOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true,
      showErrorsOnReset: false,
    };

    this.instance = new MobxReactForm({fields}, {plugins, hooks, options});

    this.disposers = [
      // set amount / conversionAmount after fields changed
      autorun(() => {
        this.instance
          .$('conversionAmount')
          .set(this.getConversionAmountFormatted(this.amount));
      }),
      // set conversionTicker after fields changed
      reaction(
        () => [this.ticker],
        () => {
          if (this.swap) {
            this.swap = false;
          } else {
            if (this.conversionTickers.includes(this.conversionTicker)) {
              return;
            }

            this.instance.$('conversionTicker').set(this.conversionTickers[0]);
          }
        }
      ),
      // set rate after fields changed
      reaction(
        () => [this.ticker, this.conversionTicker],
        () => {
          this.setCurrentRate(this._rate);
        },
        {
          fireImmediately: true,
        }
      ),
      // update rate once in 10 seconds
      reaction(
        () => this._rate,
        () => {
          this.setCurrentRate(this._rate);
        },
        {
          delay: 10000,
        }
      ),
    ];
  }

  dispose() {
    this.disposers.forEach(disposer => disposer?.());
  }
}
