import {action, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {
  AddressStatus,
  AuthMeResource,
  KYCStatus,
} from '@youtoken/ui.resource-auth-me';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import type {BadgeVariant} from '@youtoken/ui.elements';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {warning} from '@youtoken/ui.utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import type {
  IIBANAccount,
  IIBANAccountError,
  IIBANAccountsResponse,
} from './types/IIBANAccountsResponse';
import {IBANAccountsResponse} from './IBANAccountsResponse';

export enum IBANProviders {
  WIREX = 'wirex',
  FIAT_REPUBLIC = 'fiat-republic',
}

enum ProvidersNames {
  wirex = 'Wirex_yhdl Italy',
  'fiat-republic' = 'FiatRepublic',
}

export class IBANAccountsResource extends createStaticResource<
  {},
  IIBANAccountsResponse
>({
  getKey: () => 'ibanAccounts',
  getData: () => {
    const {
      products: {
        fiatRepublic: {isEnabled: isFiatRepublicEnabled},
        wirex: {isEnabled: isWirexEnabled},
      },
      addressResult,
      kycResult,
    } = AuthMeResource.getInstance({});

    const enableProvider =
      kycResult === KYCStatus.ACCEPT &&
      addressResult === AddressStatus.ACCEPT &&
      (isWirexEnabled || isFiatRepublicEnabled);

    // NOTE: enableIBAN includes checking on kyc and residence on BE, but we still need to check it if that flag is enabled directly in admin panel
    if (!enableProvider) {
      return Promise.resolve([]);
    }

    return TRANSPORT.API.get(
      `/v1/${
        isFiatRepublicEnabled
          ? IBANProviders.FIAT_REPUBLIC
          : IBANProviders.WIREX
      }/bank/account/`
    )
      .then(res =>
        deserialize(IBANAccountsResponse, res.data as IIBANAccountsResponse)
      )
      .catch(() => []);
  },
  skipRefreshOnVisible: false,
}) {
  public constructor(args: {}, data: IIBANAccountsResponse) {
    super(args, data);
    this.subscribe();

    TRANSPORT.SOCKET.on('bank-account-error', this.handleError);
    TRANSPORT.SOCKET.on('bank-account-created', this.handleUpdate);
    TRANSPORT.SOCKET.on('bank-account-completed', this.handleUpdate);
    TRANSPORT.SOCKET.on('connect', this.subscribe);
  }

  public onDestroy() {
    super.onDestroy();
    TRANSPORT.SOCKET.emit('unsub', {
      name: 'bankAccount',
    });

    TRANSPORT.SOCKET.off('bank-account-error', this.handleError);
    TRANSPORT.SOCKET.off('bank-account-created', this.handleUpdate);
    TRANSPORT.SOCKET.off('bank-account-completed', this.handleUpdate);
  }

  protected subscribe = () => {
    TRANSPORT.SOCKET.emit('sub', {
      name: 'bankAccount',
    });
  };

  @computed
  public get provider() {
    const {
      products: {
        fiatRepublic: {isEnabled: isFiatRepublicEnabled},
        wirex: {isEnabled: isWirexEnabled},
      },
    } = AuthMeResource.getInstance({});

    return isFiatRepublicEnabled
      ? IBANProviders.FIAT_REPUBLIC
      : isWirexEnabled
      ? IBANProviders.WIREX
      : null;
  }

  @computed
  public get providerName(): string | null {
    warning(
      this.provider,
      'cannot get provider name by provider',
      {},
      {
        providers: ProvidersNames,
        provider: this.provider,
      }
    );

    if (!this.provider) {
      return null;
    }

    return ProvidersNames[this.provider];
  }

  @computed
  public get isIBANEnabled() {
    return Boolean(this.provider);
  }

  @computed
  public get isIBANEurEnabled() {
    const {
      products: {
        wirex: {
          settings: {enableWirexIBAN, enableWirexIBAN_EUR},
        },
        fiatRepublic: {
          settings: {
            enableIBAN: enableFiatRepublicIBAN,
            enableIBAN_EUR: enableFiatRepublicIBAN_EUR,
          },
        },
      },
    } = AuthMeResource.getInstance({});

    return (
      (this.provider === IBANProviders.WIREX &&
        enableWirexIBAN &&
        enableWirexIBAN_EUR) ||
      (this.provider === IBANProviders.FIAT_REPUBLIC &&
        enableFiatRepublicIBAN &&
        enableFiatRepublicIBAN_EUR)
    );
  }

  @computed
  public get isIBANGbpEnabled() {
    const {
      products: {
        wirex: {
          settings: {enableWirexIBAN, enableWirexIBAN_GBP},
        },
        fiatRepublic: {
          settings: {
            enableIBAN: enableFiatRepublicIBAN,
            enableIBAN_GBP: enableFiatRepublicIBAN_GBP,
          },
        },
      },
    } = AuthMeResource.getInstance({});

    return (
      (this.provider === IBANProviders.WIREX &&
        enableWirexIBAN &&
        enableWirexIBAN_GBP) ||
      (this.provider === IBANProviders.FIAT_REPUBLIC &&
        enableFiatRepublicIBAN &&
        enableFiatRepublicIBAN_GBP)
    );
  }

  @computed
  public get isIBANAgreementsSigned() {
    const {
      data: {hasSignedWirexIBAN, hasSignedFiatRepublicIBAN},
    } = AuthMeResource.getInstance({});

    return (
      (this.provider === IBANProviders.WIREX && hasSignedWirexIBAN) ||
      (this.provider === IBANProviders.FIAT_REPUBLIC &&
        hasSignedFiatRepublicIBAN)
    );
  }

  @computed
  public get isThirdPartyPaymentsAllowed() {
    const {
      data: {isFiatRepublicThirdPartyAllowed, isWirexThirdPartyAllowed},
    } = AuthMeResource.getInstance({});

    return (
      (this.provider === IBANProviders.WIREX && isWirexThirdPartyAllowed) ||
      (this.provider === IBANProviders.FIAT_REPUBLIC &&
        isFiatRepublicThirdPartyAllowed)
    );
  }

  @computed
  public get accounts() {
    return this.data || [];
  }

  @action public openAccount = (ticker: string) => {
    return TRANSPORT.API.post<IIBANAccount>(
      `/v1/${this.provider}/bank/account/open`,
      {
        ticker,
      }
    )
      .then(() => {
        this.handleUpdate({
          ticker,
          status: 'CREATING',
          bankAddressString: null,
        });
      })
      .catch(error => {
        SENTRY.capture(error, {
          source: `${this.provider}IBAN.openAccount(ticker:${ticker})`,
        });

        throw error;
      });
  };

  @action private handleUpdate = (data: IIBANAccount) => {
    const newAccount = deserialize(IBANAccountsResponse, data);

    const existingAccountIndex = this.data.findIndex(
      account => account.ticker === newAccount.ticker
    );

    if (existingAccountIndex !== -1) {
      this.data[existingAccountIndex] = newAccount;
    } else {
      this.data.push(newAccount);
      if (['CREATING', 'UPDATING'].includes(newAccount.status)) {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.bank_wire.wirex.open_account.notification'),
        });
      }
    }

    if (newAccount.status === 'ACTIVE') {
      LOCAL_NOTIFICATIONS.info({
        text: i18n.t(
          'surface.bank_wire.wirex.open_account.status_active.notification',
          {
            ticker: newAccount.ticker.toUpperCase(),
          }
        ),
      });
    }
  };

  @action private handleError = (errorData: IIBANAccountError) => {
    const existingAccountIndex = this.data.findIndex(
      account => account.ticker === errorData.ticker
    );

    if (existingAccountIndex !== -1) {
      const fallback = i18n.t('common.errors.smth_went_wrong');
      const _errorTranslated = getTranslatedValidationMessage({
        fallback,
        i18n: {
          label: errorData.label,
        },
      });

      LOCAL_NOTIFICATIONS.error({
        text: _errorTranslated || fallback,
      });

      this.data[existingAccountIndex]!.status = errorData.status;
    }
  };

  getIBANAccountByTicker = computedFn((ticker: string) =>
    this.accounts.find(account => account.ticker === ticker)
  );

  isBadgeEnabled = computedFn((ticker: string) => {
    const account = this.getIBANAccountByTicker(ticker);
    const {bankWireDeposit} = AuthMeResource.getInstance({});

    return (
      this.provider &&
      ((ticker === 'eur' && this.isIBANEurEnabled) ||
        (ticker === 'gbp' && this.isIBANGbpEnabled)) &&
      bankWireDeposit[ticker] &&
      (!account || account?.status !== 'DISABLED')
    );
  });

  getBadgeData = computedFn(
    (
      ticker: string
    ): {
      variant: BadgeVariant;
      tooltipText: string;
    } => {
      const account = this.getIBANAccountByTicker(ticker);

      if (!account || !this.isIBANAgreementsSigned) {
        return {
          variant: 'locked',
          tooltipText: i18n.t(
            'surface.wirex_iban.status_badge.tooltip.available'
          ),
        };
      }

      if (account.status === 'ACTIVE') {
        return {
          variant: 'success',
          tooltipText: i18n.t('surface.wirex_iban.status_badge.tooltip.active'),
        };
      }

      return {
        variant: 'attention',
        tooltipText: i18n.t('surface.wirex_iban.status_badge.tooltip.opening'),
      };
    }
  );

  isActive = computedFn((ticker: string) => {
    const account = this.getIBANAccountByTicker(ticker);

    return (
      this.provider &&
      ((ticker === 'eur' && this.isIBANEurEnabled) ||
        (ticker === 'gbp' && this.isIBANGbpEnabled)) &&
      account?.status === 'ACTIVE'
    );
  });
}
