import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {SecurityDevicesResource} from '@youtoken/ui.state-security';
import {SessionModal} from './SessionModal';

export const SessionModalCell = cell<{id: string}>(({id}) => {
  const {getDeviceItem, endSession} = SecurityDevicesResource.use({});

  const item = getDeviceItem(id);

  if (!item) {
    return null;
  }

  return <SessionModal item={item} handleEndSession={endSession} />;
});
