import * as React from 'react';
import {WebView} from 'react-native-webview';
import {observer} from 'mobx-react';
import {i18n} from '@youtoken/ui.service-i18n';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {useActiveThemeType} from '@youtoken/ui.primitives';
import {type TradingViewData} from '../data';
import {type MultiHODLScreenName} from '../TVChartContainer';
import {useResource} from '@youtoken/ui.data-storage';
import {HodlsTariffsResource} from '@youtoken/ui.resource-hodl-tariffs';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export interface SmartTVChartContainerNativeProps {
  fromTicker: string;
  toTicker: string;
  mode: TradingViewData.Args['mode'];
  screenName: MultiHODLScreenName;
  initialPriceLineString?: string;
  takeProfitLineString?: string;
  marginCallLineString?: string;
  triggerPriceLineString?: string;
}

export const SmartTVChartContainerNative: React.FC<SmartTVChartContainerNativeProps> =
  observer(
    ({
      fromTicker,
      toTicker,
      mode = 'mid',
      screenName,
      initialPriceLineString,
      takeProfitLineString,
      marginCallLineString,
      triggerPriceLineString,
    }) => {
      const activeThemeType = useActiveThemeType();

      const {getTariffByTickers} = useResource(HodlsTariffsResource, {});

      const search = React.useMemo(() => {
        const tariff = getTariffByTickers(fromTicker, toTicker);

        const params: Record<string, string> = {
          fromTicker,
          toTicker,
          mode,
          precision: tariff?.precision.toString() ?? '4',
          daysOff: encodeURIComponent(JSON.stringify(tariff?.daysOff ?? [])),
          theme: activeThemeType,
          locale: i18n.language,
          env: ENVIRONMENT.APP_ENV,
        };

        if (initialPriceLineString) {
          params.initialPriceLine = encodeURIComponent(initialPriceLineString);
        }

        if (takeProfitLineString) {
          params.takeProfitLine = encodeURIComponent(takeProfitLineString);
        }

        if (marginCallLineString) {
          params.marginCallLine = encodeURIComponent(marginCallLineString);
        }

        if (triggerPriceLineString) {
          params.triggerPriceLine = encodeURIComponent(triggerPriceLineString);
        }

        return new URLSearchParams(params).toString();
      }, [
        fromTicker,
        toTicker,
        mode,
        initialPriceLineString,
        takeProfitLineString,
        marginCallLineString,
        triggerPriceLineString,
        getTariffByTickers,
        activeThemeType,
        i18n.language,
      ]);

      const INJECTED_JAVASCRIPT = `const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `;

      React.useEffect(() => {
        DATA_LAYER.trackStrict('hodl-chart-shown', {
          screenName: screenName,
          chartType: 'tradingview',
          instrumentName: `${fromTicker}/${toTicker}`,
          priceType: mode,
        });
      }, [fromTicker, toTicker, mode]);

      return (
        <WebView
          style={{
            flex: 1,
            backgroundColor: 'transparent',
          }}
          source={{
            uri: `https://tradingview.youhodler.vercel.app/?${search}`,
          }}
          injectedJavaScript={INJECTED_JAVASCRIPT}
          onMessage={() => {}}
          allowFileAccessFromFileURLs={true}
          domStorageEnabled={true}
          allowFileAccess={true}
          allowUniversalAccessFromFileURLs={true}
          originWhitelist={['*']}
          onShouldStartLoadWithRequest={() => true}
          overScrollMode="never"
          setBuiltInZoomControls={false}
          scrollEnabled={false}
          textZoom={100}
        />
      );
    }
  );
