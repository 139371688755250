import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation, type TKey} from '@youtoken/ui.service-i18n';
import {SecurityActivityData} from '@youtoken/ui.state-security';
import {DeviceInfo} from './DeviceInfo';

export interface SecurityActivityDetailsModalProps extends BoxProps {
  item: SecurityActivityData;
}

export const SecurityActivityDetailsModal: React.FC<SecurityActivityDetailsModalProps> =
  observer(({item, ...boxProps}) => {
    const {t} = useTranslation();

    const {type, target, action, fullDate, time, device} = item;

    return (
      <Box
        alignItems="center"
        px="$screen-default-offset"
        mb={32}
        {...boxProps}
      >
        <Heading variant="$heading-02-responsive" textAlign="center" my={24}>
          {t('modal.activity_details.title')}
        </Heading>

        {device && <DeviceInfo device={device} />}

        <Box alignSelf={'flex-start'} mb={16}>
          <Text variant="$body-02" color="$text-02" mb={8}>
            {t('modal.activity_details.table_title.action')}
          </Text>
          <Text variant="$body-01-medium-accent" color="$text-01">
            {type && t(`security_activity.${type}` as TKey)}{' '}
            {target && t(`security_activity.${target}` as TKey)}{' '}
            {action && t(`security_activity.${action}` as TKey)}
          </Text>
        </Box>

        <Box alignSelf={'flex-start'} mb={8}>
          <Text variant="$body-02" color="$text-02" mb={8}>
            {t('modal.activity_details.table_title.time')}
          </Text>
          <Text variant="$body-01-medium-accent" color="$text-01" mb={2}>
            {fullDate}
          </Text>
          <Text variant="$body-01" color="$text-02">
            {time}
          </Text>
        </Box>
      </Box>
    );
  });
