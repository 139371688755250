import * as React from 'react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Tabs} from '@youtoken/ui.tabs';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {SecurityDevicesResource} from '@youtoken/ui.state-security';
import {DevicesList} from './DevicesList';
import {SessionsList} from './SessionsList';
import {TrustedDevicesProps} from './types';
import {useTabItems} from './tabsItems';
import {observer} from 'mobx-react';

export const TrustedDevicesSurface: React.FC<TrustedDevicesProps> = observer(
  ({deviceData, sessionData}) => {
    const {t} = useTranslation();

    const tabsItems = useTabItems(t);

    const [activeIndex, setActiveIndex] = React.useState(0);

    const handleChangeTab = React.useCallback(
      (value: string, index: number) => {
        setActiveIndex(index);
      },
      []
    );

    return (
      <Box width="100%">
        <Heading
          variant="$heading-01-responsive"
          color="$text-01"
          mb={24}
          px={{default: 16, phone: 24, desktop: 0}}
        >
          {t('surface.trusted_devices.title')}
        </Heading>
        <Box maxWidth={342} mb={32} px={{default: 16, phone: 24, desktop: 0}}>
          <Tabs
            tabs={tabsItems}
            activeIndex={activeIndex}
            onChange={handleChangeTab}
          />
        </Box>
        <Text
          variant="$body-01"
          mb={24}
          px={{default: 16, phone: 24, desktop: 0}}
        >
          {activeIndex === 0
            ? t('surface.trusted_devices.devices.description')
            : t('surface.trusted_devices.sessions.description')}
        </Text>
        {activeIndex === 0 ? (
          <DevicesList data={deviceData} />
        ) : (
          <SessionsList data={sessionData} />
        )}
      </Box>
    );
  }
);

export const TrustedDevicesSurfaceCell = cell(() => {
  const {data, sessionListFiltered, refetchManually} =
    SecurityDevicesResource.use({});

  return (
    <TrustedDevicesSurface
      checkStatus={refetchManually}
      deviceData={data}
      sessionData={sessionListFiltered}
    />
  );
});
