import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {UmaInvoiceConfirmationForm} from '../../state';

export const InvoiceHeader: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      invoiceData: {amountToReceiveFormatted, convertedToTickerFormatted},
    },
  } = useForm<UmaInvoiceConfirmationForm>();

  return (
    <Box justifyContent="center" alignItems="center">
      <Text color="$text-02" textAlign="center" mb={8}>
        {t('surface.wallets.uma.withdrawal.they_will_receive')}
      </Text>
      <Heading textAlign="center" variant="$heading-01-responsive" mb={8}>
        {amountToReceiveFormatted} {convertedToTickerFormatted}
      </Heading>
    </Box>
  );
});
