import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryItemV3} from '@youtoken/ui.resource-rewards-v3-history';
import {Box} from '@youtoken/ui.primitives';
import {RewardsHistoryProps} from '../types';
import {keyExtractor} from '../utils';
import {RewardsHistoryTitle, RewardsHistoryItemV3Item} from '../..';

const renderItem = (item: RewardsHistoryItemV3, index: number) => {
  return (
    <RewardsHistoryItemV3Item key={keyExtractor(item, index)} data={item} />
  );
};

export interface RewardsHistoryViewV3Props extends RewardsHistoryProps {
  items: RewardsHistoryItemV3[];
}

export const RewardsHistoryViewV3: React.FC<RewardsHistoryViewV3Props> =
  observer(({showHeader, items}) => {
    return (
      <Box>
        {showHeader && <RewardsHistoryTitle />}
        {items.map(renderItem)}
      </Box>
    );
  });
