import * as React from 'react';
import {
  type BoxProps,
  type TextProps,
  Box,
  Text,
} from '@youtoken/ui.primitives';

export interface SavingsProgressWithLevelsMarkLabelProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  descriptionProps?: TextProps;
}

export const SavingsProgressWithLevelsMarkLabel: React.FC<
  SavingsProgressWithLevelsMarkLabelProps & BoxProps
> = ({title, description, descriptionProps, ...boxProps}) => {
  return (
    <Box testID="SAVINGS_PROGRESS_WITH_LEVELS_MARK_LABEL" {...boxProps}>
      {typeof title === 'string' ? (
        <Text
          testID="SAVINGS_PROGRESS_WITH_LEVELS_MARK_LABEL_TITLE"
          variant="$body-02"
          color="$text-02"
        >
          {title}
        </Text>
      ) : (
        title
      )}
      {typeof description === 'string' ? (
        <Text
          testID="SAVINGS_PROGRESS_WITH_LEVELS_MARK_LABEL_DESCRIPTION"
          variant="$body-03"
          color="$text-02"
          {...descriptionProps}
        >
          {description}
        </Text>
      ) : (
        description
      )}
    </Box>
  );
};
