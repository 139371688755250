import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {Trans} from '@youtoken/ui.elements';

interface IncentivesBonusForDepositProps {
  bonusesValue: string | number;
}

export const IncentivesBonusForDeposit: React.FC<
  IncentivesBonusForDepositProps & BoxProps
> = observer(({bonusesValue, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      flexDirection="row"
      alignSelf="flex-start"
      alignItems="center"
      borderRadius={8}
      borderWidth={1}
      borderColor="$ui-01"
      py={6}
      px={8}
      {...boxProps}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        width={24}
        height={24}
        mr={4}
        borderRadius={12}
        backgroundColor="$interactive-02"
      >
        <Icon name="first_trade" color="$interactive-01" size={16} />
      </Box>
      <Box flexDirection="row">
        <Trans
          values={{value: bonusesValue}}
          parent={props => (
            <Text variant="$body-02" color="$text-02" {...props} />
          )}
          components={{
            Strong: <Text variant="$body-02-high-accent" color="$text-05" />,
          }}
          i18nKey="surface.wallets.deposit_wizard.text.incentives_bonuses"
        />
        <Box ml={4}>
          <QuestionTooltip
            content={t(
              'surface.wallets.deposit_wizard.tooltip.incentives_bonuses'
            )}
          />
        </Box>
      </Box>
    </Box>
  );
});
