import big, {BigSource, RoundingMode} from 'big.js';
import {
  getCoinDecimalPrecision,
  getCoinDecimalPrecisionForLoans,
} from '@youtoken/ui.coin-utils';
import {warning} from '@youtoken/ui.utils';

export {priceFormatterWithEllipsis} from './priceFormatterWithEllipsis';
export {priceFormatterInThousands} from './priceFormatterInThousands';
export {priceFormatter} from './priceFormatter';

export const formatBigNumber = (
  value: BigSource | undefined,
  precision: number | undefined,
  dropZeroes = false,
  rm?: RoundingMode
) => {
  try {
    const _value = big(value!);

    if (_value.eq(0)) {
      return '0.0';
    }

    const valueRounded = _value.round(precision, rm);

    return dropZeroes
      ? valueRounded.toString()
      : valueRounded.toFixed(precision, rm);
  } catch (e) {
    return '';
  }
};

export const toBig = (value: any) => {
  try {
    return new big(value);
  } catch (e) {
    return new big(0);
  }
};

export const formatByTickerLoan = (amount: BigSource, ticker: string) => {
  return formatBigNumber(amount, getCoinDecimalPrecisionForLoans(ticker), true);
};

export const formatByTicker = (
  valueSource: BigSource | undefined,
  ticker: string | 'asMainCurrency' | undefined,
  rm?: RoundingMode
) => {
  if (valueSource === undefined || valueSource === '') {
    return '';
  }

  const p = ticker === 'asMainCurrency' ? 4 : getCoinDecimalPrecision(ticker);

  try {
    const value = big(valueSource);

    if (value.eq(0)) {
      return '0.0';
    }

    const trimmedValue = value.toFixed(p, rm);

    if (ticker === 'asMainCurrency') {
      return trimmedValue;
    }

    return big(trimmedValue).toFixed();
  } catch (e) {
    warning(
      false,
      'cannot convert ${valueSource} to big number',
      {},
      {
        value: valueSource,
      }
    );
    return '';
  }
};

export const formatNumberToOrder = (number: number) => {
  if (number === 1) {
    return '1st';
  }

  if (number === 2) {
    return '2nd';
  }

  if (number === 3) {
    return '3rd';
  }

  return `${number}th`;
};

export const isFormattedValueEqualZero = (
  valueSource: BigSource,
  ticker: string
) => {
  try {
    const valueWithPrecision = big(valueSource).toFixed(
      getCoinDecimalPrecision(ticker)
    );

    return big(valueWithPrecision).eq(0);
  } catch (e) {
    warning(
      false,
      'cannot convert ${valueSource} to big number',
      {},
      {
        value: valueSource,
      }
    );
    return false;
  }
};

export const getAmountDecimalPrecision = (amount?: BigSource) => {
  try {
    const _amount = big(amount!).abs();

    if (_amount.gte(1000)) {
      return 2;
    }

    if (_amount.gte(100)) {
      return 4;
    }

    return 6;
  } catch (e) {
    warning(
      false,
      'cannot convert ${amount} to big number',
      {},
      {
        value: amount,
      }
    );
    return 6;
  }
};

export const formatByAmountAndTicker = (
  amount: BigSource | undefined,
  ticker: string
) => {
  const precision = Math.min(
    getAmountDecimalPrecision(amount),
    getCoinDecimalPrecision(ticker)
  );

  return formatBigNumber(amount, precision, true);
};

export const getAmountRoundValue = (amount: big) => {
  return amount.gte(100) ? 0 : amount.gte(10) ? 1 : amount.gte(1) ? 2 : 3;
};
