import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type TextProps, useIsMobile, Text} from '@youtoken/ui.primitives';

interface ReferralsHeaderV1Props {
  amountFormatted: string;
}

export const ReferralsHeaderV1: React.FC<ReferralsHeaderV1Props & TextProps> =
  observer(({amountFormatted, ...textProps}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    const getUpToKey = isMobile
      ? 'surface.referral_program.referrals_and_bonuses.get_up_to_newline'
      : 'surface.referral_program.referrals_and_bonuses.get_up_to';

    return (
      <Text variant="$heading-01" color="$text-04" {...textProps}>
        {t(getUpToKey, {
          amount: amountFormatted,
        })}
      </Text>
    );
  });
