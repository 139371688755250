import {observable, action, computed, reaction} from 'mobx';
import {Animated, Platform} from 'react-native';
import {BaseChartState} from '../BaseChartState';

const useNativeDriver = Platform.select({web: false, default: true})!;

export class BaseCursor<ChartState extends BaseChartState> {
  protected _onCursorDataChangeCleanUp!: () => void;
  protected _cursorXReactionCleanUp!: () => void;
  protected _cursorAnimationCleanUp!: () => void;
  protected _onCursorIndexChangeCleanUp!: () => void;

  cleanUp() {
    this?._cursorXReactionCleanUp();
    this?._onCursorDataChangeCleanUp();
    this?._cursorAnimationCleanUp();
  }

  @observable chart!: ChartState;

  constructor(chart: ChartState) {
    this.chart = chart;

    this._cursorXReactionCleanUp = reaction(
      () => this.chart.cursorX,
      cursorX => {
        this.setCursorDataIndexFromCursorX(cursorX);
      }
    );

    this._onCursorDataChangeCleanUp = reaction(
      () => this.cursorDataPointFormatted,
      cursorDataPointFormatted => {
        this.chart.onCursorDataChange?.(cursorDataPointFormatted);
      }
    );

    this._onCursorIndexChangeCleanUp = reaction(
      () => this.cursorDataIndex,
      cursorDataIndex => {
        this.chart.onCursorIndexChange?.(cursorDataIndex);
      }
    );

    this._cursorAnimationCleanUp = reaction(
      () => this.cursorActive,
      cursorActive => {
        Animated.spring(this.cursorStateAnimatedNode, {
          toValue: cursorActive ? 0 : 1,
          useNativeDriver: useNativeDriver,
        }).start();
      }
    );
  }

  @observable cursorStateAnimatedNode: Animated.Value = new Animated.Value(1);

  @observable cursorDataIndex?: number;

  @action setCursorDataIndex = (index?: number) => {
    this.cursorDataIndex = index;
  };

  @action setCursorDataIndexFromCursorX = (cursorX?: number) => {
    if (!cursorX) {
      return this.setCursorDataIndex(undefined);
    }

    const index = this.chart.bisectorX.right(
      this.chart.data,
      this.chart.layout.scaleXChart.invert(cursorX)
    );

    this.setCursorDataIndex(
      index >= 0 && index <= this.chart.data.length - 1 ? index : undefined
    );
  };

  @computed get cursorDataPoint() {
    if (this.cursorDataIndex) {
      return this.chart.data[this.cursorDataIndex];
    }

    return undefined;
  }

  @computed get cursorDataPointFormatted() {
    if (!this.cursorDataPoint) {
      return undefined;
    }

    return {
      index: this.cursorDataIndex!,
      date: this.cursorDataPoint.date,
    };
  }

  @computed get cursorActive() {
    return Boolean(this.cursorDataIndex);
  }

  @computed get cursorAnimation() {
    return this.cursorStateAnimatedNode;
  }
}
