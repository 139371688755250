import {TRANSPORT} from '@youtoken/ui.transport';
import {action, computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {
  FeatureLoanDecreaseFormArgs,
  FeatureLoanDecreaseFormResources,
} from './index';
import {
  handleFormSubmitError,
  type ITickerWithAmountValue,
  tickerToTickerWithAmountValue,
} from '../../utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';

export class Form {
  @observable
  public args: FeatureLoanDecreaseFormArgs;

  @observable
  public resources: FeatureLoanDecreaseFormResources;

  @observable
  public instance: MobxReactForm;

  @computed.struct
  public get loan() {
    return this.resources.loanResource.data;
  }

  @computed
  public get loanId() {
    return this.loan.id;
  }

  @computed.struct
  public get tickerGroups(): [{values: ITickerWithAmountValue[]}] {
    const wallet = this.resources.walletsResource.getByTicker(
      this.loan.collateralTicker
    );

    const wallets = wallet ? [wallet] : [];

    const values = wallets.map(wallet =>
      tickerToTickerWithAmountValue(
        wallet.ticker,
        this.resources.walletsResource
      )
    );

    return [
      {
        values,
      },
    ];
  }

  @computed.struct
  public get ticker() {
    return this.tickerGroups[0].values[0];
  }

  @computed.struct
  public get conversionTicker() {
    return this.loan.borrowedTicker;
  }

  @computed
  public get amount() {
    return this.instance.$('amount').get('value');
  }

  @computed
  public get amountOnChange() {
    return this.instance.$('amount').get('onChange');
  }

  @action
  public restore = () => {};

  @action
  public submit = () => {
    return TRANSPORT.API.post(`/v${this.loan.version}/loan/decrease`, {
      id: this.loanId,
      amount: Number(this.amount),
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.loans.decrease_form.message.decreased'),
        });
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e);
      });
  };

  public constructor(
    args: FeatureLoanDecreaseFormArgs,
    resources: FeatureLoanDecreaseFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          amount: {},
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  amount: yup.string(),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
