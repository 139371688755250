import * as React from 'react';
import {
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import {
  TouchableOpacity,
  type TouchableOpacityProps,
  type ViewStyle,
} from 'react-native';
import {
  Box,
  createReanimatedBox,
  ReanimatedBox,
  useTheme,
} from '@youtoken/ui.primitives';

type SwitchProps = {
  value?: boolean;
  disabled?: boolean;
  onValueChange?: (nextValue: boolean) => void;
  hasError?: boolean;
};

const Touchable = createReanimatedBox(TouchableOpacity);

const SwitchToggler: React.FC<
  Pick<SwitchProps, 'value'> & {testID: string}
> = ({value, testID}) => {
  const handlerStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: withSpring(value ? 36 - 16 - 2 : 2, {
            overshootClamping: true,
            damping: 10,
            stiffness: 300,
          }),
        },
        {translateY: 2},
      ],
    } as ViewStyle;
  }, [value]);

  return (
    <ReanimatedBox
      width={16}
      height={16}
      borderRadius={16}
      backgroundColor="$text-04"
      style={handlerStyle}
      testID={testID}
    />
  );
};

const SwitchWrapper: React.FC<
  SwitchProps & Pick<TouchableOpacityProps, 'testID'>
> = ({children, value = false, disabled = false, onValueChange, testID}) => {
  const color = useSharedValue(value ? 1 : 0);

  React.useEffect(() => {
    color.value = withSpring(value ? 1 : 0, {
      overshootClamping: true,
      damping: 15,
      stiffness: 120,
    });
  }, [value]);

  const {colors} = useTheme();

  const touchableStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: disabled
        ? colors['$ui-02']
        : interpolateColor(
            color.value,
            [0, 1],
            [colors['$ui-04'], colors['$interactive-01']]
          ),
    };
  }, [color, colors]);

  const handlePress = React.useCallback(() => {
    onValueChange?.(!value);
  }, [value, onValueChange]);

  return (
    <Touchable
      accessible
      accessibilityRole="checkbox"
      accessibilityState={{
        checked: value,
        disabled: disabled,
      }}
      disabled={disabled}
      width={36}
      height={20}
      borderRadius={10}
      onPress={handlePress}
      style={touchableStyle}
      activeOpacity={1}
      testID={testID}
    >
      {children}
    </Touchable>
  );
};

export const Switch: React.FC<
  SwitchProps & Pick<React.ComponentProps<typeof SwitchWrapper>, 'testID'>
> = ({
  value = false,
  disabled = false,
  hasError = false,
  onValueChange,
  testID = 'SWITCH',
}) => {
  return (
    <Box width={36} height={20} position="relative">
      <SwitchWrapper
        value={value}
        disabled={disabled}
        onValueChange={onValueChange}
        testID={testID}
      >
        <SwitchToggler value={value} testID={`${testID}_TOGGLE`} />
      </SwitchWrapper>
      {hasError && (
        <Box
          position="absolute"
          zIndex={-1}
          left={-4}
          top={-4}
          width={44}
          height={28}
          borderRadius={14}
          borderColor="$danger-01"
          borderWidth={2}
        />
      )}
    </Box>
  );
};
