import * as React from 'react';
import {
  type DepositNowWizardProps,
  DepositNowWizard,
} from '@youtoken/ui.surfaces-wallets';
import {modal} from '../../../stores';
import {Modal} from '../_common';

type ModalDepositWizardProps = Omit<DepositNowWizardProps, 'onPressBack'> &
  React.ComponentProps<typeof Modal>;

export const ModalDepositWizard: React.FC<ModalDepositWizardProps> = ({
  source,
  ...modalProps
}) => {
  return (
    <Modal minHeight={200} {...modalProps}>
      <DepositNowWizard source={source} onPressBack={modal.close} />
    </Modal>
  );
};
