import * as React from 'react';
import {
  Box,
  BoxProps,
  Text,
  TouchableText,
  useTheme,
} from '@youtoken/ui.primitives';
import {useCountdown} from '@youtoken/ui.hooks';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {useEffect} from 'react';

type ResendingStatus = 'CAN_RESEND' | 'RESENDING' | 'RESEND_ON_TIMEOUT';

export const Footer: React.FC<
  BoxProps & {onResendEmail?: () => Promise<any>}
> = ({onResendEmail, ...boxProps}) => {
  const {colors} = useTheme();
  const {t} = useTranslation();

  const [sendingStatus, setSendingStatus] =
    React.useState<ResendingStatus>('RESEND_ON_TIMEOUT');

  const [timeLeft, {start, reset}] = useCountdown(45 * 1000, 1000);

  useEffect(() => {
    start();
  }, []);

  const handlePressResend = React.useCallback(() => {
    if (onResendEmail) {
      if (sendingStatus === 'CAN_RESEND') {
        setSendingStatus('RESENDING');
        onResendEmail()
          .then(() => {
            start();
            setSendingStatus('RESEND_ON_TIMEOUT');
          })
          .catch(() => {
            setSendingStatus('CAN_RESEND');
          });
      }
    }
  }, [onResendEmail, sendingStatus, start]);

  React.useEffect(() => {
    if (timeLeft === 0) {
      setSendingStatus('CAN_RESEND');
      reset();
    }
  }, [timeLeft, setSendingStatus, start]);

  return (
    <Box
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      {sendingStatus === 'RESENDING' && <ActivityIndicator size="small" />}

      {sendingStatus === 'RESEND_ON_TIMEOUT' && (
        <>
          <Text color="$text-02" variant="$body-02">
            {t('surface.await_confirm_device.link.message')}{' '}
          </Text>
          <Text variant="$body-02">
            {t('surface.await_confirm_device.link.resend_in_time', {
              timeLeft: Math.round(timeLeft / 1000),
            })}
          </Text>
        </>
      )}

      {sendingStatus === 'CAN_RESEND' && (
        <>
          <Text color="$text-02" variant="$body-02">
            {t('surface.await_confirm_device.link.message')}{' '}
          </Text>
          <TouchableText
            color="$text-05"
            variant="$body-02"
            onPress={handlePressResend}
          >
            {t('surface.await_confirm_device.link.resend')}
          </TouchableText>
        </>
      )}
    </Box>
  );
};
