import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {
  Box,
  TouchableBox,
  useIsMobile,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {
  LoanItemHeader,
  LoanItemMainAction,
  LoanItemChartPreview,
  LoanItemAlertClientMC,
} from '../../components';

export interface LoansListRegularItemProps extends TouchableBoxProps {
  item: LoanRegularV1 | LoanRegularV2;
  onCancelled?: () => void;
}

export const LoansListRegularItem: React.FC<LoansListRegularItemProps> =
  observer(({item, onCancelled, ...rest}) => {
    const isMobile = useIsMobile();

    const handlePress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('LoanItem', {
        id: item.id,
      });
    }, [item.id]);

    return (
      <TouchableBox
        testID={`LOAN_LIST_ITEM_${item.id}`}
        backgroundColor={
          item.version === 2 && item.penalty
            ? '$attention-02'
            : '$ui-background'
        }
        onPress={handlePress}
        {...rest}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          p={20}
        >
          <LoanItemHeader item={item} />
          {!isMobile && (
            <LoanItemMainAction
              item={item}
              onCancelled={onCancelled}
              size="small"
              width={200}
              ml={20}
            />
          )}
        </Box>
        <LoanItemChartPreview item={item} />
        <LoanItemAlertClientMC item={item} />
      </TouchableBox>
    );
  });
