enum unitsToValue {
  'Tr' = 12,
  'Bn' = 9,
  'M' = 6,
  'K' = 3,
}

type PriceFormatterProps = {
  amount: number | string;
  precision?: number;
};

type ConverterProps = {
  amount: string;
  unit: keyof typeof unitsToValue;
  precision?: number;
};

type PriceFormatterResponse = {value: string; unit: string};

const converter = ({
  amount,
  unit,
  precision = 2,
}: ConverterProps): PriceFormatterResponse => {
  const mainAmount = amount.slice(0, -unitsToValue[unit]);
  const precisionAmount = amount.slice(-unitsToValue[unit], amount.length);
  const resultString = mainAmount + '.' + precisionAmount.slice(0, precision);
  const value = parseFloat(resultString).toString();

  return {value, unit};
};
/** Formatting price into human-readable format with absolute minimal rounding.
 * I advise you don't use this function on numbers greater than a thousand trillion to avoid rounding problems.
 * @param amount - price value
 * @param precision - the number of decimal places you want to see; default = 2
 * @param returnUnits - if true return object {value, unit} instead of string; default = false
 * @example
 * 0.1 => '0'
 * -0.1 => '0'
 * 0.49 => '0'
 * 0.5 => '1'
 * -0.5 => '-1'
 * 99.49 => '99'
 * 99.9 => '100'
 * 999.49 => '999'
 * 999.5 => '1K'
 * 1100 => '1.1K'
 * 23 539.60 => '23.54K'
 * -23 539.60 => '-23.54K'
 * 1 000 000 => '1M'
 * -1 000 000 => '-1M'
 * 1 000 000 000 => '1Bn'
 * -1 000 000 000 => '-1Bn'
 * 1 000 000 000 000= > '1Tr'
 * -1 000 000 000 000 => '-1Tr'
 * 1 999 999 999 999 => '1.99Tr'
 * 1 999 999 999 999.5 => '2Tr'
 * 1 999 999 999 999 999 => '1999.99Tr'
 * 1 999 999 999 999 998 999 => '1999999.99Tr'
 * 1 999 999 999 999 999 999 => '2000000Tr'
 */

export const priceFormatter = ({
  amount,
  precision,
}: PriceFormatterProps): PriceFormatterResponse => {
  const numberAmount = typeof amount === 'string' ? Number(amount) : amount;
  const roundedAmount = numberAmount.toFixed();
  const absoluteAmount = Math.abs(parseFloat(roundedAmount));

  if (absoluteAmount >= 1e12) {
    return converter({
      amount: roundedAmount,
      unit: 'Tr',
      precision,
    });
  }

  if (absoluteAmount >= 1e9) {
    return converter({
      amount: roundedAmount,
      unit: 'Bn',
      precision,
    });
  }

  if (absoluteAmount >= 1e6) {
    return converter({
      amount: roundedAmount,
      unit: 'M',
      precision,
    });
  }

  if (absoluteAmount >= 1e3) {
    return converter({
      amount: roundedAmount,
      unit: 'K',
      precision,
    });
  }

  const value = parseFloat(roundedAmount).toString();
  return {value, unit: ''};
};
