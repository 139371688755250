import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SubmitButton, IntercomButton} from '@web-app/src/components/elements';
import {Text, Heading} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {FormikCustom} from '@web-app/src/components/elements/Forms';
import {TextField} from '@web-app/src/components/elements/Forms';
import {useOnSubmit, useValidationSchema} from '@web-app/src/hooks';
import {AUTH_OPERATION_TYPES} from '../../../../../../config';
import {normalizeOnlyDigits} from '@web-app/src/utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';

type Values = {
  code: string;
};

interface FormProps {
  operation: any;
}

export const GA: React.FC<FormProps> = ({operation}) => {
  const {signInConfirmSuccess, confirmOperation} = AuthMeResource.use({});

  const {push} = RouterResource.use({});

  const {t} = useTranslation();

  const onSubmit = useOnSubmit<Values>(
    ({code}) => {
      return __GLOBAL_RECAPTCHA__
        .withToken('sign_in', token =>
          confirmOperation({
            token,
            operationId: operation.operationId,
            code,
          })
        )
        .then(payload => {
          signInConfirmSuccess(payload);
        })
        .catch(e => {
          const {
            // @ts-ignore
            response: {data: {operationType, operationId} = {}},
          } = e;

          if (
            operationType === AUTH_OPERATION_TYPES.CONFIRM_DEVICE &&
            operationId
          ) {
            push(`/device-confirm?operationId=${operationId}`);
            return Promise.resolve();
          }

          if (e.response?.status === 429) {
            push('/');
          }

          throw e;
        });
    },
    {
      successMessage: t('surface.sign_in_confirm.message.code_passed'),
      errorMessage: t('surface.sign_in_confirm.message.code_invalid'),
    }
  );

  const validationSchema = useValidationSchema<Values>(yup => ({
    code: yup.string().required(),
  }));

  return (
    <>
      <Heading variant="$heading-01" mb={5}>
        {t('surface.sign_in_confirm.title')}
      </Heading>
      <Text mb={{default: 20, desktop: 30}}>
        {t('surface.sign_in_confirm.subtitle_ga')}
      </Text>
      <FormikCustom
        initialValues={{
          code: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={() => (
          <>
            <TextField
              type="text"
              label={t('surface.sign_in_confirm.code_label.ga')}
              placeholder={t('surface.sign_in_confirm.code_placeholder')}
              name="code"
              normalize={normalizeOnlyDigits}
            />
            <SubmitButton size="large" mb={30}>
              {t('surface.sign_in_confirm.continue')}
            </SubmitButton>
            <Trans
              t={t}
              i18nKey="surface.auth.to_restore"
              style={{flexWrap: 'wrap'}}
              parent={props => <Text variant="$body-01" {...props} />}
              defaults="To restore account access, <IntercomButton>contact support</IntercomButton>"
              components={{
                IntercomButton: (
                  <IntercomButton
                    render={({openIntercom, children}) => {
                      return <Link onPress={openIntercom}>{children}</Link>;
                    }}
                  />
                ),
              }}
            />
          </>
        )}
      />
    </>
  );
};
