import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Redirect,
  Route,
  type RouteComponentProps,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {HODLByIdFeature} from '@youtoken/ui.surface-hodl-positions';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SwitchWithNotFound} from '../SwitchWithNotFound';
import {authRequired} from '../authRequired';
import {
  HODLPage,
  HODLsInstrumentPage,
  HODLsMainPage,
  MultiHodlCreate,
} from '../../pages';

const withProductFlag = (Component: React.ComponentType<any>) => {
  const ComponentWithRedirect: React.FC = observer(props => {
    const {
      data: {enableHodl},
    } = AuthMeResource.use({});

    if (!enableHodl) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  });

  ComponentWithRedirect.displayName = `withProductFlag(${
    Component.displayName || ''
  }))`;

  return ComponentWithRedirect;
};

const MultiHodlInstrumentsWrapped = authRequired(
  withProductFlag(() => <HODLsMainPage innerPage={PageSwitcherValue.TRADING} />)
);
const MultiHodlPortfolioWrapped = authRequired(
  withProductFlag(() => (
    <HODLsMainPage innerPage={PageSwitcherValue.PORTFOLIO} />
  ))
);
const MultiHodlInstrumentWrapped = authRequired(
  withProductFlag(HODLsInstrumentPage)
);
const MultiHodlCreateWrapped = authRequired(withProductFlag(MultiHodlCreate));
const MultiHodlItemWrapped = authRequired(withProductFlag(HODLPage));

const MultiHodlItemPageWrapped = authRequired(
  withProductFlag(() => {
    const {id} = useParams<{
      id: string;
    }>();
    const {path} = useRouteMatch();
    const {navigate} = SHARED_ROUTER_SERVICE;

    const {isExtendTpSlAvailable, isFlipDirectionAvailable} =
      HODLByIdFeature.use({
        id,
      });

    React.useEffect(() => {
      if (path.includes('increase') && isExtendTpSlAvailable) {
        navigate('HodlExtendTpSl', {
          id,
        });
      } else if (path.includes('flip') && isFlipDirectionAvailable) {
        navigate('HodlFlip', {
          id,
        });
      } else if (path.includes('history')) {
        navigate('HodlHistory', {
          id,
        });
      }
    }, []);

    return <HODLPage />;
  })
);

export const MultihodlRoutes: React.FC<RouteComponentProps> = ({
  match: {path},
}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/instruments`} />
      </Route>
      <Route
        exact
        path={`${path}/instruments`}
        component={MultiHodlInstrumentsWrapped}
      />
      <Route
        exact
        path={`${path}/instrument/:symbol`}
        component={MultiHodlInstrumentWrapped}
      />
      <Route
        exact
        path={`${path}/portfolio`}
        component={MultiHodlPortfolioWrapped}
      />
      <Route exact path={`${path}/create`} component={MultiHodlCreateWrapped} />
      <Route exact path={`${path}/item/:id`} component={MultiHodlItemWrapped} />
      <Route
        exact
        path={`${path}/item/:id/increase`}
        component={MultiHodlItemPageWrapped}
      />
      <Route
        exact
        path={`${path}/item/:id/flip`}
        component={MultiHodlItemPageWrapped}
      />
      <Route
        exact
        path={`${path}/item/:id/history`}
        component={MultiHodlItemPageWrapped}
      />
    </SwitchWithNotFound>
  );
};
