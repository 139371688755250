import React from 'react';
import {
  type CryptoBuyWizardProps,
  CryptoBuyWizard,
} from '@youtoken/ui.surfaces-wallets';
import {modal} from '../../../stores';
import {Modal} from '../_common';

type ModalCryptoBuyProps = Omit<CryptoBuyWizardProps, 'onPressBack'> &
  React.ComponentProps<typeof Modal>;

export const ModalCryptoBuy: React.FC<ModalCryptoBuyProps> = ({
  source,
  ticker,
  ...otherProps
}) => {
  return (
    <Modal minHeight={350} {...otherProps}>
      <CryptoBuyWizard
        source={source}
        ticker={ticker}
        onPressBack={modal.close}
      />
    </Modal>
  );
};
