import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {invariant} from '@youtoken/ui.utils';
import {Icon} from '@youtoken/ui.icons';
import {prepareChildren} from '@youtoken/ui.loyalty-miner-components';
import {AccountLevelInfoItemConversionFull} from '../../../components';

export interface LevelInfoConversionProps extends BoxProps {
  level: number;
}

export const LevelInfoConversion: React.FC<LevelInfoConversionProps> = observer(
  ({level, ...boxProps}) => {
    const {t} = useTranslation();

    const {getLevel, levels} = LoyaltyResource.use({});

    const levelClass = getLevel(level);

    invariant(
      levelClass,
      `cannot get conversion for level ${level}`,
      {},
      {levels, level}
    );

    const {
      conversion: {
        ticker,
        sparksAmount,
        sparksRequiredVolumeFormatted,
        conversionVolumeFormatted,
        conversionRequiredVolumeFormatted,
      },
    } = levelClass;

    return (
      <Box testID="LEVEL_INFO_CONVERSION" {...boxProps}>
        <AccountLevelInfoItemConversionFull level={levelClass} p={0} mb={24} />
        <Box
          testID="ACCOUNT_LEVEL_CONVERSION_SPARKS"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py={4}
        >
          <Text variant="$body-02">
            {t('surface.loyalty.conversion.sparks')}
          </Text>
          <Trans
            t={t}
            parent={({children}) => {
              return (
                <Box
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  flexWrap="wrap"
                  flexGrow={1}
                  flexShrink={1}
                >
                  {prepareChildren(children)}
                </Box>
              );
            }}
            components={{
              Icon: <Icon name="spark" size={16} />,
              Strong: <Text variant="$body-02-high-accent" color="$text-01" />,
            }}
            values={{
              symbol: getCoinSymbol(ticker),
              count: sparksAmount,
              volume: sparksRequiredVolumeFormatted,
            }}
            i18nKey="surface.loyalty.conversion.sparks.value"
          />
        </Box>
        <Box
          testID="ACCOUNT_LEVEL_CONVERSION_TRADING_VOLUME"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          py={4}
          mt={8}
        >
          <Text variant="$body-02">
            {t('surface.loyalty.conversion.trading_volume')}
          </Text>
          <Trans
            t={t}
            parent={({children}) => (
              <Text
                testID="ACCOUNT_LEVEL_CONVERSION_TRADING_VOLUME_VALUE"
                variant="$body-02"
                color="$text-02"
                textAlign="right"
              >
                {children}
              </Text>
            )}
            components={{
              Strong: <Text variant="$body-02-high-accent" color="$text-01" />,
            }}
            values={{
              symbol: getCoinSymbol(ticker),
              amount: conversionVolumeFormatted,
              volume: conversionRequiredVolumeFormatted,
            }}
            i18nKey="surface.loyalty.conversion.trading_volume.value"
          />
        </Box>
      </Box>
    );
  }
);
