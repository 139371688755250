import {CustomError} from '.';

export function isCustomError(
  error: Error | CustomError
): error is CustomError {
  return (error as CustomError).__isCustomError;
}

export function maskUUID(input: string): string {
  return input.replace(
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/,
    ':uuid'
  );
}
