import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {ShareRefCodeModal} from '.';

interface ShareRefCodeModalTriggerProps {
  source: string;
}

export const ShareRefCodeModalTrigger: React.FC<ShareRefCodeModalTriggerProps> =
  observer(({source}) => {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpen = React.useCallback(() => {
      setIsOpen(true);

      DATA_LAYER.trackStrict('reward-referral-invite-click', {source});
    }, [source]);

    const handleClose = React.useCallback(() => {
      setIsOpen(false);
    }, []);

    return (
      <>
        <Button size="large" onPress={handleOpen}>
          {t('surface.referral_program.how_it_works.invite_friends_action')}
        </Button>
        <ShareRefCodeModal isOpen={isOpen} onClose={handleClose} />
      </>
    );
  });
