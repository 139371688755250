import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {type DirectionType} from '@youtoken/ui.hodls-utils';

export interface RepeatTradeButtonProps {
  baseTicker?: string;
  quoteTicker?: string;
  direction?: DirectionType;
  multiplier?: string;
  inputTicker?: string;
  inputAmount?: string;
  additionalInputAmount?: string;
}

export const RepeatTradeButton: React.FC<RepeatTradeButtonProps> = props => {
  const {t} = useTranslation();

  const createNewHODL = React.useCallback(() => {
    DATA_LAYER.trackStrict('hodl-repeat-from-closed', {
      baseTicker: props.baseTicker,
      quoteTicker: props.quoteTicker,
      direction: props.direction,
      inputTicker: props.inputTicker,
      inputAmount: props.inputAmount,
      leverage: props.multiplier,
      additionalInputAmount: props.additionalInputAmount,
    });

    SHARED_ROUTER_SERVICE.navigate('NewMultiHODL', {}, {...props});
  }, [props]);

  return (
    <Button
      size="large"
      width="100%"
      icon="hodl"
      onPress={createNewHODL}
      testID="REPEAT_TRADE_BUTTON"
    >
      {t('surface.hodls.item.repeat_trade_button')}
    </Button>
  );
};
