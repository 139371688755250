import * as React from 'react';
import {TouchableBox, Box, Text, Heading} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {Icon, type BaseIconName} from '@youtoken/ui.icons';
import {cell} from '@youtoken/ui.cell';
import type {MenuItemBaseProps} from './types';

export const MenuItemBase: React.FC<MenuItemBaseProps> = cell(
  ({
    icon,
    title,
    subtitle,
    badgeText,
    badgeVariant = 'neutral',
    onPress,
    RightPartComponentWeb,
    children,
    testID,
    ...boxProps
  }) => {
    return (
      <TouchableBox
        flexDirection="column"
        disabled={!onPress}
        onPress={onPress}
        borderBottomWidth={1}
        borderLeftWidth={{
          default: 0,
          desktop: 1,
        }}
        borderRightWidth={{
          default: 0,
          desktop: 1,
        }}
        borderColor="$ui-01"
        borderRadius={0}
        p={24}
        testID={testID}
        {...boxProps}
      >
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            flexDirection="column"
            pr={16}
            flexBasis={0}
            flexGrow={1}
            flexShrink={1}
            testID={testID + '_INNER'}
          >
            <Box flexDirection="row" alignItems="center">
              <Icon name={icon as BaseIconName} size={20} />
              <Heading variant="$heading-02" ml={8}>
                {title}
              </Heading>
              {Boolean(badgeText) && (
                <Badge
                  ml={8}
                  variant={badgeVariant}
                  size="tiny"
                  testID={`${testID}_BADGE_${badgeVariant.toUpperCase()}`}
                >
                  {badgeText}
                </Badge>
              )}
            </Box>
            {Boolean(subtitle) && (
              <Text variant="$body-02" color="$text-02" mt={8}>
                {subtitle}
              </Text>
            )}
          </Box>
          {Boolean(RightPartComponentWeb) && RightPartComponentWeb}
        </Box>
        {Boolean(children) && <Box mt={16}>{children}</Box>}
      </TouchableBox>
    );
  }
);
