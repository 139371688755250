import BigNumber from 'bignumber.js';
import base64url from 'base64url';
import {
  type BitcoinTransaction,
  type TokenCurrency,
  type Currency,
  type EthereumTransaction,
  ExchangeType,
  FeesLevel,
} from '@ledgerhq/live-app-sdk';
import {TRANSPORT} from '@youtoken/ui.transport';
import {invariant} from '@youtoken/ui.utils';
import './protocol_pb.js';

export interface SignExchangeFundParams {
  fromAccountId: string;
  currency: Currency;
  amount: number;
  payinUser: string;
  payinAddressName: string;
  payinAddress: string;
  feesStrategy: FeesLevel;
}

export const signExchangeFund = (
  txId: string,
  {
    fromAccountId,
    currency,
    amount: _amount,
    payinUser,
    payinAddressName,
    payinAddress,
    feesStrategy = FeesLevel.Medium,
  }: SignExchangeFundParams
) => {
  const exchangeType = ExchangeType.FUND;
  const ticker = currency.ticker;

  invariant(currency.units[0], 'cannot get currency units with 0 index');

  const amount = BigNumber(_amount)
    .times(BigNumber(10).pow(currency.units[0].magnitude))
    .integerValue()
    .toNumber();

  const transaction = {
    amount: new BigNumber(amount), // Correspond to 1 satoshi or 0.00000001 BTC
    recipient: payinAddress,
    family: (currency as TokenCurrency).parent ?? currency.id,
  } as EthereumTransaction | BitcoinTransaction;

  const payload = base64url(
    Buffer.from(
      createExchangeFundResponse({
        txId,
        amount,
        ticker,
        payinUser,
        payinAddressName,
        payinAddress,
      }).serializeBinary()
    )
  );

  return TRANSPORT.API.post<{
    provider: string;
    signature: string;
  }>('/v1/ledger-live/sign', {
    payload,
  }).then(({data: {provider, signature}}) => {
    return {
      exchangeType,
      feesStrategy,
      fromAccountId,
      transaction,
      binaryPayload: Buffer.from(payload),
      provider,
      signature: base64url.toBuffer(signature),
    };
  });
};

export interface CreateExchangeFundResponseParams
  extends Omit<
    SignExchangeFundParams,
    'fromAccountId' | 'currency' | 'feesStrategy'
  > {
  txId: string;
  ticker: string;
}

export const createExchangeFundResponse = ({
  txId,
  amount,
  ticker,
  payinUser,
  payinAddressName,
  payinAddress,
}: CreateExchangeFundResponseParams) => {
  // @ts-ignore
  const tr = new proto.ledger_swap.NewFundResponse();

  tr.setDeviceTransactionId(base64url.toBuffer(txId));
  tr.setInAddress(payinAddress);
  tr.setInAmount(numberToBigEndianBuffer(amount));
  tr.setInCurrency(ticker);
  tr.setUserId(payinUser);
  tr.setAccountName(payinAddressName);

  return tr;
};

export const numberToBigEndianBuffer = (x: number) => {
  var hex = x.toString(16);
  return Uint8Array.from(
    Buffer.from(hex.padStart(hex.length + (hex.length % 2), '0'), 'hex')
  );
};
