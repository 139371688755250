import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';

export const CellWrap: React.FC<{width: number; height: number}> = ({
  width,
  height,
  children,
}) => (
  <Box width={width} height={height}>
    {children}
  </Box>
);
