import {observable} from 'mobx';
import {Form} from './Form';
import {View} from './View';
import {type UmaCreateFormArgs, type UmaCreateFormResources} from './types';

export class UmaCreateForm {
  @observable
  public form: Form;

  @observable
  public view: View;

  public constructor(
    args: UmaCreateFormArgs,
    resources: UmaCreateFormResources
  ) {
    this.form = new Form(args, resources);
    this.view = new View(this.form);
  }
}
