import React from 'react';
import {observer} from 'mobx-react';
import {HeaderBase} from '../../components';
import {getFiatDepositBankWireWizardScreenTitle} from '.';

export interface FiatDepositBankWireHeaderProps {
  ticker: string;
  onPressBack?: () => void;
}

export const FiatDepositBankWireHeader: React.FC<FiatDepositBankWireHeaderProps> =
  observer(({ticker, onPressBack}) => {
    return (
      <HeaderBase onPressBack={onPressBack}>
        {getFiatDepositBankWireWizardScreenTitle(ticker)}
      </HeaderBase>
    );
  });
