import * as React from 'react';
import {warning} from '@youtoken/ui.utils';

const REGEX = new RegExp(/\[(.*?)\]\((.*?)\)/);

export const getLinkParams = (children: React.ReactNode) => {
  const linkText = React.Children.toArray(children)[0] as string;

  warning(typeof linkText === 'string', 'TransLink children must be a string');

  const match = linkText.match(REGEX);

  if (!match) {
    return {text: null, url: null};
  }

  const [_full, text, url] = match;

  return {text, url};
};
