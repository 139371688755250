import * as React from 'react';
import {observer} from 'mobx-react';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AddPaymentMethodSurface} from '../../../CardPaymentMethods';
import {HeaderBase} from '../../../components';
import {
  CardDepositWizardNavigator,
  CardDepositWizardScreenName,
} from '../index';
import {Platform} from 'react-native';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CardDepositAddCard: React.FC = observer(() => {
  const {t} = useTranslation();

  const {goBack} = useWizard<
    CardDepositWizardNavigator,
    CardDepositWizardScreenName.ADD_CARD
  >();

  return (
    <Box flex={1}>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.add_card')}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          keyboardDismissMode: 'interactive',
          style: {flex: 1},
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <AddPaymentMethodSurface onPressCancel={goBack} />
      </KeyboardScrollView>
    </Box>
  );
});
