import * as React from 'react';
import {KeyboardAvoidingView, Platform} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {Tabs} from '@youtoken/ui.tabs';
import {TabExternal} from './TabExternal';
import {TabInternal} from './TabInternal';

type BankCardAddMoneySurfaceProps = {
  cardId: string;
  onSuccess: () => void;
};
export const BankCardAddMoneySurface: React.FC<
  BankCardAddMoneySurfaceProps & BoxProps
> = cell(
  ({cardId, onSuccess, ...boxProps}) => {
    const {t} = useTranslation();

    const [activeIndex, setActiveIndex] = React.useState<number>(0);

    const handleTabChange = React.useCallback(
      (_: string, activeIndex: number) => {
        setActiveIndex(activeIndex);
      },
      [setActiveIndex]
    );

    return (
      <Box flex={1} backgroundColor="$ui-background" {...boxProps}>
        <KeyboardAvoidingView
          behavior="padding"
          enabled={Platform.OS !== 'android'}
          style={{flex: 1}}
          keyboardVerticalOffset={114}
        >
          <Box mt={24} px={24} testID="BANK_CARD_ADD_MONEY_SURFACE">
            <Tabs
              size="small"
              activeIndex={activeIndex}
              onChange={handleTabChange}
              tabs={[
                {
                  type: 'text',
                  label: t('surface.bank_cards.deposit.tab.internal'),
                  value: 'internal',
                },
                {
                  type: 'text',
                  label: t('surface.bank_cards.deposit.tab.external'),
                  value: 'external',
                },
              ]}
            />
          </Box>

          {activeIndex === 0 && (
            <TabInternal cardId={cardId} onSuccess={onSuccess} />
          )}
          {activeIndex === 1 && <TabExternal />}
        </KeyboardAvoidingView>
      </Box>
    );
  },
  {
    displayName: 'BankCardAddMoney',
  }
);

export const getTitle = () => i18n.t('surface.bank_cards.deposit.title');
