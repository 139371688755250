import {TRANSPORT} from '@youtoken/ui.transport';
import {getStartEndDates} from './getStartEndDates';
import {ChartResource} from '../types';

/** get line chart data from server */
export const getLineData = (
  args: ChartResource.Args
): Promise<ChartResource.Data> => {
  const [fromDate, toDate] = getStartEndDates(
    args.timePeriod!,
    args.fromDate,
    args.toDate
  );

  return TRANSPORT.API.get<ChartResource.Line.PointFromServer[]>(
    '/v3/rates/chart',
    {
      params: {
        type: 'line',
        product: args.product,
        fromTicker: args.fromTicker,
        toTicker: args.toTicker,
        points: args.linePoints || 120,
        fromDate,
        toDate,
        mode: args.mode ?? 'mid',
      },
    }
  ).then(response => ({
    type: 'line',
    data: response.data.map(item => ({
      date: new Date(item.date),
      rate: item.rate,
    })),
  }));
};

/** get candlestick chart data from server */
export const getCandlesData = (
  args: ChartResource.Args
): Promise<ChartResource.Data> => {
  const [, toDate] = getStartEndDates(
    args.timePeriod!,
    args.fromDate,
    args.toDate
  );

  return TRANSPORT.API.get<ChartResource.Candles.CandleFromServer[]>(
    '/v3/rates/chart',
    {
      params: {
        type: 'candle',
        product: args.product,
        fromTicker: args.fromTicker,
        toTicker: args.toTicker,
        tick: args.tick,
        points: args.candlesPoints,
        toDate,
        mode: args.mode ?? 'mid',
      },
    }
  ).then(response => ({
    type: 'candle',
    data: response.data.map(item => ({
      date: new Date(item.date),
      close: item.close,
      high: item.high,
      low: item.low,
      open: item.open,
    })),
  }));
};

export const getData = (
  args: ChartResource.Args
): Promise<ChartResource.Data> => {
  if (args.type === 'line') {
    return getLineData(args);
  }

  if (args.type === 'candle') {
    return getCandlesData(args);
  }

  throw new Error(`Type ${args.type} is not supported`);
};
