import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const FailedContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.26 2.954a7.4 7.4 0 10-4.52 14.092 7.4 7.4 0 004.52-14.092zm-2.885-.528a7.6 7.6 0 111.25 15.148 7.6 7.6 0 01-1.25-15.148zM16.4 10a6.4 6.4 0 11-12.8 0 6.4 6.4 0 0112.8 0zm-9.083 2.117L9.434 10 7.317 7.883l.566-.566L10 9.434l2.117-2.117.566.566L10.566 10l2.117 2.117-.566.566L10 10.566l-2.117 2.117-.566-.566z"
          fill={color}
        />
      </Svg>
    );
  }
);
