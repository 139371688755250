import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ChartCandlesIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M15.75 3a.75.75 0 00-.75.75v7.5a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75zm-12 5a.75.75 0 00-.75.75v7.5a.75.75 0 001.5 0v-7.5A.75.75 0 003.75 8zm7.25.75a.75.75 0 011.5 0v5.5a.75.75 0 01-1.5 0v-5.5zM7.75 5a.75.75 0 00-.75.75v5.5a.75.75 0 001.5 0v-5.5A.75.75 0 007.75 5z"
          fill={color}
        />
      </Svg>
    );
  }
);
