import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Label} from '@youtoken/ui.form-elements';
import {Icon} from '@youtoken/ui.icons';

interface OpenHistorySectionProps {
  id: string;
}

export const OpenHistorySection: React.FC<OpenHistorySectionProps> = ({id}) => {
  const {t} = useTranslation();

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('HodlHistory', {
      id,
    });
  }, [id]);

  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      height={64}
      px={24}
      onPress={handlePress}
      testID={`HODL_OPEN_HISTORY_${id}`}
    >
      <Box flexDirection="row" flexGrow={1} flexShrink={1} mr={20}>
        <Icon name="history" />
        <Label ml={8} mb={0}>
          {t('surface.hodls.item.history_button_label')}
        </Label>
      </Box>
      <Icon name="chevron_right" />
    </TouchableBox>
  );
};
