import * as React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {Box, Text} from '@youtoken/ui.primitives';

export const ResizingHintComponent: React.FC = React.memo(() => {
  return (
    <Box
      position="absolute"
      flex={1}
      width="100%"
      height="100%"
      backgroundColor="$ui-background"
      justifyContent="center"
      alignItems="center"
      opacity={0.7}
    >
      <Icon name="spinner" />
      <Text variant="$body-03-medium-accent">Resizing...</Text>
    </Box>
  );
});
