import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {EmailTextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage, useForm, Label} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {type SignInForm} from '../state';

export const EmailField: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {emailValue, hasEmailError, emailError, onEmailChange},
  } = useForm<SignInForm>();

  return (
    <Box {...boxProps}>
      <Label mb={8}>{t('forms.sign_in.fields.email.label')}</Label>

      <Box>
        <EmailTextInput
          size="large"
          value={emailValue}
          hasError={hasEmailError}
          onChangeText={onEmailChange}
          testID="EMAIL_INPUT"
        />
        <FieldErrorMessage visible={hasEmailError} placement="left">
          {getTranslatedValidationMessage(emailError)}
        </FieldErrorMessage>
      </Box>
    </Box>
  );
});
