import * as React from 'react';
import {Text, Box, Heading} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {useTranslation, i18n} from '@youtoken/ui.service-i18n';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {EmailVerificationOnSignupForm} from './EmailVerificationOnSignupForm';
import {type EmailVerificationOnSignupSurfaceProps} from './types';

export const EmailVerificationOnSignupSurface: React.FC<
  EmailVerificationOnSignupSurfaceProps
> = ({operationId, onSubmit}) => {
  const {t} = useTranslation();

  return (
    <>
      <Text variant="$body-01" color="$text-02" mb={25} mt={5}>
        {t('surface.sign_in_confirm.subtitle_email')}
      </Text>
      <EmailVerificationOnSignupForm
        operationId={operationId}
        onSubmit={onSubmit}
      />
    </>
  );
};

export const EmailVerificationOnSignupSurfaceCell = cell(
  EmailVerificationOnSignupSurface,
  {
    displayName: 'EmailVerificationOnSignupSurface',
    CellWrap: ({children}) => (
      <WebPageWrapper
        windowTitle={i18n.t('surface.verify_email.title')}
        title={i18n.t('surface.verify_email.title')}
      >
        <Box flex={1} mt={-30}>
          {children}
        </Box>
      </WebPageWrapper>
    ),
  }
) as React.FC<{}> & {getTitle: () => string};
