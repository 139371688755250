import {computed} from 'mobx';
import {
  HodlHistoryResource,
  HodlLoanChainResource,
} from '@youtoken/ui.resource-hodl';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {type IAbstractHowHODLWorks} from '@youtoken/ui.hodls-utils';

interface HodlHistoryDetailFeatureArgs {
  hodlHistoryItemId?: string;
  hodlId?: string;
}

export class HodlHistoryDetailFeature extends createFeature({
  getKey: (args: HodlHistoryDetailFeatureArgs) =>
    `feature:hodl_history_detail:${JSON.stringify(args)}`,
  getResources: ({hodlHistoryItemId, hodlId}) => {
    return {
      history: getResourceDescriptor(HodlHistoryResource, {hodlId}),
      loanChain: getResourceDescriptor(HodlLoanChainResource, {
        hodlHistoryItemId,
        hodlId,
      }),
    };
  },
}) {
  @computed get historyItem() {
    return this.resources.history.data.find(el => {
      return el.id === this.args.hodlHistoryItemId;
    });
  }

  @computed.struct
  get historyDetail(): IAbstractHowHODLWorks {
    const hasConversion = Boolean(
      this.historyItem &&
        this.historyItem?.collateralTickerFormatted !==
          this.historyItem?.inputTickerFormatted &&
        this.resources.loanChain.loansSequence?.[0]
    );

    const {
      inputAmount,
      inputAmountFormatted,
      inputTickerFormatted,
      collateralAmountFormatted,
      collateralTickerFormatted,
      borrowedAmountFormatted,
      borrowedTickerFormatted,
      settlementFeePercentFormatted,
      settlementPeriodFormatted,
      settlementFeeAmountFormatted,
      settlementFeeTickerFormatted,
    } = this.historyItem!;

    return {
      loansSequence: this.resources.loanChain.loansSequence,
      isInputAmountPositive: this.historyItem ? inputAmount > 0 : false,
      inputAmountFormatted,
      inputTickerFormatted,
      hasConversion,
      totalCollateralAmountFormatted: collateralAmountFormatted,
      totalCollateralTickerFormatted: collateralTickerFormatted,
      totalBorrowedAmountFormatted: borrowedAmountFormatted,
      totalBorrowedTickerFormatted: borrowedTickerFormatted,
      settlementPeriodFormatted,
      settlementFeePercentFormatted,
      rolloverAmountFormatted: settlementFeeAmountFormatted,
      rolloverTickerFormatted: settlementFeeTickerFormatted,
      repaymentDueAmountFormatted: formatByTicker(
        this.resources.loanChain.data.totalRepayment,
        borrowedTickerFormatted.toLowerCase()
      ),
      repaymentDueTickerFormatted: borrowedTickerFormatted,
    };
  }
}
