import * as React from 'react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TurbochargePreview} from '@youtoken/ui.video-preview';

export const Empty: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Box justifyContent="center" alignItems="center" p={20}>
      <Box
        width={{
          default: '100%',
          tablet: 400,
        }}
        mb={20}
      >
        <Heading variant="$heading-01" textAlign="center" mb={5}>
          {t('surface.loans.turbo_empty')}
        </Heading>
        <Text textAlign="center" mb={15}>
          {t('surface.loans.turbo_empty_description')}
        </Text>
      </Box>
      <TurbochargePreview />
    </Box>
  );
};
