import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Row} from './Row';
import {Item} from './Item';

type PrizeTableProps = {};

export const PrizeTable: React.FC<PrizeTableProps & BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box
        borderColor="$interactive-03"
        borderWidth={1}
        borderRadius={8}
        overflow="hidden"
        {...boxProps}
      >
        <Row backgroundColor="$interactive-02">
          <Item
            text={t(
              'surface.bank_cards.landing.learn_more.table.heading.drop_sum'
            )}
            color="$text-05"
          />
          <Item
            text={t(
              'surface.bank_cards.landing.learn_more.table.heading.winners'
            )}
            color="$text-05"
          />
          <Item
            text={t(
              'surface.bank_cards.landing.learn_more.table.heading.total'
            )}
            color="$text-05"
          />
        </Row>

        <Row>
          <Item text="1000€" />
          <Item text="1" />
          <Item text="1000€" />
        </Row>

        <Row>
          <Item text="500€" />
          <Item text="5" />
          <Item text="2500€" />
        </Row>

        <Row>
          <Item text="50€" />
          <Item text="50" />
          <Item text="2500€" />
        </Row>

        <Row>
          <Item text="15€" />
          <Item text="100" />
          <Item text="1500€" />
        </Row>

        <Row>
          <Item text="5€" />
          <Item text="500" />
          <Item text="2500€" />
        </Row>
      </Box>
    );
  }
);
