import * as React from 'react';
import {LOCAL_MODAL_NOTIFICATIONS} from '@youtoken/ui.local-modal-notifications';
import {ModalNotificationSmart} from '@youtoken/ui.notifications';

export interface NotificationModalProps {
  request: (notificationId: string) => void;
}

export const NotificationModal = React.forwardRef<NotificationModalProps, {}>(
  (_props, ref) => {
    const [notificationId, setNotificationId] = React.useState<
      string | undefined
    >();

    const request = React.useCallback((notificationId: string) => {
      setNotificationId(notificationId);
    }, []);

    const handleClose = React.useCallback(() => {
      setNotificationId(undefined);
    }, []);

    React.useImperativeHandle(
      ref,
      () => ({
        request,
      }),
      []
    );

    React.useEffect(() => {
      LOCAL_MODAL_NOTIFICATIONS.provideHandler(request);
    }, []);

    if (!notificationId) {
      return null;
    }

    return (
      <ModalNotificationSmart
        notificationId={notificationId}
        onClose={handleClose}
      />
    );
  }
);
