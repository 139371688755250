import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const QrIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M5.5 5a.5.5 0 00-.5.5V6a.5.5 0 00.5.5H6a.5.5 0 00.5-.5v-.5A.5.5 0 006 5h-.5z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2a2 2 0 00-2 2v3.5a2 2 0 002 2h3.5a2 2 0 002-2V4a2 2 0 00-2-2H4zm3.5 1.5H4a.5.5 0 00-.5.5v3.5A.5.5 0 004 8h3.5a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5z"
          fill={color}
        />
        <Path
          d="M5.5 13.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5H6a.5.5 0 00.5-.5V14a.5.5 0 00-.5-.5h-.5z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10.5a2 2 0 00-2 2V16a2 2 0 002 2h3.5a2 2 0 002-2v-3.5a2 2 0 00-2-2H4zM7.5 12H4a.5.5 0 00-.5.5V16a.5.5 0 00.5.5h3.5A.5.5 0 008 16v-3.5a.5.5 0 00-.5-.5z"
          fill={color}
        />
        <Path
          d="M12.25 12.5a.25.25 0 01.25-.25h4a.75.75 0 000-1.5h-4a1.75 1.75 0 00-1.75 1.75V16c0 .966.784 1.75 1.75 1.75h1c.69 0 1.25-.56 1.25-1.25v-1.25h1.5v1.25a.75.75 0 001.5 0V15c0-.69-.56-1.25-1.25-1.25h-2c-.69 0-1.25.56-1.25 1.25v1.25h-.75a.25.25 0 01-.25-.25v-3.5zm1.25-7A.5.5 0 0114 5h.5a.5.5 0 01.5.5V6a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-.5z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 2a2 2 0 00-2 2v3.5a2 2 0 002 2H16a2 2 0 002-2V4a2 2 0 00-2-2h-3.5zM16 3.5h-3.5a.5.5 0 00-.5.5v3.5a.5.5 0 00.5.5H16a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
