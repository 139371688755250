import * as React from 'react';
import {observer} from 'mobx-react';
import {AppEnv} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {GLOBAL} from '@youtoken/ui.service-global';
import {bootIntercom} from '@web-app/src/utils';
import {initIntercom} from './init';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

const getIntercomAppId = (env: AppEnv): string => {
  return env === AppEnv.production ? 'lbmthtqx' : 'mtikkbiu';
};

export const IntercomInitializer: React.FC = observer(() => {
  const appId = getIntercomAppId(ENVIRONMENT.APP_ENV);

  const {isAuthorized} = GLOBAL;

  const {pathname} = RouterResource.use({});

  const needIntercom = React.useMemo(() => {
    return (
      isAuthorized ||
      ['/restore-password', '/sign-in-confirm', '/new-password'].includes(
        pathname
      )
    );
  }, [isAuthorized, pathname]);

  React.useEffect(() => {
    if (needIntercom) {
      if (!isAuthorized) {
        initIntercom(appId);
        const interval = setInterval(() => {
          if (window.Intercom) {
            bootIntercom({
              appId,
            });
            clearInterval(interval);
          }
        });
      }
    } else {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    }
  }, [needIntercom]);

  return null;
});
