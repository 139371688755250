import React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BankWireFeature} from '../../../../states/BankWireFeature';
import {Item} from './components/Item';

interface DepositVerificationsProps {
  ticker: string;
}

export const DepositVerifications: React.FC<DepositVerificationsProps> =
  observer(({ticker}) => {
    const {tickerFormatted} = BankWireFeature.use({ticker});
    const {t} = useTranslation();

    return (
      <Box px={24} py={20} backgroundColor="$fill-01">
        <Box mb={16}>
          <Text weight="bold">
            {t('surface.bank_wire.deposit_verifications.header')}
          </Text>
        </Box>
        <Box mb={16}>
          <Item
            title={t(
              'surface.wallets.fiat_deposit_wire.verifications.video_call_title'
            )}
            condition={t(
              'surface.wallets.fiat_deposit_wire.verifications.condition',
              {amount: '5,000', ticker: tickerFormatted}
            )}
            icon="video"
          />
        </Box>
        <Box mb={16}>
          <Item
            title={t(
              'surface.bank_wire.deposit_verifications.source_of_funds.title'
            )}
            condition={t(
              'surface.wallets.fiat_deposit_wire.verifications.condition',
              {amount: '15,000', ticker: tickerFormatted}
            )}
            icon="doc_address"
          />
        </Box>
        <Text>
          {t(
            'surface.wallets.fiat_deposit_wire.verifications.asterisk_description'
          )}
        </Text>
      </Box>
    );
  });
