import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const MinerBlockCostIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <Path
          d="M5.288 5.408l4.8-2.7a3.9 3.9 0 013.824 0l4.8 2.7A3.9 3.9 0 0120.7 8.807v6.385a3.9 3.9 0 01-1.988 3.4l-4.8 2.7a3.9 3.9 0 01-3.824 0l-4.8-2.7a3.9 3.9 0 01-1.988-3.4V8.807a3.9 3.9 0 011.988-3.4z"
          stroke={color}
          strokeWidth={1.8}
          strokeLinejoin="round"
        />
        <Path
          d="M10.894 18.6a.487.487 0 01-.48-.568l.626-3.681a.6.6 0 00-.591-.7H8.212c-.21 0-.34-.056-.39-.166-.049-.11-.017-.26.095-.453L12.7 5.623a.487.487 0 01.889.346l-.626 3.68a.6.6 0 00.591.701h2.236c.21 0 .34.055.39.165.049.11.017.262-.095.454l-4.781 7.409a.487.487 0 01-.41.222z"
          fill={color}
        />
      </Svg>
    );
  }
);
