import React from 'react';
import {observer} from 'mobx-react';
import {PromoBanner} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface IBANBannerProps {
  onPress: () => void;
  isIBANAccountExist: boolean;
}

export const IBANBanner: React.FC<IBANBannerProps> = observer(
  ({onPress, isIBANAccountExist}) => {
    const {t} = useTranslation();

    if (isIBANAccountExist) {
      return (
        <PromoBanner
          mx={24}
          mt={24}
          imageName="ibanOpenAccount"
          testID="FIAT_DEPOSIT_BANK_WIRE_IBAN_PENDING_BANNER"
          secondaryText={t('surface.bank_wire.wirex.account.banner.title')}
          text={t('surface.bank_wire.wirex.account.banner.description')}
        />
      );
    }

    return (
      <PromoBanner
        mx={24}
        mt={24}
        imageName="ibanOpenAccount"
        onPressBanner={onPress}
        testID="FIAT_DEPOSIT_BANK_WIRE_IBAN_BANNER"
        text={t('surface.bank_wire.wirex.open_account.banner_title')}
      />
    );
  }
);
