import {i18n} from '@youtoken/ui.service-i18n';
import {ValidationMessageLocalized} from './ValidationMessageLocalized';

export const getTranslatedValidationMessage = (
  error: string | ValidationMessageLocalized | null,
  t: typeof i18n.t = i18n.t
): string => {
  if (!error) {
    return '';
  }

  if (typeof error === 'string') {
    return error;
  }

  if (!error.i18n?.label) {
    return error?.fallback;
  }

  return (
    t(`validation.${error.i18n.label}` as any, {
      ...error.i18n.params,
      defaultValue: error.i18n.fallback,
    }) ||
    error?.fallback ||
    ''
  );
};
