import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useResource} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {openQuizByLink} from '@youtoken/ui.surface-hodl-positions';
import {Icon} from '@youtoken/ui.icons';
import {MenuItemBase} from './MenuItemBase';

export const TraderQuiz: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {accountId} = useResource(AuthMeResource, {});

  const handlePress = React.useCallback(() => {
    openQuizByLink(accountId, 'profile');
  }, [accountId]);

  return (
    <MenuItemBase
      title={t('surface.profile.account.quiz.action')}
      icon="sign"
      onPress={handlePress}
      RightPartComponentWeb={
        <Icon name="chevron_right" size={20} color="$text-02" />
      }
      {...boxProps}
    />
  );
});
