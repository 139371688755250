import React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '../Form';

export const SubmitButton: React.FC<React.ComponentProps<typeof Button>> =
  observer(({disabled, loading, testID = 'SUBMIT_BUTTON', ...props}) => {
    const {
      form: {instance},
    } = useForm();

    return (
      <Button
        disabled={disabled || instance.submitting}
        loading={loading || instance.submitting}
        onPress={instance.onSubmit}
        size="large"
        testID={testID}
        {...props}
      />
    );
  });
