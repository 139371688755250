import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Heading} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {SavingsSignAgreementsFormState} from '../state';

export const SavingsSignAgreementsFormHeader: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {
      resources: {
        authMe: {hasSignedSavings},
      },
    } = useForm<SavingsSignAgreementsFormState>();

    return (
      <Box p={{default: 16, phone: 24}} {...boxProps}>
        <Heading textAlign="center">
          {hasSignedSavings
            ? t('surface.forms.sign_savings_agreements.title')
            : t('surface.forms.sign_first_time_savings_agreements.title')}
        </Heading>
      </Box>
    );
  }
);
