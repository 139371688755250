import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const GetLoanIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.078 8.35l5.13 1.69a2.7 2.7 0 011.849 2.764l-.045.596 5.468-1.148a3 3 0 013.406 1.832l.076.216a2.764 2.764 0 01-1.74 3.477l-9.045 3.063a3 3 0 01-1.556.099L3 19.199v.101a1 1 0 11-2 0v-10a1 1 0 012 0v.385l2.978-1.25a3 3 0 012.1-.084zm.209 2.008a3 3 0 00-1.914.006l-.204.077-2.819 1.193a.998.998 0 01-.35.078v5.594a1.2 1.2 0 01.374-.024l.128.018 8.118 1.638a3 3 0 001.341-.035l.217-.065 8.602-2.921a.9.9 0 00.532-1.217 1.19 1.19 0 00-1.23-.698l-.129.022-7.648 1.79a3 3 0 01-1.35.005l-.22-.06-3.842-1.187a1 1 0 01-.68-1.172l.012-.05a.869.869 0 01.967-.62l.107.023 3.754 1.072a.997.997 0 001.21-.725 1.113 1.113 0 00-.614-1.28l-.114-.046-4.248-1.416zM17.512 1a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
          fill={color}
        />
      </Svg>
    );
  }
);
