import * as React from 'react';
import {
  type NativeSyntheticEvent,
  type TextInputKeyPressEventData,
} from 'react-native';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage} from '@youtoken/ui.form-elements';
import {Button} from '@youtoken/ui.buttons';
import type {FormContentProps} from '../types';

export const FormContent: React.FC<FormContentProps> = ({
  value,
  errorText,
  onChange,
  onSubmit,
  isLoading,
}) => {
  const {t} = useTranslation();

  const handleKeyPress = React.useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (event.nativeEvent.key === 'Enter') {
        // @ts-ignore not react native way but works in .web file
        onSubmit(event.target.value);
      }
      // @ts-ignore not react native way but works in .web file
      onChange(event.target.value);
    },
    [onChange, onSubmit]
  );

  return (
    <>
      <TextInput
        autoFocus
        size="medium"
        placeholder={t('surface.2fa_form.code_placeholder')}
        value={value}
        onChangeText={onChange}
        hasError={Boolean(errorText)}
        maxLength={6}
        keyboardType="number-pad"
        onKeyPress={handleKeyPress}
      />
      <FieldErrorMessage visible={Boolean(errorText)} placement="left">
        {errorText}
      </FieldErrorMessage>
      <Button mt={24} mb={24} onPress={onSubmit} loading={isLoading}>
        {t('surface.sign_in_confirm.continue')}
      </Button>
    </>
  );
};
