import {
  captureException,
  captureMessage,
  addBreadcrumb,
  configureScope,
  startTransaction,
  getCurrentHub,
} from '@sentry/core';
import {Breadcrumb, Transaction} from '@sentry/types';
import {CustomError} from '@youtoken/ui.errors';
import EventEmitter from 'eventemitter3';
import {enhanceCapture, CaptureScope} from './capture';
import {setUser, resetUser, YouHodlerUserLikeModel} from './userManagement';

/** Sentry service for frontend apps; */
export class SentryService {
  public events = new EventEmitter<
    | 'capture'
    | 'setUserContext'
    | 'captureMessage'
    | 'addBreadcrumb'
    | 'setScreen'
    | 'setPage'
    | 'transaction'
  >();

  /** reaction to user context, sets or resets users */
  public setUserContext = <U extends YouHodlerUserLikeModel>(
    user?: U | null
  ) => {
    this.events.emit('setUserContext', user);
    user ? setUser(user) : resetUser();
  };

  /** capture an error with typed params
   * if error is `CustomError` reporting will be enhanced with `__type` and `__sentry`
   */
  public capture = (error: Error | CustomError, scope?: CaptureScope) => {
    const context = enhanceCapture(error, scope);
    this.events.emit('capture', error, context);

    let eventId;

    eventId = captureException(error, context);

    return {eventId};
  };

  /**  raw **`captureException`** from sentry
   *
   *  just to use single point of error capturing,
   *
   *  does literally nothing
   */
  public captureException = (
    exception: any,
    hint?: Parameters<typeof captureException>[1]
  ): string => {
    return captureException(exception, hint);
  };

  /** capture message with typed params */
  public captureMessage = (message: string, scope?: CaptureScope) => {
    const context = enhanceCapture(message, scope);
    this.events.emit('captureMessage', message, context);
    return {eventId: captureMessage(message, context)};
  };

  public captureComponentError = (error: Error, componentStack: any) => {
    return this.capture(error, {
      extra: {componentStack},
      source: 'ErrorBoundary',
    });
  };

  /** add breadcrumb */
  public addBreadCrumb = (breadcrumb: Breadcrumb) => {
    this.events.emit('addBreadcrumb', breadcrumb);
    addBreadcrumb(breadcrumb);
  };

  /** @platform native */
  public setScreen = (screenName: string) => {
    this.events.emit('setScreen', screenName);
    configureScope(scope => {
      scope.setTag('screen', screenName);
    });
  };

  /** @platform web */
  public setPage = (page: string) => {
    this.events.emit('setPage', page);
    configureScope(scope => {
      scope.setTag('page', page);
    });
  };

  // https://docs.sentry.io/platforms/react-native/performance/instrumentation/custom-instrumentation/
  public transaction = (
    name: string,
    params: Omit<Parameters<typeof startTransaction>[0], 'name'> = {}
  ) => {
    this.events.emit('transaction', name, params);
    return startTransaction({
      name: name,
      ...params,
    });
  };

  // set transaction to scope
  public setTransactionToScope = (transaction: Transaction) => {
    getCurrentHub().configureScope(scope => scope.setSpan(transaction));
  };
}
