import {matchSorter} from 'match-sorter';
import {WalletComboboxItem, WalletComboboxGroup} from '../types';

export const filterWalletsByInput = (
  items: WalletComboboxItem[],
  inputValue?: string | null
) => {
  if (!inputValue) {
    return items;
  }

  return matchSorter(items, inputValue, {
    keys: ['ticker', 'coinName'],
  });
};

export const filterWalletGroupsByInput = (
  items: WalletComboboxGroup[],
  inputValue?: string | null
): WalletComboboxGroup[] => {
  if (!inputValue) {
    return items;
  }

  return items
    .map(item => {
      const filteredWallets = matchSorter(item.values, inputValue, {
        keys: ['ticker', 'coinName'],
      });
      return {
        name: item.name,
        values: filteredWallets,
      };
    })
    .filter(item => item.values.length > 0);
};
