import * as React from 'react';
import {
  Text,
  TouchableBox,
  Box,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {Icon, LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';

export const TickerDropdownToggle: React.FC<
  TouchableBoxProps & {ticker: string}
> = ({onPress, ticker, ...touchableBoxProps}) => {
  return (
    <TouchableBox
      flexDirection="row"
      alignItems="center"
      height="100%"
      px={16}
      onPress={onPress}
      {...touchableBoxProps}
    >
      <Box flexDirection="row" alignItems="center">
        <LogoColored name={ticker as LogoColoredIconName} size={20} />
        <Text ml={8} mr={2} variant="$body-01-medium-accent" color="$text-01">
          {ticker.toUpperCase()}
        </Text>
      </Box>
      {!touchableBoxProps.disabled && (
        <Icon name="expand" size={20} color="$text-01" />
      )}
    </TouchableBox>
  );
};
