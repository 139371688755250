import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryV3Resource} from '@youtoken/ui.resource-rewards-v3-history';
import {RewardsHistoryViewV3} from '../../../..';
import {RewardsHistorySmartProps} from '../../types';

export const RewardsHistoryViewV3Smart: React.FC<RewardsHistorySmartProps> =
  observer(({showHeader, limit}) => {
    const {
      data: {items},
    } = RewardsHistoryV3Resource.use({limit});

    if (items.length === 0) {
      return null;
    }

    return <RewardsHistoryViewV3 items={items} showHeader={showHeader} />;
  });
