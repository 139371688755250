import * as React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {type IRadioItem, type TRadioValue} from '../../types';

interface ItemProps {
  item: IRadioItem;
  checked: boolean;
  disabled: boolean;
  onChange: (value: TRadioValue) => void;
}

export const Item: React.FC<ItemProps> = ({
  item,
  checked,
  disabled,
  onChange,
}) => {
  const handleChange = React.useCallback(() => {
    if (!disabled) {
      onChange(item.value);
    }
  }, [disabled, item.value, onChange]);

  return (
    <TouchableBox
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      mb={10}
      onPress={handleChange}
      testID={item.testID}
    >
      <Box
        width={20}
        height={20}
        borderWidth={checked ? 5 : 2}
        borderRadius={10}
        borderColor={
          disabled ? '$ui-02' : checked ? '$interactive-01' : '$ui-04'
        }
        backgroundColor="$ui-background"
      />
      <Text variant="$body-02" ml={10}>
        {item.label}
      </Text>
    </TouchableBox>
  );
};
