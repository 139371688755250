import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';

const handlePress = () => {
  window.open('https://app.youhodler.com', '_blank', 'noopener,noreferrer');
};

export const GoFullVersionButton: React.FC = observer(() => {
  const {t} = useTranslation();

  // NOTE: other checks can be added
  if (ENVIRONMENT.WEB_APP_ENV !== WebAppEnv['ledger-app']) {
    return null;
  }

  return (
    <Box
      alignSelf="center"
      flexGrow={{default: 1, tablet: 0}}
      width="100%"
      mb={24}
    >
      <Button size="large" onPress={handlePress}>
        {t('surface.ledger_app_not_found.go_to_full_version')}
      </Button>
    </Box>
  );
});
