import EventEmitter from 'eventemitter3';
import {invariant, __global__} from '@youtoken/ui.utils';
import {
  AppEnv,
  WebAppEnv,
  getBackendUrl,
  getSocketUrl,
  getDisplayUrl,
  getWebAppUrl,
  getWeb3NodeUrl,
} from '@youtoken/ui.env-utils';

declare global {
  interface __GLOBAL__ {
    __ENV__?: {[key: string]: any};
  }
}

/** write variable to global scope, just in case
 *
 * `write('dev', false)` same as  `window.__ENV_.dev = false` etc
 */
const writeEnvToGlobal = (name: string, value: any) => {
  if (!__global__.__ENV__) {
    __global__.__ENV__ = {};
  }

  __global__.__ENV__[name] = value;
};

export class EnvironmentService {
  public events = new EventEmitter<'set'>();

  private _DEV: boolean = false;
  private _APP_ENV!: AppEnv;
  private _WEB_APP_ENV!: WebAppEnv;
  private _DISPLAY_URL!: string;
  private _WEB_APP_URL!: string;
  private _BACKEND_URL!: string;
  private _SOCKET_URL!: string;
  private _WEB3_NODE_URL!: string;
  private _APPLE_PAY_MERCHANT_ID!: string;
  private _AUTH_GOOGLE_CLIENT_ID!: string;
  private _AUTH_GOOGLE_CLIENT_ID_IOS!: string;

  constructor() {
    this._DEV = false;
    writeEnvToGlobal('DEV', false);
  }

  /** setter util
   * 1. emits event for debug;
   * 2. sets local property this._NAME
   * 3. write new env to global object;
   */
  private _set(name: string, value: any) {
    // @ts-ignore
    this[`_${name}`] = value;
    writeEnvToGlobal(name, value);
    this.events.emit('set', name, value);
  }

  // private _get(name: string) {
  //   return this[`_${name}`];
  // }

  public get WEB_APP_ENV() {
    return this._WEB_APP_ENV;
  }

  public set WEB_APP_ENV(name: WebAppEnv) {
    this._set('WEB_APP_ENV', name);
  }

  public get APPLE_PAY_MERCHANT_ID() {
    return this._APPLE_PAY_MERCHANT_ID;
  }

  public set APPLE_PAY_MERCHANT_ID(merchantId: string) {
    this._set('APPLE_PAY_MERCHANT_ID', merchantId);
  }

  public get DEV() {
    return this._DEV;
  }

  public set DEV(value: boolean) {
    this._set('DEV', value);
  }

  public get AUTH_GOOGLE_CLIENT_ID() {
    return this._AUTH_GOOGLE_CLIENT_ID;
  }

  public set AUTH_GOOGLE_CLIENT_ID(clientId: string) {
    this._set('AUTH_GOOGLE_CLIENT_ID', clientId);
  }

  public get AUTH_GOOGLE_CLIENT_ID_IOS() {
    return this._AUTH_GOOGLE_CLIENT_ID_IOS;
  }

  public set AUTH_GOOGLE_CLIENT_ID_IOS(clientId: string) {
    this._set('AUTH_GOOGLE_CLIENT_ID_IOS', clientId);
  }

  public get APP_ENV(): AppEnv {
    return this._APP_ENV;
  }

  public set APP_ENV(value: AppEnv) {
    this._set('APP_ENV', value);
    this._set('BACKEND_URL', getBackendUrl(value));
    this._set('SOCKET_URL', getSocketUrl(value));
    this._set('DISPLAY_URL', getDisplayUrl(value));
    this._set('WEB_APP_URL', getWebAppUrl(value));
    this._set('WEB3_NODE_URL', getWeb3NodeUrl(value));
  }

  public get DISPLAY_URL() {
    invariant(
      this._DISPLAY_URL,
      'cannot access "BACKEND_URL", "APP_ENV" was not set!'
    );
    return this._DISPLAY_URL;
  }

  public set DISPLAY_URL(_value: string) {
    invariant(
      false,
      'cannot set "DISPLAY_URL" directly, only possible by setting "APP_ENV"!'
    );
  }

  public get WEB_APP_URL() {
    invariant(
      this._WEB_APP_URL,
      'cannot access "WEB_APP_URL", "APP_ENV" was not set!'
    );
    return this._WEB_APP_URL;
  }

  public set WEB_APP_URL(_value: string) {
    invariant(
      false,
      'cannot set "WEB_APP_URL" directly, only possible by setting "APP_ENV"!'
    );
  }

  public get BACKEND_URL() {
    invariant(
      this._BACKEND_URL,
      'cannot access "BACKEND_URL", "APP_ENV" was not set!'
    );
    return this._BACKEND_URL;
  }

  public set BACKEND_URL(_value: string) {
    invariant(
      false,
      'cannot set "BACKEND_URL" directly, only possible by setting "APP_ENV"!'
    );
  }

  public get SOCKET_URL() {
    invariant(
      this._SOCKET_URL,
      'cannot access "SOCKET_URL", "APP_ENV" was not set!'
    );
    return this._SOCKET_URL;
  }

  public set SOCKET_URL(_value: string) {
    invariant(
      false,
      'cannot set "SOCKET_URL" directly, only possible by setting "APP_ENV"!'
    );
  }

  public get WEB3_NODE_URL() {
    invariant(
      this._WEB3_NODE_URL,
      'cannot access "WEB3_NODE_URL", "APP_ENV" was not set!'
    );
    return this._WEB3_NODE_URL;
  }

  public set WEB3_NODE_URL(_value: string) {
    invariant(
      false,
      'cannot set "WEB3_NODE_URL" directly, only possible by setting "APP_ENV"!'
    );
  }
}
