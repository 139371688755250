import {
  BaseHODLChartState,
  BaseHODLChartStateProps,
} from './BaseHODLChartState';
import {observable} from 'mobx';

export interface HODLPreviewChartStateProps extends BaseHODLChartStateProps {}

export class HODLPreviewChartState extends BaseHODLChartState {
  @observable domainPaddingTop = 8;
  @observable domainPaddingBottom = 8;

  constructor(props: HODLPreviewChartStateProps) {
    super(props);
  }
}
