import {getGlobal} from '@youtoken/ui.utils';
import {ServiceRegistry} from './ServiceRegistry';

declare global {
  interface __GLOBAL__ {
    __SERVICE_REGISTRY__?: ServiceRegistry;
  }
}

/** returns global service-registry (creates empty object if needed) */
export const getServiceRegistry = () => {
  const global = getGlobal();

  if (!global.__SERVICE_REGISTRY__) {
    global.__SERVICE_REGISTRY__ = new ServiceRegistry();
  }

  return global.__SERVICE_REGISTRY__;
};
