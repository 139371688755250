import React from 'react';
import {
  InsideModalContext,
  ModalOpenedContext,
} from '@web-app/src/components/layout/Shell/AppShell/Modals';

// Этот хук должен быть в observer компоненте
// т.к. читается modal.opened
// можно попробовать вынести modal.opened в контекст
// вынес в контекст, теперь не должен быть в observed компоненте

// TODO добавить сюда навигацию(урл, хеш урла)
// в текущем варианте это не обязательно, т.к. рейты(и весь лейаут) рендерится заново

// effect обязательно должен быть замемойзен React.useCallback
// либо не передавать effect в useEffect зависимости - плохо

// useFocusEffect на нейтиве имеет непредсказуемый порядок
// на примере welcome & signup скринов
/**
LOG  onFocus Welcome screen - открыли приложение
LOG  onFocus SignUp screen - перешли на sign up
LOG  onBlur Welcome screen - перешли на sign up
LOG  onBlur SignUp screen - перешли на welcome
LOG  onFocus Welcome screen - перешли на welcome

в переходе Welcome -> SignUp
первым получает фоксус тот скрин куда переходим, потом blur того скрина с которого ушли

в переходе SignUp -> Welcome
порядок обратный(должен быть onFocus Welcome, onBlur SignUp)
 */
export const useFocusEffect = (effect = () => {}) => {
  const modalOpened = React.useContext(ModalOpenedContext);
  const insideModal = React.useContext(InsideModalContext);
  return React.useEffect(() => {
    // если модалка закрыта и хук вызван не в модалке
    // если модалка открыта и хук вызван в модалке
    // т.е. если хук в "фокусе"
    if (modalOpened === insideModal) {
      return effect();
    }
  }, [insideModal, modalOpened, effect]);
};
