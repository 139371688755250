import {serializable, optional, raw, primitive, list, object} from 'serializr';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {Big} from 'big.js';

class Loan {
  @serializable(bigNumber())
  borrowed!: Big;

  @serializable(bigNumber())
  collateral!: Big;

  @serializable(bigNumber())
  settlementFee!: Big;

  @serializable(primitive())
  settlementPeriod!: string;
}

class LoanObj {
  @serializable(bigNumber())
  ltv!: Big;

  @serializable(bigNumber())
  totalCollateral!: Big;

  @serializable(bigNumber())
  totalRepayment!: Big;

  @serializable(list(object(Loan)))
  loans: Loan[] = [];
}

export class CalculatedDataResponse {
  @serializable(primitive())
  isShort!: boolean;

  @serializable(bigNumber())
  apr!: Big;

  @serializable(bigNumber())
  borrowedAmount!: Big;

  @serializable(bigNumber())
  initialPrice!: Big;

  @serializable(bigNumber())
  inputAmount!: Big;

  @serializable(bigNumber())
  inputCollateralAmount!: Big;

  @serializable(primitive())
  borrowedTicker!: string;

  @serializable(primitive())
  collateralTicker!: string;

  @serializable(primitive())
  inputTicker!: string;

  @serializable(primitive())
  quoteTicker!: string;

  @serializable(primitive())
  baseTicker!: string;

  @serializable(raw())
  loans: any[] = [];

  @serializable(bigNumber())
  mcPrice!: Big;

  @serializable(bigNumber())
  slPrice!: Big;

  @serializable(bigNumber())
  tpPrice!: Big;

  @serializable(bigNumber())
  ftpPrice!: Big;

  @serializable(bigNumber())
  openingFee!: Big;

  @serializable(bigNumber())
  openingFeeAmount!: Big;

  @serializable(bigNumber())
  settlementFee!: Big;

  @serializable(bigNumber())
  settlementFeeAmount!: Big;

  @serializable(primitive())
  settlementPeriod!: string;

  @serializable(bigNumber())
  totalAmount!: Big;

  @serializable(bigNumber())
  maxLoss!: Big;

  @serializable(bigNumber())
  maxProfit: null | Big = null;

  @serializable(object(LoanObj))
  loan!: LoanObj;

  @serializable(bigNumber())
  mcFee!: Big;

  @serializable(optional(number()))
  volumeForecast?: number; // NOTE: When error is undefined

  @serializable(optional(number()))
  sparksAmount?: number; // NOTE: When error is undefined
}
