let reloading = false;

export const reloadPage = () => {
  if (reloading) {
    return;
  }

  document.location.reload();
  reloading = true;
};
