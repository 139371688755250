import * as React from 'react';
import {MinerUnauthorizedSurfaceCell} from '@youtoken/ui.surface-miner';
import {ServicePagesWrapper} from '../ServicePagesWrapper';

export const LedgerLiveMinerPage: React.FC = () => {
  return (
    <ServicePagesWrapper>
      <MinerUnauthorizedSurfaceCell />
    </ServicePagesWrapper>
  );
};
