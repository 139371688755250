import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {ReferralsHowItWorksSmartProps} from './types';
import {ReferralsHowItWorksV1Smart} from './v1';
import {ReferralsHowItWorksV3Smart} from './v3';

export const ReferralsHowItWorksSmart: React.FC<ReferralsHowItWorksSmartProps> =
  observer(({showShareRefCode = false}) => {
    const {
      data: {rewardVersion},
    } = AuthMeResource.use({});

    if (rewardVersion === 3) {
      return <ReferralsHowItWorksV3Smart showShareRefCode={showShareRefCode} />;
    }

    return <ReferralsHowItWorksV1Smart showShareRefCode={showShareRefCode} />;
  });
