import * as React from 'react';
import {observer} from 'mobx-react';
import {TextInput} from '@youtoken/ui.inputs';
import {Box} from '@youtoken/ui.primitives';
import {Error, Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type DepositInvoiceForm} from '../../state/DepositInvoiceForm';

export const FieldBankName: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {bankNameValue, hasBankNameError, changeBankName, instance},
  } = useForm<DepositInvoiceForm>();

  return (
    <Box>
      <Box mb={8}>
        <Label>{t('surface.wallets.fiat_deposit_wire.bank_name')}</Label>
      </Box>

      <Box
        alignItems="stretch"
        position="relative"
        width="100%"
        flexGrow={1}
        flexShrink={0}
      >
        <TextInput
          value={bankNameValue}
          onChangeText={changeBankName}
          hasError={hasBankNameError}
          placeholder="REVOLUT LTD"
          testID="FIAT_DEPOSIT_BANK_WIRE_BANK_NAME"
        />
      </Box>

      <Error
        errors={instance.errors()}
        name="bankName"
        testID="FIAT_DEPOSIT_BANK_WIRE_BANK_NAME_ERROR"
      />
    </Box>
  );
});
