import React from 'react';
import {modal} from '../../../stores';
import {Modal} from '../_common';
import {
  FiatWithdrawalWizard,
  type FiatWithdrawalWizardScreenName,
} from '@youtoken/ui.surfaces-wallets';

interface ModalFiatWithdrawalProps extends React.ComponentProps<typeof Modal> {
  ticker: string;
  defaultStep?: FiatWithdrawalWizardScreenName;
}

export const ModalFiatWithdrawal: React.FC<ModalFiatWithdrawalProps> = ({
  ticker,
  defaultStep,
  ...otherProps
}) => {
  return (
    <Modal minHeight={200} {...otherProps}>
      <FiatWithdrawalWizard
        ticker={ticker}
        defaultStep={defaultStep}
        onPressBack={modal.close}
      />
    </Modal>
  );
};
