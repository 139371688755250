export enum diffColor {
  up = '$success-01',
  down = '$danger-01',
}

type ItemSubtitleVariant = 'rate' | 'rollover' | 'capitalization';

export type TradingInstrumentItemData = {
  id: string;
  baseTicker: string;
  baseTickerFormatted: string;
  baseTickerName: string;
  quoteTicker: string;
  quoteTickerFormatted: string;
  isCategoryRollovers?: boolean;
};

export type TradingInstrumentsProps = {
  title?: string;
  items: TradingInstrumentItemData[];
  collapsedViewItemLimit?: number;
  isExpandRestricted?: boolean;
  itemSubtitleVariant?: ItemSubtitleVariant;
  showCharts?: boolean;
  mobileEstimatedListHeight?: number;
  allowRolloversIcon?: boolean;
  onPressItem?: (id: string, isExpand?: boolean) => void;
  onPressShowAll?: (isExpand: boolean) => void;
  testID?: string;
};

export type TradingInstrumentItemProps = {
  item: TradingInstrumentItemData;
  isParentListExpanded?: boolean;
  width?: number;
  subtitleVariant?: ItemSubtitleVariant;
  showCharts?: boolean;
  allowRolloversIcon?: boolean;
  onPressItem: (id: string, isParentListExpanded?: boolean) => void;
  testIdBase?: string;
  index?: number;
};

export type TradingInstrumentModalItemProps = TradingInstrumentItemProps & {
  closeModal?: () => void;
};
