import Big from 'big.js';
import {object, serializable} from 'serializr';
import {bigNumber} from './bigNumber';

export class MainCurrencyVariants {
  @serializable(bigNumber())
  usd!: Big;

  @serializable(bigNumber())
  eur!: Big;

  @serializable(bigNumber())
  btc!: Big;
}

export const mainCurrencyVariants = () => object(MainCurrencyVariants);
