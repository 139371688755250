import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {type ExchangeFormProps} from '../types';
import {ExchangeFormV1} from './v1';
import {ExchangeFormV2} from './v2';

export const ExchangeForm: React.FC<ExchangeFormProps & BoxProps> = cell(
  props => {
    const {exchangeVersion} = AuthMeResource.use({});

    if (exchangeVersion === 2) {
      return <ExchangeFormV2 {...props} />;
    }

    return <ExchangeFormV1 {...props} />;
  }
);
