import {computed} from 'mobx';
import {custom, optional, primitive, serializable} from 'serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';
import {
  type IBankCard,
  type TBankCardFormat,
  type TBankCardStatus,
  PaymentSystem,
} from './types';
import {
  type currencyName,
  currencySignMap,
} from '@youtoken/ui.resource-auth-me';

export class BankCardsResponse implements IBankCard {
  @serializable(primitive())
  enable!: boolean;

  @serializable(primitive())
  status!: TBankCardStatus;

  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  cardId!: string;

  @serializable(primitive())
  balance!: number;

  @serializable(
    custom(
      value => value,
      value => (value ? value.replace(/\*/g, '•') : value)
    )
  )
  panLast!: string;

  @serializable(primitive())
  expiryDate!: string;

  // TODO fix it
  @serializable(
    custom(
      value => value,
      value => value.toLowerCase()
    )
  )
  format!: TBankCardFormat;

  @serializable(optional(primitive()))
  paymentSystem?: PaymentSystem;

  @computed get balanceFormatted() {
    return this.ticker && this.balance !== null
      ? formatBigNumber(this.balance, getCoinDecimalPrecision(this.ticker))
      : undefined;
  }

  @computed get balanceWithTickerFormatted() {
    const currencySign = currencySignMap[this.ticker as currencyName];

    if (!this.balanceFormatted || !currencySign) {
      return undefined;
    }

    return `${currencySign}${this.balanceFormatted}`;
  }
}
