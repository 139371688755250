import * as React from 'react';
import {observer} from 'mobx-react';
import {SavingsOverviewResource} from '@youtoken/ui.resource-savings';
import {type BoxProps} from '@youtoken/ui.primitives';
import {warning} from '@youtoken/ui.utils';
import {SavingsEarned} from '../../components';

export const SavingsEarnedSmart: React.FC<BoxProps> = observer(props => {
  const {
    data: {quoteTicker, earnedInQuoteTickerFormatted, timeLeft},
    refetchWithDelay,
  } = SavingsOverviewResource.use({});

  warning(timeLeft, 'timeLeft is missing', {}, {timeLeft});

  return (
    <SavingsEarned
      ticker={quoteTicker}
      amountFormatted={earnedInQuoteTickerFormatted}
      timeLeft={timeLeft!}
      onTimerEnd={refetchWithDelay}
      flex={1}
      {...props}
    />
  );
});
