import * as React from 'react';
import {observer} from 'mobx-react';
import {PanResponder} from '@youtoken/ui.legacy-charts';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';
import {
  HODLChartSmartCell,
  HODLClosedChartSmartCell,
  ResizableBoxWithOverlay,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';

export interface DetailedChartProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  Responder?: React.ComponentProps<
    typeof HODLClosedChartSmartCell
  >['ChartPanComponent'];
}

export const DetailedChart: React.FC<DetailedChartProps> = observer(
  ({mode, id, Responder = PanResponder, ...rest}) => {
    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box width="100%" {...rest}>
        <ResizableBoxWithOverlay height={360} width="100%" {...bind}>
          {!!(width && height) && (
            <>
              {loan.isClosed ? (
                <HODLClosedChartSmartCell
                  width={width}
                  height={height}
                  fromTicker={loan.collateralTicker!}
                  toTicker={loan.borrowedTicker!}
                  initialPrice={Number(loan.initialPrice)}
                  closedPrice={Number(loan.closedPrice)}
                  startDate={loan.startDate}
                  endDate={loan.endDate}
                  ChartPanComponent={Responder}
                />
              ) : (
                <HODLChartSmartCell
                  displayTypeSwitcher={false}
                  width={width}
                  height={height}
                  fromTicker={loan.collateralTicker!}
                  toTicker={loan.borrowedTicker!}
                  takeProfit={Number(loan.takeProfit)}
                  marginCall={Number(loan.marginCall)}
                  initialPrice={Number(loan.initialPrice)}
                  startDate={loan.startDate}
                  ChartPanComponent={Responder}
                />
              )}
            </>
          )}
        </ResizableBoxWithOverlay>
      </Box>
    );
  }
);
