import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {PercentPresetButton} from './PercentPresetButton';

export interface TriggerDistancePresetButtonsProps {
  currentTriggerPricePercent: string;
  triggerPriceDistanceMax: number;
  setOfTriggerPricePercent: string[];
  onChangeDistance: (val: string) => void;
}

export const TriggerDistancePresetButtons: React.FC<
  TriggerDistancePresetButtonsProps
> = ({
  currentTriggerPricePercent,
  triggerPriceDistanceMax,
  setOfTriggerPricePercent,
  onChangeDistance,
}) => {
  const buttons = React.useMemo(() => {
    if (triggerPriceDistanceMax >= 0.02) {
      return setOfTriggerPricePercent;
    }

    return [];
  }, [triggerPriceDistanceMax]);

  if (buttons.length < 1) {
    return null;
  }

  return (
    <Box width="100%" flexDirection="row" mt={8}>
      {buttons.map((item, index) => (
        <PercentPresetButton
          key={`${item}_${index}`}
          valueFormatted={item}
          onPress={onChangeDistance}
          mr={index === buttons.length - 1 ? 0 : 8}
          type={item === currentTriggerPricePercent ? 'primary' : 'secondary'}
          size="small"
        />
      ))}
    </Box>
  );
};
