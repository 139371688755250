import * as React from 'react';
import {
  Wrap,
  LastValueLabel,
  LastValueLine,
  DataLine,
  PastDataLine,
  FutureDataLine,
  TakeProfitLine,
  MarginCallLine,
  InitialPriceLine,
  HODLHighlightArea,
  ActivityIndicator,
  TriggerPriceLine,
} from '../Elements';

import {useLocalChartState} from '../logic';

import {HODLPreviewChartState, HODLPreviewChartStateProps} from '../logic';

import {ChartThemeProvider} from '../Providers';
import {G} from 'react-native-svg';
import {useObserver} from 'mobx-react';
import {NoDataState} from './NoDataState';

export const HODLPreviewChart: React.FC<HODLPreviewChartStateProps> =
  React.memo(({children, ...props}) => {
    const state = useLocalChartState(HODLPreviewChartState, props);

    return useObserver(() => {
      if (!state.hasData) {
        return <NoDataState />;
      }

      return (
        <ChartThemeProvider>
          <Wrap chartState={state}>
            <G>
              <PastDataLine chartState={state} />
              <FutureDataLine chartState={state} />
              <DataLine chartState={state} />
            </G>

            <G>
              {state.displayInitialPriceLabel && (
                <InitialPriceLine chartState={state} fullWidth />
              )}
              <TakeProfitLine chartState={state} fullWidth />
              <MarginCallLine chartState={state} fullWidth />
              <LastValueLine chartState={state} />
              {state.pending && (
                <TriggerPriceLine chartState={state} fullWidth />
              )}

              {state.type === 'line' && !state.pending && (
                <HODLHighlightArea chartState={state} />
              )}
            </G>

            <G>
              <LastValueLabel chartState={state} />
            </G>
          </Wrap>
          {props.displayActivityIndicator && (
            <ActivityIndicator chartState={state} />
          )}
        </ChartThemeProvider>
      );
    });
  });
