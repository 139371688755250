import {getCoinName} from '@youtoken/ui.coin-utils';
import {type WalletComboboxItem} from '@youtoken/ui.ticker-and-amount-input';

export const tickerToComboboxItem = (ticker: string): WalletComboboxItem => {
  return {
    ticker: ticker,
    tickerFormatted: ticker.toUpperCase(),
    coinName: getCoinName(ticker),
    key: ticker,
  };
};
