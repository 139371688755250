import {observable} from 'mobx';
import {list, object, serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {HODLItem} from './HODLItem';

export class HODLsResponsePaginated {
  @observable
  @serializable(number())
  currentPage!: number;

  @observable
  @serializable(list(object(HODLItem)))
  rows!: HODLItem[];

  @observable
  @serializable(number())
  total!: number;
}
