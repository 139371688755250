import * as React from 'react';
import {observer} from 'mobx-react';
import {type TFuncKey} from 'react-i18next';
import {Box, Text, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Clipboard} from '@youtoken/ui.utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {useTranslation} from '@youtoken/ui.service-i18n';

type AccountDetailProps = {
  titleKey: TFuncKey;
  text: string;
};

export const AccountDetail: React.FC<AccountDetailProps & BoxProps> = observer(
  ({titleKey, text, ...boxProps}) => {
    const {t} = useTranslation();

    const detailName = t(titleKey);

    const copy = React.useCallback(() => {
      Clipboard.setString(text);
      LOCAL_NOTIFICATIONS.info({
        // "Bank Name copied to clipboard"
        text: t('surface.bank_cards.deposit.external.detail_copied_message', {
          text: detailName,
        }),
        testID: 'ACCOUNT_DETAIL_COPIED_MESSAGE',
      });
    }, [detailName]);

    return (
      <Box {...boxProps} testID={`ACCOUNT_DETAIL`}>
        <Text variant="$body-01" color="$text-02" testID="ACCOUNT_DETAIL_TITLE">
          {detailName}
        </Text>
        <Box flexDirection="row" alignItems="center" mt={8}>
          <Text
            variant="$body-01"
            color="$text-01"
            testID="ACCOUNT_DETAIL_TEXT"
          >
            {text}
          </Text>
          <TouchableBox ml={2} onPress={copy} testID="ACCOUNT_DETAIL_COPY">
            <Icon name="copy" color="$text-05" size={20} />
          </TouchableBox>
        </Box>
      </Box>
    );
  }
);
