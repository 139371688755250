import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CollapseIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.625 11.375L9.47 6.53a.75.75 0 011.06 0l4.845 4.845a.884.884 0 11-1.25 1.25L10 8.5l-4.125 4.125a.884.884 0 11-1.25-1.25z"
          fill={color}
        />
      </Svg>
    );
  }
);
