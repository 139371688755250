import React from 'react';
import {Modal} from '../_common';
import {
  LoansListItemFeatureArgs,
  LoanIncreaseForm,
} from '@youtoken/ui.legacy-components';
import {Box, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface ModalIncreaseLtvProps extends React.ComponentProps<typeof Modal> {
  loan: LoansListItemFeatureArgs;
}

export const ModalIncreaseLtv: React.FC<ModalIncreaseLtvProps> = ({
  loan,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <Modal minHeight={400} {...props}>
      <Box width="100%" height="100%" flexGrow={1}>
        <Box p={24}>
          <Heading variant="$heading-02-responsive">
            {t('surface.loans.increase_form.title')}
          </Heading>
        </Box>
        <LoanIncreaseForm id={loan.id} />
      </Box>
    </Modal>
  );
};
