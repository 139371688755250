import {match} from 'path-to-regexp';
import {parse, parseUrl} from 'query-string';
import {Routes} from './Routes';
import {routesToUrlMap} from './Routes/routeToUrlMap';

const getUrlAndQueryPart = (incomingUrl: string) => {
  if (
    incomingUrl.startsWith('https://') ||
    incomingUrl.startsWith('http://') ||
    incomingUrl.startsWith('youhodler://')
  ) {
    const _url = new URL(incomingUrl);

    return {
      url: _url.pathname,
      query: parse(_url.search, {
        parseNumbers: true,
        parseBooleans: true,
      }),
    };
  }

  const {url, query} = parseUrl(incomingUrl, {
    parseNumbers: true,
    parseBooleans: true,
  });

  return {url, query};
};

type ResultingRoute<T> = T extends Routes.AllRoutes[0]
  ? Extract<Routes.AllRoutes, [T, any, any]>
  : Routes.AllRoutes;

export const urlToRoute = <T>(incomingUrl: string): ResultingRoute<T> => {
  const {url, query} = getUrlAndQueryPart(incomingUrl);

  for (const routeName in routesToUrlMap) {
    const pathPattern = routesToUrlMap[routeName as Routes.AllRoutes[0]];

    const matchingResult = match(pathPattern)(url);

    if (matchingResult) {
      return [
        routeName,
        matchingResult.params,
        {...query},
      ] as ResultingRoute<T>;
    }
  }

  return ['NotFound', {}, {}] as ResultingRoute<T>;
};
