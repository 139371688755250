import {computed, observable} from 'mobx';
import {scaleLinear, scaleTime} from 'd3-scale';
import * as array from 'd3-array';

import {BaseChartLayout} from './BaseChartLayout';
import {BaseChartState} from '../BaseChartState';

export class LineChartLayout extends BaseChartLayout {
  @observable chart!: BaseChartState;

  constructor(chart: BaseChartState) {
    super();
    this.chart = chart;
  }

  @computed get scaleX() {
    return scaleTime()
      .domain([
        array.min(this.chart.data as any[], d => d.date)!,
        array.max(this.chart.data as any[], d => d.date)!,
      ])
      .range([
        this.chart.domainPaddingLeft,
        this.chart.width - this.chart.domainPaddingRight,
      ]);
  }

  @computed get chartWidth() {
    return this.chart.lastValueLabel.labelWidth
      ? this.chart.width - this.chart.lastValueLabel.labelWidth
      : this.chart.width - this.chart.domainPaddingRight;
  }

  @computed get scaleXChart() {
    const range = [this.chart.domainPaddingLeft, this.chartWidth];

    return this.scaleX.range(range);
  }

  @computed get scaleY() {
    return scaleLinear()
      .domain([this.chart.minValue, this.chart.maxValue])
      .range([
        this.chart.height - this.chart.domainPaddingBottom,
        this.chart.domainPaddingTop,
      ])
      .nice();
  }
}
