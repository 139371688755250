import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {FriendsActivityItem} from './FriendsActivityResponse';

export class FriendsActivityResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => `FriendsActivityResource`,
  getData: () => {
    return TRANSPORT.API.get(`/v3/rewards/activity`).then(res => {
      return deserialize(FriendsActivityItem, res.data as any[]);
    });
  },
}) {}
