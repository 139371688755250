import {useEffect} from 'react';

/** __hook, set event listener on node__*/
export const useEventListener = (
  node: HTMLElement | Document | Window,
  eventName: string,
  handler: (event: any) => void
): void => {
  useEffect(() => {
    node.addEventListener(eventName, handler);
    return () => node.removeEventListener(eventName, handler);
  }, [handler, node, eventName]);
};
