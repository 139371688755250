import {action, computed, observable} from 'mobx';
import {Platform} from 'react-native';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BankCardsResponse} from './BankCardsResponse';
import {openBrowser, requestConsent} from './utils';
import {
  type IBankCard,
  type IOpenCardResponse,
  type TBankCardFormat,
} from './types';

export class BankCardsResource extends createResource({
  getKey: () => 'bankCards',
  getData: () => {
    const {
      data: {enableVirtualBankCard, enablePhysicalBankCard},
    } = AuthMeResource.getInstance({});

    if (!enableVirtualBankCard && !enablePhysicalBankCard) {
      return Promise.resolve([]);
    }

    return TRANSPORT.API.get('/v1/intergiro/cards/')
      .then(res => {
        const cards = res.data;

        const helperCards = [];

        // add a helper physical card here when supporting physical cards
        if (
          enableVirtualBankCard &&
          (cards.length === 0 ||
            cards.some((card: IBankCard) => card.status === 'DISABLED'))
        ) {
          helperCards.push({
            enable: true,
            format: 'virtual',
            status: 'AVAILABLE',
          });
        }

        return deserialize(BankCardsResponse, [...helperCards, ...cards]);
      })
      .catch(() => []);
  },
  skipRefreshOnVisible: false,
}) {
  @observable isCardFreezeLoading = false;

  @computed
  public get cards() {
    return this.data as IBankCard[];
  }

  @action
  private handleCardFrozen = (cardId: IBankCard['cardId']) => {
    LOCAL_NOTIFICATIONS.info({
      text: i18n.t('surface.bank_cards.message.has_been_frozen'),
    });
  };

  @action
  public checkIfCardCanBeOpened = (format: TBankCardFormat) => {
    invariant(format[0], 'format is empty');

    const BEFormat = format[0].toUpperCase() + format.slice(1);

    return TRANSPORT.API.post('/v1/intergiro/cards/open/pre-check', {
      format: BEFormat,
    });
  };

  @observable isCardOpening = false;

  @action
  public openCard = (format: TBankCardFormat) => {
    invariant(format[0], 'format is empty');

    const returnUrl = Platform.select({
      native: '/wallets/cards/consent-final',
      web: '/wallets/cards',
    });

    const BEFormat = format[0].toUpperCase() + format.slice(1);

    this.isCardOpening = true;
    return new Promise<IOpenCardResponse>((resolve, reject) => {
      TRANSPORT.API.post<IOpenCardResponse>('/v1/intergiro/cards/open', {
        format: BEFormat,
        redirectURL: returnUrl,
      })
        .then(response => {
          const {redirectURL} = response.data;
          if (!redirectURL) {
            reject(new Error('No redirectUrl received'));
            return;
          }

          this.isCardOpening = false;
          openBrowser(redirectURL!).then(() => {
            if (Platform.OS !== 'web') {
              this.refetch();
              SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
            }
          });
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          this.isCardOpening = false;
        });
    });
  };

  @action
  public requestBalanceConsent = () => {
    const returnUrl = Platform.select({
      native: '/wallets/cards/consent-final',
      web: '/wallets/cards',
    })!;

    // cards todo: only used once, maybe just move requestConsent's content here to simplify?
    return requestConsent({
      returnUrl,
    })
      .then(() => {
        if (Platform.OS !== 'web') {
          this.refetch();
          SHARED_ROUTER_SERVICE.navigate('BankCards');
        }
      })
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('common.errors.smth_went_wrong'),
        });
      });
  };

  @action
  public freezeCard = (cardId: IBankCard['cardId']) => {
    this.isCardFreezeLoading = true;

    return TRANSPORT.API.post('/v1/intergiro/cards/freeze', {
      cardId,
    })
      .then(() => {
        this.refetch();
      })
      .then(() => {
        this.handleCardFrozen(cardId);
      })
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('common.errors.smth_went_wrong'),
        });
      })
      .finally(() => {
        this.isCardFreezeLoading = false;
      });
  };

  @action
  public unfreezeCard = (cardId: IBankCard['cardId']) => {
    const returnUrl = Platform.select({
      native: '/wallets/cards/consent-final',
      web: '/wallets/cards',
    });

    this.isCardFreezeLoading = true;

    return TRANSPORT.API.post('/v1/intergiro/cards/activate', {
      cardId,
      redirectURL: returnUrl,
    })
      .then(({data: {redirectURL}}) => {
        openBrowser(redirectURL).then(() => {
          if (Platform.OS !== 'web') {
            this.refetch();
          }
        });
      })
      .catch(() => {
        LOCAL_NOTIFICATIONS.error({
          text: i18n.t('common.errors.smth_went_wrong'),
        });
      })
      .finally(() => {
        this.isCardFreezeLoading = false;
      });
  };

  public getCardById = (cardId: string) => {
    return this.cards.find(card => card.cardId === cardId) as IBankCard;
  };
}
