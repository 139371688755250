import * as React from 'react';
import {observer} from 'mobx-react';
import {
  type TouchableBoxProps,
  TouchableBox,
  Box,
} from '@youtoken/ui.primitives';
import {type BaseIconName} from '@youtoken/ui.icons';
import {AccountLevelInfoItemTitle} from './Title';

export interface AccountLevelInfoItemShortProps {
  icon: BaseIconName;
  title: string;
  description?: React.ReactNode;
  badge: React.ReactNode;
}

export const AccountLevelInfoItemShort: React.FC<
  AccountLevelInfoItemShortProps & TouchableBoxProps
> = observer(({icon, title, description, badge, ...touchableBoxProps}) => {
  return (
    <TouchableBox
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      p={16}
      {...touchableBoxProps}
    >
      <Box flexGrow={1} flexShrink={1}>
        <AccountLevelInfoItemTitle icon={icon}>
          {title}
        </AccountLevelInfoItemTitle>
        {Boolean(description) && (
          <Box flexGrow={1} flexShrink={1} mt={4}>
            {description}
          </Box>
        )}
      </Box>
      <Box justifyContent="center" alignItems="flex-end" ml={4}>
        {badge}
      </Box>
    </TouchableBox>
  );
});
