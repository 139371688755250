import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {TextInput, type TextInputProps} from '@youtoken/ui.inputs';
import {type WithdrawalForm} from '../../state/WithdrawalForm';

interface FormInputFieldProps {
  label: React.ReactNode;
  name: string;
  normalizer?: (value: string) => string;
  testID?: string;
}

export const FormInputField: React.FC<FormInputFieldProps & TextInputProps> =
  observer(
    ({
      label,
      name,
      normalizer = value => value,
      testID,
      children,
      ...inputProps
    }) => {
      const {
        form: {instance},
      } = useForm<WithdrawalForm>();

      const handleChange = React.useCallback(
        (value: string) => {
          instance.$(name).onChange(normalizer(value));
        },
        [name, normalizer]
      );

      return (
        <Box mb={16}>
          <Box mb={8}>{label}</Box>

          <TextInput
            value={instance.$(name).get('value')}
            hasError={Boolean(instance.$(name).error)}
            onChangeText={handleChange}
            testID={testID}
            {...inputProps}
          />

          <FieldErrorMessage visible={Boolean(instance.$(name).error)}>
            <Text testID={testID ? `${testID}_ERROR` : undefined}>
              <ValidationMessageComponent
                error={instance.$(name).get('error')}
              />
            </Text>
          </FieldErrorMessage>

          {children}
        </Box>
      );
    }
  );
