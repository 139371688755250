import * as React from 'react';
import {Icon, type BaseIconName} from '@youtoken/ui.icons';
import {
  Box,
  Text,
  TouchableBox,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';

interface MenuItemProps {
  title: string;
  icon: BaseIconName;
  disabled?: boolean;
}

export const MenuItem: React.FC<MenuItemProps & TouchableBoxProps> = ({
  title,
  icon,
  disabled,
  ...boxProps
}) => {
  return (
    <TouchableBox
      px={26}
      py={24}
      flexDirection="row"
      alignItems="center"
      borderBottomWidth={1}
      disabled={disabled}
      opacity={disabled ? 0.4 : 1}
      borderColor="$ui-01"
      {...boxProps}
    >
      <Icon name={icon} size={16} color="$text-01" />
      <Text ml={10} variant="$body-01-medium-accent" color="$text-01">
        {title}
      </Text>
      <Box flexGrow={1} alignItems="flex-end">
        <Icon name="chevron_right" color="$text-02" size={20} />
      </Box>
    </TouchableBox>
  );
};
