import {TRANSPORT} from '@youtoken/ui.transport';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {CompanyLegalInfo, CompanyResponse} from './CompanyResponse';
import {computed} from 'mobx';
import {i18n, type TKey} from '@youtoken/ui.service-i18n';
import {computedFn} from 'mobx-utils';
import {warning} from '@youtoken/ui.utils';

export interface CompanyResourceArgs {}

export class CompanyResource extends createStaticResource({
  getKey: ({}: CompanyResourceArgs) => `companyResource`,
  getData: ({}: CompanyResourceArgs) => {
    return TRANSPORT.API.get('/v2/company').then(response =>
      deserialize(CompanyResponse, response.data)
    );
  },
}) {
  @computed get naumard() {
    return this.data.naumard;
  }

  @computed get youhodler() {
    return this.data.youhodler;
  }

  @computed get youhodlerITA() {
    return this.data.youhodler_ita;
  }

  getTranslatedLinkAndName = computedFn((info: CompanyLegalInfo) => {
    return {
      name: info.i18nName
        ? i18n.t(info.i18nName as TKey, {defaultValue: info.name})
        : info.name,
      link: info.i18nLink
        ? i18n.t(info.i18nLink as TKey, {defaultValue: info.link})
        : info.link,
    };
  });

  @computed get legalInfo() {
    warning(this.naumard.legalInfo.legalOpinion, 'no legal opinion');
    warning(
      this.naumard.legalInfo.legalOpinionSavingsReward,
      'no legal opinion savings reward'
    );
    warning(this.naumard.legalInfo.legalOpinion, 'no legal sro polyreg');

    return {
      naumardLegalOpinion: this.getTranslatedLinkAndName(
        this.naumard.legalInfo.legalOpinion!
      ),
      naumardLegalOpinionSavingsReward: this.getTranslatedLinkAndName(
        this.naumard.legalInfo.legalOpinionSavingsReward!
      ),
      youhodlerSroPolyreg: this.getTranslatedLinkAndName(
        this.youhodler.legalInfo.sroPolyreg!
      ),
    };
  }
}
