import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';
import {SavingsOverviewStatus} from '@youtoken/ui.resource-savings';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {SavingsSelectedAssetsAssetProps} from '../types';
import {SavingsSelectedAssetsFeature} from '../../Feature';

export const SavingsSelectedAssetsItemEarned: React.FC<
  SavingsSelectedAssetsAssetProps & BoxProps
> = observer(({ticker, ...boxProps}) => {
  const {status, getAssetItemPropsByTicker} = SavingsSelectedAssetsFeature.use(
    {}
  );

  const {
    quoteTicker,
    baseTickerFormatted,
    earnedInBaseTickerFormatted,
    earnedInQuoteTickerFormatted,
  } = getAssetItemPropsByTicker(ticker);

  const symbol = getCoinSymbol(quoteTicker);

  return (
    <Box
      testID="SAVINGS_SELECTED_ASSETS_ITEM_EARNED"
      alignItems="flex-end"
      {...boxProps}
    >
      <Text
        variant="$body-01"
        color={
          status === SavingsOverviewStatus.CLAIMED ? '$success-01' : '$text-02'
        }
      >
        <Text
          testID="SAVINGS_SELECTED_ASSETS_ITEM_EARNED_BASE_AMOUNT"
          variant="$body-01-high-accent"
          color={
            status === SavingsOverviewStatus.CLAIMED
              ? '$success-01'
              : '$text-01'
          }
        >
          +{earnedInBaseTickerFormatted}
        </Text>{' '}
        <Text testID="SAVINGS_SELECTED_ASSETS_ITEM_EARNED_BASE_TICKER">
          {baseTickerFormatted}
        </Text>
      </Text>
      <Text variant="$body-02" color="$text-02" mt={2}>
        <Text testID="SAVINGS_SELECTED_ASSETS_ITEM_EARNED_QUOTE_SYMBOL">
          {symbol}
        </Text>
        <Text testID="SAVINGS_SELECTED_ASSETS_ITEM_EARNED_QUOTE_AMOUNT">
          {earnedInQuoteTickerFormatted}
        </Text>
      </Text>
    </Box>
  );
});
