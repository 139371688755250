import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Separator} from '@youtoken/ui.elements';
import {
  ProgressLevelLeftTime,
  LoyaltyProgressBar,
} from '@youtoken/ui.loyalty-miner-components';
import {AccountLevelProgressWidgetLayout} from './Layout';

export interface AccountLevelProgressWidgetAuthorizedProps {
  TradeToUpgrade?: React.FC;
}

export const AccountLevelProgressWidgetAuthorized: React.FC<AccountLevelProgressWidgetAuthorizedProps> =
  observer(({TradeToUpgrade}) => {
    const {
      data: {currentLevel, downgradeLevel, timeLeftUntilLevelDowngrade},
      progressBarPoints,
      progressBarValue,
      progressBarValueText,
      refetch,
    } = LoyaltyResource.use({});

    return (
      <AccountLevelProgressWidgetLayout>
        <LoyaltyProgressBar
          points={progressBarPoints}
          value={progressBarValue}
          valueText={progressBarValueText}
        />
        {TradeToUpgrade && <TradeToUpgrade />}
        <Separator
          mt={{default: 16, tablet: 24}}
          mx={{default: -16, tablet: -24}}
          backgroundColor="$ui-01"
        />
        <Box my={16}>
          <ProgressLevelLeftTime
            currentLevel={currentLevel}
            nextSprintLevel={downgradeLevel}
            timeLeft={timeLeftUntilLevelDowngrade}
            refetch={refetch}
          />
        </Box>
      </AccountLevelProgressWidgetLayout>
    );
  });
