import * as React from 'react';
import {Platform} from 'react-native';
import {ActivityIndicator, Illustration} from '@youtoken/ui.elements';
import {Box, BoxProps, Heading, Text, useTheme} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

const imageSize = Platform.select({
  web: 230,
  default: 342,
});

export interface ContentProps extends BoxProps {
  userEmail?: string;
}

const maskPart = (part: string) => {
  const length = part.length;
  if (length > 8) {
    return (
      part.substr(0, 2) +
      part.substr(2, length - 2).replace(/\w/g, '*') +
      part.substr(-2, 2)
    );
  }
  if (length > 4) {
    return (
      part.substr(0, 1) +
      part.substr(1, length - 1).replace(/\w/g, '*') +
      part.substr(-1, 1)
    );
  }
  return part.replace(/\w/g, '*');
};

// TODO: should be moved to utils
export const maskEmailAddress = (emailAddress: string) => {
  return emailAddress.replace(
    /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
    (_m, p1, p2, p3) => {
      return maskPart(p1) + '@' + maskPart(p2) + p3;
    }
  );
};

export const Content: React.FC<ContentProps> = ({userEmail}) => {
  const {spacing} = useTheme();
  const {t} = useTranslation();

  const scrambledEmail = React.useMemo(() => {
    // TODO: replace it with locale or ensure that email exists
    return userEmail ? maskEmailAddress(userEmail) : 'your email';
  }, [userEmail]);

  return (
    <Box flex={1} alignItems="center">
      <Box alignItems="center" width="100%">
        <Box
          width={imageSize}
          height={imageSize}
          my={spacing['$screen-default-offset']}
          alignItems="center"
          justifyContent="center"
        >
          <Illustration
            name="illustration-13"
            style={{
              width: imageSize,
              height: imageSize,
            }}
          />
        </Box>

        <Heading variant="$heading-02" mt={8} mb={16} textAlign="center">
          {t('surface.await_confirm_device.title')}
        </Heading>

        <Box mb={8}>
          <Text
            variant="$body-01"
            textAlign="center"
            // TODO: check, probably inline is not needed;
            // @ts-ignore
            style={{display: Platform.select({web: 'inline', default: 'flex'})}}
          >
            <Text>
              {t('surface.await_confirm_device.text', {
                userEmail: scrambledEmail,
              })}
            </Text>
          </Text>
        </Box>
      </Box>
      <Box flexGrow={1} alignItems="center" justifyContent="center" py={24}>
        <Box mb={16}>
          <ActivityIndicator size={32} />
        </Box>
        <Text color="$text-05" variant="$body-01">
          {t('surface.await_confirm_device.loading.text')}
        </Text>
      </Box>
    </Box>
  );
};
