import {deserialize} from 'serializr';
import {computed, IReactionDisposer, reaction} from 'mobx';
import qs from 'qs';
import {GLOBAL} from '@youtoken/ui.service-global';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {Features, FeatureItem, FeaturesResponse} from './FeaturesResponse';

export {Features};

export class FeaturesResource extends createResource<
  {},
  Record<Features, FeatureItem>
>({
  shouldBeDeletedOnCleanup: false,
  refetchInterval: 5 * 60 * 1000, // 5 minutes
  getKey: () => `FeaturesResource`,
  getData: () => {
    if (!GLOBAL.isAuthorized) {
      return Promise.resolve(deserialize(FeaturesResponse, {}));
    }

    return TRANSPORT.API.get('/v1/features', {
      params: {
        feature: Object.values(Features),
      },
      paramsSerializer: params => qs.stringify(params, {indices: false}),
    })
      .then(res => {
        return deserialize(FeaturesResponse, res.data) as Record<
          Features,
          FeatureItem
        >;
      })
      .catch(error => {
        SENTRY.capture(error, {
          source: 'FeaturesResource',
        });
        return deserialize(FeaturesResponse, {});
      });
  },
}) {
  disposers: Array<IReactionDisposer> = [];

  constructor(args: {}, data: Record<Features, FeatureItem>) {
    super(args, data);

    this.disposers = [
      reaction(
        () => GLOBAL.isAuthorized,
        isAuthorized => {
          this.refetch();
        }
      ),
    ];
  }

  onDestroy(): void {
    super.onDestroy();

    this.disposers?.forEach(disposer => disposer?.());
  }

  //#region savingsWidget
  @computed
  public get savingsWidget(): FeatureItem {
    return this.data[Features.SAVINGS_WIDGET] ?? ({} as FeatureItem);
  }

  @computed
  public get savingsWidgetEnabled(): boolean {
    return this.savingsWidget.enabled ?? false;
  }

  @computed
  public get savingsWidgetName() {
    return this.savingsWidget.name as 'a' | 'b';
  }

  @computed
  public get savingsWidgetVariant() {
    return this.savingsWidget.variant as 'primary' | 'secondary';
  }

  //#endregion savingsWidget

  //#region hide Stories

  @computed
  public get storiesHidden(): FeatureItem {
    return this.data[Features.STORIES_HIDDEN] ?? ({} as FeatureItem);
  }

  @computed
  public get hideStories() {
    return this.storiesHidden.enabled ?? false;
  }
  //#endregion hide Stories

  //#region nativeSumSub
  @computed
  public get nativeSumSub() {
    return this.data[Features.NATIVE_SUMSUB] ?? ({} as FeatureItem);
  }

  @computed
  public get nativeSumSubEnabled() {
    return (
      (this.nativeSumSub.enabled && this.nativeSumSub.variant === 'native') ??
      false
    );
  }
  //#endregion nativeSumSub
}
