import * as React from 'react';
import {Text, TextProps} from 'react-native-svg';
import {themes} from '@youtoken/ui.primitives';

const font = themes.light.fonts.mono.regular;

export type LabelTextProps = {
  /** props **/
};

export const LabelText: React.FC<TextProps> = props => {
  return (
    <Text fontFamily={font} fontSize={11} letterSpacing={0.23} {...props} />
  );
};
