import * as React from 'react';
import {type TFuncKey} from 'react-i18next';
import {Box, Text, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Trans, TransLink} from '@youtoken/ui.elements';

type AboutSectionProps = {
  content: string;
};

export const AboutSection: React.FC<AboutSectionProps & BoxProps> = ({
  content,
  ...boxProps
}) => {
  const {t} = useTranslation();

  return (
    <Box pt={20} pb={24} px={24} {...boxProps}>
      <Heading variant="$heading-02">
        {t('surface.hodls.instrument.about_title')}
      </Heading>
      <Text variant="$body-01" pt={12}>
        <Trans
          i18nKey={content as TFuncKey}
          components={{
            Link: <TransLink />,
          }}
        />
      </Text>
    </Box>
  );
};
