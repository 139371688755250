import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text, type WithBoxProps} from '@youtoken/ui.primitives';
import {Banner, Link} from '@youtoken/ui.elements';
import {type CryptoDepositProps} from '../types';
import {getSupportedVersionsText, numberOfWays} from '../utils';
import {FeatureCryptoDepositBanner} from './FeatureCryptoDepositBanner';

const bannerTestId = 'CRYPTO_DEPOSIT_BANNER';
const bannerLinkTestId = 'CRYPTO_DEPOSIT_BANNER_LINK';

export const CryptoDepositBanner: React.FC<WithBoxProps<CryptoDepositProps>> =
  observer(({ticker, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      walletVersionsTitles,
      versionsListHasErc20OrBep20orPolygon,
      tickerFormatted,
      hasCaseSensitiveAddress,
    } = FeatureCryptoDepositBanner.use({ticker});

    // NOTE: For the Bitcoin we show version related banner from the CryptoDepositBannerByVersion folder
    if (ticker === 'btc') {
      return null;
    }

    if (ticker === 'bch') {
      return (
        <Banner variant="attention" testID={bannerTestId} {...boxProps}>
          {t('surface.wallets.crypto_deposit.alert_bch')}
        </Banner>
      );
    }

    if (versionsListHasErc20OrBep20orPolygon) {
      return (
        <Banner variant="attention" testID={bannerTestId} {...boxProps}>
          <Text>
            <Trans
              t={t}
              components={{
                Link: (
                  <Link
                    url={t(
                      'surface.wallets.crypto_deposit.url_erc20_bep20_networks'
                    )}
                    testID={bannerLinkTestId}
                  />
                ),
              }}
              values={{
                ticker: ticker.toUpperCase(),
                ways: numberOfWays(walletVersionsTitles, t),
                versions: getSupportedVersionsText(walletVersionsTitles, t),
              }}
              i18nKey="surface.wallets.crypto_deposit.alert_erc20_or_bep20"
            />
          </Text>

          {hasCaseSensitiveAddress && (
            <Text>
              {'\n\n'}
              {t('surface.wallets.crypto_deposit.address_case_sensitivity', {
                ticker: tickerFormatted,
              })}
            </Text>
          )}
        </Banner>
      );
    }

    if (hasCaseSensitiveAddress) {
      return (
        <Banner variant="attention" testID={bannerTestId} {...boxProps}>
          {t('surface.wallets.crypto_deposit.address_case_sensitivity', {
            ticker: tickerFormatted,
          })}
        </Banner>
      );
    }

    return null;
  });
