import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {MultiplySlider} from '@youtoken/ui.hodl-elements';
import {FlipHODLForm} from '../../form';

export const HodlFlipMultiplySection: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      form: {currentTariff, multiplier, setMultiplier},
      view: {loansInChain, leverageMin, leverageMax},
    } = useForm<FlipHODLForm>();

    return (
      <Box {...boxProps}>
        <MultiplySlider
          value={Number(multiplier)}
          minimumValue={leverageMin}
          maximumValue={leverageMax}
          loansInChain={loansInChain}
          onChange={setMultiplier}
          sliderKey={
            currentTariff?.id ? `slider-${currentTariff?.id}` : 'MultiplySlider'
          }
        />
      </Box>
    );
  }
);
