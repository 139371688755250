import * as React from 'react';
import {observer} from 'mobx-react';
import {useWizard} from '@youtoken/ui.elements';
import {CardDepositWizard} from '../../CardDepositWizard';
import {
  type FiatDepositWizardNavigator,
  type FiatDepositWizardScreenName,
} from '..';

export const FiatDepositWizardBankCard: React.FC = observer(() => {
  const {
    step: {
      props: {ticker, provider, operationMethod},
    },
    goBack,
  } = useWizard<
    FiatDepositWizardNavigator,
    FiatDepositWizardScreenName.BANK_CARD
  >();

  return (
    <CardDepositWizard
      ticker={ticker}
      provider={provider}
      method={operationMethod}
      onPressBack={goBack}
    />
  );
});
