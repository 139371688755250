import * as React from 'react';
import {observer} from 'mobx-react';
import {type IBankCard} from '@youtoken/ui.resource-bank-cards';
import {BankCardInfo} from './BankCardInfo';
import {Landing} from './Landing';
import {BankCardsFeature} from '../../../../../state/BankCardsFeature';

interface DetailsProps {
  card: IBankCard;
}

export const Details: React.FC<DetailsProps> = observer(({card}) => {
  const {openingCardStatuses} = BankCardsFeature.use({});

  if (openingCardStatuses.includes(card.status)) {
    return <Landing card={card} />;
  }

  return <BankCardInfo data={card} />;
});
