import {FEBlockState, IBlockBackendResponse} from '../MinerOverview/types';

export interface MinerUnauthorisedBlockData
  extends Partial<Omit<IBlockBackendResponse, 'status'>> {
  status: FEBlockState; // cant be used BE statuses
  coordinates: {
    r: number;
    s: number;
    q: number;
  };
}

export const mockBlocksData: MinerUnauthorisedBlockData[] = [
  {
    status: 'DISABLED',
    coordinates: {
      q: 0,
      r: 0,
      s: 0,
    },
  },
  {
    id: 'b6d50bd6-c04a-4153-82e1-58b6f0444d34',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: 0,
      r: 1,
      s: -1,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 0,
      r: -1,
      s: 1,
    },
  },
  {
    id: '855e377d-cffa-4214-98b9-9f7d10a6ed5d',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: 0,
      r: 2,
      s: -2,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 0,
      r: -2,
      s: 2,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 1,
      r: 0,
      s: -1,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 1,
      r: -1,
      s: 0,
    },
  },
  {
    id: '6278ae7e-f309-48d7-bc2f-28ced2bdbfd8',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: -1,
      r: 0,
      s: 1,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: -1,
      r: 1,
      s: 0,
    },
  },
  {
    id: '9d8636a2-5667-424c-99d5-a55d64c74b84',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: 1,
      r: 1,
      s: -2,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 1,
      r: -2,
      s: 1,
    },
  },
  {
    id: 'f6958fb5-b6ca-41f9-a42f-b2dd33ff8d52',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: -1,
      r: -1,
      s: 2,
    },
  },
  {
    id: '21210339-351c-42e5-939e-93900c309f1c',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: -1,
      r: 2,
      s: -1,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 2,
      r: 0,
      s: -2,
    },
  },
  {
    id: '6ba9457f-45fd-4e7e-b5ba-50699522f13f',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'AVAILABLE_ENOUGH_SPARKS',
    coordinates: {
      q: 2,
      r: -2,
      s: 0,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: 2,
      r: -1,
      s: -1,
    },
  },
  {
    id: 'a1f06e61-b5bb-4178-aeda-5011c2cd31ed',
    timeLeft: undefined,
    miningPrice: 3,
    earnAmount: '0.000048',
    earnAmountTicker: 'btc',
    status: 'DISABLED',
    coordinates: {
      q: -2,
      r: 0,
      s: 2,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: -2,
      r: 2,
      s: 0,
    },
  },
  {
    status: 'DISABLED',
    coordinates: {
      q: -2,
      r: 1,
      s: 1,
    },
  },
];
