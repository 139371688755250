import * as React from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';
import {Heading, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {ProfileSurfaceCell} from '@youtoken/ui.surface-more';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export const Profile: React.FC = observer(() => {
  const {t} = useTranslation();

  const {forceIdentify} = AuthMeResource.use({});

  return (
    <InnerPageShell>
      <Helmet title={t('surface.profile.tab_title')} />
      <Box>
        {forceIdentify && (
          <Box
            pb={24}
            ml={{default: 0, tablet: -12}}
            flexDirection="row"
            justifyContent="flex-start"
          >
            <Button
              type="ghost"
              onPress={SHARED_ROUTER_SERVICE.goBack}
              ml={-5}
              icon="chevron_left"
            >
              {t('surface.profile.form_confirm_phone.action.back')}
            </Button>
          </Box>
        )}
        <Heading
          variant="$heading-01-responsive"
          mb={24}
          pl={{default: 12, tablet: 0}}
        >
          {t('surface.profile.heading.title')}
        </Heading>
        <ProfileSurfaceCell />
      </Box>
    </InnerPageShell>
  );
});
