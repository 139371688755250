import {custom} from 'serializr';
import Big from 'big.js';
import {
  ERROR_TYPE,
  createCustomErrorFromGenericError,
} from '@youtoken/ui.errors';

export const bigNumber = () => {
  return custom(
    (value: Big) => value.toFixed(),
    (jsonValue: string, context) => {
      try {
        return Big(jsonValue);
      } catch (error) {
        throw createCustomErrorFromGenericError(
          error as Error,
          ERROR_TYPE.SERIALIZATION_ERROR,
          {
            tags: {
              name: context.args?.nameOrIndex,
            },
            extra: {
              name: context.args?.nameOrIndex,
              json: context.rootContext?.json,
            },
            fingerprint: [
              'bigNumber',
              context.args?.nameOrIndex,
              '{{ default }}',
            ],
          }
        );
      }
    },
    {
      beforeDeserialize: (
        callback,
        jsonValue,
        _jsonParentValue,
        _propNameOrIndex,
        context
      ) => {
        context.args = context.args || {};
        context.args.nameOrIndex = _propNameOrIndex.toString();

        callback(null, jsonValue);
      },
    }
  );
};

export const bigNumberNullable = () => {
  return custom(
    (value: Big | null, ...rest) => {
      if (value === null) {
        return value;
      }

      return bigNumber().serializer(value, ...rest);
    },
    (jsonValue, context, _oldValue, callback) => {
      if (jsonValue === null) {
        return callback(null, jsonValue);
      }

      return bigNumber().deserializer(jsonValue, callback, context);
    }
  );
};
