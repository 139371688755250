import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {minBy, maxBy} from 'lodash';
import {formatPercentTillPrecision} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {invariant, warning} from '@youtoken/ui.utils';
import {createResource} from '@youtoken/ui.data-storage';
import {filterAndSortTerms, getMarketsByTerms} from './utils';
import {TariffLoansRegular} from './TariffsLoansRegular';

export class TariffsLoansRegularResource extends createResource({
  getKey: () => `tariffsLoansRegular`,
  getData: () => {
    return TRANSPORT.API.get(`/v2/loan/tariffs`).then(res => {
      return filterAndSortTerms(
        deserialize(TariffLoansRegular, res.data as any[])
      ) as TariffLoansRegular[];
    });
  },
}) {
  @computed
  public get minApr() {
    return minBy(this.data, term => {
      return term.apr.toNumber();
    })?.apr;
  }

  @computed
  public get minAprFormatted() {
    return formatPercentTillPrecision(this.minApr, 4);
  }

  @computed
  public get maxApr() {
    return maxBy(this.data, term => {
      return term.apr.toNumber();
    })?.apr;
  }

  @computed
  public get maxAprFormatted() {
    return formatPercentTillPrecision(this.maxApr, 4);
  }

  @computed.struct
  public get loanMarkets() {
    return getMarketsByTerms(this.data);
  }

  /**
   * get loanMarkets with whiteList in default order (loanMarkets and loanMarketsTickers)
   * const loanMarkets = tariffsResource.getLoanMarketsWithWhiteList(walletsResource.loanEnabledTickers) - get markets only with loanEnabled
   */
  getLoanMarketsWithWhiteList = computedFn((whiteList: string[]) => {
    return Object.keys(this.loanMarkets)
      .filter(ticker => {
        return whiteList.includes(ticker);
      })
      .reduce((result, ticker) => {
        invariant(
          this.loanMarkets[ticker],
          'cannot find loan market by ticker'
        );

        const tickers = this.loanMarkets[ticker]!.filter(ticker => {
          return whiteList.includes(ticker);
        });

        warning(
          tickers.length,
          `TariffsLoansRegularResource: loan market by ticker with white list has empty tickers`,
          {
            ticker,
          },
          {
            whiteList,
          }
        );

        if (tickers.length) {
          result[ticker] = tickers;
        }

        return result;
      }, {} as {[key: string]: string[]});
  });
}
