import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Separator} from '@youtoken/ui.elements';
import {MenuItemBase} from '../MenuItemBase';
import {NotificationsBase} from './NotificationsBase';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

const isNative = Platform.select({
  native: true,
  default: false,
});

const handlePressNative = () => {
  SHARED_ROUTER_SERVICE.navigate('Notifications', {});
};

export const Notifications: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    setAlerts,
    setUpdates,
    data: {alerts, updates},
  } = AuthMeResource.use({});

  return (
    <MenuItemBase
      icon="bell"
      title={t('surface.more.item_name.notifications')}
      subtitle={t('surface.profile.preferences.notifications.text')}
      onPress={isNative ? handlePressNative : undefined}
      {...boxProps}
    >
      <Box flexDirection="column" flexWrap="wrap" alignItems="stretch">
        <Separator mb={16} />
        <NotificationsBase
          data={alerts}
          setNotifications={setAlerts}
          title={t(
            'surface.profile.preferences.notifications.section.alerts.title'
          )}
          description={t(
            'surface.profile.preferences.notifications.section.alerts.description'
          )}
        />
        <Separator my={16} />
        <NotificationsBase
          data={updates}
          setNotifications={setUpdates}
          title={t(
            'surface.profile.preferences.notifications.section.updates.title'
          )}
          description={t(
            'surface.profile.preferences.notifications.section.updates.description'
          )}
        />
      </Box>
    </MenuItemBase>
  );
});
