import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';

interface SavingsProgressBarProps extends BoxProps {
  color?: 'blue' | 'green' | 'red';
  size?: 'tiny' | 'small' | 'medium';
  value: number;
  title?: string;
  valueText?: string;
  amountTestID?: string;
}

// this prevents empty spaces;
const getFullText = (title?: string, valueText?: string) => {
  if (title && valueText) {
    return `${title} ${valueText}`;
  }

  if (title) {
    return title;
  }

  return valueText;
};

const getBarBgColor = (color: string) => {
  switch (color) {
    case 'blue':
      return '$interactive-03';
    case 'red':
      return '$danger-02';
    default:
      return '$success-03';
  }
};

const getColor = (color: string) => {
  switch (color) {
    case 'blue':
      return '$interactive-01';
    case 'red':
      return '$danger-04';
    default:
      return '$success-01';
  }
};

export const SavingsProgressBar: React.FC<SavingsProgressBarProps> = ({
  value = 0,
  title,
  valueText,
  color = 'blue',
  size = 'medium',
  amountTestID,
  ...rest
}) => {
  const lessThanThird = !value || value < 0.3;

  const height = size === 'medium' ? 20 : size === 'small' ? 16 : 8;
  const textSize = size === 'medium' ? '$body-02' : '$body-03';
  const padding = size === 'medium' ? 8 : 4;

  const textColorInside = '$text-04';
  const barBgColor = getBarBgColor(color);
  const barColor = getColor(color);

  return (
    <Box
      backgroundColor={barBgColor}
      borderRadius={height / 2}
      position="relative"
      flexDirection="row"
      overflow="hidden"
      height={height}
      {...rest}
    >
      <Box
        height={height}
        width={`${Math.min(value, 1) * 100}%`}
        minWidth={height}
        backgroundColor={barColor}
        justifyContent="center"
        borderRadius={10}
      >
        {!lessThanThird && (
          <Box
            flex={1}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            px={padding}
          >
            <Box>
              {Boolean(title) && (
                <Text variant={textSize} color={textColorInside}>
                  {title}
                </Text>
              )}
            </Box>
            <Box>
              {Boolean(valueText) && (
                <Text
                  variant={textSize}
                  color={textColorInside}
                  testID={amountTestID}
                >
                  {valueText}
                </Text>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {lessThanThird && (
        <Box justifyContent="center" px={padding}>
          <Text variant={textSize} color={barColor} testID={amountTestID}>
            {getFullText(title, valueText)}
          </Text>
        </Box>
      )}
    </Box>
  );
};
