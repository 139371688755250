import * as React from 'react';
import {observer} from 'mobx-react';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type TextProps, Text} from '@youtoken/ui.primitives';

export interface AccountLevelSavingsYeldLimitProps extends TextProps {
  amount: number;
}

export const AccountLevelSavingsYeldLimit: React.FC<AccountLevelSavingsYeldLimitProps> =
  observer(({amount, ...textProps}) => {
    const {t} = useTranslation();

    return (
      <Text
        testID="ACCOUNT_LEVEL_SAVINGS_YELD_LIMIT"
        variant="$body-02"
        color="$text-02"
        {...textProps}
      >
        <Trans
          t={t}
          components={{
            Strong: <Text color="$text-01" />,
          }}
          values={{amount: priceFormatterInThousands(amount)}}
          i18nKey="surface.loyalty.savings.yield_limit"
        />
      </Text>
    );
  });
