import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {type BoxProps} from '@youtoken/ui.primitives';
import {RewardsStatistics} from '../../components';

export const RewardsStatisticsV3Smart: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      data: {totalEarnedReferralUSD, referralsCount},
    } = RewardsResource.use({});

    return (
      <RewardsStatistics
        totalEarnedUSD={totalEarnedReferralUSD}
        referralsCount={referralsCount}
        {...boxProps}
      />
    );
  }
);
