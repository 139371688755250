import React from 'react';
import {Icon, IconPaymentMethod} from '@youtoken/ui.icons';
import {Images} from '@youtoken/ui.images';
import {
  currencySignMap,
  type currencyName,
} from '@youtoken/ui.resource-auth-me';
import {Box, Text, useTheme} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {PaymentSystem} from '@youtoken/ui.resource-bank-cards';
import {type BankCardType} from '../../types';
import {BaseBankCard} from '../BaseBankCard';

interface BankCardProps {
  type: BankCardType;
  cardNumber?: string;
  balance?: string;
  currency?: currencyName;
  paymentSystem?: PaymentSystem;
}

export const BankCard: React.FC<BankCardProps> = ({
  type,
  cardNumber,
  balance,
  currency = 'eur',
  paymentSystem = PaymentSystem.VISA,
}) => {
  const {colors} = useTheme();
  const {t} = useTranslation();

  const currencySign = currencySignMap[currency];
  const color = type === 'virtual' ? '$text-01' : '$text-04';
  const secondaryColor = type === 'virtual' ? '$text-02' : '$ui-02';
  const imageColor = colors[color];
  const showBalance = balance !== null;
  const paymentSystemIcon =
    paymentSystem === PaymentSystem.MASTERCARD ? 'mastercard_plain' : 'visa';

  const cardNumberFormatted = React.useMemo(() => {
    return cardNumber && /\d/g.test(cardNumber)
      ? `• ${cardNumber}`
      : cardNumber;
  }, [cardNumber]);

  return (
    <BaseBankCard type={showBalance ? type : `${type}_order`} size="big">
      <Box p={16} flexGrow={1}>
        <Box flexGrow={1}>
          {showBalance && (
            <Box flexDirection="row">
              <Box flexGrow={1} ml={4}>
                <Text variant="$body-02" color={secondaryColor} mb={2}>
                  {t('component.bank_card.balance')}
                </Text>
                <Text variant="$heading-02" color={color}>
                  {currencySign}
                  {balance}
                </Text>
              </Box>
              <Box>
                <Images.youHodlerLogo
                  width={20}
                  height={20}
                  color={imageColor}
                />
              </Box>
            </Box>
          )}
          {!showBalance && (
            <Box>
              <Images.youHodlerLogoFull
                width={88}
                height={20}
                color={imageColor}
              />
            </Box>
          )}
        </Box>

        <Box flexDirection="row" alignItems="flex-end">
          <Box ml={4} flexGrow={1}>
            {Boolean(cardNumber) && (
              <Text variant="$body-03" mb={5} color={secondaryColor}>
                {cardNumberFormatted}
              </Text>
            )}
            {type === 'plastic' && (
              <Text variant="$body-02" color={color}>
                {/* TODO cards: change lokalize */}
                Debit
                {/*{t('component.bank_card.debit_card')}*/}
              </Text>
            )}
            {type === 'virtual' && (
              <Box flexDirection="row" alignItems="center">
                <Icon name="cloud" color={color} size={16} />
                <Text variant="$body-02" color={color} ml={4}>
                  {/* TODO cards: change lokalize */}
                  Virtual
                  {/*{t('component.bank_card.virtual_card')}*/}
                </Text>
              </Box>
            )}
          </Box>

          {paymentSystem && (
            <Box mb={-8}>
              <IconPaymentMethod
                name={paymentSystemIcon}
                color={color}
                size={32}
              />
            </Box>
          )}
        </Box>
      </Box>
    </BaseBankCard>
  );
};
