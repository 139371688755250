import * as React from 'react';
import {Modal} from '@youtoken/ui.modal';
import {DescriptionModalContentSmart} from './components';

interface SectorsDescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SectorsDescriptionModal: React.FC<SectorsDescriptionModalProps> =
  React.memo(({isOpen, onClose}) => {
    return (
      <Modal
        shouldShowHeaderSeparator={false}
        snapPoints={['60%', '90%']}
        shouldBeScrollable
        isOpen={isOpen}
        onClose={onClose}
        borderRadius={14}
        webHeight={588}
        nativeStackBehavior="push"
        webOverlayBackgroundColor="$transparent"
      >
        <DescriptionModalContentSmart />
      </Modal>
    );
  });
