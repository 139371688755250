import {action, computed, observable, reaction, runInAction} from 'mobx';
import {Platform} from 'react-native';
import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {openBrowser} from './utils';
import {
  BankCardTransaction,
  BankCardTransactionsResponse,
} from './BankCardTransactionsResponse';
import {type BankCardTransactionsListSection} from './types/transactions';

export const PAGE_SIZE = 20;

export type BankCardTransactionsArgs = {
  cardId: string;
  // pass last transaction id on the current page to load next page
  transactionIdFrom?: string;
  // pass first transaction id on the current page to load previous page
  transactionIdUntil?: string;
};

export class BankCardTransactionsResource extends createStaticResource<
  BankCardTransactionsArgs,
  BankCardTransactionsResponse
>({
  getKey: () => `bankCardTransactions`,
  getData: ({cardId, transactionIdFrom, transactionIdUntil}) => {
    const returnUrl = Platform.select({
      native: '/wallets/cards/consent-final',
      web: '/wallets/cards',
    });

    return TRANSPORT.API.get('/v1/intergiro/cards/history', {
      params: {
        cardId,
        limit: PAGE_SIZE,
        redirectURL: returnUrl,
        transactionIdFrom,
        transactionIdUntil,
      },
    }).then(({data}) => {
      if ('redirectURL' in data && data.redirectURL) {
        openBrowser(data.redirectURL).then(() => {
          if (Platform.OS !== 'web') {
            SHARED_ROUTER_SERVICE.navigate('BankCards');
          }
        });
        return {rows: [], hasNext: false};
      }

      return deserialize(BankCardTransactionsResponse, data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  @observable cachedPages: BankCardTransaction[][] = [];

  @observable currentPageIndex: number = -1;

  constructor(
    args: BankCardTransactionsArgs,
    data: BankCardTransactionsResponse
  ) {
    super(args, data);

    reaction(
      () => this.data?.rows,
      rows => {
        if (rows) {
          runInAction(() => {
            this.cachedPages.push(rows);
            this.currentPageIndex++;
          });
        }
      },
      {fireImmediately: true}
    );
  }

  // #region pagination
  @computed get currentPage() {
    return this.cachedPages[this.currentPageIndex] || [];
  }

  @computed get hasNext() {
    return (
      this.data?.hasNext || this.currentPageIndex < this.cachedPages.length - 1
    );
  }

  @computed get hasPrev() {
    return this.currentPageIndex > 0;
  }

  @action toNextPage = () => {
    if (this.hasNext) {
      const lastTransactionId = this.currentPage.slice(-1)[0]?.transactionId;

      if (lastTransactionId) {
        if (this.currentPageIndex < this.cachedPages.length - 1) {
          this.currentPageIndex++;
        } else {
          this.args.transactionIdFrom = lastTransactionId;
        }
      }
    }
  };

  @action toPrevPage = () => {
    if (this.hasPrev) {
      this.currentPageIndex--;
    }
  };
  // #endregion pagination

  @computed get sectionsByDate(): BankCardTransactionsListSection[] {
    const mapByDay = this.currentPage.reduce((acc, item) => {
      const day = item.createdAtFormatted;
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day]!.push(item);
      return acc;
    }, {} as Record<string, BankCardTransaction[]>);

    return Object.entries(mapByDay).map(([title, data]) => ({title, data}));
  }
}
