import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Pagination} from '@youtoken/ui.elements';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {smoothScroll} from '@youtoken/ui.utils';
import {ActiveHODLsPaginationFeature} from '../../../features';
import {HODLsList} from '../../components';

export const ACTIVE_HEADER_ID = 'active_hodls_heading';

interface HODLsActiveBlockProps extends BoxProps {
  handleNavigate: (param: {}) => void;
}

export const HODLsActiveBlock: React.FC<HODLsActiveBlockProps> = observer(
  ({handleNavigate, ...boxProps}) => {
    const {t} = useTranslation();

    const {activePage, totalPages, hodlsWithLimit} =
      ActiveHODLsPaginationFeature.use({});

    const handlePageChange = React.useCallback(
      (pageNumber: number) => {
        handleNavigate({
          pageActive: pageNumber,
        });
        setTimeout(() => {
          smoothScroll(ACTIVE_HEADER_ID);
        }, 0);
      },
      [handleNavigate]
    );

    return (
      <Box nativeID={ACTIVE_HEADER_ID} {...boxProps}>
        <Box mb={16} px={{default: 20, desktop: 0}}>
          <Heading variant="$heading-01">
            {t('surface.hodls.portfolio.active_title')}
          </Heading>
        </Box>
        <HODLsList hodlsList={hodlsWithLimit} />
        {totalPages > 1 && (
          <Box px={{default: 20, desktop: 0}} my={24}>
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    );
  }
);
