import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';
import {FlipHODLForm} from '../../form';

export const FooterSection: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    form: {isLoading, submitForm},
  } = useForm<FlipHODLForm>();

  return (
    <Box flexDirection="row" mt={24}>
      <Box flexBasis={0} flexGrow={1}>
        <Button
          size="large"
          type="primary"
          flexGrow={1}
          loading={isLoading}
          onPress={submitForm}
        >
          {t('surface.hodls.flip_order.action')}
        </Button>
      </Box>
    </Box>
  );
});
