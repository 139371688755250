import * as React from 'react';
import {observer} from 'mobx-react';
import type {LayoutChangeEvent} from 'react-native';
import type {HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  HODLItemBriefStatus,
  HODLItemInitialProperties,
} from '../../../components';

interface OverviewSectionProps extends BoxProps {
  hodl: HODLItem;
}

export const OverviewSection: React.FC<OverviewSectionProps> = observer(
  ({hodl, children, ...otherProps}) => {
    const [shouldShowDirectionTitle, setShouldShowDirectionTitle] =
      React.useState(false);

    const handleLayout = React.useCallback(
      ({
        nativeEvent: {
          layout: {width},
        },
      }: LayoutChangeEvent) => {
        setShouldShowDirectionTitle(width > 360);
      },
      []
    );

    return (
      <Box flexDirection={{default: 'column', tablet: 'row'}} {...otherProps}>
        <Box
          flexDirection="row"
          flexGrow={1}
          justifyContent="space-between"
          alignItems="center"
          onLayout={handleLayout}
        >
          <HODLItemInitialProperties
            showDirectionTitle={shouldShowDirectionTitle}
            hodl={hodl}
          />
          <HODLItemBriefStatus hodl={hodl} isDetailedPage />
        </Box>
        {children}
      </Box>
    );
  }
);
