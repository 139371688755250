import React from 'react';
import {observer} from 'mobx-react';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {
  HODLPreviewChartSmartCell,
  ResizableBoxWithOverlay,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';

export interface HODLItemChartPreviewProps {
  hodl: HODLItem;
  height: number;
}

export const HODLItemChartPreview: React.FC<HODLItemChartPreviewProps> =
  observer(({hodl, height}) => {
    const [
      {
        size: {width},
      },
      bind,
    ] = useResizableBox();

    const {
      hodlResultFormatted: {resultDirection},
    } = hodl;
    const direction = hodl.chartDirection ?? resultDirection;

    return (
      <ResizableBoxWithOverlay width="100%" height={height} {...bind}>
        {!!width && hodl.chartData.data?.length >= 2 && (
          <HODLPreviewChartSmartCell
            fromTicker={hodl.baseTicker}
            toTicker={hodl.quoteTicker}
            precision={hodl.precision}
            data={hodl.chartData}
            width={width}
            height={height}
            startDate={hodl.startDate}
            endDate={hodl.endDate}
            takeProfit={hodl.takeProfit}
            marginCall={hodl.marginCall}
            initialPrice={hodl.initialPrice}
            triggerPrice={hodl.triggerPrice}
            closedPrice={hodl.closedPrice}
            reversed={hodl.data.isShort}
            pending={hodl.isPendingLike}
            direction={direction}
            isClosedLike={hodl.isClosedLike}
            isOpeningLike={hodl.isOpening}
          />
        )}
      </ResizableBoxWithOverlay>
    );
  });
