import * as React from 'react';
import {Modal} from '../_common';
import {ComissionsTable} from '@youtoken/ui.legacy-components';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';

export const ModalCommissions: typeof Modal = props => {
  const {t} = useTranslation();

  return (
    <Modal {...props}>
      <Box minHeight={200}>
        <Heading variant="$heading-02-responsive" py={24} textAlign="center">
          {t('surface.profile.modal_hodl_specification.title')}
        </Heading>
        <Box height={1} backgroundColor="$ui-02" />
        <ComissionsTable />
      </Box>
    </Modal>
  );
};
