import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  BankCardsResource,
  type IBankCard,
} from '@youtoken/ui.resource-bank-cards';
import {GiftCircle} from './GiftCircle';
import {Instructions} from './Instructions';
import {CardFeatures} from './CardFeatures';
import {LearnMore} from './LearnMore';
import {OnboardingNoticeModal} from './OnboardingNoticeModal';
import {BankCardsFeature} from '../../../../../../state/BankCardsFeature';
import {FooterContext} from '../../../../FooterContext';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';

interface LandingProps {
  card: IBankCard;
}

export const Landing: React.FC<LandingProps> = observer(
  ({card: {format, status}}) => {
    const {t} = useTranslation();

    const context = React.useContext(FooterContext);

    const {
      verifyCardCanBeOpenedOrRedirect,
      isCheckingIfCardCanBeOpened,
      openCard,
    } = BankCardsFeature.use({});

    const {isCardOpening} = BankCardsResource.use({});

    const [isModalOpen, setModalOpen] = React.useState(false);
    const closeModal = React.useCallback(() => setModalOpen(false), []);

    const handleOpenModalPress = React.useCallback(() => {
      verifyCardCanBeOpenedOrRedirect(format)
        .then(() => {
          setModalOpen(true);
        })
        .catch(() => {
          LOCAL_NOTIFICATIONS.error({
            text: t('common.errors.smth_went_wrong'),
          });
        });
    }, [verifyCardCanBeOpenedOrRedirect, format]);

    const handleOpenCardPress = React.useCallback(() => {
      openCard(format);
    }, [format]);

    const buttonLabel = React.useMemo(() => {
      if (format === 'virtual') {
        switch (status) {
          case 'AVAILABLE':
            return t('surface.bank_cards.landing.button_order');
          case 'ONBOARDING':
            return t('surface.bank_cards.promo_banner.finish_ordering');
          case 'CREATING':
            return t('surface.bank_cards.promo_banner.review');
        }
      }
      throw new Error(
        'Card format error: Physical bank cards are not yet supported'
      );
    }, [format, status]);

    React.useEffect(() => {
      context?.setButton?.(
        <Box mx={24}>
          <Button
            size="large"
            icon={
              format === 'virtual' && status === 'CREATING'
                ? 'pending'
                : undefined
            }
            disabled={format === 'virtual' && status === 'CREATING'}
            loading={isCheckingIfCardCanBeOpened}
            onPress={handleOpenModalPress}
          >
            {buttonLabel}
          </Button>
        </Box>
      );
      return () => {
        context?.setButton?.(null);
      };
    }, [
      handleOpenModalPress,
      isCheckingIfCardCanBeOpened,
      format,
      buttonLabel,
    ]);

    return (
      <>
        <Box flexGrow={1} mx={32}>
          <GiftCircle />
          <Heading
            variant="$heading-01"
            color="$interactive-01"
            textAlign="center"
            mt={16}
          >
            {t('surface.bank_cards.landing.main_title')}
          </Heading>

          <Instructions mt={32} />
          <CardFeatures mt={32} />
          <LearnMore my={24} />
        </Box>

        <OnboardingNoticeModal
          isOpen={isModalOpen}
          onClose={closeModal}
          isLoading={isCardOpening}
          onContinuePress={handleOpenCardPress}
        />
      </>
    );
  }
);
