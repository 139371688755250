import * as React from 'react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AssetsItem} from '@youtoken/ui.elements';
import {SavingsApr} from '../Apr';

export interface SavingsAssetProps {
  baseTicker: string;
  baseAmountFormatted: string;
  quoteTicker: string;
  quoteAmountFormatted: string;
  level: number;
  aprFormatted: string;
}

export const SavingsAsset: React.FC<SavingsAssetProps & BoxProps> = ({
  baseTicker,
  baseAmountFormatted,
  quoteTicker,
  quoteAmountFormatted,
  level,
  aprFormatted,
  children,
  ...boxProps
}) => {
  return (
    <AssetsItem
      primaryTicker={baseTicker}
      primaryAmountFormatted={baseAmountFormatted}
      secondaryTicker={quoteTicker}
      secondaryAmountFormatted={quoteAmountFormatted}
      secondaryExtension={
        <SavingsApr level={level} aprFormatted={aprFormatted} />
      }
      {...boxProps}
    >
      {children}
    </AssetsItem>
  );
};
