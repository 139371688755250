import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const PassportIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2.5H5a.5.5 0 00-.5.5v14a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5zM5 1a2 2 0 00-2 2v14a2 2 0 002 2h10a2 2 0 002-2V3a2 2 0 00-2-2H5z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 15.5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm3-3.5a4 4 0 100-8 4 4 0 000 8zm1.527-1.417A3.002 3.002 0 0012.96 8.5h-.974c-.05.788-.213 1.506-.458 2.083zM10.982 8.5c-.054.767-.232 1.42-.462 1.881-.14.279-.28.45-.387.541a.423.423 0 01-.128.077L10 11h-.005a.424.424 0 01-.127-.077c-.108-.092-.248-.263-.388-.542-.23-.46-.408-1.114-.462-1.881h1.964zm1.003-1h.974a3.002 3.002 0 00-1.432-2.083c.245.577.408 1.295.458 2.083zM10 5h.005l.02.007a.45.45 0 01.108.07c.108.092.248.263.387.542.23.46.408 1.114.462 1.881H9.018c.054-.767.232-1.42.462-1.881.14-.279.28-.45.387-.541A.426.426 0 019.995 5H10zm-1.527.417A3.002 3.002 0 007.04 7.5h.974c.05-.788.214-1.506.458-2.083zM8.015 8.5h-.974a3.003 3.003 0 001.432 2.083c-.244-.577-.408-1.295-.458-2.083z"
          fill={color}
        />
      </Svg>
    );
  }
);
