import * as React from 'react';
import {Platform} from 'react-native';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {
  UmaWithdrawalSurface,
  UmaWelcomeSurface,
  type UmaWithdrawalFormOnSuccessArgs,
} from '@youtoken/ui.surfaces-uma';
import {i18n} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {UmaCellsScrollViewWrapper} from './components';
import {HeaderBase} from '../components';
import {
  type FiatWithdrawalWizardNavigator,
  FiatWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getUmaWithdrawalTitle = (ticker: string, isUmaOpened: boolean) => {
  return i18n.t(
    isUmaOpened ? 'surface.wallets.uma.withdrawal' : 'surface.wallets.uma',
    {
      ticker: ticker.toUpperCase(),
    }
  );
};

export const UmaWithdrawalCell: React.FC = cell(
  () => {
    const {isUmaOpened} = UmaInfoResource.use({});

    const {
      step: {
        props: {ticker, uma, amount},
      },
      navigate,
      goBack,
    } = useWizard<
      FiatWithdrawalWizardNavigator,
      FiatWithdrawalWizardScreenName.UMA_WITHDRAWAL
    >();

    const handlePressCreate = React.useCallback(() => {
      navigate(FiatWithdrawalWizardScreenName.UMA_CREATE);
    }, [navigate]);

    const handleSubmitWithdrawal = React.useCallback(
      (props: UmaWithdrawalFormOnSuccessArgs) => {
        navigate(
          FiatWithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION,
          props
        );
      },
      [navigate]
    );

    return (
      <>
        {showHeader && (
          <>
            <HeaderBase onPressBack={goBack}>
              {getUmaWithdrawalTitle(ticker, isUmaOpened)}
            </HeaderBase>
            <Separator />
          </>
        )}

        {isUmaOpened ? (
          <UmaWithdrawalSurface
            ticker={ticker}
            uma={uma}
            amount={amount}
            onSuccess={handleSubmitWithdrawal}
          />
        ) : (
          <UmaWelcomeSurface onPress={handlePressCreate} />
        )}
      </>
    );
  },
  {
    CellWrap: UmaCellsScrollViewWrapper,
  }
);
