import React from 'react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {getToken} from './getToken';
import {getRecaptchaKey} from '../getKey';
import {createRecaptchaError} from '../../utils/recaptchaErrors';
import {CustomError} from '@youtoken/ui.errors';

declare global {
  interface Window {
    grecaptcha: {
      ready: (cb: Function) => void;
      execute: (siteKey: string, params: {action: string}) => Promise<string>;
    };
  }
}

export const RecaptchaV3Component = React.forwardRef<{
  requestToken: (action: string) => Promise<string>;
}>((_props, ref) => {
  React.useImperativeHandle(ref, () => {
    return {
      requestToken: (action: string) => {
        return getToken(getRecaptchaKey(ENVIRONMENT.APP_ENV), action).catch(
          (error: CustomError | Error) => {
            if (error instanceof CustomError) {
              throw error;
            }

            throw createRecaptchaError('v3', error.message);
          }
        );
      },
    };
  });

  return <></>;
});
