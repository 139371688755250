import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {ReferralsAndBonusesSummaryV1Smart} from './v1';
import {ReferralsAndBonusesSummaryV3Smart} from './v3';
import {ReferralsAndBonusesSummaryErrorFallback} from './ErrorFallback';

export const ReferralsAndBonusesSummarySmart = cell(
  () => {
    const {
      data: {rewardVersion},
    } = AuthMeResource.use({});

    if (rewardVersion === 3) {
      return <ReferralsAndBonusesSummaryV3Smart />;
    }

    return <ReferralsAndBonusesSummaryV1Smart />;
  },
  {
    CellWrap: props => <Box flex={1} minWidth={130} {...props} />,
    ErrorFallback: ReferralsAndBonusesSummaryErrorFallback,
  }
);
