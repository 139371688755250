import * as React from 'react';
import {observer} from 'mobx-react';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Box} from '@youtoken/ui.primitives';
import {SavingsSliderSlide} from './Slide';
import {Illustration} from '@youtoken/ui.elements';

export const SavingsSliderSlideWelcome: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    lastLevelData: {
      saving: {maxTickersAPR},
    },
  } = LoyaltyResource.use({});

  return (
    <SavingsSliderSlide
      img={
        <Box width={200} height={200}>
          <Illustration
            name="illustration-10"
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      }
      title={t('surface.savings.slider.welcome.title')}
      description={t('surface.savings.slider.welcome.description', {
        apr: formatPercent(maxTickersAPR),
      })}
    />
  );
});
