import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text, TouchableText} from '@youtoken/ui.primitives';

export interface ValidationMessageTouchableMinAndMaxProps {
  valueMin: string;
  valueMax: string;
  handlePressMin: () => void;
  handlePressMax: () => void;
  fieldHasError: boolean;
}

export const ValidationMessageTouchableMinAndMax: React.FC<ValidationMessageTouchableMinAndMaxProps> =
  observer(
    ({valueMin, valueMax, handlePressMin, handlePressMax, fieldHasError}) => {
      const {t} = useTranslation();

      return (
        <Text
          color={fieldHasError ? '$danger-01' : '$text-02'}
          variant="$body-02"
        >
          <Trans
            t={t}
            i18nKey={'validation.SHOULD_BE_BETWEEN_TOUCHABLE'}
            components={{
              TouchableMin: (
                <TouchableText color="$text-05" onPress={handlePressMin} />
              ),
              TouchableMax: (
                <TouchableText color="$text-05" onPress={handlePressMax} />
              ),
            }}
            values={{min: valueMin, max: valueMax}}
          />
        </Text>
      );
    }
  );
