import React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardSummaryResource} from '@youtoken/ui.resources-rewards';
import {Box, Text} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {BonusesList} from './BonusesList';
import {ReferralsWidgetSmart} from '../../../smarts';

export const ReferralsAndBonusesScreenV1 = cell(() => {
  const {t} = useTranslation();

  const {data} = RewardSummaryResource.use({});

  return (
    <Box>
      <Box mb={16}>
        <Text variant="$body-02" color="$text-02" mb={4}>
          {t('surface.referral_program.referrals_and_bonuses.total_earned')}
        </Text>
        <Text variant="$heading-01" color="$text-01">
          {data.earnedTotalUSD}
        </Text>
      </Box>
      <Box mb={{tablet: 24, default: 16}}>
        <ReferralsWidgetSmart />
      </Box>
      <BonusesList />
    </Box>
  );
});
