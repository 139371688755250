/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ledger_swap.NewFundResponse', null, global);
// goog.exportSymbol('proto.ledger_swap.NewSellResponse', null, global);
// goog.exportSymbol('proto.ledger_swap.NewTransactionResponse', null, global);
// goog.exportSymbol('proto.ledger_swap.UDecimal', null, global);
//
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.ledger_swap.NewTransactionResponse = function (opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.ledger_swap.NewTransactionResponse, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   proto.ledger_swap.NewTransactionResponse.displayName =
//     'proto.ledger_swap.NewTransactionResponse';
// }
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
//   /**
//    * Creates an object representation of this proto suitable for use in Soy templates.
//    * Field names that are reserved in JavaScript and will be renamed to pb_name.
//    * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//    * For the list of reserved names please see:
//    *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
//    * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
//    *     for transitional soy proto support: http://goto/soy-param-migration
//    * @return {!Object}
//    */
//   proto.ledger_swap.NewTransactionResponse.prototype.toObject = function (
//     opt_includeInstance
//   ) {
//     return proto.ledger_swap.NewTransactionResponse.toObject(
//       opt_includeInstance,
//       this
//     );
//   };
//
//   /**
//    * Static version of the {@see toObject} method.
//    * @param {boolean|undefined} includeInstance Whether to include the JSPB
//    *     instance for transitional soy proto support:
//    *     http://goto/soy-param-migration
//    * @param {!proto.ledger_swap.NewTransactionResponse} msg The msg instance to transform.
//    * @return {!Object}
//    * @suppress {unusedLocalVariables} f is only used for nested messages
//    */
//   proto.ledger_swap.NewTransactionResponse.toObject = function (
//     includeInstance,
//     msg
//   ) {
//     var f,
//       obj = {
//         payinAddress: jspb.Message.getFieldWithDefault(msg, 1, ''),
//         payinExtraId: jspb.Message.getFieldWithDefault(msg, 2, ''),
//         refundAddress: jspb.Message.getFieldWithDefault(msg, 3, ''),
//         refundExtraId: jspb.Message.getFieldWithDefault(msg, 4, ''),
//         payoutAddress: jspb.Message.getFieldWithDefault(msg, 5, ''),
//         payoutExtraId: jspb.Message.getFieldWithDefault(msg, 6, ''),
//         currencyFrom: jspb.Message.getFieldWithDefault(msg, 7, ''),
//         currencyTo: jspb.Message.getFieldWithDefault(msg, 8, ''),
//         amountToProvider: msg.getAmountToProvider_asB64(),
//         amountToWallet: msg.getAmountToWallet_asB64(),
//         deviceTransactionId: jspb.Message.getFieldWithDefault(msg, 11, ''),
//       };
//
//     if (includeInstance) {
//       obj.$jspbMessageInstance = msg;
//     }
//     return obj;
//   };
// }
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.ledger_swap.NewTransactionResponse}
//  */
// proto.ledger_swap.NewTransactionResponse.deserializeBinary = function (bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.ledger_swap.NewTransactionResponse();
//   return proto.ledger_swap.NewTransactionResponse.deserializeBinaryFromReader(
//     msg,
//     reader
//   );
// };
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.ledger_swap.NewTransactionResponse} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.ledger_swap.NewTransactionResponse}
//  */
// proto.ledger_swap.NewTransactionResponse.deserializeBinaryFromReader =
//   function (msg, reader) {
//     while (reader.nextField()) {
//       if (reader.isEndGroup()) {
//         break;
//       }
//       var field = reader.getFieldNumber();
//       switch (field) {
//         case 1:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setPayinAddress(value);
//           break;
//         case 2:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setPayinExtraId(value);
//           break;
//         case 3:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setRefundAddress(value);
//           break;
//         case 4:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setRefundExtraId(value);
//           break;
//         case 5:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setPayoutAddress(value);
//           break;
//         case 6:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setPayoutExtraId(value);
//           break;
//         case 7:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setCurrencyFrom(value);
//           break;
//         case 8:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setCurrencyTo(value);
//           break;
//         case 9:
//           var value = /** @type {!Uint8Array} */ (reader.readBytes());
//           msg.setAmountToProvider(value);
//           break;
//         case 10:
//           var value = /** @type {!Uint8Array} */ (reader.readBytes());
//           msg.setAmountToWallet(value);
//           break;
//         case 11:
//           var value = /** @type {string} */ (reader.readString());
//           msg.setDeviceTransactionId(value);
//           break;
//         default:
//           reader.skipField();
//           break;
//       }
//     }
//     return msg;
//   };
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.serializeBinary =
//   function () {
//     var writer = new jspb.BinaryWriter();
//     proto.ledger_swap.NewTransactionResponse.serializeBinaryToWriter(
//       this,
//       writer
//     );
//     return writer.getResultBuffer();
//   };
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.ledger_swap.NewTransactionResponse} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.ledger_swap.NewTransactionResponse.serializeBinaryToWriter = function (
//   message,
//   writer
// ) {
//   var f = undefined;
//   f = message.getPayinAddress();
//   if (f.length > 0) {
//     writer.writeString(1, f);
//   }
//   f = message.getPayinExtraId();
//   if (f.length > 0) {
//     writer.writeString(2, f);
//   }
//   f = message.getRefundAddress();
//   if (f.length > 0) {
//     writer.writeString(3, f);
//   }
//   f = message.getRefundExtraId();
//   if (f.length > 0) {
//     writer.writeString(4, f);
//   }
//   f = message.getPayoutAddress();
//   if (f.length > 0) {
//     writer.writeString(5, f);
//   }
//   f = message.getPayoutExtraId();
//   if (f.length > 0) {
//     writer.writeString(6, f);
//   }
//   f = message.getCurrencyFrom();
//   if (f.length > 0) {
//     writer.writeString(7, f);
//   }
//   f = message.getCurrencyTo();
//   if (f.length > 0) {
//     writer.writeString(8, f);
//   }
//   f = message.getAmountToProvider_asU8();
//   if (f.length > 0) {
//     writer.writeBytes(9, f);
//   }
//   f = message.getAmountToWallet_asU8();
//   if (f.length > 0) {
//     writer.writeBytes(10, f);
//   }
//   f = message.getDeviceTransactionId();
//   if (f.length > 0) {
//     writer.writeString(11, f);
//   }
// };
//
// /**
//  * optional string payin_address = 1;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getPayinAddress =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 1, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setPayinAddress = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 1, value);
// };
//
// /**
//  * optional string payin_extra_id = 2;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getPayinExtraId =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 2, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setPayinExtraId = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 2, value);
// };
//
// /**
//  * optional string refund_address = 3;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getRefundAddress =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 3, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setRefundAddress = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 3, value);
// };
//
// /**
//  * optional string refund_extra_id = 4;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getRefundExtraId =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 4, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setRefundExtraId = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 4, value);
// };
//
// /**
//  * optional string payout_address = 5;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getPayoutAddress =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 5, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setPayoutAddress = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 5, value);
// };
//
// /**
//  * optional string payout_extra_id = 6;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getPayoutExtraId =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 6, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setPayoutExtraId = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 6, value);
// };
//
// /**
//  * optional string currency_from = 7;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getCurrencyFrom =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 7, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setCurrencyFrom = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 7, value);
// };
//
// /**
//  * optional string currency_to = 8;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getCurrencyTo = function () {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
// };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setCurrencyTo = function (
//   value
// ) {
//   jspb.Message.setProto3StringField(this, 8, value);
// };
//
// /**
//  * optional bytes amount_to_provider = 9;
//  * @return {!(string|Uint8Array)}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getAmountToProvider =
//   function () {
//     return /** @type {!(string|Uint8Array)} */ (
//       jspb.Message.getFieldWithDefault(this, 9, '')
//     );
//   };
//
// /**
//  * optional bytes amount_to_provider = 9;
//  * This is a type-conversion wrapper around `getAmountToProvider()`
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getAmountToProvider_asB64 =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.bytesAsB64(this.getAmountToProvider())
//     );
//   };
//
// /**
//  * optional bytes amount_to_provider = 9;
//  * Note that Uint8Array is not supported on all browsers.
//  * @see http://caniuse.com/Uint8Array
//  * This is a type-conversion wrapper around `getAmountToProvider()`
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getAmountToProvider_asU8 =
//   function () {
//     return /** @type {!Uint8Array} */ (
//       jspb.Message.bytesAsU8(this.getAmountToProvider())
//     );
//   };
//
// /** @param {!(string|Uint8Array)} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setAmountToProvider =
//   function (value) {
//     jspb.Message.setProto3BytesField(this, 9, value);
//   };
//
// /**
//  * optional bytes amount_to_wallet = 10;
//  * @return {!(string|Uint8Array)}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getAmountToWallet =
//   function () {
//     return /** @type {!(string|Uint8Array)} */ (
//       jspb.Message.getFieldWithDefault(this, 10, '')
//     );
//   };
//
// /**
//  * optional bytes amount_to_wallet = 10;
//  * This is a type-conversion wrapper around `getAmountToWallet()`
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getAmountToWallet_asB64 =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.bytesAsB64(this.getAmountToWallet())
//     );
//   };
//
// /**
//  * optional bytes amount_to_wallet = 10;
//  * Note that Uint8Array is not supported on all browsers.
//  * @see http://caniuse.com/Uint8Array
//  * This is a type-conversion wrapper around `getAmountToWallet()`
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getAmountToWallet_asU8 =
//   function () {
//     return /** @type {!Uint8Array} */ (
//       jspb.Message.bytesAsU8(this.getAmountToWallet())
//     );
//   };
//
// /** @param {!(string|Uint8Array)} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setAmountToWallet =
//   function (value) {
//     jspb.Message.setProto3BytesField(this, 10, value);
//   };
//
// /**
//  * optional string device_transaction_id = 11;
//  * @return {string}
//  */
// proto.ledger_swap.NewTransactionResponse.prototype.getDeviceTransactionId =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.getFieldWithDefault(this, 11, '')
//     );
//   };
//
// /** @param {string} value */
// proto.ledger_swap.NewTransactionResponse.prototype.setDeviceTransactionId =
//   function (value) {
//     jspb.Message.setProto3StringField(this, 11, value);
//   };
//
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.ledger_swap.UDecimal = function (opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.ledger_swap.UDecimal, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   proto.ledger_swap.UDecimal.displayName = 'proto.ledger_swap.UDecimal';
// }
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
//   /**
//    * Creates an object representation of this proto suitable for use in Soy templates.
//    * Field names that are reserved in JavaScript and will be renamed to pb_name.
//    * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//    * For the list of reserved names please see:
//    *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
//    * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
//    *     for transitional soy proto support: http://goto/soy-param-migration
//    * @return {!Object}
//    */
//   proto.ledger_swap.UDecimal.prototype.toObject = function (
//     opt_includeInstance
//   ) {
//     return proto.ledger_swap.UDecimal.toObject(opt_includeInstance, this);
//   };
//
//   /**
//    * Static version of the {@see toObject} method.
//    * @param {boolean|undefined} includeInstance Whether to include the JSPB
//    *     instance for transitional soy proto support:
//    *     http://goto/soy-param-migration
//    * @param {!proto.ledger_swap.UDecimal} msg The msg instance to transform.
//    * @return {!Object}
//    * @suppress {unusedLocalVariables} f is only used for nested messages
//    */
//   proto.ledger_swap.UDecimal.toObject = function (includeInstance, msg) {
//     var f,
//       obj = {
//         coefficient: msg.getCoefficient_asB64(),
//         exponent: jspb.Message.getFieldWithDefault(msg, 2, 0),
//       };
//
//     if (includeInstance) {
//       obj.$jspbMessageInstance = msg;
//     }
//     return obj;
//   };
// }
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.ledger_swap.UDecimal}
//  */
// proto.ledger_swap.UDecimal.deserializeBinary = function (bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.ledger_swap.UDecimal();
//   return proto.ledger_swap.UDecimal.deserializeBinaryFromReader(msg, reader);
// };
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.ledger_swap.UDecimal} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.ledger_swap.UDecimal}
//  */
// proto.ledger_swap.UDecimal.deserializeBinaryFromReader = function (
//   msg,
//   reader
// ) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//       case 1:
//         var value = /** @type {!Uint8Array} */ (reader.readBytes());
//         msg.setCoefficient(value);
//         break;
//       case 2:
//         var value = /** @type {number} */ (reader.readUint32());
//         msg.setExponent(value);
//         break;
//       default:
//         reader.skipField();
//         break;
//     }
//   }
//   return msg;
// };
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.UDecimal.prototype.serializeBinary = function () {
//   var writer = new jspb.BinaryWriter();
//   proto.ledger_swap.UDecimal.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.ledger_swap.UDecimal} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.ledger_swap.UDecimal.serializeBinaryToWriter = function (
//   message,
//   writer
// ) {
//   var f = undefined;
//   f = message.getCoefficient_asU8();
//   if (f.length > 0) {
//     writer.writeBytes(1, f);
//   }
//   f = message.getExponent();
//   if (f !== 0) {
//     writer.writeUint32(2, f);
//   }
// };
//
// /**
//  * optional bytes coefficient = 1;
//  * @return {!(string|Uint8Array)}
//  */
// proto.ledger_swap.UDecimal.prototype.getCoefficient = function () {
//   return /** @type {!(string|Uint8Array)} */ (
//     jspb.Message.getFieldWithDefault(this, 1, '')
//   );
// };
//
// /**
//  * optional bytes coefficient = 1;
//  * This is a type-conversion wrapper around `getCoefficient()`
//  * @return {string}
//  */
// proto.ledger_swap.UDecimal.prototype.getCoefficient_asB64 = function () {
//   return /** @type {string} */ (jspb.Message.bytesAsB64(this.getCoefficient()));
// };
//
// /**
//  * optional bytes coefficient = 1;
//  * Note that Uint8Array is not supported on all browsers.
//  * @see http://caniuse.com/Uint8Array
//  * This is a type-conversion wrapper around `getCoefficient()`
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.UDecimal.prototype.getCoefficient_asU8 = function () {
//   return /** @type {!Uint8Array} */ (
//     jspb.Message.bytesAsU8(this.getCoefficient())
//   );
// };
//
// /** @param {!(string|Uint8Array)} value */
// proto.ledger_swap.UDecimal.prototype.setCoefficient = function (value) {
//   jspb.Message.setProto3BytesField(this, 1, value);
// };
//
// /**
//  * optional uint32 exponent = 2;
//  * @return {number}
//  */
// proto.ledger_swap.UDecimal.prototype.getExponent = function () {
//   return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
// };
//
// /** @param {number} value */
// proto.ledger_swap.UDecimal.prototype.setExponent = function (value) {
//   jspb.Message.setProto3IntField(this, 2, value);
// };
//
// /**
//  * Generated by JsPbCodeGenerator.
//  * @param {Array=} opt_data Optional initial data array, typically from a
//  * server response, or constructed directly in Javascript. The array is used
//  * in place and becomes part of the constructed object. It is not cloned.
//  * If no data is provided, the constructed object will be empty, but still
//  * valid.
//  * @extends {jspb.Message}
//  * @constructor
//  */
// proto.ledger_swap.NewSellResponse = function (opt_data) {
//   jspb.Message.initialize(this, opt_data, 0, -1, null, null);
// };
// goog.inherits(proto.ledger_swap.NewSellResponse, jspb.Message);
// if (goog.DEBUG && !COMPILED) {
//   proto.ledger_swap.NewSellResponse.displayName =
//     'proto.ledger_swap.NewSellResponse';
// }
//
// if (jspb.Message.GENERATE_TO_OBJECT) {
//   /**
//    * Creates an object representation of this proto suitable for use in Soy templates.
//    * Field names that are reserved in JavaScript and will be renamed to pb_name.
//    * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
//    * For the list of reserved names please see:
//    *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
//    * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
//    *     for transitional soy proto support: http://goto/soy-param-migration
//    * @return {!Object}
//    */
//   proto.ledger_swap.NewSellResponse.prototype.toObject = function (
//     opt_includeInstance
//   ) {
//     return proto.ledger_swap.NewSellResponse.toObject(
//       opt_includeInstance,
//       this
//     );
//   };
//
//   /**
//    * Static version of the {@see toObject} method.
//    * @param {boolean|undefined} includeInstance Whether to include the JSPB
//    *     instance for transitional soy proto support:
//    *     http://goto/soy-param-migration
//    * @param {!proto.ledger_swap.NewSellResponse} msg The msg instance to transform.
//    * @return {!Object}
//    * @suppress {unusedLocalVariables} f is only used for nested messages
//    */
//   proto.ledger_swap.NewSellResponse.toObject = function (includeInstance, msg) {
//     var f,
//       obj = {
//         traderEmail: jspb.Message.getFieldWithDefault(msg, 1, ''),
//         inCurrency: jspb.Message.getFieldWithDefault(msg, 2, ''),
//         inAmount: msg.getInAmount_asB64(),
//         inAddress: jspb.Message.getFieldWithDefault(msg, 4, ''),
//         outCurrency: jspb.Message.getFieldWithDefault(msg, 5, ''),
//         outAmount:
//           (f = msg.getOutAmount()) &&
//           proto.ledger_swap.UDecimal.toObject(includeInstance, f),
//         deviceTransactionId: msg.getDeviceTransactionId_asB64(),
//       };
//
//     if (includeInstance) {
//       obj.$jspbMessageInstance = msg;
//     }
//     return obj;
//   };
// }
//
// /**
//  * Deserializes binary data (in protobuf wire format).
//  * @param {jspb.ByteSource} bytes The bytes to deserialize.
//  * @return {!proto.ledger_swap.NewSellResponse}
//  */
// proto.ledger_swap.NewSellResponse.deserializeBinary = function (bytes) {
//   var reader = new jspb.BinaryReader(bytes);
//   var msg = new proto.ledger_swap.NewSellResponse();
//   return proto.ledger_swap.NewSellResponse.deserializeBinaryFromReader(
//     msg,
//     reader
//   );
// };
//
// /**
//  * Deserializes binary data (in protobuf wire format) from the
//  * given reader into the given message object.
//  * @param {!proto.ledger_swap.NewSellResponse} msg The message object to deserialize into.
//  * @param {!jspb.BinaryReader} reader The BinaryReader to use.
//  * @return {!proto.ledger_swap.NewSellResponse}
//  */
// proto.ledger_swap.NewSellResponse.deserializeBinaryFromReader = function (
//   msg,
//   reader
// ) {
//   while (reader.nextField()) {
//     if (reader.isEndGroup()) {
//       break;
//     }
//     var field = reader.getFieldNumber();
//     switch (field) {
//       case 1:
//         var value = /** @type {string} */ (reader.readString());
//         msg.setTraderEmail(value);
//         break;
//       case 2:
//         var value = /** @type {string} */ (reader.readString());
//         msg.setInCurrency(value);
//         break;
//       case 3:
//         var value = /** @type {!Uint8Array} */ (reader.readBytes());
//         msg.setInAmount(value);
//         break;
//       case 4:
//         var value = /** @type {string} */ (reader.readString());
//         msg.setInAddress(value);
//         break;
//       case 5:
//         var value = /** @type {string} */ (reader.readString());
//         msg.setOutCurrency(value);
//         break;
//       case 6:
//         var value = new proto.ledger_swap.UDecimal();
//         reader.readMessage(
//           value,
//           proto.ledger_swap.UDecimal.deserializeBinaryFromReader
//         );
//         msg.setOutAmount(value);
//         break;
//       case 7:
//         var value = /** @type {!Uint8Array} */ (reader.readBytes());
//         msg.setDeviceTransactionId(value);
//         break;
//       default:
//         reader.skipField();
//         break;
//     }
//   }
//   return msg;
// };
//
// /**
//  * Serializes the message to binary data (in protobuf wire format).
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.NewSellResponse.prototype.serializeBinary = function () {
//   var writer = new jspb.BinaryWriter();
//   proto.ledger_swap.NewSellResponse.serializeBinaryToWriter(this, writer);
//   return writer.getResultBuffer();
// };
//
// /**
//  * Serializes the given message to binary data (in protobuf wire
//  * format), writing to the given BinaryWriter.
//  * @param {!proto.ledger_swap.NewSellResponse} message
//  * @param {!jspb.BinaryWriter} writer
//  * @suppress {unusedLocalVariables} f is only used for nested messages
//  */
// proto.ledger_swap.NewSellResponse.serializeBinaryToWriter = function (
//   message,
//   writer
// ) {
//   var f = undefined;
//   f = message.getTraderEmail();
//   if (f.length > 0) {
//     writer.writeString(1, f);
//   }
//   f = message.getInCurrency();
//   if (f.length > 0) {
//     writer.writeString(2, f);
//   }
//   f = message.getInAmount_asU8();
//   if (f.length > 0) {
//     writer.writeBytes(3, f);
//   }
//   f = message.getInAddress();
//   if (f.length > 0) {
//     writer.writeString(4, f);
//   }
//   f = message.getOutCurrency();
//   if (f.length > 0) {
//     writer.writeString(5, f);
//   }
//   f = message.getOutAmount();
//   if (f != null) {
//     writer.writeMessage(
//       6,
//       f,
//       proto.ledger_swap.UDecimal.serializeBinaryToWriter
//     );
//   }
//   f = message.getDeviceTransactionId_asU8();
//   if (f.length > 0) {
//     writer.writeBytes(7, f);
//   }
// };
//
// /**
//  * optional string trader_email = 1;
//  * @return {string}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getTraderEmail = function () {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
// };
//
// /** @param {string} value */
// proto.ledger_swap.NewSellResponse.prototype.setTraderEmail = function (value) {
//   jspb.Message.setProto3StringField(this, 1, value);
// };
//
// /**
//  * optional string in_currency = 2;
//  * @return {string}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getInCurrency = function () {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
// };
//
// /** @param {string} value */
// proto.ledger_swap.NewSellResponse.prototype.setInCurrency = function (value) {
//   jspb.Message.setProto3StringField(this, 2, value);
// };
//
// /**
//  * optional bytes in_amount = 3;
//  * @return {!(string|Uint8Array)}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getInAmount = function () {
//   return /** @type {!(string|Uint8Array)} */ (
//     jspb.Message.getFieldWithDefault(this, 3, '')
//   );
// };
//
// /**
//  * optional bytes in_amount = 3;
//  * This is a type-conversion wrapper around `getInAmount()`
//  * @return {string}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getInAmount_asB64 = function () {
//   return /** @type {string} */ (jspb.Message.bytesAsB64(this.getInAmount()));
// };
//
// /**
//  * optional bytes in_amount = 3;
//  * Note that Uint8Array is not supported on all browsers.
//  * @see http://caniuse.com/Uint8Array
//  * This is a type-conversion wrapper around `getInAmount()`
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getInAmount_asU8 = function () {
//   return /** @type {!Uint8Array} */ (
//     jspb.Message.bytesAsU8(this.getInAmount())
//   );
// };
//
// /** @param {!(string|Uint8Array)} value */
// proto.ledger_swap.NewSellResponse.prototype.setInAmount = function (value) {
//   jspb.Message.setProto3BytesField(this, 3, value);
// };
//
// /**
//  * optional string in_address = 4;
//  * @return {string}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getInAddress = function () {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
// };
//
// /** @param {string} value */
// proto.ledger_swap.NewSellResponse.prototype.setInAddress = function (value) {
//   jspb.Message.setProto3StringField(this, 4, value);
// };
//
// /**
//  * optional string out_currency = 5;
//  * @return {string}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getOutCurrency = function () {
//   return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
// };
//
// /** @param {string} value */
// proto.ledger_swap.NewSellResponse.prototype.setOutCurrency = function (value) {
//   jspb.Message.setProto3StringField(this, 5, value);
// };
//
// /**
//  * optional UDecimal out_amount = 6;
//  * @return {?proto.ledger_swap.UDecimal}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getOutAmount = function () {
//   return /** @type{?proto.ledger_swap.UDecimal} */ (
//     jspb.Message.getWrapperField(this, proto.ledger_swap.UDecimal, 6)
//   );
// };
//
// /** @param {?proto.ledger_swap.UDecimal|undefined} value */
// proto.ledger_swap.NewSellResponse.prototype.setOutAmount = function (value) {
//   jspb.Message.setWrapperField(this, 6, value);
// };
//
// proto.ledger_swap.NewSellResponse.prototype.clearOutAmount = function () {
//   this.setOutAmount(undefined);
// };
//
// /**
//  * Returns whether this field is set.
//  * @return {!boolean}
//  */
// proto.ledger_swap.NewSellResponse.prototype.hasOutAmount = function () {
//   return jspb.Message.getField(this, 6) != null;
// };
//
// /**
//  * optional bytes device_transaction_id = 7;
//  * @return {!(string|Uint8Array)}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getDeviceTransactionId =
//   function () {
//     return /** @type {!(string|Uint8Array)} */ (
//       jspb.Message.getFieldWithDefault(this, 7, '')
//     );
//   };
//
// /**
//  * optional bytes device_transaction_id = 7;
//  * This is a type-conversion wrapper around `getDeviceTransactionId()`
//  * @return {string}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getDeviceTransactionId_asB64 =
//   function () {
//     return /** @type {string} */ (
//       jspb.Message.bytesAsB64(this.getDeviceTransactionId())
//     );
//   };
//
// /**
//  * optional bytes device_transaction_id = 7;
//  * Note that Uint8Array is not supported on all browsers.
//  * @see http://caniuse.com/Uint8Array
//  * This is a type-conversion wrapper around `getDeviceTransactionId()`
//  * @return {!Uint8Array}
//  */
// proto.ledger_swap.NewSellResponse.prototype.getDeviceTransactionId_asU8 =
//   function () {
//     return /** @type {!Uint8Array} */ (
//       jspb.Message.bytesAsU8(this.getDeviceTransactionId())
//     );
//   };
//
// /** @param {!(string|Uint8Array)} value */
// proto.ledger_swap.NewSellResponse.prototype.setDeviceTransactionId = function (
//   value
// ) {
//   jspb.Message.setProto3BytesField(this, 7, value);
// };

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ledger_swap.NewFundResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ledger_swap.NewFundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.ledger_swap.NewFundResponse.displayName =
    'proto.ledger_swap.NewFundResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto suitable for use in Soy templates.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
   * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
   *     for transitional soy proto support: http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ledger_swap.NewFundResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.ledger_swap.NewFundResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Whether to include the JSPB
   *     instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ledger_swap.NewFundResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.ledger_swap.NewFundResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, ''),
        accountName: jspb.Message.getFieldWithDefault(msg, 2, ''),
        inCurrency: jspb.Message.getFieldWithDefault(msg, 3, ''),
        inAmount: msg.getInAmount_asB64(),
        inAddress: jspb.Message.getFieldWithDefault(msg, 5, ''),
        deviceTransactionId: msg.getDeviceTransactionId_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ledger_swap.NewFundResponse}
 */
proto.ledger_swap.NewFundResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ledger_swap.NewFundResponse();
  return proto.ledger_swap.NewFundResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ledger_swap.NewFundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ledger_swap.NewFundResponse}
 */
proto.ledger_swap.NewFundResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setUserId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountName(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setInCurrency(value);
        break;
      case 4:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setInAmount(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setInAddress(value);
        break;
      case 6:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setDeviceTransactionId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ledger_swap.NewFundResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ledger_swap.NewFundResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ledger_swap.NewFundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ledger_swap.NewFundResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getInCurrency();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getInAmount_asU8();
  if (f.length > 0) {
    writer.writeBytes(4, f);
  }
  f = message.getInAddress();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getDeviceTransactionId_asU8();
  if (f.length > 0) {
    writer.writeBytes(6, f);
  }
};

/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.ledger_swap.NewFundResponse.prototype.getUserId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/** @param {string} value */
proto.ledger_swap.NewFundResponse.prototype.setUserId = function (value) {
  jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.ledger_swap.NewFundResponse.prototype.getAccountName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/** @param {string} value */
proto.ledger_swap.NewFundResponse.prototype.setAccountName = function (value) {
  jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string in_currency = 3;
 * @return {string}
 */
proto.ledger_swap.NewFundResponse.prototype.getInCurrency = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/** @param {string} value */
proto.ledger_swap.NewFundResponse.prototype.setInCurrency = function (value) {
  jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional bytes in_amount = 4;
 * @return {!(string|Uint8Array)}
 */
proto.ledger_swap.NewFundResponse.prototype.getInAmount = function () {
  return /** @type {!(string|Uint8Array)} */ (
    jspb.Message.getFieldWithDefault(this, 4, '')
  );
};

/**
 * optional bytes in_amount = 4;
 * This is a type-conversion wrapper around `getInAmount()`
 * @return {string}
 */
proto.ledger_swap.NewFundResponse.prototype.getInAmount_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getInAmount()));
};

/**
 * optional bytes in_amount = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getInAmount()`
 * @return {!Uint8Array}
 */
proto.ledger_swap.NewFundResponse.prototype.getInAmount_asU8 = function () {
  return /** @type {!Uint8Array} */ (
    jspb.Message.bytesAsU8(this.getInAmount())
  );
};

/** @param {!(string|Uint8Array)} value */
proto.ledger_swap.NewFundResponse.prototype.setInAmount = function (value) {
  jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional string in_address = 5;
 * @return {string}
 */
proto.ledger_swap.NewFundResponse.prototype.getInAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/** @param {string} value */
proto.ledger_swap.NewFundResponse.prototype.setInAddress = function (value) {
  jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bytes device_transaction_id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.ledger_swap.NewFundResponse.prototype.getDeviceTransactionId =
  function () {
    return /** @type {!(string|Uint8Array)} */ (
      jspb.Message.getFieldWithDefault(this, 6, '')
    );
  };

/**
 * optional bytes device_transaction_id = 6;
 * This is a type-conversion wrapper around `getDeviceTransactionId()`
 * @return {string}
 */
proto.ledger_swap.NewFundResponse.prototype.getDeviceTransactionId_asB64 =
  function () {
    return /** @type {string} */ (
      jspb.Message.bytesAsB64(this.getDeviceTransactionId())
    );
  };

/**
 * optional bytes device_transaction_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceTransactionId()`
 * @return {!Uint8Array}
 */
proto.ledger_swap.NewFundResponse.prototype.getDeviceTransactionId_asU8 =
  function () {
    return /** @type {!Uint8Array} */ (
      jspb.Message.bytesAsU8(this.getDeviceTransactionId())
    );
  };

/** @param {!(string|Uint8Array)} value */
proto.ledger_swap.NewFundResponse.prototype.setDeviceTransactionId = function (
  value
) {
  jspb.Message.setProto3BytesField(this, 6, value);
};

goog.object.extend(exports, proto.ledger_swap);
