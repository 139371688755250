import * as React from 'react';
import bgx3 from './assets/bg@3x.png';
import bgx2 from './assets/bg@2x.png';
import bgx1 from './assets/bg@1x.png';

import bgDarkx3 from './assets/bg_dark@1x.png';
import bgDarkx2 from './assets/bg_dark@2x.png';
import bgDarkx1 from './assets/bg_dark@3x.png';

import bgPromox1 from './assets/bg_promo@1x.png';
import bgPromox2 from './assets/bg_promo@2x.png';
import bgPromox3 from './assets/bg_promo@3x.png';

import bgPromo2x1 from './assets/bg_promo_2@1x.png';
import bgPromo2x2 from './assets/bg_promo_2@2x.png';
import bgPromo2x3 from './assets/bg_promo_2@3x.png';

import bgPromoNolimitX1 from './assets/bg_promo_nolimit@1x.png';
import bgPromoNolimitX2 from './assets/bg_promo_nolimit@2x.png';
import bgPromoNolimitX3 from './assets/bg_promo_nolimit@3x.png';

import bgMinerDarkx1 from './assets/bg_miner_dark@1x.png';
import bgMinerDarkx2 from './assets/bg_miner_dark@2x.png';
import bgMinerDarkx3 from './assets/bg_miner_dark@3x.png';

import bgMinerLightx1 from './assets/bg_miner@1x.png';
import bgMinerLightx2 from './assets/bg_miner@2x.png';
import bgMinerLightx3 from './assets/bg_miner@3x.png';

import {useActiveThemeType} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {GLOBAL, GlobalCampaign} from '@youtoken/ui.service-global';

export type PromoImageProps = {};

const srcLightSet = `${bgx3} 3x, ${bgx2} 2x, ${bgx1} 1x`;
const srcDarkSet = `${bgDarkx3} 3x, ${bgDarkx2} 2x, ${bgDarkx1} 1x`;
const srcPromoSet = `${bgPromox3} 3x, ${bgPromox2} 2x, ${bgPromox1} 1x`;
const srcPromo2Set = `${bgPromo2x3} 3x, ${bgPromo2x2} 2x, ${bgPromo2x1} 1x`;
const srcPromoNolimitSet = `${bgPromoNolimitX3} 3x, ${bgPromoNolimitX2} 2x, ${bgPromoNolimitX1} 1x`;
const srcMinerLightPromoSet = `${bgMinerLightx3} 3x, ${bgMinerLightx2} 2x, ${bgMinerLightx1} 1x`;
const srcMinerDarkPromoSet = `${bgMinerDarkx3} 3x, ${bgMinerDarkx2} 2x, ${bgMinerDarkx1} 1x`;
const src = `${bgx1}`;
const alt = 'YouHodler app interface';

let PromoImageLoaded = false;

export const PromoImage: React.FC<PromoImageProps> = observer(() => {
  const [loaded, setLoaded] = React.useState(PromoImageLoaded);
  const themeType = useActiveThemeType();
  const {queryParams} = RouterResource.use({});

  const handleLoad = React.useCallback(() => {
    if (!loaded) {
      setLoaded(() => {
        PromoImageLoaded = true;
        return true;
      });
    }
  }, [loaded]);

  const style = React.useMemo(() => {
    return {
      opacity: loaded ? 1 : 0,
      transition: 'opacity 0.2s ease-in',
      backgroundSize: 'cover',
    } as React.CSSProperties;
  }, [loaded]);

  const srcSet = React.useMemo(() => {
    if (queryParams.gift) {
      if (queryParams.gift === '1') {
        return srcPromoSet;
      }

      if (queryParams.gift === '2') {
        return srcPromo2Set;
      }
    }

    if (queryParams.promo?.toLowerCase() === 'nolimit') {
      return srcPromoNolimitSet;
    }

    if (GLOBAL.campaign === GlobalCampaign.NEW_MINER_FLOW) {
      if (themeType === 'dark') {
        return srcMinerDarkPromoSet;
      }
      return srcMinerLightPromoSet;
    }

    return themeType === 'dark' ? srcDarkSet : srcLightSet;
  }, [queryParams, themeType, GLOBAL.campaign]);

  return (
    <img
      srcSet={srcSet}
      src={src}
      alt={alt}
      width="100%"
      style={style}
      onLoad={handleLoad}
      onError={handleLoad}
    />
  );
});
