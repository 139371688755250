import * as React from 'react';
import {LoyaltyIcon, LoyaltyIconName} from '@youtoken/ui.icons';
import {Box, TColorTokens, Text} from '@youtoken/ui.primitives';

interface ProgressLevelLeftTimeLevelProps {
  iconName: LoyaltyIconName;
  iconColor: keyof TColorTokens;
  name: string;
}

export const ProgressLevelLeftTimeLevel: React.FC<
  ProgressLevelLeftTimeLevelProps
> = ({iconName, iconColor, name}) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      position="relative"
      pl={20}
      mr={4}
    >
      <Box position="absolute" left={0}>
        <LoyaltyIcon name={iconName} color={iconColor} size={18} />
      </Box>
      <Text variant="$body-02-high-accent">{name}</Text>
    </Box>
  );
};
