import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {RewardsBannerVariant} from '../types';
import {RewardsBannerCount} from './Count';
import {RewardsBannerGetAmount} from './GetAmount';
import {RewardsBannerLabel} from './Label';

export interface RewardsBannerContentProps {
  variant: RewardsBannerVariant;
  amount: number;
  maxAmount: number;
  newsCount: number;
}

export const RewardsBannerContent: React.FC<RewardsBannerContentProps> =
  React.memo(({variant, amount, maxAmount, newsCount}) => {
    if (variant === 'column') {
      return (
        <Box flex={1} py={16}>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="flex-start"
            flex={1}
          >
            <RewardsBannerLabel />
            <RewardsBannerCount value={newsCount} />
          </Box>
          <Box flexDirection="row">
            <RewardsBannerGetAmount amount={amount} maxAmount={maxAmount} />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flex={1}
      >
        <Box flexDirection="row" alignItems="center" flex={1}>
          <RewardsBannerGetAmount amount={amount} maxAmount={maxAmount} />
          <RewardsBannerLabel mx={12} />
        </Box>
        <RewardsBannerCount value={newsCount} />
      </Box>
    );
  });
