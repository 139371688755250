import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation, Trans} from '@youtoken/ui.service-i18n';
import {LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box, Text, type TextProps} from '@youtoken/ui.primitives';
import {LoanItemHeaderStatusOverdraftAmount} from '../LoanItemHeaderStatusOverdraftAmount';

export interface LoanItemHeaderStatusOtherV2Props {
  item: LoanRegularV2;
}

export const LoanItemHeaderStatusOtherV2: React.FC<LoanItemHeaderStatusOtherV2Props> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <Box testID={`LOAN_ITEM_STATUS_V2_${item.status}`} alignItems="flex-end">
        <LoanItemHeaderStatusOverdraftAmount item={item} />
        {item.isOpened && item.aprBySettlementPeriod.gt(0) && (
          <Text
            testID="LOAN_ITEM_STATUS_V2_DISTANCE"
            variant="$body-02"
            color="$text-02"
          >
            <Trans
              t={t}
              i18nKey={'surface.loans.item.to_fee_accrual_distance'}
              components={{
                Distance: (
                  <Text color={item.chargeAtColor as TextProps['color']} />
                ),
              }}
              values={{
                distance: item.chargeAtFormatted,
              }}
            />
          </Text>
        )}
      </Box>
    );
  });
