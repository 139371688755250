import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Select} from '@youtoken/ui.select';
import {i18n} from '@youtoken/ui.service-i18n';
import {observer} from 'mobx-react';

export type ILanguageSwitchProps = {
  size: 'small' | 'medium';
  dropdownWidth?: number;
  boxProps?: BoxProps;
  testID?: string;
  variant?: 'default' | 'icon';
} & Pick<BoxProps, 'width'>;

export const LanguageSwitchCodeLabel: React.FC = () => {
  return (
    <Box flexDirection="row" alignItems="center">
      <Icon name="language" color="$text-02" />
      <Text variant="$body-02-medium-accent" color="$text-02" ml={4}>
        {i18n.language.toUpperCase()}
      </Text>
    </Box>
  );
};

export const LanguageSwitch: React.FC<ILanguageSwitchProps> = observer(
  ({
    size,
    width = 120,
    dropdownWidth,
    boxProps,
    testID,
    variant = 'default',
  }) => {
    const handleSelect = React.useCallback((value: string) => {
      i18n.isLangSwitcherTouched = true;
      i18n._changeLanguage(value);
    }, []);

    if (!i18n.showLanguageSwitcher) {
      return null;
    }

    return (
      <Box {...boxProps}>
        <Select
          labelComponent={
            variant === 'icon' ? LanguageSwitchCodeLabel : undefined
          }
          items={i18n.languages}
          onSelect={handleSelect}
          selected={i18n.language}
          width={variant === 'icon' ? 80 : width}
          size={size}
          dropdownWidth={dropdownWidth}
          bottomSheetTitle={i18n.t('screens.welcome.select_language')}
          testID={testID}
        />
      </Box>
    );
  }
);
