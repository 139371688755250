import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Checkbox} from '@youtoken/ui.elements';
import {AlertsMethods, AuthMeResource} from '@youtoken/ui.resource-auth-me';

type NotificationsBaseProps = {
  setNotifications: (type: AlertsMethods, value: boolean) => Promise<any>;
  data: {
    email: boolean;
    sms: boolean;
    push: boolean;
  };
  title: string;
  description: string;
};

export const NotificationsBase: React.FC<NotificationsBaseProps> = observer(
  ({setNotifications, data, title, description}) => {
    const {t} = useTranslation();

    const {emailResult, kycPhoneResult, allowedMobilePush} = AuthMeResource.use(
      {}
    );

    const [values, setValues] = React.useState(data);

    const createHandleChangeField = (field: 'email' | 'sms' | 'push') => {
      return (value: boolean) => {
        setNotifications(field, value);
        setValues(prev => ({...prev, [field]: value}));
      };
    };

    return (
      <Box flexDirection={{default: 'column', tablet: 'row'}}>
        <Box flexDirection="column" width="50%">
          <Text variant="$body-01-high-accent" mb={6}>
            {title}
          </Text>
          <Text variant="$body-02" color="$text-02">
            {description}
          </Text>
        </Box>
        <Box
          flexDirection={{
            default: 'column',
            tablet: 'row',
          }}
          width="50%"
          justifyContent="flex-start"
          mt={{default: 16, tablet: 0}}
          // pl={12}
          alignItems={{default: 'flex-start', tablet: 'center'}}
          flexWrap="wrap"
        >
          <Box flexDirection="row" mt={8} mr={32}>
            <Checkbox
              value={values.email}
              onChange={createHandleChangeField('email')}
              disabled={emailResult !== 'ACCEPT'}
            />
            <Text>
              {t(
                'surface.profile.preferences.notifications.section.checkbox.email'
              )}
            </Text>
          </Box>
          <Box flexDirection="row" mt={8} mr={32}>
            <Checkbox
              value={values.sms}
              onChange={createHandleChangeField('sms')}
              disabled={kycPhoneResult !== 'ACCEPT'}
            />
            <Text>
              {t(
                'surface.profile.preferences.notifications.section.checkbox.sms'
              )}
            </Text>
          </Box>
          <Box flexDirection="row" mt={8}>
            <Checkbox
              value={values.push}
              onChange={createHandleChangeField('push')}
              disabled={!allowedMobilePush}
            />
            <Text>
              {t(
                'surface.profile.preferences.notifications.section.checkbox.push'
              )}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
