import {computed, observable} from 'mobx';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {
  formatBigNumber,
  formatByTicker,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {Form as FormV1} from './Authorized/v1/Form';
import {Form as FormV2} from './Authorized/v2/Form';
import {Form as FormUnauthorizedV1} from './Unauthorized/v1/Form';
import {Form as FormUnauthorizedV2} from './Unauthorized/v2/Form';

type ViewBaseForm = FormV1 | FormV2 | FormUnauthorizedV1 | FormUnauthorizedV2;

export class ViewBase<Form extends ViewBaseForm> {
  @observable
  form: Form;

  @computed
  get balanceFormatted() {
    return formatByTicker(this.form.balance, this.form.ticker);
  }

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.form.ticker);
  }

  @computed
  get tickerFormatted() {
    return this.form.ticker.toUpperCase();
  }

  @computed
  get conversionTickerFormatted() {
    return this.form.conversionTicker.toUpperCase();
  }

  @computed
  get amountFormatted() {
    return formatBigNumber(toBig(this.form.amount), this.tickerPrecision, true);
  }

  @computed
  get amountWithoutFeeFormatted() {
    return formatBigNumber(
      this.form.amountWithoutFee,
      this.tickerPrecision,
      true
    );
  }

  @computed
  get rateFormatted() {
    return formatBigNumber(this.form.rate, this.form.conversionTickerPrecision);
  }

  @computed
  get feeFormatted() {
    return formatBigNumber(this.form.fee, this.tickerPrecision, true);
  }

  public constructor(form: Form) {
    this.form = form;
  }
}
