import {observable, action, type IReactionDisposer} from 'mobx';
import {Form} from './Form';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';

export class FeatureDeleteAccountForm extends createFeature({
  getKey: (args: {}) => `FeatureDeleteAccount:${JSON.stringify(args)}`,
  getResources: () => {
    return {
      authMe: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @observable
  public form: Form;

  disposers: IReactionDisposer[] = [];

  constructor(args: {}, resources: {authMe: AuthMeResource}) {
    super(args, resources);

    this.form = new Form(args, resources);
  }

  @action reset = () => {
    this.form.instance.reset();
  };
}
