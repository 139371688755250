import {en} from '@youtoken/ui.lokalise-export';
import {LoanRegularStatus} from '@youtoken/ui.resource-loans';

export const statusLabel = {
  [LoanRegularStatus.PROCESSING]: 'surface.loans.item.cancel',
  [LoanRegularStatus.PENDING]: 'surface.loans.item.cancel',
  [LoanRegularStatus.OPEN]: 'surface.loans.item.repay',
  [LoanRegularStatus.CLOSING]: 'surface.loans.item.closing',
  [LoanRegularStatus.CLOSED]: 'surface.loans.item.closed',
  [LoanRegularStatus.DECLINED]: 'surface.loans.item.closed',
  [LoanRegularStatus.CANCELED]: 'surface.loans.item.closed',
} as {[key in LoanRegularStatus]: keyof typeof en};

export const getStatusLabel = (status: LoanRegularStatus) => {
  return statusLabel[status] ?? 'surface.loans.item.error';
};
