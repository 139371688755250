import {observable, computed} from 'mobx';
import {ScaleTime, ScaleLinear} from 'd3-scale';
import {computedFn} from 'mobx-utils';
import {BaseChartState} from '../BaseChartState';

type ChartDirection = 'up' | 'down' | 'neutral' | 'pending' | 'closed';
type ClampedYDirection = 'left' | 'right' | 'top' | 'bottom' | 'none';

export class BaseChartLayout {
  @observable chart!: BaseChartState;
  @observable labelHeight = 16;
  @observable labelPaddingHorizontal = 6;

  @computed get scaleX(): ScaleTime<any, any> | ScaleLinear<any, any> {
    throw new Error('Method not implemented.');
  }
  @computed get scaleXChart(): ScaleTime<any, any> | ScaleLinear<any, any> {
    throw new Error('Method not implemented.');
  }
  @computed get chartWidth(): number {
    throw new Error('Method not implemented.');
  }
  @computed get scaleY(): ScaleLinear<any, any> {
    throw new Error('Method not implemented.');
  }

  getTextWidth = (text?: string) => {
    if (!text) {
      return 0;
    }

    return Math.round(text.length * 5.6985714286);
  };

  getLabelWidth = (text?: string) => {
    return this.getTextWidth(text) + this.labelPaddingHorizontal * 2;
  };

  getIsValueInDomain = computedFn((value?: number) => {
    if (!value) {
      return false;
    }

    const y = this.scaleY(value);

    return (
      y >= this.chart.domainPaddingTop &&
      y <= this.chart.height - this.chart.domainPaddingBottom
    );
  });

  getClampedValueX = computedFn(
    (
      value: Date,
      width: number
    ): {
      x: number;
      clamped: ClampedYDirection;
    } => {
      const valueX = this.scaleX(value);

      if (valueX < width / 2) {
        return {x: 0, clamped: 'left'};
      }

      if (valueX > this.chart.width - width / 2) {
        return {x: this.chart.width, clamped: 'right'};
      }

      return {x: valueX, clamped: 'none'};
    }
  );

  getClampedValueY = computedFn(
    (
      value: number
    ): {
      y: number;
      clamped: ClampedYDirection;
    } => {
      const valueY = this.scaleY(value);

      if (valueY < this.chart.domainPaddingTop) {
        return {y: this.chart.domainPaddingTop, clamped: 'top'};
      }

      if (valueY > this.chart.height - this.chart.domainPaddingBottom) {
        return {y: this.chart.height, clamped: 'bottom'};
      }

      return {y: valueY, clamped: 'none'};
    }
  );

  getClampedLableX = computedFn(
    (
      value: Date,
      width: number,
      alignHorizontal: 'left' | 'center' = 'center'
    ) => {
      const {x, clamped} = this.getClampedValueX(value, width);

      if (clamped === 'none') {
        return x - (alignHorizontal === 'center' ? width / 2 : 0);
      }

      if (clamped === 'left') {
        return 0;
      }

      return this.chart.width - width;
    }
  );

  getClampedLableY = computedFn((value: number) => {
    const {y, clamped} = this.getClampedValueY(value);

    if (clamped === 'none') {
      return y;
    }

    if (clamped === 'top') {
      return this.chart.domainPaddingTop;
    }

    return this.chart.height - this.chart.domainPaddingBottom;
  });

  getClampedLineY = computedFn((value: number) => {
    return this.getClampedValueY(value).y;
  });

  getLineLayoutHorizontal = computedFn((valueY?: number) => {
    if (!valueY) {
      return undefined;
    }

    return {
      y: this.scaleY(valueY),
    };
  });

  getLineLayoutVertical = computedFn((valueX?: Date) => {
    if (!valueX) {
      return undefined;
    }

    return {
      x: this.scaleX(valueX),
    };
  });

  getDotLayout = computedFn(
    (valueX?: Date, valueY?: number, direction: ChartDirection = 'neutral') => {
      if (!valueX || !valueY) {
        return undefined;
      }

      return {
        x: this.scaleX(valueX),
        y: this.scaleY(valueY),
        direction,
      };
    }
  );

  getLabelLayout = computedFn(
    (
      valueY: number,
      text: string,
      align: 'left' | 'right' = 'left',
      direction: ChartDirection = 'neutral'
    ) => {
      if (!valueY) {
        return undefined;
      }

      const width = this.getLabelWidth(text);

      return {
        isInDomain: this.getIsValueInDomain(valueY),
        direction: direction,
        text: text,
        height: this.labelHeight,
        width: width,
        x: align === 'left' ? 0 : this.chart.width - width,
        y: this.getClampedLableY(valueY),
      };
    }
  );

  getLabelLayoutVertical = computedFn(
    (
      valueY: number,
      text: string,
      align: 'left' | 'right' = 'left',
      direction: ChartDirection = 'neutral',
      children?: any
    ) => {
      if (!valueY) {
        return undefined;
      }

      const width = this.getLabelWidth(text);

      return {
        isInDomain: this.getIsValueInDomain(valueY),
        direction: direction,
        text: children ? children : text,
        height: this.labelHeight,
        width: width,
        x: align === 'left' ? 0 : this.chart.width - width,
        y: this.getClampedLableY(valueY),
      };
    }
  );

  getLabelLayoutHorizontal = computedFn(
    (
      valueX: Date,
      text: string,
      alignVertical: 'top' | 'bottom' = 'top',
      alignHorizontal: 'center' | 'left' = 'center',
      direction: ChartDirection = 'neutral'
    ) => {
      if (!valueX) {
        return undefined;
      }

      const width = this.getLabelWidth(text);

      return {
        isInDomain: true,
        direction: direction,
        text: text,
        height: this.labelHeight,
        width: width,
        y:
          alignVertical === 'top'
            ? 0 + this.labelHeight / 2 + 4
            : this.chart.height - this.labelHeight / 2 - 4,
        x: this.getClampedLableX(valueX, width, alignHorizontal),
      };
    }
  );
}
