import {invariant} from '@youtoken/ui.utils';
import {AppEnv} from './AppEnv';

const GOOGLE_IOS_CLIENT_ID_PROD =
  '139478881265-mqulg94kivg5nfbfmgbt7j05tbprec2f.apps.googleusercontent.com';
const GOOGLE_IOS_CLIENT_ID_DEV =
  '139478881265-7o1d9lkidse9nakjikk2bhcumiahr1lv.apps.googleusercontent.com';
const GOOGLE_ANDROID_CLIENT_ID_PROD =
  '139478881265-n3johhvpfcem9esofrb1lvjraegd68ml.apps.googleusercontent.com';
const GOOGLE_ANDROID_CLIENT_ID_DEV =
  '139478881265-ccftnr61igprt79nukdeo25pvnbpltv2.apps.googleusercontent.com';
const GOOGLE_WEB_CLIENT_ID_PROD = GOOGLE_ANDROID_CLIENT_ID_PROD;
const GOOGLE_WEB_CLIENT_ID_DEV = GOOGLE_ANDROID_CLIENT_ID_DEV;

export function getGoogleClientId(
  env: AppEnv,
  platform: 'android' | 'ios' | 'web'
) {
  let clientId: string | undefined;

  invariant(env, 'APP_ENV is required', {
    env: JSON.stringify(env),
  });

  if (env === AppEnv.production) {
    clientId = {
      android: GOOGLE_ANDROID_CLIENT_ID_PROD,
      ios: GOOGLE_IOS_CLIENT_ID_PROD,
      web: GOOGLE_WEB_CLIENT_ID_PROD,
    }[platform];
  } else {
    clientId = {
      android: GOOGLE_ANDROID_CLIENT_ID_DEV,
      ios: GOOGLE_IOS_CLIENT_ID_DEV,
      web: GOOGLE_WEB_CLIENT_ID_DEV,
    }[platform];
  }

  invariant(
    clientId,
    `Google client ID for env: ${env} and platform: ${platform} was not found!`
  );

  return clientId;
}
