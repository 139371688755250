import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Tooltip} from '@youtoken/ui.tooltip';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {WalletFeatureArgs, WalletFeature} from '../../../../../../state';
import {Button} from '@youtoken/ui.buttons';

export interface ActionsProps extends BoxProps {
  ticker: WalletFeatureArgs['ticker'];
  onPressDeposit: () => void;
  onPressWithdrawal: () => void;
  onPressConvert: () => void;
  onPressHodl?: () => void;
  onFocus?: () => void;
}

export const Actions: React.FC<ActionsProps> = observer(
  ({
    ticker: _ticker,
    onPressDeposit,
    onPressWithdrawal,
    onPressConvert,
    onPressHodl,
    onFocus,
    ...otherProps
  }) => {
    const {
      ticker,
      tickerType,
      enableHodl,
      disabledDeposit,
      disabledWithdraw,
      disabledConvert,
      disabledHodlForWallet,
    } = WalletFeature.use({
      ticker: _ticker,
    });

    const {t} = useTranslation();
    const tickerFormatted = ticker.toUpperCase();

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        {...otherProps}
      >
        <Tooltip
          content={t('surface.wallets.common.deposit_button')}
          contentProps={{alignItems: 'center'}}
          onShow={onFocus}
        >
          <Button
            type="secondary"
            icon={tickerType === 'fiat' ? 'fiat_deposit' : 'crypto_deposit'}
            disabled={disabledDeposit}
            onPress={onPressDeposit}
            width={32}
            height={32}
            mr={10}
            testID={`${tickerFormatted}_DEPOSIT_BUTTON`}
          />
        </Tooltip>

        <Tooltip
          content={t('surface.wallets.common.withdraw_button')}
          contentProps={{alignItems: 'center'}}
          onShow={onFocus}
        >
          <Button
            type="secondary"
            icon={tickerType === 'fiat' ? 'fiat_withdraw' : 'send'}
            disabled={disabledWithdraw}
            onPress={onPressWithdrawal}
            width={32}
            height={32}
            mr={10}
            testID={`${tickerFormatted}_WITHDRAW_BUTTON`}
          />
        </Tooltip>

        <Tooltip
          content={t('surface.wallets.common.convert_button')}
          contentProps={{alignItems: 'center'}}
          onShow={onFocus}
        >
          <Button
            type="secondary"
            icon="exchange"
            disabled={disabledConvert}
            onPress={onPressConvert}
            width={32}
            height={32}
            mr={10}
            testID={`${tickerFormatted}_CONVERT_BUTTON`}
          />
        </Tooltip>

        <Tooltip
          content={t('surface.wallets.common.boost_button')}
          contentProps={{alignItems: 'center'}}
          onShow={onFocus}
        >
          <Button
            type="secondary"
            icon="hodl"
            disabled={disabledHodlForWallet || !enableHodl || !onPressHodl}
            onPress={onPressHodl}
            width={32}
            height={32}
            testID={`${tickerFormatted}_BOOST_BUTTON`}
          />
        </Tooltip>
      </Box>
    );
  }
);
