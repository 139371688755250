import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Requisites} from '../Requisites';

interface AlternativeDepositRequisitesProps {
  ticker: string;
}

export const AlternativeDepositRequisites: React.FC<
  AlternativeDepositRequisitesProps
> = ({ticker}) => {
  return (
    <Box width="100%" height="100%">
      <Requisites isAlternative ticker={ticker} />
    </Box>
  );
};
