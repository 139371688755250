import React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {ReferralsAndBonusesScreenV1} from './v1';
import {ReferralsAndBonusesScreenV3} from './v3';

export const ReferralsAndBonusesScreen = cell(() => {
  const {
    data: {rewardVersion},
  } = AuthMeResource.use({});

  if (rewardVersion === 3) {
    return <ReferralsAndBonusesScreenV3 />;
  }

  return <ReferralsAndBonusesScreenV1 />;
});
