import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {type BaseIconName} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';

export interface ButtonsSwitcherItem<T = string> {
  value: T;
  title: string;
  icon?: BaseIconName;
  indicator?: BaseIconName;
  testID?: string;
}

export interface ButtonsSwitcherElementProps<T = string>
  extends React.ComponentProps<typeof Box> {
  items: ButtonsSwitcherItem<T>[];
  value?: T;
  onChange: (value: T) => void;
  wide?: boolean;
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
}

export const ButtonsSwitcherElement: React.FC<ButtonsSwitcherElementProps> = ({
  value,
  onChange,
  items,
  wide = true,
  buttonProps,
  ...rest
}) => {
  return (
    <Box
      flexDirection="row"
      width={wide ? '100%' : 'auto'}
      backgroundColor="$interactive-02"
      borderRadius={5}
      {...rest}
    >
      {items.map((item, index) => {
        const active = item.value === value;

        return (
          <Box
            flexBasis={`${100 / items.length}%`}
            key={index}
            testID={item.testID}
          >
            <Button
              color="interactive"
              type={active ? 'primary' : 'secondary'}
              onPress={() => onChange(item.value)}
              icon={item.icon}
              size="small"
              {...buttonProps}
            >
              {item.title}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};
