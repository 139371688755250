import * as React from 'react';
import {ScrollView} from 'react-native';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {AmountSet, DataTable, RepayInfo} from '..';

export const Content: React.FC<BoxProps> = props => {
  return (
    <Box {...props}>
      <ScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flexGrow: 1,
        }}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <AmountSet zIndex={1} />
        <Separator
          my={{
            default: 16,
            phone: 24,
          }}
        />
        <DataTable flex={1} />
        <Separator
          my={{
            default: 16,
            phone: 24,
          }}
        />
        <RepayInfo />
      </ScrollView>
    </Box>
  );
};
