import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DownIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 4.5l3.75 3.75H5a.75.75 0 000 1.5h4.25a.5.5 0 00.5-.5V5a.75.75 0 10-1.5 0v2.25L4.5 3.5a.707.707 0 00-1 1z"
          fill={color}
        />
      </Svg>
    );
  }
);
