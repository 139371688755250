import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithBoxProps} from '@youtoken/ui.primitives';
import {type CryptoDepositProps} from '../types';
import {HeaderBase} from '../components';

export const CryptoDepositHeader: React.FC<WithBoxProps<CryptoDepositProps>> =
  React.memo(({ticker, onPressBack, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <HeaderBase onPressBack={onPressBack} {...boxProps}>
        {t('surface.wallets.crypto_deposit.title', {
          ticker: ticker.toUpperCase(),
        })}
      </HeaderBase>
    );
  });
