import * as React from 'react';
import {observer} from 'mobx-react';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {BoxProps, Text} from '@youtoken/ui.primitives';
import {AgreementsList} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Section} from '../Section';

export const Agree: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {enabled, form} = useForm<FeatureLoanCreateForm>();

  if (!enabled) {
    return (
      <Section {...props}>
        <Text variant="$body-02" color="$danger-01">
          {t('surface.loans.create_form.product_unavailable')}
        </Text>
      </Section>
    );
  }

  return (
    <Section {...props}>
      <AgreementsList
        items={form.agreements}
        beforeListText={t('agreements.documents.without_checkbox.start_text', {
          call_to_action: t('surface.loans.create_form.submit'),
        })}
        textProps={{variant: '$body-02', color: '$text-02'}}
      />
    </Section>
  );
});
