import {messages} from '@youtoken/ui.validation-messages';
import big, {BigSource, Big} from 'big.js';
import {BaseSchema} from 'yup';
import {Message} from 'yup/lib/types';
import {bigToString} from './bigToString';

export function create() {
  return new BigSchema();
}

export default class BigSchema extends BaseSchema<Big> {
  constructor() {
    super({type: 'big'});

    this.default(big(0));

    this.withMutation(() => {
      this.transform(function (value) {
        try {
          return big(value);
        } catch (e) {
          return null;
        }
      });
    });
  }

  // TODO with type check yup returns tech error, which I cant redefine
  // protected _typeCheck(value: any): value is NonNullable<any> {
  //   return value !== null;
  // }

  eq(valueToCompare: BigSource, message?: Message) {
    const _message = message
      ? message
      : () => messages.SHOULD_BE_EQUAL({value: bigToString(valueToCompare)});

    return this.test({
      message: _message,
      name: 'eq',
      params: {valueToCompare},
      test: value => {
        try {
          return big(value).eq(valueToCompare);
        } catch (e) {
          return false;
        }
      },
    });
  }

  gt(valueToCompare: BigSource, message?: Message) {
    const _message = message
      ? message
      : () =>
          messages.SHOULD_BE_GT({
            value: bigToString(valueToCompare),
            ticker: '',
          });

    return this.test({
      message: _message,
      name: 'gt',
      params: {valueToCompare},
      test: value => {
        try {
          return big(value).gt(valueToCompare);
        } catch (e) {
          return false;
        }
      },
    });
  }

  gte(valueToCompare: BigSource, message?: Message) {
    const _message = message
      ? message
      : () =>
          messages.SHOULD_BE_GTE({
            value: bigToString(valueToCompare),
          });

    return this.test({
      message: _message,
      name: 'gte',
      params: {valueToCompare},
      test: value => {
        try {
          return big(value).gte(valueToCompare);
        } catch (e) {
          return false;
        }
      },
    });
  }

  lt(valueToCompare: BigSource, message?: Message) {
    const _message = message
      ? message
      : () => messages.SHOULD_BE_LT({value: bigToString(valueToCompare)});

    return this.test({
      message: _message,
      name: 'lt',
      params: {valueToCompare},
      test: value => {
        try {
          return big(value).lt(valueToCompare);
        } catch (e) {
          return false;
        }
      },
    });
  }

  lte(valueToCompare: BigSource, message?: Message) {
    const _message = message
      ? message
      : () =>
          messages.SHOULD_BE_LTE({
            value: bigToString(valueToCompare),
          });

    return this.test({
      message: _message,
      name: 'lte',
      params: {valueToCompare},
      test: value => {
        try {
          return big(value).lte(valueToCompare);
        } catch (e) {
          return false;
        }
      },
    });
  }
}

create.prototype = BigSchema.prototype;
