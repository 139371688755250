import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export interface SavingsSelectAssetsFilterSearchIconProps {
  focused: boolean;
}

export const SavingsSelectAssetsFilterSearchIcon: React.FC<
  SavingsSelectAssetsFilterSearchIconProps
> = ({focused}) => {
  return (
    <Box justifyContent="center" alignItems="center" flex={1} px={12}>
      <Icon
        name="search"
        size={20}
        color={focused ? '$interactive-01' : '$text-03'}
      />
    </Box>
  );
};
