import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {SavingsSignAgreementsFormProps} from './types';
import {SavingsSignAgreementsFormState} from './state';
import {useSavingsSignAgreementsFormResources} from './hooks';
import {ScrollView} from '../../components';
import {
  SavingsSignAgreementsFormHeader,
  SavingsSignAgreementsFormContent,
  SavingsSignAgreementsFormFooter,
} from './components';

export const SavingsSignAgreementsForm: React.FC<
  SavingsSignAgreementsFormProps & BoxProps
> = cell(({onClose, ...boxProps}) => {
  const formResources = useSavingsSignAgreementsFormResources();

  const form = makeForm(
    () =>
      new SavingsSignAgreementsFormState(
        {
          onClose,
        },
        formResources
      )
  );

  return (
    <Box testID="SAVINGS_SIGN_AGREEMENTS_FORM" flex={1} {...boxProps}>
      <Form form={form}>
        <ScrollView
          style={{
            flex: 1,
          }}
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          <SavingsSignAgreementsFormHeader />
          <SavingsSignAgreementsFormContent />
        </ScrollView>
        <SavingsSignAgreementsFormFooter />
      </Form>
    </Box>
  );
});
