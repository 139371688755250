import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ChartBarIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M15.75 4a.75.75 0 00-.75.75v11.5a.75.75 0 001.5 0V4.75a.75.75 0 00-.75-.75zM7 6.75a.75.75 0 011.5 0v9.5a.75.75 0 01-1.5 0v-9.5zM3.75 8.5a.75.75 0 00-.75.75v7a.75.75 0 001.5 0v-7a.75.75 0 00-.75-.75zm8 0a.75.75 0 00-.75.75v7a.75.75 0 001.5 0v-7a.75.75 0 00-.75-.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
