import * as React from 'react';
import {TouchableBox, type TouchableBoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {CONTROLS_HEIGHT} from '../constants';

export const TradingViewModeButton: React.FC<
  TouchableBoxProps & {mode: 'classic' | 'tradingview'}
> = React.memo(({mode, onPress, ...props}) => {
  return (
    <TouchableBox
      activeOpacity={0.8}
      height={CONTROLS_HEIGHT}
      flexDirection="row"
      backgroundColor={
        mode === 'tradingview' ? '$interactive-02' : '$transparent'
      }
      alignItems="center"
      justifyContent="center"
      px={4}
      borderRadius={6}
      onPress={onPress}
      {...props}
    >
      <Icon
        name="trading_view"
        size={20}
        color={mode === 'tradingview' ? '$text-05' : '$text-02'}
      />
    </TouchableBox>
  );
});
