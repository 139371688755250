import * as React from 'react';
import {i18n} from '../index';

export const DetectLangComponent: React.FC = () => {
  React.useEffect(() => {
    /**
     * NOTE
     * Without setTimeout restoring user language may be unsuccessful
     */
    setTimeout(() => {
      i18n.detectUserLanguage();
    });
  }, []);
  return null;
};
