import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryV3Resource} from '@youtoken/ui.resource-rewards-v3-history';
import {RewardsHistoryFlatListV3} from '../../../..';
import {RewardsHistorySmartProps} from '../../types';

export const RewardsHistoryFlatListV3Smart: React.FC<RewardsHistorySmartProps> =
  observer(({showHeader, limit}) => {
    const {
      data: {items},
    } = RewardsHistoryV3Resource.use({limit});

    return <RewardsHistoryFlatListV3 items={items} showHeader={showHeader} />;
  });
