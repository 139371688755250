import * as React from 'react';
import {observer} from 'mobx-react';
import {Modal} from '@youtoken/ui.modal';
import {
  BankCardTransferSurface,
  getBankCardTransferTitle,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {modal} from '../../../stores';
import {Modal as _Modal} from '../_common';

export const modalBankCardTransferName = 'bankCardTransfer';

type ModalBankCardTransferProps = {
  cardId: string;
};

export const ModalBankCardTransfer: React.FC<
  ModalBankCardTransferProps & React.ComponentProps<typeof _Modal>
> = observer(({cardId}) => {
  const {opened, close} = modal;

  return (
    <Modal
      isOpen={opened?.name === modalBankCardTransferName}
      onClose={close}
      title={getBankCardTransferTitle()}
      webOverlayBackgroundColor="$transparent"
    >
      <BankCardTransferSurface cardId={cardId} />
    </Modal>
  );
});
