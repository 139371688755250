import * as React from 'react';
import {BaseErrorFallback} from './BaseFallback';
import {Handler} from './Handler';
import {ErrorFallbackProps} from './types';

export const OfflineErrorFallback: React.FC<ErrorFallbackProps> = props => {
  return (
    <BaseErrorFallback
      text="Application is offline"
      iconName="refresh"
      hint="Please check your internet connection"
      {...props}
    >
      <Handler onPress={props.dismissError}>Retry</Handler>
    </BaseErrorFallback>
  );
};
