import * as React from 'react';
import {observer} from 'mobx-react';
import {nanoid} from 'nanoid';
import {handleGeneralErrorTranslated} from '@youtoken/ui.validation-messages';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Button} from '@youtoken/ui.buttons';

interface OpenNowButtonProps extends React.ComponentProps<typeof Button> {
  hodl: HODLItem;
}

export const OpenNowButton: React.FC<OpenNowButtonProps> = observer(
  ({hodl, ...buttonAndBoxProps}) => {
    const {t} = useTranslation();
    const requestIdRef = React.useRef(nanoid());
    const [isLoading, setIsLoading] = React.useState(false);

    const handleOpenNow = React.useCallback(() => {
      setIsLoading(true);
      hodl
        ?.openNowPendingHodl(requestIdRef.current)
        .catch(e => {
          handleGeneralErrorTranslated(e.response?.data);
        })
        .finally(() => setIsLoading(false));
    }, [hodl]);

    return (
      <Button
        disabled={isLoading}
        onPress={handleOpenNow}
        size="large"
        {...buttonAndBoxProps}
      >
        {t('surface.hodls.item.open_now_button')}
      </Button>
    );
  }
);
