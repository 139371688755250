import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LegalEntity} from '@youtoken/ui.resource-auth-me';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {AgreementsList} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {ConversionFormClass, ExchangeFormState} from '../../state';

export const ExchangeFormAgreeToTerms: React.FC<BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        resources: {
          docsResource: {
            documents: {convert},
          },
          authMeResource: {legalEntity},
        },
      },
      view: {conversionTickerFormatted},
    } = useForm<ConversionFormClass | ExchangeFormState>();

    const extraText = React.useMemo(() => {
      return legalEntity === LegalEntity.ALL
        ? `${t('agreement.convert_form.addition')}.`
        : '';
    }, [legalEntity]);

    return (
      <Box mb={24} {...boxProps}>
        <AgreementsList
          beforeListText={t(
            'agreements.documents.without_checkbox.start_text',
            {
              call_to_action: t('surface.wallets.conversion_form.submit', {
                amount: conversionTickerFormatted,
              }),
            }
          )}
          items={convert}
          extraText={extraText}
        />
      </Box>
    );
  }
);
