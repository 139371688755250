import * as React from 'react';
import {G, Rect, Text} from 'react-native-svg';
import {themes} from '@youtoken/ui.primitives';

const font = themes.light.fonts.mono.regular;

type LabelVariant = 'success' | 'danger' | 'neutral' | 'disabled';

export const Label: React.FC<{
  x: number;
  y: number;
  width: number;
  height: number;
  textFill?: string;
  labelFill?: string;
  labelOpacity?: number;
}> = ({
  x,
  y,
  width,
  height,
  textFill = 'white',
  labelFill = 'red',
  labelOpacity = 1,
  children,
}) => {
  return (
    <G>
      <Rect
        x={x}
        y={y - 8}
        width={width}
        height={height}
        rx={3}
        fill={labelFill}
        opacity={labelOpacity}
      />

      <Text
        fontFamily={font}
        fontSize={11}
        letterSpacing={0.23}
        x={x + 6}
        y={y + 3}
        fill={textFill}
      >
        {children}
      </Text>
    </G>
  );
};
