import React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';

export interface RowItemProps extends BoxProps {
  title?: string;
  text?: string;
}

export const RowItem: React.FC<RowItemProps> = ({title, text, ...boxProps}) => {
  return (
    <Box alignItems="flex-start" {...boxProps}>
      {title !== undefined && (
        <Text variant="$body-02" color="$text-01" mb={2}>
          {title}
        </Text>
      )}
      {text !== undefined && (
        <Text variant="$body-02" color="$text-02" ellipsizeMode="tail">
          {text}
        </Text>
      )}
    </Box>
  );
};
