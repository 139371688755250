import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';

export interface DetailedActionsProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onPressPay: (args: LoansListItemFeatureArgs) => void;
  onPressCancel: (args: LoansListItemFeatureArgs) => void;
  onPressCloseNow: (args: LoansListItemFeatureArgs) => void;
}

export const DetailedActions: React.FC<DetailedActionsProps> = observer(
  ({mode, id, onPressPay, onPressCancel, onPressCloseNow, ...rest}) => {
    const {t} = useTranslation();

    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const handlePressCloseNow = React.useCallback(() => {
      onPressCloseNow({id: loan.id!, mode: loan.mode});
    }, [onPressCloseNow, loan]);

    const handlePressPay = React.useCallback(() => {
      onPressPay({id: loan.id!, mode: loan.mode});
    }, [onPressPay, loan]);

    const handlePressCancel = React.useCallback(() => {
      onPressCancel({id: loan.id!, mode: loan.mode});
    }, [onPressCancel, loan]);

    const handlePressQuickAction = React.useMemo(() => {
      return loan.isOpening ? handlePressCancel : handlePressPay;
    }, [loan.isOpening, handlePressPay, handlePressCancel]);

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box px={20} pb={20} {...rest}>
        <Button
          size="large"
          disabled={!loan.quickActionEnabled}
          onPress={handlePressQuickAction}
          width="100%"
        >
          {t(loan.quickActionText)}
        </Button>
        {loan.allowCloseNow && (
          <Button
            size="large"
            type="secondary"
            disabled={!loan.enabledCloseNow}
            onPress={handlePressCloseNow}
            width="100%"
            mt={10}
          >
            {t('surface.loans.item.close_now')}
          </Button>
        )}
      </Box>
    );
  }
);
