import {
  BaseTheme,
  createRestyleFunction,
  ResponsiveValue,
} from '@shopify/restyle';

export const color = createRestyleFunction({
  property: 'color',
  styleProperty: 'color',
  themeKey: 'colors',
  // important part - accept value as is if not found in themeKey
  transform: ({value, theme, themeKey}) => {
    if (theme[themeKey as keyof typeof theme]?.[value]) {
      return theme[themeKey as keyof typeof theme][value];
    }

    return value;
  },
});

export interface ColorProps<Theme extends BaseTheme> {
  color?: ResponsiveValue<keyof Theme['colors'], Theme>;
}
