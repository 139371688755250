import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {RatesSwitcher} from './RatesSwitcher';

export const Rates: React.FC = () => {
  return (
    <Box px={12} py={8} mt={20}>
      <RatesSwitcher />
    </Box>
  );
};
