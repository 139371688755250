import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const YoutubeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg width={size} height={size} viewBox="0 0 20 20" {...props}>
        <Path
          d="M17.376 5.444c-.577-.67-1.644-.944-3.68-.944H6.304c-2.083 0-3.168.291-3.743 1.005C2 6.201 2 7.227 2 8.647v2.706c0 2.75.666 4.147 4.304 4.147h7.392c1.766 0 2.745-.241 3.378-.833.649-.607.926-1.598.926-3.314V8.647c0-1.497-.043-2.53-.624-3.203zm-5.104 4.93l-3.357 1.713a.526.526 0 01-.506-.015.502.502 0 01-.249-.431V8.224c0-.176.094-.34.248-.431a.527.527 0 01.506-.017l3.357 1.703c.17.087.278.26.278.447 0 .188-.106.36-.277.448z"
          fill={color}
        />
      </Svg>
    );
  }
);
