import React, {useContext} from 'react';
import {Text, Box} from '@youtoken/ui.primitives';
import {FormikContext} from '@web-app/src/components/contexts';

export type LabelProps = {
  renderLabelPlugins?: Function;
  text?: string;
};

export const Label: React.FC<LabelProps> = ({children, renderLabelPlugins}) => {
  const formikProps = useContext(FormikContext);

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      flex={1}
      height={20}
      mb={10}
    >
      <Text variant="$body-01-high-accent">{children}</Text>

      {renderLabelPlugins && (
        <Box
          flex={0}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {renderLabelPlugins(formikProps)}
        </Box>
      )}
    </Box>
  );
};
