import {StyleSheet} from 'react-native';

export const sliderElementStyles = StyleSheet.create({
  wrap: {
    position: 'absolute',
    height: 16,
  },
  inner: {
    position: 'absolute',
    width: '100%',
    height: 4,
    top: 6,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
  },
  handle: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  tick: {
    position: 'absolute',
    width: 2,
    height: 8,
    marginLeft: 8,
  },
});
