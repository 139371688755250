import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {MenuButton} from './elements';
import {AppEnvStatus} from './AppEnv';
import {ThemeSwitcher} from './ThemeSwitcher';
import {HeaderProps} from './types';

export const HeaderLedger: React.FC<HeaderProps> = React.memo(
  ({renderRight, renderLeft}) => {
    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        px={{default: 16, tablet: 24}}
        py={12}
        zIndex={1}
        borderBottomWidth={1}
        borderColor="$ui-01"
      >
        <Box flexDirection="row">
          {renderLeft?.()}
          <MenuButton ml={-10} />
        </Box>

        <Box flexDirection="row" zIndex={-1}>
          {renderRight?.()}
          <AppEnvStatus />
          <ThemeSwitcher />
        </Box>
      </Box>
    );
  }
);
