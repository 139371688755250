import * as React from 'react';
import {TextInput, TextInputProps} from '../TextInput';
import {i18n} from '@youtoken/ui.service-i18n';

export const EmailTextInput: React.FC<TextInputProps> = ({...props}) => {
  return (
    <TextInput
      placeholder={i18n.t('inputs.email.placeholder')}
      textContentType="emailAddress"
      autoCapitalize="none"
      keyboardType="email-address"
      autoCorrect={false}
      autoComplete="email"
      importantForAutofill="yes"
      {...props}
    />
  );
};
