import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalFormState} from '../types';

export const CryptoWithdrawalExternalAmountAllLink: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    walletTickerFormatted,
    form: {walletAmount, amountOnPressAll},
  } = useForm<CryptoWithdrawalFormState>();

  return (
    <Link onPress={amountOnPressAll} testID="ALL_BUTTON">
      <Text variant="$body-02">
        {t('surface.wallets.crypto_withdrawal.amount_all')}{' '}
        <Text variant="$body-02-medium-accent">{walletAmount}</Text>{' '}
        {walletTickerFormatted}
      </Text>
    </Link>
  );
});
