import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {ExchangeTariffsPublicResponse} from './ExchangeTariffsPublicResponse';

export class ExchangeTariffsPublicResource extends createResource({
  getKey: () => `ExchangeTariffsPublic`,
  getData: () =>
    TRANSPORT.API.get('/v2/converts/overview-public').then(res => {
      return deserialize(ExchangeTariffsPublicResponse, res.data);
    }),
}) {
  @computed
  get tariffs() {
    return this.data.tariffs;
  }

  @computed
  get tickers() {
    return Object.keys(this.tariffs);
  }

  getConversionTickers = computedFn(ticker => {
    return Object.keys(this.tariffs[ticker] ?? []);
  });

  getTariff = computedFn((ticker, conversionTicker) => {
    return this.tariffs[ticker]?.[conversionTicker];
  });
}
