import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';

export const AccountLevelProgressWidgetLayout: React.FC = observer(
  ({children}) => {
    const {t} = useTranslation();

    return (
      <Box testID="ACCOUNT_LEVEL_DETAILED_WIDGET_PROGRESS">
        <Text variant="$body-01-high-accent" mb={16} textAlign="center">
          {t('surface.loyalty.next_sprint_level')}
        </Text>
        {children}
      </Box>
    );
  }
);
