import {Platform} from 'react-native';
import {i18n} from '@youtoken/ui.service-i18n';
import {FeatureItemProps} from './FeatureItem';

export const useFeatures = (
  t: typeof i18n.t,
  enableSkipCardOrderFee: boolean
): FeatureItemProps[] => {
  const features: FeatureItemProps[] = [
    {
      text: t('surface.bank_cards.landing.features.one'),
      icon: 'interest',
    },
  ];

  if (!enableSkipCardOrderFee) {
    features.push({
      text: Platform.select({
        default: t('surface.bank_cards.landing.features.two'),
        web: t('surface.bank_cards.landing.features.two.single_line'),
      }),
      icon: 'money',
      asteriskCount: 1,
    });
  }

  return features.concat([
    {
      text: enableSkipCardOrderFee
        ? t('surface.bank_cards.landing.features.three.free')
        : t('surface.bank_cards.landing.features.three'),
      icon: 'card',
    },
    {
      text: t('surface.bank_cards.landing.features.four'),
      icon: 'fiat_deposit',
    },
    {
      text: t('surface.bank_cards.landing.features.five'),
      icon: 'gift',
    },
    {
      text: t('surface.bank_cards.landing.features.six'),
      icon: 'fingerprint',
    },
  ]);
};
