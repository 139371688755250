import * as React from 'react';
import {observer} from 'mobx-react';
import {smoothScroll} from '@youtoken/ui.utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Pagination} from '@youtoken/ui.elements';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {Filters} from '@youtoken/ui.filters';
import {
  ClosedHODLsPaginationAndFilterFeature,
  HODLsListFeature,
} from '../../../features';
import {HODLsList} from '../../components';

const CLOSED_HEADER_ID = 'closed_hodls_heading';

interface HODLsClosedBlockProps extends BoxProps {
  handleNavigate: (param: {}) => void;
}

export const HODLsClosedBlock: React.FC<HODLsClosedBlockProps> = observer(
  ({handleNavigate, ...boxProps}) => {
    const {t} = useTranslation();

    const {closed: closedHODLs} = HODLsListFeature.use({});
    const feature = ClosedHODLsPaginationAndFilterFeature.use({});

    const {
      activePage,
      totalPages,
      closedHodlsTickerFilters,
      selectedTickers,
      applyFilters,
    } = feature;

    const handlePageChange = React.useCallback(
      (pageNumber: number) => {
        handleNavigate({pageClosed: pageNumber});

        setTimeout(() => {
          smoothScroll(CLOSED_HEADER_ID);
        }, 500);
      },
      [handleNavigate]
    );

    const handleApplyFilters = ({
      selectedTickers,
    }: {
      selectedTickers: string[];
    }) => {
      applyFilters({selectedTickers});
      handleNavigate({filterClosed: selectedTickers, pageClosed: 1});
    };

    return (
      <Box nativeID={CLOSED_HEADER_ID} {...boxProps}>
        <Box px={{default: 20, desktop: 0}}>
          <Heading variant="$heading-01">
            {t('surface.hodls.portfolio.closed_title')}
          </Heading>
        </Box>

        {closedHodlsTickerFilters.length > 1 && (
          <Box px={{default: 20, tablet: 0}}>
            <Filters
              tickers={closedHodlsTickerFilters}
              selectedTickers={selectedTickers}
              applyFilters={handleApplyFilters}
              showTitles={false}
              scrollOffset={20}
              getTickerFormatted={ticker => ticker.toUpperCase()}
              testIdBase="HODLS_CLOSED"
              mx={{default: -20, tablet: 20, desktop: 0}}
              pt={16}
              pb={{default: 0, desktop: 8}}
            />
          </Box>
        )}

        <HODLsList hodlsList={closedHODLs} />

        {totalPages > 1 && (
          <Box px={{default: 20, desktop: 0}} my={24}>
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    );
  }
);
