import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Item} from './components/Item';
import {type IRadioItem, type TRadioValue} from './types';

interface RadioFieldProps {
  value: TRadioValue;
  items: IRadioItem[];
  disabled?: boolean;
  onChange: (value: TRadioValue) => void;
}

export const RadioField: React.FC<RadioFieldProps & BoxProps> = observer(
  ({value, items, disabled = false, onChange, ...boxProps}) => {
    return (
      <Box {...boxProps}>
        {items.map(item => (
          <Item
            key={item.value}
            item={item}
            checked={item.value === value}
            disabled={disabled}
            onChange={onChange}
          />
        ))}
      </Box>
    );
  }
);
