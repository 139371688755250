import {serializable} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {Big} from 'big.js';

export class CalculatedData {
  @serializable(bigNumber())
  maxLoss!: Big;

  @serializable(bigNumber())
  maxProfit: null | Big = null;
}
