import * as React from 'react';
import {
  SectionList,
  type SectionListData,
  type SectionListRenderItem,
} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import type {BankCardTransaction} from '@youtoken/ui.resource-bank-cards';
import {HistoryListEmptyState} from './components/HistoryListEmptyState';
import {SectionHeader} from './components/SectionHeader';
import {TransactionItem} from '../TransactionItem';
import type {BankCardTransactionsListProps} from './types';

const handleRenderSectionHeader = ({
  section,
}: {
  section: SectionListData<BankCardTransaction>;
}) => {
  return <SectionHeader section={section} />;
};

const renderItem: SectionListRenderItem<BankCardTransaction> = props => (
  <TransactionItem {...props} />
);

export const TransactionsList: React.FC<BankCardTransactionsListProps> = cell(
  ({
    sectionsByDate,
    isLoading,
    SectionListHeaderComponent,
    SectionListComponent = SectionList,
  }) => {
    const keyExtractor = React.useCallback(
      (item: BankCardTransaction) => item.transactionId,
      []
    );

    return (
      <>
        {React.isValidElement(SectionListHeaderComponent) &&
          SectionListHeaderComponent}
        <Box width="100%" flexGrow={1} opacity={isLoading ? 0.6 : 1}>
          <Box borderColor="$ui-01" borderWidth={1} overflow="hidden" flex={1}>
            <SectionListComponent
              stickySectionHeadersEnabled
              sections={sectionsByDate}
              renderSectionHeader={handleRenderSectionHeader}
              renderItem={renderItem}
              ItemSeparatorComponent={Separator}
              keyExtractor={keyExtractor}
              ListEmptyComponent={HistoryListEmptyState}
            />
          </Box>
        </Box>
      </>
    );
  }
);
