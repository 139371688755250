import {GarbageCollector, IntervalRefetcher} from './ResourceFeatures';
import {ResourceDescription} from './types';
import {createStaticResource} from './createStaticResource';

/** createResource (the default one)
 * - all from staticResource;
 * - interval refetcher - will run getData every `refetchInterval`.
 * - garbage collector - after stop reading data from resource will set deletion of Resource in `cacheTime`
 */
export function createResource<Args extends object, Result extends any>({
  cacheTime = 180 * 1000,
  refetchInterval = 60 * 1000,
  ...descriptor
}: ResourceDescription<Args, Result>) {
  return class Resource extends createStaticResource(descriptor) {
    onInit() {
      super.onInit();
      this.logger('onInit', 'from createResource');
    }
    onDestroy() {
      this.garbageCollector.onDestroy();
      this.intervalRefetcher.onDestroy();
      super.onDestroy();
      this.logger('onDestroy', 'from createResource');
    }

    onFetchStarted() {
      this.logger('onFetchStarted');
      this.intervalRefetcher.clearRefetchInterval();
    }

    onFetchSucceeded() {
      this.logger('onFetchSucceeded');
      this.intervalRefetcher.setRefetchInterval();
    }

    onFetchFailed() {
      this.logger('onFetchFailed');
      this.intervalRefetcher.setRefetchInterval();
    }

    garbageCollector!: GarbageCollector<Args, Result>;
    intervalRefetcher!: IntervalRefetcher<Args, Result>;

    constructor(args: Args, data: Result) {
      super(args, data);
      this.garbageCollector = new GarbageCollector(this, cacheTime);
      this.intervalRefetcher = new IntervalRefetcher(this, refetchInterval);
    }
  };
}
