import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLMarketStatsResource} from '@youtoken/ui.resource-hodl-market-stats';
import {Text} from '@youtoken/ui.primitives';

type CardItemCapProps = {
  id: string;
  testID?: string;
};

export const CardItemCap: React.FC<CardItemCapProps> = observer(
  ({id, testID}) => {
    const {getMarketCapById} = HODLMarketStatsResource.use({count: 10}); // We needed info only about 10 cards fetched before
    const marketCapFormatted = getMarketCapById(id);

    return (
      <Text variant="$body-02" color="$text-01" testID={testID}>
        {marketCapFormatted}
      </Text>
    );
  }
);
