import * as React from 'react';
import {observer} from 'mobx-react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {TickerSelector, UmaInput} from './components';
import {UmaCreateForm} from './form';

export interface UmaCreateSurfaceProps {
  onSuccess: () => void;
}

export const UmaCreateSurface: React.FC<UmaCreateSurfaceProps> = observer(
  ({onSuccess}) => {
    const {t} = useTranslation();

    const resources = useResources({
      wallets: getResourceDescriptor(WalletsResource, {}),
      umaInfo: getResourceDescriptor(UmaInfoResource, {}),
    });

    const form = makeForm(
      () => new UmaCreateForm({onSuccess: onSuccess}, resources)
    );

    const {
      form: {isSubmitLoading, isSubmitDisabled, onSubmit},
    } = form;

    const handlePressSubmit = React.useCallback(() => {
      DATA_LAYER.trackStrict('uma-create-attempt', {});
      onSubmit();
    }, [onSubmit]);

    React.useEffect(() => {
      const {
        data: {
          products: {
            uma: {
              available,
              settings: {enable},
            },
          },
        },
      } = AuthMeResource.getInstance({});

      if (!enable || !available) {
        SHARED_ROUTER_SERVICE.navigate('More', {});
      }
    }, []);

    return (
      <Form form={form}>
        <Box flexGrow={1} justifyContent="space-between">
          <Box zIndex={1}>
            <Box px={24} py={20} justifyContent="center">
              <Text variant="$body-02" color="$text-02">
                {t('surface.wallets.uma.create.explanation')}
              </Text>
            </Box>

            <Separator />
            <UmaInput />
            <TickerSelector />
          </Box>

          <Box p={24}>
            <Button
              size="large"
              loading={isSubmitLoading}
              disabled={isSubmitDisabled}
              onPress={handlePressSubmit}
              testID="UMA_CREATE_SUBMIT_BUTTON"
            >
              {t('surface.wallets.uma.create')}
            </Button>
          </Box>
        </Box>
      </Form>
    );
  }
);
