import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {
  SignUpAdditionalSurfaceCell,
  SignUpAdditionalSurfaceProps,
} from '@youtoken/ui.surface-sign-up-additional';
import {modal} from '@web-app/src/stores';
import {Modal} from '../_common';

type ModalSignUpAdditionalProps = React.ComponentProps<typeof Modal> &
  SignUpAdditionalSurfaceProps;

export const modalSignUpAdditionalName = 'modalSignUpAdditional';

export const ModalSignUpAdditional: React.FC<ModalSignUpAdditionalProps> =
  observer(
    ({email, provider, idToken, accessCode, residence, ...modalProps}) => {
      return (
        <Modal
          shouldCloseOnEsc={false}
          shouldCloseOnOverlay={false}
          withCloseButton={false}
          {...modalProps}
        >
          <Box p={24}>
            <SignUpAdditionalSurfaceCell
              email={email}
              provider={provider}
              idToken={idToken}
              accessCode={accessCode}
              residence={residence}
              onClose={modal.close}
            />
          </Box>
        </Modal>
      );
    }
  );
