import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const ExpirationInfo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {hodlExpirationPeriod},
  } = useForm<CreateHODLFormClass>();

  if (!hodlExpirationPeriod) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <Text variant="$body-02" color="$text-02">
        {t('surface.hodls.hodl_form.footer.expiration_text', {
          count: hodlExpirationPeriod,
        })}
      </Text>
    </Box>
  );
});
