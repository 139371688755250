import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1} from '@youtoken/ui.resource-loans';
import {Box, Text} from '@youtoken/ui.primitives';
import {LoanItemHeaderStatusOverdraftAmount} from '../LoanItemHeaderStatusOverdraftAmount';

export interface LoanItemHeaderStatusOtherV1Props {
  item: LoanRegularV1;
}

export const LoanItemHeaderStatusOtherV1: React.FC<LoanItemHeaderStatusOtherV1Props> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <Box testID={`LOAN_ITEM_STATUS_V1_${item.status}`} alignItems="flex-end">
        <LoanItemHeaderStatusOverdraftAmount item={item} />
        <Text
          testID="LOAN_ITEM_STATUS_V1_DISTANCE"
          variant="$body-02"
          color="$text-02"
        >
          {t('surface.loans.item.to_repay_distance', {
            distance: item.finishAtFormatted,
          })}
        </Text>
      </Box>
    );
  });
