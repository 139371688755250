import {Duration} from '@youtoken/ui.date-fns';
import {invariant} from '@youtoken/ui.utils';

const types: {
  [key: string]: string;
} = {
  y: 'years',
  mo: 'months',
  w: 'weeks',
  d: 'days',
  h: 'hours',
  m: 'minutes',
  s: 'seconds',
};

type Result = {
  timePeriod: string;
  value: number;
  type: string;
  duration: Duration;
};

/** parse timePeriod (ex`"24h"`) to readable value to use with sub() add() fn from dates-fn
 *
 * @example
 * ```typescript
 * parseTimePeriod('24h')
 * // =>
 * {
 *  timePeriod: '24h',
 *  value: 24,
 *  type: 'hours',
 *  // this one is the notation date-fns uses
 *  // in `add(Date, {hours: 24})` etc
 *  duration: {hours: 24}
 * }
 *
 * ```
 */
export const parseTimePeriod = (timePeriod: string): Result => {
  const result = /^([\d]+)([\w]+)$/.exec(timePeriod);

  invariant(result, `Cannot parse "${timePeriod}"`);

  const [_string, _value, _type] = result;

  invariant(
    _value && _type && types[_type],
    `Cannot parse "${timePeriod}" to date-fns Duration - "${JSON.stringify({
      value: _value,
      type: _type,
    })}" is a result`
  );

  const type = types[_type]!;
  const value = Number(_value);

  return {
    timePeriod,
    value,
    type,
    duration: {[type]: value},
  };
};
