import Big from 'big.js';
import {createSimpleSchema, object, serializable} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';

class ExchangeTariffsResponseTariffData {
  @serializable(bigNumber())
  fee!: Big;
}

const ToTicker = createSimpleSchema({
  '*': object(ExchangeTariffsResponseTariffData),
});

const FromTicker = createSimpleSchema({
  '*': object(ToTicker),
});

export class ExchangeTariffsPublicResponse {
  @serializable(object(FromTicker))
  tariffs: Record<string, Record<string, ExchangeTariffsResponseTariffData>> =
    {};
}
