import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {BankWireTemplatesResource} from '@youtoken/ui.resource-bank-wire-templates';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Banner} from '@youtoken/ui.elements';
import {
  AccountTypeSwitcher,
  AttentionBanner,
  FieldAmount,
  FieldBeneficiaryName,
  FieldIban,
  FieldTemplates,
  OtherFields,
  PaymentInfo,
  Submit,
} from './components';
import {WithdrawalForm} from './state/WithdrawalForm';

interface FiatWithdrawalFormProps {
  ticker: string;
}

export const FiatWithdrawal: React.FC<FiatWithdrawalFormProps> = observer(
  ({ticker}) => {
    const {t} = useTranslation();
    const resources = useResources({
      authme: getResourceDescriptor(AuthMeResource, {}),
      bankWireTemplates: getResourceDescriptor(BankWireTemplatesResource, {
        ticker,
      }),
      fees: getResourceDescriptor(FeeAllResource, {}),
      verifications: getResourceDescriptor(VerificationResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
      ibanAccounts: getResourceDescriptor(IBANAccountsResource, {}),
    });

    const form = makeForm(() => new WithdrawalForm(ticker, resources));

    const {
      view: {shouldShowThirdPartyDisclaimer},
    } = form;

    return (
      <Form form={form}>
        <AttentionBanner />

        <Box px={24} pt={13}>
          <FieldAmount />
          <PaymentInfo />

          <FieldTemplates />

          <AccountTypeSwitcher />

          <FieldBeneficiaryName />
          <FieldIban />
        </Box>

        <OtherFields />

        <Box px={24} pb={24}>
          <Submit />
        </Box>

        {shouldShowThirdPartyDisclaimer && (
          <Banner
            variant="attention"
            testID="FIAT_DEPOSIT_BANK_WIRE_ATTENTION_BANNER"
          >
            {`${t('surface.wallets.fiat_withdrawal_wire.attention.new')}`}
          </Banner>
        )}
      </Form>
    );
  }
);
