import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';
import {AmountSetAllDebtLink} from './AllDebtLink';

export const AmountSetSource: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {form} = useForm<LoanRepayOwnFundsFormState>();

  return (
    <Box
      mb={form.ticker === form.conversionTicker ? 0 : '$screen-default-offset'}
      {...props}
    >
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label>
          {t(
            form.ticker === form.conversionTicker
              ? 'surface.loans.loan_pay_own_funds_form.conversion_ticker'
              : 'surface.loans.loan_pay_own_funds_form.ticker'
          )}
        </Label>
        {form.ticker === form.conversionTicker && <AmountSetAllDebtLink />}
      </Box>
      <TickerAndAmountInput
        wallets={form.tickerItems}
        ticker={form.ticker}
        amount={form.amount}
        onChangeTicker={form.tickerOnChange}
        onChangeAmount={form.amountOnChange}
      />
      <FieldErrorMessage visible={form.sourceHasError}>
        {form.sourceError}
      </FieldErrorMessage>
    </Box>
  );
});
