import * as React from 'react';
import {useWizard} from '@youtoken/ui.elements';
import {AdvCashWizard} from '../../AdvCashWizard';
import {type FiatDepositWizardNavigator, FiatDepositWizardScreenName} from '..';

export const FiatDepositWizardAdvCash: React.FC = () => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
  } = useWizard<
    FiatDepositWizardNavigator,
    FiatDepositWizardScreenName.ADV_CASH
  >();

  return <AdvCashWizard ticker={ticker} onPressBack={goBack} />;
};
