import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {HODLByIdFeature} from '../../../features';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Icon} from '@youtoken/ui.icons';
import {HowHODLWorksSurfaceSmart} from '../../../HowHODLWorks';
import {HODLDetailsButtonAndModal} from '@youtoken/ui.hodl-elements';

interface AgreementAndDetailsProps extends BoxProps {
  hodl: HODLItem;
}

export const AgreementAndDetailsSection: React.FC<AgreementAndDetailsProps> =
  observer(({hodl, ...boxProps}) => {
    const {
      showDetailsButton,
      showAgreementBtn,
      hodl: {agreementUrl, version},
    } = HODLByIdFeature.use({id: hodl.id});
    const handleClickAgreement = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('Agreement', {url: agreementUrl});
    }, [agreementUrl]);

    const {t} = useTranslation();

    if (!showAgreementBtn && !showDetailsButton) {
      return null;
    }

    return (
      <Box pt={10} pb={26} {...boxProps}>
        <Box flexDirection="row" justifyContent="space-between" px={24}>
          {showAgreementBtn && (
            <TouchableOpacity
              onPress={handleClickAgreement}
              testID="AGREEMENT_BUTTON"
            >
              <Box flexDirection="row" alignItems="center">
                <Icon name="document" color="$text-05" />
                <Text variant="$body-02-medium-accent" color="$text-05">
                  {t('surface.hodls.item.agreement_link')}
                </Text>
              </Box>
            </TouchableOpacity>
          )}
          {showDetailsButton && (
            <>
              <HODLDetailsButtonAndModal>
                <HowHODLWorksSurfaceSmart hodlId={hodl.id} version={version} />
              </HODLDetailsButtonAndModal>
            </>
          )}
        </Box>
      </Box>
    );
  });
