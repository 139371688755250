import * as React from 'react';
import {useCombobox} from 'downshift';
import {Box, useTheme} from '@youtoken/ui.primitives';
import {TextInput, InputLikeWrapper} from '@youtoken/ui.inputs';
import {isGroup} from '@youtoken/ui.combobox';
import {
  SearchLeftPart,
  CloseRightPart,
  TickerComboboxItem,
  EmptyState,
  TickerDropdownToggle,
  List,
} from '../../components';
import {filterWalletGroupsByInput} from '../../utils';
import type {WalletComboboxGroup, WalletComboboxItem} from '../../types';

export const TickerCombobox: React.FC<{
  setMode: (mode: 'amount' | 'ticker') => void;
  onChangeTicker?: (ticker: string) => void;
  ticker: string;
  amount?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  zIndex?: number;
  disabled?: boolean;
  hasError?: boolean;
  nothingFoundMessage?: string;
  ItemComponent?: React.FC<{wallet: WalletComboboxItem}>;
  items: WalletComboboxItem[] | WalletComboboxGroup[];
  initialIsOpen?: boolean;
  testID?: string;
}> = ({
  setMode,
  ItemComponent = TickerComboboxItem,
  hasError,
  disabled,
  onBlur,
  onChangeTicker,
  ticker,
  zIndex,
  onFocus,
  items: _items,
  initialIsOpen = false,
  testID,
}) => {
  const {shadow} = useTheme();

  //#region searchInput

  const [searchInputValue, setSearchInputValue] = React.useState('');

  const handleSearch = React.useCallback(
    ({inputValue}: {inputValue?: string}) => {
      setSearchInputValue(inputValue ?? '');
    },
    []
  );

  //#endregion searchInput

  //#region items

  const items = React.useMemo(() => {
    if (
      _items[0] &&
      isGroup<WalletComboboxItem, WalletComboboxGroup>(_items[0])
    ) {
      return _items as WalletComboboxGroup[];
    }
    return [{name: '', values: _items as WalletComboboxItem[]}];
  }, [_items]);

  const filteredInputItems = React.useMemo(() => {
    return filterWalletGroupsByInput(items, searchInputValue);
  }, [items, searchInputValue]);

  const flatItems = React.useMemo(() => {
    return filteredInputItems.reduce((acc, group) => {
      return [...acc, ...group.values];
    }, [] as WalletComboboxItem[]);
  }, [filteredInputItems]);

  //#endregion items

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useCombobox<WalletComboboxItem>({
    initialIsOpen,
    items: flatItems,
    getItemId: index => flatItems[index]!.key,
    itemToString: item => item?.ticker ?? '',
    onInputValueChange: handleSearch,
    onIsOpenChange: ({selectedItem, isOpen}) => {
      if (!isOpen) {
        onChangeTicker?.(selectedItem?.ticker || ticker);
        setMode('amount');
      }
    },
    onSelectedItemChange: ({selectedItem}) => {
      // NOTE: selectedItem can be null according to downshift docs, but we've declared it as required in onChangeTicker props
      // Now I can't see any ways to select something except existing item
      onChangeTicker?.(selectedItem!.ticker);
      setMode('amount');
    },
  });

  const {onKeyDown, ...inputProps} = getInputProps(
    {disabled},
    {suppressRefError: true}
  );

  const handleOpen = React.useCallback(() => {
    if (disabled) {
      return;
    }

    setMode('ticker');
    openMenu();
  }, [setMode, openMenu, disabled]);

  //#region onFocus and onBlur
  const prevIsOpen = React.useRef<boolean | undefined>();

  React.useEffect(() => {
    if (
      // NOTE: First onFocus when mounted
      (isOpen === true && prevIsOpen.current === undefined) ||
      // NOTE: Next onFocus/onBlur when changed props
      isOpen !== prevIsOpen.current
    ) {
      if (isOpen) {
        onFocus?.();
      } else {
        onBlur?.();
      }
    }

    prevIsOpen.current = isOpen;
  }, [isOpen, onFocus, onBlur]);

  //#endregion onFocus and onBlur

  return (
    <Box zIndex={zIndex} testID={testID}>
      {!isOpen && (
        <InputLikeWrapper {...inputProps}>
          <TickerDropdownToggle
            ticker={ticker}
            onPress={handleOpen}
            justifyContent="space-between"
            px={0}
          />
        </InputLikeWrapper>
      )}

      <Box {...getComboboxProps()}>
        {isOpen && (
          <TextInput
            {...inputProps}
            autoFocus
            disabled={disabled}
            hasError={hasError}
            onKeyPress={onKeyDown}
            LeftPartComponent={<SearchLeftPart />}
            RightPartComponent={<CloseRightPart onPress={closeMenu} />}
          />
        )}
      </Box>
      <Box
        {...getMenuProps()}
        isOpen={isOpen}
        style={{
          boxShadow: shadow,
        }}
        position="absolute"
        top="100%"
        mt={2}
        left={0}
        width="100%"
        overflow="scroll"
        maxHeight={48 * 7}
        opacity={isOpen ? 1 : 0}
        backgroundColor="$ui-background"
        borderRadius={6}
        borderColor="$ui-01"
        borderWidth={1}
      >
        {isOpen && filteredInputItems.length === 0 && <EmptyState />}
        {isOpen && filteredInputItems.length > 0 && (
          <List
            filteredInputItems={filteredInputItems}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            ItemComponent={ItemComponent}
          />
        )}
      </Box>
    </Box>
  );
};
