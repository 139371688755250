import React from 'react';
import {BoxProps} from '@youtoken/ui.primitives';
import {Button, IButtonProps} from '@youtoken/ui.buttons';

export const PercentPresetButton: React.FC<
  IButtonProps & BoxProps & {valueFormatted: string}
> = ({valueFormatted, onPress, ...buttonProps}) => {
  const handlePress = React.useCallback(() => {
    onPress?.(valueFormatted);
  }, [valueFormatted, onPress]);

  return (
    <Button flex={1} onPress={handlePress} {...buttonProps} px={0}>
      {Number(valueFormatted) > 0 ? '+' : ''}
      {valueFormatted}%
    </Button>
  );
};
