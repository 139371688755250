import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const FirstTradeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M12.402 8.919V8.36c.757-.144 1.223-.632 1.223-1.289.008-1.034-.893-1.307-1.489-1.488-.716-.217-.824-.325-.824-.586 0-.277.457-.35.727-.35.257 0 .574.084.755.2l.19.124a.105.105 0 00.145-.032l.436-.675a.105.105 0 00-.03-.145l-.192-.123a2.437 2.437 0 00-.941-.335v-.543a.105.105 0 00-.105-.105h-.804a.105.105 0 00-.104.105v.598c-.675.187-1.09.672-1.09 1.281 0 1.089.966 1.381 1.543 1.556.676.205.771.293.77.514 0 .255-.437.324-.695.324-.326 0-.678-.141-.877-.351l-.156-.165a.105.105 0 00-.148-.004l-.584.552a.105.105 0 00-.004.148l.156.165c.27.285.661.502 1.085.604v.579c0 .058.046.104.104.104h.804a.105.105 0 00.105-.104z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.906 11.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zm0-1.5a4 4 0 100-8 4 4 0 000 8zM.625 11.958a1 1 0 011-1h3a1 1 0 01.998.94c.276-.129.542-.233.804-.302.628-.165 1.21-.123 1.872.13 1.028.393 1.647.607 2.889.607 1.06 0 2.093.473 2.74 1.212a33.425 33.425 0 011.541-.626c.585-.213 1.142-.372 1.656-.372.606 0 1.116.361 1.395.81.293.472.382 1.112.069 1.72-.11.212-.295.402-.443.54a8.37 8.37 0 01-.602.504c-.455.351-1.03.75-1.619 1.136-1.167.766-2.463 1.532-3.077 1.818-1.707.797-3.07.308-4.286-.129h-.003c-.613-.22-1.343-.478-2.01-.64a5.2 5.2 0 00-.943-.152 1 1 0 01-.981.804h-3a1 1 0 01-1-1v-6zm1.5 5.5v-5h2v5h-2zm3.5-.804l.15.006c.362.023.751.096 1.129.188.755.184 1.556.468 2.162.685 1.22.438 2.044.698 3.148.183.511-.239 1.731-.954 2.888-1.713a25.52 25.52 0 001.526-1.07c.207-.16.373-.298.492-.41.082-.077.121-.122.135-.138a.186.186 0 01.006-.007.229.229 0 00.024-.114.27.27 0 00-.124-.202.11.11 0 00-.037-.015c-.236 0-.585.078-1.14.281-.46.168-1.003.398-1.673.684-.05.135-.123.26-.214.372-.3.37-.774.574-1.253.574H9.938a.75.75 0 010-1.5h2.791a2.23 2.23 0 00-1.541-.625c-1.509 0-2.327-.286-3.425-.706-.4-.153-.662-.157-.955-.08-.302.079-.648.246-1.183.534v3.073z"
          fill={color}
        />
      </Svg>
    );
  }
);
