import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const TableHeader: React.FC<BoxProps> = () => {
  const {t} = useTranslation();

  return (
    <Box
      flexDirection="row"
      px={{default: 16, phone: 24}}
      py={8}
      borderBottomColor="$ui-01"
      borderBottomWidth={1}
    >
      <Box flexBasis="35%" pr={{default: 8, tablet: 16}}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.trusted_devices.sessions_table.device_browser')}
        </Text>
      </Box>
      <Box flexBasis="35%" pr={{default: 8, tablet: 16}}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.trusted_devices.sessions_table.location')}
        </Text>
      </Box>
      <Box flexBasis="30%" alignItems="flex-end">
        <Text variant="$body-02" color="$text-02">
          {t('surface.trusted_devices.sessions_table.time')}
        </Text>
      </Box>
    </Box>
  );
};
