import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {ChartSmallPreview} from '@youtoken/ui.legacy-charts';
import {DayPreviewChartFeature, WalletsFeature} from '../../../state';

export interface WalletPreviewChartSmartCellProps {
  baseTicker: string;
  quoteTicker: string;
  width?: number;
  height?: number;
}

export const WalletPreviewChartSmartCell =
  cell<WalletPreviewChartSmartCellProps>(
    ({baseTicker, quoteTicker, width = 40, height = 40}) => {
      const {symbols} = WalletsFeature.use({});

      const {dayPreviewChartDataByPair} = DayPreviewChartFeature.use({
        tickersPairs: symbols,
      });

      const chartData = dayPreviewChartDataByPair(baseTicker, quoteTicker);

      if (!chartData) {
        return null;
      }

      return (
        <ChartSmallPreview width={width} height={height} data={chartData} />
      );
    },
    {
      displayName: 'WalletPreviewChartSmartCell',
      SuspenseFallback: props => (
        <Box {...props}>
          <DefaultSuspenseFallback />
        </Box>
      ),
      ErrorFallback: () => null,
    }
  );
