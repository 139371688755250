import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoyaltyLevel, LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps} from '@youtoken/ui.primitives';
import {
  AccountLevelInfoItemFull,
  AccountLevelConversionCoefficientBadge,
} from '..';

export interface AccountLevelInfoItemConversionFullProps {
  level: LoyaltyLevel;
}

export const AccountLevelInfoItemConversionFull: React.FC<
  AccountLevelInfoItemConversionFullProps & BoxProps
> = observer(
  ({
    level: {
      cardInfoBadgeVariant,
      conversion: {multiplier},
    },
    ...boxProps
  }) => {
    const {t} = useTranslation();

    return (
      <AccountLevelInfoItemFull
        testID="ACCOUNT_LEVEL_INFO_ITEM_CONVERSION"
        icon="exchange"
        title={t('surface.loyalty.conversion')}
        badge={
          <AccountLevelConversionCoefficientBadge
            value={multiplier}
            variant={cardInfoBadgeVariant}
          />
        }
        {...boxProps}
      />
    );
  }
);
