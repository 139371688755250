/** wraps resource getKey with prefix
 * @example
 * ```typescript
 * wrapGetKey((args) => `foo${args}`)(args) // => `resource:foo${args}`
 * ```
 */
export const wrapResourceGetKey = <Args extends {}>(
  getKey: (args: Args) => string
) => {
  return (args: Args) => `resource:${getKey(args)}`;
};
