import {
  BaseHODLChartState,
  BaseHODLChartStateProps,
} from './BaseHODLChartState';

export interface HODLChartStateProps extends BaseHODLChartStateProps {}

export class HODLChartState extends BaseHODLChartState {
  constructor(props: HODLChartStateProps) {
    super(props);
  }
}
