import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 3.5a2 2 0 012 2v9a2 2 0 01-2 2H3a2 2 0 01-2-2v-9a2 2 0 012-2h14zm.5 5h-15v6a.5.5 0 00.41.492L3 15h14a.5.5 0 00.492-.41l.008-.09v-6zM5.75 10a.75.75 0 010 1.5h-1.5a.75.75 0 010-1.5h1.5zm6 0a.75.75 0 010 1.5h-3.5a.75.75 0 010-1.5h3.5zM17 5H3a.5.5 0 00-.492.41L2.5 5.5V7h15V5.5a.5.5 0 00-.41-.492L17 5z"
          fill={color}
        />
      </Svg>
    );
  }
);
