import big from 'big.js';
import {action, computed, observable} from 'mobx';
import {computedFn} from 'mobx-utils';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {orderBy} from 'lodash';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {invariant} from '@youtoken/ui.utils';

interface RatesListFeatureArgs {}

export class RatesListFeature extends createFeature({
  getKey: (args: RatesListFeatureArgs) => {
    return `feature:ratesList:${JSON.stringify(args)}`;
  },
  getResources: () => {
    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      rates: getResourceDescriptor(RatesResource, {}),
      authme: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @observable
  showEmptyWallets = false;

  constructor(args: any, resources: any) {
    super(args, resources);
  }

  @action
  toggleShowEmptyWallets = () => {
    this.showEmptyWallets = !this.showEmptyWallets;
  };

  @computed
  public get list() {
    const filteredList = this.resources.wallets.cryptoWallets.filter(item => {
      if (['btc'].includes(item.ticker)) {
        // always show btc wallet
        return true;
      }

      if (!this.showEmptyWallets) {
        return item.hasAmountOrHodlAmount && item.isPriceSignificant;
      }

      return item.isPriceSignificant;
    });

    return orderBy(
      filteredList,
      [
        item => ['btc'].includes(item.ticker),
        item => item.hasAmountOrHodlAmount,
        item => item.equivalent.toNumber(),
        item => item.ticker,
      ],
      ['desc', 'desc', 'desc', 'asc']
    );
  }

  @computed
  private get walletsList() {
    return this.resources.wallets.cryptoWallets;
  }

  getRateObjForTicker = computedFn((ticker: string) => {
    const mainCurrency = this.resources.authme.mainCurrency;
    const wallet = this.walletsList.find(w => w.ticker === ticker)!;
    const walletWithAmount = wallet.amount.gt(0) || wallet.collateral.gt(0);

    invariant(wallet, `Wallet not found`, {ticker});

    const rateObj = this.resources.rates.getRateObj(ticker, mainCurrency);
    const {rate, diff24h} = rateObj;
    const rate24hAgo = rate - diff24h;
    const diff = big(diff24h).div(rate24hAgo);

    return {
      ticker,
      toTicker: mainCurrency,
      rate: formatByTicker(rate, 'asMainCurrency'),
      diff: diff.toFixed(),
      walletWithAmount,
      rateObj,
    };
  });
}
