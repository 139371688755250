export const COLORS = {
  Black: '#000000',
  'Black - 60%': 'rgba(0, 0, 0, 0.6)',
  'Black - 40%': 'rgba(0, 0, 0, 0.4)',
  'Black - 12%': 'rgba(0, 0, 0, 0.12)',
  'Blue 10': '#F0F5FF',
  'Blue 20': '#E3ECFF',
  'Blue 30': '#CBDBFB',
  'Blue 60': '#2979FF',
  'Blue 60 - 40%': 'rgba(41, 121, 255, 0.04)',
  'Blue 70': '#396DD0',
  'Blue 80': '#2F569F',
  'Blue 90': '#1E3C73',
  'Blue 100': '#223251',
  'Gray 10': '#F2F2F2',
  'Gray 20': '#E9EAEC',
  'Gray 30': '#DDE0E3',
  'Gray 40': '#A7AEBC',
  'Gray 50': '#838D9B',
  'Gray 60': '#70798B',
  'Gray 70': '#3F4C5F',
  'Gray 80': '#29303D',
  'Gray 90': '#212630',
  'Gray 100': '#181E25',
  'Gray 100 - 40%': 'rgba(24, 30, 37, 0.4)',
  'Gray 100 - 80%': 'rgba(24, 30, 37, 0.8)',
  'Green 10': '#EFFAF8',
  'Green 20': '#D7F5F0',
  'Green 60': '#22BEA1',
  'Green 90': '#1A6154',
  'Green 100': '#00382E',
  'Orange 10': '#FEF4E6',
  'Orange 20': '#FFEACC',
  'Orange 60': '#F49100',
  'Orange 80': '#A56428',
  'Orange 90': '#613C1A',
  'Orange 100': '#392D23',
  'Red 10': '#FEF0ED',
  'Red 20': '#FEE0DA',
  'Red 60': '#F92700',
  'Red 80': '#CA303C',
  'Red 90': '#611A20',
  'Red 100': '#3D2426',
  White: '#FFFFFF',
  Transparent: 'transparent',
  // Loyalty
  L1: '#E8F5F4',
  L2: '#EBF1FF',
  L3: '#D2FCD1',
  L4: '#E3F4FB',
  L5: '#FFF0C4',
  L6: '#F2E6FD',
  L7: '#D4F6F7',
  L8: '#FFEBEB',
  'L1 Dark': '#1A3B3D',
  'L2 Dark': '#243549',
  'L3 Dark': '#1C3D29',
  'L4 Dark': '#203C49',
  'L5 Dark': '#40341D',
  'L6 Dark': '#35335B',
  'L7 Dark': '#173740',
  'L8 Dark': '#4D2B39',
};
