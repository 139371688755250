import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {type ChartSmartCells} from './utils/types';
import {ChartDataResource} from '../state/Chart';
import {useDefaultHODLTimePeriod} from './utils/useDefaultHODLTimePeriod';
import {ChartWithoutControlsSuspenseFallback} from './SuspenseFallbacks';
import {ChartControls, HODLChart} from '../Charts';
import {CellWrap} from './utils/CellWrap';

export const HODLClosedChartSmartCell =
  cell<ChartSmartCells.HODLClosedChartSmartCellProps>(
    ({
      width,
      height,
      product,
      fromTicker,
      toTicker,
      precision,
      closedPrice,
      initialPrice,
      triggerPrice,
      pending,
      startDate,
      endDate,
      reversed,
      defaultTick = '1m',
      defaultType = 'line',
      shouldDisplayControl = false,
    }) => {
      const defaultTimePeriod = useDefaultHODLTimePeriod(
        startDate,
        shouldDisplayControl ? endDate : undefined
      );

      const {chartData, isLoading, chartControls, hasData, refetch} =
        ChartDataResource.use({
          key: 'HODLClosedChart',
          product,
          fromTicker,
          toTicker,
          type: defaultType,
          timePeriod: defaultTimePeriod,
          tick: defaultTick,
          fromDate: shouldDisplayControl ? undefined : startDate,
          toDate: endDate,
          mode: reversed ? 'ask' : 'bid',
        });

      return (
        <>
          <HODLChart
            data={chartData}
            height={height}
            width={width}
            isFetching={isLoading}
            displayActivityIndicator={false}
            domainPaddingTop={shouldDisplayControl ? undefined : 8}
            domainPaddingBottom={shouldDisplayControl ? undefined : 8}
            initialPrice={initialPrice}
            triggerPrice={triggerPrice}
            pending={pending}
            closedPrice={closedPrice}
            startDate={startDate}
            endDate={endDate}
            direction="closed"
            displayInitialPriceLabel={false}
            hasData={hasData}
            refetch={refetch}
            formatNumberPrecision={precision}
          />
          {shouldDisplayControl && (
            <ChartControls
              displayTypeSwitcher={true}
              isCursorActive={false}
              {...chartControls}
            />
          )}
        </>
      );
    },
    {
      displayName: 'HODLClosedChartSmartCell',
      SuspenseFallback: ChartWithoutControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
