import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Button} from '@youtoken/ui.buttons';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsSavings');
};

export const SavingsStartEarningButton: React.FC<
  React.ComponentProps<typeof Button>
> = observer(props => {
  const {t} = useTranslation();

  return (
    <Button
      testID="SAVINGS_START_EARNING_BUTTON"
      size="large"
      onPress={handlePress}
      width="100%"
      {...props}
    >
      {t('surface.savings.start_earning')}
    </Button>
  );
});
