import Big from 'big.js';
import axios, {AxiosError, CancelTokenSource} from 'axios';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {debounce} from 'lodash';
import {CalculatedDataResponse} from './calculatedDataResponse';

let _currentRequestToken: CancelTokenSource | null = null;

function calculateIncentives(
  amount: string,
  ticker: string,
  callback: (incentivesAmount: Big) => void
): void {
  if (_currentRequestToken) {
    _currentRequestToken.cancel('__CANCELLED_REQUEST__');
  }

  _currentRequestToken = axios.CancelToken.source();

  TRANSPORT.API.get(
    `/v1/incentives/calculate?amount=${Number(amount)}&ticker=${ticker}`,
    {cancelToken: _currentRequestToken.token}
  )
    .then(res => {
      const data = deserialize(
        CalculatedDataResponse,
        res.data
      ) as CalculatedDataResponse;

      callback(data.amount);
    })
    .catch((error: AxiosError) => {
      SENTRY.capture(error);
    })
    .finally(() => {
      _currentRequestToken = null;
    });
}

export const calculateIncentivesDebounced = debounce(
  (
    amount: string,
    ticker: string,
    callback: (incentivesAmount: Big) => void
  ) => {
    calculateIncentives(amount, ticker, callback);
  },
  200
);
