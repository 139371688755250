import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface FormADisclaimerProps extends BoxProps {}

export const FormADisclaimer: React.FC<FormADisclaimerProps> = boxProps => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Text variant="$body-02" color="$text-02" mb={24}>
        {t('surface.form_a.disclaimer')}{' '}
        <Link url="mailto:support@youhodler.com" color="$text-02">
          support@youhodler.com
        </Link>
      </Text>
    </Box>
  );
};
