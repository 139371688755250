import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {AppleAuthButtonProps} from './types';

export const AppleAuthButton: React.FC<AppleAuthButtonProps & BoxProps> = ({
  residence,
  onClick,
  ...boxProps
}) => {
  const [isLoading, setLoading] = React.useState(false);

  const handlePress = React.useCallback(() => {
    setLoading(true);
    onClick?.().then(() => setLoading(false));
  }, [onClick]);

  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Button
        size="medium"
        onPress={handlePress}
        loading={isLoading}
        icon="apple"
        iconPosition="left"
        color="external-auth"
        testID="APPLE_AUTH_BUTTON"
      >
        {t('surface.sign_up.continue.apple')}
      </Button>
    </Box>
  );
};
