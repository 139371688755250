import * as React from 'react';
import {useMemo} from 'react';
import {Image, Platform} from 'react-native';
import {Box, Text, TouchableBox, useTheme} from '@youtoken/ui.primitives';
import {StoryPreviewListItemProps} from '../../interfaces';
import {observer} from 'mobx-react';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';

export const StoryPreviewListItem = observer(
  ({
    item,
    index,
    unPressedBorderColor,
    pressedBorderColor,
    unPressedPreviewTextColor,
    pressedPreviewTextColor,
    previewSize = 60,
    showText,
    previewTextStyle,
    handleStoryPreviewPress,
    previewImageStyle,
    previewWrapperStyle,
  }: StoryPreviewListItemProps) => {
    const {colors} = useTheme();
    const {markAsSeen} = InstaStoriesResource.use({});

    const handlePressItem = React.useCallback(() => {
      handleStoryPreviewPress?.(item, index);
      markAsSeen(item.slug);
    }, [handleStoryPreviewPress, item, index, markAsSeen]);

    const previewWrapperSize = previewSize + 4;

    const styleNoFocus = useMemo(() => {
      return {
        ...(Platform.OS === 'web'
          ? {
              // @ts-ignore
              outline: 'none',
              // @ts-ignore web-only
              outlineWidth: 0,
              // @ts-ignore web-only
              outlineColor: 'transparent',
            }
          : {}),
      };
    }, [Platform.OS]);

    return (
      <Box ml={{default: index === 0 ? 0 : 6, desktop: index === 0 ? 0 : 12}}>
        <TouchableBox
          onPress={handlePressItem}
          borderWidth={1}
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          borderRadius={100}
          borderColor={
            item.seen
              ? pressedBorderColor ?? '$transparent'
              : unPressedBorderColor ?? '$interactive-01'
          } // isPressed is React state, item.shown is state from BE
          height={previewWrapperSize}
          width={previewWrapperSize}
          //@ts-ignore
          style={[previewWrapperStyle, styleNoFocus]}
        >
          <Image
            style={[
              {
                height: previewSize,
                width: previewSize,
                borderRadius: 100,
                backgroundColor: colors['$ui-01'],
              },
              previewImageStyle,
            ]}
            source={{uri: item.previewUrl}} // todo previewUrl may be undefined
          />

          {showText && (
            <Box position="absolute" left={12} right={12} bottom={12}>
              <Text
                variant="$body-02"
                textAlign="left"
                numberOfLines={2}
                ellipsizeMode="tail"
                color={
                  item.seen
                    ? pressedPreviewTextColor || undefined
                    : unPressedPreviewTextColor || undefined
                }
                //@ts-ignore
                style={[previewTextStyle, styleNoFocus]}
              >
                {item.title}
              </Text>
            </Box>
          )}
        </TouchableBox>
      </Box>
    );
  }
);
