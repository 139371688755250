import {computed} from 'mobx';
import Big from 'big.js';
import {serializable, alias, primitive, list, object} from 'serializr';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {WalletsResource} from '@youtoken/ui.resource-wallets';

export class SavingsAsset {
  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  selected!: boolean;

  @serializable(bigNumber())
  amount!: Big;

  @serializable(bigNumber())
  apr!: Big;

  @computed
  get tickerName() {
    return getCoinName(this.ticker);
  }

  @computed
  get aprFormatted() {
    return formatPercent(this.apr) + '%';
  }
}

export class SavingsChooseTickersResponse {
  @serializable(number())
  level!: number;

  @serializable(number())
  tickerAmount!: number;

  @serializable(alias('top', list(object(SavingsAsset))))
  _top!: SavingsAsset[];

  @computed
  get top() {
    const {walletsTickers} = WalletsResource.getInstance({});

    return this._top.filter(({ticker}) => walletsTickers.includes(ticker));
  }

  @serializable(alias('other', list(object(SavingsAsset))))
  _other!: SavingsAsset[];

  @computed
  get other() {
    const {walletsTickers} = WalletsResource.getInstance({});

    return this._other.filter(({ticker}) => walletsTickers.includes(ticker));
  }
}
