import {getAnalyticsSearchParams} from './getAnalyticsSearchParams';
import {getGoogleTagManagerAnalyticsParams} from './getGoogleTagManagerAnalyticsParams';

export const getAnalyticsData = () => {
  const analytics = getAnalyticsSearchParams();

  return getGoogleTagManagerAnalyticsParams().then(gtag => {
    analytics['gtag'] = gtag;

    return analytics;
  });
};
