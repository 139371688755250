import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardSummaryResource} from '@youtoken/ui.resources-rewards';
import {type BoxProps} from '@youtoken/ui.primitives';
import {RewardsStatistics} from '../../components';

export const RewardsStatisticsV1Smart: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      data: {
        totalEarned: {usd: totalEarnedUSD},
        referralsCount,
      },
    } = RewardSummaryResource.use({});

    return (
      <RewardsStatistics
        totalEarnedUSD={totalEarnedUSD.toNumber()}
        referralsCount={referralsCount}
        {...boxProps}
      />
    );
  }
);
