import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

type ActionButtonProps = {
  onGetMorePress: () => void;
  onUseBonusesPress: () => void;
};

export const ActionButtons: React.FC<ActionButtonProps & BoxProps> = observer(
  ({onGetMorePress, onUseBonusesPress, ...boxProps}) => {
    const {t} = useTranslation();
    return (
      <Box
        flexDirection="row"
        width="100%"
        flexShrink={0}
        px={24}
        {...boxProps}
      >
        <Button
          type="secondary"
          icon="fiat_deposit"
          onPress={onGetMorePress}
          flexBasis="40%"
          flexGrow={1}
          mr={8}
        >
          {t('surface.incentives.action.get_more')}
        </Button>
        <Button
          type="primary"
          icon="hodl"
          onPress={onUseBonusesPress}
          flexGrow={1}
          flexBasis="40%"
          ml={8}
        >
          {t('surface.incentives.action.use_bonuses')}
        </Button>
      </Box>
    );
  }
);
