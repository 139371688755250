import * as React from 'react';
import {observer} from 'mobx-react';
import {
  ResizableBoxWithOverlay,
  TradingViewModeSwitch,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';
import {
  ScreenNameMH,
  SmartTVChartContainerWeb,
} from '@youtoken/ui.trading-view-charts';
import {Separator} from '@youtoken/ui.elements';
import {HODLByIdFeature} from '../../../features';
import {
  getInitialPriceLineParams,
  getMarginCallLineParams,
  getTakeProfitLineParams,
  getTriggerPriceLineParams,
} from '@youtoken/ui.hodls-utils';
import {useTheme} from '@youtoken/ui.primitives';

interface TradingViewChartSectionProps {
  hodlId: string;
  onClose: () => void;
}

export const TradingViewChartSection: React.FC<TradingViewChartSectionProps> =
  observer(({hodlId, onClose}) => {
    const {hodl} = HODLByIdFeature.use({id: hodlId});
    const {colors} = useTheme();
    const lines = React.useMemo(() => {
      const initialPriceLine = getInitialPriceLineParams(
        colors,
        hodl.initialPrice,
        hodl.isPendingLike
      );
      const takeProfitLine = getTakeProfitLineParams(
        hodl.precision,
        colors,
        hodl.takeProfit,
        hodl.data.maxProfit
      );
      const marginCallLine = getMarginCallLineParams(
        hodl.precision,
        colors,
        hodl.marginCall,
        hodl.data.maxLoss
      );
      const triggerPriceLine = getTriggerPriceLineParams(
        colors,
        hodl.triggerPrice,
        hodl.isPendingLike
      );
      return {
        initialPriceLine,
        takeProfitLine,
        marginCallLine,
        triggerPriceLine,
      };
    }, [
      colors,
      hodl.precision,
      hodl.initialPrice,
      hodl.triggerPrice,
      hodl.isPendingLike,
      hodl.takeProfit,
      hodl.data.maxProfit,
      hodl.marginCall,
      hodl.data.maxLoss,
    ]);

    const mode = React.useMemo(() => {
      return hodl.isPendingLike
        ? hodl.data.isShort // NOTE: for HODL create form and for pending-orders is a chart with prices which will be used for open the deal
          ? 'bid'
          : 'ask'
        : hodl.data.isShort // NOTE: for opened position is a chart with prices which will be used for close the deal
        ? 'ask'
        : 'bid';
    }, [hodl.data.isShort, hodl.isPendingLike]);

    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <ResizableBoxWithOverlay height={500} width="100%" {...bind}>
        {Boolean(width && height) && (
          <SmartTVChartContainerWeb
            fromTicker={hodl.baseTicker}
            toTicker={hodl.quoteTicker}
            mode={mode}
            initialPriceLine={lines.initialPriceLine}
            takeProfitLine={lines.takeProfitLine}
            marginCallLine={lines.marginCallLine}
            triggerPriceLine={lines.triggerPriceLine}
            screenName={ScreenNameMH.DETAILS}
            height={440}
          />
        )}
        <Separator />
        <TradingViewModeSwitch
          mode="tradingview"
          onChange={onClose}
          pt={15}
          pb={16}
          px={24}
        />
      </ResizableBoxWithOverlay>
    );
  });
