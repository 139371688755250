import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {
  DeviceIcon,
  Icon,
  type BaseIconName,
  type DeviceIconName,
} from '@youtoken/ui.icons';

export type DeviceLogo =
  | 'os_mac'
  | 'os_windows'
  | 'os_android'
  | 'os_linux'
  | 'os_other'
  | 'question';

export interface DeviceImageProps {
  deviceIcon: DeviceIconName;
  deviceLogo: DeviceLogo;
  size?: 'small' | 'medium' | 'large';
}

export const DeviceImage: React.FC<DeviceImageProps> = ({
  deviceIcon,
  deviceLogo,
  size = 'medium',
}) => {
  const sizes = React.useMemo(() => {
    const elementsSizes = {
      small: {box: 32, icon: 8, offset: 12},
      medium: {box: 64, icon: 16, offset: 24},
      large: {box: 88, icon: 22, offset: 33},
    };

    return elementsSizes[size];
  }, [size]);

  return (
    <Box
      position="relative"
      width={sizes.box}
      height={sizes.box}
      justifyContent="center"
      alignItems="center"
    >
      <DeviceIcon name={deviceIcon} size={sizes.box} />
      <Box position="absolute" top={sizes.offset} left={sizes.offset}>
        <Icon
          name={deviceLogo as BaseIconName}
          size={sizes.icon}
          color="$text-04"
        />
      </Box>
    </Box>
  );
};
