import React from 'react';
import {TouchableBox, useTheme} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';

export const PlayButton: React.FC<
  React.ComponentProps<typeof TouchableBox> & {isLoading?: boolean}
> = ({isLoading = false, ...props}) => {
  const {colors} = useTheme();
  return (
    <TouchableBox
      width={48}
      height={48}
      borderRadius={24}
      backgroundColor="$interactive-01"
      activeOpacity={0.8}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {!isLoading && <Icon name="play" color="$text-04" size={32} />}
      {isLoading && <ActivityIndicator color={colors['$text-04']} />}
    </TouchableBox>
  );
};
