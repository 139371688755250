import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {type BadgeProps, Badge} from '@youtoken/ui.elements';

export interface AccountLevelMinerUpToBTCBadgeProps extends BadgeProps {
  amount: string;
}

export const AccountLevelMinerUpToBTCBadge: React.FC<AccountLevelMinerUpToBTCBadgeProps> =
  observer(({amount, ...badgeProps}) => {
    const {t} = useTranslation();

    return (
      <Badge
        testID="ACCOUNT_LEVEL_MINER_UP_TO_BTC_BADGE"
        size="small"
        borderRadius={12}
        {...badgeProps}
      >
        <Text variant="$body-02">
          <Trans
            t={t}
            components={{
              Strong: <Text variant="$body-02-high-accent" />,
            }}
            values={{amount}}
            i18nKey="surface.loyalty.cloud_miner.up_to_btc"
          />
        </Text>
      </Badge>
    );
  });
