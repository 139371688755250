import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {SubmitButton} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Trans} from '@youtoken/ui.elements';
import {BankCardsAgreementsLink} from '../../../components';
import {BankCardsFeature} from '../../..';

export const Submit: React.FC = observer(() => {
  const {t} = useTranslation();

  const {hasAgreement} = BankCardsFeature.use({});
  return (
    <Box>
      {hasAgreement && (
        <Box mt={16}>
          <Text variant="$body-02" color="$text-02" testID="AGREEMENTS_TEXT">
            <Trans
              components={{
                Link: <BankCardsAgreementsLink />,
              }}
              i18nKey="surface.bank_cards.transfer.agreement"
            />
          </Text>
        </Box>
      )}

      <Box mt={24}>
        <SubmitButton testID="SUBMIT_BUTTON">
          {t('surface.bank_cards.transfer.transfer_button')}
        </SubmitButton>
      </Box>
    </Box>
  );
});
