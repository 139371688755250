const sliceEnd = (text: string, end: number) => text.slice(-end);
const sliceStart = (text: string, start: number) => text.slice(0, start);

export const scramble = (text = '', {start = 3, end = 3} = {}) => {
  const length = text.length;

  if (!end) {
    if (length <= start) {
      return text;
    }
    return `${sliceStart(text, start)}...`;
  }

  if (!start) {
    if (length <= end) {
      return text;
    }
    return `...${sliceEnd(text, end)}`;
  }

  if (length <= start + end) {
    return text;
  }

  return `${sliceStart(text, start)}...${sliceEnd(text, end)}`;
};
