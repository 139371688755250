import React from 'react';
import {Clipboard} from '@youtoken/ui.utils';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {type AccountRequisite} from '../../../../types/AccountRequisite';

interface CopyRequisitesProps {
  requisites: AccountRequisite[];
}

export const CopyRequisites: React.FC<CopyRequisitesProps> = ({requisites}) => {
  const {t} = useTranslation();

  const handlePressCopy = () => {
    const requisitesArray = requisites.map(requisite => {
      const title = t(
        // @ts-ignore
        `surface.wallets.fiat_deposit_wire.requisites.${requisite.type}`,
        {
          defaultValue: requisite.title,
        }
      );

      return `${title}:\n${requisite.value}`;
    });

    Clipboard.setString(requisitesArray.join('\n\n'));
    LOCAL_NOTIFICATIONS.info({
      text: t('surface.bank_wire.wirex.requisites.copied_message'),
    });
  };

  return (
    <Button
      mb={24}
      mx={24}
      type="secondary"
      size="medium"
      onPress={handlePressCopy}
      testID="FIAT_DEPOSIT_BANK_WIRE_COPY_REQUISITES_BUTTON"
    >
      {t('surface.bank_wire.wirex.requisites.copy')}
    </Button>
  );
};
