import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const CheckIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.03 6.47a.75.75 0 010 1.06l-7 7a.75.75 0 01-1.06 0l-4-4a.75.75 0 111.06-1.06l3.47 3.47 6.47-6.47a.75.75 0 011.06 0z"
          fill={color}
        />
      </Svg>
    );
  }
);
