import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type BaseTabProps} from './BaseTab';
import {type TradeToUpgradeModalContentProps} from '../types';

const isAndroid = Platform.OS === 'android';

type BottomTextProps = Pick<BaseTabProps, 'product'> &
  Pick<TradeToUpgradeModalContentProps, 'variant'>;

export const BottomTextSparks: React.FC<{}> = observer(() => {
  const {
    data: {
      settings: {hodlSparksRequiredVolume, hodlSparksAmount},
    },
  } = MinerOverviewResource.use({});

  const {t} = useTranslation();

  return (
    <Box flexDirection="row" alignItems="center">
      <Trans
        t={t}
        i18nKey="surface.upgrade_modal.sparks.bottom_text"
        parent={({children}) => {
          // a hack for elements alignment on Android
          return (
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              {children[0]}
              <Text variant="$body-02" color="$text-02" mt={-4}>
                {children[1]}
              </Text>
              {children[2]}
              <Text variant="$body-02" color="$text-02" mt={-4}>
                {children[3]}
              </Text>
            </Box>
          );
        }}
        components={{
          Sparks: (
            <Box
              backgroundColor="$attention-02"
              py={2}
              pl={4}
              pr={6}
              flexDirection="row"
              alignItems="center"
              borderRadius={12}
              mr={4}
              mt={{default: -5, tablet: -2}}
            >
              <Icon name="spark" size={12} color="$attention-01" />
              <Text variant="$body-03" color="$attention-01">
                +{hodlSparksAmount}
              </Text>
            </Box>
          ),
          Volume: (
            <Box
              backgroundColor="$interactive-02"
              py={2}
              pl={4}
              pr={6}
              flexDirection="row"
              alignItems="center"
              borderRadius={12}
              mx={{default: 0, tablet: 4}}
              mt={{default: -5, tablet: -2}}
            >
              <Icon name="volume" size={12} color="$interactive-01" />
              <Text variant="$body-03" color="$interactive-01">
                ${hodlSparksRequiredVolume}
              </Text>
            </Box>
          ),
        }}
      />
    </Box>
  );
});

export const BottomTextVolume: React.FC<Pick<BaseTabProps, 'product'>> =
  observer(({product}) => {
    const {currentLevelData} = LoyaltyResource.use({});

    const {
      details: {
        convertVolumeMultiplier,
        convertVolumeUnit,
        hodlVolumeMultiplier,
        hodlVolumeUnit,
      },
    } = currentLevelData;

    const {t} = useTranslation();

    const multiplier =
      product === 'hodl' ? hodlVolumeMultiplier : convertVolumeMultiplier;
    const volumeUnit = product === 'hodl' ? hodlVolumeUnit : convertVolumeUnit;

    return (
      <>
        <Box flexDirection="row">
          <Text variant="$body-02" color="$text-02">
            <Trans
              t={t}
              i18nKey={
                product === 'hodl'
                  ? 'surface.upgrade_modal.volume.trading.bottom_text'
                  : 'surface.upgrade_modal.volume.convert.bottom_text'
              }
              values={{amount: volumeUnit}}
              components={{
                Volume: (
                  <Box
                    backgroundColor="$interactive-02"
                    py={2}
                    pl={4}
                    pr={6}
                    flexDirection="row"
                    alignItems="center"
                    borderRadius={12}
                    mr={4}
                    mt={isAndroid ? 0 : -2}
                  >
                    <Icon name="volume" size={12} color="$interactive-01" />
                    <Text variant="$body-03" color="$interactive-01">
                      +${(multiplier * volumeUnit).toFixed(0)}
                    </Text>
                  </Box>
                ),
              }}
              parent={({children}) => {
                // a hack for elements alignment on Android
                return (
                  <Box
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    flex={1}
                  >
                    {children[0]}
                    <Text variant="$body-02" color="$text-02">
                      {children[1]}
                    </Text>
                  </Box>
                );
              }}
            />
          </Text>
        </Box>
      </>
    );
  });

export const BottomText: React.FC<BottomTextProps & BoxProps> = ({
  variant,
  product,
  ...boxProps
}) => {
  return (
    <Box flex={1} justifyContent="center" alignItems="center" {...boxProps}>
      {variant === 'sparks' ? (
        <BottomTextSparks />
      ) : (
        <BottomTextVolume product={product} />
      )}
    </Box>
  );
};
