import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RewardsHistorySmartProps} from '../types';
import {RewardsHistoryFlatListV1Smart} from './v1';
import {RewardsHistoryFlatListV3Smart} from './v3';

export const RewardsHistoryFlatListSmart: React.FC<RewardsHistorySmartProps> =
  observer(({showHeader = false, limit = 20}) => {
    const {
      data: {rewardVersion},
    } = AuthMeResource.use({});

    if (rewardVersion === 3) {
      return (
        <RewardsHistoryFlatListV3Smart limit={limit} showHeader={showHeader} />
      );
    }

    return (
      <RewardsHistoryFlatListV1Smart limit={limit} showHeader={showHeader} />
    );
  });
