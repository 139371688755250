import {
  date,
  list,
  object,
  primitive,
  raw,
  serializable,
  alias,
} from 'serializr';
import {computed} from 'mobx';
import {RewardType} from '../RewardType';
import {i18n} from '@youtoken/ui.service-i18n';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {
  mainCurrencyVariants,
  MainCurrencyVariants,
} from '@youtoken/ui.utils-serializr';

export class StringI18n {
  @serializable(primitive())
  fallback!: string;

  @serializable(primitive())
  label!: string;

  @serializable(raw())
  params: any;
}

export class RewardFullResponse {
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  type!: RewardType;

  @serializable(alias('title', object(StringI18n)))
  _title!: StringI18n;

  @computed get title() {
    return i18n.t(this._title.label as any, {
      defaultValue: this._title.fallback,
      ...this._title.params,
    });
  }

  @serializable(alias('description', object(StringI18n)))
  _description!: StringI18n;

  @computed get description() {
    return i18n.t(this._description.label as any, {
      defaultValue: this._description.fallback,
      ...this._description.params,
    });
  }

  @serializable(primitive())
  complete!: boolean;

  @serializable(primitive())
  viewed!: boolean;

  @serializable(primitive())
  completedSteps?: number = 0;

  @serializable(primitive())
  requiredSteps?: number = 0;

  @serializable(mainCurrencyVariants())
  personalReward!: MainCurrencyVariants;

  @computed get personalRewardUSD() {
    return '$' + formatByTicker(this.personalReward.usd, 'usd');
  }

  @serializable(mainCurrencyVariants())
  referralReward!: MainCurrencyVariants;

  @computed get referralRewardUSD() {
    return '$' + formatByTicker(this.referralReward.usd, 'usd');
  }

  @serializable(alias('conditions', list(object(StringI18n))))
  _conditions!: StringI18n[];

  @computed get conditions() {
    return this._conditions.map(item =>
      i18n.t(item.label as any, {
        defaultValue: item.fallback,
        ...item.params,
      })
    );
  }

  @serializable(date())
  createdAt!: Date;

  @computed get buttonTitle() {
    return this.title;
  }

  @serializable(primitive())
  minAmountUSD?: number;
}
