import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {LoanRepayOwnFundsFormV1State} from '../../LoanRepayOwnFundsFormV1/state';

export const DataTableV1: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {ticker, conversionTicker, walletAmount, overdraftAmount} =
    useForm<LoanRepayOwnFundsFormV1State>();

  return (
    <Box {...props}>
      <InformationTableRow
        left={t('surface.loans.loan_pay_own_funds_form.wallet_balance')}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {walletAmount}
            </Text>{' '}
            {ticker}
          </Text>
        }
      />
      <InformationTableRow
        isLast={true}
        left={t('surface.loans.loan_pay_own_funds_form.loan_balance')}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              {overdraftAmount}
            </Text>{' '}
            {conversionTicker}
          </Text>
        }
      />
    </Box>
  );
});
