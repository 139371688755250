import * as React from 'react';
import {BankCardsOverview} from '@youtoken/ui.surface-wallets-bank-cards';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export const BankCardsOverviewPage: React.FC = () => {
  return (
    <InnerPageShell>
      <BankCardsOverview />
    </InnerPageShell>
  );
};
