import {AxiosError} from '@youtoken/ui.errors';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {getTranslatedValidationMessage} from './getTranslatedValidationMessage';
import {extractErrorFromResponse} from './extractErrorFromResponse';

export function handleGeneralError(error: AxiosError | Error): void {
  let text: string = i18n.t(
    'common.errors.smth_went_wrong'
  ) as unknown as string;

  if (error instanceof AxiosError && error.response) {
    text = getTranslatedValidationMessage(
      extractErrorFromResponse(error.response, '_error')
    );
  }

  LOCAL_NOTIFICATIONS.error({text});
}
