import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text, useIsMobile} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.service-i18n';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const RolloverInfo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const isMobile = useIsMobile();

  const {
    form: {calculatedData},
    view: {settlementFeeSign, settlementFeePercentFormatted},
  } = useForm<CreateHODLFormClass>();

  const i18nKey = isMobile
    ? 'surface.hodls.hodl_form.footer.rollover_text_newline'
    : 'surface.hodls.hodl_form.footer.rollover_text';

  const rolloverColor = settlementFeeSign === '+' ? '$success-01' : '$text-02';

  if (!calculatedData || !settlementFeePercentFormatted) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      alignItems={isMobile ? 'flex-end' : 'center'}
      {...boxProps}
    >
      <Box flexShrink={0} mr={4}>
        <Trans
          i18nKey={i18nKey}
          parent={props => (
            <Text variant="$body-02" color="$text-02" {...props} />
          )}
          components={{
            Color: <Text color={rolloverColor} />,
          }}
          values={{
            value: settlementFeePercentFormatted,
            period: calculatedData.settlementPeriod,
          }}
        />
      </Box>

      <QuestionTooltip
        content={t('surface.hodls.hodl_form.footer.rollover_tooltip')}
      />
    </Box>
  );
});
