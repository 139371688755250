import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {MultiplySlider} from '@youtoken/ui.hodl-elements';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const HodlCreateMultiplySection: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      form: {currentTariff, multiplier, setMultiplier},
      view: {loansInChain, leverageMin, leverageMax},
    } = useForm<CreateHODLFormClass>();

    return (
      <Box {...boxProps}>
        <MultiplySlider
          value={multiplier}
          minimumValue={leverageMin}
          maximumValue={leverageMax}
          onChange={setMultiplier}
          sliderKey={
            currentTariff?.id ? `slider-${currentTariff?.id}` : 'MultiplySlider'
          }
          loansInChain={loansInChain}
        />
      </Box>
    );
  }
);
