import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Label} from '@youtoken/ui.form-elements';

export interface SliderLabelProps {
  text: string;
  secondaryText?: string;
  value: number;
}

export const SliderLabel: React.FC<SliderLabelProps> = ({
  text,
  secondaryText,
  value,
}) => {
  return (
    <Box flexDirection="row" justifyContent="space-between" mb={2}>
      <Label>{text}</Label>
      <Box flexDirection="row" alignItems="center" justifyContent="flex-end">
        {secondaryText && (
          <Text variant="$body-02" color="$text-03">
            {secondaryText}
          </Text>
        )}
        <Box
          alignItems="center"
          justifyContent="center"
          backgroundColor="$interactive-02"
          borderRadius={6}
          px={8}
          height={24}
          minWidth={26}
          ml={12}
        >
          <Text variant="$body-02-high-accent" color="$text-05">
            x{value}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
