import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AccountLevelProgressAction} from './Action';
import {AccountLevelProgressProps} from '.';
import {AccountLevelProgressWidgetAuthorized} from '..';

export const AccountLevelProgressNoMiner: React.FC<
  AccountLevelProgressProps & BoxProps
> = observer(({TradeToUpgrade, ...boxProps}) => {
  const {t} = useTranslation();

  const {
    data: {currentLevel},
    getLevel,
  } = LoyaltyResource.use({});

  const openUpgradeLevelModal = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('UpgradeYourLevel');
  }, []);

  const title = t('surface.miner.loyalty.upgrade');

  const {requiredDepositVisibleFormatted} = getLevel(2)!;

  return (
    <Box
      testID="ACCOUNT_LEVEL_DETAILED_WIDGET"
      p={{default: 16, tablet: 24}}
      pb={currentLevel > 2 ? 0 : undefined}
      bg="$ui-background"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={16}
      {...boxProps}
    >
      {currentLevel === 1 && (
        <AccountLevelProgressAction
          onPress={openUpgradeLevelModal}
          title={title}
        >
          {t('surface.loyalty.next_level_requirement.2', {
            amount: requiredDepositVisibleFormatted,
          })}
        </AccountLevelProgressAction>
      )}
      {currentLevel === 2 && (
        <AccountLevelProgressAction
          onPress={openUpgradeLevelModal}
          title={title}
        >
          {t('surface.loyalty.next_level_requirement.3')}
        </AccountLevelProgressAction>
      )}
      {currentLevel > 2 && (
        <AccountLevelProgressWidgetAuthorized TradeToUpgrade={TradeToUpgrade} />
      )}
    </Box>
  );
});
