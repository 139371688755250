import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';

export const Back: React.FC<BoxProps> = props => {
  const {t} = useTranslation();

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.goBack();
  }, []);

  return (
    <Box flexDirection="row" justifyContent="flex-start" mb={10} {...props}>
      <Link
        testID="LOAN_BACK_BUTTON"
        variant="$body-01-medium-accent"
        onPress={handlePress}
      >
        <Box flexDirection="row" alignItems="center">
          <Icon name="chevron_left" size={24} color="$interactive-01" />
          <Text>{t('surface.loans.back')}</Text>
        </Box>
      </Link>
    </Box>
  );
};
