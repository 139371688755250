import {action, computed, IReactionDisposer, observable, reaction} from 'mobx';
import {ActiveHODLsResource} from '@youtoken/ui.resource-hodl';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {Platform} from 'react-native';

const defaultItemsLimit = Platform.select({
  web: 10,
  default: 5,
});

export class ActiveHODLsPaginationFeature extends createFeature({
  getKey: () => `feature:active_hodls_pagination`,
  getResources: () => {
    return {
      active: getResourceDescriptor(ActiveHODLsResource, {}),
    };
  },
}) {
  @computed
  get hodls() {
    return this.resources.active.data.rows;
  }

  //#region disposers
  disposers: Array<IReactionDisposer> = [];

  constructor(args: any, resources: any) {
    super(args, resources);

    this.disposers.push(
      reaction(
        () => this.hodls,
        () => {
          if (this.totalPages > 0 && this.totalPages <= this.activePage) {
            this.setPage(this.totalPages);
          }
        }
      )
    );
  }

  onDestroy(): void {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer?.());
  }
  //#endregion disposers

  @observable
  activePage = 1;

  @computed get totalPages() {
    return Math.ceil(this.hodls.length / defaultItemsLimit);
  }

  @action setPage = (page: number) => {
    if (page < 1 || page > this.totalPages) {
      return;
    }

    this.activePage = page;
  };

  @computed get hodlsWithLimit() {
    return this.hodls.slice(
      defaultItemsLimit * (this.activePage - 1),
      defaultItemsLimit * this.activePage
    );
  }
}
