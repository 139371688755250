import * as React from 'react';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {Label} from '../../Primitives';
import {BaseHODLChartState} from '../../logic';

export const InitialPriceLabel: React.FC<{
  chartState: BaseHODLChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {colors} = useTheme();

    const {
      initialPriceLabel: {layout},
      isCursorActive,
    } = chartState;

    if (!layout || !layout.isInDomain) {
      return null;
    }

    return (
      <Label
        x={layout.x}
        y={layout.y}
        width={layout.width}
        height={layout.height}
        labelFill={
          isCursorActive
            ? colors['$ui-background']
            : colors['$chart-neutral-01']
        }
        textFill={
          isCursorActive ? colors['$chart-neutral-01'] : colors['$text-04']
        }
      >
        {layout.text}
      </Label>
    );
  })
);
