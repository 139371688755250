import Big from 'big.js';
import {Duration} from 'date-fns';
import {formatDuration} from '@youtoken/ui.date-fns';

export const getAprBySettlementPeriod = (
  apr: Big,
  settlementPeriodDuration: Duration
) => {
  const {
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = settlementPeriodDuration;

  const durationInSeconds =
    days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
  const durationOfYearInSeconds = 365 * 24 * 60 * 60;

  return apr.div(durationOfYearInSeconds).mul(durationInSeconds);
};

export const getSettlementPeriodFormatted = (settlementPeriod: Duration) => {
  const str = formatDuration(settlementPeriod);

  if (str.startsWith('1 ')) {
    return str.substr(2);
  }

  return str;
};
