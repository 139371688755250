import * as React from 'react';
import {observer} from 'mobx-react';
import {useResource} from '@youtoken/ui.data-storage';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {
  AgreeToTermsField,
  DisclosureForm,
  SubmitDisclosureButton,
} from '@youtoken/ui.surface-legal-info';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Illustration} from '@youtoken/ui.elements';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {AxiosError} from '@youtoken/ui.errors';
import {invariant} from '@youtoken/ui.utils';

interface OpenIBANAccountProps {
  ticker: string;
  onClose: () => void;
}

export const OpenIBANAccount: React.FC<OpenIBANAccountProps> = observer(
  ({ticker, onClose}) => {
    const {t} = useTranslation();

    const {
      data: {
        data: {addressType},
      },
    } = VerificationResource.use({});

    const {openAccount, isIBANAgreementsSigned, providerName, provider} =
      IBANAccountsResource.use({});

    invariant(provider, 'Provider is not defined');

    const [isAccountOpening, setAccountOpening] = React.useState(false);

    const handleOpenAccount = React.useCallback(async () => {
      if (addressType !== 'FULL') {
        LOCAL_NOTIFICATIONS.error({
          text: t('validation.ADDRESS_VERIFICATION_REQUIRED'),
        });
        return VerificationResource.launchAddressVerification();
      }

      try {
        setAccountOpening(true);

        await openAccount(ticker);

        DATA_LAYER.trackStrict('iban-open-submit', {
          ticker,
          provider: providerName ?? 'unknown',
        });

        onClose();
      } catch (e) {
        const error = (e as AxiosError)?.response?.data;
        const _error = extractErrorFromResponse(error, '_error');
        const _errorTranslated = getTranslatedValidationMessage(_error);

        setAccountOpening(false);
        LOCAL_NOTIFICATIONS.error({
          text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
        });
      }
    }, [ticker, openAccount, addressType, onClose, providerName]);

    React.useEffect(() => {
      DATA_LAYER.trackStrict('iban-open-attempt', {
        ticker,
        provider: providerName ?? 'unknown',
      });
    }, [ticker]);

    return (
      <Box width="100%" flexGrow={1} px={24} pb={24} alignItems="center">
        <Box justifyContent="center" flexDirection="column" flexGrow={1}>
          <Box mt={17} mb={28} alignItems="center">
            <Illustration
              name="illustration-15"
              style={{
                width: 208,
                height: 208,
              }}
            />
          </Box>
          <Text variant="$body-02" color="$text-02" mb={24} textAlign="center">
            <Trans
              values={{
                ticker: ticker.toUpperCase(),
              }}
              i18nKey="surface.bank_wire.iban.open_account.description.p1"
            />
          </Text>

          <Text variant="$body-02" color="$text-02" textAlign="center" mb={26}>
            {t('surface.bank_wire.wirex.open_account.description.p2')}
          </Text>
        </Box>

        {!isIBANAgreementsSigned && (
          <DisclosureForm
            product={provider}
            handleOnSuccess={handleOpenAccount}
          >
            <Box flexGrow={1} width="100%" justifyContent="flex-end">
              <AgreeToTermsField
                beforeListText={t(
                  'surface.bank_wire.wirex.terms.agreements.label'
                )}
                maxWidth="100%"
                mb={26}
                alignSelf="stretch"
                testID="FIAT_DEPOSIT_BANK_WIRE_IBAN_AGREEMENT"
              />

              <SubmitDisclosureButton
                disabled={isAccountOpening}
                width="100%"
                testID="FIAT_DEPOSIT_BANK_WIRE_IBAN_OPEN_ACCOUNT_BUTTON"
              >
                {t('surface.bank_wire.wirex.terms.submit.text')}
              </SubmitDisclosureButton>
            </Box>
          </DisclosureForm>
        )}

        {isIBANAgreementsSigned && (
          <Box flexGrow={1} width="100%" justifyContent="flex-end">
            <Button
              disabled={isAccountOpening}
              width="100%"
              size="large"
              onPress={handleOpenAccount}
              testID="FIAT_DEPOSIT_BANK_WIRE_IBAN_OPEN_ACCOUNT_BUTTON"
            >
              {t('surface.bank_wire.wirex.terms.submit.text')}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);
