import * as React from 'react';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {Box} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {
  AccountLevelProgressBarVariant,
  AccountLevelProgressWidgetV2,
} from '@youtoken/ui.surface-account-levels';
import {type ExchangeFormContentProps} from '../types';
import {
  ExchangeFormAmountFastHandlers,
  ExchangeFormConversionPair,
  ExchangeFormInfoTableV2,
} from '../..';
import {ExchangeFormContentWrap} from '../Wrap';
import {ExchangeFormState} from '../../../state';

export const ExchangeFormContentV2: React.FC<ExchangeFormContentProps> =
  observer(({showBalance, showAmountFastHandlers = true, initialAmount}) => {
    const shouldShowWidget = ENVIRONMENT.WEB_APP_ENV !== WebAppEnv['ramp-app'];

    const {
      form: {volumeForecast, sparksAmount},
    } = useForm<ExchangeFormState>();

    return (
      <ExchangeFormContentWrap>
        <Box flex={1} p={24} zIndex={1}>
          <ExchangeFormConversionPair
            showBalance={showBalance}
            initialAmount={initialAmount}
          />
          {showAmountFastHandlers && <ExchangeFormAmountFastHandlers />}
          {shouldShowWidget && (
            <AccountLevelProgressWidgetV2
              variant={AccountLevelProgressBarVariant.EXCHANGE}
              volume={volumeForecast}
              sparks={sparksAmount}
              mt={24}
            />
          )}
          <ExchangeFormInfoTableV2 />
        </Box>
      </ExchangeFormContentWrap>
    );
  });
