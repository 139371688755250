import {observable} from 'mobx';
import {serializable, primitive, list} from 'serializr';
import {CoindropsTariffs} from './CoindropResource';

export class CoindropResponse {
  @observable
  @serializable(primitive())
  tariff!: CoindropsTariffs;

  @observable
  @serializable(list(primitive()))
  coins!: string[];
}
