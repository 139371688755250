import * as yup from './yup';
export * from './yup';

/**
 * @deprecated do not use in new code
 * moved from web app
 */
export const buildSchema = <FormValues extends {}>(
  callback: (y: typeof yup) => FormValues
) => {
  return yup.object().shape<FormValues>(callback(yup));
};
