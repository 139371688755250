import * as React from 'react';
import {Platform, ScrollView, ViewStyle} from 'react-native';

interface ExchangeFormContentWrapProps {
  children: React.ReactNode;
}

export const ExchangeFormContentWrap: React.FC<
  ExchangeFormContentWrapProps
> = ({children}) => {
  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      keyboardDismissMode="on-drag"
      style={[
        {
          flex: 1,
          zIndex: 1,
        },
        scrollViewStyleByPlatform,
      ]}
    >
      {children}
    </ScrollView>
  );
};

const scrollViewStyleByPlatform: ViewStyle = Platform.select({
  native: {},
  default: {
    overflow: 'visible',
  },
});
