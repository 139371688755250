import big from 'big.js';
import {capitalize, orderBy} from 'lodash';
import {action, computed, observable, toJS} from 'mobx';
import {now as mobxUtilsNow} from 'mobx-utils';
import {object, serializable} from 'serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {
  formatBigNumber,
  formatByAmountAndTicker,
  formatByTicker,
  formatPercent,
  formatPercentTillPrecision,
  periodParser,
} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  format,
  formatDistanceToNowStrict,
  isValid,
} from '@youtoken/ui.date-fns';
import {getSettlementFeeSign} from '@youtoken/ui.hodls-utils';
import {HODLItemData} from './HODLItemData';
import {deserializeHODLItemCalculatedResult} from './deserializeHODLItemCalculatedResult';
import {getHODLResult} from './utils';

export enum HodlStatus {
  PENDING = 'PENDING',
  // @deprecated, may come only for v2
  PROCESSING = 'PROCESSING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
}

export enum CloseReason {
  CLOSE_NOW = 'CLOSE_NOW',
  MARGIN_CALL = 'MARGIN_CALL',
  STOP_OUT = 'STOP_OUT',
  TAKE_PROFIT = 'TAKE_PROFIT',
  STOP_LOSS = 'STOP_LOSS',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  EXPIRED = 'EXPIRED',
  CANCEL_BY_USER = 'CANCEL_BY_USER',
  FLIP_ORDER = 'FLIP_ORDER',
}

export enum BriefStatus {
  CANCEL_BY_USER = 'CANCEL_BY_USER',
  CANCEL_OR_DECLINE = 'CANCEL_OR_DECLINE',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  OPENING = 'OPENING',
  CLOSING = 'CLOSING',
  RESULTS = 'RESULTS',
}

export type DirectionText = 'up' | 'down';

export class HODLItem {
  @observable
  @serializable(object(HODLItemData))
  data!: HODLItemData;

  //#region main params

  @computed get id() {
    return this.data.id;
  }

  @computed get version() {
    return this.data.version;
  }

  @computed get status() {
    return this.data.status as HodlStatus;
  }

  @computed get collateralTicker() {
    if (this.isClosed) {
      return this.data.outputTicker;
    }

    return this.data.inputTicker;
  }

  @computed get baseTicker() {
    return this.data.baseTicker;
  }

  @computed get quoteTicker() {
    return this.data.quoteTicker;
  }

  @computed get inputTicker() {
    return this.data.inputTicker;
  }

  @computed get outputTicker() {
    return this.data.outputTicker;
  }

  @computed get precision() {
    return this.data.precision;
  }

  @computed get multiplier() {
    return this.data.multiplier;
  }

  @computed get initialPrice() {
    return this.data.initialPrice;
  }

  @computed get triggerPrice() {
    return this.data.triggerPrice;
  }

  @computed get closedPrice() {
    return this.data.closedPrice;
  }

  @computed get takeProfit() {
    return this.data.tpPrice || this.data.ftpPrice;
  }

  @computed get tradingVolumeUsdFormatted() {
    return this.data.tradingVolumeUsd.toFixed(2);
  }

  @computed get additionalInputTicker() {
    return this.data.additionalInputTicker;
  }

  // We can't show 'YHUSD' and don't want to show 'BONUS', so we show USD
  additionalInputTickerUI = 'USD';

  @computed get _mainInputAmount() {
    return this.data.mainInputAmount;
  }

  @computed get mainInputAmount() {
    return formatByTicker(this.data.mainInputAmount, this.inputTicker);
  }

  @computed get inputAmountFormatted() {
    // NOTE: for MH without used bonuses mainInputAmount = 0
    if (this._mainInputAmount > 0) {
      return this.mainInputAmount;
    }

    return this.inputAmount;
  }

  @computed get additionalInputAmount() {
    return this.data.additionalInputAmount;
  }

  @computed get additionalInputAmountFormatted() {
    if (!this.additionalInputAmount || !this.additionalInputTicker) {
      return '';
    }

    return formatByTicker(
      this.additionalInputAmount,
      this.additionalInputTicker
    );
  }

  @computed get mainOutputAmount() {
    return (
      this.data.closed?.mainOutputAmount ||
      this.data.closeCalculate?.mainOutputAmount
    );
  }

  @computed get mainOutputAmountFormatted() {
    if (!this.mainOutputAmount) {
      return undefined;
    }
    return formatByTicker(this.mainOutputAmount, this.inputTicker);
  }

  // Output amount in base currency. It's either
  // mainOutputAmount or outputAmount depending on whether bonuses were used
  @computed get baseTickerOutputAmount() {
    return this.additionalInputAmount && this.additionalInputAmount > 0
      ? this.mainOutputAmount
      : Number(this._closeNowOutputAmount);
  }

  @computed get baseTickerOutputAmountFormatted() {
    return formatByTicker(this.baseTickerOutputAmount, this.outputTicker);
  }

  @computed get additionalOutputAmount() {
    return (
      this.data.closed?.additionalOutputAmount ||
      this.data.closeCalculate?.additionalOutputAmount
    );
  }

  @computed get additionalOutputAmountFormatted() {
    if (!this.additionalOutputAmount) {
      return undefined;
    }

    return formatByTicker(
      this.additionalOutputAmount,
      this.additionalInputTicker
    );
  }

  //#endregion main params

  //#region actions

  @action openNowPendingHodl = (requestId: string) => {
    return TRANSPORT.API.post('v3/hodl/openNow', {
      id: this.data.id,
      requestId: requestId,
    });
  };

  @action cancelHodl = (requestId: string) => {
    return TRANSPORT.API.post('v3/hodl/cancel', {
      id: this.data.id,
      requestId,
    });
  };

  @action closeHodl = ({requestId}: {requestId: string}) => {
    const {getRateObj} = RatesResource.getInstance({
      product: 'hodl',
    });
    const rateObj = getRateObj(this.data.baseTicker, this.data.quoteTicker);
    const price = this.data.isShort ? rateObj.ask : rateObj.bid;
    const date = new Date().toISOString();

    return TRANSPORT.API.post('v3/hodl/closeNow', {
      id: this.data.id,
      date,
      price,
      requestId,
    });
  };

  //#endregion actions

  //#region statuses

  @computed get isPending() {
    return this.status === HodlStatus.PENDING;
  }

  @computed get isOpening() {
    return this.status === HodlStatus.PROCESSING;
  }

  @computed get isOpen() {
    return this.status === HodlStatus.OPEN;
  }

  @computed get isClosing() {
    return this.status === HodlStatus.CLOSING;
  }

  @computed get isClosed() {
    return this.status === HodlStatus.CLOSED;
  }

  @computed get isDeclined() {
    return this.status === HodlStatus.DECLINED;
  }

  @computed get isCanceled() {
    return this.status === HodlStatus.CANCELED;
  }

  @computed get isCanceledByUser() {
    return (
      this.status === HodlStatus.CANCELED &&
      this.data.reason === CloseReason.CANCEL_BY_USER
    );
  }

  @computed get isExpired() {
    return (
      this.status === HodlStatus.CANCELED &&
      this.data.reason === CloseReason.EXPIRED
    );
  }

  @computed get isHodlExpired() {
    return (
      this.status === HodlStatus.CLOSED &&
      this.data.reason === CloseReason.EXPIRED
    );
  }

  @computed get isClosedLike() {
    return this.isClosed || this.isDeclined || this.isCanceled;
  }

  @computed get isPendingLike() {
    return this.isPending || this.isCanceledByUser || this.isExpired;
  }

  //#endregion statuses

  //#region helpers

  @computed get hasAlertByMC() {
    return this.data?.isClientMC;
  }

  @computed get showTotalAmount() {
    if (!this.data.totalAmount) {
      return false;
    }
    // extra condition removed, task: https://youhodler.atlassian.net/browse/DEV-1244
    return true;
  }

  @computed get direction(): DirectionText {
    return this.data.isShort ? 'down' : 'up';
  }

  @computed get briefStatusVariant() {
    if (this.isOpening) {
      return BriefStatus.OPENING;
    }

    if (this.isCanceledByUser) {
      return BriefStatus.CANCEL_BY_USER;
    }

    if (this.isCanceled || this.isDeclined) {
      return BriefStatus.CANCEL_OR_DECLINE;
    }

    if (this.isExpired) {
      return BriefStatus.EXPIRED;
    }

    if (this.isHodlExpired) {
      return BriefStatus.RESULTS;
    }

    if (this.isPending) {
      return BriefStatus.PENDING;
    }

    if (this.isClosing) {
      return BriefStatus.CLOSING;
    }

    return BriefStatus.RESULTS;
  }

  @computed get tableRowStatus() {
    const reason = this.data.reason;

    const marginCallFeeIsActive = Boolean(
      reason === CloseReason.MARGIN_CALL || reason === CloseReason.STOP_LOSS
    );

    return {
      marginCallLevelIsActive: Boolean(!this.isClosedLike && this.marginCall),
      takeProfitLevelIsActive: Boolean(!this.isClosedLike && this.takeProfit),
      totalCollateralIsActive: this.showTotalAmount,
      additionalInitialAmountIsActive: Boolean(
        this.additionalInputAmount && this.additionalInputTicker
      ),
      profitLosstIsActive: this.isClosed,
      conversionPLIsActive: this.isClosed && Boolean(this.conversionPLAmount),
      outputAmountIsActive: this.isClosed,
      additionalOutputAmountIsActive: Boolean(
        this.isClosed &&
          this.additionalOutputAmountFormatted &&
          this.additionalInputTicker
      ),
      totalBorrowedIsActive: Boolean(
        this.borrowedAmount && this.data.borrowedTicker
      ),
      marginCallFeeIsActive: Boolean(
        marginCallFeeIsActive && this.version !== 1 && this._marginCallFeeAmount
      ),
      originationIsActive: Boolean(
        !marginCallFeeIsActive && this.version > 1 && this._openingFeeAmount
      ),
      legacyFeeIsActive: Boolean(
        this.version === 1 && this.settlementPeriod && this._legacyFeeAmount
      ),
      hasTradingVolume: this.data.tradingVolumeUsd > 0,
      settlementIsActive: Boolean(
        this.version > 1 &&
          this.settlementPeriod &&
          this.settlementFeeAmountPerPeriod &&
          !this.isPendingLike
      ),
      hftFeeIsActive: Boolean(this.isClosed && this._hftFeeAmount),
      revShareIsActive: Boolean(this._revShareFeeAmount),
      unifiedFeeIsActive: Boolean(this._unifiedFeeAmount),
      closedIsActive: Boolean(this.isClosed && this.formattedClosedDate),
      initialPriceIsActive: Boolean(this.initialPrice && !this.isPendingLike),
      triggerPriceIsActive: Boolean(this.triggerPrice), // NOTE: pendings active, cancelled by user and expired should have
      closedPriceIsActive: Boolean(this.closedPrice && this.isClosedLike),
      expirationOrFinishedIsActive: Boolean(
        this.expirationOrFinishedText && this.expirationOrFinishedDate
      ),
      expirationIsActive: Boolean(this.data.hodlExpireAt) && !this.isClosedLike,
    };
  }

  //#endregion helpers

  //#region formatted params

  @computed get hodlId() {
    return this.data.id.slice(0, 8).toUpperCase();
  }

  @computed get initialPriceFormatted() {
    if (this.initialPrice === undefined) {
      return '';
    }
    return formatBigNumber(this.initialPrice, this.precision);
  }

  @computed get triggerPriceFormatted() {
    if (this.triggerPrice === undefined) {
      return '';
    }
    return formatBigNumber(this.triggerPrice, this.precision);
  }

  @computed get closedPriceFormatted() {
    if (this.closedPrice === undefined) {
      return '';
    }
    return formatBigNumber(this.closedPrice, this.precision);
  }

  @computed get agreementUrl() {
    return `/v2/docks/hodl/agreement/${this.data.id}`;
  }

  @computed get closedButtonTitle() {
    const reason = this.data.reason;

    if (this.isCanceled) {
      return i18n.t('surface.hodls.item.status_button.canceled');
    }

    if (this.isDeclined) {
      return i18n.t('surface.hodls.item.status_button.declined');
    }

    if (reason === CloseReason.TAKE_PROFIT) {
      return i18n.t('surface.hodls.item.status_button.closed_take_profit');
    }

    if (reason === CloseReason.INSUFFICIENT_FUNDS) {
      return i18n.t(
        'surface.hodls.item.status_button.closed_insufficient_funds'
      );
    }

    if (reason === CloseReason.CLOSE_NOW || reason === CloseReason.FLIP_ORDER) {
      return i18n.t('surface.hodls.item.status_button.closed_manually');
    }

    if (reason === CloseReason.MARGIN_CALL || reason === CloseReason.STOP_OUT) {
      return i18n.t('surface.hodls.item.status_button.closed_margin_call');
    }

    if (reason === CloseReason.STOP_LOSS) {
      return i18n.t('surface.hodls.item.status_button.closed_stop_loss');
    }

    return i18n.t('surface.hodls.item.status_button.closed');
  }

  //#region formatted params - amounts
  @computed get _inputAmount() {
    return big(this.data.inputAmount);
  }

  @computed get inputAmount() {
    return formatByTicker(this._inputAmount, this.data.inputTicker);
  }

  @computed get inputAmountFormattedByAmount() {
    return formatByAmountAndTicker(this._inputAmount, this.data.inputTicker);
  }

  @computed get borrowedAmount() {
    return formatByTicker(this.data.borrowedAmount, this.data.borrowedTicker);
  }

  @computed get totalAmount() {
    return formatByTicker(this.data.totalAmount, this.baseTicker);
  }

  @computed get _closeNowOutputAmount() {
    try {
      return big(this.data.closeCalculate?.outputAmount || 0) || big(0);
    } catch (e) {
      return big(0);
    }
  }

  @computed get closeNowOutputAmountFormatted() {
    return formatByTicker(this._closeNowOutputAmount, this.data.outputTicker);
  }

  @computed get outputAmountBig() {
    try {
      return big(
        (this.isOpen
          ? this.data.closeCalculate!.outputAmount
          : this.data.outputAmount) as number
      );
    } catch (e) {
      return undefined;
    }
  }

  @computed get closedOutputAmountFormatted() {
    return formatByTicker(this.data.outputAmount, this.data.outputTicker);
  }

  @computed get resultAmount() {
    try {
      return Number(this.outputAmountBig?.minus(this._inputAmount));
    } catch (e) {
      return undefined;
    }
  }

  @computed get resultPercent() {
    return this.isOpen
      ? this.data.closeCalculate?.percent
      : this.data.closed?.percent;
  }

  //#endregion formatted params - amounts

  //#region formatted params - profit
  @computed get calculateProfit() {
    try {
      return big(this.data.closeCalculate?.profit || 0);
    } catch (e) {
      return big(0);
    }
  }

  @computed get calculateProfitLossText() {
    return this.calculateProfit.lt(0) ? 'loss' : 'profit';
  }

  @computed get calculateProfitLossAmount() {
    return formatByTicker(this.calculateProfit, this.data.outputTicker);
  }

  @computed get closeProfit() {
    try {
      return big(this.data.closed?.profit || 0);
    } catch (e) {
      return big(0);
    }
  }

  @computed get profitLossText() {
    return this.closeProfit.lt(0) ? 'loss' : 'profit';
  }

  @computed get profitLossAmount() {
    return formatByTicker(this.closeProfit, this.data.outputTicker);
  }

  @computed get conversionPLAmount() {
    const precision = getCoinDecimalPrecision(this.data.inputTicker);

    if (this.isClosed && this.data.closed?.convertPL) {
      return formatBigNumber(this.data.closed.convertPL, precision, true);
    }

    if (!this.data.closeCalculate?.convertPL) {
      return null;
    }

    return formatBigNumber(
      this.data.closeCalculate?.convertPL,
      precision,
      true
    );
  }
  //#endregion formatted params - profit

  //#region formatted params - tickers
  @computed get inputTickerFormatted() {
    return this.data.inputTicker.toUpperCase();
  }

  @computed get outputTickerUpperCase() {
    return this.data.outputTicker.toUpperCase();
  }

  @computed get collateralTickerUpperCase() {
    return this.data.collateralTicker.toUpperCase();
  }

  @computed get borrowedTickerUpperCase() {
    return this.data.borrowedTicker.toUpperCase();
  }

  @computed get pricePair() {
    if (!this.baseTicker || !this.quoteTicker) {
      return '';
    }

    return `${this.baseTicker}/${this.quoteTicker}`.toUpperCase();
  }

  @computed get currencyPair() {
    if (!this.baseTicker || !this.quoteTicker) {
      return '';
    }

    return `${this.baseTicker}/${this.quoteTicker}`.toUpperCase();
  }

  //#endregion formatted params - tickers

  //#endregion formatted params

  //#region charts

  @computed get chartDirection() {
    if (this.isClosed || this.isOpening || this.isDeclined || this.isCanceled) {
      return 'neutral';
    }
    if (this.isPending) {
      return 'pending';
    }

    return undefined;
  }

  @computed get isChartReversed() {
    return this.data.isShort;
  }

  //#endregion charts

  //#region dates

  @computed get createdOrStartedAt() {
    return this.data.startedAt || this.data.createdAt!;
  }

  @computed get createdOrStartedAtText() {
    return this.data.startedAt ? 'started' : 'created';
  }

  @computed get startDate() {
    return this.createdOrStartedAt;
  }

  @computed get endDate() {
    return this.data.finishedAt;
  }

  @computed get formattedStartDate() {
    return format(this.createdOrStartedAt, 'dd MMM yyyy, HH:mm');
  }

  @computed get formattedClosedDate() {
    return (
      this.data.finishedAt && format(this.data.finishedAt, 'dd MMM yyyy, HH:mm')
    );
  }

  @computed get expiredAtFormatted() {
    return this.data.expireAt
      ? format(this.data.expireAt, 'dd MMM yyyy, HH:mm')
      : '';
  }

  @computed get hodlExpireAtFormatted() {
    return this.data.hodlExpireAt
      ? format(this.data.hodlExpireAt, 'dd MMM yyyy, HH:mm')
      : '';
  }

  @computed get hodlExpireAtDistanceFormatted() {
    return this.data.hodlExpireAt
      ? formatDistanceToNowStrict(this.data.hodlExpireAt)
      : '';
  }

  @computed get expirationOrFinishedText() {
    if (this.isPending) {
      return i18n.t('surface.hodls.item.details_table.will_expire');
    }

    if (this.isCanceledByUser) {
      return i18n.t('surface.hodls.item.details_table.canceled');
    }

    if (this.isExpired) {
      return i18n.t('surface.hodls.item.details_table.expired');
    }

    return '';
  }

  @computed get expirationOrFinishedDate() {
    if (this.isPending) {
      return this.expiredAtFormatted;
    }

    if (this.isCanceledByUser) {
      return this.formattedClosedDate;
    }

    if (this.isExpired) {
      return this.expiredAtFormatted;
    }

    return '';
  }

  @computed get expirationPeriodFormatted() {
    return this.data.expireAt
      ? formatDistanceToNowStrict(this.data.expireAt, {
          addSuffix: false,
        })
      : '';
  }

  @computed get relativeDate() {
    // NOTE: param expireAt is exists for all pending order
    // expireAt - we need to use only for expired orders
    // finishedAt - we  use for all closed / canceled orders, including orders, canceled by user
    // createdOrStartedAt - is used for active orders (actually now is not shown)
    const date = this.isExpired
      ? this.data.expireAt
      : this.data.finishedAt || this.createdOrStartedAt;

    if (!isValid(date)) {
      return '';
    }
    // date checked with isValid, so it's not null
    return formatDistanceToNowStrict(date!, {
      addSuffix: true,
    });
  }

  //#endregion dates

  //#region rollover

  @computed get rolloverActive() {
    return this.status === 'OPEN';
  }

  @computed get nextRolloverTime() {
    const nextChargeAt = this.data.nextChargeAt?.getTime() ?? 0;
    const nextRolloverIn = nextChargeAt - mobxUtilsNow();
    if (!nextRolloverIn || nextRolloverIn < 0) {
      return {
        h: 0,
        min: 0,
        sec: 0,
      };
    }

    const nextRolloverInSecs = Math.floor(nextRolloverIn / 1000);

    const h = Math.floor(nextRolloverInSecs / 60 / 60);
    const min = Math.floor((nextRolloverInSecs - h * 60 * 60) / 60);
    const sec = nextRolloverInSecs % 60;

    return {
      h,
      min,
      sec,
    };
  }

  @computed get rolloverInLessThan10Mins() {
    const {min} = this.nextRolloverTime;

    return min < 10;
  }

  @computed get nextRolloverTimeFormatted() {
    const {h, min, sec} = this.nextRolloverTime;
    const secFormatted = `${sec < 10 ? '0' : ''}${sec}`;
    const minFormatted = `${h > 0 && min < 10 ? '0' : ''}${min}`;

    const r: any[] = [minFormatted, secFormatted];

    if (h > 0) {
      r.unshift(h);
    }

    return r.join(':');
  }

  @computed.struct get rolloverData() {
    if (this.nextRolloverTime.min < 0) {
      return;
    }

    return {
      formattedTime: this.nextRolloverTimeFormatted,
      lessThan10Mins: this.rolloverInLessThan10Mins,
      active: this.rolloverActive,
    };
  }

  //#endregion rollover

  //#region fees

  // legacy fee

  @computed get closeFeePercent() {
    const percent =
      this.data.closeCalculate?.feePercent || this.data.closed?.feePercent || 0;

    return formatPercent(percent);
  }

  @computed get legacyFeeAmount() {
    return formatByTicker(this._legacyFeeAmount, this.data.outputTicker);
  }

  @computed get _legacyFeeAmount() {
    return (
      this.data.closeCalculate?.feeAmount || this.data.closed?.feeAmount || 0
    );
  }

  // margin call fee

  @computed get marginCallFeePercent() {
    return formatPercent(this.data.mcFee || 0);
  }

  @computed get marginCallFeeAmount() {
    return formatByTicker(this._marginCallFeeAmount, this.data.borrowedTicker);
  }

  @computed get _marginCallFeeAmount() {
    return this.data.mcFeeAmount || 0;
  }

  // opening fee

  @computed get openingFeePercent() {
    return formatPercent(this.data.openingFee);
  }

  @computed get openingFeeAmount() {
    return formatByTicker(this._openingFeeAmount, this.data.borrowedTicker);
  }

  @computed get _openingFeeAmount() {
    return this.data.openingFeeAmount || 0;
  }

  // settlement fee

  @computed get settlementPeriod() {
    return this.data.settlementPeriod;
  }

  @computed get settlementFeeIterations() {
    return (
      this.data.closeCalculate?.feeIterations ||
      this.data.closed?.feeIterations ||
      1
    );
  }

  @computed get settlementFeeIterationsDaysAndHours() {
    if (!this.data.startedAt?.getTime()) {
      return '0h';
    }

    const diff =
      (this.data.finishedAt?.getTime() || mobxUtilsNow()) -
      this.data.startedAt?.getTime();
    const hoursRaw = Math.floor(diff / 1000 / 60 / 60);

    const days = Math.floor(hoursRaw / 24);
    const hours = hoursRaw - days * 24;

    const result = [];

    if (days > 0) {
      result.push(`${days}d`);
    }

    result.push(`${hours}h`);

    return result.join(' ');
  }

  @computed get settlementPeriodName() {
    return capitalize(periodParser(this.data.settlementPeriod));
  }

  @computed get settlementFeePerPeriodSign() {
    return getSettlementFeeSign(this.data.settlementFee);
  }

  @computed get settlementFeePercent() {
    const amount = formatPercentTillPrecision(
      Math.abs(this.data.settlementFee) || 0,
      4
    );

    return `${this.settlementFeePerPeriodSign}${amount}`;
  }

  @computed get settlementFeeAmount() {
    const amount = formatByTicker(
      Math.abs(this._settlementFeeAmount || 0),
      this.data.borrowedTicker
    );

    const settlementFeeSign = getSettlementFeeSign(this._settlementFeeAmount);

    return `${settlementFeeSign}${amount}`;
  }

  @computed get settlementFeeAmountPerPeriod() {
    const amount = formatByTicker(
      big(this.data.settlementFeeAmount).abs(),
      this.data.borrowedTicker
    );

    return `${this.settlementFeePerPeriodSign}${amount}`;
  }

  @computed get _settlementFeeAmount() {
    return this.isClosedLike
      ? this.data.closed?.feeAmount
      : this.data.closeCalculate?.feeAmount;
  }

  // rev share fee

  @computed get revShareFeePercent() {
    return formatPercent(this.data.revShareFee);
  }

  @computed get revShareOfWithCurrency() {
    return `${formatByTicker(this._revShareFeeOf, this.data.outputTicker)} ${
      this.outputTickerUpperCase
    }`;
  }

  @computed get revShareFeeAmount() {
    return formatByTicker(this._revShareFeeAmount, this.data.outputTicker);
  }

  @computed get _revShareFeeOf() {
    return (
      this.data.closed?.revShareFeeOf || this.data.closeCalculate?.revShareFeeOf
    );
  }

  @computed get _revShareFeeAmount() {
    return (
      this.data.closed?.revShareFeeAmount ||
      this.data.closeCalculate?.revShareFeeAmount
    );
  }

  // hft fee (for old HODLs)

  @computed get hftFeeAmountFormatted() {
    return formatByTicker(this._hftFeeAmount, this.data.borrowedTicker);
  }

  @computed get hftFeeAmountTicker() {
    return this.data.borrowedTicker.toUpperCase();
  }

  @computed get _hftFeeAmount() {
    return (
      this.data.closed?.hftFeeAmount || this.data.closeCalculate?.hftFeeAmount
    );
  }

  // unified fee

  @computed get unifiedFeePercent() {
    return formatPercent(this._unifiedFee);
  }

  @computed get unifiedFeeAmount() {
    return formatByTicker(this._unifiedFeeAmount, this.data.borrowedTicker);
  }

  @computed get _unifiedFee() {
    return this.data.unifiedFee;
  }

  @computed get _unifiedFeeAmount() {
    return (
      this.data.closeCalculate?.unifiedFeeAmount ||
      this.data.closed?.unifiedFeeAmount
    );
  }

  //#endregion fees

  //#region marginCall

  @computed get marginCallPrices(): Array<{type: string; price: number}> {
    const order = this.direction === 'up' ? 'desc' : 'asc';

    const prices = [
      {type: 'margin_call', price: this.data.mcPrice},
      {type: 'stop_loss', price: this.data.slPrice},
      {type: 'insufficient_funds', price: this.data.ifPrice},
    ].filter(obj => !!obj.price);

    return orderBy(prices, ['price'], [order]) as Array<{
      type: string;
      price: number;
    }>;
  }

  @computed get marginCallType() {
    if (!this.marginCallPrices.length) {
      return undefined;
    }

    return this.marginCallPrices[0]!.type;
  }

  @computed get marginCall() {
    if (!this.marginCallPrices.length) {
      return undefined;
    }

    return this.marginCallPrices[0]!.price;
  }

  @computed get marginCallFormatted() {
    if (this.marginCall === undefined) {
      return '';
    }
    return formatBigNumber(this.marginCall, this.precision);
  }

  @computed get marginCallNameTranslated() {
    switch (this.marginCallType) {
      case 'insufficient_funds':
        return i18n.t('surface.hodls.item.details_table.insufficient_funds');
      case 'stop_loss':
        return i18n.t('surface.hodls.item.details_table.stop_loss');
      case 'margin_call':
      default:
        return i18n.t('surface.hodls.item.details_table.margin_call');
    }
  }

  @computed get marginCallTooltipTranslated() {
    switch (this.marginCallType) {
      case 'insufficient_funds':
        return i18n.t(
          'surface.hodls.item.details_table.insufficient_funds_tooltip'
        );
      case 'stop_loss':
        return i18n.t('surface.hodls.item.details_table.stop_loss_tooltip');
      case 'margin_call':
      default:
        return i18n.t('surface.hodls.item.details_table.margin_call_tooltip');
    }
  }

  //#endregion marginCall

  //#region takeProfit
  @computed get takeProfitFormatted() {
    if (this.takeProfit === undefined) {
      return '';
    }
    return formatBigNumber(this.takeProfit, this.precision);
  }

  @computed get takeProfitNameTranslated() {
    return i18n.t('surface.hodls.item.details_table.take_profit');
  }

  @computed get takeProfitTooltipTranslated() {
    return i18n.t('surface.hodls.item.details_table.take_profit_tooltip');
  }

  //#endregion takeProfit

  //#region HODL explanation for v2

  @computed get _loanTotalCollateral() {
    return formatByTicker(
      this.data.loan?.totalCollateral && big(this.data.loan.totalCollateral),
      this.data.loan?.collateralTicker
    );
  }

  @computed get _loanTotalRepayment() {
    return formatByTicker(
      this.data.loan?.totalCollateral && big(this.data.loan.totalRepayment),
      this.data.loan?.borrowedTicker
    );
  }

  //#endregion HODL explanation for v2

  //#region results

  @computed.struct get ratesDependencies() {
    const {getRateObj, getRate} = RatesResource.getInstance({
      product: 'hodl',
    });

    // NOTE: long / short: bid / ask
    const givenPrice = getRateObj(this.baseTicker, this.quoteTicker)[
      this.data.isShort ? 'ask' : 'bid'
    ];

    // NOTE: if input ticker not equal to base or quote ticker, then this is ratio quote ticker to input ticker, in other case - 1.
    const givenRate =
      this.inputTicker != this.baseTicker &&
      this.inputTicker != this.quoteTicker
        ? getRate(this.quoteTicker, this.inputTicker)
        : 1;

    return {givenPrice, givenRate};
  }

  @computed get calculatedResultsActiveHODL() {
    if (!this.isOpen) {
      return undefined;
    }

    const {givenPrice, givenRate} = this.ratesDependencies;

    return deserializeHODLItemCalculatedResult(this, givenPrice, givenRate);
  }

  @computed.struct get hodlResultFormatted(): {
    resultAmount?: string;
    resultTicker: string;
    resultPercent?: string;
    resultDirection: 'up' | 'down' | 'neutral';
  } {
    // trace();
    if (this.isOpen) {
      return getHODLResult(
        this.inputTicker,
        this.calculatedResultsActiveHODL?.amount,
        this.calculatedResultsActiveHODL?.percent,
        this.isOpen
      );
    }

    return getHODLResult(
      this.inputTicker,
      this.resultAmount,
      this.resultPercent,
      this.isOpen
    );
  }

  @computed get activeOutputAmountFormatted() {
    if (!this.isOpen) {
      return undefined;
    }

    return formatByAmountAndTicker(
      this.calculatedResultsActiveHODL?.outputAmount,
      this.outputTicker
    );
  }

  //#endregion results

  //#region chartData

  // NOTE: Don't use directly, use chartData
  @computed get chartDataClosed() {
    const _chartData = toJS(this.data.chartData);

    _chartData.data.push({
      date: this.endDate,
      rate: this.closedPrice,
    });

    return _chartData;
  }

  // NOTE: Don't use directly, use chartData
  @computed get chartDataInteractive() {
    const {getRateObj} = RatesResource.getInstance({
      product: 'hodl',
    });

    const {bid, ask} = getRateObj(this.baseTicker, this.quoteTicker);

    const _chartData = toJS(this.data.chartData);

    // NOTE: for HODL create form and for pending-orders is a chart with prices which will be used for open the deal
    if (['PENDING'].includes(this.status)) {
      _chartData.data.push({
        date: new Date(),
        rate: this.data.isShort ? bid : ask,
      });

      return _chartData;
    }

    // NOTE: for opened position is a chart with prices which will be used for close the deal
    if (['OPEN'].includes(this.status)) {
      _chartData.data.push({
        date: new Date(),
        rate: this.data.isShort ? ask : bid,
      });

      return _chartData;
    }

    return {};
  }

  @computed.struct get chartData() {
    if (!this.data.chartData || this.data.chartData.data.length < 3) {
      return {};
    }

    try {
      if (['PENDING', 'OPEN'].includes(this.status)) {
        return this.chartDataInteractive;
      }

      if (['CLOSED'].includes(this.status)) {
        return this.chartDataClosed;
      }

      return this.data.chartData;
    } catch (e) {
      return {};
    }
  }

  //#endregion chartData
}
