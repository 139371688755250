import * as React from 'react';
import {
  Animated,
  Easing,
  Image,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Box, TouchableBox} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {openBrowserAsync} from 'expo-web-browser';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  IUserStoryItem,
  NextOrPrevious,
  StoryListItemProps,
} from '../interfaces';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {observer} from 'mobx-react';
import {SENTRY} from '@youtoken/ui.sentry';
import {StoryTemplate} from './StoryTemplate';
import {invariant} from '@youtoken/ui.utils';

export const StoryListItem = observer(
  React.forwardRef(
    (
      {
        story,
        index,
        duration,
        onFinish,
        onPressClose,
        onPressCTA,
        currentPage,
        onStoryShown,
        showClose,
        loadedAnimationBarStyle,
        unloadedAnimationBarStyle,
        animationBarContainerStyle,
        storyImageStyle,
      }: StoryListItemProps,
      ref
    ) => {
      const {id, slug, title, slides: _slides} = story;
      const {top} = useSafeAreaInsets();

      const previousPage = React.useRef<number>();

      //#region storySlides

      const [slides, setSlides] = React.useState<IUserStoryItem[]>(
        _slides.map(slide => {
          return {
            ...slide,
          };
        })
      );

      //#endregion storySlides

      //#region storyCurrentSlide and currentSlideIndex

      const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

      const currentSlide = slides[currentSlideIndex]!;

      //#endregion storyCurrentSlide and currentSlideIndex

      //#region storyCurrentSlideButton

      const {button: currentSlideButton} = currentSlide;

      const {title: currentSlideButtonTitle, url: currentSlideButtonUrl} =
        currentSlideButton ?? {};

      const handlePressButton = React.useCallback(() => {
        if (currentSlideButtonUrl?.startsWith('http')) {
          pause();

          Platform.select({
            web: () => {
              window.open(
                currentSlideButtonUrl,
                '_blank',
                'noopener noreferrer'
              );
            },
            default: () => {
              openBrowserAsync(currentSlideButtonUrl).catch(error => {
                SENTRY.capture(error, {
                  source: 'StoryListItem',
                });
              });
            },
          })!();

          return;
        }

        invariant(
          currentSlideButtonUrl,
          'currentSlideButtonUrl was not provided'
        );

        const [name, params, query] = SHARED_ROUTER_SERVICE.urlToRoute(
          currentSlideButtonUrl
        );

        DATA_LAYER.trackStrict('story-button-click', {
          storyId: story.id,
          storySlug: story.slug,
          storyTitle: story.title,
          storyNumberOfSlides: story.slides?.length,
          currentSlideNumber: currentSlideIndex + 1,
          targetUrl: currentSlideButtonUrl,
        });

        onPressCTA?.();

        SHARED_ROUTER_SERVICE.navigate(name, params, query);
      }, [currentSlideButtonUrl, currentSlideIndex, onPressClose, story]);

      const handlePressClose = React.useCallback(() => {
        onPressClose(story);
      }, [onPressClose, story]);

      //#endregion storyCurrentSlideButton

      //#region storyCurrentSlideImages
      const {
        imagesLoaded,
        prefetchImagesForSlide,
        checkLoadingState,
        appIsActive,
      } = InstaStoriesResource.use({});

      const currentSlideImageKeys = React.useMemo(() => {
        return (['backgroundUrl', 'foregroundUrl'] as const).filter(
          imageKey => {
            return currentSlide[imageKey];
          }
        );
      }, [currentSlide]);

      const currentSlideIsLoading = React.useMemo(() => {
        if (currentSlideImageKeys.length === 0) {
          return false;
        }

        return currentSlideImageKeys.some(imageKey => {
          return (
            Boolean(currentSlide[imageKey]) &&
            !imagesLoaded[currentSlide[imageKey]!]
          );
        });
      }, [currentSlide, currentSlideImageKeys, imagesLoaded]);

      //#endregion storyCurrentSlideImages

      //#region storyImperativeHandlers

      const progress = React.useRef(new Animated.Value(0)).current;

      const [currentSlideIsFreezing, setCurrentSlideIsFreezing] =
        React.useState<boolean>(false);

      const handleFinish = React.useCallback(
        (state: NextOrPrevious) => {
          if (currentPage == index) {
            onFinish?.(state);
          }
        },
        [currentPage, index, onFinish]
      );

      const pause = React.useCallback(() => {
        setCurrentSlideIsFreezing(true);
      }, []);

      const resume = React.useCallback(() => {
        setCurrentSlideIsFreezing(false);
      }, []);

      const previous = React.useCallback(() => {
        if (currentSlideIsLoading) {
          return;
        }

        progress.stopAnimation();

        setSlides(data => {
          const slides = [...data];

          if (currentSlideIndex - 1 >= 0) {
            slides[currentSlideIndex - 1] = {
              ...slides[currentSlideIndex - 1]!,
              finish: 0,
            };
          }

          slides[currentSlideIndex] = {
            ...slides[currentSlideIndex]!,
            finish: 0,
          };

          return slides;
        });

        // checking if the previous slides is not empty
        if (currentSlideIndex - 1 >= 0) {
          setCurrentSlideIndex(currentSlideIndex - 1);
        } else {
          // the previous slides is empty
          handleFinish('previous');
        }

        progress.setValue(0);
      }, [currentSlideIsLoading, currentSlideIndex, handleFinish]);

      const next = React.useCallback(
        (isSkipped?: boolean) => {
          if (currentSlideIsLoading) {
            return;
          }

          progress.stopAnimation();

          setSlides(data => {
            const slides = [...data];

            slides[currentSlideIndex] = {
              ...slides[currentSlideIndex]!,
              finish: 1,
            };

            return slides;
          });

          // NOTE: 'story-slide-skipped' event should check the direction (only forward is skipped)
          // NOTE: 'story-slide-skipped' event should be called only after user's action, not after change slide automatically
          if (isSkipped) {
            DATA_LAYER.trackStrict('story-slide-skipped', {
              storyId: id,
              storySlug: slug,
              storyTitle: title,
              storyNumberOfSlides: slides?.length,
              currentSlideNumber: currentSlideIndex + 1,
            });
          }

          // check if the next slides is not empty
          if (currentSlideIndex < slides.length - 1) {
            setCurrentSlideIndex(currentSlideIndex + 1);

            progress.setValue(0);
          } else {
            // the next slides is empty
            handleFinish('next');

            progress.setValue(1);
          }
        },
        [currentSlideIsLoading, currentSlideIndex, slides.length, handleFinish]
      );

      const animateProgress = React.useCallback(() => {
        Animated.timing(progress, {
          toValue: 1,
          duration,
          easing: Easing.linear,
          useNativeDriver: false,
        }).start(({finished}) => {
          if (finished && appIsActive) {
            next();
          }
        });
      }, [next, appIsActive]);

      React.useEffect(() => {
        if (currentPage !== index) {
          return;
        }

        if (!appIsActive) {
          progress.stopAnimation();
        } else {
          // NOTE: to have the same speed of animation, we set progress to start position
          progress.setValue(0);
          animateProgress();
        }
      }, [appIsActive]);

      React.useImperativeHandle(
        ref,
        () => {
          return {
            pause,
            resume,
            previous,
            next,
          };
        },
        [pause, resume, previous, next]
      );

      //#endregion storyImperativeHandlers

      //#region storyMainEffects

      React.useEffect(() => {
        if (
          currentPage !== index ||
          currentSlideIsLoading ||
          currentSlideIsFreezing
        ) {
          progress.stopAnimation();
          return;
        }

        if (currentPage !== previousPage.current) {
          progress.setValue(0);
        }

        animateProgress();
      }, [
        currentPage,
        index,
        currentSlideIndex,
        currentSlideIsLoading,
        currentSlideIsFreezing,
        animateProgress,
      ]);

      React.useEffect(() => {
        if (currentPage !== previousPage.current) {
          previousPage.current = currentPage;
        }

        return () => {
          previousPage.current = undefined;
        };
      }, [currentPage]);

      React.useEffect(() => {
        if (currentPage !== index) {
          return;
        }

        DATA_LAYER.trackStrict('story-slide-shown', {
          storyId: id,
          storySlug: slug,
          storyTitle: title,
          storyNumberOfSlides: slides?.length,
          currentSlideNumber: currentSlideIndex + 1,
        });

        onStoryShown?.(id, currentSlide.order);
      }, [
        currentPage,
        index,
        currentSlideIndex,
        currentSlide.order,
        onStoryShown,
        id,
        slug,
        title,
        slides?.length,
      ]);

      // NOTE: load all next slides after 0
      // the images for 0 slides were preloaded in StorySmartContainer
      React.useEffect(() => {
        // NOTE: load second slide only for active story,
        // as for the CubeEffect components all the stories (the 0 slide) are rendered with the first open of any one
        if (
          currentSlideIndex === 0 &&
          currentPage === index &&
          story.slides.length > 1
        ) {
          prefetchImagesForSlide(story.slides[1]!);
        }
        // NOTE: load any next slide after the second
        if (
          currentSlideIndex > 0 &&
          story.slides[currentSlideIndex + 1] &&
          currentPage === index
        ) {
          prefetchImagesForSlide(story.slides[currentSlideIndex + 1]!);
        }
      }, [currentSlideIndex, currentPage]);

      //#endregion storyMainEffects

      return (
        <Box flex={1}>
          {/* IMAGES */}
          <Box
            flex={1}
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
          >
            <Box flex={1} opacity={currentSlideIsLoading ? 0 : 1}>
              {currentSlideImageKeys.map(imageKey => {
                return (
                  <Image
                    key={currentSlide[imageKey]}
                    source={{uri: currentSlide[imageKey], cache: 'force-cache'}}
                    // NOTE: Actualize loading state for loaded image
                    onLoadEnd={() => checkLoadingState(currentSlide[imageKey]!)}
                    resizeMode={
                      imageKey === 'backgroundUrl' ? 'cover' : 'contain'
                    }
                    style={[
                      {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      },
                      storyImageStyle,
                    ]}
                  />
                );
              })}
            </Box>
            {currentSlideIsLoading && (
              <Box
                zIndex={-100}
                position="absolute"
                justifyContent="center"
                backgroundColor="$ui-overlay"
                alignSelf="center"
                width="100%"
                height="100%"
              >
                <ActivityIndicator />
              </Box>
            )}
          </Box>

          {Boolean(currentSlide.templateName) &&
            Boolean(currentSlide.contentParams) && (
              <StoryTemplate
                name={currentSlide.templateName!}
                params={currentSlide.contentParams!}
              />
            )}

          {/* TOP CONTENT */}
          <Box flex={1} pt={top}>
            <Box flex={1} flexDirection="column">
              {/* PROGRESS BAR */}
              <Box
                flexDirection="row"
                width="100%"
                px={16}
                py={8}
                mt={Platform.select({default: 16, native: 0})}
                style={animationBarContainerStyle}
              >
                {slides.map(({finish}, index) => {
                  return (
                    <Box
                      key={index}
                      height={2}
                      flex={1}
                      flexDirection="row"
                      mx={2}
                      style={[unloadedAnimationBarStyle]}
                      borderRadius={1}
                    >
                      <Box
                        position="absolute"
                        height={2}
                        backgroundColor="$text-04"
                        opacity={0.3}
                        flex={1}
                        width="100%"
                        borderRadius={1}
                        left={0}
                        right={0}
                      />
                      <Animated.View
                        style={[
                          {
                            height: 2,
                            backgroundColor: 'white',
                            borderRadius: 1,
                            flex:
                              currentSlideIndex === index ? progress : finish,
                          },
                          loadedAnimationBarStyle,
                        ]}
                      />
                    </Box>
                  );
                })}
              </Box>

              {/* NAVIGATION */}
              {showClose && (
                <Box flexDirection="row" justifyContent="flex-end">
                  <TouchableBox
                    width={40}
                    height={40}
                    alignItems="center"
                    justifyContent="center"
                    mr={8}
                    onPress={handlePressClose}
                  >
                    <Box
                      width={24}
                      height={24}
                      borderRadius={12}
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="$text-04"
                    >
                      <Icon size={20} name="close" color="$text-02" />
                    </Box>
                  </TouchableBox>
                </Box>
              )}
              <Box flex={1} flexDirection="row">
                <TouchableWithoutFeedback
                  onPressIn={pause}
                  onLongPress={pause}
                  onPressOut={resume}
                  onPress={() => {
                    previous();
                  }}
                >
                  <Box
                    flex={1}
                    style={{
                      //@ts-ignore
                      outlineWidth: 0,
                    }}
                  />
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback
                  onPressIn={pause}
                  onLongPress={pause}
                  onPressOut={resume}
                  onPress={() => {
                    next(true);
                  }}
                >
                  <Box
                    flex={1}
                    style={{
                      //@ts-ignore
                      outlineWidth: 0,
                    }}
                  />
                </TouchableWithoutFeedback>
              </Box>

              {/* CALL TO ACTION */}
              {!currentSlideIsLoading && currentSlideButton && (
                <Box position="absolute" left={40} right={40} bottom={72}>
                  <Button size="large" onPress={handlePressButton}>
                    {currentSlideButtonTitle}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    }
  )
);
