import {action, computed, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Wallet, WalletsResource} from '@youtoken/ui.resource-wallets';
import {WalletsChooseTickerMethod, WalletsChooseTickerType} from '../types';
import {NETWORKS} from './consts';
import {getIsMethodEnabled, getItemsBySearch, toggleFilter} from './utils';

type WalletsChooseTickerFeatureArgs = {
  type: WalletsChooseTickerType;
  method: WalletsChooseTickerMethod;
};

export class WalletsChooseTickerFeature extends createFeature({
  getKey: ({type, method}: WalletsChooseTickerFeatureArgs) => {
    return `WalletsChooseTickerFeature({type: ${type}, method: ${method})`;
  },
  getResources: () => {
    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
    };
  },
}) {
  //#region wallets
  @computed
  get isWalletsListCreating() {
    return this.resources.walletsResource.isWalletsListCreating;
  }

  @computed
  get wallets() {
    if (this.args.type === 'fiat') {
      return this.resources.walletsResource.fiatWallets;
    }

    if (this.args.type === 'crypto') {
      return this.resources.walletsResource.cryptoWallets;
    }

    if (this.args.type === 'stable') {
      return this.resources.walletsResource.stableWallets;
    }

    if (this.args.type === 'cryptoAndStable') {
      return this.resources.walletsResource.cryptoAndStableWallets;
    }

    return [];
  }

  //#endregion wallets

  //#region search
  @observable search: string = '';

  @action updateSearch = (search: string) => {
    this.search = search;
  };
  //#endregion search

  //#region filters
  @observable
  selectedFilters: string[] = [];

  @computed
  get shownFilters(): string[] {
    if (this.args.type === 'fiat') {
      return [];
    }

    const walletsVersionsSet = new Set(
      this.wallets.flatMap(({versions}) => versions.map(({version}) => version))
    );

    return NETWORKS.filter(network => walletsVersionsSet.has(network));
  }

  @action toggleFilter = (filter: string) => {
    this.selectedFilters = toggleFilter(this.selectedFilters, filter);
  };

  @action resetFilters = () => {
    this.selectedFilters = [];
  };
  //#endregion filters

  //#region items
  @computed.struct
  get filteredItems(): Wallet[] {
    let items = getItemsBySearch(this.wallets, this.search);

    return items.filter(wallet => {
      const methodEnabled = getIsMethodEnabled(wallet, this.args.method);

      if (!methodEnabled) {
        return false;
      }

      const networks = wallet.versions.flatMap(({version}) => version);

      // Check if all selected filters are included in the networks array
      return this.selectedFilters.every(filter => networks.includes(filter));
    });
  }

  //#endregion items

  @action
  reset = () => {
    this.resetFilters();
    this.updateSearch('');
  };
}
