import * as React from 'react';
// @ts-ignore
import Notification from 'rc-notification';
import {
  AppearanceAndThemeProvider,
  Box,
  type BoxProps,
  Text,
} from '@youtoken/ui.primitives';
import {AppThemeProvider} from '@web-app/src/components/styles';

type MessageInstance = {
  removeNotice: (arg: number) => void;
  destroy: () => void;
  notice: (arg: any) => void;
} | null;

let defaultDuration = 3;
let defaultTop: number;
let messageInstance: MessageInstance;
let key = 1;
let prefixCls = 'notifications';
let getContainer = () => document.querySelectorAll('body')[0];
let maxCount = 3;

type MessageContainerProps = {
  type: 'error' | 'info';
};

const MessageContainer: React.FC<MessageContainerProps & BoxProps> = ({
  children,
  type,
  ...boxProps
}) => {
  return (
    <Box
      borderRadius={8}
      mx={20}
      px={20}
      py={8}
      backgroundColor={type === 'error' ? '$danger-01' : '$interactive-01'}
      {...boxProps}
    >
      <Text color="$text-04">{children}</Text>
    </Box>
  );
};

const NotificationContentWrapper: React.FC<{}> = ({children}) => {
  return (
    <AppThemeProvider>
      <AppearanceAndThemeProvider>{children}</AppearanceAndThemeProvider>
    </AppThemeProvider>
  );
};

function getMessageInstance(callback: (arg: MessageInstance) => void) {
  if (messageInstance) {
    callback(messageInstance);
    return;
  }
  Notification.newInstance(
    {
      prefixCls,
      style: {top: defaultTop},
      getContainer,
      maxCount,
    },
    (instance: MessageInstance) => {
      if (messageInstance) {
        callback(messageInstance);
        return;
      }
      messageInstance = instance;
      callback(instance);
    }
  );
}

function notice(
  content: string,
  duration: number,
  type: 'error' | 'info',
  onClose: () => void,
  testID: string = 'NOTIFICATION'
) {
  if (typeof duration === 'function') {
    onClose = duration;
    duration = defaultDuration;
  }

  const target = key++;
  const closePromise = new Promise(resolve => {
    const callback = () => {
      if (typeof onClose === 'function') {
        onClose();
      }
      return resolve(true);
    };
    getMessageInstance(instance => {
      instance!.notice({
        key: target,
        duration,
        style: {},
        content: (
          <NotificationContentWrapper>
            <MessageContainer
              data-cy="NOTIFICATION"
              type={type}
              testID={`${testID}_${type.toUpperCase()}`}
            >
              {content}
            </MessageContainer>
          </NotificationContentWrapper>
        ),
        onClose: callback,
      });
    });
  });
  const result = () => {
    if (messageInstance) {
      messageInstance.removeNotice(target);
    }
  };
  result.then = (
    filled: (value: unknown) => unknown,
    rejected: (reason: any) => PromiseLike<never>
  ) => closePromise.then(filled, rejected);
  result.promise = closePromise;
  return result;
}

export const message = {
  info(content: string, duration = 3, onClose = () => {}, testID?: string) {
    return notice(content, duration, 'info', onClose, testID);
  },

  error(content: string, duration = 3, onClose = () => {}, testID?: string) {
    return notice(content, duration, 'error', onClose, testID);
  },

  destroy() {
    if (messageInstance) {
      messageInstance.destroy();
      messageInstance = null;
    }
  },
};
