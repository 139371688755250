import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, type IconProps, Icon} from '@youtoken/ui.icons';

interface BenefitItemProps {
  iconName: BaseIconName;
  iconColor: IconProps<BaseIconName>['color'];
  iconColorBackground: BoxProps['backgroundColor'];
}

export const BenefitItem: React.FC<BenefitItemProps & BoxProps> = ({
  iconName,
  iconColor,
  iconColorBackground,
  children,
  ...boxProps
}) => {
  return (
    <Box flexDirection="row" alignItems="center" mt={6} {...boxProps}>
      <Box
        justifyContent="center"
        alignItems="center"
        width={24}
        height={24}
        mr={8}
        borderRadius={12}
        backgroundColor={iconColorBackground}
      >
        <Icon name={iconName} color={iconColor} size={16} />
      </Box>
      <Box flexGrow={1} flexShrink={1}>
        <Text variant="$body-02" color="$text-02">
          {children}
        </Text>
      </Box>
    </Box>
  );
};
