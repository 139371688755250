import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {HODLByIdFeature} from '../../../features';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {AdjustHODLButton, ExtendHODLButton, FlipHODLButton} from './components';

interface HODLActionsSectionProps {
  hodl: HODLItem;
  onClickAdjustHODL: () => void;
  onClickExtendHODL: () => void;
  onClickFlipHODL: () => void;
}

export const HODLActionsSection: React.FC<HODLActionsSectionProps> = observer(
  ({hodl, onClickAdjustHODL, onClickExtendHODL, onClickFlipHODL}) => {
    const {
      isExtendTpSlAvailable,
      isFlipDirectionAvailable,
      isShort,
      incentivesUsed,
    } = HODLByIdFeature.use({
      id: hodl.id,
    });

    if (!hodl.isOpen) {
      return null;
    }

    return (
      <Box
        flexDirection={{default: 'column', desktop: 'row'}}
        px={24}
        pt={8}
        pb={{default: 8, desktop: 24}}
      >
        <AdjustHODLButton onPress={onClickAdjustHODL} version={hodl.version} />
        {!incentivesUsed && (
          <>
            {isExtendTpSlAvailable && (
              <ExtendHODLButton
                onPress={onClickExtendHODL}
                mt={{default: 10, desktop: 0}}
                ml={{default: 0, desktop: 16}}
              />
            )}
            {isFlipDirectionAvailable && (
              <FlipHODLButton
                onPress={onClickFlipHODL}
                isShort={isShort}
                mt={{default: 10, desktop: 0}}
                ml={{default: 0, desktop: 16}}
              />
            )}
          </>
        )}
      </Box>
    );
  }
);
