import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, TouchableBox, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LearnMoreModal} from './LearnMoreModal';

export const LearnMore: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        {...boxProps}
      >
        <TouchableBox
          borderWidth={1}
          borderColor="$ui-01"
          borderRadius={8}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          py={8}
          pl={12}
          pr={16}
          onPress={openModal}
        >
          <Icon name="question" size={16} color="$text-02" />
          <Box ml={4}>
            <Text variant="$body-02" color="$text-02">
              {t('surface.bank_cards.landing.learn_more.button')}
            </Text>
          </Box>
        </TouchableBox>
      </Box>
      <LearnMoreModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
});
