import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RewardWidgetResource} from '@youtoken/ui.resources-rewards';
import {ReferralsAndBonusesSummary} from '../../components';

export const ReferralsAndBonusesSummaryV1Smart = observer(() => {
  const {t} = useTranslation();

  const {
    data: {
      totalEarned: {usd: totalEarnedUSD},
      maxEarnings: {usd: maxEarningsUSD},
      newsCount,
    },
  } = RewardWidgetResource.use({});

  return (
    <ReferralsAndBonusesSummary
      title={t(
        'surface.referral_program.referrals_widget.referrals_and_bonuses'
      )}
      amount={totalEarnedUSD.toNumber()}
      maxAmount={maxEarningsUSD.toNumber()}
      newsCount={newsCount}
    />
  );
});
