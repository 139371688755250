import {Big} from 'big.js';

export const getMaxDurationValue = (durationFormatted: string) => {
  return durationFormatted.match(/\d+/)?.[0];
};

export const getMaxDurationLabel = (durationFormatted: string) => {
  return durationFormatted.match(/ (\D+)/)?.[1];
};

export const getMinInterestAmount = (apr: Big, precision: number) => {
  if (apr.gt(0)) {
    return Big(1).div(Big(10).pow(precision));
  }

  return Big(0);
};

export const getInterestAmount = (
  apr: Big,
  amount: Big,
  minInterestAmount: Big
) => {
  const interest = amount.mul(apr);

  if (amount.gt(0) && interest.lt(minInterestAmount)) {
    return minInterestAmount;
  }

  return interest;
};

export const formatInterestAmount = (amount: Big, precision: number) => {
  return amount.round(precision).toFixed();
};
