import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import type {CardWrapperProps} from './types';
import {SectorsCard} from '../SectorsCard';
import {cardsData} from '../../data';

export const SectorsCardsWrapper: React.FC<CardWrapperProps> = ({
  webSectorsData,
  onPress,
}) => {
  if (!webSectorsData) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      flexWrap="wrap"
      px={{default: 20, desktop: 0}}
      m={-6}
    >
      {webSectorsData.map((item, i) => {
        return (
          <Box
            m={6}
            width={{
              default: 'calc(50% - 12px)',
              tablet: 'calc(25% - 12px)',
            }}
            minWidth={140}
            key={i}
          >
            <SectorsCard
              {...cardsData[item.name]!}
              itemCount={item.count}
              onPress={onPress}
              itemName={item.name}
              key={cardsData[item.name]?.title}
            />
          </Box>
        );
      })}
    </Box>
  );
};
