import * as React from 'react';
import {
  FlatList,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Arrow} from '../Arrow';
import {type ArrowProps, CollapsedAreaProps} from '../types';

export function CollapsedArea<T>({
  items,
  renderItem,
  onResize,
  wrapperPadding = 16,
  wrapperMargin = 12,
  itemLimit,
  scrollingScale = 3,
  needArrows = true,
  arrowIconColor,
  arrowBoxProps,
  snapToInterval,
  ...boxProps
}: CollapsedAreaProps<T> & BoxProps) {
  const scrollableAreaRef = React.useRef<FlatList>(null);

  const [isWrapperBiggerThanContent, setIsWrapperBiggerThanContent] =
    React.useState(true);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);
  const [isLeftArrowDisable, setIsLeftArrowDisable] = React.useState(true);
  const [isRightArrowDisable, setIsRightArrowDisable] = React.useState(false);

  // useLayoutEffect is used instead of useEffect to prevent the arrow from flickering after uncollapsing or layout changing
  React.useLayoutEffect(() => {
    const isWrapperBiggerThanContent =
      wrapperWidth >=
      scrollableAreaRef.current?.getScrollableNode().scrollWidth;

    setIsWrapperBiggerThanContent(isWrapperBiggerThanContent);

    if (onResize) {
      onResize(isWrapperBiggerThanContent);
    }
  }, [wrapperWidth, onResize, items.length]);

  const handleWrapperLayout = React.useCallback(
    ({nativeEvent: {layout}}: LayoutChangeEvent) => {
      setWrapperWidth(layout.width);
    },
    []
  );

  const handleArrowClick = React.useCallback<
    (direction: ArrowProps['direction']) => void
  >(
    direction => {
      const scrollableAreaElement =
        scrollableAreaRef.current?.getScrollableNode();
      const {scrollLeft: scrollPosition, clientWidth: scrollableAreaWidth} =
        scrollableAreaElement;

      const scrollDistance = scrollableAreaWidth / scrollingScale;

      const offset =
        direction === 'back'
          ? scrollPosition - scrollDistance
          : scrollPosition + scrollDistance;

      scrollableAreaRef?.current?.scrollToOffset({offset});
    },
    [scrollingScale]
  );

  const handleScroll = React.useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const currentScrollPosition = event.nativeEvent.contentOffset.x;
      const layoutMeasurement = event.nativeEvent.layoutMeasurement.width;
      const scrollableContentWidth = event.nativeEvent.contentSize.width;

      const shouldDisableRightArrow =
        currentScrollPosition + layoutMeasurement >= scrollableContentWidth;
      if (shouldDisableRightArrow !== isRightArrowDisable) {
        setIsRightArrowDisable(shouldDisableRightArrow);
      }

      const shouldDisableLeftArrow = currentScrollPosition === 0;
      if (shouldDisableLeftArrow !== isLeftArrowDisable) {
        setIsLeftArrowDisable(shouldDisableLeftArrow);
      }
    },
    [isRightArrowDisable, isLeftArrowDisable]
  );

  // Limiting data by the collapsedViewItemLimit props if provided
  const dataToRender = React.useMemo(() => {
    if (itemLimit) {
      return items.slice(0, itemLimit);
    }

    return items;
  }, [itemLimit, items]);

  const renderItemTemplate = React.useCallback(
    ({item, index}: {item: T; index: number}) => {
      return (
        <Box key={index}>
          {renderItem({item, index, isParentListExpanded: false})}
        </Box>
      );
    },
    [items.length, itemLimit, wrapperPadding, wrapperMargin, renderItem]
  );

  return (
    <Box
      onLayout={handleWrapperLayout}
      py={wrapperPadding}
      flexDirection="row"
      backgroundColor="$ui-01"
      borderRadius={16}
      {...boxProps}
    >
      {needArrows && !isWrapperBiggerThanContent && (
        <>
          {!isLeftArrowDisable && (
            <Arrow
              direction="back"
              iconColor={arrowIconColor}
              onPress={handleArrowClick}
              {...arrowBoxProps}
            />
          )}
          {!isRightArrowDisable && (
            <Arrow
              direction="forward"
              iconColor={arrowIconColor}
              onPress={handleArrowClick}
              {...arrowBoxProps}
            />
          )}
        </>
      )}
      <FlatList
        ref={scrollableAreaRef}
        data={dataToRender}
        renderItem={renderItemTemplate}
        horizontal
        pagingEnabled={true}
        onScroll={handleScroll}
        snapToInterval={snapToInterval}
        style={{
          //@ts-ignore
          scrollPadding: wrapperMargin, // only for web
        }}
        contentContainerStyle={{
          paddingHorizontal: wrapperMargin,
        }}
        showsHorizontalScrollIndicator={false}
      />
    </Box>
  );
}
