import * as React from 'react';
import {observer} from 'mobx-react';
import {BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {YouhodlerServices} from './YouhodlerServices';
import {ProductType} from '../types';

export interface YouhodlerDisclosureContentProps extends BoxProps {
  product: ProductType;
}

export const YouhodlerDisclosureContent: React.FC<YouhodlerDisclosureContentProps> =
  observer(({product}) => {
    const {t} = useTranslation();
    const title = React.useMemo(() => {
      switch (product) {
        case 'hodl2':
          return t('surface.disclosure_form.content.subtitle.hodl2');
        case 'loan':
          return t('surface.disclosure_form.content.subtitle.loan');
        case 'turbo':
          return t('surface.disclosure_form.content.subtitle.turbo');
        case 'dual':
          return t('surface.disclosure_form.content.subtitle.dual');
        default:
          return '';
      }
    }, [product, t]);

    return (
      <>
        <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
          {title}
        </Heading>
        <Text variant="$body-02" color="$text-02" mb={16}>
          {t('surface.disclosure_form.content.text')}
        </Text>
        <YouhodlerServices />
      </>
    );
  });
