import * as React from 'react';
import {observer} from 'mobx-react';
import {
  SavingsOverviewStatus,
  SavingsOverviewResource,
} from '@youtoken/ui.resource-savings';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SavingsWidgetWelcome} from './Welcome';
import {SavingsWidgetProgress} from './Progress';

export const SavingsWidgetSmart: React.FC<BoxProps> = observer(boxProps => {
  const {
    data: {status},
  } = SavingsOverviewResource.use({});

  if (status === SavingsOverviewStatus.WAITING) {
    return null;
  }

  if (status === SavingsOverviewStatus.NEW) {
    return <SavingsWidgetWelcome {...boxProps} />;
  }

  return <SavingsWidgetProgress status={status} {...boxProps} />;
});
