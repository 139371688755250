import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon, LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {useForm} from '@youtoken/ui.form-elements';
import {Combobox} from '@youtoken/ui.combobox';
import {LedgerFormState} from './types';

export interface LedgerAccountComboboxProps {
  onClose: () => void;
}

export const LedgerAccountCombobox: React.FC<LedgerAccountComboboxProps> = ({
  onClose,
}) => {
  const {
    form: {ledgerAccountsItems, ledgerAccountId, ledgerAccountIdOnChange},
  } = useForm<LedgerFormState>();

  return (
    <Combobox
      items={ledgerAccountsItems}
      ItemComponent={LedgerAccountComboboxItem}
      value={ledgerAccountId}
      initialIsOpen={true}
      onChange={ledgerAccountIdOnChange}
      onClose={onClose}
    />
  );
};

export const LedgerAccountComboboxItem: React.FC<any> = ({
  value,
  ticker,
  tickerFormatted,
  label,
  amount,
  highlighted,
  ...rest
}) => {
  return (
    <Box
      px={16}
      height={48}
      bg={highlighted ? '$interactive-02' : '$ui-background'}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Box flexDirection="row" alignItems="center">
        <LogoColored name={ticker as LogoColoredIconName} size={20} />
        <Text variant="$body-01" ml={8}>
          {tickerFormatted}
        </Text>
        <Text variant="$body-01" color="$text-03" ml={8}>
          {label}
        </Text>
      </Box>
      <Box>
        <Text variant="$body-01-medium-accent" color="$text-01">
          {amount}
        </Text>
      </Box>
    </Box>
  );
};

export interface LedgerAccountProps {
  onPress: () => void;
}

export const LedgerAccount: React.FC<LedgerAccountProps> = observer(
  ({onPress}) => {
    const {
      form: {ticker, tickerFormatted},
    } = useForm<LedgerFormState>();

    return (
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        height="100%"
        px={16}
        onPress={onPress}
      >
        <LogoColored name={ticker as LogoColoredIconName} size={20} />
        <Text ml={8} mr={2} variant="$body-01-medium-accent" color="$text-01">
          {tickerFormatted}
        </Text>
        <Icon name="expand" color="$text-01"></Icon>
      </TouchableBox>
    );
  }
);
