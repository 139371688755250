import * as React from 'react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {ModalHeaderProps} from './types';

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  subtitle,
  withSeparator,
  testID,
}) => {
  if (!title && !subtitle) {
    return null;
  }

  return (
    <>
      <Box
        justifyContent="center"
        alignItems="center"
        py={24}
        px={{default: 16, phone: 24}}
      >
        {title && (
          <Heading
            variant={
              Boolean(subtitle) ? '$heading-02' : '$heading-02-responsive'
            }
            textAlign="center"
            testID={testID ? `${testID}_TITLE` : undefined}
          >
            {title}
          </Heading>
        )}
        {subtitle && (
          <Box pt={8} testID={testID ? `${testID}_SUBTITLE` : undefined}>
            {typeof subtitle === 'string' ? (
              <Text variant="$body-02" color="$text-02" textAlign="center">
                {subtitle}
              </Text>
            ) : (
              subtitle
            )}
          </Box>
        )}
      </Box>
      {withSeparator && <Separator />}
    </>
  );
};
