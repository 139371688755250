import {periodParserDirectDigitFreeShort} from '@youtoken/ui.formatting-utils';

export const getSettlementFeeTickerComposite = (
  borrowedTickerFormatted: string,
  settlementFeePercentFormatted: string,
  settlementPeriodFormatted: string
) => {
  return `${borrowedTickerFormatted} (${settlementFeePercentFormatted}% / ${periodParserDirectDigitFreeShort(
    settlementPeriodFormatted
  )})`;
};
