import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryItemV1} from '@youtoken/ui.resources-rewards';
import {Box, Text} from '@youtoken/ui.primitives';
import {Badge} from '@youtoken/ui.elements';
import {RewardsHistoryItemIndicator} from './Indicator';

export interface RewardsHistoryItemV1ItemProps {
  data: RewardsHistoryItemV1;
}

export const RewardsHistoryItemV1Item: React.FC<RewardsHistoryItemV1ItemProps> =
  observer(
    ({data: {isToday, title, amountUSDFormatted, createdAtFormatted}}) => {
      const badgeColor = isToday ? '$success-01' : '$text-02';
      const textColor = isToday ? '$text-01' : '$text-02';

      return (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          px={{default: 16, phone: 24}}
          py={16}
        >
          {isToday && <RewardsHistoryItemIndicator />}

          <Badge type="primary" bg={badgeColor}>
            {amountUSDFormatted}
          </Badge>

          <Box mx={16} flexGrow={1} flexShrink={1}>
            <Text variant="$body-02" color={textColor}>
              {title}
            </Text>
          </Box>

          <Box alignItems="flex-end" flexShrink={1}>
            <Text variant="$body-02" color={textColor} textAlign="right">
              {createdAtFormatted}
            </Text>
          </Box>
        </Box>
      );
    }
  );
