import React from 'react';
import {Helmet} from 'react-helmet';
import {AntiPhishingSurfaceCell} from '@youtoken/ui.surface-anti-phishing';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {goBack} from '../../../../services/shared-router';

export const AntiPhishingPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.profile.security.antiphishing.form_title')} />
      <Box pb={27} flexDirection="row" justifyContent="flex-start">
        <Button type="ghost" onPress={goBack} ml={-5} icon="chevron_left">
          {t('surface.profile.form_confirm_phone.action.back')}
        </Button>
      </Box>
      <AntiPhishingSurfaceCell />
    </InnerPageShell>
  );
};
