import {observable} from 'mobx';
import {Form} from './Form';
import {View} from './View';
import {type UmaWithdrawalFormArgs} from '../types';

export class UmaWithdrawalForm {
  @observable
  public form: Form;

  @observable
  public view: View;

  public constructor(args: UmaWithdrawalFormArgs) {
    this.form = new Form(args);
    this.view = new View(this.form);
  }
}
