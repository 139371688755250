import * as React from 'react';
import {Big} from 'big.js';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {Text} from '@youtoken/ui.primitives';

interface PriceDiffPercentProps {
  value: Big;
  keepZeros?: boolean;
}

export const PriceDiffPercent: React.FC<PriceDiffPercentProps> = ({
  value,
  keepZeros = false,
}) => {
  const direction = value.gte(0) ? 'up' : 'down';
  const priceColor = direction === 'up' ? '$success-01' : '$danger-01';
  const valueFormatted = formatPercent(value.abs(), keepZeros);

  return (
    <Text
      color={priceColor}
      variant="$body-02-medium-accent"
      testID="INSTRUMENT_DIF24"
    >
      {direction === 'up' ? '+' : '-'}
      <Text variant={{default: '$body-02-medium-accent', desktop: '$body-02'}}>
        {valueFormatted}
      </Text>
      %
    </Text>
  );
};
