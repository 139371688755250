import * as React from 'react';
import {observer} from 'mobx-react';
import {SortingTabs, type SortingTabItem} from '@youtoken/ui.sorting-tabs';
import {HODLsInstrumentsSectorsFeature} from '../../../../features/HODLsInstrumentsSectorsFeature';

export const SectorsInstrumentsSortingTabs: React.FC = observer(() => {
  const {sortingTabs, activeSortingTabValue, updateSorting} =
    HODLsInstrumentsSectorsFeature.use({});

  const handlePress = React.useCallback(
    (item: SortingTabItem) => {
      updateSorting(item);
    },
    [updateSorting]
  );

  return (
    <SortingTabs
      justifyContent="flex-end"
      items={sortingTabs}
      activeValue={activeSortingTabValue}
      onChange={handlePress}
    />
  );
});
