import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';

export interface LoanItemHeaderBriefProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeaderBrief: React.FC<LoanItemHeaderBriefProps> = observer(
  ({item}) => {
    const {t} = useTranslation();

    return (
      <Box testID="LOAN_ITEM_BRIEF" flexDirection="row">
        <Box mr={10}>
          <LogoColored
            name={item.collateralTicker as LogoColoredIconName}
            size={32}
          />
        </Box>
        <Box>
          <Text
            testID="LOAN_ITEM_BRIEF_COLLATERAL"
            variant="$body-01"
            color="$text-02"
          >
            <Text variant="$body-01-high-accent" color="$text-01">
              {item.collateralAmountFormatted}
            </Text>{' '}
            {item.collateralTickerFormatted}
          </Text>
          <Box flexDirection="row" alignItems="center">
            <Text
              testID="LOAN_ITEM_BRIEF_LTV"
              variant="$body-02"
              color="$text-02"
            >
              {t('surface.loans.item.ltv')} {item.ltvFormatted}%
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
