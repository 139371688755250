import * as React from 'react';
import {Defs, LinearGradient, Path, Stop, Svg} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';
import {warning} from '@youtoken/ui.utils';
import {type ChartSmallPreviewChartData} from '../utils/types';
import {getColorName} from '../utils/getColorName';
import {getChartPath} from '../utils/getChartPath';

export interface ChartSmallPreviewProps {
  width: number;
  height: number;
  data: ChartSmallPreviewChartData[];
  gradient?: boolean;
  diffDirection?: 'up' | 'down';
}

export const ChartSmallPreview: React.FC<ChartSmallPreviewProps> = ({
  width,
  height,
  data,
  gradient,
  diffDirection,
}) => {
  const theme = useTheme();

  const colors = theme['colors'];

  const colorName = getColorName(data, diffDirection);

  const d = getChartPath(width, height, data);

  if (!d || !colorName) {
    warning(
      d && colorName,
      `ChartSmallPreview: cannot render chart; d: ${d}, colorName: ${colorName}`
    );

    return null;
  }

  return (
    <Svg width={width} height={height}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d={d}
        fill={
          gradient ? `url(#chart_small_preview_${colorName})` : 'transparent'
        }
        stroke={colors[colorName]}
      />
      {gradient && (
        <Defs>
          <LinearGradient
            id={`chart_small_preview_${colorName}`}
            x1="-2"
            y1="61"
            x2="-2"
            y2="1"
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor={colors[colorName]} stopOpacity="0.01" />
            <Stop stopColor={colors[colorName]} stopOpacity="0.14" offset="1" />
          </LinearGradient>
        </Defs>
      )}
    </Svg>
  );
};
