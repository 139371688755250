import {action} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {localClientSettings} from '@youtoken/ui.resource-auth-me';
import {
  RewardOverviewStatus,
  RewardStatus,
  RewardOverview,
  RewardLevel,
} from './RewardsResponse';

export {RewardOverviewStatus, RewardStatus, RewardOverview, RewardLevel};

export class RewardsResource extends createResource({
  getKey: () => `rewardsV3`,
  getData: () => {
    return TRANSPORT.API.get(`/v3/rewards/overview`, {
      params: {
        referralViewedDateTime: localClientSettings?.referralViewedDateTime,
        rewardViewedDateTime: localClientSettings?.rewardViewedDateTime,
      },
    }).then(res => {
      return deserialize(RewardOverview, res.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  @action
  claim = () => {
    return TRANSPORT.API.get(`/v3/rewards/claim`).then(() => this.refetch());
  };
}
