import * as React from 'react';
import {observer} from 'mobx-react';
import {Link} from '@youtoken/ui.elements';
import {Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const LegalInfoLink: React.FC = observer(() => {
  const {t} = useTranslation();

  const handleOpenLink = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('LegalAndInfo');
  }, []);

  return (
    <Box mt={24}>
      <Link onPress={handleOpenLink} variant="$body-02" color="$text-01">
        {t('surface.profile.legal_info.title')}
      </Link>
    </Box>
  );
});
