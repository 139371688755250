import * as React from 'react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {DevelopmentInfo} from './DevelopmentInfo';
import {ErrorFallbackProps} from '../types';

export interface BaseErrorFallbackProps extends ErrorFallbackProps {
  /**
   * error text
   * @default 'Something went wrong'
   **/
  text?: string;
  /**
   * error icon name
   * @default 'warning'
   * */
  iconName?: React.ComponentProps<typeof Icon>['name'];

  /** optional hint for error */
  hint?: string;
}

export const BaseErrorFallback: React.FC<BaseErrorFallbackProps> = ({
  error,
  text = 'Something went wrong',
  iconName = 'warning',
  hint,
  backgroundColor = '$ui-background',
  reportingResult,
  children,
  ...rest
}) => {
  return (
    <Box
      backgroundColor={backgroundColor}
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      {...rest}
    >
      <Icon name={iconName} size={32} color="$text-01" />

      <Text color="$text-01" variant="$body-01" mt={4}>
        {text}
      </Text>

      {hint && (
        <Text color="$text-02" variant="$body-02" mt={4}>
          {hint}
        </Text>
      )}

      {children}

      {ENVIRONMENT.DEV && (
        <DevelopmentInfo error={error} reportingResult={reportingResult} />
      )}
    </Box>
  );
};
