import React from 'react';
import {observer} from 'mobx-react';
import {WarningIndicator} from '@youtoken/ui.elements';

export interface MarginCallIndicatorProps {
  hasAlertByMC?: boolean;
}

export const MarginCallIndicator: React.FC<MarginCallIndicatorProps> = observer(
  ({hasAlertByMC}) => {
    if (!hasAlertByMC) {
      return null;
    }

    return <WarningIndicator />;
  }
);
