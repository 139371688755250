import {ChangeEvent} from 'react';
import {normalizeOnlyDigits as _normalizeOnlyDigits} from '@youtoken/ui.normalizers';

type NormalizeFunction = (
  event: ChangeEvent<HTMLInputElement>
) => ChangeEvent<HTMLInputElement>;

export const normalizeOnlyDigits: NormalizeFunction = event => {
  event.target.value = _normalizeOnlyDigits(event.target.value);

  return event;
};
