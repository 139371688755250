import React from 'react';
import {KeyboardTypeOptions, TextInput} from 'react-native';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {InputPure} from '..';

export interface InputAmountProps
  extends React.ComponentProps<typeof InputPure> {
  precission?: number;
}

export const InputAmount = React.forwardRef<TextInput, InputAmountProps>(
  (
    {precission = 2, keyboardType: _keyboardType, onChangeText, ...otherProps},
    ref
  ) => {
    const keyboardType =
      _keyboardType && inputAmountKeyboards.includes(_keyboardType)
        ? _keyboardType
        : inputAmountKeyboards[0];

    const handleChangeText = React.useCallback(
      (text: string) => {
        if (onChangeText) {
          onChangeText(normalizeAmountWithPrecision(text, precission));
        }
      },
      [onChangeText, precission]
    );

    return (
      <InputPure
        ref={ref}
        keyboardType={keyboardType}
        onChangeText={handleChangeText}
        {...otherProps}
      />
    );
  }
);

export const inputAmountKeyboards = [
  'numeric',
  'number-pad',
  'decimal-pad',
] as KeyboardTypeOptions[];
