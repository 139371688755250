import * as React from 'react';
import {observer} from 'mobx-react';
import {
  RewardOverviewStatus,
  RewardsResource,
} from '@youtoken/ui.resource-rewards-v3';
import {RewardsOverviewStatusMessageOpened} from './Opened';
import {RewardsOverviewStatusMessageCompleted} from './Completed';

export const RewardsOverviewStatusMessage: React.FC = observer(() => {
  const {
    data: {status, lastLevelAmount},
  } = RewardsResource.use({});

  if (status === RewardOverviewStatus.OPEN) {
    return <RewardsOverviewStatusMessageOpened amount={lastLevelAmount} />;
  }

  return <RewardsOverviewStatusMessageCompleted />;
});
