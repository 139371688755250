import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '@youtoken/ui.modal';
import {
  BankCardTransactionsSurface,
  getBankCardTransactionsTitle,
} from '@youtoken/ui.surface-wallets-bank-cards';
import {modal} from '../../../stores';
import {Modal as _Modal} from '../_common';

export const modalBankCardTransactionsName = 'bankCardTransactions';

type ModalBankCardTransactionsProps = {
  cardId: string;
};

export const ModalBankCardTransactions: React.FC<
  ModalBankCardTransactionsProps & React.ComponentProps<typeof _Modal>
> = cell(({cardId}) => {
  const {opened, close} = modal;

  return (
    <Modal
      isOpen={opened?.name === modalBankCardTransactionsName}
      onClose={close}
      title={getBankCardTransactionsTitle()}
      webOverlayBackgroundColor="$transparent"
      webHeight={576}
    >
      <BankCardTransactionsSurface cardId={cardId} />
    </Modal>
  );
});
