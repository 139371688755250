import * as React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {invariant} from '@youtoken/ui.utils';
import {TouchableComponent} from './TouchableComponent';
import {TextComponent} from './TextComponent';
import {IButtonProps} from './types';
import {IconComponent} from './IconComponent';
import {LoadingComponent} from './LoadingComponent';

export const Button: React.FC<BoxProps & IButtonProps> = ({
  children,
  color = 'interactive',
  size = 'medium',
  type = 'primary',
  iconPosition = 'center',
  icon,
  disabled,
  pressable = true,
  ...props
}) => {
  invariant(
    icon || children,
    `Button should have at least "children" or "icon" props, icon=${icon} and children=${children} were given`
  );

  return (
    <TouchableComponent
      color={color}
      type={type}
      size={size}
      icon={icon}
      disabled={disabled}
      pressable={pressable}
      onPress={props.onPress}
      {...props}
    >
      <>
        {props.loading && (
          <LoadingComponent color={color} type={type} size={size} />
        )}

        {!props.loading && children && !icon && (
          <TextComponent
            color={color}
            size={size}
            type={type}
            disabled={disabled}
          >
            {children}
          </TextComponent>
        )}

        {!props.loading && children && icon && (
          <Box
            alignSelf="stretch"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            {iconPosition === 'left' && (
              <>
                <Box position="absolute" left={0}>
                  <IconComponent
                    color={color}
                    size={size}
                    type={type}
                    icon={icon}
                    disabled={disabled}
                  />
                </Box>
                <TextComponent
                  color={color}
                  size={size}
                  type={type}
                  disabled={disabled}
                >
                  {children}
                </TextComponent>
              </>
            )}
            {iconPosition === 'center' && (
              <>
                <IconComponent
                  color={color}
                  size={size}
                  type={type}
                  icon={icon}
                  disabled={disabled}
                  rightMargin
                />
                <TextComponent
                  color={color}
                  size={size}
                  type={type}
                  disabled={disabled}
                >
                  {children}
                </TextComponent>
              </>
            )}
            {iconPosition === 'right' && (
              <>
                <TextComponent
                  color={color}
                  size={size}
                  type={type}
                  disabled={disabled}
                >
                  {children}
                </TextComponent>
                <Box position="absolute" right={0}>
                  <IconComponent
                    color={color}
                    size={size}
                    type={type}
                    icon={icon}
                    disabled={disabled}
                  />
                </Box>
              </>
            )}
          </Box>
        )}

        {!props.loading && !children && icon && (
          <IconComponent
            color={color}
            size={size}
            type={type}
            icon={icon}
            disabled={disabled}
          />
        )}
      </>
    </TouchableComponent>
  );
};
