import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LoyaltyIconName, LoyaltyIcon} from '@youtoken/ui.icons';

export const LEVEL_ICON_WRAP_SIZE = 56;

export interface LevelIconProps {
  name: LoyaltyIconName;
  size?: number;
  wrapSize?: number;
}

export const LevelIcon: React.FC<LevelIconProps & BoxProps> = React.memo(
  ({name, size = 36, wrapSize = LEVEL_ICON_WRAP_SIZE, ...boxProps}) => {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        width={wrapSize}
        height={wrapSize}
        bg="$ui-background"
        borderWidth={4}
        borderRadius={wrapSize / 2}
        borderColor="$ui-background"
        {...boxProps}
      >
        <LoyaltyIcon name={name} size={size} />
      </Box>
    );
  }
);
