import * as React from 'react';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {DashedLine} from '../../Primitives';
import {BaseHODLChartState} from '../../logic';

export const InitialPriceLine: React.FC<{
  chartState: BaseHODLChartState;
  fullWidth?: boolean;
}> = React.memo(
  observer(({chartState, fullWidth}) => {
    const {colors} = useTheme();
    const {
      width,
      initialPriceLabel: {layout},
    } = chartState;

    // NOTE: if initial price isn't on chart - no line
    if (!layout || !layout.isInDomain) {
      return null;
    }

    return (
      <DashedLine
        x1={fullWidth ? 0 : layout.isInDomain ? layout.width : 0}
        x2={width}
        y1={layout.y}
        y2={layout.y}
        stroke={colors['$chart-neutral-01']}
      />
    );
  })
);
