import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLSmallPreviewChartSmartCell} from '@youtoken/ui.legacy-charts';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {ItemDiff} from './ItemDiff';
import {type TradingInstrumentModalItemProps} from '../../types';

export const TradingInstrumentModalItem: React.FC<TradingInstrumentModalItemProps> =
  observer(
    ({
      item,
      onPressItem,
      closeModal,
      isParentListExpanded,
      testIdBase,
      index,
    }) => {
      const handlePress = React.useCallback(() => {
        closeModal?.();
        onPressItem(item.id, isParentListExpanded);
      }, [item.id, onPressItem, closeModal, isParentListExpanded]);

      return (
        <TouchableBox
          key={item.id + index}
          flexDirection="row"
          justifyContent="space-between"
          height={64}
          py={16}
          px={24}
          borderBottomColor="$ui-01"
          borderBottomWidth={1}
          onPress={handlePress}
          testID={`${testIdBase}_CARD_${
            item.baseTickerFormatted + item.quoteTickerFormatted
          }`}
        >
          <Box flexDirection="row" alignItems="center">
            <LogoColored
              name={item.baseTicker as LogoColoredIconName}
              size={32}
            />
            <Box maxWidth={{default: 140, desktop: undefined}} ml={8}>
              <Text
                variant="$body-01-high-accent"
                color="$text-01"
                testID={`${testIdBase}_INSTRUMENT_TICKERS`}
              >
                {item.baseTickerFormatted} / {item.quoteTickerFormatted}
              </Text>
              <Text
                variant="$body-02"
                color="$text-02"
                // @ts-ignore TS telling that this prop doesn't exist, but it does and work on mobile
                ellipsizeMode="tail"
                numberOfLines={1}
                testID={`${testIdBase}_INSTRUMENT_NAME`}
              >
                {item.baseTickerName}
              </Text>
            </Box>
          </Box>
          <Box
            ml="auto"
            marginRight={10}
            testID={`${testIdBase}_INSTRUMENT_CHART`}
          >
            <HODLSmallPreviewChartSmartCell
              width={40}
              height={30}
              fromTicker={item.baseTicker}
              toTicker={item.quoteTicker}
              gradient
            />
          </Box>
          <ItemDiff
            baseTicker={item.baseTicker}
            quoteTicker={item.quoteTicker}
          />
        </TouchableBox>
      );
    }
  );
