import {NotificationScopes} from './NotificationsResource';

const routeNameScope: Record<NotificationScopes, string> = {
  [NotificationScopes.WALLETS]: 'Wallets',
  [NotificationScopes.LOANS]: 'Loans',
  [NotificationScopes.MULTIHODL]: 'HODLS',
  [NotificationScopes.MINER]: 'Miner',
  [NotificationScopes.TURBOCHARGE]: 'MoreTurbos',
};

// as any for now
export function getFullRouteNameFromNativeApp({
  index,
  routes,
}: {
  index: number;
  routes: any[];
}) {
  const route = routes[index];

  const {index: tabIndex, routes: tabs} = route.state ?? {};
  const tab = tabs?.[tabIndex ?? 0];

  const {index: tabChildrenIndex, routes: tabChildrenRoutes} = tab?.state ?? {};
  const tabChildren = tabChildrenRoutes?.[tabChildrenIndex ?? 0];

  return `${tab?.name ?? ''}${tabChildren?.name ?? ''}`;
}

export function getModalNotificationScope(
  routeName: string
): NotificationScopes | undefined {
  return Object.values(NotificationScopes).find(scope => {
    return routeName.includes(routeNameScope[scope]);
  });
}
