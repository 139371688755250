import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {Wallet} from '@youtoken/ui.resource-wallets';

export const getWalletComboboxItems = (walletsList: Wallet[]) => {
  return walletsList.map(w => ({
    ticker: w.ticker,
    tickerFormatted: w.tickerFormatted,
    amount: w.amount,
    amountFormatted: w.amount.toFixed(getCoinDecimalPrecision(w.ticker)),
    coinName: w.tickerName,
    hasAmount: w.amount?.gt(0),
    key: w.ticker,
  }));
};
