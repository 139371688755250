import * as React from 'react';
import {
  Loans,
  LoansListItemFeature,
  type LoansListItemFeatureArgs,
} from '@youtoken/ui.legacy-components';
import {Box, Text} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {RouterResource} from '../../../../../routes/RouterResource';
import {Link} from '@youtoken/ui.elements';
import {message} from '../../../../../../utils';
import {modal} from '@web-app/src/stores';
import {TRANSPORT} from '@youtoken/ui.transport';

const {Detailed} = Loans;

export const Content: React.FC = cell(() => {
  const {t} = useTranslation();

  const routerResource = RouterResource.use({});

  const loan = LoansListItemFeature.use({
    mode: 'turbo',
    id: routerResource.queryParams.id,
  });

  const handlePressSetClosePrice = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('setClosePrice', {loan});
    },
    [modal.open]
  );

  const handlePressReopen = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('reopenLoan', {loan});
    },
    [modal.open]
  );

  const handlePressIncrease = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('increaseLtv', {loan});
    },
    [modal.open]
  );

  const handlePressDecrease = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('decreaseLtv', {loan});
    },
    [modal.open]
  );

  const handlePressPay = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('loanPay', {loan});
    },
    [modal.open]
  );

  const handlePressCancel = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      const res = window.confirm(
        t('surface.loans.item.cancel_confirm_description')
      );

      if (!res) {
        return;
      }

      TRANSPORT.API.post('/v1/loan/cancel', {id: loan.id});
    },
    [t]
  );

  const handlePressCloseNow = React.useCallback(
    (loan: LoansListItemFeatureArgs) => {
      modal.open('closeNow', {loan});
    },
    [modal.open]
  );

  const handlePressCopy = React.useCallback(
    (_message: string) => {
      message.info(_message);
    },
    [message.info]
  );

  const handleBack = React.useCallback(() => {
    routerResource.push('/turbocharge');
  }, [routerResource.push]);

  return (
    <>
      <Box px={{default: 20, desktop: 0}} mb={15}>
        <Link onPress={handleBack}>
          <Box flexDirection="row" alignItems="center">
            <Icon name="chevron_left" color="$text-05" />
            <Text color="$interactive-01" variant="$body-01-medium-accent">
              {t('surface.loans.back')}
            </Text>
          </Box>
        </Link>
      </Box>
      {loan.id && (
        <Detailed
          mode={loan.mode}
          id={loan.id}
          onPressSetClosePrice={handlePressSetClosePrice}
          onPressReopen={handlePressReopen}
          onPressIncrease={handlePressIncrease}
          onPressDecrease={handlePressDecrease}
          onPressPay={handlePressPay}
          onPressCancel={handlePressCancel}
          onPressCloseNow={handlePressCloseNow}
          onPressCopy={handlePressCopy}
          borderRadius={5}
          borderWidth={1}
          borderColor="$ui-01"
        />
      )}
    </>
  );
});
