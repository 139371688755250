import {primitive, serializable} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {Big} from 'big.js';

export class CalculatedIncentivesDataResponse {
  @serializable(bigNumber())
  amount!: Big;

  @serializable(primitive())
  percent!: number;
}
