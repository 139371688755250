import * as React from 'react';
import {FlatList} from 'react-native';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {RefreshControl} from '@youtoken/ui.elements';
import {SavingsSelectAssetsFeature} from '../../Feature';
import {SavingsAssetsEmptyState} from '../../../../components';
import {SavingsSelectAssetsListTitle} from './Title';
import {SavingsSelectAssetsListAssetTouchable} from './AssetTouchable';
import type {ListItem, ListItemTitle, ListItemAsset} from '../../types';

export const SavingsSelectAssetsList: React.FC<BoxProps> = observer(
  boxProps => {
    const {isLoadingManually, refetchManually, items} =
      SavingsSelectAssetsFeature.use({});

    const renderItem = React.useCallback(
      ({item: {type, data}, index}: {item: ListItem; index: number}) => {
        if (type === 'title') {
          return (
            <SavingsSelectAssetsListTitle
              borderBottomWidth={1}
              borderColor="$ui-01"
            >
              {(data as ListItemTitle['data']).title}
            </SavingsSelectAssetsListTitle>
          );
        }

        if (type === 'asset') {
          return (
            <SavingsSelectAssetsListAssetTouchable
              ticker={(data as ListItemAsset['data']).ticker}
              borderBottomWidth={index < items.length - 1 ? 1 : 0}
              borderColor="$ui-01"
            />
          );
        }

        return null;
      },
      [items.length]
    );

    const keyExtractor = React.useCallback((item: ListItem, index: number) => {
      return `${item.type}-${index}`;
    }, []);

    return (
      <Box flex={1} {...boxProps}>
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={isLoadingManually}
              onRefresh={refetchManually}
            />
          }
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListEmptyComponent={SavingsAssetsEmptyState}
          style={{flex: 1}}
          contentContainerStyle={{minHeight: '100%'}}
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="handled"
        />
      </Box>
    );
  }
);
