import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const LanguageIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M3.333 4.167h5.834M7.5 2.5v1.667c0 3.681-1.866 6.666-4.167 6.666M4.167 7.5c-.003 1.787 2.46 3.257 5.583 3.333m.916 5.834L14 9.167l3.333 7.5M16.5 15h-5"
          strokeWidth={1.667}
          strokeLinecap="round"
          stroke={color}
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
);
