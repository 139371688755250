import * as React from 'react';
import {modal} from '@web-app/src/stores';
import {HodlHistoryBottomSheet} from '@youtoken/ui.surface-hodl-positions';

interface ModalExtendTpSlProps {
  id: string;
}

export const ModalHodlHistory: React.FC<ModalExtendTpSlProps> = ({id}) => {
  return <HodlHistoryBottomSheet onClose={modal.close} hodlId={id} />;
};
