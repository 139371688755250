import * as React from 'react';
import {observer} from 'mobx-react';
import {type TextProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface MinAmountProps {
  amountFormatted?: string;
}

export const MinAmount: React.FC<MinAmountProps & TextProps> = observer(
  ({amountFormatted, ...textProps}) => {
    const {t} = useTranslation();

    if (!amountFormatted) {
      return null;
    }

    return (
      <Text
        testID="DEPOSIT_NOW_INFO_MIN_AMOUNT"
        variant="$body-02"
        color="$text-02"
        {...textProps}
      >
        {t('surface.wallets.deposit_now.min_amount', {
          amount: amountFormatted,
        })}
      </Text>
    );
  }
);
