import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const StarIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.295 2.577a.6.6 0 01.228.228l2.199 3.902a.6.6 0 00.404.294l4.39.885a.6.6 0 01.323.995l-3.031 3.297a.6.6 0 00-.154.475l.514 4.449a.6.6 0 01-.846.614l-4.072-1.864a.6.6 0 00-.5 0l-4.072 1.864a.6.6 0 01-.846-.614l.514-4.45a.6.6 0 00-.154-.474L2.161 8.88a.6.6 0 01.323-.995L6.874 7a.6.6 0 00.404-.294l2.2-3.901a.6.6 0 01.817-.229zm-.197 2.784a.2.2 0 00-.232.026l-.04.05-1.606 2.85a.2.2 0 01-.08.078l-.055.02-3.206.646a.2.2 0 00-.143.278l.036.054 2.213 2.407a.2.2 0 01.05.101l.001.058-.375 3.248a.2.2 0 00.23.221l.052-.016 2.974-1.36a.2.2 0 01.111-.017l.055.016 2.974 1.361a.2.2 0 00.281-.15l.001-.054-.376-3.25a.2.2 0 01.02-.11l.032-.048 2.213-2.407a.2.2 0 00-.057-.314l-.05-.018-3.206-.647a.2.2 0 01-.1-.052l-.035-.046-1.606-2.849a.2.2 0 00-.076-.076z"
          fill={color}
        />
      </Svg>
    );
  }
);
