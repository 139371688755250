import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoansListItemFeatureArgs, LoansListItemFeature} from '../../../state';
import {ActionsAdditionalNew} from '../../Wallets/Groups/List';
import {DetailedActionsAdditionalsProps} from '..';

export interface BriefActionsProps extends BoxProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
  onChangeOpenedAdditionalActions?: (opened: boolean) => void;
  onPressPay?: (loan: LoansListItemFeatureArgs) => void;
  onPressCancel?: (loan: LoansListItemFeatureArgs) => void;
  onPressCloseNow?: DetailedActionsAdditionalsProps['onPressSetClosePrice'];
  onPressIncrease?: DetailedActionsAdditionalsProps['onPressIncrease'];
  onPressDecrease?: DetailedActionsAdditionalsProps['onPressDecrease'];
  onPressReopen?: DetailedActionsAdditionalsProps['onPressReopen'];
  onPressSetClosePrice?: DetailedActionsAdditionalsProps['onPressSetClosePrice'];
}

export const BriefActions: React.FC<BriefActionsProps> = observer(
  ({
    mode,
    id,
    onChangeOpenedAdditionalActions,
    onPressCloseNow,
    onPressIncrease,
    onPressDecrease,
    onPressSetClosePrice,
    onPressReopen,
    onPressPay,
    onPressCancel,
    ...rest
  }) => {
    const {t} = useTranslation();

    const styleButton = React.useMemo(() => {
      return {
        width: 20,
        height: 20,
      };
    }, []);

    const styleContent = React.useMemo(() => {
      return {
        width: 155,
      };
    }, []);

    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const handlePressPay = React.useCallback(() => {
      onPressPay?.({id: loan.id!, mode: loan.mode});
    }, [onPressPay, loan]);

    const handlePressCancel = React.useCallback(() => {
      onPressCancel?.({id: loan.id!, mode: loan.mode});
    }, [onPressCancel, loan]);

    const handlePressCloseNow = React.useCallback(() => {
      onPressCloseNow?.({id: loan.id!, mode: loan.mode});
    }, [onPressCloseNow, loan]);

    const handlePressIncrease = React.useCallback(() => {
      onPressIncrease?.({id: loan.id!, mode: loan.mode});
    }, [onPressIncrease, loan]);

    const handlePressDecrease = React.useCallback(() => {
      onPressDecrease?.({id: loan.id!, mode: loan.mode});
    }, [onPressDecrease, loan]);

    const handlePressReopen = React.useCallback(() => {
      onPressReopen?.({id: loan.id!, mode: loan.mode});
    }, [onPressReopen, loan]);

    const handlePressSetClosePrice = React.useCallback(() => {
      onPressSetClosePrice?.({id: loan.id!, mode: loan.mode});
    }, [onPressSetClosePrice, loan]);

    const handlePressQuickAction = React.useMemo(() => {
      return loan.isOpening ? handlePressCancel : handlePressPay;
    }, [loan, handlePressPay, handlePressCancel]);

    const items = React.useMemo(() => {
      let items = [];

      if (loan.enabledCloseNow) {
        items.push({
          icon: 'check',
          title: t('surface.loans.item.close_now'),
          onPress: handlePressCloseNow,
        });
      }

      if (loan.enabledIncrease) {
        items.push({
          icon: 'increase',
          title: t('surface.loans.item.increase'),
          onPress: handlePressIncrease,
        });
      }

      if (loan.enabledDecrease) {
        items.push({
          icon: 'extend',
          title: t('surface.loans.item.extend'),
          onPress: handlePressDecrease,
        });
      }

      if (loan.enabledReopen) {
        items.push({
          icon: 'reopen',
          title: t('surface.loans.item.reopen'),
          onPress: handlePressReopen,
        });
      }

      if (loan.enabledSetClosePrice) {
        items.push({
          icon: 'ftp',
          title: t('surface.loans.item.ftp'),
          onPress: handlePressSetClosePrice,
        });
      }

      return items;
    }, [
      t,
      loan.enabledCloseNow,
      loan.enabledIncrease,
      loan.enabledDecrease,
      loan.enabledReopen,
      loan.enabledSetClosePrice,
      handlePressCloseNow,
      handlePressIncrease,
      handlePressDecrease,
      handlePressReopen,
      handlePressSetClosePrice,
    ]);

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        {...rest}
      >
        <Button
          disabled={!loan.quickActionEnabled}
          onPress={handlePressQuickAction}
          flexShrink={0}
          width={200}
          ml={20}
          size="small"
        >
          {t(loan.quickActionText)}
        </Button>
        {items.length > 0 && (
          <ActionsAdditionalNew
            onChangeOpened={onChangeOpenedAdditionalActions}
            items={items}
            styleButton={styleButton}
            styleContent={styleContent}
            position="absolute"
            right={-20}
          />
        )}
      </Box>
    );
  }
);
