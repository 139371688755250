import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';
import {Content} from './components';

export const LegalInfoProfileSurface: React.FC = cell(() => {
  const {t} = useTranslation();

  return (
    <Box width="100%" alignContent="flex-start">
      <Heading variant="$heading-01" color="$text-01" mb={32}>
        {t('surface.profile.legal_info.title')}
      </Heading>
      <Content mb={-8} />
    </Box>
  );
});
