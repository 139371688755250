import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {ReferralsSmartProps} from './types';
import {ReferralsScreenV1} from './v1';
import {ReferralsSmartV3} from './v3';

export const ReferralsSmart: React.FC<ReferralsSmartProps> = observer(
  ({showShareRefCode = false, ...rest}) => {
    const {
      data: {rewardVersion},
      updateReferralViewedDateTime,
    } = AuthMeResource.use({});

    React.useEffect(() => {
      updateReferralViewedDateTime();
    }, []);

    if (rewardVersion === 3) {
      return <ReferralsSmartV3 showShareRefCode={showShareRefCode} {...rest} />;
    }

    return <ReferralsScreenV1 showShareRefCode={showShareRefCode} {...rest} />;
  }
);
