import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, TouchableBox, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Combobox} from '@youtoken/ui.combobox';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {CryptoDepositLedgerFormState} from '../state';

export const CryptoDepositLedgerFormFee: React.FC = observer(() => {
  const {
    form: {feeLabel, fee, fees, feeHasError, feeError, feeOnChange},
  } = useForm<CryptoDepositLedgerFormState>();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = React.useCallback(() => {
    setIsOpen(value => !value);
  }, []);

  return (
    <Box mt={24}>
      <Label mb={8}>{feeLabel}</Label>
      {isOpen ? (
        <Combobox
          initialIsOpen={true}
          value={fee}
          items={fees}
          hasError={feeHasError}
          onChange={feeOnChange}
          onClose={handleToggle}
        />
      ) : (
        <FeeSelected onPress={handleToggle} />
      )}

      <FieldErrorMessage visible={feeHasError}>{feeError}</FieldErrorMessage>
    </Box>
  );
});

export interface FeeSelectedProps {
  onPress: () => void;
}

export const FeeSelected: React.FC<FeeSelectedProps> = observer(({onPress}) => {
  const {
    form: {fee, feeHasError},
  } = useForm<CryptoDepositLedgerFormState>();

  return (
    <TouchableBox
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      height={48}
      borderRadius={8}
      borderWidth={1}
      borderColor={feeHasError ? '$danger-03' : '$ui-01'}
      px={16}
      onPress={onPress}
    >
      <Text ml={8} mr={2} variant="$body-01-medium-accent" color="$text-01">
        {fee}
      </Text>
      <Icon name="expand" color="$text-01"></Icon>
    </TouchableBox>
  );
});
