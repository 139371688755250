import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const MoneyIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25 2.758a3.763 3.763 0 00-1.227 7.21l3.363 1.442a2.262 2.262 0 011.364 2.077 2.261 2.261 0 01-2.25 2.263H10a2.75 2.75 0 01-2.705-2.252c-.05-.272-.269-.498-.545-.498h-.5c-.276 0-.503.225-.471.5a4.253 4.253 0 003.471 3.684V19a.5.5 0 00.5.5h.5a.5.5 0 00.5-.5v-1.758a3.763 3.763 0 001.227-7.21L8.614 8.59A2.262 2.262 0 017.25 6.513 2.261 2.261 0 019.5 4.25h.5a2.75 2.75 0 012.705 2.253c.05.271.269.497.545.497h.5c.276 0 .503-.225.471-.5a4.253 4.253 0 00-3.471-3.684V1a.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5v1.758z"
          fill={color}
        />
      </Svg>
    );
  }
);
