import {observer} from 'mobx-react';
import React from 'react';
import {modal} from '@web-app/src/stores';

// NOTE
// Этот контекст создан чтобы хук useFocusEffect мог работать без observer
export const ModalOpenedContext = React.createContext(false);

export const ModalOpenedContextProvider: React.FC = observer(({children}) => {
  const value = Boolean(modal.opened);

  return (
    <ModalOpenedContext.Provider value={value}>
      {children}
    </ModalOpenedContext.Provider>
  );
});
