import {computed} from 'mobx';
import {getResourceDescriptor, createFeature} from '@youtoken/ui.data-storage';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {
  LoanItemRegularResource,
  LoanItemTurboResource,
} from '@youtoken/ui.resource-loans';
import {formatByTickerLoan} from '@youtoken/ui.formatting-utils';

export interface LoanRepayFeatureArgs {
  mode: 'regular' | 'turbo';
  id: string;
}

const getKey = (args: LoanRepayFeatureArgs) => {
  return `loanRepay:${JSON.stringify(args)}`;
};

const getResources = (args: LoanRepayFeatureArgs) => {
  return {
    rates: getResourceDescriptor(RatesResource, {}),
    authMe: getResourceDescriptor(AuthMeResource, {}),
    wallets: getResourceDescriptor(WalletsResource, {}),
    loan: getResourceDescriptor(
      args.mode === 'regular' ? LoanItemRegularResource : LoanItemTurboResource,
      {id: args.id}
    ),
  };
};

export class LoanRepayFeature extends createFeature({
  getKey,
  getResources,
}) {
  @computed
  public get mode() {
    return this.args.mode;
  }

  @computed
  public get data() {
    return this.resources.loan.data;
  }

  @computed
  public get version() {
    return this.data?.version;
  }

  @computed
  public get id() {
    return this.data?.id;
  }

  @computed
  public get borrowedTicker() {
    return this.data?.borrowedTicker;
  }

  @computed
  public get borrowedAmount() {
    return formatByTickerLoan(this.data.borrowedAmount, this.borrowedTicker);
  }

  @computed
  public get overdraftAmount() {
    return formatByTickerLoan(this.data.overdraftAmount, this.borrowedTicker);
  }

  @computed
  public get allowAdvCashPayment() {
    return (
      this.resources.authMe.products.depositFiatAdvcash.isEnabled &&
      ['eur', 'usd'].includes(this.borrowedTicker)
    );
  }
}
