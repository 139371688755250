import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SparkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M8.58 18a.5.5 0 01-.494-.582l.806-4.836A.5.5 0 008.4 12H5.49c-.25 0-.404-.067-.463-.2-.058-.133-.02-.317.113-.55l5.703-9.017A.5.5 0 0111.265 2h.134a.5.5 0 01.493.582l-.806 4.836A.5.5 0 0011.58 8h2.91c.25 0 .403.067.461.2.059.133.022.317-.112.55l-5.702 9.017a.5.5 0 01-.423.233H8.58z"
          fill={color}
        />
      </Svg>
    );
  }
);
