import * as React from 'react';
import {ScrollView} from 'react-native';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {type BoxProps, useIsMobile, Box} from '@youtoken/ui.primitives';
import {
  AllInstrumentsHeader,
  InstrumentsListSmart,
  SearchField,
  InstrumentsSortingTabs,
  type searchMode,
  InstrumentsFilters,
} from './components';

export type AllInstrumentsSectionProps = {
  onPressInstrument?: (id: string) => void;
  onSearchClick?: () => void;
  separateInstrumentsSection?: boolean;
  disableFilters?: boolean;
  isStarDisabled?: boolean;
};

export const AllInstrumentsSection: React.FC<
  AllInstrumentsSectionProps & BoxProps
> = React.memo(
  ({
    onPressInstrument,
    onSearchClick,
    separateInstrumentsSection,
    disableFilters = false,
    ...boxProps
  }) => {
    const isMobile = useIsMobile();

    const [mode, setMode] = React.useState<searchMode>('icon');
    const showHeader = !(mode === 'full' && isMobile);

    const handleSetFullMode = React.useCallback(() => {
      DATA_LAYER.trackStrict('hodl-instruments-search', {});
      setMode('full');
    }, [setMode]);

    const handleSetIconMode = React.useCallback(() => {
      setMode('icon');
    }, [setMode]);

    return (
      <Box pb={10} {...boxProps}>
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          pt={24}
          pb={16}
          px={{
            desktop: separateInstrumentsSection ? 24 : 0,
            default: 20,
          }}
        >
          {showHeader && <AllInstrumentsHeader />}
          <SearchField
            mode={mode}
            onSetFullMode={handleSetFullMode}
            onSetIconMode={handleSetIconMode}
            width={!showHeader ? '100%' : undefined}
          />
        </Box>

        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <Box
            flexDirection="row"
            alignItems="center"
            pb={16}
            px={{
              desktop: separateInstrumentsSection ? 20 : 0,
              default: 20,
            }}
          >
            {!disableFilters && <InstrumentsFilters />}
            <InstrumentsSortingTabs ml={disableFilters ? 0 : 4} />
          </Box>
        </ScrollView>

        <InstrumentsListSmart
          separateInstrumentsSection={separateInstrumentsSection}
        />
      </Box>
    );
  }
);
