import {Text as NativeText} from 'react-native';
import {createText} from './createText';
import {type TextProps} from './createRestyledText';
import {TextStyleContext, useMergedTextStyle} from './TextStyleContext';

export {createText};
export {TextStyleContext};
export {useMergedTextStyle};
export type {TextProps};

export const Text = createText(NativeText);
