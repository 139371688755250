import {comparer, computed} from 'mobx';
import type {WalletComboboxItem} from '@youtoken/ui.ticker-and-amount-input';
import {walletToComboboxItem} from './utils';
import {ViewBase} from '../ViewBase';
import {Form as FormV1} from './v1/Form';
import {Form as FormV2} from './v2/Form';

export class View extends ViewBase<FormV1 | FormV2> {
  //#region wallets

  @computed({
    equals: comparer.shallow,
  })
  get tickersShowed() {
    return this.form.tickers.filter(ticker => {
      return this.form.resources.authMeResource.showedWalletTickers.includes(
        ticker
      );
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get tickersHidden() {
    return this.form.tickers.filter(ticker => {
      return this.form.resources.authMeResource.hiddenWalletTickers.includes(
        ticker
      );
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get wallets() {
    const res: {
      name: string;
      values: WalletComboboxItem[];
    }[] = [];

    const portfolioItems = this.tickersShowed.map((ticker: string) => {
      return walletToComboboxItem(
        this.form.resources.walletsResource.getByTicker(ticker)!
      );
    });
    const hiddenItems = this.tickersHidden.map((ticker: string) => {
      return walletToComboboxItem(
        this.form.resources.walletsResource.getByTicker(ticker)!
      );
    });

    if (portfolioItems.length > 0) {
      res.push({
        name: 'Portfolio',
        values: portfolioItems,
      });
    }

    if (hiddenItems.length > 0) {
      res.push({
        name: 'Hidden',
        values: hiddenItems,
      });
    }

    return res;
  }

  //#endregion wallets

  //#region conversionWallets

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickersShowed() {
    return this.form.conversionTickers.filter(ticker => {
      return this.form.resources.authMeResource.showedWalletTickers.includes(
        ticker
      );
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickersHidden() {
    return this.form.conversionTickers.filter(ticker => {
      return this.form.resources.authMeResource.hiddenWalletTickers.includes(
        ticker
      );
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickersAdded() {
    return [...this.conversionTickersShowed, ...this.conversionTickersHidden];
  }

  @computed({
    equals: comparer.shallow,
  })
  get conversionTickersOther() {
    return this.form.conversionTickers.filter(ticker => {
      return !this.conversionTickersAdded.includes(ticker);
    });
  }

  @computed({
    equals: comparer.shallow,
  })
  get conversionWallets() {
    const res: {
      name: string;
      values: WalletComboboxItem[];
    }[] = [];

    const portfolioItems = this.conversionTickersShowed.map(ticker => {
      return walletToComboboxItem(
        this.form.resources.walletsResource.getByTicker(ticker)!
      );
    });
    const hiddenItems = this.conversionTickersHidden.map((ticker: string) => {
      return walletToComboboxItem(
        this.form.resources.walletsResource.getByTicker(ticker)!
      );
    });
    const otherItems = this.conversionTickersOther.map((ticker: string) => {
      return walletToComboboxItem(
        this.form.resources.walletsResource.getByTicker(ticker)!
      );
    });

    if (portfolioItems.length > 0) {
      res.push({
        name: 'Portfolio',
        values: portfolioItems,
      });
    }

    if (hiddenItems.length > 0) {
      res.push({
        name: 'Hidden',
        values: hiddenItems,
      });
    }

    if (otherItems.length > 0) {
      res.push({
        name: 'Other',
        values: otherItems,
      });
    }

    return res;
  }

  //#endregion conversionWallets

  public constructor(form: FormV1 | FormV2) {
    super(form);
  }
}
