import React from 'react';
import {observer} from 'mobx-react';
import {TopLevelWrap} from '../Primitives/TopLevelWrap';
import {BaseChartState} from '../logic';

export const Wrap: React.FC<{chartState: BaseChartState}> = React.memo(
  observer(({children, chartState}) => {
    return (
      <TopLevelWrap width={chartState.width} height={chartState.height}>
        {children}
      </TopLevelWrap>
    );
  })
);
