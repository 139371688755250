import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  type UmaInvoiceRequestArgs,
  UmaInvoiceResource,
} from '@youtoken/ui.resources-uma-invoice';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {cell} from '@youtoken/ui.cell';
import {InvoiceHeader, RateTimer, TFAComponent} from './components';
import {UmaInvoiceConfirmationForm} from './state';
import {InfoTableSection} from './sections';

interface UmaInvoiceConfirmationSurfaceProps extends UmaInvoiceRequestArgs {
  onRateTimerEnd: () => void;
}

export const UmaInvoiceConfirmationSurface: React.FC<UmaInvoiceConfirmationSurfaceProps> =
  cell(
    ({
      uma,
      ticker,
      toTicker,
      amount,
      requestUuid,
      onRateTimerEnd,
      invoiceRequestId,
    }) => {
      const {t} = useTranslation();

      const resources = useResources({
        authMe: getResourceDescriptor(AuthMeResource, {}),
        umaInvoiceResource: getResourceDescriptor(UmaInvoiceResource, {
          uma,
          amount,
          ticker,
          toTicker,
          requestUuid,
          invoiceRequestId,
        }),
      });

      const formState = makeForm(
        () =>
          new UmaInvoiceConfirmationForm({ticker, onRateTimerEnd}, resources)
      );

      const {
        form: {
          instance: {submitting},
          onSubmit,
        },
      } = formState;

      const handleSubmit = React.useCallback(() => {
        DATA_LAYER.trackStrict('withdraw-uma-send-attempt', {});
        onSubmit();
      }, [onSubmit]);

      return (
        <Form form={formState}>
          <Box p={24} flexGrow={1}>
            <InvoiceHeader />
            <InfoTableSection />
            <RateTimer />
          </Box>

          <TFAComponent />

          <Box p={24} pb={24}>
            <Button
              size="large"
              disabled={submitting}
              loading={submitting}
              onPress={handleSubmit}
              testID="UMA_INVOICE_SUBMIT_BUTTON"
            >
              {t('surface.wallets.uma.withdrawal.send_now_button')}
            </Button>
          </Box>
        </Form>
      );
    }
  );
