import * as React from 'react';
import {Route} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {WalletsRoutes} from './WalletsRoutes';
import {LoansRoutes} from './LoansRoutes';
import {MultihodlRoutes} from './MultihodlRoutes';
import {MinerRoutes} from './MinerRoutes';
import {TurbochargeRoutes} from './TurbochargeRoutes';
import {AccountStatsRoutes} from './AccountStatsRoutes';
import {ProfileRoutes} from './ProfileRoutes';
import {WidgetsRoutes} from './WidgetsRoutes';
import {HistoryRoutes} from './HistoryRoutes';
import {authRequired} from './authRequired';

const ProfileRoutesWrapped = authRequired(ProfileRoutes);

export const AppWebRoutes: React.FC = () => {
  return (
    <SwitchWithNotFound>
      <Route path="/wallets" component={WalletsRoutes} />
      <Route path="/loans" component={LoansRoutes} />
      <Route path="/multihodl" component={MultihodlRoutes} />
      <Route path="/miner" component={MinerRoutes} />
      <Route path="/turbocharge" component={TurbochargeRoutes} />
      <Route path="/account-stats" component={AccountStatsRoutes} />
      <Route path="/profile" component={ProfileRoutesWrapped} />
      <Route path="/widgets" component={WidgetsRoutes} />

      {/* A redirect to account-stats for backward compatability */}
      <Route path="/history" component={HistoryRoutes} />
    </SwitchWithNotFound>
  );
};
