import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type TextProps, Text} from '@youtoken/ui.primitives';

export interface AccountLevelSavingsMaxAssetsProps {
  count: number;
}

export const AccountLevelSavingsMaxAssets: React.FC<
  AccountLevelSavingsMaxAssetsProps & TextProps
> = observer(({count, ...textProps}) => {
  const {t} = useTranslation();

  return (
    <Text
      testID="ACCOUNT_LEVEL_SAVINGS_MAX_ASSETS"
      variant="$body-02"
      color="$text-02"
      textAlign="right"
      {...textProps}
    >
      <Trans
        t={t}
        components={{
          Strong: <Text color="$text-01" />,
        }}
        i18nKey="surface.loyalty.savings.max_assets"
        values={{count}}
      />
    </Text>
  );
});
