import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const OsAndroidIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.386.751l-.807 1.496A5.012 5.012 0 0114.523 4c.484.75.727 1.57.727 2.46H4.739c0-.89.242-1.71.727-2.46a4.99 4.99 0 011.955-1.753L6.614.75C6.56.652 6.579.577 6.67.523c.099-.046.175-.023.228.068L7.716 2.1A5.56 5.56 0 0110 1.62c.803 0 1.564.16 2.284.48l.818-1.51c.053-.09.129-.114.228-.068.09.053.11.13.056.228zM7.602 4.554a.425.425 0 00.313-.132.431.431 0 00.13-.314.43.43 0 00-.13-.314.426.426 0 00-.313-.13.44.44 0 00-.432.445c0 .121.042.226.125.313a.406.406 0 00.307.132zm4.796 0c.12 0 .223-.044.306-.132a.439.439 0 00.125-.314.44.44 0 00-.431-.445.426.426 0 00-.313.131.43.43 0 00-.13.314c0 .122.043.227.13.314a.425.425 0 00.313.132z"
          fill={color}
        />
        <Path
          d="M2.34 6.997c.228-.228.505-.343.83-.343.319 0 .591.115.819.343.227.228.34.502.34.822v4.91c0 .328-.111.605-.335.833a1.109 1.109 0 01-.824.343c-.325 0-.602-.114-.83-.343A1.137 1.137 0 012 12.73V7.82c0-.32.114-.594.34-.822zm2.444 7.479c0 .35.12.647.363.89.243.244.538.366.887.366h.84l.012 2.592c0 .327.114.605.34.833.228.229.501.343.819.343.326 0 .602-.114.83-.343.227-.228.34-.506.34-.833v-2.592h1.569v2.592c0 .327.113.605.34.833.228.229.504.343.83.343.325 0 .602-.114.83-.343.227-.228.34-.506.34-.833v-2.592h.852c.341 0 .633-.122.876-.365.242-.244.363-.54.363-.891V6.871H4.784v7.605zm11.228-7.485c.227-.224.5-.337.818-.337.325 0 .602.113.83.337.226.225.34.5.34.828v4.91c0 .328-.114.605-.34.833a1.126 1.126 0 01-.83.343 1.11 1.11 0 01-.818-.343 1.136 1.136 0 01-.341-.833v-4.91c0-.327.113-.603.34-.828z"
          fill={color}
        />
      </Svg>
    );
  }
);
