import * as React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {getRecaptchaKey} from '../getKey';
// import {RecaptchaMethods} from '../../utils/recaptchaMethods';
import './Modal';

export const RecaptchaV2Component = React.forwardRef<{
  requestToken: (action: string) => Promise<string>;
}>((_props, ref) => {
  React.useImperativeHandle(
    ref,
    () => ({
      requestToken: (action: string) => {
        return new Promise<string>((resolve, reject) => {
          NiceModal.show('recaptcha-v2-modal', {
            siteKey: getRecaptchaKey(ENVIRONMENT.APP_ENV),
            onSuccess: resolve,
            onError: reject,
          });
        });
      },
    }),
    []
  );

  return <></>;
});
