import {invariant} from '@youtoken/ui.utils';
import EventEmitter from 'eventemitter3';

interface Handlers {
  openSupportChat: (message?: string) => void;
  reloadApp: () => void;
}

export class SupportService {
  public events = new EventEmitter<
    'provideHandlers' | 'openSupportChat' | 'reloadApp'
  >();

  constructor() {}

  private _handlers?: Handlers = undefined;

  public provideHandlers = (handlers: Handlers) => {
    this.events.emit('provideHandlers', handlers);
    this._handlers = handlers;
  };

  public openSupportChat = (message?: string) => {
    this.events.emit('openSupportChat');

    invariant(this._handlers, 'handlers was not provided for SupportService');

    this._handlers.openSupportChat(message);
  };

  public reloadApp = () => {
    this.events.emit('reloadApp');

    invariant(this._handlers, 'handlers was not provided for SupportService');

    this._handlers.reloadApp();
  };
}
