import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const InterestIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <G fill={color}>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 5.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM8.5 12a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"
          />
          <Path d="M33.06 6.94a1.5 1.5 0 010 2.12l-24 24a1.5 1.5 0 01-2.12-2.12l24-24a1.5 1.5 0 012.12 0z" />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.5 28a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm6.5-3.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7z"
          />
        </G>
      </Svg>
    );
  }
);
