// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {deserialize} from 'serializr';
import axios, {Canceler} from 'axios';
import {debounce} from 'lodash';
import {nanoid} from 'nanoid';
import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
  when,
} from 'mobx';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {handleFormFieldsErrors} from '@youtoken/ui.form-utils';
import {toBig} from '@youtoken/ui.formatting-utils';
import {TRANSPORT} from '@youtoken/ui.transport';
import * as yupPackage from '@youtoken/ui.yup';
import {invariant} from '@youtoken/ui.utils';
import {CheckRecipientResponse} from './CheckRecipientResponse';
import {type UmaWithdrawalFormArgs} from '../types';
import {umaValidator} from '../../../utils';

const CANCEL_MESSAGE = '__CANCELLED_REQUEST__';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  private args: UmaWithdrawalFormArgs;

  @observable
  public isUmaValid: boolean = false;

  @observable
  public isUmaChecking: boolean = false;

  @observable
  public checkCanceller: Canceler | undefined;

  @observable
  public recipientData: null | CheckRecipientResponse = null;

  disposers: Array<IReactionDisposer> = [];

  public constructor(args: UmaWithdrawalFormArgs) {
    this.args = args;

    this.instance = new MobxReactForm(
      {
        fields: {
          uma: {value: args.uma ?? ''},
          ticker: {value: args.ticker},
          sendAmount: {value: ''},
          getAmount: {value: ''},
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                const schema = {
                  uma: yup
                    .string()
                    .required()
                    .test((umaValue?: string) => {
                      return umaValidator(umaValue ?? '');
                    }),
                  ticker: yup.string().required(),
                  sendAmount: yup.big().required().gt(0),
                  getAmount: yup.big().required().gt(0),
                };

                return yup.object().shape(schema);
              }),
          }),
        },
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            this.isUmaChecking = true;
            const values = form.values();

            invariant(this.recipientData, 'Recipient data is not defined');

            // NOTE: Check validation for users invoice
            return TRANSPORT.API.post('/v1/uma/invoice/validate', {
              uma: `$${values.uma}`,
              toTicker: this.recipientData.receiver.ticker,
              fromTicker: values.ticker,
              amount: values.sendAmount,
              invoiceRequestId: this.recipientData.invoiceRequestId,
            })
              .then(() => {
                args.onSuccess({
                  uma: values.uma,
                  ticker: values.ticker,
                  toTicker: this.recipientData!.receiver.ticker,
                  amount: values.sendAmount,
                  invoiceRequestId: this.recipientData!.invoiceRequestId,
                  requestUuid: nanoid(),
                });
              })
              .catch(error => {
                handleFormFieldsErrors(this.instance, error);
              })
              .finally(() => {
                this.isUmaChecking = false;
              });
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: true,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
          validationDebounceWait: 700,
        },
      }
    );

    this.disposers.push(
      reaction(
        () => this.uma,
        debounce(value => {
          this.isUmaValid = false;

          // NOTE: FE validation
          if (umaValidator(value) !== true) {
            return;
          }

          // NOTE: Set up new cancel token
          this.isUmaChecking = true;
          const {token, cancel} = axios.CancelToken.source();
          this.checkCanceller = cancel;

          // NOTE: BE Validation
          TRANSPORT.API.get('/v1/uma/recipient', {
            params: {
              uma: `$${value}`,
              ticker: this.ticker,
            },
            cancelToken: token,
          })
            .then(res => {
              this.recipientData = deserialize(
                CheckRecipientResponse,
                res.data
              );
              this.isUmaValid = true;
            })
            .catch(error => {
              if (axios.isCancel(error)) {
                return;
              }

              handleFormFieldsErrors(this.instance, error);
              this.isUmaValid = false;
            })
            .finally(() => {
              this.isUmaChecking = false;
            });
        }, 700),
        // NOTE: fireImmediately is needed for case of returning from una-invoice form
        {
          fireImmediately: true,
        }
      ),
      when(
        () =>
          Boolean(this.recipientData) &&
          Boolean(this.args.amount) &&
          !this.sendAmount,
        () => {
          this.setSendAmount(this.args.amount ?? '');
        }
      )
    );
  }

  @computed
  public get uma() {
    return this.instance.$('uma').value;
  }

  @computed
  public get ticker() {
    return this.instance.$('ticker').value;
  }

  @computed
  public get sendAmount() {
    return this.instance.$('sendAmount').value;
  }

  @computed
  public get sendAmountPrecision() {
    return getCoinDecimalPrecision(this.ticker);
  }

  @computed
  public get getAmount() {
    return this.instance.$('getAmount').value;
  }

  @computed
  public get getAmountPrecision() {
    return getCoinDecimalPrecision(this.recipientData?.receiver?.ticker);
  }

  @action public setTicker = (value: string) => {
    this.instance.$('ticker').set('value', value);
  };

  @action public setUma = (value: string) => {
    // NOTE: Canceling of the previous request and change validation state
    this.checkCanceller?.(CANCEL_MESSAGE);

    // NOTE: Validate uma field to hide error message during changing
    this.instance.$('uma').validate();

    this.instance.$('uma').set('value', value);
  };

  @action public setSendAmount = (value: string) => {
    const normalizedValue = normalizeAmountWithPrecision(
      value,
      this.sendAmountPrecision
    );

    this.instance.$('sendAmount').set('value', normalizedValue);

    const bigAmount = toBig(normalizedValue);

    if (bigAmount.eq(0)) {
      return;
    }

    try {
      invariant(this.recipientData, 'Recipient data is not defined');
      const calculatedGetAmount = bigAmount.times(this.recipientData.rate);

      if (calculatedGetAmount.lt(0)) {
        this.instance.$('getAmount').set('0');
        return;
      }

      this.instance
        .$('getAmount')
        .set(
          normalizeAmountWithPrecision(
            calculatedGetAmount.toString(),
            this.getAmountPrecision
          )
        );
    } catch (e) {
      throw Error(
        `Error while calculating get amount. amount: ${value}, rate: ${this.recipientData?.rate}; error: ${e}`
      );
    }
  };

  @action public setGetAmount = (value: string) => {
    const normalizedValue = normalizeAmountWithPrecision(
      value,
      this.getAmountPrecision
    );

    this.instance.$('getAmount').set('value', normalizedValue);

    const bigAmount = toBig(normalizedValue);

    if (bigAmount.eq(0)) {
      return;
    }

    try {
      invariant(this.recipientData, 'Recipient data is not defined');
      const calculatedSendAmount = bigAmount.div(this.recipientData.rate);

      if (calculatedSendAmount.lt(0)) {
        this.instance.$('sendAmount').set('0');
        return;
      }

      this.instance
        .$('sendAmount')
        .set(
          normalizeAmountWithPrecision(
            calculatedSendAmount.toString(),
            this.sendAmountPrecision
          )
        );
    } catch (e) {
      throw Error(
        `Error while calculating send amount. amount: ${value}, rate: ${this.recipientData?.rate}; error: ${e}`
      );
    }
  };

  @action
  public submitForm = () => {
    this.instance.submit();
  };

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };
}
