import * as React from 'react';
import type {ListRenderItem} from 'react-native';
import {observer} from 'mobx-react';
import {RewardsHistoryItemV3} from '@youtoken/ui.resource-rewards-v3-history';
import {Separator} from '@youtoken/ui.elements';
import type {RewardsHistoryProps} from '../types';
import {keyExtractor} from '../utils';
import {BottomSheetFlatList, RewardsHistoryTitle} from '../..';
import {RewardsHistoryItemV3Item} from '../Item';

const renderItem: ListRenderItem<RewardsHistoryItemV3> = ({item}) => {
  return <RewardsHistoryItemV3Item data={item} />;
};

export interface RewardsHistoryFlatListV3Props extends RewardsHistoryProps {
  items: RewardsHistoryItemV3[];
}

export const RewardsHistoryFlatListV3: React.FC<RewardsHistoryFlatListV3Props> =
  observer(({showHeader, items}) => {
    return (
      <BottomSheetFlatList
        // @ts-ignore
        ListHeaderComponent={showHeader && RewardsHistoryTitle}
        ItemSeparatorComponent={Separator}
        style={{flex: 1}}
        data={items}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    );
  });
