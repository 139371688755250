import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {priceFormatterInThousands} from '@youtoken/ui.formatting-utils';
import {LoyaltyProgressBarPoint} from '@youtoken/ui.resource-loyalty';
import {SavingsProgressBar} from '@youtoken/ui.progress-bar';
import {MarkIcon} from './MarkIcon';
import {Mark} from './Mark';
import {MarkLabel} from './MarkLabel';

/**
 * value: Number from 0 to 1
 */
export type LoyaltyProgressBarProps = {
  points: LoyaltyProgressBarPoint[];
  value: number;
  valueText: string;
};

export const LoyaltyProgressBar: React.FC<LoyaltyProgressBarProps> = ({
  points,
  value,
  valueText,
}) => {
  return (
    <Box testID="LOYALTY_PROGRESS_BAR" position="relative" mx={12}>
      <Box py={38}>
        <SavingsProgressBar size="medium" value={value} valueText={valueText} />
      </Box>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        position="absolute"
        left={0}
        right={0}
      >
        {points.map(
          (
            {requiredVolume, name, iconName, iconColor, iconColorBorder},
            index
          ) => {
            const isFirst = index === 0;
            const isPreLast = index === points.length - 2;
            const isLast = index === points.length - 1;

            const labelPosition = isFirst
              ? 'flex-start'
              : isLast || (isPreLast && points.length > 3)
              ? 'flex-end'
              : 'center';
            const label = isFirst || isPreLast || isLast ? name : undefined;

            return (
              <Box key={index} position="relative">
                <Box
                  height={38}
                  alignItems="center"
                  justifyContent="center"
                  pt={30}
                >
                  <MarkIcon
                    iconName={iconName}
                    iconColor={iconColor}
                    iconColorBorder={iconColorBorder}
                  />
                  <Mark />
                </Box>
                <Box height={20} my={2} />
                <Box height={38} alignItems="center" pb={30}>
                  <Mark />
                  <MarkLabel
                    labelPosition={labelPosition}
                    label={`$${priceFormatterInThousands(requiredVolume)}`}
                    secondaryLabel={label}
                  />
                </Box>
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
};
