import * as React from 'react';
import {ScrollView, Platform} from 'react-native';
import {observer} from 'mobx-react';
import {stringify} from 'query-string';
import {invariant, openBrowserAsync} from '@youtoken/ui.utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {LogoColored} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {Header} from '../Header';
import {type AdvCashWizardNavigator, AdvCashWizardScreenName} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const AdvCashPayment: React.FC<BoxProps> = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {ticker, payment},
    },
    goBack,
    close,
  } = useWizard<AdvCashWizardNavigator, AdvCashWizardScreenName.PAYMENT>();

  invariant(payment, "Payment data wasn't provided", {}, {payment});

  const {ac_currency, ac_amount} = payment;

  const webUrl = `/wallets/${ticker}`;

  const {successUrl, failUrl} = Platform.select({
    web: {
      successUrl: webUrl,
      failUrl: webUrl,
    },
    native: {
      successUrl: `/deposit/success`,
      failUrl: `/deposit/fail`,
    },
  })!;

  const paymentData = {
    ...payment,
    ac_success_url_method: 'GET',
    ac_fail_url_method: 'GET',
    ac_success_url: ENVIRONMENT.WEB_APP_URL + successUrl,
    ac_fail_url: ENVIRONMENT.WEB_APP_URL + failUrl,
  };

  const handlePress = React.useCallback(() => {
    openBrowserAsync(
      `https://wallet.advcash.com/sci/?${stringify(paymentData)}`
    ).then(() => close());
  }, [paymentData, close]);

  return (
    <Box flex={1}>
      {showHeader && <Header ticker={ac_currency} onPressBack={goBack} />}
      <ScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flexGrow: 1,
        }}
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <Box flex={1} p={{default: 16, phone: 24}}>
          <Box
            justifyContent="center"
            alignItems="center"
            flex={1}
            minHeight={170}
          >
            <LogoColored name="advcash" size={56} />
            <Text
              variant="$heading-01"
              weight="regular"
              color="$text-02"
              mt={8}
            >
              <Text weight="bold" color="$text-01">
                {ac_amount}
              </Text>{' '}
              {ac_currency}
            </Text>
          </Box>
          <Box>
            <Text variant="$body-02" color="$text-02">
              {t('surface.wallets.fiat_deposit.adv_cash.payment.info')}
            </Text>
          </Box>
        </Box>
      </ScrollView>
      <Separator />
      <Box p={{default: 16, phone: 24}}>
        <Button size="large" onPress={handlePress}>
          {t('surface.wallets.fiat_deposit.adv_cash.payment.submit')}
        </Button>
      </Box>
    </Box>
  );
});
