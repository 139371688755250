import {observable, action} from 'mobx';
import {Form} from './Form';
import {type SignInFormArgs, type SignInFormResources} from './types';

export class SignInForm {
  @observable
  public form: Form;

  @action
  public submit = () => {
    return this.form.instance.submit();
  };

  constructor(args: SignInFormArgs, resources: SignInFormResources) {
    this.form = new Form(args, resources);
  }
}
