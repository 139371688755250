import {iconSet, type IconName} from '../__generated/IconSmall';
import {createIconComponent} from '../createIcon';

export {iconSet};
export type {IconName};

export const Icon = createIconComponent<IconName, typeof iconSet>({
  componentName: 'Icon',
  iconSet: iconSet,
  defaultSize: 12,
});
