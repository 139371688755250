import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RewardsHistorySmartProps} from '../types';
import {RewardsHistoryViewV1Smart} from './v1';
import {RewardsHistoryViewV3Smart} from './v3';

export const RewardsHistoryViewSmart: React.FC<RewardsHistorySmartProps> =
  observer(({showHeader = false, limit = 3}) => {
    const {
      data: {rewardVersion},
    } = AuthMeResource.use({});

    if (rewardVersion === 3) {
      return (
        <RewardsHistoryViewV3Smart showHeader={showHeader} limit={limit} />
      );
    }

    return <RewardsHistoryViewV1Smart showHeader={showHeader} limit={limit} />;
  });
