import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

interface ActionButtonsProps extends BoxProps {
  baseTicker: string;
  quoteTicker: string;
  itIsIndex?: boolean;
  onPressAction?: () => void;
}

export const ActionButtons: React.FC<ActionButtonsProps> = observer(
  ({baseTicker, quoteTicker, itIsIndex, onPressAction, ...boxProps}) => {
    const {getAvailableInstrumentTariffs} = HODLsTariffsFeature.use({});

    const {sellTariffAvailable, buyTariffAvailable} =
      getAvailableInstrumentTariffs(baseTicker, quoteTicker);

    const handlePressSell = React.useCallback(() => {
      onPressAction?.();
      SHARED_ROUTER_SERVICE.navigate(
        'NewMultiHODL',
        {},
        {
          direction: 'sell',
          baseTicker,
          quoteTicker,
        }
      );
    }, [baseTicker, quoteTicker, onPressAction]);

    const handlePressBuy = React.useCallback(() => {
      onPressAction?.();
      SHARED_ROUTER_SERVICE.navigate(
        'NewMultiHODL',
        {},
        {
          direction: 'buy',
          baseTicker,
          quoteTicker,
        }
      );
    }, [baseTicker, quoteTicker, onPressAction]);

    if (itIsIndex) {
      return <Box width={72} {...boxProps} />;
    }

    return (
      <Box flexDirection="row" alignItems="center" py={16} {...boxProps}>
        <Box>
          <Button
            px={8}
            size="small"
            color="danger"
            icon="down"
            onPress={handlePressSell}
            disabled={!sellTariffAvailable}
            testID="INSTRUMENT_ACTION_BUTTON_SELL"
          />
        </Box>
        <Box ml={8}>
          <Button
            px={8}
            size="small"
            color="success"
            icon="up"
            onPress={handlePressBuy}
            disabled={!buyTariffAvailable}
            testID="INSTRUMENT_ACTION_BUTTON_BUY"
          />
        </Box>
      </Box>
    );
  }
);
