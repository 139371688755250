import {observable} from 'mobx';
import {serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';

export class HODLItemCalculatedResult {
  @observable
  @serializable(number())
  outputAmount!: number;

  @observable
  @serializable(number())
  amount!: number;

  @observable
  @serializable(number())
  percent!: number;
}
