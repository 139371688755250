import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

export const YouHodlerLogoImage = React.memo(
  ({color = '#212121', ...props}: SvgProps) => {
    return (
      <Svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.882 15.765v.706l-5.883 3.53-5.882-3.53v-.706l5.882 3.53 5.883-3.53zm0-1.411v.706l-5.883 3.53-5.882-3.53v-.706l5.882 3.53 5.883-3.53zm0-1.412v.706l-5.883 3.53-5.882-3.53v-.706l5.882 3.53 5.883-3.53zm-.586-3.81v.707l-2.353 1.411v2.824l-.59.353v-3.53l2.943-1.764zm-10.709 0l2.943 1.765v3.53l-.59-.353.002-2.824-2.355-1.41v-.706zm10.709 1.412v.706l-1.177.706v1.412l-.59.352v-2.117l1.767-1.059zm-10.709 0l1.766 1.059v2.117l-.59-.352.002-1.412-1.178-.706v-.706zm10.709 1.412v.706l-.59.353v-.706l.59-.353zm-10.709 0l.59.353v.706l-.588-.353-.002-.706zm4.824-9.955l.588.353-5.882 3.53v7.058l-.588-.353V5.53L9.411 2zm1.177 0l5.882 3.53v7.058l-.588.353V5.883l-5.883-3.53.588-.352zm1.176-.706l5.882 3.53v7.058l-.588.353V5.177l-5.882-3.53.588-.352zm1.177-.706l5.882 3.53v7.058l-.588.353V4.471L12.352.941 12.94.59zm-5.883 0l.588.353-5.882 3.53v7.058l-.588-.353V4.118L7.058.588zm5.294 3.765l.588.353L10 6.47 7.057 4.707l.588-.353L10 5.766l2.353-1.412zM2.94 12.236l-.588-.353V4.824l5.883-3.53.588.354-5.883 3.53v7.058zm8.236-8.588l.588.353L9.999 5.06 8.235 4l.588-.352 1.176.706 1.177-.706zm-1.177-.706l.588.353-.588.353-.588-.353.588-.353z"
          fill={color}
        />
      </Svg>
    );
  }
);
