import * as React from 'react';
import {Circle, Path, Svg, SvgProps} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const BnbIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Circle cx={16} cy={16} r={16} fill="#F3BA2F" />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.116 14.404L16 10.52l3.886 3.886 2.26-2.26L16 6l-6.144 6.144 2.26 2.26zM6 16l2.26-2.26L10.52 16l-2.26 2.26L6 16zm10 5.48l-3.884-3.884-2.263 2.257L16 26l6.146-6.147-2.26-2.259L16 21.48zM21.48 16l2.26-2.26L26 16l-2.26 2.26L21.48 16zm-3.186-.002h-.002L16 13.706l-1.89 1.889-.401.402-.004.003L16 18.294 18.294 16v-.002z"
          fill="#fff"
        />
      </Svg>
    );
  }
);
