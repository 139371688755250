import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {i18n} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {HeaderBase} from '../../../components';
import {UnlimintSPEI} from '../../../components/FiatDeposit';
import {type FiatDepositWizardNavigator, FiatDepositWizardScreenName} from '..';

const showHeader = Platform.select({
  default: true,
  native: false,
});

const getFiatDepositWizardSPEITitle = (ticker: string) => {
  return i18n.t('surface.wallets.fiat_deposit.spei.title', {
    ticker: ticker.toUpperCase(),
  });
};

export const getFiatDepositWizardSPEIOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {
      ticker: string;
    };
  };
}) => {
  return {
    title: getFiatDepositWizardSPEITitle(ticker),
  };
};

export const FiatDepositWizardSPEI: React.FC = observer(() => {
  const {
    step: {
      props: {ticker},
    },
    goBack,
    close,
  } = useWizard<FiatDepositWizardNavigator, FiatDepositWizardScreenName.SPEI>();

  React.useEffect(() => {
    DATA_LAYER.trackStrict('deposit-fiat-attempt', {
      type: 'fiat',
      category: 'deposit',
      provider: 'SPEI',
      ticker,
    });
  }, [ticker]);

  return (
    <>
      {showHeader && (
        <HeaderBase onPressBack={goBack}>
          {getFiatDepositWizardSPEITitle(ticker)}
        </HeaderBase>
      )}
      <UnlimintSPEI ticker={ticker} onSubmit={close} />
    </>
  );
});
