import {computed, observable} from 'mobx';
import {alias, date, optional, primitive, serializable} from 'serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {number} from '@youtoken/ui.utils-serializr';
import {format} from '@youtoken/ui.date-fns';
import {
  getSettlementFeePercentFormatted,
  getSettlementFeeSign,
  getSettlementFeeTickerComposite,
} from '@youtoken/ui.hodls-utils';
import {HodlOperationType} from '../types';

const getPercentFormatted = (percent?: number) => {
  return percent !== undefined ? percent + '%' : '';
};

export class HodlHistoryResponse {
  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(primitive())
  operationType!: HodlOperationType;

  @observable
  @serializable(number())
  ftpPrice!: number;

  @observable
  @serializable(optional(number()))
  ftpPriceDiff?: number;

  @observable
  @serializable(number())
  mcPrice!: number;

  @observable
  @serializable(optional(number()))
  mcPriceDiff?: number;

  @observable
  @serializable(number())
  initialPrice!: number;

  @observable
  @serializable(optional(number()))
  initialPriceDiff?: number;

  @observable
  @serializable(number())
  currentPrice!: number;

  @observable
  @serializable(number())
  borrowedAmount!: number;

  @observable
  @serializable(optional(number()))
  borrowedAmountDiff?: number;

  @observable
  @serializable(primitive())
  borrowedTicker!: string;

  @observable
  @serializable(alias('totalAmount', number()))
  collateralAmount!: number;

  @observable
  @serializable(optional(alias('totalAmountDiff', number())))
  collateralAmountDiff?: number;

  @observable
  @serializable(primitive())
  collateralTicker!: string;

  @observable
  @serializable(number())
  inputAmount!: number;

  @observable
  @serializable(optional(number()))
  inputAmountDiff?: number;

  @observable
  @serializable(primitive())
  inputTicker!: string;

  @observable
  @serializable(primitive())
  quoteTicker!: string;

  @observable
  @serializable(number())
  settlementPeriod!: number;

  @observable
  @serializable(number())
  settlementFee!: number;

  @observable
  @serializable(number())
  settlementFeeAmount!: number;

  @observable
  @serializable(alias('decimals', number()))
  precision!: number;

  @observable
  @serializable(date())
  timestamp!: Date;

  @computed get historyItemDateFormatted(): string {
    return format(this.timestamp, 'dd MMM yyyy, HH:mm');
  }

  @computed get ftpPriceFormatted(): string {
    return this.ftpPrice.toFixed(this.precision);
  }

  @computed get ftpPriceDiffFormatted(): string {
    return getPercentFormatted(this.ftpPriceDiff);
  }

  @computed get mcPriceFormatted(): string {
    return this.mcPrice.toFixed(this.precision);
  }

  @computed get mcPriceDiffFormatted(): string {
    return getPercentFormatted(this.mcPriceDiff);
  }

  @computed get initialPriceFormatted(): string {
    return this.initialPrice.toFixed(this.precision);
  }

  @computed get initialPriceDiffFormatted(): string {
    return getPercentFormatted(this.initialPriceDiff);
  }

  @computed get currentPriceFormatted(): string {
    return this.currentPrice.toFixed(this.precision);
  }

  @computed get collateralAmountFormatted(): string {
    return formatByTicker(this.collateralAmount, this.collateralTicker);
  }

  @computed get collateralAmountDiffFormatted(): string {
    return getPercentFormatted(this.collateralAmountDiff);
  }

  @computed get collateralTickerFormatted(): string {
    return this.collateralTicker.toUpperCase();
  }

  @computed get borrowedAmountFormatted(): string {
    return formatByTicker(this.borrowedAmount, this.borrowedTicker);
  }

  @computed get borrowedAmountDiffFormatted(): string {
    return getPercentFormatted(this.borrowedAmountDiff);
  }

  @computed get borrowedTickerFormatted(): string {
    return this.borrowedTicker.toUpperCase();
  }

  @computed get inputAmountFormatted(): string {
    return formatByTicker(this.inputAmount, this.inputTicker);
  }

  @computed get inputAmountDiffFormatted(): string {
    return getPercentFormatted(this.inputAmountDiff);
  }

  @computed get inputTickerFormatted(): string {
    return this.inputTicker.toUpperCase();
  }

  @computed get quoteTickerFormatted(): string {
    return this.quoteTicker.toUpperCase();
  }

  @computed get settlementPeriodFormatted(): string {
    return this.settlementPeriod.toString();
  }

  @computed get settlementFeePercentFormatted(): string {
    return getSettlementFeePercentFormatted(this.settlementFee);
  }

  @computed get settlementFeeSign(): string {
    return getSettlementFeeSign(this.settlementFee);
  }

  @computed get settlementFeeAmountFormatted() {
    const amount = formatByTicker(
      Math.abs(this.settlementFeeAmount),
      this.borrowedTicker
    );

    return `${this.settlementFeeSign}${amount}`;
  }

  @computed get settlementFeeTickerFormatted() {
    return getSettlementFeeTickerComposite(
      this.borrowedTickerFormatted,
      this.settlementFeePercentFormatted,
      this.settlementPeriodFormatted
    );
  }
}
