export interface IBankCard {
  enable: boolean;
  format: TBankCardFormat;
  status: TBankCardStatus;
  ticker?: string;
  cardId: string;
  balance: number;
  panLast?: string;
  expiryDate?: string;
  paymentSystem?: PaymentSystem;
  balanceFormatted?: string;
  balanceWithTickerFormatted?: string;
}

type TBankCardMockStatus = 'AVAILABLE';

export type TBankCardStatus =
  | TBankCardMockStatus
  | 'ONBOARDING'
  | 'CREATING'
  | 'NEED_ACTIVATION'
  | 'ACTIVE'
  | 'DISABLED'
  | 'BLOCKED'
  | 'FROZEN'
  | 'DECLINED';

export type TBankCardFormat = 'virtual' | 'plastic';

export enum PaymentSystem {
  VISA = 'Visa',
  MASTERCARD = 'Mastercard',
}
