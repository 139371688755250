import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const HistoryIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M13.472 15.197A6.25 6.25 0 0110 16.25a.75.75 0 000 1.5A7.75 7.75 0 102.25 10H1.207a.5.5 0 00-.353.854l1.792 1.793a.5.5 0 00.708 0l1.792-1.793A.5.5 0 004.793 10H3.75a6.25 6.25 0 119.722 5.197z"
          fill={color}
        />
        <Path
          d="M9.742 11a.75.75 0 01-.75-.75l-.003-3.5a.75.75 0 01.648-.743L9.74 6a.75.75 0 01.75.75l.002 2.75h2.257a.75.75 0 110 1.5H9.742z"
          fill={color}
        />
      </Svg>
    );
  }
);
