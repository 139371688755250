import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export interface VisibilityTogglerProps extends BoxProps {
  label?: string;
  labelCount: number;
  visibility: boolean;
}

export const VisibilityToggler: React.FC<VisibilityTogglerProps> = React.memo(
  ({label: _label, labelCount, visibility, ...otherProps}) => {
    const {t} = useTranslation();
    const label = _label || t('surface.wallets.wallets_list.hidden_wallets');

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        {...otherProps}
      >
        <Text color="$ui-04">
          {label} ({labelCount})
        </Text>
        <Box
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          minWidth={60}
        >
          <Text color="$ui-04" mr={4}>
            {visibility
              ? t('surface.wallets.wallets_list.hide')
              : t('surface.wallets.wallets_list.show')}
          </Text>
          <Icon
            color="$ui-04"
            name={visibility ? 'hide' : 'show'}
            width={20}
            height={20}
          />
        </Box>
      </Box>
    );
  }
);
