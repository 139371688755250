import * as React from 'react';
import {dateWithoutTimezone, warning} from '@youtoken/ui.utils';
import {Box, Text, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

type FormAInformationProps = {
  fullName?: string;
  dateOfBirth: string;
};

export const FormAInformation: React.FC<FormAInformationProps> = ({
  fullName,
  dateOfBirth,
}) => {
  const {t} = useTranslation();

  warning(fullName, 'FormAInformation: fullName is required', {}, {fullName});

  return (
    <>
      {/* @ts-ignore */}
      <Heading variant="$heading-03" mb={24} mt={20} focusable={false}>
        {t('surface.form_a.id_info')}
      </Heading>
      <Box flexDirection="row" width="100%">
        <Box width="50%">
          <Text variant="$body-02" color="$text-02" mb={8}>
            {t('surface.form_a.field.name')}
          </Text>
          <Text variant="$body-02">{fullName}</Text>
        </Box>
        <Box width="50%">
          <Text variant="$body-02" color="$text-02" mb={8}>
            {t('surface.form_a.field.date_of_birth')}
          </Text>
          <Text variant="$body-02">{dateWithoutTimezone(dateOfBirth)}</Text>
        </Box>
      </Box>
    </>
  );
};
