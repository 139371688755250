import * as React from 'react';
import NiceModal, {useModal} from '@ebay/nice-modal-react';
import ReactModal, {type Styles} from 'react-modal';
import {Box, Heading, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon} from '@youtoken/ui.icons';
import {createRecaptchaError} from '../../utils/recaptchaErrors';
import {RecaptchaView} from './RecaptchaView';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 500,
  },
  content: {
    position: 'relative',
    background: 'transparent',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 0,
    outline: 'none',
    width: '100%',
    border: 'none',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const RecaptchaModal = NiceModal.create<{
  siteKey: string;
  onError: (error: any) => void;
  onSuccess: (token: string | null) => void;
}>(({siteKey, onError, onSuccess}) => {
  const {hide, visible} = useModal();
  // const [result, setResult] = React.useState<string | null>(null);
  const {t} = useTranslation();

  const handleManualClose = React.useCallback(() => {
    onError(createRecaptchaError('v2', 'was closed by user'));
    hide();
  }, [hide, onError]);

  const handleSuccess = React.useCallback(
    (token: string | null) => {
      if (!token) {
        onError(createRecaptchaError('v2', 'returned error: token is null'));
        hide();
        return;
      }
      onSuccess(token);
      hide();
    },
    [hide, onSuccess]
  );

  const handleError = React.useCallback(
    (error: any) => {
      onError(createRecaptchaError('v2', `returned error: ${error}`));
      hide();
    },
    [hide, onError]
  );

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={handleManualClose}
      style={customStyles as Styles}
    >
      <Box
        backgroundColor="$ui-background"
        borderRadius={6}
        width={{default: '100%', tablet: 590}}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          borderBottomColor="$ui-01"
          borderBottomWidth={1}
          height={64}
        >
          <Heading variant="$heading-01">
            {t('surface.recaptcha.complete')}
          </Heading>
          <TouchableBox
            position="absolute"
            right={0}
            top={0}
            width={64}
            height={64}
            justifyContent="center"
            alignItems="center"
            onPress={handleManualClose}
            activeOpacity={0.8}
          >
            <Icon name="close" color="$text-02" />
          </TouchableBox>
        </Box>
        <Box
          minHeight={78}
          width="100%"
          justifyContent="center"
          alignItems="center"
          py={24}
        >
          <RecaptchaView
            siteKey={siteKey}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        </Box>
      </Box>
    </ReactModal>
  );
});

NiceModal.register('recaptcha-v2-modal', RecaptchaModal);
