import {action, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {serializable, deserialize, primitive, list, object} from 'serializr';
import {stringify} from 'query-string';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LoansItem, LoanStatus} from './LoansItem';
import {type LoansActiveOrClosedResourceArgs} from './types';

const DEFAULT_LIMIT = 10;
const DEFAULT_OFFSET = 0;

export interface LoansClosedResourceArgs
  extends LoansActiveOrClosedResourceArgs {
  offset?: number;
  limit?: number;
}

export class LoansClosedResource extends createResource<
  LoansClosedResourceArgs,
  LoansResponsePaginated
>({
  getKey: (args: LoansClosedResourceArgs) => {
    return `loansClosed(${args.mode})`;
  },
  getData: (args: LoansClosedResourceArgs) => {
    return TRANSPORT.API.get(`/v1/loan`, {
      params: {
        mode: args.mode,
        status: [
          LoanStatus.CLOSED,
          LoanStatus.DECLINED,
          LoanStatus.CANCELED,
          LoanStatus.FAIL,
        ],
        sort: 'finishedAt',
        order: 'desc',
        limit: args.limit ?? DEFAULT_LIMIT,
        offset: args.offset ?? DEFAULT_OFFSET,
      },
      paramsSerializer: params => stringify(params, {arrayFormat: 'none'}),
    }).then(response => {
      return deserialize(LoansResponsePaginated, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {
  @computed
  public get limit() {
    return this.args.limit ?? DEFAULT_LIMIT;
  }

  @computed
  public get offset() {
    return this.args.offset ?? DEFAULT_OFFSET;
  }

  @computed
  public get total() {
    return this.data.total ?? 0;
  }

  @computed
  public get rows() {
    return this.data.rows ?? [];
  }

  @computed
  public get totalPages() {
    return Math.ceil(this.total / this.limit);
  }

  @computed
  public get activePage() {
    return this.offset / this.limit + 1;
  }

  @action
  public setPage = (page: number) => {
    const offset = this.limit * page;

    if (offset >= this.total) {
      return;
    }

    this.args.offset = offset;
  };

  public getById = computedFn((id: string) => {
    return this.rows.find(l => l.id === id);
  });
}

export class LoansResponsePaginated {
  @serializable(primitive())
  currentPage!: number;

  @serializable(list(object(LoansItem)))
  rows!: LoansItem[];

  @serializable(primitive())
  total!: number;
}
