import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DualIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.097 10.281c.325.18.544.405.658.677.113.272.096.611-.05 1.016a1.76 1.76 0 01-.29.522c-.118.14-.248.248-.391.32-.143.073-.31.121-.5.144-.19.023-.382.025-.579.006a5.463 5.463 0 01-.664-.111L11.984 14l-.684-.189.293-1.127-.243-.068-.298-.086-.294 1.131-.684-.189.297-1.145-.3-.086L9 11.944l.351-.784.493.136c.148.041.254-.012.317-.158l.47-1.805.07.02a.284.284 0 00-.019-.008l-.05-.016.335-1.29c.014-.213-.091-.352-.316-.414l-.493-.136.191-.737.94.265.22.06.213.055L12.016 6l.684.189-.289 1.11.172.042.373.098.286-1.1.683.189-.294 1.131c.229.086.427.177.596.273.168.096.318.21.45.34.13.132.222.282.275.452.052.17.062.357.028.56-.088.559-.382.891-.883.997zM12.215 8.11l-.359 1.379.318.09.075.02.262.059a1.662 1.662 0 00.233.032l.188.01a.766.766 0 00.237-.024c.066-.02.13-.044.196-.076a.436.436 0 00.165-.14.645.645 0 00.1-.218.619.619 0 00.012-.278.658.658 0 00-.086-.226.7.7 0 00-.18-.185 1.592 1.592 0 00-.226-.141 2.553 2.553 0 00-.325-.134l-.106-.035a1.614 1.614 0 00-.087-.025l-.417-.108zm1.073 3.574a.648.648 0 01-.156.15.627.627 0 01-.21.086 1.53 1.53 0 01-.499.03l-.141-.014-.122-.017-.122-.023-.142-.03-.2-.05-.189-.052-.225-.066.395-1.518.343.087.156.042.102.03.128.042.073.026c.128.045.234.088.319.131.084.043.174.097.269.163a.712.712 0 01.33.46.626.626 0 01-.008.306.611.611 0 01-.101.217z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 18a8 8 0 002-.252 8 8 0 100-15.496A8 8 0 108 18zM7.281 3.54a6.501 6.501 0 000 12.92A7.99 7.99 0 014 10a7.989 7.989 0 013.281-6.46zM12 16.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
          fill={color}
        />
      </Svg>
    );
  }
);
