import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  Notification,
  NotificationCategory,
  NotificationType,
} from './NotificationsResponse';

// Note: After changing the backend remove this fn
export const getModalNotification = (notifications: Notification[]) => {
  const {
    data: {
      products: {
        rewards: {available: rewardsAvailable},
      },
    },
  } = AuthMeResource.getInstance({});

  return notifications.filter(({type, category}) => {
    if (category !== NotificationCategory.POP_UP) {
      return false;
    }

    // filter rewards popup if rewards are not available
    if (type === NotificationType.REWARDS) {
      return rewardsAvailable;
    }

    return true;
  });
};
