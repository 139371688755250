import * as React from 'react';
import {SectorsIcon, type SectorsIconName} from '@youtoken/ui.icons';
import {Trans} from '@youtoken/ui.elements';
import {
  TouchableBox,
  Box,
  Text,
  type BoxProps,
  type Theme,
} from '@youtoken/ui.primitives';

export type SectorsCardProps = {
  title: string;
  icon: SectorsIconName;
  cardColor: keyof Theme['colors'];
  itemCount: number;
  itemName: string;
  onPress: (sector: string) => void;
};

export const SectorsCard: React.FC<SectorsCardProps & BoxProps> = ({
  title,
  icon,
  cardColor,
  itemCount,
  itemName,
  onPress,
  ...boxProps
}) => {
  const handleCardPress = React.useCallback(() => {
    onPress(itemName);
  }, [itemName]);

  return (
    <TouchableBox
      height={64}
      py={14}
      pr={16}
      pl={48}
      borderRadius={16}
      justifyContent="center"
      backgroundColor={cardColor}
      overflow="hidden"
      onPress={handleCardPress}
      testID={`SECTORS_${itemName.toUpperCase()}`}
      {...boxProps}
    >
      <Box position="absolute" left={-16} zIndex={-1} opacity={0.5}>
        <SectorsIcon name={icon} />
      </Box>
      <Text variant="$body-01-high-accent" color="$text-04" mb={2}>
        {title}
      </Text>
      <Text variant="$body-02" color="$text-04">
        <Trans
          i18nKey="surface.hodls.portfolio.sectors_card_text"
          values={{
            count: itemCount,
          }}
        />
      </Text>
    </TouchableBox>
  );
};
