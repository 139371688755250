import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Banner, Link} from '@youtoken/ui.elements';
import {type CryptoDepositBannerByVersionProps} from '../types';

const bannerTestId = 'CRYPTO_DEPOSIT_BANNER';
const bannerLinkTestId = 'CRYPTO_DEPOSIT_BANNER_LINK';

export const CryptoDepositBannerByVersion: React.FC<CryptoDepositBannerByVersionProps> =
  observer(({ticker, walletVersion}) => {
    const {t} = useTranslation();

    if (ticker === 'btc') {
      if (walletVersion === 'bep20') {
        return null;
      }

      return (
        <Banner mt={24} variant="attention" testID={bannerTestId}>
          <Trans
            t={t}
            components={{
              Link: (
                <Link
                  url={t(
                    'surface.wallets.crypto_deposit.url_btc_address_format'
                  )}
                  testID={bannerLinkTestId}
                />
              ),
            }}
            i18nKey="surface.wallets.crypto_deposit.alert_btc"
          />
        </Banner>
      );
    }

    return null;
  });
