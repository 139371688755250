import React from 'react';
import {Modal} from '../_common';
import {SecurityActivityDetailsModalCell} from '@youtoken/ui.surface-security-activity';

interface ModalSecurityActivityDetailsProps
  extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalSecurityActivityDetails: React.FC<
  ModalSecurityActivityDetailsProps
> = ({id}) => {
  return (
    <Modal maxWidth={444} alignItems="stretch">
      <SecurityActivityDetailsModalCell id={id} />
    </Modal>
  );
};
