import {comparer} from 'mobx';
import {DATA_STORAGE} from '../DataStorage';

export interface FeatureClassType {
  new (...args: any[]): any;
  getKey(...args: any[]): string;
}

export function useFeature<F extends FeatureClassType>(
  FeatureClass: F,
  ...args: ConstructorParameters<F>
) {
  const featureKey = FeatureClass.getKey(args[0]);

  if (!DATA_STORAGE.has(featureKey)) {
    DATA_STORAGE.set(featureKey, new FeatureClass(...args));
  }

  if (!comparer.structural(DATA_STORAGE.get(featureKey).args, args[0])) {
    /**
     * Нам важно обновлять аргументы, даже если они не учавствуют в создании ключа
     * Эти аргументы в дальнейшем могут использоваться во вложенных классах,
     * например при создании форм через фичи
     * В этом случае при обновлении аргументов фичи нужно так же позаботиться об новлении аргументов формы
     */

    DATA_STORAGE.get(featureKey).args = args[0];
  }

  if (!comparer.shallow(DATA_STORAGE.get(featureKey).resources, args[1])) {
    /**
     * Фича зависит от ресурсов, ресурсы самоудаляются если они не используются
     * Но мы передаем ссылку на объект и сохраняем ее в инстансе фичи
     * Таким образом, удалив ресурс из DATA_STORAGE, ссылка на объект остается в самом инстансе фичи
     * Пресоздаем фичу в случае если ссылка на ресурс поменялась
     */

    DATA_STORAGE.get(featureKey).resources = args[1];
  }

  return DATA_STORAGE.get(featureKey) as InstanceType<F>;
}
