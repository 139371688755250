import React from 'react';
import {Platform, ScrollView} from 'react-native';
import {DepositInvoice} from '@youtoken/ui.surface-bank-wire';
import {FiatDepositBankWireHeader} from '../Header';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export interface FiatDepositBankWireInvoiceProps {
  ticker: string;
  onPressBack?: () => void;
}

export const FiatDepositBankWireInvoice: React.FC<
  FiatDepositBankWireInvoiceProps
> = ({ticker, onPressBack}) => {
  return (
    <>
      {showHeader && (
        <FiatDepositBankWireHeader ticker={ticker} onPressBack={onPressBack} />
      )}
      <ScrollView
        keyboardDismissMode="interactive"
        keyboardShouldPersistTaps="handled"
        style={{
          flex: 1,
        }}
        contentContainerStyle={{
          minHeight: '100%',
        }}
      >
        <DepositInvoice ticker={ticker} />
      </ScrollView>
    </>
  );
};
