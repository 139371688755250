import * as React from 'react';
import {observer} from 'mobx-react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource, KYCStatus} from '@youtoken/ui.resource-auth-me';

import {
  RewardOverviewStatus,
  RewardsResource,
} from '@youtoken/ui.resource-rewards-v3';
import {
  RewardsReviewModalTrigger,
  RewardsBannedModalTrigger,
} from '../../../../modals';
import {RewardsOverviewStatusActionVerify} from './Verify';
import {RewardsOverviewStatusActionClaim} from './Claim';

export const RewardsOverviewStatusAction: React.FC = observer(() => {
  const {
    authMe: {
      data: {kycResult},
    },
    rewards: {
      data: {status},
    },
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    rewards: getResourceDescriptor(RewardsResource, {}),
  });

  if (kycResult !== KYCStatus.ACCEPT) {
    return <RewardsOverviewStatusActionVerify />;
  }

  if (status === RewardOverviewStatus.PENDING) {
    return <RewardsReviewModalTrigger />;
  }

  if (status === RewardOverviewStatus.READY) {
    return <RewardsOverviewStatusActionClaim />;
  }

  if (
    status === RewardOverviewStatus.BANNED ||
    status === RewardOverviewStatus.ERROR
  ) {
    return <RewardsBannedModalTrigger />;
  }

  return null;
});
