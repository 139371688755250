export const getThemeColorForDirection = (directionColor: string) => {
  switch (directionColor) {
    case 'up':
      return '$success-01';
    case 'down':
      return '$danger-01';
    case 'neutral':
    default:
      return '$text-02';
  }
};
