import {list, object, optional, primitive, raw, serializable} from 'serializr';

export type HappyBirthdayStoryParams = {
  user: {fullName?: string};
};

export type TemplateParams = {
  HappyBirthday: HappyBirthdayStoryParams;
};

export type TemplateName = keyof TemplateParams;

export class SlideButton {
  @serializable(primitive())
  url!: string;

  @serializable(primitive())
  title!: string;
}

export class Slide {
  @serializable(primitive())
  order!: number;

  @serializable(primitive())
  shown!: boolean;

  @serializable(optional(primitive()))
  backgroundUrl?: string;

  @serializable(optional(primitive()))
  foregroundUrl?: string;

  @serializable(optional(primitive()))
  templateName?: TemplateName;

  @serializable(raw())
  contentParams?: TemplateParams[TemplateName];

  @serializable(optional(object(SlideButton)))
  button?: SlideButton;
}

export class Story {
  @serializable(primitive())
  id!: string; // uniq id of story for user, it is different for all the users

  @serializable(primitive())
  storyId!: string; // id of the story, it is the same for all users, uniq for the different stories

  @serializable(primitive())
  slug!: string;

  @serializable(list(object(Slide)))
  slides!: Slide[];

  @serializable(primitive())
  title!: string;

  @serializable(optional(primitive()))
  previewUrl?: string;

  @serializable(primitive())
  shown!: boolean;
}

export class InstaStoriesResponse {
  @serializable(list(object(Story)))
  stories!: Story[];
}
