import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export interface LoanItemAgreementProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemAgreement: React.FC<LoanItemAgreementProps> = observer(
  ({item}) => {
    const {t} = useTranslation();

    const handlePress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('Agreement', {url: item.agreementUrl});
    }, [item.agreementUrl]);

    if (!item.agreementAvaliable) {
      return null;
    }

    return (
      <TouchableBox
        testID="LOAN_ITEM_AGREEMENT_BUTTON"
        onPress={handlePress}
        alignSelf="flex-start"
        flexDirection="row"
        alignItems="center"
        mb={20}
      >
        <Icon name="document" color="$text-05" size={16} />
        <Text color="$text-05" variant="$body-02" ml={3}>
          {t('common.elements.agreement_link')}
        </Text>
      </TouchableBox>
    );
  }
);
