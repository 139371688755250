import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type BaseIconName, Icon} from '@youtoken/ui.icons';

export interface SavingsEarningResultProps {
  icon: BaseIconName;
  title: string;
  description?: string;
}

export const SavingsEarningResult: React.FC<
  SavingsEarningResultProps & BoxProps
> = React.memo(({icon, title, description, ...boxProps}) => {
  return (
    <Box justifyContent="center" alignItems="center" {...boxProps}>
      <Box
        justifyContent="center"
        alignItems="center"
        width={40}
        height={40}
        borderRadius={20}
        backgroundColor="$success-02"
      >
        <Icon name={icon} size={24} color="$success-01" />
      </Box>
      <Text
        variant="$body-01-high-accent"
        color="$success-01"
        mt={4}
        textAlign="center"
      >
        {title}
      </Text>
      {Boolean(description) && (
        <Text variant="$body-02" color="$text-02" mt={4} textAlign="center">
          {description}
        </Text>
      )}
    </Box>
  );
});
