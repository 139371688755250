import React from 'react';
import {observer} from 'mobx-react';
import {NotificationType} from '@youtoken/ui.resource-notifications';
import {RewardsWelcomeModal} from '@youtoken/ui.surfaces-referral-program';
import {UpgradeToLevelModal} from '@youtoken/ui.surface-account-levels';
import {ModalNotificationProps} from './types';

export const ModalNotification: React.FC<ModalNotificationProps> = observer(
  ({data: {notificationId, type, payloadByType}, onShow, onClose}) => {
    const handleShow = React.useCallback(() => {
      onShow?.(notificationId);
    }, [notificationId, onShow]);

    const handleClose = React.useCallback(() => {
      onClose?.(notificationId);
    }, [notificationId, onClose]);

    React.useEffect(() => {
      handleShow();
    }, [handleShow]);

    if (type === NotificationType.REWARDS) {
      return <RewardsWelcomeModal isOpen={true} onClose={handleClose} />;
    }

    if (type === NotificationType.LOYALTY) {
      return (
        <UpgradeToLevelModal
          levelNumber={payloadByType.level!}
          isOpen={true}
          onClose={handleClose}
        />
      );
    }

    return null;
  }
);
