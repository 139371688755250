import {
  serializable,
  custom,
  date,
  list,
  map,
  object,
  primitive,
  raw,
  deserialize,
} from 'serializr';
import {computed} from 'mobx';
import {toBig} from '@youtoken/ui.formatting-utils';

// LoanStatus

export enum LoanStatus {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  FAIL = 'FAIL',
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  CLOSING = 'CLOSING',
  PROCESSING = 'PROCESSING',
}

// LoansItemChainLoan

export class LoansItemChainLoan {
  @serializable(primitive())
  borrowedAmount!: number;

  @serializable(primitive())
  collateralAmount!: number;

  @serializable(primitive())
  interest!: number;

  @serializable(primitive())
  overdraftAmount!: number;
}

export const chainLoans = () => {
  return list(object(LoansItemChainLoan), {
    beforeDeserialize: (callback, jsonValue) => {
      callback(null, Array.isArray(jsonValue) ? jsonValue : []);
    },
  });
};

// ChartData

export class LoansItemChartDataItem {
  @serializable(primitive())
  rate!: number;

  @serializable(date())
  date!: Date;
}

export class LoansItemChartData {
  @serializable(primitive())
  type!: 'line';

  @serializable(list(object(LoansItemChartDataItem)))
  data!: LoansItemChartDataItem[];
}

export const chartData = () => {
  return custom(
    outValue => outValue,
    inValue => {
      let data = [];
      try {
        data = (inValue || [])
          .map((d: LoansItemChartDataItem) => {
            if (typeof d === 'object') {
              return deserialize(LoansItemChartDataItem, {
                rate: d.rate,
                date: new Date(d.date),
              });
            }

            return null;
          })
          .filter(Boolean);
      } catch (e) {}

      return deserialize(LoansItemChartData, {type: 'line', data});
    }
  );
};

// LoansItemPayoffAddress

export class LoansItemPayoffAddress {
  @serializable(primitive())
  ticker!: string;

  @serializable(primitive())
  address!: string;
}

export const payoffAddress = () => list(object(LoansItemPayoffAddress));

// LoansItemCloseNow

export class LoansItemCloseNow {
  @serializable(primitive())
  closeNowAmount!: number;

  @serializable(primitive())
  closeFee!: number;

  @serializable(primitive())
  collateralAmount!: number;

  @serializable(primitive())
  collateralValue!: number;

  @serializable(primitive())
  loanCloseNowFee!: number;

  @serializable(primitive())
  overdraftAmount!: number;

  @serializable(primitive())
  price!: number;

  @serializable(primitive())
  sellCollateralAmount!: number;

  @serializable(primitive())
  tailCollateralAmount!: number;

  @serializable(primitive())
  tailCollateralValue!: number;
}

export const closeNow = () => object(LoansItemCloseNow);

// LoansItemIncrease

export class LoansItemIncrease {
  @serializable(raw())
  oldData?: any;

  @serializable(primitive())
  borrowedAmount!: number;

  @serializable(primitive())
  calculatedFrom!: number;

  @serializable(primitive())
  fee!: number;

  @serializable(primitive())
  increasedAmount!: number;

  @serializable(primitive())
  ltv!: number;

  @serializable(primitive())
  maxAmount!: number;

  @serializable(primitive())
  mcPrice!: number;

  @serializable(primitive())
  newMc!: number;

  @serializable(primitive())
  newOverdraft!: number;

  @serializable(primitive())
  oldBorrowedAmount!: number;

  @serializable(primitive())
  oldOverdraftAmount!: number;

  @serializable(primitive())
  repaymentAmount!: number;
}

export const increase = () => object(LoansItemIncrease);

// LoansItemReopen

export class LoansItemReopen {
  @serializable(primitive())
  all!: number;

  @serializable(primitive())
  amount!: number;

  @serializable(primitive())
  fee!: number;
}

export const reopen = () => object(LoansItemReopen);

// LoansItem

export class LoansItem {
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  status!: LoanStatus;

  @serializable(primitive())
  isClientMC?: boolean;

  @serializable(primitive())
  collateralTicker!: string;

  @serializable(primitive())
  borrowedTicker!: string;

  @serializable(chartData())
  chartData!: LoansItemChartData;

  @serializable(primitive())
  collateralAmount!: number;

  @serializable(primitive())
  borrowedAmount!: number;

  @serializable(primitive())
  days!: number;

  @serializable(primitive())
  APR!: number;

  @serializable(primitive())
  LTV!: number;

  @serializable(map(primitive()))
  details!: {[key: string]: number};

  @serializable(primitive())
  initialPrice!: number;

  @serializable(primitive())
  closedPrice?: number;

  @serializable(primitive())
  FTP?: number;

  @serializable(primitive())
  marginCall?: number;

  @serializable(payoffAddress())
  payoffAddresses!: LoansItemPayoffAddress[];

  @serializable(primitive())
  overdueCollateralPercent?: number;

  @serializable(primitive())
  overdueDays?: number;

  @serializable(primitive())
  remainingAmount?: number;

  @serializable(primitive())
  collateralRecompenseAmount?: number;

  @serializable(primitive())
  minFTP?: number;

  @serializable(primitive())
  maxFTP?: number;

  @serializable(primitive())
  turbo?: number;

  @serializable(chainLoans())
  loans!: LoansItemChainLoan[];

  @serializable(closeNow())
  closeNow!: LoansItemCloseNow | false;

  @serializable(reopen())
  reopen?: LoansItemReopen;

  @serializable(increase())
  increase!: LoansItemIncrease | false;

  @serializable(primitive())
  decrease!: boolean;

  @serializable(primitive())
  company?: 'NAUMARD' | 'YOUHODLER';

  @serializable(date())
  createdAt!: Date;

  @serializable(date())
  startedAt?: Date;

  @serializable(date())
  updatedAt?: Date;

  @serializable(date())
  finishedAt?: Date;

  @serializable(date())
  reopenedAt?: Date;

  @serializable(date())
  finishAt?: Date;

  @computed get overdraftAmount() {
    return toBig(this.details[`_${this.borrowedTicker}`]).abs().toNumber();
  }
}
