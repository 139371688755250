import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoDepositLedgerFormState} from '../state';

export const CryptoDepositLedgerFormAmountAllLink: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    walletTickerFormatted,
    form: {ledgerAccountBalance, amountOnPressAll},
  } = useForm<CryptoDepositLedgerFormState>();

  return (
    <Link onPress={amountOnPressAll}>
      <Text variant="$body-02">
        {t('surface.wallets.crypto_deposit.amount_all')}{' '}
        <Text variant="$body-02-medium-accent">{ledgerAccountBalance}</Text>{' '}
        {walletTickerFormatted}
      </Text>
    </Link>
  );
});
