import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryItemV3} from '@youtoken/ui.resource-rewards-v3-history';
import {Box, Text} from '@youtoken/ui.primitives';
import {RewardsHistoryItemIndicator} from './Indicator';

export interface RewardsHistoryItemV3ItemProps {
  data: RewardsHistoryItemV3;
}

export const RewardsHistoryItemV3Item: React.FC<RewardsHistoryItemV3ItemProps> =
  observer(({data: {isToday, amountUSDFormatted, createdAtFormatted}}) => {
    const textColor = isToday ? '$success-01' : '$text-01';

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        px={{default: 16, phone: 24}}
        py={16}
      >
        {isToday && <RewardsHistoryItemIndicator />}
        <Text variant="$body-01-high-accent" color={textColor}>
          {amountUSDFormatted}
        </Text>
        <Text variant="$body-02" color={textColor}>
          {createdAtFormatted}
        </Text>
      </Box>
    );
  });
