import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {normalizeWithoutMultiplySpaces} from '@youtoken/ui.normalizers';
import {type WithdrawalForm} from '../../state/WithdrawalForm';
import {FormInputField} from '../FormInputField';

export const FieldBeneficiaryName: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    view: {showBeneficiaryDescriptions},
  } = useForm<WithdrawalForm>();

  return (
    <FormInputField
      name="beneficiaryName"
      testID="FIAT_WITHDRAWAL_BANK_WIRE_BENEFICIARY_NAME"
      maxLength={35}
      normalizer={normalizeWithoutMultiplySpaces}
      label={
        <Label>
          {t('surface.wallets.withdrawal_bank_wire_form.beneficiary_name')}
        </Label>
      }
    >
      {showBeneficiaryDescriptions && (
        <Text color="$text-02" variant="$body-02" mt={8}>
          {t(
            'surface.wallets.withdrawal_bank_wire_form.beneficiary_name_description'
          )}
        </Text>
      )}
    </FormInputField>
  );
});
