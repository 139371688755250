import * as React from 'react';
import {Clipboard} from '@youtoken/ui.utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {Button} from '@youtoken/ui.buttons';

export interface CopyButtonProps extends React.ComponentProps<typeof Button> {
  message: string;
  messageCopied: string;
}

export const CopyButton: React.FC<CopyButtonProps> = React.memo(
  ({children, message, messageCopied, ...rest}) => {
    const handlePress = React.useCallback(() => {
      Clipboard.setString(message);

      LOCAL_NOTIFICATIONS.info({
        text: messageCopied,
      });
    }, [message]);

    return (
      <Button onPress={handlePress} {...rest}>
        {children}
      </Button>
    );
  }
);
