import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {FieldErrorMessage, useForm} from '@youtoken/ui.form-elements';
import {Combobox} from '@youtoken/ui.combobox';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';
import {type WithdrawalForm} from '../../state/WithdrawalForm';

interface FormComboboxFieldProps {
  label: React.ReactNode;
  name: string;
  testID?: string;
}

export const FormComboboxField: React.FC<
  FormComboboxFieldProps & React.ComponentProps<typeof Combobox>
> = observer(({label, name, testID, children, ...comboboxProps}) => {
  const {
    form: {instance},
  } = useForm<WithdrawalForm>();

  const handleChange = React.useCallback(
    (value: string) => {
      instance.$(name).set('value', value);
    },
    [name]
  );

  return (
    <Box mb={10} zIndex={1}>
      <Box mb={8}>{label}</Box>

      <Combobox
        onChange={handleChange}
        value={instance.$(name).get('value')}
        testID={testID}
        {...comboboxProps}
      />

      <FieldErrorMessage visible={Boolean(instance.$(name).error)}>
        <Text testID={testID ? `${testID}_ERROR` : undefined}>
          <ValidationMessageComponent error={instance.$(name).get('error')} />
        </Text>
      </FieldErrorMessage>

      {children}
    </Box>
  );
});
