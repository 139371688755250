import * as React from 'react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {AppEnv} from '@youtoken/ui.env-utils';
import {Box, Text, TouchableBox, useAppearance} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const ThemeSwitcher = () => {
  const {activeTheme, setUserPreferredTheme} = useAppearance();

  const handleChangeTheme = React.useCallback(() => {
    setUserPreferredTheme(activeTheme === 'dark' ? 'light' : 'dark');
  }, [setUserPreferredTheme, activeTheme]);

  if (ENVIRONMENT.APP_ENV === AppEnv.production) {
    return null;
  }

  return (
    <Box
      ml={8}
      borderRadius={6}
      px={8}
      height={32}
      bg="$success-02"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      visible={{
        default: false,
        tablet: true,
      }}
    >
      <Icon
        name="warning"
        color="$success-01"
        size={18}
        opacity={activeTheme === 'light' ? 1 : 0.3}
      />

      <Text variant="$body-02" weight="bold" color="$success-01" ml={4} mr={4}>
        {activeTheme}
      </Text>
      <TouchableBox
        onPress={handleChangeTheme}
        borderLeftWidth={1}
        borderColor="$success-03"
        pl={4}
        ml={8}
        height={32}
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="trading" color="$success-01" size={18} />
      </TouchableBox>
    </Box>
  );
};
