import {action, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import big from 'big.js';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {formatBigNumber, formatPercent} from '@youtoken/ui.formatting-utils';
import {getCoinName, getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';

export interface WalletFeatureArgs {
  ticker: string;
}

export class WalletFeature extends createFeature({
  getKey: (_args: WalletFeatureArgs) => {
    return `feature:wallet(${JSON.stringify(_args)})`;
  },
  getResources: () => {
    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      rates: getResourceDescriptor(RatesResource, {}),
      authme: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @computed
  public get ticker() {
    return this.data ? this.data.ticker : '';
  }

  @computed
  public get tickerType() {
    return this.data ? this.data.tickerType : '';
  }

  @computed
  public get amount() {
    return this.data ? this.data.amount : '';
  }

  @computed
  public get added() {
    return this.data ? this.data.added : false;
  }

  @computed
  public get hidden() {
    return this.data ? this.data.hidden : false;
  }

  @computed
  public get tickerName() {
    return this.data ? this.data.tickerName : '';
  }

  @computed
  public get apr() {
    return this.data ? this.data.apr : '';
  }

  @computed
  public get hasApr() {
    return this.data ? this.data.hasApr : false;
  }

  @computed
  public get hasExtraApr() {
    return this.data ? this.data.hasExtraApr : false;
  }

  @computed
  public get allowRemoveFromTickersAdded() {
    return this.data ? this.data.allowRemoveFromTickersAdded : false;
  }

  @computed
  public get earned() {
    return this.data ? this.data.earned : '';
  }

  @computed
  public get hasHodlsInputAmount() {
    return this.data ? this.data.hasHodlsInputAmount : false;
  }

  @computed get hasDualsInputAmount() {
    return this.data ? this.data.hasDualsInputAmount : false;
  }

  @computed
  public get hodlsInputAmount() {
    return this.data ? this.data.hodlsInputAmount : '';
  }

  public get dualsInputAmount() {
    return this.data ? this.data.dualsInputAmount : '';
  }

  @computed
  public get equivalentTicker() {
    return this.data ? this.data.equivalentTicker : '';
  }

  @computed
  public get equivalent() {
    return this.data ? this.data.equivalent : '';
  }

  @computed
  public get equivalentRate() {
    return this.data ? this.data.equivalentRate : '';
  }

  @computed
  public get equivalentRateDiff() {
    return this.data ? this.data.equivalentRateDiff : '';
  }

  @computed
  public get hasRate() {
    return this.data ? this.data.hasRate : false;
  }

  @computed
  public get hasChart() {
    return this.data ? this.data.hasChart : false;
  }

  @computed
  public get enableHodl() {
    return this.data ? this.data.enableHodl : false;
  }

  @computed
  public get disabledDeposit() {
    return this.data ? this.data.disabledDeposit : true;
  }

  @computed
  public get disabledWithdraw() {
    return this.data ? this.data.disabledWithdraw : true;
  }

  @computed
  public get disabledConvert() {
    return this.data ? this.data.disabledConvert : true;
  }

  @computed
  public get disabledHodlForWallet() {
    return this.data ? this.data.disabledHodlForWallet : true;
  }

  private isWithdrawalAvailable = computedFn(
    ({isFiatTicker}: {isFiatTicker: boolean}) => {
      const {available, isEnabled, requirements} =
        this.resources.authme.products[
          isFiatTicker ? 'withdrawalFiat' : 'withdrawalCrypto'
        ];

      // NOTE: Withdrawal button should be active to trigger validations from the requirements list even if product itself is not available yet
      if (requirements.length > 0 && isEnabled) {
        return true;
      }

      return available && isEnabled && !requirements.length;
    }
  );

  @computed
  public get data() {
    const authme = this.resources.authme;

    const wallet = this.resources.wallets.getByTicker(this.args.ticker);

    if (!wallet) {
      return null;
    }

    const equivalentTicker = this.resources.authme.mainCurrency;

    const tickerType = wallet.tags.includes('fiat') ? 'fiat' : 'crypto';

    const tickerName = getCoinName(wallet.ticker);

    const tickerPrec = getCoinDecimalPrecision(wallet.ticker);

    const amount = wallet.amount;

    const hasAmount = amount.gt(0);

    const hasRate = ['yusd', 'ybtc'].includes(wallet.ticker)
      ? false
      : equivalentTicker !== wallet.ticker;

    const hasChart =
      hasRate &&
      !wallet.tags.includes('fiat') &&
      !wallet.tags.includes('stableCoin') &&
      wallet.chartEnabled;

    const equivalentRate = big(
      this.resources.rates.getRate(wallet.ticker, equivalentTicker)
    );

    const diff = this.resources.rates.getDiff24(
      wallet.ticker,
      equivalentTicker
    );

    const rate24hAgo = big(equivalentRate).minus(diff);

    const equivalentRateDiff = big(diff).div(rate24hAgo);

    const equivalent = big(equivalentRate.mul(wallet.amount));

    const savingApr = big(0);

    const extraApr = big(wallet.referralReward?.apr || 0);

    const apr = savingApr.plus(extraApr);

    const hasApr = apr.gt(0);

    const hasExtraApr = extraApr.gt(0);

    const earned = wallet.payout?.toEarn?.amount || 0;

    const hodlsInputAmount = wallet.hodlsInputAmount;

    const hasHodlsInputAmount = hodlsInputAmount.gt(0);
    const hasDualsInputAmount = wallet.dualsInputAmount.gt(0);

    const isHiddenTicker = this.resources.authme.hiddenWalletTickers.includes(
      wallet.ticker
    );

    const isAddedTicker = this.resources.authme.addedWalletTickers.includes(
      wallet.ticker
    );

    const isWithdrawalProductAvailable = this.isWithdrawalAvailable({
      isFiatTicker: wallet.tags.includes('fiat'),
    });

    return {
      ...wallet,
      tickerType,
      tickerName,
      ticker: wallet.ticker,
      amount: formatBigNumber(amount, tickerPrec),
      hasChart,
      hasRate,
      equivalentTicker,
      equivalentRate: formatBigNumber(equivalentRate, 4),
      equivalentRateDiff: formatPercent(equivalentRateDiff),
      equivalent: formatBigNumber(equivalent, 2),
      apr: formatPercent(apr),
      hasApr,
      hasExtraApr,
      earned: formatBigNumber(earned, tickerPrec),
      hasHodlsInputAmount,
      hasDualsInputAmount,
      hodlsInputAmount: formatBigNumber(hodlsInputAmount, tickerPrec),
      dualsInputAmount: formatBigNumber(wallet.dualsInputAmount, tickerPrec),
      added: isAddedTicker,
      hidden: isHiddenTicker,
      enableHodl: this.resources.authme.enableHodl,
      disabledDeposit: !wallet.depositEnabled,
      disabledWithdraw:
        !isWithdrawalProductAvailable || !wallet.withdrawEnabled,
      disabledConvert: !wallet.marketEnabled,
      disabledHodlForWallet: !wallet.hodlEnabled,
      allowRemoveFromTickersAdded: !(hasAmount || hasHodlsInputAmount),
      allowRemoveFromTickersHidden: isHiddenTicker,
      allowAppendToTickersHidden: !isHiddenTicker,
    };
  }

  // public actions

  @action
  public appendToShowedTickers = () => {
    if (this.data) {
      this.resources.authme.appendToShowedTickers(this.data.ticker);
    }
  };

  @action
  public appendToHiddenTickers = () => {
    if (this.data) {
      this.resources.authme.appendToHiddenTickers(this.data.ticker);
    }
  };

  @action
  public removeFromAddedTickers = () => {
    if (this.data) {
      this.resources.authme.removeFromAddedTickers(this.data.ticker);
    }
  };
}
