import * as React from 'react';
import {SvgProps, Svg, Circle} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const NotificationIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        {...props}
      >
        <Circle cx={6} cy={6} r={3} fill={color} />
      </Svg>
    );
  }
);
