import {autorun, observable, action} from 'mobx';
import {DATA_STORAGE} from '../../DataStorage';
import {ResourceInstance} from '../types';
import {BaseResourceFeature} from './BaseResourceFeature';

/** garbage collector for resources:
 * - set up timeout for garbage collector once resource data becomes unobserved;
 * - removes timeout once become observed again (if it's not too late already);
 */
export class GarbageCollector<
  Args extends {},
  Result
> extends BaseResourceFeature<Args, Result> {
  featureName = 'GarbageCollector';
  cacheTime!: number;

  onDestroy() {
    super.onDestroy();
    this.disposeOfDataObservedReaction();
    this.cancelGarbageCollector();
  }

  disposeOfDataObservedReaction!: ReturnType<typeof autorun>;

  //tracking if interval is set;
  @observable isGarbageCollectorSet: boolean = false;

  @observable wasObservedAtLeastOnce: boolean = false;

  constructor(resource: ResourceInstance<Args, Result>, cacheTime: number) {
    super(resource);

    this.cacheTime = cacheTime;

    this.disposeOfDataObservedReaction = autorun(() => {
      if (this.resource.isDataObserved) {
        // if data become observed we set flag that it was observed at least ones;
        this.wasObservedAtLeastOnce = true;
        // cancel garbage collection if there is any
        this.cancelGarbageCollector();
      } else {
        // only setting garbage collection if data was observed at least ones
        // TODO: maybe set garbage collection anyway? if resource was created but not read?..
        if (this.wasObservedAtLeastOnce) {
          this.setupGarbageCollector();
        }
      }
    }, {});
  }

  /** garbage collector timeout id */
  _garbageCollectorTimeout?: number;

  /** set up garbage collection */
  @action setupGarbageCollector = () => {
    this.logger('setupGarbageCollector');

    this.cancelGarbageCollector();

    this._garbageCollectorTimeout = setTimeout(() => {
      this.logger('deleteResource');
      DATA_STORAGE.delete(this.resource.getKey(this.resource.args));
    }, this.cacheTime) as unknown as number;

    this.isGarbageCollectorSet = true;
  };

  /** cancel garbage collection */
  @action cancelGarbageCollector = () => {
    if (this.isGarbageCollectorSet) {
      this.logger('cancelGarbageCollector');
      clearTimeout(this._garbageCollectorTimeout);
      this._garbageCollectorTimeout = undefined;
      this.isGarbageCollectorSet = false;
    }
  };
}
