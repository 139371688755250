import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';

export const BulletListItem: React.FC = ({children}) => {
  return (
    <Box flexDirection="row" mt={16}>
      <Box pt={7} mx={8}>
        <Box width={3} height={3} borderRadius={3} backgroundColor="$text-02" />
      </Box>

      <Box flexShrink={1}>
        <Text variant="$body-02" color="$text-02">
          {children}
        </Text>
      </Box>
    </Box>
  );
};
