import {SUPPORT} from '@youtoken/ui.support';
import {openIntercomChat} from '@web-app/src/utils';

export const initSupportService = () => {
  SUPPORT.provideHandlers({
    openSupportChat: message => {
      openIntercomChat(message);
    },
    reloadApp: () => {
      window.location.reload();
    },
  });
};
