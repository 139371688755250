import * as React from 'react';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {DeviceImage} from '@youtoken/ui.elements';

export interface BlankDeviceColumnProps {}

export const BlankDeviceColumn: React.FC<BlankDeviceColumnProps> = () => {
  const isMobile = useIsMobile();

  return (
    <>
      <Box alignSelf="center" mr={8}>
        <DeviceImage deviceIcon="laptop" deviceLogo="question" size="small" />
      </Box>

      <Box>
        <Text
          variant="$body-02"
          color="$text-01"
          mb={2}
          numberOfLines={1}
          ellipsizeMode="tail"
          style={
            isMobile && {
              maxWidth: 88,
            }
          }
        >
          Unrecognized device
        </Text>
      </Box>
    </>
  );
};
