import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box} from '@youtoken/ui.primitives';
import {PleaseVerifyFooterRefreshButton} from './RefreshButton';
import {PleaseVerifyFooterVerifyButton} from './VerifyButton';

export const PleaseVerifyFooter: React.FC = observer(() => {
  const {forceIdentifyWaiting} = AuthMeResource.use({});

  return (
    <Box p={24} borderTopWidth={1} borderColor="$ui-01">
      {forceIdentifyWaiting ? (
        <PleaseVerifyFooterRefreshButton />
      ) : (
        <PleaseVerifyFooterVerifyButton />
      )}
    </Box>
  );
});
