import React from 'react';
import {type WithBoxProps} from '@youtoken/ui.primitives';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {type CryptoDepositProps} from '@youtoken/ui.surfaces-wallets';
import {Modal} from '../_common';

const CryptoWithdrawalByLedger = React.lazy(() =>
  import(
    /* webpackChunkName: "crypto-withdrawal-by-ledger"*/ '@youtoken/ui.surfaces-wallets/src/CryptoWithdrawalLedger'
  ).then(result => {
    return {default: result.CryptoWithdrawalLedger};
  })
);

const CryptoWithdrawalByAddress = React.lazy(() =>
  import(
    /* webpackChunkName: "crypto-withdrawal-by-address"*/ '@youtoken/ui.surfaces-wallets/src/smart/CryptoWithdrawalWizard'
  ).then(result => {
    return {default: result.CryptoWithdrawalWizard};
  })
);

const CryptoWithdrawal: React.FC<WithBoxProps<CryptoDepositProps>> = props => {
  if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ledger-app']) {
    return <CryptoWithdrawalByLedger {...props} />;
  }

  return <CryptoWithdrawalByAddress {...props} />;
};

interface ModalCryptoWithdrawalProps
  extends React.ComponentProps<typeof Modal> {
  ticker: string;
}

export const ModalCryptoWithdrawal: React.FC<ModalCryptoWithdrawalProps> = ({
  ticker,
  ...otherProps
}) => {
  return (
    <Modal minHeight={200} {...otherProps}>
      <CryptoWithdrawal ticker={ticker} />
    </Modal>
  );
};
