import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {SavingsWidgetWelcomeExperimentA} from './ExperimentA';

export const SavingsWidgetWelcome: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    lastLevelData: {saving},
    currentLevelData: {
      saving: {shouldShowTariff},
    },
  } = LoyaltyResource.use({});

  if (!shouldShowTariff) {
    return null;
  }

  return (
    <SavingsWidgetWelcomeExperimentA saving={saving} {...boxProps}>
      {t('surface.savings.meet')}
    </SavingsWidgetWelcomeExperimentA>
  );
});
