import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BorderBoxWrapper} from '@youtoken/ui.screen-elements';
import {CreateHODLSurface} from './CreateHODLSurface';
import {getTitle} from './index.meta';
import {CreateHODLSurfaceProps} from './types';

export const CreateHODLSurfaceCell = cell(CreateHODLSurface, {
  displayName: 'CreateHODLSurface',
  CellWrap: ({children}) => (
    <WebPageWrapper
      windowTitle={getTitle()}
      title={getTitle()}
      hasBackButton
      onBackPress={() => SHARED_ROUTER_SERVICE.goBack()}
    >
      <BorderBoxWrapper flex={1}>{children}</BorderBoxWrapper>
    </WebPageWrapper>
  ),
}) as React.FC<CreateHODLSurfaceProps> & {getTitle: () => string};

CreateHODLSurfaceCell.getTitle = getTitle;
