import * as React from 'react';
import {observer} from 'mobx-react';
import {HodlHistoryResponse} from '@youtoken/ui.resource-hodl';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {InformationTableRightElement} from '@youtoken/ui.hodl-elements';

interface InformationTableSectionshistoryItem {
  historyItem: HodlHistoryResponse;
}

export const InformationTableSections: React.FC<InformationTableSectionshistoryItem> =
  observer(({historyItem}) => {
    const {t} = useTranslation();

    return (
      <InformationTable>
        <InformationTableRow
          left={t('surface.hodls.extend_tp.table.max_sl')}
          right={
            <InformationTableRightElement
              preInfoText={historyItem.mcPriceDiffFormatted}
              mainText={historyItem.mcPriceFormatted}
              additionalText={historyItem.quoteTickerFormatted}
            />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.extend_tp.table.max_tp')}
          right={
            <InformationTableRightElement
              preInfoText={historyItem.ftpPriceDiffFormatted}
              mainText={historyItem.ftpPriceFormatted}
              additionalText={historyItem.quoteTickerFormatted}
            />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.item.details_table.initial_price')}
          leftTooltipText={t(
            'surface.hodls.item.details_table.initial_price_tooltip'
          )}
          tooltipOffset={68}
          right={
            <InformationTableRightElement
              preInfoText={historyItem.initialPriceDiffFormatted}
              mainText={historyItem.initialPriceFormatted}
              additionalText={historyItem.quoteTickerFormatted}
            />
          }
        />
        {Boolean(historyItem.currentPrice) && (
          <InformationTableRow
            left={t('surface.hodls.item.details_table.current_price')}
            leftTooltipText={t(
              'surface.hodls.item.details_table.current_price_tooltip'
            )}
            tooltipOffset={55}
            right={
              <InformationTableRightElement
                mainText={historyItem.currentPriceFormatted}
                additionalText={historyItem.quoteTickerFormatted}
              />
            }
          />
        )}
        <InformationTableRow
          left={t('surface.hodls.item.details_table.total_borrowed')}
          right={
            <InformationTableRightElement
              preInfoText={historyItem.borrowedAmountDiffFormatted}
              mainText={historyItem.borrowedAmountFormatted}
              additionalText={historyItem.borrowedTickerFormatted}
            />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.item.details_table.total_collateral')}
          right={
            <InformationTableRightElement
              preInfoText={historyItem.collateralAmountDiffFormatted}
              mainText={historyItem.collateralAmountFormatted}
              additionalText={historyItem.collateralTickerFormatted}
            />
          }
        />
        <InformationTableRow
          left={t('surface.hodls.item.details_table.initial_amount')}
          right={
            <InformationTableRightElement
              preInfoText={historyItem.inputAmountDiffFormatted}
              mainText={historyItem.inputAmountFormatted}
              mainTextId="HODL_HISTORY_TABLE_ROW_INITIAL_AMOUNT_VALUE"
              additionalText={historyItem.inputTickerFormatted}
              additionalTextId="HODL_HISTORY_TABLE_ROW_INITIAL_AMOUNT_TICKER"
            />
          }
          isLast
        />
      </InformationTable>
    );
  });
