import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {createWizard} from '@youtoken/ui.elements';
import {getFiatDepositBankWireWizardScreenTitle} from '..';
import {FiatDepositBankWireWizardOpenAccount} from './OpenAccount';
import {FiatDepositBankWireWizardAlternativeRequisites} from './AlternativeRequisites';
import {FiatDepositBankWireWizardMainRequisites} from './MainRequisites';

export enum FiatDepositBankWireRequisitesWizardScreenName {
  MAIN_REQUISITES = 'mainRequisites',
  ALTERNATIVE_REQUISITES = 'alternativeRequisites',
  OPEN_ACCOUNT = 'openAccount',
}

export interface FiatDepositBankWireRequisitesWizardScreenProps {
  ticker: string;
}

export interface FiatDepositBankWireRequisitesWizardNavigator {
  [FiatDepositBankWireRequisitesWizardScreenName.MAIN_REQUISITES]: FiatDepositBankWireRequisitesWizardScreenProps;
  [FiatDepositBankWireRequisitesWizardScreenName.ALTERNATIVE_REQUISITES]: FiatDepositBankWireRequisitesWizardScreenProps;
  [FiatDepositBankWireRequisitesWizardScreenName.OPEN_ACCOUNT]: FiatDepositBankWireRequisitesWizardScreenProps;
}

const Wizard = createWizard<FiatDepositBankWireRequisitesWizardNavigator>();

const getFiatDepositBankWireWizardScreenOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {
      ticker: string;
    };
  };
}) => {
  return {
    headerShown: true,
    title: getFiatDepositBankWireWizardScreenTitle(ticker),
  };
};

export interface FiatDepositBankWireRequisitesWizardProps
  extends FiatDepositBankWireRequisitesWizardScreenProps {
  onPressBack?: () => void;
}

export const FiatDepositBankWireRequisitesWizard: React.FC<FiatDepositBankWireRequisitesWizardProps> =
  cell(({ticker, onPressBack}) => {
    return (
      <Wizard.Navigator
        initialName={
          FiatDepositBankWireRequisitesWizardScreenName.MAIN_REQUISITES
        }
        initialProps={{
          ticker,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={FiatDepositBankWireRequisitesWizardScreenName.MAIN_REQUISITES}
          component={FiatDepositBankWireWizardMainRequisites}
          options={getFiatDepositBankWireWizardScreenOptions}
        />
        <Wizard.Screen
          name={
            FiatDepositBankWireRequisitesWizardScreenName.ALTERNATIVE_REQUISITES
          }
          component={FiatDepositBankWireWizardAlternativeRequisites}
          options={getFiatDepositBankWireWizardScreenOptions}
        />
        <Wizard.Screen
          name={FiatDepositBankWireRequisitesWizardScreenName.OPEN_ACCOUNT}
          component={FiatDepositBankWireWizardOpenAccount}
          options={getFiatDepositBankWireWizardScreenOptions}
        />
      </Wizard.Navigator>
    );
  });
