import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box, Text, Heading} from '@youtoken/ui.primitives';
import {Trans} from '@youtoken/ui.elements';

export interface UpgradeToLevelMaxIncomeProps {
  periodFormatted: string;
  tickerFormatted: string;
  amountFormatted: string;
}

export const UpgradeToLevelMaxIncome: React.FC<
  UpgradeToLevelMaxIncomeProps & BoxProps
> = observer(
  ({
    periodFormatted,
    tickerFormatted,
    amountFormatted,
    children,
    ...boxProps
  }) => {
    return (
      <Box p={16} bg="$ui-background" borderRadius={12} {...boxProps}>
        <Text variant="$body-02" color="$text-02" mb={4}>
          {children}
        </Text>
        <Heading testID="ACCOUNT_LEVEL_UP_TO" color="$text-01" weight="regular">
          <Trans
            components={{
              Strong: <Text weight="bold" />,
            }}
            values={{
              amount: amountFormatted,
              ticker: tickerFormatted,
              period: periodFormatted,
            }}
            i18nKey="surface.loyalty.up_to"
          />
        </Heading>
      </Box>
    );
  }
);
