import {action, computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource, currencyName} from '@youtoken/ui.resource-auth-me';

export class MainCurrencyFeature extends createFeature({
  getKey: () => {
    return `feature:mainCurrency`;
  },
  getResources: () => {
    return {
      authme: getResourceDescriptor(AuthMeResource, {}),
    };
  },
}) {
  @computed
  get mainCurrency() {
    return this.resources.authme.mainCurrency;
  }

  @action
  setMainCurrency = (nextCurrency: currencyName) => {
    this.resources.authme.setMainCurrency(nextCurrency);
  };
}
