import * as React from 'react';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {
  ChartSection,
  InstrumentActionsSection,
  MarketStatsSection,
  OverviewInstrumentSection,
  PerformanceSection,
} from '../Instrument';
import {cell} from '@youtoken/ui.cell';
import {AllInstrumentsSection, SectorsSection} from '../InstrumentsList';

export const LedgerLiveWelcomeTradingSurface: React.FC = cell(() => {
  const {t} = useTranslation();
  // Preload RatesResource for Hodls for using in child components through __DANGEROUSLY__getInstanceStatically method
  RatesResource.use({product: 'hodl'});

  return (
    <>
      <Box
        flexDirection={{default: 'column', desktop: 'row'}}
        borderRadius={16}
        borderColor="$ui-01"
        borderWidth={1}
        mb={16}
      >
        <Box
          width={{default: '100%', desktop: '50%'}}
          borderRightWidth={1}
          borderRightColor="$ui-01"
        >
          <OverviewInstrumentSection instrumentId="btc_usdt" isStarDisabled />
          <InstrumentActionsSection instrumentId="btc_usdt" />
          <ChartSection instrumentId="btc_usdt" chartHeight={330} />
        </Box>
        <Box width={{default: '100%', desktop: '50%'}}>
          <MarketStatsSection
            baseTicker="btc"
            quoteTicker="usdt"
            isLedgerMode
          />
          <Separator />
          <PerformanceSection baseTicker="btc" quoteTicker="usdt" />
        </Box>
      </Box>
      <Box
        flexDirection="row"
        borderRadius={16}
        borderColor="$ui-01"
        borderWidth={1}
        px={24}
        py={16}
        mb={16}
      >
        <Text variant="$body-01" color="$text-02">
          {t('surface.welcome.ledger_hodl_disclaimer')}
        </Text>
      </Box>
      <Box
        px={{default: 0, desktop: 24}}
        py={24}
        flexDirection="row"
        borderRadius={16}
        borderColor="$ui-01"
        borderWidth={1}
        mb={16}
      >
        <SectorsSection width="100%" />
      </Box>
      <Box
        flexDirection="row"
        borderRadius={16}
        borderColor="$ui-01"
        borderWidth={1}
      >
        <AllInstrumentsSection
          width="100%"
          disableFilters
          separateInstrumentsSection
        />
      </Box>
    </>
  );
});
