import * as React from 'react';
import {LoanItemRegularResource} from '@youtoken/ui.resource-loans';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {type LoanRepayOwnFundsFormProps} from './types';
import {LoanRepayOwnFundsFormV1} from './LoanRepayOwnFundsFormV1';
import {LoanRepayOwnFundsFormV2} from './LoanRepayOwnFundsFormV2';

export const LoanRepayOwnFundsForm: React.FC<
  LoanRepayOwnFundsFormProps & BoxProps
> = cell(props => {
  if (props.mode === 'turbo') {
    return <LoanRepayOwnFundsFormV1 {...props} />;
  }

  return <LoanRepayOwnFundsFormRegular {...props} />;
});

export const LoanRepayOwnFundsFormRegular: React.FC<
  LoanRepayOwnFundsFormProps & BoxProps
> = cell(props => {
  const {
    data: {version},
  } = LoanItemRegularResource.use({id: props.id});

  if (version === 1) {
    return <LoanRepayOwnFundsFormV1 {...props} />;
  }

  if (version === 2) {
    return <LoanRepayOwnFundsFormV2 {...props} />;
  }

  return null;
});
