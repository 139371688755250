import {getStoredLanguage} from './storage';
import {detectEnvLanguage} from './detectEnvLanguage';
export {languages, DEFAULT_LANG} from './langList';

export const getOrDetectLocalLanguage = (): string => {
  const localLang = getStoredLanguage();

  if (!localLang) {
    return detectEnvLanguage();
  }

  return localLang;
};
