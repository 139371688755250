import * as React from 'react';
import {AuthMeResource, LegalEntity} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {NaumardInfo} from '../../DisclosureSurface/components';

export type DisclosureWalletsProps = {};

export const DisclosureWallets: React.FC<DisclosureWalletsProps> = () => {
  const {legalEntity} = AuthMeResource.use({});
  const {
    documents: {naumardDocuments},
  } = DocsResource.use({country: legalEntity});

  const {t} = useTranslation();

  if (legalEntity !== LegalEntity.ALL) {
    return null;
  }

  return (
    <>
      <Box mb={24}>
        <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
          {t('surface.profile.legal_info.subtitle.wallets_savings')}
        </Heading>

        <Text variant="$body-02" color="$text-02" mb={16}>
          {t('surface.profile.legal_info.wallets_savings.p1')}
        </Text>
        <Text variant="$body-02" color="$text-02" mb={16}>
          {t('surface.profile.legal_info.wallets_savings.p2')}
        </Text>

        <NaumardInfo mb={16} />

        <Text variant="$body-02" textTransform="uppercase" mb={12}>
          {t('surface.profile.legal_info.subtitle.documents')}
        </Text>

        {Boolean(naumardDocuments) &&
          naumardDocuments.map(item => {
            // @ts-ignore
            const itemUrl = item.i18nLink ? t(item.i18nLink) : item.link;
            // @ts-ignore
            const itemName = item.i18nName ? t(item.i18nName) : item.name;

            return (
              <Box flexDirection="row" mb={8} key={item.link}>
                <Link
                  underlined
                  url={itemUrl}
                  variant="$body-02"
                  color="$text-02"
                >
                  {itemName}
                </Link>
              </Box>
            );
          })}
      </Box>
    </>
  );
};
