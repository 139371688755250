import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const DiscountIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.971 7.69a.508.508 0 11.72-.719.508.508 0 01-.72.72zm-1.06-1.78a2.008 2.008 0 102.84 2.84 2.008 2.008 0 00-2.84-2.84zm0 7.118a.75.75 0 001.06 1.061l7.118-7.118a.75.75 0 10-1.06-1.06L5.91 13.028zm6.399 0a.508.508 0 11.718-.718.508.508 0 01-.718.718zm-1.061-1.779a2.008 2.008 0 102.84 2.84 2.008 2.008 0 00-2.84-2.84z"
          fill={color}
        />
      </Svg>
    );
  }
);
