import React, {createContext} from 'react';
import {FormikProps} from 'formik';

export type FormikContextProps = FormikProps<{}> & {
  requireConfirm?: boolean;
  confirmText?: string;
};

// @ts-ignore
export const FormikContext = createContext<any>({});

export type FormikContextProviderProps = {
  formikProps: FormikProps<{}> & {
    name?: string;
    requireConfirm?: boolean;
    confirmText?: string;
    handleSubmit: (e: Event) => void;
  };
};

export const FormikContextProvider: React.FC<FormikContextProviderProps> = ({
  children,
  formikProps,
}) => {
  return (
    <FormikContext.Provider value={{...formikProps}}>
      {children}
    </FormikContext.Provider>
  );
};
