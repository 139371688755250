import * as React from 'react';
import {AccountLevelProgressWidgetUnauthorized} from '@youtoken/ui.surface-account-levels';
import {MinerOverviewUnauthorisedResource} from '@youtoken/ui.resources-miner';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {MinerOverviewResourceContext} from '../context';
import {
  HexGrid,
  HowItWorksBanner,
  SparkForVolumeWithContext,
} from '../components';

export const MinerUnauthorizedSurfaceCell: React.FC = cell(() => {
  const {t} = useTranslation();

  const handleStartMining = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('SignUp');
  }, []);

  return (
    <MinerOverviewResourceContext.Provider
      value={MinerOverviewUnauthorisedResource}
    >
      <Box
        pb={16}
        borderWidth={1}
        borderRadius={16}
        borderColor="$ui-01"
        overflow="hidden"
      >
        <Box flexDirection="row" justifyContent="center">
          <HexGrid maxWidth={400} />
        </Box>

        <Box px={40}>
          <HowItWorksBanner />
        </Box>

        <Separator my={24} />

        <Box px={40}>
          <AccountLevelProgressWidgetUnauthorized />
          <Button my={16} size="large" onPress={handleStartMining}>
            {t('surface.welcome.ledger_miner_action_button')}
          </Button>
          <SparkForVolumeWithContext />
        </Box>
      </Box>
    </MinerOverviewResourceContext.Provider>
  );
});
