import * as React from 'react';
import {Platform, ScrollView} from 'react-native';
import type {TFuncKey} from 'react-i18next';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {BankCardsAccountDetailsResource} from '@youtoken/ui.resource-bank-cards';
import {Button} from '@youtoken/ui.buttons';
import {Separator} from '@youtoken/ui.elements';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {Clipboard} from '@youtoken/ui.utils';
import {AccountDetail} from './components';

const keyMap: Record<string, TFuncKey> = {
  fullName: 'surface.bank_cards.deposit.external.detail.fullName',
  iban: 'surface.bank_cards.deposit.external.detail.iban',
  bic: 'surface.bank_cards.deposit.external.detail.bic',
  bankCountry: 'surface.bank_cards.deposit.external.detail.bankCountry',
  bankCity: 'surface.bank_cards.deposit.external.detail.bankCity',
  bankStreet: 'surface.bank_cards.deposit.external.detail.bankStreet',
  bankPostalCode: 'surface.bank_cards.deposit.external.detail.bankPostalCode',
};

type TabExternalProps = {};

export const TabExternal: React.FC<TabExternalProps & BoxProps> = observer(
  ({...boxProps}) => {
    const {t} = useTranslation();

    const {data: fields} = BankCardsAccountDetailsResource.use({});

    const fieldsEntries = Object.entries(fields);

    const copyAll = React.useCallback(() => {
      const detailsArray = fieldsEntries.map(([field, value]) => {
        const title = t(keyMap[field] as TFuncKey);
        return `${title}:\n${value}`;
      });

      Clipboard.setString(detailsArray.join('\n\n'));

      LOCAL_NOTIFICATIONS.info({
        text: t('surface.bank_wire.wirex.requisites.copied_message'),
      });
    }, [fields]);

    return (
      <Box width="100%" flex={1} pt={24} px={24} {...boxProps}>
        <ScrollView
          keyboardDismissMode="on-drag"
          showsVerticalScrollIndicator={Platform.OS === 'web'}
        >
          <Text variant="$body-01-high-accent" color="$text-01">
            {t('surface.bank_cards.deposit.external.title')}
          </Text>
          <Box mt={8}>
            <Text variant="$body-01" color="$text-02">
              {t('surface.bank_cards.deposit.external.subtitle')}
            </Text>
          </Box>

          <Box pb={24}>
            {fieldsEntries.map(([field, value]) => (
              <AccountDetail
                key={field}
                titleKey={keyMap[field] as TFuncKey}
                text={value}
                mt={24}
              />
            ))}
          </Box>
        </ScrollView>

        <Separator mx={-24} />
        <Button
          size="medium"
          type="secondary"
          mt={24}
          mb={Platform.OS === 'android' ? 24 : 0}
          onPress={copyAll}
        >
          {t('surface.bank_cards.deposit.external.copy')}
        </Button>
      </Box>
    );
  }
);
