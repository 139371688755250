import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoWithdrawalExternalFormState} from '../state';
import {
  CryptoWithdrawalVersions,
  CryptoWithdrawalAddress,
  CryptoWithdrawalExtraId,
  CryptoWithdrawalConfirmCode,
} from '../../CryptoWithdrawalComponents';
import {CryptoWithdrawalExternalAmount} from './Amount';
import {CryptoWithdrawalExternalAgree} from './Agree';
import {CryptoWithdrawalBannerByVersion} from '../../CryptoWithdrawalBannerByVersion';

export const CryptoWithdrawalExternalContent: React.FC = observer(() => {
  const {walletTicker, walletVersionValue, shouldShowContent} =
    useForm<CryptoWithdrawalExternalFormState>();

  return (
    <>
      <Box flexGrow={1} py={24} zIndex={1}>
        <CryptoWithdrawalVersions />
        {shouldShowContent && (
          <>
            <CryptoWithdrawalBannerByVersion
              walletVersion={walletVersionValue}
              ticker={walletTicker}
            />
            <Box flexGrow={1} px={24} mt={32}>
              <CryptoWithdrawalExternalAmount />
              <CryptoWithdrawalAddress />
              <CryptoWithdrawalExtraId />
            </Box>
          </>
        )}
      </Box>
      {shouldShowContent && (
        <>
          <CryptoWithdrawalExternalAgree />
          <CryptoWithdrawalConfirmCode />
        </>
      )}
    </>
  );
});
