import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, LogoColored} from '@youtoken/ui.icons';

export interface LevelInfoSavingsItemProps extends BoxProps {
  ticker: string;
  aprFormatted: string;
}

export const LevelInfoSavingsItem: React.FC<LevelInfoSavingsItemProps> =
  observer(({ticker, aprFormatted, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box
        testID={`LEVEL_INFO_SAVINGS_ITEM_${ticker.toUpperCase()}`}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center">
          <Box mr={4}>
            <LogoColored name={ticker as LogoColoredIconName} size={16} />
          </Box>
          <Text testID="LEVEL_INFO_SAVINGS_ITEM_TICKER" variant="$body-02">
            {ticker.toUpperCase()}
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Text variant="$body-02">
            <Text
              testID="LEVEL_INFO_SAVINGS_ITEM_APR"
              variant="$body-02-high-accent"
            >
              {aprFormatted}
            </Text>{' '}
            {t('surface.wallets.apr')}
          </Text>
        </Box>
      </Box>
    );
  });
