import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {Banner} from '@youtoken/ui.elements';
import {CryptoDepositLedgerFormAmount} from './Amount';
import {CryptoDepositLedgerFormFee} from './Fee';

export const CryptoDepositLedgerFormContent: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box flexGrow={1} zIndex={1}>
      <Box flexGrow={1} p={24} zIndex={1}>
        <CryptoDepositLedgerFormAmount />
        <CryptoDepositLedgerFormFee />
      </Box>
      <Banner variant="danger">
        {t('surface.wallets.crypto_deposit.ledger_live_warning')}
      </Banner>
    </Box>
  );
});
