import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {RatesListFeature} from '@youtoken/ui.legacy-components';
import {RateDiff} from '@web-app/src/components/elements';

interface ItemProps {
  ticker: string;
}

export const Item: React.FC<ItemProps> = observer(({ticker}) => {
  const feature = RatesListFeature.use({});

  const wallet = feature.getRateObjForTicker(ticker);

  return (
    <Box
      width="100%"
      flexDirection="row"
      alignItems="center"
      height={36}
      justifyContent="space-between"
      opacity={0.6}
    >
      <Box flexDirection="row" alignItems="center">
        <Text variant="$body-02">{ticker?.toUpperCase()}</Text>
      </Box>

      <Box
        flexDirection="row"
        alignItems="center"
        flex={2}
        justifyContent="flex-end"
      >
        <Text variant="$body-02-high-accent">
          <b>{wallet.rate}</b> {wallet.toTicker?.toUpperCase()}
        </Text>
      </Box>

      <Box flexDirection="row" flex={1} justifyContent="flex-end">
        <RateDiff value={wallet.diff} />
      </Box>
    </Box>
  );
});
