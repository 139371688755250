import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {InstrumentItemBase} from './InstrumentItemBase';
import {InstrumentItemRate} from './InstrumentItemRate';
import {type OverviewInstrumentSectionProps} from './types';

export const OverviewInstrumentSection: React.FC<
  OverviewInstrumentSectionProps & BoxProps
> = observer(({instrumentId, isStarDisabled, ...boxProps}) => {
  const {
    getInstrumentById,
    toggleStarredHODLsInstrument,
    getDiffAndFormattedRate,
  } = HODLsTariffsFeature.use({});

  const item = getInstrumentById(instrumentId);

  if (!item) {
    return null;
  }

  const {diffValue, diffPercentFormatted, diffDirection, rateFormatted} =
    getDiffAndFormattedRate(item.baseTicker, item.quoteTicker);

  const handlePressStar = React.useCallback(() => {
    toggleStarredHODLsInstrument(item);
  }, [item, toggleStarredHODLsInstrument]);

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      px={24}
      pt={22}
      pb={14}
      {...boxProps}
    >
      <InstrumentItemBase
        baseTicker={item.baseTicker}
        baseTickerFormatted={item.baseTickerFormatted}
        baseTickerName={item.baseTickerName}
        quoteTickerFormatted={item.quoteTickerFormatted}
        isStarred={item.isStarred}
        onPressStar={handlePressStar}
        isStarDisabled={isStarDisabled}
      />
      <InstrumentItemRate
        alignItems="flex-end"
        rateFormatted={rateFormatted}
        quoteTickerFormatted={item.quoteTickerFormatted}
        diffValueFormatted={diffValue}
        diffPercentFormatted={diffPercentFormatted}
        diffDirection={diffDirection}
      />
    </Box>
  );
});
