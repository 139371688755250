/**
 * Removes timezone from date, which makes it possible to use Date.toLocaleDateString() without timezone
 * @param date - initial date
 * @returns date without timezone
 */
export const dateWithoutTimezone = (date: string | null): string => {
  if (!date) {
    return '';
  }

  const parts = date.split('T')[0]?.split('-');

  if (!parts) {
    return '';
  }

  return parts.reverse().join('.');
};
