import * as React from 'react';
import {observer} from 'mobx-react';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';
import {UmaInput, AmountInputs} from './components';
import {type UmaWithdrawalFormArgs} from './types';
import {UmaWithdrawalForm} from './form';

export const UmaWithdrawalSurface: React.FC<UmaWithdrawalFormArgs> = observer(
  ({ticker, uma, amount, onSuccess}) => {
    const {t} = useTranslation();

    const form = makeForm(
      () =>
        new UmaWithdrawalForm({
          ticker,
          uma,
          amount,
          onSuccess,
        })
    );

    const {
      form: {submitForm, isUmaValid, isUmaChecking},
    } = form;

    return (
      <Form form={form}>
        <Box p={24} flex={1} flexGrow={1} justifyContent="space-between">
          <Box>
            <UmaInput pb={24} />
            <AmountInputs />
          </Box>

          <Button
            size="large"
            onPress={submitForm}
            disabled={!isUmaValid}
            loading={isUmaChecking}
            testID="UMA_WITHDRAW_SUBMIT_BUTTON"
          >
            {t('surface.wallets.uma.withdrawal.success_button')}
          </Button>
        </Box>
      </Form>
    );
  }
);
