import * as React from 'react';
import {Platform} from 'react-native';
import {impactAsync, ImpactFeedbackStyle} from 'expo-haptics';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {type MinerBlockData} from '@youtoken/ui.resources-miner';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {MinerOverviewResourceContext} from '../../context';
import {type LayoutProps, Layout, HexGrid as Grid} from './_Library';
import {ClickableHexagon, Hexagon} from './Hexagon';

const RADIUS = 41;
const size = {x: RADIUS, y: RADIUS};
const origin = {x: 210, y: 210};

const GridWrapper: React.FC<{testID?: string} & LayoutProps> = ({
  testID,
  children,
}) => {
  return (
    <Box
      testID={testID}
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
    >
      <Grid width="100%" height="100%" viewBox="0 0 420 420">
        <Layout
          // the size of underlying hexagons which are used to calculate the layout
          size={size}
          flat={false}
          spacing={1.05}
          origin={origin}
        >
          {children}
        </Layout>
      </Grid>
    </Box>
  );
};

interface HexGridProps {
  onNotEnoughSparks?: (miningPrice: number) => void;
}

export const HexGrid: React.FC<HexGridProps & BoxProps> = cell(
  ({onNotEnoughSparks, ...boxProps}) => {
    const {
      blocks: hexagons,
      startMining,
      startClaiming,
      showClaimedInfo,
      getBlockFinalStatus,
    } = React.useContext(MinerOverviewResourceContext).use({});

    const haptic = async () => {
      Platform.select({
        ios: () => {
          impactAsync(ImpactFeedbackStyle.Light);
        },
        default: () => {},
      })();
    };

    const handlePress = React.useCallback(
      (block: MinerBlockData) => {
        const {id, miningPrice} = block;

        const hexState = getBlockFinalStatus(id);

        if (hexState === 'AVAILABLE_ENOUGH_SPARKS') {
          DATA_LAYER.trackStrict('miner-block-mining-attempt', {
            category: 'miner',
            type: 'block-mining',
          });

          haptic();
          startMining(id);
        }

        if (hexState === 'READY') {
          DATA_LAYER.trackStrict('miner-block-claim-attempt', {
            category: 'miner',
            type: 'block-claim',
          });

          haptic();
          startClaiming(id);
        }

        if (hexState === 'CLAIMED_GREY') {
          haptic();
          showClaimedInfo(id);
        }

        if (hexState === 'AVAILABLE_NOT_ENOUGH_SPARKS') {
          haptic();
          onNotEnoughSparks?.(miningPrice);
        }
      },
      [startMining, startClaiming, showClaimedInfo, onNotEnoughSparks]
    );

    const renderHex = React.useCallback((hex: MinerBlockData) => {
      const {
        coordinates: {q, r, s},
        id,
      } = hex;

      return (
        <Hexagon
          key={`${q},${r},${s},${id}`}
          size={RADIUS}
          q={q}
          r={r}
          s={s}
          block={hex}
        />
      );
    }, []);

    const renderClickableHex = React.useCallback(
      (hex: MinerBlockData) => {
        const {
          coordinates: {q, r, s},
          id,
        } = hex;

        return (
          <ClickableHexagon
            key={`${q},${r},${s},${id}`}
            size={RADIUS}
            q={q}
            r={r}
            s={s}
            block={hex}
            onPress={handlePress}
          />
        );
      },
      [handlePress]
    );

    return (
      <Box
        testID="HEX_GRID"
        aspectRatio={1}
        width="100%"
        height="100%"
        bg="$ui-background"
        position="relative"
        overflow="hidden"
        {...boxProps}
      >
        <GridWrapper testID="HEXAGONS">{hexagons.map(renderHex)}</GridWrapper>

        {/* a clickable overlay for an android-specific bug */}
        <GridWrapper testID="HEXAGONS_CLICKABLE">
          {hexagons.map(renderClickableHex)}
        </GridWrapper>
      </Box>
    );
  }
);
