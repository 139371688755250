import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLInstrumentResource} from '@youtoken/ui.resource-hodl-instrument';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading, Text, type BoxProps} from '@youtoken/ui.primitives';
import {ITab, Tabs} from '@youtoken/ui.tabs';
import {PriceRangeBar} from './PriceRangeBar';
import {type PriceRangeSectionProps} from './types';

export const PriceRangeSection: React.FC<PriceRangeSectionProps & BoxProps> =
  observer(({baseTicker, quoteTicker, ...boxProps}) => {
    const {t} = useTranslation();
    const {filteredPriceRange, filteredPriceRangePeriods} =
      HODLInstrumentResource.use({
        baseTicker,
        quoteTicker,
      });
    // NOTE: There is conditions for render in parent components
    const [period, setPeriod] = React.useState(filteredPriceRange![0]!.period);

    const priceRangeData = React.useMemo(() => {
      return filteredPriceRange!.find(el => {
        return el.period === period;
      });
    }, [period]);

    const handleChangePeriod = React.useCallback((val: string) => {
      setPeriod(val);
    }, []);

    const periodTabs: ITab[] = filteredPriceRangePeriods!.map(period => ({
      label: period.toUpperCase(),
      value: period,
      type: 'text',
    }));

    return (
      <Box width="100%" px={24} py={20} {...boxProps}>
        <Box mb={16} flexDirection="row" justifyContent="space-between">
          <Heading variant="$heading-02">
            {t('surface.hodls.instrument.price_range_title')}
          </Heading>
          <Box flexDirection="column" width={110}>
            <Tabs
              activeIndex={filteredPriceRangePeriods!.indexOf(period) ?? 0}
              size="tiny"
              type="ghost"
              onChange={handleChangePeriod}
              tabs={periodTabs}
            />
          </Box>
        </Box>
        {priceRangeData && (
          <>
            <Box mb={12} flexDirection="row" justifyContent="space-between">
              <Text variant="$body-01-medium-accent">
                {priceRangeData.lowPriceFormatted}
              </Text>
              <Text variant="$body-01-medium-accent">
                {priceRangeData.highPriceFormatted}
              </Text>
            </Box>
            <PriceRangeBar
              currentPricePercentFormatted={
                priceRangeData.currentPricePercentFormatted
              }
            />
            <Box flexDirection="row" justifyContent="space-between" mb={12}>
              <Text variant="$body-02" color="$text-02">
                {t('surface.hodls.instrument.price_range_low_direction')}
              </Text>
              <Text variant="$body-02" color="$text-02">
                {t('surface.hodls.instrument.price_range_high_direction')}
              </Text>
            </Box>
          </>
        )}
      </Box>
    );
  });
