import React from 'react';
import {TextInputProps, StyleSheet} from 'react-native';
import {observer} from 'mobx-react';
import {InputAmount, useForm} from '../../../../../components';
import {FeatureLoanFTPForm} from '../../state';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';

export const Amount: React.FC<TextInputProps> = observer(({...props}) => {
  const {
    form: {loanBorrowedTicker, amount, amountOnChange},
  } = useForm<FeatureLoanFTPForm>();

  return (
    <InputAmount
      precission={
        loanBorrowedTicker
          ? getCoinDecimalPrecisionForLoans(loanBorrowedTicker)
          : undefined
      }
      value={amount}
      onChangeText={amountOnChange}
      placeholder="0.00"
      style={styles.input}
      {...props}
    />
  );
});

const styles = StyleSheet.create({
  input: {
    textAlign: 'right',
  },
});
