import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';
import {CoindropBadge, CoinIconsBig} from '../../Coindrop';
import {type CoindropPromoParams} from '@youtoken/ui.resource-onboarding-widget';

type CoindropPromoProps = {
  params: CoindropPromoParams;
};

export const CoindropPromo: React.FC<CoindropPromoProps> = observer(
  ({params: {coins}}) => {
    const {t} = useTranslation();

    return (
      <Box alignItems="center">
        <CoindropBadge mb={16} />
        <CoinIconsBig coins={coins} mb={16} />
        <Heading>{t('surface.onboarding_widget.promo_content.title')}</Heading>
      </Box>
    );
  }
);
