import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ShareIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.648 8.493a.75.75 0 010-1.486L12.75 7h1.75a2 2 0 012 2v7a2 2 0 01-2 2h-9a2 2 0 01-2-2V9a2 2 0 012-2h1.75a.75.75 0 01.102 1.493L7.25 8.5H5.5a.5.5 0 00-.492.41L5 9v7a.5.5 0 00.41.492l.09.008h9a.5.5 0 00.492-.41L15 16V9a.5.5 0 00-.41-.492L14.5 8.5h-1.75l-.102-.007zM10 12.996a.75.75 0 00.75-.75V3.793L12 5.043a.707.707 0 101-1l-2.47-2.47a.75.75 0 00-1.06 0L7 4.043a.707.707 0 001 1l1.25-1.25v8.453c0 .414.336.75.75.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
