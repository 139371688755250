import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SavingsStartEarningButton} from '../../components';
import {SavingsSlider} from '../../smarts';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsSavingsSelect');
};

export const SavingsAccountWelcome: React.FC<BoxProps> = boxProps => {
  return (
    <Box testID="SAVINGS_ACCOUNT_WELCOME" flex={1} {...boxProps}>
      <SavingsSlider />
      <Box px={{default: 16, phone: 24, desktop: 0}} py={16}>
        <SavingsStartEarningButton onPress={handlePress} />
      </Box>
    </Box>
  );
};
