import * as React from 'react';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {BaseChartState} from '../../logic';
import {Label} from '../../Primitives';
import {useChartThemeContext} from '../../Providers';

export const LastValueLabel: React.FC<{
  chartState: BaseChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {getColorByDirection} = useChartThemeContext();
    const {colors} = useTheme();

    const {
      lastValueLabel: {layout},
      isCursorActive,
    } = chartState;
    if (!layout) {
      return null;
    }

    const color = getColorByDirection(layout.direction);

    return (
      <Label
        x={layout.x}
        y={layout.y}
        width={layout.width}
        height={layout.height}
        textFill={isCursorActive ? color : colors['$text-04']}
        labelFill={isCursorActive ? colors['$ui-background'] : color}
      >
        {layout.text}
      </Label>
    );
  })
);
