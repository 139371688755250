import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const BonusesIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 7.5a6 6 0 11-12 0 6 6 0 0112 0zm-6.434 2.889a4.508 4.508 0 01-1.001-.163 3.827 3.827 0 01-.565-.23l.29-.814c.183.092.403.174.66.249.262.074.559.11.89.11.393 0 .664-.056.812-.17a.568.568 0 00.231-.47.616.616 0 00-.094-.343.88.88 0 00-.265-.257 2.294 2.294 0 00-.41-.205 14.534 14.534 0 00-.514-.197 6.87 6.87 0 01-.53-.214 2.321 2.321 0 01-.471-.29 1.444 1.444 0 01-.334-.428 1.31 1.31 0 01-.128-.608c0-.393.117-.727.35-1 .235-.28.594-.46 1.079-.54v-.958h.89v.924c.273.023.518.063.735.12.217.051.388.102.514.154l-.214.847a5.329 5.329 0 00-.59-.18 3.115 3.115 0 00-.77-.086c-.309 0-.54.057-.694.172a.523.523 0 00-.23.453c0 .114.022.211.068.29a.702.702 0 00.205.206c.091.057.203.114.334.171.131.052.28.106.445.163.234.091.453.188.658.29a2.2 2.2 0 01.548.352c.154.13.274.288.36.47.09.183.136.4.136.65 0 .377-.117.702-.35.976-.234.274-.62.45-1.156.53v1.07h-.89v-1.044zM15 7.5c0 .407-.032.807-.095 1.196a4.5 4.5 0 11-6.21 6.21 7.556 7.556 0 01-1.657.08 6.001 6.001 0 107.948-7.948c.01.153.014.307.014.462z"
          fill={color}
        />
      </Svg>
    );
  }
);
