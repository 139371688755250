import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {
  HeaderBase,
  useWalletsChooseTickerItemWrapper,
  WalletsChooseTicker,
} from '../../../components';
import {
  type CryptoDepositWizardNavigator,
  CryptoDepositWizardScreenName,
} from '..';

export const CryptoDepositWizardChooseTickerBase: React.FC = observer(() => {
  const {t} = useTranslation();

  const {navigate, goBack} = useWizard<
    CryptoDepositWizardNavigator,
    CryptoDepositWizardScreenName.CHOOSE_TICKER
  >();

  const handlePress = React.useCallback(
    (ticker: string) => {
      DATA_LAYER.trackStrict('buy-wizard-currency-chosen', {
        type: 'crypto',
        ticker,
      });

      navigate(CryptoDepositWizardScreenName.DEPOSIT, {ticker});
    },
    [navigate]
  );

  const TouchableWrapper = useWalletsChooseTickerItemWrapper(handlePress);

  return (
    <>
      <HeaderBase onPressBack={goBack}>
        {t('surface.wallets.deposit_wizard.select_ticker_crypto.title')}
      </HeaderBase>
      <WalletsChooseTicker
        type="cryptoAndStable"
        method="deposit"
        ListItemWrapper={TouchableWrapper}
      />
    </>
  );
});
