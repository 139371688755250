import * as React from 'react';
import {useGesture} from 'react-use-gesture';
import {type PanResponderProps} from './types';

export type {PanResponderProps};

// from stackoveflow, may or may not be optimal but for now it kinda works;
const isTouchDevice = () => {
  return (
    !!(typeof window !== 'undefined' && 'ontouchstart' in window) ||
    !!(
      typeof navigator !== 'undefined' &&
      // @ts-ignore
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  );
};

const _shouldUseTouchEvents = isTouchDevice();

const gestureConfig = {
  hover: {
    enabled: true,
  },
  eventOptions: {
    passive: true,
    // capture: false,
    // pointer: true,
  },
  drag: {
    axis: 'x' as 'x',
    threshold: 15,
    filterTaps: true,
    delay: 180,
    bounds: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
};

/**
 * provides callback  `onCursorChange` that returns cursor X position inside the component;
 * width/height is required because we will use it with charts only that required exact size,
 * so we could as well optimize;
 *
 * - not, for optimization we only use X position, until design requires Y;
 * - __web component only__;
 */
export const PanResponder: React.FC<
  PanResponderProps & {
    style?: any;
    shouldUseTouchEvents?: boolean;
    shouldUseMouseEvents?: boolean;
  }
> = React.memo(
  ({
    children,
    width,
    height,
    onCursorChange,
    shouldUseTouchEvents = _shouldUseTouchEvents,
    shouldUseMouseEvents = !shouldUseTouchEvents,
    style,
    ...props
  }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const _setCursorX = React.useCallback(
      (x?: number) => {
        if (onCursorChange) {
          if (x && x >= 0 && x <= width) {
            onCursorChange(x);
          } else {
            onCursorChange(undefined);
          }
        }
      },
      [onCursorChange]
    );

    const getCursorX = React.useCallback(
      ({xy: [eventXCoord]}: {xy: number[]}) => {
        if (!eventXCoord) {
          return undefined;
        }

        const offset = ref.current?.getBoundingClientRect().x ?? 0;

        return eventXCoord - offset;
      },
      []
    );

    const bind = useGesture(
      {
        onDrag: event => {
          if (shouldUseTouchEvents) {
            _setCursorX(getCursorX(event));
          }
        },
        onDragEnd: () => {
          if (shouldUseTouchEvents) {
            _setCursorX(undefined);
          }
        },
        onHover: () => {},
        onMouseLeave: () => {
          if (shouldUseMouseEvents) {
            _setCursorX(undefined);
          }
        },
        onMove: event => {
          if (shouldUseMouseEvents) {
            _setCursorX(getCursorX(event));
          }
        },
      },
      gestureConfig
    );

    const _style = React.useMemo(
      () => ({
        touchAction: 'pan-y',
        ...(style ? style : {}),
        width: width,
        height: height,
      }),
      [style, width, height]
    );

    return (
      <div style={_style} ref={ref} {...bind()} {...props}>
        {children}
      </div>
    );
  }
);
