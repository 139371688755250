import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Wallet} from '@youtoken/ui.resource-wallets';
import {
  SavingsOverviewStatus,
  SavingsOverviewResource,
} from '@youtoken/ui.resource-savings';
import {Text} from '@youtoken/ui.primitives';
import {SavingsApr} from '@youtoken/ui.surface-savings';

export interface SavingsSummaryAprSmartProps {
  item: Wallet;
}

export const SavingsSummaryAprSmart: React.FC<SavingsSummaryAprSmartProps> = ({
  item,
}) => {
  const {hasSavings, savingVersion} = AuthMeResource.use({});

  if (!hasSavings) {
    return null;
  }

  if (savingVersion === 4) {
    return <SavingsSummaryAprV4 item={item} />;
  }

  return <SavingsSummaryAprLegacy item={item} />;
};

export const SavingsSummaryAprV4: React.FC<SavingsSummaryAprSmartProps> =
  observer(({item}) => {
    const {
      data: {status, level, tickers},
    } = SavingsOverviewResource.use({});

    const saving = tickers?.[item.ticker];

    if (status !== SavingsOverviewStatus.OPEN || !saving) {
      return null;
    }

    return <SavingsApr level={level!} aprFormatted={saving.aprFormatted} />;
  });

export const SavingsSummaryAprLegacy: React.FC<SavingsSummaryAprSmartProps> = ({
  item,
}) => {
  const {t} = useTranslation();

  if (!item.hasApr) {
    return null;
  }

  return (
    <Text
      variant="$body-02"
      color="$interactive-01"
      mx={4}
      testID={`${item.tickerFormatted}_APR`}
    >
      {item.aprFormatted} {t('surface.wallets.apr')}
    </Text>
  );
};
