import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';

type ExpirationButtonProps = {
  onPress: () => void;
};

export const ExpirationButton: React.FC<ExpirationButtonProps & BoxProps> =
  observer(({onPress, ...boxProps}) => {
    const {t} = useTranslation();
    const {incentivesBurningIn} = IncentivesFeature.use({});

    if (!incentivesBurningIn) {
      return null;
    }

    return (
      <TouchableBox
        borderWidth={1}
        borderColor="$ui-01"
        borderRadius={8}
        flexDirection="row"
        alignItems="center"
        p={8}
        onPress={onPress}
        {...boxProps}
      >
        <Box flexDirection="row" alignItems="center" gap={4}>
          <Icon name="pending" color="$text-01" size={16} />

          <Text variant="$body-02" color="$text-01">
            {t('surface.incentives.expiration_button.expires_in', {
              period: incentivesBurningIn,
            })}
          </Text>
        </Box>

        <Box ml={8} flexDirection="row" alignItems="center" gap={2}>
          <Text variant="$body-02" color="$text-05">
            {t('surface.incentives.expiration_button.prolong')}
          </Text>
          <Icon name="chevron_right" size={16} color="$text-05" />
        </Box>
      </TouchableBox>
    );
  });
