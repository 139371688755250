import * as React from 'react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {LoanItemRegularResource} from '@youtoken/ui.resource-loans';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {LoanRepayOwnFundsFormV2State} from './state';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {cell} from '@youtoken/ui.cell';
import type {LoanRepayOwnFundsFormProps} from '../types';
import {LoanRepayOwnFundsFormLayout} from '../components';
import {type BoxProps} from '@youtoken/ui.primitives';

export const LoanRepayOwnFundsFormV2: React.FC<
  LoanRepayOwnFundsFormProps & BoxProps
> = cell(({mode, id, showHeader = true, onPressBack, ...boxProps}) => {
  const authMeResource = AuthMeResource.use({});

  const resources = useResources({
    docsResource: getResourceDescriptor(DocsResource, {
      country: authMeResource.residenceOrCountry,
    }),
    walletsResource: getResourceDescriptor(WalletsResource, {}),
    loanResource: getResourceDescriptor(LoanItemRegularResource, {
      id,
    }),
    ratesResource: getResourceDescriptor(RatesResource, {}),
    feeAllResource: getResourceDescriptor(FeeAllResource, {}),
  });

  const state = makeForm(() => {
    return new LoanRepayOwnFundsFormV2State(
      {
        mode,
        id,
      },
      resources
    );
  });

  return (
    <Form form={state}>
      <LoanRepayOwnFundsFormLayout
        showHeader={showHeader}
        onPressBack={onPressBack}
        {...boxProps}
      />
    </Form>
  );
});
