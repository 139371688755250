import {useMemo} from 'react';

export const useIsWebView = () => {
  // @ts-ignore
  const standalone = window.navigator.standalone;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);

  return useMemo(() => {
    return (ios && !standalone && !safari) || userAgent.includes('wv');
  }, [window.navigator]);
};
