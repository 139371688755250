import {HodlTariffResponseItem} from '@youtoken/ui.resource-hodl-tariffs';
import {WalletComboboxItem} from '@youtoken/ui.ticker-and-amount-input';

export const getSourceWallets = <T extends WalletComboboxItem>(
  tariff: HodlTariffResponseItem,
  wallets: T[]
): T[] => {
  const allowedWallets = wallets.filter(w =>
    tariff.allowedInputTickers.includes(w.ticker)
  );

  if (allowedWallets.length === 0) {
    return [];
  }

  const walletsWithAmount = allowedWallets.filter(i => i.hasAmount);

  if (walletsWithAmount.length > 0) {
    return walletsWithAmount;
  }

  const usdWallet = allowedWallets.find(i => i.ticker === 'usd');

  if (usdWallet) {
    return [usdWallet];
  }

  return [allowedWallets[0]!];
};
