import {setUser as _setUser, addBreadcrumb} from '@sentry/core';

export interface YouHodlerUserLikeModel {
  id: string;
  email: string;
}

export const setUser = <U extends YouHodlerUserLikeModel>(user: U) => {
  _setUser({
    id: user.id,
    email: user.email,
  });

  addBreadcrumb({
    message: 'User context set',
    type: 'user',
    category: 'user',
    data: user,
  });
};

/** resets user, effectively a sign-out */
export const resetUser = () => {
  _setUser(null);

  addBreadcrumb({
    message: 'User context reset',
    type: 'user',
    category: 'user',
  });
};
