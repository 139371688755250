import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {CheckboxField, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../../../state/WithdrawalForm';

export const FieldAgreement: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    view: {showAgreementField},
  } = useForm<WithdrawalForm>();

  if (!showAgreementField) {
    return null;
  }

  return (
    <CheckboxField
      px={24}
      pb={24}
      name="agree"
      testID="FIAT_WITHDRAWAL_BANK_WIRE_AGREEMENT"
    >
      <Box mr={20} flexShrink={1}>
        <Text variant="$body-02" color="$text-01">
          {t('surface.wallets.withdrawal_bank_wire_form.agree')}
        </Text>
      </Box>
    </CheckboxField>
  );
});
