import * as React from 'react';
import {View, StyleSheet} from 'react-native';
import {ActivityIndicator} from '@youtoken/ui.elements';

export const DefaultSuspenseFallback = () => {
  return (
    <View style={styles.wrap}>
      <ActivityIndicator />
    </View>
  );
};

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
