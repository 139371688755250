import * as React from 'react';
import {observer} from 'mobx-react';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';

export const RegistrationLink: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <>
      <Link
        url="https://www.organismo-am.it/elenchi-registri/operatori_valute_virtuali/index.html"
        variant="$body-02"
        color="$text-01"
        mb={8}
      >
        {t('company.youhodler.legal_documents.oam_registration.name')}
      </Link>
      <Text>
        <Link
          url="https://app.bde.es/rbe_spa/#/buscador"
          variant="$body-02"
          color="$text-01"
        >
          {t(
            'company.youhodler.legal_documents.bank_of_spain_registration.name'
          )}{' '}
        </Link>
        <Text variant="$body-02" color="$text-02">
          Código BE: D939
        </Text>
      </Text>
    </>
  );
});
