import {invariant} from '@youtoken/ui.utils';
import {HexCoords} from './MinerOverviewResponse';
import type {IBlockBackendResponse} from './types';

// just disabled "empty" block like backend would return;
export const createEmptyDisabledBlock = (
  coordinates: HexCoords
): IBlockBackendResponse => {
  const {q, r, s} = coordinates;
  return {
    id: `${q}${r}${s}`,
    coordinates,
    earnAmount: '0',
    earnAmountTicker: 'btc',
    miningPrice: 0,
    status: 'DISABLED',
    timeLeft: 0,
  };
};

// add fields to all the disabled blocks in the collection
export const hydrateDisabledBlocks = (
  blocks: Partial<IBlockBackendResponse>[]
): IBlockBackendResponse[] => {
  return blocks.map(block => {
    invariant(block.coordinates, 'block coordinates are required');

    if (block.status === 'DISABLED') {
      return createEmptyDisabledBlock(block.coordinates!);
    }
    return block as IBlockBackendResponse;
  });
};
