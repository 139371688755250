import * as React from 'react';
import {Heading, Box, BoxProps} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface FormAHeaderProps extends BoxProps {}

export const FormAHeader: React.FC<FormAHeaderProps> = boxProps => {
  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Heading variant="$heading-02-responsive" textAlign="center">
        {t('surface.more.verification_tier.form_a.title')}
      </Heading>
      <Separator mt={24} mx={-24} />
    </Box>
  );
};
