import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SignOutIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.75 5A2.75 2.75 0 0014 2.25h-3a.75.75 0 000 1.5h3c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25h-3a.75.75 0 000 1.5h3A2.75 2.75 0 0016.75 15V5zm-10.72.97a.75.75 0 00-1.06 0l-3.5 3.5a.75.75 0 000 1.06l3.5 3.5a.75.75 0 001.06-1.06l-2.22-2.22H10a.75.75 0 000-1.5H3.81l2.22-2.22a.75.75 0 000-1.06z"
          fill={color}
        />
      </Svg>
    );
  }
);
