import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {Box} from '@youtoken/ui.primitives';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {
  ConversionPairSourceAuthed,
  ConversionPairTarget,
  FormFooter,
  InfoTableV2,
  TwoFA,
} from '../../..';
import {BuyCryptoFormAuthorizedYouHodlerState} from '../../../../state';
import {DepositMethodRampEnum} from '../../../../types';
import {getTitle} from '../../utils';
import {HeaderBase} from '../../../../../../components';
import {PayWithField} from '../../../PayWith';
import {
  type BuyCryptoFormAuthorizedYouHodlerWizardNavigator,
  BuyCryptoFormAuthorizedYouHodlerWizardScreenName,
} from '..';
import {IncentivesBonuses} from '../../../IncentivesBonuses';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const BuyCryptoFormAuthorizedYouHodlerMain: React.FC = observer(() => {
  const {navigate, goBack} = useWizard<
    BuyCryptoFormAuthorizedYouHodlerWizardNavigator,
    BuyCryptoFormAuthorizedYouHodlerWizardScreenName.MAIN
  >();

  const {
    form: {conversionTicker, depositMethod},
  } = useForm<BuyCryptoFormAuthorizedYouHodlerState>();

  const handlePress = React.useCallback(() => {
    navigate(BuyCryptoFormAuthorizedYouHodlerWizardScreenName.CHOOSE_METHOD);
  }, [navigate]);

  return (
    <>
      {showHeader && (
        <HeaderBase onPressBack={goBack}>
          {getTitle(conversionTicker)}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          style: {flex: 1, overflow: 'visible'},
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            overflow: 'visible',
          },
        }}
      >
        <Box p={24} pb={0} justifyContent="space-between">
          <Box>
            <ConversionPairSourceAuthed />
            <ConversionPairTarget mt={24} />
            <PayWithField onPress={handlePress} mt={24} />
            <IncentivesBonuses mt={24} />
          </Box>
          <Box>
            {depositMethod !== DepositMethodRampEnum.BANK_WIRE && (
              <InfoTableV2 mt={24} />
            )}
            <TwoFA mt={24} />
          </Box>
        </Box>
        <FormFooter />
      </KeyboardScrollView>
    </>
  );
});
