import * as React from 'react';
import {Helmet} from 'react-helmet';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {NotificationsWidget} from '@youtoken/ui.notifications';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Content} from './Content';
import {InstaStoriesResource} from '@youtoken/ui.resource-insta-stories';
import {RouterResource} from '../../../routes/RouterResource';

export const WalletsList: React.FC = cell(() => {
  const {t} = useTranslation();
  const {
    userEmail,
    data: {enableTopBar},
  } = AuthMeResource.use({});
  const {updateActiveStoryIndex} = InstaStoriesResource.use({});
  const {
    queryParams: {storyId},
  } = RouterResource.use({});

  React.useEffect(() => {
    if (storyId) {
      updateActiveStoryIndex(storyId);
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  return (
    <InnerPageShell>
      <Helmet title={t('surface.wallets.tab_title')} />
      {enableTopBar && (
        <NotificationsWidget mb={16} mx={{default: 20, desktop: 0}} />
      )}
      <Content />
    </InnerPageShell>
  );
});
