import * as React from 'react';
import {observer} from 'mobx-react';
import {Animated} from 'react-native';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.elements';
import {invariant} from '@youtoken/ui.utils';
import {
  BankCardsResource,
  type IBankCard,
} from '@youtoken/ui.resource-bank-cards';
import {SliderItem, Details} from './components';

export const Content: React.FC = observer(() => {
  const isMobile = useIsMobile();
  const {cards} = BankCardsResource.use({});

  const initialIndex = 0;

  // cards todo: look into Ilya's comment here: "wtf? Need to fix slider inside?"
  const [activeIndex, setActiveIndex] = React.useState(initialIndex);
  const [currentIndex, setCurrentIndex] = React.useState(initialIndex);

  const opacityAnimation = React.useRef(new Animated.Value(1)).current;
  const opacityStyle = {
    opacity: opacityAnimation.interpolate({
      inputRange: [0, 0.25, 0.35, 0.5, 0.75, 1],
      outputRange: [0, 0, 0.2, 0.5, 0.75, 1],
    }),
  };

  const renderItem = React.useCallback(
    ({item, index}: {item: IBankCard; index: number}) => (
      <SliderItem
        key={index}
        data={item}
        onPress={() => setCurrentIndex(index)}
      />
    ),
    [activeIndex]
  );

  const handleProgressChange = React.useCallback((floatIndex: number) => {
    const opacity = Math.abs(1 - (floatIndex % 1) * 2);
    const index = Math.round(floatIndex);

    setActiveIndex(activeIndexState => {
      if (activeIndexState !== index) {
        opacityAnimation.setValue(0);
      } else {
        Animated.timing(opacityAnimation, {
          toValue: opacity,
          duration: 100,
          useNativeDriver: true,
        }).start();
      }

      return index;
    });
  }, []);

  invariant(
    cards[activeIndex],
    'No card found for active index',
    {},
    {cards, activeIndex}
  );

  return (
    <Box flexGrow={1}>
      <Box height={cards.length > 1 ? 192 : 162} mb={8}>
        <Slider
          data={cards}
          renderItem={renderItem}
          initialIndex={initialIndex}
          currentIndex={currentIndex}
          cardWidthMax={240}
          gap={isMobile ? 16 : 12}
          onProgressChange={handleProgressChange}
        />
      </Box>

      <Box flexGrow={1} alignItems="stretch">
        <Animated.View style={[opacityStyle, {flexGrow: 1}]}>
          <Details card={cards[activeIndex]!} />
        </Animated.View>
      </Box>
    </Box>
  );
});
