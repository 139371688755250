import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {Icon} from '@youtoken/ui.icons';
import {
  HodlTariffResponseItem,
  HodlsTariffsResource,
} from '@youtoken/ui.resource-hodl-tariffs';
import {
  getSettlementFeePercentFormatted,
  getSettlementFeeSign,
} from '@youtoken/ui.hodls-utils';

type PromoSectionProps = Pick<
  HodlTariffResponseItem,
  'baseTicker' | 'quoteTicker'
>;

export const PromoSection: React.FC<PromoSectionProps & BoxProps> = observer(
  ({baseTicker, quoteTicker, ...boxProps}) => {
    const {t} = useTranslation();

    const {getBestRolloverTariffByTickers} = HodlsTariffsResource.use({});
    const {settlementPeriod, settlementFee} = getBestRolloverTariffByTickers(
      baseTicker,
      quoteTicker
    );

    const settlementFeeSign = getSettlementFeeSign(settlementFee);
    const settlementFeePercentFormatted = getSettlementFeePercentFormatted(
      settlementFee,
      true
    );

    return (
      <Box
        backgroundColor="$success-02"
        px={24}
        py={12}
        mb={-1}
        zIndex={1}
        {...boxProps}
      >
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box flexDirection="row" alignItems="center">
            <Box mr={4}>
              <Text variant="$body-02" color="$text-02">
                {t('surface.hodls.instruments.rollover_promo.title')}
              </Text>
            </Box>
            <QuestionTooltip
              content={t('surface.hodls.instruments.rollover_promo.tooltip')}
            />
          </Box>

          <Box flexDirection="row" alignItems="center">
            <Box mr={2}>
              <Icon name="discount" color="$success-01" />
            </Box>
            <Text
              variant="$body-02"
              color={settlementFeeSign === '+' ? '$success-01' : '$text-02'}
            >{`${settlementFeePercentFormatted}% `}</Text>

            <Text variant="$body-02" color="$text-02">
              {t('surface.hodls.common.per_period', {period: settlementPeriod})}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  }
);
