import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {type Wallet} from '@youtoken/ui.resource-wallets';
import {AddWalletsItemButton, TouchableWrapper} from './components';

interface AddWalletsItemWrapperProps {
  item: Wallet;
}

export const AddWalletsItemWrapper: React.FC<AddWalletsItemWrapperProps> =
  observer(({item, children}) => {
    const {ticker, hasAmountOrHodlAmount: disabled} = item;

    const {addedWalletTickers, appendToShowedTickers, removeFromAddedTickers} =
      AuthMeResource.use({});

    const handlePress = React.useCallback(() => {
      if (!addedWalletTickers.includes(ticker)) {
        DATA_LAYER.trackStrict('wallet-add-attempt', {ticker});
        appendToShowedTickers(ticker);
      } else {
        DATA_LAYER.trackStrict('wallet-remove-attempt', {ticker});
        removeFromAddedTickers(ticker);
      }
    }, [
      ticker,
      addedWalletTickers,
      appendToShowedTickers,
      removeFromAddedTickers,
    ]);

    return (
      <TouchableWrapper item={item} onPress={handlePress}>
        {children}
        <AddWalletsItemButton
          item={item}
          disabled={disabled}
          onPress={handlePress}
        />
      </TouchableWrapper>
    );
  });
