import * as React from 'react';
import {Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Clipboard} from '@youtoken/ui.utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {useTranslation} from '@youtoken/ui.service-i18n';

export type ClickableIdProps = {
  id: string;
  notificationKey?: string;
} & React.ComponentProps<typeof TouchableBox>;

export const ClickableId: React.FC<ClickableIdProps> = ({
  id,
  notificationKey = 'common.elements.clickable_id.notification',
  ...props
}) => {
  const shortId = id.split('-')[0]!.toUpperCase();

  const {t} = useTranslation();

  const handlePress = React.useCallback(() => {
    Clipboard.setString(id);

    LOCAL_NOTIFICATIONS.info({
      // @ts-ignore
      text: t(notificationKey),
    });
  }, [id]);

  return (
    <TouchableBox
      onPress={handlePress}
      flexDirection="row"
      alignItems="center"
      {...props}
    >
      <Text fontSize={12} color="$text-05" variant="$body-02" mr={3}>
        {shortId}
      </Text>
      <Icon name="copy" color="$text-05" size={16} />
    </TouchableBox>
  );
};
