import * as React from 'react';
import {observer} from 'mobx-react';
import {SavingsOverviewStatus} from '@youtoken/ui.resource-savings';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {
  SavingsClaimEarningButton,
  SavingsStartEarningButton,
} from '../../../components';
import {SavingsProgressSmart, SavingsEarnedSmart} from '../..';
import {SavingsWidgetLayout} from '../Layout';

interface SavingsWidgetProgressProps {
  status: SavingsOverviewStatus;
}

export const SavingsWidgetProgress: React.FC<
  SavingsWidgetProgressProps & BoxProps
> = observer(({status, ...boxProps}) => {
  return (
    <SavingsWidgetLayout
      variant="secondary"
      primaryContent={<SavingsProgressSmart />}
      secondaryContent={
        <>
          {status === SavingsOverviewStatus.READY && (
            <SavingsClaimEarningButton size="small" alignSelf="center" />
          )}
          {status === SavingsOverviewStatus.OPEN && <SavingsEarnedSmart />}
          {status === SavingsOverviewStatus.CLAIMED && (
            <SavingsStartEarningButton size="small" alignSelf="center" />
          )}
        </>
      }
      {...boxProps}
    />
  );
});
