import React from 'react';
import {observer} from 'mobx-react';
import {ErrorBoundary, ErrorFallbackProps} from '@youtoken/ui.error-boundary';
import {DefaultSuspenseFallback} from './DefaultSuspenseFallback';

export {DefaultSuspenseFallback};

export type CellParams<ComponentProps extends {}> = {
  SuspenseFallback?: React.FC<ComponentProps>;
  ErrorFallback?: React.FC<ErrorFallbackProps>;
  CellWrap?: React.FC<ComponentProps>;
  displayName?: string;
};

export const cell = <ComponentProps extends {}>(
  Component: React.FC<ComponentProps>,
  params: CellParams<ComponentProps> = {}
): React.FC<ComponentProps> => {
  const {
    SuspenseFallback = DefaultSuspenseFallback,
    ErrorFallback,
    CellWrap,
    displayName,
  } = params;

  const ObserverComponent = observer(Component);
  const cellDisplayName = displayName ? `cell<${displayName}>` : `cell`;

  const CellRenderingComponent: React.FC<ComponentProps> = props => {
    const base = (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        tags={{cell: cellDisplayName}}
      >
        <React.Suspense fallback={<SuspenseFallback {...props} />}>
          <ObserverComponent {...props} />
        </React.Suspense>
      </ErrorBoundary>
    );

    return CellWrap ? <CellWrap {...props}>{base}</CellWrap> : base;
  };

  CellRenderingComponent.displayName = cellDisplayName;

  return CellRenderingComponent;
};
