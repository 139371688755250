import * as React from 'react';
import {Platform, LayoutAnimation} from 'react-native';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Icon, type BaseIconName} from '@youtoken/ui.icons';
import {Box, Text, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {WalletFeatureArgs, WalletFeature} from '../../../../../../state';
import {getTestIDPrefix} from './utils';

export interface ActionsAdditionalWebItemNewProps {
  icon?: string;
  title: string;
  testID?: string;
  onPress: () => void;
}

export interface ActionsAdditionalWebNewProps extends BoxProps {
  ticker?: string;
  items: Array<ActionsAdditionalWebItemNewProps>;
  onChangeOpened?: (opened: boolean) => void;
  styleButton?: any;
  styleContent?: any;
}

export const ActionsAdditionalWebItemNew: React.FC<ActionsAdditionalWebItemNewProps> =
  React.memo(({icon, title, testID, onPress}) => {
    return (
      <Box borderRadius={5} width="100%" height={34}>
        <TouchableBox
          flexDirection="row"
          alignItems="center"
          width="100%"
          height="100%"
          px={10}
          py={5}
          onPress={onPress}
          testID={testID}
        >
          <Box mr={5}>
            <Box width={20} height={20}>
              {icon && <Icon name={icon as BaseIconName} color="$text-01" />}
            </Box>
          </Box>
          <Text color="$text-01">{title}</Text>
        </TouchableBox>
      </Box>
    );
  });

const ActionsAdditionalNativeItemNew: React.FC<ActionsAdditionalWebItemNewProps> =
  React.memo(({icon, title, onPress}) => {
    return (
      <TouchableBox
        justifyContent="center"
        alignItems="center"
        width={100}
        height="100%"
        bg="$interactive-01"
        onPress={onPress}
      >
        {icon && (
          <Box mb={2}>
            <Icon name={icon as BaseIconName} color="$text-04" />
          </Box>
        )}
        <Text variant="$body-03" color="$text-04">
          {title}
        </Text>
      </TouchableBox>
    );
  });

export const ActionsAdditionalItemNew = Platform.select({
  web: ActionsAdditionalWebItemNew,
  default: ActionsAdditionalNativeItemNew,
})!;

export const ActionsAdditionalWebNew: React.FC<ActionsAdditionalWebNewProps> =
  React.memo(
    ({
      ticker,
      styleButton: _styleButton = {},
      styleContent: _styleContent = {},
      items,
      onChangeOpened,
      ...rest
    }) => {
      const [opened, setOpened] = React.useState(false);

      const ref = React.useRef<HTMLDivElement>();

      const styleButton = React.useMemo(() => {
        return [
          {
            width: 32,
            height: 32,
          },
          _styleButton,
        ];
      }, [_styleButton]);

      const styleContent = React.useMemo(() => {
        return [
          {
            boxShadow: '0 5px 30px 0 rgba(0,0,0,0.12)',
          },
          _styleContent,
        ];
      }, [_styleContent]);

      const handleChangeOpened = React.useCallback(
        (value: boolean) => {
          setOpened(value);

          if (onChangeOpened) {
            onChangeOpened(value);
          }
        },
        [setOpened, onChangeOpened]
      );

      const handleClickOutside = React.useCallback(
        (event: MouseEvent) => {
          if (
            event.target instanceof Element &&
            ref.current &&
            !ref.current.contains(event.target) &&
            opened
          ) {
            handleChangeOpened(false);
          }
        },
        [ref, opened, handleChangeOpened]
      );

      const handleToggleOpen = React.useCallback(() => {
        handleChangeOpened(!opened);
      }, [opened, handleChangeOpened]);

      const createHandlePressItem = React.useCallback(
        (next?: () => void) => () => {
          handleChangeOpened(false);
          next && next();
        },
        [handleChangeOpened]
      );

      React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [handleClickOutside]);

      return (
        <Box ref={ref} alignItems="flex-end" position="relative" {...rest}>
          <TouchableBox
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            onPress={handleToggleOpen}
            style={styleButton}
            testID={`${getTestIDPrefix(ticker)}MENU_BUTTON`}
          >
            <Icon
              name="actions"
              size={16}
              color={opened ? '$text-02' : '$text-03'}
            />
          </TouchableBox>
          {opened && (
            <Box
              bg="$ui-background"
              position="absolute"
              top={40}
              right={0}
              p={5}
              borderRadius={10}
              overflow="hidden"
              style={styleContent}
            >
              {items.map(({onPress, ...rest}, index) => {
                return (
                  <ActionsAdditionalItemNew
                    key={index}
                    onPress={createHandlePressItem(onPress)}
                    {...rest}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      );
    }
  );

export const ActionsAdditionalNativeNew: React.FC<ActionsAdditionalWebNewProps> =
  React.memo(({items}) => {
    return (
      <Box flexDirection="row">
        {items.map((item, index) => {
          return <ActionsAdditionalItemNew key={index} {...item} />;
        })}
      </Box>
    );
  });

export const ActionsAdditionalNew = Platform.select({
  web: ActionsAdditionalWebNew,
  default: ActionsAdditionalNativeNew,
})!;

//

export interface ActionsAdditionalProps extends BoxProps {
  ticker: WalletFeatureArgs['ticker'];
  onPressShow?: () => void;
  onPressHide?: () => void;
  onChangeOpened?: ActionsAdditionalWebNewProps['onChangeOpened'];
}

export const ActionsAdditionalWeb: React.FC<ActionsAdditionalProps> = observer(
  ({onChangeOpened, ...rest}) => {
    const items = useItems(rest);

    return (
      <ActionsAdditionalWebNew
        ticker={rest.ticker}
        items={items}
        onChangeOpened={onChangeOpened}
        styleContent={{
          width: 130,
        }}
      />
    );
  }
);

export const ActionsAdditionalNative: React.FC<ActionsAdditionalProps> =
  observer(props => {
    const items = useItems(props);

    return <ActionsAdditionalNew ticker={props.ticker} items={items} />;
  });

export const ActionsAdditional = Platform.select({
  web: ActionsAdditionalWeb,
  default: ActionsAdditionalNative,
})!;

export const useItems = ({
  ticker: _ticker,
  onPressShow,
  onPressHide,
}: ActionsAdditionalProps) => {
  const {t} = useTranslation();

  const {
    ticker,
    allowRemoveFromTickersAdded,
    hidden,
    appendToShowedTickers,
    appendToHiddenTickers,
    removeFromAddedTickers,
  } = WalletFeature.use({ticker: _ticker});

  const isNative = Platform.select({
    web: false,
    default: true,
  });

  const layoutAnimationConfig = React.useMemo(() => {
    return {
      duration: 400,
      update: {
        type: LayoutAnimation.Types.spring,
        springDamping: 0.5,
      },
    };
  }, []);

  const handleFormatTitle = React.useCallback(
    (title: string) => {
      if (isNative) {
        return title.toUpperCase();
      }

      return title;
    },
    [isNative]
  );

  const handlePressShow = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-show-attempt', {ticker});

    LayoutAnimation.configureNext(layoutAnimationConfig);
    appendToShowedTickers();

    if (onPressShow) {
      onPressShow();
    }
  }, [ticker, onPressShow, layoutAnimationConfig, appendToShowedTickers]);

  const handlePressHide = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-hide-attempt', {ticker});

    LayoutAnimation.configureNext(layoutAnimationConfig);
    appendToHiddenTickers();

    if (onPressHide) {
      onPressHide();
    }
  }, [ticker, onPressHide, layoutAnimationConfig, appendToHiddenTickers]);

  const handlePressRemove = React.useCallback(() => {
    DATA_LAYER.trackStrict('wallet-remove-attempt', {ticker});

    LayoutAnimation.configureNext(layoutAnimationConfig);
    removeFromAddedTickers();
  }, [ticker, layoutAnimationConfig, removeFromAddedTickers]);

  return React.useMemo(() => {
    const testIDPrefix = getTestIDPrefix(ticker);
    let items: ActionsAdditionalWebItemNewProps[] = [];

    if (hidden) {
      items.push({
        icon: 'show',
        title: handleFormatTitle(t('surface.wallets.item.show')),
        onPress: handlePressShow,
        testID: `${testIDPrefix}SHOW_BUTTON`,
      });
    } else {
      items.push({
        icon: 'hide',
        title: handleFormatTitle(t('surface.wallets.item.hide')),
        onPress: handlePressHide,
        testID: `${testIDPrefix}HIDE_BUTTON`,
      });
    }

    if (allowRemoveFromTickersAdded) {
      items.push({
        icon: 'remove',
        title: handleFormatTitle(t('surface.wallets.item.remove')),
        onPress: handlePressRemove,
        testID: `${testIDPrefix}REMOVE_BUTTON`,
      });
    }

    return items;
  }, [
    t,
    ticker,
    hidden,
    allowRemoveFromTickersAdded,
    handleFormatTitle,
    handlePressShow,
    handlePressHide,
    handlePressRemove,
  ]);
};
