import * as React from 'react';
import {Box, type BoxProps, Text, TColorTokens} from '@youtoken/ui.primitives';

type ItemProps = {
  text: string;
  color?: keyof TColorTokens;
};

export const Item: React.FC<ItemProps & BoxProps> = ({
  text,
  color = '$text-01',
  ...boxProps
}) => {
  return (
    <Box
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      py={8}
      px={16}
      borderColor="$interactive-03"
      borderRightWidth={1}
      mr={-1}
      {...boxProps}
    >
      <Text variant="$body-02" color={color}>
        {text}
      </Text>
    </Box>
  );
};
