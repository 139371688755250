import * as React from 'react';
import {Box, BoxProps, Heading} from '@youtoken/ui.primitives';
import {ConfirmationStatus} from '../types';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface StatusContentProps extends BoxProps {
  status: ConfirmationStatus;
}

export const StatusContent: React.FC<StatusContentProps> = ({
  status,
  ...boxProps
}) => {
  const {t} = useTranslation();

  const styleProps = React.useMemo<BoxProps>(() => {
    return {
      borderTopWidth: 1,
      borderBottomWidth: {default: 0, tablet: 1},
      borderLeftWidth: {default: 0, tablet: 1},
      borderRightWidth: {default: 0, tablet: 1},
      borderRadius: {default: 0, tablet: 10},
      width: {default: '100%', tablet: 700},
      height: {default: 240, tablet: 435},
    };
  }, []);

  return (
    <Box flex={1} alignItems="center" justifyContent="center" {...boxProps}>
      <Box
        alignItems="center"
        justifyContent="center"
        borderColor="$ui-01"
        {...styleProps}
      >
        <Box
          borderWidth={3}
          borderRadius={40}
          borderColor="$text-05"
          alignItems="center"
          justifyContent="center"
          width={80}
          height={80}
          mb={24}
        >
          <Icon
            size={48}
            color="$text-05"
            name={status === 'allow' ? 'check' : 'close'}
          />
        </Box>

        <Heading variant="$heading-01" color="$text-05" textAlign="center">
          {/*{i18n.t('surface.await_confirm_device.title')}*/}
          {status === 'allow'
            ? t('surface.await_confirm_device.status.allow')
            : t('surface.await_confirm_device.status.not_allow')}
        </Heading>
      </Box>
    </Box>
  );
};
