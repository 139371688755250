import * as React from 'react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export type AppliedFilterProps = {
  icon?: React.ReactNode;
  label: string;
  onClose: () => void;
  testID?: string;
};

export const AppliedFilter: React.FC<AppliedFilterProps> = ({
  icon,
  label,
  onClose,
  testID,
}) => {
  return (
    <TouchableBox
      mr={8}
      mb={8}
      flexDirection="row"
      alignItems="center"
      height={32}
      backgroundColor="$interactive-02"
      borderRadius={6}
      px={8}
      overflow="hidden"
      onPress={onClose}
      testID={testID}
    >
      {icon}
      <Text
        variant="$body-02-medium-accent"
        color="$text-05"
        ml={4}
        testID={`${testID}_LABEL`}
      >
        {label}
      </Text>
      <Box
        height="100%"
        ml={4}
        alignItems="center"
        justifyContent="center"
        borderRadius={6}
        testID={`${testID}_CLOSE`}
      >
        <Icon name="close" size={16} color="$text-05" />
      </Box>
    </TouchableBox>
  );
};
