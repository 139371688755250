import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const SearchIcon: React.FC = () => {
  return (
    <Box justifyContent="center" alignItems="center" height="100%" px={16}>
      <Icon name="search" color="$text-01" />
    </Box>
  );
};
