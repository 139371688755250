export type TLoyaltyColorTokensNames =
  | '$loyalty-level-01'
  | '$loyalty-level-02'
  | '$loyalty-level-03'
  | '$loyalty-level-04'
  | '$loyalty-level-05'
  | '$loyalty-level-06'
  | '$loyalty-level-07'
  | '$loyalty-level-08';

export type TLoyaltyColorTokens = Record<TLoyaltyColorTokensNames, string>;

export type TSectorColorTokensNames =
  | '$hodls-instruments-sectors-01'
  | '$hodls-instruments-sectors-02'
  | '$hodls-instruments-sectors-03'
  | '$hodls-instruments-sectors-04'
  | '$hodls-instruments-sectors-05'
  | '$hodls-instruments-sectors-06'
  | '$hodls-instruments-sectors-07'
  | '$hodls-instruments-sectors-08'
  | '$hodls-instruments-sectors-09'
  | '$hodls-instruments-sectors-10'
  | '$hodls-instruments-sectors-11'
  | '$hodls-instruments-sectors-12';

export type TSectorsColorTokens = Record<TSectorColorTokensNames, string>;

export type TColorTokensNames =
  | '$ui-overflow'
  | '$ui-overlay'
  | '$ui-background'
  | '$ui-shadow'
  | '$ui-01'
  | '$ui-02'
  | '$ui-03'
  | '$ui-04'
  | '$blackout-01'
  | '$blackout-02'
  | '$transparent'
  | '$hover'
  | '$success-01'
  | '$success-02'
  | '$success-03'
  | '$danger-01'
  | '$danger-02'
  | '$danger-03'
  | '$danger-04'
  | '$attention-01'
  | '$attention-02'
  | '$attention-03'
  | '$attention-04'
  | '$fill-01'
  | '$fill-02'
  | '$fill-03'
  | '$fill-04'
  | '$text-01'
  | '$text-02'
  | '$text-03'
  | '$text-04'
  | '$text-05'
  | '$text-06'
  | '$interactive-01'
  | '$interactive-02'
  | '$interactive-03'
  | '$interactive-04'
  | '$gradient-success-01'
  | '$gradient-danger-01'
  | '$gradient-ui-01'
  | '$miner-gloss'
  | '$miner-gloss-blocked'
  | '$chart-neutral';

export type TChartTokens =
  | '$chart-success-01'
  | '$chart-success-02'
  | '$chart-danger-01'
  | '$chart-danger-02'
  | '$chart-neutral-01'
  | '$chart-interactive-01'
  | '$chart-interactive-02'
  | '$chart-disabled-01'
  | '$chart-disabled-02';

export type TColorTokens = Record<
  | TColorTokensNames
  | TLoyaltyColorTokensNames
  | TSectorColorTokensNames
  | TChartTokens,
  string
>;

export const HODLS_INSTRUMENTS_SECTORS_COLOR_TOKENS: TSectorsColorTokens = {
  '$hodls-instruments-sectors-01': '#0B8E66',
  '$hodls-instruments-sectors-02': '#E62994',
  '$hodls-instruments-sectors-03': '#D19900',
  '$hodls-instruments-sectors-04': '#CD2D2D',
  '$hodls-instruments-sectors-05': '#1041D4',
  '$hodls-instruments-sectors-06': '#9539E1',
  '$hodls-instruments-sectors-07': '#0BADC3',
  '$hodls-instruments-sectors-08': '#46318C',
  '$hodls-instruments-sectors-09': '#D32F79',
  '$hodls-instruments-sectors-10': '#298817',
  '$hodls-instruments-sectors-11': '#008D63',
  '$hodls-instruments-sectors-12': '#034065',
};
