import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {Miner} from '../pages';
import {NotFoundPage} from '../pages';

import {cell} from '@youtoken/ui.cell';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

const MinerWithNotFoundIfMinerDisabled = cell(() => {
  const {
    data: {enableMiner},
  } = AuthMeResource.use({});

  if (!enableMiner) {
    return (
      <InnerPageShell>
        <NotFoundPage />
      </InnerPageShell>
    );
  }

  return <Miner />;
});

const MinerWrapped = authRequired(MinerWithNotFoundIfMinerDisabled);

export const MinerRoutes: React.FC<RouteComponentProps> = ({match: {path}}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={path} component={MinerWrapped} />
    </SwitchWithNotFound>
  );
};
