import * as React from 'react';
import {Animated, Easing, Platform, ViewProps, ViewStyle} from 'react-native';
import {Rect} from 'react-native-svg';
import {observer} from 'mobx-react';
import {ContentLoader} from '../../SmartCells/ContentLoader';
import {BaseChartState} from '../logic';

const useNativeDriver = Platform.select({web: false, default: true})!;

export const ActivityIndicator: React.FC<
  {
    chartState: BaseChartState;
  } & Animated.AnimatedProps<ViewProps>
> = React.memo(
  // @ts-ignore
  observer(({chartState, style}) => {
    const {height, width, isFetching} = chartState;

    const animation = React.useRef(new Animated.Value(isFetching ? 1 : 0));

    React.useEffect(() => {
      Animated.timing(animation.current, {
        toValue: isFetching ? 1 : 0,
        duration: 400,
        easing: Easing.linear,
        useNativeDriver: useNativeDriver,
      }).start();
    }, [isFetching]);

    const _style = React.useMemo(() => {
      return [
        style,
        {
          position: 'absolute',
          top: 0,
          left: 0,
          width: width,
          height: height,
          opacity: animation.current.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 0.6],
          }),
        },
      ];
    }, [width, height, style]) as ViewStyle;

    return (
      <Animated.View style={_style}>
        <ContentLoader
          animate={isFetching}
          width={width}
          height={height}
          speed={400}
        >
          <Rect width={width} height={height} />
        </ContentLoader>
      </Animated.View>
    );
  })
);
