import {observable, computed} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {i18n} from '@youtoken/ui.service-i18n';

export class Form {
  @observable
  public args: {};

  @observable
  public resources: {};

  @observable
  public instance: MobxReactForm;

  @computed
  public get emailCode() {
    return this.instance.$('emailCode');
  }

  public constructor(args: {}, resources: {}) {
    this.args = args;
    this.resources = resources;

    this.instance = new MobxReactForm(
      {
        fields: {
          emailCode: {
            name: 'emailCode',
            value: '',
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  emailCode: yup
                    .string()
                    .min(6, i18n.t('validation.CODE_INVALID'))
                    .max(6, i18n.t('validation.CODE_INVALID'))
                    .matches(/[0-9]+/, i18n.t('validation.CODE_INVALID'))
                    .required('validation.REQUIRED'),
                };
                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );
  }
}
