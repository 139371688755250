import * as React from 'react';
import {Modal} from '@youtoken/ui.modal';
import {ModalContentSmart, type ModalContentSmartProps} from './components';

interface SectorsModalProps extends ModalContentSmartProps {
  isOpen: boolean;
}

export const SectorsModal: React.FC<SectorsModalProps> = React.memo(
  ({isOpen, onClose, onHelpIconPress}) => {
    return (
      <Modal
        shouldShowHeaderSeparator={false}
        isOpen={isOpen}
        onClose={onClose}
        closeIconColor="$text-04"
        borderRadius={14}
        webHeight={588}
      >
        <ModalContentSmart
          onClose={onClose}
          onHelpIconPress={onHelpIconPress}
        />
      </Modal>
    );
  }
);
