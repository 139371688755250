import * as React from 'react';
import {SvgProps, Svg, Circle} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const GrowthIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Circle cx={13.666} cy={16.334} r={2.5} fill={color} />
        <Circle cx={4.666} cy={13.334} r={3.5} fill={color} />
        <Circle cx={12.168} cy={6.833} r={5} fill={color} />
      </Svg>
    );
  }
);
