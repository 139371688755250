import * as React from 'react';
import {observer} from 'mobx-react';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {makeForm, Form} from '@youtoken/ui.form-elements';
import {TwoFactorDisableFormState} from './state';
import {
  TwoFactorDisableFormContent,
  TwoFactorDisableFormFooter,
} from './components';

export const TwoFactorDisableForm: React.FC = observer(() => {
  const resources = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
  });

  const form = makeForm(() => new TwoFactorDisableFormState({}, resources));

  return (
    <Form form={form}>
      <TwoFactorDisableFormContent />
      <TwoFactorDisableFormFooter />
    </Form>
  );
});
