import {runInAction, observable, action, computed} from 'mobx';
import {now} from 'mobx-utils';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {TRANSPORT} from '@youtoken/ui.transport';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {
  type TwoFactorDisableFormArgs,
  type TwoFactorDisableFormResources,
} from './types';

export class Form {
  @observable
  args: TwoFactorDisableFormArgs;

  @observable
  resources: TwoFactorDisableFormResources;

  @observable
  instance: MobxReactForm;

  @observable loading = false;

  @observable phoneMask = '';
  @observable operationId = '';
  @observable operationLockTime = 0;

  //#region code

  @computed get code() {
    return this.instance.$('code').get('value');
  }

  @computed
  public get codeError() {
    return getTranslatedValidationMessage(this.instance.$('code').get('error'));
  }

  @computed
  public get codeHasError() {
    return Boolean(this.codeError);
  }

  @computed get codeCanResend() {
    return this.operationLockTime < now();
  }

  @computed get codeLeftTime() {
    if (this.codeCanResend) {
      return 0;
    }

    return Math.floor((this.operationLockTime - now()) / 1000);
  }

  @computed get codeOnChange() {
    return this.instance.$('code').get('onChange');
  }

  //#endregion code

  @action createOperation = () => {
    this.loading = true;
    return __GLOBAL_RECAPTCHA__
      .requestToken('2fa_disable')
      .then(token => {
        return TRANSPORT.API.post('/v1/security/disable-2fa', {
          token,
        }).then(({data}) => {
          runInAction(() => {
            this.phoneMask = data.phoneMask;
            this.operationId = data.operationId;
            this.operationLockTime = Date.now() + 45 * 1000;
          });
          return data;
        });
      })
      .catch(error => handleFormSubmitError(this.instance, error))
      .finally(() => {
        this.loading = false;
      });
  };

  constructor(
    args: TwoFactorDisableFormArgs,
    resources: TwoFactorDisableFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          code: {
            value: '',
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                return yup.object().shape({
                  code: yup.string().required(),
                });
              }),
          }),
        },
        hooks: {
          onSuccess: () => {
            return __GLOBAL_RECAPTCHA__
              .requestToken('2fa_disable')
              .then((token: string) => {
                return this.resources.authMe
                  .confirmOperation({
                    token,
                    operationId: this.operationId,
                    code: this.code,
                  })
                  .then(() => {
                    return this.resources.authMe.signOut();
                  });
              })
              .catch(error => handleFormSubmitError(this.instance, error));
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );
  }
}
