import * as React from 'react';
import {Box, TColorTokens, Text, type TextProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

type SparkPriceBasicProps = {
  amount: number;
  iconSize?: number;
} & TextProps;

export const SparkPriceBasic: React.FC<SparkPriceBasicProps> = ({
  amount,
  iconSize = 20,
  variant = '$body-01-high-accent',
  color = '$attention-01',
  ...textProps
}) => {
  return (
    <>
      <Icon name="spark" size={20} color={color as keyof TColorTokens} />
      <Text color={color} variant={variant} {...textProps}>
        {amount}
      </Text>
    </>
  );
};
