import {comparer, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import Big from 'big.js';
import uniqBy from 'lodash/uniqBy';
import {
  ActiveHODLsResource,
  ClosedHODLsResource,
  PendingHODLsResource,
} from '@youtoken/ui.resource-hodl';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {HODLOverviewResource} from '@youtoken/ui.resource-hodl-overview';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {formatBigNumber, toBig} from '@youtoken/ui.formatting-utils';
import {invariant} from '@youtoken/ui.utils';

interface HODLsListFeatureArgs {
  closedLimit?: number;
}

export class HODLsListFeature extends createFeature({
  getKey: (args: HODLsListFeatureArgs) =>
    `feature:hodls:${JSON.stringify(args)}`,
  getResources: (args: HODLsListFeatureArgs) => {
    return {
      active: getResourceDescriptor(ActiveHODLsResource, {}),
      pending: getResourceDescriptor(PendingHODLsResource, {}),
      closed: getResourceDescriptor(ClosedHODLsResource, {
        limit: args.closedLimit,
      }),
      overview: getResourceDescriptor(HODLOverviewResource, {}),
      authme: getResourceDescriptor(AuthMeResource, {}),
      rates: getResourceDescriptor(RatesResource, {product: 'hodl'}),
    };
  },
}) {
  @computed get mainCurrency() {
    return this.resources.authme.mainCurrency;
  }

  @computed
  get active() {
    return this.resources.active.data.rows
      .slice()
      .sort((a, b) => Number(b.data.isClientMC) - Number(a.data.isClientMC));
  }

  @computed
  get pending() {
    return this.resources.pending.data.rows;
  }

  @computed
  get closed() {
    return this.resources.closed.data.rows;
  }

  @computed
  get activeAndPending() {
    return uniqBy([...this.active, ...this.pending], 'id');
  }

  @computed get shouldShowActiveHODLs() {
    return this.resources.active.hodlIds.length > 0;
  }

  @computed get shouldShowPendingHODLs() {
    return this.pending.length > 0;
  }

  @computed get shouldShowClosedHODLs() {
    return this.closed.length > 0;
  }

  getHodlById = computedFn(
    (id: string) => {
      const activeHodl = this.active.find(i => i.id === id);
      const pendingHodl = this.pending.find(i => i.id === id);
      const closedHodl = this.closed.find(i => i.id === id);

      return activeHodl || pendingHodl || closedHodl;
    },
    {equals: comparer.shallow}
  );

  // react-native-list specific stuff
  // but may be used later;

  /** is list empty? (react-native empty component in list will be evoked) */
  @computed get isEmpty() {
    return this.activeAndPending.length <= 0 && this.closed.length <= 0;
  }

  //#region live-calculations

  @computed get totalInvestedFormatted() {
    const totalInvestedInMainCurrency = this.activeAndPending.reduce(
      (acc, hodlItem) => {
        const rate =
          hodlItem.inputTicker === this.mainCurrency
            ? 1
            : this.resources.rates.getRate(
                hodlItem.inputTicker,
                this.mainCurrency
              );
        const valueInMainCurrency = hodlItem._inputAmount.mul(rate);
        return acc.plus(valueInMainCurrency);
      },
      Big(0)
    );

    return formatBigNumber(totalInvestedInMainCurrency, 2);
  }

  @computed get currentProfitInMainCurrency() {
    return this.active.reduce((acc, currentHODL) => {
      const hodlTicker = currentHODL.inputTicker;
      const rate =
        hodlTicker === this.mainCurrency
          ? 1
          : this.resources.rates.getRate(hodlTicker, this.mainCurrency);

      invariant(
        typeof currentHODL.calculatedResultsActiveHODL?.amount !== 'undefined',
        'current HODL amount is not defined',
        {},
        {
          calculatedResultsActiveHOD: currentHODL.calculatedResultsActiveHODL,
          amount: currentHODL.calculatedResultsActiveHODL?.amount,
        }
      );

      // NOTE: on BE side profit - is a result before subtraction all the fees,
      // real profit on BE is called 'amount'
      const valueInMainCurrency = toBig(
        currentHODL.calculatedResultsActiveHODL!.amount
      ).mul(rate);

      return acc.plus(valueInMainCurrency);
    }, Big(0));
  }

  @computed get currentProfitFormatted() {
    return formatBigNumber(this.currentProfitInMainCurrency, 2);
  }

  @computed
  get currentProfitColor() {
    //
    return this.currentProfitInMainCurrency.eq(0)
      ? 'neutral'
      : this.currentProfitInMainCurrency.gt(0)
      ? 'up'
      : 'down';
  }
  //#endregion live-calculations
}
