import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsHistoryResource} from '@youtoken/ui.resources-rewards';
import {RewardsHistoryViewV1} from '../../../..';
import {RewardsHistorySmartProps} from '../../types';

export const RewardsHistoryViewV1Smart: React.FC<RewardsHistorySmartProps> =
  observer(({showHeader, limit}) => {
    const {
      data: {items},
    } = RewardsHistoryResource.use({limit});

    if (items.length === 0) {
      return null;
    }

    return <RewardsHistoryViewV1 showHeader={showHeader} items={items} />;
  });
