import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {observer} from 'mobx-react';

export const NotEnoughData: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <Box
      backgroundColor="$ui-background"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Text color="$text-01" variant="$body-01">
        {t('ramp.conversion_form.not_enough_data')}
      </Text>
    </Box>
  );
});
