// is Error contains validation message that recaptcha validation have failed;
export const shouldFallbackToV2Recaptcha = (error: any) => {
  try {
    // if any errors was thrown during recaptcha v3 lifecycle
    if (
      error?.message?.toLowerCase().includes('recaptcha') ||
      JSON.stringify(error).toLowerCase().includes('recaptcha')
    ) {
      return true;
    }

    // if server token v3 validation failed;
    if (
      (error?.response?.data?._error as string)
        ?.toLowerCase()
        .includes('recaptcha') ||
      JSON.stringify(error?.response)?.toLowerCase().includes('recaptcha')
    ) {
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
};
