import {computed} from 'mobx';
import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {HodlLoanChainResponse} from './HodlLoanChainResponse';
import {getHodlLoansSequence} from '@youtoken/ui.hodls-utils';

export interface HodlLoanChainArgs {
  hodlId: string;
  hodlHistoryItemId?: string;
}

export class HodlLoanChainResource extends createResource<
  HodlLoanChainArgs,
  HodlLoanChainResponse
>({
  skipRefreshOnVisible: false,
  getKey: ({hodlId, hodlHistoryItemId}: HodlLoanChainArgs) =>
    `HodlLoanChain(${hodlId}, ${hodlHistoryItemId})`,
  getData: ({hodlId, hodlHistoryItemId}) =>
    TRANSPORT.API.get(
      `/v3/hodl/${hodlId}/loanChain${
        hodlHistoryItemId ? `/${hodlHistoryItemId}` : ''
      }`
    ).then(res => deserialize(HodlLoanChainResponse, res.data)),
}) {
  @computed.struct get loansSequence() {
    const {loans, borrowedTicker, collateralTicker, ltv} = this.data;
    return getHodlLoansSequence(borrowedTicker, collateralTicker, ltv, loans);
  }

  @computed get totalCollateralAmountFormatted() {
    return formatByTicker(
      this.data.totalCollateral,
      this.data.collateralTicker
    );
  }

  @computed get repaymentDueAmountFormatted() {
    return formatByTicker(this.data.totalRepayment, this.data.borrowedTicker);
  }
}
