import {MouseEvent} from 'react';

/** binds close function to `esc` button click */
export const bindCloseOnEsc =
  (onCloseRequested: Function, shouldCloseOnEsc: boolean) =>
  (event: KeyboardEvent) => {
    if (event.defaultPrevented || !shouldCloseOnEsc) {
      return;
    }

    const key = event.key || event.keyCode;
    if (key === 'Escape' || key === 'Esc' || key === 27) {
      onCloseRequested();
    }
  };

/** bidns `close` function to overlay click */
export const bindlCloseOnOverlay =
  (onCloseRequested: Function, shouldCloseOnOverlay: boolean) =>
  (event: MouseEvent<HTMLElement>) => {
    if (event.defaultPrevented || !shouldCloseOnOverlay) {
      return;
    }

    onCloseRequested();
  };

/** Prevents default for click event and stops event propagation */
export const preventPropagation = (event: MouseEvent<HTMLElement>) => {
  event.stopPropagation();
  // event.preventDefault();
};

const body = document.body;
const html = document.querySelectorAll('html')[0]!;

/** Lock body to current position and prevents scroll */
export const setLock = (lockPostion: number) => {
  body.style.setProperty('margin-top', `-${lockPostion}px`);
  html.style.setProperty('position', 'fixed');
  html.style.setProperty('width', '100%');
  html.style.setProperty('height', '100%');
};

/** Removed lock from body and returns scroll controll */
export const removeLock = (lockPostion: number) => {
  body.style.removeProperty('margin-top');
  html.style.removeProperty('position');
  html.style.removeProperty('width');
  html.style.removeProperty('height');
  window.scrollTo(0, lockPostion);
};
