import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ExtendIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 8a1 1 0 01.993.883L11 9v5h1.293a.5.5 0 01.411.784l-.058.07-2.292 2.292a.5.5 0 01-.638.058l-.07-.058-2.292-2.292a.5.5 0 01.263-.846l.09-.008H9V9a1 1 0 011-1zM4.5 4a1 1 0 010 2h-1a1 1 0 010-2h1zm4 0a1 1 0 010 2h-1a1 1 0 010-2h1zm4 0a1 1 0 110 2h-1a1 1 0 110-2h1zm4 0a1 1 0 110 2h-1a1 1 0 110-2h1z"
          fill={color}
        />
      </Svg>
    );
  }
);
