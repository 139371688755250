import * as React from 'react';
import {useWizard} from '@youtoken/ui.elements';
import {CryptoBuyWizard} from '../..';
import {DepositNowWizardNavigator, DepositNowWizardScreenName} from '..';

export const DepositNowWizardBuyCrypto: React.FC = () => {
  const {goBack} = useWizard<
    DepositNowWizardNavigator,
    DepositNowWizardScreenName.BUY_CRYPTO
  >();

  return <CryptoBuyWizard source="FORK" onPressBack={goBack} />;
};
