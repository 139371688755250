import React, {ReactElement} from 'react';
import {Field as FormikField, FieldProps as FormikFieldProps} from 'formik';
import {ErrorMessage} from './ErrorMessage';
import {Label} from './Label';
import {Box} from '@youtoken/ui.primitives';

export type FieldProps = {
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  render?: (props: FormikFieldProps) => ReactElement<any>;
  renderLabelPlugins?: Function;
  renderError?: boolean;
  errorProps?: Omit<React.ComponentProps<typeof ErrorMessage>, 'name'>;
};

export const Field: React.FC<FieldProps> = ({
  name,
  placeholder,
  label,
  render,
  renderLabelPlugins,
  renderError = true,
  errorProps,
  ...otherProps
}) => {
  return (
    <Box
      pb={renderError ? 18 : 10}
      mb={5}
      position="relative"
      flex={1}
      alignItems="stretch"
      width="100%"
    >
      {label && <Label renderLabelPlugins={renderLabelPlugins}>{label}</Label>}
      <FormikField name={name} {...otherProps} render={render} />
      {renderError && <ErrorMessage name={name} {...errorProps} />}
    </Box>
  );
};
