import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {
  normalizeWithoutSpaces,
  normalizeWithoutMultiplySpaces,
} from '@youtoken/ui.normalizers';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../../../state/WithdrawalForm';
import {FormComboboxField} from '../../../FormComboboxField';
import {FormInputField} from '../../../FormInputField';

export const BeneficiaryAddressFields: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {countryItems},
    view: {showBeneficiaryDescriptions},
  } = useForm<WithdrawalForm>();

  return (
    <Box mb={10}>
      <Heading variant="$heading-01" mb={10}>
        {t('surface.wallets.withdrawal_bank_wire_form.beneficiary_address')}
      </Heading>

      {showBeneficiaryDescriptions && (
        <Text color="$text-02" variant="$body-02" mb={10}>
          {t(
            'surface.wallets.withdrawal_bank_wire_form.beneficiary_address_description'
          )}
        </Text>
      )}

      <FormComboboxField
        name="beneficiaryAddressCountry"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BENEFICIARY_ADDRESS_COUNTRY"
        items={countryItems}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.country')}
          </Label>
        }
      />

      <FormInputField
        name="beneficiaryAddressCity"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BENEFICIARY_ADDRESS_CITY"
        maxLength={255}
        normalizer={normalizeWithoutMultiplySpaces}
        label={
          <Label>{t('surface.wallets.withdrawal_bank_wire_form.city')}</Label>
        }
      />

      <FormInputField
        name="beneficiaryAddressStreetAddress"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BENEFICIARY_ADDRESS_STREET_ADDRESS"
        maxLength={255}
        normalizer={normalizeWithoutMultiplySpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.street_address')}
          </Label>
        }
      />

      <FormInputField
        name="beneficiaryAddressZipCode"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_BENEFICIARY_ADDRESS_ZIP_CODE"
        maxLength={15}
        normalizer={normalizeWithoutSpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.postal_code')}
          </Label>
        }
      />

      <FormInputField
        name="paymentReference"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_PAYMENT_REFERENCE"
        maxLength={255}
        normalizer={normalizeWithoutMultiplySpaces}
        label={
          <Label>
            {t('surface.wallets.withdrawal_bank_wire_form.payment_reference')}
          </Label>
        }
      />
    </Box>
  );
});
