import {i18n} from '@youtoken/ui.service-i18n';

export const getStringFromArray = (
  arr: number[],
  postfix: string,
  t: typeof i18n.t
) => {
  if (!arr.length) {
    return undefined;
  }

  if (arr.length === 1) {
    if (arr[0] === 0) {
      return undefined;
    }

    return `${arr[0]}${postfix}`;
  }

  const min = Math.min(...arr);
  const max = Math.max(...arr);

  return `${t('surface.fee_string.template.from')} ${min}${postfix} ${t(
    'surface.fee_string.template.to'
  )} ${max}${postfix}`;
};
