import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {ExchangeFormAmountButtons, ExchangeFormAmountSlider} from '..';

export const ExchangeFormAmountFastHandlers: React.FC = () => {
  return (
    <Box mt={24}>
      <ExchangeFormAmountSlider />
      <ExchangeFormAmountButtons />
    </Box>
  );
};
