import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const OsOtherIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2a8 8 0 110 16 8 8 0 010-16zM8.694 7.306c.26.26.26.683 0 .943l-1.751 1.75 1.75 1.752a.667.667 0 11-.942.943L5.529 10.47a.667.667 0 010-.943L7.75 7.306c.26-.26.682-.26.943 0zm2.612.943a.667.667 0 01.943-.943l2.222 2.222c.26.26.26.683 0 .943l-2.222 2.223a.667.667 0 01-.943-.943L13.057 10l-1.75-1.751z"
          fill={color}
        />
      </Svg>
    );
  }
);
