import * as React from 'react';
import {CircleProps} from 'react-native-svg';
import {AnimatedCircle} from './AnimatedGroup';
import {Animated, Easing} from 'react-native';

export interface PulsatingDotProps extends CircleProps {
  x: number;
  y: number;
  animate?: boolean;
}

const config = {
  duration: 800,
  easing: Easing.inOut(Easing.quad),
  useNativeDriver: false,
};

export const PulsatingDot: React.FC<PulsatingDotProps> = React.memo(
  ({x, y, animate = true, ...props}) => {
    const animation = React.useRef(new Animated.Value(0));

    React.useEffect(() => {
      const node = Animated.loop(
        Animated.sequence([
          Animated.timing(animation.current, {
            toValue: 1,
            ...config,
          }),
          Animated.timing(animation.current, {
            toValue: 0,
            ...config,
          }),
        ])
      );

      if (animate) {
        node.start();
      } else {
        node.reset();
        node.stop();
      }

      return () => node.stop();
    }, [animate]);

    return (
      <>
        <AnimatedCircle
          // style={{
          //   opacity: animation.current.interpolate({
          //     inputRange: [0, 1],
          //     outputRange: [0.7, 1],
          //   }),
          // }}
          cx={x}
          cy={y}
          // r={animation.current.interpolate({
          //   inputRange: [0, 1],
          //   outputRange: [4.5, 6],
          // })}
          r={4.5}
          fillOpacity={1}
          vectorEffect="non-scaling-stroke"
          {...props}
        />
        <AnimatedCircle
          // style={{
          //   opacity: animation.current.interpolate({
          //     inputRange: [0, 1],
          //     outputRange: [0.7, 1],
          //   }),
          // }}
          // @ts-ignore
          fill="white"
          cx={x}
          cy={y}
          r={animation.current.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 3],
          })}
          // r={4.5}
          fillOpacity={1}
          vectorEffect="non-scaling-stroke"
        />
      </>
    );
  }
);
