import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowBorrowedProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemDataTableRowBorrowed: React.FC<LoanItemDataTableRowBorrowedProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_BORROWED"
        left={t('surface.loans.item.table.borrowed')}
        right={
          <Text
            testID="LOAN_ITEM_TABLE_ROW_BORROWED_VALUE"
            variant="$body-02"
            color="$text-02"
          >
            <Text variant="$body-02-high-accent" color="$text-01">
              {item.borrowedAmountFormatted}
            </Text>{' '}
            {item.borrowedTickerFormatted}
          </Text>
        }
      />
    );
  });
