import * as React from 'react';
import {HODLPreviewChart} from '../Charts';
import {cell} from '@youtoken/ui.cell';
import {ChartSmartCells} from './utils/types';
import {ChartWithoutControlsSuspenseFallback} from './SuspenseFallbacks';
import {CellWrap} from './utils/CellWrap';

export const HODLPreviewChartSmartCell =
  cell<ChartSmartCells.HODLPreviewChartSmartCellProps>(
    ({
      height,
      width,
      fromTicker: _fromTicker,
      precision,
      data,
      isClosedLike,
      isOpeningLike,
      initialPrice,
      triggerPrice,
      closedPrice,
      startDate,
      endDate,
      reversed,
      pending,
      direction,
      marginCall,
      takeProfit,
    }) => {
      return (
        <>
          {data && (
            <HODLPreviewChart
              width={width}
              height={height}
              data={data}
              marginCall={
                isClosedLike || isOpeningLike ? undefined : marginCall
              }
              takeProfit={
                isClosedLike || isOpeningLike ? undefined : takeProfit
              }
              initialPrice={initialPrice}
              triggerPrice={triggerPrice}
              closedPrice={closedPrice}
              startDate={isOpeningLike ? new Date() : startDate}
              endDate={endDate}
              reversed={reversed}
              pending={pending}
              domainPaddingTop={8}
              domainPaddingBottom={8}
              direction={direction}
              displayInitialPriceLabel={!pending}
              displayActivityIndicator={isOpeningLike && !isClosedLike}
              formatNumberPrecision={precision}
            />
          )}
        </>
      );
    },
    {
      displayName: 'HODLPreviewChartSmartCell',
      SuspenseFallback: ChartWithoutControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
