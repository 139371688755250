import React from 'react';
import {Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';
import {GLOBAL} from '@youtoken/ui.service-global';

/** __HOC for pages what requires auth;__
 *
 * will redirect users to `/sign-in` if they not authorized
 */
export const authRequired = (Component: React.ComponentType<any>) => {
  const ComponentWithRedirect: React.FC = observer(props => {
    if (!GLOBAL.token) {
      return <Redirect to="/sign-in" />;
    }

    return <Component {...props} />;
  });

  ComponentWithRedirect.displayName = `authRequired(${
    Component.displayName || ''
  }))`;

  return ComponentWithRedirect;
};
