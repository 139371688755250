import * as React from 'react';
import {Box, TColorTokens} from '@youtoken/ui.primitives';
import {LoyaltyIconName} from '@youtoken/ui.icons';
import {LevelIcon} from '../LevelIcon';

type MarkIconProps = {
  iconName: LoyaltyIconName;
  iconColor: keyof TColorTokens;
  iconColorBorder: keyof TColorTokens;
};

export const MarkIcon: React.FC<MarkIconProps> = ({
  iconName,
  iconColor,
  iconColorBorder,
}) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      p={2}
      position="absolute"
      top={0}
      borderColor={iconColorBorder}
      borderWidth={1}
      borderRadius={16}
    >
      <LevelIcon
        name={iconName}
        size={18}
        wrapSize={24}
        bg={iconColor}
        borderWidth={0}
      />
    </Box>
  );
};
