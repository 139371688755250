import * as React from 'react';
import {Wallets} from '@youtoken/ui.legacy-components';
import {cell} from '@youtoken/ui.cell';
import {Box, type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {type List as TList, type SectionList} from '@youtoken/ui.utils';
import {SectionToggler} from '@youtoken/ui.legacy-components/src/components/Wallets/Groups/List';
import {useGroupsController} from './hooks';
import {WalletsResource} from '@youtoken/ui.resource-wallets';

const BR = {default: 0, tablet: 10};
const SECTIONS: SectionList[] = ['fiat', 'stable', 'crypto'];

const {
  Groups: {
    List: {Provider, Item, VisibilityToggler},
  },
} = Wallets;

export const List: React.FC<BoxProps> = cell(boxProps => {
  const {
    listVisibilities,
    listTickers,
    createHandlePress,
    createHandlePressDeposit,
    createHandlePressWithdrawal,
    createHandlePressConvert,
    createHandlePressHodl,
    createHandleBringToFront,
    createHandleToggleHiddenVisibility,
    bringedToFront,
  } = useGroupsController();

  const {
    data: {wallets},
  } = WalletsResource.use({});

  const createRenderItem = React.useCallback(
    (list: TList) => (ticker: string, i: number) => {
      const item = wallets.find(wallet => wallet.ticker === ticker);
      return (
        <Item
          zIndex={bringedToFront.ticker === ticker ? 1 : 0}
          height={72}
          key={`${list}_${ticker}`}
          item={item!}
          onPress={createHandlePress(ticker)}
          onPressDeposit={createHandlePressDeposit(ticker)}
          onPressWithdrawal={createHandlePressWithdrawal(ticker)}
          onPressConvert={createHandlePressConvert(ticker)}
          onPressHodl={
            createHandlePressHodl ? createHandlePressHodl(ticker) : undefined
          }
          onBringToFront={createHandleBringToFront(list, ticker)}
          borderBottomWidth={i === listTickers[list].length - 1 ? 0 : 1}
          borderBottomColor="$ui-01"
          testID={`WALLETS_LIST_ITEM_${ticker.toUpperCase()}`}
        />
      );
    },
    [
      bringedToFront.ticker,
      listTickers,
      createHandlePress,
      createHandlePressDeposit,
      createHandlePressWithdrawal,
      createHandlePressConvert,
      createHandlePressHodl,
      createHandleBringToFront,
    ]
  );

  const renderSection = React.useCallback(
    (list: SectionList, index: number, array: any[]) => {
      const isLast = index === array.length - 1;
      return (
        <Box
          borderTopLeftRadius={index === 0 ? BR : 0}
          borderTopRightRadius={index === 0 ? BR : 0}
          zIndex={bringedToFront.list === list ? 1 : 0}
          borderBottomWidth={isLast ? 0 : 1}
          borderBottomColor="$ui-01"
          key={list}
        >
          <SectionToggler
            section={list}
            visibility={listVisibilities[list]}
            tickers={listTickers[list]}
            disabled={listTickers[list].length === 0}
            onPress={createHandleToggleHiddenVisibility(list)}
            borderTopLeftRadius={index === 0 ? BR : 0}
            borderTopRightRadius={index === 0 ? BR : 0}
            borderBottomLeftRadius={isLast ? BR : 0}
            borderBottomRightRadius={isLast ? BR : 0}
          />
          {listVisibilities[list] && (
            <Box zIndex={bringedToFront.list === list ? 1 : 0}>
              {listTickers[list].map(createRenderItem(list))}
            </Box>
          )}
        </Box>
      );
    },
    [
      bringedToFront.list,
      listTickers,
      listVisibilities,
      createHandleToggleHiddenVisibility,
      createRenderItem,
    ]
  );

  return (
    <Box
      borderRadius={BR}
      borderWidth={1}
      borderTopWidth={{default: 0, tablet: 1}}
      borderBottomWidth={{default: 0, tablet: 1}}
      borderColor="$ui-01"
      {...boxProps}
    >
      <Provider>
        {SECTIONS.map(renderSection)}

        {listTickers.hidden.length > 0 && (
          <Box
            backgroundColor="$ui-01"
            borderBottomLeftRadius={BR}
            borderBottomRightRadius={BR}
          >
            <TouchableBox
              onPress={createHandleToggleHiddenVisibility('hidden')}
            >
              <VisibilityToggler
                labelCount={listTickers.hidden.length}
                visibility={listVisibilities.hidden}
                p={20}
              />
            </TouchableBox>
            {listVisibilities.hidden && (
              <Box zIndex={bringedToFront.list === 'hidden' ? 1 : 0}>
                {listTickers.hidden.map(createRenderItem('hidden'))}
              </Box>
            )}
          </Box>
        )}
      </Provider>
    </Box>
  );
});
