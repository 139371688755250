import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';

export const OrSeparator: React.FC<BoxProps> = ({...boxProps}) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      {...boxProps}
    >
      <Box flexGrow={1} height={1} backgroundColor="$ui-01" mr={6} />
      <Text color="$text-03">or</Text>
      <Box flexGrow={1} height={1} backgroundColor="$ui-01" ml={6} />
    </Box>
  );
};
