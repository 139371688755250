import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AccountLevelProgressBarVariant} from '../types';
import {AccountLevelProgressBar} from '../../components';

export interface AccountLevelProgressWidgetV2Props {
  variant: AccountLevelProgressBarVariant;
  volume?: number;
  sparks?: number;
}

export const AccountLevelProgressWidgetV2: React.FC<
  AccountLevelProgressWidgetV2Props & BoxProps
> = observer(({variant, volume, sparks, ...boxProps}) => {
  const {
    products: {loyalty, miner},
  } = AuthMeResource.use({});

  if (!loyalty.available) {
    return null;
  }

  return (
    <AccountLevelProgressWidgetV2Content
      variant={variant}
      volume={volume}
      sparks={miner.available ? sparks : undefined}
      {...boxProps}
    />
  );
});

const AccountLevelProgressWidgetV2Content: React.FC<
  AccountLevelProgressWidgetV2Props & BoxProps
> = observer(({variant, volume, sparks, ...boxProps}) => {
  const {
    getProgressBarLevelsWidget,
    getProgressBarAchievedLevels,
    getProgressBarValue,
  } = LoyaltyResource.use({});

  const handlePress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('AccountLevelProgressDetailed', {
      variant,
      volume,
    });
  }, [variant, volume]);

  const progressBarLevels = getProgressBarLevelsWidget(volume);

  return (
    <Box mt={8} {...boxProps}>
      <AccountLevelProgressBar
        progressBarLevels={progressBarLevels}
        achievedLevels={getProgressBarAchievedLevels(volume)}
        value={getProgressBarValue(volume)}
        sparks={sparks}
        onPress={handlePress}
      />
    </Box>
  );
});
