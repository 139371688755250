import * as React from 'react';
import {observer} from 'mobx-react';
import {invariant} from '@youtoken/ui.utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator, useWizard} from '@youtoken/ui.elements';
import {HeaderBase, DepositMethods} from '../../../components';
import {FiatDepositWizardNavigator, FiatDepositWizardScreenName} from '..';

export const FiatDepositWizardChooseMethodBase: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    step: {
      props: {ticker},
    },
    navigate,
    goBack,
  } = useWizard<
    FiatDepositWizardNavigator,
    FiatDepositWizardScreenName.CHOOSE_METHOD
  >();

  return (
    <>
      <HeaderBase onPressBack={goBack}>
        {t('surface.wallets.fiat_deposit.title', {
          ticker: ticker.toUpperCase(),
        })}
      </HeaderBase>
      <Separator />
      <DepositMethods
        ticker={ticker}
        onChange={(method, provider, operationMethod) => {
          invariant(
            method in FiatDepositWizardScreenName,
            'FiatDepositWizardChooseMethodBase: Unknown method'
          );

          navigate(method as unknown as FiatDepositWizardScreenName, {
            ticker,
            provider,
            operationMethod,
          });
        }}
      />
    </>
  );
});
