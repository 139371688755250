import * as React from 'react';
import {observer} from 'mobx-react';
import {BigSource} from 'big.js';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {type BadgeProps, Badge} from '@youtoken/ui.elements';

export interface AccountLevelSavingsUpToAPRBadgeProps extends BadgeProps {
  percent: BigSource;
}

export const AccountLevelSavingsUpToAPRBadge: React.FC<AccountLevelSavingsUpToAPRBadgeProps> =
  observer(({percent, ...badgeProps}) => {
    const {t} = useTranslation();

    return (
      <Badge
        testID="ACCOUNT_LEVEL_SAVINGS_UP_TO_APR_BADGE"
        size="small"
        borderRadius={12}
        {...badgeProps}
      >
        <Text variant="$body-02">
          <Trans
            t={t}
            components={{
              Strong: <Text variant="$body-02-high-accent" />,
            }}
            values={{percent: formatPercent(percent)}}
            i18nKey="surface.loyalty.savings.up_to_percent"
          />
        </Text>
      </Badge>
    );
  });
