import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {LoyaltyLevel, LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {LevelCard} from '../LevelCard';
import {Slider} from '@youtoken/ui.elements';

export const Levels: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Box width="100%" bg="$ui-background">
      {isMobile ? <LevelsSlider /> : <LevelsList />}
    </Box>
  );
};

export const LevelsSlider: React.FC = observer(() => {
  const {
    data: {levels, currentLevel},
  } = LoyaltyResource.use({});

  const renderItem = React.useCallback(({item}: {item: LoyaltyLevel}) => {
    return <LevelCard key={item.level} levelNumber={item.level} />;
  }, []);

  return (
    <Box width="100%" height={550} mt={16} bg="$ui-background">
      <Slider
        data={levels}
        renderItem={renderItem}
        initialIndex={currentLevel - 1}
        cardWidthMax={360}
      />
    </Box>
  );
});

export const LevelsList: React.FC = observer(() => {
  const {
    data: {levels},
  } = LoyaltyResource.use({});

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      flexWrap="wrap"
      px={{default: 12, desktop: 0}}
      mx={{default: 0, desktop: -12}}
    >
      {levels.map(item => {
        return (
          <Box width="50%" mt={24} px={12} key={item.level}>
            <LevelCard key={item.level} levelNumber={item.level} />
          </Box>
        );
      })}
    </Box>
  );
});
