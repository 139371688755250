import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {RewardsOverviewStatusMessage} from './Message';
import {RewardsOverviewStatusAction} from './Action';

export const RewardsOverviewStatus: React.FC = () => {
  return (
    <Box p={24}>
      <RewardsOverviewStatusMessage />
      <RewardsOverviewStatusAction />
    </Box>
  );
};
