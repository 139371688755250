import * as React from 'react';
import {TextInputProps} from 'react-native';
import {
  spacingShorthand,
  spacing,
  backgroundColor,
  backgroundColorShorthand,
  color,
  type BackgroundColorShorthandProps,
  type BackgroundColorProps,
  type Theme,
  type SpacingProps,
  type SpacingShorthandProps,
  type ColorProps,
} from '@youtoken/ui.primitives';
import {
  createRestyleComponent,
  border,
  layout,
  opacity,
  position,
  shadow,
  visible,
  typography,
  type BorderProps,
  type LayoutProps,
  type OpacityProps,
  type PositionProps,
  type ShadowProps,
  type VisibleProps,
  type TypographyProps,
} from '@shopify/restyle';

export type InputProps = BackgroundColorProps<Theme> &
  BackgroundColorShorthandProps<Theme> &
  BorderProps<Theme> &
  LayoutProps<Theme> &
  OpacityProps<Theme> &
  PositionProps<Theme> &
  ShadowProps<Theme> &
  SpacingProps<Theme> &
  SpacingShorthandProps<Theme> &
  VisibleProps<Theme> &
  TypographyProps<Theme> &
  ColorProps<Theme>;

export const createInput = <
  Component extends React.ComponentType<any> = React.ComponentType<TextInputProps>
>(
  BaseComponent: Component
) => {
  return createRestyleComponent<
    React.PropsWithChildren<InputProps> &
      Omit<React.ComponentProps<Component>, keyof InputProps>,
    Theme
  >(
    [
      backgroundColor,
      backgroundColorShorthand,
      opacity,
      visible,
      layout,
      spacing,
      spacingShorthand,
      border,
      shadow,
      position,
      color,
      typography,
    ],
    BaseComponent
  );
};
