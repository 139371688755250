import React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {useForm} from '../../../../../components';
import {FeatureLoanFTPForm} from '../../state';

export const Ticker: React.FC<BoxProps> = observer(props => {
  const {
    loanCollateralTicker,
    form: {loanCollateralTicker: _loanCollateralTicker},
  } = useForm<FeatureLoanFTPForm>();

  if (!loanCollateralTicker) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      px={10}
      mr={5}
      position="absolute"
      top={0}
      bottom={0}
      {...props}
    >
      <Box mr={5}>
        <LogoColored
          size={20}
          name={_loanCollateralTicker as LogoColoredIconName}
        />
      </Box>
      <Text>{loanCollateralTicker}</Text>
    </Box>
  );
});
