import React from 'react';
import {observer} from 'mobx-react';
import {Box, useActiveThemeType} from '@youtoken/ui.primitives';
import GoogleRecaptcha from 'react-google-recaptcha';
import {i18n} from '@youtoken/ui.service-i18n';

type RecaptchaViewProps = {
  siteKey: string;
  onSuccess: (token: string | null) => void;
  onError: (...args: any[]) => void;
};

export const RecaptchaView: React.FC<RecaptchaViewProps> = observer(
  ({onError, onSuccess, siteKey}) => {
    const activeTheme = useActiveThemeType();
    const {language} = i18n;

    return (
      <Box>
        <GoogleRecaptcha
          sitekey={siteKey}
          onExpired={onError}
          onError={onError}
          onChange={onSuccess}
          type="image"
          size="normal"
          theme={activeTheme}
          hl={language}
        />
      </Box>
    );
  }
);
