import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const MastercardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg width={size} height={size} viewBox="0 0 32 32" {...props}>
        <G fill="none" fillRule="evenodd">
          <Path fill="#EC6100" d="M11.636 8.143h8.727v15.286h-8.727z" />
          <Path
            d="M12.309 15.811a9.819 9.819 0 003.72 7.713 9.658 9.658 0 01-6.02 2.098c-5.38 0-9.742-4.393-9.742-9.811C.267 10.392 4.628 6 10.009 6c2.272 0 4.364.784 6.02 2.098a9.819 9.819 0 00-3.72 7.713"
            fill="#E50200"
            fillRule="nonzero"
          />
          <Path
            d="M31.764 15.811c0-5.419-4.362-9.811-9.742-9.811A9.661 9.661 0 0016 8.098a9.818 9.818 0 013.722 7.713A9.818 9.818 0 0116 23.524a9.661 9.661 0 006.022 2.098c5.38 0 9.742-4.393 9.742-9.811"
            fill="#F79F00"
            fillRule="nonzero"
          />
        </G>
      </Svg>
    );
  }
);
