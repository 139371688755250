import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const EditIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 16 16"
        {...props}
      >
        <Path
          d="M13 7l-7.707 7.707a1 1 0 01-.707.293H1.5a.5.5 0 01-.5-.5v-3.086a1 1 0 01.293-.707L9 3m4 4l1.44-1.44a1.5 1.5 0 000-2.12l-1.88-1.88a1.5 1.5 0 00-2.12 0L9 3m4 4L9 3"
          stroke={color}
          strokeWidth={1.5}
        />
      </Svg>
    );
  }
);
