import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {warning} from '@youtoken/ui.utils';
import {Button} from '@youtoken/ui.buttons';
import {Error, Label, useForm} from '../../../elements';
import {FeatureLoanDecreaseForm} from '../../state';
import {Amount} from './Amount';
import {Ticker as TickerField} from './Ticker';

export const Ticker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {instance, ticker, amountOnChange},
  } = useForm<FeatureLoanDecreaseForm>();

  const handlePressAllAmount = React.useCallback(() => {
    ticker && amountOnChange(ticker.amount);
  }, [ticker, amountOnChange]);

  if (!ticker) {
    warning(ticker, 'Ticker is not defined');

    return null;
  }

  return (
    <Box width="100%" {...props}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Label mb={0}>{t('surface.loans.decrease_form.amount')}</Label>
        <Button type="ghost" size="tiny" onPress={handlePressAllAmount}>
          {`${t('surface.loans.decrease_form.amount_all')} ${
            ticker.amount
          } ${ticker.ticker.toUpperCase()}`}
        </Button>
      </Box>
      <Box position="relative">
        <Amount />
        <TickerField />
      </Box>
      <Error errors={instance.errors()} name={['amount']} />
    </Box>
  );
});
