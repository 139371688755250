import * as React from 'react';
import {Path, PathProps} from 'react-native-svg';

export const LineCurve: React.FC<PathProps> = React.memo(props => {
  return (
    <Path
      fill="transparent"
      vectorEffect="non-scaling-stroke"
      strokeWidth={1.5}
      strokeLinejoin="round"
      strokeLinecap="round"
      {...props}
    />
  );
});
