import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const ExternalLinkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M3.75 5c0-.69.56-1.25 1.25-1.25h2a.75.75 0 000-1.5H5A2.75 2.75 0 002.25 5v10A2.75 2.75 0 005 17.75h10A2.75 2.75 0 0017.75 15v-2a.75.75 0 00-1.5 0v2c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25V5z"
          fill={color}
        />
        <Path
          d="M12 2.25a.75.75 0 000 1.5h3.19L9.47 9.47a.75.75 0 101.06 1.06l5.72-5.72V8a.75.75 0 001.5 0V3a.75.75 0 00-.75-.75h-5z"
          fill={color}
        />
      </Svg>
    );
  }
);
