import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {InstrumentsList} from '../InstrumentsList';
import {NoResults} from '../../../../components';

interface InstrumentsListSmartProps {
  separateInstrumentsSection?: boolean;
}

export const InstrumentsListSmart: React.FC<InstrumentsListSmartProps> =
  observer(({separateInstrumentsSection}) => {
    const {instrumentsSorted} = HODLsTariffsFeature.use({});

    const instrumentsListProps = separateInstrumentsSection
      ? {
          borderRightWidth: 0,
          borderLeftWidth: 0,
          borderBottomWidth: 0,
          borderTopWidth: 1,
          borderRadius: 0,
        }
      : {};

    if (instrumentsSorted.length === 0) {
      return <NoResults />;
    }

    return (
      <InstrumentsList items={instrumentsSorted} {...instrumentsListProps} />
    );
  });
