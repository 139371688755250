import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {scramble} from '@web-app/src/utils';
import {
  MenuItem,
  MenuItemProps,
} from '@web-app/src/components/elements/MenuItem';
import {VerificationStatus} from './VerificationStatus';

export const MenuItemProfile: React.FC<Pick<MenuItemProps, 'onPress'>> =
  observer(({onPress}) => {
    const {t} = useTranslation();

    const {
      data: {fullName, email, kycResult},
    } = AuthMeResource.use({});

    return (
      <MenuItem
        testID="MENU_ITEM_PROFILE_BUTTON"
        onPress={onPress}
        to="/profile"
        icon="profile"
        pathKey="profile"
        text={t('surface.menu.profile')}
      >
        <VerificationStatus
          status={
            kycResult === 'ACCEPT' || kycResult === 'DENY'
              ? kycResult
              : 'REVIEW'
          }
        >
          {fullName || scramble(email, {start: 8, end: 12})}
        </VerificationStatus>
      </MenuItem>
    );
  });
