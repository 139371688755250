import * as React from 'react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const NoResults: React.FC<BoxProps> = React.memo(({...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      height={64}
      alignItems="center"
      justifyContent="center"
      borderRadius={{default: 0, desktop: 10}}
      borderWidth={{default: 0, desktop: 1}}
      overflow="hidden"
      borderColor="$ui-01"
      {...boxProps}
    >
      <Text>{t('surface.hodls.instruments.no_result_title')}</Text>
    </Box>
  );
});
