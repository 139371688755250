import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SnowflakeCrossedIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M10 .75a.75.75 0 01.75.75v2l.8-.6a.75.75 0 11.9 1.2l-1.7 1.275v1.047l-1.5 1.5V5.375L7.55 4.1a.75.75 0 01.9-1.2l.8.6v-2A.75.75 0 0110 .75zm4.458 7.62l3.273-3.273a.75.75 0 01.005 1.302l-1.732 1 .92.393a.75.75 0 01-.59 1.38l-1.876-.802zm-3.214 3.214l1.098-1.098 2.038 1.177 1.955-.835a.75.75 0 11.589 1.38l-.92.393 1.732 1a.75.75 0 11-.75 1.298l-1.732-1 .12.993a.75.75 0 11-1.49.18l-.254-2.11-2.386-1.378zM9.25 14.625v-1.047l1.5-1.5v2.547l1.7 1.275a.75.75 0 11-.9 1.2l-.8-.6v2a.75.75 0 01-1.5 0v-2l-.8.6a.75.75 0 11-.9-1.2l1.7-1.275zM5.542 11.63l-3.273 3.273a.75.75 0 01-.005-1.302l1.732-1-.92-.393a.75.75 0 01.59-1.38l1.876.802zm3.214-3.214L7.658 9.514 5.62 8.337l-1.955.835a.75.75 0 01-.589-1.38l.92-.392-1.732-1a.75.75 0 11.75-1.3l1.732 1-.12-.992a.75.75 0 011.49-.18l.254 2.11 2.386 1.378zM17.53 3.53a.75.75 0 00-1.06-1.06l-14 14a.75.75 0 101.06 1.06l14-14z"
          fill={color}
        />
      </Svg>
    );
  }
);
