import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {AdvCashCreatePaymentFormState} from '../state';

export const Footer: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {
      instance: {submitting, onSubmit},
    },
  } = useForm<AdvCashCreatePaymentFormState>();

  return (
    <Box p={{default: 16, phone: 24}} {...boxProps}>
      <Button size="large" loading={submitting} onPress={onSubmit}>
        {t('surface.wallets.fiat_deposit.adv_cash.submit')}
      </Button>
    </Box>
  );
});
