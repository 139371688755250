import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';

export interface LoanItemHeaderStatusOpeningProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeaderStatusOpening: React.FC<LoanItemHeaderStatusOpeningProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <Box testID={`LOAN_ITEM_STATUS_${item.status}`} alignItems="flex-end">
        <Text variant="$body-02" color="$text-02">
          {t('surface.loans.item.opening_distance')}
        </Text>
      </Box>
    );
  });
