import * as React from 'react';
import * as yupExtended from '@youtoken/ui.yup';

type Yup = typeof yupExtended;

/** __hook, use memoized validation schema__
 *
 * main reason for this is to be a hook is to be able to validate against dynamic values;
 *
 * dont forget to pass dynamic values to `inputs` as of `useMemo`
 */
export const useValidationSchema = <FormValues>(
  fn: (y: Yup) => Record<keyof FormValues, yupExtended.AnySchema>
) => {
  return React.useCallback(() => {
    return yupExtended.object().shape(fn(yupExtended));
  }, []);
};
