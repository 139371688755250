import * as React from 'react';
import {observer} from 'mobx-react';
import {type ListRenderItem} from 'react-native';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type Wallet} from '@youtoken/ui.resource-wallets';
import {type WalletsChooseTickerListItemWrapper} from '../../types';
import {FlatListComponent} from './FlatListComponent';
import {ListItem} from './Item';

export const WalletsListCreating: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box flex={1} overflow="hidden" py={24} px={{default: 16, phone: 24}}>
      <Box flexGrow={1} alignItems="center" justifyContent="center" py={24}>
        <Box mb={16}>
          <ActivityIndicator size={32} />
        </Box>
        <Text color="$text-05" variant="$body-01" textAlign="center">
          {t('surface.wallets.wallets_list.creating_wallets')}
        </Text>
      </Box>
    </Box>
  );
});

export interface WalletsListProps {
  items: Wallet[];
  ListItemWrapper?: WalletsChooseTickerListItemWrapper;
}

export const WalletsList: React.FC<WalletsListProps> = observer(
  ({items, ListItemWrapper}) => {
    const renderItem = React.useCallback<ListRenderItem<Wallet>>(
      ({item}) => {
        if (!ListItemWrapper) {
          return <ListItem item={item} />;
        }

        return (
          <ListItemWrapper item={item}>
            <ListItem item={item} />
          </ListItemWrapper>
        );
      },
      [ListItemWrapper]
    );

    const keyExtractor = React.useCallback((item: Wallet) => item.ticker, []);

    return (
      <FlatListComponent
        items={items}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    );
  }
);
