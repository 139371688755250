import * as React from 'react';
import {Svg, SvgProps} from 'react-native-svg';

export type TopLevelWrapProps = {
  /** props **/
};

export const TopLevelWrap: React.FC<SvgProps> = React.memo(props => {
  return <Svg {...props} />;
});
