import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const SuccessRewardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg width={size} height={size} fill="none" {...props}>
        <Path
          fill={color}
          fillRule="evenodd"
          d="M2.8 2.6a.6.6 0 00.6-.6.6.6 0 00.6.6.6.6 0 00-.6.6.6.6 0 00-.6-.6M16 2.2a1.4 1.4 0 01-1.4 1.4A1.4 1.4 0 0116 5a1.4 1.4 0 011.4-1.4A1.4 1.4 0 0116 2.2M1.4 9.6A1.4 1.4 0 010 11a1.4 1.4 0 011.4 1.4A1.4 1.4 0 012.8 11a1.4 1.4 0 01-1.4-1.4M17 15.2a1 1 0 01-1 1 1 1 0 011 1 1 1 0 011-1 1 1 0 01-1-1m-.6-5.2a6.4 6.4 0 11-12.8 0 6.4 6.4 0 0112.8 0M9.742 2.603a7.4 7.4 0 013.152.585l.078-.184a7.6 7.6 0 00-10.378 5.29l.195.045a7.4 7.4 0 016.953-5.736m7.63 6.74a7.4 7.4 0 00-.816-2.777l.177-.093a7.602 7.602 0 01-6.087 11.098 7.6 7.6 0 01-7.404-4.096l.178-.092a7.4 7.4 0 0013.951-4.04M8.874 6.906c-.676 0-1.219.462-1.219 1.031 0 .151.025.316.091.47a.656.656 0 00-.653.655v.563c0 .262.153.488.375.593v2.032c0 .362.294.656.656.656h3.75a.656.656 0 00.656-.656v-2.032a.66.66 0 00.375-.593v-.563a.656.656 0 00-.653-.656c.066-.153.09-.318.09-.469 0-.569-.542-1.03-1.218-1.03-.456 0-.867.262-1.125.693-.258-.431-.669-.694-1.125-.694m-.557 1.367c.054.065.156.133.37.133h1.01c-.094-.642-.509-.937-.823-.937-.45 0-.656.288-.656.468 0 .146.037.262.099.336m-.568.696h1.969v.75H7.75a.094.094 0 01-.094-.094v-.563c0-.051.042-.093.094-.093m.281 3.281v-1.969H9.72v2.063H8.125a.094.094 0 01-.094-.094m2.25-1.969v2.063h1.594a.094.094 0 00.094-.094v-1.969zm0-1.312v.75h1.969a.094.094 0 00.094-.094v-.563a.094.094 0 00-.094-.093zm1.401-.696c-.054.065-.156.133-.37.133h-1.01c.094-.642.509-.937.823-.937.45 0 .656.288.656.468a.52.52 0 01-.099.336"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
