import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DocumentIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M7.25 6a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5h-5.5zM6.5 9.75A.75.75 0 017.25 9h5.5a.75.75 0 010 1.5h-5.5a.75.75 0 01-.75-.75zM7.25 12a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 4a2 2 0 012-2h6.257a3 3 0 012.122.879L15.62 4.62a3 3 0 01.879 2.122V16a2 2 0 01-2 2h-9a2 2 0 01-2-2V4zM5 4a.5.5 0 01.5-.5h6.172a2 2 0 011.414.586l1.328 1.328A2 2 0 0115 6.828V16a.5.5 0 01-.5.5h-9A.5.5 0 015 16V4z"
          fill={color}
        />
      </Svg>
    );
  }
);
