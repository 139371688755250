import React from 'react';
import {observer} from 'mobx-react';
import {BriefStatus, type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export interface StatusWithPeriodProps {
  hodl: HODLItem;
  briefStatusVariant: string;
  isDetailedPage?: boolean;
}

export const StatusWithPeriod: React.FC<StatusWithPeriodProps> = observer(
  ({briefStatusVariant, hodl: {relativeDate}, isDetailedPage}) => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();

    return (
      <Box>
        {(isMobile || isDetailedPage) && (
          <Box flexDirection="row" justifyContent="flex-end">
            <Text variant="$body-02-high-accent" color="$text-02" mb={2}>
              {briefStatusVariant === BriefStatus.CANCEL_BY_USER &&
                t('surface.hodls.item.canceled_label')}
              {briefStatusVariant === BriefStatus.EXPIRED &&
                t('surface.hodls.item.expired_label')}
            </Text>
          </Box>
        )}
        <Box flexDirection="row" justifyContent="flex-end">
          <Text variant="$body-02" color="$text-02">
            {relativeDate}
          </Text>
        </Box>
      </Box>
    );
  }
);
