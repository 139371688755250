import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AgreementsList} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {Box} from '@youtoken/ui.primitives';

interface FooterSectionProps {
  onCLoseButtonPress: () => void;
  isClosing: boolean;
  amountFormatted: string;
  tickerFormatted: string;
  isApproximate?: boolean;
}

export const FooterSection: React.FC<FooterSectionProps> = observer(
  ({
    onCLoseButtonPress,
    isClosing,
    amountFormatted,
    tickerFormatted,
    isApproximate,
  }) => {
    const {t} = useTranslation();

    return (
      <>
        <Box px={24} py={16}>
          <AgreementsList
            beforeListText={`${t(
              'agreements.documents.without_checkbox.start_text_short',
              {
                call_to_action: t('surface.hodls.close_now.action_button'),
              }
            )}, ${t('surface.hodls.close_now.checkbox_text')}`}
            items={[
              {
                name: t('surface.hodls.close_now.checkbox_link_title'),
                link: t('surface.hodls.close_now.checkbox_link'),
              },
            ]}
          />
        </Box>
        <Button
          m={24}
          mt={0}
          size="large"
          onPress={onCLoseButtonPress}
          disabled={isClosing}
          testID="CLOSE_AND_GET_BUTTON"
        >
          {`${t('surface.hodls.close_now.action_button')} ${
            isApproximate ? '≈' : ''
          } ${amountFormatted} ${tickerFormatted}`}
        </Button>
      </>
    );
  }
);
