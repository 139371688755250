import Big from 'big.js';
import {
  alias,
  createSimpleSchema,
  object,
  primitive,
  serializable,
} from 'serializr';
import {bigNumber, number} from '@youtoken/ui.utils-serializr';

export class ExchangeTariffsResponseTariffData {
  @serializable(bigNumber())
  fee!: Big;

  @serializable(primitive())
  min!: string;

  @serializable(primitive())
  max!: string;

  @serializable(alias('decimal', number()))
  precision!: number;
}

const ToTicker = createSimpleSchema({
  '*': object(ExchangeTariffsResponseTariffData),
});

const FromTicker = createSimpleSchema({
  '*': object(ToTicker),
});

export class ExchangeTariffsResponse {
  @serializable(object(FromTicker))
  tariffs: Record<string, Record<string, ExchangeTariffsResponseTariffData>> =
    {};
}
