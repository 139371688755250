import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {LoanItemHeaderStatusOtherV1} from './OtherV1';
import {LoanItemHeaderStatusOtherV2} from './OtherV2';

export interface LoanItemHeaderStatusOtherProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeaderStatusOther: React.FC<LoanItemHeaderStatusOtherProps> =
  observer(({item}) => {
    if (item.version === 2) {
      return <LoanItemHeaderStatusOtherV2 item={item} />;
    }

    return <LoanItemHeaderStatusOtherV1 item={item} />;
  });
