import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const CoindropBadge: React.FC<BoxProps> = observer(({...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      backgroundColor="$attention-02"
      borderRadius={24}
      py={4}
      pr={10}
      pl={8}
      alignItems="center"
      flexDirection="row"
      {...boxProps}
    >
      <Box mr={6}>
        <Icon name="pending" color="$attention-01" size={16} />
      </Box>
      <Text
        variant="$body-02-medium-accent"
        color="$attention-01"
        textAlign="center"
        testID="COINDROPS_BADGE_TEXT"
      >
        {t('coindrop.badge')}
      </Text>
    </Box>
  );
});
