import {TColorTokens} from '@youtoken/ui.primitives';
import {type GetColorUtils} from './types';

export const getGhostTabTextColorVariant = ({
  type,
  size,
  disabled,
}: GetColorUtils): keyof TColorTokens => {
  if (disabled) {
    return '$ui-04';
  }
  if (type === 'ghost' && size === 'tiny') {
    return '$interactive-01';
  }
  return '$text-04';
};

export const getUnderlayTextColorVariant = ({
  type,
  size,
  disabled,
}: GetColorUtils): keyof TColorTokens => {
  if (disabled) {
    return '$ui-02';
  }
  if (type === 'ghost' && size === 'tiny') {
    return '$interactive-02';
  }
  return '$interactive-01';
};

export const getTabTextColorVariant = ({
  type,
  size,
  disabled,
}: GetColorUtils): keyof TColorTokens => {
  if (disabled) {
    return '$text-03';
  }
  if (type === 'ghost' && size === 'tiny') {
    return '$text-02';
  }
  return '$interactive-01';
};
