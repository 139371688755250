import {
  action,
  autorun,
  reaction,
  computed,
  observable,
  IReactionDisposer,
} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {
  FeatureLoanCreateLandingFormArgs,
  FeatureLoanCreateLandingFormResources,
} from './index';
import {toBig} from '@youtoken/ui.formatting-utils';
import {
  getCoinDecimalPrecisionForLoans,
  getCoinName,
} from '@youtoken/ui.coin-utils';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';

export type FormTerm =
  | FeatureLoanCreateLandingFormResources['tariffsResource']['data'][number]
  | undefined;

export class Form {
  @observable
  public args: FeatureLoanCreateLandingFormArgs;

  @observable
  public resources: FeatureLoanCreateLandingFormResources;

  @observable
  public instance: MobxReactForm;

  @observable
  public disposers: IReactionDisposer[];

  @observable
  public from: string = 'amount';

  @computed.struct
  public get loanMarkets() {
    return this.resources.tariffsResource.loanMarkets;
  }

  @computed.struct
  public get tickers() {
    const avaliableTickers = Object.keys(this.loanMarkets);

    const res = avaliableTickers.map(ticker => {
      const coinName = getCoinName(ticker);

      return {
        ticker: ticker,
        tickerFormatted: ticker.toUpperCase(),
        coinName,
        amountFormatted: '',
        hasAmount: false,
        key: `${ticker} ${coinName}`,
      };
    });

    return res;
  }

  @computed.struct
  public get conversionTickers() {
    const avaliableTickers = this.loanMarkets[this.ticker];

    const res = avaliableTickers?.map(ticker => {
      const coinName = getCoinName(ticker);

      return {
        ticker: ticker,
        tickerFormatted: ticker.toUpperCase(),
        coinName,
        amountFormatted: '',
        hasAmount: false,
        key: `${ticker} ${coinName}`,
      };
    });

    return res;
  }

  @computed.struct
  public get terms() {
    return this.resources.tariffsResource.data.filter(
      term =>
        term.collateralTicker === this.ticker &&
        term.settings.find(
          setting => setting.borrowedTicker === this.conversionTicker
        )
    );
  }

  @computed.struct
  public get term() {
    return this.terms.find(term => term.id === this.termId);
  }

  @computed.struct
  public get termSetting() {
    return this.term?.settings.find(
      setting => setting.borrowedTicker === this.conversionTicker
    );
  }

  @computed
  public get rate() {
    return this.resources.ratesResource
      .getRate(this.ticker, this.conversionTicker)
      .toString();
  }

  // collateral start

  @computed
  public get ticker() {
    return (
      this.instance?.$('ticker').get('value') ??
      this.tickers.find(t => t.ticker === this.args.ticker)?.ticker ??
      this.tickers.find(t => t.ticker === 'btc')?.ticker ??
      this.tickers[0]?.ticker
    );
  }

  @computed
  public get amount() {
    return this.instance?.$('amount').get('value') ?? this.args.amount;
  }

  @computed
  public get collateralError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get collateralHasError() {
    return Boolean(this.collateralError);
  }

  @computed
  public get tickerOnChange() {
    return this.instance.$('ticker').get('onChange');
  }

  @computed
  public get amountOnChange() {
    return this.instance.$('amount').get('onChange');
  }

  // collateral end

  // borrowed start

  @computed
  public get conversionTicker() {
    return (
      this.instance?.$('conversionTicker').get('value') ??
      this.conversionTickers?.find(t => t.ticker === this.args.conversionTicker)
        ?.ticker ??
      this.conversionTickers?.find(t => t.ticker === 'usd')?.ticker ??
      this.conversionTickers?.[0]?.ticker
    );
  }

  @computed
  public get conversionAmount() {
    return this.instance?.$('conversionAmount').get('value') ?? '';
  }

  @computed
  public get borrowedError() {
    return getTranslatedValidationMessage(
      this.instance.$('conversionTicker').get('error') ??
        this.instance.$('conversionAmount').get('error')
    );
  }

  @computed
  public get borrowedHasError() {
    return Boolean(this.borrowedError);
  }

  @computed
  public get conversionTickerOnChange() {
    return this.instance.$('conversionTicker').get('onChange');
  }

  @computed
  public get conversionAmountOnChange() {
    return this.instance.$('conversionAmount').get('onChange');
  }

  // borrowed end

  // termId start

  @computed
  public get termId() {
    return this.instance?.$('termId').get('value') ?? this.terms[0]?.id;
  }

  @computed
  public get termIdError() {
    return getTranslatedValidationMessage(
      this.instance.$('termId').get('error')
    );
  }

  @computed
  public get termIdHasError() {
    return Boolean(this.termIdError);
  }

  @computed
  public get termIdOnChange() {
    return this.instance.$('termId').get('onChange');
  }

  // termId end

  @action
  public restore = () => {
    // if we want to set the default amount associated with the wallet,
    // we need to do this on restore, because the values in the constructor are immutable
  };

  @action
  public reset = () => {
    this.from = 'amount';
    this.instance.reset();
  };

  @action
  public submit = () => {
    if (window.top) {
      window.top.location.href = this.args.url
        ? this.args.url
        : 'https://app.youhodler.com/sign-up';
    }

    return Promise.resolve();
  };

  public constructor(
    args: FeatureLoanCreateLandingFormArgs,
    resources: FeatureLoanCreateLandingFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
          },
          conversionTicker: {
            value: this.conversionTicker,
          },
          termId: {
            value: this.termId,
          },
          amount: {
            value: this.amount,
            handlers: {
              onChange: (_field: any) => {
                this.from = 'amount';
              },
            },
          },
          conversionAmount: {
            value: this.conversionAmount,
            handlers: {
              onChange: (_field: any) => {
                this.from = 'conversionAmount';
              },
            },
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  ticker: yup.string(),
                  conversionTicker: yup.string(),
                  amount: yup.big(),
                  conversionAmount: yup.big(),
                  termId: yup.string(),
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
    this.disposers = [
      // if ticker changed
      // Then need recalculate [conversionTicker]
      reaction(
        () => {
          return this.ticker;
        },
        () => {
          // console.log('Then need recalculate [conversionTicker]');
          this.conversionTickerOnChange(this.conversionTickers?.[0]?.ticker);
        }
      ),
      // If changed [terms]
      // Then need recalculate [termId]
      reaction(
        () => {
          return this.terms;
        },
        () => {
          if (
            !this.terms.find(term => term.id === this.termId) &&
            this.terms[0]?.id
          ) {
            // console.log('Then need recalculate [termId]');
            this.termIdOnChange(this.terms[0].id);
          }
        }
      ),
      // If changed [from, ticker, conversionTicker, amount, conversionAmount, rate, ltv]
      // Then need recalculate [amount || conversionAmount]
      autorun(() => {
        if (this.from === 'amount') {
          // console.log('Then need recalculate [conversionAmount]');
          const amount = toBig(this.amount);

          if (amount.gt(0)) {
            this.instance
              .$('conversionAmount')
              .set(
                'value',
                toBig(this.rate)
                  .mul(toBig(this.amount))
                  .times(toBig(this.term?.ltv))
                  .round(getCoinDecimalPrecisionForLoans(this.conversionTicker))
                  .toString()
              );

            return;
          }

          this.instance.$('conversionAmount').set('value', '');
          return;
        }

        if (this.from === 'conversionAmount') {
          // console.log('Then need recalculate [amount]');
          const conversionAmount = toBig(this.conversionAmount);

          if (conversionAmount.gt(0)) {
            this.instance
              .$('amount')
              .set(
                'value',
                conversionAmount
                  .div(toBig(this.rate))
                  .div(toBig(this.term?.ltv))
                  .round(getCoinDecimalPrecisionForLoans(this.ticker))
                  .toString()
              );

            return;
          }

          this.instance.$('amount').set('value', '');
          return;
        }
      }),
    ];
  }
}
