import {computed, action} from 'mobx';
import {ChartDataResource} from './ChartDataResource';
import {CandlesLiveData, LineLiveData} from './LiveData';
import {ChartResource} from './types';

/** chart data resource with live data from sockets
 * - same as `ChartDataResource`;
 * - will try to use sockets to enhance data;
 * - sockets does not change signature of a resource, `chartData` is the same
 */
export class LiveChartDataResource extends ChartDataResource {
  candlesLiveData!: CandlesLiveData;

  lineLiveData!: LineLiveData;

  constructor(args: ChartResource.Args, data: ChartResource.Data) {
    super(args, data);
    this.candlesLiveData = new CandlesLiveData(this);
    this.lineLiveData = new LineLiveData(this);
  }

  onDestroy() {
    this.lineLiveData.onDestroy();
    this.candlesLiveData.onDestroy();

    super.onDestroy();
  }

  @computed get chartData(): ChartResource.Data {
    if (this.data.type === 'line') {
      return this.lineLiveData.data;
    }

    if (this.data.type === 'candle') {
      return this.candlesLiveData.data;
    }

    return this.data;
  }

  @action assignData = (data: ChartResource.Data) => {
    this.data = data; // this one will remove socket point if data is modified (new response from REST for example)
    this.candlesLiveData.candlesFromSocket = [];
    // this.lineLiveData.socketLinePoint = undefined; // todo check if we need
  };
}
