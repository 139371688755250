import * as React from 'react';
import {TKey} from '@youtoken/ui.service-i18n';
import {ExchangeFormReverseButton} from '../ReverseButton';
import {ExchangeFormConversionPairSource} from './Source';
import {ExchangeFormConversionPairTarget} from './Target';

export {ExchangeFormConversionPairField} from './Field';
export {ExchangeFormConversionPairSource} from './Source';

export interface ExchangeFormConversionPairProps {
  showBalance?: boolean;
  targetLabelKey?: TKey;
  targetAmountEditable?: boolean;
  initialAmount?: number;
}

export const ExchangeFormConversionPair: React.FC<
  ExchangeFormConversionPairProps
> = ({showBalance, targetLabelKey, targetAmountEditable, initialAmount}) => {
  return (
    <>
      <ExchangeFormConversionPairSource
        showBalance={showBalance}
        initialAmount={initialAmount}
      />
      <ExchangeFormReverseButton />
      <ExchangeFormConversionPairTarget
        labelKey={targetLabelKey}
        amountEditable={targetAmountEditable}
      />
    </>
  );
};
