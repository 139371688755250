import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {AdjustPendingOrderFormClass} from '../../form';

export interface ActionsSectionProps extends BoxProps {
  onCancel: () => void;
}
export const ActionsSection: React.FC<ActionsSectionProps> = observer(
  ({onCancel, ...boxProps}) => {
    const {
      form: {onSubmit},
      view: {isLoading},
    } = useForm<AdjustPendingOrderFormClass>();
    const {t} = useTranslation();

    return (
      <Box flexDirection="row" p={24} gap={16} {...boxProps}>
        <Box flexBasis={0} flexGrow={1}>
          <Button size="large" type="secondary" onPress={onCancel}>
            {t('surface.hodls.adjust_tp.action_cancel')}
          </Button>
        </Box>
        <Box flexBasis={0} flexGrow={1}>
          <Button size="large" onPress={onSubmit} disabled={isLoading}>
            {t('surface.hodls.adjust_tp.action_adjust')}
          </Button>
        </Box>
      </Box>
    );
  }
);
