import * as React from 'react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {AppEnv, LOCAL_STORAGE_ENV_KEY} from '@youtoken/ui.env-utils';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';

export const AppEnvStatus = () => {
  if (ENVIRONMENT.APP_ENV === AppEnv.production) {
    return null;
  }

  const handleChangeContour = React.useCallback(() => {
    SIMPLE_STORAGE.delete(LOCAL_STORAGE_ENV_KEY);
    window.location.reload();
  }, []);

  return (
    <Box
      ml={8}
      borderRadius={6}
      px={8}
      height={32}
      bg="$success-02"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      visible={{
        default: false,
        tablet: true,
      }}
    >
      <Icon name="blockexplorer" color="$success-01" size={18} />

      <Text variant="$body-02" weight="bold" color="$success-01" ml={4} mr={4}>
        {ENVIRONMENT.APP_ENV}
      </Text>

      <TouchableBox
        onPress={handleChangeContour}
        borderLeftWidth={1}
        borderColor="$success-03"
        pl={4}
        ml={8}
        height={32}
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="trading" color="$success-01" size={18} />
      </TouchableBox>
    </Box>
  );
};
