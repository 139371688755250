import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const RemoveIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.97 2.97a.75.75 0 01.976-.073l.084.073L6 4.939l1.97-1.97a.75.75 0 011.133.977l-.073.084L7.061 6l1.97 1.97.072.084a.75.75 0 01-1.05 1.049L7.97 9.03 6 7.061l-1.97 1.97a.75.75 0 01-1.133-.977l.073-.084L4.939 6l-1.97-1.97-.072-.084a.75.75 0 01.073-.976z"
          fill={color}
        />
      </Svg>
    );
  }
);
