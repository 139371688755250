import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {RewardsHistoryModalTrigger} from '../../..';

interface RewardsHistoryTitleProps {
  showAllTrigger?: boolean;
}

export const RewardsHistoryTitle: React.FC<RewardsHistoryTitleProps> = observer(
  ({showAllTrigger = true}) => {
    const {t} = useTranslation();

    return (
      <Box
        px={24}
        py={16}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Text variant="$heading-02">
            {t('surface.referral_program.referrals.recent_bonuses_subheading')}
          </Text>
        </Box>
        {showAllTrigger && <RewardsHistoryModalTrigger />}
      </Box>
    );
  }
);
