import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LoanItemHeaderBrief} from '../LoanItemHeaderBrief';
import {LoanItemHeaderStatus} from '../LoanItemHeaderStatus';

export interface LoanItemHeaderProps extends BoxProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemHeader: React.FC<LoanItemHeaderProps> = observer(
  ({item, ...rest}) => {
    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        {...rest}
      >
        <LoanItemHeaderBrief item={item} />
        <LoanItemHeaderStatus item={item} />
      </Box>
    );
  }
);
