import * as React from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Separator, useWizard, KeyboardScrollView} from '@youtoken/ui.elements';
import {i18n} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {HeaderBase} from '../../../components';
import {CryptoWithdrawalBanner} from '../../../CryptoWithdrawalBanner';
import {CryptoWithdrawalExternalForm} from '../../../CryptoWithdrawalExternalForm';
import {
  CryptoWithdrawalWizardNavigator,
  CryptoWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const getCryptoWithdrawalTitle = (ticker: string) => {
  return i18n.t('surface.wallets.crypto_withdrawal.title', {
    ticker: ticker.toUpperCase(),
  });
};

const CryptoWithdrawalContentWrapper: React.FC = observer(({children}) => {
  const {bottom} = useSafeAreaInsets();

  return (
    <KeyboardScrollView
      insideTabs={false}
      scrollViewProps={{
        alwaysBounceVertical: false,
        keyboardDismissMode: 'none',
        style: {flexGrow: 0},
        contentContainerStyle: {
          flexGrow: 0,
          paddingTop: 0,
          paddingBottom: bottom,
          paddingLeft: 0,
          paddingRight: 0,
        },
      }}
    >
      {children}
    </KeyboardScrollView>
  );
});

export const CryptoWithdrawal: React.FC = cell(
  () => {
    const {
      step: {
        props: {ticker},
      },
      goBack,
    } = useWizard<
      CryptoWithdrawalWizardNavigator,
      CryptoWithdrawalWizardScreenName.WITHDRAWAL
    >();

    return (
      <>
        {showHeader && (
          <>
            <HeaderBase onPressBack={goBack}>
              {getCryptoWithdrawalTitle(ticker)}
            </HeaderBase>
            <Separator />
          </>
        )}

        <CryptoWithdrawalContentWrapper>
          <CryptoWithdrawalBanner ticker={ticker} />
          <CryptoWithdrawalExternalForm ticker={ticker} />
        </CryptoWithdrawalContentWrapper>
      </>
    );
  },
  {
    CellWrap: ({children}) => <Box minHeight={200}>{children}</Box>,
  }
);
