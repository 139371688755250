import * as React from 'react';
import {SvgProps, Svg, Path, Circle} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';

export const ProfileIcon = ({...props}: SvgProps) => {
  const {colors} = useTheme();

  return (
    <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <Circle cx="14" cy="14" r="14" fill={colors['$ui-02']} />
      <Path
        d="M3.22853 22.9435C4.55635 20.3827 6.86122 18.4102 9.64948 17.5196L14 21L18.3505 17.5196C21.1388 18.4102 23.4436 20.3827 24.7715 22.9435C22.2034 26.033 18.3314 28 14 28C9.66857 28 5.79656 26.033 3.22853 22.9435Z"
        fill={colors['$ui-04']}
      />
      <Path
        d="M14 16C17.3137 16 20 13.3137 20 10C20 6.68629 17.3137 4 14 4C10.6863 4 8 6.68629 8 10C8 13.3137 10.6863 16 14 16Z"
        fill={colors['$ui-04']}
      />
      <Path
        d="M9.67847 5.83775C11.997 8.16584 15.9814 9.01631 17 9L17 7.5C17.8706 7.95178 18.8011 8.23592 19.7832 8.39608C19.0817 5.86113 16.7582 4 14 4C12.3026 4 10.7699 4.70482 9.67847 5.83775Z"
        fill={colors['$text-02']}
      />
    </Svg>
  );
};
