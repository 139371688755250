import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ReferralsRoundedText} from '../..';

interface RewardsBannerGetAmountProps {
  amount: number;
  maxAmount: number;
}

export const RewardsBannerGetAmount: React.FC<RewardsBannerGetAmountProps> =
  React.memo(({amount, maxAmount}) => {
    const {t} = useTranslation();

    return (
      <ReferralsRoundedText bg="$interactive-01" textColor="$text-04">
        {amount > 0
          ? '$' + amount
          : t('surface.referral_program.referrals_widget.get_amount', {
              amount: '$' + maxAmount,
            })}
      </ReferralsRoundedText>
    );
  });
