import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLInstrumentResource} from '@youtoken/ui.resource-hodl-instrument';
import {Box, Heading, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MarketStatsItem} from './MarketStatsItem/MarketStatsItem';
import type {MarketStatsSectionProps} from './types';
import {invariant} from '@youtoken/ui.utils';

enum colorOptions {
  'up' = '$success-01',
  'down' = '$danger-01',
}

export const MarketStatsSection: React.FC<MarketStatsSectionProps & BoxProps> =
  observer(({baseTicker, quoteTicker, isLedgerMode, ...boxProps}) => {
    const {t} = useTranslation();
    const {
      data: {marketStats},
    } = HODLInstrumentResource.use({
      baseTicker,
      quoteTicker,
    });

    invariant(marketStats, 'Market stats should be defined', {}, {marketStats});

    const {
      marketCapAmount,
      marketCapTotalPercentFormatted,
      volumeAmount,
      volumePercentDiffFormatted,
      volumePercentDiffDirection,
      supplyAmount,
      supplyFromTotalPercentFormatted,
      rankFormatted,
    } = marketStats;

    return (
      <Box px={24} pt={20} pb={24} {...boxProps}>
        <Heading variant="$heading-02" mb={16}>
          {t('surface.hodls.instrument.market_stats_title')}
        </Heading>
        <Box
          flexDirection={{
            default: 'column',
            desktop: isLedgerMode ? 'column' : 'row',
          }}
          flexWrap="wrap"
          m={-4}
        >
          <Box flexDirection="row" flex={1}>
            {Boolean(marketCapAmount) && (
              <MarketStatsItem
                title={t('surface.hodls.instrument.market_cap')}
                amountFormatted={marketCapAmount!.amountFormatted}
                amountMeasureFormatted={marketCapAmount!.unit}
                tooltipText={t('surface.hodls.instrument.market_cap_tooltip')}
                description={
                  marketCapTotalPercentFormatted &&
                  t('surface.hodls.instrument.market_cap_description', {
                    amount: marketCapTotalPercentFormatted,
                  })
                }
              />
            )}
            {Boolean(volumeAmount) && (
              <MarketStatsItem
                title={t('surface.hodls.instrument.volume')}
                amountFormatted={volumeAmount.amountFormatted}
                amountMeasureFormatted={volumeAmount.unit}
                tooltipText={t('surface.hodls.instrument.volume_tooltip')}
                webTooltipOffset={{default: -143, desktop: 0}}
                description={
                  volumePercentDiffFormatted && (
                    <Box flexDirection="row" alignItems="center">
                      <Icon
                        name={volumePercentDiffDirection}
                        size={12}
                        color={colorOptions[volumePercentDiffDirection]}
                      />
                      <Text
                        ml={3}
                        variant="$body-02-medium-accent"
                        color={colorOptions[volumePercentDiffDirection]}
                      >
                        {volumePercentDiffFormatted}
                      </Text>
                    </Box>
                  )
                }
              />
            )}
          </Box>
          <Box flexDirection="row" flex={1}>
            {Boolean(supplyAmount) && (
              <MarketStatsItem
                title={t('surface.hodls.instrument.supply')}
                amountFormatted={supplyAmount.amountFormatted}
                amountMeasureFormatted={supplyAmount.unit}
                tooltipText={t('surface.hodls.instrument.supply_tooltip')}
                description={
                  supplyFromTotalPercentFormatted &&
                  t('surface.hodls.instrument.supply_description', {
                    amount: supplyFromTotalPercentFormatted,
                  })
                }
              />
            )}
            {Boolean(rankFormatted) && (
              <MarketStatsItem
                title={t('surface.hodls.instrument.rank')}
                amountFormatted={rankFormatted!}
                tooltipText={t('surface.hodls.instrument.rank_tooltip')}
                webTooltipOffset={{default: -143, desktop: 0}}
                description={t('surface.hodls.instrument.rank_description')}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  });
