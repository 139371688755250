import {observable} from 'mobx';
import type {BuyCryptoFormAuthorizedStateBaseArgs} from '../types';
import type {BuyCryptoFormAuthorizedYouHodlerStateResources} from './types';
import {ViewBase} from '../ViewBase';
import {Form} from './Form';

export class BuyCryptoFormAuthorizedYouHodlerState {
  @observable
  public form: Form;

  @observable
  public view: ViewBase;

  constructor(
    args: BuyCryptoFormAuthorizedStateBaseArgs,
    resources: BuyCryptoFormAuthorizedYouHodlerStateResources
  ) {
    this.form = new Form(args, resources);
    this.view = new ViewBase(this.form);
  }
}
