import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';

export const InstrumentsListCleaner: React.FC = observer(({children}) => {
  const {setInputVal} = HODLsTariffsFeature.use({});

  React.useEffect(() => {
    setInputVal('');
  }, [setInputVal]);

  return <>{children}</>;
});
