import * as React from 'react';
import {observer} from 'mobx-react';
import {SubmitButton} from '@youtoken/ui.form-elements';
import {BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const Submit: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();

  return (
    <SubmitButton icon="os_mac" color="apple-pay" {...props}>
      {t('surface.apple_pay.deposit.components.submit.default')}
    </SubmitButton>
  );
});
