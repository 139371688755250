import * as React from 'react';
import {useChartCursorState, WalletChart, ChartControls} from '../Charts';
import {cell} from '@youtoken/ui.cell';
import {LiveChartDataResource} from '../state/Chart/LiveChartDataResource';
import {ChartWithControlsSuspenseFallback} from './SuspenseFallbacks';
import {ChartSmartCells} from './utils/types';
import {CellWrap} from './utils/CellWrap';
import {PanResponder} from '../PanResponder';

/** Wallet smart cell. accepts only
 *
 * - fromTicker,
 * - toTicker
 * - dimensions (width, height)
 * - PanController component for cursor (not required)
 *
 * and takes care of the rest
 * */
export const WalletChartSmartCell =
  cell<ChartSmartCells.WalletChartSmartCellProps>(
    ({
      width,
      height,
      fromTicker,
      toTicker,
      precision,
      ChartPanComponent = PanResponder,
      // mode,
    }) => {
      const {setPointIndex, pointIndex, setCursorX, cursorX} =
        useChartCursorState();

      const {isLoading, chartData, chartControls, hasData, refetch} =
        LiveChartDataResource.use({
          key: 'wallet',
          fromTicker,
          toTicker,
          timePeriod: '24h',
          tick: '15m',
          type: 'line',
          mode: 'mid',
        });

      return (
        <>
          <ChartPanComponent
            width={width}
            height={height}
            onCursorChange={setCursorX}
          >
            <WalletChart
              data={chartData}
              height={height}
              width={width}
              cursorX={hasData ? cursorX : undefined}
              isFetching={isLoading}
              displayActivityIndicator
              onCursorIndexChange={setPointIndex}
              hasData={hasData}
              refetch={refetch}
              formatNumberPrecision={precision}
            />
          </ChartPanComponent>
          <ChartControls
            isCursorActive={pointIndex !== undefined && hasData}
            {...chartControls}
          />
        </>
      );
    },
    {
      displayName: 'SmartWalletChartCell',
      SuspenseFallback: ChartWithControlsSuspenseFallback,
      CellWrap: CellWrap,
    }
  );
