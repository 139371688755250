import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardWidgetResource} from '@youtoken/ui.resources-rewards';
import {
  type RewardsBannerLayoutProps,
  RewardsBannerContent,
} from '../../components';

export const RewardsBannerV1Smart: React.FC<RewardsBannerLayoutProps> =
  observer(({variant}) => {
    const {
      data: {
        totalEarned: {usd: totalEarnedUSD},
        maxEarnings: {usd: maxEarningsUSD},
        newsCount,
      },
    } = RewardWidgetResource.use({});

    return (
      <RewardsBannerContent
        variant={variant}
        amount={totalEarnedUSD.toNumber()}
        maxAmount={maxEarningsUSD.toNumber()}
        newsCount={newsCount}
      />
    );
  });
