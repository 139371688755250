import {ResourceInstance} from '@youtoken/ui.data-storage';
import {
  action,
  computed,
  observable,
  observe,
  reaction,
  runInAction,
} from 'mobx';
import {type ChartResource} from '../types';
import {TRANSPORT} from '@youtoken/ui.transport';

/** candles data socket enhancer
 * - will sub/unsub to sockets;
 * - will merge data from rest api with socket data (if any);
 */
export class CandlesLiveData {
  resource!: ResourceInstance<ChartResource.Args, ChartResource.Data>;

  _disposeFromTickObserver!: ReturnType<typeof observe>;
  _disposeFromTypeObserver!: ReturnType<typeof reaction>;
  _disposeFromDataObserver!: ReturnType<typeof reaction>;
  _disposeOfShouldSubscribeReaction!: ReturnType<typeof reaction>;
  _disposeFromObservedState!: ReturnType<typeof observe>;
  _disposeFromModeObserver!: ReturnType<typeof observe>;

  @observable isSubscribedToSocket: boolean = false;
  @observable candlesFromSocket: ChartResource.Candles.Candle[] = [];

  @computed get subscriptionEventName() {
    return this.resource.args.product === 'hodl'
      ? 'product-rates-candles'
      : 'candles';
  }

  onDestroy() {
    this._disposeFromTickObserver?.();
    this._disposeFromTypeObserver?.();
    this._disposeFromDataObserver?.();
    this._disposeFromObservedState?.();
    this._disposeFromModeObserver?.();
    this._disposeOfShouldSubscribeReaction?.();

    TRANSPORT.SOCKET.off(this.subscriptionEventName, this.handleSocketCandles);
    TRANSPORT.SOCKET.off('connect', this.onConnectSocket);
  }

  @action onConnectSocket = () => {
    if (this.shouldSubscribeToSockets) {
      this.isSubscribedToSocket = false;

      this.sub(this.resource.args);
    }
  };

  constructor(
    resource: ResourceInstance<ChartResource.Args, ChartResource.Data>
  ) {
    this.resource = resource;

    TRANSPORT.SOCKET.on(this.subscriptionEventName, this.handleSocketCandles);
    TRANSPORT.SOCKET.on('connect', this.onConnectSocket);

    // react to tick change. this is exclusive for candles
    this._disposeFromTickObserver = observe(
      this.resource.args,
      'tick',
      this.reactToTickChanged
    );

    this._disposeFromModeObserver = observe(
      this.resource.args,
      'mode',
      this.reactToModeChanged
    );

    this._disposeOfShouldSubscribeReaction = reaction(
      () => this.shouldSubscribeToSockets,
      shouldSubscribe => {
        if (shouldSubscribe) {
          this.sub(this.resource.args);
        } else {
          this.unsub(this.resource.args);
          this.resetSocketCandles();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  @computed get shouldSubscribeToSockets() {
    return this.resource.isDataObserved && this.resource.args.type === 'candle';
  }

  @action resetSocketCandles = () => {
    this.candlesFromSocket = [];
  };

  @computed get data(): ChartResource.Candles.Data {
    // if type of rest api data is line we just return in (just in case)
    if (this.resource.data.type !== 'candle') {
      return this.resource.data as unknown as ChartResource.Candles.Data;
    }

    if (!this.resource.data.data || this.resource.data.data.length <= 2) {
      return this.resource.data as unknown as ChartResource.Candles.Data;
    }

    // if candles from socket is empty there is nothing to do, just return it;
    if (this.candlesFromSocket.length === 0) {
      return this.resource.data as unknown as ChartResource.Candles.Data;
    }

    // cloning data from rest response, we would not like to modify it;
    const data = observable(this.resource.data.data.slice());

    // trying to merge socket candles with candles from rest
    this.candlesFromSocket.forEach(socketCandle => {
      // trying to find an index of candle with same data as socket candle
      const indexOfExistingCandle = data.findIndex(candle => {
        return candle.date.getTime() === socketCandle.date.getTime();
      });

      if (indexOfExistingCandle > -1) {
        // if there is candle with same date we replace it with socket candle, as socket candle is considered correct
        data[indexOfExistingCandle] = socketCandle;
      } else {
        // if socket candle is newer when last candle in data we push it to the end of the array
        if (
          data[data.length - 1] &&
          socketCandle.date.getTime() > data[data.length - 1]!.date.getTime()
        ) {
          data.push(socketCandle);
        }
      }
    });

    return {
      type: 'candle',
      data: data,
    };
  }

  /** react to type change
   * - emit unsub from candles;
   * - dispose of cached candles;
   */
  @action reactToTypeChange() {
    if (this.resource.args.type === 'candle') {
      this.sub(this.resource.args);
    } else {
      this.unsub(this.resource.args);
      this.candlesFromSocket = [];
    }
  }

  /** react to tick change
   * - emit unsub from current tick;
   * - emit sub to next tick;
   * - dispose of existing candles from socket in cache;
   */
  @action reactToTickChanged = ({
    oldValue,
    newValue,
  }: {
    oldValue?: ChartResource.Tick;
    newValue?: ChartResource.Tick;
  }) => {
    if (oldValue !== newValue) {
      this.unsub({
        ...this.resource.args,
        tick: oldValue!,
      });

      this.sub({
        ...this.resource.args,
        tick: newValue!,
      });

      this.resetSocketCandles();
    }
  };

  @action reactToModeChanged = ({
    oldValue,
    newValue,
  }: {
    oldValue?: 'bid' | 'ask' | 'mid';
    newValue?: 'bid' | 'ask' | 'mid';
  }) => {
    if (oldValue !== newValue) {
      this.unsub({
        ...this.resource.args,
        mode: oldValue!,
      });

      this.sub({
        ...this.resource.args,
        mode: newValue!,
      });

      this.resetSocketCandles();
    }
  };

  /** handling new candles incoming from socket
   * - throttled to skip at least some of the updates;
   * - parsing them and pushing to the array;
   */
  handleSocketCandles = (candle: ChartResource.Candles.CandleFromSocket) => {
    // ignoring candles if not connected socket;
    // here we are emitting unsub, unlike line charts so it's just in case and for consistency
    if (!this.isSubscribedToSocket) {
      return;
    }
    // if socket is not right (wrong tick, wrong ticker etc) we just skip it
    if (!this.filterSocketCandle(candle)) {
      return;
    }

    // creating new candle from socket data;
    const incomingCandle: ChartResource.Candles.Candle = {
      close: Number(candle.close),
      date: new Date(candle.date),
      high: Number(candle.high),
      low: Number(candle.low),
      open: Number(candle.open),
    };

    runInAction(() => {
      // if no candles just push new candle to the array
      if (this.candlesFromSocket.length === 0) {
        this.candlesFromSocket.push(incomingCandle);
        return;
      }

      // trying to find existing candle with same date;
      const existingCandleIndex = this.candlesFromSocket.findIndex(item => {
        return item.date.getTime() === incomingCandle.date.getTime();
      });

      // if found we just replace it with new one;
      // if not we just push it to the end of the array;
      if (existingCandleIndex > -1) {
        this.candlesFromSocket[existingCandleIndex] = incomingCandle;
      } else {
        this.candlesFromSocket.push(incomingCandle);
      }
    });
  };

  /** sub/unsub are async and some candles can still be emitted by server before unsub is processed
   * - check that current type is candles;
   * - check tickers of incoming candles;
   * - check tick;
   */
  filterSocketCandle = (point: ChartResource.Candles.CandleFromSocket) => {
    const {type, fromTicker, toTicker, tick, mode = 'mid'} = this.resource.args;

    return (
      type === 'candle' &&
      point.fromTicker === fromTicker &&
      point.toTicker === toTicker &&
      point.tick === tick! &&
      point.mode === mode
    );
  };

  @computed get subscriptionName() {
    return this.resource.args.product === 'hodl'
      ? 'product-rates-candles'
      : 'candles';
  }

  sub = ({
    fromTicker,
    toTicker,
    tick,
    mode,
    product,
  }: Partial<ChartResource.Args>) => {
    if (!this.isSubscribedToSocket) {
      const args: any = {
        name: this.subscriptionName,
        fromTicker: fromTicker,
        toTicker: toTicker,
        tick: tick,
        mode: mode ?? 'mid',
      };

      if (Boolean(product)) {
        args.product = product;
      }

      TRANSPORT.SOCKET.emit('sub', args);
      this.isSubscribedToSocket = true;
    }
  };

  unsub = ({
    fromTicker,
    toTicker,
    tick,
    mode,
    product,
  }: Partial<ChartResource.Args>) => {
    if (this.isSubscribedToSocket) {
      const args: any = {
        name: this.subscriptionName,
        fromTicker: fromTicker,
        toTicker: toTicker,
        tick: tick,
        mode: mode ?? 'mid',
      };

      if (Boolean(product)) {
        args.product = product;
      }

      TRANSPORT.SOCKET.emit('unsub', args);
      this.isSubscribedToSocket = false;
    }
  };
}
