import * as React from 'react';
import {Button, IButtonProps} from '@youtoken/ui.buttons';
import {BoxProps} from '@youtoken/ui.primitives';

export interface SliderButtonProps extends IButtonProps {
  label: string;
  value: number;
  onPress?: (value: number) => void;
}

export const SliderButton: React.FC<SliderButtonProps & BoxProps> = ({
  label,
  value,
  onPress,
  ...buttonProps
}) => {
  const handlePress = React.useCallback(() => {
    onPress?.(value);
  }, [value, onPress]);

  return (
    <Button flex={1} onPress={handlePress} {...buttonProps}>
      {label}
    </Button>
  );
};
