import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {DeviceModal} from './DeviceModal';
import {SecurityDevicesResource} from '@youtoken/ui.state-security';

export const DeviceModalCell = cell<{id: string}>(({id}) => {
  const {getDeviceItem, removeDevice} = SecurityDevicesResource.use({});

  const item = getDeviceItem(id);

  if (!item) {
    return null;
  }

  return <DeviceModal item={item} handleRemoveDevice={removeDevice} />;
});
