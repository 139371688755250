import {computed, comparer, observable, action} from 'mobx';
import {computedFn} from 'mobx-utils';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {i18n} from '@youtoken/ui.service-i18n';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {SavingsOverviewResource} from '@youtoken/ui.resource-savings';
import {ListItem} from './types';
import {invariant} from '@youtoken/ui.utils';

const itemProgress = {
  type: 'progress',
};

const itemHowItWorks = {
  type: 'how-it-works',
};

const itemPeriod = {
  type: 'period',
};

const itemEarned = {
  type: 'earned',
};

const itemTitle = {
  type: 'title',
};

export class SavingsSelectedAssetsFeature extends createFeature({
  getKey: () => `SavingsSelectedAssets`,
  getResources: () => {
    return {
      wallets: getResourceDescriptor(WalletsResource, {}),
      savingsOverview: getResourceDescriptor(SavingsOverviewResource, {}),
    };
  },
}) {
  @computed
  get status() {
    const {
      data: {status},
    } = this.resources.savingsOverview;

    return status;
  }

  //#region timer

  @computed
  get timeLeft() {
    return this.resources.savingsOverview.data.timeLeft;
  }

  @action
  onTimerEnd = () => {
    this.resources.savingsOverview.refetchWithDelay();
  };

  //#endregion timer

  @computed
  get itemsAssets() {
    const {walletsListWithAmountOrder} = this.resources.wallets;

    const {
      data: {tickersKeys, tickers},
    } = this.resources.savingsOverview;

    return walletsListWithAmountOrder
      .filter(({ticker}) => tickersKeys.includes(ticker))
      .map(({ticker, tickerName}) => {
        return {
          type: 'asset',
          data: {
            ticker,
            tickerName,
            ...tickers?.[ticker],
          },
        };
      });
  }

  //#region totalEarned

  @computed
  get totalEarnedQuoteTicker() {
    const {
      savingsOverview: {
        data: {quoteTicker},
      },
    } = this.resources;

    return quoteTicker;
  }

  @computed
  get totalEarnedInQuoteTickerFormatted() {
    const {
      savingsOverview: {
        data: {earnedInQuoteTickerFormatted},
      },
    } = this.resources;

    return earnedInQuoteTickerFormatted;
  }

  //#endregion totalEarned

  @computed
  get items(): ListItem[] {
    return [
      itemProgress,
      itemHowItWorks,
      itemPeriod,
      itemEarned,
      itemTitle,
      ...this.itemsAssets,
    ];
  }

  getAssetItemPropsByTicker = computedFn(
    (ticker: string) => {
      const {
        wallets: {getByTicker},
        savingsOverview: {
          data: {level, tickers, getEarnedByTicker},
        },
      } = this.resources;

      const wallet = getByTicker(ticker);

      invariant(
        wallet,
        `Wallet with ticker ${ticker} not found`,
        {},
        {wallet, ticker}
      );

      const {
        ticker: baseTicker,
        tickerFormatted: baseTickerFormatted,
        amountForSavingsFormatted: baseAmountFormatted,
        equivalentTicker: quoteTicker,
        equivalentForSavingsFormatted: quoteAmountFormatted,
      } = wallet;

      const tickerItem = tickers?.[baseTicker];

      const {earnedInBaseTickerFormatted, earnedInQuoteTickerFormatted} =
        getEarnedByTicker(ticker);

      return {
        baseTicker,
        baseTickerFormatted,
        baseAmountFormatted,
        quoteTicker,
        quoteAmountFormatted,
        level,
        aprFormatted: tickerItem?.aprFormatted ?? '',
        earnedInBaseTickerFormatted,
        earnedInQuoteTickerFormatted,
      };
    },
    {
      equals: comparer.shallow,
    }
  );

  //#region submit

  @observable
  submitting = false;

  @action
  toggleSubmitting = () => {
    this.submitting = !this.submitting;
  };

  @action
  submit = () => {
    DATA_LAYER.trackStrict('savings-sprint-claim-attempt', {});

    this.toggleSubmitting();
    return TRANSPORT.API.post('/v4/saving/claim')
      .then(() => {
        this.resources.savingsOverview.setStatusWaiting();
        this.resources.savingsOverview.refetch();
      })
      .catch(axiosError => {
        const error = axiosError?.response?.data;

        const _error = extractErrorFromResponse(error, '_error');
        const _errorTranslated = getTranslatedValidationMessage(_error);

        LOCAL_NOTIFICATIONS.error({
          text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
        });
      })
      .finally(this.toggleSubmitting);
  };

  //#endregion submit
}
