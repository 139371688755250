import React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';

interface ReferralsRoundedTextProps {
  size?: number;
  textVariant?: React.ComponentProps<typeof Text>['variant'];
  textColor?: React.ComponentProps<typeof Text>['color'];
}

export const ReferralsRoundedText: React.FC<
  ReferralsRoundedTextProps & BoxProps
> = ({
  size = 20,
  bg = '$interactive-03',
  textVariant = '$body-02-medium-accent',
  textColor = '$text-05',
  children,
  ...boxProps
}) => {
  const padding = Math.ceil(size / 4);

  return (
    <Box
      minWidth={size}
      height={size}
      borderRadius={size}
      bg={bg}
      flexShrink={1}
      justifyContent="center"
      alignItems="center"
      flexGrow={0}
      paddingHorizontal={padding}
      {...boxProps}
    >
      <Text variant={textVariant} color={textColor}>
        {children}
      </Text>
    </Box>
  );
};
