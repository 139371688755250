import {deserialize} from 'serializr';
import {computed} from 'mobx';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {
  HODLInstrumentResponse,
  PriceRangeData,
  HistoricalPriceData,
} from './HODLInstrumentResponse';

export interface HODLInstrumentArgs {
  baseTicker: string;
  quoteTicker: string;
}

export class HODLInstrumentResource extends createResource<
  HODLInstrumentArgs,
  HODLInstrumentResponse
>({
  skipRefreshOnVisible: false,
  getKey: ({baseTicker, quoteTicker}) =>
    `hodlInstrument:${baseTicker}/${quoteTicker}`,
  getData: ({baseTicker, quoteTicker}) => {
    return TRANSPORT.API.get(`/v3/hodl/grpc/getMarketStatsBySymbol`, {
      params: {
        baseTicker,
        quoteTicker,
      },
    }).then(res => {
      return deserialize(HODLInstrumentResponse, res.data.data);
    });
  },
}) {
  @computed get shouldShowMarketStatsSection() {
    return Boolean(this.data.marketStats?.marketCapAmount);
  }

  @computed get filteredPriceRange(): PriceRangeData[] | undefined {
    return this.data.priceRange?.filter(el => el.low !== 0 || el.high !== 0);
  }

  @computed get shouldShowPriceRangeSection() {
    return this.filteredPriceRange && this.filteredPriceRange.length > 0;
  }

  @computed get filteredPriceRangePeriods() {
    return this.filteredPriceRange?.map(el => {
      return el.period;
    });
  }

  @computed get filteredPerformance(): HistoricalPriceData[] {
    return this.data.performance.filter(el => el.amountFormatted !== '');
  }

  @computed get shouldShowPerformanceSection() {
    return this.filteredPerformance.length > 2;
  }
}
