import * as React from 'react';
import {Animated, Platform} from 'react-native';
import {Svg, Rect, Defs, ClipPath, Stop, SvgProps} from 'react-native-svg';
import {useTheme} from '@youtoken/ui.primitives';
import {AnimatedLinearGradient} from './AnimatedLinearGradient';

const useNativeDriver = Platform.select({web: false, default: true})!;

const uid = (): string => Math.random().toString(36).substring(6);

export interface ContentLoaderProps extends SvgProps {
  animate?: boolean;
  rtl?: boolean;
  speed?: number;
  uniqueKey?: string;
}

export const ContentLoader: React.FC<ContentLoaderProps> = ({
  animate = true,
  rtl = false,
  speed = 1200,
  uniqueKey,
  style,
  children,
  ...props
}) => {
  const {colors} = useTheme();
  const [clipId, gradientId] = React.useMemo(() => {
    const fixedId = uniqueKey || uid();
    return [`${fixedId}-diff`, `${fixedId}-animated-diff`];
  }, [uniqueKey]);

  const animatedValue = React.useRef(new Animated.Value(-1));

  const x1Animation = React.useRef(
    animatedValue.current.interpolate({
      extrapolate: 'clamp',
      inputRange: [-1, 2],
      outputRange: ['-100%', '100%'],
    })
  );

  const x2Animation = React.useRef(
    animatedValue.current.interpolate({
      extrapolate: 'clamp',
      inputRange: [-1, 2],
      outputRange: ['0%', '200%'],
    })
  );

  const _style = React.useMemo(() => {
    return [
      style,
      rtl ? {transform: [{rotateY: '180deg'}]} : {},
      {
        opacity: 0.6,
      },
    ];
  }, [style, rtl]);

  const animation = React.useMemo(
    () =>
      Animated.loop(
        Animated.timing(animatedValue.current, {
          toValue: 2,
          delay: speed,
          duration: speed,
          useNativeDriver: useNativeDriver,
        })
      ),
    [speed]
  );

  React.useEffect(() => {
    if (animate) {
      animation.start();
    } else {
      animation.stop();
      animation.reset();
    }

    return () => animation.stop();
  }, [animate, speed]);

  return (
    <Svg style={_style} {...props}>
      <Rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        fill={`url(#${clipId})`}
        clipPath={`url(#${gradientId})`}
      />

      <Defs>
        <ClipPath id={gradientId}>{children}</ClipPath>

        <AnimatedLinearGradient
          //@ts-ignore
          id={clipId}
          x1={x1Animation.current}
          x2={x2Animation.current}
          y1={0}
          y2={0}
        >
          <Stop offset={0} stopColor={colors['$ui-background']} />
          <Stop offset={0.5} stopColor={colors['$ui-01']} />
          <Stop offset={1} stopColor={colors['$ui-background']} />
        </AnimatedLinearGradient>
      </Defs>
    </Svg>
  );
};
