import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';

export const RepayInfo: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {conversionTicker, conversionAmount, feeAmount} =
    useForm<LoanRepayOwnFundsFormState>();

  return (
    <Box {...props}>
      <Text variant="$body-01" lineHeight={28} color="$text-02" mb={2}>
        <Trans
          t={t}
          components={{
            Strong: <Text variant="$heading-01" color="$text-01" />,
          }}
          values={{
            amount: conversionAmount,
            ticker: conversionTicker,
          }}
          i18nKey="surface.loans.loan_pay_own_funds_form.to_pay"
        />
      </Text>
      <Text variant="$body-02" color="$text-02">
        <Trans
          t={t}
          components={{
            Strong: <Text variant="$body-02-high-accent" />,
          }}
          values={{
            amount: feeAmount,
            ticker: conversionTicker,
          }}
          i18nKey="surface.loans.loan_pay_own_funds_form.to_pay_comission"
        />
      </Text>
    </Box>
  );
});
