import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {MenuWrapperProps} from './types';

export const MenuWrapper: React.FC<MenuWrapperProps> = observer(
  ({children, ...boxProps}) => {
    return (
      <Box flex={1} {...boxProps}>
        {children}
      </Box>
    );
  }
);
