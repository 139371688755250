import {AxiosError as OriginalAxiosError} from 'axios';
import {ERROR_TYPE} from '../../ERROR_TYPE';

export function pickErrorType<T extends any = any>(
  error: OriginalAxiosError<T>
): ERROR_TYPE {
  if (!error) {
    return ERROR_TYPE.NETWORK_ERROR;
  }

  if (error.message === '__OFFLINE__') {
    return ERROR_TYPE.NETWORK_ERROR_OFFLINE;
  }

  if (error.message === '__TIMEOUT__') {
    return ERROR_TYPE.NETWORK_ERROR_TIMEOUT;
  }

  if (error.response?.status === 503) {
    return ERROR_TYPE.NETWORK_ERROR_MAINTENANCE;
  }

  if (error.response?.status && error.response?.status >= 500) {
    return ERROR_TYPE.NETWORK_ERROR_SERVER;
  }

  if (error.response?.status === 401) {
    return ERROR_TYPE.NETWORK_ERROR_UNAUTHORIZED;
  }

  if (error.response?.status && error.response?.status >= 400) {
    return ERROR_TYPE.NETWORK_ERROR_CLIENT_ERROR;
  }

  return ERROR_TYPE.NETWORK_ERROR;
}
