import * as React from 'react';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  useResizableBox,
  ResizableBox,
  HODLCreateChartSmartCell,
} from '@youtoken/ui.legacy-charts';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {observer} from 'mobx-react';

interface ChartSectionProps extends BoxProps {
  onPressTradingViewModeButton?: () => void;
}

export const ChartSection: React.FC<ChartSectionProps> = observer(
  ({onPressTradingViewModeButton, height = 340, ...boxProps}) => {
    const {
      form: {hodlInstrument, precision, isShort},
      view: {chartData},
    } = useForm<CreateHODLFormClass>();

    const [{size}, bind] = useResizableBox();

    return (
      <Box
        width="100%"
        height={height}
        position="relative"
        zIndex={1}
        {...boxProps}
      >
        <ResizableBox width="100%" height={height} {...bind}>
          {Boolean(size.width && size.height) && (
            <HODLCreateChartSmartCell
              height={size.height!}
              width={size.width!}
              fromTicker={hodlInstrument.baseTicker}
              toTicker={hodlInstrument.quoteTicker}
              precision={precision}
              marginCall={chartData?.marginCall}
              takeProfit={chartData?.takeProfit}
              maxLoss={chartData?.maxLoss}
              maxProfit={chartData?.maxProfit}
              maxLossTicker={chartData?.maxLossTicker}
              maxProfitTicker={chartData?.maxProfitTicker}
              reversed={isShort}
              pending={chartData?.pending}
              triggerPrice={chartData?.triggerPrice}
              onPressTradingViewModeButton={onPressTradingViewModeButton}
            />
          )}
        </ResizableBox>
      </Box>
    );
  }
);
