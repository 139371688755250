import {deserialize} from 'serializr';
import {computedFn} from 'mobx-utils';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {
  HODLPublicInstrumentItem,
  HODLPublicInstrumentResponse,
} from './HODLPublicInstrumentResponse';

export {HODLPublicInstrumentItem};

export class HODLPublicInstrumentResource extends createResource<
  {},
  HODLPublicInstrumentItem[]
>({
  skipRefreshOnVisible: false,
  getKey: () => 'hodlPublicInstruments',
  getData: () => {
    return TRANSPORT.API.get('/v3/hodl/publicInstruments').then(res => {
      const {data} = deserialize(HODLPublicInstrumentResponse, res);

      return data;
    });
  },
}) {
  getById = computedFn((id: string) => {
    return this.data.find(tariff => tariff.id === id);
  });

  getTariffByTickers = computedFn(
    (baseTicker, quoteTicker): HODLPublicInstrumentItem | undefined => {
      return this.data.find(
        tariff =>
          tariff.baseTicker === baseTicker && tariff.quoteTicker === quoteTicker
      );
    }
  );
}
