import {invariant} from '@youtoken/ui.utils';
import {getDisplayUrl} from './webAppDisplayUrl';
import {AppEnv} from './AppEnv';

/** Returns backend url based on given app environment */
export const getWebAppUrl = (env: AppEnv) => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot get webAppUrl for AppEnv="${env}"`
  );

  if (env === AppEnv.localhost) {
    return `http://localhost:8088`;
  }

  return `https://${getDisplayUrl(env)}`;
};
