import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {SubmitButton} from '@youtoken/ui.form-elements';

export const TwoFactorDisableFormFooter: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box
      p={{default: 16, phone: 24}}
      gap={20}
      borderTopWidth={1}
      borderColor="$ui-01"
    >
      <Text variant="$body-02" color="$danger-01">
        {t('surface.more.2fa_enable_ga.message.recommend')}
      </Text>
      <SubmitButton>
        {t('surface.more.2fa_disable.action.disable')}
      </SubmitButton>
      <Text variant="$body-02" color="$text-02" textAlign="center">
        {t('surface.more.2fa_enable_ga.message.info')}
      </Text>
    </Box>
  );
});
