import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {CryptoDepositLedgerFormState} from '../state';

export const CryptoDepositLedgerFormFooter: React.FC = observer(() => {
  const {t} = useTranslation();

  const {submitting, submit} = useForm<CryptoDepositLedgerFormState>();

  return (
    <Box p={24} borderTopWidth={1} borderColor="$ui-01">
      <Button size="large" loading={submitting} onPress={submit}>
        {t('surface.wallets.crypto_deposit.submit')}
      </Button>
    </Box>
  );
});
