import * as React from 'react';
import {TwoFactorAuthVerificationComponent} from './TwoFactorAuthVerificationComponent';
import {TwoFactorAuthParams} from './types';
import {InvariantError} from '@youtoken/ui.errors';

export class TwoFactorAuthVerification extends React.PureComponent {
  twoFactoAuthRef = React.createRef<{
    requestTwoFactorAuth: (params: TwoFactorAuthParams) => Promise<null>;
  }>();

  requestTwoFactorAuth = (params: TwoFactorAuthParams) => {
    if (!this.twoFactoAuthRef.current?.requestTwoFactorAuth) {
      return Promise.reject(
        new InvariantError(
          `TwoFactorAuth not initialized, method: {requestTwoFactorAuth}`
        )
      );
    }

    return this.twoFactoAuthRef.current.requestTwoFactorAuth(params);
  };

  render() {
    return <TwoFactorAuthVerificationComponent ref={this.twoFactoAuthRef} />;
  }
}
