import * as React from 'react';
import {observer} from 'mobx-react';
import {Text, TextProps} from 'react-native-svg';
import {FEBlockState} from '@youtoken/ui.resources-miner';
import {useTheme} from '@youtoken/ui.primitives';
import {useLegacyOpacityAnimation, useMinerColors} from '../../hooks';

export type AmountProps = {
  amount: string;
  ticker: string;
  hexState: FEBlockState;
} & TextProps;

const OPACITIES: Record<FEBlockState, number> = {
  DISABLED: 0,
  AVAILABLE_NOT_ENOUGH_SPARKS: 0,
  AVAILABLE_ENOUGH_SPARKS: 0,
  MINING_STARTING: 0,
  MINING: 0,
  READY: 0,
  CLAIMING_STARTING: 0,
  CLAIMING: 1,
  CLAIMED_COLORED: 0,
  CLAIMED_GREY: 0,
  CLAIMED_INFO: 1,
};

const FONT_SIZE = 10;

export const Amount: React.FC<AmountProps> = observer(
  ({hexState, amount, ticker, ...rest}) => {
    const {textColor} = useMinerColors(hexState);

    const style = useLegacyOpacityAnimation(OPACITIES, hexState);

    const text = `+${amount}`;

    const {fonts} = useTheme();

    if (style.opacity === 0) {
      return null;
    }

    return (
      <>
        <Text
          opacity={style.opacity}
          x={0}
          y={0}
          fill={textColor}
          textAnchor="middle"
          fontSize={FONT_SIZE}
          fontFamily={fonts.normal.bold}
          fontWeight={700}
          {...rest}
        >
          {text}
        </Text>
        <Text
          opacity={style.opacity}
          x={0}
          y={11}
          fill={textColor}
          textAnchor="middle"
          fontSize={8}
          fontFamily={fonts.normal.regular}
          fontWeight={400}
          {...rest}
        >
          {ticker.toUpperCase()}
        </Text>
      </>
    );
  }
);
