import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {type OutcomeProps} from '../../types';

export const Outcome: React.FC<OutcomeProps> = observer(props => {
  const {
    totalCollateralAmountFormatted,
    totalCollateralTickerFormatted,
    totalBorrowedAmountFormatted,
    totalBorrowedTickerFormatted,
    rolloverAmountFormatted,
    rolloverTickerFormatted,
    repaymentDueAmountFormatted,
    repaymentDueTickerFormatted,
  } = props;
  const {t} = useTranslation();

  return (
    <Box flexWrap="wrap" justifyContent="space-between">
      <Box flexDirection="row" mt={16} mb={10}>
        <Box width="50%" height="100%">
          <Text variant="$body-01-medium-accent" mb={10}>
            {t('surface.hodls.hodl_form.details.total_collateral')}
          </Text>
          <Text
            variant="$body-01-high-accent"
            testID="HODL_DETAILS_OUTCOME_TOTAL_COLLATERAL"
          >
            {totalCollateralAmountFormatted}{' '}
            <Text variant="$body-01" color="$text-02">
              {totalCollateralTickerFormatted}
            </Text>
          </Text>
        </Box>
        <Box
          width="50%"
          height="100%"
          pl={12}
          borderLeftColor="$ui-01"
          borderLeftWidth={1}
        >
          <Text variant="$body-01-medium-accent" mb={10}>
            {t('surface.hodls.hodl_form.details.total_borrowed')}
          </Text>
          <Text
            variant="$body-01-high-accent"
            testID="HODL_DETAILS_OUTCOME_TOTAL_BORROWED"
          >
            {totalBorrowedAmountFormatted}{' '}
            <Text variant="$body-01" color="$text-02">
              {totalBorrowedTickerFormatted}
            </Text>
          </Text>
        </Box>
      </Box>
      <Box flexDirection="row">
        <Box width="50%" height="100%">
          <Text variant="$body-01-medium-accent" mb={10}>
            {t('surface.hodls.hodl_form.details.rollover')}
          </Text>
          <Text
            variant="$body-01-high-accent"
            testID="HODL_DETAILS_OUTCOME_ROLLOVER"
          >
            {rolloverAmountFormatted}{' '}
            <Text variant="$body-01" color="$text-02">
              {rolloverTickerFormatted}
            </Text>
          </Text>
        </Box>
        <Box
          width="50%"
          height="100%"
          pl={12}
          borderLeftColor="$ui-01"
          borderLeftWidth={1}
        >
          <Text variant="$body-01-medium-accent" mb={10}>
            {`${t('surface.hodls.hodl_form.details.repayment_due')} *`}
          </Text>
          <Text
            variant="$body-01-high-accent"
            testID="HODL_DETAILS_OUTCOME_REPAYMENT_DUE"
          >
            {repaymentDueAmountFormatted}{' '}
            <Text variant="$body-01" color="$text-02">
              {repaymentDueTickerFormatted}
            </Text>
          </Text>
        </Box>
      </Box>
    </Box>
  );
});
