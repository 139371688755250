import {action, computed, observable} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {messages} from '@youtoken/ui.validation-messages';
import {__GLOBAL_RECAPTCHA__} from '@youtoken/ui.two-factor-auth-and-recaptcha';
import {i18n} from '@youtoken/ui.service-i18n';
import {TRANSPORT} from '@youtoken/ui.transport';
import {getAnalyticsData} from '@youtoken/ui.env-utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {
  FeatureSignUpAdditionalFormArgs,
  FeatureSignUpAdditionalFormResources,
} from './types';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {GLOBAL, GlobalCampaign} from '@youtoken/ui.service-global';

export class Form {
  @observable
  public args: FeatureSignUpAdditionalFormArgs;

  @observable
  public resources: FeatureSignUpAdditionalFormResources;

  @observable
  public instance: MobxReactForm;

  @observable
  recaptchaToken!: string;

  @observable
  accessCode!: string;

  @observable
  idToken?: string;

  @observable
  email!: string;

  @observable
  handleClose?: () => void;

  @computed.struct
  public get submitting() {
    return this.instance.submitting;
  }

  @computed.struct
  public get errors() {
    return this.instance.errors();
  }

  @computed
  public get residence() {
    return this.instance?.$('residence').get('value');
  }

  @computed
  public get showPromoField() {
    return this.instance?.$('showPromoField').get('value');
  }

  @computed
  public get inviteeCode() {
    return this.instance?.$('inviteeCode').get('value');
  }

  @computed
  public get agree() {
    return this.instance?.$('agree').get('value');
  }

  @action
  public submit = () => {
    const {requestToken} = __GLOBAL_RECAPTCHA__;

    return Promise.all([requestToken('sign_up'), getAnalyticsData()]).then(
      ([token, analytics]) => {
        return TRANSPORT.API.post(`/auth/${this.args.provider}/signup`, {
          residence: this.residence,
          receiveUpdates: true,
          inviteeCode: this.showPromoField ? this.inviteeCode : undefined,
          agree: this.agree,
          token,
          accessCode: this.accessCode,
          idToken: this.idToken,
          email: this.email,
          language: i18n.language,
          analytics,
          onboardingType:
            GLOBAL.campaign === GlobalCampaign.NEW_MINER_FLOW
              ? 'miner'
              : undefined,
        })
          .then(response =>
            this.resources.authMe.signInConfirmSuccess(response, {
              firstSignIn: true,
            })
          )
          .then(() => {
            LOCAL_NOTIFICATIONS.info({
              text: i18n.t('forms.sign_in.success_message'),
            });
            this.instance.reset();
          })
          .then(() => this.handleClose?.())
          .catch(err => {
            handleFormSubmitError(this.instance, err, {});
          })
          .catch(() => {});
      }
    );
  };

  public constructor(
    args: FeatureSignUpAdditionalFormArgs,
    resources: FeatureSignUpAdditionalFormResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          residence: {
            value: this.residence || args.initialValues.residence || undefined,
          },
          showPromoField: {
            value: this.showPromoField || args.initialValues.showPromoField,
          },
          inviteeCode: {
            value: this.inviteeCode || args.initialValues.inviteeCode,
          },
          agree: {
            value: this.agree,
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  residence: yup.string().required(),
                  showPromoField: yup.boolean(),
                  inviteeCode: this.showPromoField
                    ? yup.string().required()
                    : yup.string(),
                  agree: yup
                    .agreeToTerms()
                    .required()
                    .oneOf([true], messages.REQUIRED),
                };
                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }
}
