import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {RewardsListResource} from '@youtoken/ui.resources-rewards';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ReferralsHowItWorksSmartProps} from '../types';
import {
  ReferralsRoundedText,
  ShareRefCodeModalTrigger,
  BottomSheetScrollView,
  Bonus,
} from '../../..';

export const ReferralsHowItWorksV1Smart: React.FC<ReferralsHowItWorksSmartProps> =
  observer(({showShareRefCode}) => {
    const {t} = useTranslation();

    const {data} = RewardsListResource.use({});

    return (
      <Box flex={1}>
        <Box p={24} alignItems="center">
          <Text variant="$heading-02">
            {t('surface.referral_program.how_it_works.heading')}
          </Text>
        </Box>
        <BottomSheetScrollView
          style={{
            paddingHorizontal: 24,
          }}
        >
          <Box flexDirection="row" mb={24} flex={1}>
            <Box mr={12}>
              <ReferralsRoundedText bg="$text-05" textColor="$text-04">
                1
              </ReferralsRoundedText>
            </Box>
            <Box flex={1}>
              <Text variant="$body-01-high-accent">
                {t('surface.referral_program.how_it_works.rule_1')}
              </Text>
            </Box>
          </Box>

          <Box flexDirection="row" mb={24}>
            <Box mr={12}>
              <ReferralsRoundedText bg="$text-05" textColor="$text-04">
                2
              </ReferralsRoundedText>
            </Box>
            <Box flex={1}>
              <Text variant="$body-01-high-accent">
                {t('surface.referral_program.how_it_works.rule_2')}
              </Text>
            </Box>
          </Box>

          {data.referralItems.map(item => (
            <Box key={item.id} mb={16}>
              <Bonus
                disabled={true}
                id={item.id}
                title={item.title}
                amount={item.referralRewardUSD}
                description={item.description}
              />
            </Box>
          ))}
          <Text variant="$body-02" color="$text-02" pb={24}>
            {t('surface.referral_program.how_it_works.disclaimer.text')}
          </Text>
        </BottomSheetScrollView>
        {showShareRefCode && (
          <Box p={24}>
            <ShareRefCodeModalTrigger source="referrals-v1-how-it-works" />
          </Box>
        )}
      </Box>
    );
  });
