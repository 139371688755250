import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';
import {InformationTableRightElement} from '@youtoken/ui.hodl-elements';
import {ExtendTpSlForm} from '../../form';

export const InfoTable: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    view: {infoTableFormattedData},
  } = useForm<ExtendTpSlForm>();

  if (!infoTableFormattedData) {
    return null;
  }

  const {
    maxStopLossFormatted,
    maxTakeProfitFormatted,
    initialPriceFormatted,
    inputAmountFormatted,
    inputTickerFormatted,
    hodlTickersFormatted,
  } = infoTableFormattedData;

  return (
    <Box {...boxProps}>
      <InformationTableRow
        left={t('surface.hodls.extend_tp.table.new_sl')}
        leftTooltipText={t(
          'surface.hodls.item.details_table.margin_call_tooltip'
        )}
        right={
          <InformationTableRightElement
            mainText={maxStopLossFormatted}
            additionalText={hodlTickersFormatted}
          />
        }
        tooltipContentWidth={250}
        tooltipOffset={45}
      />
      <InformationTableRow
        left={t('surface.hodls.extend_tp.table.new_tp')}
        leftTooltipText={t(
          'surface.hodls.item.details_table.take_profit_tooltip'
        )}
        right={
          <InformationTableRightElement
            mainText={maxTakeProfitFormatted}
            additionalText={hodlTickersFormatted}
          />
        }
        tooltipContentWidth={260}
        tooltipOffset={45}
      />
      <InformationTableRow
        left={t('surface.hodls.item.details_table.initial_price')}
        right={
          <InformationTableRightElement
            mainText={initialPriceFormatted}
            additionalText={hodlTickersFormatted}
          />
        }
      />
      <InformationTableRow
        left={t('surface.hodls.extend_tp.table.input_amount')}
        right={
          <InformationTableRightElement
            mainText={inputAmountFormatted}
            additionalText={inputTickerFormatted}
          />
        }
        isLast
      />
    </Box>
  );
});
