import {invariant} from '@youtoken/ui.utils';
import {getDisplayUrl} from './webAppDisplayUrl';
import {AppEnv} from './AppEnv';

/** Returns the url of the proxy node for web3 wallet based on given app environment */
export const getWeb3NodeUrl = (env: AppEnv) => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot get web3NodeUrl for AppEnv="${env}"`
  );

  const path = 'web3';

  if (env === AppEnv.localhost) {
    return `http://localhost:8088/${path}`;
  }

  return `https://${getDisplayUrl(env)}/${path}`;
};
