import {type BoxProps} from '@youtoken/ui.primitives';
import {type InputLikeWrapperProps, type Size} from './types';

const useInputSizeProps = (size: Size) => {
  return (
    {
      textarea: {
        height: 162,
        px: 16,
      },
      large: {
        height: 48,
        px: 16,
        // py: 12,
      },
      medium: {
        height: 40,
        px: 16,
        // py: 10,
      },
      small: {
        height: 32,
        px: 12,
        // py: 6,
      },
    } as {[key in Size]: BoxProps}
  )[size];
};

const useInputColorsStyles = ({
  variant = 'default',
  ...props
}: InputLikeWrapperProps & {
  focused?: boolean;
}): BoxProps => {
  const isDefault = variant === 'default';
  const borderWidth = isDefault ? 1 : 0;

  if (props.hasError) {
    if (props.disabled) {
      return {
        backgroundColor: isDefault ? '$ui-01' : '$transparent',
        borderColor: '$danger-03',
        borderWidth,
      };
    }

    return {
      borderColor: props.focused ? '$danger-01' : '$danger-03',
      backgroundColor: isDefault ? '$ui-background' : '$transparent',
      borderWidth,
    };
  } else {
    if (props.disabled) {
      return {
        borderColor: '$ui-02',
        backgroundColor: isDefault ? '$ui-01' : '$transparent',
        borderWidth,
      };
    }

    return {
      borderColor: props.focused ? '$interactive-01' : '$ui-02',
      backgroundColor: isDefault ? '$ui-background' : '$transparent',
      borderWidth,
    };
  }
};

export const useInputStyleProps = ({
  size = 'large',
  ...props
}: InputLikeWrapperProps): BoxProps => {
  return {
    ...useInputSizeProps(size),
    ...useInputColorsStyles(props),
  };
};
