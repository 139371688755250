import {observable} from 'mobx';
import {
  type AdjustPendingOrderFormArgs,
  type AdjustPendingOrderFormResources,
} from './types';
import {AdjustPendingOrderForm} from './Form';
import {AdjustPendingOrderFormView} from './View';

export class AdjustPendingOrderFormClass {
  @observable
  form: AdjustPendingOrderForm;

  @observable
  view: AdjustPendingOrderFormView;

  constructor(
    resources: AdjustPendingOrderFormResources,
    args: AdjustPendingOrderFormArgs
  ) {
    this.form = new AdjustPendingOrderForm(resources, args);
    this.view = new AdjustPendingOrderFormView(this.form);
  }
}
