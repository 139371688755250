import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useInstructions} from './useInstructions';
import {InfoItem} from '../InfoItem';

export const Instructions: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const instructions = useInstructions(t);

  return (
    <Box {...boxProps}>
      {instructions.map(({title, text, icon, asteriskCount}, index) => (
        <InfoItem
          title={title}
          text={text}
          icon={icon}
          asteriskCount={asteriskCount}
          mb={index < instructions.length - 1 ? 24 : 0}
          key={text}
        />
      ))}
    </Box>
  );
});
