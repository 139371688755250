import * as React from 'react';
import {observer} from 'mobx-react';
import {SavingsOverviewResource} from '@youtoken/ui.resource-savings';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SavingsProgress} from '../../components';

export const SavingsProgressSmart: React.FC<BoxProps> = observer(props => {
  const {
    data: {
      tickersKeys,
      quoteTicker,
      progressBarAmountInQuoteTicker,
      progressBarLimitInQuoteTicker,
    },
  } = SavingsOverviewResource.use({});

  return (
    <SavingsProgress
      tickers={tickersKeys}
      ticker={quoteTicker}
      amount={progressBarAmountInQuoteTicker}
      limit={progressBarLimitInQuoteTicker}
      flex={1}
      {...props}
    />
  );
});
