import React from 'react';
import {Text, TextProps, Box} from '@youtoken/ui.primitives';

export type VerificationStatusProps = {
  status: 'ACCEPT' | 'DENY' | 'REVIEW';
} & TextProps;

export const VerificationStatus: React.FC<VerificationStatusProps> = ({
  status,
  children,
  ...otherProps
}) => {
  return (
    <Box width="100%">
      <Text
        variant="$body-03-high-accent"
        mb={3}
        mt={4}
        textTransform="uppercase"
        color={
          status === 'ACCEPT'
            ? '$success-01'
            : status === 'DENY'
            ? '$danger-01'
            : '$interactive-01'
        }
        {...otherProps}
      >
        {children}
      </Text>
    </Box>
  );
};
