import {
  formatByTicker,
  formatPercentTillPrecision,
} from '@youtoken/ui.formatting-utils';
import {getSettlementFeePercentFormatted} from './getSettlementFeePercentFormatted';
import {type Loans} from '@youtoken/ui.resource-hodl';
import {type ILoanSequenceItem} from './types';
import {type BigSource} from 'big.js';

export const getHodlLoansSequence = (
  borrowedTicker: string,
  collateralTicker: string,
  ltv: BigSource,
  loans?: Loans[]
): ILoanSequenceItem[] | [] => {
  if (!loans) {
    return [];
  }

  return loans.map(loan => ({
    collateralAmountFormatted: formatByTicker(
      loan.collateral,
      collateralTicker
    ),
    collateralTickerFormatted: collateralTicker.toUpperCase(),
    feePercentFormatted: getSettlementFeePercentFormatted(loan.settlementFee),
    borrowedAmountFormatted: formatByTicker(loan.borrowed, borrowedTicker),
    borrowedTickerFormatted: borrowedTicker.toUpperCase(),
    ltvValueFormatted: formatPercentTillPrecision(ltv, 2),
  }));
};
