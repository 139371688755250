import * as React from 'react';
import {observer} from 'mobx-react';
import {OnboardingWidgetResource} from '@youtoken/ui.resource-onboarding-widget';
import {CoindropPromo} from './CoindropPromo';

export const PromoContent: React.FC = observer(() => {
  const {promo} = OnboardingWidgetResource.use({});

  if (!promo) {
    return null;
  }

  if (promo?.type === 'COINDROP') {
    return <CoindropPromo params={promo.params} />;
  }

  return null;
});
