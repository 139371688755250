import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SearchIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 9a4 4 0 11-8 0 4 4 0 018 0zm-.677 4.383a5.5 5.5 0 111.06-1.06l3.147 3.147a.75.75 0 11-1.06 1.06l-3.147-3.147z"
          fill={color}
        />
      </Svg>
    );
  }
);
