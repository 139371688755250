import * as React from 'react';
import {Helmet} from 'react-helmet';
import {Box} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Button} from '@youtoken/ui.buttons';
import {Text} from '@youtoken/ui.primitives';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {AuthPromoBackground} from '../Elements';
import {AppShell, OuterPageShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {Logo} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';
import {useOnMount} from '@web-app/src/hooks';
import {SMS, GA, Email} from './elements';
import CONFIG from '@web-app/src/config';

import {RouterResource} from '@web-app/src/components/routes/RouterResource';

export const SignInConfirmPage: React.FC = () => {
  const {push, queryParams} = RouterResource.use({});

  const [operation, setOperation] = React.useState<any>(null);

  const {getOperation} = AuthMeResource.use({});

  useOnMount(() => {
    getOperation({operationId: queryParams.operationId})
      .then(({data}) => {
        setOperation(data);
      })
      .catch(() => {
        push('/');
      });
  });

  const {t} = useTranslation();

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
        renderRight={() => {
          return (
            <Box flexDirection="row" alignItems="center">
              <Text mr={10} ml={10}>
                {t('surface.auth.dont_have_account')}
              </Text>
              <Button
                type="secondary"
                onPress={() => {
                  push('/sign-up');
                }}
              >
                {t('surface.auth.actions.sign_up')}
              </Button>
            </Box>
          );
        }}
      />
    );
  }, [t]);

  const renderFooter = React.useCallback(() => null, []);

  return (
    <AppShell renderHeader={renderHeader} renderFooter={renderFooter}>
      <OuterPageShell>
        <Helmet title={t('surface.sign_in_confirm.tab_title')} />
        <Box
          width="100%"
          flexDirection={{default: 'column', tablet: 'row'}}
          justifyContent={{default: 'flex-start', tablet: 'space-between'}}
        >
          <Box
            position="relative"
            flexDirection="row"
            justifyContent="flex-end"
            width={{default: '100%', tablet: '50%'}}
          >
            <AuthPromoBackground />
          </Box>
          <Box
            width={{default: '100%', tablet: '50%'}}
            alignItems="stretch"
            pb={20}
            pl={{default: 0, tablet: 80}}
          >
            <Box px={{default: 20, tablet: 0}}>
              {!operation && (
                <Box mt={150}>
                  <ActivityIndicator />
                </Box>
              )}
              {operation &&
                operation.twoFactorAuthType ===
                  CONFIG.TWO_FACTOR_AUTH_TYPES[0] && (
                  <SMS operation={operation} />
                )}
              {operation &&
                operation.twoFactorAuthType ===
                  CONFIG.TWO_FACTOR_AUTH_TYPES[1] && (
                  <GA operation={operation} />
                )}
              {operation &&
                operation.twoFactorAuthType ===
                  CONFIG.TWO_FACTOR_AUTH_TYPES[2] && (
                  <Email operation={operation} />
                )}
            </Box>
          </Box>
        </Box>
      </OuterPageShell>
    </AppShell>
  );
};
