import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  useIsMobile,
  type TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {LoansListItemFeatureArgs, LoansListItemFeature} from '../../../state';
import {
  HODLPreviewChartSmartCell,
  ResizableBoxWithOverlay,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';

export interface BriefChartProps
  extends Omit<TouchableBoxProps, 'onPress' | 'hitSlop'> {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
}

export const BriefChart: React.FC<BriefChartProps> = observer(
  ({mode, id, ...rest}) => {
    const isMobile = useIsMobile();

    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return (
      <Box width="100%" {...rest}>
        <ResizableBoxWithOverlay
          width="100%"
          height={isMobile ? 71 : 100}
          {...bind}
        >
          {!!(width && height && loan.chartDataAvailable) && (
            <HODLPreviewChartSmartCell
              fromTicker={loan.collateralTicker!}
              toTicker={loan.borrowedTicker!}
              data={loan.chartData}
              width={width}
              height={height}
              startDate={loan.startDate}
              endDate={loan.isClosed ? loan.endDate : undefined}
              takeProfit={Number(loan.takeProfit)}
              marginCall={Number(loan.marginCall)}
              initialPrice={Number(loan.initialPrice)}
              closedPrice={Number(loan.closedPrice)}
              isClosedLike={loan.isClosed}
              isOpeningLike={loan.isOpening}
              direction={
                loan.isOpening || loan.isClosed ? 'neutral' : undefined
              } // NOTE: if no direction set, get it from market moving
            />
          )}
        </ResizableBoxWithOverlay>
      </Box>
    );
  }
);
