import * as React from 'react';
import {type RecaptchaMethods} from './utils/recaptchaMethods';
import {RecaptchaComponent} from './RecaptchaComponent';
import {InvariantError} from '@youtoken/ui.errors';

type PickStartsWith<T extends object, S extends string> = {
  [K in keyof T as K extends `${S}${infer R}` ? K : never]: T[K];
};

function methodMissingReject(method: string): Promise<never> {
  return Promise.reject(
    new InvariantError('Recaptcha not initialized', {method})
  );
}

function createRecaptchaMethod(
  method: keyof PickStartsWith<RecaptchaMethods, `request${string}`>
) {
  return (action: string) => {
    if (!__GLOBAL_RECAPTCHA_REF__.current?.[method]) {
      return methodMissingReject(method);
    }

    return __GLOBAL_RECAPTCHA_REF__.current[method](action);
  };
}

function createRecaptchaMethodWithCallback(
  method: keyof PickStartsWith<RecaptchaMethods, `with${string}`>
) {
  return (action: string, callback: (token: string) => Promise<any>) => {
    if (!__GLOBAL_RECAPTCHA_REF__.current?.[method]) {
      return methodMissingReject(method);
    }

    return __GLOBAL_RECAPTCHA_REF__.current[method](action, callback);
  };
}

export const __GLOBAL_RECAPTCHA__: RecaptchaMethods = {
  withToken: createRecaptchaMethodWithCallback('withToken'),
  withTokenV2: createRecaptchaMethodWithCallback('withTokenV2'),
  withTokenV3: createRecaptchaMethodWithCallback('withTokenV3'),
  requestToken: createRecaptchaMethod('requestToken'),
  requestTokenV2: createRecaptchaMethod('requestTokenV2'),
  requestTokenV3: createRecaptchaMethod('requestTokenV3'),
};

export const __GLOBAL_RECAPTCHA_REF__ = React.createRef<RecaptchaComponent>();

export const RecaptchaContext =
  React.createContext<RecaptchaMethods>(__GLOBAL_RECAPTCHA__);

export const RecaptchaContextProvider: React.FC = ({children}) => {
  return (
    <RecaptchaContext.Provider value={__GLOBAL_RECAPTCHA__}>
      <RecaptchaComponent ref={__GLOBAL_RECAPTCHA_REF__} />
      {children}
    </RecaptchaContext.Provider>
  );
};

export const useRecaptcha = () => {
  return React.useContext(RecaptchaContext);
};
