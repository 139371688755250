import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SavingsOverviewStatus} from '@youtoken/ui.resource-savings';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SavingsSelectedAssetsFeature} from '../../Feature';
import {SavingsEarningResult} from '../../../../components';

export const SavingsSelectedAssetsEarningResult: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {status} = SavingsSelectedAssetsFeature.use({});

    if (status === SavingsOverviewStatus.READY) {
      return (
        <SavingsEarningResult
          testID="SAVINGS_EARNING_RESULT_ENDED"
          icon="interest"
          title={t('surface.savings.earning_period.ended.title')}
          description={t('surface.savings.earning_period.ended.description')}
          {...boxProps}
        />
      );
    }

    if (status === SavingsOverviewStatus.CLAIMED) {
      return (
        <SavingsEarningResult
          testID="SAVINGS_EARNING_RESULT_CLAIMED"
          icon="check"
          title={t('surface.savings.earning_period.claimed.title')}
          {...boxProps}
        />
      );
    }

    return (
      <SavingsEarningResult
        testID="SAVINGS_EARNING_RESULT_STARTED"
        icon="interest"
        title={t('surface.savings.earning_period.started.title')}
        description={t('surface.savings.earning_period.started.description')}
        {...boxProps}
      />
    );
  }
);
