import * as React from 'react';
import {observer} from 'mobx-react';
import {LoyaltyUnauthorizedResource} from '@youtoken/ui.resource-loyalty';
import {LoyaltyProgressBar} from '@youtoken/ui.loyalty-miner-components';
import {AccountLevelProgressWidgetLayout} from './Layout';

export const AccountLevelProgressWidgetUnauthorized: React.FC = observer(() => {
  const {progressBarPoints, progressBarValue, progressBarValueText} =
    LoyaltyUnauthorizedResource.use({});

  return (
    <AccountLevelProgressWidgetLayout>
      <LoyaltyProgressBar
        points={progressBarPoints}
        value={progressBarValue}
        valueText={progressBarValueText}
      />
    </AccountLevelProgressWidgetLayout>
  );
});
