import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, TouchableBox, type BoxProps, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CardItem} from '@youtoken/ui.resource-cards';
import {
  IconPaymentMethod,
  type IconPaymentMethodName,
} from '@youtoken/ui.icons';

interface CardSelectorProps {
  item: CardItem;
  isSelected: boolean;
  onSelectCard: (id: string) => void;
}

export const CardItemComponent: React.FC<CardSelectorProps & BoxProps> =
  observer(({item, isSelected, onSelectCard}) => {
    const {t} = useTranslation();
    const {id, last4, expMonth, expYear, brand} = item;
    const iconName: IconPaymentMethodName =
      brand.toLowerCase() === 'visa' ? 'visa' : 'mastercard';

    return (
      <TouchableBox
        onPress={() => onSelectCard(id)}
        key={id}
        borderWidth={1}
        borderRadius={6}
        borderColor={isSelected ? '$interactive-01' : '$ui-01'}
        mt={8}
        p={16}
        flexDirection="row"
        justifyContent="space-between"
        testID={`${last4}_CARD_CARD_ITEM` + (isSelected ? '_SELECTED' : '')}
      >
        <Box flexDirection="row" alignItems="center">
          <Box
            width={20}
            height={20}
            borderRadius={10}
            borderWidth={isSelected ? 5 : 2}
            borderColor={isSelected ? '$interactive-01' : '$ui-04'}
            mr={16}
          />
          <IconPaymentMethod
            name={iconName}
            // NOTE: visa icon requires a non-standard color
            // @ts-ignore
            color={iconName === 'visa' ? '#173AC3' : undefined}
            size={20}
          />

          <Box
            width={3}
            height={3}
            borderRadius={3}
            backgroundColor="$text-01"
            ml={16}
            mr={3}
          />
          <Text>{last4}</Text>
        </Box>
        <Text variant="$body-02" color="$text-02">
          {t('surface.wallets.checout_bank_card.expires') +
            ' ' +
            expMonth.toString() +
            '/' +
            expYear.toString()}
        </Text>
      </TouchableBox>
    );
  });
