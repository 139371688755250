import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {i18nService} from './i18nService';

export {type TKey} from './i18nService';

import {
  Trans as Trans_,
  useTranslation as useTranslation_,
} from 'react-i18next';

// // NOTE Это хак, что бы vscode подставлял правильные импорты в автокомплите
export const useTranslation = useTranslation_;
export const Trans = Trans_;

export const i18n = __SERVICE_REGISTRY__.registerService(
  'i18n',
  i18nService,
  require('../package.json').version
);

export {DetectLangComponent} from './utils/DetectLangComponent';
export {languages, getOrDetectLocalLanguage} from './utils';
