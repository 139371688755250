import {action, computed} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {SavingsWidgetResponse} from './SavingsWidgetResponse';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';

export class SavingsWidgetResource extends createResource({
  getKey: () => `SavingsWidget`,
  getData: () =>
    TRANSPORT.API.get('/v4/saving/widget').then(response => {
      return deserialize(SavingsWidgetResponse, response.data);
    }),
  skipRefreshOnVisible: false,
}) {
  @computed get earnedInQuoteTickerFormatted() {
    return formatByTicker(this.data.earnedAmount, this.data.quoteTicker);
  }

  @computed get tickerSymbol() {
    return getCoinSymbol(this.data.quoteTicker);
  }

  @action
  refetchWithDelay = () => {
    setTimeout(this.refetch, 1000);
  };
}
