import * as React from 'react';
import {type TFuncKey} from 'react-i18next';
import {
  Text,
  type TextProps,
  Box,
  type BoxProps,
} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DocumentItem} from '@youtoken/ui.resource-documents';
import {choosePunctuationMark} from './utils';

interface AgreementsListProps {
  items?: DocumentItem[];
  textProps?: TextProps;
  linkProps?: TextProps;
  extraText?: string;
  beforeListText?: string;
}

export const AgreementsList: React.FC<AgreementsListProps & BoxProps> =
  React.memo(
    ({
      items = [],
      textProps,
      linkProps,
      extraText,
      beforeListText,
      ...boxProps
    }) => {
      const {t} = useTranslation();

      const handleOpenLink = React.useCallback((url: string) => {
        SHARED_ROUTER_SERVICE.navigate('Agreement', {url});
      }, []);

      if (!items.length) {
        return null;
      }

      return (
        <Box flexGrow={1} flexShrink={1} {...boxProps}>
          <Text
            variant="$body-02"
            color="$text-02"
            style={{
              flexWrap: 'wrap',
            }}
            {...textProps}
          >
            {beforeListText ||
              t('agreements.documents.start_text', {count: items.length})}{' '}
            {items
              .filter(Boolean)
              .map(({name, link, i18nName, i18nLink, template}, index, arr) => {
                const itemUrl = i18nLink
                  ? t(i18nLink as TFuncKey, {defaultValue: link})
                  : link;
                const itemName = i18nName
                  ? t(i18nName as TFuncKey, {defaultValue: name})
                  : name;

                return (
                  <React.Fragment key={`${index}-${i18nLink}`}>
                    <Link
                      key={i18nLink}
                      {...(template
                        ? {onPress: () => handleOpenLink(itemUrl)}
                        : {url: itemUrl})}
                      {...linkProps}
                      testID={`AGREEMENTS_LIST_ITEM_${index}`}
                      useInAppBrowser
                    >
                      {itemName}
                    </Link>
                    {choosePunctuationMark(name, index, arr.length, t)}
                  </React.Fragment>
                );
              })}
            {extraText && ` ${extraText}`}
          </Text>
        </Box>
      );
    }
  );
