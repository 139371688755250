import * as React from 'react';
import {observer} from 'mobx-react';
import {TFAFormField, useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../state';

export const TwoFA: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {useYHBalance, createTwoFactorAuthOperation, codeError, codeOnChange},
  } = useForm<
    BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
  >();

  if (!useYHBalance) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <TFAFormField
        getOperationData={createTwoFactorAuthOperation}
        onChangeCode={codeOnChange}
        error={codeError}
      />
    </Box>
  );
});
