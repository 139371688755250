import * as React from 'react';
import {BaseIconName, Icon} from '@youtoken/ui.icons';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {getAsterisksSting} from '../utils';

export type FeatureItemProps = {
  icon: BaseIconName;
  text: string;
  asteriskCount?: number;
};

export const FeatureItem: React.FC<FeatureItemProps & BoxProps> = ({
  icon,
  text,
  asteriskCount,
  ...boxProps
}) => {
  const asterisks = getAsterisksSting(asteriskCount);

  return (
    <Box flexDirection="row" flex={1} {...boxProps}>
      <Icon name={icon} color="$interactive-01" size={24} />
      <Box ml={16} flex={1}>
        <Text variant="$body-01-medium-accent" color="$text-05">
          {text}
          {asterisks && (
            <Text variant="$body-02" color="$text-05">
              {' ' + asterisks}
            </Text>
          )}
        </Text>
      </Box>
    </Box>
  );
};
