import {alias, serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {computed, observable} from 'mobx';

export class RateObj {
  @observable
  @serializable(number())
  diff: number = 0;

  @observable
  @serializable(number())
  diff24h: number = 0;

  @observable
  @serializable(number())
  rate: number = 1;

  @observable
  @serializable(alias('bid', number()))
  _bid?: number;

  @computed get bid() {
    return this._bid || this.rate;
  }
  set bid(value) {
    this._bid = value;
  }

  @observable
  @serializable(alias('ask', number()))
  _ask?: number;

  @computed get ask() {
    return this._ask || this.rate;
  }
  set ask(value) {
    this._ask = value;
  }
}

export interface RatesResponse {
  [key: string]: RateObj | undefined;
}
