import * as React from 'react';
import {Box, Heading} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {i18n} from '@youtoken/ui.service-i18n';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {Content} from './Content';
import {LandingButtonProvider} from './FooterContext';
import {BankCardsFeature} from '../../state/BankCardsFeature';
import {Footer} from './Footer';

export const getBankCardsOverviewTitle = () =>
  i18n.t('surface.bank_cards.title.your_cards');

export const BankCardsOverview: React.FC = cell(
  () => {
    const {pageTitle} = BankCardsFeature.use({});

    React.useEffect(() => {
      DATA_LAYER.trackStrict('card-page-visited', {
        provider: 'InterGiro',
      });
    }, []);

    return (
      <LandingButtonProvider>
        <Box
          mb={30}
          ml={{
            default: 16,
            phone: 24,
            desktop: 0,
          }}
        >
          <Heading variant="$heading-01-responsive" color="$text-01">
            {pageTitle}
          </Heading>
        </Box>

        <Box
          pt={32}
          borderColor="$ui-01"
          borderRadius={{desktop: 10}}
          borderWidth={{desktop: 1}}
          overflow="hidden"
        >
          <Content />
          <Footer />
        </Box>
      </LandingButtonProvider>
    );
  },
  {
    CellWrap: ({children}) => (
      <WebPageWrapper
        windowTitle={getBankCardsOverviewTitle()}
        hasBackButton
        onBackPress={() => {
          SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
        }}
      >
        {children}
      </WebPageWrapper>
    ),
  }
);
