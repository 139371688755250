import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {type WithdrawalForm} from '../../../../state/WithdrawalForm';
import {FeeRow} from './components/FeeRow';

interface WithdrawalFeesProps {
  showLastSeparator?: boolean;
}

export const WithdrawalFees: React.FC<WithdrawalFeesProps> = observer(
  ({showLastSeparator}) => {
    const {
      form: {feesFormattedList},
    } = useForm<WithdrawalForm>();

    return (
      <>
        {feesFormattedList.map((fee, index) => (
          <FeeRow
            key={`${fee.provider}_${fee.amount}`}
            provider={fee.provider}
            percent={fee.percent}
            amount={fee.amount}
            showSeparator={
              index < feesFormattedList.length - 1 || showLastSeparator
            }
          />
        ))}
      </>
    );
  }
);
