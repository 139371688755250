import * as React from 'react';
import {Helmet} from 'react-helmet';
import {UmaCreateSurface} from '@youtoken/ui.surfaces-uma';
import {WebPageWrapper} from '@youtoken/ui.screen-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {goBack} from '../../../../services/shared-router';
import {InnerPageShell} from '../../../layout/Shell';

export const UmaCreatePage = cell(() => {
  const {t} = useTranslation();

  return (
    <InnerPageShell>
      <Helmet title={t('surface.wallets.uma.page.nav_title')} />

      <WebPageWrapper
        hasBackButton
        onBackPress={goBack}
        windowTitle={t('surface.wallets.uma.create')}
        title={t('surface.wallets.uma.create')}
      >
        <Box borderColor="$ui-01" borderWidth={1} borderRadius={10}>
          <UmaCreateSurface onSuccess={goBack} />
        </Box>
      </WebPageWrapper>
    </InnerPageShell>
  );
});
