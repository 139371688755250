import {serializable} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import Big from 'big.js';

export class LoanCloseNow {
  @serializable(bigNumber())
  closeFee!: Big;

  @serializable(bigNumber())
  price!: Big;

  @serializable(bigNumber())
  sellCollateralAmount!: Big;

  @serializable(bigNumber())
  recompenseAmount!: Big;
}
