import * as React from 'react';
import {CODE_TOKENS} from '../../system/themes/fonts/TOKENS';
import {Text, TextProps} from '../Text';

export const Code: React.FC<
  Omit<TextProps, 'variant'> & {
    variant?: keyof typeof CODE_TOKENS;
  }
> = ({children, variant = '$code-01', ...props}) => {
  return (
    <Text variant={variant} accessibilityRole="text" {...props}>
      {children}
    </Text>
  );
};
