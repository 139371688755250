import {observable} from 'mobx';
import {
  createSimpleSchema,
  object,
  optional,
  primitive,
  serializable,
} from 'serializr';

export class CompanyLegalInfo {
  @observable
  @serializable(primitive())
  name!: string;

  @observable
  @serializable(primitive())
  link!: string;

  @observable
  @serializable(optional(primitive()))
  i18nName?: string;

  @observable
  @serializable(optional(primitive()))
  i18nLink?: string;
}

export const CompanyLegalInfoSchema = createSimpleSchema<{
  [key: string]: CompanyLegalInfo;
}>({
  '*': object(CompanyLegalInfo),
});

export class Company {
  @observable
  @serializable(primitive())
  address!: string;

  @observable
  @serializable(primitive())
  email!: string;

  @observable
  @serializable(primitive())
  name!: string;

  @observable
  @serializable(primitive())
  pawnbrokerAuthNumber!: string | null;

  @observable
  @serializable(primitive())
  phone!: string | null;

  @observable
  @serializable(primitive())
  site!: string;

  @observable
  @serializable(object(CompanyLegalInfoSchema))
  legalInfo!: {
    [key: string]: CompanyLegalInfo;
  };
}

export type Companies = {
  naumard: Company;
  youhodler: Company;
  youhodler_ita: Company;
};

export const CompanyResponse = createSimpleSchema<Companies>({
  '*': object(Company),
});
