import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CoindropResource} from '@youtoken/ui.coindrop-resource';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {Link, StepItem} from '@youtoken/ui.elements';
import {useGetCoindropsData} from './hooks';
import {CoinIconsBig, CoindropBadge} from './components';

export const CoindropSurface: React.FC = cell(() => {
  const {t} = useTranslation();

  const {
    authMe: {setHasAlreadySeenCoindropModal},
    coindrop: {coins, coinsListStringify, dropsAvailableByTariff},
  } = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
    verification: getResourceDescriptor(VerificationResource, {}),
    coindrop: getResourceDescriptor(CoindropResource, {}),
  });

  const {coindropsData, currentStep} = useGetCoindropsData();

  React.useEffect(() => {
    setHasAlreadySeenCoindropModal();
  }, []);

  if (!dropsAvailableByTariff) {
    return null;
  }

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      py={24}
    >
      <Box width="100%" alignItems="center">
        <Box alignItems="center" width="100%">
          <CoindropBadge mb={32} />
          <CoinIconsBig coins={coins} mb={32} />

          <Box mb={32} px={24}>
            <Text textAlign="center" variant="$heading-01">
              {coindropsData.title}
            </Text>
            <Text textAlign="center" color="$text-02" pt={16}>
              {t(`surface.coindrop.subtitle_with_coins_list`, {
                tickersList: coinsListStringify,
              })}
            </Text>
          </Box>

          <Box width="100%" px={24}>
            {coindropsData.steps.map(
              ({title, description, isCompleted, testID}, index) => {
                const isLast = index === coindropsData.steps.length - 1;

                return (
                  <StepItem
                    width="100%"
                    backgroundColor="$ui-background"
                    iconColor={isCompleted ? '$success-03' : '$interactive-02'}
                    textColor="$text-01"
                    descriptionColor="$text-02"
                    icon={isLast ? 'gift' : 'check'}
                    title={title}
                    description={description}
                    onPress={currentStep?.stepAction}
                    testID={testID}
                    linkDown={!isLast}
                    shouldShowSingleLink
                  />
                );
              }
            )}
          </Box>
        </Box>
      </Box>

      <Box width="100%" alignItems="center">
        <Link
          p={24}
          color="$text-02"
          variant="$body-02"
          url="http://help.youhodler.com/articles/9614953"
          testID="COINDROPS_TERMS_AND_CONDITIONS_LINK"
        >
          {t('agreement.youhodler.terms_and_conditions')}
        </Link>

        {currentStep && (
          <Box px={24} width="100%">
            <Button
              type="primary"
              size="large"
              onPress={currentStep.stepAction}
              testID="COINDROPS_ACTION_BUTTON"
            >
              {currentStep.actionLabel}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
});
