import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {BankCardsAccountDetailsResponse} from './BankCardsAccountDetailsResponse';

export class BankCardsAccountDetailsResource extends createResource({
  getKey: () => 'bankCardsAccountDetails',
  getData: () => {
    return TRANSPORT.API.get('/v1/intergiro/bank-account/details/').then(
      ({data}) => {
        return deserialize(BankCardsAccountDetailsResponse, data);
      }
    );
  },
  skipRefreshOnVisible: false,
}) {}
