import {computed} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {type CryptoWithdrawalProps} from '../types';
import {walletVersionsToItems} from '../utils';

export class FeatureCryptoWithdrawalBanner extends createFeature({
  getKey: (args: CryptoWithdrawalProps) =>
    `CryptoWithdrawalBanner(${args.ticker})`,
  getResources: (_args: CryptoWithdrawalProps) => {
    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
    };
  },
}) {
  @computed.struct
  get wallet() {
    return this.resources.walletsResource.getByTicker(this.args.ticker)!;
  }

  @computed.struct
  get walletVersions() {
    return walletVersionsToItems(this.wallet.versions);
  }

  @computed.struct
  get walletVersionsValues() {
    return this.walletVersions.map(v => v.value);
  }

  @computed.struct
  get walletVersionsTitles() {
    return this.walletVersions.map(v => v.label);
  }

  @computed
  get versionsListHasErc20OrBep20orPolygon() {
    return this.walletVersionsValues.some(
      v => v === 'erc20' || v === 'bep20' || v === 'polygon'
    );
  }

  @computed
  get tickerFormatted() {
    return this.wallet.ticker.toUpperCase();
  }

  @computed
  get hasCaseSensitiveAddress() {
    return ['xrp', 'dot', 'trx', 'sol', 'xtz'].includes(this.wallet.ticker);
  }
}
