import {AxiosPromise} from 'axios';
import big from 'big.js';
import {action, computed, observable, runInAction} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import * as yupPackage from '@youtoken/ui.yup';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {
  getTranslatedValidationMessage,
  handleGeneralErrorTranslated,
  messages,
} from '@youtoken/ui.validation-messages';
import {TRANSPORT} from '@youtoken/ui.transport';
import {type IComboboxItem} from '@youtoken/ui.combobox';
import {
  countriesList,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';
import {
  convertBankWireData,
  type IBankWireTemplate,
} from '@youtoken/ui.resource-bank-wire-templates';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {type WithdrawalFormResources} from './types';
import {invariant} from '@youtoken/ui.utils';

const paymentReferenceRegExp = new RegExp(/^([A-Z0-9\s.\-/&]){6,140}$/i);

export type TAccountType = 'iban' | 'accountNumber';
export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  private ticker: string;

  @observable
  private resources: WithdrawalFormResources;

  @observable
  public countryItems: IComboboxItem[];

  @observable
  public accountType: TAccountType = 'iban';

  @observable
  public ibanStep: 'small' | 'full' = 'small';

  @observable
  public provider: string;

  @observable
  public providerByIban: string | null = null;

  public constructor(ticker: string, resources: WithdrawalFormResources) {
    this.ticker = ticker;
    this.resources = resources;

    this.provider = this.ticker === 'eur' ? '' : 'swift';

    this.countryItems = getCountriesListLocalized(countriesList).map(
      country => ({
        key: country.code,
        value: country.code,
        label: country.name,
      })
    );

    const {
      data: {fullName, address},
    } = this.resources.authme;

    this.instance = new MobxReactForm(
      {
        fields: {
          amount: {value: ''},
          templateId: {value: 'new'},
          beneficiaryName: {value: fullName || ''},
          iban: {
            value: '',
            handlers: {
              onChange:
                (field: any) =>
                (value = '') => {
                  field.set('value', value.toUpperCase());

                  runInAction(() => {
                    this.instance.$('templateId').set('value', 'new');
                    this.providerByIban = null;

                    if (this.accountType === 'iban') {
                      this.provider = this.ticker === 'eur' ? '' : 'swift';
                      this.ibanStep = 'small';
                    }
                  });
                },
            },
          },
          bankName: {value: ''},
          bankCountry: {value: undefined},
          bankCity: {value: ''},
          bankStreetAddress: {value: ''},
          bankZipCode: {value: ''},
          swiftCode: {value: ''},
          beneficiaryAddressCountry: {
            value: address?.country
              ? this.countryItems.find(c => c.value === address.country)?.value
              : undefined,
          },
          beneficiaryAddressCity: {value: address?.city ?? ''},
          beneficiaryAddressStreetAddress: {value: address?.address ?? ''},
          beneficiaryAddressZipCode: {value: address?.zipCode ?? ''},
          paymentReference: {value: ''},
          agree: {value: false},
          twoFactorAuthOperationId: {value: ''},
          twoFactorAuthCode: {value: ''},
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                const schema = {
                  amount: yup.big().gt(0).lte(this.wallet.amount),
                  templateId: yup.string(),
                  beneficiaryName: yup
                    .string()
                    .required()
                    .matches(/^[A-Z,\-,\s]+$/i, messages.IS_NOT_LATIN_SYMBOLS),
                  iban:
                    this.accountType === 'iban'
                      ? yup
                          .string()
                          .required()
                          .matches(
                            /^[A-Z]{2}\d{2}([A-Z]|\d){1,30}$/i,
                            messages.IBAN_WRONG_FORMAT
                          )
                      : yup
                          .string()
                          .required()
                          .matches(
                            /^[A-Za-z0-9]+$/,
                            messages.ACCOUNT_NUMBER_WRONG_FORMAT
                          ),

                  bankName: yup.string(),
                  paymentReference: yup
                    .string()
                    .test(
                      'paymentReferenceRegExp',
                      messages.WITHDRAWAL_PAYMENT_REFERENCE_REGEXP,
                      value => {
                        // NOTE: This custom validation should be applied only for EUR and only if user has already entered something in the input
                        // see https://youhodler.atlassian.net/browse/WL-2435 for details
                        if (this.ticker !== 'eur' || !value) {
                          return true;
                        }

                        return paymentReferenceRegExp.test(value);
                      }
                    ),
                  agree: yup.agreeToTerms(),
                  twoFactorAuthOperationId: yup.string(),
                  twoFactorAuthCode: yup.string(),
                  bankCountry: yup.string(),
                  bankCity: yup.string(),
                  bankStreetAddress: yup.string(),
                  bankZipCode: yup.string(),
                  swiftCode: yup.string(),
                  beneficiaryAddressCountry: yup.string(),
                  beneficiaryAddressCity: yup.string(),
                  beneficiaryAddressStreetAddress: yup.string(),
                  beneficiaryAddressZipCode: yup.string(),
                };

                if (
                  this.accountType === 'accountNumber' ||
                  this.ibanStep === 'full'
                ) {
                  schema.bankName = yup.string().required();

                  if (!this.resources.ibanAccounts.isActive(this.ticker)) {
                    schema.agree = yup.agreeToTerms().required();
                  }

                  if (this.isTFARequired) {
                    schema.twoFactorAuthCode = yup
                      .string()
                      .required(messages.TFA_REQUIRED);
                  }

                  schema.beneficiaryAddressCountry = yup.string().required();
                  schema.beneficiaryAddressCity = yup.string().required();
                  schema.beneficiaryAddressStreetAddress = yup
                    .string()
                    .required();
                  schema.beneficiaryAddressZipCode = yup.string().required();

                  if (this.provider === 'swift') {
                    schema.bankCountry = yup.string().required();
                    schema.bankCity = yup.string().required();
                    schema.bankStreetAddress = yup.string().required();
                    schema.bankZipCode = yup.string().required();
                    schema.swiftCode = yup.string().required();
                  }
                }

                return yup.object().shape(schema);
              }),
          }),
        },
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            const values = form.values();

            if (this.accountType === 'iban' && this.ibanStep === 'small') {
              let provider = '';

              return TRANSPORT.API.get(
                `/v1/payment-details/iban/${values.iban}`
              )
                .then(({data}) => {
                  const ibanFields = {
                    provider: 'transfer_type',
                    bankName: 'bank',
                    swiftCode: 'bic',
                    bankCountry: 'country_iso',
                    bankCity: 'city',
                    bankStreetAddress: 'address',
                    bankZipCode: 'zip',
                  };

                  Object.keys(ibanFields).forEach(name => {
                    if (name === 'provider') {
                      if (this.ticker === 'eur') {
                        provider = data[ibanFields[name]] || '';
                      } else {
                        provider = 'swift';
                      }
                    } else if (name === 'bankCountry') {
                      this.instance
                        .$(name)
                        .set(
                          'value',
                          this.countryItems.find(
                            c => c.value === data[ibanFields[name]]
                          )?.value
                        );
                    } else {
                      this.instance
                        .$(name)
                        .set(
                          'value',
                          data[ibanFields[name as keyof typeof ibanFields]] ||
                            ''
                        );
                    }
                  });
                })
                .catch(() => {
                  provider = 'swift';
                })
                .then(() => {
                  this.ibanStep = 'full';
                  this.provider = provider;
                  this.providerByIban = provider;
                });
            }

            const payload = {
              ...values,
              ticker: this.ticker,
              provider: this.provider,
              accountType: this.accountType,
            };

            return TRANSPORT.API.post('/v1/withdrawal/bank-wire', {
              operationId: values.twoFactorAuthOperationId,
              code: values.twoFactorAuthCode,
              ...convertBankWireData(payload),
            })
              .then(() => {
                if (values.templateId !== 'new') {
                  return this.resources.bankWireTemplates
                    .edit(values.templateId, payload as IBankWireTemplate)
                    .catch()
                    .finally(() => {
                      SHARED_ROUTER_SERVICE.navigate('__CloseModal');
                      SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
                        ticker: this.ticker,
                      });
                    });
                }

                return this.resources.bankWireTemplates
                  .add(payload as IBankWireTemplate)
                  .catch()
                  .finally(() => {
                    SHARED_ROUTER_SERVICE.navigate('__CloseModal');
                    SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
                      ticker: this.ticker,
                    });
                  });
              })
              .catch(error => {
                if (!error.response) {
                  handleFormSubmitError(form, error);

                  return;
                }

                const {i18n, ...fieldsError} = error.response?.data ?? {};

                if (Object.keys(fieldsError).length) {
                  error.response.data = convertBankWireData(
                    fieldsError,
                    'toFrontend'
                  );
                  error.response.data.i18n = convertBankWireData(
                    i18n,
                    'toFrontend'
                  );
                }

                handleFormSubmitError(form, error);
              });
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
  }

  @computed
  public get isTFARequired() {
    return ['sms', 'ga', 'email'].includes(
      this.resources.authme.twoFactorAuthType
    );
  }

  @computed
  public get wallet() {
    const wallet = this.resources.wallets.getByTicker(this.ticker);

    invariant(
      wallet,
      `Wallet for ticker ${this.ticker} not found in wallets list`,
      {
        ticker: this.ticker,
      },
      {
        ticker: this.ticker,
        wallets: this.resources.wallets.data,
      }
    );

    return wallet;
  }

  @computed
  public get amountValue() {
    return this.instance.$('amount').get('value') || 0;
  }

  @computed
  public get templateIdValue() {
    return this.instance.$('templateId').get('value');
  }

  @computed
  public get errorTFA() {
    return getTranslatedValidationMessage(
      this.instance.$('twoFactorAuthCode').get('error')
    );
  }

  @computed
  public get amountFormatted() {
    return this.amountValue ? big(this.amountValue).toFixed(2) : 0;
  }

  @computed
  public get receiveAmountFormatted() {
    const feeByProviderAmount = this.provider
      ? this.resources.fees.calculateFee(
          'withdraw',
          this.provider,
          this.ticker,
          this.ticker,
          this.amountValue
        )
      : big(0);

    const receiveAmount = big(this.amountValue).minus(feeByProviderAmount);

    return receiveAmount.gt(0) ? receiveAmount.toFixed(2) : 0;
  }

  @computed
  public get feesFormattedList(): {
    amount: string;
    minAmount: string;
    provider: string;
    percent?: string;
    estimationTime?: string;
  }[] {
    const fees = [];

    const feeByProvider = this.resources.fees.getFee(
      'withdraw',
      this.provider,
      this.ticker,
      this.ticker
    );

    if (feeByProvider) {
      const feeByProviderAmount = this.resources.fees.calculateFee(
        'withdraw',
        this.provider,
        this.ticker,
        this.ticker,
        this.amountValue
      );

      const feeByProviderPercent =
        feeByProviderAmount.gt(feeByProvider.min) &&
        feeByProviderAmount.lt(feeByProvider.max)
          ? feeByProvider.percent
          : null;

      fees.push({
        provider: this.provider,
        amount: feeByProviderAmount.toFixed(2),
        minAmount: feeByProvider.minAmount.toFixed(2),
        percent: feeByProviderPercent?.toFixed(2),
        estimationTime:
          this.resources.fees.getFeeEstimationPeriodLocalize(feeByProvider),
      });
    } else {
      const feeSepa =
        this.ticker === 'eur'
          ? this.resources.fees.getFee(
              'withdraw',
              'sepa',
              this.ticker,
              this.ticker
            )
          : null;

      const feeSwift = this.resources.fees.getFee(
        'withdraw',
        'swift',
        this.ticker,
        this.ticker
      );

      if (feeSepa) {
        const feeSepaAmount = this.resources.fees.calculateFee(
          'withdraw',
          'sepa',
          this.ticker,
          this.ticker,
          this.amountValue
        );

        const feeSepaPercent =
          feeSepaAmount.gt(feeSepa.min) && feeSepaAmount.lt(feeSepa.max)
            ? feeSepa.percent
            : null;

        fees.push({
          provider: 'sepa',
          amount: feeSepaAmount.toFixed(2),
          minAmount: feeSepa.minAmount.toFixed(2),
          percent: feeSepaPercent?.toFixed(2),
        });
      }

      if (feeSwift) {
        const feeSwiftAmount = this.resources.fees.calculateFee(
          'withdraw',
          'swift',
          this.ticker,
          this.ticker,
          this.amountValue
        );

        const feeSwiftPercent =
          feeSwiftAmount.gt(feeSwift.min) && feeSwiftAmount.lt(feeSwift.max)
            ? feeSwift.percent
            : null;

        fees.push({
          provider: 'swift',
          amount: feeSwiftAmount.toFixed(2),
          minAmount: feeSwift.minAmount.toFixed(2),
          percent: feeSwiftPercent?.toFixed(2),
        });
      }
    }

    return fees;
  }

  @action
  public changeTemplateId = (value: string) => {
    this.instance.$('templateId').set('value', value);

    [
      'beneficiaryName',
      'iban',
      'bankName',
      'swiftCode',
      'bankCountry',
      'bankCity',
      'bankStreetAddress',
      'bankZipCode',
      'beneficiaryAddressCountry',
      'beneficiaryAddressCity',
      'beneficiaryAddressStreetAddress',
      'beneficiaryAddressZipCode',
      'paymentReference',
    ].forEach(fieldName => {
      this.instance.$(fieldName).set('value', '');
    });

    this.provider = '';
    this.providerByIban = null;

    const {
      data: {fullName, address},
    } = this.resources.authme;

    if (value === 'new') {
      this.accountType = 'iban';
      this.ibanStep = 'small';

      this.instance.$('beneficiaryName').set('value', fullName || '');
      this.instance
        .$('beneficiaryAddressCountry')
        .set(
          'value',
          address?.country
            ? this.countryItems.find(c => c.value === address.country)?.value
            : undefined
        );
      this.instance
        .$('beneficiaryAddressCity')
        .set('value', address?.city ?? '');
      this.instance
        .$('beneficiaryAddressStreetAddress')
        .set('value', address?.address ?? '');
      this.instance
        .$('beneficiaryAddressZipCode')
        .set('value', address?.zipCode ?? '');
    } else {
      const template = this.resources.bankWireTemplates.data.find(
        t => t.templateId === value
      );

      invariant(
        template,
        `Template with id ${value} not found in bank wire templates list`,
        {
          value,
        },
        {
          value,
          templates: this.resources.bankWireTemplates.data,
        }
      );

      const {ticker, amount, accountType, provider, ...templateOtherFields} =
        template;

      this.accountType = accountType as typeof this.accountType;
      this.provider = provider;

      if (accountType === 'iban') {
        this.ibanStep = 'full';
        this.providerByIban = provider;
      }

      Object.keys(templateOtherFields).forEach((fieldName: string) => {
        if (['bankCountry', 'beneficiaryAddressCountry'].includes(fieldName)) {
          this.instance
            .$(fieldName)
            .set(
              'value',
              this.countryItems.find(
                c =>
                  c.value ===
                  templateOtherFields[
                    fieldName as keyof typeof templateOtherFields
                  ]
              )?.value
            );
        } else {
          this.instance
            .$(fieldName)
            .set(
              'value',
              templateOtherFields[fieldName as keyof typeof templateOtherFields]
            );
        }
      });
    }
  };

  @action
  public changeAccountType = (accountType: typeof this.accountType) => {
    this.accountType = accountType;

    if (accountType === 'accountNumber') {
      this.provider = 'swift';
    } else if (this.providerByIban) {
      this.provider = this.providerByIban;
    } else {
      this.ibanStep = 'small';
      this.provider = this.ticker === 'eur' ? '' : 'swift';
    }

    this.instance.showErrors(false);
  };

  @action
  public changeIbanStep = (ibanStep: typeof this.ibanStep) => {
    this.ibanStep = ibanStep;
  };

  @action
  public getOperationData = <
    T extends {operationId: string; phoneMask?: string}
  >(
    withToken: (
      name: string,
      withTokenCallback: (token: string) => AxiosPromise<T>
    ) => AxiosPromise<T>
  ) => {
    return withToken('fiat_withdrawal_tfa', token =>
      TRANSPORT.API.post('/v1/withdrawal/authorize', {
        token,
      })
    )
      .then(({data}) => {
        runInAction(() => {
          this.instance
            .$('twoFactorAuthOperationId')
            .set('value', data.operationId);
        });

        return data;
      })
      .catch(response => {
        handleGeneralErrorTranslated(response?.data);
      });
  };
}
