import * as React from 'react';
import {observer} from 'mobx-react';
import {BriefStatus, type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box} from '@youtoken/ui.primitives';
import {
  CurrentResult,
  JustStatus,
  StatusWithPeriod,
  TriggerPrice,
  WillExpireIn,
} from './elements';

export interface HODLItemBriefStatusProps {
  hodl: HODLItem;
  isDetailedPage?: boolean;
  testID?: string;
}

export const HODLItemBriefStatus: React.FC<HODLItemBriefStatusProps> = observer(
  ({hodl, isDetailedPage = false, testID}) => {
    const {briefStatusVariant} = hodl;

    return (
      <Box testID={testID}>
        {briefStatusVariant === BriefStatus.RESULTS && (
          <CurrentResult hodl={hodl} />
        )}
        {briefStatusVariant === BriefStatus.PENDING && !isDetailedPage && (
          <TriggerPrice hodl={hodl} />
        )}
        {briefStatusVariant === BriefStatus.PENDING && isDetailedPage && (
          <WillExpireIn hodl={hodl} />
        )}
        {(briefStatusVariant === BriefStatus.CANCEL_BY_USER ||
          briefStatusVariant === BriefStatus.CANCEL_OR_DECLINE ||
          briefStatusVariant === BriefStatus.EXPIRED) && (
          <StatusWithPeriod
            hodl={hodl}
            isDetailedPage={isDetailedPage}
            briefStatusVariant={briefStatusVariant}
          />
        )}
        {(briefStatusVariant === BriefStatus.OPENING ||
          briefStatusVariant === BriefStatus.CLOSING) && (
          <JustStatus briefStatusVariant={briefStatusVariant} />
        )}
      </Box>
    );
  }
);
