import * as React from 'react';
import {Helmet} from 'react-helmet';
import {MinerSurfaceCell} from '@youtoken/ui.surface-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export const Miner: React.FC = () => {
  const {t} = useTranslation();
  const {userEmail} = AuthMeResource.use({});

  return (
    <InnerPageShell>
      <Helmet title={t('surface.miner.tab_title')} />
      <MinerSurfaceCell />
    </InnerPageShell>
  );
};
