import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {invariant} from '@youtoken/ui.utils';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {FeatureLoanCreateLandingForm} from '../../LoanRegularCreateLandingForm/state';

export const ConversionTicker: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {form} = useForm<
    FeatureLoanCreateForm | FeatureLoanCreateLandingForm
  >();

  invariant(
    form.conversionTickers,
    'cannot get wallets for TickerAndAmountInput',
    {},
    {wallets: form.conversionTickers}
  );

  return (
    <Box zIndex={20} width="100%" {...props}>
      <Label mb={8}>{t('surface.loans.create_form.conversion_amount')}</Label>
      <TickerAndAmountInput
        wallets={form.conversionTickers}
        precision={getCoinDecimalPrecisionForLoans(form.conversionTicker)}
        ticker={form.conversionTicker}
        amount={form.conversionAmount}
        onChangeTicker={form.conversionTickerOnChange}
        onChangeAmount={form.conversionAmountOnChange}
      />
      <FieldErrorMessage visible={form.borrowedHasError}>
        {form.borrowedError}
      </FieldErrorMessage>
    </Box>
  );
});
