import * as React from 'react';
import {
  SvgProps,
  Svg,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const TabletIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 11a1 1 0 00-1 1v4a1 1 0 001 1h.5v-6h-.5zM53 11h.5a1 1 0 011 1v4a1 1 0 01-1 1H53v-6zm0 8h.5a1 1 0 011 1v4a1 1 0 01-1 1H53v-6z"
          fill="#425266"
        />
        <Path
          d="M10 9a6 6 0 016-6h32a6 6 0 016 6v46a6 6 0 01-6 6H16a6 6 0 01-6-6V9z"
          fill="#425266"
        />
        <Path
          d="M52 55V9a4 4 0 00-4-4H16a4 4 0 00-4 4v46a4 4 0 004 4h32a4 4 0 004-4z"
          fill={`url(#${_id}_tablet_svg__tablet_svg__paint0_linear)`}
        />
        <Defs>
          <LinearGradient
            id={`${_id}_tablet_svg__tablet_svg__paint0_linear`}
            x1={32}
            y1={3}
            x2={32}
            y2={61}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6B7A94" />
            <Stop offset={0} stopColor="#6B7A94" />
            <Stop offset={1} stopColor="#353E4F" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
