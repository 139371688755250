import * as React from 'react';
import {
  WalletChartState,
  WalletChartStateProps,
} from './ChartState/WalletChartState';

interface IBaseChartStateProps<P extends WalletChartStateProps> {
  new (props: P): WalletChartState;
}

export const useLocalChartState = <
  StoreClass extends IBaseChartStateProps<Props>,
  Props extends WalletChartStateProps
>(
  StoreClass: StoreClass,
  props: Props
): InstanceType<StoreClass> => {
  const {data, cursorX, ...restProps} = props;

  const [state] = React.useState(() => new StoreClass(props));

  React.useEffect(() => {
    state.setData(data);
  }, [data]);

  React.useEffect(() => {
    state.setCursor(cursorX);
  }, [cursorX]);

  React.useEffect(() => {
    state.setFromProps(restProps);
  }, [restProps]);

  return state as InstanceType<StoreClass>;
};
