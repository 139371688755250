import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  formatPercent,
  formatNumberToOrder,
} from '@youtoken/ui.formatting-utils';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.legacy-slider';
import {Error, LabelWithValue, useForm, FieldWrap} from '../../elements';
import {FeatureTurboCreateForm} from '../stores';
import {Images} from '@youtoken/ui.images';

export const LeverageField: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {
    form: {instance, termSettingMinFtp, termSettingMaxFtp},
  } = useForm<FeatureTurboCreateForm>();

  const labelRenderer = React.useCallback(
    ({value: valueFromSlider}: {value: number}) => (
      <LabelWithValue
        text={t('surface.loans.item.loans')}
        value={valueFromSlider}
      />
    ),
    [t]
  );

  return (
    <>
      <FieldWrap {...props} backgroundColor="$interactive-02">
        <Slider
          value={instance.$('leverage').value}
          minimumValue={termSettingMinFtp}
          maximumValue={termSettingMaxFtp}
          renderLabel={labelRenderer}
          onChange={instance.$('leverage').onChange}
          mode="middle"
        />
        <Error errors={instance.errors()} name="leverage" />
        <CalculatedLoans />
      </FieldWrap>
    </>
  );
});

// TODO: refactor
export const CalculatedLoans: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {calculated} = useForm<FeatureTurboCreateForm>();

  const handleMap = React.useCallback(
    (loan: any, index: number, list: {}[]) => {
      return (
        <Box position="relative" key={index} zIndex={list.length - index}>
          <Box
            justifyContent="center"
            width={120}
            height={100}
            px={16}
            mr={8}
            bg="$ui-background"
            borderRadius={8}
          >
            <Text variant="$body-02" color="$interactive-01" weight="bold">
              {formatNumberToOrder(index + 1)}{' '}
              {t('surface.loans.item.loans.loan')}
            </Text>
            <Text variant="$body-02" color="$text-02">
              <Text weight="bold">{loan.collateralAmount}</Text>{' '}
              {loan.collateralTicker.toUpperCase()}
            </Text>
            <Text variant="$body-02" color="$text-02">
              <Text weight="bold">{loan.borrowedAmount}</Text>{' '}
              {loan.borrowedTicker.toUpperCase()}
            </Text>
            <Text variant="$body-02" color="$text-02">
              <Text weight="bold">{formatPercent(loan.interest)}%</Text>{' '}
              {t('surface.loans.item.loans.fee')}
            </Text>
          </Box>
          {list.length > 1 && index < list.length - 1 && (
            <Box position="absolute" right={0} top="50%" mr={-9} mt={-11}>
              <Images.arrSnake width={24} height={22} />
            </Box>
          )}
        </Box>
      );
    },
    []
  );

  return (
    <Box opacity={calculated.ready ? 1 : 0.6} {...props}>
      <ScrollView
        horizontal
        snapToInterval={120}
        decelerationRate="fast"
        contentContainerStyle={{
          paddingVertical: 20,
        }}
      >
        {calculated.data && (
          <Box flexDirection="row">{calculated.data.loans.map(handleMap)}</Box>
        )}

        {!calculated.data && (
          <>
            <Box
              justifyContent="center"
              width={120}
              height={100}
              px={16}
              mr={8}
              bg="$ui-background"
              borderRadius={8}
            />
            <Box
              justifyContent="center"
              width={120}
              height={100}
              px={16}
              mr={8}
              bg="$ui-background"
              borderRadius={8}
            />
            <Box
              justifyContent="center"
              width={120}
              height={100}
              px={16}
              mr={8}
              bg="$ui-background"
              borderRadius={8}
            />
          </>
        )}
      </ScrollView>
    </Box>
  );
});
