import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {BoxProps} from '@youtoken/ui.primitives';
import {Box} from '@youtoken/ui.primitives';
import {LoanItemDataTableRowLtv} from './RowLtv';
import {LoanItemDataTableRowPdl} from './RowPdl';
import {LoanItemDataTableRowBorrowed} from './RowBorrowed';
import {LoanItemDataTableRowApr} from './RowApr';
import {LoanItemDataTableRowTerm} from './RowTerm';
import {LoanItemDataTableRowRepayUntil} from './RowRepayUntil';
import {LoanItemDataTableRowId} from './RowId';
import {LoanItemDataTableRowAprDaily} from './RowAprDaily';
import {LoanItemDataTableRowAprTotal} from './RowAprTotal';
import {LoanItemDataTableRowOpened} from './RowOpened';

export interface LoanItemDataTableProps extends BoxProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemDataTable: React.FC<LoanItemDataTableProps> = observer(
  ({item, ...rest}) => {
    if (item.version === 2) {
      return <LoanItemDataTableV2 item={item} {...rest} />;
    }

    return <LoanItemDataTableV1 item={item} {...rest} />;
  }
);

export interface LoanItemDataTableV1Props extends LoanItemDataTableProps {
  item: LoanRegularV1;
}

export const LoanItemDataTableV1: React.FC<LoanItemDataTableV1Props> = observer(
  ({item, ...rest}) => {
    return (
      <Box testID="LOAN_ITEM_TABLE_V1" {...rest}>
        <LoanItemDataTableRowLtv item={item} />
        <LoanItemDataTableRowPdl item={item} />
        <LoanItemDataTableRowBorrowed item={item} />
        <LoanItemDataTableRowApr item={item} />
        <LoanItemDataTableRowTerm item={item} />
        <LoanItemDataTableRowRepayUntil item={item} />
        <LoanItemDataTableRowId item={item} />
      </Box>
    );
  }
);

export interface LoanItemDataTableV2Props extends LoanItemDataTableProps {
  item: LoanRegularV2;
}

export const LoanItemDataTableV2: React.FC<LoanItemDataTableV2Props> = observer(
  ({item, ...rest}) => {
    return (
      <Box testID="LOAN_ITEM_TABLE_V2" {...rest}>
        <LoanItemDataTableRowLtv item={item} />
        <LoanItemDataTableRowPdl item={item} />
        <LoanItemDataTableRowAprDaily item={item} />
        <LoanItemDataTableRowAprTotal item={item} />
        <LoanItemDataTableRowBorrowed item={item} />
        <LoanItemDataTableRowOpened item={item} />
        <LoanItemDataTableRowRepayUntil item={item} />
        <LoanItemDataTableRowId item={item} />
      </Box>
    );
  }
);
