import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps, Text, useTheme} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Images} from '@youtoken/ui.images';
import {LoanSequence, Outcome} from './sections';
import {type IAbstractHowHODLWorks} from '@youtoken/ui.hodls-utils';

export const HowHODLWorksSurface: React.FC<IAbstractHowHODLWorks & BoxProps> =
  observer(props => {
    const {
      isInputAmountPositive,
      inputAmountFormatted,
      inputTickerFormatted,
      additionalInputAmountFormatted,
      additionalInputTickerFormatted,
      hasConversion = false,
      loansSequence,
      settlementPeriodFormatted,
      settlementFeePercentFormatted,
      totalCollateralAmountFormatted,
      totalCollateralTickerFormatted,
      totalBorrowedAmountFormatted,
      totalBorrowedTickerFormatted,
      rolloverAmountFormatted,
      rolloverTickerFormatted,
      repaymentDueAmountFormatted,
      repaymentDueTickerFormatted,
      ...boxProps
    } = props;
    const {t} = useTranslation();

    const {colors} = useTheme();

    return (
      <Box
        px={{
          default: 16,
          desktop: 24,
        }}
        pt={{
          default: 16,
          tablet: 24,
        }}
        pb={{
          default: 32,
          phone: 40,
        }}
        {...boxProps}
      >
        <Text variant="$body-02" color="$text-02" mb={16}>
          {t('surface.hodls.hodl_form.details.description')}
        </Text>

        {isInputAmountPositive && (
          <>
            <Text
              variant="$body-01-medium-accent"
              testID="HODL_DETAILS_INITIAL_AMOUNT"
            >
              {t('surface.hodls.hodl_form.details.initial_amount')}{' '}
              {inputAmountFormatted} {inputTickerFormatted}
            </Text>
            {additionalInputAmountFormatted && (
              <Text
                variant="$body-01-medium-accent"
                testID="HODL_DETAILS_INITIAL_AMOUNT"
              >
                {t('surface.hodls.hodl_form.details.used_bonuses')}
                {': '}
                {additionalInputAmountFormatted}{' '}
                {additionalInputTickerFormatted}
              </Text>
            )}

            {hasConversion && (
              <Box mb={7} pt={30} position="relative">
                <Box position="absolute" left={7} top={7}>
                  <Images.arrConvert
                    width={7}
                    height={15}
                    color={colors['$text-02']}
                  />
                </Box>
                <Text
                  variant="$body-01-medium-accent"
                  testID="HODL_DETAILS_AMOUNT_CONVERTED_TO"
                >
                  {t('surface.hodls.hodl_form.details.converted_to')}{' '}
                  {loansSequence[0]?.collateralAmountFormatted ?? 0}{' '}
                  {loansSequence[0]?.collateralTickerFormatted ?? ''}
                </Text>
              </Box>
            )}

            <LoanSequence loanSequence={loansSequence} />

            <Text variant="$body-02" color="$text-02" mt={10} mb={6}>
              {t('surface.hodls.hodl_form.details.borrowed_amount_description')}
            </Text>

            <Outcome
              totalCollateralAmountFormatted={totalCollateralAmountFormatted}
              totalCollateralTickerFormatted={totalCollateralTickerFormatted}
              totalBorrowedAmountFormatted={totalBorrowedAmountFormatted}
              totalBorrowedTickerFormatted={totalBorrowedTickerFormatted}
              rolloverAmountFormatted={rolloverAmountFormatted}
              rolloverTickerFormatted={rolloverTickerFormatted}
              repaymentDueAmountFormatted={repaymentDueAmountFormatted}
              repaymentDueTickerFormatted={repaymentDueTickerFormatted}
            />

            <Text variant="$body-02" color="$text-02" mt={19}>
              <Text color="$text-01">{'* '}</Text>
              {t('surface.hodls.hodl_form.details.repayment_example', {
                period: settlementPeriodFormatted,
                percent: settlementFeePercentFormatted,
              })}
            </Text>
          </>
        )}
      </Box>
    );
  });
