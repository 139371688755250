import {custom} from 'serializr';
import {type Duration} from '@youtoken/ui.date-fns';
import {invariant} from '@youtoken/ui.utils';
import {invert} from 'lodash';

const unitMap: {
  [key: string]: keyof Duration;
} = {
  Y: 'years',
  M: 'months',
  w: 'weeks',
  d: 'days',
  h: 'hours',
  m: 'minutes',
  s: 'seconds',
};

/** parse timePeriod (e.g.`"23h 15m"`) to date-fns dutation object to use with date-fns sub() or add()
 *
 * @example
 * ```typescript
 * parseDuration('23h 15m'); // => {hours: 23, minutes: 15}
 * ```
 */
export const parseDuration = (durationString: string): Duration => {
  invariant(
    durationString,
    `Cannot parse duration string: durationString is falsy`,
    {durationString}
  );

  const regex = /(\d+)([\w])/g;
  const matches = Array.from(durationString.matchAll(regex));
  const duration: Partial<Duration> = {};

  matches.forEach(match => {
    const [substring, amount, shorthandUnit] = match;
    const value = Number(amount);

    invariant(
      shorthandUnit && unitMap[shorthandUnit],
      'Cannot parse duration string',
      {
        substring,
        durationString,
      }
    );

    const unit = unitMap[shorthandUnit];

    if (unit) {
      duration[unit] = (duration[unit] || 0) + value;
    }
  });

  return duration as Duration;
};

const serializeDuration = (value: Duration) => {
  invariant(value, `Cannot serialize duration: duration is falsy`, {
    duration: value,
  });

  const unitMapInverted = invert(unitMap);

  return Object.entries(value)
    .map(([unit, amount]) => {
      const shorthandUnit = unitMapInverted[unit];

      invariant(
        shorthandUnit,
        `Cannot serialize duration: invalid duration object`,
        {
          field: `${unit}: ${amount}`,
          duration: JSON.stringify(value),
        }
      );

      return shorthandUnit ? `${amount}${shorthandUnit}` : '';
    })
    .join(' ');
};

export const duration = () => custom(serializeDuration, parseDuration);

export const durationNullable = () =>
  custom(
    (value: Duration | null, ...rest) => {
      if (value === null) {
        return value;
      }

      return duration().serializer(value, ...rest);
    },
    (jsonValue, context, _oldValue, callback) => {
      if (jsonValue === null) {
        return callback(null, jsonValue);
      }

      return duration().deserializer(jsonValue, callback, context);
    }
  );
