import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {AboutSection} from '../../sections';

export const AboutInstrumentCell = cell<{
  baseTicker: string;
  quoteTicker: string;
}>(
  ({baseTicker, quoteTicker}) => {
    const {translate} = LocaliseResource.use({
      namespace: LocaliseResourceNamespace.HODL,
    });

    const instrumentDescription = translate(
      `hodls.instrument.about.${baseTicker}/${quoteTicker}`
    );

    if (!instrumentDescription) {
      return null;
    }

    return (
      <>
        <Separator />
        <AboutSection content={instrumentDescription} />
      </>
    );
  },
  {
    CellWrap: ({children}) => {
      return <Box>{children}</Box>;
    },
  }
);
