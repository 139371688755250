import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {OnboardingWidgetResource} from '@youtoken/ui.resource-onboarding-widget';
import {PromoContent, RegularContent, Action, Steps} from './components';

export const OnboardingWidget: React.FC<BoxProps> = observer(boxProps => {
  const {isCompleted, promo} = OnboardingWidgetResource.use({});

  if (isCompleted) {
    return null;
  }

  return (
    <Box
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={12}
      p={16}
      {...boxProps}
    >
      {promo ? <PromoContent /> : <RegularContent />}
      <Steps my={16} />
      <Action />
    </Box>
  );
});
