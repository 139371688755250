import {computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {invariant, warning} from '@youtoken/ui.utils';
import {createResource} from '@youtoken/ui.data-storage';
import {filterAndSortTerms, getMarketsByTerms} from './utils';
import {LoanTariffsResponseItem} from './LoanTariffsResponse';

export class LoanTariffsResource extends createResource({
  getKey: () => 'LoanTariffsResource',
  getData: () => {
    return TRANSPORT.API.get(`/v1/tariff`).then(res => {
      return filterAndSortTerms(
        deserialize(LoanTariffsResponseItem, res.data as any[])
      ) as LoanTariffsResponseItem[];
    });
  },
}) {
  @computed.struct
  public get loanTerms() {
    const terms = this.data;

    return terms.reduce((acc, term) => {
      const termSettingsFiltered = term.settings.filter(setting => {
        return setting.mode === 'regular';
      });

      if (termSettingsFiltered.length) {
        acc.push({
          ...term,
          settings: termSettingsFiltered,
        });
      }

      return acc;
    }, [] as typeof terms);
  }

  @computed.struct
  public get turboTerms() {
    const terms = this.data;

    return terms.reduce((acc, term) => {
      const termSettingsFiltered = term.settings.filter(
        ({mode, turboMin, turboMax}) => {
          if (mode === 'turbo') {
            const validTurboMin = Boolean(turboMin && turboMin > 0);
            const validTurboMax = Boolean(turboMax && turboMax > 0);
            const validLeverageSettings = validTurboMin && validTurboMax;

            warning(
              validLeverageSettings,
              `TariffsV1Resource: Term has invalid leverage`,
              {
                id: term.id,
              }
            );

            return validLeverageSettings;
          }

          return false;
        }
      );

      if (termSettingsFiltered.length) {
        acc.push({
          ...term,
          settings: termSettingsFiltered,
        });
      }

      return acc;
    }, [] as typeof terms);
  }

  @computed.struct
  public get loanMarkets() {
    return getMarketsByTerms(this.loanTerms);
  }

  @computed.struct
  public get turboMarkets() {
    return getMarketsByTerms(this.turboTerms);
  }

  /**
   * get loanMarkets with whiteList in default order (loanMarkets and loanMarketsTickers)
   * const loanMarkets = tariffsResource.getLoanMarketsWithWhiteList(walletsResource.loanEnabledTickers) - get markets only with loanEnabled
   */
  getLoanMarketsWithWhiteList = computedFn((whiteList: string[]) => {
    return Object.keys(this.loanMarkets)
      .filter(ticker => {
        return whiteList.includes(ticker);
      })
      .reduce((result, ticker) => {
        invariant(
          this.loanMarkets[ticker],
          'cannot find loan market by ticker'
        );

        const tickers = this.loanMarkets[ticker]!.filter(ticker => {
          return whiteList.includes(ticker);
        });

        warning(
          tickers.length,
          `TariffsV1Resource: loan market by ticker with white list has empty tickers`,
          {
            ticker,
          },
          {whiteList}
        );

        if (tickers.length) {
          result[ticker] = tickers;
        }

        return result;
      }, {} as {[key: string]: string[]});
  });

  /**
   * get turboMarkets with whiteList in default order (loanMarkets and loanMarketsTickers)
   * const turboMarkets = tariffsResource.getTurboMarketsWithWhiteList(walletsResource.loanEnabledTickers) - get markets only with loanEnabled
   */
  getTurboMarketsWithWhiteList = computedFn((whiteList: string[]) => {
    return Object.keys(this.turboMarkets)
      .filter(ticker => {
        return whiteList.includes(ticker);
      })
      .reduce((result, ticker) => {
        invariant(
          this.loanMarkets[ticker],
          'cannot find loan market by ticker'
        );

        const tickers = this.turboMarkets[ticker]!.filter(ticker => {
          return whiteList.includes(ticker);
        });

        warning(
          tickers.length,
          `TariffsV1Resource: turbo market by ticker with white list has empty tickers`,
          {
            ticker,
          },
          {whiteList}
        );

        if (tickers.length) {
          result[ticker] = tickers;
        }

        return result;
      }, {} as {[key: string]: string[]});
  });
}
