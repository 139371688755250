import {BaseChartState} from '../BaseChartState';
import {computed, observable} from 'mobx';

export class Grid {
  @observable chart!: BaseChartState;

  constructor(chart: BaseChartState) {
    this.chart = chart;
  }

  @computed get layout() {
    const scale = this.chart.layout.scaleY;

    const ticks = scale.ticks(this.chart.ticksNumber).map(tick => {
      const text = this.chart.formatNumber(tick);
      return {
        label: text,
        width: this.chart.layout.getLabelWidth(text),
        y: this.chart.layout.scaleY(tick),
      };
    });

    const maxWidth = ticks.reduce((acc, tick) => Math.max(acc, tick.width), 0);

    return {
      maxWidth,
      ticks: ticks,
    };
  }
}
