import * as yup from 'yup';
import {messages} from '@youtoken/ui.validation-messages';

yup.setLocale({
  mixed: {
    required: messages.REQUIRED,
  },
  string: {
    max: messages.MAX_LENGTH,
    email: messages.EMAIL_FORMAT,
  },
  object: {},
});

export * from 'yup';
export {create as big} from './BigSchema';
export {create as agreeToTerms} from './AgreeToTermsSchema';
