import * as React from 'react';
import {useTranslation, Trans as _Trans} from '@youtoken/ui.service-i18n';

type Props = Omit<
  React.ComponentProps<typeof _Trans>,
  't' | 'style' | 'parent'
> &
  Partial<Pick<React.ComponentProps<typeof _Trans>, 't' | 'style' | 'parent'>>;

export function Trans(props: Props) {
  const {t} = useTranslation<'translations' | 'countries'>();

  // @ts-ignore reasone - who knows, type of t is not compatable ??
  return <_Trans t={t} style={{flexWrap: 'wrap'}} {...props} />;
}
