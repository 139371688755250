import * as React from 'react';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';

type SectionHeaderProps = {
  title: string;
};

export const SectionHeader: React.FC<SectionHeaderProps & BoxProps> =
  React.memo(({title, ...boxProps}) => {
    return (
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        pt={24}
        px={24}
        pb={16}
        {...boxProps}
      >
        <Heading variant="$heading-02">{title}</Heading>
      </Box>
    );
  });
