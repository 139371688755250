import React from 'react';
import {observer} from 'mobx-react';
import {LoansListItemFeature, LoansListItemFeatureArgs} from '../../../state';
import {WarningIndicator} from '@youtoken/ui.elements';

export interface DetailedAlertProps {
  mode: LoansListItemFeatureArgs['mode'];
  id: LoansListItemFeatureArgs['id'];
}

export const DetailedAlert: React.FC<DetailedAlertProps> = observer(
  ({mode, id}) => {
    const loan = LoansListItemFeature.use({
      mode,
      id,
    });

    if (!loan.id) {
      console.error(`Loan id=${id} not found`);
      return null;
    }

    return <>{loan.isAlert && <WarningIndicator />}</>;
  }
);
