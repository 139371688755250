import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useWizard} from '@youtoken/ui.elements';
import {BuyCryptoFormAuthorizedYouHodler} from '../../BuyCryptoForm';
import {type CryptoBuyWizardNavigator, CryptoBuyWizardScreenName} from '..';

export const CryptoBuyWizardBuy = () => {
  const {
    step: {
      props: {source, ticker},
    },
    goBack,
    close,
  } = useWizard<CryptoBuyWizardNavigator, CryptoBuyWizardScreenName.BUY>();

  React.useEffect(() => {
    DATA_LAYER.trackStrict('buy-wizard-opened', {
      source,
      ticker,
    });
  }, [source, ticker]);

  return (
    <BuyCryptoFormAuthorizedYouHodler
      conversionTicker={ticker}
      onPressBack={goBack}
      onSubmit={close}
    />
  );
};
