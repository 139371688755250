import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';
import {AccountLevelMinerUpToBTCBadge} from '@youtoken/ui.surface-account-levels';

type LevelRewardPerBlockProps = {
  amount: string;
  variant: 'success' | 'locked';
};

export const LevelRewardPerBlock: React.FC<LevelRewardPerBlockProps> = ({
  amount,
  variant,
}) => {
  const {t} = useTranslation();

  return (
    <>
      <AccountLevelMinerUpToBTCBadge
        amount={amount}
        variant={variant}
        size="medium"
        borderRadius={16}
        mt={12}
      />
      <Text mt={8} color="$text-02" variant="$body-02">
        {t('surface.miner.loyalty.reward')}
      </Text>
    </>
  );
};
