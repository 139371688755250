import * as React from 'react';
import {BaseErrorFallback} from './BaseFallback';
import {ErrorFallbackProps} from './types';

export const UnauthorizedErrorFallback: React.FC<
  ErrorFallbackProps
> = props => {
  return (
    <BaseErrorFallback
      iconName="profile"
      text="User is not authorized"
      {...props}
    />
  );
};
