import React from 'react';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Helmet} from 'react-helmet';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AccountStatsSurface} from '@youtoken/ui.surface-account-stats';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Title} from '@web-app/src/components/layout/Shell';

export const AccountStatsPage: React.FC = cell(() => {
  const {t} = useTranslation();
  return (
    <InnerPageShell>
      <Helmet title={t('surface.history.tab_title')} />
      <Box px={{default: 20, desktop: 0}}>
        <Title>{t('surface.history.title')}</Title>
        <AccountStatsSurface />
      </Box>
    </InnerPageShell>
  );
});
