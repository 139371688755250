import {serializable, primitive, list, object} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {Big} from 'big.js';
import {computed} from 'mobx';
import {getPercentByAmount} from '@youtoken/ui.hodls-utils';

class Loan {
  @serializable(bigNumber())
  borrowed!: Big;

  @serializable(bigNumber())
  collateral!: Big;

  @serializable(bigNumber())
  settlementFee!: Big;

  @serializable(primitive())
  settlementPeriod!: string;
}

class LoanObj {
  @serializable(list(object(Loan)))
  loans: Loan[] = [];
}

export class CalculatedDataResponse {
  @serializable(primitive())
  isShort!: boolean;

  @serializable(bigNumber())
  initialPrice!: Big;

  @serializable(primitive())
  inputTicker!: string;

  @serializable(bigNumber())
  mcPrice!: Big;

  @serializable(bigNumber())
  ftpPrice!: Big;

  @computed
  public get roundingMode() {
    return this.isShort ? 3 : 0;
  }
  @computed
  private get defaultTPValue() {
    return this.initialPrice.mul(this.isShort ? 0.5 : 2);
  }

  @serializable(object(LoanObj))
  loan!: LoanObj;

  @computed
  public get tpPercent(): string {
    return getPercentByAmount(this.initialPrice, this.tpValue);
  }

  @computed
  public get tpValue() {
    return this.ftpPrice && this.ftpPrice.gt(0)
      ? this.ftpPrice
      : this.defaultTPValue;
  }

  @computed
  public get slPercent(): string {
    return getPercentByAmount(this.initialPrice, this.mcPrice);
  }

  @computed
  public get loansInChain(): number {
    return Number(this.loan.loans.length);
  }

  @computed
  public get inputTickerFormatted(): string {
    return this.inputTicker.toUpperCase();
  }
}
