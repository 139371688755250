import {useTheme, Theme} from '@youtoken/ui.primitives';

export const useIconColor = (color?: keyof Theme['colors']) => {
  const {colors} = useTheme();

  if (!color) {
    return undefined;
  }

  return colors[color] ?? color;
};
