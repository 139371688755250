import * as React from 'react';
import {Button} from '@youtoken/ui.buttons';

export interface SearchButtonProps {
  onPress?: () => void;
}

export const SearchButton: React.FC<SearchButtonProps> = React.memo(
  ({onPress}) => (
    <Button
      px={0}
      my={2}
      height={28}
      type="ghost"
      icon="search"
      color="interactive"
      onPress={onPress}
      testID="ALL_INSTRUMENTS_SEARCH_BUTTON"
    />
  )
);
