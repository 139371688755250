import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const IdCardIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M11.75 7a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zm.25 3.75a.75.75 0 01.75-.75h3.5a.75.75 0 010 1.5h-3.5a.75.75 0 01-.75-.75z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 8.5c0 .76-.34 1.441-.875 1.9A3.502 3.502 0 0110 13.5a.75.75 0 01-1.5 0 2 2 0 00-4 0 .75.75 0 01-1.5 0 3.5 3.5 0 011.875-3.1A2.5 2.5 0 119 8.5zm-2.5 1a1 1 0 100-2 1 1 0 000 2z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 5a2 2 0 012-2h16a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2V5zm2-.5h16a.5.5 0 01.5.5v10a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
