import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {SortingTab} from './SortingTab';
import {type SortingTabsProps} from './types';

export const SortingTabs: React.FC<SortingTabsProps> = ({
  items,
  activeValue,
  size = 'small',
  onChange,
  ...boxProps
}) => {
  return (
    <Box accessibilityRole="tablist" flexDirection="row" mr={-8} {...boxProps}>
      {items.map((item, index) => {
        return (
          <SortingTab
            key={index}
            item={item}
            size={size}
            isActive={item.value === activeValue}
            onChange={onChange}
          />
        );
      })}
    </Box>
  );
};
