import {WalletChartState} from '../WalletChartState';
import {computed} from 'mobx';

export class BaseDataDisaplay {
  chart!: WalletChartState;

  constructor(chart: WalletChartState) {
    this.chart = chart;
  }

  @computed get dataStartIndex() {
    if (!this.chart.startDate) {
      return 0;
    }
    // NOTE: for cases, when date of start deal was added to the chart.data
    const exactDateIndex = this.chart.data.findIndex(
      item => item.date === this.chart.startDate
    );

    return exactDateIndex !== -1
      ? exactDateIndex
      : this.chart.bisectorX.left(this.chart.data, this.chart.startDate) - 1;
  }

  @computed get dataEndIndex() {
    if (!this.chart.endDate) {
      return this.chart.data.length;
    }

    return this.chart.bisectorX.right(this.chart.data, this.chart.endDate);
  }
}
