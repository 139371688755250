import * as React from 'react';
import {Platform, Share} from 'react-native';
import {observer} from 'mobx-react';
import {SENTRY} from '@youtoken/ui.sentry';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Button} from '@youtoken/ui.buttons';

export const ShareRefCodeShareButtonSmart: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    data: {rewardVersion},
    referralLink,
  } = AuthMeResource.use({});

  const title = React.useMemo(() => {
    if (rewardVersion === 3) {
      return t('surface.referral_program.share_ref_code.message_v3.title');
    }

    return t('surface.referral_program.share_ref_code.message_v1.title');
  }, [rewardVersion, t]);

  const message = React.useMemo(() => {
    if (rewardVersion === 3) {
      return t('surface.referral_program.share_ref_code.message_v3.content', {
        link: referralLink,
      });
    }

    return t('surface.referral_program.share_ref_code.message_v1.content', {
      link: referralLink,
    });
  }, [rewardVersion, title, t]);

  const handleShare = React.useCallback(async () => {
    const contentMessage = `${title}\n\n${message}`;

    const content = Platform.select({
      default: {
        title,
        message: contentMessage,
      },
      ios: {
        message: contentMessage,
      },
    });

    const options = Platform.select({
      default: {
        dialogTitle: title,
      },
      ios: {
        subject: title,
      },
    });

    try {
      await Share.share(content, options);
      DATA_LAYER.trackStrict('reward-referral-link-share', {});
    } catch (error) {
      SENTRY.capture(error as Error, {
        source: 'ShareRefCodeScreen',
      });
    }
  }, [title, message]);

  return (
    <Button size="large" onPress={handleShare}>
      {t('surface.referral_program.share_ref_code.share_ref_link')}
    </Button>
  );
});
