import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {HODLByIdFeature} from '../../../features';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {RepeatTradeButton} from './RepeatTradeButton';

interface RepeatTradeSectionProps {
  hodl: HODLItem;
}

export const RepeatTradeSection: React.FC<RepeatTradeSectionProps> = observer(
  ({hodl}) => {
    const {repeatTradeParams} = HODLByIdFeature.use({id: hodl.id});

    return (
      <Box px={24} py={8}>
        <RepeatTradeButton {...repeatTradeParams} />
      </Box>
    );
  }
);
