import * as React from 'react';
import {observer} from 'mobx-react';
import {SmallBankCard} from '@youtoken/ui.bank-card';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useForm} from '@youtoken/ui.form-elements';
import {BankCardTransferForm} from '../state';

export const FromField: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {
      card: {
        format,
        paymentSystem,
        panLast,
        balance,
        balanceWithTickerFormatted,
      },
      tickerFormatted,
      maxAmount,
      onSumChange,
    },
    view: {allOrMaxText},
  } = useForm<BankCardTransferForm>();

  const maxSum = React.useMemo(() => {
    return Math.min(balance, maxAmount.toNumber());
  }, [balance, maxAmount]);

  const handleAllPress = React.useCallback(() => {
    onSumChange(maxSum);
  }, [maxSum, onSumChange]);

  return (
    <Box {...boxProps}>
      <Box flexDirection="row" justifyContent="space-between">
        <Text variant="$body-01-high-accent" testID="FROM_LABEL">
          {t('surface.bank_cards.transfer.from')}
        </Text>

        <TouchableBox
          flexDirection="row"
          onPress={handleAllPress}
          testID="MAX_AMOUNT"
        >
          <Text variant="$body-02" color="$text-05" testID="MAX_AMOUNT_LABEL">
            {allOrMaxText}
          </Text>
          <Text
            variant="$body-02-medium-accent"
            color="$text-05"
            testID="MAX_AMOUNT_VALUE"
          >
            {` ${maxSum}`}
          </Text>
          <Text variant="$body-02" color="$text-05" testID="MAX_AMOUNT_TICKER">
            {` ${tickerFormatted}`}
          </Text>
        </TouchableBox>
      </Box>

      <Box
        borderRadius={8}
        backgroundColor="$interactive-02"
        flexDirection="row"
        alignItems="center"
        p={16}
        mt={8}
        testID="FROM_CARD"
      >
        <SmallBankCard
          type={format}
          paymentSystem={paymentSystem}
          cardNumber={panLast}
        />
        <Box flexDirection="column" ml={8}>
          <Text
            variant="$body-01-medium-accent"
            color="$text-01"
            testID="FROM_CARD_FORMAT"
          >
            {t('component.bank_card.virtual_card')}
          </Text>
          <Text variant="$body-02" color="$text-02" testID="FROM_CARD_BALANCE">
            {balanceWithTickerFormatted}
          </Text>
        </Box>
      </Box>
    </Box>
  );
});
