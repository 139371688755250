import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Box, Text} from '@youtoken/ui.primitives';
import {ShareRefCodeCopyReferralCodeButtonSmart} from './CopyReferralCodeButton';
import {ShareRefCodeShareButtonSmart} from './ShareButton';

export const ShareRefCodeSmart: React.FC = observer(() => {
  const {t} = useTranslation();

  const {referralCode} = AuthMeResource.use({});

  return (
    <Box p={24}>
      <Box
        flexDirection="row"
        borderColor="$interactive-02"
        borderWidth={1}
        borderRadius={8}
        px={24}
        py={16}
        mb={24}
      >
        <Box flex={3}>
          <Text variant="$body-02" color="$text-02">
            {t('surface.referral_program.share_ref_code.your_referral_code')}
          </Text>
          <Text variant="$heading-02" color="$text-01">
            {referralCode}
          </Text>
        </Box>
        <Box flex={2}>
          <ShareRefCodeCopyReferralCodeButtonSmart />
        </Box>
      </Box>
      <ShareRefCodeShareButtonSmart />
    </Box>
  );
});
