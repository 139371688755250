import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const AdditionalBenefits: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Box height={75} flexDirection="row" alignItems="center" {...boxProps}>
      <Box position="relative" width={48} height={48} mr={12}>
        <Box
          position="absolute"
          backgroundColor="$interactive-02"
          width={32}
          height={32}
          borderRadius={32}
          left={0}
          bottom={0}
          justifyContent="center"
          alignItems="center"
        >
          <Icon name="volume" color="$interactive-01" size={20} />
        </Box>
        <Box
          position="absolute"
          backgroundColor="$attention-02"
          width={34}
          height={34}
          borderRadius={34}
          borderWidth={2}
          borderColor="$ui-background"
          right={0}
          top={0}
          justifyContent="center"
          alignItems="center"
        >
          <Icon name="spark" color="$attention-01" size={20} />
        </Box>
      </Box>
      {/* @ts-ignore */}
      <Text variant="$body-02" color="$text-02" flex={1}>
        {t('surface.upgrade_modal.volume.additional_benefit')}
      </Text>
    </Box>
  );
});
