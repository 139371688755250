import {computed, observable, runInAction} from 'mobx';
import {TradingViewData} from './types';
import {TRANSPORT} from '@youtoken/ui.transport';
import {warning} from '@youtoken/ui.utils';
import {
  convertBarFromServerToBarForLibrary,
  generatePricescale,
  getNextDailyBarTime,
  getTickFromResolution,
  parseSymbol,
  getWorkingHoursFromDaysOff,
} from './utils';
import {
  type Bar,
  type ChartingLibraryWidgetOptions,
  type ResolutionString,
  type SeriesFormat,
  type Timezone,
} from '../charting_library';

type SocketFn = (
  tick: string | undefined,
  fromTicker: string,
  toTicker: string
) => void;

const tvChartResolutions = [
  '1',
  '5',
  '15',
  '30',
  '60',
  '240',
  '480',
] as ResolutionString[];

export class TradingViewChartData {
  args!: TradingViewData.Args;

  @observable isSubscribedToSocket: boolean;

  // Use it to keep a record of the socket subscriptions
  private channelToSubscription = new Map();

  // Use it to keep a record of the most recent bar on the chart
  private lastBarsCache = new Map();

  // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.DatafeedConfiguration#supported_resolutions
  private configurationData = {
    supported_resolutions: tvChartResolutions,
  };

  constructor(args: TradingViewData.Args) {
    this.args = args;
    this.isSubscribedToSocket = false;

    TRANSPORT.SOCKET.on('product-rates-candles', this.handleSocketBars);
  }

  subscribeToSocket: SocketFn = (tick, fromTicker, toTicker) => {
    if (!this.isSubscribedToSocket) {
      TRANSPORT.SOCKET.emit('sub', {
        name: 'product-rates-candles',
        product: 'hodl',
        tick,
        fromTicker,
        toTicker,
        mode: this.args.mode,
      });
      this.isSubscribedToSocket = true;
    }
  };

  unsubscribeFromSocket: SocketFn = (tick, fromTicker, toTicker) => {
    if (this.isSubscribedToSocket) {
      TRANSPORT.SOCKET.emit('unsub', {
        name: 'product-rates-candles',
        product: 'hodl',
        tick,
        fromTicker,
        toTicker,
        mode: this.args.mode,
      });
      this.isSubscribedToSocket = false;
    }
  };

  handleSocketBars = (incomingBar: TradingViewData.BarFromServer) => {
    // ignoring data if not connected socket;
    if (!this.isSubscribedToSocket) {
      return;
    }

    const {fromTicker, toTicker, mode} = incomingBar;
    const bar = convertBarFromServerToBarForLibrary(incomingBar);
    const channelString = `0~${fromTicker}_${toTicker}.${mode}`;
    const subscriptionItem = this.channelToSubscription.get(channelString);

    if (subscriptionItem === undefined) {
      return;
    }

    runInAction(() => {
      const lastDailyBar = subscriptionItem.lastDailyBar;

      const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

      let newDailyBar: Bar;
      if (bar.time >= nextDailyBarTime) {
        newDailyBar = {
          time: nextDailyBarTime,
          open: bar.open,
          high: bar.high,
          low: bar.low,
          close: bar.close,
        };
      } else {
        newDailyBar = {
          ...lastDailyBar,
          high: Math.max(lastDailyBar.high, bar.high),
          low: Math.min(lastDailyBar.low, bar.low),
          close: bar.close,
        };
      }

      subscriptionItem.lastDailyBar = newDailyBar;

      // Send data to every subscriber of that symbol
      subscriptionItem.handlers.forEach(
        (handler: {callback: (args: Bar) => void}) =>
          handler.callback(newDailyBar)
      );
    });
  };

  @computed.struct
  public get datafeed(): ChartingLibraryWidgetOptions['datafeed'] {
    return {
      onReady: callback => {
        setTimeout(() => callback(this.configurationData));
      },
      searchSymbols: () => {
        return [];
      },
      resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
        const {fromTicker, toTicker, mode} = parseSymbol(symbolName);
        const formattedPair = `${fromTicker?.toUpperCase()}/${toTicker?.toUpperCase()}`;

        // NOTE: Symbol information object
        const symbolInfo = {
          /** Symbol Name It's the name of the symbol.
                     It is a string that your users will be able to see.
                     Also, it will be used for data requests if you are not using tickers.
                     */
          name: formattedPair,

          /** A string that has the EXCHANGE:SYMBOL format.
                     This string is displayed on the chart legend, a user-friendly name
                     If you do not specify full_name manually, Charting Library generates the property value using name and exchange.
                     Some Trading Terminal features like Watchlist, Details, and Account Manager does not support ticker,
                     and the library uses full_name to request data for them.
                     */
          full_name: symbolName,

          /** This identifier is not displayed to the users.
                     If you specify this property then its value will be used for all data requests for this symbol.
                     ticker will be treated the same as name if not specified explicitly.
                     */
          ticker: symbolName,

          /** Will be displayed in the chart legend for this symbol. */
          description: `${formattedPair} ${mode}`,

          /**  possible values: https://www.tradingview.com/charting-library-docs/latest/api/modules/Charting_Library/#symboltype
           * ​"stock" | "index" | "forex" | "futures" | "bitcoin" | "crypto" | "undefined" |
           * "expression" | "spread" | "cfd" | "economic" | "equity" | "dr" | "bond" | "right" |
           * "warrant" | "fund" | "structured" | "commodity" | "fundamental" | "spot"
           * The value of the type parameter affects how the symbol is presented to users in the TradingView interface.
           * It determines the default charting behavior, available features, and relevant data fields associated with the symbol.
           * For example, if the type is set to "stock," TradingView might display additional fundamental data such as
           * company name, sector, market cap, etc., whereas for a "cryptocurrency" type, it may show specific crypto-related data.
           * */
          type: 'crypto',

          /* possible format: https://www.tradingview.com/charting-library-docs/latest/connecting_data/Trading-Sessions/
           * at the first release will be '24x7'
           * todo get, using this.daysOff string as: '0000-2400:2345|0000-2100:6|2100-2400:1', where 1 - sunday, 2 - monday, ... 7 - saturday
           * */
          session: getWorkingHoursFromDaysOff(this.args.daysOff),

          /** List of exchange descriptors. An empty array leads to an absence of the exchanges filter in the Symbol Search list.
           * Use value='' if you wish to include all exchanges.
           * is used to specify the exchange or market where the financial instrument or symbol is traded.
           * It helps TradingView determine which exchange-specific data and trading features to associate with the symbol.
           * */
          exchange: 'YouHodler',
          listed_exchange: 'YouHodler',

          /* can be changed to custom timezone https://www.tradingview.com/charting-library-docs/latest/api/modules/Charting_Library#customtimezones */
          timezone: 'Etc/UTC' as Timezone,

          /** format for labels on the price scale */
          format: 'price' as SeriesFormat,

          /** A number of decimal places or fractions that the price has. */
          // pricescale: generatePricescale(this.precision),
          pricescale: generatePricescale(this.args.precision),

          /** The number of units that make up one tick.
           * I use '1', as all other values (0.01, 0.0001) makes library to concat value in decimals part, for example: 1820.4545129 -> 1820..129*/
          minmov: 1,

          /** now we have only intraday source data on our BE part */
          has_daily: false,
          has_intraday: true,
          has_weekly_and_monthly: false,

          supported_resolutions: tvChartResolutions, // ['2', '5', '15'],
          intraday_multipliers: ['1', '5', '15', '30', '60', '240'], //  inform TradingView about the available intraday resolutions supported by your backend data source

          /** The currency in which the instrument is traded. */
          // original_currency_code: symbolItem.quoteTicker,

          /** The currency in which the instrument is traded or some other currency if currency conversion is enabled.
                     It is displayed in the Symbol Info dialog and on the price axes.
                     */
          // currency_code: symbolItem.quoteTicker,

          data_status: 'streaming' as const,
        };
        // NOTE: this is a fix of a warning from library
        setTimeout(() => onSymbolResolvedCallback(symbolInfo));
      },
      getBars: async (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
      ) => {
        const {from, to, countBack, firstDataRequest} = periodParams;
        const {fromTicker, toTicker, mode} = parseSymbol(
          symbolInfo.ticker as string
        );
        const tick = getTickFromResolution(resolution);

        try {
          const bars = await TRANSPORT.API.get<TradingViewData.BarsFromServer>(
            '/v3/rates/chart',
            {
              params: {
                type: 'candle',
                product: 'hodl',
                fromTicker,
                toTicker,
                points: countBack,
                fromDate: new Date(from * 1000),
                toDate: new Date(to * 1000),
                tick,
                mode,
              },
            }
          ).then(response => {
            const formattedResponse = response.data.map(item =>
              convertBarFromServerToBarForLibrary(item)
            );

            if (firstDataRequest) {
              this.lastBarsCache.set(symbolInfo.ticker, {
                ...formattedResponse[formattedResponse.length - 1],
              });
            }

            return formattedResponse;
          });

          if (bars.length === 0) {
            onHistoryCallback([], {noData: true});
            return;
          }

          onHistoryCallback(bars, {noData: false});
        } catch (error) {
          onErrorCallback(error as string);
        }
      },
      subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscriberUID
      ) => {
        const channelString = `0~${symbolInfo.ticker}`;
        const lastDailyBar = this.lastBarsCache.get(symbolInfo.ticker);
        const handler = {
          id: subscriberUID,
          callback: onRealtimeCallback,
        };
        let subscriptionItem = this.channelToSubscription.get(channelString);
        if (subscriptionItem) {
          // Already subscribed to the channel, use the existing subscription
          subscriptionItem.handlers.push(handler);
          return;
        }
        subscriptionItem = {
          subscriberUID,
          resolution,
          lastDailyBar,
          handlers: [handler],
        };
        this.channelToSubscription.set(channelString, subscriptionItem);

        const {fromTicker, toTicker} = parseSymbol(symbolInfo.ticker as string);
        const tick = getTickFromResolution(resolution);

        warning(
          fromTicker && toTicker && tick,
          'fromTicker, toTicker and tick is required to subscribe to the socket'
        );

        this.subscribeToSocket(tick, fromTicker!, toTicker!);
      },
      unsubscribeBars: subscriberUID => {
        // Find a subscription with id === subscriberUID
        // @ts-ignore
        for (const channelString of this.channelToSubscription.keys()) {
          const subscriptionItem =
            this.channelToSubscription.get(channelString);
          const handlerIndex = subscriptionItem.handlers.findIndex(
            (handler: {id: string}) => handler.id === subscriberUID
          );
          const {resolution} = subscriptionItem;

          if (handlerIndex !== -1) {
            // Remove from handlers
            subscriptionItem.handlers.splice(handlerIndex, 1);

            if (subscriptionItem.handlers.length === 0) {
              // Unsubscribe from the channel if it is the last handler
              const tick = getTickFromResolution(resolution);
              this.unsubscribeFromSocket(
                tick,
                this.args.fromTicker,
                this.args.toTicker
              );
              this.channelToSubscription.delete(channelString);
              break;
            }
          }
        }
      },
    };
  }
}
