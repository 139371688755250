import React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AccountLevelShort} from '@youtoken/ui.surface-account-levels';
import {TopBarLayout} from './Layout';
import {TopBarProfileButton} from './ProfileButton';

const handlePressProfile = () => {
  DATA_LAYER.trackStrict('top-bar-open-profile', {});
  SHARED_ROUTER_SERVICE.navigate('More', {reset: true});
};

const handlePressLoyalty = () => {
  DATA_LAYER.trackStrict('top-bar-open-loyalty', {});
  SHARED_ROUTER_SERVICE.navigate('AccountLevels');
};

export const TopBarMain: React.FC<BoxProps> = observer(boxProps => {
  const {
    data: {
      products: {
        loyalty: {available: loyaltyAvailable},
      },
    },
  } = AuthMeResource.use({});

  return (
    <TopBarLayout {...boxProps}>
      <TopBarProfileButton onPress={handlePressProfile} />
      {loyaltyAvailable && <AccountLevelShort onPress={handlePressLoyalty} />}
    </TopBarLayout>
  );
});
