import * as React from 'react';
import * as LocalAuthentication from 'expo-local-authentication';
import {
  useSharedValue,
  withTiming,
  useAnimatedStyle,
  withSequence,
  Easing,
  runOnJS,
} from 'react-native-reanimated';
import {Box, ReanimatedBox, Text} from '@youtoken/ui.primitives';
import {PasscodeKeyboard} from './PasscodeKeyboard';
import {PasscodeValue} from './PasscodeValue';
import {mapAuthType} from './utils';
import {SECURE_STORAGE} from '@youtoken/ui.service-storage';

export interface PasscodeProps {
  prompt?: string;
  onSuccess: () => void;
  onFail?: () => void;
}

export const Passcode: React.FC<PasscodeProps> = ({
  prompt,
  onSuccess,
  onFail,
}) => {
  const [passcodeValue, setPasscodeValue] = React.useState<string>('');
  const [isLoading, setLoading] = React.useState(false);
  const [isShaking, setShaking] = React.useState(false);
  const [localAuthType, setLocalAuthType] = React.useState<
    'NONE' | 'FINGERPRINT' | 'FACIAL_RECOGNITION'
  >('NONE');

  const onLocalAuthPress = () => {
    setLoading(true);

    LocalAuthentication.authenticateAsync({
      promptMessage: prompt,
    })
      .then(result => {
        if (result.success) {
          onSuccess();
        } else {
          onFail?.();
        }
      })
      .catch(() => {
        onFail?.();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    Promise.all([
      LocalAuthentication.hasHardwareAsync(),
      LocalAuthentication.isEnrolledAsync(),
      LocalAuthentication.supportedAuthenticationTypesAsync(),
    ]).then(([hasHardware, isEnrolled, supportedAuthType]) => {
      if (hasHardware && isEnrolled) {
        const authType = mapAuthType(supportedAuthType);
        setLocalAuthType(authType);
      }
    });
  }, []);

  const [storedPasscode] = React.useState(() => {
    const passcode = SECURE_STORAGE.get('youhodlerPasscodeKey');
    return passcode;
  });

  React.useEffect(() => {
    if (passcodeValue.length === 4) {
      setLoading(true);

      if (storedPasscode) {
        if (passcodeValue === storedPasscode) {
          onSuccess();
        } else {
          shake();
          onFail?.();
        }

        setPasscodeValue('');
        setLoading(false);
      }
    }
  }, [storedPasscode, passcodeValue, onSuccess, onFail]);

  const onNumberPress = React.useCallback(
    (number: number) => {
      setPasscodeValue(value => `${value}${number}`);
    },
    [setPasscodeValue]
  );

  const onBackspacePress = React.useCallback(() => {
    setPasscodeValue(value => value.slice(0, value.length - 1));
  }, [setPasscodeValue]);

  const animatedOffset = useSharedValue(0);
  const style = useAnimatedStyle(() => ({
    left: animatedOffset.value,
  }));

  const shake = React.useCallback(() => {
    setShaking(true);
    animatedOffset.value = withSequence(
      withTiming(-10, {duration: 50, easing: Easing.ease}),
      withTiming(10, {duration: 100}),
      withTiming(-10, {duration: 100}),
      withTiming(
        0,
        {
          duration: 50,
          easing: Easing.ease,
        },
        () => runOnJS(setShaking)(false)
      )
    );
  }, []);

  return (
    <Box flex={1} pt={24}>
      <Box width="100%">
        {Boolean(prompt) && (
          <Text variant="$body-01" color="$text-01" textAlign="center" px={64}>
            {prompt}
          </Text>
        )}
      </Box>

      <ReanimatedBox style={style}>
        <PasscodeValue
          value={passcodeValue}
          isWrong={isShaking}
          mt={40}
          mb={60}
        />
      </ReanimatedBox>

      <Box alignItems="center">
        <PasscodeKeyboard
          onNumberPress={onNumberPress}
          onBackspacePress={onBackspacePress}
          onLocalAuthPress={onLocalAuthPress}
          localAuthType={localAuthType}
          isBackspaceActive={passcodeValue.length > 0}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
};
