import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {RewardsHistoryResponse} from './RewardsHistoryResponse';

export interface RewardsHistoryV3ResourceArgs {
  limit?: number;
}

export class RewardsHistoryV3Resource extends createResource({
  getKey: (args: RewardsHistoryV3ResourceArgs) =>
    `rewardsHistoryV3(${args.limit})`,
  getData: args => {
    return TRANSPORT.API.get(`/v3/rewards/history3`, {
      params: args,
    }).then(res => {
      return deserialize(RewardsHistoryResponse, res.data);
    });
  },
  skipRefreshOnVisible: false,
}) {}
