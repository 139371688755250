import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {LandingLoan, LandingExchangePage} from '@web-app/src/components/pages';

export const WidgetsRoutes: React.FC<RouteComponentProps> = ({
  match: {path},
}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}/loan`} component={LandingLoan} />
      <Route
        exact
        path={`${path}/loan-track`}
        component={() => <LandingLoan />}
      />
      <Route exact path={`${path}/exchange`} component={LandingExchangePage} />
    </SwitchWithNotFound>
  );
};
