import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SnowflakeIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M10 1.5V5m0 13.5V15m0-10L8 3.5M10 5l2-1.5M10 5v10m0 0l-2 1.5m2-1.5l2 1.5M2.639 5.75l3.03 1.75m11.692 6.75l-3.03-1.75m-8.661-5l-2.3.982m2.3-.982l-.3-2.482m.3 2.482l8.66 5m0 0l.3 2.482m-.3-2.482l2.3-.982m.731-5.768l-3.03 1.75M2.638 14.25l3.03-1.75m8.661-5l.3-2.482m-.3 2.482l2.3.982m-2.3-.982l-8.66 5m0 0l-2.3-.982m2.3.982l-.3 2.482"
          stroke={color}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  }
);
