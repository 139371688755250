import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {ExchangeFormState} from '../../state';
import {ExchangeFormAgreeToTerms} from '../AgreeToTerms';
import {type ExchangeFormFooterProps} from './types';

export const ExchangeFormFooterV2: React.FC<
  ExchangeFormFooterProps & BoxProps
> = observer(({showAgreements = true, ...boxProps}) => {
  const {t} = useTranslation();

  const {
    form: {checkIsLoading, rateTimeLeft},
    view: {conversionTickerFormatted},
  } = useForm<ExchangeFormState>();

  return (
    <Box p={24} borderTopWidth={1} borderColor="$ui-01" {...boxProps}>
      {showAgreements && <ExchangeFormAgreeToTerms />}
      <SubmitButton testID="CONVERSION_SUBMIT_BUTTON" loading={checkIsLoading}>
        {t('surface.wallets.conversion_form.submit', {
          amount: conversionTickerFormatted,
        })}{' '}
        ({rateTimeLeft})
      </SubmitButton>
    </Box>
  );
});
