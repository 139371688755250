import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Heading, Text} from '@youtoken/ui.primitives';

export interface SavingsTotalEarnedProps {
  ticker: string;
  amountFormatted: string;
  amountProps?: React.ComponentProps<typeof Heading>;
}

export const SavingsTotalEarned: React.FC<SavingsTotalEarnedProps & BoxProps> =
  observer(({ticker, amountFormatted, amountProps = {}, ...boxProps}) => {
    const {t} = useTranslation();

    const tickerSymbol = getCoinSymbol(ticker);

    return (
      <Box testID="SAVINGS_TOTAL_EARNED" alignItems="center" {...boxProps}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.savings.total_earned')}
        </Text>
        <Heading variant="$heading-01" mt={4} {...amountProps}>
          <Text testID="SAVINGS_TOTAL_EARNED_SYMBOL">{tickerSymbol}</Text>
          <Text testID="SAVINGS_TOTAL_EARNED_VALUE">{amountFormatted}</Text>
        </Heading>
      </Box>
    );
  });
