import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {FeatureLoanCreateLandingForm} from '../../LoanRegularCreateLandingForm/state';
import {
  type BoxProps,
  Box,
  Heading,
  Text,
  TouchableBox,
  useIsMobile,
} from '@youtoken/ui.primitives';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import chunk from 'lodash/chunk';
import {Section} from '../Section';
import {TariffLoansRegular} from '@youtoken/ui.resource-loans';

export const Term: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  const {form} = useForm<
    FeatureLoanCreateForm | FeatureLoanCreateLandingForm
  >();

  const termsChunks = React.useMemo(() => {
    const chunksLength = isMobile ? 3 : 5;

    let chunks = chunk<(typeof form.terms)[number] | null>(
      form.terms,
      chunksLength
    );

    while (
      chunks[chunks.length - 1] &&
      chunks[chunks.length - 1]!.length < chunksLength
    ) {
      chunks[chunks.length - 1]!.push(null);
    }

    return chunks;
  }, [isMobile, form.terms]);

  const handleMapTerms = React.useCallback(
    (_term: TariffLoansRegular | null, index: number) => (
      <Box key={`term-${index}-${_term?.id}`} flex={1} m={4}>
        {_term && (
          <TouchableBox
            justifyContent="center"
            alignItems="center"
            flex={1}
            p={12}
            borderRadius={8}
            backgroundColor={
              form.termId === _term.id ? '$interactive-01' : '$interactive-02'
            }
            onPress={() => {
              form.termIdOnChange(_term.id);
            }}
          >
            <Heading
              variant="$heading-02"
              color={form.termId === _term.id ? '$text-04' : '$text-05'}
            >
              {formatPercent(_term.ltv)}%
            </Heading>
          </TouchableBox>
        )}
      </Box>
    ),
    [form.termId, form.termIdOnChange]
  );

  const handleMapTermsChunks = React.useCallback(
    (items: (TariffLoansRegular | null)[], index: number) => (
      <Box flexDirection="row" key={`terms-${index}`}>
        {items.map(handleMapTerms)}
      </Box>
    ),
    [handleMapTerms]
  );

  return (
    <Section {...props}>
      <Label mb={8}>{t('surface.loans.create_form.term_ltv')}</Label>
      <Text variant="$body-02" color="$text-02" mb={16}>
        {t('surface.loans.create_form.term_ltv_description')}
      </Text>
      <Box m={-4}>{termsChunks.map(handleMapTermsChunks)}</Box>
      <FieldErrorMessage visible={form.termIdHasError}>
        {form.termIdError}
      </FieldErrorMessage>
    </Section>
  );
});
