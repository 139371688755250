import * as React from 'react';
import {observer} from 'mobx-react';
import {
  useForm,
  ValidationMessageTouchableMinAndMax,
} from '@youtoken/ui.form-elements';
import {CreateHODLFormClass} from '../../CreateHODLForm';

export const ValidationMessageTouchable: React.FC = observer(() => {
  const {
    form: {updateTriggerPriceValue, triggerPriceValueMin, triggerPriceValueMax},
    view: {triggerPriceHasError},
  } = useForm<CreateHODLFormClass>();

  const handlePressMin = React.useCallback(() => {
    updateTriggerPriceValue(triggerPriceValueMin);
  }, [updateTriggerPriceValue, triggerPriceValueMin]);

  const handlePressMax = React.useCallback(() => {
    updateTriggerPriceValue(triggerPriceValueMax);
  }, [updateTriggerPriceValue, triggerPriceValueMax]);

  return (
    <ValidationMessageTouchableMinAndMax
      valueMin={triggerPriceValueMin}
      valueMax={triggerPriceValueMax}
      handlePressMin={handlePressMin}
      handlePressMax={handlePressMax}
      fieldHasError={triggerPriceHasError}
    />
  );
});
