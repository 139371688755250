declare global {
  interface Window {
    AFKClickUrl: string;
  }
}

export function init() {
  window.AFKClickUrl = 'https://track.youhodler.app/click';

  const script = document.createElement('script');

  script.src = 'https://track.youhodler.app/hp';
  script.async = true;

  document.body.insertBefore(script, document.body.firstChild);
}
