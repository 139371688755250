import {en} from '@youtoken/ui.lokalise-export';
import {i18n} from '@youtoken/ui.service-i18n';
import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';

export class MarginCallLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (
      !this.chart.marginCallType ||
      !this.chart.marginCall ||
      this.chart.initialPriceChart === 0
    ) {
      return undefined;
    }

    const marginCallTranslated = i18n.t(
      `charts.${this.chart.marginCallType}` as keyof typeof en
    );
    const text = `${marginCallTranslated} ${this.chart.formatNumber(
      this.chart.marginCall
    )} ${this.chart.formatPercent(
      this.chart.getDiffWithValue(
        this.chart.marginCall,
        this.chart.initialPriceChart
      )
    )}`;

    return this.chart.layout.getLabelLayout(
      this.chart.marginCall,
      text,
      'right',
      this.chart.direction
    );
  }
}
