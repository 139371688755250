import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {PageMenu} from './PageMenu';
import {HODLsInstrumentsPageContent} from './HODLsInstrumentsPageContent';
import {HODLsPortfolioPageContent} from './HODLsPortfolioPageContent';

export interface HODLsMainPageContentProps {
  innerPage: PageSwitcherValue;
}

export const HODLsMainPageContent = cell<HODLsMainPageContentProps>(
  ({innerPage}) => {
    React.useEffect(() => {
      if (innerPage === PageSwitcherValue.TRADING) {
        DATA_LAYER.trackStrict('hodl-what-to-trade', {});
      }
      if (innerPage === PageSwitcherValue.PORTFOLIO) {
        DATA_LAYER.trackStrict('hodl-portfolio', {});
      }
    }, [innerPage]);

    return (
      <>
        <PageMenu page={innerPage} />
        {innerPage === PageSwitcherValue.TRADING && (
          <HODLsInstrumentsPageContent />
        )}
        {innerPage === PageSwitcherValue.PORTFOLIO && (
          <HODLsPortfolioPageContent />
        )}
      </>
    );
  }
);
