import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {RewardSummaryResponse} from './RewardSummaryResponse';
import {localClientSettings} from '@youtoken/ui.resource-auth-me';

export class RewardSummaryResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => `RewardSummaryResource`,
  getData: () => {
    return TRANSPORT.API.get(`/v3/rewards/summary`, {
      params: {
        referralViewedDateTime: localClientSettings?.referralViewedDateTime,
        rewardViewedDateTime: localClientSettings?.rewardViewedDateTime,
      },
    }).then(res => {
      return deserialize(RewardSummaryResponse, res.data);
    });
  },
}) {}
