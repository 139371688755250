import * as React from 'react';
import {useEventListener} from '@web-app/src/hooks';
import {
  preventPropagation,
  bindCloseOnEsc,
  bindlCloseOnOverlay,
  setLock,
  removeLock,
} from './utils';
import {Icon} from '@youtoken/ui.icons';
import qs from 'query-string';
import {modal} from '../../../../stores';
import {cell} from '@youtoken/ui.cell';
import {Box, TouchableBox, type BoxProps} from '@youtoken/ui.primitives';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';

const style: React.CSSProperties = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  padding: '20px',
  zIndex: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  WebkitOverflowScrolling: 'touch',
};

export interface ModalProps extends BoxProps {
  shouldCloseOnEsc?: boolean /** Should modal be closed on `esc` button press */;
  shouldCloseOnOverlay?: boolean /** Should modal be closed on overlay click */;
  withCloseButton?: boolean /** Should modal have close button */;
}

export const Modal: React.FC<ModalProps> = cell(
  ({
    children,
    shouldCloseOnEsc = true,
    shouldCloseOnOverlay = true,
    withCloseButton = true,
    ...otherProps
  }) => {
    const [lockPostion] = React.useState(window.pageYOffset);

    const closeOnEsc = bindCloseOnEsc(modal.close, shouldCloseOnEsc);

    const closeOnOverlayClick = bindlCloseOnOverlay(
      modal.close,
      shouldCloseOnOverlay
    );

    const {pathname, queryParams, push} = RouterResource.use({});

    useEventListener(document, 'keyup', closeOnEsc);
    React.useEffect(() => {
      setLock(lockPostion);
      return () => {
        if (queryParams.action) {
          delete queryParams.action;
          push({
            pathname,
            search: qs.stringify(queryParams),
          });
        }
        removeLock(lockPostion);
      };
    }, []);

    const mouseDownTarget = React.useRef<EventTarget | null>(null);
    const mouseUpTarget = React.useRef<EventTarget | null>(null);

    const handleMouseDown = (event: React.MouseEvent<HTMLElement>) => {
      if (event.target) {
        mouseDownTarget.current = event.target;
      }
    };

    const handleMouseUp = (event: React.MouseEvent<HTMLElement>) => {
      mouseUpTarget.current = event.target;
    };

    const handleOverlayClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        // Ignore the events not coming from the "backdrop"
        // We don't want to close the dialog when clicking the dialog content.
        if (event.target !== event.currentTarget) {
          return;
        }

        // Make sure the event starts and ends on the same DOM element.
        if (
          event.target !== mouseDownTarget.current ||
          event.target !== mouseUpTarget.current
        ) {
          return;
        }

        mouseDownTarget.current = null;
        mouseUpTarget.current = null;

        closeOnOverlayClick(event);
      },
      []
    );

    return (
      <aside
        style={style}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleOverlayClick}
      >
        <Box
          position="relative"
          width={{
            tablet: 590,
            default: '100%',
          }}
          bg="$ui-background"
          borderRadius={8}
          m="auto"
          {...otherProps}
          //@ts-ignore TODO legacy behavior
          onClick={preventPropagation}
        >
          <>
            {children}
            {withCloseButton && (
              <TouchableBox
                position="absolute"
                top={8}
                right={8}
                zIndex={1}
                onPress={modal.close}
                testID="CLOSE_MODAL_BUTTON"
              >
                <Icon name="close" />
              </TouchableBox>
            )}
          </>
        </Box>
      </aside>
    );
  }
);
