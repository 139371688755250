import * as React from 'react';
import {TSpan} from 'react-native-svg';
import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';
import {i18n} from '@youtoken/ui.service-i18n';

export class TriggerPriceNameLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (!this.chart.triggerPrice) {
      return undefined;
    }

    const text = i18n.t('charts.trigger_price');
    const labelLayout = this.chart.layout.getLabelLayout(
      this.chart.triggerPrice,
      text,
      'left',
      this.chart.direction
    )!;

    return {
      ...labelLayout!,
      text: <TSpan>{text}</TSpan>,
    };
  }
}
