import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Content, Footer} from './elements';

export const DeleteAccountSurface: React.FC<{}> = cell(() => {
  return (
    <Box>
      <Content />
      <Footer />
    </Box>
  );
});
