import * as React from 'react';
import {Icon} from '@youtoken/ui.icons';
import {
  Box,
  Text,
  type BoxProps,
  type TColorTokens,
} from '@youtoken/ui.primitives';

type AlertType = 'information' | 'success' | 'attention' | 'warning';

export interface AlertProps {
  type?: AlertType;
}

const mapTypeToIcon = (
  type: AlertType
): React.ComponentProps<typeof Icon>['name'] => {
  switch (type) {
    case 'attention':
    case 'warning':
      return 'warning';
    case 'success':
      return 'check';
    case 'information':
    default:
      return 'question';
  }
};

const mapTypeToColor = (type: AlertType): keyof TColorTokens => {
  switch (type) {
    case 'warning':
      return '$danger-01';
    case 'attention':
      return '$attention-04';
    case 'success':
      return '$success-01';
    case 'information':
    default:
      return '$interactive-01';
  }
};

const mapTypeToBg = (type: AlertType): BoxProps['bg'] => {
  switch (type) {
    case 'warning':
      return '$danger-02';
    case 'attention':
      return '$attention-02';
    case 'success':
      return '$success-02';
    case 'information':
    default:
      return '$interactive-02';
  }
};

export const Alert: React.FC<AlertProps & BoxProps> = ({
  type = 'information',
  children,
  ...rest
}) => {
  return (
    <Box px={24} py={16} flexDirection="row" bg={mapTypeToBg(type)} {...rest}>
      <Box mr={8}>
        <Icon name={mapTypeToIcon(type)} color={mapTypeToColor(type)} />
      </Box>
      <Box flex={1} justifyContent="center">
        <Text color={mapTypeToColor(type)} variant="$body-02">
          {children}
        </Text>
      </Box>
    </Box>
  );
};
