import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';

const LANG_STORAGE_KEY = 'youhodler_lang';

export const getStoredLanguage = (): string | undefined => {
  return SIMPLE_STORAGE.get(LANG_STORAGE_KEY);
};

export const setStoredLanguage = (language: string): string => {
  try {
    SIMPLE_STORAGE.set(LANG_STORAGE_KEY, language);
  } catch {}
  return language;
};

export const removeStoreLanguage = (): void => {
  SIMPLE_STORAGE.delete(LANG_STORAGE_KEY);
};
