import {computed} from 'mobx';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {RewardsListResponse} from './RewardsListResponse';

export class RewardsListResource extends createResource({
  skipRefreshOnVisible: false,
  getKey: () => `RewardsListResource`,
  getData: () => {
    return TRANSPORT.API.get(`/v3/rewards/`).then(res => {
      return deserialize(RewardsListResponse, res.data);
    });
  },
}) {
  @computed
  public get depositReward() {
    return this.data.items.find(r => r.type === 'deposit');
  }

  @computed
  public get depositRewardPersonalAmount() {
    return this.depositReward && !this.depositReward.complete
      ? formatByTicker(this.depositReward.personalReward.usd, 'usd')
      : 0;
  }

  @computed
  public get depositRewardMinAmount() {
    return this.depositReward && !this.depositReward.complete
      ? this.depositReward.minAmountUSD || 0
      : 0;
  }
}
