import * as React from 'react';
import {Rect} from 'react-native-svg';
import {range} from 'lodash';
import {ContentLoader} from '../ContentLoader';

const buttons = range(0, 6);

export const ChartWithControlsSuspenseFallback = React.memo<{
  width: number;
  height: number;
}>(({width, height}) => {
  return (
    <ContentLoader width={width} height={height}>
      {/*  chart itself */}
      <Rect x={0} y={0} width={width} height={height - 16 - 16 - 28} />
      {/* switcher */}
      <Rect
        width={72}
        height={28}
        x={width - 72 - 20}
        y={height - 28 - 16}
        rx={3}
      />

      {/* time control buttons */}
      {buttons.map(i => (
        <Rect
          key={`button-${i}`}
          height={28}
          width={32}
          rx={3}
          y={height - 28 - 16}
          x={20 + i * 32 + i * 5}
        />
      ))}
    </ContentLoader>
  );
});
