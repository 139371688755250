import {kebabCase} from 'lodash';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

// given `idCheckOnStepInitiated` returns `id-check-on-step-initiated`
type Kebab<
  T extends string,
  A extends string = ''
> = T extends `${infer F}${infer R}`
  ? Kebab<R, `${A}${F extends Lowercase<F> ? '' : '-'}${Lowercase<F>}`>
  : A;

// given `idCheck.onStepInitiated` returns `verification.StepInitiated`
type Replace<
  T extends string,
  R extends string = 'idCheck.on',
  E extends string = 'verification'
> = T extends `${R}${infer F}` ? `${E}${F}` : never;

// given `idCheck.onStepInitiated` returns `verification-step-initiated`
type EventNameFromCheckName<T extends string> = Kebab<Replace<T>>;

export type CheckMethods =
  | 'idCheck.onStepInitiated'
  | 'idCheck.onStepCompleted'
  | 'idCheck.onApplicantStatusChanged';

export type CheckPayload = Record<string, string | number | Date | boolean>;

export const checks = [
  'idCheck.onStepInitiated',
  'idCheck.onStepCompleted',
  'idCheck.onApplicantStatusChanged',
] as CheckMethods[];

export const sumSubTrack = (
  method: 'idCheck.onInitialized' | CheckMethods,
  payload: CheckPayload
) => {
  if (checks.includes(method as CheckMethods)) {
    const eventName = kebabCase(method).replace(
      'id-check-on',
      'verification'
    ) as EventNameFromCheckName<CheckMethods>;

    DATA_LAYER.trackStrict(eventName, payload);
  }
};
