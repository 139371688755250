import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const DevicesIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 2.5H3a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h7V17H3a2 2 0 01-2-2V3a2 2 0 012-2h8a2 2 0 012 2v3c-.546 0-1.059.146-1.5.401V3a.5.5 0 00-.5-.5H9a1 1 0 01-1 1H6a1 1 0 01-1-1zm1 11.25a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75zM16 8.5h1a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V9a.5.5 0 01.5-.5h1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5zM11 9a2 2 0 012-2h4a2 2 0 012 2v8a2 2 0 01-2 2h-4a2 2 0 01-2-2V9zm3 6.75a.75.75 0 01.75-.75h.5a.75.75 0 010 1.5h-.5a.75.75 0 01-.75-.75z"
          fill={color}
        />
      </Svg>
    );
  }
);
