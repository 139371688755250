import {getDisplayUrl} from './webAppDisplayUrl';
import {AppEnv} from './AppEnv';
import {invariant} from '@youtoken/ui.utils';

export const getSocketUrl = (env: AppEnv) => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot get webAppUrl for AppEnv="${env}"`
  );

  if (env === AppEnv.localhost) {
    return `ws://localhost:8088`;
  }

  return `wss://${getDisplayUrl(env)}`;
};
