import {IFeatureTurboCreateFormResources} from '../../types';
import {getCoinName, getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {toBig} from '@youtoken/ui.formatting-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {LoanTariffsResponseItem} from '@youtoken/ui.resource-loan-tariffs';

//+++
export const handleChangeCollateralTicker = (
  value: string,
  form: any,
  resources: IFeatureTurboCreateFormResources
) => {
  const _borrowedTickerName = form.$('borrowedTicker').value;

  const collateralTickerName = value;
  const borrowedTickers = getBorrowedTickers(collateralTickerName, resources);
  const borrowedTickerValues = getBorrowedTickerGroups(
    collateralTickerName,
    resources
  );
  const borrowedTickerName = tickerToValue(
    borrowedTickers.includes(_borrowedTickerName)
      ? _borrowedTickerName
      : borrowedTickers[0],
    resources
  ).ticker;

  form.$('collateralTicker').set('value', collateralTickerName);

  const collateralAmount =
    resources.walletsResource.getByTicker(collateralTickerName)?.amount ||
    toBig(0);

  form.$('collateralAmount').set(
    'value',
    normalizeAmountByTicker(collateralAmount.toString(), collateralTickerName) // collateralTickerName.amount
  );
  form.$('borrowedTicker').set('extra', {
    values: borrowedTickerValues,
  });
  handleChangeBorrowedTicker(borrowedTickerName, form, resources);
};

//+++
export const handleChangeBorrowedTicker = (
  value: string,
  form: any,
  resources: IFeatureTurboCreateFormResources
) => {
  const _collateralTickerName = form.$('collateralTicker').value;

  const borrowedTickerName = value;
  const termValues = getTermValues(
    resources.tariffsResource,
    _collateralTickerName,
    borrowedTickerName
  );

  form.$('borrowedTicker').set('value', borrowedTickerName);
  form.$('term').set('extra', {values: termValues});
  handleChangeTerm(termValues[0], form, resources);
};

//+
export const handleChangeTerm = (
  value: any,
  form: any,
  resources: IFeatureTurboCreateFormResources
) => {
  const _collateralTickerName = form.$('collateralTicker').value;
  const _borrowedTickerName = form.$('borrowedTicker').value;
  const _leverage = toBig(form.$('leverage').value);

  const initialPrice = toBig(
    resources.ratesResource.getRate(
      _collateralTickerName.ticker,
      _borrowedTickerName
    )
  );
  const term = value;
  const termSetting = getTermSetting(term, _borrowedTickerName)!;
  const leverageMin = toBig(termSetting.turboMin);
  const leverageMax = toBig(termSetting.turboMax);
  const leverage =
    _leverage.gte(leverageMin) && _leverage.lte(leverageMax)
      ? _leverage
      : leverageMin;
  const ftpMin = toBig(termSetting.minFTP);
  const ftpMax = toBig(termSetting.maxFTP);
  const ftpDefault = toBig(termSetting.defaultFTP);

  form.$('term').set('value', term);
  form.$('leverage').set('value', Number(leverage), form, resources);
  form.$('leverage').set('extra', {
    min: Number(leverageMin),
    max: Number(leverageMax),
  });
  form.$('useFTP').set('disabled', ftpDefault.gt(0));
  if (ftpDefault.gt(0)) {
    form.$('useFTP').set('value', true);
    form
      .$('FTP')
      .set(
        'value',
        ftpDefault
          .mul(initialPrice)
          .toFixed(getCoinDecimalPrecision(_borrowedTickerName))
      );
  }
  form.$('FTP').set('extra', {
    min: Number(ftpMin),
    max: Number(ftpMax),
  });

  syncDynamicFields(form, resources);
};

//+
export const handleChangeCollateralAmount = (
  value: string,
  form: any,
  resources: IFeatureTurboCreateFormResources
) => {
  form
    .$('collateralAmount')
    .set(
      'value',
      normalizeAmountByTicker(value, form.$('collateralTicker').value)
    );

  syncDynamicFields(form, resources);
};

export const syncDynamicFields = (
  form: any,
  resources: IFeatureTurboCreateFormResources
) => {
  const _collateralTickerName = form.$('collateralTicker').value;
  const _borrowedTickerName = form.$('borrowedTicker').value;
  const _collateralAmount = form.$('collateralAmount').value;
  const _term = form.$('term').value;

  const initialPrice = toBig(
    resources.ratesResource.getRate(_collateralTickerName, _borrowedTickerName)
  );
  const borrowedAmount = initialPrice
    .mul(toBig(_collateralAmount))
    .mul(toBig(_term.ltv))
    .round(getCoinDecimalPrecision(_borrowedTickerName));

  form.$('initialPrice').set('value', initialPrice.toString());
  form.$('borrowedAmount').set('value', borrowedAmount.toString());
};

//+
export const handleChangeFTP = (
  value: string,
  form: any,
  _resources: IFeatureTurboCreateFormResources
) => {
  form
    .$('FTP')
    .set(
      'value',
      normalizeAmountByTicker(value, form.$('borrowedTicker').value)
    );
};

//

export const getBorrowedTickers = (
  collateralTicker: string,
  resources: IFeatureTurboCreateFormResources
) => {
  return resources.walletsResource.loanEnabledTickers.filter(
    (ticker: string) => {
      return (
        resources.tariffsResource.getTurboMarketsWithWhiteList(
          resources.walletsResource.loanEnabledTickers
        )[collateralTicker] || []
      ).includes(ticker);
    }
  );
};

export const getCollateralTickerGroups = (
  resources: IFeatureTurboCreateFormResources
) => {
  const turboMarketsTickers =
    resources.walletsResource.loanEnabledTickers.filter((ticker: string) => {
      return Object.keys(
        resources.tariffsResource.getTurboMarketsWithWhiteList(
          resources.walletsResource.loanEnabledTickers
        )
      ).includes(ticker);
    });

  const portfolioTickers = turboMarketsTickers.filter((ticker: string) => {
    const wallet = resources.walletsResource.getByTicker(ticker)!;

    return toBig(wallet.amount).gt(0);
  });

  const coinsTickers = turboMarketsTickers.filter((ticker: string) => {
    return !portfolioTickers.includes(ticker);
  });

  let values = [];
  if (portfolioTickers.length) {
    values.push({
      name: 'Your Portfolio',
      values: portfolioTickers.map((ticker: string) =>
        tickerToValue(ticker, resources)
      ),
    });
  }
  if (coinsTickers.length) {
    values.push({
      name: 'Coins',
      values: coinsTickers.map((ticker: string) =>
        tickerToValue(ticker, resources)
      ),
    });
  }

  return values;
};

export const getBorrowedTickerGroups = (
  collateralTicker: string,
  resources: IFeatureTurboCreateFormResources
) => {
  return [
    {
      name: 'Coins',
      values: getBorrowedTickers(collateralTicker, resources).map(
        (ticker: string) => tickerToValue(ticker, resources)
      ),
    },
  ];
};

export const getTermValues = (
  tariffsResource: IFeatureTurboCreateFormResources['tariffsResource'],
  collateralTicker: string,
  borrowedTicker: string
) => {
  return tariffsResource.turboTerms.filter(term => {
    return (
      term.collateralTicker === collateralTicker &&
      getTermSetting(term, borrowedTicker)
    );
  });
};

export const getTermSetting = (
  term: LoanTariffsResponseItem,
  borrowedTicker: string
) => {
  return term.settings.find(
    (setting: any) => setting.borrowedTicker === borrowedTicker
  );
};

export const tickerToValue = (
  _ticker: string,
  resources: IFeatureTurboCreateFormResources
) => {
  const {ticker, amount, tickerFormatted, amountFormatted} =
    resources.walletsResource.getByTicker(_ticker) || {
      ticker: _ticker,
      amount: toBig(0),
      tickerFormatted: _ticker.toUpperCase(),
      amountFormatted: '0',
    };

  const name = getCoinName(ticker);

  return {
    ticker,
    tickerFormatted,
    amountFormatted,
    coinName: name,
    hasAmount: amount.gt(0),
    key: ticker,
    keywords: `${ticker} ${name}`,
  };
};
