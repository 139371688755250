import {StaticResourceDescription} from '../types';
import {wrapResourceGetKey} from './wrapResourceKey';
import {wrapMergeArgs} from './wrapMergeArgs';
import {wrapGetData} from './wrapGetData';

export {wrapGetData, wrapMergeArgs, wrapResourceGetKey};

export const wrapDescriptor = <Args extends {}, Result extends any>(
  descriptor: StaticResourceDescription<Args, Result>
) => {
  const getKey = wrapResourceGetKey(descriptor.getKey);
  const mergeArgs = wrapMergeArgs(descriptor.defaultArgs ?? {});
  const getData = wrapGetData(descriptor.getData, getKey);
  const create = wrapGetData(descriptor.create ?? descriptor.getData, getKey);

  return {
    getKey,
    mergeArgs,
    getData,
    create,
  };
};
