import * as React from 'react';
import {DevelopmentInfoTrigger} from './Trigger';
import {DevelopmentInfoComponent} from './Content';

type Props = {
  error: Error;
  reportingResult?: {eventId: string};
};

export const DevelopmentInfo: React.FC<Props> = React.memo(
  ({error, reportingResult}) => {
    const [errorInfoShown, setErrorInfoShown] = React.useState(false);

    const toggleErrorInfo = React.useCallback(() => {
      setErrorInfoShown(v => !v);
    }, []);

    return (
      <>
        {errorInfoShown && (
          <DevelopmentInfoComponent
            error={error}
            reportingResult={reportingResult}
          />
        )}

        <DevelopmentInfoTrigger
          open={errorInfoShown}
          onPress={toggleErrorInfo}
        />
      </>
    );
  }
);
