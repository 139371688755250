import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Heading} from '@youtoken/ui.primitives';
import {IconPaymentMethod} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const WeAccept: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();

  return (
    <Box {...props}>
      <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
        {t('surface.more.legal.subtitle.accept')}
      </Heading>
      <Box flexDirection="row">
        <Box mr={10}>
          {/* NOTE: visa icon requires a non-standard color */}
          {/* @ts-ignore */}
          <IconPaymentMethod name="visa" color="#0F2499" />
        </Box>
        <Box mr={10}>
          <IconPaymentMethod name="mastercard" />
        </Box>
        <Box mr={10}>
          <IconPaymentMethod name="maestro" />
        </Box>
      </Box>
    </Box>
  );
});
