import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {AgreementsList, Switch} from '@youtoken/ui.elements';
import {useForm, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {SavingsSignAgreementsFormState} from '../state';

export const SavingsSignAgreementsFormAgree: React.FC<BoxProps> = observer(
  boxProps => {
    const {
      form: {agreements, agree, agreeHasError, agreeError, agreeOnChange},
    } = useForm<SavingsSignAgreementsFormState>();

    return (
      <Box mb={16} {...boxProps}>
        <Box flexDirection="row">
          <Box flexGrow={1} flexShrink={1} mr={20}>
            <AgreementsList
              items={agreements}
              textProps={{
                testID: 'SAVINGS_SIGN_AGREEMENTS_FORM_AGREEMENTS_LIST',
              }}
            />
          </Box>
          <Box>
            <Switch
              testID="SAVINGS_SIGN_AGREEMENTS_FORM_AGREE_SWITCH"
              value={agree}
              onValueChange={agreeOnChange}
              hasError={agreeHasError}
            />
          </Box>
        </Box>
        <FieldErrorMessage visible={agreeHasError}>
          {agreeError}
        </FieldErrorMessage>
      </Box>
    );
  }
);
