import {list, object, primitive, serializable} from 'serializr';

export class LoanTariffsResponseItemSettingItem {
  @serializable(primitive())
  borrowedTicker!: string;

  @serializable(primitive())
  defaultFTP?: number;

  @serializable(primitive())
  maxAmount?: number;

  @serializable(primitive())
  maxFTP!: number;

  @serializable(primitive())
  minAmount?: number;

  @serializable(primitive())
  minFTP!: number;

  @serializable(primitive())
  mode?: 'regular' | 'turbo';

  @serializable(primitive())
  turboMax?: number;

  @serializable(primitive())
  turboMin?: number;
}

export class LoanTariffsResponseItem {
  @serializable(primitive())
  apr?: number;

  @serializable(primitive())
  collateralTicker!: string;

  @serializable(primitive())
  days!: number;

  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  ltv!: number;

  @serializable(primitive())
  marginCall?: number;

  @serializable(list(object(LoanTariffsResponseItemSettingItem)))
  settings: LoanTariffsResponseItemSettingItem[] = [];

  @serializable(primitive())
  type?: string;

  @serializable(primitive())
  company?: 'NAUMARD' | 'YOUHODLER';
}
