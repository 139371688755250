import * as React from 'react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {type BoxProps, TouchableBox, Box} from '@youtoken/ui.primitives';
import {SavingsWidgetLayoutProps} from './types';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('WalletsSavings');
};

export const SavingsWidgetLayout: React.FC<
  SavingsWidgetLayoutProps & BoxProps
> = ({variant = 'primary', primaryContent, secondaryContent, ...boxProps}) => {
  return (
    <TouchableBox
      testID="SAVINGS_WIDGET"
      onPress={handlePress}
      flexDirection="row"
      justifyContent="space-between"
      backgroundColor={
        variant === 'primary' ? '$interactive-01' : '$interactive-02'
      }
      borderRadius={12}
      {...boxProps}
    >
      <Box flex={1} p={16}>
        {primaryContent}
      </Box>
      {Boolean(secondaryContent) && (
        <Box
          flexDirection="row"
          width={140}
          p={16}
          borderLeftWidth={1}
          borderColor="$ui-background"
        >
          {secondaryContent}
        </Box>
      )}
    </TouchableBox>
  );
};
