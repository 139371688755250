import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {CryptoWithdrawalAddressScanQRCode} from './AddressScanQRCode';
import {CryptoWithdrawalFormState} from '../types';

export interface CryptoWithdrawalAddressProps {
  disabled?: boolean;
}

export const CryptoWithdrawalAddress: React.FC<CryptoWithdrawalAddressProps> =
  observer(({disabled}) => {
    const isMobile = useIsMobile();

    const {
      form: {
        addressLabel,
        addressPlaceholder,
        address,
        addressHasError,
        addressError,
        addressOnChange,
      },
    } = useForm<CryptoWithdrawalFormState>();

    return (
      <Box mt={16}>
        <Label mb={8}>{addressLabel}</Label>
        <TextInput
          size="large"
          placeholder={addressPlaceholder}
          value={address}
          onChangeText={addressOnChange}
          disabled={disabled}
          RightPartComponent={
            disabled ? null : CryptoWithdrawalAddressScanQRCodeNode
          }
          autoCorrect={false}
          spellCheck={false}
          multiline={isMobile}
          testID="ADDRESS_INPUT"
        />
        <FieldErrorMessage visible={addressHasError}>
          {addressError}
        </FieldErrorMessage>
      </Box>
    );
  });

const CryptoWithdrawalAddressScanQRCodeNode = Platform.select({
  native: <CryptoWithdrawalAddressScanQRCode />,
  web: null,
})!;
