import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

type InstrumentActionsSectionProps = {
  instrumentId: string;
};

export const InstrumentActionsSection: React.FC<
  InstrumentActionsSectionProps & BoxProps
> = observer(({instrumentId, ...boxProps}) => {
  const {t} = useTranslation();
  const {getInstrumentById, getAvailableInstrumentTariffs} =
    HODLsTariffsFeature.use({});

  const {baseTicker, quoteTicker, itIsIndex} =
    getInstrumentById(instrumentId) ?? {};
  const {sellTariffAvailable, buyTariffAvailable} =
    getAvailableInstrumentTariffs(baseTicker, quoteTicker);

  const handlePressSell = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate(
      'NewMultiHODL',
      {},
      {
        direction: 'sell',
        baseTicker,
        quoteTicker,
      }
    );
  }, [baseTicker, quoteTicker]);

  const handlePressBuy = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate(
      'NewMultiHODL',
      {},
      {
        direction: 'buy',
        baseTicker,
        quoteTicker,
      }
    );
  }, [baseTicker, quoteTicker]);

  if (itIsIndex) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      pt={16}
      pb={24}
      px={24}
      {...boxProps}
    >
      <Box mr={8} flexBasis={0} flexGrow={1}>
        <Button
          onPress={handlePressSell}
          size="large"
          color="danger"
          icon="down"
          disabled={!sellTariffAvailable}
          testID="SELL_ACTION_BUTTON"
        >
          {t('surface.hodls.instruments.direction.down')}
        </Button>
      </Box>
      <Box ml={8} flexBasis={0} flexGrow={1}>
        <Button
          onPress={handlePressBuy}
          size="large"
          color="success"
          icon="up"
          disabled={!buyTariffAvailable}
          testID="BUY_ACTION_BUTTON"
        >
          {t('surface.hodls.instruments.direction.up')}
        </Button>
      </Box>
    </Box>
  );
});
