import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import type {BoxProps} from '@youtoken/ui.primitives';
import type {HODLItem} from '@youtoken/ui.resource-hodl';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {InformationTableRightElement} from '@youtoken/ui.hodl-elements';

interface DataTableSectionProps extends BoxProps {
  item: HODLItem;
}

export const DataTableSection: React.FC<DataTableSectionProps> = observer(
  ({item, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <InformationTable {...boxProps}>
        <InformationTableRow
          left={t('surface.hodls.close_now.initial_amount')}
          right={
            <InformationTableRightElement
              mainText={item.inputAmountFormatted}
              mainTextId="HODL_CLOSE_TABLE_ROW_INITIAL_AMOUNT_VALUE"
              additionalText={item.inputTickerFormatted}
              additionalTextId="HODL_CLOSE_TABLE_ROW_INITIAL_AMOUNT_TICKER"
            />
          }
          testID="HODL_CLOSE_TABLE_ROW_INITIAL_AMOUNT"
          mb={8}
        />

        {item.tableRowStatus.additionalInitialAmountIsActive && (
          <InformationTableRow
            left={t('surface.hodls.close_now.initial_bonus_amount')}
            leftTooltipText={t(
              'surface.hodls.close_now.initial_bonus_amount.tooltip'
            )}
            right={
              <InformationTableRightElement
                mainText={item.additionalInputAmountFormatted}
                mainTextId="HODL_CLOSE_TABLE_ROW_INITIAL_BONUS_AMOUNT_VALUE"
                additionalText={item.additionalInputTickerUI}
                additionalTextId="HODL_CLOSE_TABLE_ROW_INITIAL_BONUS_AMOUNT_TICKER"
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_INITIAL_BONUS_AMOUNT"
            mb={8}
          />
        )}

        {item.tableRowStatus.additionalOutputAmountIsActive && (
          <InformationTableRow
            left={t('surface.hodls.close_now.returned_bonuses')}
            leftTooltipText={t(
              'surface.hodls.close_now.returned_bonuses.tooltip'
            )}
            tooltipOffset={20}
            right={
              <InformationTableRightElement
                mainText={item.additionalOutputAmountFormatted!}
                mainTextId="HODL_CLOSE_TABLE_ROW_RETURNED_BONUSES_AMOUNT_VALUE"
                additionalText={item.additionalInputTickerUI}
                additionalTextId="HODL_CLOSE_TABLE_ROW_RETURNED_BONUSES_TICKER"
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_RETURNED_BONUSES_AMOUNT"
            mb={8}
          />
        )}

        <InformationTableRow
          left={t(`surface.hodls.close_now.${item.calculateProfitLossText}`)}
          leftTooltipText={t('surface.hodls.close_now.profit_loss_toolip')}
          tooltipOffset={106}
          right={
            <InformationTableRightElement
              mainText={`${item.calculateProfit.gt(0) ? '+' : ''}${
                item.calculateProfitLossAmount
              }`}
              mainTextId="HODL_CLOSE_TABLE_ROW_PROFIT_VALUE"
              additionalText={item.outputTickerUpperCase}
              additionalTextId="HODL_CLOSE_TABLE_ROW_PROFIT_TICKER"
            />
          }
          testID="HODL_CLOSE_TABLE_ROW_PROFIT"
          mb={8}
        />

        {item.tableRowStatus.legacyFeeIsActive && (
          <InformationTableRow
            left={t('surface.hodls.close_now.legacy_fee')}
            right={
              <InformationTableRightElement
                preInfoText={`${item.closeFeePercent}%, ${item.settlementPeriod}`}
                preInfoTextId="HODL_CLOSE_TABLE_ROW_LEGACY_FEE_PERCENT"
                mainText={item.legacyFeeAmount}
                mainTextId="HODL_CLOSE_TABLE_ROW_LEGACY_FEE_VALUE"
                additionalText={item.outputTickerUpperCase}
                additionalTextId="HODL_CLOSE_TABLE_ROW_LEGACY_FEE_TICKER"
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_LEGACY_FEE"
            mb={8}
          />
        )}

        {item.tableRowStatus.originationIsActive && (
          <InformationTableRow
            left={t('surface.hodls.close_now.origination_fee')}
            right={
              <InformationTableRightElement
                preInfoText={`${item.openingFeePercent}%`}
                preInfoTextId="HODL_CLOSE_TABLE_ROW_ORIGINATION_FEE_PERCENT"
                mainText={`≈ ${item.openingFeeAmount}`}
                mainTextId="HODL_CLOSE_TABLE_ROW_ORIGINATION_FEE_VALUE"
                additionalText={item.borrowedTickerUpperCase}
                additionalTextId="HODL_CLOSE_TABLE_ROW_ORIGINATION_FEE_TICKER"
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_ORIGINATION_FEE"
            mb={8}
          />
        )}

        {item.tableRowStatus.settlementIsActive && (
          <>
            <InformationTableRow
              left={t('surface.hodls.item.details_table.rollover_fee')}
              leftTooltipText={t(
                'surface.hodls.item.details_table.rollover_fee_tooltip'
              )}
              tooltipOffset={84}
              right={
                <InformationTableRightElement
                  mainText={item.settlementFeeAmountPerPeriod}
                  mainTextId="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_VALUE"
                  additionalText={item.borrowedTickerUpperCase}
                  additionalTextId="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_TICKER"
                  postInfoText={`(${item.settlementFeePercent}%) ${t(
                    'surface.hodls.common.per_period',
                    {period: item.settlementPeriod}
                  )}`}
                  postInfoTextId="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_PERCENT"
                />
              }
              testID="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE"
              mb={8}
            />
            <InformationTableRow
              left={t('surface.hodls.item.details_table.rollover_fee_total')}
              right={
                <InformationTableRightElement
                  mainText={item.settlementFeeAmount}
                  mainTextId="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_TOTAL_VALUE"
                  additionalText={item.borrowedTickerUpperCase}
                  additionalTextId="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_TOTAL_TICKER"
                  postInfoText={item.settlementFeeIterationsDaysAndHours}
                  postInfoTextId="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_TOTAL_INFO"
                />
              }
              testID="HODL_CLOSE_TABLE_ROW_ROLLOVER_FEE_TOTAL"
              mb={8}
            />
          </>
        )}

        {item.tableRowStatus.conversionPLIsActive && (
          <InformationTableRow
            left={t('surface.hodls.item.details_table.conversion_pl')}
            leftTooltipText={t(
              'surface.hodls.item.details_table.conversion_pl_tooltip'
            )}
            tooltipOffset={50}
            right={
              <InformationTableRightElement
                mainText={item.conversionPLAmount!}
                additionalText={item.inputTickerFormatted}
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_CONVERTION_PL"
            mb={8}
          />
        )}

        {item.tableRowStatus.revShareIsActive && (
          <InformationTableRow
            left={t('surface.hodls.close_now.rev_share_fee')}
            right={
              <InformationTableRightElement
                preInfoText={`${item.revShareFeePercent}%`}
                preInfoTextId="HODL_CLOSE_TABLE_ROW_REV_SHARE_FEE_PERCENT"
                mainText={`≈ ${item.revShareFeeAmount}`}
                mainTextId="HODL_CLOSE_TABLE_ROW_REV_SHARE_FEE_VALUE"
                additionalText={item.outputTickerUpperCase}
                additionalTextId="HODL_CLOSE_TABLE_ROW_REV_SHARE_FEE_TICKER"
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_REV_SHARE_FEE"
            mb={8}
          />
        )}

        {item.tableRowStatus.unifiedFeeIsActive && (
          <InformationTableRow
            left={t('surface.hodls.close_now.unified_fee')}
            leftTooltipText={t('surface.hodls.close_now.unified_fee_tooltip')}
            right={
              <InformationTableRightElement
                preInfoText={`${item.unifiedFeePercent}%`}
                preInfoTextId="HODL_CLOSE_TABLE_ROW_UNIFIED_FEE_PERCENT"
                mainText={`≈ ${item.unifiedFeeAmount}`}
                mainTextId="HODL_CLOSE_TABLE_ROW_UNIFIED_FEE_VALUE"
                additionalText={item.borrowedTickerUpperCase}
                additionalTextId="HODL_CLOSE_TABLE_ROW_UNIFIED_FEE_TICKER"
              />
            }
            testID="HODL_CLOSE_TABLE_ROW_UNIFIED_FEE"
            mb={8}
          />
        )}
      </InformationTable>
    );
  }
);
