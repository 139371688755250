import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {MenuItemBase} from './MenuItemBase';

export const Email: React.FC<BoxProps> = observer(boxProps => {
  const {userEmail, emailResult} = AuthMeResource.use({});
  const emailVerified = emailResult === 'ACCEPT';

  const {t} = useTranslation();

  const badgeText: string = t(
    emailVerified
      ? 'surface.profile.verification.item.badge.verified'
      : 'surface.profile.verification.item.badge.not_verified'
  );

  return (
    <MenuItemBase
      icon="email"
      title={t('surface.more.verification_tier.email')}
      subtitle={userEmail}
      alwaysShowSubtitle
      borderTopLeftRadius={{
        default: 0,
        tablet: 10,
      }}
      borderTopRightRadius={{
        default: 0,
        desktop: 10,
      }}
      badgeText={badgeText.toUpperCase()}
      badgeVariant={emailVerified ? 'success' : 'danger'}
      {...boxProps}
    />
  );
});
