import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanSequenceItem} from '../components';
import {ILoanSequenceItem} from '@youtoken/ui.hodls-utils';

export const LoanSequence: React.FC<{
  loanSequence: ILoanSequenceItem[];
}> = observer(({loanSequence}) => {
  return (
    <>
      {loanSequence.map((loanSequenceItem, index) => {
        const first = index === 0;
        const last = index + 1 === loanSequence.length;
        return (
          <LoanSequenceItem
            key={`${loanSequenceItem.collateralAmountFormatted}-${loanSequenceItem.borrowedAmountFormatted}`}
            loanSequenceItem={loanSequenceItem}
            testNumber={index + 1}
            first={first}
            last={last}
          />
        );
      })}
    </>
  );
});
