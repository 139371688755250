import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const useTwoFaAction = (twoFactorAuthType: string) => {
  const {t} = useTranslation();

  const {
    data: {phoneMask},
  } = AuthMeResource.use({});

  const text =
    {
      ga: t('surface.profile.security.2fa.info.app'),
      sms: t('surface.profile.security.2fa.info.sms', {phone: phoneMask}),
    }[twoFactorAuthType] || '';

  const buttonText =
    twoFactorAuthType === 'email'
      ? t('surface.profile.security.2fa.action.app')
      : t('surface.profile.security.2fa.action.disable');

  const handlePress = {
    ga: () =>
      SHARED_ROUTER_SERVICE.navigate('TwoFactorAuthenticationGADisable'),
    sms: () =>
      SHARED_ROUTER_SERVICE.navigate('TwoFactorAuthenticationSMSDisable'),
    email: () =>
      SHARED_ROUTER_SERVICE.navigate('TwoFactorAuthenticationGAEnable'),
  }[twoFactorAuthType];

  const testID = {
    ga: '2FA_GA_DISABLE',
    sms: '2FA_SMS_DISABLE',
    email: '2FA_GA_ENABLE',
  }[twoFactorAuthType];

  return {
    text,
    buttonText,
    handlePress,
    testID,
  };
};
