import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {formatPercent} from '@youtoken/ui.formatting-utils';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  SavingsOverviewStatus,
  SavingsOverviewResource,
} from '@youtoken/ui.resource-savings';
import {Text, TouchableText} from '@youtoken/ui.primitives';
import {CurvedLine} from '../CurvedLine';
import {
  useAccountVerificationChecker,
  VerificationItemAccountCode,
} from '@youtoken/ui.resource-verification';
import {WalletsItemFeature} from '../../../../../../features/WalletsItemFeature';

interface WalletsItemGeneralCurvedLineAprProps {
  ticker: string;
}

const savingsVerifications = [
  VerificationItemAccountCode.FORM_A,
  VerificationItemAccountCode.IDENTITY,
];

const signSavings = () => {
  SHARED_ROUTER_SERVICE.navigate('SignSavingsModal');
};

export const WalletsItemGeneralCurvedLineApr: React.FC<WalletsItemGeneralCurvedLineAprProps> =
  observer(({ticker}) => {
    const {savingVersion, savings4Active} = AuthMeResource.use({
      ticker,
    });

    if (savingVersion === 4) {
      // NOTE: BLOCK REQUEST TO OVERVIEW, IF AGREEMENT DIDN'T SIGN
      if (savings4Active) {
        return <WalletsItemGeneralCurvedLineAprV4 ticker={ticker} />;
      }

      return null;
    }

    return <WalletsItemGeneralCurvedLineAprLegacy ticker={ticker} />;
  });

export const WalletsItemGeneralCurvedLineAprV4: React.FC<WalletsItemGeneralCurvedLineAprProps> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {
      data: {status, tickers, getEarnedByTicker},
    } = SavingsOverviewResource.use({});

    const saving = tickers?.[ticker];

    if (status !== SavingsOverviewStatus.OPEN || !saving) {
      return null;
    }

    const {apr} = saving;
    const {earnedInBaseTickerFormatted} = getEarnedByTicker(ticker);

    return (
      <CurvedLine>
        <Text variant="$body-02" color="$interactive-01">
          {t('surface.wallets.wallet_detail.earned_at', {
            amount: earnedInBaseTickerFormatted,
            ticker: ticker.toUpperCase(),
            apr: formatPercent(apr),
          })}
        </Text>
      </CurvedLine>
    );
  });

export const WalletsItemGeneralCurvedLineAprLegacy: React.FC<WalletsItemGeneralCurvedLineAprProps> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const {
      hasApr,
      hasSavings,
      aprFormatted,
      tickerFormatted,
      earnedFormatted,
      shouldSignSavings,
    } = WalletsItemFeature.use({
      ticker,
    });

    const handlePressSignSavings = useAccountVerificationChecker(
      savingsVerifications,
      signSavings
    );

    if (!hasApr) {
      return null;
    }

    return (
      <>
        {(shouldSignSavings || hasSavings) && (
          <CurvedLine>
            {shouldSignSavings && (
              <TouchableText
                variant="$body-02"
                color="$interactive-01"
                onPress={handlePressSignSavings as () => void | Promise<void>}
              >
                {t('surface.wallets.wallet_detail.sign_agreement', {
                  ticker: tickerFormatted,
                  apr: aprFormatted,
                })}
              </TouchableText>
            )}
            {hasSavings && (
              <Text variant="$body-02" color="$interactive-01">
                {t('surface.wallets.wallet_detail.earned_at', {
                  amount: earnedFormatted,
                  ticker: tickerFormatted,
                  apr: aprFormatted,
                })}
              </Text>
            )}
          </CurvedLine>
        )}
      </>
    );
  });
