import {custom} from 'serializr';

export const number = () =>
  custom(
    (value: number) => {
      return value;
    },
    (jsonValue: any) => {
      if (typeof jsonValue === 'number') {
        return jsonValue;
      }

      return Number(jsonValue);
    }
  );
