import * as React from 'react';
import {type StyleProp, type ViewStyle} from 'react-native';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';

export const CoinsPair: React.FC<
  {
    firstTicker: DualAssetCoinProps['iconName'];
    secondTicker: DualAssetCoinProps['iconName'];
    iconSize?: DualAssetCoinProps['iconSize'];
    borderOffset?: DualAssetCoinProps['offset'];
    iconOffset?: number;
  } & BoxProps
> = ({
  firstTicker,
  secondTicker,
  iconSize = 32,
  borderOffset = 3,
  iconOffset = 24,
  ...boxProps
}) => {
  const firstTickerOffsetStyle: ViewStyle = {
    transform: [
      {
        translateX: -iconSize - borderOffset * 3,
      },
      {
        translateY: -borderOffset,
      },
    ],
  };

  const secondTickerOffsetStyle: ViewStyle = {
    transform: [
      {
        translateX: iconOffset - borderOffset,
      },
      {
        translateY: -borderOffset,
      },
    ],
  };

  const size = iconSize + borderOffset * 2;

  return (
    <Box
      flexDirection="row"
      height={iconSize}
      width={iconSize + iconOffset}
      {...boxProps}
    >
      <Coin
        size={size}
        iconSize={iconSize}
        iconName={secondTicker}
        offset={borderOffset}
        style={secondTickerOffsetStyle}
      />
      <Coin
        size={size}
        iconSize={iconSize}
        iconName={firstTicker}
        offset={borderOffset}
        style={firstTickerOffsetStyle}
      />
    </Box>
  );
};

interface DualAssetCoinProps {
  size: number;
  offset: number;
  iconSize: number;
  iconName: LogoColoredIconName;
  style: StyleProp<ViewStyle>;
}

const Coin: React.FC<DualAssetCoinProps> = ({
  size,
  offset,
  iconName,
  iconSize,
  style,
}) => {
  return (
    <Box
      width={size}
      height={size}
      borderWidth={offset}
      borderColor="$ui-background"
      backgroundColor="$ui-background"
      borderRadius={size}
      style={style}
    >
      <LogoColored name={iconName} size={iconSize} />
    </Box>
  );
};
