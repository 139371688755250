import * as React from 'react';
import {Box, Text, TextStyleContext} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {
  useBadgeProps,
  useIconProps,
  useIconWrapperProps,
  useTextProps,
} from './styles';
import {BadgeProps} from './types';

export const Badge: React.FC<BadgeProps> = ({
  variant = 'interactive',
  size = 'medium',
  type = 'primary',
  icon,
  children,
  ...boxProps
}) => {
  const badgeProps = useBadgeProps(size, type, variant);
  const iconProps = useIconProps(size, type, variant);
  const iconWrapperProps = useIconWrapperProps(size);
  const textProps = useTextProps(size, type, variant);

  return (
    <Box
      borderRadius={4}
      justifyContent={'center'}
      alignItems="center"
      {...badgeProps}
      {...boxProps}
    >
      <TextStyleContext.Provider value={textProps}>
        <Box flexDirection="row" justifyContent="center" alignItems="center">
          {icon && (
            <Box {...iconWrapperProps}>
              <Icon name={icon} {...iconProps} />
            </Box>
          )}
          {typeof children === 'string' || typeof children === 'number' ? (
            <Text>{children}</Text>
          ) : (
            <>{children}</>
          )}
        </Box>
      </TextStyleContext.Provider>
    </Box>
  );
};
