import * as React from 'react';
import {observer} from 'mobx-react';
import {SavingsOverviewStatus} from '@youtoken/ui.resource-savings';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SavingsSelectedAssetsFeature} from '../../Feature';
import {SavingsStartNextPeriodButton} from '../../../../components';
import {SavingsSelectedAssetsActionWaiting} from './Waiting';
import {SavingsSelectedAssetsActionClaim} from './Claim';

export const SavingsSelectedAssetsAction: React.FC<BoxProps> = observer(
  boxProps => {
    const {status} = SavingsSelectedAssetsFeature.use({});

    return (
      <Box
        px={{default: 16, phone: 24, desktop: 0}}
        py={{default: 16, phone: 24}}
        {...boxProps}
      >
        {status === SavingsOverviewStatus.OPEN && (
          <SavingsSelectedAssetsActionWaiting />
        )}
        {status === SavingsOverviewStatus.READY && (
          <SavingsSelectedAssetsActionClaim />
        )}
        {status === SavingsOverviewStatus.CLAIMED && (
          <SavingsStartNextPeriodButton />
        )}
      </Box>
    );
  }
);
