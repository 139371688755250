import {reduce, set, toPairs, get} from 'lodash';

const keysMap = {
  templateId: 'id',
  accountType: 'accountType',
  ticker: 'ticker',
  amount: 'amount',
  beneficiaryName: 'beneficiary.name',
  iban: 'iban',
  provider: 'provider',
  bankName: 'bank.name',
  swiftCode: 'swiftCode',
  bankCountry: 'bank.country',
  bankCity: 'bank.city',
  bankStreetAddress: 'bank.streetAddress',
  bankZipCode: 'bank.zipCode',
  beneficiaryAddressCountry: 'beneficiary.address.country',
  beneficiaryAddressCity: 'beneficiary.address.city',
  beneficiaryAddressStreetAddress: 'beneficiary.address.streetAddress',
  beneficiaryAddressZipCode: 'beneficiary.address.zipCode',
  paymentReference: 'paymentReference',
  twoFactorAuthCode: 'code',
  _error: '_error',
};

export const convertData = (
  data: Object,
  direction: 'toBackend' | 'toFrontend' = 'toBackend'
) => {
  return reduce(
    toPairs(keysMap),
    (acc, [frontKey, backendKey]) => {
      const sourceKey = direction === 'toBackend' ? frontKey : backendKey;
      const destinationKey = direction === 'toBackend' ? backendKey : frontKey;

      const value = get(data, sourceKey, '');
      if (!value && direction === 'toBackend') {
        return acc;
      }

      return set(acc, destinationKey, value);
    },
    {}
  );
};
