import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const HexagonSparkIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M4.407 4.507l4-2.25a3.25 3.25 0 013.186 0l4 2.25a3.25 3.25 0 011.657 2.832v5.322a3.25 3.25 0 01-1.657 2.832l-4 2.25a3.25 3.25 0 01-3.186 0l-4-2.25a3.25 3.25 0 01-1.657-2.832V7.339a3.25 3.25 0 011.657-2.832z"
          stroke={color}
          strokeWidth={1.5}
          strokeLinejoin="round"
        />
        <Path
          d="M9.078 15.5a.406.406 0 01-.4-.474l.521-3.067a.5.5 0 00-.493-.584H6.843c-.175 0-.283-.046-.324-.137-.041-.092-.015-.218.079-.379l3.984-6.173a.406.406 0 01.74.288l-.521 3.067a.5.5 0 00.493.584h1.863c.175 0 .283.046.324.137.041.092.015.218-.078.379l-3.985 6.173a.406.406 0 01-.34.186z"
          fill={color}
        />
      </Svg>
    );
  }
);
