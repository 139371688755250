import * as React from 'react';
import {Platform} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import {TouchableText} from '@youtoken/ui.primitives';
import {getLinkParams} from './utils';

/** TransLink
 * For usage with constructions like <TransLink>[text](url)</TransLink> in text of Trans component
 */
export const TransLink: React.FC<React.ComponentProps<typeof TouchableText>> =
  React.memo(({children, ...touchableTextProps}) => {
    const {text, url} = getLinkParams(children);

    if (!text || !url) {
      return null;
    }

    const handlePressLink = React.useCallback(() => {
      Platform.select({
        web: () => {
          window.open(url, '_blank', 'noopener,noreferrer');
        },
        native: () => {
          WebBrowser.openBrowserAsync(url);
        },
      })!();
    }, [url]);

    return (
      <TouchableText
        mb={25}
        color="$interactive-01"
        onPress={handlePressLink}
        variant="$body-01"
        {...touchableTextProps}
      >
        {text}
      </TouchableText>
    );
  });
