import * as React from 'react';
import Axios from 'axios';
import {observer} from 'mobx-react';
import io, {Socket} from 'socket.io-client';
import {identity} from 'lodash';
import {GLOBAL} from '@youtoken/ui.service-global';
import {handleUnauthorizedError} from '@youtoken/ui.resource-auth-me';
import {DataSourcesProvider as YouHodlerDataSourcesProvider} from '@youtoken/ui.legacy-components';
import {enhanceErrorInterceptor} from '@youtoken/ui.errors';
import {getDeviceID} from '@youtoken/ui.security-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {handleMaintenance} from '@web-app/src/utils';

const useRestApiClientWithoutVersion = () => {
  return React.useMemo(() => {
    const instance = Axios.create({
      baseURL: ENVIRONMENT.BACKEND_URL,
      headers: {
        ['x-device-uuid']: getDeviceID(),
        ['x-device-type']: 'web',
        ['x-use-i18n-errors']: true,
        ['x-web-app-env']: ENVIRONMENT.WEB_APP_ENV,
      },
    });

    instance.interceptors.request.use(
      config => {
        if (GLOBAL.token) {
          config.headers['Authorization'] = GLOBAL.token;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(...enhanceErrorInterceptor);

    instance.interceptors.response.use(identity, error => {
      handleMaintenance(error);

      if (
        error &&
        error.config &&
        (error.config.url.includes('signout') ||
          error.config.url.includes('signin'))
      ) {
        throw error;
      }

      handleUnauthorizedError(error);

      throw error;
    });

    return instance;
  }, [ENVIRONMENT.BACKEND_URL, ENVIRONMENT.WEB_APP_ENV]);
};

let latestSocket: Socket | null = null;

const useSocketClient = (token: string | null) => {
  return React.useMemo(() => {
    if (latestSocket) {
      latestSocket.disconnect();
    }

    latestSocket = io(ENVIRONMENT.SOCKET_URL, {
      transports: ['websocket'],
      autoConnect: true,
      reconnectionDelayMax: 5000,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
      reconnection: true,
      path: '/ws/',
      auth: token
        ? {
            authorization: token,
          }
        : undefined,
    });

    return latestSocket;
  }, [ENVIRONMENT.SOCKET_URL, token]);
};

export const DataSourcesProvider: React.FC = observer(({children}) => {
  const apiClient = useRestApiClientWithoutVersion();
  const socketClient = useSocketClient(GLOBAL.token);

  return (
    <YouHodlerDataSourcesProvider api={apiClient} socket={socketClient}>
      {children}
    </YouHodlerDataSourcesProvider>
  );
});
