import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {ActivityIndicator, Separator} from '@youtoken/ui.elements';
import {TwoFactorEnableGAFormClass} from './form';
import {Footer, StepFirst, StepSecond} from './components';

export const TwoFactorEnableGaSurface: React.FC = observer(() => {
  const isMobile = useIsMobile();
  const resources = useResources({
    authMe: getResourceDescriptor(AuthMeResource, {}),
  });

  const formClass = makeForm(
    () => new TwoFactorEnableGAFormClass({}, resources)
  );
  const {
    form: {loading, createOperation},
  } = formClass;

  return (
    <Form form={formClass}>
      <Box position="relative">
        <Box
          flexDirection={{default: 'column', tablet: 'row'}}
          justifyContent={{
            default: 'flex-start',
            tablet: 'space-between',
          }}
          width="100%"
        >
          <StepFirst />
          {isMobile && <Separator />}
          <StepSecond />
        </Box>

        <Footer />
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            alignItems="center"
            flexGrow={1}
            justifyContent="center"
            backgroundColor="$ui-background"
          >
            <ActivityIndicator />
          </Box>
        )}
      </Box>
    </Form>
  );
});
