import * as React from 'react';
import {Platform} from 'react-native';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {Illustration} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

const imageWidth = Platform.select({
  web: 396,
  default: 315,
});

const imageHeight = Platform.select({
  web: 272,
  default: 216,
});

export const WentWrongContent: React.FC<BoxProps> = ({...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box flex={1} alignItems="center" {...boxProps}>
      <Box alignItems="center" width="100%">
        <Box
          width={imageWidth}
          height={imageHeight}
          my={{smallPhone: 16, phone: 24}}
          alignItems="center"
          justifyContent="center"
          mb={32}
        >
          <Illustration
            name="illustration-12"
            style={{
              width: imageWidth,
              height: imageHeight,
            }}
          />
        </Box>

        <Heading variant="$heading-01" mb={16} textAlign="center">
          {t('common.errors.smth_went_wrong')}
          {/*{i18n.t('surface.device_confirm_result.title')}*/}
        </Heading>

        <Box mb={8}>
          <Text
            variant="$body-01"
            textAlign="center"
            // @ts-ignore
            style={{display: Platform.select({web: 'inline', default: 'flex'})}}
          >
            {/* <Text>
              Something went wrong ..... attempt by clicking the link in ...
              lorem ipsum
            </Text> */}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
