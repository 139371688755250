import styled from 'styled-components';
import {TColorTokens} from '@youtoken/ui.primitives';

export const StyledInput = styled.input<{
  invalid?: boolean;
  readonly?: boolean;
  ref?: any;
  colors: TColorTokens;
}>`
  font-family: Ubuntu;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 36px;
  background: ${props => props.colors['$ui-background']};
  width: 100%;
  border: 1px solid ${props => props.colors['$ui-02']};
  border-radius: 5px;
  padding: 0 15px;
  font-weight: 500;
  font-size: 14px;
  color: ${props =>
    props.invalid ? props.colors['$danger-01'] : props.colors['$text-01']};
  letter-spacing: 0.5px;
  line-height: 20px;
  vertical-align: middle;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    border: 1px solid ${props => props.colors['$interactive-01']};
    outline: none;
  }

  &.invalid {
    border: 1px solid ${props => props.colors['$danger-01']};
    color: ${props => props.colors['$danger-01']};
    background-color: ${props => props.colors['$danger-02']};
  }

  ${props =>
    props.invalid
      ? `
    border: 1px solid ${props.colors['$danger-01']};
    color: ${props.colors['$danger-01']};
    background-color: ${props.colors['$danger-02']};
  `
      : ''}
`;
