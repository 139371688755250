import * as React from 'react';
import {BoxProps, Box, Heading} from '@youtoken/ui.primitives';

export interface NumberItemProps extends BoxProps {
  focused?: boolean;
  filled?: boolean;
  hasError?: boolean;
}

export const NumberItem: React.FC<NumberItemProps> = ({
  focused,
  filled,
  children,
  hasError,
  ...props
}) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width={40}
      height={56}
      backgroundColor={
        hasError
          ? '$ui-background'
          : filled
          ? '$interactive-02'
          : '$ui-background'
      } //
      borderWidth={1}
      borderColor={
        hasError
          ? '$danger-01'
          : filled
          ? '$interactive-03'
          : focused
          ? '$interactive-01'
          : '$ui-02'
      }
      borderRadius={5}
      {...props}
    >
      <Heading
        variant="$heading-02"
        color={hasError ? '$danger-01' : '$text-05'}
      >
        {children}
      </Heading>
    </Box>
  );
};
