import * as React from 'react';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {AgreementsList, Switch} from '@youtoken/ui.elements';
import {HODLDetailsButtonAndModal} from '@youtoken/ui.hodl-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HowHODLWorksSurface} from '@youtoken/ui.surface-hodl-positions';

interface DetailsSectionProps extends BoxProps {}

export const DetailsSection: React.FC<DetailsSectionProps> = observer(
  ({...boxProps}) => {
    const {residenceOrCountry, enableHodl} = AuthMeResource.use({});

    const {
      documents: {hodlCreateForm},
    } = DocsResource.use({
      country: residenceOrCountry,
    });

    const {
      form: {setIsAgreementsAccepted, isAgreementsAccepted, direction},
      view: {detailsData, shouldShowAgreementsSwitcher},
    } = useForm<CreateHODLFormClass>();

    const {t} = useTranslation();

    const startActionKey =
      direction === 'down'
        ? 'surface.hodls.item.direction.start_down'
        : 'surface.hodls.item.direction.start_up';

    return (
      <Box {...boxProps}>
        <HODLDetailsButtonAndModal isTiny={!detailsData.isInputAmountPositive}>
          <HowHODLWorksSurface {...detailsData} />
        </HODLDetailsButtonAndModal>

        {enableHodl && (
          <Box
            mt={24}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flexGrow={1}
            flexShrink={1}
          >
            <AgreementsList
              beforeListText={t(
                'agreements.documents.without_checkbox.start_text',
                {
                  call_to_action: t(startActionKey),
                }
              )}
              items={hodlCreateForm}
            />
            {shouldShowAgreementsSwitcher && (
              // NOTE: mt={-2} is a little fix for alignment the switcher with text
              <Box ml={20} mt={-2}>
                <Switch
                  value={isAgreementsAccepted}
                  onValueChange={setIsAgreementsAccepted}
                  testID="SET_AGREEMENTS_ACCEPTED_SWITCHER"
                />
              </Box>
            )}
          </Box>
        )}
        {!enableHodl && (
          <Text color="$danger-01" weight="bold" mt={24}>
            {t('surface.hodls.hodl_form.product_unavailable')}
          </Text>
        )}
      </Box>
    );
  }
);
