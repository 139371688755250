import * as React from 'react';

const FormContext = React.createContext<any>({});

interface GenericForm {
  form: {
    instance: {
      $: (path: string) => {
        value: any;
        onChange: (...args: any[]) => void;
      };
      errors: () => any;
      submitting: boolean;
      onSubmit: (e: any) => void;
    };
  };
}

export const Form: React.FC<{form: any}> = ({form, children}) => {
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
};

export const useForm = <T extends GenericForm = GenericForm>(): T => {
  return React.useContext<T>(FormContext);
};
