import * as React from 'react';
import {
  type ConfirmationModalProps,
  ConfirmationModal,
} from './ConfirmationModal';
import {InvariantError} from '@youtoken/ui.errors';
import {ConfirmationOptions} from '@youtoken/ui.local-confirmations';

export const __GLOBAL_CONFIRMATION_REF__ =
  React.createRef<ConfirmationModalProps>();

export const __GLOBAL_CONFIRMATION__ = {
  requestConfirmation: (params: ConfirmationOptions) => {
    if (!__GLOBAL_CONFIRMATION_REF__.current?.requestConfirmation) {
      return Promise.reject(
        new InvariantError('ConfirmationContext not initialized')
      );
    }

    return __GLOBAL_CONFIRMATION_REF__.current?.requestConfirmation(params);
  },
};

export const ConfirmationContext = React.createContext<ConfirmationModalProps>(
  __GLOBAL_CONFIRMATION__
);

export const ConfirmationContextProvider: React.FC = ({children}) => {
  return (
    <ConfirmationContext.Provider value={__GLOBAL_CONFIRMATION__}>
      <ConfirmationModal ref={__GLOBAL_CONFIRMATION_REF__} />
      {children}
    </ConfirmationContext.Provider>
  );
};

export const useConfirmation = () => {
  const context = React.useContext(ConfirmationContext);

  if (!context) {
    throw new Error(
      'useConfirmation must be used within a ConfirmationContextProvider'
    );
  }

  return context;
};
