import * as React from 'react';
import {AxiosInstance} from 'axios';
import {Socket} from 'socket.io-client';
import {invariant} from '@youtoken/ui.utils';
import {TRANSPORT} from '@youtoken/ui.transport';

interface DataSourcesProviderProps {
  api: AxiosInstance;
  socket: Socket;
}

// TODO: should be memo? just in case?
export const DataSourcesProvider: React.FC<DataSourcesProviderProps> = ({
  children,
  api,
  socket,
}) => {
  invariant(api, 'api is required in DataSourcesProvider!');
  invariant(socket, 'socket is required in DataSourcesProvider!');

  TRANSPORT.API = api;
  TRANSPORT.SOCKET = socket;

  return <>{children}</>;
};
