import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Modal} from '@youtoken/ui.modal';
import {TwoFactorDisableForm} from '@youtoken/ui.surface-more';

export const ModalTwoFactorAuthenticationDisable: typeof Modal = props => {
  const {t} = useTranslation();

  return (
    <Modal title={t('surface.profile.form_2fa_app_disable.title')} {...props}>
      <TwoFactorDisableForm />
    </Modal>
  );
};
