import * as React from 'react';
import {getSize, SmallBankCard} from '@youtoken/ui.bank-card';
import {type IBankCard} from '@youtoken/ui.resource-bank-cards';
import {Box} from '@youtoken/ui.primitives';

const CARD_BORDER_WIDTH = 2;
const {width, borderRadius} = getSize('small');
const CARD_WIDTH = width + 2 * CARD_BORDER_WIDTH;

// How much wider the stack is allowed be compared to a single card.
const MAX_STACK_OFFSET = 24; //               |<->|
// 1 card layout:  [****]             -> [****]
// 2 cards layout: [****][****]       -> [***[****]
// 3 cards layout: [****][****][****] -> [*[*[****]

type CardStackProps = {
  cards: IBankCard[];
};

export const CardStack: React.FC<CardStackProps> = ({cards}) => {
  //  How much each rendered card should be offset to the right from the previous one,
  //  while keeping the stack width constant.
  const eachCardOffset = React.useMemo(
    () => MAX_STACK_OFFSET / (cards.length - 1),
    [cards.length]
  );

  return (
    <Box flexDirection="row" testID="BANK-CARDS_WIDGET_CARDS">
      {cards.map((card, index) => (
        <Box
          key={`${card.panLast} ${index}`}
          borderWidth={2}
          borderColor="$fill-01"
          borderRadius={borderRadius + CARD_BORDER_WIDTH}
          ml={index === 0 ? 0 : -CARD_WIDTH + eachCardOffset}
          testID={`BANK-CARDS_WIDGET_CARD_${card.panLast}`}
        >
          <SmallBankCard type={card.format} cardNumber={card.panLast} />
        </Box>
      ))}
    </Box>
  );
};
