import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import type {LoanRepayOwnFundsFormState} from '../../types';
import {AmountSetSource} from './Source';
import {AmountSetTarget} from './Target';

export const AmountSet: React.FC<BoxProps> = observer(props => {
  const {
    form: {ticker, conversionTicker},
  } = useForm<LoanRepayOwnFundsFormState>();

  return (
    <Box {...props}>
      <AmountSetSource zIndex={1} />
      {ticker !== conversionTicker && <AmountSetTarget />}
    </Box>
  );
});
