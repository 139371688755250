import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Modal} from '@youtoken/ui.modal';
import {RewardsOverview} from '../../smarts';

export const RewardsWelcomeModal: React.FC<
  React.ComponentProps<typeof Modal>
> = modalProps => {
  const {t} = useTranslation();

  const handlePress = React.useCallback(() => {
    modalProps.onClose();

    DATA_LAYER.trackStrict('reward-widget-click', {});
    SHARED_ROUTER_SERVICE.navigate('RewardsMain');
  }, [modalProps.onClose]);

  return (
    <Modal
      title={t('surface.referral_program.rewards.welcome.title')}
      shouldShowHeaderSeparator={false}
      shouldUseAnimatedSnapPoints
      snapPoints={['CONTENT_HEIGHT']}
      {...modalProps}
    >
      <Box p={24} pt={0}>
        <RewardsOverview type="welcome" />
        <Button size="large" onPress={handlePress} mt={24}>
          {t('surface.referral_program.rewards.welcome.action')}
        </Button>
      </Box>
    </Modal>
  );
};
