import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export type LevelStatusIconProps = Pick<
  React.ComponentProps<typeof Icon>,
  'name' | 'color' | 'size'
>;

export const LevelStatusIcon: React.FC<LevelStatusIconProps & BoxProps> =
  React.memo(({name, color, size = 16, ...rest}) => {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        position="absolute"
        right={16}
        top={16}
        width={24}
        height={24}
        borderRadius={12}
        {...rest}
      >
        <Icon name={name} color={color} size={size} />
      </Box>
    );
  });
