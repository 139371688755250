import React from 'react';
import {Form} from '@youtoken/ui.form-elements';
import {FeatureDisclosureForm} from '../../state';
import {type DisclosureSurfaceProps} from '../../types';

interface DisclosureFormProps {
  product: DisclosureSurfaceProps['product'];
  handleOnSuccess: DisclosureSurfaceProps['handleOnSuccess'];
}

export const DisclosureForm: React.FC<DisclosureFormProps> = ({
  product,
  handleOnSuccess,
  children,
}) => {
  const form = FeatureDisclosureForm.use({product, handleOnSuccess});

  React.useEffect(() => {
    form.reset();
  }, []);

  return <Form form={form}>{children}</Form>;
};
