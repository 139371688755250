import * as React from 'react';
import {observer} from 'mobx-react';
import {Icon} from '@youtoken/ui.icons';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, Heading, type BoxProps} from '@youtoken/ui.primitives';
import {diffColor} from '../../types';

type CardItemDiffProps = {
  baseTicker: string;
  quoteTicker: string;
  testID?: string;
};

export const CardItemDiff: React.FC<CardItemDiffProps & BoxProps> = observer(
  ({baseTicker, quoteTicker, testID, ...boxProps}) => {
    const {getDiffAndFormattedRate} = HODLsTariffsFeature.use({});
    const {diffDirection, diffPercentFormatted} = getDiffAndFormattedRate(
      baseTicker,
      quoteTicker
    );

    return (
      <Box
        flexDirection="row"
        alignItems="center"
        testID={testID}
        {...boxProps}
      >
        <Icon name={diffDirection} size={20} color={diffColor[diffDirection]} />
        <Heading variant="$heading-02" color={diffColor[diffDirection]} ml={8}>
          {diffPercentFormatted}
        </Heading>
      </Box>
    );
  }
);
