import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface PaySumRowProps extends BoxProps {
  value: string;
  ticker: string;
}

export const PaySumRow: React.FC<PaySumRowProps> = observer(
  ({value, ticker, ...props}) => {
    const {t} = useTranslation();

    return (
      <Box
        height={68}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        {...props}
      >
        <Box>
          <Text>{t('surface.apple_pay.deposit.components.pay_sum.pay')}</Text>
        </Box>
        <Box>
          <Heading>
            {value} {ticker}
          </Heading>
        </Box>
      </Box>
    );
  }
);
