import {format} from 'd3-format';

/** Formatted price, see exapmles
 * @example
 * 0.1 => '0'
 * 0.49 => '0'
 * 0.5 => '1'
 * 99.49 => '99'
 * 99.9 => '100'
 * 999.49 => '999'
 * 999.5 => '1K'
 * 1000 => '1K'
 * 1100 => '1.1K'
 * 1234 => '1.23K'
 * 12345 => '12.3K'
 * 123456 => '123K'
 */

export const priceFormatterInThousands = (amount: number) => {
  const amountStr = String(amount);
  const rounded =
    amount < 1000
      ? Number(amount.toFixed(0))
      : Number(
          amountStr
            .split('')
            .map((c, i) => {
              // Drop some digits to prevent rounding up
              if (i >= 3 && c !== '.') {
                return '0';
              }

              return c;
            })
            .join('')
        );

  const formatString = rounded < 1000 ? '.3' : '.3~s';

  return format(formatString)(rounded).toUpperCase();
};
