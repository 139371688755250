import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {MenuItemBase} from './MenuItemBase';

export const UniversalMoneyAddress: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();
  const {
    products: {
      uma: {available: umaAvailable},
    },
  } = AuthMeResource.use({});

  const handlePress = React.useCallback(() => {
    if (umaAvailable) {
      SHARED_ROUTER_SERVICE.navigate('UmaSettings');
    }
  }, [umaAvailable]);

  return (
    <MenuItemBase
      icon="uma"
      title={t('surface.wallets.uma')}
      onPress={handlePress}
      {...boxProps}
    />
  );
});
