import * as React from 'react';
import {observer} from 'mobx-react';
import {Text} from '@youtoken/ui.primitives';
import {Label, useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {normalizeWithoutSpaces} from '@youtoken/ui.normalizers';
import {type WithdrawalForm} from '../../state/WithdrawalForm';
import {FormInputField} from '../FormInputField';

export const FieldIban: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {accountType, feesFormattedList},
  } = useForm<WithdrawalForm>();

  const currentProvider =
    feesFormattedList.length === 1 ? feesFormattedList[0]!.provider : null;

  return (
    <FormInputField
      name="iban"
      testID="FIAT_WITHDRAWAL_BANK_WIRE_IBAN"
      maxLength={34}
      normalizer={normalizeWithoutSpaces}
      label={
        <Label>
          {accountType === 'iban' &&
            t('surface.wallets.withdrawal_bank_wire_form.iban')}
          {accountType === 'accountNumber' &&
            t('surface.wallets.withdrawal_bank_wire_form.account_number')}
        </Label>
      }
    >
      {accountType === 'iban' && (
        <Text color="$text-02" variant="$body-02" mt={8}>
          {t('surface.wallets.withdrawal_bank_wire_form.iban_description')}
        </Text>
      )}

      {currentProvider && (
        <Text color="$text-02" variant="$body-02" mt={8}>
          {t('surface.wallets.withdrawal_bank_wire_form.type_of_transfer')}:{' '}
          {currentProvider === 'sepa' &&
            t(
              'surface.wallets.withdrawal_bank_wire_form.type_of_transfer_sepa'
            )}
          {currentProvider === 'swift' &&
            t(
              'surface.wallets.withdrawal_bank_wire_form.type_of_transfer_swift'
            )}
        </Text>
      )}
    </FormInputField>
  );
});
