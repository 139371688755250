import * as React from 'react';
import {observer} from 'mobx-react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../../../../state';
import {HeaderBase, MethodItem} from '../../../../../../components';
import {
  type BuyCryptoFormAuthorizedYouHodlerWizardNavigator,
  BuyCryptoFormAuthorizedYouHodlerWizardScreenName,
} from '..';

export const BuyCryptoFormAuthorizedYouHodlerChooseMethodBase: React.FC =
  observer(() => {
    const {t} = useTranslation();

    const {goBack} = useWizard<
      BuyCryptoFormAuthorizedYouHodlerWizardNavigator,
      BuyCryptoFormAuthorizedYouHodlerWizardScreenName.CHOOSE_METHOD
    >();

    const {
      form: {
        possibleDepositMethods,
        depositMethodsData,
        depositMethod,
        changeDepositMethod,
      },
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    if (possibleDepositMethods.length === 0) {
      return null;
    }

    return (
      <>
        <HeaderBase onPressBack={goBack}>
          {t('ramp.conversion_form.label.pay_with')}
        </HeaderBase>
        {possibleDepositMethods.map((method, index) => (
          <MethodItem
            key={method}
            hasActive
            isActive={depositMethod == method}
            onPress={() => {
              DATA_LAYER.trackStrict('buy-wizard-payment-method-chosen', {
                method,
              });

              changeDepositMethod(method);
              goBack();
            }}
            showSeparator={index !== possibleDepositMethods.length - 1}
            {...depositMethodsData[method]!}
          />
        ))}
      </>
    );
  });
