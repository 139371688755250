import * as React from 'react';
import {Line, LineProps} from 'react-native-svg';

const strokeDasharray = [3, 3];

export const DashedLine: React.FC<LineProps> = React.memo(props => {
  return (
    <Line
      vectorEffect="non-scaling-stroke"
      strokeWidth={1}
      strokeDashoffset={1}
      strokeDasharray={strokeDasharray}
      {...props}
    />
  );
});
