import React from 'react';
import {Clipboard} from '@youtoken/ui.utils';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type AccountRequisite} from '../../../../types/AccountRequisite';
import {RequisiteCopyIcon} from './components/RequisiteCopyIcon';

interface RequisitesListItemProps {
  requisite: AccountRequisite;
}

export const RequisitesListItem: React.FC<RequisitesListItemProps> = ({
  requisite,
}) => {
  const {t} = useTranslation();
  const requisiteTypeFormatted = requisite.type
    .toUpperCase()
    .replace(/-/g, '_');

  const title = t(
    // @ts-ignore
    `surface.wallets.fiat_deposit_wire.requisites.${requisite.type}`,
    {
      defaultValue: requisite.title,
    }
  );

  const handlePressCopy = () => {
    Clipboard.setString(requisite.value);
    LOCAL_NOTIFICATIONS.info({
      text: t('surface.wallets.fiat_deposit_wire.copied_message', {
        title,
      }),
    });
  };

  return (
    <>
      <Box mb={8}>
        <Text variant="$body-02" color="$text-02">
          {title}
        </Text>
      </Box>
      <TouchableBox
        onPress={handlePressCopy}
        flexDirection="row"
        mb={16}
        pb={8}
        pr={4}
        testID={`FIAT_DEPOSIT_BANK_WIRE_REQUISITE_${requisiteTypeFormatted}`}
      >
        <Text>
          {requisite.value}
          &nbsp;
          <RequisiteCopyIcon />
        </Text>
      </TouchableBox>
    </>
  );
};
