import React from 'react';
import {Modal} from '../_common';
import {BonusScreen} from '@youtoken/ui.surfaces-referral-program';
import {Box} from '@youtoken/ui.primitives';
import {modal} from '@web-app/src/stores';

interface ModalRewardDetailsProps extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalRewardDetails: React.FC<ModalRewardDetailsProps> = ({
  id,
  ...props
}) => {
  const handlePressButton = React.useCallback(() => {
    modal.close();
  }, []);

  return (
    <Modal minHeight={200} {...props}>
      <Box
        width="100%"
        borderTopLeftRadius={8}
        borderRadius={8}
        overflow="hidden"
      >
        <BonusScreen onPressButton={handlePressButton} id={id} />
      </Box>
    </Modal>
  );
};
