import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {warning} from '@youtoken/ui.utils';
import {SavingsSelectedAssetsFeature} from '../../Feature';
import {SavingsPayoutIn} from '../../../../components';

export const SavingsSelectedAssetsActionWaiting: React.FC = observer(() => {
  const {timeLeft, onTimerEnd} = SavingsSelectedAssetsFeature.use({});

  warning(timeLeft, 'timeLeft is missing', {}, {timeLeft});

  return (
    <Box p={8}>
      <SavingsPayoutIn
        timerProps={{
          timeLeft: timeLeft as number,
          onTimerEnd,
          color: '$success-01',
        }}
        textAlign="center"
      />
    </Box>
  );
});
