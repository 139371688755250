import * as React from 'react';
import {useChartThemeContext} from '../../Providers';
import {DashedLine} from '../../Primitives';
import {observer} from 'mobx-react';
import {BaseHODLChartState} from '../../logic';

export const TakeProfitLine: React.FC<{
  chartState: BaseHODLChartState;
  fullWidth?: boolean;
}> = React.memo(
  observer(({chartState, fullWidth}) => {
    const {
      colors: {success},
    } = useChartThemeContext();

    const {
      width,
      isCursorActive,
      takeProfitLabel,
      maxProfitLabel,
      triggerPricePriority,
    } = chartState;

    if (
      !takeProfitLabel.layout ||
      !takeProfitLabel.layout.isInDomain ||
      triggerPricePriority
    ) {
      return null;
    }

    return (
      <DashedLine
        stroke={success}
        x1={
          fullWidth
            ? 0
            : maxProfitLabel.layout && !isCursorActive
            ? maxProfitLabel.layout.width
            : 0
        }
        x2={fullWidth ? width : width - takeProfitLabel.layout.width}
        y1={takeProfitLabel.layout.y}
        y2={takeProfitLabel.layout.y}
      />
    );
  })
);
