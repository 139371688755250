import * as React from 'react';
import {useTheme} from '@youtoken/ui.primitives';
import {type ChartDirection} from '../logic';

export interface ChartTheme {
  colors: {
    success: string;
    error: string;
    neutral: string;
    base: string;
  };
}

export interface ChartThemeContextValue {
  theme: ChartTheme;
  getColorByDirection: (direction: ChartDirection) => string;
}

export const ChartThemeContext = React.createContext<ChartThemeContextValue>(
  // @ts-expect-error
  {}
);

export const useChartThemeContext = () => {
  const {
    theme: {colors},
    getColorByDirection,
  } = React.useContext(ChartThemeContext);

  return {
    colors,
    getColorByDirection,
  };
};

export const ChartThemeProvider: React.FC<{theme?: ChartTheme}> = React.memo(
  ({children}) => {
    const theme = useTheme();

    const value = React.useMemo<ChartThemeContextValue>(
      () => ({
        theme: {
          colors: {
            base: theme.colors['$chart-interactive-01'],
            success: theme.colors['$chart-success-01'],
            error: theme.colors['$chart-danger-01'],
            neutral: theme.colors['$chart-neutral-01'],
            closed: theme.colors['$chart-disabled-02'],
          },
        },
        getColorByDirection: (direction: ChartDirection) => {
          return {
            up: theme.colors['$chart-success-01'],
            down: theme.colors['$chart-danger-01'],
            neutral: theme.colors['$chart-neutral-01'],
            pending: theme.colors['$chart-interactive-01'],
            closed: theme.colors['$chart-disabled-02'],
          }[direction];
        },
      }),
      [theme]
    );

    return (
      <ChartThemeContext.Provider value={value}>
        {children}
      </ChartThemeContext.Provider>
    );
  }
);
