import * as React from 'react';
import {observer} from 'mobx-react';
import {
  ResizableBoxWithOverlay,
  TradingViewModeSwitch,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';
import {
  ScreenNameMH,
  SmartTVChartContainerWeb,
} from '@youtoken/ui.trading-view-charts';
import {Separator} from '@youtoken/ui.elements';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';

interface TradingViewChartSectionProps {
  instrumentId: string;
  onClose: () => void;
}

export const TradingViewChartSection: React.FC<TradingViewChartSectionProps> =
  observer(({instrumentId, onClose}) => {
    const {getInstrumentById} = HODLsTariffsFeature.use({});
    const {baseTicker, quoteTicker} = getInstrumentById(instrumentId);

    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <ResizableBoxWithOverlay height={500} mb={8} width="100%" {...bind}>
        {Boolean(width && height) && (
          <SmartTVChartContainerWeb
            fromTicker={baseTicker}
            toTicker={quoteTicker}
            mode="mid"
            screenName={ScreenNameMH.INSTRUMENT}
            height={440}
          />
        )}
        <Separator />
        <TradingViewModeSwitch
          mode="tradingview"
          onChange={onClose}
          pt={15}
          pb={16}
          px={24}
        />
      </ResizableBoxWithOverlay>
    );
  });
