import {computed, observable} from 'mobx';
import {list, object, primitive, serializable} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {priceFormatter} from '@youtoken/ui.formatting-utils';

/**
 * Documentation: https://youhodler.atlassian.net/wiki/spaces/TRD/pages/3150413857/Market+stats+for+hodl#GET-%2FgetMarketStatsList
 */

class MarketStatsInstrumentData {
  @observable
  @serializable(primitive())
  symbol!: string;

  @observable
  @serializable(number())
  marketCap!: number;

  @observable
  @serializable(number())
  marketCapDominance!: number;

  @observable
  @serializable(number())
  volume24h!: number;

  @observable
  @serializable(number())
  totalSupply!: number;

  @observable
  @serializable(number())
  circulatingSupply!: number;

  @observable
  @serializable(number())
  capRank!: number;

  @observable
  @serializable(number())
  volumeChange24h!: number;
}

class MarketStatsInstrument {
  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(primitive())
  baseTicker!: string;

  @computed get baseTickerFormatted() {
    return this.baseTicker.toUpperCase();
  }

  @observable
  @serializable(primitive())
  quoteTicker!: string;

  @computed get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @computed get baseTickerName() {
    return getCoinName(this.baseTicker);
  }

  @observable
  @serializable(object(MarketStatsInstrumentData))
  data!: MarketStatsInstrumentData;

  @computed get marketCapFormatted() {
    const {value, unit} = priceFormatter({
      amount: this.data.marketCap,
      precision: 1,
    });
    return `$${value}${unit}`;
  }
}

export class HODLMarketStatsResponse {
  @observable
  @serializable(list(object(MarketStatsInstrument)))
  data!: MarketStatsInstrument[];
}
