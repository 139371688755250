// import {BaseChartLayoutState} from './BaseChartLayoutState'

import {computed, observable} from 'mobx';
import {scaleLinear} from 'd3-scale';
import * as array from 'd3-array';

import {BaseChartLayout} from './BaseChartLayout';
import {BaseChartState} from '../BaseChartState';

export class CandlesChartLayout extends BaseChartLayout {
  @observable chart!: BaseChartState;

  constructor(chart: BaseChartState) {
    super();
    this.chart = chart;
  }

  @computed get scaleX() {
    return scaleLinear()
      .domain([0, this.chart.candles.visibleCandles!.length])
      .range([this.chart.domainPaddingLeft - 5, this.chart.layout.chartWidth]);
  }

  @computed get chartWidth() {
    return this.chart.lastValueLabel.labelWidth
      ? this.chart.width - this.chart.lastValueLabel.labelWidth
      : this.chart.width - this.chart.domainPaddingRight;
  }

  @computed get scaleXChart() {
    const range = [this.chart.domainPaddingLeft, this.chartWidth];

    return this.scaleX.copy().range(range);
  }

  @computed get scaleY() {
    return scaleLinear()
      .domain([
        array.min(this.chart.candles.visibleCandles!, i => i.low)!,
        array.max(this.chart.candles.visibleCandles!, i => i.high)!,
      ])
      .range([
        this.chart.height - this.chart.domainPaddingBottom,
        this.chart.domainPaddingTop,
      ])
      .nice();
  }
}
