import {
  BaseHODLChartState,
  BaseHODLChartStateProps,
} from './BaseHODLChartState';

export interface HODLCreateChartStateProps extends BaseHODLChartStateProps {}

export class HODLCreateChartState extends BaseHODLChartState {
  constructor(props: HODLCreateChartStateProps) {
    super(props);
  }
}
