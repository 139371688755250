const roundToMultipleOfTen = (number: number) => {
  return Math.round(number / 10) * 10;
};

const getButtonData = (value: number) => {
  const label = `x${value}`;
  const testID = `MULTIPLY_BUTTON_${value}`;
  return {label, value, testID};
};

export const getMultiplySliderButtons = (
  minValue: number,
  maxValue: number
) => {
  const range = maxValue - minValue;

  if (range === 0) {
    return [];
  }

  // By default, we want to show 5 intermediate buttons but if range is less than 50 the number of buttons should decrease proportionally
  const intermediateButtonsCount = range > 50 ? 5 : Math.floor(range / 10);

  // +1 because of last button above intermediate buttons
  const step = range / (intermediateButtonsCount + 1);

  // Create array with first button
  const buttons = [getButtonData(minValue)];

  // Add generated buttons between minValue and maxValue with values rounded to 10
  for (let i = 1; i <= intermediateButtonsCount; i++) {
    const buttonData = getButtonData(roundToMultipleOfTen(step * i));
    buttons.push(buttonData);
  }

  // Add last button
  buttons.push(getButtonData(maxValue));

  return buttons;
};
