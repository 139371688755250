import {list, object, primitive, serializable} from 'serializr';
import Big from 'big.js';
import {
  bigNumber,
  bigNumberNullable,
  duration,
} from '@youtoken/ui.utils-serializr';
import {Duration} from '@youtoken/ui.date-fns';
import {computed} from 'mobx';
import {getAprBySettlementPeriod, getSettlementPeriodFormatted} from '../utils';

export class TariffLoansRegularSetting {
  /** A label of a currency of the borrowed, e.g. "chf" */
  @serializable(primitive())
  borrowedTicker!: string;

  /** Maximum amount of a loan e.g. "1000000.00" */
  @serializable(bigNumber())
  maxAmount!: Big;

  /** Maximum FTP value, e.g. "1.100" */
  @serializable(bigNumber())
  maxTP!: Big;

  /** Minimum amount of a loan e.g. "100.00" */
  @serializable(bigNumber())
  minAmount!: Big;

  /** Maximum FTP value, e.g. "2.000" */
  @serializable(bigNumber())
  minTP!: Big;

  @serializable(bigNumberNullable())
  tp!: Big | null;
}

export class TariffLoansRegular {
  /** Tariff Id e.g. "4b941f00-bbd7-4c37-bb29-a2f74a4686c4" */
  @serializable(primitive())
  id!: string;

  /** Collateral ticker e.g. "1inch" */
  @serializable(primitive())
  collateralTicker!: string;

  /** cost of the loan. For v1 full cost in percent. For v2 annual percent rate e.g.
   * "0.200000"
   * */
  @serializable(bigNumber())
  apr!: Big;

  /** annual percent rate if a fee would be taken from overdraft e.g.
   * "0.300000"
   *  */
  @serializable(bigNumber())
  penaltyAPR!: Big;

  /** company. Possible values "YOUHODLER", "NAUMARD", "YOUHODLER_ITA" */
  @serializable(primitive())
  company!: 'NAUMARD' | 'YOUHODLER' | 'YOUHODLER_ITA';

  /** 	loan to value ratio. The amount of fiat funds that the client can
   * receive in exchange for his or her cryptocurrency is used as collateral for a crypto-backed loan,
   * e.g."0.9000"
   *  */
  @serializable(bigNumber())
  ltv!: Big;

  /** Maximum Loan term, e.g. "364d" */
  @serializable(duration())
  maxDuration!: Duration;

  /** When the price drops below the marginCall level,
   * YouHodler has the right to sell the collateral and close the deal.
   * e.g. "-0.15"
   * */
  @serializable(bigNumber())
  marginCall!: Big;

  /** Possible values: "COMMON", "PROMO", "VIP" */
  @serializable(primitive())
  type!: 'COMMON' | 'PROMO' | 'VIP';

  /** Settings data */
  @serializable(list(object(TariffLoansRegularSetting)))
  settings!: TariffLoansRegularSetting[];

  @serializable(duration())
  settlementPeriod!: Duration;

  @computed
  public get aprBySettlementPeriod() {
    return getAprBySettlementPeriod(this.apr, this.settlementPeriod);
  }

  @computed
  public get penaltyAprBySettlementPeriod() {
    return getAprBySettlementPeriod(this.penaltyAPR, this.settlementPeriod);
  }

  @computed
  public get settlementPeriodFormatted() {
    return getSettlementPeriodFormatted(this.settlementPeriod);
  }
}
