import * as React from 'react';
import {ThemeProvider} from '@youtoken/ui.primitives';
import {InterfaceOverview} from './InterfaceOverview';

export const LightThemeDemo: React.FC = React.memo(() => {
  return (
    <ThemeProvider themeName="light">
      <InterfaceOverview />
    </ThemeProvider>
  );
});

export const DarkThemeDemo: React.FC = React.memo(() => {
  return (
    <ThemeProvider themeName="dark">
      <InterfaceOverview />
    </ThemeProvider>
  );
});
