import Big, {BigSource} from 'big.js';
import {warning} from '@youtoken/ui.utils';

export const getSettlementFeeSign = (
  settlementFee?: BigSource,
  showPlus: boolean = true
) => {
  if (settlementFee === undefined || settlementFee === '') {
    return '';
  }

  try {
    const settlementFeeBig = Big(settlementFee);

    if (settlementFeeBig.eq(0)) {
      return '';
    }

    if (settlementFeeBig.lt(0)) {
      return showPlus ? '+' : '';
    }

    return '-';
  } catch (e) {
    warning(
      false,
      `getSettlementFeeSign: cannot convert $settlementFee to big number`,
      {},
      {
        value: settlementFee,
      }
    );
    return '';
  }
};
