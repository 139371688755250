declare global {
  interface Window {
    intercomSettings: {[key: string]: any};
    attachEvent?: Function;
  }
}

export const initIntercom = (appId: string) => {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      // @ts-ignore
      i.c(arguments);
    };
    // @ts-ignore
    i.q = [];
    // @ts-ignore
    i.c = function (args) {
      // @ts-ignore
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${appId}`;
      var x = d.getElementsByTagName('script')[0];
      if (x?.parentNode) {
        x.parentNode.insertBefore(s, x);
      }
    };
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};
