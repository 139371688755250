import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {BoxProps} from '@youtoken/ui.primitives';
import {
  HistoryItemData,
  HistoryItemResource,
} from '@youtoken/ui.resource-history-new';
import {HistoryItemDetails} from '../HistoryItemDetails';

type Props = {
  id: HistoryItemData['id'];
} & BoxProps;

export const HistoryItemDetailsSurface: React.FC<Props> = cell(
  ({id, ...boxProps}) => {
    const {data: item, refetch} = HistoryItemResource.use({id});

    // For the item, we use the one from a stored historyList (HistoryListResource).
    // If it changed (e.g. status), we need to update the stored item (HistoryItemResource).
    React.useEffect(() => {
      refetch();
    }, []);

    return <HistoryItemDetails item={item} {...boxProps} />;
  }
);
