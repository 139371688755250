import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Switch} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {FieldErrorMessage, Label} from '@youtoken/ui.form-elements';
import {Combobox} from '@youtoken/ui.combobox';
import {useCountryItems} from '@youtoken/ui.hooks';
import {TextInput} from '@youtoken/ui.inputs';
import {Button} from '@youtoken/ui.buttons';
import {getTranslatedValidationMessage} from '@youtoken/ui.validation-messages';
import {TermsCheckbox} from './TermsCheckbox';
import {FeatureSignUpAdditionalForm} from './state';

export type SignUpAdditionalSurfaceProps = {
  email: string;
  provider: 'google' | 'apple';
  idToken: string;
  accessCode: string;
  residence?: string;
  onClose?: () => void;
};

export const SignUpAdditionalSurface = ({
  accessCode,
  idToken,
  email,
  provider,
  residence,
  onClose,
}: SignUpAdditionalSurfaceProps) => {
  const {t} = useTranslation();

  const feature = FeatureSignUpAdditionalForm.use({
    initialValues: {
      residence,
      showPromoField: false,
      inviteeCode: '',
      agree: false,
    },
    provider,
  });

  const countryItems = useCountryItems();

  const handlePressSubmit = React.useCallback(
    (event: any) => {
      feature.form.accessCode = accessCode;
      feature.form.idToken = idToken;
      feature.form.email = email;
      feature.form.handleClose = onClose;

      return feature.form.instance.onSubmit(event);
    },
    [accessCode, idToken, email, feature.form]
  );

  return (
    <Box justifyContent="flex-start">
      <Box zIndex={10} mb={12}>
        <Label>{t('forms.sign_up.fields.country.label')}</Label>
        <Box mb={15} mt={10} zIndex={10}>
          <Combobox
            size="medium"
            items={countryItems}
            value={feature.form.residence}
            onChange={feature.form.instance.$('residence').onChange}
            hasError={Boolean(feature.form.instance.$('residence').error)}
            testID="COUNTRY_DD"
          />
          <FieldErrorMessage
            visible={Boolean(feature.form.instance.$('residence').error)}
          >
            {getTranslatedValidationMessage(
              feature.form.instance.$('residence').error
            )}
          </FieldErrorMessage>
        </Box>
      </Box>

      <Box
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        mb={24}
      >
        <Text variant="$body-01-high-accent" style={{flex: 1}}>
          {t('forms.sign_up.fields.promo.label_with_referral')}
        </Text>
        <Switch
          value={feature.form.showPromoField}
          onValueChange={feature.form.instance.$('showPromoField').onChange}
          testID="REFERRAL_CHECKBOX"
        ></Switch>
      </Box>

      {feature.form.showPromoField && (
        <Box mb={24}>
          <TextInput
            hasError={Boolean(feature.form.instance.$('inviteeCode').error)}
            value={feature.form.inviteeCode}
            onChangeText={feature.form.instance.$('inviteeCode').onChange}
            placeholder={t('forms.sign_up.fields.promo.placeholder')}
            testID="REFERRAL_INPUT"
          />
          <FieldErrorMessage
            visible={Boolean(feature.form.instance.$('inviteeCode').error)}
          >
            {getTranslatedValidationMessage(
              feature.form.instance.$('inviteeCode').error
            )}
          </FieldErrorMessage>
        </Box>
      )}

      <TermsCheckbox feature={feature} mb={24} width="100%" />

      <Button
        onPress={handlePressSubmit}
        loading={feature.form.submitting}
        testID={`SIGN_UP_WITH_${provider.toUpperCase()}`}
      >
        {t('surface.sign_up_additional.action', {
          provider: `${provider.charAt(0).toUpperCase()}${provider.slice(1)}`,
        })}
      </Button>
    </Box>
  );
};

export const SignUpAdditionalSurfaceCell = cell(SignUpAdditionalSurface);
