import {AxiosError} from 'axios';
import {toBig} from '@youtoken/ui.formatting-utils';
import {getCoinName} from '@youtoken/ui.coin-utils';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {i18n} from '@youtoken/ui.service-i18n';
import {LogoColoredIconName} from '@youtoken/ui.icons';

export const handleFormSubmitError = (
  form: any,
  axiosError: AxiosError,
  fieldsNames: {[key: string]: string} = {}
) => {
  const error = axiosError?.response?.data;

  const _error = extractErrorFromResponse(error, '_error');
  const _errorTranslated = getTranslatedValidationMessage(_error);

  LOCAL_NOTIFICATIONS.error({
    text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
  });

  if (_error && _error.i18n?.label === 'MISSING_FORM_A_SIGNED') {
    SHARED_ROUTER_SERVICE.navigate('FormAVerificationModal');
    return;
  }

  if (error) {
    Object.keys(error)
      .filter(k => k !== 'i18n')
      .forEach(fieldName => {
        const fieldNameFront = fieldsNames[fieldName] || fieldName;
        const fieldError = extractErrorFromResponse(error, fieldName);

        if (fieldError) {
          try {
            form.$(fieldNameFront).invalidate(fieldError);
          } catch (e) {}
        }
      });
  }
  throw axiosError;
};

export interface ITickerWithAmountValue {
  icon: LogoColoredIconName;
  ticker: string;
  amount: string;
  name: string;
  keywords: string;
}

export const tickerToTickerWithAmountValue = (
  _ticker: string,
  walletsResource: WalletsResource
): ITickerWithAmountValue => {
  const {ticker, amount} = walletsResource.getByTicker(_ticker) || {
    ticker: _ticker,
    amount: toBig(0),
  };

  const name = getCoinName(ticker);

  return {
    icon: ticker as LogoColoredIconName,
    ticker: ticker,
    amount: amount.toString(),
    name: name,
    keywords: `${ticker} ${name}`,
  };
};
