import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {YouhodlerServices} from './YouhodlerServices';
import {CompanyResource} from '@youtoken/ui.resource-companies';
import {useResource} from '@youtoken/ui.data-storage';

export const WalletDisclosureContent: React.FC = observer(() => {
  const {t} = useTranslation();
  const {naumard} = useResource(CompanyResource, {});

  return (
    <>
      <Box mb={16}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.disclosure_form.content.text')}
        </Text>
      </Box>
      <Heading variant="$heading-03" mb={16} textTransform="uppercase">
        {t('surface.disclosure_form.content.subtitle.wallets')}
      </Heading>
      <Box mb={16}>
        <Text variant="$body-02" color="$text-02">
          {t('surface.disclosure_form.content.services_offered', {
            company: naumard?.name,
            address: naumard?.address,
          })}
        </Text>
      </Box>
      <Heading variant="$heading-03" mb={16} textTransform="uppercase">
        {t('surface.disclosure_form.content.subtitle.conversion')}
      </Heading>
      <YouhodlerServices />
    </>
  );
});
