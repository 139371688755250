import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {type TextProps, Text} from '@youtoken/ui.primitives';

export interface AccountLevelMinerUpToUSDProps extends TextProps {
  amount: string;
  period?: string;
}

export const AccountLevelMinerUpToUSD: React.FC<AccountLevelMinerUpToUSDProps> =
  observer(({amount, period, ...textProps}) => {
    const {t} = useTranslation();

    return (
      <Text
        testID="ACCOUNT_LEVEL_MINER_UP_TO_USD"
        variant="$body-02"
        color="$text-02"
        {...textProps}
      >
        <Trans
          t={t}
          components={{
            Strong: <Text color="$text-01" />,
          }}
          values={{amount, period}}
          i18nKey="surface.loyalty.cloud_miner.up_to_usd"
        />
      </Text>
    );
  });
