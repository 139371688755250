import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const ChevronRightIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.35 9.25l9.69 9.69a1.5 1.5 0 010 2.12l-9.69 9.69a1.768 1.768 0 01-2.5-2.5L24.1 20l-8.25-8.25a1.768 1.768 0 112.5-2.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
