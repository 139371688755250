import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {IncentivesFeature} from '@youtoken/ui.resource-wallets';
import {IncentivesBonusForDeposit} from './IncentivesBonusForDeposit';

export type IncentivesBonusForDepositSmartProps = {
  bonusesValue: string;
};

export const IncentivesBonusForDepositSmart: React.FC<
  IncentivesBonusForDepositSmartProps & BoxProps
> = observer(({bonusesValue, ...boxProps}) => {
  const {incentivesEnabled} = IncentivesFeature.use({});

  if (!incentivesEnabled) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <IncentivesBonusForDeposit bonusesValue={bonusesValue} />
    </Box>
  );
});
