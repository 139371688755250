import * as React from 'react';
import {ReanimatedBox, Text} from '@youtoken/ui.primitives';
import type {ProgressComponent} from '../types';
import {
  useAnimatedStyle,
  withSpring,
  type SharedValue,
} from 'react-native-reanimated';
import {backgroundColor} from './consts';

export const ProgressBarText: React.FC<
  ProgressComponent & {
    _progress: SharedValue<number>;
  }
> = ({text, color, progress, _progress}) => {
  const _color = progress >= 0.5 ? '$text-04' : backgroundColor[color];

  const style = useAnimatedStyle(() => {
    const normalizedProgress = Math.max(Math.min(_progress.value, 1), 0);

    if (_progress.value >= 0.5) {
      return {
        right: withSpring(`${100 - normalizedProgress * 100}%`, {
          overshootClamping: true,
        }),
      };
    }
    return {
      left: withSpring(`${normalizedProgress * 100}%`, {
        overshootClamping: true,
      }),
    };
  });

  return (
    <ReanimatedBox
      justifyContent="center"
      alignItems="flex-end"
      height={24}
      position="absolute"
      px={8}
      style={style}
    >
      <Text color={_color} variant="$body-02-medium-accent">
        {text}
      </Text>
    </ReanimatedBox>
  );
};
