import * as React from 'react';
import {observer} from 'mobx-react';
import {Button} from '@youtoken/ui.buttons';
import {type BoxProps} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('MultiHodlSpecification');
};

export const MultiHodlSpecification: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Button size="large" type="secondary" onPress={handlePress} {...boxProps}>
      {t('surface.profile.account.fees.action_1')}
    </Button>
  );
});
