import * as React from 'react';

const FormContext = React.createContext<any>({});

interface GenericForm {
  form: {
    instance: {
      $: (path: string) => {
        value: any;
        onChange: (...args: any[]) => void;
        error: null | Object;
      };
      errors: () => any;
      submitting: boolean;
      onSubmit: (e: any) => void;
    };
    dispose?: () => void;
  };
  view?: any;
}

export const Form: React.FC<{form: any}> = ({form, children}) => {
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
};

export const useForm = <T extends GenericForm = GenericForm>(): T => {
  return React.useContext<T>(FormContext);
};

export function makeForm<T extends GenericForm>(initForm: () => T) {
  const form = React.useRef<T | null>(null);

  if (!form.current) {
    form.current = initForm();
  }

  React.useEffect(() => {
    return () => {
      return form.current?.form?.dispose?.();
    };
  }, [form.current]);

  return form.current;
}
