import * as React from 'react';
import {
  SvgProps,
  Svg,
  Rect,
  Path,
  Circle,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const PhoneIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        fill="none"
        {...props}
      >
        <Rect x={16} y={2} width={32} height={60} rx={6} fill="#425266" />
        <Path
          d="M18 8a4 4 0 014-4h1.56c.92 0 1.801.374 2.44 1.037a3.39 3.39 0 002.44 1.037h7.12A3.39 3.39 0 0038 5.037 3.39 3.39 0 0140.44 4H42a4 4 0 014 4v48a4 4 0 01-4 4H22a4 4 0 01-4-4V8z"
          fill={`url(#${_id}_phone_svg__phone_svg__paint0_linear)`}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 11a1 1 0 00-1 1v4a1 1 0 001 1h.5v-6h-.5zM47 11h.5a1 1 0 011 1v4a1 1 0 01-1 1H47v-6zm0 8h.5a1 1 0 011 1v4a1 1 0 01-1 1H47v-6z"
          fill="#425266"
        />
        <Circle cx={32} cy={56} r={2} fill="#425266" />
        <Defs>
          <LinearGradient
            id={`${_id}_phone_svg__phone_svg__paint0_linear`}
            x1={32}
            y1={1.926}
            x2={32}
            y2={62.074}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#6B7A94" />
            <Stop offset={0} stopColor="#6B7A94" />
            <Stop offset={1} stopColor="#353E4F" />
          </LinearGradient>
        </Defs>
      </Svg>
    );
  }
);
