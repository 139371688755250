import {Big} from 'big.js';
import {Platform} from 'react-native';
import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {getWebAppUrl} from '@youtoken/ui.env-utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {messages} from '@youtoken/ui.validation-messages';
import {TRANSPORT} from '@youtoken/ui.transport';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {openBrowserAsync} from '@youtoken/ui.utils';
import {calculateIncentivesDebounced} from '@youtoken/ui.incentives-utils';
import {
  formatByTicker,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import type {UnlimintSPEIFormArgs, UnlimintSPEIFormResources} from '../types';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  public readonly ticker: string;

  @observable
  public readonly conversionTicker = 'mxn';

  @observable
  private incentivesBonus = toBig(0);

  @observable
  private disposers: IReactionDisposer[] = [];

  @observable
  private resources: UnlimintSPEIFormResources;

  public constructor(
    {ticker, onSubmit}: UnlimintSPEIFormArgs,
    resources: UnlimintSPEIFormResources
  ) {
    this.ticker = ticker;
    this.resources = resources;

    this.instance = new MobxReactForm(
      {
        fields: {
          amount: {value: ''},
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                return yup.object().shape({
                  amount: yup
                    .big()
                    .gte(this.minAmount.toNumber(), () =>
                      messages.MIN_AMOUNT({
                        value: this.minAmount.toFixed(this.tickerPrecision),
                        ticker: this.tickerFormatted,
                      })
                    )
                    .lte(this.maxAmount.toNumber(), () =>
                      messages.MAX_AMOUNT({
                        value: this.maxAmount.toFixed(this.tickerPrecision),
                        ticker: this.tickerFormatted,
                      })
                    )
                    .required(messages.REQUIRED),
                });
              }),
          }),
        },
        hooks: {
          onSuccess: () => {
            DATA_LAYER.trackStrict('deposit-fiat-submit', {
              type: 'fiat',
              category: 'deposit',
              provider: 'unlimintSPEI',
              ticker: this.ticker,
              amount: Number(formatByTicker(this.amountValue, this.ticker)),
              amountUSD: Number(formatByTicker(this.amountInUSD, 'usd')),
            });

            const baseUrl = getWebAppUrl(ENVIRONMENT.APP_ENV);
            const returnUrls = Platform.select({
              web: {
                returnUrl: `${baseUrl}/wallets/${ticker}`,
              },
              native: {
                cancelUrl: `${baseUrl}/deposit/cancel`,
                declineUrl: `${baseUrl}/deposit/fail`,
                inprocessUrl: `${baseUrl}/deposit/processing`,
                successUrl: `${baseUrl}/deposit/success`,
              },
            })!;

            return TRANSPORT.API.post('/v1/deposit', {
              provider: 'unlimintSPEI',
              amount: this.amountValue,
              ticker: ticker,
              conversionTicker: this.conversionTicker,
              providerData: {
                redirectUrls: returnUrls,
              },
            })
              .then(response => {
                const providerUrl =
                  response?.data?.providerResponse?.redirect_url;

                if (providerUrl) {
                  return openBrowserAsync(providerUrl).then(() => onSubmit?.());
                }
              })
              .catch(e => {
                handleFormSubmitError(this.instance, e);
              });
          },
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );

    this.disposers = [
      // NOTE: update bonuses value after amount changed
      reaction(
        () => this.amountValue,
        amount => {
          calculateIncentivesDebounced(
            amount,
            this.ticker,
            this.setIncentivesBonus
          );
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  get amountValue() {
    return this.instance.$('amount').value;
  }

  @computed
  public get amountError() {
    return this.instance.$('amount').error;
  }

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.ticker);
  }

  @computed
  public get tickerFees() {
    return this.resources.feeAll.getFeeForProviderDeposit(
      'unlimintSPEI',
      this.ticker,
      this.conversionTicker
    );
  }

  @computed
  public get minAmount() {
    return toBig(this.tickerFees?.minAmount);
  }

  @computed
  public get maxAmount() {
    return toBig(this.tickerFees?.maxAmount);
  }

  @computed
  get amountInUSD(): Big {
    const {getRate} = this.resources.rates;
    const rate = getRate(this.ticker, 'usd');
    return toBig(this.amountValue).times(toBig(rate));
  }

  @computed
  get amountInConversionTicker(): Big {
    const {getRate} = this.resources.rates;
    const rate = getRate(this.ticker, this.conversionTicker);
    return toBig(this.amountValue).times(toBig(rate));
  }

  @computed
  get paymentFee() {
    return toBig(this.tickerFees?.percent);
  }

  @computed
  get paymentFeeFormatted() {
    return `${formatPercent(this.paymentFee)}%`;
  }

  @computed
  get paymentAmountFormatted() {
    const feeValue = toBig(
      this.amountInConversionTicker
        .mul(this.paymentFee)
        .toFixed(this.tickerPrecision, 0)
    );
    const feeValueFormatted = feeValue.toFixed(this.tickerPrecision, 0);

    const amount = this.amountInConversionTicker
      .plus(feeValueFormatted)
      .toFixed(0);
    const preSign = this.amountValue > 0 ? '~' : '';

    return `${preSign}${amount} ${this.conversionTicker.toUpperCase()}`;
  }

  @action
  onChangeAmount = (value: string) => {
    const onChange = this.instance.$('amount').get('onChange');
    onChange(normalizeAmountByTicker(value, this.ticker));
  };

  @computed get incentivesBonusFormatted() {
    return this.incentivesBonus.toFixed(getCoinDecimalPrecision('bonus'));
  }

  @action setIncentivesBonus = (value: Big) => {
    this.incentivesBonus = value;
  };
}
