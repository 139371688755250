import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const FiatDepositIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5 25.5a1.5 1.5 0 011.486 1.297L34 27v4a4 4 0 01-3.701 3.989L30 35H10a4 4 0 01-3.989-3.701L6 31v-4a1.5 1.5 0 012.986-.203L9 27v3.5a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5V27l.014-.203A1.5 1.5 0 0132.5 25.5zM20 5a1.5 1.5 0 011.5 1.5v15L24 19a1.414 1.414 0 011.825-.15L26 19a1.414 1.414 0 010 2l-4.94 4.94a1.5 1.5 0 01-2.12 0L14 21a1.414 1.414 0 011.825-2.15L16 19l2.5 2.5v-15A1.5 1.5 0 0120 5z"
          fill={color}
        />
      </Svg>
    );
  }
);
