export const blacklistCodes = [
  'USA', // USA
  'BGD', // Bangladesh
  'CHN', // China
  'IRQ', // Iraq
  'PAK', // Pakistan
  'UMI', // United States Minor Outlying Islands
  'ASM', // AmericanSamoa
  'GUM', // Guam
  'MNP', // Northern Mariana Islands
  'PRI', // Puerto Rico
  'VIR', // Virgin Islands, U.S.
  'PRK', // Korea, Democratic People's Republic of
  'IRN', // Iran, Islamic Republic of
  'SYR', // Syrian Arab Republic
  'CUB', // Cuba
  'SDN', // Sudan
  'AFG', // Afghanistan
  'PSE', // Palestinian Territory, Occupied
  'UMI', // United States Minor Outlying Islands
];
