import * as React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  HodlTariffResponseItem,
  HODLsTariffsFeature,
} from '@youtoken/ui.resource-hodl-tariffs';
import {HODLPublicInstrumentItem} from '@youtoken/ui.resource-hodl-public-instruments';
import {Box} from '@youtoken/ui.primitives';
import {SwipeElement} from '../';

interface SwipeActionsProps {
  item: HodlTariffResponseItem | HODLPublicInstrumentItem;
}

export const SwipeActions: React.FC<SwipeActionsProps> = observer(({item}) => {
  const {toggleStarredHODLsInstrument, getAvailableInstrumentTariffs} =
    HODLsTariffsFeature.use({});
  const {t} = useTranslation();

  const {sellTariffAvailable, buyTariffAvailable} =
    getAvailableInstrumentTariffs(item.baseTicker, item.quoteTicker);

  const handlePressSell = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate(
      'NewMultiHODL',
      {},
      {
        direction: 'sell',
        baseTicker: item.baseTicker,
        quoteTicker: item.quoteTicker,
      }
    );
  }, [item.baseTicker, item.quoteTicker]);

  const handlePressBuy = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate(
      'NewMultiHODL',
      {},
      {
        direction: 'buy',
        baseTicker: item.baseTicker,
        quoteTicker: item.quoteTicker,
      }
    );
  }, [item.baseTicker, item.quoteTicker]);

  const handlePressStar = React.useCallback(() => {
    toggleStarredHODLsInstrument(item);
  }, [toggleStarredHODLsInstrument, item]);

  return (
    <Box flexDirection="row">
      {!item.itIsIndex && (
        <>
          <SwipeElement
            onPress={handlePressSell}
            icon="down"
            text={t('surface.hodls.instruments.instruments_sell_title')}
            mainColor="$danger-01"
            backgroundColor="$danger-02"
            disabled={!sellTariffAvailable}
          />
          <SwipeElement
            onPress={handlePressBuy}
            icon="up"
            text={t('surface.hodls.instruments.instruments_buy_title')}
            mainColor="$success-01"
            backgroundColor="$success-02"
            disabled={!buyTariffAvailable}
          />
        </>
      )}
      <SwipeElement
        onPress={handlePressStar}
        icon={item.isStarred ? 'star_filled' : 'star'}
        text={
          item.isStarred
            ? t('surface.hodls.instruments.instruments_unstar_title')
            : t('surface.hodls.instruments.instruments_star_title')
        }
        mainColor="$text-05"
        backgroundColor="$interactive-02"
      />
    </Box>
  );
});
