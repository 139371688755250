import React, {useEffect} from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {Icon} from '@youtoken/ui.icons';
import {Form} from '@youtoken/ui.form-elements';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {FeatureLoanCreateForm} from './state';
import {
  Ticker,
  ConversionTicker,
  Term,
  TermDetails,
  TP,
  Agree,
  Section,
  LoanRepay,
} from '../components';

export const LoanRegularCreateForm: React.FC<
  React.ComponentProps<typeof Section>
> = cell<React.ComponentProps<typeof Section>>(props => {
  const {t} = useTranslation();

  const isMobile = useIsMobile();

  const feature = FeatureLoanCreateForm.use({});

  useEffect(() => {
    DATA_LAYER.trackStrict('loan-regular-create-attempt', {});

    feature.restore();
    return () => {
      feature.reset();
    };
  }, []);

  if (!feature.restored) {
    return <DefaultSuspenseFallback />;
  }

  return (
    <Section p={0} {...props}>
      <Form form={feature}>
        {/* CONTENT */}
        <Section p={0} flexGrow={1} zIndex={1}>
          <Section
            flexDirection={{tablet: 'row', default: 'column'}}
            borderBottomWidth={1}
            zIndex={1}
          >
            <Box
              flexGrow={1}
              flexShrink={0}
              flexBasis={{tablet: 0, default: 'auto'}}
              mb={{tablet: 0, default: 20}}
              zIndex={2}
            >
              <Ticker />
            </Box>
            {!isMobile && (
              <Box alignItems="center" mx={15} mt={41}>
                <Icon name="convert" color="$text-03" />
              </Box>
            )}
            <Box
              flexGrow={1}
              flexShrink={0}
              flexBasis={{tablet: 0, default: 'auto'}}
              zIndex={1}
            >
              <ConversionTicker />
            </Box>
          </Section>
          <Term borderBottomWidth={1} />
          <TermDetails borderBottomWidth={feature.canSetTP ? 1 : 0} />
          {feature.canSetTP && <TP />}
        </Section>
        {/* FOOTER */}
        <Section borderTopWidth={1}>
          <LoanRepay />
          <Agree p={0} mb={16} />
          <Button
            size="large"
            loading={feature.submitting}
            onPress={feature.form.instance.onSubmit}
          >
            {t('surface.loans.create_form.submit')}
          </Button>
        </Section>
      </Form>
    </Section>
  );
});
