import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {CardItem} from '@youtoken/ui.resource-cards';
import {Box, Text} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {IconPaymentMethod, IconPaymentMethodName} from '@youtoken/ui.icons';
import {observer} from 'mobx-react';

interface ManagePaymentMethodsSurfaceProps {
  item: CardItem;
  onPressRemove: (id: string) => void;
}

export const ManageCardItemComponent: React.FC<ManagePaymentMethodsSurfaceProps> =
  observer(({item, onPressRemove}) => {
    const {t} = useTranslation();
    const {id, last4, expMonth, expYear, brand} = item;
    const iconName: IconPaymentMethodName =
      brand.toLowerCase() === 'visa' ? 'visa' : 'mastercard';

    return (
      <Box borderWidth={1} borderColor="$ui-01" borderRadius={6} mb={16}>
        <Box flexDirection="row" p={24} justifyContent="space-between">
          <Box flexDirection="row" alignItems="center">
            <IconPaymentMethod
              name={iconName}
              // NOTE: visa icon requires a non-standard color
              // @ts-ignore
              color={iconName === 'visa' ? '#173AC3' : undefined}
              size={20}
            />
            <Box
              width={3}
              height={3}
              borderRadius={3}
              backgroundColor="$text-01"
              ml={16}
              mr={3}
            />
            <Text>{last4}</Text>
          </Box>
          <Text variant="$body-02" color="$text-02">
            {expMonth.toString() + '/' + expYear.toString()}
          </Text>
        </Box>
        <Separator />
        <Box flexDirection="row" justifyContent="flex-end" p={8}>
          <Button
            icon="remove"
            type="ghost"
            size="small"
            ml={12}
            onPress={() => onPressRemove(id)}
            testID={`${last4}_CARD_REMOVE_CARD_BUTTON`}
          >
            {t('surface.wallets.item.remove')}
          </Button>
        </Box>
      </Box>
    );
  });
