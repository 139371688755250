import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;
const getUniqueId = () => uniqueId++;
export const CryptoWithdrawIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.215 2.605a.6.6 0 01.405.654l-.025.105-5.926 17.777a.6.6 0 01-1.062.153l-.053-.095-3.518-7.738a.6.6 0 00-.204-.244l-.094-.054L3 9.646a.6.6 0 01-.04-1.072l.099-.043 17.777-5.926a.6.6 0 01.38 0zm-3.747 2.853L7.071 9.105a.3.3 0 00-.024.556l4.291 1.927 6.13-6.13zm1.273 1.273l-6.13 6.13 1.928 4.292a.3.3 0 00.556-.024l3.646-10.398z"
          fill="#2979FF"
        />
      </Svg>
    );
  }
);
