import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const FilterIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 9a3.001 3.001 0 002.905-2.25H17.5a.75.75 0 000-1.5h-6.595a3.001 3.001 0 00-5.81 0H2.5a.75.75 0 000 1.5h2.595A3.001 3.001 0 008 9zm0-1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm6.905 7.25a3.001 3.001 0 01-5.81 0H2.5a.75.75 0 010-1.5h6.595a3.001 3.001 0 015.81 0H17.5a.75.75 0 010 1.5h-2.595zM13.5 14a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
          fill={color}
        />
      </Svg>
    );
  }
);
