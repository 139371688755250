// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {
  type AxiosError,
  ERROR_TYPE,
  isRecaptchaError,
} from '@youtoken/ui.errors';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';

export const handleFormFieldsErrors = (
  form: MobxReactForm,
  axiosError: AxiosError,
  fieldsNames: {[key: string]: string} = {}
) => {
  const error = axiosError?.response?.data;

  if (error) {
    Object.keys(error)
      .filter(k => k !== 'i18n')
      .forEach(fieldName => {
        const fieldNameFront = fieldsNames[fieldName] || fieldName;
        const fieldError = extractErrorFromResponse(error, fieldName);

        if (fieldError) {
          try {
            form.$(fieldNameFront).invalidate(fieldError);
          } catch (e) {}
        }
      });
  }
};

export const handleFormSubmitError = (
  form: MobxReactForm,
  axiosError: AxiosError,
  fieldsNames: {[key: string]: string} = {},
  catchNetworkError?: () => void,
  showLocalNotification = true
) => {
  const error = axiosError?.response?.data;

  const _error = extractErrorFromResponse(error, '_error');
  const _errorTranslated = getTranslatedValidationMessage(_error);

  if (
    !_errorTranslated &&
    typeof catchNetworkError === 'function' &&
    [
      ERROR_TYPE.NETWORK_ERROR,
      ERROR_TYPE.NETWORK_ERROR_SERVER,
      ERROR_TYPE.NETWORK_ERROR_TIMEOUT,
    ].includes(axiosError?.__type)
  ) {
    catchNetworkError();
    return;
  }

  if (isRecaptchaError(error)) {
    return;
  }

  if (showLocalNotification) {
    LOCAL_NOTIFICATIONS.error({
      text: _errorTranslated || i18n.t('common.errors.smth_went_wrong'),
    });
  }

  handleFormFieldsErrors(form, axiosError, fieldsNames);

  // this is the important part. I think this erros are still reported (via some global error handler?)
  // so we will report them explicitly
  axiosError.name = `FormSubmitError: ${axiosError.name}`;
  axiosError.__sentry = axiosError.__sentry || {};
  axiosError.__sentry.fingerprint = axiosError.__sentry.fingerprint
    ? ['form-submit-error', ...axiosError.__sentry.fingerprint]
    : ['form-submit-error', '{{ default }}'];

  throw axiosError;
};
