import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const VideoIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26 8a4 4 0 014 4v2.398l4.75-3.798A2 2 0 0138 12.16v15.68a2 2 0 01-3.25 1.562L30 25.6V28a4 4 0 01-4 4H6a4 4 0 01-4-4V12a4 4 0 014-4h20zm0 3H6a1 1 0 00-.984.82L5 12v16a1 1 0 00.82.984L6 29h20a1 1 0 00.984-.82L27 28V12a1 1 0 00-.82-.984L26 11zm9 14.756V14.24l-7.2 5.758 7.2 5.758z"
          fill={color}
        />
      </Svg>
    );
  }
);
