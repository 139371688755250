import * as React from 'react';
import {observer} from 'mobx-react';
import {Combobox} from '@youtoken/ui.combobox';
import {Box, type BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon, LogoColored} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {InstrumentElement} from './elements';
import type {InstrumentItem} from './types';

export interface InstrumentSelectorProps extends BoxProps {
  instrument: InstrumentItem;
  items: InstrumentItem[];
  onChange: (item: InstrumentItem) => void;
}

export const InstrumentSelector: React.FC<InstrumentSelectorProps> = observer(
  ({instrument, items, onChange, ...boxProps}) => {
    const {t} = useTranslation();
    const [showComboBox, setShowComboBox] = React.useState(false);

    const formattedItems = React.useMemo(() => {
      return items.map((item, index) => ({
        ...item,
        key: `${item.value}${index}`, // NOTE: for type compatibility
        label: item.value, // NOTE: for type compatibility
      }));
    }, [items]);

    const handleOpen = React.useCallback(() => {
      setShowComboBox(true);
    }, []);

    const handleClose = React.useCallback(() => {
      setShowComboBox(false);
    }, []);

    const handleChange = React.useCallback(
      (val: string) => {
        const instrumentItem = items.find(item => item.value === val);

        if (!instrumentItem) {
          return;
        }

        onChange(instrumentItem);
      },
      [items, onChange]
    );

    if (!instrument) {
      return null;
    }

    return (
      <Box flexDirection="row" justifyContent="center" {...boxProps}>
        {!showComboBox && (
          <TouchableBox onPress={handleOpen}>
            <Box flexDirection="row" alignItems="center">
              <Box mr={8}>
                <LogoColored name={instrument.baseTicker as any} size={24} />
              </Box>
              <Text weight="bold" testID="HODL_CREATE_INSTRUMENT">
                {instrument.value.toUpperCase()}
              </Text>
              <Box width={16} height={16} ml={2}>
                <Icon name="expand" />
              </Box>
            </Box>
          </TouchableBox>
        )}
        {showComboBox && (
          <Combobox
            initialIsOpen
            items={formattedItems}
            // @ts-ignore
            ItemComponent={InstrumentElement}
            onChange={handleChange}
            onClose={handleClose}
            placeholder={t('common.placeholder.search')}
            size="medium"
            testID="INSTRUMENT_SELECTOR_FIELD"
          />
        )}
      </Box>
    );
  }
);
