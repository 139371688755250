import * as React from 'react';
import {ExchangeForm} from '@youtoken/ui.surfaces-wallets';
import {Modal} from '../_common';

interface ModalConversionProps extends React.ComponentProps<typeof Modal> {
  ticker: string;
  toTicker?: string;
  initialAmount?: number;
  dl_source?: string;
}

export const ModalConversion: React.FC<ModalConversionProps> = ({
  ticker,
  toTicker,
  initialAmount,
  dl_source,
  ...otherProps
}) => {
  return (
    <Modal minHeight={570} {...otherProps}>
      <ExchangeForm
        showHeader
        ticker={ticker}
        conversionTicker={toTicker}
        initialAmount={initialAmount}
        dl_source={dl_source}
      />
    </Modal>
  );
};
