import * as React from 'react';
import {observer} from 'mobx-react';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {Box} from '@youtoken/ui.primitives';
import {FramesComponent} from './FramesComponent';
import {CardsForm} from './state';

interface AddPaymentMethodSurfaceProps {
  onPressCancel: () => void;
}

export const AddPaymentMethodSurface: React.FC<AddPaymentMethodSurfaceProps> =
  observer(({onPressCancel}) => {
    const form = makeForm(() => {
      return new CardsForm({
        onSuccess: onPressCancel,
      });
    });

    return (
      <Box flex={1}>
        <Form form={form}>
          <FramesComponent onPressCancel={onPressCancel} />
        </Form>
      </Box>
    );
  });
