import * as React from 'react';
import {Path, Svg, SvgProps} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const HodlIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number;
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.9 3.6a.9.9 0 00-1.8 0V18a2.7 2.7 0 002.7 2.7H21a.9.9 0 000-1.8H4.8a.9.9 0 01-.9-.9V3.6zm10.8 1.8a.9.9 0 00.9.9h2.627L13.8 10.727l-1.94-1.94a1.5 1.5 0 00-2.12 0l-3.776 3.777a.9.9 0 101.272 1.272l3.564-3.563 1.94 1.94a1.5 1.5 0 002.12 0l4.64-4.64V10.2a.9.9 0 001.8 0V5.4a.9.9 0 00-.9-.9h-4.8a.9.9 0 00-.9.9z"
          fill={color}
        />
      </Svg>
    );
  }
);
