import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const FtpIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.454 4.875a2.5 2.5 0 11-.853 4.85l-.022.025-4.345 4.345a.75.75 0 01-.976.072l-.084-.072-1.97-1.81-3.375 3.374A.884.884 0 012.5 14.5l.08-.09 4.094-4.095a.75.75 0 01.976-.073l.084.073 1.97 1.81 3.554-3.555a2.5 2.5 0 012.196-3.695zm0 1.5a1 1 0 100 2 1 1 0 000-2zM10.25 6.5a.75.75 0 010 1.5h-1.5a.75.75 0 010-1.5h1.5zm-4.5 0a.75.75 0 010 1.5h-1.5a.75.75 0 010-1.5h1.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
