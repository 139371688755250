import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type SortingTabProps} from './types';
import {getArrowColor, getTabHeightBySize} from './utils';

export const SortingTab: React.FC<SortingTabProps> = observer(
  ({item, size = 'medium', isActive, onChange}) => {
    const tabHeight = getTabHeightBySize(size);

    const handlePress = React.useCallback(() => {
      let newDirection = item.direction;
      if (isActive) {
        newDirection = item.direction === 'asc' ? 'desc' : 'asc';
      }

      onChange({...item, direction: newDirection});
    }, [isActive, onChange, item]);

    return (
      <Box accessibilityRole="tab" accessible={false}>
        <TouchableBox
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          height={tabHeight}
          pl={12}
          pr={8}
          mr={8}
          backgroundColor={isActive ? '$interactive-02' : '$transparent'}
          borderRadius={6}
          onPress={handlePress}
          testID={item.testID}
        >
          <Box flexDirection="row" mr={4}>
            <Text
              accessible={false}
              color={isActive ? '$text-05' : '$text-02'}
              variant="$body-02-medium-accent"
            >
              {item.label}
            </Text>
          </Box>

          <Box flexDirection="row" mr={-8}>
            <Icon
              name="sorting"
              style={{
                transform: [{translateX: -4}],
              }}
              size={16}
              color={getArrowColor({
                position: 'first',
                direction: item.direction,
                isActive,
              })}
            />
            <Box flexDirection="row" ml={-8}>
              <Icon
                name="sorting"
                size={16}
                style={{
                  transform: [{rotate: '180deg'}, {translateX: 4}],
                }}
                color={getArrowColor({
                  position: 'second',
                  direction: item.direction,
                  isActive,
                })}
              />
            </Box>
          </Box>
        </TouchableBox>
      </Box>
    );
  }
);
