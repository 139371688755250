import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {IconSmall} from '@youtoken/ui.icons';
import {type PriceRangeBarProps} from '../types';

export const PriceRangeBar: React.FC<PriceRangeBarProps> = ({
  currentPricePercentFormatted,
  iconSize = 16,
}) => {
  return (
    <Box
      position="relative"
      height={8}
      mb={12}
      backgroundColor="$interactive-03"
      borderRadius={8}
    >
      <Box
        position="absolute"
        top={5}
        ml={-iconSize / 2}
        left={currentPricePercentFormatted}
      >
        <IconSmall size={iconSize} name="gain" color="$interactive-01" />
      </Box>
    </Box>
  );
};
