import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';

export const BorderBoxWrapper: React.FC<BoxProps> = ({children, ...props}) => {
  return (
    <Box
      backgroundColor="$ui-background"
      borderColor="$ui-01"
      borderBottomWidth={1}
      borderTopWidth={{
        default: 0,
        desktop: 1,
      }}
      borderLeftWidth={{
        default: 0,
        desktop: 1,
      }}
      borderRightWidth={{
        default: 0,
        desktop: 1,
      }}
      borderRadius={{
        default: 0,
        desktop: 10,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
