import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {type RewardsOverviewType} from '../types';
import {RewardsHowItWorksModalTrigger} from '../../..';
import {RewardsOverviewProgressAmount} from './Amount';
import {RewardsOverviewProgressSlider} from './Slider';

interface RewardsOverviewProgressProps {
  type?: RewardsOverviewType;
}

export const RewardsOverviewProgress: React.FC<
  RewardsOverviewProgressProps
> = ({type = 'challenge'}) => {
  return (
    <Box bg="$ui-background" p={8} m={4} borderRadius={12}>
      <RewardsOverviewProgressAmount />
      <RewardsOverviewProgressSlider type={type} />
      {type === 'challenge' && <RewardsHowItWorksModalTrigger />}
    </Box>
  );
};
