import * as React from 'react';
import {
  Box,
  Text,
  TouchableBox,
  TouchableBoxProps,
} from '@youtoken/ui.primitives';
import {DarkThemeDemo, LightThemeDemo} from './ThemeDemo';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';

const ThemeItem: React.FC<{
  themeName: string;
  active?: boolean;

  disabled?: boolean;
  onPress: TouchableBoxProps['onPress'];
}> = ({
  themeName,

  active = false,
  children,
  disabled,
  onPress,
}) => {
  return (
    <TouchableBox
      bg={active ? '$interactive-02' : '$ui-01'}
      borderWidth={2}
      borderColor={active ? '$interactive-01' : '$ui-01'}
      borderRadius={12}
      mr={8}
      flex={1}
      py={24}
      alignItems="center"
      justifyContent="center"
      disabled={disabled}
      onPress={onPress}
      testID={`APPEARANCE_THEME_ITEM_${themeName.toUpperCase()}`}
    >
      {children}
      <Text my={8}>{themeName}</Text>
      <Box
        width={20}
        height={20}
        borderRadius={10}
        borderColor={active ? '$interactive-01' : '$ui-04'}
        borderWidth={active ? 5 : 2}
        backgroundColor={active ? '$text-04' : '$ui-background'}
      />
    </TouchableBox>
  );
};

export const AppearanceSwitcher: React.FC<{
  disabled: boolean;
  selectedTheme: 'light' | 'dark' | 'system';
  setUserPreferredTheme: (theme: 'light' | 'dark') => void;
}> = observer(({selectedTheme, setUserPreferredTheme}) => {
  const {t} = useTranslation();

  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <ThemeItem
        themeName={t('surface.appearance.color_themes.light')}
        active={selectedTheme === 'light'}
        onPress={() => setUserPreferredTheme('light')}
      >
        <LightThemeDemo />
      </ThemeItem>
      <ThemeItem
        themeName={t('surface.appearance.color_themes.dark')}
        active={selectedTheme === 'dark'}
        onPress={() => setUserPreferredTheme('dark')}
      >
        <DarkThemeDemo />
      </ThemeItem>
    </Box>
  );
});
