import {AuthMeResponse} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export const onSuccessSignIn = (
  authMeResourceData: AuthMeResponse,
  verificationResource: VerificationResource
) => {
  // NOTE: verificationResource without fallback can be used only when user is signed in
  verificationResource.args.withFallback = false;

  return verificationResource.refetch().then(() => {
    if (
      authMeResourceData.challenges?.some(
        challenge => challenge.type === 'disclosuresSignature'
      )
    ) {
      return SHARED_ROUTER_SERVICE.navigate('ReSignDisclosures', {});
    }

    const redirectDestination = verificationResource.accountVerificationComplete
      ? 'WalletsList'
      : 'PleaseVerify';

    SHARED_ROUTER_SERVICE.navigate(redirectDestination, {});
  });
};
