import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface RewardsStatisticsProps {
  totalEarnedUSD: number;
  referralsCount: number;
}

export const RewardsStatistics: React.FC<RewardsStatisticsProps & BoxProps> =
  observer(({totalEarnedUSD, referralsCount, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        p={{default: 16, phone: 24}}
        {...boxProps}
      >
        <Box alignItems="flex-start">
          <Text variant="$body-01" color="$text-02">
            {t('surface.referral_program.referrals.total_bonus')}
          </Text>
          <Heading variant="$heading-01">${totalEarnedUSD}</Heading>
        </Box>
        <Box alignItems="flex-end">
          <Text variant="$body-01" color="$text-02">
            {t('surface.referral_program.referrals.joined')}
          </Text>
          <Heading variant="$heading-01">{referralsCount}</Heading>
        </Box>
      </Box>
    );
  });
