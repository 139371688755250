import React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text, TouchableBox} from '@youtoken/ui.primitives';
import {Badge, DeviceImage, DeviceLogo} from '@youtoken/ui.elements';
import {DeviceIconName, Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SecurityDeviceData} from '@youtoken/ui.state-security';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';

export interface DevicesListItemProps extends BoxProps {
  item: SecurityDeviceData;
  isLast?: boolean;
  index?: number;
}

export const DevicesListItem: React.FC<DevicesListItemProps> = observer(
  ({item, isLast = false, index, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      isCurrent,
      displayLastUsedRelativeTime,
      displayName,
      displayLocation,
      id,
      displayDeviceIcon,
      displayDeviceLogo,
    } = item;

    const handlePressDevice = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('DeviceModal', {id});
    }, [id]);

    return (
      <TouchableBox
        onPress={handlePressDevice}
        flexDirection="row"
        borderBottomColor="$ui-01"
        borderBottomWidth={{default: 1, tablet: isLast ? 0 : 1}}
        px={{
          default: 16,
          phone: 24,
        }}
        testID={`DEVICE_${index}`}
        {...boxProps}
      >
        <Box alignSelf="center" mr={19}>
          <DeviceImage
            deviceIcon={displayDeviceIcon as DeviceIconName}
            deviceLogo={displayDeviceLogo as DeviceLogo}
          />
        </Box>
        <Box my={24}>
          <Text
            variant="$body-01-high-accent"
            color="$text-01"
            mb={8}
            testID="DEVICE_NAME"
          >
            {displayName}
          </Text>
          <Box flexDirection="row" mb={8}>
            <Icon name="location" size={16} color="$text-02" />
            <Text
              variant="$body-01"
              color="$text-02"
              ml={2}
              testID="DEVICE_LOCATION"
            >
              {displayLocation}
            </Text>
          </Box>
          {isCurrent ? (
            <Badge
              variant="success"
              size="small"
              type="secondary"
              icon="check"
              testID="DEVICE_CURRENT"
            >
              {t('surface.trusted_devices.devices.active_badge')}
            </Badge>
          ) : (
            <Text
              variant="$body-01"
              color="$text-02"
              mb={8}
              testID="DEVICE_LAST_USED"
            >
              {displayLastUsedRelativeTime}
            </Text>
          )}
        </Box>
      </TouchableBox>
    );
  }
);
