import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button, type IButtonProps} from '@youtoken/ui.buttons';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const AdjustPendingButton: React.FC<IButtonProps> = ({onPress}) => {
  const {t} = useTranslation();

  const handlePress = React.useCallback(() => {
    DATA_LAYER.trackStrict('hodl-change-pending-attempt', {});

    onPress?.();
  }, [onPress]);

  return (
    <Button
      size="large"
      width="100%"
      icon="ftp"
      type="secondary"
      onPress={handlePress}
      testID="ADJUST_PENDING_BUTTON"
    >
      {t('surface.hodls.item.adjust_pending_button')}
    </Button>
  );
};
