import * as React from 'react';
import {CreateHODLFormClass} from '../../CreateHODLForm';
import {observer} from 'mobx-react';
import {
  PredefinedAmountButtons,
  TickerAndAmountInput,
} from '@youtoken/ui.ticker-and-amount-input';
import {useForm, Label} from '@youtoken/ui.form-elements';
import {
  Box,
  BoxProps,
  Text,
  TouchableText,
  useIsDesktop,
} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {ValidationMessageComponent} from '@youtoken/ui.validation-messages';

interface AmountSectionProps extends BoxProps {}

export const AmountSection: React.FC<AmountSectionProps> = observer(
  ({...boxProps}) => {
    const {
      form: {
        inputAmount,
        inputTicker,
        setInputTicker,
        instance,
        allSourceAmount,
        setAllSourceToAmount,
        amountListFormatted,
      },
      view: {
        sourceWallets,
        showCollateral,
        collateralValueFormatted,
        collateralTickerFormatted,
        inputTickerFormatted,
        showEquivalentAmount,
        inputAmountEquivalentFormatted,
        mainCurrencyTickerFormatted,
      },
    } = useForm<CreateHODLFormClass>();

    const {t} = useTranslation();
    const isDesktop = useIsDesktop();

    return (
      <Box {...boxProps} zIndex={10}>
        <Box flexDirection={isDesktop ? 'row' : 'column'} zIndex={10}>
          <Box
            zIndex={50}
            flexBasis={isDesktop ? 0 : 'auto'}
            flexGrow={1}
            mb={showCollateral || showEquivalentAmount ? 8 : 16}
          >
            <Box flexDirection="row" justifyContent="space-between" mb={8}>
              <Label>{t('surface.hodls.hodl_form.amount_label')}</Label>
              <TouchableText
                variant="$body-02"
                color="$text-05"
                onPress={setAllSourceToAmount}
                testID="SET_ALL_SOURCE_TO_AMOUNT_BUTTON"
              >
                <Trans
                  t={t}
                  i18nKey="surface.hodls.hodl_form.amount.action.all_source"
                  components={{
                    Bold: <Text variant="$body-02-medium-accent" />,
                  }}
                  values={{
                    amount: allSourceAmount,
                    ticker: inputTickerFormatted,
                  }}
                  defaults="All <Bold>{{amount}}</Bold> {{ticker}}"
                />
              </TouchableText>
            </Box>

            <TickerAndAmountInput
              focusAfterChangeTicker={false}
              wallets={sourceWallets}
              ticker={inputTicker}
              amount={inputAmount}
              // onFocus={Keyboard.dismiss}
              onChangeTicker={setInputTicker}
              onChangeAmount={instance.$('inputAmount').onChange}
              setCaretPositionToEndOnFocus
            />
            {!!instance.$('inputAmount').error && (
              <Box mt={10}>
                <Text color="$danger-01" variant="$body-02">
                  <ValidationMessageComponent
                    error={instance.$('inputAmount').get('error')}
                  />
                </Text>
              </Box>
            )}
          </Box>
        </Box>
        {(showCollateral || showEquivalentAmount) && (
          <Box
            flexDirection="row"
            justifyContent="space-between"
            mb={16}
            flexWrap="wrap"
          >
            <Box>
              {showCollateral && (
                <Text color="$text-02" variant="$body-02">
                  {t('surface.hodls.hodl_form.collateral_amount_label') +
                    ' ' +
                    collateralValueFormatted +
                    ' ' +
                    collateralTickerFormatted}
                </Text>
              )}
            </Box>
            <Box>
              {showEquivalentAmount && (
                <Text color="$text-02" variant="$body-02">
                  {inputAmountEquivalentFormatted +
                    ' ' +
                    mainCurrencyTickerFormatted}
                </Text>
              )}
            </Box>
          </Box>
        )}
        <PredefinedAmountButtons
          amountList={amountListFormatted}
          mt={4}
          currentAmount={inputAmount}
          onPressAmount={instance.$('inputAmount').onChange}
          buttonsSize="small"
        />
      </Box>
    );
  }
);
