import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {AdaIcon as ada} from './Ada';
import {AgixIcon as agix} from './Agix';
import {ApeIcon as ape} from './Ape';
import {AsrIcon as asr} from './Asr';
import {AvaxIcon as avax} from './Avax';
import {BnbIcon as bnb} from './Bnb';
import {DaiIcon as dai} from './Dai';
import {DotIcon as dot} from './Dot';
import {ManaIcon as mana} from './Mana';
import {PepeIcon as pepe} from './Pepe';
import {SolIcon as sol} from './Sol';
import {UsdcIcon as usdc} from './Usdc';

export type IconName =
  | 'ada'
  | 'agix'
  | 'ape'
  | 'asr'
  | 'avax'
  | 'bnb'
  | 'dai'
  | 'dot'
  | 'mana'
  | 'pepe'
  | 'sol'
  | 'usdc';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  ada,
  agix,
  ape,
  asr,
  avax,
  bnb,
  dai,
  dot,
  mana,
  pepe,
  sol,
  usdc,
};
