import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {SimpleStorage} from './SimpleStorage';
import {SecureStorage} from './SecureStorage';

export {type SECURE_KEYS} from './SecureStorage/types';

export const SIMPLE_STORAGE = __SERVICE_REGISTRY__.registerService(
  'SIMPLE_STORAGE',
  SimpleStorage,
  require('../package.json').version
);

export const SECURE_STORAGE = __SERVICE_REGISTRY__.registerService(
  'SECURE_STORAGE',
  SecureStorage,
  require('../package.json').version
);
