import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {FiatDepositWizardChooseMethodBase} from './index.base';

export const FiatDepositWizardChooseMethod: React.FC = observer(() => {
  return (
    <Box pb={32}>
      <FiatDepositWizardChooseMethodBase />
    </Box>
  );
});
