import * as React from 'react';
import {observer} from 'mobx-react';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {FieldErrorMessage, Label, useForm} from '@youtoken/ui.form-elements';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {FeatureLoanCreateForm} from '../../LoanRegularCreateForm/state';
import {FeatureLoanCreateLandingForm} from '../../LoanRegularCreateLandingForm/state';
import {Link} from '@youtoken/ui.elements';
import {InputAccessoryView, Platform} from 'react-native';
import {Button} from '@youtoken/ui.buttons';

export interface TickerProps extends BoxProps {
  showAllAmount?: boolean;
  showAccessoryView?: boolean;
}

export const Ticker: React.FC<TickerProps> = observer(
  ({showAllAmount = true, showAccessoryView = true, ...rest}) => {
    const {t} = useTranslation();

    const {form} = useForm<
      FeatureLoanCreateForm | FeatureLoanCreateLandingForm
    >();

    const inputAccessoryViewID = 'loanCollaterallId';

    const isShowInputAccessoryView =
      showAccessoryView &&
      Platform.select({
        ios: true,
        default: false,
      });

    return (
      <Box zIndex={20} width="100%" {...rest}>
        <Box flexDirection="row" justifyContent="space-between" mb={8}>
          <Label>
            <Label>{t('surface.loans.create_form.amount')}</Label>
          </Label>
          {showAllAmount && <AllWalletLink />}
        </Box>
        <TickerAndAmountInput
          inputAccessoryViewID={inputAccessoryViewID}
          wallets={form.tickers}
          precision={getCoinDecimalPrecisionForLoans(form.ticker)}
          ticker={form.ticker}
          amount={form.amount}
          onChangeTicker={form.tickerOnChange}
          onChangeAmount={form.amountOnChange}
        />
        {isShowInputAccessoryView && (
          <TickerAccessoryView nativeID={inputAccessoryViewID} />
        )}
        <FieldErrorMessage visible={form.collateralHasError}>
          {form.collateralError}
        </FieldErrorMessage>
      </Box>
    );
  }
);

export const TickerAccessoryView: React.FC<
  React.ComponentProps<typeof InputAccessoryView>
> = observer(props => {
  const {form} = useForm<FeatureLoanCreateForm>();

  return (
    <InputAccessoryView {...props}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        p={5}
        bg="$ui-background"
      >
        <Box flexDirection="row">
          <Button
            size="small"
            onPress={() => {
              form.amountOnChange(
                form.wallet?.amount
                  .mul(0.2)
                  .toFixed(getCoinDecimalPrecisionForLoans(form.ticker))
              );
            }}
            mr={5}
          >
            20%
          </Button>
          <Button
            size="small"
            onPress={() => {
              form.amountOnChange(
                form.wallet?.amount
                  .mul(0.5)
                  .toFixed(getCoinDecimalPrecisionForLoans(form.ticker))
              );
            }}
          >
            50%
          </Button>
        </Box>
        <Button
          size="small"
          onPress={() => {
            form.amountOnChange(
              form.wallet?.amount.toFixed(
                getCoinDecimalPrecisionForLoans(form.ticker)
              )
            );
          }}
        >
          {}
          {form.wallet?.amount.toFixed(
            getCoinDecimalPrecisionForLoans(form.ticker)
          )}{' '}
          {form.wallet?.ticker.toUpperCase()}
        </Button>
      </Box>
    </InputAccessoryView>
  );
});

export const AllWalletLink: React.FC<
  Omit<React.ComponentProps<typeof Link>, 'onPress'>
> = observer(props => {
  const {t} = useTranslation();

  const {form} = useForm<FeatureLoanCreateForm>();

  return (
    <Link
      onPress={() => {
        form.amountOnChange(form.walletAmount);
      }}
      {...props}
    >
      <Text variant="$body-02">
        {t('surface.loans.loan_pay_own_funds_form.conversion_ticker_all')}{' '}
        <Text variant="$body-02-medium-accent">
          {
            form.tickers.find(wallet => wallet.ticker === form.ticker)
              ?.amountFormatted
          }
        </Text>{' '}
        {form.ticker.toUpperCase()}
      </Text>
    </Link>
  );
});
