import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from './SwitchWithNotFound';
import {authRequired} from './authRequired';
import {AccountStatsPage} from '../pages';

const AccountStatsWrapped = authRequired(AccountStatsPage);

export const AccountStatsRoutes: React.FC<RouteComponentProps> = ({
  match: {path},
}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}`} component={AccountStatsWrapped} />
    </SwitchWithNotFound>
  );
};
