import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowAprDailyProps {
  item: LoanRegularV2;
}

export const LoanItemDataTableRowAprDaily: React.FC<LoanItemDataTableRowAprDailyProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    if (!item.isOpened) {
      return null;
    }

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_APR_DAILY"
        left={t('surface.loans.item.table.apr_daily')}
        leftTooltipText={t('surface.loans.item.table.apr_daily_hint', {
          period: item.settlementPeriodFormatted,
          percent: item.aprBySettlementPeriodFormatted,
          multiplier: item.penaltyAprBySettlementPeriodFormatted,
        })}
        right={
          <Text
            testID="LOAN_ITEM_TABLE_ROW_APR_DAILY_VALUE"
            variant="$body-02"
            color="$text-02"
          >
            <Trans
              t={t}
              i18nKey="surface.loans.item.table.apr_daily_value"
              components={{
                Strong: (
                  <Text variant="$body-02-high-accent" color="$text-01" />
                ),
              }}
              values={{
                period: item.settlementPeriodFormatted,
                ticker: item.borrowedTickerFormatted,
                percent: item.aprBySettlementPeriodFormatted,
                amount: item.settlementFeeAmountFormatted,
              }}
            />
          </Text>
        }
      />
    );
  });
