import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Heading} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {IconSocial} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const FollowUs: React.FC<BoxProps> = observer(({...props}) => {
  const {t} = useTranslation();

  return (
    <Box {...props}>
      <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
        {t('surface.more.legal.subtitle.follow')}
      </Heading>
      <Box flexDirection="row">
        <Link url="https://twitter.com/youhodler">
          <Box mr={10} alignItems="center" justifyContent="center">
            <IconSocial
              name="twitter"
              size={16}
              opacity={0.6}
              color="$text-01"
            />
          </Box>
        </Link>
        <Link url="https://t.me/youhodler_official">
          <Box mr={10} alignItems="center" justifyContent="center">
            <IconSocial
              name="telegram"
              size={16}
              opacity={0.6}
              color="$text-01"
            />
          </Box>
        </Link>
        <Link url="https://www.facebook.com/YouHodler/">
          <Box mr={10} alignItems="center" justifyContent="center">
            <IconSocial
              name="facebook"
              size={16}
              opacity={0.6}
              color="$text-01"
            />
          </Box>
        </Link>
        <Link url="https://www.linkedin.com/company/youhodler/">
          <Box mr={10} alignItems="center" justifyContent="center">
            <IconSocial
              name="linkedin"
              size={16}
              opacity={0.6}
              color="$text-01"
            />
          </Box>
        </Link>
        <Link url="https://www.instagram.com/youhodler/">
          <Box mr={10} alignItems="center" justifyContent="center">
            <IconSocial
              name="instagram"
              size={16}
              opacity={0.6}
              color="$text-01"
            />
          </Box>
        </Link>
        <Link url="https://www.youtube.com/channel/UC-Kxvqm8Q-l_WGxQL0VpEEQ">
          <Box mr={5} alignItems="center" justifyContent="center">
            <IconSocial
              name="youtube"
              size={16}
              opacity={0.6}
              color="$text-01"
            />
          </Box>
        </Link>
      </Box>
    </Box>
  );
});
