import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Modal} from '@youtoken/ui.modal';
import {ReferralsHowItWorksSmart} from '../../smarts';

export const ReferralsHowItWorksModal: React.FC<
  React.ComponentProps<typeof Modal>
> = observer(modalProps => {
  return (
    <Modal snapPoints={['90%']} nativeStackBehavior="push" {...modalProps}>
      <ReferralsHowItWorksSmart
        showShareRefCode={Platform.select({
          default: false,
          native: true,
        })}
      />
    </Modal>
  );
});
