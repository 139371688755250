import * as React from 'react';
import {SvgProps, Svg, G, Path} from 'react-native-svg';

export const ArrConnectionImage = React.memo(
  ({color = '#212121', ...props}: SvgProps) => {
    return (
      <Svg height="1em" viewBox="0 0 24 47" width="1em" {...props}>
        <G
          fill="none"
          fillRule="evenodd"
          stroke={color}
          strokeLinecap="round"
          strokeOpacity={0.4}
        >
          <Path
            d="M21.5 44.5h-16a5 5 0 01-5-5v-34a5 5 0 015-5h18"
            strokeDasharray="2 3"
          />
          <Path d="M21.5 46.5l2-2-2-2" strokeLinejoin="round" />
        </G>
      </Svg>
    );
  }
);
