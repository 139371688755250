import {primitive, serializable, optional} from 'serializr';
import {observable} from 'mobx';

/**
 * For documentation check link below:
 * https://youhodler.atlassian.net/wiki/spaces/AL/pages/3589603331/UMA+FE+API#GET-%2Fuma%2Finfo---%D0%9F%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F-%D0%BE%D0%B1-UMA-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F
 **/
export class UmaInfoResponse {
  @serializable(optional(primitive()))
  @observable
  uma?: string;

  @serializable(optional(primitive()))
  @observable
  ticker?: string;

  @serializable(primitive())
  @observable
  domain!: string;
}
