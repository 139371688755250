import * as Sentry from '@sentry/react';
import {AppEnv} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {History} from 'history';
import {customErrorDataIntegration} from '@youtoken/ui.sentry';

export const initSentry = (history: History) => {
  Sentry.init({
    debug: false,
    dsn: 'https://5d534a9244c040e7826d4b6e40aa66ef@o179365.ingest.sentry.io/4505239786291200',
    environment:
      ENVIRONMENT.APP_ENV === AppEnv.production ? 'production' : 'development',
    release: process.env.REACT_APP_WEB_VERSION ?? 'NOT_SET',
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      customErrorDataIntegration(),
    ],
    enabled: true,
    sampleRate: 1,
    tracesSampleRate: ENVIRONMENT.APP_ENV === AppEnv.production ? 0.05 : 1,
    profilesSampleRate: ENVIRONMENT.APP_ENV === AppEnv.production ? 0.05 : 1,
    tracePropagationTargets:
      ENVIRONMENT.APP_ENV === AppEnv.production
        ? ['https://app.youhodler.com/api']
        : [],
    autoSessionTracking: true,
    denyUrls: [
      /^(https?:\/\/)?([\w\-]+\.)*pinimg\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*criteo\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*admaxium\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*taboola\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*tiktok\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*customer\.io(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*gstatic\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*googletagmanager\.com(?:\/|$)/i,
      /^(https?:\/\/)?([\w\-]+\.)*google\.com(?:\/|$)/i,
    ],
    // TODO: uncomment when sentry is fixed and we know we dont filter out important errors
    // beforeSend: function (event, hint) {
    //   // filter out UnhandledRejection errors that have no information
    //   if (
    //     event !== undefined &&
    //     event.exception !== undefined &&
    //     event.exception.values !== undefined &&
    //     event.exception.values.length == 1
    //   ) {
    //     var e = event.exception.values[0];
    //     if (
    //       e.type === 'UnhandledRejection' &&
    //       e.value === 'Non-Error promise rejection captured with value: '
    //     ) {
    //       return null;
    //     }
    //   }
    // },
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb.category === 'console') {
        return null;
      }

      return breadcrumb;
    },
    enableTracing: true,
  });
};
