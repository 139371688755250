import * as React from 'react';
import {Modal} from '@youtoken/ui.modal';
import {RewardsBanned} from '../../components';

export const RewardsBannedModal: React.FC<
  React.ComponentProps<typeof Modal>
> = modalProps => {
  return (
    <Modal
      shouldShowHeaderSeparator={false}
      shouldUseAnimatedSnapPoints
      snapPoints={['CONTENT_HEIGHT']}
      webWidth={590}
      webHeight={200}
      {...modalProps}
    >
      <RewardsBanned />
    </Modal>
  );
};
