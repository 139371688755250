import {deserialize} from 'serializr';
import {createStaticResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {BankCardLimitsResponse} from './BankCardLimitsResponse';

export class BankCardLimitsResource extends createStaticResource<
  {cardId: string},
  BankCardLimitsResponse
>({
  getKey: ({cardId}) => `bankCardLimits-${cardId}`,
  getData: ({cardId}) => {
    return TRANSPORT.API.post('/v1/intergiro/cards/top-up/limits', {
      cardId,
    }).then(({data}) => {
      return deserialize(BankCardLimitsResponse, data);
    });
  },
  skipRefreshOnVisible: false,
}) {}
