import * as React from 'react';
import {Helmet} from 'react-helmet';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {Content} from './Content';

export const List: React.FC = cell(() => {
  const {t} = useTranslation();
  const {userEmail} = AuthMeResource.use({});

  return (
    <InnerPageShell>
      <Helmet title={t('surface.loans.tab_title')} />
      <Content />
    </InnerPageShell>
  );
});
