import * as React from 'react';
import {SvgProps, Svg, Path, Rect} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SpinnerIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          opacity={0.65}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.905 1.726a.833.833 0 111.443.833l-1.666 2.888a.833.833 0 11-1.443-.833l1.666-2.888z"
          fill={color}
        />
        <Path
          opacity={0.6}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.214.833a.833.833 0 011.667 0v3.334a.833.833 0 01-1.667 0V.833z"
          fill={color}
        />
        <Path
          opacity={0.1}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.604 4.738a.833.833 0 01.833 1.443L15.55 7.848a.833.833 0 11-.833-1.443l2.887-1.667z"
          fill={color}
        />
        <Path
          opacity={0.15}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.167 9.214a.833.833 0 010 1.667h-3.334a.833.833 0 010-1.667h3.334z"
          fill={color}
        />
        <Path
          opacity={0.2}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.437 13.905a.833.833 0 01-.833 1.443l-2.887-1.666a.833.833 0 11.833-1.443l2.887 1.666z"
          fill={color}
        />
        <Path
          opacity={0.25}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.348 17.604a.833.833 0 01-1.443.833l-1.667-2.887a.833.833 0 111.443-.833l1.667 2.887z"
          fill={color}
        />
        <Rect
          opacity={0.3}
          x={10.881}
          y={20}
          width={1.667}
          height={5}
          rx={0.833}
          transform="rotate(180 10.881 20)"
          fill={color}
        />
        <Path
          opacity={0.35}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.181 18.437a.833.833 0 01-1.443-.833l1.667-2.887a.833.833 0 111.443.833l-1.667 2.887z"
          fill={color}
        />
        <Path
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.56 15.348a.833.833 0 11-.833-1.443l2.886-1.667a.833.833 0 01.833 1.443L2.56 15.348z"
          fill={color}
        />
        <Path
          opacity={0.45}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M.833 10.881a.833.833 0 110-1.667h3.334a.833.833 0 010 1.667H.833z"
          fill={color}
        />
        <Path
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.727 6.181a.833.833 0 11.833-1.443l2.887 1.667a.833.833 0 01-.833 1.443L1.726 6.181z"
          fill={color}
        />
        <Path
          opacity={0.55}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.738 2.56a.833.833 0 111.443-.833l1.667 2.886a.833.833 0 01-1.443.833L4.738 2.56z"
          fill={color}
        />
      </Svg>
    );
  }
);
