import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {HodlHistoryDetailFeature} from '../../features/HodlHistoryDetailFeature';
import {HowHODLWorksSurface} from '../HowHODLWorksSurface';

interface HowHODLWorksInHistorySurfaceSmartProps {
  hodlId: string;
  hodlHistoryItemId: string;
}

export const HowHODLWorksInHistorySurfaceSmart: React.FC<HowHODLWorksInHistorySurfaceSmartProps> =
  cell(({hodlId, hodlHistoryItemId}) => {
    const {historyDetail} = HodlHistoryDetailFeature.use({
      hodlId,
      hodlHistoryItemId,
    });

    return <HowHODLWorksSurface {...historyDetail} />;
  });
