import * as React from 'react';
import {TouchableOpacity} from 'react-native';
import {Box, BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {Icon, LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';
import {type InstrumentItemBaseProps} from '../types';

export const InstrumentItemBase: React.FC<
  InstrumentItemBaseProps & BoxProps
> = ({
  baseTicker,
  baseTickerFormatted,
  baseTickerName,
  quoteTickerFormatted,
  isStarred,
  onPressStar,
  isStarDisabled,
  ...otherProps
}) => {
  return (
    <Box flexDirection="row" alignItems="center" {...otherProps}>
      <Box mr={10}>
        <LogoColored name={baseTicker as LogoColoredIconName} size={32} />
      </Box>
      <Box>
        <Box
          flexDirection="row"
          alignItems="center"
          maxWidth={{default: 160, desktop: undefined}}
        >
          <Heading
            variant="$heading-02"
            // @ts-ignore TS telling that this prop doesn't exist, but it does and work on mobile
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {baseTickerName}
          </Heading>
          {!isStarDisabled && (
            <TouchableOpacity onPress={onPressStar}>
              <Box ml={2} mt={-4}>
                <Icon
                  name={isStarred ? 'star_filled' : 'star'}
                  size={16}
                  color="$text-05"
                />
              </Box>
            </TouchableOpacity>
          )}
        </Box>
        <Text variant="$body-02" color="$text-02">
          {baseTickerFormatted}/{quoteTickerFormatted}
        </Text>
      </Box>
    </Box>
  );
};
