import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Text} from '@youtoken/ui.primitives';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanItemDataTableRowTermProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemDataTableRowTerm: React.FC<LoanItemDataTableRowTermProps> =
  observer(({item}) => {
    const {t} = useTranslation();

    return (
      <InformationTableRow
        testID="LOAN_ITEM_TABLE_ROW_TERM"
        left={t('surface.loans.item.table.term')}
        leftTooltipText={t('surface.loans.item.table.term_hint', {
          days: item.days,
        })}
        right={
          <Text
            testID="LOAN_ITEM_TABLE_ROW_TERM_VALUE"
            variant="$body-02"
            color="$text-02"
          >
            <Trans
              t={t}
              i18nKey="surface.loans.item.table.term_value"
              components={{
                Strong: (
                  <Text variant="$body-02-high-accent" color="$text-01" />
                ),
              }}
              values={{days: item.days}}
            />
          </Text>
        }
      />
    );
  });
