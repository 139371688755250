import {action, computed, observable} from 'mobx';
import {computedFn} from 'mobx-utils';
import {stringify} from 'query-string';
import {deserialize, list, object, primitive, serializable} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {HistoryItemData} from './HistoryItemData';
import {type HistoryListResourceArgs, type HistoryListSection} from './types';

const DEFAULT_LIMIT = 20;
export const HISTORY_LIST_KEY = 'historyList';

export class HistoryData {
  @serializable(primitive())
  @observable
  currentPage!: number;

  @serializable(primitive())
  @observable
  total!: number;

  @serializable(list(object(HistoryItemData)))
  @observable
  rows!: HistoryItemData[];
}

export class HistoryListResource extends createResource<
  HistoryListResourceArgs,
  HistoryData
>({
  getKey: ({key, ticker}: HistoryListResourceArgs) =>
    `${HISTORY_LIST_KEY}:${key}:${ticker}`,
  getData: ({limit, offset, ticker, type}: HistoryListResourceArgs) => {
    return TRANSPORT.API.get(`/v2/balance/history`, {
      params: {
        limit: limit || DEFAULT_LIMIT,
        offset: offset || 0,
        ticker,
        type,
      },
      paramsSerializer: (params: any) =>
        stringify(params, {arrayFormat: 'none'}),
    }).then(res => {
      return deserialize(HistoryData, res.data);
    });
  },
  refetchInterval: 1000 * 60, // 1 minute
  skipRefreshOnVisible: false,
}) {
  @computed get sectionsByDate(): HistoryListSection[] {
    const mapByDay = this.data.rows.reduce((acc, item) => {
      const day = item.createdAtFormatted;
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day]!.push(item);
      return acc;
    }, {} as Record<string, HistoryItemData[]>);

    return Object.entries(mapByDay).map(([title, data]) => ({title, data}));
  }

  // Pagination

  @computed
  public get pageSize() {
    return this.args.limit || DEFAULT_LIMIT;
  }

  @computed
  public get totalPages() {
    return Math.ceil(this.data.total / this.pageSize);
  }

  @computed
  public get currentPage(): number {
    return this.data.currentPage;
  }

  getById = computedFn((id: string): HistoryItemData | undefined => {
    return this.data.rows.find(item => item.id === id);
  });

  @action setHistoryPage = (page: number) => {
    const offset = this.pageSize * page;
    if (offset > this.data.total) {
      return;
    }

    this.updateArgs({offset});
  };

  @action resetPagination = () => {
    this.setHistoryPage(0);
  };

  // Pagination end

  @action updateArgs = (newArgs: Partial<HistoryListResourceArgs>) => {
    this.args = {
      ...this.args,
      ...newArgs,
    };
  };
}
