import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../../../state/WithdrawalForm';

export const WithdrawalAmount: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {receiveAmountFormatted},
    view: {tickerFormatted},
  } = useForm<WithdrawalForm>();

  return (
    <Box
      px={24}
      py={20}
      mb={24}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderBottomWidth={1}
      borderColor="$ui-01"
    >
      <Text variant="$body-01" color="$text-02">
        {t('surface.bank_wire.withdrawal.receive.label')}
      </Text>
      <Box flexDirection="row">
        <Text
          variant="$heading-02"
          color="$text-01"
          testID="FIAT_WITHDRAWAL_BANK_WIRE_RECEIVE_AMOUNT"
        >
          {receiveAmountFormatted}{' '}
        </Text>
        <Text variant="$heading-02" fontWeight="normal" color="$text-02">
          {tickerFormatted}
        </Text>
      </Box>
    </Box>
  );
});
