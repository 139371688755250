import {type NativeMMKV} from 'react-native-mmkv';

type Value = string | boolean | number | Uint8Array;

// copied from react-native-mmkv/src/createMMKV.mock.ts
export class InMemoryMMKVLikeStorage implements NativeMMKV {
  private _storage: Map<string, Value>;

  constructor() {
    this._storage = new Map();
  }

  clearAll = () => this._storage.clear();
  delete = (key: string) => this._storage.delete(key);
  set = (key: string, value: Value) => this._storage.set(key, value);
  getString = (key: string) => {
    const result = this._storage.get(key);
    return typeof result === 'string' ? result : undefined;
  };
  getNumber = (key: string): number | undefined => {
    const result = this._storage.get(key);
    return typeof result === 'number' ? result : undefined;
  };
  getBoolean = (key: string): boolean | undefined => {
    const result = this._storage.get(key);
    return typeof result === 'boolean' ? result : undefined;
  };
  getBuffer = (key: string): Uint8Array | undefined => {
    const result = this._storage.get(key);
    return result instanceof Uint8Array ? result : undefined;
  };
  getAllKeys = () => Array.from(this._storage.keys());
  contains = (key: string) => this._storage.has(key);
  recrypt = () => {
    console.warn('Encryption is not supported in mocked MMKV instances!');
  };
}
