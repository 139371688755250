import * as React from 'react';
import {TSpan} from 'react-native-svg';
import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';
import {themes} from '@youtoken/ui.primitives';
import {i18n} from '@youtoken/ui.service-i18n';

const font = themes.light.fonts.mono.regular;

export class MaxLossLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(protected chart: ChartState) {}

  @computed get layout() {
    if (!this.chart.maxLoss || !this.chart.marginCall) {
      return undefined;
    }

    const value = this.chart.formatNumberToPrecision(this.chart.maxLoss);
    const ticker = this.chart.maxLossTicker?.toUpperCase();

    const maxLossTranslated = i18n.t('charts.max_loss');
    const text = `${maxLossTranslated} ${value}${ticker ? ` ${ticker}` : ''}`;

    const labelLayout = this.chart.layout.getLabelLayout(
      this.chart.marginCall,
      text,
      'left',
      this.chart.direction
    )!;

    if (
      this.chart.initialPriceLabel.layout?.isInDomain &&
      Math.abs(labelLayout.y - this.chart.initialPriceLabel.layout.y) <=
        this.chart.layout.labelHeight
    ) {
      return undefined;
    }

    return {
      ...labelLayout,
      text: (
        <TSpan>
          {maxLossTranslated} <TSpan fontFamily={font}>{value}</TSpan>
          {ticker && <TSpan> {ticker}</TSpan>}
        </TSpan>
      ),
    };
  }
}
