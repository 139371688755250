import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {SwitchWithNotFound} from '../SwitchWithNotFound';
import {authRequired} from '../authRequired';
import {TurbosList, TurbosDetails, TurbosCreate} from '../../pages';

const TurbosListWrapped = authRequired(TurbosList);
const TurbosDetailsWrapped = authRequired(TurbosDetails);
const TurbosCreateWrapped = authRequired(TurbosCreate);

export const AppWebTurbochargeRoutes: React.FC<RouteComponentProps> = ({
  match: {path},
}) => {
  return (
    <SwitchWithNotFound>
      <Route exact path={`${path}`} component={TurbosListWrapped} />
      <Route exact path={`${path}/details`} component={TurbosDetailsWrapped} />
      <Route exact path={`${path}/create`} component={TurbosCreateWrapped} />
    </SwitchWithNotFound>
  );
};
