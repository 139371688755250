import * as React from 'react';
import {observer} from 'mobx-react';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {VerificationItem} from '@youtoken/ui.surface-more';
import {Box, BoxProps} from '@youtoken/ui.primitives';

export const VerificationSteps: React.FC<BoxProps> = observer(boxProps => {
  const {accountVerificationCodes} = VerificationResource.use({});
  return (
    <Box {...boxProps}>
      {accountVerificationCodes?.map(code => (
        <VerificationItem key={code} code={code} />
      ))}
    </Box>
  );
});
