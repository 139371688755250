import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {cell} from '@youtoken/ui.cell';
import {
  type StackNavigatorScreenWizardWrappedProps,
  transparentScreenOptions,
  createWizard,
} from '@youtoken/ui.elements';
import {getCryptoBuyWizardOptions, getCryptoDepositWizardOptions} from '..';
import {DepositNowWizardChooseType} from './ChooseType';
import {DepositNowWizardBuyCrypto} from './BuyCrypto';
import {DepositNowWizardDepositCrypto} from './DepositCrypto';
import {DepositNowWizardDepositFiat} from './DepositFiat';

export enum DepositNowWizardScreenName {
  CHOOSE_TYPE = 'chooseType',
  BUY_CRYPTO = 'buyCrypto',
  DEPOSIT_FIAT = 'depositFiat',
  DEPOSIT_CRYPTO = 'depositCrypto',
}

export interface DepositNowWizardScreenPropsWithSource {
  source?: 'TOP_BAR' | 'WALLETS' | 'WALLET';
}

export interface DepositNowWizardNavigator {
  [DepositNowWizardScreenName.CHOOSE_TYPE]: DepositNowWizardScreenPropsWithSource;
  [DepositNowWizardScreenName.BUY_CRYPTO]: DepositNowWizardScreenPropsWithSource;
  [DepositNowWizardScreenName.DEPOSIT_FIAT]: {};
  [DepositNowWizardScreenName.DEPOSIT_CRYPTO]: {};
}

const Wizard = createWizard<DepositNowWizardNavigator>();

export interface DepositNowWizardProps {
  source?: 'TOP_BAR' | 'WALLETS' | 'WALLET';
  onPressBack?: () => void;
}

export const DepositNowWizard: React.FC<DepositNowWizardProps> = cell(
  ({source, onPressBack}) => {
    React.useEffect(() => {
      DATA_LAYER.trackStrict('buy-wizard-fork-opened', {
        source,
      });
    }, [source]);

    return (
      <Wizard.Navigator
        initialName={DepositNowWizardScreenName.CHOOSE_TYPE}
        initialProps={{
          source,
        }}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={DepositNowWizardScreenName.CHOOSE_TYPE}
          options={transparentScreenOptions}
          component={DepositNowWizardChooseType}
        />
        <Wizard.Screen
          name={DepositNowWizardScreenName.BUY_CRYPTO}
          options={getCryptoBuyWizardOptions}
          component={DepositNowWizardBuyCrypto}
        />
        <Wizard.Screen
          name={DepositNowWizardScreenName.DEPOSIT_FIAT}
          options={transparentScreenOptions}
          component={DepositNowWizardDepositFiat}
        />
        <Wizard.Screen
          name={DepositNowWizardScreenName.DEPOSIT_CRYPTO}
          options={getCryptoDepositWizardOptions}
          component={DepositNowWizardDepositCrypto}
        />
      </Wizard.Navigator>
    );
  }
);

export const DepositNowWizardWrapped = ({
  route: {
    params: {source},
  },
  navigation: {goBack},
}: StackNavigatorScreenWizardWrappedProps<DepositNowWizardScreenPropsWithSource>) => {
  return <DepositNowWizard source={source} onPressBack={goBack} />;
};
