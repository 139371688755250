import {action, computed, observable} from 'mobx';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Wallet, WalletsResource} from '@youtoken/ui.resource-wallets';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {NETWORKS} from './consts';
import {getIsMethodEnabled, getItemsBySearch, toggleFilter} from './utils';
import {WalletsChooseTickerMethod} from '../types';

type WalletsChooseTickerBuyCryptoFeatureArgs = {
  method: WalletsChooseTickerMethod;
};

export class WalletsChooseTickerBuyCryptoFeature extends createFeature({
  getKey: ({method}: WalletsChooseTickerBuyCryptoFeatureArgs) => {
    return `WalletsChooseTickerBuyCryptoFeature({method: ${method}})`;
  },
  getResources: () => {
    return {
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsResource, {}),
    };
  },
}) {
  //#region wallets
  @computed
  get isWalletsListCreating() {
    return this.resources.walletsResource.isWalletsListCreating;
  }

  @computed
  get wallets() {
    return this.resources.walletsResource.cryptoAndStableWallets.filter(
      wallet => {
        const methodEnabled = getIsMethodEnabled(wallet, this.args.method);

        return (
          methodEnabled && this.resources.exchangeTariffs.tariffs[wallet.ticker]
        );
      }
    );
  }

  //#endregion wallets

  //#region search
  @observable
  search: string = '';

  @action
  updateSearch = (search: string) => {
    this.search = search;
  };
  //#endregion search

  //#region filters
  @observable
  selectedFilters: string[] = [];

  @computed
  get shownFilters(): string[] {
    const walletsVersionsSet = new Set(
      this.wallets.flatMap(({versions}) => versions.map(({version}) => version))
    );

    return NETWORKS.filter(network => walletsVersionsSet.has(network));
  }

  @action
  toggleFilter = (filter: string) => {
    this.selectedFilters = toggleFilter(this.selectedFilters, filter);
  };

  @action
  resetFilters = () => {
    this.selectedFilters = [];
  };
  //#endregion filters

  //#region items
  @computed.struct
  get filteredItems(): Wallet[] {
    let items = getItemsBySearch(this.wallets, this.search);

    return items.filter(wallet => {
      if (!wallet.marketToEnabled) {
        return false;
      }

      const networks = wallet.versions.flatMap(({version}) => version);

      // Check if all selected filters are included in the networks array
      return this.selectedFilters.every(filter => networks.includes(filter));
    });
  }

  //#endregion items

  @action
  reset = () => {
    this.resetFilters();
    this.updateSearch('');
  };
}
