import {computed} from 'mobx';
import {serializable, primitive, date, map, list, object} from 'serializr';
import Big from 'big.js';
import {
  ChartDataItem,
  number,
  chartData,
  bigNumber,
  bigNumberNullable,
} from '@youtoken/ui.utils-serializr';
import {LoanCloseNow} from './LoanCloseNow';
import {LoanTurboChainLoans} from './LoanTurboChainLoans';
import {toBig} from '@youtoken/ui.formatting-utils';

export enum LoanTurboStatus {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  OPEN = 'OPEN',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  FAIL = 'FAIL',
}

export class LoanTurbo {
  version: number = 1;

  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  status!: LoanTurboStatus;

  @serializable(primitive())
  collateralTicker!: string;

  @serializable(primitive())
  borrowedTicker!: string;

  @serializable(chartData())
  chartData!: ChartDataItem[];

  @serializable(bigNumber())
  collateralAmount!: Big;

  @serializable(bigNumber())
  borrowedAmount!: Big;

  @serializable(number())
  days!: number;

  @serializable(bigNumber())
  APR!: Big;

  @serializable(bigNumber())
  LTV!: Big;

  @serializable(number())
  turbo!: number;

  @serializable(
    list(object(LoanTurboChainLoans), {
      beforeDeserialize: (callback, jsonValue) => {
        callback(null, Array.isArray(jsonValue) ? jsonValue : []);
      },
    })
  )
  loans?: LoanTurboChainLoans[];

  @serializable(bigNumber())
  initialPrice!: Big;

  @serializable(bigNumberNullable())
  closedPrice?: Big | null;

  @serializable(bigNumber())
  marginCall!: Big;

  @serializable(bigNumber())
  minFTP!: Big;

  @serializable(bigNumber())
  maxFTP!: Big;

  @serializable(bigNumberNullable())
  FTP?: Big | null;

  @serializable(primitive())
  isClientMC?: boolean;

  @serializable(date())
  createdAt!: Date;

  @serializable(date())
  reopenedAt?: Date;

  @serializable(date())
  startedAt?: Date;

  @serializable(date())
  finishedAt?: Date;

  @serializable(date())
  finishAt?: Date;

  @serializable(object(LoanCloseNow))
  closeNow?: LoanCloseNow;

  @serializable(primitive())
  company!: 'NAUMARD' | 'YOUHODLER';

  @serializable(number())
  overdueDays?: number;

  @serializable(map(primitive()))
  details!: {[key: string]: number};

  @computed get minTP() {
    return this.minFTP;
  }

  @computed get maxTP() {
    return this.maxFTP;
  }

  @computed get tp() {
    return this.FTP;
  }

  @computed get overdraftAmount() {
    return toBig(this.details[`_${this.borrowedTicker}`]).abs();
  }
}
