import * as React from 'react';
import {observer} from 'mobx-react';
import {FlatList} from 'react-native';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {SecurityDeviceData} from '@youtoken/ui.state-security';
import {DevicesListItem} from './DevicesListItem';

export interface DevicesListProps extends BoxProps {
  checkStatus?: () => Promise<any>;
  data: SecurityDeviceData[];
}

export const DevicesList: React.FC<DevicesListProps> = observer(
  ({checkStatus, data, ...boxProps}) => {
    const styleProps = React.useMemo<BoxProps>(() => {
      return {
        borderTopWidth: 1,
        borderBottomWidth: {default: 0, tablet: 1},
        borderLeftWidth: {default: 0, tablet: 1},
        borderRightWidth: {default: 0, tablet: 1},
        borderRadius: {default: 0, tablet: 10},
      };
    }, []);

    const [isRefreshing, setIsRefreshing] = React.useState(false);

    const onRefresh = React.useCallback(() => {
      if (checkStatus) {
        setIsRefreshing(true);
        checkStatus().finally(() => setIsRefreshing(false));
      }
    }, [checkStatus]);

    const renderItem = React.useCallback(
      ({item, index}: {item: SecurityDeviceData; index: number}) => {
        return (
          <DevicesListItem
            key={item.id}
            item={item}
            isLast={data.length - 1 === index}
            index={index}
          />
        );
      },
      [data]
    );

    return (
      <Box borderColor="$ui-01" {...styleProps} {...boxProps}>
        <FlatList
          data={data}
          renderItem={renderItem}
          onRefresh={onRefresh}
          refreshing={isRefreshing}
        />
      </Box>
    );
  }
);
