import * as React from 'react';
import {observer} from 'mobx-react';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {PriceDiffPercent} from '../';

interface RateSmartProps extends BoxProps {
  baseTicker: string;
  quoteTicker: string;
}

export const RateSmart: React.FC<RateSmartProps> = observer(
  ({baseTicker, quoteTicker}) => {
    const {getDiffAndFormattedRate} = HODLsTariffsFeature.use({});

    const {diffPercent, rateWithEllipsisFormatted} = getDiffAndFormattedRate(
      baseTicker,
      quoteTicker
    );

    return (
      <Box alignItems="flex-end">
        <Text testID="INSTRUMENT_PRICE">{rateWithEllipsisFormatted}</Text>
        <PriceDiffPercent value={diffPercent} />
      </Box>
    );
  }
);
