import {__SERVICE_REGISTRY__} from '@youtoken/ui.service-registry';
import {SentryService} from './SentryService';

export {customErrorDataIntegration} from './integrations';

export const SENTRY = __SERVICE_REGISTRY__.registerService(
  'SENTRY',
  SentryService,
  require('../package.json').version
);
