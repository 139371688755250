import React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {PromoImage} from './PromoImage';

interface AuthPromoBackgroundProps extends React.ComponentProps<typeof Box> {}

export const AuthPromoBackground: React.FC<
  AuthPromoBackgroundProps
> = props => {
  return (
    <Box visible={{default: false, tablet: true}} width={860} {...props}>
      <PromoImage />
    </Box>
  );
};
