import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import type {LoanRepayOwnFundsFormProps} from '../../types';
import {Header, Content, Footer} from '..';

export const LoanRepayOwnFundsFormLayout: React.FC<
  Pick<LoanRepayOwnFundsFormProps, 'showHeader' | 'onPressBack'> & BoxProps
> = ({showHeader = true, onPressBack, ...boxProps}) => {
  return (
    <Box {...boxProps}>
      {showHeader && <Header p={24} onPressBack={onPressBack} />}
      <Content flex={1} p={24} zIndex={1} />
      <Footer p={24} borderColor="$ui-01" borderTopWidth={1} />
    </Box>
  );
};
