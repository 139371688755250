import * as React from 'react';
import type {TFuncKey} from 'react-i18next';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const DisclosureIntergiro: React.FC = () => {
  const {legalEntity} = AuthMeResource.use({});
  const {
    documents: {intergiroCards},
  } = DocsResource.use({country: legalEntity});

  const {t} = useTranslation();

  return (
    <>
      <Box mb={24}>
        <Heading variant="$heading-03" textTransform="uppercase" mb={16}>
          {t('surface.profile.legal_info.subtitle.intergiro')}
        </Heading>

        <Text variant="$body-02" textTransform="uppercase" mb={12}>
          {t('surface.profile.legal_info.subtitle.documents')}
        </Text>

        {Boolean(intergiroCards) &&
          intergiroCards!.map(item => {
            const itemUrl = item.i18nLink
              ? t(item.i18nLink as TFuncKey)
              : item.link;
            const itemName = item.i18nName
              ? t(item.i18nName as TFuncKey)
              : item.name;

            return (
              <Box flexDirection="row" mb={8} key={item.link}>
                <Link
                  underlined
                  url={itemUrl}
                  variant="$body-02"
                  color="$text-02"
                >
                  {itemName}
                </Link>
              </Box>
            );
          })}
      </Box>
    </>
  );
};
