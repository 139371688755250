import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Box, ThemeProvider} from '@youtoken/ui.primitives';
import {LoanRegularCreateLandingForm} from '@youtoken/ui.surface-loan-regular-create';
import {useLandingTheme, useLandingEffectChangeLanguage} from '../hooks';
import {RouterResource} from '../../../routes/RouterResource';

const tickerAmounts = {
  btc: 1,
  eth: 10,
  xrp: 10000,
  xlm: 10000,
  ltc: 50,
  bch: 10,
  dash: 10,
  eos: 500,
  rep: 50,
  bsv: 50,
  bat: 10000,
  bnb: 1000,
  uni: 100,
  comp: 100,
  mkr: 100,
};

export const Loan: React.FC = cell(() => {
  const {
    queryParams: {
      theme: themeName,
      lang,
      getLoanTicker,
      getLoanConversionTicker,
    },
  } = RouterResource.use({});

  const theme = useLandingTheme(themeName);

  useLandingEffectChangeLanguage(lang);

  return (
    <ThemeProvider theme={theme}>
      <Box
        justifyContent={{
          default: 'flex-start',
          tablet: 'center',
        }}
        alignItems="center"
        width="100%"
        height="100%"
        bg="$ui-background"
      >
        <LoanRegularCreateLandingForm
          ticker={getLoanTicker}
          conversionTicker={getLoanConversionTicker}
          amount={tickerAmounts[getLoanTicker as keyof typeof tickerAmounts]}
          borderWidth={1}
          borderRadius={16}
          width="100%"
          maxWidth={688}
        />
      </Box>
    </ThemeProvider>
  );
});
