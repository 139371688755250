import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {
  verificationCodeActions,
  VerificationResource,
} from '@youtoken/ui.resource-verification';

export const PleaseVerifyFooterVerifyButton: React.FC = observer(() => {
  const {t} = useTranslation();

  const {accountVerificationCurrent} = VerificationResource.use({});

  const handlePress = () => {
    if (ENVIRONMENT.WEB_APP_ENV === WebAppEnv['ramp-app']) {
      return verificationCodeActions[accountVerificationCurrent!]();
    }

    VerificationResource.launchIdentityVerification();
  };

  return (
    <Button testID="VERIFY_ACCOUNT_BUTTON" size="large" onPress={handlePress}>
      {t('surface.please_verify.call_to_action')}
    </Button>
  );
});
