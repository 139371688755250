import * as React from 'react';
import {observer} from 'mobx-react';
import {Icon} from '@youtoken/ui.icons';
import {TouchableBox} from '@youtoken/ui.primitives';
import {ModalCloseIconProps} from './types';

export const CloseIcon: React.FC<ModalCloseIconProps> = observer(
  ({onClose, closeIconColor, testID}) => {
    return (
      <TouchableBox
        position="absolute"
        right={0}
        top={0}
        width={36}
        height={36}
        justifyContent="center"
        alignItems="center"
        activeOpacity={0.8}
        onPress={onClose}
        zIndex={1}
        testID={testID}
      >
        <Icon name="close" color={closeIconColor} />
      </TouchableBox>
    );
  }
);
