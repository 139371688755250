import {type BankCardSize} from '../types';

export const getSize = (size: BankCardSize) => {
  switch (size) {
    case 'tiny':
      return {
        width: 20,
        height: 16,
        borderRadius: 3,
      };
    case 'small':
      return {
        width: 50,
        height: 32,
        borderRadius: 6,
      };
    case 'big':
    default:
      return {
        width: 232,
        height: 138,
        borderRadius: 16,
      };
  }
};
