import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SendIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.359 4.342a1 1 0 01.674 1.09l-.042.175-9.876 29.628a1 1 0 01-1.77.254l-.089-.157-5.862-12.896a.999.999 0 00-.34-.408l-.156-.09L5 16.078a1 1 0 01-.068-1.787l.165-.072 29.628-9.876a1 1 0 01.633 0zm-6.245 4.755l-17.33 6.077a.5.5 0 00-.04.928l7.153 3.211L29.114 9.097zm2.12 2.121L21.02 21.434l3.212 7.154a.5.5 0 00.928-.04l6.076-17.33z"
          fill={color}
        />
      </Svg>
    );
  }
);
