import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text} from '@youtoken/ui.primitives';

interface RolloverInProps {
  rolloverData?: {
    formattedTime: string;
    lessThan10Mins: boolean;
  };
}

export const RolloverIn: React.FC<RolloverInProps> = observer(
  ({rolloverData}) => {
    const {t} = useTranslation();

    if (!rolloverData) {
      return null;
    }

    return (
      <Text variant="$body-02" color="$text-02">
        {t('surface.hodls.item.rollover_in')}{' '}
        <Text color={rolloverData.lessThan10Mins ? '$danger-01' : '$text-02'}>
          {rolloverData.formattedTime}
        </Text>
      </Text>
    );
  }
);
