import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const TwofaIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path d="M15 6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill={color} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.25 7a5.75 5.75 0 114 5.479V13c0 .69-.56 1.25-1.25 1.25h-.75V15c0 .69-.56 1.25-1.25 1.25h-.75v1.25c0 .69-.56 1.25-1.25 1.25H2.5c-.69 0-1.25-.56-1.25-1.25v-1.793c0-.331.132-.65.366-.884l5.947-5.947A5.742 5.742 0 017.25 7zM13 2.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zm-4.722 7.532L2.75 15.811v1.439h3V16c0-.69.56-1.25 1.25-1.25h.75V14c0-.69.56-1.25 1.25-1.25h.75v-1.006a5.784 5.784 0 01-1.472-1.462z"
          fill={color}
        />
      </Svg>
    );
  }
);
