import * as React from 'react';
import {Route, Switch, type RouteProps} from 'react-router-dom';
import {NotFoundPage as NotFoundPageDefault} from '../pages';

export interface SwitchWithNotFound {
  NotFoundPage?: RouteProps['component'];
}

export const SwitchWithNotFound: React.FC<SwitchWithNotFound> = ({
  children,
  NotFoundPage = NotFoundPageDefault,
}) => {
  return (
    <Switch>
      {children}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};
