import * as React from 'react';
import {Box, BoxProps, Heading} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage} from '@youtoken/ui.form-elements';
import {
  getTranslatedValidationMessage,
  ValidationMessageLocalized,
} from '@youtoken/ui.validation-messages';

interface FormATextInputProps extends BoxProps {
  title: string;
  error?: ValidationMessageLocalized;
  value: string;
  onChange: (value: string) => void;
}

export const FormATextInput: React.FC<FormATextInputProps> = ({
  title,
  error,
  value,
  onChange,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {/* @ts-ignore */}
      <Heading variant="$heading-03" mb={8} focusable={false}>
        {title}
      </Heading>
      <TextInput
        hasError={Boolean(error)}
        value={value}
        onChangeText={onChange}
      />
      <FieldErrorMessage visible={Boolean(error)}>
        {Boolean(error) && getTranslatedValidationMessage(error!)}
      </FieldErrorMessage>
    </Box>
  );
};
