import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type UnlimintCoDiForm} from '../../state/UnlimintCoDiForm';

export const FeeRow: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {paymentFeeFormatted},
  } = useForm<UnlimintCoDiForm>();

  return (
    <Box
      height={48}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      borderColor="$ui-01"
      borderBottomWidth={1}
    >
      <Box>
        <Text>
          {t('surface.wallets.fiat_deposit.unlimint_codi.fee_row.fee')}
        </Text>
      </Box>
      <Box flexDirection="row">
        <Text mr={8} testID="FIAT_DEPOSIT_CODI_FEE">
          {paymentFeeFormatted}
        </Text>
      </Box>
    </Box>
  );
});
