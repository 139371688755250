import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const MiningIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.438 2.535c-.322-.871-1.554-.871-1.876 0L8.96 6.868a1 1 0 01-.591.59L4.035 9.063c-.871.322-.871 1.554 0 1.876l4.333 1.603a1 1 0 01.59.591l1.604 4.333c.322.871 1.554.871 1.876 0l1.603-4.333a1 1 0 01.591-.59l4.334-1.604c.87-.322.87-1.554 0-1.876L14.632 7.46a1 1 0 01-.59-.591l-1.604-4.333zM11.5 4.322l-1.134 3.066a2.5 2.5 0 01-1.478 1.477L5.822 10l3.066 1.135a2.5 2.5 0 011.478 1.477l1.134 3.066 1.135-3.066a2.5 2.5 0 011.477-1.477L17.178 10l-3.066-1.135a2.5 2.5 0 01-1.477-1.477L11.5 4.322z"
          fill={color}
        />
        <Path
          d="M3.031 2.267a.5.5 0 01.938 0l.397 1.072a.5.5 0 00.295.295l1.072.397a.5.5 0 010 .938l-1.072.397a.5.5 0 00-.295.295l-.397 1.072a.5.5 0 01-.938 0L2.634 5.66a.5.5 0 00-.295-.295l-1.072-.397a.5.5 0 010-.938l1.072-.397a.5.5 0 00.295-.295l.397-1.072zm.938 12a.5.5 0 00-.938 0l-.126.342a.5.5 0 01-.296.296l-.342.126a.5.5 0 000 .938l.342.126a.5.5 0 01.296.296l.126.342a.5.5 0 00.938 0l.126-.342a.5.5 0 01.296-.296l.342-.126a.5.5 0 000-.938l-.342-.126a.5.5 0 01-.296-.296l-.126-.342z"
          fill={color}
        />
      </Svg>
    );
  }
);
