import {InvariantError} from '@youtoken/ui.errors';

/** invariant to throw errors;
 * - throw if predicate is false;
 * - assets predicate for [typescript](https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions)
 * - will **not** strip away error message in production (unlike tiny-invariant);
 */
export function invariant<T>(
  predicate: T,
  message: string,
  tags?: Record<string, any>,
  extra?: Record<string, any>
): asserts predicate {
  if (predicate) {
    return;
  }

  throw new InvariantError(message, tags, extra);
}
