import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {SecurityActivityResource} from '@youtoken/ui.state-security';
import {SecurityActivityDetailsModal} from './SecurityActivityDetailsModal';

export const SecurityActivityDetailsModalCell = cell<{id: string}>(({id}) => {
  const {getActivityItem} = SecurityActivityResource.use({});

  const item = getActivityItem(id);

  if (!item) {
    // TODO: replace with empty state;
    return null;
  }

  return <SecurityActivityDetailsModal item={item} />;
});
