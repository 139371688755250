import * as React from 'react';
import {
  Wrap,
  GridLabels,
  GridLines,
  LastValueLabel,
  LastValueLine,
  DataLine,
  PastDataLine,
  FutureDataLine,
  LineChartCursor,
  CandlesChartCursor,
  DataCandles,
  MaxProfitLabel,
  MaxLossLabel,
  InitialPriceLabel,
  TakeProfitLabel,
  MarginCallLabel,
  TakeProfitLine,
  MarginCallLine,
  ActivityIndicator,
  TriggerPriceLine,
  TriggerPriceLabel,
  TriggerPriceNameLabel,
} from '../Elements';
import {
  useLocalChartState,
  HODLCreateChartState,
  type HODLCreateChartStateProps,
} from '../logic';

import {ChartThemeProvider} from '../Providers';
import {G} from 'react-native-svg';
import {useObserver} from 'mobx-react';
import {NoDataState} from './NoDataState';

export const HODLCreateChart: React.FC<HODLCreateChartStateProps> = React.memo(
  ({children, refetch, ...props}) => {
    const state = useLocalChartState(HODLCreateChartState, props);

    return useObserver(() => {
      if (!state.hasData) {
        return <NoDataState refetch={refetch} />;
      }

      return (
        <ChartThemeProvider>
          <Wrap chartState={state}>
            <GridLines chartState={state} />

            <GridLabels chartState={state} />

            {state.type === 'line' && (
              <G>
                <PastDataLine chartState={state} />
                <FutureDataLine chartState={state} />
                <DataLine chartState={state} />
              </G>
            )}

            {state.type === 'candle' && (
              <G>
                <DataCandles chartState={state} />
              </G>
            )}

            <G>
              <TakeProfitLine chartState={state} />
              <MarginCallLine chartState={state} />
              <LastValueLine chartState={state} />
              <TriggerPriceLine chartState={state} />
            </G>

            <G>
              <MaxProfitLabel chartState={state} />
              <MaxLossLabel chartState={state} />
              <InitialPriceLabel chartState={state} />
              <TakeProfitLabel chartState={state} />
              <LastValueLabel chartState={state} />
              <MarginCallLabel chartState={state} />
              <TriggerPriceLabel chartState={state} />
              <TriggerPriceNameLabel chartState={state} />
            </G>

            {state.type === 'line' && <LineChartCursor chartState={state} />}
            {state.type === 'candle' && (
              <CandlesChartCursor chartState={state} />
            )}
          </Wrap>

          {props.displayActivityIndicator && (
            <ActivityIndicator chartState={state} />
          )}
        </ChartThemeProvider>
      );
    });
  }
);
