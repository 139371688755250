import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {TextInput, type TextInputProps} from '@youtoken/ui.inputs';
import {SavingsSelectAssetsFeature} from '../../Feature';
import {SavingsSelectAssetsFilterSearchIcon} from './SearchIcon';

export const SavingsSelectAssetsFilter: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();

    const {requiredTickersCount, filterSearch, setFilterSearch} =
      SavingsSelectAssetsFeature.use({});

    return (
      <Box
        testID="SAVINGS_SELECT_ASSETS_FILTER"
        p={{default: 16, phone: 24}}
        {...boxProps}
      >
        <Text variant="$body-01" color="$text-02" mb={16}>
          {t('surface.savings.select_assets.filter.required_tickers', {
            count: requiredTickersCount,
          })}
        </Text>
        <TextInput
          testID="SAVINGS_SELECT_ASSETS_FILTER_INPUT"
          size="medium"
          value={filterSearch}
          placeholder={t(
            'surface.savings.select_assets.filter.search_placeholder'
          )}
          onChangeText={setFilterSearch}
          LeftPartComponent={
            SavingsSelectAssetsFilterSearchIcon as TextInputProps['LeftPartComponent']
          }
        />
      </Box>
    );
  }
);
