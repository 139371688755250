import * as React from 'react';
import {observer} from 'mobx-react';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {ReferralsHeaderV3, ReferralsWidget} from '../../components';

export const ReferralsWidgetV3Smart: React.FC = observer(() => {
  const {
    data: {
      maxEarningsPercentFormatted,
      referralsCount,
      totalEarnedReferralUSD,
      newEarnedUSD,
    },
  } = RewardsResource.use({});

  return (
    <ReferralsWidget
      header={
        <ReferralsHeaderV3
          variant="$heading-02"
          percentFormatted={maxEarningsPercentFormatted}
        />
      }
      referralsCount={referralsCount}
      totalEarnedUSD={totalEarnedReferralUSD}
      newEarnedUSD={newEarnedUSD}
    />
  );
});
