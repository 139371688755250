import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {MinerOverviewResource} from '@youtoken/ui.resources-miner';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Timer} from '../../Timer';

export const NextFreeSparkIn: React.FC = observer(() => {
  const {timeLeftUntilNextFreeSparksDrop, refetch} = MinerOverviewResource.use(
    {}
  );

  const {
    currentLevelData: {
      miner: {freeSparksAmount},
    },
  } = LoyaltyResource.use({});

  const handleTimerEnd = React.useCallback(() => {
    refetch();
  }, [refetch]);

  const {t} = useTranslation();

  if (!timeLeftUntilNextFreeSparksDrop || !freeSparksAmount) {
    return null;
  }

  return (
    <Box
      testID="TIME_COUNTER_FIELD"
      flexDirection="row"
      alignItems="center"
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={24}
      mt={8}
      px={12}
      py={4}
    >
      <Text variant="$body-02" color="$text-02" mr={2}>
        {t('surface.miner.overview.next')}
      </Text>
      <Icon name="spark" size={16} color="$attention-01" />
      <Text variant="$body-02" color="$text-02" ml={2}>
        {t('surface.miner.overview.next_in')}
      </Text>
      <Timer
        timeLeft={timeLeftUntilNextFreeSparksDrop}
        onTimerEnd={handleTimerEnd}
        ml={4}
      />
    </Box>
  );
});
