import * as React from 'react';
import {HistoryItemDetailsSurface} from '@youtoken/ui.surface-history-list';
import {Modal} from '../_common';

interface ModalHistoryItemDetailsProps
  extends React.ComponentProps<typeof Modal> {
  id: string;
}

export const ModalHistoryItemDetails: React.FC<
  ModalHistoryItemDetailsProps
> = ({id, ...otherProps}) => {
  return (
    <Modal overflow="hidden" {...otherProps}>
      <HistoryItemDetailsSurface id={id} width="100%" />
    </Modal>
  );
};
