import {TRANSPORT} from '@youtoken/ui.transport';
import {openBrowser} from '.';
import {invariant} from '@youtoken/ui.utils';

// return url is where Intergiro redirects after their flow finishes, like '/wallets/cards'
export const requestConsent: ({
  returnUrl,
}: {
  returnUrl: string;
}) => Promise<void> = ({returnUrl}) => {
  return TRANSPORT.API.post('/v1/intergiro/profile/consent', {
    redirectURL: returnUrl,
  }).then(response => {
    const {redirect_url} = response.data?.method;

    invariant(redirect_url, 'No redirect url received');

    return openBrowser(redirect_url);
  });
};
