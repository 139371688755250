export const iconSet = {
  ai: require('./ai.png'),
  avalanche: require('./avalanche.png'),
  'bnb-chain': require('./bnb-chain.png'),
  defi: require('./defi.png'),
  'fan-tokens': require('./fan-tokens.png'),
  indexes: require('./indexes.png'),
  'layer-1': require('./layer-1.png'),
  memes: require('./memes.png'),
  metaverse: require('./metaverse.png'),
  nft: require('./nft.png'),
  polkadot: require('./polkadot.png'),
  solana: require('./solana.png'),
  stable: require('./stable.png'),
};

export type IconName = keyof typeof iconSet;
