import * as React from 'react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {type PerformanceItemData} from '../types';

enum colorOptions {
  'up' = '$success-01',
  'down' = '$danger-01',
}

enum backgroundColorOptions {
  'up' = '$success-02',
  'down' = '$danger-02',
}

export const PerformanceItem: React.FC<PerformanceItemData & BoxProps> = ({
  direction,
  periodFormatted,
  amountFormatted,
  ...boxProps
}) => {
  return (
    <Box
      width={{default: '31%', tablet: '32%'}}
      p={16}
      m={4}
      backgroundColor={backgroundColorOptions[direction]}
      borderRadius={8}
      {...boxProps}
    >
      <Text mb={4} variant="$body-01">
        {periodFormatted}
      </Text>
      <Box flexDirection="row">
        <Icon name={direction} size={18} color={colorOptions[direction]} />
        <Text
          ml={3}
          variant="$body-01-medium-accent"
          color={colorOptions[direction]}
        >
          {amountFormatted}
        </Text>
      </Box>
    </Box>
  );
};
