import * as React from 'react';
import {scaleLinear} from 'd3-scale';
import * as array from 'd3-array';
import {area} from 'd3-shape';
import {ChartSmallPreviewChartData} from './types';

export const getChartPath = (
  width: number,
  height: number,
  data: ChartSmallPreviewChartData[]
): string | undefined => {
  return React.useMemo(() => {
    if (data.length < 2) {
      return undefined;
    }

    const scaleX = scaleLinear()
      .domain([array.min(data, d => d.date), array.max(data, d => d.date)] as [
        number,
        number
      ])
      .range([-1, width + 1]);

    const scaleY = scaleLinear()
      .domain([array.min(data, d => d.rate), array.max(data, d => d.rate)] as [
        number,
        number
      ])
      .range([height - 7, -1])
      .nice();

    const d = area<ChartSmallPreviewChartData>()
      .x(d => scaleX(d.date))
      .y0(height + 1)
      .y1(d => scaleY(d.rate))(data);

    return d || undefined;
  }, [width, height, data]);
};
