import * as React from 'react';
import {Box, BoxProps} from '@youtoken/ui.primitives';
import {Banner, Link} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SUPPORT} from '@youtoken/ui.support';

interface FormAErrorsProps extends BoxProps {
  notUS: any;
  isSoleOwner: any;
  disclosureAccepted: any;
}

export const FormAErrors: React.FC<FormAErrorsProps> = ({
  notUS,
  isSoleOwner,
  disclosureAccepted,
  ...boxProps
}) => {
  const {t} = useTranslation();

  const handleIntercomLink = React.useCallback(() => {
    SUPPORT.openSupportChat();
  }, []);

  if (!notUS && !isSoleOwner && !disclosureAccepted) {
    return null;
  }

  return (
    <Box {...boxProps}>
      {Boolean(notUS) && (
        <Banner variant="danger">{t('surface.form_a.error.non_us')}</Banner>
      )}
      {Boolean(isSoleOwner) && (
        <Banner variant="danger" mt={8}>
          {t('surface.form_a.error.sole_owner')}{' '}
          <Link
            color="$danger-04"
            textDecorationLine="underline"
            onPress={handleIntercomLink}
          >
            {t('forms.new_password.contact_support')}
          </Link>
        </Banner>
      )}
      {Boolean(disclosureAccepted) && (
        <Banner variant="danger" mt={8}>
          {t('surface.form_a.error.disclosure')}
        </Banner>
      )}
    </Box>
  );
};
