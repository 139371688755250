import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const BankWireIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M8 6.05a.75.75 0 01.75.75v7a.75.75 0 01-1.5 0v-7A.75.75 0 018 6.05zm4.75.75a.75.75 0 00-1.5 0v7a.75.75 0 001.5 0v-7z"
          fill={color}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.757 2.06l-7 1.75A1 1 0 002 4.78V6.8a.5.5 0 00.5.5.5.5 0 01.5.5v6a.5.5 0 01-.5.5.5.5 0 00-.5.5V17a1 1 0 001 1h14a1 1 0 001-1v-2.2a.5.5 0 00-.5-.5.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5.5.5 0 00.5-.5V4.78a1 1 0 00-.758-.97l-7-1.75a1 1 0 00-.485 0zM3.5 5.172v.897a2 2 0 011 1.733v6a2 2 0 01-1 1.732v.967h13v-.967a2 2 0 01-1-1.732v-6a2 2 0 011-1.733v-.897L10 3.546 3.5 5.171z"
          fill={color}
        />
      </Svg>
    );
  }
);
