import {BaseCursor} from './BaseCursor';
import {
  BaseChartState,
  CandlestickChartDataItem,
  ChartDirection,
} from '../BaseChartState';
import {computed, action} from 'mobx';
import {ScaleLinear} from 'd3-scale';
import {i18n} from '@youtoken/ui.service-i18n';

export class CandlesCursor extends BaseCursor<BaseChartState> {
  constructor(chart: BaseChartState) {
    super(chart);
  }

  @action setCursorDataIndexFromCursorX = (cursorX?: number) => {
    if (!cursorX || !this.chart.normalizedCandlesData) {
      return this.setCursorDataIndex(undefined);
    }

    const scaleX = this.chart.layout.scaleX as ScaleLinear<any, any>;

    const index = Math.round(scaleX.invert(cursorX));

    this.setCursorDataIndex(
      index >= 0 && index <= this.chart.data.length - 1 ? index : undefined
    );
  };

  @computed get cursorDataPoint() {
    if (this.cursorDataIndex && this.chart.normalizedCandlesData) {
      return this.chart.normalizedCandlesData[this.cursorDataIndex];
    }

    return undefined;
  }

  @computed get x() {
    return this.cursorDataIndex
      ? this.chart.layout.scaleX(this.cursorDataIndex)
      : undefined;
  }

  @computed get yHigh() {
    return this.cursorDataPoint
      ? this.chart.layout.scaleY(this.cursorDataPoint.high)
      : undefined;
  }

  @computed get yLow() {
    return this.cursorDataPoint
      ? this.chart.layout.scaleY(this.cursorDataPoint.low)
      : undefined;
  }

  @computed get yOpen() {
    return this.cursorDataPoint
      ? this.chart.layout.scaleY(this.cursorDataPoint.open)
      : undefined;
  }

  @computed get yClose() {
    return this.cursorDataPoint
      ? this.chart.layout.scaleY(this.cursorDataPoint.close)
      : undefined;
  }

  @computed get yUpper() {
    return Math.min(this.yOpen, this.yClose);
  }

  @computed get yLower() {
    return Math.max(this.yOpen, this.yClose);
  }

  @computed get direction(): ChartDirection | undefined {
    return this.cursorDataPoint
      ? this.cursorDataPoint.open > this.cursorDataPoint.close
        ? 'down'
        : 'up'
      : undefined;
  }

  @computed get candleLayout() {
    if (!this.cursorDataPoint) {
      return undefined;
    }
    return {
      x: this.x!,
      direction: this.direction!,
      yHigh: this.yHigh!,
      yLow: this.yLow!,
      yBody: this.yUpper!,
      bodyHeight: this.yLower! - this.yUpper!,
      bodyWidth: 7,
    };
  }

  @computed get crosshair() {
    if (!this.cursorDataPoint) {
      return undefined;
    }

    return {
      x: this.x,
      yHigh: this.yHigh,
      yLow: this.yLow,
      yClose: this.yClose,
    };
  }

  @computed get priceLabel() {
    if (!this.cursorDataPoint) {
      return undefined;
    }

    const value = this.cursorDataPoint.close;
    const valueText = `${this.chart.formatNumber(
      value
    )} ${this.chart.formatPercent(this.chart.getDiff(value))}`;

    return this.chart.layout.getLabelLayout(
      value,
      valueText,
      'right',
      this.chart.direction
    )!;
  }

  @computed get candleInfo() {
    if (!this.cursorDataPoint) {
      return undefined;
    }

    const {date, open, close, high, low} = this
      .cursorDataPoint as CandlestickChartDataItem;

    const _high = this.chart.formatNumber(high);
    const _open = this.chart.formatNumber(open);
    const _close = this.chart.formatNumber(close);
    const _low = this.chart.formatNumber(low);

    const infoText = [
      `${i18n.t('charts.candles.open')}: ${_open}`,
      `${i18n.t('charts.candles.high')}: ${_high}`,
      `${i18n.t('charts.candles.low')}: ${_low}`,
      `${i18n.t('charts.candles.close')}: ${_close}`,
    ].join(' / ');
    const dateText = this.chart.formatDate(date);

    return {
      date: {
        x: 20,
        y: this.chart.height - this.chart.layout.labelHeight * 2,
        width: this.chart.layout.getLabelWidth(dateText),
        height: this.chart.layout.labelHeight,
        text: dateText,
      },
      info: {
        x: 20,
        y: this.chart.height - this.chart.layout.labelHeight,
        width: this.chart.layout.getLabelWidth(infoText),
        height: this.chart.layout.labelHeight,
        text: infoText,
        // text: (
        //   <TSpan>
        //     <TSpan>
        //       O: <TSpan fontWeight="bold">{_open}</TSpan>
        //     </TSpan>
        //     {' / '}
        //     <TSpan>
        //       H: <TSpan fontWeight="bold">{_high}</TSpan>
        //     </TSpan>
        //     {' / '}
        //     <TSpan>
        //       L: <TSpan fontWeight="bold">{_low}</TSpan>
        //     </TSpan>
        //     {' / '}
        //     <TSpan>
        //       C: <TSpan fontWeight="bold">{_close}</TSpan>
        //     </TSpan>
        //   </TSpan>
        // ),
      },
    };
  }
}
