import * as React from 'react';
import {observer} from 'mobx-react';
import {Link} from '@youtoken/ui.elements';
import {BankCardsFeature} from '../..';

export const BankCardsAgreementsLink: React.FC = observer(() => {
  const {agreementsUrl, agreementsLinkText} = BankCardsFeature.use({});

  if (!agreementsUrl || !agreementsLinkText) {
    return null;
  }

  return (
    <Link color="$text-05" url={agreementsUrl} testID="AGREEMENTS_LINK">
      {agreementsLinkText}
    </Link>
  );
});
