import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ChartControls, HODLChart, useChartCursorState} from '../Charts';
import {LiveChartDataResource} from '../state/Chart';
import {ChartWithControlsSuspenseFallback} from './SuspenseFallbacks';
import {type ChartSmartCells} from './utils/types';
import {useDefaultHODLTimePeriod} from './utils/useDefaultHODLTimePeriod';
import {CellWrap} from './utils/CellWrap';
import {PanResponder} from '../PanResponder';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const HODLChartSmartCell = cell<ChartSmartCells.HODLChartSmartCellProps>(
  ({
    width,
    height,
    product,
    fromTicker,
    toTicker,
    mode,
    precision,
    initialPrice,
    triggerPrice,
    startDate,
    endDate,
    ChartPanComponent = PanResponder,
    screenName,
    isClosedLike,
    isOpeningLike,
    reversed,
    pending,
    direction,
    marginCallType = 'margin_call',
    marginCall,
    maxLoss,
    maxLossTicker,
    takeProfit,
    maxProfit,
    maxProfitTicker,
    displayTypeSwitcher,
    onPressTradingViewModeButton,
    defaultType = 'line',
    defaultTick = '1m',
    defaultTimePeriod: _defaultTimePeriod,
  }) => {
    const {setPointIndex, pointIndex, setCursorX, cursorX} =
      useChartCursorState();

    const defaultTimePeriod =
      _defaultTimePeriod || useDefaultHODLTimePeriod(startDate);
    const defaultMode = React.useMemo(() => {
      if (Boolean(mode)) {
        return mode;
      }

      return pending
        ? reversed // NOTE: for HODL create form and for pending-orders is a chart with prices which will be used for open the deal
          ? 'bid'
          : 'ask'
        : reversed // NOTE: for opened position is a chart with prices which will be used for close the deal
        ? 'ask'
        : 'bid';
    }, [mode, pending, reversed])!;

    const {chartData, isLoading, chartControls, hasData, refetch} =
      LiveChartDataResource.use({
        key: 'HODLChart',
        product,
        fromTicker,
        toTicker,
        type: defaultType,
        timePeriod: defaultTimePeriod,
        tick: defaultTick,
        mode: defaultMode,
      });

    React.useLayoutEffect(() => {
      chartControls.setMode(defaultMode);
    }, [defaultMode, chartControls.setMode]);

    React.useEffect(() => {
      if (product === 'hodl') {
        DATA_LAYER.trackStrict('hodl-chart-shown', {
          screenName: screenName,
          chartType: chartControls.type,
          instrumentName: `${fromTicker}/${toTicker}`,
          priceType: chartControls.mode!,
        });
      }
    }, [
      product,
      screenName,
      chartControls.type,
      fromTicker,
      toTicker,
      chartControls.mode,
    ]);

    return (
      <>
        <ChartPanComponent
          width={width}
          height={height}
          onCursorChange={setCursorX}
        >
          <HODLChart
            data={chartData}
            height={height}
            width={width}
            cursorX={cursorX}
            isFetching={isLoading}
            displayActivityIndicator
            onCursorIndexChange={setPointIndex}
            initialPrice={initialPrice}
            triggerPrice={triggerPrice}
            marginCallType={isClosedLike ? undefined : marginCallType}
            marginCall={isClosedLike ? undefined : marginCall}
            maxLoss={isClosedLike ? undefined : maxLoss}
            maxLossTicker={isClosedLike ? undefined : maxLossTicker}
            takeProfit={isClosedLike ? undefined : takeProfit}
            maxProfit={isClosedLike ? undefined : maxProfit}
            maxProfitTicker={isClosedLike ? undefined : maxProfitTicker}
            startDate={isOpeningLike ? new Date() : startDate}
            endDate={endDate}
            direction={direction}
            displayInitialPriceLabel={!isClosedLike}
            reversed={reversed}
            pending={pending}
            hasData={hasData}
            refetch={refetch}
            formatNumberPrecision={precision}
          />
        </ChartPanComponent>
        <ChartControls
          isCursorActive={pointIndex !== undefined && hasData}
          displayTypeSwitcher={displayTypeSwitcher}
          onPressTradingViewModeButton={onPressTradingViewModeButton}
          {...chartControls}
        />
      </>
    );
  },
  {
    displayName: 'HODLChartSmartCell',
    SuspenseFallback: ChartWithControlsSuspenseFallback,
    CellWrap: CellWrap,
  }
);
