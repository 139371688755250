import {AxiosError} from '@youtoken/ui.errors';
import {extractErrorFromResponse} from '@youtoken/ui.validation-messages';

export const handleFormSubmitRateChangedError = (
  axiosError: AxiosError,
  cb: () => void
) => {
  const axiosErrorData = axiosError?.response?.data;

  const validationError = extractErrorFromResponse(axiosErrorData, '_error');

  const isRateChangedValidationError = Boolean(
    validationError && validationError.i18n?.label === 'RATE_CHANGED'
  );

  if (isRateChangedValidationError) {
    cb();
  }
};
