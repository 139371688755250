import * as React from 'react';
import {observer} from 'mobx-react';
import {Platform} from 'react-native';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Separator} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {MenuItemBase} from '../MenuItemBase';
import {MultiHodlSpecification} from './MultiHodlSpecification';
import {HelpDesk} from './HelpDesk';
import {Agreement} from './Agreement';

const handlePress = () => {
  SHARED_ROUTER_SERVICE.navigate('FeesAndCommissions');
};

const isNative = Platform.select({
  native: true,
  default: false,
});

export const FeesAndCommissions: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <MenuItemBase
      icon="interest"
      title={t('surface.profile.account.fees.title')}
      subtitle={t('surface.profile.account.fees.text')}
      onPress={isNative ? handlePress : undefined}
      {...boxProps}
    >
      <Box flexDirection="column" flexWrap="wrap" alignItems="flex-start">
        <Box flexDirection="row" flexWrap="wrap">
          <MultiHodlSpecification mr={16} mb={{default: 0, smallPhone: 12}} />
          <HelpDesk />
        </Box>
      </Box>
    </MenuItemBase>
  );
});
