export enum TradingOrderType {
  MARKET = 'MARKET', // ordinal order without trigger price
  LIMIT = 'LIMIT', // pending-order 1) isShort = false & currentPrice > triggerPrice 2) isShort = true & currentPrice < triggerPrice
  STOP = 'STOP', // pending-order 1) isShort = false & currentPrice < triggerPrice 2) isShort = true & currentPrice > triggerPrice
}

export enum HodlOperationType {
  PENDING = 'PENDING',
  OPENED = 'OPENED',
  EXTEND_SL = 'EXTEND_SL',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
}
