import {useMemo} from 'react';
import {
  countriesListSignUp,
  getCountriesListLocalized,
} from '@youtoken/ui.countries-utils';

export const useCountryItems = () => {
  return useMemo(() => {
    const list = getCountriesListLocalized(countriesListSignUp);
    return list.map(country => ({
      value: country.code,
      label: country.name,
      key: country.code,
    }));
  }, []);
};
