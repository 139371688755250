import {
  type IReactionDisposer,
  action,
  autorun,
  comparer,
  computed,
  observable,
} from 'mobx';
import {IBANAccountsResource} from '@youtoken/ui.resource-iban-accounts';
import {type List, type SectionList} from '@youtoken/ui.utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {RatesSettingsResource} from '@youtoken/ui.resource-rates-settings';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';

const SECTION_VISIBILITIES_STORAGE_KEY = 'wallets:sectionsVisibility';
const DEFAULT_SECTION_VISIBILITIES = {
  fiat: true,
  stable: true,
  crypto: true,
};

export class WalletsFeature extends createFeature({
  getKey: _args => {
    return `feature:wallets(${JSON.stringify(_args)})`;
  },
  getResources: _args => {
    const {mainCurrency} = AuthMeResource.getInstance({});

    return {
      wallets: getResourceDescriptor(WalletsResource, _args),
      // NOTE: rateSettings is used in WalletsResponse to find precisions
      rateSettings: getResourceDescriptor(RatesSettingsResource, {
        ticker: mainCurrency,
      }),
      authme: getResourceDescriptor(AuthMeResource, _args),
      verifications: getResourceDescriptor(VerificationResource, {}),
      ibanAccountsResource: getResourceDescriptor(IBANAccountsResource, {}),
    };
  },
}) {
  // public

  @observable disposers: IReactionDisposer[] = [];

  constructor(args: any, resources: any) {
    super(args, resources);
    this.disposers.push(
      autorun(() => {
        const needSetDefaultTickers =
          !this.resources.authme.data?.clientSettings?.showedWalletTickers &&
          !this.resources.authme.data?.clientSettings?.hiddenWalletTickers;

        const tickersWithAmount = this.resources.wallets.walletsList
          .filter(
            wallet =>
              (wallet.amount.gt(0) || wallet.hodlsInputAmount.gt(0)) &&
              !this.tickers.includes(wallet.ticker)
          )
          .map(wallet => wallet.ticker);

        if (tickersWithAmount.length) {
          this.resources.authme.replaceShowedTickers([
            ...this._allShownTickers,
            ...tickersWithAmount,
          ]);
        } else if (needSetDefaultTickers) {
          this.resources.authme.replaceShowedTickers(this._allShownTickers);
        }
        this.sectionVisibilities = this.getSectionVisibilities();

        if (this.resources.authme.data?.clientSettings) {
          const sections: SectionList[] = ['fiat', 'stable', 'crypto'];
          sections.forEach(section => {
            if (this.listTickers[section]?.length === 0) {
              this.setSectionVisibility(section, false);
            }
          });
        }
      })
    );
  }

  onDestroy() {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer());
  }

  @computed
  public get creating() {
    return this.resources.wallets.walletsList.length === 0; //need indicator from backend, temp solution
  }

  @computed
  public get listVisibilities() {
    return {
      fiat: this.sectionVisibilities.fiat,
      stable: this.sectionVisibilities.stable,
      crypto: this.sectionVisibilities.crypto,
      hidden: this.resources.authme.showedHiddenTickers,
    };
  }

  @computed
  public get listTickers() {
    return {
      fiat: this._shownFiatTickers,
      stable: this._shownStableTickers,
      crypto: this._shownCryptoTickers,
      hidden: this._hiddenTickers,
    };
  }

  /**
   * tickers in default order
   */
  @computed({
    equals: comparer.shallow,
  })
  public get tickers() {
    return this.resources.wallets.walletsTickersWithAmountOrder.filter(
      ticker => {
        return this.resources.authme.addedWalletTickers.includes(ticker);
      }
    );
  }

  @computed
  public get symbols() {
    return this.tickers.map(ticker => {
      return `${ticker}/${this.resources.authme.mainCurrency}`;
    });
  }

  @observable sectionVisibilities: Record<SectionList, boolean> =
    DEFAULT_SECTION_VISIBILITIES;

  @action getSectionVisibilities = (): Record<SectionList, boolean> => {
    const storedString = SIMPLE_STORAGE.get(SECTION_VISIBILITIES_STORAGE_KEY);
    if (!storedString) {
      return DEFAULT_SECTION_VISIBILITIES;
    }

    try {
      const data = JSON.parse(storedString);
      return data;
    } catch {
      return DEFAULT_SECTION_VISIBILITIES;
    }
  };

  @action setSectionVisibility = (section: SectionList, value: boolean) => {
    const event = value ? 'wallet-section-expand' : 'wallet-section-collapse';
    DATA_LAYER.trackStrict(event, {section});

    this.sectionVisibilities = {
      ...this.sectionVisibilities,
      [section]: value,
    };

    this.storeSectionVisibilities();
  };

  @action storeSectionVisibilities = () => {
    SIMPLE_STORAGE.set(
      SECTION_VISIBILITIES_STORAGE_KEY,
      JSON.stringify(this.sectionVisibilities)
    );
  };

  @action
  public setVisibility = (list: List, visibility: boolean) => {
    if (list === 'hidden') {
      this.resources.authme.setShowedHiddenTickers(visibility);
    } else {
      this.setSectionVisibility(list, visibility);
    }
  };

  // private

  @computed
  private get _hiddenTickers() {
    return this.tickers.filter(ticker =>
      this.resources.authme.hiddenWalletTickers.includes(ticker)
    );
  }

  @computed
  private get _allShownTickers() {
    return this.tickers.filter(ticker =>
      this.resources.authme.showedWalletTickers.includes(ticker)
    );
  }

  @computed
  get _shownFiatTickers() {
    return this._allShownTickers.filter(ticker =>
      this.resources.wallets.fiatTickers.includes(ticker)
    );
  }

  @computed
  get _shownCryptoTickers() {
    return this._allShownTickers.filter(ticker =>
      this.resources.wallets.cryptoTickers.includes(ticker)
    );
  }

  @computed
  get _shownStableTickers() {
    return this._allShownTickers.filter(ticker =>
      this.resources.wallets.stableTickers.includes(ticker)
    );
  }
}
