import * as React from 'react';
import {type LayoutChangeEvent} from 'react-native';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {ListContext} from '../contexts';

export interface ProviderProps extends BoxProps {}

export const Provider: React.FC<ProviderProps> = React.memo(
  ({children, ...otherProps}) => {
    const [allowChart, setAllowChart] = React.useState(false);

    const handleLayout = React.useCallback(
      ({
        nativeEvent: {
          layout: {width},
        },
      }: LayoutChangeEvent) => {
        setAllowChart(width >= 375);
      },
      [setAllowChart]
    );

    return (
      <Box width="100%" onLayout={handleLayout} {...otherProps}>
        <ListContext.Provider
          value={{
            allowChart,
          }}
        >
          {children}
        </ListContext.Provider>
      </Box>
    );
  }
);
