import * as React from 'react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {
  type LevelIconProps,
  LevelIcon,
} from '@youtoken/ui.loyalty-miner-components';

export interface SavingsProgressWithLevelsMarkIconProps {
  name: LevelIconProps['name'];
}

export const SavingsProgressWithLevelsMarkIcon: React.FC<
  SavingsProgressWithLevelsMarkIconProps & BoxProps
> = ({name, ...boxProps}) => {
  return (
    <Box
      p={2}
      mr={-15}
      borderWidth={1}
      borderColor="$transparent"
      borderRadius={17}
      {...boxProps}
    >
      <LevelIcon name={name} size={18} wrapSize={24} />
    </Box>
  );
};
