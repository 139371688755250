import {deserialize} from 'serializr';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LoanRegularV1, LoanRegularV2} from '../Loan';

export class LoanItemRegularResource extends createResource<
  {id: string},
  LoanRegularV1 | LoanRegularV2
>({
  getKey: ({id}) => `LoanRegular(${id})`,
  getData: ({id}) => {
    return TRANSPORT.API.get(`/v2/loan/${id}`).then(response => {
      if (response.data.version === 1) {
        return deserialize(LoanRegularV1, response.data);
      }

      return deserialize(LoanRegularV2, response.data);
    });
  },
  skipRefreshOnVisible: false,
}) {}
