import {type Theme, useResponsive} from '@youtoken/ui.primitives';
import {type QuestionTooltipProps} from '../QuestionTooltip';
import {type ResponsiveValue} from '@shopify/restyle';

export const useCalculateBodyOffset = (
  bodyOffset: QuestionTooltipProps['bodyHorizontalOffset'] = 0
) => {
  return useResponsive(
    typeof bodyOffset === 'number'
      ? {default: bodyOffset}
      : (bodyOffset as ResponsiveValue<number, Theme['breakpoints']>)
  );
};

export const calculateOffset = (iconSize: number, caretPosition?: string) => {
  const halfIconOffset = iconSize / 2;

  switch (caretPosition) {
    case 'left':
      return halfIconOffset;
    case 'right':
      return -halfIconOffset;
    default:
      return 0;
  }
};
