import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {cell} from '@youtoken/ui.cell';
import {
  AllInstrumentsSection,
  BestRolloverRatesSection,
  InstrumentsListCleaner,
  SectorsSection,
  StarredSection,
  TopCapSection,
  TopMoversSection,
} from '@youtoken/ui.surface-hodl-instruments';

export const HODLsInstrumentsPageContent: React.FC = cell(() => {
  const handlePressInstrument = React.useCallback((id: string) => {
    DATA_LAYER.trackStrict('hodl-open-instrument-from-instruments', {});
  }, []);

  return (
    <>
      <StarredSection mb={24} />
      <TopCapSection mb={24} />
      <SectorsSection mb={24} />
      <BestRolloverRatesSection mb={24} />
      <TopMoversSection />
      <InstrumentsListCleaner>
        <AllInstrumentsSection
          width="100%"
          onPressInstrument={handlePressInstrument}
        />
      </InstrumentsListCleaner>
    </>
  );
});
