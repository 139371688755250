import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, useIsMobile} from '@youtoken/ui.primitives';
import {
  ChartTypeSwitch,
  TimeFrameButton,
  TradingViewModeSwitch,
} from './components';
import {type ChartControlsProps} from './types';
import {timePeriods, ticks, CONTROLS_HEIGHT} from './constants';

export const ChartControls = React.memo<ChartControlsProps>(
  ({
    isCursorActive,
    type,
    setChartType,
    timePeriod,
    setTimePeriod,
    tick,
    setTick,
    displayTypeSwitcher = true,
    onPressTradingViewModeButton,
  }) => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();

    const tradingViewModeSwitchPosition = React.useMemo(() => {
      return Boolean(onPressTradingViewModeButton)
        ? isMobile
          ? 'end'
          : 'start'
        : undefined;
    }, [onPressTradingViewModeButton, isMobile]);

    return (
      <Box
        position="absolute"
        height={CONTROLS_HEIGHT + 16 + 16}
        bottom={0}
        left={0}
        opacity={isCursorActive ? 0 : 1}
        px={isMobile ? 8 : 24}
        width="100%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        py={16}
      >
        {Boolean(onPressTradingViewModeButton) &&
          tradingViewModeSwitchPosition === 'start' && (
            <TradingViewModeSwitch
              mode="classic"
              onChange={onPressTradingViewModeButton!}
              flexGrow={1}
            />
          )}
        <Box
          flexDirection="row"
          flexGrow={Boolean(tradingViewModeSwitchPosition) ? 0 : 1}
          mr={16}
        >
          {type === 'line' && (
            <>
              {timePeriods.map(_timePeriod => (
                <TimeFrameButton
                  key={_timePeriod}
                  active={_timePeriod === timePeriod}
                  onPress={() => setTimePeriod(_timePeriod)}
                  testID={`LINE_CHART_TIME_PERIOD_BUTTON_${_timePeriod}`}
                >
                  {t(`charts.time_periods.${_timePeriod}`, {
                    defaultValue: _timePeriod,
                  })}
                </TimeFrameButton>
              ))}
            </>
          )}
          {type === 'candle' && (
            <>
              {ticks.map(_tick => (
                <TimeFrameButton
                  key={_tick}
                  active={_tick === tick}
                  onPress={() => setTick(_tick)}
                  testID={`CANDLE_CHART_TICK_BUTTON_${_tick}`}
                >
                  {t(`charts.tick_periods.${_tick}`, {defaultValue: _tick})}
                </TimeFrameButton>
              ))}
            </>
          )}
        </Box>

        {displayTypeSwitcher && (
          <ChartTypeSwitch type={type} onChange={setChartType} />
        )}
        {Boolean(onPressTradingViewModeButton) &&
          tradingViewModeSwitchPosition === 'end' && (
            <TradingViewModeSwitch
              mode="classic"
              onChange={onPressTradingViewModeButton!}
            />
          )}
      </Box>
    );
  }
);
