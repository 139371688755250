import React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {Tabs} from '@youtoken/ui.tabs';
import {BankWireFeature} from '../../../../states/BankWireFeature';
import {type AccountType} from '../../../../types/AccountType';

interface AccountTypeSwitcherProps {
  ticker: string;
}

export const AccountTypeSwitcher: React.FC<AccountTypeSwitcherProps> = observer(
  ({ticker}) => {
    const {
      isTypeSwitcherAvailable,
      accountTypesTabs,
      accountTypesTabActive,
      setAccountType,
    } = BankWireFeature.use({ticker});

    React.useEffect(() => {
      if (accountTypesTabActive === -1 && accountTypesTabs.length > 1) {
        setAccountType(accountTypesTabs[0]!.value as AccountType);
      }
    }, [accountTypesTabActive, accountTypesTabs, setAccountType]);

    if (!isTypeSwitcherAvailable) {
      return null;
    }

    return (
      <Box px={24} pt={24}>
        <Tabs
          size="small"
          activeIndex={accountTypesTabActive}
          onChange={value => setAccountType(value as AccountType)}
          tabs={accountTypesTabs}
        />
      </Box>
    );
  }
);
