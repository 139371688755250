import * as React from 'react';
import {Box, Heading, Text} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {SecurityActivityResource} from '@youtoken/ui.state-security';
import {SecurityActivityList} from './SecurityActivityList';
import {SecurityActivityProps} from './types';

export const SecurityActivitySurface: React.FC<SecurityActivityProps> = ({
  items,
}) => {
  const {t} = useTranslation();

  return (
    <Box width="100%">
      <Heading
        variant="$heading-01-responsive"
        color="$text-01"
        mb={24}
        px={{default: 16, phone: 24, desktop: 0}}
      >
        {t('surface.security_activity.title')}
      </Heading>
      <Text
        variant="$body-01"
        mb={24}
        px={{default: 16, phone: 24, desktop: 0}}
      >
        {t('surface.security_activity.description')}
      </Text>
      <SecurityActivityList items={items} />
    </Box>
  );
};

export const SecurityActivitySurfaceCell = cell(() => {
  const {data, refetchManually} = SecurityActivityResource.use({});

  return <SecurityActivitySurface items={data} checkStatus={refetchManually} />;
});
