import * as Cookies from 'js-cookie';
import {useState} from 'react';

export const useCookies = <T extends string>(
  key: string,
  initialValue?: T
): [
  T | undefined | string,
  (value: T, options?: Cookies.CookieAttributes) => void
] => {
  const [item, setInnerValue] = useState(() => {
    return Cookies.get(key) || initialValue;
  });

  const setValue = (value: T, options?: Cookies.CookieAttributes) => {
    setInnerValue(value);
    Cookies.set(key, value, options);
  };

  return [item, setValue];
};
