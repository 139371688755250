import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, BoxProps, Text} from '@youtoken/ui.primitives';
import {Badge, DeviceImage} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {SecurityDeviceData} from '@youtoken/ui.state-security';

export interface FirstLoginModalProps extends BoxProps {
  item: SecurityDeviceData;
}

export const FirstLoginModal: React.FC<FirstLoginModalProps> = observer(
  ({item, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      displayDeviceModel,
      displayDeviceIcon,
      displayDeviceLogo,
      displayLocationWithIp,
      isCurrent,
    } = item;

    const handleNavigate = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      SHARED_ROUTER_SERVICE.navigate('TrustedDevices');
    }, []);

    return (
      <Box
        alignItems="center"
        py={32}
        px="$screen-default-offset"
        {...boxProps}
      >
        <Box mb={12}>
          <DeviceImage
            deviceIcon={displayDeviceIcon as any}
            deviceLogo={displayDeviceLogo as any}
            size="large"
          />
        </Box>
        <Box flexDirection="row" mb={8}>
          <Icon name="location" size={16} color="$text-02" />
          <Text variant="$body-01" color="$text-02" ml={2}>
            {displayLocationWithIp}
          </Text>
        </Box>

        <Box flexDirection="row" mb={8}>
          <Text variant="$body-01" color="$text-02">
            {displayDeviceModel}
          </Text>
        </Box>

        {isCurrent && (
          <Badge variant="success" size="small" type="secondary" icon="check">
            {t('surface.trusted_devices.devices.active_badge')}
          </Badge>
        )}
        <Box my={32}>
          <Text variant="$body-02" textAlign="center">
            {t('modal.first_login.description')}
          </Text>
        </Box>
        <Box width="100%">
          <Button type="secondary" size="large" onPress={handleNavigate}>
            {t('modal.first_login.action_text')}
          </Button>
        </Box>
      </Box>
    );
  }
);
