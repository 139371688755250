import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {TouchableBox, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {ConversionFormClass, ExchangeFormState} from '../../../state';

export const ExchangeFormConversionPairSourceBalance: React.FC = observer(
  () => {
    const {t} = useTranslation();

    const {
      form: {setAmountByBalance},
      view: {tickerFormatted, balanceFormatted},
    } = useForm<ConversionFormClass | ExchangeFormState>();

    return (
      <TouchableBox
        testID="CONVERT_AMOUNT_BUTTON_ALL"
        onPress={setAmountByBalance}
      >
        <Text color="$interactive-01" variant="$body-02">
          {t('surface.wallets.conversion_form.from_all')}{' '}
          <Text color="$interactive-01" variant="$body-02-medium-accent">
            {balanceFormatted}
          </Text>{' '}
          {tickerFormatted}
        </Text>
      </TouchableBox>
    );
  }
);
