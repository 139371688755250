import * as React from 'react';
import {ScrollView} from 'react-native';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {RefreshControl} from '@youtoken/ui.elements';
import {PleaseVerifyWaiting} from './Waiting';
import {PleaseVerifyWelcome} from './Welcome';

export const PleaseVerifyContent: React.FC<BoxProps> = observer(() => {
  const {forceIdentifyWaiting, isLoadingManually, refetchManually} =
    AuthMeResource.use({});

  return (
    <ScrollView
      refreshControl={
        <RefreshControl
          refreshing={isLoadingManually}
          onRefresh={refetchManually}
        />
      }
    >
      <Box
        flex={1}
        flexShrink={0}
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        p={24}
        mt={36}
      >
        {forceIdentifyWaiting ? (
          <PleaseVerifyWaiting />
        ) : (
          <PleaseVerifyWelcome />
        )}
      </Box>
    </ScrollView>
  );
});
