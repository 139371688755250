import * as React from 'react';
import {modal} from '@web-app/src/stores';
import {HodlFlipBottomSheet} from '@youtoken/ui.surface-hodl-positions';

interface ModalExtendTpSlProps {
  id: string;
}

export const ModalHodlFlip: React.FC<ModalExtendTpSlProps> = ({id}) => {
  return <HodlFlipBottomSheet onClose={modal.close} hodlId={id} />;
};
