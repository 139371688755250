import * as React from 'react';
import {Helmet} from 'react-helmet';
import {PleaseVerify} from '@youtoken/ui.surface-please-verify';
import {LanguageSwitch} from '@youtoken/ui.language-switch';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {CoindropResource} from '@youtoken/ui.coindrop-resource';
import {CoindropSurface} from '@youtoken/ui.surfaces-wallets';
import {AppShell} from '@web-app/src/components/layout/Shell';
import {Header} from '@web-app/src/components/layout/Shell/AppShell/Header';
import {
  Help,
  Logo,
} from '@web-app/src/components/layout/Shell/AppShell/Header/elements';

export const PleaseVerifyPage: React.FC = () => {
  const {t} = useTranslation();

  const {forceIdentify, signOut} = AuthMeResource.use({});
  // Data must be fetched silently to avoid showing any errors if something goes wrong on BE side. Instead of errors it should render regular verify flow
  const data = CoindropResource.use({shouldFetchWithSuppressErrors: true});

  const handleSignOut = React.useCallback(() => {
    signOut().then(() => {
      SHARED_ROUTER_SERVICE.navigate('SignIn', {});
    });
  }, [signOut]);

  const handlePressProfile = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.navigate('More', {});
  }, []);

  React.useEffect(() => {
    if (!forceIdentify) {
      SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
    }
  }, [forceIdentify]);

  const renderHeader = React.useCallback(() => {
    return (
      <Header
        renderLeft={() => {
          return <Logo />;
        }}
        renderRight={() => {
          return (
            <Box flexDirection="row">
              <LanguageSwitch
                size="small"
                width={96}
                dropdownWidth={116}
                boxProps={{ml: 16}}
                testID="LANGUAGE_LIST"
              />
              <Button
                type="secondary"
                size="small"
                onPress={handlePressProfile}
                testID="PROFILE_LINK"
                icon="profile"
                ml={8}
              >
                {t('surface.menu.profile')}
              </Button>
              <Help />
              <Button
                type="secondary"
                size="small"
                onPress={handleSignOut}
                testID="SIGN_OUT_LINK"
                ml={8}
              >
                {t('surface.menu.sign_out')}
              </Button>
            </Box>
          );
        }}
      />
    );
  }, [t]);

  return (
    <>
      <Helmet title={`YouHodler | ${t('surface.please_verify.title')}`} />
      <AppShell renderHeader={renderHeader}>
        <Box
          alignSelf="center"
          width={{default: '100%', desktop: 700}}
          my={32}
          borderRadius={10}
          borderColor="$ui-01"
          borderWidth={1}
        >
          {data?.dropsAvailableByTariff ? (
            <CoindropSurface />
          ) : (
            <PleaseVerify />
          )}
        </Box>
      </AppShell>
    </>
  );
};
