import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../state/WithdrawalForm';

const providersMap = {
  sepa: {
    label: i18n.t('surface.wallets.withdrawal_bank_wire_form.sepa'),
    labelTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_SEPA_LABEL',
    minAmountTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_SEPA_MIN_AMOUNT',
  },
  swift: {
    label: i18n.t('surface.wallets.withdrawal_bank_wire_form.swift'),
    labelTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_SWIFT_LABEL',
    minAmountTestID: 'FIAT_WITHDRAWAL_BANK_WIRE_SWIFT_MIN_AMOUNT',
  },
};

export const PaymentInfo: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {feesFormattedList},
    view: {tickerFormatted},
  } = useForm<WithdrawalForm>();

  if (!feesFormattedList.length) {
    return null;
  }

  return (
    <Box mb={24}>
      {feesFormattedList.map(fee => {
        const providerData =
          providersMap[fee.provider as keyof typeof providersMap];

        return (
          <React.Fragment key={fee.provider}>
            <Text variant="$body-02" color="$text-02">
              {t('surface.wallets.withdrawal_bank_wire_form.minimum')}:{' '}
              <Text
                testID={
                  providerData?.minAmountTestID ??
                  'FIAT_WITHDRAWAL_BANK_WIRE_ESTIMATED_MIN_AMOUNT'
                }
              >
                {fee.minAmount}
              </Text>{' '}
              {tickerFormatted}
              {feesFormattedList.length > 1 && providerData && (
                <Text testID={providerData.labelTestID}>
                  {' '}
                  ({providerData.label})
                </Text>
              )}
            </Text>

            {fee.estimationTime && (
              <Text
                variant="$body-02"
                color="$text-02"
                testID="FIAT_WITHDRAWAL_BANK_WIRE_ESTIMATED_ARRIVAL_LABEL"
              >
                {t(
                  'surface.wallets.withdrawal_bank_wire_form.estimated_arrival'
                )}
                : {fee.estimationTime}
              </Text>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
});
