import * as React from 'react';
import {Route} from 'react-router-dom';
import {SwitchWithNotFound} from '../SwitchWithNotFound';
import {authRequired} from '../authRequired';
import {WalletsRoutes} from '../WalletsRoutes';
import {LoansRoutes} from '../LoansRoutes';
import {MultihodlRoutes} from '../MultihodlRoutes';
import {MinerRoutes} from '../MinerRoutes';
import {TurbochargeRoutes} from '../TurbochargeRoutes';
import {AccountStatsRoutes} from '../AccountStatsRoutes';
import {ProfileRoutes} from '../ProfileRoutes';

const ProfileRoutesWrapped = authRequired(ProfileRoutes);

export const AppLedgerRoutes: React.FC = () => {
  return (
    <SwitchWithNotFound>
      <Route path="/wallets" component={WalletsRoutes} />
      <Route path="/loans" component={LoansRoutes} />
      <Route path="/multihodl" component={MultihodlRoutes} />
      <Route path="/miner" component={MinerRoutes} />
      <Route path="/turbocharge" component={TurbochargeRoutes} />
      <Route path="/account-stats" component={AccountStatsRoutes} />
      <Route path="/profile" component={ProfileRoutesWrapped} />
    </SwitchWithNotFound>
  );
};
