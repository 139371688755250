// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {debounce} from 'lodash';
import {Canceler} from 'axios';
import {
  action,
  computed,
  type IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {messages} from '@youtoken/ui.validation-messages';
import {TRANSPORT} from '@youtoken/ui.transport';
import {i18n} from '@youtoken/ui.service-i18n';
import {invariant} from '@youtoken/ui.utils';
import * as yupPackage from '@youtoken/ui.yup';
import {
  handleFormFieldsErrors,
  handleFormSubmitError,
} from '@youtoken/ui.form-utils';
import {asyncCheckUmaAvailability, UMA_USERNAME_REGEX} from '../../../utils';
import {type UmaCreateFormArgs, type UmaCreateFormResources} from './types';

export class Form {
  @observable
  public instance: MobxReactForm;

  @observable
  public isUmaChecking: boolean = false;

  @observable
  public isFormSubmitting: boolean = false;

  @observable
  public checkCanceller: Canceler | undefined;

  @observable
  public resources: UmaCreateFormResources;

  disposers: Array<IReactionDisposer> = [];

  public constructor(
    args: UmaCreateFormArgs,
    resources: UmaCreateFormResources
  ) {
    this.resources = resources;

    invariant(
      resources.wallets.openedWalletsListSortedByEquivalent.length > 0,
      'list of opened wallets is empty'
    );

    const defaultTicker =
      resources.wallets.openedWalletsListSortedByEquivalent[0]!.ticker;

    this.instance = new MobxReactForm(
      {
        fields: {
          uma: {value: ''},
          ticker: {value: defaultTicker},
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                const schema = {
                  uma: yup
                    .string()
                    .required(messages.REQUIRED)
                    .max(63)
                    .matches(
                      UMA_USERNAME_REGEX,
                      i18n.t(
                        'surface.wallets.uma.uma_input.message.is_not_valid'
                      )
                    ),
                  ticker: yup.string().required(messages.REQUIRED),
                };

                return yup.object().shape(schema);
              }),
          }),
        },
        hooks: {
          onSuccess: (form: MobxReactForm) => {
            this.isFormSubmitting = true;
            const values = form.values();

            return asyncCheckUmaAvailability(this, this.checkCanceller)
              .then(() => {
                return TRANSPORT.API.post('/v1/uma', {
                  ticker: values.ticker,
                  uma: this.umaFullName,
                })
                  .then(async () => {
                    await resources.umaInfo.refetch();

                    LOCAL_NOTIFICATIONS.info({
                      text: i18n.t('surface.wallets.uma.created_notification'),
                    });

                    args.onSuccess();
                  })
                  .catch(e => {
                    handleFormSubmitError(form, e);
                  })
                  .finally(() => {
                    this.isFormSubmitting = false;
                  });
              })
              .catch(error => {
                handleFormFieldsErrors(this.instance, error);
                this.isFormSubmitting = false;
              });
          },
        },
        options: {
          validateOnInit: false,
          validateOnBlur: false,
          validateOnChange: true,
          showErrorsOnReset: false,
          validationDebounceWait: 700,
        },
      }
    );

    this.disposers.push(
      reaction(
        () => this.uma,
        debounce(async () => {
          if (this.hasUmaError) {
            this.isUmaChecking = false;
            return;
          }

          return asyncCheckUmaAvailability(this, this.checkCanceller).catch(
            error => {
              handleFormFieldsErrors(this.instance, error);
            }
          );
        }, 700),
        {
          fireImmediately: false,
        }
      )
    );
  }

  @computed
  public get uma() {
    return this.instance.$('uma').value;
  }

  @computed
  public get umaFullName() {
    return `$${this.uma}${this.resources.umaInfo.umaDomainFormatted}`;
  }

  @computed get hasUmaError() {
    return Boolean(this.instance.$('uma').error);
  }

  @computed get isSubmitDisabled() {
    return (
      this.hasUmaError ||
      !this.uma.length ||
      this.isUmaChecking ||
      this.isFormSubmitting
    );
  }

  @computed get isSubmitLoading() {
    return this.isUmaChecking || this.isFormSubmitting;
  }

  @action public setTicker = (value: string) => {
    this.instance.$('ticker').set('value', value);
  };

  @action public setUma = (value: string) => {
    this.isUmaChecking = true;
    this.instance.$('uma').set('value', value);
  };

  @action
  public onSubmit = () => {
    this.instance.submit();
  };

  @action
  dispose = () => {
    this.disposers.forEach(disposer => {
      disposer?.();
    });
  };
}
