// locales list https://stackoverflow.com/questions/3191664/list-of-all-locales-and-their-short-codes

export const DEFAULT_LANG = 'en';

export const languages = [
  {value: 'en', label: 'English', production: true},
  {value: 'fr', label: 'French', production: true},
  {value: 'it', label: 'Italian', production: true},
  {value: 'es', label: 'Spanish', production: true},
  {value: 'de', label: 'German', production: true},
  {value: 'ro', label: 'Romanian', production: true},
  {value: 'pt', label: 'Portuguese', production: true},
  {value: 'cimode', label: 'Keys', production: false},
];
