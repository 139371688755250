import EventEmitter from 'eventemitter3';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {SENTRY} from '@youtoken/ui.sentry';

export class DataStorage {
  public events = new EventEmitter<
    'set' | 'delete' | 'delete-error' | 'clean'
  >();

  // yep, it will be any for now; sorry about that;
  public storage: {[key: string]: any};

  constructor() {
    this.storage = {};
  }

  public has = (key: string): boolean => {
    return Boolean(this.storage[key]);
  };

  public get = <T extends any = any>(key: string): T => {
    return this.storage[key];
  };

  public set = <T extends any = any>(key: string, item: T): T => {
    this.storage[key] = item;
    this.events.emit('set', key, item);

    return item;
  };

  private reportDeletionError = (key: string, error: any) => {
    // display in console;
    if (ENVIRONMENT.DEV) {
      console.error('DATA_STORAGE.delete(): error', key, error);
    }

    // capture with sentry;
    SENTRY.capture(error as Error, {
      source: 'DATA_STORAGE.delete()',
      extra: {
        key,
      },
    });

    // emit custom event (just in case :)
    this.events.emit('delete-error', key, this.storage[key], error);
  };

  public delete = (key: string): void => {
    this.events.emit('delete', key, this.storage[key]);

    if (this.storage[key]) {
      try {
        // this is kinda hacky but it assumes that
        // in case of instance of Resource/Feature there is sync onDestroy() fn;
        // also may be not; thats why there is a question marks everywhere;
        this.storage[key]?.onDestroy?.();

        // we will not wrap it in timeout (at least until we need to);
        delete this.storage[key];
      } catch (error) {
        // in case there is error in onDestroy();
        this.reportDeletionError(key, error);
      }
    }
  };

  public clean = (): void => {
    this.events.emit('clean');

    this.getAllKeys().forEach(key => {
      if (this.storage[key].shouldBeDeletedOnCleanup === false) {
        return;
      }

      this.delete(key);
    });
  };

  public getAllKeys = (): string[] => {
    return Object.keys(this.storage);
  };
}
