import {startCase, lowerCase} from 'lodash';

declare global {
  interface Window {
    Intercom: Function;
  }
}

type IntercomCallback = (Intercom: typeof window.Intercom) => void;

/** Run callback with `window.Intercom` */
export const withIntercom = (cb: IntercomCallback) => {
  if (typeof window !== 'undefined' && window.Intercom) {
    cb(window.Intercom);
  }
};

export const isIntercomAvaliable = (): boolean => {
  return Boolean(window.Intercom);
};

type User = {
  appId: string;
  userHash?: string | null;
  email?: string;
  name?: string;
  id?: string | null;
  isDeposit?: boolean;
  phone?: string;
};

/** boot Intercom for user */
export const bootIntercom = (params: User) => {
  const {email, name, id, isDeposit, phone, userHash, appId} = params;

  return withIntercom(Intercom => {
    Intercom('boot', {
      app_id: appId,
      email: email,
      user_hash: userHash,
      ...(phone ? {phone} : {}),
      ...(name ? {name: startCase(lowerCase(name))} : {}),
      ...(id ? {user_id: id} : {}),
      ...(isDeposit ? {Deposited: isDeposit} : {}),
    });
  });
};

/** Opens intercom chat if intercom is enable */
export const openIntercomChat = (message?: string): void => {
  withIntercom(Intercom => {
    Intercom('showNewMessage', message);
  });
};
