import {invariant} from '@youtoken/ui.utils';
import {AppEnv} from './AppEnv';
import {getWebAppUrl} from './webAppUrl';

/** Returns backend url based on given app environment */
export const getBackendUrl = (env: AppEnv) => {
  invariant(
    Object.values(AppEnv).includes(env),
    `cannot get backendUrl for AppEnv="${env}"`
  );

  return `${getWebAppUrl(env)}/api`;
};
