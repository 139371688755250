import * as React from 'react';
import {createWizard, transparentScreenOptions} from '@youtoken/ui.elements';
import {UmaInfoResource} from '@youtoken/ui.resources-uma-info';
import {cell} from '@youtoken/ui.cell';
import {getUmaInvoiceConfirmationTitle} from '../../Uma/UmaInvoiceConfirmationCell';
import {getUmaWithdrawalTitle} from '../../Uma/UmaWithdrawalCell';
import {FiatWithdrawalChooseMethod} from './ChooseMethod';
import {getUmaCreateTitle} from '../../Uma/UmaCreateCell';
import {
  FiatWithdrawalBankWire,
  getFiatWithdrawalBankWireTitle,
} from './BankWire';
import {
  UmaCreateCell,
  UmaInvoiceConfirmationCell,
  UmaWithdrawalCell,
} from '../../Uma';

export enum FiatWithdrawalWizardScreenName {
  CHOOSE_METHOD = 'chooseMethod',
  BANK_WIRE = 'bankWire',
  CREDIT_CARD = 'creditCard',
  UMA_CREATE = 'umaCreate',
  UMA_WITHDRAWAL = 'umaWithdrawal',
  UMA_INVOICE_CONFIRMATION = 'umaInvoiceConfirmation',
}

export interface FiatWithdrawalWithTickerProps {
  ticker: string;
}

export interface FiatWithdrawalWizardNavigator {
  [FiatWithdrawalWizardScreenName.CHOOSE_METHOD]: FiatWithdrawalWithTickerProps;
  [FiatWithdrawalWizardScreenName.BANK_WIRE]: FiatWithdrawalWithTickerProps;
  [FiatWithdrawalWizardScreenName.CREDIT_CARD]: FiatWithdrawalWithTickerProps;
  [FiatWithdrawalWizardScreenName.UMA_CREATE]: {};
  [FiatWithdrawalWizardScreenName.UMA_WITHDRAWAL]: {
    ticker: string;
    uma?: string;
    amount?: string;
    fromTicker?: string;
  };
  [FiatWithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION]: {
    uma: string;
    ticker: string;
    toTicker: string;
    amount: string;
    invoiceRequestId: string;
    requestUuid: string;
  };
}

const Wizard = createWizard<FiatWithdrawalWizardNavigator>();

export interface FiatWithdrawalWizardProps {
  ticker: string;
  defaultStep?: FiatWithdrawalWizardScreenName;
  onPressBack?: () => void;
}

export interface FiatWithdrawalWizardWrappedProps {
  route: {
    params: Omit<FiatWithdrawalWizardProps, 'onPressBack'>;
  };
  navigation: {
    goBack: () => void;
  };
}

export const FiatWithdrawalWizard: React.FC<FiatWithdrawalWizardProps> = cell(
  ({
    ticker,
    defaultStep = FiatWithdrawalWizardScreenName.CHOOSE_METHOD,
    onPressBack,
  }) => {
    const {isUmaOpened} = UmaInfoResource.use({});

    return (
      <Wizard.Navigator
        initialName={defaultStep}
        initialProps={{ticker}}
        goBack={onPressBack}
      >
        <Wizard.Screen
          name={FiatWithdrawalWizardScreenName.CHOOSE_METHOD}
          options={transparentScreenOptions}
          component={FiatWithdrawalChooseMethod}
        />
        <Wizard.Screen
          name={FiatWithdrawalWizardScreenName.BANK_WIRE}
          options={{
            title: getFiatWithdrawalBankWireTitle(ticker),
          }}
          component={FiatWithdrawalBankWire}
        />
        <Wizard.Screen
          name={FiatWithdrawalWizardScreenName.UMA_CREATE}
          options={{
            title: getUmaCreateTitle(),
          }}
          component={UmaCreateCell}
        />
        <Wizard.Screen
          name={FiatWithdrawalWizardScreenName.UMA_WITHDRAWAL}
          options={{
            title: getUmaWithdrawalTitle(ticker, isUmaOpened),
          }}
          component={UmaWithdrawalCell}
        />
        <Wizard.Screen
          name={FiatWithdrawalWizardScreenName.UMA_INVOICE_CONFIRMATION}
          options={{title: getUmaInvoiceConfirmationTitle(ticker)}}
          component={UmaInvoiceConfirmationCell}
        />
        <Wizard.Screen
          name={FiatWithdrawalWizardScreenName.CREDIT_CARD}
          component={() => null}
        />
      </Wizard.Navigator>
    );
  }
);

export const FiatWithdrawalWizardWrapped = ({
  route: {
    params: {ticker, defaultStep},
  },
  navigation: {goBack},
}: FiatWithdrawalWizardWrappedProps) => {
  return (
    <FiatWithdrawalWizard
      ticker={ticker}
      defaultStep={defaultStep}
      onPressBack={goBack}
    />
  );
};
