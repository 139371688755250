import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const RefreshIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.111 9.072c-5.88-5.517-15.13-5.396-20.884.358-5.87 5.87-5.878 15.376-.02 21.234 5.73 5.73 14.952 5.846 20.845.354l.388-.374.134-.154a1.501 1.501 0 00-2.087-2.113l-.168.145c-4.696 4.695-12.3 4.703-16.987.016-4.686-4.686-4.68-12.291.016-16.986 4.695-4.696 12.3-4.703 16.987-.016l-1.44 1.439a.75.75 0 00.52 1.28l4.915.078a.75.75 0 00.744-.587l.018-.175-.078-4.916a.75.75 0 00-1.28-.518L31.46 9.41l-.349-.338z"
          fill={color}
        />
      </Svg>
    );
  }
);
