import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {CryptoWithdrawalMethodsBase} from './index.base';

export const CryptoWithdrawalMethods: React.FC = observer(() => {
  return (
    <Box pb={32}>
      <CryptoWithdrawalMethodsBase />
    </Box>
  );
});
