import * as React from 'react';
import {observer} from 'mobx-react';
import {
  Easing,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import {
  LoyaltyProgressBarLevel,
  LoyaltyLevel,
} from '@youtoken/ui.resource-loyalty';
import {Box, ReanimatedBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {LevelIcon} from '@youtoken/ui.loyalty-miner-components';

interface MarkIconProps {
  level: LoyaltyLevel;
}

const MarkIcon: React.FC<MarkIconProps> = ({level: {iconName, color}}) => {
  return (
    <Box p={1} borderWidth={1} borderColor="$ui-01" borderRadius={16}>
      <LevelIcon
        name={iconName}
        size={16}
        width={22}
        height={22}
        bg={color}
        borderWidth={0}
      />
    </Box>
  );
};

const MarkIconAnimated: React.FC<MarkIconProps> = observer(({level}) => {
  const ref = React.useRef(level);

  const progress = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: progress.value,
    };
  }, [progress]);

  // NOTE: useLayoutEffect for fix blink icons
  React.useLayoutEffect(() => {
    progress.value = 1;
    progress.value = withTiming(
      0,
      {
        duration: 1000,
        easing: Easing.linear,
      },
      finished => {
        if (finished) {
          ref.current = level;
        }
      }
    );
  }, [level, progress]);

  return (
    <Box>
      <MarkIcon level={level} />
      <ReanimatedBox position="absolute" style={animatedStyle}>
        <MarkIcon level={ref.current} />
      </ReanimatedBox>
    </Box>
  );
});

interface MarkIconAchievedAnimatedProps {
  achieved?: boolean;
}

const MarkIconAchievedAnimated: React.FC<MarkIconAchievedAnimatedProps> = ({
  achieved,
}) => {
  const progress = useSharedValue(achieved ? 1 : 0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: progress.value,
    };
  }, [progress]);

  React.useEffect(() => {
    progress.value = withTiming(achieved ? 1 : 0, {
      duration: 300,
      easing: Easing.linear,
    });
  }, [achieved]);

  return (
    <ReanimatedBox
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      borderWidth={2}
      borderColor="$success-01"
      borderRadius={16}
      style={animatedStyle}
    >
      <Box
        position="absolute"
        top={-4}
        right={-4}
        bg="$ui-background"
        borderRadius={7}
      >
        <Icon name="success" color="$success-01" size={11.2} />
      </Box>
    </ReanimatedBox>
  );
};

interface MarkIconWithAchievedProps {
  progressBarLevel: LoyaltyProgressBarLevel;
}

export const MarkIconWithAchieved: React.FC<
  MarkIconWithAchievedProps & MarkIconAchievedAnimatedProps
> = ({progressBarLevel: {level, isAchieved}}) => {
  return (
    <Box>
      <MarkIconAnimated level={level} />
      <MarkIconAchievedAnimated achieved={isAchieved} />
    </Box>
  );
};
