import React from 'react';
import {TextInputProps, StyleSheet} from 'react-native';
import {observer} from 'mobx-react';
import {InputAmount, useForm} from '../../../../../components';
import {FeatureLoanFTPForm} from '../../state';
import {getCoinDecimalPrecisionForLoans} from '@youtoken/ui.coin-utils';

export const Amount: React.FC<TextInputProps> = observer(props => {
  const {
    form: {loanCollateralTicker},
    expectedReturn,
  } = useForm<FeatureLoanFTPForm>();

  return (
    <InputAmount
      precission={
        loanCollateralTicker
          ? getCoinDecimalPrecisionForLoans(loanCollateralTicker)
          : undefined
      }
      value={expectedReturn}
      style={styles.input}
      placeholder="0.00"
      focusable={false}
      editable={false}
      {...props}
    />
  );
});

const styles = StyleSheet.create({
  input: {
    textAlign: 'right',
  },
});
