import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const IncreaseIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          d="M15.5 6h1.043a.5.5 0 00.354-.854l-1.793-1.792a.5.5 0 00-.707 0l-1.793 1.792a.5.5 0 00.353.854H14v10.25a.75.75 0 001.5 0V6zm-4 4.25a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-4 2a.75.75 0 00-1.5 0v4a.75.75 0 001.5 0v-4zm-4 1a.75.75 0 00-1.5 0v3a.75.75 0 001.5 0v-3z"
          fill={color}
        />
      </Svg>
    );
  }
);
