import {AxiosResponse} from 'axios';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {TRANSPORT} from '@youtoken/ui.transport';
import {SENTRY} from '@youtoken/ui.sentry';
import {ApplePayServiceBase} from './components/ApplePayServiceBase';
import type {DepositResponse} from './components/ApplePayServiceBase/types/DepositResponse';
import type {InitBackendSessionResponse} from './components/ApplePayServiceBase/types/InitBackendSessionResponse';

export class ApplePayService extends ApplePayServiceBase {
  public canMakePaymentsWithActiveCards: boolean = false;

  constructor() {
    super();

    this.isAvailable = 'ApplePaySession' in window;

    if (this.isAvailable) {
      try {
        this.canMakePayments = ApplePaySession.canMakePayments();
      } catch (e) {
        this.canMakePayments = false;
      }
    }
  }

  private defineIfHasActiveCards() {
    // NOTE: for web implementation host url is used as merchantId, so use DISPLAY_URL instead of APPLE_PAY_MERCHANT_ID
    ApplePaySession.canMakePaymentsWithActiveCard(ENVIRONMENT.DISPLAY_URL)
      .then((res: boolean) => {
        this.canMakePaymentsWithActiveCards = res;
      })
      .catch(() => {
        this.canMakePaymentsWithActiveCards = false;
      });
  }

  private initBackendSession = (
    validationUrl: string
  ): Promise<AxiosResponse<InitBackendSessionResponse>> => {
    return TRANSPORT.API.get(`/v1/deposit/apple-pay-session`, {
      params: {
        validationUrl,
        provider: this.provider,
      },
    });
  };

  public makePayment = (
    currency: string,
    amount: string,
    amountWithFee: string
  ): Promise<boolean> => {
    return new Promise(resolve => {
      try {
        this.defineIfHasActiveCards();

        const request: ApplePayJS.ApplePayPaymentRequest = {
          currencyCode: currency,
          countryCode: this.countryCode,
          supportedNetworks: this.supportedNetworks,
          merchantCapabilities: this
            .merchantCapabilities as ApplePayJS.ApplePayPaymentRequest['merchantCapabilities'],
          total: {
            label: this.label,
            amount: amountWithFee,
          },
        };

        const session = new ApplePaySession(10, request);
        session.begin();

        session.onvalidatemerchant = (
          event: ApplePayJS.ApplePayValidateMerchantEvent
        ) => {
          this.initBackendSession(event.validationURL)
            .then(res => {
              session.completeMerchantValidation(
                res.data as InitBackendSessionResponse
              );
            })
            .catch(() => {
              this.events.emit('initBackendSessionError');
              resolve(false);
            });
        };

        session.onpaymentauthorized = (
          event: ApplePayJS.ApplePayPaymentAuthorizedEvent
        ) => {
          const applePaymentData: ApplePayJS.ApplePayPayment = event.payment;
          const reqData = {
            amount: amount,
            ticker: currency.toLowerCase(),
            provider: this.provider,
            providerData: {applePaymentData},
          };
          this.requestDeposit(reqData)
            .then(res => {
              const resData: DepositResponse = res.data;
              if (resData.providerResponse && resData.transaction) {
                if (resData.transaction.status === 'created') {
                  this.events.emit('paymentPending');
                  session.completePayment(ApplePaySession.STATUS_SUCCESS);
                  resolve(true);
                  return;
                }
                if (resData.transaction.status === 'success') {
                  this.events.emit('paymentSuccess');
                  session.completePayment(ApplePaySession.STATUS_SUCCESS);
                  resolve(true);
                  return;
                }
                this.events.emit('paymentError');
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                resolve(false);
              } else {
                this.events.emit('paymentError');
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                resolve(false);
              }
            })
            .catch(() => {
              session.completePayment(ApplePaySession.STATUS_FAILURE);
              SENTRY.captureMessage('ApplePay payment error', {
                source: 'Apple Pay deposit',
              });
              resolve(false);
            });
        };

        session.oncancel = () => {
          SENTRY.captureMessage('ApplePay session canceled', {
            source: 'Apple Pay deposit',
          });
          this.events.emit('applePaySessionCanceled');
          resolve(false);
        };
      } catch (e) {
        this.events.emit('applePaySessionCanceled');
        resolve(false);
      }
    });
  };
}
