import * as React from 'react';
import {first, last} from 'lodash';
import {ChartSmallPreviewChartData} from './types';
import {type TColorTokens} from '@youtoken/ui.primitives';

const COLOR_OPTIONS = {
  up: '$success-01',
  down: '$danger-01',
} as Record<string, keyof TColorTokens>;

export const getColorName = (
  data: ChartSmallPreviewChartData[],
  diffDirection?: 'up' | 'down'
): keyof TColorTokens | undefined => {
  return React.useMemo(() => {
    if (diffDirection) {
      return COLOR_OPTIONS[diffDirection];
    }

    const rateFirst = first(data)?.rate;
    const rateLast = last(data)?.rate;

    return rateFirst && rateLast && rateFirst <= rateLast
      ? COLOR_OPTIONS.up
      : COLOR_OPTIONS.down;
  }, [data, diffDirection]);
};
