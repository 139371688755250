import {observable, action, computed, reaction, IReactionDisposer} from 'mobx';
import {Form} from './Form';
import {LoanItemRegularResource} from '@youtoken/ui.resource-loans';
import {
  formatByTickerLoan,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {Calculated} from './Calculated';
import {debounce} from 'lodash';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';

export interface FeatureLoanDecreaseFormArgs {
  id: string;
}

export interface FeatureLoanDecreaseFormResources {
  docsResource: DocsResource;
  walletsResource: WalletsResource;
  loanResource: LoanItemRegularResource;
}

export class FeatureLoanDecreaseForm extends createFeature({
  getKey: (args: FeatureLoanDecreaseFormArgs) =>
    `DecreaseForm:${JSON.stringify(args)}`,
  getResources: (args: FeatureLoanDecreaseFormArgs) => {
    const authMeResource = AuthMeResource.use({});

    return {
      docsResource: getResourceDescriptor(DocsResource, {
        country: authMeResource.residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      loanResource: getResourceDescriptor(LoanItemRegularResource, {
        id: args.id,
      }),
    };
  },
}) {
  @observable
  public restored = false;

  @observable
  public form: Form;

  @observable
  public calculated: Calculated;

  @observable
  public disposers: IReactionDisposer[] = [];

  @computed
  public get loan() {
    return this.form.loan;
  }

  @computed
  public get tickerCalculated() {
    return this._tickerCalculated?.toUpperCase();
  }

  @computed
  public get conversionTickerCalculated() {
    return this._conversionTickerCalculated?.toUpperCase();
  }

  @computed
  public get amountCalculated() {
    return this.calculated.data?.amount;
  }

  @computed
  public get newLTV() {
    if (this.calculated.data?.ltv) {
      return formatPercent(this.calculated.data?.ltv);
    }
    return '0';
  }

  @computed
  public get newPDL() {
    if (this.calculated.data?.mcPrice) {
      return formatPercent(
        toBig(-1).plus(
          toBig(this.calculated.data.mcPrice).div(this.loan.initialPrice)
        )
      );
    }
    return '0';
  }

  @computed
  public get newPDLAmount() {
    if (this.calculated.data?.mcPrice && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data?.mcPrice,
        this._conversionTickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get serviceFee() {
    if (this.calculated.data?.fee && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data?.fee,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get totalCollateralIncrease() {
    if (this.calculated.data?.addCollateralAmount && this._tickerCalculated) {
      return formatByTickerLoan(
        this.calculated.data?.addCollateralAmount,
        this._tickerCalculated
      );
    }
    return '0';
  }

  @computed
  public get agreements() {
    const documents = this.resources.docsResource.data;

    if (
      this.loan.company === 'NAUMARD' &&
      documents.loanDecreaseFormNaumard?.length
    ) {
      return documents.loanDecreaseFormNaumard;
    }

    if (
      this.loan.company === 'YOUHODLER' &&
      documents.loanDecreaseFormYouhodler?.length
    ) {
      return documents.loanDecreaseFormYouhodler;
    }

    return documents.loanDecreaseForm || [];
  }

  @action
  public restore = () => {
    this.form.restore();
    this.restored = true;
  };

  @action
  public reset = () => {
    this.restored = false;
    this.form.instance.reset();
  };

  @computed
  private get _tickerCalculated() {
    return this.calculated.data?.ticker;
  }

  @computed
  private get _conversionTickerCalculated() {
    return this.calculated.data?.conversionTicker;
  }

  constructor(
    args: FeatureLoanDecreaseFormArgs,
    resources: FeatureLoanDecreaseFormResources
  ) {
    super(args, resources);
    this.form = new Form(args, resources);
    this.calculated = new Calculated(this.form);
    this.disposers = [
      reaction(
        () => {
          return [this.restored, this.form.amount];
        },
        debounce(() => {
          if (this.restored) {
            this.calculated.load();
          }
        }, 300),
        {
          fireImmediately: true,
        }
      ),
    ];
  }
}
