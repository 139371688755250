import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HODLsTariffsFeature} from '@youtoken/ui.resource-hodl-tariffs';
import {type BoxProps} from '@youtoken/ui.primitives';
import {TradingInstruments} from '../../components';

export const BestRolloverRatesSection: React.FC<BoxProps> = observer(
  boxProps => {
    const {t} = useTranslation();
    const {instrumentsBestRolloverRates: items} = HODLsTariffsFeature.use({});

    if (!items.length) {
      return null;
    }

    return (
      <TradingInstruments
        title={t('surface.hodls.portfolio.best_rollover_rates.title')}
        items={items}
        itemSubtitleVariant="rollover"
        isExpandRestricted
        showCharts={false}
        mobileEstimatedListHeight={64}
        testID="BEST_ROLLOVER_RATES_SECTION"
        {...boxProps}
      />
    );
  }
);
