import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const WalletsIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 3a3 3 0 013 3v2a2 2 0 012 2v4a2 2 0 01-2 2v2a3 3 0 01-3 3H6a3 3 0 01-3-3V6a3 3 0 013-3h12zm0 2H6a1 1 0 00-.993.883L5 6v12a1 1 0 00.883.993L6 19h12a1 1 0 00.993-.883L19 18v-2.001L16 16a4 4 0 010-8l3-.001V6a1 1 0 00-.883-.993L18 5zm3 5h-5a2 2 0 00-.15 3.995L16 14h5v-4zm-5 1a1 1 0 110 2 1 1 0 010-2z"
          fill={color}
        />
      </Svg>
    );
  }
);
