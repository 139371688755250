import * as React from 'react';
import {useForm} from '@youtoken/ui.form-elements';
import {SENTRY} from '@youtoken/ui.sentry';
import {CardDepositForm} from '../../state';
import {getRiskJsData} from '@youtoken/ui.env-utils';
import {ENVIRONMENT} from '@youtoken/ui.environment';

export const RiskJsComponent: React.FC = () => {
  const {
    form: {setDeviceSessionId, finishRiskJsScriptLoading},
  } = useForm<CardDepositForm>();

  React.useEffect(() => {
    const {url, token} = getRiskJsData(ENVIRONMENT.APP_ENV);
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    script.onload = async () => {
      let requestsCounter = 1;
      let isSuccessful = false;

      const risk = window.Risk.init(token);

      while (requestsCounter <= 3) {
        await risk
          .publishRiskData()
          .then(res => {
            setDeviceSessionId(res);
            isSuccessful = true;
          })
          .catch(e => {
            if (requestsCounter >= 3) {
              SENTRY.capture(e, {
                source: 'RiskJsComponent',
              });

              finishRiskJsScriptLoading();
            }

            requestsCounter++;
          });

        if (isSuccessful) {
          break;
        }
      }
    };

    script.onerror = args => {
      SENTRY.capture(new Error('cannot init RiskJs script'), {
        source: 'RiskJsComponent',
        extra: {args},
      });

      finishRiskJsScriptLoading();
    };

    document.body.insertBefore(script, document.body.firstChild);
  }, [setDeviceSessionId]);

  return null;
};
