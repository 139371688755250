import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';

export const ComboboxGroupTitle: React.FC = ({children}) => {
  return (
    <Box
      height={48}
      flexDirection="column"
      justifyContent="center"
      borderBottomWidth={1}
      borderBottomColor="$ui-02"
    >
      <Text
        variant="$body-02"
        color="$text-02"
        textTransform="uppercase"
        px={16}
      >
        {children}
      </Text>
    </Box>
  );
};
