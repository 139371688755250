const FIAT = ['yusd', 'usd', 'eur', 'gbp', 'chf', 'rub'];

const STABLE = [
  'usdt',
  'usdc',
  'pax',
  'usdp',
  'tusd',
  'dai',
  'husd',
  'eurs',
  'busd',
  'pyusd',
  'gyen',
  'nzds',
  'xchf',
];

const CRYPTO = [
  'ybtc',
  'btc',
  'eth',
  'paxg',
  'ht',
  'bnb',
  'xrp',
  'xlm',
  'ethw',
  'ltc',
  'bch',
  'bsv',
  'dash',
  'eos',
  'hot',
  'zil',
  'xmr',
  'sol',
  'theta',
  'icp',
  'vet',
  'flr',
  'matic',
  'mhix', // NOTE: it can be new group INDEX in future
  'fet',
];

const TOKEN = [
  'bat',
  'rep',
  'link',
  'comp',
  'mkr',
  'uni',
  'bnt',
  'omg',
  'snx',
  'sushi',
  'yfi',
  'zrx',
  'doge',
  'shib',
  'ada',
  'aave',
  'trx',
  'xtz',
  'dot',
  'sgb',
  'atom',
  'avax',
  'btt',
  'bttc',
  'cake',
  'egld',
  'fil',
  'ftm',
  'ftt',
  'luna',
  'near',
  '1inch',
  'srm',
  'mana',
  'sand',
  'axs',
  'ilv',
  'gala',
  'ape',
  'gmt',
  'apt',
  'grt',
  'agix',
  'thkd',
  'xaut',
  'pepe',
  'floki',
  'arb',
  'sui',
  'brz',
  'wxt',
  'mav',
  'wld',
  'ton',
  'algo',
  'inj',
  'op',
  'rlb',
  'jup',
  'bonk',
  'mnt',
  'stx',
  'tia',
  'wif',
  'meme',
  'not',
  'asi',
  'astr',
  'ena',
  'hbar',
  'strk',
  'w',
  'ray',
  'lista',
  'pendle',
  'zro',
  'hmstr',
  'boden',
  'tremp',
  'people',
  'wen',
  'io',
  'myro',
  'silly',
  'dogs',
  'render',
  'beam',
  'ens',
  'flow',
  'gt',
  'hnt',
  'imx',
  'jasmy',
  'ldo',
  'leo',
  'om',
  'ondo',
  'pyth',
  'qnt',
  'zec',
  'banana',
  'pol',
];

export const webTickerConfig = {
  TICKERS: {
    FIAT,
    STABLE,
    CRYPTO,
    TOKEN,
  },
};
