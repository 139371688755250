import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const StarFilledIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.75 15.852l-4.072 1.864a.6.6 0 01-.846-.614l.514-4.45a.6.6 0 00-.154-.474L2.161 8.88a.6.6 0 01.323-.995L6.874 7a.6.6 0 00.404-.294l2.2-3.901a.6.6 0 011.045 0l2.199 3.901a.6.6 0 00.404.294l4.39.885a.6.6 0 01.323.995l-3.031 3.297a.6.6 0 00-.154.475l.514 4.449a.6.6 0 01-.846.614l-4.072-1.864a.6.6 0 00-.5 0z"
          fill={color}
        />
      </Svg>
    );
  }
);
