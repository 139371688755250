import {last} from 'lodash';
import {
  action,
  computed,
  observable,
  observe,
  reaction,
  runInAction,
} from 'mobx';
import {InteractionManager} from 'react-native';
import {TRANSPORT} from '@youtoken/ui.transport';
import {ResourceInstance} from '@youtoken/ui.data-storage';
import {ChartResource} from '../types';

/** line data socket enhancer
 * - will sub/unsub to sockets;
 * - will merge data from rest api with socket data (if any);
 */
export class LineLiveData {
  resource!: ResourceInstance<ChartResource.Args, ChartResource.Data>;

  _disposeFromTypeObserver!: ReturnType<typeof observe>;
  _disposeFromObservedState!: ReturnType<typeof observe>;
  _disposeFromDataObserver!: ReturnType<typeof reaction>;
  _disposeOfShouldSubscribeReaction!: ReturnType<typeof reaction>;
  _disposeFromModeObserver!: ReturnType<typeof observe>;

  @observable socketLinePoint?: ChartResource.Line.Point = undefined;
  @observable isSubscribedToSocket: Boolean = false;

  @computed get subscriptionEventName() {
    return this.resource.args.product === 'hodl'
      ? 'product-rates-lines'
      : 'rates-throttle';
  }

  onDestroy() {
    this._disposeFromTypeObserver?.();
    this._disposeFromDataObserver?.();
    this._disposeOfShouldSubscribeReaction?.();

    TRANSPORT.SOCKET.off(
      this.subscriptionEventName,
      this.handleSocketLinePoint
    );
    TRANSPORT.SOCKET.off('connect', this.onConnectSocket);
  }

  @action onConnectSocket = () => {
    if (this.shouldSubscribeToSockets) {
      this.isSubscribedToSocket = false;

      this.sub(this.resource.args);
    }
  };

  constructor(
    resource: ResourceInstance<ChartResource.Args, ChartResource.Data>
  ) {
    this.resource = resource;

    TRANSPORT.SOCKET.on(this.subscriptionEventName, this.handleSocketLinePoint);
    TRANSPORT.SOCKET.on('connect', this.onConnectSocket);

    this._disposeOfShouldSubscribeReaction = reaction(
      () => this.shouldSubscribeToSockets,
      shouldSubscribe => {
        if (shouldSubscribe) {
          this.sub(this.resource.args);
        } else {
          this.unsub(this.resource.args);
          this.resetSocketPoint();
        }
      },
      {
        fireImmediately: true,
      }
    );

    // this one will remove socket point if data is modified (new response from REST for example)
    // this._disposeFromDataObserver = reaction(
    //   () => this.resource.data,
    //   this.resetSocketPoint,
    //   {fireImmediately: false}
    // );
  }

  @computed get shouldSubscribeToSockets() {
    return this.resource.isDataObserved && this.resource.args.type === 'line';
  }

  @action resetSocketPoint = () => {
    this.socketLinePoint = undefined;
  };

  @computed get data(): ChartResource.Line.Data {
    if (this.resource.data.type !== 'line') {
      return this.resource.data as unknown as ChartResource.Line.Data;
    }

    if (!this.resource.data.data || this.resource.data.data.length <= 2) {
      return this.resource.data as unknown as ChartResource.Line.Data;
    }

    if (!this.socketLinePoint) {
      return this.resource.data as unknown as ChartResource.Line.Data;
    }

    const lastPoint = last(
      this.resource.data.data as ChartResource.Line.Point[]
    );

    // if socket point is older we will return data from rest as-is
    if (
      lastPoint &&
      lastPoint?.date?.getTime() >= this.socketLinePoint.date.getTime()
    ) {
      return this.resource.data;
    }

    const mode = this.resource.args.mode ?? 'mid';
    return {
      type: 'line',
      data: [...this.resource.data.data, this.socketLinePoint].map(point => {
        return {
          date: point.date,
          rate: mode === 'mid' ? point.rate : point[mode] ?? point.rate,
        };
      }),
    };
  }

  handleSocketLinePoint = (points: any[]) => {
    // ignoring rates if not "connected" socket (chart is unmounted);
    if (!this.isSubscribedToSocket) {
      return;
    }

    const point = points.find(
      p =>
        p.fromTicker === this.resource.args.fromTicker &&
        p.toTicker === this.resource.args.toTicker
    ) as ChartResource.Line.PointFromSocket;

    if (!this.filterSocketLinePoint(point)) {
      return;
    }

    InteractionManager.runAfterInteractions(() => {
      runInAction(() => {
        this.socketLinePoint = {
          date: new Date(point.date),
          rate: Number(point.price),
          bid: point.bid ? Number(point.bid) : undefined,
          ask: point.ask ? Number(point.ask) : undefined,
        };
      });
    });
  };

  filterSocketLinePoint = (
    incomingPoint: ChartResource.Line.PointFromSocket | undefined
  ) => {
    // if no point at all, just in case;
    if (!incomingPoint) {
      return false;
    }

    const {fromTicker, toTicker, type} = this.resource.args;

    if (type !== 'line') {
      return false;
    }

    // if point is there but not from this args;
    if (
      incomingPoint.fromTicker !== fromTicker ||
      incomingPoint.toTicker !== toTicker
    ) {
      return false;
    }

    return true;
  };

  @computed get subscriptionName() {
    return this.resource.args.product === 'hodl'
      ? 'product-rates-lines'
      : 'rates-throttle';
  }

  sub = ({
    fromTicker,
    toTicker,
    mode,
    product,
  }: Partial<ChartResource.Args>) => {
    if (!this.isSubscribedToSocket) {
      const args: any = {
        name: this.subscriptionName,
        fromTicker: fromTicker,
        toTicker: toTicker,
        mode: mode ?? 'mid',
      };

      if (Boolean(product)) {
        args.product = product;
      }

      TRANSPORT.SOCKET.emit('sub', args);
      this.isSubscribedToSocket = true;
    }
  };

  unsub = ({
    fromTicker,
    toTicker,
    mode,
    product,
  }: Partial<ChartResource.Args>) => {
    if (this.isSubscribedToSocket) {
      const args: any = {
        name: this.subscriptionName,
        fromTicker: fromTicker,
        toTicker: toTicker,
        mode: mode ?? 'mid',
      };

      if (Boolean(product)) {
        args.product = product;
      }

      TRANSPORT.SOCKET.emit('unsub', args);
      this.isSubscribedToSocket = false;
    }
  };
}
