import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {Icon} from '@youtoken/ui.icons';
import {Link} from '@youtoken/ui.elements';
import {FeatureTurboCreateForm} from '../stores';

export const AmountFieldSet: React.FC = observer(() => {
  const {
    form: {
      instance,
      collateralTickerGroups,
      borrowedTickerGroups,
      totalCollateralAmount,
    },
  } = FeatureTurboCreateForm.use({});
  const {t} = useTranslation();

  const handlePressAllAmount = React.useCallback(() => {
    instance.$('collateralAmount').onChange(totalCollateralAmount);
  }, [totalCollateralAmount, instance.$('collateralTicker').onChange]);

  return (
    <Box
      zIndex={1}
      padding={20}
      flexDirection={{default: 'column', tablet: 'row'}}
    >
      <Box
        flex={1}
        flexDirection="column"
        alignItems="stretch"
        mb={{default: 10, tablet: 0}}
        zIndex={10}
      >
        <Box
          flex={1}
          flexDirection="row"
          justifyContent="space-between"
          mb={10}
        >
          <Text variant="$body-01-high-accent">
            {t('surface.loans.create_turbo_form.ticker')}
          </Text>
          <Link variant="$body-02-high-accent" onPress={handlePressAllAmount}>
            {`${t(
              'surface.loans.create_turbo_form.ticker_all'
            )} ${totalCollateralAmount} ${instance
              .$('collateralTicker')
              .value.toUpperCase()}`}
          </Link>
        </Box>
        <TickerAndAmountInput
          zIndex={10}
          wallets={collateralTickerGroups}
          ticker={instance.$('collateralTicker').value}
          amount={instance.$('collateralAmount').value}
          onChangeTicker={instance.$('collateralTicker').onChange}
          onChangeAmount={instance.$('collateralAmount').onChange}
        />
      </Box>
      <Box px={12} mt={40} visible={{default: false, tablet: true}}>
        <Icon name="exchange" size={20} color="$text-02" />
      </Box>
      <Box flex={1} flexDirection="column" alignItems="stretch" zIndex={9}>
        <Box flex={1} mb={10}>
          <Text variant="$body-01-high-accent">
            {t('surface.loans.create_turbo_form.conversion_ticker')}
          </Text>
        </Box>
        <TickerAndAmountInput
          tickerOnly
          zIndex={10}
          wallets={borrowedTickerGroups}
          ticker={instance.$('borrowedTicker').value}
          onChangeTicker={instance.$('borrowedTicker').onChange}
        />
      </Box>
    </Box>
  );
});
