import * as React from 'react';
import {Box, type BoxProps, Heading} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {HODLsInstrumentsSectorsFeature} from '../../features/HODLsInstrumentsSectorsFeature';
import {SectorsDescriptionModal} from '../../modals/SectorsDescriptionModal';
import {SectorsModal} from '../../modals/SectorsModal';
import {SectorsCardsWrapper} from './components';
import {ErrorFallback} from '@youtoken/ui.error-boundary';

export const SectorsSection: React.FC<BoxProps> = cell(
  ({...boxProps}) => {
    const {
      shouldShowHodlsSectors,
      webHodlsSectors,
      nativeHodlsSectors,
      setChosenSector,
      removeChosenSector,
    } = HODLsInstrumentsSectorsFeature.use({});
    const {t} = useTranslation();

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isAboutModalOpen, setIsAboutModalOpen] = React.useState(false);

    const handleModalClose = React.useCallback(() => {
      removeChosenSector();
      setIsAboutModalOpen(false);
      setIsModalOpen(false);
    }, []);

    const handleAboutModalClose = React.useCallback(() => {
      setIsAboutModalOpen(false);
    }, []);

    const handleAboutModalOpen = React.useCallback(() => {
      setIsAboutModalOpen(true);
    }, []);

    const handleCardPress = React.useCallback((sector: string) => {
      setChosenSector(sector);
      setIsModalOpen(true);
    }, []);

    if (!shouldShowHodlsSectors) {
      return null;
    }

    return (
      <>
        <Box {...boxProps}>
          <Heading
            variant="$heading-02-responsive"
            mb={{default: 16, desktop: 24}}
            px={{default: 24, desktop: 0}}
          >
            {t('surface.hodls.portfolio.sectors_title')}
          </Heading>
          <SectorsCardsWrapper
            nativeSectorsData={nativeHodlsSectors}
            webSectorsData={webHodlsSectors}
            onPress={handleCardPress}
          />
        </Box>
        <SectorsModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onHelpIconPress={handleAboutModalOpen}
        />
        <SectorsDescriptionModal
          isOpen={isAboutModalOpen}
          onClose={handleAboutModalClose}
        />
      </>
    );
  },
  {
    ErrorFallback: ({...props}) => (
      <Box width="100%" height={216}>
        <ErrorFallback {...props} />
      </Box>
    ),
  }
);
