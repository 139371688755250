import * as React from 'react';
import {differenceInHours} from '@youtoken/ui.date-fns';

export const useDefaultHODLTimePeriod = (startDate?: Date, endDate?: Date) => {
  return React.useMemo(() => {
    if (!startDate) {
      return '1h';
    }

    const handledEndDate = endDate ?? new Date(Date.now());
    const diff = differenceInHours(handledEndDate, startDate);

    if (diff < 1) {
      return '1h';
    } else if (diff < 24) {
      return '24h';
    } else if (diff < 24 * 7) {
      return '7d';
    } else if (diff < 24 * 30) {
      return '30d';
    } else {
      return '90d';
    }
  }, [startDate]);
};
