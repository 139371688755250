import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {useForm} from '@youtoken/ui.form-elements';
import {Box, Text} from '@youtoken/ui.primitives';
import {UmaInvoiceConfirmationForm} from '../../state';

export const RateTimer: React.FC = observer(({}) => {
  const {t} = useTranslation();

  const {
    form: {
      invoiceData: {
        convertedFromTickerFormatted,
        convertedToTickerFormatted,
        rate,
      },
      rateTimeIntervalProgress,
      rateTimeIntervalName,
    },
  } = useForm<UmaInvoiceConfirmationForm>();

  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        mb={8}
        testID="UMA_INVOICE_RATE_TIMER"
      >
        <Box flexDirection="row">
          <Text variant="$body-02" color="$text-02" mr={4}>
            {t('surface.wallets.uma.withdrawal.conversion_rate')}
          </Text>
          <QuestionTooltip
            bodyHorizontalOffset={38}
            content={t(
              'surface.wallets.uma.withdrawal.conversion_rate.tooltip'
            )}
          />
        </Box>
        <Box flexDirection="row">
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              1{' '}
            </Text>
            {convertedFromTickerFormatted} {'= '}
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_RATE_VALUE"
              mr={3}
            >
              {rate}{' '}
            </Text>
            {convertedToTickerFormatted}
          </Text>
        </Box>
      </Box>

      <ProgressBar
        key={rateTimeIntervalName}
        progress={rateTimeIntervalProgress}
      />
    </>
  );
});
