import {computed, action} from 'mobx';
import {stringify} from 'query-string';
import {deserialize} from 'serializr';
import {TRANSPORT} from '@youtoken/ui.transport';
import {createResource} from '@youtoken/ui.data-storage';
import {HODLsResponsePaginated} from '../HODLsResponsePaginated';
import {HODLItem} from '../HODLItem';
import {HODLItemData} from '../HODLItemData';

export class PendingHODLsResource extends createResource<
  {},
  HODLsResponsePaginated
>({
  skipRefreshOnVisible: false,
  getKey: () => 'hodls:pending',
  getData: () =>
    TRANSPORT.API.get('/v3/hodl', {
      params: {
        status: ['PENDING'],
      },
      paramsSerializer: (params: any) =>
        stringify(params, {arrayFormat: 'none'}),
    }).then(res => {
      const data = res.data;

      const rows = data.rows.map((i: any) => ({
        data: i,
      }));

      return deserialize(HODLsResponsePaginated, {
        ...data,
        rows,
      });
    }),
}) {
  @action.bound
  removeHodl = (hodl: HODLItemData) => {
    this.data.rows = this.data.rows.filter(h => h.id !== hodl.id);
  };

  @action.bound
  addNewHodl = (hodl: HODLItemData) => {
    if (!this.data.rows.some(h => h.id === hodl.id)) {
      this.data.rows.unshift(deserialize(HODLItem, {data: hodl}));
    }
  };

  @computed get hodlIds() {
    return this.data.rows.map(h => h.id);
  }

  onInit() {
    super.onInit();

    TRANSPORT.SOCKET.on('hodl-pending', this.addNewHodl);
    TRANSPORT.SOCKET.on('hodl-opened', this.removeHodl);
    TRANSPORT.SOCKET.on('hodl-closed', this.removeHodl);
  }

  onDestroy() {
    super.onDestroy();

    TRANSPORT.SOCKET.off('hodl-pending', this.addNewHodl);
    TRANSPORT.SOCKET.off('hodl-opened', this.removeHodl);
    TRANSPORT.SOCKET.off('hodl-closed', this.removeHodl);
  }
}
