import * as React from 'react';
import {observer} from 'mobx-react';
import {type LoyaltyProgressBarLevel} from '@youtoken/ui.resource-loyalty';
import {Box, Text} from '@youtoken/ui.primitives';
import {MarkIconWithAchieved} from '../../../components/AccountLevelProgressBar/MarkIcon';

export type AccountLevelProgressBarDetailedNextGoalsProps = {
  progressBarLevel: LoyaltyProgressBarLevel;
};

export const AccountLevelProgressBarDetailedNextGoalsLevel: React.FC<AccountLevelProgressBarDetailedNextGoalsProps> =
  observer(({progressBarLevel}) => {
    const {
      isNext,
      isAchieved,
      volumeFormattedWithSeparators,
      level: {
        level,
        name,
        requiredVolume,
        requiredVolumeFormattedWithSeparators,
      },
    } = progressBarLevel;

    return (
      <Box
        testID={`ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_NEXT_GOALS_LEVEL_NUMBER_${level}`}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="row" alignItems="center">
          <MarkIconWithAchieved progressBarLevel={progressBarLevel} />
          <Text
            testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_NEXT_GOALS_LEVEL_NAME"
            variant="$body-01"
            color={isAchieved ? '$text-02' : '$text-01'}
            ml={8}
          >
            {name}
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Text
            testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_NEXT_GOALS_LEVEL_VOLUME"
            variant="$body-01"
            color={isAchieved ? '$text-02' : '$text-01'}
          >
            {Boolean(requiredVolume) && (
              <>
                <Text
                  testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_NEXT_GOALS_LEVEL_VOLUME_VALUE"
                  color={isNext ? '$text-05' : undefined}
                >
                  {volumeFormattedWithSeparators}
                </Text>{' '}
                /{' '}
                <Text testID="ACCOUNT_LEVEL_PROGRESS_BAR_DETAILED_NEXT_GOALS_LEVEL_VOLUME_REQUIRED">
                  {requiredVolumeFormattedWithSeparators}
                </Text>
              </>
            )}
          </Text>
        </Box>
      </Box>
    );
  });
