import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {type CryptoDepositProps} from '../../../types';
import {FeatureCryptoDepositLedger} from '../../state';
import {CryptoDepositLedgerForm} from '../../../CryptoDepositLedgerForm';
import {CryptoDepositExternal} from '../../../CryptoDepositExternal';

export const CryptoDepositLedgerMethod: React.FC<CryptoDepositProps> = cell(
  ({ticker}) => {
    const {
      method: {value},
    } = FeatureCryptoDepositLedger.use({
      ticker,
    });

    if (value === 'ledger') {
      return <CryptoDepositLedgerForm ticker={ticker} />;
    }

    return <CryptoDepositExternal ticker={ticker} />;
  }
);
