import {i18n} from '@youtoken/ui.service-i18n';
import {type TColorTokens} from '@youtoken/ui.primitives';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';

export const getInitialPriceLineParams = (
  colors: TColorTokens,
  initialPrice?: number,
  pending?: boolean
) => {
  // NOTE: hide InitialPrice for pending-orders
  if (initialPrice === undefined || pending) {
    return undefined;
  }

  return {
    price: initialPrice,
    // @ts-ignore
    text: i18n.t('charts.initial_price'),
    bodyTextColor: colors['$chart-neutral-01'],
    lineColor: colors['$chart-neutral-01'],
  };
};

export const getTakeProfitLineParams = (
  precision: number | undefined,
  colors: TColorTokens,
  takeProfit?: number,
  maxProfit?: number
) => {
  if (takeProfit === undefined || maxProfit === undefined) {
    return undefined;
  }

  return {
    price: takeProfit,
    // @ts-ignore
    text: `${i18n.t('charts.max_profit')} ${formatBigNumber(
      maxProfit,
      precision,
      true
    )}`,
    bodyTextColor: colors['$chart-success-01'],
    lineColor: colors['$chart-success-01'],
  };
};

export const getMarginCallLineParams = (
  precision: number | undefined,
  colors: TColorTokens,
  marginCall?: number,
  maxLoss?: number
) => {
  if (marginCall === undefined || maxLoss === undefined) {
    return undefined;
  }

  return {
    price: marginCall,
    // @ts-ignore
    text: `${i18n.t('charts.max_loss')} ${formatBigNumber(
      maxLoss,
      precision,
      true
    )}`,
    bodyTextColor: colors['$chart-danger-01'],
    lineColor: colors['$chart-danger-01'],
  };
};

export const getTriggerPriceLineParams = (
  colors: TColorTokens,
  triggerPrice?: number,
  pending?: boolean
) => {
  // NOTE: show TriggerPrice only for pending-orders
  if (triggerPrice === undefined || !pending) {
    return undefined;
  }

  return {
    price: triggerPrice,
    // @ts-ignore
    text: i18n.t('charts.trigger_price'),
    bodyTextColor: colors['$chart-interactive-01'],
    lineColor: colors['$chart-interactive-01'],
  };
};
