import React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Heading} from '@youtoken/ui.primitives';
import {
  SavingsSelectAssetsSurface,
  SavingsSelectAssetsSmart,
} from '@youtoken/ui.surface-savings';
import {Modal} from '../_common';

export const WalletsSavingsSelect: typeof Modal = props => {
  const {t} = useTranslation();

  return (
    <Modal height="90vh" {...props}>
      <Box alignItems="center" p={24}>
        <Heading variant="$heading-02-responsive">
          {SavingsSelectAssetsSurface.getTitle()}
        </Heading>
      </Box>
      <SavingsSelectAssetsSmart />
    </Modal>
  );
};
