import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';

export const HistoryListEmptyState: React.FC = observer(() => {
  const {t} = useTranslation();
  return (
    <Box flexGrow={1} alignItems="center" justifyContent="center" height={160}>
      <Text variant="$body-01" color="$text-02">
        {t('surface.wallets.wallet_detail.history_no_transactions')}
      </Text>
    </Box>
  );
});
