import {invariant} from '@youtoken/ui.utils';
import {AppEnv} from '../AppEnv';

const V2_BETA_CONTOUR_KEY = '6LfoNo8bAAAAAE0udDOUomkRBzGd8snjygGk7jO5';
const V3_BETA_CONTOUR_KEY = '6LeyN48bAAAAADrbB5IWRaYem0YIEnZe0CE4NWVa';

export const getRecaptchaKey = (env: AppEnv, version: 'v2' | 'v3'): string => {
  const keys: {[key in typeof version]: {[key in AppEnv]: string}} = {
    v2: {
      [AppEnv.local]: '6Ld--OkZAAAAADsJ8wCttJ8MTUovdOaCVJL7me9p',
      [AppEnv.development]: '6Ld--OkZAAAAADsJ8wCttJ8MTUovdOaCVJL7me9p',
      [AppEnv.stage]: '6LeE-OkZAAAAAOrXuRWKEgvQixAusqEXAr_P2kgw',
      [AppEnv.rnd]: '6LeL-OkZAAAAAAxbqhMU6LHLZdDgai72krymClwu',
      [AppEnv.production]: '6Lf0-OkZAAAAAAoVNByWiWSBhkGle4_YgSeADVt9',
      [AppEnv.alpha]: '6LfZNI8bAAAAAAEuaBFUaYNlJLWqnVlkvI63dmd3',
      [AppEnv.beta]: V2_BETA_CONTOUR_KEY,
      [AppEnv.gamma]: '6Lf0R4ccAAAAABcqc0qrZOIYd_f_nt61JDpVKRu9',
      [AppEnv.delta]: V2_BETA_CONTOUR_KEY,

      // new contours, use beta keys
      [AppEnv.lambda]: V2_BETA_CONTOUR_KEY,
      [AppEnv.epsilon]: V2_BETA_CONTOUR_KEY,
      [AppEnv.zeta]: V2_BETA_CONTOUR_KEY,
      [AppEnv.iota]: V2_BETA_CONTOUR_KEY,
      // newer contours, use alpha keys
      [AppEnv['core-test']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['core-uat']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['iota-aws']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['delta-aws']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['core-test-aws']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['core-uat-aws']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['zeta-aws']]: V2_BETA_CONTOUR_KEY,
      [AppEnv['epsilon-aws']]: V2_BETA_CONTOUR_KEY,
      [AppEnv.localhost]: V2_BETA_CONTOUR_KEY,
      [AppEnv['b2b-sandbox']]: V2_BETA_CONTOUR_KEY,
    },
    v3: {
      [AppEnv.local]: '6LdQ-OkZAAAAAFBy6uHtk7VL5tUPemEZGojx9fKz',
      [AppEnv.development]: '6LdQ-OkZAAAAAFBy6uHtk7VL5tUPemEZGojx9fKz',
      [AppEnv.stage]: '6Ldb-OkZAAAAAOJdGhZ5uP4T4BQ_sm-vqJ-i5DOn',
      [AppEnv.rnd]: '6Ldq-OkZAAAAAFAcBlr9hsyMzwYGJoek3zD3Yr25',
      [AppEnv.production]: '6Lf9VYoUAAAAAOzOl10ELvXQvsyYcI44ay-PtaD2',
      [AppEnv.alpha]: '6LcNNo8bAAAAAGIqHdvXCEgzolz6uJyxWfvC3ekp',
      [AppEnv.beta]: V3_BETA_CONTOUR_KEY,
      [AppEnv.gamma]: '6LfJSoccAAAAAOkGpZvLc0EzIesAJ3sXg73KlvrQ',
      [AppEnv.delta]: V3_BETA_CONTOUR_KEY,

      // new contours, use beta keys
      [AppEnv.lambda]: V3_BETA_CONTOUR_KEY,
      [AppEnv.epsilon]: V3_BETA_CONTOUR_KEY,
      [AppEnv.zeta]: V3_BETA_CONTOUR_KEY,
      [AppEnv.iota]: V3_BETA_CONTOUR_KEY,
      // newer contours, use beta keys
      [AppEnv['core-test']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['core-uat']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['iota-aws']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['delta-aws']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['core-test-aws']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['core-uat-aws']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['zeta-aws']]: V3_BETA_CONTOUR_KEY,
      [AppEnv['epsilon-aws']]: V3_BETA_CONTOUR_KEY,
      [AppEnv.localhost]: V3_BETA_CONTOUR_KEY,
      [AppEnv['b2b-sandbox']]: V3_BETA_CONTOUR_KEY,
    },
  };

  const key = keys[version][env];

  invariant(
    key,
    `Recaptcha siteKey for env: ${env} and version: ${version} was not found!`
  );

  return key;
};
