import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, TouchableBox, type BoxProps, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

type HowItWorksButtonProps = {
  onPress: () => void;
};

export const HowItWorksButton: React.FC<HowItWorksButtonProps & BoxProps> =
  observer(({onPress, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <TouchableBox
        flexDirection="row"
        alignItems="center"
        onPress={onPress}
        borderRadius={8}
        borderWidth={1}
        borderColor="$ui-01"
        py={8}
        px={16}
        {...boxProps}
      >
        <Box mr={4}>
          <Icon name="question" color="$text-02" size={16} />
        </Box>

        <Text color="$text-02" variant="$body-02">
          {t('common.action.how_it_works')}
        </Text>
      </TouchableBox>
    );
  });
