import {useCallback, useEffect} from 'react';

export const useKeyPressEffect = (targetKey: string, callback: () => void) => {
  const downHandler = useCallback(
    ({key}: {key: string}) => {
      if (key === targetKey) {
        callback();
      }
    },
    [targetKey, callback]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};
