import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {
  ExchangeTariffsResource,
  ExchangeTariffsOnRampResource,
} from '@youtoken/ui.resource-exchange-tariffs';

export enum DepositMethodRampEnum {
  BANK_CARD = 'bankCard',
  BANK_WIRE = 'bankWire',
  ADV_CASH = 'advCash',
  YH_BALANCE = 'yhBalance',
}

export type DepositMethodType = 'instant' | 'later';

export interface BuyCryptoFormAuthorizedStateBaseArgs {
  ticker?: string;
  conversionTicker?: string;
  onSubmit?: () => void;
}

export interface BuyCryptoFormAuthorizedStateBaseResources {
  authMeResource: AuthMeResource;
  verificationResource: VerificationResource;
  docsResource: DocsResource;
  walletsResource: WalletsResource;
  marketsResource: MarketsResource;
  ratesResource: RatesResource;
  fees: FeeAllResource;
  exchangeTariffs: ExchangeTariffsResource | ExchangeTariffsOnRampResource;
}
