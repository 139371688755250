import * as React from 'react';
import {ScrollView} from 'react-native';
import {Box, useIsMobile} from '@youtoken/ui.primitives';

type AppliedFiltersWrapperProps = {
  scrollOffset: number;
};

export const AppliedFiltersWrapper: React.FC<
  AppliedFiltersWrapperProps & {children: React.ReactNode}
> = ({scrollOffset, children}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box width="100%">
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <Box flexDirection="row" pl={scrollOffset} pr={scrollOffset - 8}>
            {children}
          </Box>
        </ScrollView>
      </Box>
    );
  }

  return (
    <Box
      flex={1}
      flexDirection="row"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      {children}
    </Box>
  );
};
