import * as React from 'react';
import {SvgProps, Svg, Circle, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const AppearanceIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
      >
        <Circle cx={10} cy={10} r={7.25} stroke={color} strokeWidth={1.5} />
        <Path d="M10 18a8 8 0 010-16v16z" fill={color} />
      </Svg>
    );
  }
);
