import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {Modal} from '../_common';
import {ReSignDisclosuresSurfaceCell} from '@youtoken/ui.surface-re-sign-disclosures';

export const ModalReSignDisclosures: typeof Modal = cell(props => {
  return (
    <Modal
      {...props}
      withCloseButton={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlay={false}
    >
      <ReSignDisclosuresSurfaceCell />
    </Modal>
  );
});
