export const normalizeAmount = (value: string) => {
  const match = value
    .replace(/[^\d.,]+/g, '')
    .replace(/[.,]+/g, '.')
    .match(/\d+(\.([\d.]+)?)?/);

  if (match) {
    const [integral, ...precisions] = match[0].split('.');

    const amountParts: string[] = [];
    if (integral) {
      amountParts.push(Number(integral).toString()); // 0123456 => 123456
    }
    if (precisions.length) {
      amountParts.push(precisions.join('')); // 65.43.21 => 654321
    }

    return amountParts.join('.'); // 0123456.65.43.21 => 123456.654321
  }

  return '';
};
