import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useIsDesktop} from '@youtoken/ui.primitives';
import {MenuItemsProps} from './types';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {MenuItem} from '@web-app/src/components/elements/MenuItem';

export const MenuItemSignOut: React.FC<MenuItemsProps> = observer(() => {
  const {t} = useTranslation();
  const {signOut} = AuthMeResource.use({});

  const isDesktop = useIsDesktop();

  if (isDesktop) {
    return null;
  }

  return (
    <MenuItem
      onPress={signOut}
      testID="MENU_ITEM_SIGN_OUT_BUTTON"
      text={t('surface.menu.sign_out')}
    />
  );
});
