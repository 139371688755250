import {observable, computed} from 'mobx';
import {serializable, alias, primitive, object, date} from 'serializr';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {type BaseIconName} from '@youtoken/ui.icons';

export enum NotificationScopes {
  WALLETS = 'wallets',
  LOANS = 'loans',
  MULTIHODL = 'multihodl',
  MINER = 'miner',
  TURBOCHARGE = 'turbocharge',
}

export enum NotificationCategory {
  TOP_BAR = 'TOP_BAR',
  POP_UP = 'POP_UP',
  CAROUSEL = 'CAROUSEL',
}

export enum NotificationType {
  GENERIC = 'GENERIC',
  LOYALTY = 'LOYALTY',
  MINER = 'MINER',
  HODL = 'HODL',
  REWARDS = 'REWARDS',
  BANNER = 'BANNER',
}

export class NotificationTexts {
  @observable
  @serializable(primitive())
  body!: string;

  @observable
  @serializable(primitive())
  title?: string;

  @observable
  @serializable(primitive())
  button?: string;
}

export type NotificationPayloadDesignKind = 'primary' | 'secondary';

export type NotificationPayloadDesignVariant =
  | 'interactive'
  | 'success'
  | 'attention'
  | 'danger';

export class NotificationDesign {
  @observable
  @serializable(primitive())
  kind!: NotificationPayloadDesignKind;

  @observable
  @serializable(primitive())
  variant!: NotificationPayloadDesignVariant;

  @observable
  @serializable(primitive())
  iconName!: BaseIconName;
}

export class NotificationConditionsScopes {
  @observable
  @serializable(primitive())
  wallets!: boolean;

  @observable
  @serializable(primitive())
  loans!: boolean;

  @observable
  @serializable(primitive())
  multihodl!: boolean;

  @observable
  @serializable(primitive())
  miner!: boolean;

  @observable
  @serializable(primitive())
  turbocharge!: boolean;
}

export class NotificationConditions {
  @observable
  @serializable(primitive())
  canClose!: boolean;

  @observable
  @serializable(primitive())
  url?: string;

  @observable
  @serializable(alias('screenForms', object(NotificationConditionsScopes)))
  scopes?: NotificationConditionsScopes;
}

export class NotificationPayload {
  // DEPENDS ON TYPE!
  @observable
  @serializable(primitive())
  level?: number;
}

export interface NotificationPayloadByType
  extends NotificationPayload,
    Pick<Notification, 'design'> {}

export class Notification {
  @observable
  @serializable(primitive())
  category!: NotificationCategory;

  @observable
  @serializable(primitive())
  type!: NotificationType;

  @observable
  @serializable(primitive())
  notificationId!: string;

  @observable
  @serializable(alias('texts', object(NotificationTexts)))
  _texts?: NotificationTexts;

  @computed
  get texts() {
    const {translate} = LocaliseResource.getInstance({
      namespace: LocaliseResourceNamespace.NOTIFICATION,
    });

    const {title, body, button} = this._texts ?? {};

    return {
      title: title && translate(title),
      body: body && translate(body),
      button: button && translate(button),
    };
  }

  @observable
  @serializable(object(NotificationConditions))
  conditions!: NotificationConditions;

  @observable
  @serializable(object(NotificationPayload))
  payload?: NotificationPayload;

  @computed
  get payloadByType(): NotificationPayloadByType {
    return {
      level: this.payload?.level,
      design: this.design,
    };
  }

  @observable
  @serializable(object(NotificationDesign))
  design?: NotificationDesign;

  @observable
  @serializable(date())
  finishAt!: Date | null;

  @observable
  @serializable(primitive())
  showed!: boolean;
}
