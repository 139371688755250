import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';

export const RewardsBanned: React.FC = observer(() => {
  const {t} = useTranslation();

  return (
    <Box flex={1} justifyContent="center" alignItems="center" p={24}>
      <Icon name="warning" size={40} color="$attention-01" />
      <Text
        variant="$heading-02"
        color="$attention-01"
        textAlign="center"
        mt={8}
      >
        {t('surface.referral_program.rewards.banned.title')}
      </Text>
      <Text variant="$body-02" color="$text-02" textAlign="center" mt={16}>
        {t('surface.referral_program.rewards.banned.description')}
      </Text>
    </Box>
  );
});
