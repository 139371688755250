import * as React from 'react';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';

export const useExchangeAttemptTracker = (
  fromTicker: string,
  toTicker: string,
  version: string,
  dl_source?: string
) => {
  React.useEffect(() => {
    DATA_LAYER.trackStrict('convert-attempt', {
      fromTicker,
      toTicker,
      version,
      dl_source,
    });
  }, [fromTicker, toTicker, version, dl_source]);
};
