import * as React from 'react';
import {observer} from 'mobx-react';
import {TouchableBox} from '@youtoken/ui.primitives';
import {Banner} from '@youtoken/ui.elements';
import {useForm} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type WithdrawalForm} from '../../state/WithdrawalForm';

export const AttentionBanner: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    view: {
      shouldForceAllow,
      isAllowed,
      handleBannerClick,
      resources: {
        authme: {kycResult, addressResult},
      },
    },
  } = useForm<WithdrawalForm>();

  const kycText = React.useMemo(() => {
    if (!shouldForceAllow) {
      if (kycResult !== 'ACCEPT' && addressResult !== 'ACCEPT') {
        return t(
          'surface.bank_wire.withdrawal.attention_banner.kyc_and_address'
        );
      }

      if (kycResult !== 'ACCEPT') {
        return t('surface.bank_wire.withdrawal.attention_banner.kyc');
      }

      if (addressResult !== 'ACCEPT') {
        return t('surface.bank_wire.withdrawal.attention_banner.address');
      }
    }

    return null;
  }, [kycResult, addressResult, shouldForceAllow]);

  if (isAllowed) {
    return null;
  }

  return (
    <TouchableBox onPress={handleBannerClick}>
      <Banner
        variant="attention"
        testID="FIAT_WITHDRAWAL_BANK_WIRE_ATTENTION_BANNER"
      >
        {t('surface.bank_wire.withdrawal.attention_banner.desc')}
        {Boolean(kycText) && <>: {kycText}</>}
      </Banner>
    </TouchableBox>
  );
});
