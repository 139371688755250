import * as React from 'react';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {Pagination} from '@youtoken/ui.elements';
import {LoansListRegularFeature} from '../LoansListRegularFeature';

export const LoansListRegularPagination: React.FC = observer(() => {
  const {totalPages, activePage, setActivePage} = LoansListRegularFeature.use(
    {}
  );

  if (totalPages <= 1) {
    return null;
  }

  return (
    <Box testID="LOAN_LIST_PAGINATION" alignSelf="center" m={20}>
      <Pagination
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={setActivePage}
      />
    </Box>
  );
});
