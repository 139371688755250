import * as React from 'react';
import {observer} from 'mobx-react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {RewardsResource} from '@youtoken/ui.resource-rewards-v3';
import {RewardsHistoryV3Resource} from '@youtoken/ui.resource-rewards-v3-history';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {ReferralsSmartProps} from './types';
import {
  BottomSheetScrollView,
  ReferralsHeaderV3,
  InviteFriends,
  RewardsStatisticsSmart,
  ReferralsHowItWorksSmart,
  RewardsHistoryViewSmart,
  ShareRefCodeModalTrigger,
} from '../..';
import {ReferralsLayout} from './Layout';

export const ReferralsSmartV3: React.FC<ReferralsSmartProps> = observer(
  ({showShareRefCode, ...rest}) => {
    const {
      rewards: {
        data: {maxEarningsPercentFormatted},
      },
      rewardsHistory: {
        data: {items},
      },
    } = useResources({
      rewards: getResourceDescriptor(RewardsResource, {}),
      rewardsHistory: getResourceDescriptor(RewardsHistoryV3Resource, {}),
    });

    return (
      <ReferralsLayout
        header={
          <ReferralsHeaderV3 percentFormatted={maxEarningsPercentFormatted} />
        }
        {...rest}
      >
        <BottomSheetScrollView style={{flex: 1}}>
          {!showShareRefCode && (
            <>
              <InviteFriends />
              <Separator />
            </>
          )}
          <RewardsStatisticsSmart />
          <Separator />
          {items.length > 0 ? (
            <RewardsHistoryViewSmart showHeader />
          ) : (
            <ReferralsHowItWorksSmart />
          )}
        </BottomSheetScrollView>
        {showShareRefCode && (
          <Box p={24}>
            <ShareRefCodeModalTrigger source="referrals-v3" />
          </Box>
        )}
      </ReferralsLayout>
    );
  }
);
