import * as React from 'react';
import {observer} from 'mobx-react';
import {TFuncKey} from 'react-i18next';
import {Text, TouchableText} from '@youtoken/ui.primitives';
import {
  ValidationMessageComponent,
  ValidationMessageLocalized,
} from '@youtoken/ui.validation-messages';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';

type ValidationMessageProps = {
  validationMessage?: {errorKey: TFuncKey; amount: string};
  onValidationClick: () => void;
  validationError: string | ValidationMessageLocalized | null;
  isValidationClickable: boolean;
  testIdSection: string;
};

export const ValidationMessage: React.FC<ValidationMessageProps> = observer(
  ({
    validationMessage,
    onValidationClick,
    validationError,
    isValidationClickable,
    testIdSection,
  }) => {
    const {t} = useTranslation();
    const isMessageWithAction =
      validationMessage && (!validationError || isValidationClickable);
    const isSimpleMessage =
      (!!validationError && !isValidationClickable) || !validationMessage;

    if (isMessageWithAction) {
      return (
        <Text
          color={!!validationError ? '$danger-01' : '$text-02'}
          variant="$body-02"
          mb={10}
        >
          <Trans
            t={t}
            i18nKey={validationMessage.errorKey}
            components={{
              Touchable: (
                <TouchableText
                  color="$text-05"
                  onPress={onValidationClick}
                  testID={`${testIdSection}_LIMIT`}
                />
              ),
            }}
            values={{amount: validationMessage.amount}}
            defaults="Must be less than or equal to <Touchable>{{amount}}</Touchable>"
          />
        </Text>
      );
    }

    if (isSimpleMessage) {
      return (
        <Text color="$danger-01" variant="$body-02" mb={10}>
          <ValidationMessageComponent error={validationError} />
        </Text>
      );
    }

    return null;
  }
);
