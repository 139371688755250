import {observable} from 'mobx';
import {serializable, optional, primitive} from 'serializr';
import {number} from '@youtoken/ui.utils-serializr';

export class Calculated {
  @serializable(primitive())
  @observable
  isFixedRate!: boolean;

  @serializable(optional(number()))
  @observable
  volumeForecast?: number; // NOTE: When error is undefined

  @serializable(optional(number()))
  @observable
  sparksAmount?: number; // NOTE: When error is undefined
}
