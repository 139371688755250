import * as React from 'react';
import {Modal} from '../_common';
import {DisclosureSurface} from '@youtoken/ui.surface-legal-info';
import {modal} from '../../../stores';

interface ModalDisclosureWalletsProps
  extends React.ComponentProps<typeof Modal> {
  onSuccess?: () => void;
}

export const ModalDisclosureWallets: React.FC<ModalDisclosureWalletsProps> = ({
  onSuccess,
}) => {
  const handleSuccess = React.useCallback(() => {
    if (onSuccess) {
      onSuccess();
    } else {
      modal.close();
    }
  }, [onSuccess]);

  return (
    <Modal maxWidth={590} minHeight={200}>
      <DisclosureSurface product={'wallet'} handleOnSuccess={handleSuccess} />
    </Modal>
  );
};
