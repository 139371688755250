import * as React from 'react';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {QuestionTooltip, type TooltipProps} from '@youtoken/ui.tooltip';

export interface TableRowProps extends Omit<BoxProps, 'left' | 'right'> {
  testID?: string;
  left: string;
  leftTooltipText?: string;
  tooltipOffset?: TooltipProps['bodyHorizontalOffset'];
  tooltipCaretPosition?: TooltipProps['caretPosition'];
  tooltipContentWidth?: number;
  isLast?: boolean;
  right: string | React.ReactElement | null;
}

export const InformationTableRow: React.FC<TableRowProps> = ({
  testID,
  left,
  leftTooltipText,
  isLast,
  right,
  tooltipOffset,
  tooltipCaretPosition,
  tooltipContentWidth,
  ...boxProps
}) => {
  return (
    <Box
      testID={testID}
      flexDirection="row"
      justifyContent="space-between"
      mb={isLast ? 0 : 16}
      minHeight={16}
      alignItems="center"
      {...boxProps}
    >
      <Box flexDirection="row" alignItems="center" height={14}>
        <Text color="$text-02" variant="$body-02">
          {left}
        </Text>
        {Boolean(leftTooltipText) && (
          <Box mt={1} ml={4}>
            <QuestionTooltip
              bodyHorizontalOffset={tooltipOffset}
              content={leftTooltipText!}
              caretPosition={tooltipCaretPosition}
              width={tooltipContentWidth}
            />
          </Box>
        )}
      </Box>
      <Box height={12} justifyContent="flex-end" flexGrow={1} mx={5}>
        <Box height={1} overflow="hidden">
          <Box
            height={2}
            borderWidth={1}
            borderColor="$ui-02"
            borderStyle="dashed"
          />
        </Box>
      </Box>
      <Box flexDirection="row">
        {typeof right === 'string' ? (
          <Text color="$text-02" variant="$body-02">
            {right}
          </Text>
        ) : (
          right
        )}
      </Box>
    </Box>
  );
};
