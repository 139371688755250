import React from 'react';
import {HodlTariffResponseItem} from '@youtoken/ui.resource-hodl-tariffs';
import {HODLPublicInstrumentItem} from '@youtoken/ui.resource-hodl-public-instruments';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {RateSmart} from '../RateSmart';

interface InstrumentOverviewBlockProps extends BoxProps {
  item: HodlTariffResponseItem | HODLPublicInstrumentItem;
}

export const InstrumentOverviewBlock: React.FC<
  InstrumentOverviewBlockProps
> = ({item, ...boxProps}) => {
  if (!item) {
    return null;
  }

  return (
    <Box
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      {...boxProps}
    >
      <RateSmart baseTicker={item.baseTicker} quoteTicker={item.quoteTicker} />
    </Box>
  );
};
