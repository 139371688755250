import * as React from 'react';
import {observer} from 'mobx-react';
import {LoanRegularV1, LoanRegularV2} from '@youtoken/ui.resource-loans';
import {Box} from '@youtoken/ui.primitives';
import {
  useResizableBox,
  ResizableBox,
  HODLPreviewChartSmartCell,
} from '@youtoken/ui.legacy-charts';

export interface LoanItemChartPreviewProps {
  item: LoanRegularV1 | LoanRegularV2;
}

export const LoanItemChartPreview: React.FC<LoanItemChartPreviewProps> =
  observer(({item}) => {
    const [{size}, bind] = useResizableBox();

    return (
      <ResizableBox width="100%" height={{desktop: 100, default: 71}} {...bind}>
        {size.width && size.height && item.chartData.length > 2 && (
          <Box testID="LOAN_ITEM_CHART_PREVIEW">
            <HODLPreviewChartSmartCell
              fromTicker={item.collateralTicker}
              toTicker={item.borrowedTicker}
              data={{
                type: 'line',
                data: item.isClosed
                  ? item.chartData
                  : item.chartDataInteractive,
              }}
              width={size.width}
              height={size.height}
              startDate={item.startedAt ? item.startedAt : undefined}
              endDate={item.endDate ? item.endDate : undefined}
              takeProfit={item.tp?.toNumber()}
              marginCall={item.mcPrice.toNumber()}
              initialPrice={item.initialPrice.toNumber()}
              closedPrice={item.closedPrice?.toNumber()}
              isClosedLike={item.isClosed}
              isOpeningLike={item.isOpening}
              direction={
                item.isOpening || item.isClosed ? 'neutral' : undefined
              } // NOTE: if no direction set, get it from market moving
            />
          </Box>
        )}
      </ResizableBox>
    );
  });
